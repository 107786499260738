import toastActions from "./actions";
import toastMutations from "./mutations";
import toastGetters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      isEnabled: false,
      message: "",
      color: "",
    };
  },
  mutations: toastMutations,
  actions: toastActions,
  getters: toastGetters,
};
