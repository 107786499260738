export const RESOURCES_ACTIONS_MENU_TYPES = {
  ADD_CAT: "add_cat",
  ADD_RESOURCE: "add_resource",
  ADD_SUB_CAT: "add_sub_cat",
  REMOVE_RESOURCE_CATE: "remove-resource-cate",
  RENAME_RESOURCE_CATE: "rename-resource-cate",
};

export const RESOURCES_ACTIONS_MENU = [
  { text: "Add New Group", id: RESOURCES_ACTIONS_MENU_TYPES.ADD_CAT },
  { text: "Add New Sub-group", id: RESOURCES_ACTIONS_MENU_TYPES.ADD_SUB_CAT },
  { text: "Add Resource", id: RESOURCES_ACTIONS_MENU_TYPES.ADD_RESOURCE },
  {
    text: "Rename Item",
    id: RESOURCES_ACTIONS_MENU_TYPES.RENAME_RESOURCE_CATE,
  },
  {
    text: "Remove Item",
    id: RESOURCES_ACTIONS_MENU_TYPES.REMOVE_RESOURCE_CATE,
  },
];

export const RESOURCE_CATE_ACTIONS_TO_TOOGLE = [
  "Remove Item",
  "Rename Item",
  // "Add New Topic",
  "Add New Sub-group",
];

export const RESOURCE_CATE_ACTIONS_TO_TOOGLE_IN_PROJECT = [
  "Remove Item",
  "Rename Item",
  "Add New Group",
  "Add New Sub-group",
];

export const RESOURCE_ITEM_ACTIONS_TO_SHOW_IN_PROJECT = [
  "Remove Item",
  "Rename Item",
];

export const MENU_OPTS_HIDE_FOR_RESOURCE = [
  // "Add Place",
  "Add New Group",
  "Add New Sub-group",
];
