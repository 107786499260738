import stateActions from "./actions";
import stateMutators from "./mutations";
import stateGetters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      isDialogOpen: false,
      taskData: {},
    };
  },
  actions: stateActions,
  mutations: stateMutators,
  getters: stateGetters,
};
