<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        small
        tabindex="-1"
        @click="handleClick"
        :class="btnClassNames"
      >
        <v-icon :size="btnIconSize" :color="btnIconColor">
          {{ btnIcon }}</v-icon
        >
      </v-btn>
    </template>
    <span>{{ btnText }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    btnText: {
      type: String,
    },
    btnIcon: {
      type: String,
      default: "mdi-pencil",
    },
    btnIconColor: {
      type: String,
    },
    btnIconSize: {
      type: [String, Number],
      default: 18,
    },
    cssClass: {
      type: String,
    },
  },
  methods: {
    handleClick(e) {
      e.stopPropagation();
      e.preventDefault();
      this.$emit("click", e);
    },
  },
  computed: {
    btnClassNames() {
      return {
        "edit-btn": true,
        [this.cssClass]: !!this.cssClass,
      };
    },
  },
};
</script>
<style scoped>
.edit-btn {
  display: none;
  padding-bottom: 2px;
}
</style>
