<template>
  <div class="wrapper">
    <OptSelectorInput
      ref="select"
      :value="selectedVal"
      :opts="projectOpts"
      :itemLabelToUse="labelToUse"
      itemValueToUse="value"
      placeholder="Select Area"
      @change="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="handleKeyDown"
      :selectorClass="selectorClasses"
      :menuProps="{ contentClass: 'project-selector-popup' }"
      :useCustomSlot="true"
    >
      <template #no-data>
        <v-btn @click="addNewProject" text class="new-proj-btn">
          Add New Area
        </v-btn>
      </template>
    </OptSelectorInput>
    <ProjectFormDialog @project:added="handleProjectAdd" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { createProjectOpts } from "@/helpers/projects";
import ProjectFormDialog from "./ProjectFormDialog.vue";
import OptSelectorInput from "@/components/OptSelectorInput";
export default {
  data() {
    return {
      selectedVal: "",
    };
  },
  props: {
    value: {
      type: String,
    },
    labelType: {
      type: String,
      default: "full",
    },
    showOnlyIncompleteOpts: {
      type: Boolean,
      default: false,
    },
    selectorClass: {
      type: String
    }
  },
  components: {
    ProjectFormDialog,
    OptSelectorInput,
  },
  computed: {
    ...mapGetters("task", ["projects", "categories"]),
    projectOpts() {
      const projectOpts = createProjectOpts(
        this.projects,
        this.categories,
        this.showOnlyIncompleteOpts
      );
      return projectOpts;
    },
    labelToUse() {
      return this.labelType === "short" ? "shortLabel" : "fullLabel";
    },
    selectorClasses() {
      let classes = "project-input";
      if (this.selectorClass?.trim?.()) {
        classes += ` ${this.selectorClass}`;
      }
      return classes;
    }
  },
  methods: {
    ...mapActions("cateData", ["openCateForm"]),
    handleProjectAdd(projectData) {
      this.selectedVal = projectData.key;
      this.handleChange(projectData.key);
    },
    addNewProject() {
      const currInput = this.$el.querySelector(".v-select__slot  input");
      const enteredVal = currInput.value && currInput.value.trim();

      this.openCateForm({
        data: {
          title: enteredVal,
        },
      });
    },
    handleChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    openInput() {
      this.$refs.select.openInput();
    },
    blurInput() {
      this.$refs.select.blurInput();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedVal = newVal;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.new-proj-btn {
  width: 100%;
  text-transform: none !important;
}
</style>
