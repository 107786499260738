export default {
  taskData(state) {
    return state.taskData;
  },
  taskId(state) {
    return state.taskId;
  },
  projectId(state) {
    return state.projectId;
  },
  isOpen(state) {
    return state.isOpen;
  },
  openNewForm(state) {
    return state.openNewForm;
  },
  selectedTasks(state) {
    return state.selectedTasks;
  },
  updatedTaskData(state) {
    return state.updatedTaskData;
  },
  isEditConfirmBoxOpened(state) {
    return state.isEditConfirmBoxOpened;
  },
  updatedProjectData(state) {
    return state.updatedProjectData;
  },
  noteData(state) {
    return state.noteData;
  },
  resourceData(state) {
    return state.resourceData;
  },
  updatedObjectiveData(state) {
    return state.updatedObjectiveData;
  },
  selectedResourceId(state) {
    return state.selectedResourceId;
  },
  returnViewInfo(state) {
    return state.returnViewInfo;
  },
  resourceDataChanges(state) {
    return state.resourceDataChanges;
  },
};
