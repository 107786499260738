import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"menuWrapper",attrs:{"id":"menu-wrapper"}},[_c('div',{ref:"activatorBtn",attrs:{"id":"activator-btn-wrapper"}},[_vm._t("activator-btn",null,{"handleClick":_vm.openMenu,"selectedValue":_vm.value})],2),_c('div',{staticClass:"selector-wrapper"},[_c(VSelect,{ref:"select",attrs:{"items":_vm.opts,"item-value":"key","value":_vm.value,"menu-props":_vm.selectMenuProps,"disabled":_vm.disabled,"hide-details":""},on:{"change":_vm.handleChange,"blur":_vm.handleBlur,"focus":_vm.handleFocus,"keydown":_vm.handleKeyDown},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._t("selection-content",null,{"item":item})]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_vm._t("item-opt",null,{"item":item,"attrs":attrs,"on":on})]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }