import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"select",class:_vm.selectorClassNames,attrs:{"value":_vm.value,"items":_vm.opts,"item-text":_vm.itemLabelToUse,"item-value":_vm.itemValueToUse,"placeholder":_vm.placeholder,"loading":_vm.loading,"menu-props":_vm.propsForMenu,"tabIndex":_vm.tabIndex,"solo":_vm.solo,"return-object":_vm.returnObject,"auto-select-first":"","hide-details":"","background-color":_vm.backgroundColor,"hideNoData":_vm.hideNoData},on:{"change":_vm.handleChange,"blur":_vm.handleBlur,"focus":_vm.handleFocus,"keydown":_vm.handleKeyDown,"update:search-input":_vm.handleSearch},scopedSlots:_vm._u([(_vm.useItemSelectionSlot)?{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._t("selection-content",null,{"item":item})]}}:null,(_vm.useItemSlot)?{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_vm._t("select-item",null,{"item":item,"attrs":attrs,"on":on})]}}:null,(_vm.useCustomSlot)?{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }