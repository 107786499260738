<template>
  <v-dialog
    width="25%"
    :value="isFormOpen"
    @click:outside="closeDialog"
    @keydown="handleKeyDown"
    content-class="project-form"
    @focus.stop
  >
    <v-card>
      <v-card-title class="text-h6">
        Select the Category and Importance for the new area
      </v-card-title>
      <v-card-text>
        <div class="category-selector-wrapper">
          <ProjectCategorySelector
            ref="categorySelector"
            v-model="selectedCate"
            @keydown="handleSelectorKeyDown"
            placeholder="Select the Category for the new area"
          />
        </div>
        <div class="mt-2 project-priority-selector-wrapper">
          <PrioritySelector
            v-model="selectedPriority"
            ref="prioritySelector"
            placeholder="Select the Importance for the new area"
            @keydown="handleSelectorKeyDown"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed text @click="closeDialog" color="error"> Cancel</v-btn>
        <v-btn
          color="primary"
          class="save-confirm-btn"
          depressed
          text
          @click="submitData"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import { PrioritySelectorInput as PrioritySelector } from "@/components/EditComponents/PrioritySelector";
import { mapActions, mapGetters } from "vuex";
import ProjectCategorySelector from "@/components/ProjectCategorySelector/ProjectCategorySelector.vue";
import { PRIMARY_COLOR } from "@/variables/colors";
import {
  createProjectDataForAdd,
  storeProjectAddAction,
} from "@/helpers/projects";
import isEmpty from "lodash/isEmpty";
import DatabaseInterface from "@/services/DatabaseInterface";
import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_TREE_EVENT } from "@/variables/events";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import isEscKey from "@/utils/isEscKey";
import { isSubmitKeys, isTabKey } from "@/utils/keys";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";

import popupHelpersMixin from "@/mixins/popupHelpersMixin";
export default {
  mixins: [userDetailsMixin, categoriesHelpersMixin, popupHelpersMixin],
  components: {
    ProjectCategorySelector,
    PrioritySelector,
  },
  data() {
    return {
      selectedCate: "",
      selectedPriority: "",
    };
  },
  methods: {
    ...mapActions("cateData", ["resetCateData"]),
    handleSelectorKeyDown(e) {
      e.stopPropagation();
      e.paths = getPathsFromEvent(e);
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
    async submitData() {
      const treeEl = this.showClearedTasks
        ? document.querySelector("#treeview-all-data")
        : document.querySelector("#treeview");
      const cateTree = treeEl?.ej2_instances[0];
      const selectedCate = this.selectedCate && this.selectedCate.trim();
      const selectedPriority = this.selectedPriority?.trim();

      if (!selectedCate) {
        this.$swal({
          titleText: "Oops!",
          html: "Category is required",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
        });
        return;
      }

      if (!selectedPriority) {
        this.$swal({
          titleText: "Oops!",
          html: "Importance is required",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
        });
        return;
      }

      const createdProjectData = createProjectDataForAdd(
        { ...this.projectData, priority: selectedPriority },
        selectedCate
      );

      if (isEmpty(createdProjectData)) {
        this.$swal({
          titleText: "Oops!",
          html: "Invalid Project Data",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
        });

        return;
      }

      // if (cateTree) {
      //   const createdDataWithAttrs = this.addAttributesInCate(
      //     createdProjectData,
      //     "projects-tree"
      //   );
      //   this.addCategoryInTree(createdDataWithAttrs, selectedCate, false);
      // } else {
      await DatabaseInterface.update(
        {
          [`/projects/${createdProjectData.key}`]: createdProjectData,
        },
        this.userInfo.uid
      );

      storeProjectAddAction(createdProjectData);

      if (cateTree) {
        EventEmitter.emit(REFRESH_TREE_EVENT, false);
      }
      // }
      // EventEmitter.emit(NEW_PROJECT_ADDED_EVENT, createdProjectData);
      await Vue.nextTick();
      this.closeDialog();
      this.$emit("project:added", createdProjectData);
    },
    closeDialog() {
      this.selectedCate = "";
      this.selectedPriority = "";
      this.resetCateData();
    },
    handleKeyDown(e) {
      let paths = getPathsFromEvent(e);

      if (isEmpty(paths)) {
        paths = !isEmpty(e.paths) ? e.paths : [];
      }
      if (isEscKey(e)) {
        this.closeDialog();
      }

      if (isSubmitKeys(e)) {
        const submitBtnParentFound = findParentFromPathsByClassName(paths, [
          "save-confirm-btn",
        ]);
        if (submitBtnParentFound && !e.shiftKey) {
          this.submitData();
          return;
        }
      }

      if (isTabKey(e)) {
        const cateSelectorElFound = findParentFromPathsByClassName(paths, [
          "category-selector-wrapper",
        ]);
        const prioritySelectorElFound = findParentFromPathsByClassName(paths, [
          "project-priority-selector-wrapper",
        ]);
        if (!e.shiftKey) {
          if (cateSelectorElFound) {
            this.$refs.categorySelector.blurInput();
            setTimeout(() => {
              this.$refs.prioritySelector.openInput();
            }, 0);
            // handle tab from category
          }

          if (prioritySelectorElFound) {
            this.$refs.prioritySelector.blurInput();
            setTimeout(() => {
              const btn = document.querySelector(
                ".project-form .save-confirm-btn"
              );
              btn?.focus();
            }, 0);
            // handle tab from priority
          }
        }
        // const el = this.$refs.categorySelector;
        // el?.blurInput?.();
      }
    },
    focusOnInput() {
      this.focusOnSelector("categorySelector");
    },
  },
  computed: {
    ...mapGetters("cateData", {
      projectData: "data",
      isFormOpen: "isFormOpen",
    }),
    ...mapGetters("task", ["showClearedTasks"]),
  },
  watch: {
    isFormOpen(n) {
      if (n) {
        this.focusOnInput();
      }
    },
  },
};
</script>
<style>
.project-form .project-category-input,
.project-form .priority-type-input {
  padding-top: 0px;
  margin-top: 0px;
}
</style>
