import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex d-md-block flex nav-bar",class:{'flex-column': !_vm.sidebarOpen}},[_c(VBtnToggle,{staticClass:"nav-bar-btns",attrs:{"value":_vm.navIndex,"mandatory":"","text":"","dense":"","color":_vm.isInboxViewEnabled ? '#878787' : 'var(--primary-color)',"group":""}},_vm._l((_vm.menuOpts),function(item){return _c(VTooltip,{key:item.index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"nav-btn keep-task-selected",attrs:{"link":"","icon":"","elevation":"0","data-action-type":"nav-route-change","data-action-value":item.actionVal},on:{"click":function($event){return _vm.handleMenuClick(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":item.fontSize}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"float-right non-toggle-button nav-btn",attrs:{"link":"","icon":"","elevation":"0","color":_vm.isInboxViewEnabled ? 'var(--primary-color)' : '#878787',"data-action-type":"nav-route-change","data-action-value":"inbox"},on:{"click":function($event){return _vm.handleMenuClick(_vm.inboxMenuConfig)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" $ctiInboxNewIcon ")])],1)]}}])},[_c('span',[_vm._v("Inbox")])]),(!_vm.sidebarOpen)?_c('div',{staticClass:"d-md-none d-flex flex align-end"},[_c('SettingsMenu')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }