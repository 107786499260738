import Vue from "vue";

import { mapGetters } from "vuex";
import {
  createRestoreDataForCategory,
  updateCatOrSubCatOrProject,
  createUpdateData as createCatUpdateData,
  addCategories,
  removeCategories,
  // createCatOrSubCatOrProjectDataForTree,
  // removeCategories,
} from "@/helpers/categories";
import userDetailsMixin from "./userDetailsMixin";
import categoriesHelpersMixin from "./categoriesHelpersMixin";
// import { findProjectOrCategory, removeProject } from "@/helpers/projects";
import {
  cloneDeep,
  // cloneDeep,
  isEmpty,
} from "lodash";
import DatabaseInterface from "@/services/DatabaseInterface";
import { catTypes } from "@/variables/categories";
import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_TREE_EVENT, TREE_NODE_RENAME_EVENT } from "@/variables/events";
import {
  moveResourceCateByTaskCateData,
  removeTaskCateFromResourceCateData,
  storeTaskCateInResourceCateData,
  updateTaskCateInResourceCateData,
} from "@/helpers/resourceCategoriesHelpers";
import { CATEGORY_ACTIONS } from "@/helpers/actionHistory";
import { getTaskTreeView } from "@/helpers/tasks";
import { addProject, removeProject } from "@/helpers/projects";
// import { getTreeFields } from "@/components/TreeView/treeUtils";
const mixin = {
  mixins: [userDetailsMixin, categoriesHelpersMixin],
  methods: {
    restoreCategoriesData(catData, isRedoAction) {
      if (!Array.isArray(catData)) catData = [catData];
      const renameList = [];
      const parentChangedList = [];
      const extraDataUpdates = [];
      const visibilityUpdates = [];
      catData.forEach((cat) => {
        const { updates, type } = createRestoreDataForCategory(
          isRedoAction ? cat.changedProps : cat,
          cat.key,
          cat.type
        );
        let catType;
        if (this.categories[cat.key]) {
          catType = "category";
        } else if (this.projects[cat.key]) {
          catType = this.projects[cat.key].type;
        }

        if (type) {
          if (type === "parent-changed") {
            parentChangedList.push({
              updates: { ...updates },
              key: cat.key,
              type: catType,
            });
          } else if (type === "rename") {
            renameList.push({
              updates: { ...updates },
              key: cat.key,
              type: catType,
            });
          } else if (type === "extra-details") {
            extraDataUpdates.push({
              updates: { ...updates },
              key: cat.key,
              type: catType,
            });
          } else if (type === "visibility") {
            visibilityUpdates.push({
              updates: { ...updates },
              key: cat.key,
              type: catType,
            });
          }
        }
      });

      this.handleRenameOfCategories(renameList);
      this.handleParentChanged(parentChangedList);
      this.handleExtraDetailsCatUpdate(extraDataUpdates);
      this.handleVisibilityUpdates(visibilityUpdates);
    },
    handleRenameOfCategories(list) {
      if (list && list.length) {
        const resourceUpdates = [];

        list.forEach((i) => {
          resourceUpdates.push({
            refKey: i.key,
            updatedData: {
              title: i.updates.title,
            },
          });
        });

        updateTaskCateInResourceCateData(resourceUpdates);

        let { element: treeElement } = getTaskTreeView();
        if (treeElement) {
          this.updateLocalTree(list, true);
        } else {
          updateCatOrSubCatOrProject(list, this.userInfo.uid, false);
        }

        // updateCatOrSubCatOrProject(list, this.userInfo.uid, false);
      }
    },
    async handleExtraDetailsCatUpdate(list) {
      if (list && list.length) {
        await updateCatOrSubCatOrProject(list, this.userInfo.uid, false);
        EventEmitter.emit(REFRESH_TREE_EVENT, undefined, false);
      }
    },
    handleHiddenCateForResources(list) {
      if (!isEmpty(list)) {
        const resourceUpdates = [];
        list.forEach((i) => {
          if (!isEmpty(i.updates)) {
            const resrcUpdates = {};

            if (i.updates.hidden !== undefined) {
              resrcUpdates.hidden = i.updates.hidden;
            }

            if (i.updates.deleted !== undefined) {
              resrcUpdates.deleted = i.updates.deleted;
            }

            resourceUpdates.push({
              refKey: i.key,
              updatedData: { ...resrcUpdates },
            });
          }
        });

        updateTaskCateInResourceCateData(resourceUpdates);
      }
    },
    async handleVisibilityUpdates(list) {
      if (list && list.length) {
        this.handleHiddenCateForResources(list);
        await updateCatOrSubCatOrProject(list, this.userInfo.uid, false);
        EventEmitter.emit(REFRESH_TREE_EVENT, undefined, false);
      }
    },
    async updateLocalTree(list, useEvent) {
      // const { projects, categories } = createCatOrSubCatOrProjectDataForTree(
      //   list,
      //   this.projects,
      //   this.categories
      // );

      if (useEvent) {
        EventEmitter.emit(TREE_NODE_RENAME_EVENT, true, true);
      }

      await Vue.nextTick();

      const targetId = this.showClearedTasks
        ? "#treeview-all-data"
        : "#treeview";
      const treevalidate = document.querySelector(targetId).ej2_instances[0];
      list.forEach((item) => {
        treevalidate.updateNode(item.key, item.updates.title);
      });

      // Vue.nextTick(() => {
      //   if (useEvent) {
      //     EventEmitter.emit(TREE_NODE_RENAME_EVENT, false);
      //   }
      // });

      // const treeData = getTreeFields({
      //   categories,
      //   projects,
      //   tasksMap: this.tasksMap,
      //   selectedNodes: this.selectedCatIds,
      //   isInspectModeEnabled: this.isInspectModeEnabled,
      // });

      // treevalidate.fields.dataSource = treeData.dataSource;
    },
    async handleParentChanged(list) {
      if (list && list.length) {
        const resourceCateUpdates = [];
        const { element: treeElement } = getTaskTreeView();

        const isTreeAvailable = !isEmpty(treeElement);
        const projectTypes = ["project", "subcategory"];

        const dbUpdatesList = [];
        // const clonedTree = cloneDeep(this.projectsTree);
        // const catUpdatesMap = {};
        // const oldParentUpdatesMap = {};
        list.forEach((itemD) => {
          const itemUpdates = itemD.updates;
          const dirOrder = itemUpdates.order;
          // const dirUpdates = [];
          const parentKey = itemUpdates.subcategory
            ? itemUpdates.subcategory
            : itemUpdates.category;
          let itemData = {};
          let currData = {};
          if (
            projectTypes.indexOf(itemD.type) >= 0 &&
            this.projects[itemD.key]
          ) {
            currData = this.projects[itemD.key];
          } else if (itemD.type === "category" && this.categories[itemD.key]) {
            currData = this.categories[itemD.key];
          }

          if (!isEmpty(currData)) {
            // const currParentKey = itemData.subcategory
            //   ? itemData.subcategory
            //   : itemData.category;

            itemData = {
              ...currData,
              order: dirOrder,
              subcategory: itemUpdates.subcategory,
              category: itemUpdates.category,
              key: itemD.key,
            };

            resourceCateUpdates.push({
              refKey: itemD.key,
              order: dirOrder,
              parentKey,
            });

            // if (!catUpdatesMap[parentKey || "root"]) {
            //   catUpdatesMap[parentKey || "root"] = [];
            // }
            // let children = clonedTree;

            // if (parentKey) {
            //   const parent = findProjectOrCategory(
            //     clonedTree,
            //     (node) => node.key === parentKey
            //   );
            //   children = parent.projects || [];
            // }

            // if (currParentKey) {
            //   const currParent = findProjectOrCategory(
            //     clonedTree,
            //     (node) => node.key === currParentKey
            //   );
            //   const currChildren = currParent?.projects || [];
            //   if (currChildren && currChildren.length) {
            //     const currIndex = currChildren.findIndex(
            //       (c) => c.key === itemD.key
            //     );
            //     if (currIndex >= 0) {
            //       currChildren.splice(currIndex, 1);
            //       currChildren.sort((a, b) => a.order - b.order);
            //       if (!oldParentUpdatesMap[currParentKey]) {
            //         oldParentUpdatesMap[currParentKey] = [];
            //       }
            //       currChildren.forEach((currChild, index) => {
            //         oldParentUpdatesMap[currParentKey].push({
            //           updates: {
            //             order: index,
            //           },
            //           key: currChild.key,
            //           title: currChild.title,
            //           type: currChild.type,
            //         });
            //       });
            //     }
            //   }
            // }

            // if (children && children.length) {
            //   children = cloneDeep(children);
            //   const currIndex = children.findIndex((c) => c.key === itemD.key);
            //   if (currIndex >= 0) {
            //     children.splice(currIndex, 1);
            //   }
            //   children.splice(dirOrder, 0, { ...itemData });
            //   children.sort((a, b) => a.order - b.order);
            //   children.forEach((c, index) => {
            //     dirUpdates.push({
            //       updates: {
            //         order: index,
            //         subcategory: c.subcategory,
            //         category: c.category,
            //       },
            //       key: c.key,
            //       type: c.type,
            //       title: c.title,
            //     });
            //   });
            // } else {
            //   children.push({
            //     ...itemData,
            //     order: 0,
            //   });
            //   dirUpdates.push({
            //     updates: {
            //       order: 0,
            //       subcategory: itemData.subcategory,
            //       category: itemData.category,
            //     },
            //     key: itemData.key,
            //     type: itemData.type,
            //   });
            // }

            // catUpdatesMap[parentKey || "root"] = dirUpdates;

            if (isTreeAvailable) {
              this.moveNodesInTree(itemData, parentKey);
            } else {
              dbUpdatesList.push({
                ...itemD,
                updates: {
                  ...itemData,
                  subcategory: itemData.subcategory || "",
                  category: itemData.category || "",
                },
              });
            }
          }
        });

        moveResourceCateByTaskCateData(resourceCateUpdates);

        if (!isEmpty(dbUpdatesList)) {
          await updateCatOrSubCatOrProject(
            dbUpdatesList,
            this.userInfo.uid,
            false
          );
        }

        // await this.handleReorderAndMoveForLocalTree(
        //   catUpdatesMap,
        //   oldParentUpdatesMap
        // );

        // this.handleCatReorderUpdates(catUpdatesMap, oldParentUpdatesMap);
        // this.handleOldParentUpdatesMap(oldParentUpdatesMap);
        // list.forEach((i) => {});
        // updateCatOrSubCatOrProject(list, this.userInfo.uid, false);
      }
    },
    async handleProjectAddAction(projectData) {
      if (!Array.isArray(projectData)) projectData = [projectData];

      const resourcesListUpdates = [];

      const { element: treeElement } = getTaskTreeView();

      const isTreeAvailable = !isEmpty(treeElement);
      const dbUpdatesList = [];
      // const categoriesSnapShot = await DatabaseInterface.get(
      //   `/${this.userInfo.uid}/projects/`
      // ).get();
      // let currProjects = cloneDeep(categoriesSnapShot.val());
      projectData.forEach((proj) => {
        // const projOrder = proj.order;
        let createdProjectData = { ...proj };
        // currProjects = {
        //   ...currProjects,
        //   [proj.key]: {
        //     ...proj,
        //   },
        // };
        let parentId = proj.subcategory ? proj.subcategory : proj.category;
        resourcesListUpdates.push(createdProjectData);
        // if (parentId) {
        // const parentData = findProjectOrCategory(
        //   this.projectsTree,
        //   (node) => node.key === parentId
        // );
        // let children = [];
        // if (!isEmpty(parentData)) {
        //   children = parentData.projects;
        // }

        // if (children && children.length) {
        //   const currIndex = children.findIndex(
        //     (c) => c.key === projectData.key
        //   );
        //   if (currIndex >= 0) {
        //     children.splice(currIndex, 1);
        //   }
        //   children.splice(projOrder, 0, proj);
        //   children.sort((a, b) => a.order - b.order);
        // } else {
        //   createdProjectData = { ...createdProjectData, order: 0 };
        //   children = [{ ...createdProjectData }];
        // }

        // children.forEach((ch, index) => {
        //   currProjects[ch.key] = {
        //     ...currProjects[ch.key],
        //     order: index,
        //   };
        // });
        // }

        // this.updateCategoriesTree(currProjects, this.categories);
        // console.debug("CREATED", createdProjectData);

        if (isTreeAvailable) {
          this.addCategoryInTree({ ...createdProjectData }, parentId, false);
        } else {
          dbUpdatesList.push({ ...createdProjectData });
        }
      });

      storeTaskCateInResourceCateData(resourcesListUpdates);

      if (!isEmpty(dbUpdatesList)) {
        addProject(dbUpdatesList, undefined, false);
      }

      // DatabaseInterface.addTo(`/${this.userInfo.uid}/projects/`, currProjects);
    },
    async handleAddCategoryAction(catData) {
      let subCategoriesList = [];
      let categoriesList = [];
      const resourceListUpdates = [];
      if (Array.isArray(catData)) {
        catData.forEach((cat) => {
          // cat.key = createUniqueId();
          if (cat.type === "category") {
            categoriesList.push(cat);
          } else if (cat.type === "subcategory") {
            subCategoriesList.push(cat);
          }
        });
      } else {
        // catData.key = createUniqueId();
        if (catData.type === "category") {
          categoriesList.push(catData);
        } else if (catData.type === "subcategory") {
          subCategoriesList.push(catData);
        }
      }

      if (categoriesList && categoriesList.length) {
        const { element: treeElement } = getTaskTreeView();

        const isTreeAvailable = !isEmpty(treeElement);

        const dbUpdatesList = [];
        // let children = cloneDeep(this.projectsTree);
        // const categoriesSnapShot = await DatabaseInterface.get(
        //   `/${this.userInfo.uid}/categories/`
        // ).get();
        // let currCategories = cloneDeep(categoriesSnapShot.val());
        categoriesList.forEach((cat) => {
          let createdCatData = { ...cat };
          // currCategories = {
          //   ...currCategories,
          //   [cat.key]: {
          //     ...cat,
          //   },
          // };

          // if (children && children.length) {
          //   const currIndex = children.findIndex((c) => c.key === cat.key);
          //   if (currIndex >= 0) {
          //     children.splice(currIndex, 1);
          //   }
          //   children.splice(catOrder, 0, cat);
          //   children.sort((a, b) => a.order - b.order);
          // } else {
          //   createdCatData = { ...createdCatData, order: 0 };
          //   // children = [{ ...createdCatData }];
          // }

          // children.forEach((ch, index) => {
          //   currCategories[ch.key] = {
          //     ...currCategories[ch.key],
          //     order: index,
          //   };
          // });

          resourceListUpdates.push(createdCatData);

          if (isTreeAvailable) {
            this.addCategoryInTree(createdCatData, null, false);
          } else {
            dbUpdatesList.push(createdCatData);
          }
        });

        if (!isEmpty(dbUpdatesList)) {
          addCategories(dbUpdatesList, undefined, false);
        }

        // await DatabaseInterface.addTo(
        //   `/${this.userInfo.uid}/categories/`,
        //   currCategories
        // );
      }

      if (subCategoriesList && subCategoriesList.length) {
        // const categoriesSnapShot = await DatabaseInterface.get(
        //   `/${this.userInfo.uid}/projects/`
        // ).get();
        // let currSubCategories = cloneDeep(categoriesSnapShot.val());

        subCategoriesList.forEach((cat) => {
          // const catOrder = cat.order;
          const catParentKey = cat.subcategory ? cat.subcategory : cat.category;
          let createdSubCatData = { ...cat };
          // currSubCategories = {
          //   ...currSubCategories,
          //   [cat.key]: {
          //     ...cat,
          //   },
          // };

          // if (catParentKey) {
          //   // const parent = findProjectOrCategory(
          //   //   this.projectsTree,
          //   //   (node) => node.key === catParentKey
          //   // );
          //   // let children = parent.projects || [];
          //   // if (children && children.length) {
          //   //   children = cloneDeep(children);
          //   //   const currIndex = children.findIndex((c) => c.key === cat.key);
          //   //   if (currIndex >= 0) {
          //   //     children.splice(currIndex, 1);
          //   //   }
          //   //   children.splice(catOrder, 0, cat);
          //   //   children.sort((a, b) => a.order - b.order);
          //   // } else {
          //   //   createdSubCatData = { ...createdSubCatData, order: 0 };
          //   //   children = [{ ...createdSubCatData }];
          //   // }

          //   // children.forEach((ch, index) => {
          //   //   currSubCategories[ch.key] = {
          //   //     ...currSubCategories[ch.key],
          //   //     order: index,
          //   //   };
          //   // });
          // }
          resourceListUpdates.push(createdSubCatData);
          this.addCategoryInTree(createdSubCatData, catParentKey, false);
        });
        // await DatabaseInterface.addTo(
        //   `/${this.userInfo.uid}/projects/`,
        //   currSubCategories
        // );
      }

      storeTaskCateInResourceCateData(resourceListUpdates);
    },
    async handleRemoveCategoryAction(catData) {
      // await removeCategories(cloneDeep(catData), this.userInfo.uid, false);
      const taskCateToRemove = [catData.key];

      removeTaskCateFromResourceCateData(taskCateToRemove);

      const { element: treeElement } = getTaskTreeView();

      if (treeElement) {
        this.removeCategoryFromTree(catData.key);
      } else {
        removeCategories([catData], undefined, false);
      }
    },
    async handleProjectRemoveAction(projData) {
      // await removeProject(projData, this.userInfo.uid, false);

      const taskCateToRemove = [projData.key];
      removeTaskCateFromResourceCateData(taskCateToRemove);

      const { element: treeElement } = getTaskTreeView();

      if (treeElement) {
        this.removeCategoryFromTree(projData.key);
      } else {
        removeProject([{ ...projData }], undefined, false);
      }
    },
    async handleCatReorderUpdates(catUpdatesMap, oldParentUpdatesMap) {
      // const catUpdatesKeys = Object.keys(catUpdatesMap);
      const combinedMap = { ...catUpdatesMap, ...oldParentUpdatesMap };

      const combinedMapKeys = Object.keys(combinedMap);
      let updates = {};
      for (const catKey of combinedMapKeys) {
        combinedMap[catKey].forEach((i) => {
          const keyPath = `/${catTypes[i.type]}/${i.key}`;
          createCatUpdateData(i.updates, keyPath, updates);
        });
      }

      DatabaseInterface.update(updates, this.userInfo.uid);
    },
    async handleOldParentUpdatesMap(oldParentUpdatesMap) {
      const oldParentUpdatesKeys = Object.keys(oldParentUpdatesMap);
      let updates = {};
      for (const catKey of oldParentUpdatesKeys) {
        oldParentUpdatesMap[catKey].forEach((i) => {
          const keyPath = `/${catTypes[i.type]}/${i.key}`;
          createCatUpdateData(i.updates, keyPath, updates);
        });
      }
      DatabaseInterface.update(updates, this.userInfo.uid);
    },
    async handleReorderAndMoveForLocalTree(catUpdatesMap, oldParentUpdatesMap) {
      // const catUpdatesKeys = Object.keys(catUpdatesMap);
      const combinedMap = { ...catUpdatesMap, ...oldParentUpdatesMap };
      const combinedMapKeys = Object.keys(combinedMap);
      const updateList = [];
      for (const catKey of combinedMapKeys) {
        combinedMap[catKey].forEach((i) => {
          updateList.push({
            key: i.key,
            type: i.type,
            updates: i.updates,
          });
        });
      }

      // const oldParentUpdatesKeys = Object.keys(oldParentUpdatesMap);
      // for (const catKey of oldParentUpdatesKeys) {
      //   oldParentUpdatesMap[catKey].forEach((i) => {
      //     updateList.push({
      //       key: i.key,
      //       type: i.type,
      //       updates: i.updates,
      //     });
      //   });
      // }

      this.updateLocalTree(updateList);
    },
    handleCategoryUndoAction(currAction) {
      switch (currAction.type) {
        case CATEGORY_ACTIONS.EDIT:
        case CATEGORY_ACTIONS.BATCH_EDIT:
          this.restoreCategoriesData(currAction.data);
          break;
        case CATEGORY_ACTIONS.ADD:
        case CATEGORY_ACTIONS.BATCH_ADD:
          this.handleRemoveCategoryAction(currAction.data);
          // await removeCategories(
          //   cloneDeep(currAction.data),
          //   this.userInfo.uid,
          //   false
          // );
          break;
        case CATEGORY_ACTIONS.DELETE:
        case CATEGORY_ACTIONS.BATCH_DELETE:
          this.handleAddCategoryAction(
            currAction.data,
            this.userInfo.uid,
            false
          );
          break;
        case CATEGORY_ACTIONS.REORDER_OR_MOVE:
          this.restoreCategoriesData(currAction.data);
          break;
      }
    },
    async handleCategoryRedoAction(currAction) {
      switch (currAction.type) {
        // case CATEGORY_ACTIONS.EDIT:
        //   await this.restoreProjectData(currAction.data);
        //   break;
        case CATEGORY_ACTIONS.ADD:
        case CATEGORY_ACTIONS.BATCH_ADD:
          await this.handleAddCategoryAction(
            currAction.data,
            this.userInfo.uid,
            false
          );
          break;
        case CATEGORY_ACTIONS.DELETE:
        case CATEGORY_ACTIONS.BATCH_DELETE:
          await this.handleRemoveCategoryAction(cloneDeep(currAction.data));
          break;
        case CATEGORY_ACTIONS.EDIT:
        case CATEGORY_ACTIONS.BATCH_EDIT:
        case CATEGORY_ACTIONS.REORDER_OR_MOVE:
          await this.restoreCategoriesData(currAction.data, true);
          break;
      }
    },
  },
  computed: {
    ...mapGetters("task", [
      "projects",
      "categories",
      "projectsTree",
      "selectedOptsList",
      "isInspectModeEnabled",
      "showClearedTasks",
    ]),
  },
};

export default mixin;
