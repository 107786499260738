<template>
  <div style="height: 100%">
    <div v-show="areTasksVisible" style="height: 100%">
      <div
        id="data-table-wrapper"
        style="height: 100%"
        :class="tableWrapperStyles"
        @contextmenu="handleContextMenuTrigger"
      >
        <DataTable
          :list="tasks"
          :columnsList="tableColumns"
          ref="dataGrid"
          :itemSize="36"
          :options="options"
          @filter="handleFilterSuccess"
          @edit:enabled="handleEditEnabled"
          @list:processed="handleListProcessed"
          @list:post-grouping="handlePostGrouping"
          :renderGroupCaption="createGroupItemsCaptions"
          :rowClassNamesCreator="createRowClassNames"
          :rowSelectionChecker="checkIfRowSelectionIsAllowed"
          :allowDragAndDrop="allowDragAndDrop"
          :rowDragVerifier="verifyRowDrag"
          :whiteListBtns="[
            'project-form',
            'data-table-popup-menu',
            'keep-task-selected',
            'add-resource-pop-up',
            'resource-type-selector',
            'resource-type-cate-selector',
            'resource-save-btn',
            'resource-title-editor-wrapper',
            'resource-error-pop-up',
            'convert-dialog',
            'note-path-selector-popup',
            'v-overlay',
            'area-priority-selector-wrapper',
            'area-priority-setter-dialog',
          ]"
          @row:clicked="handleRowClicked"
          @row:added="handleRowAdd"
          @row:updated="handleRowUpdated"
          @rows:selected="handleRowsSelected"
          @row:reordered="handleRowDragStop"
          @row:focused="handleRowFocused"
          @row:dragging="handleRowDragging"
          @group:toggled="handleGroupToggle"
          @keydown="handleKeydown"
          @key-pressed:delete="handleDeleteCall"
          @row:touch:long="handleLongTouch"
          :collapsedGroupsList="collapsedGroupsList"
          :postSortProcessor="processTasksAfterSort"
          :postGroupProcessor="processTasksAfterGrouping"
          :verifyColumnSortablity="checkColumnIsSortable"
          :checkRowDragEnabled="checkIfRowDragEnabled"
          placeholderText="Select tasks to display"
        />
        <!-- :collapsedGroupsList="collapsedGroups" -->
      </div>
      <ContextMenu
        target="#data-table-wrapper"
        :items="menuItems"
        menuId="data-table-menu-opts"
        @menu-item:selected="handleMenuSelection"
        @beforeMenuOpen="handleBeforeMenuOpen"
        class="data-table-popup-menu"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import DataTable from "@/core/components/DataTable";
import ContextMenu from "@/core/components/ContextMenu";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ActionsVue from "./components/TasksActionCell.vue";
import RowHandler from "@/components/GridComponents/RowHandlerCell.vue";
import TaskTypeCell from "./components/TaskTypeCell.vue";
import Project from "./components/ProjectNameCell.vue";
import Due from "./components/DueCell.vue";
import Flag from "./components/FlagCell.vue";
import TaskTypeSelectEditorCell from "./components/EditTaskTypeCell.vue";
import TitleViewerCell from "@/components/GridComponents/TitleViewerCell.vue";
import ProjectEditor from "./components/EditProjectCell.vue";
import DateCell from "@/components/GridComponents/DateCell.vue";
import EditDueDateCell from "./components/EditDueDateCell.vue";
import EventEmitter from "@/helpers/eventEmitter";
import {
  createUpdateAndEditedTaskDataByCell,
  createTaskRowDataByCell,
  // createTaskSearchQueryFilter,
  createTaskListFiltersFromFiltersMap,
  createShowClearedTasksFilters,
  addProjectOrCategoriesFilter,
  addDueDateFilter,
  processTaskList,
  createReviewGroupLabel,
  createDataForAddOrRemoveOrUpdateInGoogleCalByTaskData,
  isTaskRecurring,
  createTaskDataFromProvidedData,
  addOrRemoveOrUpdateTasksInLocalTasksList,
  addOrRemoveOrUpdateTasksInDb,
  createDateTimeFromDue,
  createTaskDataForGoogleCal,
  parseRuleDataFromTaskRecurrenceRules,
  createRecurringDatesFromTaskRules,
  getRecurrenceIdByDateFromRecurrences,
  excludeDatesInTaskRules,
  processDroppedTasks,
  getTasksByOrderAndPosition,
  isTaskVirtual,
  createDbDataForConvertingRecurringTaskToRealTask,
  createAndAddTodayFilter,
  duplicateTask,
  checkIfTaskIsContinued,
  createContinuedTaskData,
  createTaskRowDataByRowData,
  checkIfRowTaskDataEditedChanged,
  createDbUpdateDataFromProvidedData,
  checkIfTaskHasReminder,
  createReminderTasks,
  getReminderTasksLinkedToTaskId,
  // filterOutFlaggedAndUnFlaggedDraggedTasks,
  filterOutCustomOrderTasks,
  checkIfSelectedTaskCateHasOnlyArea,
  isTitleUpdated,
  isTypeUpdated,
  isProjectUpdated,
  createSearchQueryFilters,
  getOrderedTasksByProject,
  checkIfTaskIsUnscheduled,
  getNextFirstTaskByDate,
  checkIfTaskIsOrderedTask,
  checkIfSelectedTaskFiltersHasCategory,
  checkStatusBtnPosIsFlagCell,
  checkTaskSettingsIsNotEmpty,
  checkStatusBtnPosIsAreaCell,
  checkTaskAdditionalFiltersEmpty,
  checkIfContingentTaskIsUnscheduled,
  checkIfTaskIsRecurring,
} from "@/helpers/tasks";
import { createDateFromCommand, formatDate, getCurrDate, isSameDates, makeDateFormat, makeTimeFormat } from "@/helpers/dates";
import DatabaseInterface from "@/services/DatabaseInterface";
import { getUserActions, TASK_ACTIONS } from "@/helpers/actionHistory";
import {
  sortOpts,
  groupOpts,
  INLINE_EDIT_TASK_PROPS,
  TASK_MENU_ACTIONS,
  TASK_ACTIONS_TYPES,
  TASK_PROPS_FOR_FORM,
  REMINDER_TASK_EDIT_PROPS,
  nextActionsGroupsDragAllowed,
  DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS,
} from "@/variables/tasks";
import {
  dragAllowedGroups,
  navViewFiltersConfig,
  treeSideBarModes,
  viewsWithDateFilter,
  calModes,
} from "@/variables/viewConfigs";
import createGroupedTasksCaptions, {
  createCategoryCaption,
} from "./helpers/createGroupedTasksCaptions";
import {
  groupsConfigs as groupViewConfigs,
  clearedTaskFiltersToRemove,
} from "@/variables/viewConfigs";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import recurringTasksHelperMixin from "@/mixins/recurringTasksHelpersMixin";
import googleCalHelpersMixin, {
  addOrUpdateOrRemoveTasksInGoogleCal,
  addTasksToGoogleCal,
} from "@/mixins/googleCalHelpersMixins";
import cloneDeep from "lodash/cloneDeep";
import { isValidDate } from "@/helpers/dates";
import {
  ADD_TASK_EVENT,
  REFRESH_TASKS_EVENT,
  CLOSE_INLINE_ADD,
  SELECT_TEXT_IN_INPUT,
  TASK_ADDED_EVENT,
  SET_ID_FOR_SCROLL,
} from "@/variables/events";
import removeProps from "@/utils/removeProps";
import FlagColumnHeader from "./components/FlagColumnHeader.vue";
import SaveBtnCell from "./components/SaveBtnCell.vue";
import {
  getRowContingentClass,
  getRowPriorityClass,
  getRowReminderClass,
} from "./helpers/rowClassNamesCreators";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import {
  areQlDeltasEqual,
  convertHTMLToQlDelta,
  createOrGetQlInstance,
  removeQlInstance,
} from "@/helpers/quill";
import getTaskRowHandlerColor from "./helpers/getTaskRowHandlerColor";
// import { createUpdateAndEditedForCatOrProj } from "@/helpers/categories";
// import { PRIMARY_COLOR } from "@/variables/colors";
import PauseBtnVue from "./components/PauseBtnCell.vue";
import TaskEditBtnCell from "./components/TaskEditBtnCell.vue";
import { convertValueToArray } from "@/helpers/common";
import {
  changeCatOfProjectResources,
  getAllMentionedResourceIdsFromText,
  getProjectResourcesFromText,
} from "@/helpers/resources";
import isPlainObject from "lodash/isPlainObject";
import Illustration from "@/components/Illustration/Illustration.vue";
import { decrement, increment } from "@/utils/number";
import { parseJSONDate } from "@/helpers/dates";
import { NODE_BROWSE_STRING_KEYCODES } from "@/variables/keys";
// import { checkIfProjectIsChangedOrUnChanged } from "@/helpers/tasks";
import { parseISO } from "date-fns";
import { INTERNAL_DATE_FORMAT } from "@/variables/dates";
import { NEW_DARK_ICON_COLOR } from "@/variables/colors";
import { scrollListForDrag } from "@/utils/elements";
const currDateOnly = parseISO(formatDate(new Date(), INTERNAL_DATE_FORMAT));
import debounce from "lodash/debounce";
import { getPathsFromEvent } from "@/utils/events";
import isEscKey from "@/utils/isEscKey";

// const debounedListScroller = debounce(scrollListForDrag, 250);
export default {
  refreshGridOnToggle: true,
  mixins: [
    userDetailsMixin,
    googleCalHelpersMixin,
    tasksHelpersMixin,
    recurringTasksHelperMixin,
  ],
  components: {
    DataTable,
    ContextMenu,
    // eslint-disable-next-line vue/no-unused-components
    Illustration,
  },
  props: {
    isActive: {
      type: Boolean,
    },
  },
  data(vm) {
    return {
      selectedUpcomingTaskId: "",
      allowDragAndDrop: false,
      columns: [
        {
          frozen: true,
          width: 0,
          minWidth: 0,
          cssClass: "intend-cell",
          field: "handler",
          sortable: false,
          editable: false,
        },
        {
          rowHandle: true,
          frozen: true,
          width: 30,
          minWidth: 30,
          cssClass: "row-handler-cell",
          CellComponent: RowHandler,
          isCellDisabled: vm.checkIfCellIsDisabled,
          field: "actions",
          sortable: false,
          editable: false,
          extraData: {
            useDynamicColor: true,
            colorProvider: () => {
              return NEW_DARK_ICON_COLOR;
            },
          },
        },
        {
          field: "priority",
          width: 30,
          resizable: false,
          editable: false,
          // cellEditor: PriorityEditor,
          // cellEditor: PrioritySelectEditor,
          isCellDisabled: vm.checkIfCellIsDisabled,
          vertAlign: "middle",
          CellComponent: ActionsVue,
          sortable: false,
          onDataUpdate: vm.onDataUpdate,
          extraData: {
            showContingentBtn: true,
            //doNotShowEditBtn: true,
          },
        },
        {
          title: "Type",
          field: "taskType",
          resizable: false,
          editable: true,
          vertAlign: "middle",
          CellComponent: TaskTypeCell,
          cellEdited: vm.cellEdited,
          CellEditor: TaskTypeSelectEditorCell,
          isCellDisabled: vm.checkIfCellIsDisabled,
          onDataUpdate: vm.onDataUpdate,
          verifySortability: vm.checkColumnIsSortable,
          tristateSort: false,
          startSortDir: "asc",
          width: 38,
        },
        {
          frozen: true,
          width: 32,
          visible: false,
          minWidth: 32,
          HeaderComponent: FlagColumnHeader,
          CellComponent: Flag,
          CellEditor: Flag,
          isCellDisabled: vm.checkIfCellIsDisabled,
          onDataUpdate: vm.onDataUpdate,
          field: "flag",
          sortable: false,
          editable: true,
          extraData: {
            useDynamicColor: true,
            colorProvider: (cell) => {
              return getTaskRowHandlerColor(cell.data.priority);
            },
            checkForContingent: true,
          },
        },
        {
          field: "edit-btn",
          width: 32,
          resizable: false,
          editable: false,
          visible: false,
          // cellEditor: PriorityEditor,
          // cellEditor: PrioritySelectEditor,
          isCellDisabled: vm.checkIfCellIsDisabled,
          vertAlign: "middle",
          CellComponent: TaskEditBtnCell,
          sortable: false,
          onDataUpdate: vm.onDataUpdate,
        },
        {
          title: "Task",
          field: "title",
          resizable: false,
          editable: true,
          primary: true,
          vertAlign: "middle",
          cellEdited: this.cellEdited,
          CellEditor: TitleViewerCell,
          CellComponent: TitleViewerCell,
          extraData: {
            watchResources: true,
            showObjectiveBtn: false,
            previousCell: "taskType",
            filterResourcesByArea: true,
            nextCell: "project",
          },
          width: "stretch",
          minWidth: 300,
          placeholder: "Enter Title",
          onDataUpdate: vm.onDataUpdate,
          tristateSort: false,
          startSortDir: "asc",
          expand: true,
          verifySortability: vm.checkColumnIsSortable,
        },

        {
          field: "project",
          title: "Area",
          resizable: false,
          editable: true,
          CellComponent: Project,
          width: 148,
          getWidth: () => {
            return vm.checkIfStatusBtnIsVisibleInAreaColumn() ? 176 : 148;
          },
          CellEditor: ProjectEditor,
          vertAlign: "middle",
          cellEdited: this.cellEdited,
          tristateSort: false,
          startSortDir: "asc",
          previousCell: "title",
          onDataUpdate: vm.onDataUpdate,
          getSortField: () =>
            vm.isInCalOrTodayMode ? "ReviewGroup" : "project",
          verifySortability: vm.checkColumnIsSortable,
          extraData: {
            useDynamicColor: true,
            colorProvider: (cell) => {
              return getTaskRowHandlerColor(cell.data.priority);
            },
            checkForContingent: true,
          },
        },
        {
          field: "dueAsDate",
          visible: true,
          title: "Due",
          resizable: false,
          editable: true,
          cellEdited: vm.cellEdited,
          CellComponent: Due,
          CellEditor: EditDueDateCell,
          onDataUpdate: vm.onDataUpdate,
          tristateSort: false,
          startSortDir: "asc",
          defaultValue: new Date("1990-01-01"),
          isCellDisabled: vm.checkIfCellIsDisabled,
          width: 235,

          getWidth: () => {
            return vm.checkIfDueDateDayAndDateEnabled() ? 250 : 235;
          },
          type: "date",
          transformFunc: (val) => (isValidDate(val) ? val : null),
          verifySortability: vm.checkColumnIsSortable,
          extraData: {
            checkForContingent: true,
          },
        },
        {
          width: 135,
          field: "modifiedAsDate",
          title: "Modified",
          CellComponent: DateCell,
          visible: false,
          tristateSort: false,
          startSortDir: "asc",
          editable: false,
          resizable: false,
          defaultValue: new Date("1970-01-01"),
          missingValue: "skip",
          transformFunc: (val) => (isValidDate(val) ? val : null),
          verifySortability: vm.checkColumnIsSortable,
        },
        {
          field: "createdAsDate",
          visible: false,
          title: "Created",
          CellComponent: DateCell,
          tristateSort: false,
          startSortDir: "asc",
          width: 135,
          resizable: false,
          editable: false,
          verifySortability: vm.checkColumnIsSortable,
        },
        {
          field: "saveBtnCell",
          isCellDisabled: vm.checkIfCellIsDisabled,
          resizable: false,
          editable: true,
          width: 75,
          CellComponent: SaveBtnCell,
          CellEditor: SaveBtnCell,
          onDataUpdate: vm.onDataUpdate,
          sortable: false,
        },
        {
          field: "pausedReminderDays",
          title: "",
          width: 50,
          resizable: false,
          editable: false,
          visible: false,
          CellComponent: PauseBtnVue,
          onDataUpdate: vm.onDataUpdate,
          sortable: false,
        },
        {
          field: "due",
          visible: false,
        },
        {
          field: "project",
          visible: false,
        },
        {
          field: "snapshots",
          visible: false,
        },
        {
          field: "googleEventId",
          visible: false,
        },
        {
          field: "linkedTo",
          visible: false,
        },
        {
          field: "isVirtual",
          visible: false,
        },
        {
          field: "isContingent",
          visible: false,
        },
        {
          title: "Order",
          field: "order",
          visible: false,
          width: 75,
        },
        {
          title: "Position",
          field: "userPosition",
          visible: false,
          width: 75,
        },

        {
          field: "created",
          visible: false,
        },
        {
          field: "modified",
          visible: false,
        },
      ],
      tableColumns: [],
      options: {},
      createdFilters: [],
      reviewViewColumns: ["createdAsDate", "modifiedAsDate"],
      groupViewsWithModifiedColumns: [1, 2],
      menuItems: TASK_MENU_ACTIONS,
    };
  },
  mounted() {
    this.setTableOpts();
    this.addToggleOrderedTasksWatcher();
    this.debouncedListScroller = debounce(this.callAutoScroller, 240);
    EventEmitter.on(ADD_TASK_EVENT, this.addNewTask);
    EventEmitter.on(REFRESH_TASKS_EVENT, this.handleTasksRefresh);
    EventEmitter.on(CLOSE_INLINE_ADD, this.closeInlineAdd);
    EventEmitter.on(TASK_ADDED_EVENT, this.handleRowAdd);
    EventEmitter.on(SET_ID_FOR_SCROLL, this.setTaskIdsForScroll);
  },
  beforeDestroy() {
    EventEmitter.off(ADD_TASK_EVENT, this.addNewTask);
    EventEmitter.off(REFRESH_TASKS_EVENT, this.handleTasksRefresh);
    EventEmitter.off(CLOSE_INLINE_ADD, this.closeInlineAdd);
    EventEmitter.off(TASK_ADDED_EVENT, this.handleRowAdd);
    EventEmitter.off(SET_ID_FOR_SCROLL, this.setTaskIdsForScroll);
  },
  methods: {
    handleLongTouch(e, item) {
      const menu = document.getElementById("data-table-menu-opts")
      if (menu?.ej2_instances && menu.ej2_instances[0].vueInstance?.open) {
        const touch = e.touches[0] || e.changedTouches[0] || e.targetTouches[0];
        const x = touch.pageX;
        const y = touch.pageY;
        menu.ej2_instances[0].vueInstance.open(y, x)
      }
    },
    handleKeydown(e) {
      if (isEscKey(e)) {
        const isGridEditingActive = this.$refs.dataGrid.isEditingActive();
        if (isGridEditingActive) {
          e.stopImmediatePropagation();
          e.stopPropagation();
          e.preventDefault();
        }
      }
    },
    callAutoScroller(eventData) {
      const paths = getPathsFromEvent(eventData);
      const listWrapperEl = this.$el.querySelector(
        ".vue-recycle-scroller__item-wrapper"
      );
      const parentWrapperEl = this.$el.querySelector(".vue-recycle-scroller");

      scrollListForDrag({
        isDragStarted: true,
        draggedItemEl: paths[0],
        listWrappEl: listWrapperEl,
        parentEl: parentWrapperEl,
      });
    },
    handleRowDragging() {
      // this.debouncedListScroller(eventData);
      // debounedListScroller({
      //   draggedItemEl: paths[0],
      //   listWrappEl: this.listWrappEl,
      //   parentEl: this.parentWrapperEl,
      // });
      // console.debug("PATHS");
      // console.debug("REEE", eventData);
    },
    checkIfRowDragEnabled(rowData) {
      // return !rowData.isReminderTask;
      return true;
    },
    processTasksAfterSort(list) {
      const sortBy = this.$refs.dataGrid.sortBy || [];
      if (
        this.isInCalOrTodayMode &&
        !isEmpty(sortBy) &&
        !this.checkIfSortListContainsReminderCol(sortBy)
      ) {
        list.sort((a, b) => {
          const firstSortVal = a["reminderTaskOrder"];
          const secondSortVal = b["reminderTaskOrder"];
          if (firstSortVal > secondSortVal) {
            return 1;
          } else if (firstSortVal < secondSortVal) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      if (!this.isInNextActionsMode) {
        return list;
      }

      list = this.processTasksOfNextActionsMode(
        list,
        sortBy,
        false,
        false,
        false,
        this.showAllOrderedTasks
      );

      return list;
    },
    checkIfSortListContainsReminderCol(list) {
      return list.some((i) => i.column === "reminderTaskOrder");
    },

    ...mapActions("task", [
      "addTask",
      "updateTasks",
      "removeTasks",
      "updateOrAddTasks",
      "updateSelectedTasks",
      "addOrUpdateOrRemoveTasks",
    ]),
    ...mapActions("deleteRecurringConfirmDialog", {
      openDeleteRecurringDialog: "openDialog",
    }),
    ...mapMutations("task", ["updateState"]),
    ...mapMutations(["setLoader"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    ...mapMutations(["setCurrRowInEditing"]),
    browserCateFilters(e) {
      let arrowRightCalled = false;
      // eslint-disable-next-line no-unused-vars
      let arrowLeftCalled = false;

      if (e.key && NODE_BROWSE_STRING_KEYCODES.includes(e.key)) {
        if (e.key === "ArrowLeft") {
          arrowLeftCalled = true;
        } else if (e.key === "ArrowRight") {
          arrowRightCalled = true;
        }
      } else {
        const keyCode = e.which ? e.which : e.keyCode;
        if (keyCode === 37) {
          arrowLeftCalled = true;
        } else if (keyCode === 39) {
          arrowRightCalled = true;
        }
      }

      const categoryFiltersEl = document.querySelector(".category-filters");

      if (categoryFiltersEl) {
        const categoryNodeList =
          categoryFiltersEl.querySelectorAll(".cate-filter-btn");

        if (categoryNodeList) {
          let currActiveCateFilters = !isEmpty(this.filters.category)
            ? cloneDeep(this.filters.category)
            : [];

          if (
            treeSideBarModes.includes(this.currNav) &&
            !isEmpty(this.selectedOptsList)
          ) {
            currActiveCateFilters = this.selectedOptsList
              .filter((i) => i.type === "category")
              .map((n) => n.id);
          }
          let indexToUse = -1;

          if (!isEmpty(currActiveCateFilters)) {
            const cateIndex = [...categoryNodeList].findIndex(
              (n) =>
                n.dataset.filterCateId ===
                currActiveCateFilters[currActiveCateFilters.length - 1]
            );
            if (cateIndex >= 0) {
              indexToUse = cateIndex;
            }
          }
          indexToUse = arrowRightCalled ? indexToUse + 1 : indexToUse - 1;

          if (indexToUse < 0) {
            indexToUse = categoryNodeList.length - 1;
          } else if (indexToUse === categoryNodeList.length) {
            indexToUse = 0;
          }
          if (categoryNodeList[indexToUse]) {
            const selectedEvent = new Event("selected");
            categoryNodeList[indexToUse].dispatchEvent(selectedEvent);
          }
        }
      }
    },
    handleKeyDown() {
      // let callCateFilterHandler = false;
      // if (e.key && NODE_BROWSE_STRING_KEYCODES.includes(e.key)) {
      //   callCateFilterHandler = true;
      // } else {
      //   const keyCode = e.which ? e.which : e.keyCode;
      //   if (NODE_BROWSE_KEYS.includes(keyCode)) {
      //     callCateFilterHandler = true;
      //   }
      // }
      // if (callCateFilterHandler) {
      //   this.browserCateFilters(e);
      // }
    },
    checkColumnIsSortable(colData) {
      if (colData.field === "taskType") {
        return true;
      }
      if (colData.field === "title") {
        return false;
      }

      if (this.isInboxViewEnabled && colData.field === "project") {
        return false;
      }
      if (this.isGlobalViewEnabled || !this.isInNextActionsMode) {
        return colData.sortable !== false;
      }

      if (
        this.isInNextActionsMode &&
        checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList) &&
        colData.field === "project"
      ) {
        return false;
      }

      if (colData.field === "modifiedAsDate") {
        return true;
      }

      if (colData.field !== "project") {
        return false;
      }

      return true;
    },
    async processTasksAfterGrouping(list) {
      // const sortBy = this.$refs.dataGrid.sortBy || [];

      if (!this.isInNextActionsMode) {
        return list;
      }

      return list;
      // return this.processTasksOfNextActionsMode(list, sortBy);

      // const isAreaSelected = sortBy.find((sort) => sort.column === "project");
      // // console.debug("PROCESS");
      // const areaWiseSortedTasks = {};
      // const areaWiseSortedTasksGroups = [];
      // const collator = new Intl.Collator([], { numeric: true });
      // if (!isEmpty(list)) {
      //   if (
      //     isEmpty(this.selectedOptsList) &&
      //     !this.isSearchKeywordValid &&
      //     isEmpty(isAreaSelected)
      //   ) {
      //     list.sort((a, b) => {
      //       const orderedA = a.areaWiseOrder;
      //       const orderedB = b.areaWiseOrder;
      //       return collator.compare(orderedA, orderedB);
      //     });
      //   }

      //   list.forEach((t) => {
      //     if (t["CTICustomGroup"] !== "1") {
      //       return;
      //     }

      //     const orderPropValue = t["ReviewGroup"];
      //     if (!areaWiseSortedTasks[orderPropValue]) {
      //       areaWiseSortedTasks[orderPropValue] = [];
      //       areaWiseSortedTasksGroups.push(orderPropValue);
      //     }
      //     if (!areaWiseSortedTasks[orderPropValue].includes(t.key)) {
      //       areaWiseSortedTasks[orderPropValue].push(t.key);
      //       t.orderedTaskNumber = areaWiseSortedTasks[orderPropValue].length;
      //       t.isFirstOrderedTask = t.orderedTaskNumber === 1;
      //       t.orderedReviewTask = `${t.ReviewGroup}-${t.orderedTaskNumber}`;
      //     }
      //   });

      //   // opts.sort((a, b) => {
      //   //   return collator.compare(a.order, b.order);
      //   // });

      //   if (isEmpty(this.selectedOptsList) && !this.isSearchKeywordValid) {
      //     list = list.filter((t) => {
      //       return t.isFirstOrderedTask;
      //     });

      //     if (isEmpty(isAreaSelected)) {
      //       const modifiedColSortData = sortBy.find(
      //         (sort) => sort.column === "modifiedAsDate"
      //       );
      //       list.sort((a, b) => {
      //         let modifiedA = getDateValue(a.modifiedAsDate);
      //         let modifiedB = getDateValue(b.modifiedAsDate);

      //         if (!modifiedA) {
      //           modifiedA = new Date("1970-01-01");
      //         }

      //         if (!modifiedB) {
      //           modifiedB = new Date("1970-01-01");
      //         }

      //         if (modifiedA > modifiedB) {
      //           if (
      //             !!modifiedColSortData &&
      //             modifiedColSortData.order === "asc"
      //           ) {
      //             return 1;
      //           }
      //           return -1;
      //         } else if (modifiedA < modifiedB) {
      //           if (
      //             !!modifiedColSortData &&
      //             modifiedColSortData.order === "asc"
      //           ) {
      //             return -1;
      //           }
      //           return 1;
      //         } else {
      //           return 0;
      //         }
      //       });
      //     }

      //     // list.forEach((t) => {});

      //     // list = list.filter((t) => {
      //     //   return t.isFirstOrderedTask;
      //     // });

      //     // list.sort((a, b) => {
      //     //   let pDate = a.modified;
      //     //   let nDate = b.modified;

      //     //   pDate = getDateValue(pDate);
      //     //   nDate = getDateValue(nDate);

      //     //   if (!pDate) {
      //     //     pDate = new Date("1970-01-01");
      //     //   }

      //     //   if (!nDate) {
      //     //     nDate = new Date("1970-01-01");
      //     //   }

      //     //   if (pDate > nDate) {
      //     //     return -1;
      //     //   } else if (pDate < nDate) {
      //     //     return 1;
      //     //   }

      //     //   return 0;

      //     //   //             switch (true) {
      //     //   //   case pValue > nValue:
      //     //   //     return reverse[i] ? -1 : 1;
      //     //   //   case pValue < nValue:
      //     //   //     return reverse[i] ? 1 : -1;
      //     //   // }
      //     // });
      //     if (this.selectedTaskFilter) {
      //       list = this.filterTasksBasedOnSelectedFilters(
      //         list,
      //         this.selectedTaskFilter
      //       );
      //     }

      //     return list;
      //   }

      //   // console.l;

      //   list.sort((a, b) => {
      //     // eslint-disable-next-line
      //     if (!!isAreaSelected) {
      //       const firstVal = a.orderedReviewTask;
      //       const secondVal = b.orderedReviewTask;

      //       if (!firstVal || !secondVal) {
      //         return 0;
      //       }

      //       if (isAreaSelected.order === "desc") {
      //         return firstVal.localeCompare(secondVal);
      //       }

      //       return secondVal.localeCompare(firstVal);
      //       // if (!a.orderedReviewTask || !b.orderedReviewTask) {

      //       //   return 0;
      //       // }
      //       // if (isAreaSelected.order === "desc") {
      //       //   return a.orderedReviewTask.localeCompare(b.orderedReviewTask);
      //       // }

      //       // return b.orderedReviewTask.localeCompare(a.orderedReviewTask);
      //     }
      //     const prevOrderedVal = a.orderedTaskNumber;
      //     const nextOrderedVal = b.orderedTaskNumber;

      //     if (prevOrderedVal > nextOrderedVal) {
      //       return 1;
      //     } else if (prevOrderedVal < nextOrderedVal) {
      //       return -1;
      //     } else {
      //       return 0;
      //     }

      //     // return prevOrderedVal > nextOrderedVal;
      //   });
      // }

      // if (this.selectedTaskFilter && !this.isSearchKeywordValid) {
      //   list = this.filterTasksBasedOnSelectedFilters(
      //     list,
      //     this.selectedTaskFilter
      //   );
      // }

      // return list;
    },
    // filterTasksBasedOnSelectedFilters(taskList, selectedFilter) {
    //   const normalisedFilter = selectedFilter?.trim() || "";
    //   if (!normalisedFilter) {
    //     return taskList;
    //   }
    //   const createdFilter = createNextModeFilters(selectedFilter);

    //   return taskList.filter(createdFilter);
    // },

    handlePostGrouping(list) {
      if (!isEmpty(list)) {
        const nextUpcomingTask = getNextFirstTaskByDate(list, currDateOnly);
        if (!isEmpty(nextUpcomingTask)) {
          this.selectedUpcomingTaskId = nextUpcomingTask.key;
          this.$refs.dataGrid.setActiveRow(nextUpcomingTask.key);
        }
      }
      // if (this.isInNextActionsMode) {
      //   const areaWiseSortedTasks = {};
      //   const areaWiseSortedTasksGroups = [];
      //   if (!isEmpty(list)) {
      //     list.forEach((t) => {
      //       if (t["CTICustomGroup"] !== "1") return;
      //       const orderPropValue = t["ReviewGroup"];
      //       if (!areaWiseSortedTasks[orderPropValue]) {
      //         areaWiseSortedTasks[orderPropValue] = [];
      //         areaWiseSortedTasksGroups.push(orderPropValue);
      //       }
      //       if (!areaWiseSortedTasks[orderPropValue].includes(t.key)) {
      //         areaWiseSortedTasks[orderPropValue].push(t.key);
      //       }
      //     });
      //   }
      //   console.debug("JHH", areaWiseSortedTasks);
      //   this.updateState({
      //     areaWiseSortedTasks: Object.freeze(areaWiseSortedTasks),
      //     areaWiseSortedTasksGroups: Object.freeze(areaWiseSortedTasksGroups),
      //   });
      // }
    },
    checkIfCellIsDisabled() {
      // _, rowData
      //  return rowData.isReminderTask;
      return false;
    },
    verifyRowDrag(dropRowData) {
      if (this.isInAreasMode) {
        return true;
      }

      if (this.isInNextActionsMode) {
        if (
          checkIfSelectedTaskFiltersHasCategory(this.filters) ||
          !this.checkIfSelectedOptsHasOnlyArea(this.selectedOptsList)
        ) {
          return false;
        }
        let groupKey = this.$refs.dataGrid.getGroupBy();

        if (!isEmpty(groupKey) && isPlainObject(groupKey)) {
          groupKey = groupKey.key;
        }

        const rowGroupKey = dropRowData[groupKey];

        return nextActionsGroupsDragAllowed.includes(rowGroupKey);
      }
    },
    checkIfRowSelectionIsAllowed() {
      // rowData
      // const taskKey = rowData.key;

      let isAllowed = true;

      // const taskData = this.tasks.find((t) => t.key === taskKey);

      // if (!isEmpty(taskData)) {
      //   isAllowed = !taskData.isReminderTask;
      // }

      return isAllowed;
    },
    handleRowClicked(e, rowData) {
      this.doNotSetForScroll = true;
      if (rowData?.key) {
        this.setCurrRowInEditing(rowData.key);
      }
    },
    handleDeleteCall() {
      if (isEmpty(this.selectedTasks)) return;
      const selectedTasks = cloneDeep(this.selectedTasks);
      this.$refs.dataGrid.deselectPreviousRows();

      this.handleDeletionOfTasks(selectedTasks);

      this.updateSelectedTasks([]);
    },
    async handleRowFocused(rowId) {
      if (this.triggerEditForRow) {
        this.triggerEditForRow = false;
        this.$refs.dataGrid.enableEditByRowId(rowId);
        await this.$nextTick();
        setTimeout(() => {
          EventEmitter.emit(SELECT_TEXT_IN_INPUT);
        }, 0);
      }
    },
    createRowClassNames(rowData) {
      let classNames = "";

      const rowPriorityClass = getRowPriorityClass(rowData.priority || "");
      const rowContingentClass = getRowContingentClass(rowData.isContingent);
      const rowReminderTaskClass = getRowReminderClass(
        !!rowData.isReminderTask
      );
      if (rowPriorityClass) {
        classNames += ` lighten-3 ${rowPriorityClass}`;
      }

      if (rowContingentClass) {
        classNames += ` ${rowContingentClass}`;
      }

      if (rowReminderTaskClass) {
        classNames += ` ${rowReminderTaskClass}`;
      }

      if (rowData.isOlderTask) {
        classNames += ` is-task-older`;
      }

      if (rowData.key === this.selectedUpcomingTaskId) {
        classNames += ` upcoming-task`;
      }
      return classNames;
    },
    handleContextMenuTrigger(e) {
      const isGridInEditMode = this.$refs.dataGrid.isEditingActive();
      if (isGridInEditMode) {
        e.stopImmediatePropagation();
        e.stopPropagation();
      }
    },
    handleEditEnabled(_, rowData, callBack = undefined) {
      this.currEditedRow = rowData;
      if (callBack) callBack();
    },
    updateCollapsedGroupListInDb(list) {
      let navToUse = this.currNav;

      if (this.isGlobalViewEnabled || this.isInboxViewEnabled) {
        navToUse = this.isGlobalViewEnabled ? 6 : 5;
      }

      DatabaseInterface.update(
        {
          [`/view/${navToUse}/collapsedGroups`]: list,
        },
        this.userInfo.uid
      );
    },
    handleGroupToggle(groupData, mode) {
      const currCollapsedGroups = cloneDeep(this.collapsedGroupsList);
      const storedIndex = currCollapsedGroups.findIndex((g) =>
        isEqual(g, groupData)
      );

      if (mode) {
        if (mode === "remove") {
          if (storedIndex >= 0) {
            currCollapsedGroups.splice(storedIndex, 1);
          }
        }

        if (mode === "add") {
          if (storedIndex === -1) {
            currCollapsedGroups.push({ ...groupData });
          }
        }
      } else {
        if (storedIndex >= 0) {
          currCollapsedGroups.splice(storedIndex, 1);
        } else {
          currCollapsedGroups.push({ ...groupData });
        }
      }

      if (
        this.isInspectModeEnabled ||
        this.checkIfNextAreasAndSelectionIsEmpty()
      ) {
        this.updateState({
          collapsedGroups: currCollapsedGroups || [],
        });
      } else {
        this.updateCollapsedGroupListInDb(currCollapsedGroups);
      }
    },
    async focusOnSelectedItem(selectedItemKey, select, noScroll, noWait) {
      this.$refs.dataGrid.focusOnSelectedRow({
        selectedItemKey,
        select,
        noScroll,
        noWait,
      });
    },
    closeInlineAdd() {
      this.$refs.dataGrid.cancelAddRow();
    },

    handleTaskUnscheduling(currTaskData, updatedTaskData = {}) {
      const groupByData = "CTICustomGroup";

      updatedTaskData = createTaskDataFromProvidedData({ ...updatedTaskData });

      const finalUpdatedTaskData = {
        ...currTaskData,
        ...updatedTaskData,
      };

      if (finalUpdatedTaskData.project) {
        const orderedTaskOfProject = getOrderedTasksByProject(
          this.tasks,
          finalUpdatedTaskData.project
        );
        this.processTaskDroppedInCustomOrder({
          draggedTasks: [{ ...currTaskData, updates: { ...updatedTaskData } }],
          dropRowData: orderedTaskOfProject[0],
          isLastRow: false,
          groupByField: groupByData,
          tasksList: orderedTaskOfProject,
        });
      }
    },
    async handleListProcessed(list = []) {
      list = list.filter((t) => !t.isReminderTask);

      this.updateState({
        filteredTasks: list,
        tasksCount: list.length || 0,
      });

      // if (!isEmpty(list)) {
      //   const nextUpcomingTask = getNextFirstTaskByDate(list, currDateOnly);
      //   if (!isEmpty(nextUpcomingTask)) {
      //     this.$refs.dataGrid.setActiveRow(nextUpcomingTask.key);
      //   }
      // }

      this.doNotSetForScroll = false;
    },

    async handleTasksRefresh(reprocessTasks, useExisting, forceUpdate) {
      if (reprocessTasks) {
        const tasksToUse = useExisting ? this.tasks : this.rawTasks;
        this.updateState({
          tasks: processTaskList(
            [...tasksToUse],
            this.projects,
            this.categories
          ),
        });
      }
      await this.$nextTick();
      this.refreshList(undefined, forceUpdate);
    },
    handleRowUpdated({ rowId, updatedRowData: editedRowData }) {
      this.setCurrRowInEditing("");
      let currTaskData = this.tasks.find((t) => t.key === rowId);

      if (!isEmpty(currTaskData)) {
        let isReminderTask = !!currTaskData.isReminderTask;
        if (currTaskData.isReminderTask) {
          this.reminderTaskInfo = {
            linkedTo: currTaskData.reminderLinkedTo,
            reminderDate: currTaskData.reminderDate,
          };

          currTaskData = this.tasks.find(
            (t) => t.key === currTaskData.reminderLinkedTo
          );

          rowId = currTaskData.key;
        }
        if (isTaskRecurring(currTaskData)) {
          this.handleRecurringTaskUpdate(
            editedRowData,
            currTaskData,
            isReminderTask
          );
          return;
        }

        const updatedData = createTaskRowDataByRowData(
          currTaskData,
          editedRowData
        );

        let taskIsUnscheduled = false;
        // const isProjectChangedOrUnChanged = checkIfProjectIsChangedOrUnChanged(
        //   updatedData,
        //   currTaskData,
        //   editedRowData
        // );

        const isTaskDataChanged = checkIfRowTaskDataEditedChanged(
          updatedData,
          currTaskData,
          TASK_PROPS_FOR_FORM
        );
        if (!isTaskDataChanged) {
          return;
        }

        const isAreaChanged = updatedData.project !== currTaskData.project;

        // const res = true;

        // if (res) return;
        if (checkIfTaskIsUnscheduled(updatedData, currTaskData)) {
          taskIsUnscheduled = true;
        }
        if (
          checkIfTaskHasReminder(currTaskData) &&
          this.checkIfParsedTaskDataHasReminderProps(Object.keys(updatedData))
        ) {
          this.handleReminderTaskUpdates(
            { key: rowId, updates: updatedData, currRowData: currTaskData },
            !taskIsUnscheduled,
            true,
            taskIsUnscheduled
          );
        } else {
          if (!taskIsUnscheduled) {
            this.updateRowData({ key: rowId, updates: updatedData }, true);
          }
        }

        if (taskIsUnscheduled) {
          this.handleTaskUnscheduling(currTaskData, updatedData);
        }

        if (this.isGoogleCalSyncEnabled) {
          this.updateTaskDataInGoogleCal(
            {
              key: rowId,
              updates: { ...currTaskData, ...editedRowData },
              currData: currTaskData,
            },
            false
          );
        }

        if (isAreaChanged) {
          const mentionedProjectResources = getProjectResourcesFromText(
            updatedData.title
          );

          if (!isEmpty(mentionedProjectResources)) {
            changeCatOfProjectResources([
              {
                resources: mentionedProjectResources,
                catId: updatedData.project,
                isTaskRef: true,
              },
            ]);
          }
        }
      }
    },
    async handleReminderTaskUpdates(
      rowUpdates,
      refreshList = true,
      setForScroll,
      updateRemindersOnly
    ) {
      let reminderTasksToAdd = [];
      let reminderTasksToRemove = [];
      let tasksToUpdate = [];
      if (!Array.isArray(rowUpdates)) rowUpdates = [rowUpdates];
      rowUpdates.forEach((r) => {
        const { currRowData, updates: updatedRowData, key } = r;
        if (!currRowData.completed && updatedRowData.completed) {
          reminderTasksToRemove.push(
            ...getReminderTasksLinkedToTaskId(this.tasks, key)
          );
        } else if (currRowData.completed && !updatedRowData.completed) {
          reminderTasksToAdd.push(...createReminderTasks(updatedRowData));
        } else if (
          !isEqual(
            currRowData.pausedReminderDays,
            updatedRowData.pausedReminderDays
          ) ||
          isTitleUpdated(updatedRowData, currRowData) ||
          isTypeUpdated(updatedRowData, currRowData) ||
          isProjectUpdated(updatedRowData, currRowData)
        ) {
          reminderTasksToRemove.push(
            ...getReminderTasksLinkedToTaskId(this.tasks, key)
          );
          reminderTasksToAdd.push(...createReminderTasks(updatedRowData));
        }

        if (!updateRemindersOnly) {
          tasksToUpdate.push({ key, updates: updatedRowData });
        }
      });

      if (setForScroll) {
        let taskDataToUse = { ...rowUpdates[0] };
        // this.reminderTaskInfo = {
        //     linkedTo: currRowData.reminderLinkedTo,
        //     reminderDate: currRowData.reminderDate,
        //   };
        if (!isEmpty(this.reminderTaskInfo)) {
          const reminderList = !isEmpty(reminderTasksToAdd)
            ? reminderTasksToAdd
            : getReminderTasksLinkedToTaskId(
                this.tasks,
                this.reminderTaskInfo.linkedTo
              );

          const reminderTaskData = reminderList.find((t) =>
            isSameDates(t.reminderDate, this.reminderTaskInfo.reminderDate)
          );

          if (!isEmpty(reminderTaskData)) {
            taskDataToUse = { ...reminderTaskData };
          }
          this.reminderTaskInfo = undefined;
        }
        this.setTaskIdsForScroll(taskDataToUse.key);
      }

      await this.$nextTick();
      addOrRemoveOrUpdateTasksInLocalTasksList(
        {
          tasksToAdd: !isEmpty(reminderTasksToAdd) ? reminderTasksToAdd : [],
          tasksToRemove: !isEmpty(reminderTasksToRemove)
            ? reminderTasksToRemove
            : [],
          tasksToUpdate: !isEmpty(tasksToUpdate) ? tasksToUpdate : [],
          verifyAdd: false,
        },
        refreshList
      );
    },
    onDataUpdate(rowId, fieldName, value, cell) {
      if (!rowId) return;
      const row = cell.getRow();
      let currRowData = row.getData();
      const currTaskRepeatInfo = currRowData.repeatInfo;

      if (fieldName === "pausedReminderDays" && currRowData.isReminderTask) {
        this.handleReminderPause({
          mainTaskId: currRowData.reminderLinkedTo,
          updatedRowData: {
            [fieldName]: value,
          },
        });
        return;
      }

      if (currRowData.isReminderTask) {
        this.reminderTaskInfo = {
          linkedTo: currRowData.reminderLinkedTo,
          reminderDate: currRowData.reminderDate,
        };

        currRowData = this.tasks.find(
          (t) => t.key === currRowData.reminderLinkedTo
        );

        rowId = currRowData.key;
      }

      if (isTaskRecurring(currRowData)) {
        let editData = {
          [fieldName]: value,
        };

        if (
          fieldName === "dueAsDate" &&
          !isValidDate(value) &&
          !isValidDate(currRowData.dueAsDate)
        ) {
          editData.flag = false;

          if (checkIfTaskIsOrderedTask(currRowData)) {
            editData.isCustomPositioned = false;
          }

          if (currRowData.isContingent) {
            editedData.isContingent = false;
          }
        }
        this.handleRecurringTaskUpdate(
          {
            ...editData,
          },
          currRowData,
          true
        );
        return;
      }
      const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
        fieldName,
        currValue: value,
        rowId,
        oldValue: currRowData[fieldName],
        currRowData,
      });

      const createdRowData = createTaskRowDataByCell(
        currRowData,
        fieldName,
        value,
        currRowData[fieldName]
      );

      let taskIsUnscheduled = false;

      if (
        fieldName === "dueAsDate" &&
        (checkIfContingentTaskIsUnscheduled(createdRowData, currRowData) ||
          checkIfTaskIsUnscheduled(createdRowData, currRowData))
      ) {
        taskIsUnscheduled = true;
      }

      if (fieldName === "completed" && value) {
        let stopExcution = false;
        if (!isEmpty(currTaskRepeatInfo)) {
          stopExcution = true;
          this.completeCurrTaskAndCreateNextRepeatTask({
            key: rowId,
            updates,
            editedData,
            updatedRowData: createdRowData,
          });
        } else if (checkIfTaskIsContinued(currRowData)) {
          stopExcution = true;
          this.completeContinuedTask({
            key: rowId,
            updates,
            editedData,
            updatedRowData: createdRowData,
          });
        }

        if (stopExcution) {
          return;
        }
      }

      if (
        checkIfTaskHasReminder(currRowData) &&
        REMINDER_TASK_EDIT_PROPS.includes(fieldName)
      ) {
        this.handleReminderTaskUpdates(
          { key: rowId, updates: createdRowData, currRowData: currRowData },
          !taskIsUnscheduled,
          true,
          taskIsUnscheduled
        );
      } else {
        if (!taskIsUnscheduled) {
          this.updateRowData(
            { key: rowId, updates: createdRowData },
            undefined,
            true
          );
        }
      }

      if (taskIsUnscheduled) {
        this.handleTaskUnscheduling(currRowData, createdRowData);
      }

      if (!taskIsUnscheduled) {
        this.updateTaskInDbAndStoreEditData(rowId, updates, editedData);
      }

      if (this.isGoogleCalSyncEnabled) {
        this.updateTaskDataInGoogleCal(
          {
            key: rowId,
            updates: { [fieldName]: value },
          },
          true
        );
      }
    },

    handleReminderPause({ mainTaskId, updatedRowData }) {
      const fieldName = "pausedReminderDays";
      const value = updatedRowData[fieldName];

      const currRowData = this.tasks.find((t) => t.key === mainTaskId);

      if (!isEmpty(currRowData)) {
        const rowId = currRowData.key;
        if (isTaskRecurring(currRowData)) {
          this.handleRecurringTaskUpdate(
            {
              ...updatedRowData,
            },
            currRowData,
            false
          );
          return;
        }
        const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
          fieldName,
          currValue: updatedRowData[fieldName],
          rowId: mainTaskId,
          oldValue: currRowData[fieldName],
          currRowData,
        });

        const createdRowData = createTaskRowDataByCell(
          currRowData,
          fieldName,
          updatedRowData[fieldName],
          currRowData[fieldName]
        );

        this.handleReminderTaskUpdates(
          { key: rowId, updates: createdRowData, currRowData: currRowData },
          undefined,
          false
        );

        this.updateTaskInDbAndStoreEditData(rowId, updates, editedData);

        if (this.isGoogleCalSyncEnabled) {
          this.updateTaskDataInGoogleCal(
            {
              key: rowId,
              updates: { [fieldName]: value },
            },
            true
          );
        }
      }
    },
    setTaskIdsForScroll(taskListData, setAsRows) {
      const normalisedList = convertValueToArray(taskListData);
      if (setAsRows) {
        this.$refs.dataGrid.setSelectedRows(normalisedList);
      } else {
        this.$refs.dataGrid.setRowIdsForScroll(normalisedList);
      }
    },
    async completeAndCreateNextContinuedTask(
      responseData,
      { rowId, currRawTaskData, providedDbUpdates, editedData, updatedRowData }
    ) {
      if (
        (!responseData.isConfirmed && responseData.dismiss === "backdrop") ||
        responseData.dismiss === "esc"
      ) {
        return;
      }
      let onlyCompleteCurrentTask = false;
      let newCreatedTask = {};
      let dbUpdates = { ...providedDbUpdates };
      let reminderTasksToRemove = [];
      let reminderTasksToAdd = [];
      let userActionsBatch = {};
      if (!responseData.isConfirmed && responseData.dismiss === "cancel") {
        onlyCompleteCurrentTask = true;
      }

      setTimeout(async () => {
        userActionsBatch = {
          ...editedData,
          key: rowId,
        };

        reminderTasksToRemove = getReminderTasksLinkedToTaskId(
          this.tasks,
          currRawTaskData.key
        );
        if (!onlyCompleteCurrentTask) {
          newCreatedTask = createContinuedTaskData({
            ...currRawTaskData,
            title: currRawTaskData.title,
            description: "",
            dueAsDate: createDateTimeFromDue(
              currRawTaskData.due,
              currRawTaskData.time
            ),
          });

          dbUpdates = {
            ...dbUpdates,
            [`/tasks/${newCreatedTask.key}`]: newCreatedTask,
          };

          if (checkIfTaskHasReminder(newCreatedTask)) {
            reminderTasksToAdd.push(...createReminderTasks(newCreatedTask));
          }
          userActionsBatch = [
            {
              type: "edit",
              data: {
                ...userActionsBatch,
              },
            },
            {
              type: TASK_ACTIONS.ADD,
              data: {
                ...newCreatedTask,
              },
            },
          ];
        }

        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        let actionToStore = TASK_ACTIONS.EDIT;
        if (Array.isArray(userActionsBatch)) {
          actionToStore = TASK_ACTIONS.ACTIONS_BATCH;
        }
        getUserActions().addTaskAction({
          type: actionToStore,
          data: userActionsBatch,
        });
        this.triggerEditForRow = !onlyCompleteCurrentTask;
        this.setTaskIdsForScroll(
          !onlyCompleteCurrentTask ? newCreatedTask.key : updatedRowData.key
        );
        await addOrRemoveOrUpdateTasksInLocalTasksList({
          tasksToAdd: !onlyCompleteCurrentTask
            ? [{ ...newCreatedTask }, ...reminderTasksToAdd]
            : [],
          tasksToUpdate: [{ key: rowId, updates: updatedRowData }],
          tasksToRemove: !isEmpty(reminderTasksToRemove)
            ? reminderTasksToRemove
            : [],
        });
        if (this.isGoogleCalSyncEnabled) {
          if (!onlyCompleteCurrentTask && newCreatedTask.due !== "none") {
            addTasksToGoogleCal([
              {
                key: newCreatedTask.key,
                data: createTaskDataForGoogleCal({
                  ...newCreatedTask,
                  dueAsDate: createDateTimeFromDue(
                    newCreatedTask.due,
                    newCreatedTask.time
                  ),
                  taskKey: newCreatedTask.key,
                }),
              },
            ]);
          }
        }
      }, 10);
    },
    async completeContinuedTask({ key, updates, editedData, updatedRowData }) {
      const currRawTaskData = this.rawTasksMap[key];

      if (!isEmpty(currRawTaskData)) {
        const confirmRes = await this.$swal({
          text: "Do you wish to add a next step?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });

        setTimeout(() => {
          this.completeAndCreateNextContinuedTask(confirmRes, {
            rowId: key,
            providedDbUpdates: updates,
            editedData,
            updatedRowData,
            currRawTaskData,
          });
        }, 0);
      }
    },
    updateTaskDataInGoogleCal(tasks, getRawData) {
      if (!Array.isArray(tasks)) tasks = [tasks];

      if (getRawData) {
        tasks = tasks.map((t) => ({
          key: t.key,
          updates: t.updates,
          currData: this.rawTasks.find((rT) => rT.key === t.key),
        }));
      }
      const dataForGoogleCal =
        createDataForAddOrRemoveOrUpdateInGoogleCalByTaskData(tasks);
      this.addOrUpdateOrRemoveTasksInGoogleCal(dataForGoogleCal);
    },
    checkTaskDataChanged(dataToUpdate, currData) {
      const qlInstance = createOrGetQlInstance();
      const isDataChanged = INLINE_EDIT_TASK_PROPS.some((propData) => {
        if (dataToUpdate[propData.field] !== undefined) {
          if (propData.field === "title") {
            const oldConveredData = convertHTMLToQlDelta(
              currData[propData.field],
              qlInstance
            );
            const updatedConveredData = convertHTMLToQlDelta(
              dataToUpdate[propData.field],
              qlInstance
            );
            return !areQlDeltasEqual(oldConveredData, updatedConveredData);
          } else if (propData.field === "pausedReminderDays") {
            const currPausedReminderDays = dataToUpdate[propData.field] || [];
            const oldPausedReminderDays = currData[propData.field] || [];

            return !isEqual(currPausedReminderDays, oldPausedReminderDays);
          } else {
            return !isEqual(
              dataToUpdate[propData.field],
              currData[propData.field]
            );
          }
        }
      });
      removeQlInstance();
      return isDataChanged;
    },
    async handleRecurringTaskUpdate(
      dataToUpdate = {},
      currRowData,
      setForScroll
    ) {
      const dataChanged = this.checkTaskDataChanged(dataToUpdate, currRowData);
      if (!dataChanged) {
        return;
      }

      const parsedDataToUpdate = createTaskDataFromProvidedData(dataToUpdate);
      const currRawData = this.getRawDataOfTheRecurringTask(currRowData);

      const combinedData = {
        ...currRawData,
        ...parsedDataToUpdate,
        modified: getCurrDate(),
      };

      combinedData.dueAsDate = createDateTimeFromDue(
        combinedData.due,
        combinedData.time
      );

      const isAreaChanged = combinedData.project !== currRawData.project;

      if (isAreaChanged) {
        const mentionedProjectResources = getProjectResourcesFromText(
          combinedData.title
        );

        if (!isEmpty(mentionedProjectResources)) {
          changeCatOfProjectResources([
            {
              resources: mentionedProjectResources,
              catId: combinedData.project,
              isTaskRef: true,
            },
          ]);
        }
      }

      if (
        checkIfTaskIsUnscheduled(combinedData, currRowData, dataToUpdate) ||
        checkIfContingentTaskIsUnscheduled(
          combinedData,
          currRowData,
          dataToUpdate
        )
      ) {
        const reminderTasksToRemove = [];
        const allReminderTasksLinkedToKey = getReminderTasksLinkedToTaskId(
          this.tasks,
          currRowData.key
        );
        if (!isEmpty(allReminderTasksLinkedToKey)) {
          reminderTasksToRemove.push(...allReminderTasksLinkedToKey);
        }

        await addOrRemoveOrUpdateTasksInLocalTasksList(
          {
            tasksToRemove: [...reminderTasksToRemove],
          },
          false
        );

        this.handleTaskUnscheduling(currRowData, combinedData);
      } else {
        const { createdData } = await this.updateSingleRecurringTask(
          currRowData,
          combinedData,
          false,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          false
        );

        if (setForScroll) {
          let taskKeyToUse;
          if (
            !isEmpty(this.reminderTaskInfo) &&
            this.reminderTaskInfo.linkedTo === createdData.key
          ) {
            const allReminderTasks = getReminderTasksLinkedToTaskId(
              this.tasks,
              this.reminderTaskInfo.linkedTo
            );

            const reminderTaskData = allReminderTasks.find((t) =>
              isSameDates(t.reminderDate, this.reminderTaskInfo.reminderDate)
            );

            if (!isEmpty(reminderTaskData)) {
              taskKeyToUse = reminderTaskData.key;
            }
            this.reminderTaskInfo = undefined;
          } else if (!this.doNotSetForScroll) {
            taskKeyToUse = createdData.key;
          }

          if (taskKeyToUse) {
            this.setTaskIdsForScroll(taskKeyToUse);
          }
        }
        this.refreshList();
      }

      //
    },
    async cellEdited(cell) {
      const fieldName = cell.getField();
      let oldValue = cell.getOldValue();
      let updateVals = true;
      let extraUpdateData = {};
      const row = cell.getRow();
      let currRowData = row.getData();
      let rowId = cell.getRow().getIndex();
      const currValue = cell.getValue();

      let isReminderTask = !!currRowData.isReminderTask;
      if (isReminderTask) {
        this.reminderTaskInfo = {
          linkedTo: currRowData.reminderLinkedTo,
          reminderDate: currRowData.reminderDate,
        };

        currRowData = this.tasks.find(
          (t) => t.key === currRowData.reminderLinkedTo
        );

        rowId = currRowData.key;
      }

      if (fieldName === "title") {
        const qlInstance = createOrGetQlInstance();
        const convertedOldValue = convertHTMLToQlDelta(oldValue, qlInstance);
        const convertedNewValue = convertHTMLToQlDelta(currValue, qlInstance);
        removeQlInstance();
        updateVals = !areQlDeltasEqual(convertedOldValue, convertedNewValue);

        if (updateVals) {
          extraUpdateData = {
            resources: getAllMentionedResourceIdsFromText(
              convertedNewValue,
              false
            ),
          };
        }
      } else {
        updateVals = oldValue !== currValue;
      }

      if (updateVals) {
        if (isTaskRecurring(currRowData)) {
          return;
        }

        if (
          fieldName === "dueAsDate" &&
          isValidDate(oldValue) &&
          !isValidDate(currValue)
        ) {
          return;
        }

        const { updates, editedData } = createDbUpdateDataFromProvidedData(
          {
            [fieldName]: currValue,
            ...extraUpdateData,
          },
          {
            ...currRowData,
          }
        );

        let userActions = { ...editedData };

        // if (fieldName === "project") {
        //   const newProjectData = this.projects[currValue];

        //   if (!isEmpty(newProjectData)) {
        //     const projectPriority = newProjectData.priority || "";
        //     if (!projectPriority && currRowData.priority) {
        //       const { editedData: projectEditedData } =
        //         createUpdateAndEditedForCatOrProj(
        //           {
        //             priority: currRowData.priority,
        //           },
        //           newProjectData
        //         );

        //       userActions = [
        //         {
        //           type: "edit",
        //           data: {
        //             ...editedData,
        //             key: rowId,
        //           },
        //           on: "task",
        //         },
        //         {
        //           type: "edit",
        //           data: {
        //             ...projectEditedData,
        //             key: newProjectData.key,
        //           },
        //           on: "project",
        //         },
        //       ];

        //       updates[`/projects/${newProjectData.key}/priority`] =
        //         currRowData.priority;
        //     }
        //   }
        // }
        this.updateTaskInDbAndStoreEditData(rowId, updates, userActions);
      }
    },
    updateOptions(sortOpt, groupOpt) {
      const sortBy = sortOpt.map((sort) => ({
        ...sort,
        missingValue: "last",
      }));

      this.options = {
        sortBy,
        groupBy: groupOpt,
      };
    },
    addMissingValuesInSortOpts(sortOpt) {
      const sortBy = sortOpt.map((sort) => ({
        ...sort,
        missingValue: sort.skipMissing ? "skip" : "last",
      }));
      return sortBy;
    },
    async setTableOpts() {
      const { sortOpt, groupOpt } = this.groupAndSortTasksConfig;
      const addedSortOpts = this.addMissingValuesInSortOpts(sortOpt);
      const filters = cloneDeep(this.createCurrNavFilters());
      let currColumns = this.columns;

      if (this.currNav !== 3) {
        currColumns = this.columns.map((col) => {
          if (
            col.field === "modifiedAsDate" &&
            this.groupViewsWithModifiedColumns.includes(this.groupMode)
          ) {
            return { ...col, visible: true };
          }

          if (
            col.field === "flag" &&
            ((checkTaskSettingsIsNotEmpty(this.taskSettings) &&
              checkStatusBtnPosIsFlagCell(this.taskSettings)) ||
              (!checkTaskSettingsIsNotEmpty(this.taskSettings) &&
                treeSideBarModes.includes(this.currNav)))

            // ||
            //   calModes.includes(this.currNav)

            // &&
            // !this.isInboxViewEnabled &&
            // !this.isGlobalViewEnabled
          ) {
            return { ...col, visible: true };
          }
          if (col.field === "edit-btn" && this.checkIfEditBtnEnabled()) {
            return { ...col, visible: true };
          }

          // if (col.field === "pausedReminderDays" && this.currNav === 0) {
          //   return { ...col, visible: true };
          // }

          return col;
        });
      }

      if (this.groupMode === 3) {
        currColumns = this.columns.map((col) => {
          if (col.field === "flag") {
            const isVisible =
              (checkTaskSettingsIsNotEmpty(this.taskSettings) &&
                checkStatusBtnPosIsFlagCell(this.taskSettings)) ||
              (!checkTaskSettingsIsNotEmpty(this.taskSettings) &&
                treeSideBarModes.includes(this.currNav));

            return {
              ...col,
              visible: isVisible,
            };
          }
          if (col.field === "edit-btn" && this.checkIfEditBtnEnabled()) {
            return { ...col, visible: true };
          }

          return {
            ...col,
            visible:
              col.visible !== false ||
              this.reviewViewColumns.includes(col.field),
          };
        });
      }

      this.setGroupCollapsedList();
      this.tableColumns = currColumns;
      this.options = {
        ...this.options,
        sortBy: addedSortOpts,
        groupBy: groupOpt || "",
        filters,
      };
    },
    handleFilterSuccess({ count }) {
      // const count = filteredList.count();
      this.updateState({
        tasksCount: count,
      });
    },
    // this.changeTasksCount(count);
    addNewTask(data, useDefaultTaskType = true) {
      if (!this.isActive) return;

      const dataToUse = { ...data };

      if (useDefaultTaskType) {
        dataToUse.taskType = "task";
      }

      this.$refs.dataGrid.addNewRow({ ...dataToUse });
    },
    async updateTaskInDbAndStoreEditData(rowId, updates, editedData) {
      try {
        DatabaseInterface.update(updates, this.userInfo.uid);

        if (Array.isArray(editedData)) {
          getUserActions().addBatchAction({
            data: editedData,
            on: "all",
          });
        } else {
          getUserActions().addTaskAction({
            type: "edit",
            data: {
              ...editedData,
              key: rowId,
            },
          });
        }
      } catch (error) {
        console.error("Error while updating task in db:", error.message);
      }
    },
    async handleRowAdd(dataToData) {
      let data = dataToData;
      if (!Array.isArray(data)) data = [data];
      let reminderTasks = [];
      const processedTaskList = processTaskList(
        data,
        this.projects,
        this.categories
      );

      const taskDataToAdd = data[0];

      if (checkIfTaskHasReminder(taskDataToAdd)) {
        reminderTasks.push(...createReminderTasks(taskDataToAdd));
      }

      await addOrRemoveOrUpdateTasksInLocalTasksList(
        {
          tasksToAdd: [...processedTaskList, ...reminderTasks],
          verifyAdd: true,
        },
        false
      );
      await this.$nextTick();
      this.setTaskIdsForScroll(data[0].key);
      this.refreshList();
    },
    async refreshList(refreshCallBack, forceUpdate) {
      await this.$nextTick();
      this.$refs.dataGrid.setup(undefined, forceUpdate);

      await this.$nextTick();
      if (refreshCallBack) {
        refreshCallBack();
      }
    },
    async deselectTasks(taskKeys) {
      await this.$nextTick();
      this.$refs.dataGrid.deselectRows(taskKeys);
    },
    async updateRowData(rowUpdates, refreshList = true, setForScroll) {
      if (!Array.isArray(rowUpdates)) rowUpdates = [rowUpdates];

      this.updateTasks(rowUpdates);

      if (setForScroll) {
        this.setTaskIdsForScroll(rowUpdates[0].key);
      }
      await this.$nextTick();
      if (refreshList) {
        this.refreshList();
      }
    },
    async updateOrAddTaskRows(tasksToUpdate, tasksToAdd, refreshCallback) {
      this.updateOrAddTasks({
        tasksToUpdate,
        tasksToAdd,
      });
      await this.$nextTick();
      this.refreshList(refreshCallback);
    },
    async addUpdateRemoveTaskRows(taskData) {
      this.addOrUpdateOrRemoveTasks(taskData);
      await this.$nextTick();
      this.refreshList();
    },
    async handleRowRemove(rowId) {
      if (!Array.isArray(rowId)) rowId = [rowId];
      this.removeTasks(rowId);
      await this.$nextTick();
      this.refreshList();
    },
    createGroupItemsCaptions(data, group) {
      const groupCaption = createGroupedTasksCaptions(
        data,
        group,
        (groupKey) => {
          if (groupKey === "1") {
            return checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList)
              ? "Next Actions (drag to order)"
              : "Next Actions";
          }
          if (this.categories[groupKey]) {
            return createCategoryCaption(groupKey, this.categories);
          }
          return createReviewGroupLabel(
            groupKey,
            this.projects,
            this.categories
          );
        }
      );

      return groupCaption;
    },
    changeGroupAndSort() {
      let { sortOpt, groupOpt } = this.groupAndSortTasksConfig;
      const addedSortOpts = this.addMissingValuesInSortOpts(sortOpt);
      this.options = {
        ...this.options,
        sortBy: addedSortOpts,
        groupBy: groupOpt || "",
      };
    },
    toggleColumns(groupView, currNav) {
      const grid = this.$refs.dataGrid;
      if (grid) {
        this.$nextTick(() => {
          if (currNav !== 3) {
            // if (currNav !== 0) {
            //   grid.hideColumn(["pausedReminderDays"]);
            // } else {
            //   grid.showColumn(["pausedReminderDays"]);
            // }

            grid.hideColumn(["flag"]);
            if (groupView === 1) {
              grid.hideColumn(["createdAsDate", "modifiedAsDate"]);
              grid.showColumn(["modifiedAsDate"]);
            } else if (groupView === 3) {
              grid.showColumn(["createdAsDate", "modifiedAsDate"]);
            } else {
              grid.hideColumn(["createdAsDate", "modifiedAsDate"]);
            }

            if (this.isGlobalViewEnabled) {
              grid.hideColumn(["createdAsDate"]);
            }
          } else {
            // grid.showColumn(["pausedReminderDays"]);
            grid.hideColumn(["createdAsDate", "modifiedAsDate"]);
          }

          const showEditBtn = this.checkIfEditBtnEnabled();

          if (showEditBtn) {
            grid.showColumn(["edit-btn"]);
          } else {
            grid.hideColumn(["edit-btn"]);
          }
          if (
            (checkTaskSettingsIsNotEmpty(this.taskSettings) &&
              checkStatusBtnPosIsFlagCell(this.taskSettings)) ||
            (!checkTaskSettingsIsNotEmpty(this.taskSettings) &&
              treeSideBarModes.includes(this.currNav))
          ) {
            grid.showColumn(["flag"]);
          }
        });
      }
    },
    updateFilters() {
      const filters = this.createCurrNavFilters();
      this.createdFilters = filters;
    },
    changeNavFilter() {
      this.updateFilters();
    },
    createCurrNavFilters() {
      let currFilters = cloneDeep(this.navFilter);
      const searchQuery = this.searchQuery;
      const selectedDates = this.selectedDates;
      const selectedOptsList = this.selectedOptsList;
      const filters = this.filters;
      const isCategoryFilterEnabled =
        !isEmpty(filters) && !isEmpty(filters.category);
      const contingentFilter = this.contingentFilter;

      const showContingentTasks = this.showContingentTasks;

      if (viewsWithDateFilter.includes(this.currNav)) {
        if (!this.isInboxViewEnabled || this.isInspectModeEnabled) {
          // if (selectedDates && selectedDates.length) {
          currFilters = addDueDateFilter(
            selectedDates,
            contingentFilter,
            currFilters,
            this.currNav,
            showContingentTasks,
            this.isGlobalViewEnabled
          );
          // }

          if (!this.isGlobalViewEnabled && this.currNav === 3) {
            currFilters = createAndAddTodayFilter(
              contingentFilter,
              selectedDates,
              currFilters,
              showContingentTasks
            );
          }

          // if(this.currNav === 0){

          // }
        }

        //
      } else if (treeSideBarModes.includes(this.currNav)) {
        if (!this.isInboxViewEnabled || this.isInspectModeEnabled) {
          currFilters = addProjectOrCategoriesFilter(
            selectedOptsList,
            currFilters,
            this.isGlobalViewEnabled,
            isCategoryFilterEnabled,
            this.showAllOrderedTasks
          );
          // if (selectedOptsList && selectedOptsList.length) {

          // }
        }
      }

      currFilters = createSearchQueryFilters(
        currFilters,
        searchQuery,
        this.isInNextActionsMode && !this.isGlobalViewEnabled
      );
      // if (searchQuery && searchQuery !== "*") {
      //   if (this.isInNextActionsMode && !this.isGlobalViewEnabled) {
      //     const storedIndex = currFilters.findIndex((f) =>
      //       isEqual(f, nextActionsGroupFilter)
      //     );
      //     if (storedIndex >= 0) {
      //       currFilters.splice(storedIndex, 1);
      //     }
      //   }
      //   currFilters.push([...createTaskSearchQueryFilter(searchQuery)]);
      // }

      if (!isEmpty(filters)) {
        const createdFilters = createTaskListFiltersFromFiltersMap(filters);

        if (!isEmpty(createdFilters)) {
          currFilters = [...currFilters, ...createdFilters];
        }
      }

      // if (this.showClearedTasks) {
      currFilters = createShowClearedTasksFilters(
        currFilters,
        clearedTaskFiltersToRemove,
        this.showClearedTasks
      );

      // }

      return currFilters;
    },
    async setUpdatedFiltersInGrid() {
      const filters = cloneDeep(this.createdFilters);
      this.options = {
        ...this.options,
        filters: filters,
      };
      await this.$nextTick();
      const tasks = this.$refs.dataGrid?.getSelectedRows();
      this.updateState({
        selectedTasks: tasks,
      });
    },
    handleRowsSelected(rows) {
      if (!this.isEditTaskFormOpen) {
        this.updateSelectedTasks(rows);
      }
    },
    changeProjectByDragAndDrop(eventData) {
      this.setLoader(true);
      const { dropTargetRowData = {} } = eventData;
      const draggedTargetRows = eventData.draggedRows;
      let tasksToUpdateInLocalList = [];
      const targetProject = dropTargetRowData.project || "";
      const dropTargetOrder = dropTargetRowData.order;
      const dropTargetUserPosition = dropTargetRowData.userPosition;
      const editedTasksData = [];
      const tasksToAddInDb = [];
      const tasksToUpdateInDb = [];
      const tasksToRemoveFromLocalList = [];
      const tasksToUpdateInGoogle = [];
      const tasksToAddInLocaList = [];
      let groupedEditedTaskData = {};
      let recurringTasksToUpdateWithExcludedDates = {};
      const googleRecurringEventUpdateListMap = {};
      let recurringTasksForVirtualTasksUpdate = {};

      const { taskIdsToFilter } = processDroppedTasks(
        draggedTargetRows,
        targetProject,
        dropTargetOrder
      );

      const currTasksOnDropOrderAndPosition = getTasksByOrderAndPosition(
        this.filteredTasks,
        dropTargetOrder,
        dropTargetUserPosition,
        taskIdsToFilter
      );

      let newPositionIndex = dropTargetUserPosition + draggedTargetRows.length;
      let dropPositionIndex = dropTargetUserPosition;
      currTasksOnDropOrderAndPosition.forEach((t) => {
        if (t.key) {
          if (isTaskVirtual(t)) {
            const mainTaskData = this.rawTasksMap[t.linkedTo];
            if (
              !isEmpty(mainTaskData) &&
              !recurringTasksForVirtualTasksUpdate[mainTaskData.key]
            ) {
              tasksToUpdateInDb.push({
                key: mainTaskData.key,
                updates: {
                  order: dropTargetOrder,
                  userPosition: newPositionIndex,
                },
              });
              recurringTasksForVirtualTasksUpdate[mainTaskData.key] = {
                order: dropTargetOrder,
                userPosition: newPositionIndex,
              };
            }
          } else {
            tasksToUpdateInLocalList.push({
              key: t.key,
              updates: {
                order: dropTargetOrder,
                userPosition: newPositionIndex,
              },
            });

            editedTasksData.push({
              key: t.key,
              order: t.order,
              userPosition: t.userPosition,
              positionChanged: t.positionChanged || false,
              changedProps: {
                order: dropTargetOrder,
                userPosition: newPositionIndex,
              },
            });

            tasksToUpdateInDb.push({
              key: t.key,
              updates: {
                order: dropTargetOrder,
                userPosition: newPositionIndex,
              },
            });
          }

          newPositionIndex++;
        }
      });

      draggedTargetRows.forEach((draggedRow) => {
        if (draggedRow.key) {
          if (isTaskVirtual(draggedRow)) {
            const mainTaskData = this.rawTasksMap[draggedRow.linkedTo];
            if (!isEmpty(mainTaskData)) {
              if (draggedRow.project === targetProject) {
                if (!recurringTasksForVirtualTasksUpdate[mainTaskData.key]) {
                  tasksToUpdateInDb.push({
                    key: mainTaskData.key,
                    updates: {
                      order: dropTargetOrder,
                      userPosition: dropPositionIndex,
                      positionChanged: true,
                    },
                  });
                  recurringTasksForVirtualTasksUpdate[mainTaskData.key] = {
                    order: dropTargetOrder,
                    userPosition: dropPositionIndex,
                    positionChanged: true,
                  };
                }
              } else {
                if (
                  !recurringTasksToUpdateWithExcludedDates[mainTaskData.key]
                ) {
                  recurringTasksToUpdateWithExcludedDates[mainTaskData.key] = {
                    excludedDates: [],
                    childrenUpdates: [],
                    childrenEditedData: [],
                  };
                }

                const parsedDate = parseJSONDate(draggedRow.dueAsDate);
                const res = createDbDataForConvertingRecurringTaskToRealTask(
                  { ...draggedRow, dueAsDate: parsedDate },
                  {
                    ...draggedRow,
                    dueAsDate: parsedDate,
                    project: targetProject,
                    order: dropTargetOrder,
                    userPosition: dropPositionIndex,
                    positionChanged: true,
                  },
                  false,
                  false
                );

                if (!isEmpty(res.dataToAddInDb)) {
                  tasksToAddInDb.push({
                    ...res.dataToAddInDb,
                    // order: dropTargetOrder,
                    // userPosition: dropPositionIndex,
                  });
                  tasksToAddInLocaList.push({
                    ...res.dataToAddInLocalList,
                    // order: dropTargetOrder,
                    // userPosition: dropPositionIndex,
                  });
                  tasksToRemoveFromLocalList.push(draggedRow);
                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].excludedDates.push(...res.excludedDates);

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenUpdates.push(res.dataForGoogle);
                  res.editedTaskData.changedProps = {
                    ...res.editedTaskData.changedProps,
                    order: dropTargetOrder,
                    userPosition: dropPositionIndex,
                    positionChanged: true,
                  };

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenEditedData.push(res.editedTaskData);
                }
              }
            }
          } else {
            tasksToUpdateInLocalList.push({
              key: draggedRow.key,
              updates: {
                project: targetProject,
                order: dropTargetOrder,
                userPosition: dropPositionIndex,
                positionChanged: true,
                key: draggedRow.key,
              },
            });

            editedTasksData.push({
              key: draggedRow.key,
              order: draggedRow.order,
              userPosition: draggedRow.userPosition,
              project: draggedRow.project || "",
              positionChanged: draggedRow.positionChanged || false,
              changedProps: {
                order: dropTargetOrder,
                project: targetProject,
                userPosition: dropPositionIndex,
                positionChanged: true,
              },
            });

            tasksToUpdateInDb.push({
              key: draggedRow.key,
              updates: {
                project: targetProject,
                order: dropTargetOrder,
                userPosition: dropPositionIndex,
                positionChanged: true,
              },
            });
          }

          dropPositionIndex++;
        }
      });

      for (const recurringTaskIdKey in recurringTasksToUpdateWithExcludedDates) {
        const recurringTaskData = this.rawTasksMap[recurringTaskIdKey];
        const excludedDates =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .excludedDates || [];
        const childrenUpdates =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .childrenUpdates;
        const childrenEditedData =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .childrenEditedData || [];
        const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules(
          {
            recurrence: recurringTaskData.recurrence,
            dueAsDate: createDateTimeFromDue(
              recurringTaskData.due,
              recurringTaskData.time
            ),
          }
        );

        excludeDatesInTaskRules(excludedDates, parsedRuleSet);
        const convertedRules = parsedRuleSet.valueOf().slice(1);
        tasksToUpdateInDb.push({
          key: recurringTaskData.key,
          updates: {
            recurrence: convertedRules,
          },
        });

        const recurrences = createRecurringDatesFromTaskRules({
          ...recurringTaskData,
          recurrence: convertedRules,
        });
        const firstRecurrence = recurrences[0];
        const firstTaskData = getRecurrenceIdByDateFromRecurrences(
          firstRecurrence,
          recurringTaskData.key
        );

        if (!isEmpty(firstTaskData)) {
          const currTaskData = this.tasks.find(
            (t) => t.key === firstTaskData.key
          );
          if (!isEmpty(currTaskData)) {
            tasksToUpdateInLocalList.push({
              key: currTaskData.key,
              updates: {
                isCalendarOnly: false,
              },
            });
          }
        }

        if (this.isGoogleCalSyncEnabled && recurringTaskData.googleEventId) {
          if (
            !googleRecurringEventUpdateListMap[recurringTaskData.googleEventId]
          ) {
            googleRecurringEventUpdateListMap[recurringTaskData.googleEventId] =
              {
                childrenUpdates: [],
                mainEventUpdates: {},
              };
          }
          googleRecurringEventUpdateListMap[
            recurringTaskData.googleEventId
          ].mainEventUpdates = {
            ...createTaskDataForGoogleCal(
              {
                recurrence: convertedRules,
                taskKey: recurringTaskData.key,
              },
              { ...recurringTaskData }
            ),
          };

          googleRecurringEventUpdateListMap[
            recurringTaskData.googleEventId
          ].childrenUpdates = [...childrenUpdates];

          //         googleRecurringEventUpdateListMap[
          // recurringTaskData.googleEventId
          //         ].childrenUpdates.push({
          //           currData: {
          //             dueAsDate: createDateTimeFromDue(
          //               currRawData.due,
          //               currRawData.time
          //             ),
          //             taskKey: currRawData.key,
          //             googleEventId: currRawData.googleEventId,
          //           },
          //           newData: createTaskDataForGoogleCal(
          //             { status: "cancelled", taskKey: currRawData.key },
          //             { ...currRawData }
          //           ),
          //         });

          // tasksToUpdateInGoogle.push({
          //   key: recurringTaskData.googleEventId,
          //   updates:
          // });

          // childrenUpdates.forEach((childUpdates) => {
          //   this.findRecurringEventByIdOrDateAndUpdateIt(
          //     childUpdates,
          //     recurringTaskIdKey
          //   );
          // });
        }

        groupedEditedTaskData[recurringTaskIdKey] = {
          editedTasks: [...childrenEditedData],
        };
      }

      for (const mainTaskId in recurringTasksForVirtualTasksUpdate) {
        const dataToUpdate = recurringTasksForVirtualTasksUpdate[mainTaskId];
        if (this.rawTasksMap[mainTaskId]) {
          const recurringTasksToUpdate = this.recurringTasksMap[mainTaskId];
          if (!isEmpty(recurringTasksToUpdate)) {
            recurringTasksToUpdate.forEach((t) => {
              tasksToUpdateInLocalList.push({
                key: t.key,
                updates: { ...dataToUpdate },
              });
            });
          }
          groupedEditedTaskData[mainTaskId] = {
            updateMain: true,
            editedData: {
              order: this.rawTasksMap[mainTaskId].order,
              userPosition: this.rawTasksMap[mainTaskId].userPosition,
              positionChanged:
                this.rawTasksMap[mainTaskId].positionChanged || false,
              changedProps: {
                ...dataToUpdate,
              },
              key: mainTaskId,
            },
            recreateTasks: true,
          };
        }
      }

      if (!isEmpty(editedTasksData)) {
        groupedEditedTaskData["normal"] = {
          editedTasks: [...editedTasksData],
        };
      }

      addOrRemoveOrUpdateTasksInDb({
        tasksToUpdate: tasksToUpdateInDb,
        tasksToAdd: tasksToAddInDb,
      });

      getUserActions().addTaskAction({
        data: groupedEditedTaskData,
        type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
      });

      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToUpdate: tasksToUpdateInLocalList,
        tasksToAdd: tasksToAddInLocaList,
        tasksToRemove: tasksToRemoveFromLocalList,
      });
      addOrUpdateOrRemoveTasksInGoogleCal({
        tasksToUpdate: tasksToUpdateInGoogle,
      });
      this.updateRecurringAndItsChildEventsFromMap(
        googleRecurringEventUpdateListMap
      );

      this.$nextTick(() => {
        this.setLoader(false);
      });
    },
    handleRowDragStop(eventData) {
      if (this.isInAreasMode) {
        this.changeProjectByDragAndDrop(eventData);
      }
      if (this.isInNextActionsMode) {
        this.changeOrderOfTasksByDragAndDrop(eventData);
      }
    },

    processTaskDroppedInFlaggedAndUnFlaggedGroups(draggedRows, dropRowData) {
      this.setLoader(true);
      const editedTasksData = [];
      const tasksToAddInDb = [];
      const tasksToUpdateInDb = [];
      const tasksToRemoveFromLocalList = [];
      const tasksToUpdateInGoogle = [];
      const tasksToAddInLocaList = [];
      const tasksToUpdateInLocalList = [];
      let groupedEditedTaskData = {};
      let recurringTasksToUpdateWithExcludedDates = {};
      let recurringTasksForVirtualTasksUpdate = {};
      let groupKeyData = this.$refs.dataGrid.getGroupBy();
      const googleRecurringEventUpdateListMap = {};
      if (!isEmpty(groupKeyData) && isPlainObject(groupKeyData)) {
        groupKeyData = groupKeyData.key;
      }

      const customOrderTasks = filterOutCustomOrderTasks(
        draggedRows,
        groupKeyData
      );

      if (!isEmpty(customOrderTasks) && !isEmpty(dropRowData)) {
        let dataToUpdate = {
          due: "none",
          time: "00:00",
          flag: false,
          isCustomPositioned: false,
          modified: getCurrDate(),
        };

        if (dropRowData[groupKeyData] === "2") {
          dataToUpdate.flag = true;
        }
        customOrderTasks.forEach((draggedRow) => {
          if (draggedRow.key) {
            if (isTaskVirtual(draggedRow)) {
              const mainTaskData = this.rawTasksMap[draggedRow.linkedTo];
              if (!isEmpty(mainTaskData)) {
                if (!recurringTasksForVirtualTasksUpdate[mainTaskData.key]) {
                  tasksToUpdateInDb.push({
                    key: mainTaskData.key,
                    updates: {
                      ...dataToUpdate,
                    },
                  });
                  recurringTasksForVirtualTasksUpdate[mainTaskData.key] = {
                    ...dataToUpdate,
                  };
                }
              } else {
                if (
                  !recurringTasksToUpdateWithExcludedDates[mainTaskData.key]
                ) {
                  recurringTasksToUpdateWithExcludedDates[mainTaskData.key] = {
                    excludedDates: [],
                    childrenUpdates: [],
                    childrenEditedData: [],
                  };
                }

                const parsedDate = createDateTimeFromDue(
                  dataToUpdate.due,
                  dataToUpdate.time
                );
                const res = createDbDataForConvertingRecurringTaskToRealTask(
                  { ...draggedRow, dueAsDate: parsedDate },
                  {
                    ...draggedRow,
                    dueAsDate: parsedDate,
                    ...dataToUpdate,
                  },
                  false,
                  false
                );

                if (!isEmpty(res.dataToAddInDb)) {
                  tasksToAddInDb.push({
                    ...res.dataToAddInDb,
                  });
                  tasksToAddInLocaList.push({
                    ...res.dataToAddInLocalList,
                  });
                  tasksToRemoveFromLocalList.push(draggedRow);
                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].excludedDates.push(...res.excludedDates);

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenUpdates.push(res.dataForGoogle);
                  res.editedTaskData.changedProps = {
                    ...res.editedTaskData.changedProps,
                    ...dataToUpdate,
                  };

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenEditedData.push(res.editedTaskData);
                }
              }
            } else {
              tasksToUpdateInLocalList.push({
                key: draggedRow.key,
                updates: {
                  ...dataToUpdate,
                  key: draggedRow.key,
                },
              });

              editedTasksData.push({
                key: draggedRow.key,
                isCustomPositioned: draggedRow.isCustomPositioned || false,
                time: draggedRow.time,
                due: draggedRow.due,
                flag: draggedRow.flag || false,
                changedProps: {
                  ...dataToUpdate,
                },
              });

              tasksToUpdateInDb.push({
                key: draggedRow.key,
                updates: {
                  ...dataToUpdate,
                },
              });
            }
          }
        });
      }

      for (const recurringTaskIdKey in recurringTasksToUpdateWithExcludedDates) {
        const recurringTaskData = this.rawTasksMap[recurringTaskIdKey];
        const excludedDates =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .excludedDates || [];
        const childrenUpdates =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .childrenUpdates;
        const childrenEditedData =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .childrenEditedData || [];
        const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules(
          {
            recurrence: recurringTaskData.recurrence,
            dueAsDate: createDateTimeFromDue(
              recurringTaskData.due,
              recurringTaskData.time
            ),
          }
        );

        excludeDatesInTaskRules(excludedDates, parsedRuleSet);
        const convertedRules = parsedRuleSet.valueOf().slice(1);
        tasksToUpdateInDb.push({
          key: recurringTaskData.key,
          updates: {
            recurrence: convertedRules,
          },
        });

        const recurrences = createRecurringDatesFromTaskRules({
          ...recurringTaskData,
          recurrence: convertedRules,
        });
        const firstRecurrence = recurrences[0];
        const firstTaskData = getRecurrenceIdByDateFromRecurrences(
          firstRecurrence,
          recurringTaskData.key
        );

        if (!isEmpty(firstTaskData)) {
          const currTaskData = this.tasks.find(
            (t) => t.key === firstTaskData.key
          );
          if (!isEmpty(currTaskData)) {
            tasksToUpdateInLocalList.push({
              key: currTaskData.key,
              updates: {
                isCalendarOnly: false,
              },
            });
          }
        }

        if (this.isGoogleCalSyncEnabled && recurringTaskData.googleEventId) {
          if (
            !googleRecurringEventUpdateListMap[recurringTaskData.googleEventId]
          ) {
            googleRecurringEventUpdateListMap[recurringTaskData.googleEventId] =
              {
                childrenUpdates: [],
                mainEventUpdates: {},
              };
          }
          googleRecurringEventUpdateListMap[
            recurringTaskData.googleEventId
          ].mainEventUpdates = {
            ...createTaskDataForGoogleCal(
              {
                recurrence: convertedRules,
                taskKey: recurringTaskData.key,
              },
              { ...recurringTaskData }
            ),
          };

          googleRecurringEventUpdateListMap[
            recurringTaskData.googleEventId
          ].childrenUpdates = [...childrenUpdates];

          // tasksToUpdateInGoogle.push({
          //   key: recurringTaskData.googleEventId,
          //   updates: createTaskDataForGoogleCal(
          //     {
          //       recurrence: convertedRules,
          //       taskKey: recurringTaskData.key,
          //     },
          //     recurringTaskData
          //   ),
          // });

          // childrenUpdates.forEach((childUpdates) => {
          //   this.findRecurringEventByIdOrDateAndUpdateIt(
          //     childUpdates,
          //     recurringTaskIdKey
          //   );
          // });
        }

        groupedEditedTaskData[recurringTaskIdKey] = {
          editedTasks: [...childrenEditedData],
        };
      }

      for (const mainTaskId in recurringTasksForVirtualTasksUpdate) {
        const dataToUpdate = recurringTasksForVirtualTasksUpdate[mainTaskId];
        if (this.rawTasksMap[mainTaskId]) {
          const recurringTasksToUpdate = this.recurringTasksMap[mainTaskId];
          if (!isEmpty(recurringTasksToUpdate)) {
            recurringTasksToUpdate.forEach((t) => {
              tasksToUpdateInLocalList.push({
                key: t.key,
                updates: { ...dataToUpdate },
              });
            });
          }
          groupedEditedTaskData[mainTaskId] = {
            updateMain: true,
            editedData: {
              time: this.rawTasksMap[mainTaskId].time,
              due: this.rawTasksMap[mainTaskId].due,
              flag: this.rawTasksMap[mainTaskId].flag || false,
              isCustomPositioned:
                this.rawTasksMap[mainTaskId].isCustomPositioned || false,
              changedProps: {
                ...dataToUpdate,
              },
              key: mainTaskId,
            },
            recreateTasks: true,
          };
        }
      }

      if (!isEmpty(editedTasksData)) {
        groupedEditedTaskData["normal"] = {
          editedTasks: [...editedTasksData],
        };
      }

      if (!isEmpty(groupedEditedTaskData)) {
        getUserActions().addTaskAction({
          data: groupedEditedTaskData,
          type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
        });
      }

      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToUpdate: tasksToUpdateInLocalList,
        tasksToAdd: tasksToAddInLocaList,
        tasksToRemove: tasksToRemoveFromLocalList,
      });
      addOrUpdateOrRemoveTasksInGoogleCal({
        tasksToUpdate: tasksToUpdateInGoogle,
      });

      this.setLoader(false);
      this.updateRecurringAndItsChildEventsFromMap(
        googleRecurringEventUpdateListMap
      );

      this.$nextTick(() => {
        addOrRemoveOrUpdateTasksInDb({
          tasksToUpdate: tasksToUpdateInDb,
          tasksToAdd: tasksToAddInDb,
        });
      });
    },
    changeOrderOfTasksByDragAndDrop(eventData) {
      const { dropTargetRowData = {}, isLastRow } = eventData;

      const currGroupData = this.$refs.dataGrid.getGroupBy();
      let groupKey = currGroupData;
      if (
        !isEmpty(currGroupData) &&
        isPlainObject(currGroupData) &&
        currGroupData.key
      ) {
        groupKey = currGroupData.key;
      }

      const draggedTargetRows = eventData.draggedRows;
      const dropTargetRowGroup = dropTargetRowData[groupKey];
      if (groupKey === "CTICustomGroup") {
        if (dropTargetRowGroup === "1") {
          this.processTaskDroppedInCustomOrder({
            draggedTasks: draggedTargetRows,
            dropRowData: dropTargetRowData,
            groupByField: currGroupData,
            tasksList: this.filteredTasks,
            isLastRow,
          });
        } else if (nextActionsGroupsDragAllowed.includes(dropTargetRowGroup)) {
          this.processTaskDroppedInFlaggedAndUnFlaggedGroups(
            draggedTargetRows,
            dropTargetRowData
          );
        }
      }
    },
    toggleDragAndDrop(groupMode, currNav) {
      this.allowDragAndDrop =
        treeSideBarModes.includes(currNav) &&
        dragAllowedGroups.includes(groupMode);
    },
    scrollToInspectedRow(selectRow) {
      const selectedTasks = this.selectedTasks;

      if (!isEmpty(selectedTasks)) {
        this.$refs.dataGrid.scrollToRow(
          selectedTasks[0].key,
          selectRow,
          true,
          "IOMNS"
        );
      }
    },
    clearCollapsedGroups() {
      this.$refs.dataGrid.clearCollapsedGroups();
    },
    setGroupCollapsedList() {
      const collapsedGroups = this.getCollapsedGroupsList();
      // let collapsedGroups = this.collapsedGroups;
      // // console.log("CHECK", this.checkIfNextAreasAndSelectionIsEmpty());
      // if (this.checkIfNextAreasAndSelectionIsEmpty()) {
      //   // if(!this.isSearchKeywordValid && checkTaskAdditionalFiltersEmpty(this.filters)){
      //   //   collpasedG
      //   // }

      //   if (
      //     !this.isSearchKeywordValid &&
      //     checkTaskAdditionalFiltersEmpty(this.filters)
      //   ) {
      //     collapsedGroups = [...DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS];
      //   } else {
      //     collapsedGroups = [];
      //   }
      // }

      this.$refs.dataGrid.setCollapsedGroupList(collapsedGroups);
    },
    getCollapsedGroupsList() {
      let collapsedGroups = this.collapsedGroups;
      if (this.checkIfNextAreasAndSelectionIsEmpty()) {
        if (
          !this.isSearchKeywordValid &&
          checkTaskAdditionalFiltersEmpty(this.filters)
        ) {
          collapsedGroups = [...DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS];
        } else {
          collapsedGroups = [];
        }
      }

      return collapsedGroups;
    },
    async handleMenuSelection(args) {
      await this.$nextTick();
      const selectedMenuItem = args.item;
      if (!isEmpty(this.selectedTaskToUse)) {
        switch (selectedMenuItem.id) {
          case TASK_ACTIONS_TYPES.DUPLICATE:
            this.duplicateTask([this.selectedTaskToUse]);
            break;
          case TASK_ACTIONS_TYPES.DELETE:
            this.deleteTasks([this.selectedTaskToUse]);
            break;
          case TASK_ACTIONS_TYPES.COMPLETE:
            this.taskCompleteToggle([this.selectedTaskToUse]);
            break;
          case TASK_ACTIONS_TYPES.CONTINGENT:
            this.toggleContingentTasks([this.selectedTaskToUse], !this.selectedTaskToUse.isContingent)
        }
        const parentId = selectedMenuItem.parentObj.id;
        switch (parentId) {
          case TASK_ACTIONS_TYPES.REPEAT_DAY:
          case TASK_ACTIONS_TYPES.REPEAT_WEEK:
          case TASK_ACTIONS_TYPES.REPEAT_MONTH:
          case TASK_ACTIONS_TYPES.REPEAT_YEAR:
            this.updateRepeat([this.selectedTaskToUse], parentId.replace("repeat_", ""), selectedMenuItem.text);
            break;
          case TASK_ACTIONS_TYPES.REMINDER:
            this.updateReminder([this.selectedTaskToUse], selectedMenuItem.text);
            break;
        }
      }
    },
    toggleContingentTasks(tasks, isContingent) {
      const updates = {
        isContingent,
        dueAsDate: null
      }
      if (!isContingent) {
        updates.dueAsDate = new Date();
        updates.due = makeDateFormat();
        updates.time = makeTimeFormat();
      }
      this.updateRecurringOrNormalTasks(tasks, updates)
    },
    updateReminder(tasks, days) {
      const updates = {
        reminderDays: null
      }
      if (days > 0) {
        updates.reminderDays = days
      }
      this.updateRecurringOrNormalTasks(tasks, updates)
    },
    updateRepeat(tasks, frequency, days) {
      const updates = {
        repeatInfo: null
      }
      if (days > 0) {
        updates.repeatInfo = {
          frequency,
          count: days
        }
      }
      console.log('updateRepeat',  tasks, updates)
      this.updateRecurringOrNormalTasks(tasks, updates)
    },
    handleBeforeMenuOpen(args) {
      const menuObj = document.getElementById("data-table-menu-opts")
        .ej2_instances[0];
      if (isEmpty(this.selectedTasks)) {
        args.cancel = true;
        return;
      }

      const taskToSelect = this.selectedTasks[0];
      if (!isEmpty(taskToSelect)) {
        if (taskToSelect.completed) {
          menuObj.hideItems(["Complete"]);
        } else {
          menuObj.showItems(["Complete"]);
        }

        this.selectedTaskToUse = { ...taskToSelect };
      }
    },
    duplicateTask(tasksToUse) {
      if (!isEmpty(tasksToUse)) {
        let taskDataToUse = cloneDeep(
          tasksToUse[decrement(tasksToUse.length, 1)]
        );

        if (!isEmpty(taskDataToUse)) {
          taskDataToUse = removeProps(taskDataToUse, [
            "recurrence",
            "linkedTo",
            "coRelationalId",
            "frequency",
            "recurrenceCount",
            "repeatMonthDate",
            "recurrenceRepeatDay",
            "key",
            "repeatInfo",
          ]);

          taskDataToUse.userPosition = increment(taskDataToUse.userPosition, 1);
          taskDataToUse.customPositionIndex = increment(
            taskDataToUse.customPositionIndex,
            1
          );
          taskDataToUse.description = "";
          taskDataToUse = duplicateTask(taskDataToUse, taskDataToUse.order);
          taskDataToUse.duplicated = true;
          delete taskDataToUse.key;
          this.addNewTask({ ...taskDataToUse }, false);
        }
      }
    },

    deleteTasks(tasksToDelete) {
      this.handleDeletionOfTasks(tasksToDelete);
    },
    taskCompleteToggle(tasksToComplete) {
      const selectedTask = tasksToComplete[0];
      const currTaskRepeatInfo = selectedTask.repeatInfo;

      if (!isEmpty(currTaskRepeatInfo)) {
        const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
          fieldName: "completed",
          currValue: true,
          rowId: selectedTask.key,
          oldValue: false,
          currRowData: selectedTask,
        });
        const createdRowData = createTaskRowDataByCell(
          selectedTask,
          "completed",
          true,
          false
        );
        this.completeCurrTaskAndCreateNextRepeatTask({
          key: selectedTask.key,
          updates,
          editedData,
          updatedRowData: createdRowData,
        });

        return;
      } else if (checkIfTaskIsContinued(selectedTask)) {
        const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
          fieldName: "completed",
          currValue: true,
          rowId: selectedTask.key,
          oldValue: false,
          currRowData: selectedTask,
        });
        const createdRowData = createTaskRowDataByCell(
          selectedTask,
          "completed",
          true,
          false
        );

        this.completeContinuedTask({
          key: selectedTask.key,
          updates,
          editedData,
          updatedRowData: createdRowData,
        });

        return;
      }
      this.updateRecurringOrNormalTasks(tasksToComplete, { completed: true });
    },
    getCurrEditingRowId() {
      return this.$refs.dataGrid.getCurrEditingRow();
    },
    isGridInEditMode() {
      return this.$refs.dataGrid.isEditingActive();
    },
    checkIfSelectedOptsHasOnlyArea(selectedOptsList) {
      return checkIfSelectedTaskCateHasOnlyArea(selectedOptsList);
    },
    toggleNextActionsGroups() {
      if (this.isInNextActionsMode) {
        const { sortOpt, groupOpt } = this.groupAndSortTasksConfig;
        this.options = {
          ...this.options,
          sortBy: sortOpt,
          groupBy: groupOpt,
        };
      }
    },
    handleSearchQueryChange() {
      if (!this.isInNextActionsMode) {
        this.updateFilters();
      } else {
        this.refreshList();
      }
    },

    handleTaskSettingsChange(n, o) {
      if (
        n.statusBtnPosition !== o.statusBtnPosition ||
        n.showEditBtn !== o.showEditBtn
      ) {
        this.toggleColumns();
      }
    },

    checkIfNextAreasAndSelectionIsEmpty() {
      return (
        this.isInNextActionsMode &&
        !this.isGlobalViewEnabled &&
        isEmpty(this.selectedOptsList)
      );
    },
  },
  computed: {
    ...mapGetters("task", [
      "tasks",
      "groupMode",
      "isInboxViewEnabled",
      "isGlobalViewEnabled",
      "currNav",
      "selectedDates",
      "selectedOptsList",
      "searchQuery",
      "filters",
      "isInspectModeEnabled",
      "showClearedTasks",
      "projects",
      "categories",
      "rawTasks",
      "rawTasksMap",
      "filteredTasks",
      "recurringTasksMap",
      "contingentFilter",
      "selectedTasks",
      "collapsedGroups",
      "selectedTaskFilter",
      "showContingentTasks",
    ]),
    ...mapGetters(["isGoogleCalSyncEnabled", "taskSettings"]),
    ...mapGetters("editForm", {
      isEditTaskFormOpen: "isOpen",
    }),
    collapsedGroupsList() {
      let collapsedGroups = this.getCollapsedGroupsList();

      // if (this.checkIfNextAreasAndSelectionIsEmpty()) {
      //   if (
      //     !this.isSearchKeywordValid &&
      //     checkTaskAdditionalFiltersEmpty(this.filters)
      //   ) {
      //     collapsedGroups = [...DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS];
      //   } else {
      //     collapsedGroups = [];
      //   }
      // }

      return collapsedGroups;
    },
    tableWrapperStyles() {
      return {
        // "today-tasks-view": this.currNav === 3,
        "tasks-table": true,
      };
    },
    isSearchKeywordValid() {
      let searchQuery = this.searchQuery;

      searchQuery = searchQuery && searchQuery.trim();

      return !isEmpty(searchQuery) && searchQuery !== "*";
    },
    groupAndSortTasksConfig() {
      const groupViewConfig = groupViewConfigs[this.groupMode];
      const viewsWithContingent = [0, 3];
      let sortOptIndex = groupViewConfig.sortColumnType;
      let searchQuery = this.searchQuery;

      searchQuery = searchQuery && searchQuery.trim();
      // if (this.isInboxViewEnabled && this.groupMode === 2) {
      //   sortOptIndex = 5;
      // }

      if (this.groupMode === 2) {
        sortOptIndex = 7;
      }

      let groupOptIndex = groupViewConfig.groupColumnType;

      if (
        viewsWithContingent.includes(this.currNav) &&
        !this.isGlobalViewEnabled &&
        !this.isInspectModeEnabled
      ) {
        sortOptIndex = 6;

        if (this.groupMode === 2) {
          sortOptIndex = 7;
        }

        if (this.currNav === 0 && this.groupMode === 3) {
          sortOptIndex = 3;
        }
        if (this.currNav === 3 && this.groupMode === 0) {
          groupOptIndex = 4;
          // sortOptIndex = 8;
        }

        // Group to show contingent tasks
        groupOptIndex = 7;
        // if (
        //   !isEmpty(this.selectedDates) &&
        //   this.selectedDates.length === 1 &&
        //   this.selectedDates[0] !== getCurrDate("date")
        // ) {
        //   groupOptIndex = 7;
        // }
      }

      if (
        this.isInNextActionsMode &&
        !this.isGlobalViewEnabled
        // this.checkIfSelectedOptsHasOnlyArea(this.selectedOptsList)
      ) {
        sortOptIndex = 9;
        groupOptIndex = 5;

        if (
          isEmpty(this.selectedOptsList) &&
          (!searchQuery || searchQuery === "*") &&
          !this.showAllOrderedTasks
        ) {
          sortOptIndex = 12;
          groupOptIndex = 3;
        }

        // if (
        //   !this.checkIfSelectedOptsHasOnlyArea(this.selectedOptsList) &&
        //   (!searchQuery || searchQuery === "*")
        // ) {
        //   groupOptIndex = 3;
        // }
      }

      const sortOpt = cloneDeep(sortOpts[sortOptIndex]);
      const groupOpt = cloneDeep(groupOpts[groupOptIndex]);

      return {
        sortOpt,
        groupOpt,
      };
    },
    navFilter() {
      let navIndex = this.currNav;
      if (navIndex === 3) {
        navIndex = 5;
      }
      if (this.isInboxViewEnabled) {
        navIndex = 3;
      }
      if (this.isGlobalViewEnabled) {
        navIndex = 2;
      }

      if (navIndex === 4) {
        navIndex = 7;
      }

      return navViewFiltersConfig[navIndex].filters;
    },
    areTasksVisible() {
      // eslint-disable-next-line no-unused-vars
      let isVisible = true;

      if (
        treeSideBarModes.includes(this.currNav) &&
        !this.isInboxViewEnabled &&
        !this.isGlobalViewEnabled &&
        isEmpty(this.selectedOptsList)
      ) {
        isVisible = false;
      }

      return true;
    },
    isInAreasMode() {
      return this.groupMode === 2 && this.currNav === 1;
    },
    isInNextActionsMode() {
      return this.groupMode === 1 && this.currNav === 4;
    },
    displayPlaceholder() {
      return (
        this.isInNextActionsMode &&
        !this.isGlobalViewEnabled &&
        isEmpty(this.selectedOptsList) &&
        !this.isSearchKeywordValid
      );
    },
    isInCalOrTodayMode() {
      return (
        viewsWithDateFilter.includes(this.currNav) &&
        !this.isGlobalViewEnabled &&
        !this.isInboxViewEnabled
      );
    },
  },
  watch: {
    taskSettings: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.handleTaskSettingsChange(n, o);
        }
      },
      deep: true,
    },
    showContingentTasks() {
      this.updateFilters();
    },
    groupMode(val) {
      this.changeGroupAndSort();
      this.toggleDragAndDrop(val, this.currNav);
      this.toggleColumns(val, this.currNav);
    },
    currNav: {
      handler(n) {
        this.setGroupCollapsedList();
        // this.clearCollapsedGroups();
        this.changeGroupAndSort();
        this.toggleColumns(this.groupMode, n);
        this.toggleDragAndDrop(this.groupMode, n);
        this.changeNavFilter();
      },
    },
    isGlobalViewEnabled() {
      this.setGroupCollapsedList();
      // this.clearCollapsedGroups();
      this.changeGroupAndSort();
      this.toggleColumns(this.groupMode, this.currNav);
      this.changeNavFilter();
    },
    isInboxViewEnabled() {
      this.setGroupCollapsedList();
      // this.clearCollapsedGroups();
      this.changeGroupAndSort();
      this.changeNavFilter();
    },
    selectedDates(newDates, oldDates) {
      if (!isEqual(newDates, oldDates)) {
        this.changeGroupAndSort();
        this.updateFilters();
      }
    },
    selectedOptsList(newList, oldList) {
      if (!isEqual(newList, oldList)) {
        this.setGroupCollapsedList();
        this.toggleNextActionsGroups(newList);
        this.updateFilters();
      }
    },
    searchQuery() {
      this.setGroupCollapsedList();
      this.changeGroupAndSort();
      this.handleSearchQueryChange();
    },
    showClearedTasks() {
      this.updateFilters();
    },
    filters: {
      handler(newFilters, oldFilters) {
        if (!isEqual(newFilters, oldFilters)) {
          this.setGroupCollapsedList();
          this.changeGroupAndSort();
          this.updateFilters();
        }
      },
      deep: true,
    },
    createdFilters: {
      handler(newCreatedFilters, oldCreatedFilters) {
        if (!isEqual(newCreatedFilters, oldCreatedFilters)) {
          this.setUpdatedFiltersInGrid();
        }
      },
      deep: true,
    },
    contingentFilter: {
      handler() {
        this.updateFilters();
      },
    },
    showAllOrderedTasks: {
      async handler() {
        await Vue.nextTick();
        this.updateFilters();
      },
    },
  },
};
</script>
<style lang="scss">
.no-tasks-text {
  font-size: 16px;
  margin-top: 5px;
  // margin-bottom: 5px;
}
.today-tasks-view .is-contingent:not(.row-editing) * {
  color: #878787;
}

.today-tasks-view .is-contingent .row-handler-cell * {
  color: #000;
}
.datagrid-row[role="group"] {
  min-height: 36px;
  line-height: 36px;
  &:hover {
    background: white !important;
  }
}

.datagrid-row[role="empty-row"] {
  min-height: 36px;
  line-height: 36px;
  &:hover {
    background: white !important;
  }
  & .empty-row-text {
    margin-left: 16px !important;
  }
}

.row-drop {
  border: 1px solid #eee;
}

.row-drop-line {
  height: 1px;
  width: 100%;
  background: #212121;
  display: none;
}

.row-drop-line-visible {
  display: block;
}
.datagrid-row[role="row"] {
  cursor: pointer;
  min-height: 34px;
  line-height: 34px;
  transition: transform 0.3s;
  &:hover {
    // background: #eee !important;
    // .edit-task-btn {
    //   display: inline-block;
    // }

    // .contingent-toggle-btn {
    //   display: inline-block;
    // }

    // .datagrid-cell {
    //   background: none !important;
    // }

    // .datagrid-group {
    //   background: none !important;
    // }
    // .intend-cell {
    //   background: #fff !important;
    // }
    // .priority-cell {
    //   width: 70px !important;
    // }
  }
  &.row-selected {
    // background: #e0e0e0 !important;

    .datagrid-cell {
      background: none;
    }
    .intend-cell {
      background: #fff;
    }
  }

  & {
    .intend-cell {
      border-width: 1px;
      border-color: #fff !important;
    }
  }
}

.datagrid-container .datagrid-row[role="row"].row-selected:not(.row-editing) {
  // .datagrid-cell {
  //   background: none;
  // }
  // .intend-cell {
  //   background: #fff;
  // }
  .datagrid-cell:not(.intend-cell):not(.row-handler-cell) {
    background: var(--selection-color) !important;
    // background: none;
    // border-top: 1px solid var(--primary-color) !important;
    // border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  // .row-handler-cell {
  //   // border-left: 1px solid var(--primary-color) !important;
  // }
}

.tasks-table .edit-btn-always-visible .v-icon {
  color: rgb(81 81 81 / 60%) !important;
}

.tasks-table .datagrid-row[role="row"]:hover:not(.row-editing) {
  // background: none !important;
  .edit-item-btn {
    display: inline-block;
  }

  .edit-btn-always-visible .v-icon {
    color: #515151 !important;
  }

  .contingent-toggle-btn {
    display: inline-block;
  }

  // .datagrid-cell {
  //   background: none !important;
  // }

  // .datagrid-group {
  //   background: none !important;
  // }
  .intend-cell {
    background: #fff !important;
  }
  .datagrid-cell:not(.intend-cell) {
    // background: none !important;
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  .row-handler-cell {
    border-left: 1px solid var(--primary-color) !important;
  }
  // .priority-cell {
  //   width: 70px !important;
  // }
}

.datagrid-row[role="row"].is-reminder-task:not(.row-editing) {
  font-style: italic !important;
}
.datagrid-row[role="row"] .datagrid-cell {
  // border: 0;
  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

.datagrid-row[role="row"] .priority-cell {
  padding: 0px !important;
}

.datagrid-row[role="row"] .project-cell > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.datagrid-row[role="row"]
  .datagrid-cell:not(.title-cell):not(.project-cell)
  > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.datagrid-row[role="row"] .row-handler-cell {
  background: none;
  cursor: move;
  padding: 0px 5px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  position: relative;
}

// .datagrid-row[role="row"] .taskType-cell {
//   // background: #;
//   // cursor: move;
//   // padding: 0 10px 0;
// }
.datagrid-row[role="row"] .intend-cell {
  background: #fff;
}

.datagrid-row[role="row"] .handler-icon-container {
  // display: inline-flex;
  // width: 100%;
  // height: 100%;
  // align-items: center;
  // justify-content: center;
}
// .datagrid-cell.title-cell > div {
//   line-height: 1.55 !important;
//   // padding-top: 7px;
//   // padding-bottom: 7px;
//   // white-space: pre-wrap;
//   overflow: hidden;
//   height: 33px;
//   // height: 100%;
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
//   // margin-top: 7px;
//   // margin-bottom: 7px;
// }
// .datagrid-row.row-selected .datagrid-cell.expand.title-cell > div {
//   line-height: 1.55 !important;
//   white-space: pre-line !important;
//   max-height: max-content !important;
//   height: auto !important;
//   display: block;
//   // padding-top: 0px !important;
//   // padding-bottom: 0px !important;
//   // padding-top: 7px !important;
//   // padding-bottom: 7px !important;
// }

.datagrid-row.datagrid-list-item.editing
// .datagrid-row.datagrid-list-item.row-add
  > .datagrid-cell.dueAsDate-cell
  > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.datagrid-row.datagrid-list-item.editing
// .datagrid-row.datagrid-list-item.row-add
  > .datagrid-cell.dueAsDate-cell
  .edit-date-wrapper {
  width: 185px !important;
}

.datagrid-row.datagrid-list-item.row-add
// .datagrid-row.datagrid-list-item.row-add
  > .datagrid-cell.dueAsDate-cell
  > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}
.datagrid-row.datagrid-list-item.row-add
// .datagrid-row.datagrid-list-item.row-add
  > .datagrid-cell.dueAsDate-cell
  .edit-date-wrapper {
  width: 185px !important;
}
.datagrid-row.datagrid-list-item.row-add,
.datagrid-row.datagrid-list-item.editing {
  height: auto !important;
}

// .datagrid-row.row-selected .datagrid-cell.expand.title-cell > div {
//   line-height: 1.55 !important;
//   white-space: pre-line !important;
//   max-height: max-content !important;
//   height: auto !important;
//   display: block;
//   // padding-top: 0px !important;
//   // padding-bottom: 0px !important;
//   // padding-top: 7px !important;
//   // padding-bottom: 7px !important;
// }

.datagrid-row.datagrid-list-item.row-add
  > .datagrid-cell.expand.title-cell
  > div,
.datagrid-row.datagrid-list-item.editing
  > .datagrid-cell.expand.title-cell
  > div {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: block;
  height: auto !important;
  // margin-top: 0px !important;
  // margin-bottom: 0px !important;
}

// .title-cell.expand > div {
//   line-height: 1.55 !important;
// }

.datagrid-header {
  background: #fff;
  border-bottom: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 20px;

  .datagrid-cell {
    background: #fff;
    border-color: rgb(224, 224, 224);

    &:hover {
      background: #fff;
    }

    &.datagrid-sortable.datagrid-header-cell {
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
    }

    &.row-handler-cell,
    &.intend-cell {
      border: 0;
    }
    &.pausedReminderDays-cell {
      border: 0;
    }

    &.sortable-header {
      cursor: pointer;
    }

    .e-sortfilterdiv {
      height: 10px;
      padding: 3px;
      width: 10px;
      font-size: 10px;
      color: #212121;
    }
  }
}

.datagrid-list-cell.flag-cell > div {
  height: 100%;
}

.datagrid-list-cell.flag-cell + .taskType-cell {
  padding: 0px !important;
  & .task-type-wrapper {
    margin-left: -5px;
  }
}

.datagrid-header-cell.flag-cell {
  border: 0;
  & + .taskType-cell {
    border-left: 1px solid #e0e0e0;
  }
}

.datagrid-row.editing .datagrid-cell,
.datagrid-row.editing:hover .datagrid-cell {
  background: #fff !important;
}
.datagrid-container .vue-recycle-scroller__item-view {
  // transition: transform 0.3s;
}

.datagrid-row:not(.row-editing) .title-cell .ql-editor {
  caret-color: transparent !important;
}

.opacity-5 {
  opacity: 0.5;
}

.hint-to-filters {
  margin-top: 0px;
  position: absolute !important;
  top: 40px;
  margin-left: 88px;
}
.illustration-search {
  margin-top: 90px;
}

.tasks-table .datagrid-row[role="row"] .datagrid-cell:not(.row-handler-cell) {
  background: #fff;
}

// .tasks-table .datagrid-row[role="row"] .datagrid-cell.intend-cell {
//   padding-left: 2px;
// }
.tasks-table
  .datagrid-row.is-task-older[role="row"]:not(.row-editing)
  .datagrid-cell:not(.intend-cell) {
  background: #d7d6d6;
}

.tasks-table .datagrid-row[role="row"] .row-handler-cell {
  background: #fff;
}

.tasks-table
  .datagrid-row[role="row"].row-selected:not(.row-editing)
  .datagrid-cell.row-handler-cell {
  background: var(--selection-color) !important;
}

.history-table
  .datagrid-row.is-task-older[role="row"]:not(.row-editing)
  .datagrid-cell:not(.intend-cell) {
  background: #d7d6d6 !important;
}

.selection-tip-label {
  // background-color: rgb(255 209 148 / 80%);
  // color: rgba(64, 58, 49, 0.8);
  text-transform: uppercase;
  text-align: center;
  // border: 1px solid red;
  font-size: 12px;
  font-weight: 700;
  // line-height: 15px;

  // width: 32px;
  // padding-top: 2px;

  // padding-left: 6px;
  // padding-right: 6px;
  // padding-bottom: 2px;
  border-radius: 4px;
}

#data-table-wrapper
  .datagrid-row[role="row"].row-active:not(.row-editing):not(.upcoming-task) {
  .datagrid-cell:not(.intend-cell) {
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }
  .datagrid-cell.row-handler-cell {
    border-left: 1px solid var(--primary-color) !important;
  }
}

#data-table-wrapper
  .datagrid-row[role="row"].row-active.upcoming-task:not(.row-editing) {
  .datagrid-cell:not(.intend-cell) {
    border-top: 1px solid var(--primary-color) !important;
  }
}
</style>
