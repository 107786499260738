import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";

export default class GroupedPandas {
  constructor(group, groupCol) {
    this.__groups__ = Object.keys(group);
    this.__sortedGroups__ = Object.keys(group);
    this.__groupMap__ = group;
    this.__groupColProp = groupCol;
    this.__sortedGroupsMap = group;
  }

  toMap(type = "default") {
    let map = {};
    if (type === "default") {
      map = this.__groupMap__;
    } else if (type === "sortedGroup") {
      map = this.__sortedGroupsMap;
    }
    return map;
  }
  toCollection() {
    return this.__groups__.map((group) => ({
      group,
      children: this.__groupMap__[group],
    }));
  }

  toList() {
    return this.__groups__.reduce((acc, group) => {
      acc.push({
        group,
        key: group,
        data: [this.__groupMap__[group][0]],
        dataCount: this.__groupMap__[group]?.length || 0,
        groupProp: this.__groupColProp,
      });
      acc = acc.concat(this.__groupMap__[group]);
      return acc;
    }, []);
  }

  sort(condition) {
    this.__sortedGroups__ = this.__groups__.sort(condition);
    return this;
  }

  filter(condition) {
    this.__groups__ = this.__sortedGroups__.filter(condition);
    return this;
  }
}

export const groupBy = function (pandas, groupColData) {
  const grouped = {};
  const groupedKeysMap = {};
  let groupColKey = groupColData;
  let checkGroups = false;
  let groupKeysToCheck = [];
  let groupVisiblityCheckRequired = false;
  if (
    !isEmpty(groupColData) &&
    isPlainObject(groupColData) &&
    groupColData.key
  ) {
    groupColKey = groupColData.key;

    if (!isEmpty(groupColData.groupKeys)) {
      checkGroups = true;
      groupKeysToCheck = [...groupColData.groupKeys];
    }

    if (isEmpty(groupKeysToCheck) && groupColData.checkGroups) {
      checkGroups = true;
    }

    if (
      groupColData.checkForGroupVisiblity &&
      groupColData.groupVisiblityChecker
    ) {
      groupVisiblityCheckRequired = true;
    }
  }

  pandas.__rows__.forEach((row) => {
    let isGroupVisible = true;
    const groupVal = row[groupColKey];

    if (groupVisiblityCheckRequired) {
      isGroupVisible = groupColData.groupVisiblityChecker(groupVal);
    }

    if (isGroupVisible) {
      if (!grouped[row[groupColKey]]) {
        grouped[row[groupColKey]] = [];
        groupedKeysMap[row[groupColKey]] = [];
      }

      if (!groupedKeysMap[row[groupColKey]].includes(row.key)) {
        groupedKeysMap[row[groupColKey]].push(row.key);
        grouped[row[groupColKey]].push(row);
      }
    }
  });

  if (checkGroups) {
    groupKeysToCheck = !isEmpty(groupKeysToCheck)
      ? groupKeysToCheck
      : Object.keys(grouped);

    groupKeysToCheck.forEach((groupKey) => {
      if (
        isEmpty(grouped[groupKey]) &&
        groupColData.isEmptyRowAllowed(groupKey)
      ) {
        let isGroupVisible = true;

        if (groupVisiblityCheckRequired) {
          isGroupVisible = groupColData.groupVisiblityChecker(groupKey);
        }

        if (isGroupVisible) {
          grouped[groupKey] = [{ ...groupColData.emptyRowCreator(groupKey) }];
        }
      }
    });
  }
  return new GroupedPandas(grouped, groupColData);
};
