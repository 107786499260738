export default {
  openDialog(context, payload) {
    const { taskData } = payload;
    context.commit("setData", {
      taskData,
      isDialogOpen: true,
    });
  },
  closeDialog(context) {
    context.commit("setData", {
      taskData: {},
      isDialogOpen: false,
    });
  },
  toggleDeleteRecurringConfirmDialog(context, payload) {
    context.commit("setData", {
      isDialogOpen: payload,
    });
  },
};
