import { cloneDeep, isEmpty } from "lodash";

export default {
  showEditTaskDialog(context, payload) {
    context.commit("showDialog", payload);
  },
  showNewEditTaskDialog(context, payload) {
    context.commit("showNewDialog", payload);
  },
  closeEditTaskDialog(context) {
    context.commit("resetDialog");
  },
  setTaskData(context, payload) {
    context.commit("setTaskData", payload);
  },
  updateCurrTaskData(context, payload) {
    context.commit("updateTaskData", payload);
  },
  openEditTaskConfirmBox(context, payload) {
    context.commit("openEditConfirmBox", payload);
  },
  closeEditTaskConfirmBox(context) {
    context.commit("closeEditTaskConfirmBox");
  },
  closeEditForm(context) {
    context.commit("resetDialog");
  },
  setDataForNoteEdit(context, payload) {
    const currState = cloneDeep(context.state);
    if (!isEmpty(payload)) {
      const { dataToSet } = payload;
      currState.noteData = { ...dataToSet };
      currState.isOpen = true;
    }

    context.commit("setData", currState);
  },
  setNoteData(context, payload) {
    const currState = cloneDeep(context.state);
    if (!isEmpty(payload)) {
      currState.noteData = cloneDeep(payload);
    }

    context.commit("setData", currState);
  },
  setDataForResourceEdit(context, payload) {
    const currState = cloneDeep(context.state);
    if (!isEmpty(payload)) {
      const { dataToSet } = payload;
      currState.resourceData = { ...dataToSet };
      currState.isOpen = true;
    }

    context.commit("setData", currState);
  },
};
