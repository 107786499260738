<template>
  <span class="item-count-wrapper" @mousedown="handleMouseDown">
    <span class="edit-btn-wrapper">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :attrs="attrs"
            v-on="on"
            small
            tabindex="-1"
            class="edit-item-btn"
            @click="openContextMenu"
            ref="editBtn"
          >
            <v-icon size="16" color="black">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
    </span>
    <span class="item-count"> {{ itemCount }} </span>
  </span>
</template>
<script>
import EventEmitter from "@/helpers/eventEmitter";
import { CONTEXT_MENU_OPENED } from "@/variables/events";
export default {
  props: {
    itemCount: {
      type: Number,
    },
    menuId: {
      type: String,
      required: true,
    },
    preventEvent: {
      type: Boolean,
    },
    nodeId: {
      type: String,
    },
  },
  methods: {
    openContextMenu(e) {
      e.stopPropagation();
      e.preventDefault();
      const element = this.$refs.editBtn.$el;

      let contextEl;

      const positions = element.getBoundingClientRect();

      const contextMenuWrappers = document.querySelectorAll(
        ".e-contextmenu-wrapper"
      );

      for (const menuWrapper of contextMenuWrappers) {
        const childContextEl = menuWrapper.childNodes[0];
        if (childContextEl?.id && childContextEl?.id === this.menuId) {
          contextEl = childContextEl;
          break;
        }
      }

      if (contextEl) {
        const contextInstance = contextEl?.ej2_instances[0];
        if (contextInstance) {
          contextInstance.open(positions.top, positions.left, element);
          EventEmitter.emit(CONTEXT_MENU_OPENED, this.nodeId);
        }
      }
    },
    handleMouseDown(e) {
      if (this.preventEvent) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.item-count-wrapper {
  display: inline-flex;
  align-items: center;
}

.item-count {
  margin-left: 5px;
}
.edit-btn-wrapper {
  display: none;
  height: 18px;
  z-index: 10;
  /* margin-right: 5px; */
  position: relative;
}
.edit-item-btn {
  height: 100% !important;
  width: 100% !important;
  position: relative;
  z-index: 111;
}
</style>
