<template>
  <div class="resource-mention-info-wrapper">
    <div class="resource-mention-name-info">
      <div class="resource-type-wrapper">
        <v-icon color="#fff" size="18">{{ resourceIcon }}</v-icon>
      </div>
      <div class="resource-name-wrapper">
        <RichTextEditor
          ref="titleEditor"
          isReadOnly
          :showToolbar="false"
          :value="resourceData.title"
          noBorders
          noPadding
        />
      </div>
    </div>
    <div class="resource-descr-wrapper">
      <RichTextEditor
        ref="textEditor"
        isReadOnly
        :showToolbar="false"
        noBorders
        :value="resourceData.descr"
        noPadding
        v-if="resourceData.descr"
      />
      <p v-else class="mb-0">
        Click to add or edit information
      </p>
    </div>
  </div>
</template>
<script>
import RichTextEditor from "@/components/RichTextEditor";
import { resourceTypesMap } from "@/data/resources";
export default {
  props: {
    resourceData: {
      type: Object,
    },
    isVisible: {
      type: Boolean,
    },
  },
  mounted() {
    // this.setData();
  },
  components: {
    RichTextEditor,
  },
  computed: {
    resourceIcon() {
      return resourceTypesMap[this.resourceData.type]?.icon || "";
    },
  },
  methods: {
    async setData() {
      await this.$nextTick();
      this.$refs.titleEditor.setHTMLData(this.resourceData.title, true);
      if (this.$refs.textEditor) {
        this.$refs.textEditor.setHTMLData(this.resourceData.descr, true);
      }
    },
  },
  watch: {
    // isVisible: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.setData();
    //     }
    //   },
    //   immediate: true,
    // },
    // resourceData: {
    //   handler(newVal, oldVal) {
    //     console.debug("EEE");
    //     if (!isEqual(newVal, oldVal)) {
    //       // this.setData();
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>
<style scoped>
.resource-mention-info-wrapper {
  /* height: 100%; */
  /* padding: 5px 10px 5px 10px; */
}
.resource-mention-name-info {
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 10px 16px 6px;
}
.resource-info-pop-up .resource-mention-name-info * {
  color: white !important;
}

.resource-descr-wrapper {
  background-color: #FCFCFC;
  /* margin-right: -11px;
  margin-left: -11px;
  margin-bottom: -9px; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 16px;
  max-height: 40vh;
  overflow: auto;
}

.resource-info-pop-up .resource-descr-wrapper * {
  color: black !important;
}

.resource-descr-wrapper >>> .ql-editor,
.resource-name-wrapper >>> .ql-editor {
  min-height: auto;
  height: auto;
  cursor: pointer;
}

.resource-name-wrapper >>> .ql-editor {
  font-weight: bold;
  font-size: 13px;
}

.resource-descr-wrapper >>> .ql-editor > p {
  cursor: pointer;
  font-size: 13px;
}
.resource-descr-wrapper {
  margin-top: 8px;
}
.no-descr-text {
  font-size: 13px;
}
</style>
