import { isCatChildOrGrandChild } from "@/helpers/categories";
import { cloneDeep, isEmpty } from "lodash";
import store from "@/store";
import { isTaskRecurring } from "@/helpers/tasks";
export function createTasksMap(tasks) {
  return tasks.reduce((a, t) => {
    if (t.key) {
      a[t.key] = t;
    }
    return a;
  }, {});
}

export const createProjectMapData = ({
  projectData,
  projectTasksMap,
  isInspectModeEnabled,
  selectedCategories,
  selectedNodes,
}) => {
  const projectKey = projectData.key;
  const tasksCount = projectTasksMap[projectKey]
    ? projectTasksMap[projectKey].incomplete.length
    : 0; // Gets the length of stored tasks by project key
  const allTasksCount = projectTasksMap[projectKey]
    ? projectTasksMap[projectKey].all.length
    : 0;
  projectData.tasksCount = tasksCount;
  projectData.allTasksCount = allTasksCount;
  if (projectKey != "open") {
    // Creates property required for attributes in TreeView
    projectData["hasAttribute"] = {
      class: `${projectData.type} tree-node`,
      "data-action-type": "project-change",
      "data-action-value": projectKey,
      "data-prevent-close": "1",
    };
  }

  // // expand the tree or close it

  if (isInspectModeEnabled) {
    if (selectedCategories.includes(projectKey)) {
      projectData.open = true;
    } else {
      projectData.open = false;
    }
  }

  projectData.isSelected = selectedNodes.includes(projectKey);

  return projectData;
};
export function getTreeFields(options) {
  const {
    categories,
    projects,
    // tasksMap,
    selectedNodes,
    isInspectModeEnabled,
    selectedCategories,
    // expandedCategories = [],
  } = options;
  const processedTasks = store.getters["task/tasks"] || [];
  let tmpCategoriesB = cloneDeep(categories);
  let tmpProjectsB = cloneDeep(projects);
  let visibleProjectsMap = {};
  let visibleChildrenMap = {};
  let visibleCategoriesMap = {};
  let processedCategories = [];
  let processedVisibleCategories = [];
  // Tasks map received as prop
  // Store category wise projecs
  let childrenArrays = {};
  // Store project wise tasks;
  let projectTasksMap = {};
  let projectTaskListMap = {};
  let recurringTasksList = [];

  processedTasks.forEach((pT) => {
    const isRecurringTask = isTaskRecurring(pT);

    if (isRecurringTask && !pT.isReminderTask) {
      const isVirtualTask = pT.isVirtual;
      let countTask = false;
      let addToRecurringList = false;
      if (isVirtualTask) {
        if (!recurringTasksList.includes(pT.linkedTo)) {
          countTask = true;
          addToRecurringList = true;
        }
      } else {
        countTask = true;
      }

      if (countTask) {
        if (tmpProjectsB[pT.project]) {
          // Checks if project key doesn't exists in projectTaskMap
          // then assigned an empty array of tasks
          if (!projectTasksMap[pT.project]) {
            projectTasksMap[pT.project] = {
              incomplete: [],
              all: [],
            };
          }

          // Pushes task to project key array if task is not completed

          projectTasksMap[pT.project].all.push(pT);
          if (!pT.completed) {
            projectTasksMap[pT.project].incomplete.push(pT);
          }
        }
      }

      if (addToRecurringList) {
        recurringTasksList.push(pT.linkedTo);
      }

      // Recurring logic
    } else {
      if (!pT.isReminderTask && tmpProjectsB[pT.project]) {
        // Checks if project key doesn't exists in projectTaskMap
        // then assigned an empty array of tasks
        if (!projectTasksMap[pT.project]) {
          projectTasksMap[pT.project] = {
            incomplete: [],
            all: [],
          };
        }
        projectTasksMap[pT.project].all.push(pT);
        // Pushes task to project key array if task is not completed
        if (!pT.completed) {
          projectTasksMap[pT.project].incomplete.push(pT);
        }
      }
    }
  });

  // Groups the projects sub categories
  for (let projectName in tmpProjectsB) {
    const project = tmpProjectsB[projectName]; // Project object

    if (project.deleted) continue;
    project.projects = [];
    if (!project.hidden && !visibleProjectsMap[projectName]) {
      visibleProjectsMap[projectName] = project;
    }

    const tasksCount = projectTasksMap[projectName]
      ? projectTasksMap[projectName].incomplete.length
      : 0; // Gets the length of stored tasks by project key
    const allTasksCount = projectTasksMap[projectName]
      ? projectTasksMap[projectName].all.length
      : 0;
    project.tasksCount = tasksCount;
    project.allTasksCount = allTasksCount;

    const allTasksOfProject = projectTasksMap[projectName]?.all || [];
    const incompleteTasksOfProject =
      projectTasksMap[projectName]?.incomplete || [];
    projectTaskListMap[projectName] = {
      all: allTasksOfProject,
      incomplete: incompleteTasksOfProject,
    };
    if (projectName != "open") {
      // Creates property required for attributes in TreeView
      project["hasAttribute"] = {
        class: `${project.type} tree-node`,
        "data-action-type": "project-change",
        "data-action-value": projectName,
        "data-prevent-close": "1",
        // "data-ignore": "1",
      };

      if (project.type === "subcategory") {
        project["hasAttribute"]["data-action-type"] = "project-category-change";

        // "data-ignore": "1",
      }
    }

    // // expand the tree or close it

    if (isInspectModeEnabled) {
      if (selectedCategories.includes(projectName)) {
        project.open = true;
      } else {
        project.open = false;
      }
    }

    project.isSelected = selectedNodes.includes(projectName);

    let childKey = null;
    let childType = "";
    let storeInVisileMap = false;
    if (project.subcategory) {
      childKey = project.subcategory;
      childType = "sub-category";

      if (projects[childKey] && !projects[childKey].hidden) {
        storeInVisileMap = true;
      }
    } else {
      childKey = project.category;
      childType = "category";

      if (categories[childKey] && !categories[childKey].hidden) {
        storeInVisileMap = true;
      }
    }

    if (storeInVisileMap) {
      if (!visibleChildrenMap[childKey]) {
        visibleChildrenMap[childKey] = {
          list: [],
          tasksCount: 0,
          allTasksCount: 0,
          type: childType,
          key: childKey,
          taskListMap: {
            all: [],
            incomplete: [],
          },
        };
      }

      if (!project.hidden) {
        visibleChildrenMap[childKey].tasksCount += tasksCount;
        visibleChildrenMap[childKey].allTasksCount += allTasksCount;
        visibleChildrenMap[childKey].list.push(project);
        visibleChildrenMap[childKey].taskListMap.all.push(...allTasksOfProject);
        visibleChildrenMap[childKey].taskListMap.incomplete.push(
          ...incompleteTasksOfProject
        );
      }
    }

    if (!childrenArrays[childKey]) {
      childrenArrays[childKey] = {
        list: [],
        tasksCount: 0,
        allTasksCount: 0,
        type: childType,
        key: childKey,
        taskListMap: {
          all: [],
          incomplete: [],
        },
      };
    }
    childrenArrays[childKey].tasksCount += tasksCount;
    childrenArrays[childKey].allTasksCount += allTasksCount;
    childrenArrays[childKey].list.push(project);
    childrenArrays[childKey].taskListMap.all.push(...allTasksOfProject);
    childrenArrays[childKey].taskListMap.incomplete.push(
      ...incompleteTasksOfProject
    );

    // Map which are not hddien
    if (project.type === "project") {
      addTaskCountInParentSubCategory(
        childKey,
        tmpProjectsB,
        childrenArrays,
        tasksCount,
        allTasksCount,
        visibleChildrenMap,
        undefined,
        projectTaskListMap[projectName]
        // expandedCategories
      );
    }
  }

  // Sorts projects
  for (let key in childrenArrays) {
    childrenArrays[key].list.sort(function (a, b) {
      return a.order - b.order;
    });
  }

  for (let key in visibleChildrenMap) {
    visibleChildrenMap[key].list.sort(function (a, b) {
      return a.order - b.order;
    });
  }
  // Map projects and tasksCount for sub categories
  Object.entries(childrenArrays).forEach(([key, value]) => {
    if (value.type === "sub-category" && tmpProjectsB[key]) {
      tmpProjectsB[key].projects = value.list;
      tmpProjectsB[key].tasksCount = value.tasksCount;
      tmpProjectsB[key].allTasksCount = value.allTasksCount;
      tmpProjectsB[key].taskListMap = Object.freeze(value.taskListMap);
    }
  });

  Object.entries(visibleChildrenMap).forEach(([key, value]) => {
    if (value.type === "sub-category" && visibleProjectsMap[key]) {
      visibleProjectsMap[key].projects = value.list;
      visibleProjectsMap[key].tasksCount = value.tasksCount;
      visibleProjectsMap[key].allTasksCount = value.allTasksCount;
      visibleProjectsMap[key].taskListMap = Object.freeze(value.taskListMap);
    }
  });

  // Loops over categories to properly map projects and tasks count;
  for (let category in tmpCategoriesB) {
    const categoryData = tmpCategoriesB[category];

    if (categoryData.deleted) continue;
    let childCategory = childrenArrays[category] || {
      list: [],
      tasksCount: 0,
      allTasksCount: 0,
      taskListMap: {
        all: [],
        incomplete: [],
      },
    };

    const visibleChildCategory = visibleChildrenMap[category] || {
      list: [],
      tasksCount: 0,
      allTasksCount: 0,
      taskListMap: {
        all: [],
        incomplete: [],
      },
    };

    if (category === "inboxCat") continue;
    if (category != "open") {
      const isCategoryHidden = tmpCategoriesB[category].hidden;
      tmpCategoriesB[category].projects = childCategory.list;
      tmpCategoriesB[category].tasksCount = childCategory.tasksCount;
      tmpCategoriesB[category].allTasksCount = childCategory.allTasksCount;
      tmpCategoriesB[category].taskListMap = Object.freeze(
        childCategory.taskListMap
      );
      if (!isCategoryHidden) {
        if (!visibleCategoriesMap[category]) {
          visibleCategoriesMap[category] = cloneDeep(categories[category]);
        }

        visibleCategoriesMap[category].projects = visibleChildCategory.list;
        visibleCategoriesMap[category].tasksCount =
          visibleChildCategory.tasksCount;
        visibleCategoriesMap[category].allTasksCount =
          visibleChildCategory.allTasksCount;
        visibleCategoriesMap[category].taskListMap = Object.freeze(
          visibleChildCategory.taskListMap
        );

        if (
          visibleCategoriesMap[category].children &&
          visibleCategoriesMap[category].children.length
        ) {
          const subCategories = visibleCategoriesMap[category].children;
          subCategories.forEach((subCat) => {
            if (visibleChildrenMap[subCat]) {
              visibleCategoriesMap[category].tasksCount +=
                visibleChildrenMap[subCat].tasksCount;
              visibleCategoriesMap[category].allTasksCount +=
                visibleChildrenMap[subCat].allTasksCount;
              if (!isEmpty(visibleCategoriesMap[category].taskListMap?.all)) {
                visibleCategoriesMap[category].taskListMap?.all.push(
                  ...(visibleChildrenMap[subCat].taskListMap?.all || [])
                );
              } else {
                visibleCategoriesMap[category].taskListMap = Object.freeze({
                  ...visibleCategoriesMap[category].taskListMap,
                  all: visibleChildrenMap[subCat].taskListMap?.all || [],
                });
                // visibleCategoriesMap[category].taskListMap.all =
                //   visibleChildrenMap[subCat].taskListMap?.all || [];
              }

              if (
                !isEmpty(visibleCategoriesMap[category].taskListMap?.incomplete)
              ) {
                visibleCategoriesMap[category].taskListMap?.incomplete.push(
                  ...(visibleChildrenMap[subCat].taskListMap?.incomplete || [])
                );
              } else {
                visibleCategoriesMap[category].taskListMap = Object.freeze({
                  ...visibleCategoriesMap[category].taskListMap,
                  incomplete:
                    visibleChildrenMap[subCat].taskListMap?.incomplete || [],
                });

                // .incomplete =
                //   visibleChildrenMap[subCat].taskListMap?.incomplete || [];
              }
            }
          });
        }
        // Attribute required for tree node
        visibleCategoriesMap[category].hasAttribute = {
          class: `${visibleCategoriesMap[category].type} tree-node`,
          "data-action-type": "project-category-change",
          "data-action-value": category,
          "data-prevent-close": "1",
          "data-continue-process": "1",
          "data-close-form-only": "1",
        };

        if (isInspectModeEnabled) {
          if (selectedCategories.includes(category)) {
            visibleCategoriesMap[category].open = true;
          } else {
            visibleCategoriesMap[category].open = false;
          }
        }

        visibleCategoriesMap[category].isSelected =
          selectedNodes.includes(category);
        processedVisibleCategories.push(visibleCategoriesMap[category]);
      }

      // Adds tasks count of sub categories to the parent category
      if (
        tmpCategoriesB[category].children &&
        tmpCategoriesB[category].children.length
      ) {
        const subCategories = tmpCategoriesB[category].children;
        subCategories.forEach((subCat) => {
          if (childrenArrays[subCat]) {
            tmpCategoriesB[category].tasksCount +=
              childrenArrays[subCat].tasksCount;
            tmpCategoriesB[category].allTasksCount +=
              childrenArrays[subCat].allTasksCount;
            if (!isEmpty(tmpCategoriesB[category].taskListMap?.all)) {
              tmpCategoriesB[category].taskListMap?.all.push(
                ...(childrenArrays[subCat].taskListMap?.all || [])
              );
            } else {
              tmpCategoriesB[category].taskListMap = Object.freeze({
                ...tmpCategoriesB[category].taskListMap,
                all: childrenArrays[subCat].taskListMap?.all || [],
              });

              // .all =
              //   childrenArrays[subCat].taskListMap?.all || [];
            }

            if (
              !isEmpty(visibleCategoriesMap[category].taskListMap?.incomplete)
            ) {
              tmpCategoriesB[category].taskListMap?.incomplete.push(
                ...(childrenArrays[subCat].taskListMap?.incomplete || [])
              );
            } else {
              tmpCategoriesB[category].taskListMap = Object.freeze({
                ...tmpCategoriesB[category].taskListMap,
                incomplete:
                  childrenArrays[subCat].taskListMap?.incomplete || [],
              });

              // .incomplete =
              //   childrenArrays[subCat].taskListMap?.incomplete || [];
            }
          }
        });
      }
      // Attribute required for tree node
      tmpCategoriesB[category].hasAttribute = {
        class: `${tmpCategoriesB[category].type} tree-node`,
        "data-action-type": "project-category-change",
        "data-action-value": category,
        "data-prevent-close": "1",
        "data-continue-process": "1",
        "data-close-form-only": "1",
      };
    }

    if (isInspectModeEnabled) {
      if (selectedCategories.includes(category)) {
        tmpCategoriesB[category].open = true;
      } else {
        tmpCategoriesB[category].open = false;
      }
    }

    // else {
    //   tmpCategoriesB[category].open = expandedCategories.includes(category);
    // }
    // if (isInspectModeEnabled && selectedNodes.includes(category)) {
    //   tmpCategoriesB[category].open = true;
    // } else if (isInspectModeEnabled && !selectedNodes.includes(category)) {
    //   tmpCategoriesB[category].open = false;
    // }

    tmpCategoriesB[category].isSelected = selectedNodes.includes(category);
    processedCategories.push(tmpCategoriesB[category]);
  }

  const rtn = processedCategories.sort((a, b) => {
    return parseInt(a.order) - parseInt(b.order);
  });

  const finalProcessedVisibleList = processedVisibleCategories.sort((a, b) => {
    return parseInt(a.order) - parseInt(b.order);
  });

  // Sort categories
  // const rtn = processedCategories.sort(function (a, b) {
  //   return a.order - b.order;
  // });

  // rtn.pop(); // Removes inbox category;
  // returns the fields data required for tree view

  // console.log("RNT", rtn);
  // console.log("VISIBLE", finalProcessedVisibleList);
  return {
    dataSource: rtn,
    visbleDataSource: finalProcessedVisibleList,
    id: "key",
    text: "title",
    child: "projects",
    htmlAttributes: "hasAttribute",
    expanded: "open",
    selected: "isSelected",
  };
}

export function addTaskCountInParentSubCategory(
  key,
  projects,
  treelist,
  tasksCount,
  allTasksCount,
  visibleTreeMap,
  expandedCategories = [],
  projectTaskListMap
) {
  const project = projects[key];
  if (!project) return;
  let parentKey = project?.subcategory;
  const parent = projects[parentKey];

  if (!parent || parent.deleted) return;

  let parentType = "";
  if (!treelist[parentKey]) {
    treelist[parentKey] = {
      list: [],
      tasksCount: 0,
      allTasksCount: 0,
      type: parentType,
      key: parentKey,
      taskListMap: {
        all: [],
        incomplete: [],
      },
    };
  }

  if (!project.hidden) {
    if (!visibleTreeMap[parentKey]) {
      visibleTreeMap[parentKey] = {
        list: [],
        tasksCount: 0,
        allTasksCount: 0,
        type: parentType,
        key: parentKey,
        taskListMap: {
          all: [],
          incomplete: [],
        },
      };
    }

    visibleTreeMap[parentKey].tasksCount += tasksCount;
    visibleTreeMap[parentKey].allTasksCount += allTasksCount;
    visibleTreeMap[parentKey].taskListMap.all.push(...projectTaskListMap.all);
    visibleTreeMap[parentKey].taskListMap.incomplete.push(
      ...projectTaskListMap.incomplete
    );
  }

  treelist[parentKey].tasksCount += tasksCount;
  treelist[parentKey].allTasksCount += allTasksCount;
  treelist[parentKey].taskListMap.all.push(...projectTaskListMap.all);
  treelist[parentKey].taskListMap.incomplete.push(
    ...projectTaskListMap.incomplete
  );
  // treelist[parentKey].open = expandedCategories.includes(parentKey)
  addTaskCountInParentSubCategory(
    parentKey,
    projects,
    treelist,
    tasksCount,
    allTasksCount,
    visibleTreeMap,
    expandedCategories,
    projectTaskListMap
  );
}

export function onDragItem(args) {
  if (
    args.draggedNode.classList.contains("subcategory") ||
    args.draggedNode.classList.contains("project")
  ) {
    onDragProjectAndSubcategory(args);
  } else if (args.draggedNode.classList.contains("category")) {
    if (canMoveCategory(args)) {
      args.dropIndicator = "e-drop-next";
    } else {
      args.dropIndicator = "e-no-drop";
    }
  } else {
    args.dropIndicator = "e-no-drop";
  }
}

function canMoveCategory(args) {
  /**
   * Can only drop and level 1
   * Can drop without any target
   * Can drop if not inside
   */
  return args.dropLevel == 1 && !args.dropTarget && args.position !== "Inside";
}

function onDragProjectAndSubcategory(args) {
  if (
    args.dropLevel > 1 &&
    args.droppedNode.classList.contains("project") &&
    args.position == "Inside"
  ) {
    args.dropIndicator = "e-no-drop";
  } else if (
    args.droppedNode != null &&
    args.dropLevel > 1 &&
    ((args.droppedNode.getElementsByClassName("category") &&
      args.droppedNode.getElementsByClassName("category").length === 0) ||
      (args.droppedNode.getElementsByClassName("subcategory") &&
        args.droppedNode.getElementsByClassName("subcategory").length === 0))
  ) {
    const targetId = args.droppedNode.getAttribute("data-uid");
    const draggedNodeId = args.draggedNodeData.id;
    if (isCatChildOrGrandChild(targetId, draggedNodeId)) {
      args.cancel = true;
      args.dropIndicator = "e-no-drop";
    } else {
      args.cancel = false;
      args.position = "After";
    }
  } else if (
    args.dropLevel > 1 &&
    args.droppedNode.classList.contains("project") &&
    args.position != "Inside"
  ) {
    args.dropIndicator = "e-drop-next";
  } else if (
    args.dropLevel == 1 &&
    args.droppedNode.classList.contains("category") &&
    args.position == "Inside"
  ) {
    args.dropIndicator = "e-drop-in";
  } else {
    args.dropIndicator = "e-no-drop";
  }
}

export function onDragStop(args) {
  //allow a category to be dropped at base level

  if (args.dropIndicator == "e-no-drop") {
    args.cancel = true;
  } else if (args.draggedNode.classList.contains("category")) {
    if (canMoveCategory(args)) {
      // args.position = "After";
      args.cancel = false;
    } else {
      args.cancel = true;
    }
  } else if (
    args.draggedNode.classList.contains("subcategory") ||
    args.draggedNode.classList.contains("project")
  ) {
    if (
      args.dropLevel > 1 &&
      args.droppedNode.classList.contains("project") &&
      args.position == "Inside"
    ) {
      args.cancel = true;
    } else if (
      args.droppedNode != null &&
      args.dropLevel > 1 &&
      ((args.droppedNode.getElementsByClassName("category") &&
        args.droppedNode.getElementsByClassName("category").length === 0) ||
        (args.droppedNode.getElementsByClassName("subcategory") &&
          args.droppedNode.getElementsByClassName("subcategory").length === 0))
    ) {
      const targetId = args.droppedNode.getAttribute("data-uid");
      const draggedNodeId = args.draggedNodeData.id;
      if (isCatChildOrGrandChild(targetId, draggedNodeId)) {
        args.cancel = true;
        args.dropIndicator = "e-no-drop";
      } else {
        args.cancel = false;
      }
    } else if (
      args.dropLevel > 1 &&
      args.droppedNode.classList.contains("project") &&
      args.position != "Inside"
    ) {
      args.cancel = false;
    } else if (
      args.dropLevel == 1 &&
      args.droppedNode.classList.contains("category") &&
      args.position == "Inside"
    ) {
      args.cancel = false;
    } else {
      args.cancel = true;
    }
  } else {
    args.cancel = true;
  }

  return args;
}
