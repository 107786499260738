<template>
  <CoreLoaderWrapper v-if="isLoaderEnabled" :loader-size="48">
    <template #default="{ size }">
      <v-progress-circular
        :size="size"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </template>
  </CoreLoaderWrapper>
</template>
<script>
import CoreLoaderWrapper from "@/core/components/CoreLoaderWrapper";
import { mapGetters } from "vuex";
export default {
  components: {
    CoreLoaderWrapper,
  },
  computed: {
    ...mapGetters(["showLoader"]),
    isLoaderEnabled() {
      return this.showLoader;
    },
  },
};
</script>
