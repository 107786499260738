export default {
  storeProjectsOpts(context, payload) {
    context.commit("storeOpts", {
      type: "projectOpts",
      list: payload,
    });
  },
  storeTaskTypesOpts(context, payload) {
    context.commit("storeOpts", {
      type: "taskTypeOpts",
      list: payload,
    });
  }
};
