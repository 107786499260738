import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import {
  updateLocalTasks,
  addTasksInLocalTasks,
  removeTasksFromLocalList,
} from "@/helpers/tasks";
import { isSameDay } from "date-fns";
export default {
  setList(state, payload) {
    state[payload.type] = payload.list;
  },
  setNav(state, payload) {
    state.nav = payload;
  },
  setGroup(state, payload) {
    state.groupMode = payload;
  },
  toggleInbox(state) {
    state.inboxViewEnabled = !state.inboxViewEnabled;
  },
  setListFilter(state, payload) {
    let currList = cloneDeep(state.filters);
    const key = payload.key;
    const filterKey = payload.filter;
    const overwrite = payload.overwrite;
    let list = currList[filterKey];

    if (!isEmpty(payload.list)) {
      if (!payload.overwrite) {
        payload.list.forEach((i) => {
          if (list.includes(i)) {
            list.splice(list.indexOf(i), 1);
          } else {
            list.push(i);
          }
        });
      } else {
        currList[filterKey] = payload.list;
      }
    } else {
      if (list.includes(key)) {
        list.splice(list.indexOf(key), 1);
      } else {
        if (overwrite) {
          list = [key];

          currList[filterKey] = list;
        } else {
          list.push(key);
        }
      }
    }

    state.filters = currList;
  },
  resetListFilters(state, payload) {
    if (!isEmpty(payload)) {
      state.filters[payload] = [];
    } else {
      state.filters = {
        priority: [],
        taskType: [],
        flag: [],
        category: [],
        type: [],
        resourceTypes: [],
      };
    }
  },
  updateState(state, payload) {
    Object.assign(state, payload);
  },
  updateTaskCount(state, payload) {
    state.tasksCount = payload;
  },
  setSelectedTasks(state, payload) {
    let selectedRows = [];
    if (!isEmpty(payload)) {
      selectedRows = cloneDeep(payload);
    }
    state.selectedTasks = selectedRows;
  },
  setSelectedDates(state, payload) {
    let selectedDates = [];
    if (isEmpty(payload)) {
      selectedDates = cloneDeep(payload);
    }
    state.selectedDates = selectedDates;
  },
  toggleClearedTasks(state) {
    state.showClearedTasks = !state.showClearedTasks;
  },
  setProjectsTree(state, payload) {
    state.projectsTree = payload;
  },
  setSelectedCatIds(state, payload) {
    state.selectedCatIds = payload;
  },
  addTask(state, payload) {
    if (!isEmpty(payload)) {
      const currTasks = [...state.tasks];
      // currTasks.push(payload);
      state.tasks = addTasksInLocalTasks(payload, currTasks);
    }
  },
  updateTasks(state, payload) {
    if (payload && payload.length) {
      const currTasks = [...state.tasks];
      // currTasks = ;
      // payload.forEach((p) => {
      //   const storedItemIndex = currTasks.findIndex((t) => t.key === p.key);
      //   if (storedItemIndex >= 0) {
      //     currTasks[storedItemIndex] = {
      //       ...currTasks[storedItemIndex],
      //       ...p.updates,
      //     };
      //   }
      // });
      state.tasks = updateLocalTasks(payload, currTasks);
    }
  },
  removeTasks(state, payload) {
    if (payload && payload.length) {
      const currTasks = [...state.tasks];
      payload.forEach((p) => {
        const storedTaskIndex = currTasks.findIndex((t) => t.key === p);
        if (storedTaskIndex >= 0) {
          currTasks.splice(storedTaskIndex, 1);
        }
      });

      state.tasks = currTasks;
    }
  },
  updateOrAddTasks(state, payload) {
    if (!isEmpty(payload)) {
      let currTasks = [...state.tasks];
      const { tasksToAdd, tasksToUpdate } = payload;
      if (tasksToAdd && tasksToAdd.length) {
        currTasks = addTasksInLocalTasks(tasksToAdd, currTasks);
      }
      if (tasksToUpdate && tasksToUpdate.length) {
        currTasks = updateLocalTasks(tasksToUpdate, currTasks);
      }

      state.tasks = currTasks;
    }
  },
  addOrUpdateOrRemoveTasks(state, payload) {
    if (!isEmpty(payload)) {
      let currTasks = [...state.tasks];

      const {
        tasksToAdd,
        tasksToUpdate,
        taskIdsToRemove,
        addMethod,
        verifyAdd,
      } = payload;
      if (tasksToUpdate && tasksToUpdate.length) {
        currTasks = updateLocalTasks(tasksToUpdate, currTasks);
      }

      if (taskIdsToRemove && taskIdsToRemove.length) {
        currTasks = removeTasksFromLocalList(taskIdsToRemove, currTasks);
      }

      if (tasksToAdd && tasksToAdd.length) {
        currTasks = addTasksInLocalTasks(
          tasksToAdd,
          currTasks,
          addMethod,
          verifyAdd
        );
      }

      state.tasks = currTasks;
    }
  },
  removeRecurringTasksFromMap(state, payload) {
    const { list } = payload;

    if (list && list.length) {
      list.forEach((l) => {
        const tasksIds = l.tasksIds;
        let currReccurringTasksList = state.recurringTasksMap[l.mainId];
        if (!isEmpty(currReccurringTasksList)) {
          currReccurringTasksList = [...currReccurringTasksList];

          tasksIds.forEach((taskId) => {
            const storedIndex = currReccurringTasksList.findIndex(
              (t) => t.key === taskId
            );

            if (storedIndex >= 0) {
              currReccurringTasksList.splice(storedIndex, 1);
            }
          });

          state.recurringTasksMap[l.mainId] = currReccurringTasksList;
        }
      });
    }
  },
  removeRecurringTasksFromMapByDates(state, payload) {
    const { datesToCheck, mainTaskId } = payload;

    let currReccurringTasksList = state.recurringTasksMap[mainTaskId];

    if (!isEmpty(currReccurringTasksList)) {
      currReccurringTasksList = currReccurringTasksList.filter((t) =>
        datesToCheck.some((d) => isSameDay(d, t.date))
      );

      state.recurringTasksMap[mainTaskId] = currReccurringTasksList;
    }
  },
  addOrUpdateRecurringTasksInRecurringMap(state, payload) {
    const { list } = payload;

    list.forEach((item) => {
      const { mainTaskId, tasks } = item;
      if (state.recurringTasksMap[mainTaskId]) {
        const mainChildTasks = [...state.recurringTasksMap[mainTaskId]];
        tasks.forEach((taskData) => {
          const { date, updates } = taskData;
          const currIndex = mainChildTasks.findIndex((chTask) =>
            isSameDay(date, chTask.date)
          );

          if (currIndex >= 0) {
            mainChildTasks[currIndex] = {
              ...mainChildTasks[currIndex],
              ...updates,
            };
          } else {
            mainChildTasks.push({
              key: updates.key,
              date,
            });
          }
        });
        state.recurringTasksMap[mainTaskId] = mainChildTasks;
      }
    });
  },
  replaceRecurringTasksInfoInMap(state, payload) {
    const { list } = payload;

    list.forEach((item) => {
      const { mainTaskId, tasks } = item;

      state.recurringTasksMap[mainTaskId] = tasks;
    });
  },
  addRecurringTasksInMap(state, payload) {
    const { tasksToAdd, mainId } = payload;
    if (!state.recurringTasksMap[mainId]) {
      state.recurringTasksMap[mainId] = tasksToAdd;
    }
  },
  removeAllTasks(state) {
    state.tasks = [];
  },
};
