const getters = {
  data(state) {
    return state.data;
  },
  isFormOpen(state) {
    return state.isFormOpen;
  },
  isNoteTopicFormOpen(state) {
    return state.isNoteTopicFormOpen;
  },
  isResourceCateFormOpen(state) {
    return state.isResourceCateFormOpen;
  },
};

export default getters;
