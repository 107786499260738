<template>
  <div>
    <input
      type="file"
      ref="file"
      style="display: none"
      accept=".csv"
      @change="handleUpload"
    />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          v-on:click="openUploadWindow"
          class="action-btn small-icon import-btn"
          data-action-type="data-change"
          data-action-value="upload"
        >
          <v-icon color="#fff">$ctiImport </v-icon>
        </v-btn>
      </template>
      <span> Import </span>
    </v-tooltip>
  </div>
</template>
<script>
import Vue from "vue";
import VuePapaParse from "vue-papa-parse";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DatabaseInterface from "@/services/DatabaseInterface";
import {
  createDateTimeFromDue,
  createTaskDataForGoogleCal,
  createTasksDataFromParsedCSV,
  processTask,
} from "@/helpers/tasks";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import isEmpty from "lodash/isEmpty";
import { addTasksToGoogleCal } from "@/mixins/googleCalHelpersMixins";
Vue.use(VuePapaParse);

export default {
  mixins: [userDetailsMixin, tasksHelpersMixin],
  methods: {
    openUploadWindow() {
      this.$refs.file.click();
    },
    handleUpload(e) {
      const file = e.target.files[0];
      if (file) {
        this.setLoader(true);
        this.$papa.parse(file, {
          // dynamicTyping: true,
          complete: async (results) => {
            const { errors } = results;
            let message = "Import was successful";
            let toastColor;
            let tasksTableList = [];

            if (isEmpty(errors)) {
              toastColor = "green";
              const updates = createTasksDataFromParsedCSV(
                results,
                this.isGoogleCalSyncEnabled
              );
              await DatabaseInterface.update(updates, this.userInfo.uid);

              if (!isEmpty(updates)) {
                tasksTableList = Object.values(updates);
              }
            } else {
              message = "An error occurred while importing";
            }
            this.setLoader(false);
            this.showToast({ message, color: toastColor });
            this.addTasksInTable(tasksTableList);
          },
          error: () => {
            this.setLoader(false);
            this.showToast("An error occurred while parsing");
          },
        });
      }
    },
    async addTasksInTable(tasksTableList) {
      if (!isEmpty(tasksTableList)) {
        const tasksToAdd = [];

        // processTaskList(
        //           tasksTableList,
        //           this.projects,
        //           this.categories
        //         );
        const processedTasks = tasksTableList.map((t) => {
          if (t.googleEventId) {
            tasksToAdd.push({
              key: t.key,
              data: {
                ...createTaskDataForGoogleCal({
                  ...t,
                  dueAsDate: createDateTimeFromDue(t.due, t.time),
                  taskKey: t.key,
                }),
              },
            });
          }
          return processTask({ ...t });
        });
        await Vue.nextTick();
        this.updateTasksInTable({
          tasksToAdd: processedTasks,
        });
        this.syncTasksToGoogle(tasksToAdd);
      }
    },
    syncTasksToGoogle(tasksToAdd) {
      if (this.isGoogleCalSyncEnabled) {
        addTasksToGoogleCal(tasksToAdd);
      }
    },
    ...mapActions(["toggleLoader"]),
    ...mapActions("toast", ["showToast"]),
    ...mapMutations(["setLoader"]),
  },
  computed: {
    ...mapGetters("task", ["projects", "categories"]),
    ...mapGetters(["isGoogleCalSyncEnabled"]),
  },
};
</script>
<style scoped>
.action-btn {
  height: 28px !important;
  width: 28px !important;
  min-width: auto !important;
}
.action-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.action-btn.btn-is-active {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.action-btn >>> svg {
  fill: #fff !important;
}

.small-icon >>> svg {
  height: 20px;
  width: 20px;
}
</style>
