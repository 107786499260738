const classes = {
  container: "datagrid-container",
  datagrid: "datagrid",
  header: "datagrid-header",
  headerCell: "datagrid-cell datagrid-header-cell",
  headerText: "datagrid-header-text",
  rowWrapper: "datagrid-row-wrapper",
  content: "datagrid-content",
  row: "datagrid-row datagrid-list-item",
  emptyRow: "datagrid-empty-row",
  cell: "datagrid-cell datagrid-list-cell",
  groupRow: "datagrid-row-group",
  groupCell: "datagrid-group-cell",
  selected: "row-selected",
  active: "row-active",
  editing: "editing",
  addNew: "row-add",
  sortDesc: "e-sortfilterdiv e-icons e-descending e-icon-descending",
  sortAsc: "e-sortfilterdiv e-icons e-ascending e-icon-ascending",
};

export default classes;
