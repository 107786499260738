import firebase from "./firebase";
import userModel from "@/data/defaultUserData";
const db = firebase.database().ref("/users");
class DatabaseInterface {
  initUser(uid) {
    return db.child(uid).set(userModel);
  }

  getAll() {
    return db;
  }

  get(key) {
    return db.child(key);
  }

  add(key, data) {
    return db.child(key).set(data);
  }

  async addTo(key, data) {
    const snapshot = await this.get(key).get();
    return db.child(key).set({
      ...snapshot.val(),
      ...data,
    });
  }

  create(data) {
    return db.push(data);
  }

  update(value, uid) {
    return db.child(uid).update(value);
  }

  delete(key) {
    return db.child(key).remove();
  }

  deleteAll() {
    return db.remove();
  }
}

export default new DatabaseInterface();
