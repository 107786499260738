import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g({attrs:{"attrs":attrs,"small":"","depressed":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},on),[_c(VIcon,{attrs:{"color":_vm.greyIconColor}},[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v(" Save")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g({attrs:{"attrs":attrs,"icon":"","small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancel.apply(null, arguments)}}},on),[_c(VIcon,{attrs:{"color":_vm.greyIconColor,"size":"22"}},[_vm._v("mdi-close-circle")])],1)]}}])},[_c('span',[_vm._v(" Cancel ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }