import { getPathsFromEvent } from "@/utils/events";

export const findElInDragEventPaths = (eventData, validator) => {
  const paths = getPathsFromEvent(eventData);
  let el;
  for (const path of paths) {
    if (validator(path)) {
      el = path;
      break;
    }
  }

  return el;
};

export const getPosOfEl = (elToCheck) => {
  return elToCheck.getBoundingClientRect();
};

export const getElCurrPos = (
  draggedItemEl,
  parentElTop,
  offset = 0,
  propToUse = "bottom"
) => {
  return getPosOfEl(draggedItemEl)[propToUse] - parentElTop - offset;
};

export const focusOnInputEle = (inputEl) => {
  inputEl.focus();
  if (typeof inputEl.selectionStart == "number") {
    inputEl.selectionStart = inputEl.selectionEnd = inputEl.value.length;
  } else if (typeof inputEl.createTextRange != "undefined") {
    const range = inputEl.createTextRange();
    range.collapse(false);
    range.select();
  }
};

export const setCursorInEle = (el, collapse) => {
  const selection = window.getSelection();
  const range = document.createRange();
  selection.removeAllRanges();
  range.selectNodeContents(el);
  if (collapse) {
    range.collapse(false);
  }
  selection.addRange(range);
  // el.focus();
};
