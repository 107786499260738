const getters = {
  data(state) {
    return state.data;
  },
  isOpen(state) {
    return state.isOpen;
  },
  returnViewInfo(state) {
    return state.returnViewInfo;
  },
  selectedTaskData(state) {
    return state.selectedTaskData;
  },
  storedTaskData(state) {
    return state.storedTaskData;
  },
};

export default getters;
