const actions = {
  openDialog(context, payload) {
    context.commit("setData", {
      isOpen: true,
      areaId: payload.areaId || "",
      taskList: payload.taskList || [],
      isAddMode: payload.isAddMode || false,
      taskUpdates: payload.taskUpdates || {},
    });
  },
  resetDialogData(context) {
    context.commit("setData", {
      isOpen: false,
      areaId: "",
      taskList: [],
      isAddMode: false,
      taskUpdates: {},
    });
  },
};

export default actions;
