<template>
  <div
    id="edit-due-date"
    class="edit-date-wrapper"
    @keydown.enter="handleKeyDown"
    @keydown.tab="handleKeyDown"
    ref="datepicker"
  >
    <AdvancedDateTimePicker
      :value="datetime"
      name="dueAsDate"
      @dateChanged="handleChange"
      @blur="handleBlur"
      :scrollTimeList="enableTimeScroll"
    />
  </div>
</template>
<script>
import AdvancedDateTimePicker from "@/components/AdvancedDateTimePicker";
import { isValidDate } from "@/helpers/dates";
import EventEmitter from "@/helpers/eventEmitter";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { columnPropsMixin } from "@/core/components/DataTable";

import { createTaskId } from "@/helpers/tasks";
import cloneDeep from "lodash/cloneDeep";
import { isReverseTabKeys } from "@/utils/hotKeys";
import { mapActions } from "vuex";
import { checkIfProjectHasNoPriorityByKey } from "@/helpers/projects";

// const propsToCheck = ["project", "dueAsDate"];
// checkIfRowTaskDataEditedChanged,
const submitKeys = [9, 13];
export default {
  components: {
    AdvancedDateTimePicker,
  },
  mixins: [columnPropsMixin, tasksHelpersMixin],
  data() {
    return {
      datetime: null,
    };
  },
  mounted() {
    if (this.data && isValidDate(this.data.dueAsDate)) {
      this.datetime = this.data.dueAsDate;
    }

    // this.cell
    this.onRendered(() => {
      this.focusInput();
      this.selectDateText();
    }, this.cell);
  },
  methods: {
    cancelAdd() {
      EventEmitter.emit("cancel-add-task");
    },
    submitTask() {
      EventEmitter.emit("submit-task");
    },
    handleBlur(val) {
      this.datetime = isValidDate(val) ? val : null;
      this.onSuccess(this.datetime, this.datetime, this.cell);
    },
    handleChange(value) {
      this.datetime = value;
    },
    async handleKeyDown(e) {
      setTimeout(async () => {
        const keyCode = e.keyCode;
        this.onSuccess(this.datetime, this.datetime, this.cell);

        if (isReverseTabKeys(e)) return;
        // const editedData = this.cell.api.getEditRowData();
        const currData = cloneDeep(this.cell.getData());
        const currentCell = this.$el.parentNode.parentNode;
        const isInAddMode = this.cell.api.isAddModeEnabled();
        if (submitKeys.includes(keyCode) && currentCell) {
          let callEndEdit = true;
          // if (!isInAddMode) {
          //   // if (
          //   //   currData.dueAsDate !== undefined &&
          //   //   !isValidDate(currData.dueAsDate)
          //   // ) {
          //   //   currData.dueAsDate = null;
          //   // }
          //   // const isDataChanged = checkIfRowTaskDataEditedChanged(
          //   //   editedData,
          //   //   currData,
          //   //   propsToCheck
          //   // );
          // }

          e.stopPropagation();
          e.preventDefault();

          if (isInAddMode) {
            const createdTaskKey = createTaskId();
            if (currData.taskType && currData.taskType === "note") {
              this.addTaskAsNote({
                ...currData,
                key: createdTaskKey,
              });
              return;
            }

            currData.key = createdTaskKey;

            const selectedProject = currData.project;

            if (checkIfProjectHasNoPriorityByKey(selectedProject)) {
              this.openDialog({
                taskList: [{ ...currData, key: createdTaskKey }],
                isAddMode: true,
                areaId: selectedProject,
              });

              return;
            }

            const addedTaskData = await this.addNewTaskEntry(currData);
            this.cell.api.triggerAddRow(addedTaskData);
          } else {
            setTimeout(() => {
              if (callEndEdit) {
                this.cell.api.endEditing();
                // this.cell.api.endEditAndEnableOnNextRow(false, true, true);
              } else {
                currentCell.parentNode
                  .querySelector(".taskType-cell")
                  .querySelector("input")
                  .focus();
              }
            });
          }
        }
      }, 0);
    },
    selectDateText() {
      this.$refs.datepicker.querySelector("input").select();
    },
    focusInput() {
      this.$refs.datepicker.querySelector("input").focus();
    },
    ...mapActions("areaPrioritySetterData", ["openDialog"]),
  },
  computed: {
    isCompInAddMode() {
      return this.cell.api.isAddModeEnabled();
    },
    enableTimeScroll() {
      return this.isCompInAddMode || !isValidDate(this.data.dueAsDate);
    },
  },
};
</script>
<style scoped>
.edit-date-wrapper {
  display: inline-flex;
  width: 100%;
}

#edit-due-date .date-picker-wrapper >>> input {
  padding-left: 10px;
}
.actions-wrapper {
  position: absolute;
  right: 10px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  z-index: 2;
}

.save-btn {
  margin-right: 5px;
}
</style>
