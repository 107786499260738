export const DATE_SHORTCUTS_REGEX =
  /^#?(?<short1>[dwm])(?<numb1>\d{1,2})?$|^#?(?<numb2>\d{1,2})?(?<short2>[dwm])$|^#?(?<timeNum1>[0-9]|1[0-2])(?<timeShort1>[ap])$|^#?(?<timeShort2>[ap])(?<timeNum2>[0-9]|1[0-2])$/i;

export const TIME_SHORTCUTS_REGEX =
  /^#?(?<timeNumHrs>[0-9]|1[0-2]):(?<timeNumMins>[0-5]?[0-9]?)(?<timeShortKey1>[ap])$|^#?(?<timeShortKey2>[ap])(?<timeNumHrs2>[0-9]|1[0-2]):(?<timeNumMins2>[0-5][0-9]?)$/i;

export const TIME_IN_24_HOURS_REGEX =
  /^#?(?<numHrsIn24_1>(0?[1-9]|[0-1][0-9]|2[0-3])):(?<numMinsIn24_1>([0-5][0-9]))[ap]?$|^#?[ap]?(?<numHrsIn24_2>(0?[1-9]|[0-1][0-9]|2[0-3])):(?<numMinsIn24_2>([0-5][0-9]))$/i;
export const DAY_STRING_REGEX =
  /^#?(?<shortString>(now|t|y|n|yesterday|tomorrow))$/i;

export const ALPHA_NUMERIC_REGEX = /^([a-zA-Z0-9_]+)$/i;

export const DATE_FORMAT_CAPTION_REGEX = /(\d{1,2}\/\d{1,2}\/\d{2})/g;

export const DATE_SCHEMA_REGEX = /(\d{4}-\d{2}-\d{2})/;

export const WEEK_DAYS_REGEX =
  /^#?(?<weekDayFull>(monday|tuesday|wednesday|thursday|friday|saturday|sunday))|(?<weekDayShort>(mon|tues|wed|thurs|fri|sat|sun))$/i;

export const WEEK_DAYS_SHORT_REGEX = /^#?(?<weekDayFull>([a-zA-Z]){2})(.*)?$/i;
// {
//   /* ^#?(?<weekDayShort>([a-zA-Z]){2,})$ */
// }

// export const MENTION_REGEX = /\B@[a-z0-9_]+/gim;
export const MENTION_REGEX = /(@\S+)/gim;

export const HTML_BLOB_REGEX = /^(?:<html-blob>)/gim;

export const HTML_BLOB_CONTENT_REGEX =
  /(?<=<html-blob>)(.*?)(?=<\/html-blob>)/gim;
