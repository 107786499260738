export const isUndoHotKeys = (e) =>
  (e.ctrlKey || e.metaKey) && e.keyCode === 90 && !e.shiftKey;
export const isRedoHotKeys = (e) =>
  (e.ctrlKey || e.metaKey) &&
  (e.keyCode === 89 || (e.keyCode === 90 && e.shiftKey));

export const isDeleteKey = (e) => {
  const keyCode = e.which ? e.which : e.keyCode;
  return keyCode === 46;
};

export const isReverseTabKeys = (e) => {
  const keyCode = e.which ? e.which : e.keyCode;
  return keyCode === 9 && e.shiftKey;
};

export const isSubmitKey = (e) => {
  const keyCode = e.which ? e.which : e.keyCode;
  return keyCode === 9 || keyCode === 13;
};

export const isEscKey = (e) => {
  let isEscKeyTriggered = false;
  if (e.key && e.key === "Escape") {
    isEscKeyTriggered = true;
  } else {
    const keyCode = e.keyCode ? e.keyCode : e.which;

    isEscKeyTriggered = keyCode === 27;
  }

  return isEscKeyTriggered;
};
