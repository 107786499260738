import { isEmpty } from "lodash";

const actions = {
  openResourceInfoViewer(context, payload) {
    let currState = context.state;
    currState = Object.assign(currState, {
      isOpen: true,
      data: payload.data,
      selectedTaskData: !isEmpty(payload.selectedTaskData)
        ? payload.selectedTaskData
        : {},
      returnViewInfo: payload.returnViewInfo,
    });
    context.commit("setData", currState);
  },
  setResourceData(context, resourceData) {
    let currState = context.state;

    currState = Object.assign(currState, {
      data: resourceData,
      selectedTaskData: {},
    });

    context.commit("setData", currState);
  },
  setLinkedTaskData(context, linkedTaskData) {
    let currState = context.state;
    const stateUpdates = {};

    if (!isEmpty(linkedTaskData)) {
      stateUpdates.selectedTaskData = Object.freeze(linkedTaskData);
    }
    currState = Object.assign(currState, {
      ...stateUpdates,
    });

    context.commit("setData", currState);
  },

  setStoredTaskData(context, payload = {}) {
    let currState = context.state;

    currState = Object.assign(currState, {
      storedTaskData: { ...payload },
    });
    context.commit("setData", currState);
  },
  clearStoredTaskData(context) {
    let currState = context.state;

    currState = Object.assign(currState, {
      storedTaskData: {},
    });
    context.commit("setData", currState);
  },
  setSelectedTaskData(context, payload) {
    let currState = context.state;

    currState = Object.assign(currState, {
      selectedTaskData: { ...payload },
    });
    context.commit("setData", currState);
  },
  clearSelectedTaskData(context) {
    let currState = context.state;

    currState = Object.assign(currState, {
      selectedTaskData: {},
    });
    context.commit("setData", currState);
  },
  closeResourceInfoViewer(context) {
    let currState = context.state;
    currState = Object.assign(currState, {
      isOpen: false,
      data: {},
      selectedTaskData: {},
      returnViewInfo: {},
      storedTaskData: {},
    });
    context.commit("setData", currState);
  },
};

export default actions;
