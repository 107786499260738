<template>
  <div
    class="title-cell-wrapper"
    @keydown="handleMainKeydown"
    @click="handleClick"
  >
    <div style="width: 100%; overflow: hidden">
      <div class="title-wrapper">
        <RichTextEditor
          ref="titleEditor"
          :isReadOnly="!enableInputArea"
          v-model="title"
          :showToolbar="false"
          @keydown="handleKeydown"
          @focus="handleFocus"
          @blur="handleBlur"
          @remove-mention="handleMentionDeletion"
          editorId="title-cell-editor"
          class="title-field"
          :class="textareaStyles"
          mentionMenuPosition="fixed"
          :useAsInput="isRowEditing"
          :enableMentions="isMentionsEnabled"
          :placeholder="cellPlaceholder"
          :useMentionFilters="useResourceWithAreaFilters"
          :mentionOpts="mentionOpts"
          :doNotHandleMentionRemove="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import RichTextEditor from "@/components/RichTextEditor";
import EventEmitter from "@/helpers/eventEmitter";
import { columnPropsMixin } from "@/core/components/DataTable";
import isEqual from "lodash/isEqual";
import { mapGetters, mapActions } from "vuex";
import isEmpty from "lodash/isEmpty";
import { CLOSE_RESOURCE_POPUP, SELECT_TEXT_IN_INPUT } from "@/variables/events";
import { getUserResources } from "@/helpers/resources";
export default {
  mixins: [columnPropsMixin],
  components: {
    RichTextEditor,
  },
  data() {
    return {
      isTextAreaVisible: false,
      title: "",
      showLabel: false,
      activatorEl: null,
      enableObjLabel: false,
    };
  },
  mounted() {
    this.activatorEl =
      this.$el.parentElement.parentElement.parentElement.querySelector(
        ".actions-cell"
      );
    if (this.cell.api.isAddModeEnabled()) {
      this.updateData = true;
      const { duplicated, resourceToUse, ...restOfData } = this.data;
      this.$refs.titleEditor.setHTMLData(restOfData.title);

      if (duplicated) {
        this.handleTextSelection();
      } else if (this.cell.primary) {
        this.focusOnInput(true, true);

        this.$nextTick(() => {
          if (resourceToUse && this.resourcesMap[resourceToUse]) {
            this.$refs.titleEditor.handleResourceAdd(
              {
                ...this.resourcesMap[resourceToUse],
              },
              true
            );
          }
        });
      }
    } else {
      this.updateData = !!this.data.title;
      this.$refs.titleEditor.setHTMLData(this.data.title, true, true);
      this.setHoverListener();
    }

    if (this.extraData.watchResources) {
      this.$watch("resourcesMap", this.handleResourcesChange, {
        deep: true,
      });
      this.$watch("resourceSettings", this.handleResourcesChange, {
        deep: true,
      });
    }
    this.setMentionClickListener();
  },
  beforeDestroy() {
    this.removeHoverListener();
    this.removeSelectionListener();
    this.removeMentionClickListener();
  },
  methods: {
    ...mapActions(["updateRootState"]),
    handleMouseEnter(e) {
      const targetEl = e.target;

      if (targetEl.classList.contains("mention")) {
        if (this.$route.name === "Dashboard") {
          this.updateRootState({
            viewedItemKey: this.data.isReminderTask
              ? this.data.reminderLinkedTo
              : this.data.key,
          });
        }
      }
    },
    setHoverListener() {
      this.$el.addEventListener("mouseover", this.handleMouseEnter);
    },
    removeHoverListener() {
      this.$el.removeEventListener("mouseover", this.handleMouseEnter);
    },
    setMentionClickListener() {},
    removeMentionClickListener() {},
    handleClick() {},
    async handleMentionDeletion(mentionData) {
      if (!isEmpty(mentionData.mentionInfo)) {
        // if (this.cell.api.isAddModeEnabled()) {
        //   this.$refs.titleEditor.removeMentionFromData(mentionData);
        // } else {
        const editingRowId = this.currRowInEditing;
        if (
          !this.cell.api.isAddModeEnabled() &&
          editingRowId !== this.data.key
        ) {
          this.cell.api.deselectPreviousRows();
          this.cell.api.setRowIdsForScroll([this.data.key]);
          await Vue.nextTick();
          this.cell.api.enableEdit({}, { key: this.data.key });
        }
      }

      await Vue.nextTick();

      this.$refs.titleEditor.removeMentionFromData(mentionData);

      await this.$nextTick();

      EventEmitter.emit(CLOSE_RESOURCE_POPUP);
      // }
    },
    handleMainKeydown(e) {
      const keyCode = e.which ? e.which : e.keyCode;

      if (keyCode !== 27) e.stopPropagation();
    },
    async handleResourcesChange(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        if (this.cell.api.isAddModeEnabled() || this.cell.api.editingRow) {
          return;
        }
        this.$refs.titleEditor.setHTMLData(this.data.title, false, true);
      }
    },
    setCurrData(isVisible) {
      if (isVisible) {
        // this.title = this.data.title || "";
        this.$refs.titleEditor.setHTMLData(this.data.title, false, true);
      } else {
        this.title = "";

        if (!this.isRowSelected) {
          this.$refs.titleEditor.setHTMLData(this.data.title, false, true);
        }
      }
    },
    focusOnInput(isVisible, byPassCheck) {
      if (isVisible) {
        this.$refs.titleEditor?.focusOnEl(undefined, byPassCheck);
      }
    },
    blurInput() {
      this.$refs.titleEditor?.blurInput();
    },
    handleBlur() {
      if (!this.updateData) return;

      if (this.isRowEditing) {
        this.onSuccess(this.title, this.title, this.cell);
      }
    },
    handleKeydown(e) {
      const keyCode = e.which ? e.which : e.keyCode;

      if (keyCode === 27) {
        this.updateData = false;
        this.title = this.data.title || "";
        this.blurInput();
      }
      if (keyCode === 9) {
        this.blurInput();

        let nextCell;

        const currentCell = this.$el.parentNode.parentNode;

        if (e.shiftKey) {
          const cellToGet = this.extraData.previousCell;

          nextCell = currentCell.parentNode.querySelector(`.${cellToGet}-cell`);
          // currentCell?.previousSibling || currentCell?.previousElementSibling;
        } else {
          if (this.extraData?.nextCell) {
            nextCell = currentCell.parentNode.querySelector(
              `.${this.extraData?.nextCell}-cell`
            );
          } else {
            nextCell =
              currentCell?.nextSibling || currentCell?.nextElementSibling;
          }
        }

        const input = nextCell?.querySelector("input");
        input?.focus();
      }
    },
    checkAndRestoreVal(isEditing) {
      if (!isEditing && this.cell.api.isEditCancelledByEsc()) {
        this.setCurrData(true, "restore");
      }
    },

    handleFocus() {
      this.updateData = true;
    },
    handleTextSelection() {
      setTimeout(() => {
        this.$refs.titleEditor?.focusOnEl(true);
      }, 0);
    },
    addSelectionListener() {
      EventEmitter.on(SELECT_TEXT_IN_INPUT, this.handleTextSelection);
    },
    removeSelectionListener() {
      EventEmitter.off(SELECT_TEXT_IN_INPUT, this.handleTextSelection);
    },
    setTriggerSelectionListener(isOpen) {
      if (isOpen) {
        this.addSelectionListener();
      } else {
        this.removeSelectionListener();
      }
    },

    async handleObjectiveTooltipForSelection(isSelected) {
      await this.$nextTick();
      const currEditingRow = this.cell.api.getCurrEditingRow();
      // const currSelectedRows =
      // const currSelectedRows = this.cell.api.getSelectedRowKeys();

      if (currEditingRow === this.data.key) return;
      if (isSelected) {
        this.enableObjLabel = false;
        this.showLabel = false;
        this.$nextTick();
        this.triggerVisiblityOfObjective(true);
      } else {
        this.enableObjLabel = false;
        this.showLabel = false;
      }
    },
  },
  computed: {
    ...mapGetters("task", ["objectivesMap"]),
    ...mapGetters(["resourceSettings"]),
    ...mapGetters("resourcesData", {
      resourcesMap: "rawResourcesMap",
    }),
    ...mapGetters(["currRowInEditing"]),
    enableInputArea() {
      return this.isRowEditing || this.isRowSelected;
    },
    textareaStyles() {
      return {
        "textarea-focused": this.isRowSelected,
        "textarea-edit-mode": this.isRowEditing,
        "text-striked": !this.extraData.doNotUseLine && this.data.completed,
      };
    },
    currRowIsEditing() {
      return this.isRowEditing;
    },
    cellPlaceholder() {
      const providedPlaceholder = this.extraData.placeholder;

      return providedPlaceholder || "Enter title";
    },
    showObjectiveIcon() {
      return this.extraData.showObjectiveBtn && !isEmpty(this.data.objective);
    },
    showObjectiveLabel() {
      return this.extraData.showObjectiveBtn && !isEmpty(this.data.objective);
    },
    isMentionsEnabled() {
      let isEnabled = true;
      const providedMentionSwitch = this.extraData.enableMentions;

      if (providedMentionSwitch !== undefined) {
        isEnabled = providedMentionSwitch;
      }
      return isEnabled;
    },
    objectiveLabel() {
      const objectiveId = this.data.objective;
      let label = "";

      if (objectiveId && this.objectivesMap[objectiveId]) {
        label = this.objectivesMap[objectiveId].name;
      }
      return label;
    },

    useResourceWithAreaFilters() {
      return !!this.extraData.filterResourcesByArea;
    },

    mentionOpts() {
      let opts = {};

      if (this.useResourceWithAreaFilters && this.projectId) {
        const resourcesCatgeories = getUserResources().getCategories("list");

        const resourceCate = resourcesCatgeories?.find(
          (c) => c.refKey === this.projectId
        );

        const cateKey = resourceCate?.key || "";
        opts.formData = {
          catId: cateKey,
          type: "project",
        };
        opts.useAdditionalFilters = true;
        opts.mentionFiltersProps = {
          catId: cateKey,
          filterByProjectAndCatId: true,
        };
      }

      return opts;
    },

    projectId() {
      const isRowEditing = this.currRowIsEditing;
      let projectId = "";

      if (isRowEditing) {
        projectId = this.cell.api.isAddModeEnabled()
          ? this.data.project
          : this.cell.api.getEditRowData().project || this.data.project;
      }

      return projectId;
    },
  },
  watch: {
    enableInputArea: {
      handler(nVal) {
        this.updateData = !!nVal;
        // this.setCurrData(nVal, "enable");
      },
    },
    currRowIsEditing: {
      handler(nVal) {
        if (!nVal) {
          this.blurInput();
        }
        this.setTriggerSelectionListener(nVal);
        this.checkAndRestoreVal(nVal);
      },
    },
    "data.title": {
      handler(nVal) {
        this.updateData = true;
        this.showLabel = false;
        this.$refs.titleEditor.setHTMLData(nVal, false, true);
      },
    },
  },
};
</script>
<style scoped>
.wrapper >>> textarea {
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 4px;
}

.textarea-focused >>> .v-text-field > .v-input__control > .v-input__slot:after,
.v-text-field > .v-input__control > .v-input__slot::before {
  bottom: 0px !important;
  visibility: visible;
}

.textarea-edit-mode >>> textarea,
.textarea-edit-mode.text-striked >>> .ql-editor {
  text-decoration: none;
}

.textarea-edit-mode
  >>> .v-text-field
  > .v-input__control
  > .v-input__slot:before {
  bottom: 0px !important;
}

.textarea-edit-mode
  >>> .v-text-field
  > .v-input__control
  > .v-input__slot:after,
.v-text-field > .v-input__control > .v-input__slot::before {
  visibility: visible;
  bottom: 0px !important;
}

.text-striked >>> .ql-editor {
  text-decoration: line-through;
}

.title-field {
  height: 100%;
}
.title-field >>> .ql-editor {
  cursor: pointer;
}
.title-wrapper {
  width: 100%;
  cursor: pointer;
  position: relative;
}

.objective-label-wrapper {
  height: 22px;
  width: 14ch;
  text-align: center;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 3px;
  user-select: all;
  color: #0378d5;
  text-align: center;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.objective-label-wrapper:hover {
  text-decoration: underline;
}
</style>
<style>
#title-cell-editor {
  border: none;
  width: 100%;
}
#title-cell-editor .ql-editor {
  font-size: 13px;
  min-height: 33px !important;
  padding: 6px 0px 6px 0px !important;
  line-height: 1.55;
  display: block; /* Change it as per your requirement. */
  overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit; */
}

#title-cell-editor .ql-editor.ql-blank::before {
  top: 8px;
  left: 0px;
  font-style: normal;
  color: #a6a6a6;
}

.title-field .ql-editor,
.title-field .ql-editor * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.title-field .ql-editor > p,
.title-field .ql-editor li,
.title-field .ql-editor ul,
.title-field .ql-editor ol,
.title-field .ql-editor div,
.title-field .ql-editor blockquote {
  display: inline;
}

.title-field .ql-editor > p:not(:first-child) {
  display: none;
}

.title-field.text-striked .ql-editor > p {
  text-decoration: line-through;
}

.title-field.textarea-focused .ql-editor > p,
.title-field.textarea-edit-mode .ql-editor > p,
.title-field.textarea-focused .ql-editor > li,
.title-field.textarea-edit-mode .ql-editor > li,
.title-field.textarea-focused .ql-editor > ol,
.title-field.textarea-edit-mode .ql-editor > ol,
.title-field.textarea-focused .ql-editor > ul,
.title-field.textarea-edit-mode .ql-editor > ul,
.title-field.textarea-edit-mode .ql-editor > blockquote,
.title-field.textarea-focused .ql-editor > div,
.title-field.textarea-edit-mode .ql-editor > div {
  white-space: pre-wrap;
  display: block;
}

.title-cell-wrapper {
  width: 100% !important;
  display: flex;
}

.objective-task-icon:hover {
  color: var(--primary-color) !important;
  caret-color: var(--primary-color) !important;
}
</style>
