<template>
  <div style="width: 100%">{{ resourceGroupHeaderText }}</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("resourcesData", ["selectedResourceTypeOpt"]),
    resourceGroupHeaderText() {
      return this.selectedResourceTypeOpt === "project" ? "Area" : "Group";
    },
  },
};
</script>
