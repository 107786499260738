export default {
  showToast(context, payload) {
    context.commit("show", {
      message: typeof payload === "object" ? payload.message : payload,
      color: payload.color,
    });
  },
  hideToast(context) {
    context.commit("hide");
  },
};
