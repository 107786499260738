<template>
  <v-dialog
    width="25%"
    :value="isFormOpen"
    @click:outside="closeDialog"
    @keydown="handleKeyDown"
    content-class="resource-cate-add-form"
  >
    <v-card>
      <v-card-title class="text-h5"> Add {{ itemTypeLabel }} </v-card-title>
      <v-card-text>
        <div>
          <div>
            <ResourceTypeSelector
              v-model="cateType"
              ref="resourceTypeSelector"
              placeholder="Select Type"
              selectorClass="resource-type-selector resource-form-field"
              :menuProps="{
                'content-class': 'resource-type-selector-menu',
              }"
              @keydown="handleSelectorKeyDown"
            />
          </div>

          <div class="mt-4">
            <ResourceCateSelector
              ref="resourceCateSelector"
              v-model="parentCatKey"
              :optsList="createdOptsList"
              :cateType="cateType"
              useProvidedOpts
              :useNoDataSlot="false"
              class="resource-type-cate-selector resource-form-field"
              :placeholder="selectPlaceholder"
              :menuProps="{
                'content-class': 'resource-type-selector-menu',
              }"
              @keydown="handleSelectorKeyDown"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed text @click="closeDialog" color="error"> Cancel</v-btn>
        <v-btn
          color="primary"
          class="save-confirm-btn"
          depressed
          text
          @click="submitData"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ResourceTypeSelector from "@/components/EditComponents/ResourceTypeSelectorInput.vue";
import { PRIMARY_COLOR } from "@/variables/colors";
import {
  createCategoryDataForAdd,
  getChildrenOfResourceCate,
} from "@/helpers/resourceCategoriesHelpers";
// import isEmpty from "lodash/isEmpty";
// import EventEmitter from "@/helpers/eventEmitter";
// import { REFRESH_TREE_EVENT } from "@/variables/events";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { createResourceCategoriesOpts } from "@/helpers/resourceCategoriesHelpers";
import {
  getChildrenOfTaskCate,
  getTaskCateByRefKey,
  storeCatAddAction,
  storeCateInTasksCateData,
} from "@/helpers/categories";
import { getUserResources } from "@/helpers/resources";
import isEmpty from "lodash/isEmpty";
import createUniqueId from "@/utils/createUniqueId";
import { getCurrDate } from "@/helpers/dates";
import popupHelpersMixin from "@/mixins/popupHelpersMixin";
import isEscKey from "@/utils/isEscKey";
import { isSubmitKeys, isTabKey } from "@/utils/keys";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
export default {
  mixins: [userDetailsMixin, categoriesHelpersMixin, popupHelpersMixin],
  components: {
    ResourceTypeSelector,
    ResourceCateSelector: () =>
      import("../ResourceCateSelector/ResourceCateSelector.vue"),
  },
  data() {
    return {
      parentCatKey: "",
      cateType: "",
    };
  },
  methods: {
    ...mapActions("cateData", ["resetCateData"]),
    ...mapMutations(["setLoader"]),

    handleSelectorKeyDown(e) {
      e.stopPropagation();

      const paths = getPathsFromEvent(e);

      e.paths = paths;
      // this.handleKeyDown(e);
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
    async submitData() {
      const selectedCateType = this.cateType && this.cateType.trim();
      const currTree = this.resourcesCateTree;
      const currParentChildMap = this.cateChildrenMap;
      const allCategories = this.categories;
      const selectedCate =
        (this.parentCatKey && this.parentCatKey.trim()) || "";

      if (!selectedCateType) {
        this.$swal({
          titleText: "Oops!",
          html: "Type is required",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
          customClass: {
            container: "resource-area-error-pop-up",
          },
        });
        return;
      }

      if (!selectedCate) {
        this.$swal({
          titleText: "Oops!",
          html: "Parent Group/Area is required",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
          customClass: {
            container: "resource-area-error-pop-up",
          },
        });
        return;
      }

      this.setLoader(true);

      let extraData = {
        cateType: selectedCateType,
      };

      let taskCateDataToAdd = {};

      if (this.$route.name !== "Resources") {
        const cateOrder =
          getChildrenOfResourceCate(selectedCate, allCategories)?.length || 0;
        extraData = {
          ...this.resourceCateData,
          parentCatKey: selectedCate,
          order: cateOrder,
          ...extraData,
        };
      }

      if (selectedCateType === "project") {
        const parentCateData = allCategories[selectedCate];
        const taskParentData = getTaskCateByRefKey(parentCateData.refKey);

        if (!isEmpty(taskParentData)) {
          const parentType = taskParentData.type.split(" ")[0];
          const childrenOfTaskParent = getChildrenOfTaskCate(
            taskParentData.key
          );
          const taskCateOrder = childrenOfTaskParent?.length || 0;
          taskCateDataToAdd = {
            key: createUniqueId(),
            title: this.resourceCateData.name,
            hasAttribute: { class: "project" },
            type: "project",
            open: false,
            completed: false,
            created: getCurrDate(),
            description: "New Area Description",
            icon: "mdi-circle",
            modified: getCurrDate(),
            order: taskCateOrder,
            priority: "",
            tasks: [],
          };

          extraData.refKey = taskCateDataToAdd.key;

          if (parentType === "category") {
            taskCateDataToAdd.category = taskParentData.key;
          } else if (parentType === "subcategory") {
            taskCateDataToAdd.category = taskParentData.category;
            taskCateDataToAdd.subcategory = taskParentData.key;
          }
        }
      }

      const createdResourceCateData = createCategoryDataForAdd(
        {
          ...this.resourceCateData,
          parentCatKey: selectedCate,
        },
        currParentChildMap,
        currTree,
        allCategories,
        {
          ...extraData,
        }
      );

      const categoriesData = {
        [createdResourceCateData.key]: createdResourceCateData,
      };
      storeCatAddAction(createdResourceCateData, "resource-category");

      await getUserResources().add(categoriesData, "categories");

      if (!isEmpty(taskCateDataToAdd)) {
        await storeCateInTasksCateData(
          taskCateDataToAdd,
          "project",
          this.userInfo.uid
        );
      }

      //   await DatabaseInterface.update(
      //     {
      //       [`/projects/${createdProjectData.key}`]: createdProjectData,
      //     },
      //     this.userInfo.uid
      //   );

      //   storeProjectAddAction(createdProjectData);
      // if (cateTree) {
      //   EventEmitter.emit(REFRESH_TREE_EVENT, false);
      // }

      await Vue.nextTick();

      setTimeout(() => {
        this.closeDialog();
        this.setLoader(false);
        this.$emit("resource-cate:added", createdResourceCateData);
      }, 0);
    },
    closeDialog() {
      this.resetStateData();
      this.resetCateData();
    },
    handleKeyDown(e) {
      let paths = getPathsFromEvent(e);

      if (isEmpty(paths)) {
        paths = !isEmpty(e.paths) ? e.paths : [];
      }
      if (isEscKey(e)) {
        this.closeDialog();
      }
      if (isSubmitKeys(e)) {
        const submitBtnParentFound = findParentFromPathsByClassName(paths, [
          "save-confirm-btn",
        ]);
        if (submitBtnParentFound && !e.shiftKey) {
          this.submitData();
          return;
        }
      }

      if (isTabKey(e)) {
        const resourceTypeElFound = findParentFromPathsByClassName(paths, [
          "resource-type-selector",
        ]);

        const resourceCateElFound = findParentFromPathsByClassName(paths, [
          "resource-type-cate-selector",
        ]);

        if (!e.shiftKey) {
          if (resourceTypeElFound) {
            this.$refs.resourceTypeSelector.blurInput();
            setTimeout(() => {
              this.$refs.resourceCateSelector.openInput();
            }, 0);
          }

          if (resourceCateElFound) {
            this.$refs.resourceCateSelector.blurInput();
            setTimeout(() => {
              const btn = document.querySelector(
                ".resource-cate-add-form .save-confirm-btn"
              );
              btn?.focus();
            }, 0);
          }
        }
      }
    },
    resetStateData() {
      this.cateType = "";
      this.parentCatKey = "";
    },
    prefillFormData() {
      const resourceCateData = this.resourceCateData;

      let focusOnTypeSelector = true;
      if (resourceCateData?.cateType) {
        focusOnTypeSelector = false;
        this.cateType = resourceCateData.cateType;
      }

      if (resourceCateData?.parentCatKey) {
        this.parentCatKey = resourceCateData.parentCatKey;
      }

      const selectorRef = focusOnTypeSelector
        ? "resourceTypeSelector"
        : "resourceCateSelector";

      this.focusOnSelector(selectorRef);
    },
    focusOnInput() {
      this.focusOnSelector("noteCateSelector");
    },
  },
  computed: {
    ...mapGetters("resourcesData", {
      categories: "categories",
      resources: "resources",
      resourcesCateTree: "categoriesTree",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      cateChildrenMap: "cateChildrenMap",
      rawResourcesMap: "rawResourcesMap",
    }),
    ...mapGetters("cateData", {
      resourceCateData: "data",
      isFormOpen: "isResourceCateFormOpen",
    }),
    itemTypeLabel() {
      return this.cateType && this.cateType === "project" ? "Area" : "Group";
    },
    selectPlaceholder() {
      let itemType = "Group";
      let itemMainLabel = "Group";
      let itemSubLabel = "Sub-Group";

      if (this.itemTypeLabel === "Area") {
        itemType = "Area";
        itemMainLabel = "Area";
        itemSubLabel = "Sub-Area";
      }
      return `Select Parent ${itemMainLabel}/${itemSubLabel} for ${itemType}`;
    },
    createdOptsList() {
      if (this.cateType) {
        return createResourceCategoriesOpts(
          this.categories,
          this.cateType,
          true
        );
      }
      return [];
    },
  },
  watch: {
    isFormOpen: {
      handler(n) {
        if (n) {
          this.prefillFormData();
        } else {
          this.resetStateData();
        }
      },
    },
  },
};
</script>
<style scoped>
.selected-resource {
  display: flex;
  align-items: center;
}
</style>
