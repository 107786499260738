import DatabaseInterface from "@/services/DatabaseInterface";
import isEmpty from "lodash/isEmpty";

const patchTasksToAddOrderAndPosition = async (userId) => {
  DatabaseInterface.get("/" + userId + "/tasks/").once(
    "value",
    async (item) => {
      const tasksMap = item.val();
      const updates = {};

      let taskIndex = 0;

      for (const taskId in tasksMap) {
        const fullTaskData = tasksMap[taskId];
        if (!fullTaskData || !fullTaskData.key) {
          continue;
        }

        if (typeof fullTaskData.order !== "number") {
          updates[`tasks/${fullTaskData.key}/order`] = taskIndex;
          updates[`tasks/${fullTaskData.key}/userPosition`] = 0;
          taskIndex++;
        }
      }

      if (!isEmpty(updates)) {
        await DatabaseInterface.update(updates, userId);
      }
    }
  );
};

export default patchTasksToAddOrderAndPosition;
