<template>
  <DialogLayout label="List Options">
    <template #content>
      <div>
        <p class="selector-label">Tag Format:</p>

        <v-radio-group
          v-model="selectedResourceDisplay"
          class="resource-display-selector"
          hide-details
        >
          <v-radio
            v-for="(opt, index) in displayOpts"
            :key="index"
            :label="opt.label"
            :value="opt.value"
            class="resource-display-opt"
          ></v-radio>
        </v-radio-group>
        <v-checkbox
          class="show-underline-checkbox"
          v-model="selectedResourceHover"
          hide-details
        >
          <template #prepend> Show underline on hover </template>
        </v-checkbox>
        <p class="selector-label">Choose position of status button</p>

        <v-radio-group
          v-model="selectedStatusDisplay"
          class="display-selector"
          hide-details
        >
          <v-radio
            v-for="(opt, index) in statusBtnDisplayOpts"
            :key="index"
            :label="opt.label"
            :value="opt.value"
          ></v-radio>
        </v-radio-group>

        <p class="selector-label">Choose display of due date in the list</p>
        <v-radio-group
          v-model="selectedDueDateDisplay"
          class="display-selector"
          hide-details
        >
          <v-radio
            v-for="(opt, index) in dueDateDisplayOpts"
            :key="index"
            :label="opt.label"
            :value="opt.value"
          ></v-radio>
        </v-radio-group>
        <v-checkbox
          class="show-underline-checkbox"
          v-model="useEditPen"
          hide-details
        >
          <template #prepend>
            Use the edit pen in a separate column to access task details.
          </template>
        </v-checkbox>
        <v-divider></v-divider>
      </div>
    </template>

    <template #actions>
      <!-- <v-spacer /> -->
      <v-btn class="mr-4" @click="saveSettings"> submit </v-btn>
      <v-btn @click="closeDialog"> cancel </v-btn>
    </template>
  </DialogLayout>
</template>
<script>
import { resourceDisplayOpts } from "@/data/resourceSettingsConfig";
import DatabaseInterface from "@/services/DatabaseInterface";
import { mapActions, mapGetters } from "vuex";
import {
  statusBtnDisplayOpts,
  dueDateDisplayOpts,
} from "@/data/taskSettingsConfig";
import DialogLayout from "./components/DialogLayout.vue";
import userDetailsMixin from "@/mixins/userDetailsMixin";
export default {
  mixins: [userDetailsMixin],
  components: {
    DialogLayout,
  },
  data() {
    return {
      selectedResourceDisplay: "",
      selectedStatusDisplay: "",
      selectedDueDateDisplay: "",
      selectedResourceHover: false,
      useEditPen: false,
    };
  },
  props: {},
  mounted() {
    this.fillData();
  },
  methods: {
    ...mapActions("toast", ["showToast"]),
    ...mapActions(["toggleSettingsDialog"]),
    async saveSettings() {
      let showSuccessMsg = false;

      const dbUpdates = {};
      const updatedResourceDisplay = this.selectedResourceDisplay;
      const updatedResourceShowUnderline = this.selectedResourceHover ?? true;
      const useEditPen = this.useEditPen || false;
      dbUpdates[`/resourceSettings/displayType`] = updatedResourceDisplay;
      dbUpdates["/resourceSettings/showUnderlineOnHover"] =
        updatedResourceShowUnderline;

      dbUpdates["/taskSettings/showEditBtn"] = useEditPen;
      dbUpdates[`/taskSettings/statusBtnPosition`] =
        this.selectedStatusDisplay || "";
      dbUpdates["/taskSettings/dueDateDisplay"] =
        this.selectedDueDateDisplay || "";
      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      showSuccessMsg = true;

      this.closeDialog();

      if (showSuccessMsg) {
        this.showToast({ message: "Changes saved", color: "green" });
      }
    },
    fillData() {
      this.selectedResourceDisplay =
        this.resourceSettings?.displayType ?? "default";
      this.selectedResourceHover =
        this.resourceSettings?.showUnderlineOnHover ?? true;

      this.selectedStatusDisplay = this.taskSettings?.statusBtnPosition || "";
      this.selectedDueDateDisplay = this.taskSettings?.dueDateDisplay || "date";
      this.useEditPen = this.taskSettings?.showEditBtn || false;
    },
    closeDialog() {
      this.toggleSettingsDialog();
    },
  },
  computed: {
    displayOpts() {
      return resourceDisplayOpts;
    },
    statusBtnDisplayOpts() {
      return statusBtnDisplayOpts;
    },
    dueDateDisplayOpts() {
      return dueDateDisplayOpts;
    },
    ...mapGetters(["resourceSettings", "taskSettings"]),
  },
};
</script>
<style scoped>
.selector-label {
  margin-bottom: 0px;
}

.show-underline-checkbox {
  align-items: center;
}
.show-underline-checkbox >>> .v-input__control {
  flex: 0;
}
</style>
