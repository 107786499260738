<template>
  <v-dialog
    width="20%"
    :value="isOpen"
    @click:outside="closeDialog"
    @keydown="handleKeyDown"
    content-class="area-priority-setter-dialog"
  >
    <v-card>
      <v-card-title class="text-h5">
        Set importance for this Area
      </v-card-title>
      <v-card-text>
        <div class="area-priority-selector-wrapper">
          <PrioritySelector
            v-model="selectedVal"
            ref="prioritySelector"
            @keydown="handleSelectorKeyDown"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="save-confirm-btn"
          depressed
          text
          @click="submitData"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { PrioritySelectorInput as PrioritySelector } from "@/components/EditComponents/PrioritySelector";
import { mapActions, mapGetters } from "vuex";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import projectsHelpersMixin from "@/mixins/projectsHelpersMixin";
import isEmpty from "lodash/isEmpty";
import DatabaseInterface from "@/services/DatabaseInterface";
import EventEmitter from "@/helpers/eventEmitter";
import {
  getUserActions,
  PROJECT_ACTIONS,
  TASK_ACTIONS,
} from "@/helpers/actionHistory";
import {
  CLOSE_INLINE_ADD,
  TASK_ADDED_EVENT,
  UPDATE_AREA_DATA,
} from "@/variables/events";
import isEscKey from "@/utils/isEscKey";
import { isSubmitKeys, isTabKey } from "@/utils/keys";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
import popupHelpersMixin from "@/mixins/popupHelpersMixin";
import {
  changeCatOfProjectResources,
  getProjectResourcesFromText,
} from "@/helpers/resources";
import { refreshSubTasksTable } from "@/helpers/tasks";
export default {
  mixins: [tasksHelpersMixin, projectsHelpersMixin, popupHelpersMixin],
  components: {
    PrioritySelector,
  },
  data() {
    return {
      selectedVal: "",
    };
  },
  methods: {
    handleSelectorKeyDown(e) {
      e.stopPropagation();
      e.paths = getPathsFromEvent(e);
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
    handleKeyDown(e) {
      e.stopPropagation();
      let paths = getPathsFromEvent(e);

      if (isEmpty(paths)) {
        paths = !isEmpty(e.paths) ? e.paths : [];
      }
      if (isEscKey(e)) {
        this.closeDialog();
      }

      if (isSubmitKeys(e)) {
        const submitBtnParentFound = findParentFromPathsByClassName(paths, [
          "save-confirm-btn",
        ]);
        if (submitBtnParentFound && !e.shiftKey) {
          this.submitData();
          return;
        }
      }

      if (isTabKey(e)) {
        const priortySelectorElFound = findParentFromPathsByClassName(paths, [
          "area-priority-selector-wrapper",
        ]);
        if (priortySelectorElFound && !e.shiftKey) {
          const el = this.$refs.prioritySelector;

          el?.blurInput?.();
          const btn = document.querySelector(
            ".area-priority-setter-dialog .save-confirm-btn"
          );
          btn?.focus();
        }
      }
    },
    closeDialog() {
      this.resetDialogData();
      this.clearVal();
    },
    async submitData() {
      const selectedVal = this.selectedVal;
      let addEnabled = false;
      let taskDataAdded = {};
      if (!selectedVal) {
        this.$swal({
          titleText: "Oops!",
          html: "Priority is required",
          icon: "error",
        });
        return;
      }

      const dbUpdates = {};
      const projectEditData = {
        priority: "",
        changedProps: {
          priority: selectedVal,
        },
        key: this.areaId,
      };

      dbUpdates[`/projects/${this.areaId}/priority`] = selectedVal;

      if (this.isAddMode) {
        const taskData = { ...this.taskList[0] };
        let taskPosition = 0;
        let updatedTasksListOfProject =
          this.projects[taskData.project].tasks || [];

        if (Array.isArray(updatedTasksListOfProject)) {
          updatedTasksListOfProject = [
            ...updatedTasksListOfProject,
            taskData.key,
          ];
        }
        if (
          !isEmpty(this.projects[this.areaId]) &&
          !isEmpty(this.projects[this.areaId].tasks)
        ) {
          taskPosition = this.projects[taskData.project].tasks.length;
        }
        dbUpdates[`/projects/${this.areaId}/tasks/${taskPosition}`] =
          taskData.key;

        projectEditData.tasks = this.projects[this.areaId].tasks || [];
        projectEditData.changedProps.tasks = updatedTasksListOfProject;
        taskData.project = this.areaId;

        if (this.areaId) {
          const mentionedProjectResources = getProjectResourcesFromText(
            taskData.title
          );

          if (!isEmpty(mentionedProjectResources)) {
            changeCatOfProjectResources([
              {
                resources: mentionedProjectResources,
                catId: this.areaId,
                isTaskRef: true,
              },
            ]);
          }
        }
        const userBatchActions = [
          {
            type: PROJECT_ACTIONS.EDIT,
            data: projectEditData,
            on: "project",
          },
        ];

        addEnabled = true;

        DatabaseInterface.update(dbUpdates, this.userInfo.uid);

        const createdTaskData = await this.addNewTaskEntry(taskData, false);
        taskDataAdded = { ...createdTaskData };
        userBatchActions.push({
          type: TASK_ACTIONS.BATCH_ADD,
          data: [createdTaskData],
          on: "task",
        });
        getUserActions().addBatchAction({
          data: userBatchActions,
          on: "all",
        });
      } else {
        let dataToUpdate = {
          project: this.areaId,
        };

        if (!isEmpty(this.taskUpdates)) {
          dataToUpdate = {
            ...this.taskUpdates,
            ...dataToUpdate,
          };
        }
        dataToUpdate.projectUpdateData = this.createProjectPriorityDbData(
          { priority: this.selectedVal },
          this.areaId
        );

        this.updateRecurringOrNormalTasks(
          this.taskList,
          dataToUpdate,
          undefined,
          true
        );
      }

      EventEmitter.emit(UPDATE_AREA_DATA, { priority: selectedVal });

      if (addEnabled) {
        EventEmitter.emit(CLOSE_INLINE_ADD);
        EventEmitter.emit(TASK_ADDED_EVENT, taskDataAdded);
        refreshSubTasksTable();
      }

      this.closeDialog();
      // this.resetDialogData();
      // this.clearVal();
    },
    clearVal() {
      this.selectedVal = "";
    },
    focusOnInput() {
      this.focusOnSelector("prioritySelector");
    },
    ...mapActions("areaPrioritySetterData", ["resetDialogData"]),
  },
  computed: {
    ...mapGetters("task", ["projects"]),
    ...mapGetters("areaPrioritySetterData", [
      "isOpen",
      "areaId",
      "taskList",
      "isAddMode",
      "taskUpdates",
    ]),
  },
  watch: {
    isOpen(n) {
      if (n) {
        this.focusOnInput();
      }
    },
  },
};
</script>
