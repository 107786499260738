<template>
  <TipLayout :isVisible="true" @tip:closed="hideTip">
    <div class="d-flex align-center content-wrapper">
      <span class="selection-tip-label mr-2 primary-text"> tip </span>
      <span>Select</span>&nbsp; a category on the left to view its related
      category activities

      <template v-if="!isGraphEnabled">
        click&nbsp;
        <span class="task-order-number-text-wrapper my-0 mx-1">1</span>&nbsp;to
        organize the activities for a specific&nbsp;
        <i>area of activity.</i>&nbsp;
      </template>
    </div>
  </TipLayout>
  <!-- <div class="d-flex align-center flex-wrap">

      <span class="selection-tip-label mr-2"> tip </span>
      <span class="primary--text">Select</span>&nbsp;a category on the left to
      view its related category activities

      <template v-if="!isGraphEnabled">
        click&nbsp;<span class="task-order-number-text-wrapper my-0 mx-1"
          >1</span
        >&nbsp;to organize the activities for a specific&nbsp;
        <i>area of activity.</i>&nbsp;
      </template>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            :attrs="attrs"
            icon
            small
            depressed
            @click.stop="hideTip"
          >
            <v-icon :color="greyIconColor" size="22">mdi-close-circle</v-icon>
          </v-btn>
        </template>
        <span> Hide tip </span>
      </v-tooltip>

    </div> -->
</template>
<script>
import TipSvg from "@/assets/icons/svg/tip.svg";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
import { setAreasTipStatus } from "@/helpers/tips";
import { mapActions, mapGetters } from "vuex";
import TipLayout from "@/components/TipLayout.vue";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TipSvg,
    TipLayout,
  },
  props: {
    isGraphEnabled: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions(["updateRootState"]),
    hideTip() {
      setAreasTipStatus(!this.isAreasTipHidden);
      this.updateRootState({
        isAreasTipHidden: !this.isAreasTipHidden,
      });
    },
  },
  computed: {
    ...mapGetters(["isAreasTipHidden"]),
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
  },
};
</script>
<style scoped>
.select-tip {
  margin-bottom: 1px;
}

.content-wrapper {
  /* border: 1px solid red; */
  font-size: 12px;
}
</style>
