<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        small
        @click="handleClick"
        :tabindex="tabIndexToUse"
        @keydown="handleKeyDown"
        :class="cssClass"
        :disabled="disabled"
      >
        <v-icon :color="iconData.color" :size="iconSize">
          {{ iconData.icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ iconData.label }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    cssClass: {
      type: String,
    },
    iconSize: {
      type: [String, Number],
    },
    isFocusable: {
      type: Boolean,
      default: true,
    },
    iconData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    handleClick(e) {
      e.stopPropagation();
      this.$emit("input", !this.value);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
  },
  computed: {
    tabIndexToUse() {
      return !this.isFocusable ? "-1" : undefined;
    },
  },
};
</script>
