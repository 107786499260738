import resourcesActions from "./actions";
import resourcesMutators from "./mutations";
import resourcesGetters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      processedResources: [],
      rawResourcesMap: {},
      categories: {},
      resourcesCount: 0,
      selectedCategories: [],
      selectedResources: [],
      filteredResources: [],
      categoriesTree: [],
      cateChildrenMap: {},
      resourcesTasksMap: {},
      selectedResourceTypeOpt: "",
      collapsedGroupsList: [],
      internalCollapsedGroups: [],
      query: "",
      filterResources: [],
      rawSelectedCategoriesMap: {},
    };
  },
  actions: resourcesActions,
  mutations: resourcesMutators,
  getters: resourcesGetters,
};
