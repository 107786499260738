import editTaskDialogActions from "./actions";
import editTaskDialogMutations from "./mutations";
import editTaskDialogGetters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      isOpen: false,
      taskData: {},
      selectedTasks: [],
      updatedTaskData: {},
      isEditConfirmBoxOpened: false,
      updatedProjectData: {},
      updatedObjectiveData: {},
      resourceDataChanges: {},
      noteData: {},
      resourceData: {},
      selectedResourceId: "",
      returnViewInfo: {},
      openNewForm: false,
      taskId: null,
      projectId: null,
    };
  },
  mutations: editTaskDialogMutations,
  actions: editTaskDialogActions,
  getters: editTaskDialogGetters,
};
