<template>
  <RichTextEditor
    ref="editor"
    editorId="desription-editor"
    :value="value"
    @input="handleChange"
    mentionMenuPosition="fixed"
    @mention-removed="handleMentionRemoved"
    @keydown="handleKeyDown"
    :placeholder="placeholder"
    :enableMentions="enableMentions"
    :mentionOpts="mentionOpts"
    :addSpaceOnEnter="addSpaceOnEnter"
  />
</template>
<script>
import RichTextEditor from "@/components/RichTextEditor";
import EventEmitter from "@/helpers/eventEmitter";
import { CLOSE_RESOURCE_POPUP } from "@/variables/events";
export default {
  components: {
    RichTextEditor,
  },
  props: {
    value: {
      type: String,
    },
    refSetter: {
      type: Function,
    },
    enableMentions: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    mentionOpts: {
      type: Object,
    },
    addSpaceOnEnter: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.refSetter) {
      this.refSetter(this.$refs.editor);
    }
  },
  methods: {
    handleChange(val, oldDelta, newDelta) {
      this.$emit("input", val, oldDelta, newDelta);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },

    handleMentionRemoved() {
      EventEmitter.emit(CLOSE_RESOURCE_POPUP);
    },
  },
};
</script>
