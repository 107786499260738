import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"priority-selector-wrapper"},[_c('SelectorInput',{ref:"select",staticClass:"task-priority-selector",attrs:{"opts":_vm.priorityOpts,"value":_vm.value,"openOnFocus":_vm.openOnFocus},on:{"input":_vm.handleValueChange,"keydown":_vm.handleKeyDown,"focus":_vm.handleFocus},scopedSlots:_vm._u([{key:"activator-btn",fn:function(ref){
var handleClick = ref.handleClick;
return [_c(VBtn,{class:_vm.selectorBtnClasses,attrs:{"color":_vm.selectedPriorityColor,"dark":"","min-width":"15px","small":""},on:{"click":handleClick}})]}},{key:"item-opt",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({on:{"keydown":_vm.handleKeyDown}},'v-list-item',attrs,false),on),[_c(VIcon,{staticClass:"mr-3",attrs:{"color":item.color}},[_vm._v(" mdi-circle ")]),_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }