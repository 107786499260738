import { mapGetters } from "vuex";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { treeSideBarModes } from "@/variables/viewConfigs";
import taskHelpersMixin from "@/mixins/tasksHelpersMixin";

export const NAV_ITEMS = {
  CALENDER: 0,
  AREA: 1,
  CONTEXTS: 2,
  NOTES: 3,
  INBOX: 4
}

export default {
  mixins: [userDetailsMixin, taskHelpersMixin],
  data() {
    return {
      navIndex: 0
    }
  },
  methods: {
    setNavValue() {
      this.navIndex = this.getNavValue(); 
    },
    getNavValue() {
      let nav = this.nav;

      if (this.isNotesView || this.$route.name === "Notes") {
        nav = 3;
      } else if (
        this.currActiveView === "resources" ||
        this.$route.name === "Resources"
      ) {
        nav = 2;
      } else if (this.isInboxViewEnabled) {
        nav = 4;
      } else if (nav === 0 || nav === 3) {
        nav = 0;
      } else if (
        !this.isInboxViewEnabled &&
        (this.isProjectsResourceModeEnabled || treeSideBarModes.includes(nav))
      ) {
        nav = 1;
      }

      return nav;
    }
  },
  watch: {
    nav: {
      handler() {
        this.setNavValue();
      },
      immediate: true,
    },
    isInboxViewEnabled() {
      this.setNavValue();
    },
    isNotesView() {
      this.setNavValue();
    },
    currActiveView() {
      this.setNavValue();
    },
    isProjectsResourceModeEnabled() {
      this.setNavValue();
    },
  },
  computed: {
    ...mapGetters("task", {
      isInboxViewEnabled: "isInboxViewEnabled",
      isProjectsResourceModeEnabled: "isProjectsResourceModeEnabled",
    }),
    ...mapGetters(["isNotesView", "nav", "currActiveView"]),
  },
};