<template>
  <div class="d-flex resource-type-selector-wrapper">
    <v-tooltip v-for="(btnData, index) in resourceTypeOpts" :key="index" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          v-bind="attrs"
          v-on="on"
          @click="handleClick($event, btnData)"
          :key="index"
          class="resource-type-change-btn"
          :class="{
            selected: selectedFilterOptIndex === index,
            [`${btnData.key}-nav-btn`]: true,
          }"
          data-action-type="resource-type-change"
          :data-action-value="btnData.key"
        >
          <v-icon
            left
            v-if="btnData.icon"
            :color="getBtnIconColor(btnData, index)"
            size="16"
            class="resource-type-icon"
          >
            {{ btnData.icon }}
          </v-icon>
        </v-btn>
      </template>

      <span> {{ btnData.title }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import Vue from "vue";
import {
  resourceTypeFilterIndexs,
  resourceTypeFilterOptList,
} from "@/data/resources";
import { TOGGLE_ORDERED_TASKS } from "@/variables/events";
import { mapActions, mapGetters } from "vuex";
import { getUserResources } from "@/helpers/resources";
import EventEmitter from "@/helpers/eventEmitter";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
export default {
  mixins: [tasksHelpersMixin],
  methods: {
    ...mapActions("resourcesData", ["setResourceTypeOpt"]),
    ...mapActions("task", ["setAdditionalTaskFilters"]),
    ...mapActions(["updateRootState"]),
    async handleClick(_, itemData) {
      this.hideTempVisibleTasks();
      this.handleResourceTypeSelect(itemData.key);

      this.setAdditionalTaskFilters({
        key: "resourceTypes",
        list: [itemData.key],
      });
      this.updateRootState({
        currViewData: {},
        itemInspectEnabled: false,
      });

      await Vue.nextTick();
      EventEmitter.emit(TOGGLE_ORDERED_TASKS, true);
    },
    handleResourceTypeSelect(val) {
      getUserResources().set(val, "selectedResourceType");
    },
    // createBtnStyles(btnData, index) {
    //   let basicStyles = {
    //     background: "#E8E8E8",
    //   };
    //   if (this.selectedFilterOptIndex === index) {
    //     const activeStyles = btnData.activeStyles;
    //     if (activeStyles?.borderColor) {
    //       // basicStyles.border = `1.2px solid ${activeStyles.borderColor} !important`;
    //     }
    //     // basicStyles.background = btnData.bgColor;
    //     basicStyles.background = activeStyles?.borderColor;
    //     // basicStyles.color = "#fff";
    //   }
    //   return basicStyles;
    // },
    getBtnIconColor(btnData, index) {
      return this.selectedFilterOptIndex === index
        ? // ? btnData.iconColor
          "#fff"
        : "#878787";
    },
    getSelectedBtn() {
      const allResourceOpts = resourceTypeFilterOptList.slice();
      allResourceOpts.splice(0, 1);
      return allResourceOpts.find(
        (__, index) => this.selectedFilterOptIndex === index
      );
    },
  },
  computed: {
    ...mapGetters("resourcesData", ["selectedResourceTypeOpt"]),
    resourceTypeOpts() {
      const list = resourceTypeFilterOptList.slice();

      list.splice(0, 1);
      return list;
    },
    selectedFilterOptIndex() {
      return resourceTypeFilterIndexs[this.selectedResourceTypeOpt];
    },
  },
  created() {
    const selectedResourceType = this.getSelectedBtn();

    if (selectedResourceType) {
      this.setAdditionalTaskFilters({
        key: "resourceTypes",
        list: [selectedResourceType.key],
      });
    }
    // console.log(selectedResourceType)
  },
};
</script>

<style lang="scss">
.resource-type-selector-wrapper button {
  height: 26px !important;
  width: 26px !important;
  text-transform: capitalize !important;
  font-weight: 400;
  color: #000;
  //border: 1.2px solid transparent;
  padding: 0px 6px !important;
  min-width: auto !important;
  background-color: #e8e8e8 !important;
}
.resource-type-selector-wrapper button:first-child {
  padding-left: 8px !important;
}
.resource-type-selector-wrapper button:last-child {
  padding-right: 8px !important;
}

.resource-type-selector-wrapper .v-btn {
  border-radius: 0px;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.resource-type-selector-wrapper button .resource-type-icon {
  margin: 0px !important;
}

.project-nav-btn.selected {
  background-color: var(--project-muted) !important;
}
.people-nav-btn.selected {
  background-color: var(--people-muted) !important;
}
.place-nav-btn.selected {
  background-color: var(--place-muted) !important;
}
.thing-nav-btn.selected {
  background-color: var(--thing-muted) !important;
}
</style>
