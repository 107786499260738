<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        text
        v-bind="attrs"
        v-on="on"
        class="action-btn task-bar-action-btn settings-btn"
        :class="{
          'btn-is-active': attrs['aria-expanded'] === 'true',
        }"
        ref="settingsBtn"
        data-action-type="open-settings"
      >
        <v-icon size="22"> $ctiSetting </v-icon>
      </v-btn>
    </template>
    <v-list>
      <!-- <v-list-item-group> -->
      <template v-for="(item, index) in userOptions">
        <v-list-item
          :key="index"
          v-on:click="menuSelect(item)"
          v-if="isMenuItemVisible(item)"
        >
          <!-- <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon> -->
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
      <!-- </v-list-item-group> -->
    </v-list>
  </v-menu>
</template>
<script>
import { mapActions } from "vuex";
import userDetailsMixin from "@/mixins/userDetailsMixin";

export default {
  name: "SettingsMenu",
  mixins: [userDetailsMixin],
  data() {
    return {
      userOptions: [
        {
          title: "Settings",
          name: "account-settings",
          isVisible: () => true,
        },
        // {
        //   title: "Color Theme",
        //   name: "color-theme",
        //   isVisible: () => true,
        // },
        // {
        //   title: "List Options",
        //   name: "list-options",
        //   isVisible: () => true,
        // },
        // {
        //   title: "Details Pane",
        //   name: "details-pane-settings",
        //   isVisible: () => true,
        // },
        // {
        //   title: "Google Calendar",
        //   name: "google-cal-settings",
        //   isVisible: () => true,
        // },
        {
          title: "Sign Out",
          icon: "mdi-cancel",
          name: "sign-out",
          isVisible: () => true,
        },
      ]
    };
  },
  props: {
    color: String
  },
  methods: {
    menuSelect(item) {
      switch (item.name) {
        case "sign-out":
          this.handleUserSignout();

          break;

        default:
          this.toggleSettingsDialog({
            settingsToOpen: item.name,
          });
      }
    },
    isMenuItemVisible(item) {
      return item.isVisible ? item.isVisible() : true;
    },
    ...mapActions([
      "toggleSettingsDialog",
    ]),
  },
}
</script>
<style scoped>
.settings-btn {
  padding: 0 13px !important;
  min-width: auto !important;
}
</style>