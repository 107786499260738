<template>
  <div class="fill-height">
    <v-card-title> {{ label }} </v-card-title>
    <v-card-text>
      <slot name="content"> </slot>
    </v-card-text>
    <v-card-actions>
      <slot name="actions"> </slot>
    </v-card-actions>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
  },
};
</script>
