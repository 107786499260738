<template>
  <div
    id="calendar"
    @mouseup="handleMouseUp"
    @mousedown="handleMouseDown"
    @keydown="handleKeyDown"
    tabindex="0"
  >
    <v-row
      v-show="isInCalMode"
      align="center"
      no-gutters
      justify="center"
      class="forecast-selector-wrapper"
    >
      <v-col cols="auto">
        <CalForecast @forecast:set="handleForecast" />
      </v-col>
    </v-row>
    <v-row class="month-text-wrapper">
      <button
        @click="substractOneMonth"
        data-action-type="cal-month-change"
        class="cal-month-change-btn"
        data-action-value="previous"
      >
        <v-icon>mdi-skip-backward</v-icon>
      </button>
      <v-spacer></v-spacer>
      <span class="text-center month">
        {{ format(currentMonth, "MMM yyyy") }}
      </span>
      <v-spacer></v-spacer>
      <button
        class="float-right cal-month-change-btn"
        @click="addOneMonth"
        data-action-type="cal-month-change"
        data-action-value="next"
      >
        <v-icon>mdi-skip-forward</v-icon>
      </button>
    </v-row>
    <div class="week-days mr-3 ml-3">
      <p v-for="weekName in weekNames" :key="weekName">{{ weekName }}</p>
    </div>

    <div class="days mr-3 ml-3">
      <div
        v-for="(day, index) in daysOfCurrentMonth"
        :key="index"
        :id="formatToDate(day)"
        class="day cal-day"
        :class="{
          today: isToday(day),
          selected: isSelected(day),
        }"
        :style="`grid-column: column(${index}); 
                    grid-column-start: ${index === 0 ? weekdayOffset : '0'}; 
                    color: black;
                    `"
        style="line-height: 1 !important"
        @drop="
          dragExit($event);
          onDrop($event);
        "
        @dragover.prevent
        @dragover="dragOver($event)"
        @dragleave="dragExit($event)"
        data-action-type="cal-date-select"
        :data-action-value="formatToDate(day)"
        @click.exact="selectSingleDate($event)"
        @click.shift.exact="selectRangeDate($event)"
        @click.ctrl.exact="selectMultipleDate($event)"
        @click.meta.exact="selectMultipleDate($event)"
        @mousedown.left.exact="enableCellDragSelect"
        @mouseover.left.exact="handleCellMouseOver"
      >
        <label class="text-caption ml-1 mr-1 unselectable date">
          {{ formatDay(day) }}
        </label>
        <p
          class="text-center unselectable task-count-day"
          v-if="readTaskCount(day)"
        >
          {{ readTaskCount(day) }}
        </p>
        <p v-else class="task-count-day"></p>
      </div>
    </div>
    <p class="text-center mt-5 month">
      {{ format(nextMonth, "MMM yyyy") }}
    </p>
    <div class="days mr-3 ml-3">
      <div
        v-for="(day, index) in daysOfNextMonth"
        :key="index"
        :id="formatToDate(day)"
        class="day cal-day"
        :class="{
          today: isToday(day),
          selected: isSelected(day),
        }"
        :style="`grid-column: column(${index}); 
                    grid-column-start: ${
                      index === 0 ? weekdayOffsetNext : '0'
                    }; 
                    color: black;
                    background-color: ${
                      isSelected(day)
                        ? 'rgb(190, 183, 183)'
                        : 'rgba(255, 255, 255, 0.2)'
                    }`"
        style="line-height: 1 !important"
        @drop="
          dragExit($event);
          onDrop($event);
        "
        @dragover.prevent
        @dragover="dragOver($event)"
        @dragleave="dragExit($event)"
        @click.exact="selectSingleDate($event)"
        @click.ctrl="selectMultipleDate($event)"
        @click.shift="selectRangeDate($event)"
        @click.meta.exact="selectMultipleDate($event)"
        @mousedown.left.exact="enableCellDragSelect"
        @mouseover.left.exact="handleCellMouseOver"
        data-action-type="cal-date-select"
        :data-action-value="formatToDate(day)"
      >
        <label class="text-caption ml-1 mr-1 unselectable date">
          {{ formatDay(day) }}
        </label>
        <p
          class="text-center unselectable task-count-day"
          v-if="readTaskCount(day)"
        >
          {{ readTaskCount(day) }}
        </p>
        <p v-else class="task-count-day"></p>
      </div>
    </div>
    <context-menu target="#calendar" :items="menuItems" />
  </div>
</template>

<script>
import {
  startOfMonth,
  addMonths,
  format,
  subMonths,
  addDays,
  startOfWeek,
  sub,
  add,
  eachDayOfInterval,
  getDay,
  isToday,
} from "date-fns";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import {
  formatDate,
  isValidDate,
  parseAndFormatDate,
  parseDate,
  sortDateArray,
} from "@/helpers/dates";
import { createViewDataForDb } from "@/helpers/tasks";
import ContextMenu from "@/core/components/ContextMenu";
import ContextMenuOpts from "./contextMenuOpts";
import { getPathsFromEvent } from "@/utils/events";
import { INTERNAL_DATE_FORMAT, DAY_FORMAT } from "@/variables/dates";
import isAfter from "date-fns/isAfter";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import isEmpty from "lodash/isEmpty";
import { NAVIGATION_KEYS } from "@/variables/keys";
import { parseISO } from "date-fns/esm";
import subDays from "date-fns/subDays";
import isCtrlKey from "@/utils/isCtrlKey";
import isBefore from "date-fns/isBefore";
import isEqual from "lodash/isEqual";
import differenceInMonths from "date-fns/differenceInMonths";
import { decrement } from "@/utils/number";

import CalForecast from "./CalForecast.vue";
import { calModes } from "@/variables/viewConfigs";

const isSelected = (key, selectedDates) => {
  return selectedDates.includes(format(new Date(key), INTERNAL_DATE_FORMAT));
};

export default {
  components: { ContextMenu, CalForecast },
  props: {
    navMode: Number,
    changeView: Function,
  },
  mixins: [userDetailsMixin, tasksHelpersMixin],
  data() {
    return {
      currentMonth: startOfMonth(new Date()),
      firstDayOfWeek: startOfWeek(new Date(), {
        weekStartsOn: 0, // monday
      }),
      taskCounts: {},
      selectedDates: [],
      dateRange: [],
      menuItems: [
        { text: "Add New Task", id: ContextMenuOpts.ADD_SCHEDUKED_TASK },
      ],
    };
  },
  computed: {
    isInCalMode() {
      return calModes.includes(this.currNav);
    },
    makeAllTasksCountVisible() {
      return this.showCompleted;
    },
    previousMonth() {
      return startOfMonth(subMonths(new Date(this.currentMonth), 1));
    },
    nextMonth() {
      return startOfMonth(addMonths(new Date(this.currentMonth), 1));
    },
    daysOfCurrentMonth() {
      return eachDayOfInterval({
        start: this.currentMonth,
        end: sub(this.nextMonth, { days: 1 }),
      });
    },

    daysOfNextMonth() {
      return eachDayOfInterval({
        start: startOfMonth(addMonths(new Date(this.currentMonth), 1)),
        end: sub(startOfMonth(addMonths(new Date(this.currentMonth), 2)), {
          days: 1,
        }),
      });
    },
    startDate() {
      return this.daysOfCurrentMonth[0];
    },
    endDate() {
      return this.daysOfNextMonth[decrement(this.daysOfNextMonth.length, 1)];
    },
    weekNames() {
      return [...Array(7)].map((_, index) =>
        format(addDays(this.firstDayOfWeek, index), "EEEEEE")
      );
    },
    weekdayOffset() {
      return (getDay(this.currentMonth) + 8) % 7 || 7; // `|| 7` is basically for sunday, edge case
    },
    weekdayOffsetNext() {
      return (getDay(this.nextMonth) + 8) % 7 || 7; // `|| 7` is basically for sunday, edge case
    },
    ...mapGetters("task", {
      selectedDate: "selectedDates",
      tasks: "tasks",
      isInspectModeEnabled: "isInspectModeEnabled",
      currNav: "currNav",
      isGlobalViewEnabled: "isGlobalViewEnabled",
      showCompleted: "showClearedTasks",
    }),
    ...mapGetters("editForm", {
      isTaskEditFormOpened: "isOpen",
    }),
    ...mapGetters(["isGoogleCalSyncEnabled"]),
  },
  methods: {
    ...mapActions("task", ["updateSelectedTasks"]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions(["updateRootState"]),
    async handleKeyDown(e) {
      const keyCode = e.which ? e.which : e.keyCode;
      if (!NAVIGATION_KEYS.includes(keyCode)) return;
      e.stopPropagation();
      e.preventDefault();
      let dateToUse,
        changeDate = true;

      if (!isEmpty(this.selectedDate)) {
        dateToUse = this.selectedDate[decrement(this.selectedDate.length, 1)];
        if (e.shiftKey && !isCtrlKey(e)) {
          if (keyCode === 38) {
            dateToUse = this.selectedDate[0];
          }
        }
      }

      const parsedDate = parseISO(dateToUse);
      if (isValidDate(parsedDate)) {
        let newDate = parsedDate;

        if (changeDate) {
          if (keyCode === 37) {
            newDate = subDays(parsedDate, 1);
          }
          if (keyCode === 39) {
            newDate = addDays(parsedDate, 1);
          }

          if (keyCode === 38) {
            newDate = subDays(parsedDate, 7);
          }

          if (keyCode === 40) {
            newDate = addDays(parsedDate, 7);
          }
        }

        const isNewDateBeforeStartDate = isBefore(newDate, this.startDate);
        const isNewDateAfterEndDate = isAfter(newDate, this.endDate);
        if (isNewDateBeforeStartDate || isNewDateAfterEndDate) {
          this.changeCalMonth(isNewDateBeforeStartDate ? "previous" : "next");
        }

        await this.$nextTick();
        const formattedDate = formatDate(newDate, INTERNAL_DATE_FORMAT);
        if (!isCtrlKey(e) && !e.shiftKey) {
          this.selectSingleDate({}, formattedDate);
          return;
        }

        if (e.shiftKey && !isCtrlKey(e)) {
          this.selectRangeDate({}, formattedDate);
          return;
        }
      }
    },
    addOrRemoveDateInRange(dateToUse) {
      if (this.selectedDates.includes(dateToUse)) {
        this.selectedDates.splice(this.selectedDates.indexOf(dateToUse), 1);
      } else {
        this.selectedDates.push(dateToUse);
      }
    },
    handleMouseDown() {
      this.elIsInFocus = true;
    },
    enableCellDragSelect(e) {
      if (this.isTaskEditFormOpened) return;
      this.dateRange = [];
      const { selected } = this.getHoveredDateFromPaths(e);
      if (selected) {
        this.cellDragEnabled = true;
        if (!this.dateRange.includes(selected)) {
          this.dateRange.push(selected);
        }
      }
      return false;
    },
    handleCellMouseOver(e) {
      if (this.cellDragEnabled) {
        const { selected: hoveredDate, selectedEl: hoveredEl } =
          this.getHoveredDateFromPaths(e);

        if (hoveredEl) {
          if (!this.dateRange.includes(hoveredDate)) {
            this.dateRange.push(hoveredDate);
          }
        }
      }
    },
    handleMouseUp() {
      if (this.cellDragEnabled) {
        this.cellDragEnabled = false;
        let selectedDates = [...this.dateRange];
        if (selectedDates.length > 1) {
          selectedDates = sortDateArray(selectedDates);
          this.setSelectedDates(selectedDates);
        }
      }
    },
    changeCalMonth(type) {
      if (type === "next") {
        this.addOneMonth();
      }

      if (type === "previous") {
        this.substractOneMonth();
      }
    },
    formatToDate(date) {
      return format(date, INTERNAL_DATE_FORMAT);
    },
    daysInRange(firstDay, lastDay) {
      return eachDayOfInterval({
        start: new Date(firstDay),
        end: new Date(lastDay),
      });
    },
    formatDay(day) {
      return format(day, DAY_FORMAT);
    },
    readTaskCount(day) {
      const date = format(day, INTERNAL_DATE_FORMAT);
      let count = 0;

      if (this.makeAllTasksCountVisible) {
        count = this.taskCounts[date]?.all;
      } else {
        count = this.taskCounts[date]?.incomplete;
      }
      return count ?? 0;
    },
    createTaskCountMap(tasks) {
      const taskCountMap = {};

      for (let task of tasks) {
        if (task.due === "none" || task.isReminderTask || task.showReminder) {
          continue;
        }

        const date = task.due;
        if (!taskCountMap[date]) {
          taskCountMap[date] = {
            all: 0,
            incomplete: 0,
          };
        }

        taskCountMap[date].all += 1;
        if (!task.completed) {
          taskCountMap[date].incomplete += 1;
        }
      }
      return taskCountMap;
    },
    format,
    isToday,
    isSelected(key) {
      return (
        isSelected(key, this.selectedDate) || isSelected(key, this.dateRange)
      );
    },
    substractOneMonth() {
      this.currentMonth = sub(this.currentMonth, { months: 1 });
    },
    addOneMonth() {
      this.currentMonth = add(this.currentMonth, { months: 1 });
    },
    resetToToday() {
      this.currentMonth = startOfMonth(new Date());
    },
    onDrop(event) {
      const tasks = JSON.parse(event.dataTransfer.getData("text"));
      let newDate = "";

      const paths = (event.composedPath && event.composedPath()) || event.path;

      for (const path of paths) {
        if (path.id && isValidDate(new Date(path.id))) {
          newDate = parseAndFormatDate(
            path.id,
            INTERNAL_DATE_FORMAT,
            INTERNAL_DATE_FORMAT
          );
          break;
        }
      }
      if (newDate) {
        const data = {
          tasks,
          changeTo: newDate,
          field: "due",
        };
        this.changeDateOfTasks(data);
      }
      // this.setSelectedDates(data);
      // this.$emit("updateDueDate", data);
    },
    async changeDateOfTasks(data) {
      if (data.tasks && data.tasks.length) {
        // const tasks = data.tasks;

        this.updateRecurringOrNormalTasks(
          data.tasks,
          { due: data.changeTo },
          true
        );

        this.updateSelectedTasks([]);
      }
    },
    getHoveredPaths(event) {
      return (event.composedPath && event.composedPath()) || event.path;
    },
    dragOver(event) {
      const paths = this.getHoveredPaths(event);
      let hoveredEl;
      for (const path of paths) {
        if (path.id && isValidDate(new Date(path.id))) {
          hoveredEl = path;
          break;
        }
      }

      hoveredEl?.classList.add("hovered");
    },
    dragExit(event) {
      const paths = this.getHoveredPaths(event);
      let hoveredEl;
      for (const path of paths) {
        if (path.id && isValidDate(new Date(path.id))) {
          hoveredEl = path;
          break;
        }
      }

      hoveredEl?.classList.remove("hovered");
    },
    getHoveredDateFromPaths(event) {
      const paths = getPathsFromEvent(event);
      let selected, selectedEl;
      for (const path of paths) {
        if (path.id != "" && isValidDate(new Date(path.id))) {
          selected = parseAndFormatDate(
            path.id,
            INTERNAL_DATE_FORMAT,
            INTERNAL_DATE_FORMAT
          );
          selectedEl = path;
          break;
        }
      }

      return { selected, selectedEl };
    },
    selectRangeDate(event, dateToUse) {
      let selected;
      this.dateRange = [];
      let dateRange = [];

      if (!isEmpty(event)) {
        const paths = getPathsFromEvent(event);
        for (const path of paths) {
          if (path.id != "" && isValidDate(new Date(path.id))) {
            selected = parseAndFormatDate(
              path.id,
              INTERNAL_DATE_FORMAT,
              INTERNAL_DATE_FORMAT
            );
            break;
          }
        }
      } else if (dateToUse) {
        selected = dateToUse;
      }

      const isCurrSelectedDateValid =
        this.selectedDates[0] && isValidDate(new Date(this.selectedDates[0]));
      const isSelectedDateValid = selected && isValidDate(new Date(selected));

      if (isCurrSelectedDateValid && isSelectedDateValid) {
        const parsedCurrSelectedDate = parseDate(
          this.selectedDates[0],
          INTERNAL_DATE_FORMAT
        );
        const parsedSelectedDate = parseDate(selected, INTERNAL_DATE_FORMAT);
        if (isAfter(parsedSelectedDate, parsedCurrSelectedDate)) {
          dateRange = this.daysInRange(
            parsedCurrSelectedDate,
            parsedSelectedDate
          );
        } else {
          dateRange = this.daysInRange(
            parsedSelectedDate,
            parsedCurrSelectedDate
          );
        }
      } else if (isSelectedDateValid) {
        const parsedDate = parseDate(selected, INTERNAL_DATE_FORMAT);
        dateRange = this.daysInRange(parsedDate, parsedDate);
      } else if (isCurrSelectedDateValid) {
        const parsedDate = parseDate(
          this.selectedDates[0],
          INTERNAL_DATE_FORMAT
        );
        dateRange = this.daysInRange(parsedDate, parsedDate);
      }

      let data = [];
      for (const date of dateRange) {
        data.push(format(date, INTERNAL_DATE_FORMAT));
      }

      this.setSelectedDates(data);
    },
    async selectMultipleDate(event) {
      let selected;
      this.dateRange = [];

      if (!isEmpty(event)) {
        const paths = getPathsFromEvent(event);
        for (const path of paths) {
          if (path.id != "" && isValidDate(new Date(path.id))) {
            selected = parseAndFormatDate(
              path.id,
              INTERNAL_DATE_FORMAT,
              INTERNAL_DATE_FORMAT
            );
            break;
          }
        }

        this.addOrRemoveDateInRange(selected);
        // if (this.selectedDates.includes(selected)) {
        //   this.selectedDates.splice(this.selectedDates.indexOf(selected), 1);
        // } else {
        //   this.selectedDates.push(selected);
        // }
      }

      await this.$nextTick();
      let data = [];
      for (let i = 0; i < this.selectedDates.length; i++) {
        data.push(
          parseAndFormatDate(
            this.selectedDates[i],
            INTERNAL_DATE_FORMAT,
            INTERNAL_DATE_FORMAT
          )
        );
      }

      data = sortDateArray(data);
      this.setSelectedDates(data);
    },

    // extractDate(date) {
    //   const dateObj = new Date(date);

    //   console.debug("DT", dateObj.toISOString());
    //   return dateObj.toISOString().slice(0, 10);
    // },
    selectSingleDate(event, dateToUse) {
      let selected;
      const isEditFormOpened = this.isTaskEditFormOpened;
      if (!isEmpty(event)) {
        if (!event.isTrusted || isEditFormOpened) {
          this.cellDragEnabled = false;
          this.dateRange = [];
        }
        const paths = getPathsFromEvent(event);

        for (const path of paths) {
          if (path.id != "" && isValidDate(new Date(path.id))) {
            selected = parseAndFormatDate(
              path.id,
              INTERNAL_DATE_FORMAT,
              INTERNAL_DATE_FORMAT
            );
            break;
          }
        }
      } else if (dateToUse) {
        selected = dateToUse;
      }

      let data = [selected];
      if (this.enableCellDragSelect && !isEmpty(this.dateRange)) {
        this.dateRange = [];
      }

      // if (!this.selectedDates.includes(selected)) {
      this.selectedDates = [selected];
      // this.setSelectedDates(data);
      // }
      // if (this.selectedDates.includes(selected)) {
      //   if (this.selectedDates.length == 1) {
      //     this.selectedDates = [];
      //     data = [];
      //   } else {
      //     this.selectedDates = [selected];
      //     data = [selected];
      //   }
      // } else {

      // }

      const sortedDates = sortDateArray(data);
      this.setSelectedDates(sortedDates);
    },
    isArraySorted(arr) {
      return arr.slice(1).every((item, i) => arr[i] <= item);
    },
    setSelectedDates(dates, wipeForecast = true, forecastVal) {
      let dbUpdates = {};
      const localUpdates = {
        isFilterBarOptsEnabled: false,
      };

      if (this.isInspectModeEnabled) {
        localUpdates.inspectModeEnabled = false;
      }

      let viewDbData = {};

      // if (!this.isGlobalViewEnabled) {
      viewDbData = createViewDataForDb("0");
      // }

      dbUpdates["/view/0/selectedDates"] = dates;

      if (forecastVal !== undefined) {
        dbUpdates["/view/0/selectedForecastType"] = forecastVal;
      }

      if (wipeForecast) {
        dbUpdates["/view/0/selectedForecastType"] = "";
      }

      dbUpdates = {
        ...dbUpdates,
        ...viewDbData,
      };

      this.hideTempVisibleTasks();

      this.updateState({ ...localUpdates });
      DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      this.updateRootState({
        searchVal: "",
        currViewData: {},
        itemInspectEnabled: false,
      });
      // DatabaseInterface.update(
      //   {
      //     [`/view/0/selectedDates`]: dates,
      //   },
      //   this.userInfo.uid
      // );
      // this.changeView("0");
    },
    refreshTaskCountMap(tasks) {
      let counts = {};
      if (!isEmpty(tasks)) {
        counts = this.createTaskCountMap(tasks);
      }

      this.taskCounts = counts;
    },
    checkAndRefreshTasksCountMap(newList, oldList) {
      let refreshMap = false;

      if (!isEmpty(newList) && isEmpty(oldList)) {
        refreshMap = true;
      } else {
        const newListCount = newList.length;
        const oldListCount = oldList.length;
        if (newListCount !== oldListCount) {
          refreshMap = true;
        } else {
          refreshMap = newList.some(
            (task, index) =>
              task.due !== oldList[index].due ||
              task.completed !== oldList[index].completed
          );
        }
      }

      if (refreshMap) {
        this.refreshTaskCountMap(this.tasks);
      }
    },
    positionMonth() {
      const firstDate = this.selectedDate?.[0];

      if (!isEmpty(firstDate)) {
        const parsedDate = parseDate(firstDate, INTERNAL_DATE_FORMAT);

        if (isValidDate(parsedDate)) {
          const startMonth = startOfMonth(parsedDate);

          const pastMonthsDiff = differenceInMonths(
            this.currentMonth,
            startMonth
          );

          const nextMonthsDiff = differenceInMonths(
            startMonth,
            this.currentMonth
          );
          if (pastMonthsDiff > 0) {
            this.currentMonth = sub(this.currentMonth, {
              months: pastMonthsDiff,
            });
          } else if (nextMonthsDiff > 1) {
            this.currentMonth = add(this.currentMonth, {
              months: nextMonthsDiff,
            });
          }
        }
      }
    },

    positionCalBasedOnDate() {
      const selectedDates = this.selectedDate;

      if (!isEmpty(selectedDates) && selectedDates.length === 1) {
        this.positionMonth();
      }
    },
    async handleForecast(dates, type) {
      this.setSelectedDates(dates, false, type);

      await this.$nextTick();
      this.resetToToday();
    },
  },
  watch: {
    selectedDate: {
      handler(newVal) {
        // if (!isEqual(newVal, oldVal)) {
        if (newVal.length === 0 || !newVal) {
          this.selectedDates = [];
          this.dateRange = [];
          this.currentMonth = startOfMonth(new Date());
        }
        // }
      },
      deep: true,
    },
    tasks: {
      handler(newList, oldList) {
        if (!isEqual(newList, oldList)) {
          if (!isEmpty(newList) && isEmpty(oldList)) {
            this.positionCalBasedOnDate();
          }
          this.checkAndRefreshTasksCountMap(newList, oldList);
        }
      },
      deep: true,
    },
    isInspectModeEnabled: {
      handler() {
        // if (newVal) {
        this.positionMonth();
        // }
      },
      immediate: true,
    },
    currNav: {
      handler() {
        if (!this.isInspectModeEnabled) {
          this.positionMonth();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!isEmpty(this.tasks)) {
      this.positionCalBasedOnDate();
      this.refreshTaskCountMap(this.tasks);
    }
  },
};
</script>

<style scoped>
.days,
.week-days {
  display: grid;
  grid-template-columns: repeat(7, 14%);
  grid-column: 7;
  gap: 1px;
}
* + address,
* + dl,
* + fieldset,
* + figure,
* + ol,
* + p,
* + pre,
* + ul {
  margin-top: 0px;
  margin-bottom: 0px;
}
.day {
  background-color: rgba(255, 255, 255, 0.2);
}
.hovered {
  background-color: #cfcfcf !important;
  color: #000 !important;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
  -ms-user-select: none;
  user-select: none;
}
.month {
  font-size: 20px;
  font-weight: 500;
  color: #6b6666;
}

.week-days p {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  /* color: #ff9800; */
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  text-align: center;
}

.v-application .date {
  font-size: 11px !important;
  /* text-align: right; */
  display: flex;
  justify-content: flex-end;
}

.days div {
  outline: 1px dotted #fee6c5;
  cursor: pointer;
}

.days div:hover {
  background-color: #e9e9e9;
}

.selected {
  color: var(--primary-color) !important;
  background-color: var(--selection-color) !important;
  font-weight: bold;
}
.today {
  border: 1px solid var(--primary-color) !important;
  margin: -1px;
  position: relative;
  z-index: 1;
  outline: none !important;
}

.month-text-wrapper {
  margin: 12px 8px;
}

#calendar {
  outline: none;
}

.task-count-day {
  min-height: 21px;
  line-height: 1.5 !important;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color);
}

.day:not(.selected) p.task-count-day {
  /* color: #e58100; */
  /* color: #2a2a2a; */
}
</style>
