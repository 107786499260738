import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{ref:"select",staticClass:"priority-type-input",attrs:{"items":_vm.priorityTypeOpts,"item-value":"key","placeholder":_vm.placeholder,"hide-details":""},on:{"keydown":_vm.handleKeyDown},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"selection-container"},[_c('span',{staticClass:"priority-color",style:({ backgroundColor: item.color })}),_c('span',[_vm._v(" "+_vm._s(item.title))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c(VListItemIcon,[_c(VIcon,{staticClass:"mr-3",attrs:{"color":item.color}},[_vm._v(" mdi-circle ")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }