<template>
  <div>
    <SaveBtn v-if="data.addNew" @save="saveResource" @cancel="cancelSave" />
  </div>
</template>
<script>
import SaveBtn from "@/components/GridComponents/SaveAndCancelBtns.vue";
import { columnPropsMixin } from "@/core/components/DataTable";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import { createResourceDataForAdd } from "@/helpers/resources";

import { mapGetters } from "vuex";
export default {
  mixins: [columnPropsMixin, resourcesHelpersMixin],
  components: {
    SaveBtn,
  },
  computed: {
    ...mapGetters("resourcesData", ["categories", "resources"]),
  },
  methods: {
    async saveResource() {
      const currCategories = this.categories;
      const data = this.cell.getData();

      const resourceTag = (data.tag && data.tag.trim()) || "";

      const isValidRes = await this.checkIfTagIsValid(resourceTag, data.type);
      if (!isValidRes.isValid) {
        this.showAlertForTagNotValid(isValidRes);
        return;
      }

      const resourceData = createResourceDataForAdd(
        {
          title: data.title,
          catId: data.catId,
          tag: resourceTag || "",
          type: data.type,
        },
        this.resources,
        currCategories
      );

      this.addOrRemoveOrUpdateResourcesInList({
        resourcesToAdd: [resourceData],
      });
      this.cell.api.triggerAddRow(resourceData);
    },
    cancelSave() {
      this.cell.api.removeNewRow();
    },
  },
};
</script>
