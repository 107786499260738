export const priorityTypesMap = {
  purpose: {
    title: "Purpose",
    key: "purpose",
    color: "#2196F3",
    displayOrder: 1,
    icon: "mdi-checkbox-blank-circle",
  },
  impact: {
    title: "Impact",
    key: "impact",
    color: "#4CAF50",
    displayOrder: 2,
    icon: "mdi-checkbox-blank-circle",
  },
  priority: {
    title: "Priority",
    key: "priority",
    color: "#ff9800",
    displayOrder: 3,
    icon: "mdi-checkbox-blank-circle",
  },
  power: {
    title: "Power",
    key: "power",
    color: "#F44336",
    displayOrder: 4,
    icon: "mdi-checkbox-blank-circle",
  },
  none: {
    title: "None",
    key: "none",
    color: "#0000008a",
    displayOrder: 0,
    icon: "mdi-checkbox-blank-circle",
  },
};

// export const projectPriorityTypeOpts = Object.values(projectPriorityTypesMap);
export const priorityTypeOpts = Object.values(priorityTypesMap).filter(
  (p) => p.key !== "none"
);
