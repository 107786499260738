<template>
  <div class="d-flex filters-row">
    <v-btn-toggle
      v-show="showModifiedFilter"
      id="tasks-filter-btns-row"
      :value="selectedOptIndex"
      borderless
    >
      <v-tooltip
        v-for="(opt, index) in filterOpts"
        :key="index"
        bottom
        disabled
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="handleSelectFilter(opt)"
            :class="createFilterBtnStyleClasses(opt)"
            :style="createFilterBtnStyles(opt)"
          >
            <span class="task-filter-btn-text">
              {{ opt.displayText }}
            </span>
          </v-btn>
        </template>
        <span> {{ opt.label }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <div v-show="bothFiltersAreVisible" class="filter-seperate mr-2 ml-2"></div>
    <div v-show="showDateFilter" class="date-filter-wrapper">
      <ForecastBtns
        :value="selectedDateFilter"
        @input="handleSelectFilter($event, 'date')"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { taskFiltersConfig, taskFiltersValMap } from "@/variables/viewConfigs";
import { mapGetters, mapMutations } from "vuex";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import DatabaseInterface from "@/services/DatabaseInterface";
import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_TASKS_EVENT } from "@/variables/events";
import isEmpty from "lodash/isEmpty";
import ForecastBtns from "@/components/ForecastBtns";
import {
  checkIfSelectedTaskCateHasCategory,
  checkIfSelectedTaskCateHasOnlyArea,
} from "@/helpers/tasks";
// selectedTaskDateFilter
export default {
  mixins: [userDetailsMixin],
  components: {
    ForecastBtns,
  },
  methods: {
    ...mapMutations("task", ["updateState"]),
    async handleSelectFilter(filterOpt, type = "modified") {
      if (this.isSearchKeywordValid) {
        return;
      }
      const dbUpdates = {};
      const localStateUpdates = {};

      if (type === "modified") {
        dbUpdates[`/view/${this.currNav}/selectedFilter`] = filterOpt.value;
        localStateUpdates.selectedTaskFilter = filterOpt.value;
        if (filterOpt.value === this.selectedTaskFilter) {
          dbUpdates[`/view/${this.currNav}/selectedFilter`] = "";
          localStateUpdates.selectedTaskFilter = "";
        }
      }

      if (type === "date") {
        dbUpdates[`/view/${this.currNav}/selectedDateFilter`] = filterOpt.value;
        localStateUpdates.selectedTaskDateFilter = filterOpt.value;

        if (filterOpt.value === this.selectedTaskDateFilter) {
          dbUpdates[`/view/${this.currNav}/selectedDateFilter`] = "";
          localStateUpdates.selectedTaskDateFilter = "";
        }
      }

      if (!this.isInspectModeEnabled) {
        await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      } else {
        this.updateState({
          ...localStateUpdates,
        });
      }

      await Vue.nextTick();

      EventEmitter.emit(REFRESH_TASKS_EVENT);
    },

    createFilterBtnStyleClasses(forecastOptData) {
      return {
        "task-filter-btn": true,
        "filter-btn-selected":
          !this.isSearchKeywordValid &&
          forecastOptData.value === this.selectedTaskFilter,
      };
    },
    createFilterBtnStyles(filterOpt) {
      return {
        backgroundColor: filterOpt.bgColor,
      };
    },
  },
  computed: {
    ...mapGetters("task", [
      "selectedTaskFilter",
      "selectedTaskDateFilter",
      "isInspectModeEnabled",
      "currNav",
      "searchQuery",
      "selectedOptsList",
      "isGlobalViewEnabled",
    ]),
    filterOpts() {
      const list = [...taskFiltersConfig];
      return list.reverse();
    },
    selectedOptIndex() {
      return !this.isSearchKeywordValid
        ? taskFiltersValMap[this.selectedTaskFilter]
        : undefined;
    },
    isSearchKeywordValid() {
      let searchQuery = this.searchQuery;

      searchQuery = searchQuery && searchQuery.trim();

      return !isEmpty(searchQuery) && searchQuery !== "*";
    },
    selectedDateFilter() {
      return !this.isSearchKeywordValid
        ? this.selectedTaskDateFilter
        : undefined;
    },
    showModifiedFilter() {
      return (
        this.isGlobalViewEnabled ||
        isEmpty(this.selectedOptsList) ||
        checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList) ||
        checkIfSelectedTaskCateHasCategory(this.selectedOptsList)
      );
    },
    showDateFilter() {
      return (
        this.isGlobalViewEnabled ||
        checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList) ||
        checkIfSelectedTaskCateHasCategory(this.selectedOptsList)
      );
    },
    bothFiltersAreVisible() {
      return this.showDateFilter && this.showModifiedFilter;
    },
  },
};
</script>
<style>
#tasks-filter-btns-row {
  border-radius: 6px !important;
  background-color: #efefef !important;
  gap: 0px 5px;
}

#tasks-filter-btns-row .task-filter-btn.filter-btn-selected {
  border: 1px solid var(--primary-color) !important;
  /* color: #fff !important; */
}

#tasks-filter-btns-row .task-filter-btn {
  background-color: #efefef;
  color: var(--grey-icon-color);
  width: 20px;
  border: 1px solid;
}

#tasks-filter-btns-row .task-filter-btn .task-filter-btn-text {
  font-size: 15px;
}

#tasks-filter-btns-row button.task-filter-btn.v-btn.v-btn.v-size--default {
  height: 22px !important;
  min-width: auto !important;
  border-radius: 6px !important;
  padding: 5px 5px !important;
}

.filter-seperate {
  width: 2px;
  background: #b6b6b6;
  height: 20px;
  /* margin-top: 2px; */
}

.date-filter-wrapper {
  display: flex;
  align-items: center;
}

.date-filter-wrapper #forecast-btns-row {
  gap: 0px 2px;
  background-color: #f9f9f9 !important;
}

.date-filter-wrapper #forecast-btns-row .forecast-btn {
  background-color: #f9f9f9;
  border: 1px solid transparent !important;
}

.date-filter-wrapper #forecast-btns-row .forecast-selected {
  background-color: #f9f9f9 !important;
  color: var(--grey-icon-color) !important;
  border-color: var(--primary-color) !important;
}

.filters-row {
  align-items: center;
}
</style>
