// import { isEmpty } from "lodash";
import * as authTokenHelpers from "./authTokenHelpers";
import axios from "axios";
import Storage from "@/utils/storage";
import { isEmpty } from "lodash";
import sleep from "@/utils/sleep";
// const defaultScopes = [
//   "https://www.googleapis.com/auth/calendar",
//   "https://www.googleapis.com/auth/calendar.events",
// ];

import store from "@/store";
import { getCurrentTimeZoneName } from "./dates";

export function isGoogleCalSyncEnabled() {
  return store.getters.isGoogleCalSyncEnabled;
}

// let defaultCalendar = "primary";
// let defaultCalendar = "lk2b87vbc51lordcu4a8l100d0@group.calendar.google.com";
class GoogleCalHelpers {
  tokenClient = {};
  static #calAuthKey = "google-cal-auth";
  static #calAuthRefreshKey = "google-cal-refresh-auth";
  static #lastCalSyncKey = "google-cal-last-sync";
  static #calIdKey = "google-cal-id";
  static async createCalAuthLink() {
    try {
      const reqData = await axios.get(
        process.env.VUE_APP_GOOGLE_CREATE_AUTH_TOKEN_URL
      );
      const { url } = reqData.data;
      return url;
    } catch (error) {
      console.debug("ERR", error);
      throw new Error("Issue in starting calendar sync", error.message);
    }
  }
  static async loadCalAuthToken(authToken, refreshToken) {
    const tokenExpiry = authTokenHelpers.createAuthTokenExpirationDate();
    await this.setCalAuthToken(authToken, tokenExpiry);
    await this.setCalAuthRefreshToken(refreshToken);
  }
  static async createAuthHeaders() {
    const authToken = await this.getCalAuthToken();

    return { Authorization: `Bearer ${authToken}` };
  }
  static async setCalAuthToken(token, tokenExpiry) {
    // if (!isGoogleCalSyncEnabled()) return;
    authTokenHelpers.setAuthToken(this.#calAuthKey, token, tokenExpiry);
  }
  static async setCalAuthRefreshToken(token) {
    // if (!isGoogleCalSyncEnabled()) return;
    authTokenHelpers.setAuthRefreshToken(this.#calAuthRefreshKey, token);
  }
  static async getNewCalAuthToken() {
    // if (!isGoogleCalSyncEnabled()) return;
    // get new token from server with refresh token
    const refreshToken = authTokenHelpers.getAuthRefreshToken(
      this.#calAuthRefreshKey
    );
    const requestData = await axios.get(
      `${process.env.VUE_APP_GOOGLE_REFRESH_AUTH_TOKEN_URL}?refreshToken=${refreshToken}`
    );
    return requestData.data.accessToken;
  }

  static async getCalAuthToken() {
    // if (!isGoogleCalSyncEnabled()) return;
    const isTokenExpired = authTokenHelpers.isAuthTokenExpired(
      this.#calAuthKey
    );
    if (!isTokenExpired) {
      const tokenData = await authTokenHelpers.getAuthToken(this.#calAuthKey);
      return tokenData;
    }

    const newAuthToken = await this.getNewCalAuthToken();
    const tokenExpiryDate = authTokenHelpers.createAuthTokenExpirationDate();
    this.setCalAuthToken(newAuthToken, tokenExpiryDate);
    return newAuthToken;
  }

  /**
   * Get calendar with the provided id
   * @param {string} calId Id of the calendar
   * @returns {Promise<{[string]:*}>}
   */
  static async getCalendarById(calId) {
    let calData = {};
    try {
      const authHeaders = await this.createAuthHeaders();
      if (calId) {
        const googleCalDataRes = await axios.get(
          `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calId}`,
          {
            headers: authHeaders,
          }
        );

        calData = googleCalDataRes.data;
      }
    } catch (error) {
      console.error(`Error in getting calendar: ${error.message}`);
    }

    return calData;
  }

  /**
   * Create new a calendar with the provided data
   * @param {{[string]:*}} calendarData Data for new calendar
   * @returns {Promise<{[string]:*}>}
   */
  static async createGoogleCal({ summary } = {}) {
    const authHeaders = await this.createAuthHeaders();
    const createdCalData = {
      summary,
      timeZone: getCurrentTimeZoneName(),
    };

    const calDataRes = await axios.post(
      process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL,
      {
        ...createdCalData,
      },
      {
        headers: authHeaders,
      }
    );

    return calDataRes.data;
  }
  /**
   * Check if calendar with provided id already exists and create a new one if it does not exists
   * @param {string} googleCalId Id of google calendar
   * @param {string} calName  Calendar Name for creating a new calendar
   * @returns {Promise<{calendarCreated:boolean, calendarId:string}>}
   */
  static async checkAndSetupCalendar(
    googleCalId,
    calName = "ChaosToIntention"
  ) {
    let createGoogleCal = true;
    let storedGoogleCalData = {};
    let googleCalIdToStore = null;
    if (googleCalId) {
      storedGoogleCalData = await this.getCalendarById(googleCalId);
      createGoogleCal = isEmpty(storedGoogleCalData);
    }

    if (createGoogleCal) {
      storedGoogleCalData = await this.createGoogleCal({
        summary: calName,
      });
    }

    if (!isEmpty(storedGoogleCalData)) {
      Storage.set(this.#calIdKey, storedGoogleCalData.id);
      googleCalIdToStore = storedGoogleCalData.id;
    }

    return {
      calendarCreated: createGoogleCal,
      calendarId: googleCalIdToStore,
    };
  }
  /**
   * Get stored calendar id in local storage
   * @returns {string}
   */
  static getStoredCalId() {
    return Storage.get(this.#calIdKey) || "";
  }

  static async revokeAccess() {
    // if (!isGoogleCalSyncEnabled()) return;
    try {
      const calRefreshAuth = authTokenHelpers.getAuthRefreshToken(
        this.#calAuthRefreshKey
      );
      await axios.post(
        `${process.env.VUE_APP_GOOGLE_REVOKE_TOKEN_URL}?token=${calRefreshAuth}`
      );

      authTokenHelpers.clearAuthToken(
        this.#calAuthKey,
        this.#calAuthRefreshKey
      );
      Storage.remove(this.#lastCalSyncKey);
    } catch (error) {
      throw new Error(`Error in revoke access call: ${error.message}`);
    }
  }

  static async addEvents(
    eventDataList,
    slowDownRequests,
    verifyCalendar = true
  ) {
    if (!isGoogleCalSyncEnabled()) return { status: "disabled" };

    let res = {};

    let calendarStatusRes = {};
    let calendarId = this.getStoredCalId();

    if (verifyCalendar) {
      calendarStatusRes = await this.checkAndSetupCalendar(calendarId);
      calendarId = calendarStatusRes.calendarId;
    }
    try {
      if (!Array.isArray(eventDataList)) eventDataList = [eventDataList];
      const authHeaders = await this.createAuthHeaders();
      const reqList = [];

      for (const data of eventDataList) {
        if (slowDownRequests) {
          await sleep(250);
        }

        reqList.push(
          axios.post(
            `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calendarId}/events`,
            {
              ...data,
            },
            {
              headers: authHeaders,
            }
          )
        );
      }

      if (reqList && reqList.length) {
        await Promise.all(reqList);
      }

      // const reqData = await axios.post(
      //   `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calendarId}/events`,
      //   {
      //     ...eventData,
      //   },
      //   {
      //     headers: authHeaders,
      //   }
      // );
      res = {
        status: "OK",
      };
    } catch (error) {
      console.error(`Error in add event call: ${error.message}`);
      res.status = "error";
      res.message = error.message;
    }

    return { ...res, ...calendarStatusRes };
  }
  static async removeEvents(eventIds, slowDownRequests, verifyCalendar = true) {
    if (!isGoogleCalSyncEnabled()) return { status: "disabled" };

    let res = {};

    let calendarStatusRes = {};
    let calendarId = this.getStoredCalId();

    if (verifyCalendar) {
      calendarStatusRes = await this.checkAndSetupCalendar(calendarId);
      calendarId = calendarStatusRes.calendarId;
    }

    try {
      const authHeaders = await this.createAuthHeaders();

      if (!Array.isArray(eventIds)) eventIds = [eventIds];
      const allCalls = [];

      for (const eventId of eventIds) {
        if (slowDownRequests) {
          await sleep(200);
        }

        allCalls.push(
          axios.patch(
            `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calendarId}/events/${eventId}`,
            {
              status: "cancelled",
            },
            {
              headers: authHeaders,
            }
          )
        );
      }
      res = {
        status: "OK",
      };
    } catch (error) {
      console.error(`Error in remove events call: ${error.message}`);
      res.status = "Error";
      res.message = error.message;
    }

    return { ...res, ...calendarStatusRes };
  }

  static async updateEvents(
    eventsToUpdate,
    _,
    slowDownRequests,
    verifyCalendar = true
  ) {
    if (!isGoogleCalSyncEnabled()) return { status: "disabled" };
    let res = {};

    let calendarStatusRes = {};
    let calendarId = this.getStoredCalId();

    if (verifyCalendar) {
      calendarStatusRes = await this.checkAndSetupCalendar(calendarId);

      calendarId = calendarStatusRes.calendarId;
    }
    try {
      const authHeaders = await this.createAuthHeaders();

      if (!Array.isArray(eventsToUpdate)) eventsToUpdate = [eventsToUpdate];
      const allCalls = [];

      for (const eventData of eventsToUpdate) {
        if (slowDownRequests) {
          await sleep(200);
        }

        if (!isEmpty(eventData.updates)) {
          delete eventData.updates.dataForAdd;
          allCalls.push(
            axios.patch(
              `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calendarId}/events/${eventData.key}`,
              {
                ...eventData.updates,
              },
              {
                headers: authHeaders,
              }
            )
          );
        }
      }
      // eventsToUpdate.forEach((eventData) => {

      // });

      if (allCalls && allCalls.length) {
        await Promise.all(allCalls);
      }
      res = {
        status: "OK",
      };
    } catch (error) {
      console.error(`Error in update events call: ${error.message}`);
      res.status = "Error";
      res.message = error.message;
    }

    return {
      ...res,
      ...calendarStatusRes,
    };
  }

  static async list(_, lastSyncTimestamp, verifyCalendar = true) {
    if (!isGoogleCalSyncEnabled()) return { status: "disabled" };

    let calendarStatusRes = {};
    let calendarId = this.getStoredCalId();

    if (verifyCalendar) {
      calendarStatusRes = await this.checkAndSetupCalendar(calendarId);

      calendarId = calendarStatusRes.calendarId;
    }

    const authHeaders = await this.createAuthHeaders();
    const paramsToSend = {
      showDeleted: true,
    };
    if (lastSyncTimestamp) {
      paramsToSend.updatedMin = lastSyncTimestamp;
    }

    const resultReq = await axios.get(
      `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calendarId}/events`,
      {
        headers: authHeaders,
        params: {
          ...paramsToSend,
        },
      }
    );

    return {
      events: resultReq.data.items || [],
      status: "OK",
      ...calendarStatusRes,
    };
  }

  static async listRecurring(eventId, _, params, verifyCalendar = true) {
    if (!isGoogleCalSyncEnabled()) return { status: "disabled" };

    let calendarStatusRes = {};
    let calendarId = this.getStoredCalId();

    if (verifyCalendar) {
      calendarStatusRes = await this.checkAndSetupCalendar(calendarId);

      calendarId = calendarStatusRes.calendarId;
    }

    const authHeaders = await this.createAuthHeaders();

    let paramsToSend = {};

    if (!isEmpty(params)) {
      if (params.start) {
        paramsToSend.timeMin = params.start;
      }

      if (params.end) {
        paramsToSend.timeMax = params.end;
      }
    }

    const resultReq = await axios.get(
      `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calendarId}/events/${eventId}/instances`,
      {
        headers: authHeaders,
        params: {
          ...paramsToSend,
        },
      }
    );

    return {
      list: resultReq.data.items,
      status: "OK",
      ...calendarStatusRes,
    };
  }
  static async setLastSync(lastSyncTimestamp) {
    if (!isGoogleCalSyncEnabled()) return;
    Storage.set(this.#lastCalSyncKey, lastSyncTimestamp);
    return true;
  }

  /**
   * Get event data by the provided id
   * @param {string} eventId Id of the event
   * @param {boolean} verifyCalendar Verify calendar existence
   * @returns {Promise<{status:string, event:{[string]:*}, [string]:*}>}
   */
  static async getEventById(eventId, verifyCalendar = true) {
    // param: calendarId
    if (!isGoogleCalSyncEnabled()) return { status: "disabled" };

    let calendarStatusRes = {};
    let calendarId = this.getStoredCalId();

    if (verifyCalendar) {
      calendarStatusRes = await this.checkAndSetupCalendar(calendarId);

      calendarId = calendarStatusRes.calendarId;
    }

    let res = {};
    try {
      const authHeaders = await this.createAuthHeaders();

      const resultReq = await axios.get(
        `${process.env.VUE_APP_GOOGLE_CALENDAR_REST_URL}/${calendarId}/events/${eventId}`,
        {
          headers: authHeaders,
        }
      );

      res.status = "OK";
      res.event = resultReq.data;
    } catch (error) {
      console.error(`Error in getEventById call: ${error.message}`);

      res.status = "Error";
      res.message = error.message;
    }

    return {
      ...res,

      ...calendarStatusRes,
    };
  }
}

export default GoogleCalHelpers;
