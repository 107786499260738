export const taskTypesMap = {
  event: {
    icon: "$ctiEvent",
    key: "event",
    title: "Event",
    order: 1,
    iconSize: 13,
  },
  task: {
    icon: "$ctiSimpleTask",
    key: "task",
    title: "Task",
    order: 0,
    iconSize: 13,
  },
  // post: {
  //   icon: "$ctiAnnounce",
  //   key: "post",
  //   title: "Announce",
  //   order: 2,
  //   iconSize: 13,
  // },

  consult: {
    icon: "$ctiConsult",
    key: "consult",
    title: "Call",
    order: 2,
    iconSize: 13,
  },
  // contact: {
  //   icon: "$ctiContact",
  //   key: "contact",
  //   title: "Contact",
  //   order: 4,
  //   iconSize: 13,
  // },
  message: {
    icon: "$ctiMessage",
    key: "message",
    title: "Message",
    order: 3,
    iconSize: 13,
  },
  deskwork: {
    icon: "$ctiDesktop",
    key: "deskwork",
    title: "Deskwork",
    order: 4,
    iconSize: 13,
  },
  errand: {
    icon: "$ctiErrands",
    key: "errand",
    title: "Errand",
    order: 5,
    iconSize: 13,
  },

  work: {
    icon: "$ctiTaskWork",
    key: "work",
    title: "Work",
    order: 6,
    iconSize: 14,
    displayIconSize: 20,
  },
  note: {
    icon: "$ctiNoteNew",
    key: "note",
    title: "Convert to Note",
    order: 7,
    iconSize: 13,
    useDottedBorder: true,
  },

  // goal: {
  //   icon: "$ctiBullsEyeNew",
  //   key: "goal",
  //   title: "Goal",
  //   order: 6,
  // },
  // event: {
  //   icon: "mdi-calendar-check",
  //   key: "event",
  //   title: "Event",
  // },
  // // journal: {
  // //   icon: "$journal",
  // //   key: "journal",
  // //   title: "Journal",
  // // },
  // message: {
  //   icon: "$ctiMessage",
  //   key: "message",
  //   title: "Message",
  // },
  // note: {
  //   icon: "$ctiNoteNew",
  //   key: "note",
  //   title: "Note",
  // },
  // // plan: {
  // //   icon: "$ctiPlanNew",
  // //   key: "plan",
  // //   title: "Plan",
  // // },
  // post: {
  //   icon: "$ctiAnnounce",
  //   key: "post",
  //   title: "Announce",
  // },
  // task: {
  //   icon: "$ctiNewTaskIcon",
  //   key: "task",
  //   title: "Task",
  // },
};

export const typesMap = {
  continued: {
    icon: "$ctiLogout",
    key: "continued",
    title: "Continued",
    order: 1,
    filterOrder: 4,
    iconSize: 13,
  },
  repeat: {
    icon: "$ctiRepeat",
    key: "repeat",
    title: "Repeat",
    order: 2,
    filterOrder: 3,
    iconSize: 12,
  },
  // repeatEvery: {
  //   icon: "$ctiRepeat",
  //   key: "repeatEvery",
  //   title: "Repeat Every",
  //   order: 2,
  //   filterOrder: 3,
  //   iconSize: 12,
  // },
  // repeatIn: {
  //   icon: "$ctiRepeat",
  //   key: "repeatIn",
  //   title: "Repeat In",
  //   order: 3,
  //   filterOrder: 2,
  //   iconSize: 12,
  // },
  normal: {
    icon: "$ctiCircleOutline",
    key: "normal",
    title: "Normal",
    order: 0,
    filterOrder: 1,
    iconSize: 13,
  },
};

export const itemTypeMap = {
  task: {
    icon: "$ctiNewTaskIcon",
    key: "task",
    title: "Convert to Task",
    order: 1,
    useDottedBorder: true,
  },
  note: {
    icon: "$ctiNoteNew",
    key: "note",
    title: "Note",
    order: 0,
  },
};

const taskTypeOptsList = Object.values(taskTypesMap);
export const itemTypeOpts = Object.values(itemTypeMap).sort(
  (a, b) => a.order - b.order
);
export const taskTypeOpts = taskTypeOptsList.sort(
  (a, b) => a.order - b.order
  // a.title.toLowerCase().localeCompare(b.title.toLowerCase())
);

export const taskTypeOptsWithoutNoteOpt = taskTypeOpts.filter(
  (task) => task.key !== "note"
);
export const taskTypePlaceholders = {
  consult: "Enter details for consult here",
  goal: "Enter details of your goal here",
  // plan: "Enter details of your plan here",
  // journal: "Enter details of your journal here",
  message: "Enter your message description here",
  post: "Enter details of announcement here",
  task: "Enter your task description here",
  event: "Enter details of your event here",
  errand: "Enter details of your errand here",
  note: "Enter details of your note here",
  contact: "Enter description here",
  work: "Enter details here",
};
