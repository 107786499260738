export default {
  "internalCollapsedGroups": [],
  "collapsedGroups": [],
  "usedTags": [],
  "resources": {},
  "usedTagsMap": {
    "thing": []
  },
  "selectedCategories": {
    "place": [
      "e83bb813-52b5-4c75-83c9-62cad635197d"
    ],
    "thing": [],
    "people": [
      "root"
    ],
    "undefined": []
  },
  "selectedResourceType": "thing",
  "categories": {
    "e9a89f8e-9e22-42b4-83c5-bfa056dd1c8b": {
      "cateType": "project",
      "created": "2024-07-22T10:36:21-04:00",
      "name": "Family & Life",
      "modified": "2024-07-22T10:36:21-04:00",
      "parentCatKey": "f0566660-be96-40c8-9b84-10fbb6b70377",
      "refKey": "aca14ab9-8380-4b1a-a087-d9eaefdc4227",
      "open": false,
      "key": "e9a89f8e-9e22-42b4-83c5-bfa056dd1c8b",
      "order": 0
    },
    "cbeb37b6-aebe-40a9-86bb-8649e2538660": {
      "cateType": "thing",
      "created": "2024-07-22T10:47:49-04:00",
      "name": "Expense",
      "modified": "2024-07-22T10:47:49-04:00",
      "parentCatKey": "552db372-ad80-44ed-a032-5642beec253f",
      "refKey": "",
      "open": false,
      "key": "cbeb37b6-aebe-40a9-86bb-8649e2538660",
      "order": 2
    },
    "0077b775-34aa-43d9-9231-5756b1a8545c": {
      "cateType": "thing",
      "created": "2024-07-22T10:45:20-04:00",
      "name": "Hardware",
      "modified": "2024-07-22T10:45:20-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": true,
      "key": "0077b775-34aa-43d9-9231-5756b1a8545c",
      "order": 1
    },
    "e1957b7d-5f3b-48fb-9117-70881da48da8": {
      "cateType": "project",
      "created": "2024-07-22T11:02:01-04:00",
      "name": "Admin-Planning",
      "modified": "2024-07-22T11:02:01-04:00",
      "parentCatKey": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "refKey": "50329913-333a-49e2-bd61-b7b0c79617ed",
      "open": false,
      "key": "e1957b7d-5f3b-48fb-9117-70881da48da8",
      "order": 0
    },
    "b27e2e37-1caf-4cb9-82c8-1b698f6962a8": {
      "cateType": "project",
      "created": "2024-07-22T10:39:12-04:00",
      "name": "Sell, Return, Warranty",
      "modified": "2024-07-22T10:39:12-04:00",
      "parentCatKey": "b731896f-0ee7-4a9a-9771-9f563e2b6c72",
      "refKey": "15edd194-3676-4f54-9ca2-6edfd05f3df6",
      "key": "b27e2e37-1caf-4cb9-82c8-1b698f6962a8",
      "open": false,
      "order": 3
    },
    "eeaa4cdb-b183-4f58-ab12-80b6766b6af8": {
      "cateType": "project",
      "created": "2024-07-22T10:37:48-04:00",
      "name": "Home & Fixtures",
      "modified": "2024-07-22T10:37:48-04:00",
      "parentCatKey": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "refKey": "44ea18a6-7d97-4775-826f-cffcfed3cf1f",
      "open": false,
      "key": "eeaa4cdb-b183-4f58-ab12-80b6766b6af8",
      "order": 2
    },
    "e50c15fa-422d-44f7-a07b-a1d604fbdc1b": {
      "cateType": "project",
      "created": "2024-07-22T10:40:05-04:00",
      "name": "Classes",
      "modified": "2024-07-22T10:40:05-04:00",
      "parentCatKey": "c21cc93f-e188-4f2e-b317-aa24794c4021",
      "refKey": "90ab8c4b-6684-4741-b3e1-6796f85956e1",
      "open": false,
      "key": "e50c15fa-422d-44f7-a07b-a1d604fbdc1b",
      "order": 0
    },
    "f849b553-3990-41d6-b73f-2af229d8e886": {
      "cateType": "people",
      "created": "2024-07-22T10:57:49-04:00",
      "name": "Friends",
      "modified": "2024-07-22T10:57:49-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "f849b553-3990-41d6-b73f-2af229d8e886",
      "open": false,
      "order": 3
    },
    "26754b5c-61c5-4ab4-9d4a-89c8fd5130b6": {
      "cateType": "project",
      "created": "2024-07-22T10:38:10-04:00",
      "name": "Pet Care",
      "modified": "2024-07-22T10:38:10-04:00",
      "parentCatKey": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "refKey": "9610471f-8082-488b-b472-aca4c894d877",
      "key": "26754b5c-61c5-4ab4-9d4a-89c8fd5130b6",
      "open": false,
      "order": 4
    },
    "e9279ef7-c9fd-4179-87df-837ebb79d5cb": {
      "cateType": "place",
      "created": "2024-07-22T10:56:44-04:00",
      "name": "Community-Government",
      "modified": "2024-07-22T10:56:44-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "e9279ef7-c9fd-4179-87df-837ebb79d5cb",
      "open": false,
      "order": 2
    },
    "c653546f-abf7-45ca-8d32-60bb1dff99cd": {
      "cateType": "project",
      "created": "2024-07-22T10:38:31-04:00",
      "name": "Software",
      "modified": "2024-07-22T10:38:31-04:00",
      "parentCatKey": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "refKey": "1ba8cbd9-56c9-494f-baf5-038592f3cc6c",
      "key": "c653546f-abf7-45ca-8d32-60bb1dff99cd",
      "open": false,
      "order": 5
    },
    "a018088a-861a-4fd2-8ca8-1a4a3a6ba668": {
      "cateType": "people",
      "created": "2024-07-22T10:58:03-04:00",
      "name": "Professionals",
      "modified": "2024-07-22T10:58:03-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "a018088a-861a-4fd2-8ca8-1a4a3a6ba668",
      "open": false,
      "order": 6
    },
    "e174b55c-6513-4ca9-8580-f31c6fe17c80": {
      "cateType": "project",
      "created": "2024-07-22T10:39:04-04:00",
      "name": "Income",
      "modified": "2024-07-22T10:39:04-04:00",
      "parentCatKey": "b731896f-0ee7-4a9a-9771-9f563e2b6c72",
      "refKey": "ba55df3c-08e8-46b5-9b21-d29ff891dfd7",
      "key": "e174b55c-6513-4ca9-8580-f31c6fe17c80",
      "open": false,
      "order": 2
    },
    "7ca95677-b29e-4274-87d9-193211c930cd": {
      "cateType": "thing",
      "created": "2024-07-22T10:48:12-04:00",
      "name": "Income",
      "modified": "2024-07-22T10:48:12-04:00",
      "parentCatKey": "552db372-ad80-44ed-a032-5642beec253f",
      "refKey": "",
      "key": "7ca95677-b29e-4274-87d9-193211c930cd",
      "open": false,
      "order": 3
    },
    "56b63a49-19ae-4ff5-8cd4-f6390c6fd12e": {
      "cateType": "project",
      "created": "2024-07-22T10:37:59-04:00",
      "name": "Information Management",
      "modified": "2024-07-22T10:37:59-04:00",
      "parentCatKey": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "refKey": "b1019419-1a7d-466d-b160-e5e664925ec2",
      "key": "56b63a49-19ae-4ff5-8cd4-f6390c6fd12e",
      "open": false,
      "order": 3
    },
    "d462730b-e2cd-4e82-a4e4-6aded67aa1e6": {
      "cateType": "project",
      "created": "2024-07-22T10:35:58-04:00",
      "name": "Vision",
      "modified": "2024-07-22T10:35:58-04:00",
      "parentCatKey": "57dd0cbe-8cf5-479b-adbd-1d2abe3ebecc",
      "refKey": "0f81565e-d301-43b2-b029-57c47b1e35ce",
      "key": "d462730b-e2cd-4e82-a4e4-6aded67aa1e6",
      "open": false,
      "order": 3
    },
    "d0cbec8f-9d06-441d-b36a-cd1665607b3c": {
      "cateType": "people",
      "created": "2024-07-22T10:57:59-04:00",
      "name": "Medical",
      "modified": "2024-07-22T10:57:59-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "d0cbec8f-9d06-441d-b36a-cd1665607b3c",
      "order": 5
    },
    "c21cc93f-e188-4f2e-b317-aa24794c4021": {
      "cateType": "project",
      "created": "2024-07-22T10:35:10-04:00",
      "name": "Learning",
      "modified": "2024-07-22T10:35:10-04:00",
      "parentCatKey": "",
      "refKey": "3f53ff19-0ad1-4b0d-8aa3-ca4e669a40c0",
      "open": false,
      "key": "c21cc93f-e188-4f2e-b317-aa24794c4021",
      "order": 7
    },
    "23e70cc8-6b97-4a13-abc7-0f4558173625": {
      "cateType": "people",
      "created": "2024-07-22T10:57:39-04:00",
      "name": "Community",
      "modified": "2024-07-22T10:57:39-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "23e70cc8-6b97-4a13-abc7-0f4558173625",
      "order": 1
    },
    "75615cc4-ec4e-4267-817c-a6f123e025d9": {
      "cateType": "project",
      "created": "2024-07-22T10:39:38-04:00",
      "name": "Shop Online",
      "modified": "2024-07-22T10:39:38-04:00",
      "parentCatKey": "18aafb92-fa6e-49a1-bcb6-449507fefde8",
      "refKey": "422423cf-8af4-4596-a1d8-88a81299c264",
      "open": false,
      "key": "75615cc4-ec4e-4267-817c-a6f123e025d9",
      "order": 1
    },
    "e83bb813-52b5-4c75-83c9-62cad635197d": {
      "cateType": "place",
      "created": "2024-07-22T10:56:39-04:00",
      "name": "Business Local",
      "modified": "2024-07-22T10:56:39-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "e83bb813-52b5-4c75-83c9-62cad635197d",
      "open": false,
      "order": 0
    },
    "3228c9b6-21c2-41e9-a218-97f6d6a2011d": {
      "cateType": "place",
      "created": "2024-07-22T10:56:29-04:00",
      "name": "Business Online",
      "modified": "2024-07-22T10:56:29-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "3228c9b6-21c2-41e9-a218-97f6d6a2011d",
      "order": 1
    },
    "d9b7d561-500a-4088-82f1-9af316039345": {
      "cateType": "project",
      "created": "2024-07-22T10:41:35-04:00",
      "name": "Professional",
      "modified": "2024-07-22T10:41:35-04:00",
      "parentCatKey": "f0566660-be96-40c8-9b84-10fbb6b70377",
      "refKey": "d6d26bcb-4198-430b-82d3-8fdb878b12dd",
      "open": false,
      "key": "d9b7d561-500a-4088-82f1-9af316039345",
      "order": 4
    },
    "90911f5c-2eb7-46c9-a068-2d4bf4d2f811": {
      "cateType": "project",
      "created": "2024-08-06T13:54:50-04:00",
      "name": "Work",
      "modified": "2024-08-06T13:54:50-04:00",
      "parentCatKey": "",
      "refKey": "4da220eb-2971-405a-bf11-63742543e4c7",
      "open": false,
      "key": "90911f5c-2eb7-46c9-a068-2d4bf4d2f811",
      "order": 0
    },
    "825c7ab2-0952-4486-9ca8-c43a06440c70": {
      "cateType": "project",
      "created": "2024-07-22T10:36:05-04:00",
      "name": "Primary Care",
      "modified": "2024-07-22T10:36:05-04:00",
      "parentCatKey": "57dd0cbe-8cf5-479b-adbd-1d2abe3ebecc",
      "refKey": "4fdb79aa-0039-4ada-a8ab-4e423c7fee78",
      "key": "825c7ab2-0952-4486-9ca8-c43a06440c70",
      "open": false,
      "order": 1
    },
    "86f65165-331e-4a1e-811b-70d2ba06b6f5": {
      "cateType": "project",
      "created": "2024-07-22T10:36:35-04:00",
      "name": "Friends",
      "modified": "2024-07-22T10:36:35-04:00",
      "parentCatKey": "f0566660-be96-40c8-9b84-10fbb6b70377",
      "refKey": "e38c8e32-ee7e-4f83-9ba7-9ca2ce66e50a",
      "open": false,
      "key": "86f65165-331e-4a1e-811b-70d2ba06b6f5",
      "order": 1
    },
    "0132a432-a51b-4640-910d-ff9039afe3ca": {
      "cateType": "project",
      "created": "2024-07-22T11:02:25-04:00",
      "name": "Development",
      "modified": "2024-07-22T11:02:25-04:00",
      "parentCatKey": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "refKey": "fcd697a9-fd76-43cb-b0b4-3a520a2bb55e",
      "key": "0132a432-a51b-4640-910d-ff9039afe3ca",
      "open": false,
      "order": 1
    },
    "10f0759d-caa6-4fdf-976a-fe21b727eb9d": {
      "cateType": "project",
      "created": "2024-07-22T10:36:41-04:00",
      "name": "Groups",
      "modified": "2024-07-22T10:36:41-04:00",
      "parentCatKey": "f0566660-be96-40c8-9b84-10fbb6b70377",
      "refKey": "aeaed343-d34f-4508-ada2-e35cd88dacd5",
      "key": "10f0759d-caa6-4fdf-976a-fe21b727eb9d",
      "open": false,
      "order": 2
    },
    "18e4316d-7bd1-4b61-b388-1df31b24a670": {
      "cateType": "project",
      "created": "2024-07-22T10:40:15-04:00",
      "name": "Holidays & Birthdays",
      "modified": "2024-07-22T10:40:15-04:00",
      "parentCatKey": "a8565cf8-cc93-42e6-8574-5057aa046a86",
      "refKey": "34a09089-5e8e-411b-9015-efef9834d2b6",
      "open": false,
      "key": "18e4316d-7bd1-4b61-b388-1df31b24a670",
      "order": 0
    },
    "028f9acb-173f-4cd1-9d37-a2b7ea2945c7": {
      "cateType": "people",
      "created": "2024-07-22T10:57:54-04:00",
      "name": "Groups",
      "modified": "2024-07-22T10:57:54-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "028f9acb-173f-4cd1-9d37-a2b7ea2945c7",
      "order": 4
    },
    "8c48be6a-86f8-48bd-a253-61254b262497": {
      "cateType": "thing",
      "created": "2024-07-22T10:47:18-04:00",
      "name": "Banking-Savings",
      "modified": "2024-07-22T10:47:18-04:00",
      "parentCatKey": "552db372-ad80-44ed-a032-5642beec253f",
      "refKey": "",
      "key": "8c48be6a-86f8-48bd-a253-61254b262497",
      "open": false,
      "order": 0
    },
    "306aea57-d57c-4aea-909e-89c69bb88d8d": {
      "cateType": "project",
      "created": "2024-07-22T10:42:05-04:00",
      "name": "Health ADMIN",
      "modified": "2024-07-22T10:42:05-04:00",
      "parentCatKey": "57dd0cbe-8cf5-479b-adbd-1d2abe3ebecc",
      "refKey": "8159cda7-1829-40a1-ac6e-b6c6c3a0bca3",
      "open": false,
      "key": "306aea57-d57c-4aea-909e-89c69bb88d8d",
      "order": 0
    },
    "91fe6d2d-e7c2-4b02-af7f-6dc09cc77b4c": {
      "cateType": "place",
      "created": "2024-07-22T10:56:53-04:00",
      "name": "Medical",
      "modified": "2024-07-22T10:56:53-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "91fe6d2d-e7c2-4b02-af7f-6dc09cc77b4c",
      "open": false,
      "order": 3
    },
    "ac155dc8-3b57-4516-95ff-356fe594f083": {
      "cateType": "project",
      "created": "2024-07-22T11:02:52-04:00",
      "name": "Finances",
      "modified": "2024-07-22T11:02:52-04:00",
      "parentCatKey": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "refKey": "f0a9bc8e-2892-4f1a-9cce-4aee4ae571a0",
      "key": "ac155dc8-3b57-4516-95ff-356fe594f083",
      "open": false,
      "order": 2
    },
    "9c6c5ec9-9c07-4ff4-9b4a-9cd7b9daeb10": {
      "cateType": "thing",
      "created": "2024-07-22T10:47:40-04:00",
      "name": "Credit",
      "modified": "2024-07-22T10:47:40-04:00",
      "parentCatKey": "552db372-ad80-44ed-a032-5642beec253f",
      "refKey": "",
      "open": false,
      "key": "9c6c5ec9-9c07-4ff4-9b4a-9cd7b9daeb10",
      "order": 1
    },
    "f0566660-be96-40c8-9b84-10fbb6b70377": {
      "cateType": "project",
      "created": "2024-07-22T10:34:48-04:00",
      "name": "Relations",
      "modified": "2024-07-22T10:34:48-04:00",
      "parentCatKey": "",
      "refKey": "8439074b-93af-42e8-a8be-013ac7ca602e",
      "key": "f0566660-be96-40c8-9b84-10fbb6b70377",
      "open": false,
      "order": 3
    },
    "141c9301-94f4-4326-8335-4544745788c7": {
      "cateType": "project",
      "created": "2024-07-22T11:03:15-04:00",
      "name": "Training",
      "modified": "2024-07-22T11:03:15-04:00",
      "parentCatKey": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "refKey": "8f454bb2-2cbf-4a83-b606-795ff5caf537",
      "open": false,
      "key": "141c9301-94f4-4326-8335-4544745788c7",
      "order": 6
    },
    "fa3f3cb6-538e-4b1a-9826-131a7902c933": {
      "cateType": "people",
      "created": "2024-07-22T10:57:44-04:00",
      "name": "Family",
      "modified": "2024-07-22T10:57:44-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "fa3f3cb6-538e-4b1a-9826-131a7902c933",
      "open": false,
      "order": 2
    },
    "16c7ea28-deb8-4e80-9820-547f67297b52": {
      "cateType": "thing",
      "created": "2024-07-22T10:45:27-04:00",
      "name": "Home Things",
      "modified": "2024-07-22T10:45:27-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "16c7ea28-deb8-4e80-9820-547f67297b52",
      "open": false,
      "order": 2
    },
    "148db25d-db17-4f81-9d10-85d42a086cfc": {
      "cateType": "thing",
      "created": "2024-07-22T10:45:53-04:00",
      "name": "Vehicles",
      "modified": "2024-07-22T10:45:53-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "148db25d-db17-4f81-9d10-85d42a086cfc",
      "order": 5
    },
    "root": {
      "created": "2024-07-22T10:35:20-04:00",
      "modified": "",
      "parentCatKey": "",
      "refKey": "",
      "key": "root",
      "open": false
    },
    "b731896f-0ee7-4a9a-9771-9f563e2b6c72": {
      "cateType": "project",
      "created": "2024-07-22T10:34:59-04:00",
      "name": "Finances",
      "modified": "2024-07-22T10:34:59-04:00",
      "parentCatKey": "",
      "refKey": "1ab58335-8bb0-4b1c-8dc3-545f1d0eaaa9",
      "open": false,
      "key": "b731896f-0ee7-4a9a-9771-9f563e2b6c72",
      "order": 5
    },
    "552db372-ad80-44ed-a032-5642beec253f": {
      "cateType": "thing",
      "created": "2024-07-22T10:45:14-04:00",
      "name": "Accounts",
      "modified": "2024-07-22T10:45:14-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "552db372-ad80-44ed-a032-5642beec253f",
      "open": true,
      "order": 0
    },
    "f9d30e9c-d796-4cc2-a583-04e24d0b69b2": {
      "cateType": "project",
      "created": "2024-07-22T10:38:59-04:00",
      "name": "Expenses",
      "modified": "2024-07-22T10:38:59-04:00",
      "parentCatKey": "b731896f-0ee7-4a9a-9771-9f563e2b6c72",
      "refKey": "8466dbbb-881e-42d5-9e9c-acfbcfdfdb8a",
      "key": "f9d30e9c-d796-4cc2-a583-04e24d0b69b2",
      "open": false,
      "order": 1
    },
    "bab38663-97ce-4ef1-ab6d-59b209dfd945": {
      "cateType": "people",
      "created": "2024-07-22T10:58:09-04:00",
      "name": "School",
      "modified": "2024-07-22T10:58:09-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "bab38663-97ce-4ef1-ab6d-59b209dfd945",
      "order": 7
    },
    "75a06a3f-9529-4ff5-9464-6a3b567a6d98": {
      "cateType": "thing",
      "created": "2024-07-22T10:45:46-04:00",
      "name": "Software",
      "modified": "2024-07-22T10:45:46-04:00",
      "parentCatKey": "",
      "refKey": "",
      "key": "75a06a3f-9529-4ff5-9464-6a3b567a6d98",
      "open": false,
      "order": 4
    },
    "b89c9904-0028-491d-b318-0eb321cbaf3d": {
      "cateType": "project",
      "created": "2024-07-22T10:34:54-04:00",
      "name": "Fixing",
      "modified": "2024-07-22T10:34:54-04:00",
      "parentCatKey": "",
      "refKey": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "key": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "open": false,
      "order": 4
    },
    "9593a563-1e7e-49c3-84f7-f9b739e250cd": {
      "cateType": "project",
      "created": "2024-07-22T11:02:09-04:00",
      "name": "Networking",
      "modified": "2024-07-22T11:02:09-04:00",
      "parentCatKey": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "refKey": "788a3fd9-4b44-46ee-929a-37f36d67d799",
      "key": "9593a563-1e7e-49c3-84f7-f9b739e250cd",
      "open": false,
      "order": 5
    },
    "b8e2c772-8970-46eb-a254-dc0667633a03": {
      "cateType": "project",
      "created": "2024-07-22T10:40:25-04:00",
      "name": "News, Entertain & Literature",
      "modified": "2024-07-22T10:40:25-04:00",
      "parentCatKey": "a8565cf8-cc93-42e6-8574-5057aa046a86",
      "refKey": "2422ec77-743f-4ed9-8930-0b71255dce97",
      "key": "b8e2c772-8970-46eb-a254-dc0667633a03",
      "open": false,
      "order": 1
    },
    "202b074b-cda0-4d34-b7b4-a9804600a14d": {
      "cateType": "project",
      "created": "2024-07-22T10:36:46-04:00",
      "name": "Community",
      "modified": "2024-07-22T10:36:46-04:00",
      "parentCatKey": "f0566660-be96-40c8-9b84-10fbb6b70377",
      "refKey": "df8c281a-56a6-4b9a-b7ee-035990732de1",
      "open": false,
      "key": "202b074b-cda0-4d34-b7b4-a9804600a14d",
      "order": 3
    },
    "84f23fbb-a86d-40cb-939b-8ac484de52f8": {
      "cateType": "thing",
      "created": "2024-07-22T10:45:34-04:00",
      "name": "Information",
      "modified": "2024-07-22T10:45:34-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "84f23fbb-a86d-40cb-939b-8ac484de52f8",
      "order": 3
    },
    "2bacb734-3b9a-4e9e-af89-8943ff01b87d": {
      "cateType": "project",
      "created": "2024-07-22T10:35:52-04:00",
      "name": "Dental",
      "modified": "2024-07-22T10:35:52-04:00",
      "parentCatKey": "57dd0cbe-8cf5-479b-adbd-1d2abe3ebecc",
      "refKey": "506632e5-f417-4b61-84bf-f6f40d78cf24",
      "open": false,
      "key": "2bacb734-3b9a-4e9e-af89-8943ff01b87d",
      "order": 2
    },
    "fc83d4c5-dbbe-4ac9-af7c-511460829545": {
      "cateType": "project",
      "created": "2024-07-22T10:38:37-04:00",
      "name": "Transportation",
      "modified": "2024-07-22T10:38:37-04:00",
      "parentCatKey": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "refKey": "ad0d1ec4-90fd-4723-a0ff-9b014f75aeaa",
      "open": false,
      "key": "fc83d4c5-dbbe-4ac9-af7c-511460829545",
      "order": 6
    },
    "c0fc03fc-3650-4e43-b19e-43a22d151329": {
      "cateType": "people",
      "created": "2024-07-22T10:57:26-04:00",
      "name": "Business",
      "modified": "2024-07-22T10:57:26-04:00",
      "parentCatKey": "",
      "refKey": "",
      "open": false,
      "key": "c0fc03fc-3650-4e43-b19e-43a22d151329",
      "order": 0
    },
    "a8565cf8-cc93-42e6-8574-5057aa046a86": {
      "cateType": "project",
      "created": "2024-07-22T10:35:15-04:00",
      "name": "Fun",
      "modified": "2024-07-22T10:35:15-04:00",
      "parentCatKey": "",
      "refKey": "a18129a2-f189-468b-af80-177bc7791540",
      "key": "a8565cf8-cc93-42e6-8574-5057aa046a86",
      "open": false,
      "order": 8
    },
    "9c9a6890-40f8-495c-82ad-396fe5516295": {
      "cateType": "project",
      "created": "2024-07-22T10:38:51-04:00",
      "name": "Credit",
      "modified": "2024-07-22T10:38:51-04:00",
      "parentCatKey": "b731896f-0ee7-4a9a-9771-9f563e2b6c72",
      "refKey": "b7ad912b-e50e-4b2d-86bd-5182eafa9a58",
      "open": false,
      "key": "9c9a6890-40f8-495c-82ad-396fe5516295",
      "order": 0
    },
    "18aafb92-fa6e-49a1-bcb6-449507fefde8": {
      "cateType": "project",
      "created": "2024-07-22T10:35:05-04:00",
      "name": "Consuming",
      "modified": "2024-07-22T10:35:05-04:00",
      "parentCatKey": "",
      "refKey": "5bd8df90-1972-45be-9424-c164bb028d6a",
      "open": false,
      "key": "18aafb92-fa6e-49a1-bcb6-449507fefde8",
      "order": 6
    },
    "b64cbdb2-b9bb-4f16-924a-becf6f0f8e02": {
      "cateType": "project",
      "created": "2024-07-22T10:40:37-04:00",
      "name": "Travel & Experiences",
      "modified": "2024-07-22T10:40:37-04:00",
      "parentCatKey": "a8565cf8-cc93-42e6-8574-5057aa046a86",
      "refKey": "6a995f61-2193-4cb7-8a24-fee997ccb2fa",
      "open": false,
      "key": "b64cbdb2-b9bb-4f16-924a-becf6f0f8e02",
      "order": 2
    },
    "57dd0cbe-8cf5-479b-adbd-1d2abe3ebecc": {
      "cateType": "project",
      "created": "2024-07-22T10:34:43-04:00",
      "name": "Health",
      "modified": "2024-07-22T10:34:43-04:00",
      "parentCatKey": "",
      "refKey": "6fc12101-4071-4f6c-8978-f9929dad0d17",
      "open": false,
      "key": "57dd0cbe-8cf5-479b-adbd-1d2abe3ebecc",
      "order": 2
    },
    "2bcc8bc5-3be0-4b7b-8be6-33c4286c4e78": {
      "cateType": "project",
      "created": "2024-07-22T10:37:25-04:00",
      "name": "Furnishings & Appliances",
      "modified": "2024-07-22T10:37:25-04:00",
      "parentCatKey": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "refKey": "e739b0ba-772d-4894-a591-d14d5eb2f2ec",
      "key": "2bcc8bc5-3be0-4b7b-8be6-33c4286c4e78",
      "open": false,
      "order": 0
    },
    "73e62e0b-705c-4694-b251-af8d3d2282a8": {
      "cateType": "project",
      "created": "2024-07-22T10:34:36-04:00",
      "name": "Business",
      "modified": "2024-07-22T10:34:36-04:00",
      "parentCatKey": "",
      "refKey": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "open": false,
      "key": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "order": 1
    },
    "50f47b63-f863-4123-bf06-80ffc85ce1be": {
      "cateType": "project",
      "created": "2024-07-22T10:39:30-04:00",
      "name": "Shop Local",
      "modified": "2024-07-22T10:39:30-04:00",
      "parentCatKey": "18aafb92-fa6e-49a1-bcb6-449507fefde8",
      "refKey": "88e53460-ca68-421b-b730-14972e1b340f",
      "open": false,
      "key": "50f47b63-f863-4123-bf06-80ffc85ce1be",
      "order": 0
    },
    "1af7546b-c933-4f26-9af5-aedb690b899f": {
      "cateType": "project",
      "created": "2024-07-22T10:37:40-04:00",
      "name": "Hardware",
      "modified": "2024-07-22T10:37:40-04:00",
      "parentCatKey": "b89c9904-0028-491d-b318-0eb321cbaf3d",
      "refKey": "d64cfb5a-8121-4e42-acd7-a9f3e344399a",
      "open": false,
      "key": "1af7546b-c933-4f26-9af5-aedb690b899f",
      "order": 1
    },
    "b6495f46-ce40-4dab-8f3b-8f502edc39ea": {
      "cateType": "project",
      "created": "2024-07-22T11:03:00-04:00",
      "name": "Marketing",
      "modified": "2024-07-22T11:03:00-04:00",
      "parentCatKey": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "refKey": "ab60c79a-78ac-4415-89c4-94a23701565f",
      "open": false,
      "key": "b6495f46-ce40-4dab-8f3b-8f502edc39ea",
      "order": 3
    },
    "1a25897f-4458-4ec5-bd05-61aaf55593e9": {
      "cateType": "project",
      "created": "2024-07-22T11:03:06-04:00",
      "name": "Legal",
      "modified": "2024-07-22T11:03:06-04:00",
      "parentCatKey": "73e62e0b-705c-4694-b251-af8d3d2282a8",
      "refKey": "621a2170-6de5-49d7-9bfd-66407edb5f39",
      "key": "1a25897f-4458-4ec5-bd05-61aaf55593e9",
      "open": false,
      "order": 4
    }
  }
}