<template>
  <div class="fill-height resource-edit-form">
    <FormLayout
      :showForm="isResourceDataValid"
      :showLoading="loading"
      editType="Resource"
      :showDivider="false"
      :isEditStarted="isEditStarted"
      :currItemIndex="currItemIndex"
      :totalItemCount="totalResourcesCount"
      :useBtnRightSide="true"
      paginationRowClass="pagination-row"
      paginationBtnIconColor="#515151"
      useBeforeControls
      @save="submitData"
      @close="closeForm"
      @edit-item:select="handleResourceEditType"
      :showPagination="false"
    >
      <template #before-controls>
        <div class="d-flex btn-row">
          <ClearFilterBtn
            :isEmpty="isTaskListEmpty"
            :count="taskList.length"
            @clear-btn:click="handleFiltersClearClick"
            :btnAttrs="clearBtnAttrs"
          />

          <ProjectsViewToggleBtn v-if="showProjectsToggleBtn" />
        </div>

        <!-- <v-row no-gutters class="btn-row">
          <v-col cols="auto">
            <ClearFilterBtn
              :isEmpty="isTaskListEmpty"
              :count="taskList.length"
              @clear-btn:click="handleFiltersClearClick"
              :btnAttrs="clearBtnAttrs"
            />
          </v-col>
          <v-col cols="auto">
            <ToggleOrderedTasksBtnVue />
          </v-col>
        </v-row> -->
      </template>

      <template #header-left-side>
        <TasksFilterBar
          v-if="isResourceDataValid"
          :showClrFilterBtn="false"
          mode="resource"
          :filterOpts="filterOpts"
          :showResourceCategories="true"
        />
        <!-- <NewTaskFilterBar
          :showClrFilterBtn="false"
          :filterOpts="filterBarOpts"
        /> -->
      </template>
      <template #main-content>
        <div
          class="task-title-actions-wrapper resource-type-title-row editable-area"
        >
          <v-row no-gutters>
            <v-col cols="auto" md="auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="descr-toggler-btn"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleDescr"
                    :color="iconColor"
                    small
                  >
                    <span :class="descrTogglerData.iconClasses" />
                  </v-btn>
                </template>
                <span> {{ descrTogglerData.label }} </span>
              </v-tooltip>
            </v-col>
            <v-col cols>
              <v-row
                class="item-and-title-resource-wrapper"
                no-gutters
                dense
                justify="space-between"
              >
                <v-col cols="auto">
                  <v-row no-gutters dense>
                    <v-col cols="auto">
                      <div>
                        <ItemTypeMenu
                          useSmallBtn
                          :iconSize="16"
                          type="resource"
                          iconColor="#000000de"
                          :value="newResourceData.type"
                          @input="handleResourceTypeChange"
                          ref="resourceTypeSelector"
                        />
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <div class="resource-title-input-wrapper">
                        <TitleEditor
                          :value="newResourceData.title"
                          @input="handleTitleChange"
                          :enableMentions="false"
                          :refSetter="(refEl) => setRef(refEl, 'titleInput')"
                          :hideBorder="true"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="auto">
                  <div class="d-flex resource-tag-editor-row">
                    <p class="resource-tag-editor-label">Tag:</p>
                    <div class="flex-1">
                      <ResourceTagEditorInput
                        ref="resourceTagInput"
                        v-model="newResourceData.tag"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="descr-editor-wrapper mt-2 editable-area">
          <div class="descr-box-wrapper" v-show="isDescrOpen">
            <DescrEditor
              :value="descrData"
              :enableMentions="false"
              @input="handleDescrDataChange"
              :placeholder="editorPlaceholder"
              :refSetter="(refEl) => setRef(refEl, 'editor')"
              :addSpaceOnEnter="true"
            />
          </div>
        </div>

        <div
          class="task-title-actions-wrapper editable-area"
          style="margin-top: 10px; margin-bottom: 10px"
          v-if="isTaskActive"
        >
          <v-row no-gutters align="center" justify="space-between">
            <v-col cols="auto" class="flex-1">
              <v-row dense align="center">
                <v-col cols="auto">
                  <div class="project-priority-wrapper">
                    <PrioritySelectorInput
                      :value="activeProject.priority"
                      @input="updateProjectPriority"
                      contentClass="px-1 project-priority"
                    />
                  </div>
                </v-col>
                <v-col cols="auto" class="flex-shrink-80">
                  <div class="project-selector-wrapper">
                    <ProjectSelector
                      :value="newTaskData.project"
                      @change="changeProject"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="auto" sm="12">
              <v-row align="center" dense>
                <v-col cols="auto">
                  <v-row align="center" dense>
                    <v-col cols="auto">
                      <v-row align="center" dense>
                        <v-col cols="auto">
                          <span class="txt-sm"> Repeat </span>
                        </v-col>
                        <v-col cols="auto">
                          <div style="width: 70px">
                            <OptSelector
                              ref="repeatSelector"
                              :value="newTaskData.repeatMode"
                              @input="handleRepeatTypeSelection"
                              @focus="handleRepeatTypeFocus"
                              @keydown="
                                handleTabFocus(
                                  $event,
                                  'repeat-interval-selector',
                                  'title'
                                )
                              "
                              class="txt-sm"
                              :opts="repeatTypeOpts"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                    <template v-if="newTaskData.isRecurring">
                      <v-col cols="auto">
                        <div style="width: 50px">
                          <OptSelector
                            :value="newTaskData.recurrenceCount"
                            @input="handleIntervalChange"
                            class="txt-sm"
                            :opts="repeatCountOpts"
                            @keydown="
                              handleTabFocus(
                                $event,
                                'repeat-frequency-selector',
                                'repeat-type-selector'
                              )
                            "
                            @focus="handleRepeatInternalSelectorFocus"
                            ref="repeatEveryIntervalSelector"
                          />
                        </div>
                      </v-col>
                      <v-col cols="auto">
                        <div style="width: 85px">
                          <OptSelector
                            :value="newTaskData.frequency"
                            @input="handleFrequencyChange"
                            @blur="handleFreqBlur"
                            @keydown="
                              handleTabFocus(
                                $event,
                                'date-picker',
                                'repeat-interval-selector'
                              )
                            "
                            @focus="handleRepeatFrequencySelector"
                            class="txt-sm repeat-every-frequency"
                            ref="repeatEveryFrequencySelector"
                            :opts="freqOpts"
                          />
                          <error-text :text="freqError" />
                        </div>
                      </v-col>
                    </template>

                    <template v-if="isTaskInRepeatInMode">
                      <v-col cols="auto">
                        <div style="width: 50px">
                          <v-text-field
                            class="repeat-interval-input"
                            :value="newTaskData.repeatInfo.count"
                            placeholder="Enter number"
                            @input="handleInternvalCount"
                            @focus="handleRepeatInternalSelectorFocus"
                            type="number"
                            min="1"
                            hide-details
                            ref="repeatInIntervalInput"
                          />
                        </div>
                      </v-col>

                      <v-col cols="auto">
                        <div style="width: 85px">
                          <OptSelector
                            :value="newTaskData.repeatInfo.frequency"
                            @input="handleFrequencyChange"
                            @blur="handleFreqBlur"
                            @keydown="
                              handleTabFocus(
                                $event,
                                taskRepeatInfo.showTxt
                                  ? 'repeat-info'
                                  : 'date-picker',
                                'repeat-interval-selector'
                              )
                            "
                            @focus="handleRepeatFrequencySelector"
                            class="txt-sm repeat-in-frequency"
                            ref="repeatInFrequencySelector"
                            :opts="freqOpts"
                          />

                          <error-text :text="freqError" />
                        </div>
                      </v-col>
                    </template>

                    <v-col cols="auto" v-if="taskRepeatInfo.showTxt">
                      <div
                        style="
                          height: 24px;
                          width: 24px;
                          text-align: center;
                          cursor: pointer;
                        "
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              size="20"
                              :color="infoIconColor"
                              class="repeat-info-icon"
                              @keydown="
                                handleTabFocus(
                                  $event,
                                  'date-picker',
                                  'frequency-selector'
                                )
                              "
                              :tabindex="-1"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <span>{{ taskRepeatInfo.txt }}</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="auto">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        @click="toggleReminderDays"
                        @keydown="handleTabFocus($event, '', 'date-picker')"
                      >
                        <v-icon
                          size="16"
                          :color="reminderDaysBtnIconData.color"
                        >
                          {{ reminderDaysBtnIconData.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ reminderDaysBtnIconData.label }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="auto">
                  <div style="width: 40px">
                    <v-text-field
                      class="reminder-input"
                      :value="newTaskData.reminderDays"
                      @input="
                        handleReminderDaysVal(
                          $event,
                          'newTaskData',
                          'storedTaskData'
                        )
                      "
                      @keydown="handleTabFocus($event)"
                      placeholder="Enter number of days to remind before"
                      type="number"
                      min="0"
                      hide-details
                      ref="reminderInput"
                    />
                  </div>
                </v-col>

                <v-col cols="auto">
                  <ContingentToggleBtn
                    :isEnabled="newTaskData.isContingent"
                    iconName="$ctiCalendarCross"
                    @click="toggleContingent('newTaskData', 'storedTaskData')"
                  />
                </v-col>
                <v-col cols="auto">
                  <div class="advance-date-input-wrapper" v-if="isTaskActive">
                    <AdvancedDateTimePicker
                      :value="newTaskData.dueAsDate"
                      ref="datePicker"
                      @dateChanged="
                        changeTaskDate($event, 'newTaskData', 'storedTaskData')
                      "
                      @blur="handleDateInputBlur('newTaskData')"
                      inputWidth="xs"
                      :scrollTimeList="!selectedDateIsValid"
                      :tabindex="6"
                      useExistingValue
                    />
                    <error-text :text="startDateError" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <div
          class="mt-3 form-grid resource-tasks-grid"
          style="height: 510px"
          :data-grid-active="isFormGridActive"
        >
          <ActivityHistoryTable
            @rows:selected="handleRowsSelected"
            @list:processed="handleListProcessed"
            projectId=""
            mode="resource-edit"
            :resourceId="newResourceData.key"
            :listLoaded="dataIsLoaded"
            :actionsAllowed="['add']"
            useAdditionalTaskFilters
            :isActive="isActive"
            ref="historyTable"
          />
        </div>

        <ResourceParentChangeDialog />
        <RecurringTaskEditConfirm
          :toggleLoader="loaderToggler"
          :setDataForEditForm="true"
          inResourceMode
          @data-submit:success="callActionAfterSubmitSuccess"
        />
      </template>
    </FormLayout>
  </div>

  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import Vue from "vue";
import cloneDeep from "lodash/cloneDeep";
import EventEmitter from "@/helpers/eventEmitter";
import isEmpty from "lodash/isEmpty";
import {
  TRIGGER_USER_ACTION_EVENT,
  REFRESH_FORM_DATA,
  REFRESH_TASKS_EVENT,
  REFRESH_NOTES_LIST,
  REFRESH_RESOURCE_FORM_DATA,
  OPEN_SELECTED_ITEM,
  TOGGLE_RESOURCE_FORM_EVENT,
  REFRESH_TREE_EVENT,
  REFRESH_TASK_DATA_IN_RESOURCE_FORM,
} from "@/variables/events";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { DATE_WITH_TIME_FORMAT_IN_USE, DAY_FORMAT } from "@/variables/dates";

import userDetailsMixin from "@/mixins/userDetailsMixin";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
import {
  checkIfItemDataIsEdited,
  convertDateTimeToReadableFormat,
  isGroupRow,
} from "@/helpers/common";
import {
  addOrUpdateOrRemoveResourcesInLocalList,
  createUpdateAndEditedResourceData,
  getUserResources,
  processResourceData,
  expandAllParentsOfResource,
  setResourceIdInTree,
  checkIfResourceDescrIsEmpty,
} from "@/helpers/resources";
import { RESOURCE_PROPS_FOR_FORM } from "@/variables/resources";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import formHelpersMixin from "@/mixins/formHelpersMixin";
import taskFormHelpersMixin from "@/mixins/taskFormHelpersMixin";
import {
  GREY_ICON_COLOR,
  DARK_GREY_ICON_COLOR,
  PRIMARY_COLOR,
} from "@/variables/colors";
import TitleEditor from "@/components/FormComponents/TitleEditor.vue";
import DescrEditor from "@/components/FormComponents/DescrEditor.vue";
import ItemTypeMenu from "@/components/ItemTypeMenu";
import { SubTasksTable as ActivityHistoryTable } from "@/components/ActivityHistoryTable";
import ResourceCateSelector from "@/components/EditComponents/ResourceCateSelector";
import ResourceTagEditorInput from "@/components/ResourcesListView/components/ResourcesTable/components/ResourceTagEditorInput.vue";
import debounce from "lodash/debounce";
import CreatedAndModifiedViewer from "@/components/FormComponents/CreatedAndModifiedViewer.vue";
import FormLayout from "@/layouts/FormLayout.vue";
import TasksFilterBar from "@/components/NewTaskFilterBar";
import { decrement, increment } from "@/utils/number";
import {
  areTaskRecurrenceRulesValid,
  checkIfTaskIsParentRecurringTask,
  checkIfTaskIsRecurring,
  createAdditionalDates,
  createStrWithMentionsFromQlDelta,
  getMainTaskRulesAndFillRecurringOpts,
  parseRuleDataFromTaskRecurrenceRules,
  makeVirtualTaskVisibleInFormGrid,
  createRecurrenceOptsValuesFromTaskRecurrenceRules,
  checkIfTaskIsContinued,
  addOrRemoveOrUpdateTasksInDb,
  addOrRemoveOrUpdateTasksInLocalTasksList,
  getDateAndTimeFromDueDate,
} from "@/helpers/tasks";
import DatabaseInterface from "@/services/DatabaseInterface";
// import ToggleOrderedTasksBtnVue from "@/components/ToggleOrderedTasksBtn";
import ClearFilterBtn from "@/components/ClearFilterBtn";
import ResourceParentChangeDialog from "@/components/ResourceParentChangeDialog.vue";
import PrioritySelectorInput from "@/components/EditComponents/PrioritySelector/PrioritySelector.vue";
import ProjectsViewToggleBtn from "@/components/ProjectsViewToggleBtn.vue";
import ProjectSelector from "@/components/EditComponents/ProjectSelector/ProjectSelector.vue";
import OptSelector from "@/components/OptSelector";
import AdvancedDateTimePicker from "@/components/AdvancedDateTimePicker";
import ErrorText from "@/core/components/ErrorText";
import ContingentToggleBtn from "@/components/TaskEditForm/components/ContingentToggleBtn.vue";
// import ContingentToggleBtn from "./components/ContingentToggleBtn.vue";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { closeTaskCateTreeview } from "@/helpers/categories";
import isEscKey from "@/utils/isEscKey";
import repeatTypeOpts from "@/data/repeatTypeOpts";
import frequencyOpts, { frequencyOptsMap } from "@/data/frequencyOpts";
import repeatCountOpts from "@/data/repeatTasksCountOpts";
import { daysOfWeek } from "@/data/weekDays";
import {
  isValidDate,
  formatDate,
  getComponentsFromDate,
} from "@/helpers/dates";
import { checkIfTaskIsRepeatIn, createDateTimeFromDue } from "@/helpers/tasks";
import { TASK_FREQ_FROM_RRULE_MAP } from "@/variables/tasks";
import createUniqueId from "@/utils/createUniqueId";
import isUndefinedVal from "@/utils/isUndefinedVal";
import {
  ACTION_ON_TYPES,
  getUserActions,
  RESOURCE_ACTIONS,
} from "@/helpers/actionHistory";
import { addOrUpdateOrRemoveTasksInGoogleCal } from "@/mixins/googleCalHelpersMixins";
import RecurringTaskEditConfirm from "@/components/RecurringTaskEditConfirm";
import { isEqual } from "lodash";
// import TasksFilterBar from 'components/TasksListView/components/TasksFilterBar';
export default {
  components: {
    ErrorText,
    AdvancedDateTimePicker,
    ContingentToggleBtn,
    OptSelector,
    ProjectSelector,
    PrioritySelectorInput,
    ResourceParentChangeDialog,
    // ToggleOrderedTasksBtnVue,
    // eslint-disable-next-line vue/no-unused-components
    CreatedAndModifiedViewer,
    TitleEditor,
    ItemTypeMenu,
    // eslint-disable-next-line vue/no-unused-components
    ResourceCateSelector,
    // eslint-disable-next-line vue/no-unused-components
    ResourceTagEditorInput,
    FormLayout,
    DescrEditor,
    ActivityHistoryTable,
    ClearFilterBtn,
    TasksFilterBar,
    ProjectsViewToggleBtn,
    RecurringTaskEditConfirm,
  },
  mixins: [
    userDetailsMixin,
    resourcesHelpersMixin,
    formHelpersMixin,
    tasksHelpersMixin,
    taskFormHelpersMixin,
  ],
  props: {
    sendRefreshEvent: {
      type: Boolean,
    },
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      areChangesSaved: false,
      loading: true,
      showEditor: false,
      dataIsLoaded: false,
      triggerEditChecker: false,
      newTaskData: {},
      // currItemIndex: 0,
      newResourceData: {},
      repeatInModeInfo: {},
      descrData: "",
      descrDelta: [],
      titleDelta: [],
      showList: false,
      loadList: false,
      isDescrOpen: false,
      taskList: [],
      activeProject: {},

      endDateError: "",
      freqError: "",
      startDateError: "",
      descriptionEditorDelta: {
        old: null,
        new: null
      }
    };
  },
  methods: {
    ...mapMutations(["setLoader"]),
    ...mapActions({
      loaderToggler: "toggleLoader",
      updateRootState: "updateRootState",
    }),
    ...mapActions("toast", ["showToast"]),
    ...mapActions("editForm", [
      "closeEditTaskDialog",
      "setTaskData",
      "openEditTaskConfirmBox",
      "setDataForNoteEdit",
      "setDataForResourceEdit",
    ]),
    ...mapActions("resourceInfo", [
      "setResourceData",
      "closeResourceInfoViewer",
      "setStoredTaskData",
    ]),
    ...mapActions("resourceParentForm", ["openResourceParentForm"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    ...mapActions("task", ["updateSelectedTasks"]),

    isValidRemindVal(val) {
      return val > 0;
      //
    },
    toggleReminderDays() {
      let reminderDays = this.newTaskData.reminderDays || null;

      if (
        !isUndefinedVal(reminderDays) &&
        this.isValidRemindVal(reminderDays)
      ) {
        this.currPausedReminderDays = !isEmpty(
          this.newTaskData.pausedReminderDays
        )
          ? [...this.newTaskData.pausedReminderDays]
          : undefined;
        this.newTaskData.reminderDays = 0;
        this.newTaskData.pausedReminderDays = [];
        this.newTaskData.isContingent =
          this.storedTaskData.isContingent || false;
      } else {
        this.newTaskData.reminderDays = this.storedTaskData.reminderDays || 1;
        this.newTaskData.isContingent = false;
        if (!isEmpty(this.currPausedReminderDays)) {
          this.newTaskData.pausedReminderDays = [
            ...this.currPausedReminderDays,
          ];
        }
      }
    },
    handleFreqBlur() {
      let freqError = "";
      let showRequiredError = false;
      if (this.isTaskInRepeatEveryMode) {
        if (this.newTaskData.isRecurring) {
          if (!this.newTaskData.frequency) {
            showRequiredError = true;
          }
        }
      }

      if (this.isTaskInRepeatInMode) {
        if (
          !isEmpty(this.newTaskData.repeatInfo) &&
          !this.newTaskData.repeatInfo.frequency
        ) {
          showRequiredError = true;
        }
      }

      if (showRequiredError) {
        freqError = "Frequency is required";
      }

      this.freqError = freqError;
    },
    handleIntervalChange(val) {
      this.newTaskData.recurrenceCount = val;
      this.$nextTick(this.createRecurringDates);
    },
    handleInternvalCount(val) {
      this.newTaskData.repeatInfo = {
        ...this.newTaskData.repeatInfo,
        count: val !== "" ? parseInt(val, 10) : undefined,
      };
      // this.taskCopy.repeatInfo.count =
    },
    handleFrequencyChange(val) {
      this.newTaskData.frequency = val;
      this.freqError = "";
      let isSelectedTaskDateValid = this.selectedDateIsValid;
      if (this.isTaskInRepeatEveryMode) {
        if (val === "weekly" && isSelectedTaskDateValid) {
          this.newTaskData.recurrenceRepeatDay = getComponentsFromDate(
            this.newTaskData.dueAsDate
          ).weekDay;
        }

        if (val === "monthly" && isSelectedTaskDateValid) {
          this.newTaskData.repeatMonthDate = `${formatDate(
            this.newTaskData.dueAsDate,
            DAY_FORMAT
          )}_monthly`;
        }
        this.$nextTick(this.createRecurringDates);
      }

      if (this.isTaskInRepeatInMode) {
        this.newTaskData.repeatInfo = {
          ...this.newTaskData.repeatInfo,
          frequency: val,
        };
      }
    },
    handleRepeatTypeSelection(val) {
      let isDateValid = true;

      if (val !== "never") {
        if (val === "every") {
          let changeRestVals = true;
          if (!isValidDate(this.newTaskData.dueAsDate)) {
            isDateValid = false;
            changeRestVals = false;
          }
          let frequencyToSet, frequencyCount;

          if (!this.newTaskData.frequency) {
            frequencyToSet = "daily";
          }

          if (
            !checkIfTaskIsRecurring(this.storedTaskData) &&
            checkIfTaskIsRepeatIn(this.storedTaskData)
          ) {
            frequencyToSet = this.storedTaskData.repeatInfo.frequency;
            frequencyCount = this.storedTaskData.repeatInfo.count;
          }

          if (frequencyToSet !== undefined) {
            this.newTaskData.frequency = frequencyToSet;

            if (frequencyCount !== undefined) {
              this.newTaskData.recurrenceCount = frequencyCount;
            }
          }

          if (changeRestVals) {
            if (this.newTaskData.frequency === "monthly") {
              this.newTaskData.repeatMonthDate = `${formatDate(
                this.newTaskData.dueAsDate,
                DAY_FORMAT
              )}_monthly`;
            }

            if (!this.newTaskData.recurrenceRepeatDay) {
              this.newTaskData.recurrenceRepeatDay = getComponentsFromDate(
                this.newTaskData.dueAsDate
              ).weekDay;
            }
          }
          this.newTaskData.isRecurring = true;
          this.newTaskData.repeatInfo = {};
        }

        if (val === "in") {
          if (!isValidDate(this.newTaskData.dueAsDate)) {
            isDateValid = false;
          }

          if (isEmpty(this.storedTaskData.repeatInfo)) {
            let repeatInfo = {
              count: 1,
              frequency: "daily",
            };

            if (
              !checkIfTaskIsRepeatIn(this.storedTaskData) &&
              checkIfTaskIsRecurring(this.storedTaskData)
            ) {
              const mainTaskData =
                this.rawTasksMap[this.getTaskId(this.storedTaskData)];

              if (!isEmpty(mainTaskData)) {
                const { rule } = parseRuleDataFromTaskRecurrenceRules({
                  recurrence: mainTaskData.recurrence,
                  dueAsDate: createDateTimeFromDue(
                    mainTaskData.due,
                    mainTaskData.time
                  ),
                });
                const parsedRuleOptions = rule.options;
                repeatInfo.frequency =
                  TASK_FREQ_FROM_RRULE_MAP[parsedRuleOptions.freq];
                repeatInfo.count = parsedRuleOptions.interval;
              }
              // repeatInfo.
            }

            this.newTaskData.repeatInfo = { ...repeatInfo };
          } else {
            this.newTaskData.repeatInfo = {
              ...this.storedTaskData.repeatInfo,
            };
          }

          this.newTaskData.isRecurring = false;
          this.newTaskData.recurrence = [];
          this.newTaskData.frequency = "";
        }

        if (!isDateValid) {
          this.startDateError = "Date is required";
        }
      } else {
        this.newTaskData.frequency = this.storedTaskData.frequency
          ? this.storedTaskData.frequency
          : "";
        this.newTaskData.recurrence = areTaskRecurrenceRulesValid(
          this.storedTaskData.recurrence
        )
          ? this.storedTaskData.recurrence
          : null;

        this.newTaskData.coRelationalId = this.storedTaskData.coRelationalId
          ? this.storedTaskData.coRelationalId
          : "";

        this.newTaskData.repeatInfo = {};
        this.newTaskData.isRecurring = false;
        this.freqError = "";
        this.startDateError = "";
        // this.resetModes();
      }

      this.newTaskData.repeatMode = val;

      this.$nextTick(this.createRecurringDates);
    },
    createRecurringDates() {
      const startDateTime = this.newTaskData.dueAsDate;
      const isRecurring = this.newTaskData.isRecurring;

      const currFrequency = frequencyOptsMap[this.newTaskData.frequency];
      const currRecurrenceCount = this.newTaskData.recurrenceCount;
      const currRepeatDay = this.newTaskData.recurrenceRepeatDay;
      let rules = [];
      if (
        isRecurring &&
        isValidDate(startDateTime) &&
        currFrequency &&
        currFrequency.value !== "once"
      ) {
        // Calculate

        const res = createAdditionalDates({
          startDateTime,
          frequency: currFrequency,
          repeatCount: currRecurrenceCount,
          repeatDay: currRepeatDay,
        });
        rules = res.rules;
      }

      if (!isEmpty(rules) && !this.newTaskData.coRelationalId) {
        this.newTaskData.coRelationalId = createUniqueId();
      }

      this.newTaskData.recurrence = rules;
    },
    handleResourceTypeChange(type) {
      if (type !== this.resourceData.type) {
        this.openResourceParentForm({
          resourceData: {
            tag: this.newResourceData.tag,
            type,
            key: this.newResourceData.key,
          },
        });
      }
      // this.newResourceData.type = type;
      // if (type !== this.resourceData.type) {
      //   this.oldResourceCatId = this.newResourceData.catId || "";
      //   this.newResourceData.catId = "";
      // } else if (this.oldResourceCatId) {
      //   this.newResourceData.catId = this.oldResourceCatId;
      // }
    },
    handleRowsSelected(rows) {
      this.checkAndLoadTaskDataFromSelection(rows.map((row) => row.key));
      this.updateSelectedTasks(rows);
    },
    handleFiltersClearClick() {
      const isResourceDataChanged = this.checkIfAnyEditIsMade();

      if (isResourceDataChanged) {
        this.userActionType = "clear-list";
        this.userActionValue = "clear";
        this.showConfirmBoxForChanges(undefined, false);
        return;
      }
      this.closeForm();
      this.clearListFiltersList(false, true);
    },
    toggleDescr() {
      this.isDescrOpen = !this.isDescrOpen;
    },
    handleListProcessed(list) {
      this.taskList = list;
    },
    handleOpenSelectedItem(resourceData) {
      if (!this.isOpen || (this.isOpen && this.isEditFormOpen)) {
        return;
      }

      const isDataChanged = this.checkIfAnyEditIsMade();
      if (isDataChanged) {
        this.useResorceDataForLoad = true;
        this.resourceDataToLoad = { ...resourceData };
        this.showConfirmBoxForChanges(undefined, false);
        return;
      }

      this.setProvidedResourceDataInForm({ ...resourceData });
    },
    checkIfCheckFlowShouldContinue(eventData) {
      if (!isEmpty(eventData)) {
        const isEscKeyPress = isEscKey(eventData);
        const parentRes = findParentFromPathsByClassName(
          getPathsFromEvent(eventData),
          ["form-grid", "editable-area", "item-selector-menu"],
          "obj"
        );
        if (isEscKeyPress && parentRes.found) {
          return false;
        }

        return true;
      }
      return true;
    },
    filterMentionsFromDelta(delta) {
      return delta?.filter(d => {
        if (d?.insert && d?.insert?.resourceMention) {
          return false;
        }
        return true
      })
    },
    handleDescrDataChange(val, oldDelta, newDelta) {
      if (this.areChangesSaved) this.areChangesSaved = false;
      
      this.descrData = val;

      this.descriptionEditorDelta = {
        old: this.filterMentionsFromDelta(oldDelta),
        new: this.filterMentionsFromDelta(newDelta),
      }

      this.debounedConvertAndSetDescrDelta(val);
      // this.debouncedDescrSave(val);
    },
    setRef(ref, name) {
      this.$refs[name] = ref;
    },
    async clearResourceSelection() {
      const currSelectedResourceType = this.selectedResourceTypeOpt;

      const dataToSet = {
        mode: currSelectedResourceType,
        selection: [],
      };

      if (this.isProjectsResourceModeEnabled) {
        const dbUpdates = {
          [`/view/1/selectedOptsList`]: [],
        };

        await closeTaskCateTreeview();
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }
      await getUserResources().set(dataToSet, "selectedCategories");
      return true;
    },
    checkForSendingRefreshAndSendEvent() {
      if (this.sendRefreshEvent) {
        if (this.isEditFormOpen) {
          EventEmitter.emit(REFRESH_FORM_DATA, true);
        }
        EventEmitter.emit(
          this.$route.name === "Notes"
            ? REFRESH_NOTES_LIST
            : REFRESH_TASKS_EVENT
        );
      }
    },
    async returnToOriginalView(returnViewData, doNotRun) {
      // let dbUpdates = {};
      // let viewConfig = {};
      // let routeName = "";
      if (
        !this.isOpen ||
        (this.userActionType && this.userActionType !== "close-form") ||
        doNotRun ||
        isEmpty(returnViewData)
      )
        return;

      const clonedReturnViewInfo = cloneDeep(returnViewData);

      if (this.$route.name === "Dashboard") {
        delete clonedReturnViewInfo.to;

        await this.$nextTick();

        if (clonedReturnViewInfo.goBackToResourceMode) {
          this.handleDashboardReturnToView(clonedReturnViewInfo);
        } else {
          this.handleDashboardViewConfig(clonedReturnViewInfo);
        }
      } else {
        this.handleResourcesViewReturnInfo(clonedReturnViewInfo);
      }
    },
    async closeDialog(ignoreOtherSteps) {
      const isStoredViewDataValid =
        this.closedByEsc && !isEmpty(this.storedViewData);
      if (this.closeFormAfterAction) {
        if (
          this.clearSelectedResource &&
          (this.$route.name === "Resources" ||
            this.isProjectsResourceModeEnabled)
        ) {
          this.clearSelectedResource = false;
          this.clearResourceSelection();
        }

        const returnViewInfo = isStoredViewDataValid
          ? this.storedViewData
          : this.storedReturnViewInfo;

        this.checkForSendingRefreshAndSendEvent();
        this.returnToOriginalView(cloneDeep(returnViewInfo), ignoreOtherSteps);

        if (!this.closedByEsc) {
          this.updateRootState({
            currViewData: {},
            itemInspectEnabled: false,
          });
        }
        if (!isStoredViewDataValid) {
          this.closeResourceInfoViewer();
        }
      }

      this.closedByEsc = false;
    },
    triggerActionActionAfterSubmit() {
      EventEmitter.emit(TRIGGER_USER_ACTION_EVENT, {
        type: this.userActionType,
        value: this.userActionValue,
      });
      this.resetUserAction();

      // if (this.isEditFormOpen) {
      //   this.closeEditTaskDialog();
      // }
      this.closeDialog(true);

      // Add action send logic
    },
    callActionAfterSubmitSuccess() {
      // cccclea

      //       "data-action-type": "clear-list",
      // "data-action-value": "clear",
      const formActions = [
        "item-change",
        "close-form",
        "clear-list",
        "close-by-esc",
      ];

      if (this.resumeActionAfterSubmit && this.userActionType) {
        if (formActions.includes(this.userActionType)) {
          if (this.userActionType === formActions[0]) {
            this.changeItemForEdit(this.userActionValue);
          }

          if (
            this.userActionType === formActions[1] ||
            this.userActionType === formActions[3]
          ) {
            this.closeDialog();
          }

          if (this.userActionType === formActions[2]) {
            this.updateRootState({
              currViewData: {},
              itemInspectEnabled: false,
            });
            this.clearListFiltersList(false, true);
          }

          this.resetUserAction();
        } else {
          this.triggerActionActionAfterSubmit();
        }
      } else if (this.loadTaskFromList && !isEmpty(this.taskDataToLoad)) {
        this.setSelectedTaskDataInForm(
          getMainTaskRulesAndFillRecurringOpts(this.taskDataToLoad)
        );
      } else if (
        this.useResorceDataForLoad &&
        !isEmpty(this.resourceDataToLoad)
      ) {
        this.setProvidedResourceDataInForm({ ...this.resourceDataToLoad });
      }
    },
    setProvidedResourceDataInForm(resourceData) {
      this.useResorceDataForLoad = false;
      this.resourceDataToLoad = undefined;
      this.setResourceFormData(resourceData);
    },
    closeForm() {
      this.clearSelectedResource = true;
      this.checkForEditAndCloseForm();
      this.$router.replace({ query: null });
    },

    handleTitleChange(val) {
      if (this.isResourceEditEnabled) {
        this.newResourceData.title = val;
      }
      this.debouncedConvertAndSetDelta(val);
    },

    toggleLoader(val) {
      this.loading = val;
    },

    async processResourceData() {
      const currResourceData = this.resourceData;
      let updatedResourceData = this.newResourceData;
      const updatedTag =
        (updatedResourceData.tag && updatedResourceData.tag.trim()) || "";
      updatedResourceData = {
        ...this.newResourceData,
        descr: this.getDescrVal(undefined, "descrRTE") || "",
        tag: updatedTag,
      };

      const currTag = currResourceData.tag || "";

      if (updatedTag !== currTag) {
        const isTagValidRes = await this.checkIfTagIsValid(
          updatedTag,
          updatedResourceData.type
        );
        if (!isTagValidRes.isValid) {
          this.showAlertForTagNotValid(isTagValidRes);
          return;
        }
      }
      this.loading = true;
      const { updates, editedData } = createUpdateAndEditedResourceData(
        { ...updatedResourceData },
        currResourceData
      );

      this.addOrRemoveOrUpdateResourcesInList({
        resourcesToUpdate: [
          {
            updates,
            editedData,
            key: currResourceData.key,
          },
        ],
      });

      addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToUpdate: [
          {
            key: currResourceData.key,
            updates,
          },
        ],
      });

      await Vue.nextTick();
      this.setResourceFormData({ ...this.newResourceData, ...updates });
      this.callActionAfterSubmitSuccess();
    },
    async updateEditResourceData(data) {
      const processedResourceData = processResourceData(data);
      this.setResourceData(processedResourceData);
    },
    async setResourceFormData(dataToSet, processData = true) {
      const { taskData, ...resourceData } = dataToSet;
      const resourceObj = processData
        ? processResourceData(resourceData)
        : resourceData;
      this.setResourceData({
        ...resourceObj,
      });

      if (!isEmpty(taskData)) {
        this.setStoredTaskData(taskData);
      }

      await this.$nextTick();
      this.reloadItemData();
    },

    reloadItemData(onlyRefreshData, refreshDescrText) {
      if (this.isOpen && !isEmpty(this.resourceData)) {
        if (onlyRefreshData) {
          this.refreshResourceFormData(refreshDescrText);
        } else {
          this.loadResourceData();
        }
      }
    },
    async refreshResourceFormData(refreshDescr, resetLocalData) {
      const currResourceKey = this.resourceData.key;

      if (resetLocalData) {
        const updatedResourceData =
          getUserResources().getResources()[currResourceKey];

        this.updateEditResourceData(updatedResourceData);
      }

      await this.$nextTick();
      const allResourcesMap = getUserResources().getResources();

      const currRawResourceData = allResourcesMap[currResourceKey];
      if (refreshDescr) {
        this.setDescrText(currRawResourceData.descr || "");
      } else {
        this.newResourceData = {
          ...this.newResourceData,
          ...cloneDeep(currRawResourceData),
        };

        const resourceDescr = this.addNewLineInText(this.newResourceData.descr);
        this.newResourceData.descr = resourceDescr;
        const currDescrConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.resourceData.descr
        );
        const currTitleConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.resourceData.title
        );
        const convertedDelta = this.$refs.editor.convertHTMLToDelta(
          this.newResourceData.descr
        );
        const convertedRawTitleDelta = this.$refs.editor.convertHTMLToDelta(
          this.newResourceData.title
        );

        this.setDescrText(this.newResourceData.descr || "");
        this.$refs.titleInput.setDeltaContents(convertedRawTitleDelta, false);
        this.currDescrData = currDescrConvertedData;
        this.currTitleDelta = currTitleConvertedData;
        this.descrDelta = convertedDelta;
        this.titleDelta = convertedRawTitleDelta.ops;
        this.$refs.historyTable?.refreshList(true);
      }

      setTimeout(() => {
        this.$refs.editor.setDeltaContents(this.$refs.editor.delta);
      }, 0)
    },
    processResourceDataForSave({ currResourceData, updatedResourceData }) {
      const { updates, editedData } = createUpdateAndEditedResourceData(
        { ...updatedResourceData },
        currResourceData
      );

      return {
        updates,
        editedData,
      };
    },

    async convertRecurringTask({
      currTaskData,
      updatedTaskData,
      resourceData,
      type = "normal",
    }) {
      const {
        newTaskData,
        taskDataForLocalSave,
        localTaskListConfig,

        // removeAllTasks,
        googleCalUpdateConfig,
        userActionData,
        dbListUpdateConfig,
      } = this.processRecurringTaskDataForConvert({
        currTaskData,
        updatedTaskData,
        type,
      });

      const resourceEditDataConfig = {
        data: [{ ...resourceData.editedData, doNotUpdateForm: true }],
        type: RESOURCE_ACTIONS.BATCH_EDIT,
        on: ACTION_ON_TYPES.RESOURCE,
      };

      const userBatchActions = [
        { ...resourceEditDataConfig },
        { ...userActionData },
      ];

      getUserActions().addBatchAction({
        data: userBatchActions,
        on: "all",
      });

      await addOrRemoveOrUpdateTasksInDb(
        {
          ...dbListUpdateConfig,
        },
        undefined,
        this.userInfo.uid
      );

      addOrUpdateOrRemoveTasksInGoogleCal(
        {
          ...googleCalUpdateConfig,
        },
        false
      );

      addOrRemoveOrUpdateTasksInLocalTasksList({
        ...localTaskListConfig,
      });

      if (newTaskData.project) {
        this.activeProject = cloneDeep(this.projects[newTaskData.project]);
      }

      this.addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToUpdate: [
            {
              updates: resourceData.updates,
              editedData: {},
              key: resourceData.key,
            },
          ],
        },
        false
      );

      addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToUpdate: [
          {
            key: resourceData.key,
            updates: resourceData.updates,
          },
        ],
      });

      await Vue.nextTick();
      this.setResourceFormData({
        ...this.newResourceData,
        ...resourceData.updates,
        taskData: taskDataForLocalSave,
      });

      this.callActionAfterSubmitSuccess();
    },

    async saveTaskDataAndResourceData() {
      const currTaskData = this.storedTaskData || {};
      const updatedTaskData = this.newTaskData || {};
      const updatedProjectData = this.activeProject || {};

      const currResourceData = this.resourceData;
      let updatedResourceData = this.newResourceData;
      const updatedTag =
        (updatedResourceData.tag && updatedResourceData.tag.trim()) || "";
      updatedResourceData = {
        ...this.newResourceData,
        descr: this.getDescrVal(undefined, "descrRTE") || "",
        tag: updatedTag,
      };

      const currTag = currResourceData.tag || "";

      if (updatedTag !== currTag) {
        const isTagValidRes = await this.checkIfTagIsValid(
          updatedTag,
          updatedResourceData.type
        );
        if (!isTagValidRes.isValid) {
          this.showAlertForTagNotValid(isTagValidRes);
          return;
        }
      }
      const { updates: resourceUpdates, editedData: resourceEditedData } =
        this.processResourceDataForSave({
          currResourceData: currResourceData,
          updatedResourceData,
        });
      const isCurrTaskRecurring = checkIfTaskIsRecurring(currTaskData);
      let updatedReminderDays = updatedTaskData.reminderDays || null;
      if (updatedReminderDays === "0") {
        updatedTaskData.reminderDays = 0;
        updatedReminderDays = null;
      }
      let runRecurringChecks = true;
      let runModeChecks = false;

      if (!isUndefinedVal(updatedReminderDays)) {
        let stopExcution = false;
        if (!this.isValidRemindVal(updatedReminderDays)) {
          this.showReminderErrorMsg("reminder");
          stopExcution = true;
        } else if (!isValidDate(updatedTaskData.dueAsDate)) {
          this.showReminderErrorMsg("date");
          stopExcution = true;
        }
        if (stopExcution) {
          return;
        }
      }

      if (!runModeChecks && this.checkIfTaskIsInRepeatInMode(updatedTaskData)) {
        runRecurringChecks = false;
        if (
          isEmpty(updatedTaskData.repeatInfo) ||
          !updatedTaskData.repeatInfo.frequency ||
          !updatedTaskData.repeatInfo.count ||
          !isValidDate(updatedTaskData.dueAsDate)
        ) {
          this.showRequiredFieldsToast("repeatIn");
          return;
        }

        if (isCurrTaskRecurring) {
          this.convertRecurringTask({
            currTaskData,
            updatedTaskData,
            resourceData: {
              updates: resourceUpdates,
              key: currResourceData.key,
              editedData: resourceEditedData,
            },
            type: "repeat-in",
          });
          return;
        }
      }

      if (runRecurringChecks && !runModeChecks) {
        if (
          updatedTaskData.isRecurring &&
          !this.checkIfFieldsExistsForRecurring(updatedTaskData)
        ) {
          this.showRequiredFieldsToast();
          return;
        }
        if (isCurrTaskRecurring && updatedTaskData.isRecurring) {
          if (!this.checkIfFieldsExistsForRecurring(updatedTaskData)) {
            this.showRequiredFieldsToast();
            return;
          }

          this.openEditTaskConfirmBox({
            taskData: {
              ...updatedTaskData,
            },
            projectData: updatedProjectData,
            objectiveData: {},
            resourceData: {
              updates: resourceUpdates,
              editedData: resourceEditedData,
              key: currResourceData.key,
            },
          });
          return;
        }

        if (
          isCurrTaskRecurring &&
          !this.checkIfTaskIsInRepeatInMode(updatedTaskData) &&
          !updatedTaskData.isRecurring
        ) {
          this.convertRecurringTask({
            currTaskData,
            updatedTaskData,
            resourceData: {
              key: currResourceData.key,
              updates: resourceUpdates,
              editedData: resourceEditedData,
            },
          });
          return;
        }
      }
      this.loading = true;
      let {
        localTasksListConfig,
        dbUpdateConfig,
        dbUpdates,
        createdTaskData,
        onlyProjectPriorityChanged,
        recurringDatesList,
        userBatchActions,
        taskDataForLocalSave,
        userActionType,
      } = await this.processTaskDataForSave({
        currTaskData: this.storedTaskData,
        updatedTaskData: this.newTaskData,
        updatedProjectData: this.activeProject,
      });

      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);

      if (!isEmpty(dbUpdateConfig)) {
        await addOrRemoveOrUpdateTasksInDb({
          ...dbUpdateConfig,
        });
      }

      await addOrRemoveOrUpdateTasksInLocalTasksList({
        ...localTasksListConfig,
      });

      // if (onlyProjectPriorityChanged) {
      //   EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
      // }

      this.storeRecurringTasksInInfoMap(
        createdTaskData.key,
        recurringDatesList
      );

      const resourceEditDataConfig = {
        data: [{ ...resourceEditedData, key: currResourceData.key }],
        type: RESOURCE_ACTIONS.BATCH_EDIT,
        on: ACTION_ON_TYPES.RESOURCE,
      };

      if (userActionType === "all") {
        userBatchActions.unshift({ ...resourceEditDataConfig });
      } else {
        userBatchActions = [
          { ...resourceEditDataConfig },
          { ...userBatchActions },
        ];
      }

      getUserActions().addBatchAction({
        data: userBatchActions,
        on: "all",
      });

      this.activeProject = cloneDeep(this.projects[createdTaskData.project]);

      this.addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToUpdate: [
            {
              updates: resourceUpdates,
              editedData: resourceEditedData,
              key: currResourceData.key,
            },
          ],
        },
        false
      );

      addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToUpdate: [
          {
            key: currResourceData.key,
            updates: resourceUpdates,
          },
        ],
      });

      await Vue.nextTick();
      this.setResourceFormData({
        ...this.newResourceData,
        ...resourceUpdates,
        taskData: taskDataForLocalSave,
      });

      this.callActionAfterSubmitSuccess();
    },
    async submitData() {
      const isTaskDataChanged = this.checkIfTaskDataHasChanged();

      if (isTaskDataChanged) {
        this.saveTaskDataAndResourceData();
        return;
      }

      await this.processResourceData();
    },

    focusOnTitleInput(selectText) {
      setTimeout(() => {
        this.$refs.resourceTagInput?.focusOnEl(selectText);
      }, 0);
    },

    getDescrVal() {
      return this.descrData || "";
    },

    checkResourceDataForAnyEdits() {
      if (!this.dataIsLoaded) {
        return false;
      }
      const currTitleDelta = this.currTitleDelta;
      const updatedTitleDelta = this.titleDelta;

      const updatedDescrDelta = this.descrDelta;
      const currStitchedText = createStrWithMentionsFromQlDelta(currTitleDelta);
      let currSitchedDescrText = createStrWithMentionsFromQlDelta(
        this.currDescrData
      );
      const updatedStitchedText = createStrWithMentionsFromQlDelta({
        ops: updatedTitleDelta,
      });
      let updatedSitchedDescrText =
        createStrWithMentionsFromQlDelta(updatedDescrDelta);

      let isDataChanged = checkIfItemDataIsEdited(
        {
          ...this.newResourceData,
          descr: updatedSitchedDescrText,
          title: updatedStitchedText,
        },
        {
          ...this.resourceData,
          descr: currSitchedDescrText,
          title: currStitchedText,
        },
        RESOURCE_PROPS_FOR_FORM
      );

      return isDataChanged;
    },

    checkIfTaskDataHasChanged() {
      return this.checkIfTaskOrProjectDataHasChanged({
        currTaskData: this.storedTaskData,
        updatedTaskData: this.newTaskData,
        currProjectData: this.projects[this.activeProject.key],
        updatedProjectData: this.activeProject,
        taskPropCheckList: [
          "dueAsDate",
          "isContingent",
          "project",
          "flag",
          "reminderDays",
        ],
      });
    },
    checkIfAnyEditIsMade() {
      let isChanged = false;

      if (this.isOpen) {
        if (
          this.dataIsLoaded &&
          !isEmpty(this.storedTaskData) &&
          this.storedTaskData.key
        ) {
          isChanged = this.checkIfTaskDataHasChanged();
        }

        if (!isChanged) {
          isChanged = this.checkResourceDataForAnyEdits();
        }
      }
      return isChanged || !isEqual(this.descriptionEditorDelta.old, this.descriptionEditorDelta.new);;
    },
    async checkForEditAndCloseForm() {
      const taskDataChanged = this.checkIfAnyEditIsMade();
      if (taskDataChanged) {
        await this.showConfirmBoxForChanges(undefined, undefined, true);
        return;
      } else {
        this.closeFormAfterAction = true;
      }

      this.closeDialog();
    },

    async getResourceData(type) {
      let resourceFound = true;
      let currIndex = this.currItemIndex;
      if (currIndex !== undefined || currIndex !== null) {
        if (type === "previous") {
          currIndex--;
        }
        if (type === "next") {
          currIndex++;
        }
        let resourceData = this.filteredResources[currIndex];
        if (isEmpty(resourceData)) {
          resourceFound = false;
        } else if (isGroupRow(resourceData)) {
          currIndex =
            type === "next" ? increment(currIndex, 1) : decrement(currIndex, 1);
          if (
            !this.filteredResources[currIndex] ||
            !this.filteredResources[currIndex].key
          ) {
            resourceFound = false;
          }
        }

        if (!resourceFound) {
          this.loading = false;
          this.showToast("No resource found!");
          return;
        }

        resourceData = this.filteredResources[currIndex];
        await setResourceIdInTree(
          resourceData,
          this.$route.name === "Resources"
        );
        expandAllParentsOfResource(resourceData);
        this.setResourceFormData(resourceData, false);
      }
    },
    changeItemForEdit(type) {
      this.loading = true;

      this.getResourceData(type);
    },
    handleResourceEditType(type, event) {
      const isDataChanged = this.checkIfAnyEditIsMade();

      if (isDataChanged) {
        const parentRes = findParentFromPathsByClassName(
          getPathsFromEvent(event),
          ["task-action-btn"],
          "obj"
        );

        this.showConfirmBoxForChanges(parentRes.parentEl, false);
        return;
      }
      this.changeItemForEdit(type);
    },

    async selectTaskInGrid(taskData, triggerRefresh) {
      if (!isEmpty(taskData)) {
        const { key, date } = taskData;
        let taskIdToUse = key;
        if (checkIfTaskIsParentRecurringTask(this.rawTasksMap[key])) {
          taskIdToUse = this.getFirstVirtualReccuringTask(key, date)?.key;
        }
        if (taskIdToUse) {
          this.$refs.historyTable.selectTask(taskIdToUse);

          if (triggerRefresh) {
            await Vue.nextTick();
            this.$refs.historyTable.refreshList();
          }

          EventEmitter.emit(TOGGLE_RESOURCE_FORM_EVENT, true);

          // this.$refs.historyTable.refreshList();
        }
      }
    },
    async loadResourceData() {
      this.loading = true;
      this.dataIsLoaded = false;
      this.$refs.historyTable?.clearSelection();
      try {
        const linkedItemData = cloneDeep(this.selectedTaskData);
        const selectedTaskData = this.storedTaskData;
        await this.$nextTick();
        const allResourcesMap = getUserResources().getResources();
        const currResourceKey = this.resourceData.key;
        // const openDescr = this.resourceData.openDescr;
        const currRawResourceData = allResourcesMap[currResourceKey];

        let resourceDescr = this.addNewLineInText(currRawResourceData.descr);

        this.newResourceData = cloneDeep(currRawResourceData);

        this.newResourceData.descr = resourceDescr;
        // this.currItemIndex = this.filteredResources.findIndex(
        //   (n) => n.key === currResourceKey
        // );

        await this.$nextTick();

        const currDescrConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.resourceData.descr
        );
        const currTitleConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.resourceData.title
        );
        const convertedDelta =
          this.$refs.editor.convertHTMLToDelta(resourceDescr);

        const convertedRawTitleDelta = this.$refs.editor.convertHTMLToDelta(
          currRawResourceData.title
        );
        this.currDescrData = currDescrConvertedData;
        this.currTitleDelta = currTitleConvertedData;
        this.descrDelta = convertedDelta;
        this.titleDelta = convertedRawTitleDelta.ops;
        this.$refs.titleInput.setDeltaContents(convertedRawTitleDelta, false);
        this.$refs.editor.setDeltaContents(convertedDelta, false);

        if (this.checkIfEditorShouldBeCollapsed()) {
          if (
            // openDescr ||
            !checkIfResourceDescrIsEmpty(currRawResourceData.descr)
          ) {
            this.isDescrOpen = true;
          } else {
            this.isDescrOpen = false;
          }
        }

        await this.$nextTick();
        if (!isEmpty(linkedItemData)) {
          const { key } = linkedItemData;

          if (key) {
            if (this.rawTasksMap[key]) {
              this.selectTaskInGrid(linkedItemData);
            } else {
              this.setTaskDataForSelection = {
                ...linkedItemData,
              };
            }
          }
          // if()
        }

        if (!isEmpty(selectedTaskData)) {
          this.setSelectedTaskDataInForm(
            getMainTaskRulesAndFillRecurringOpts(selectedTaskData)
          );
        }
        // this.loadList = true;
        await this.$nextTick();

        setTimeout(() => {
          this.loading = false;
          this.dataIsLoaded = true;
          // this.focusOnTitleInput();
          this.loaderToggler(false);
        }, 0);
      } catch (error) {
        this.loading = false;
        this.loaderToggler(false);
        console.error("Error in loadResourceData", error);
      } finally {
        // this.setLoader(false);
      }
      setTimeout(() => {
        this.$refs.editor.setDeltaContents(this.$refs.editor.delta);
      }, 0)
    },
    resetResourceData() {
      this.newResourceData = {};
      this.isDescrOpen = false;
    },
    setFormListeners() {
      document.addEventListener("mousedown", this.handleGlobalClick, true);
      document.addEventListener("keydown", this.handleGlobalKeydown);
      EventEmitter.on(REFRESH_RESOURCE_FORM_DATA, this.reloadItemData);
      EventEmitter.on(
        REFRESH_TASK_DATA_IN_RESOURCE_FORM,
        this.refreshTaskDataInForm
      );
      EventEmitter.on(OPEN_SELECTED_ITEM, this.handleOpenSelectedItem);
    },
    removeFormListeners() {
      document.removeEventListener("mousedown", this.handleGlobalClick, true);
      document.removeEventListener("keydown", this.handleGlobalKeydown);
      EventEmitter.off(REFRESH_RESOURCE_FORM_DATA, this.reloadItemData);
      EventEmitter.off(OPEN_SELECTED_ITEM, this.handleOpenSelectedItem);
      EventEmitter.off(
        REFRESH_TASK_DATA_IN_RESOURCE_FORM,
        this.refreshTaskDataInForm
      );
    },

    async refreshTaskDataOnly() {
      await this.$nextTick();
      await this.refreshResourceFormData(undefined, true);

      const isValidTaskLoaded =
        !isEmpty(this.storedTaskData) && !isEmpty(this.storedTaskData.key);
      const isRecurringTask = checkIfTaskIsRecurring(this.storedTaskData);
      let currRawData = {},
        taskRecurrence = [],
        parsedRuleOptions = {},
        repeatMonthDate = "",
        extraData = {};
      // refreshProjectTasks = false;
      // if (refreshHistoryTable) {
      //   this.refreshActivityHistoryTable();
      // } else {
      if (isRecurringTask) {
        const isVirtualTask = this.storedTaskData.isVirtual;
        const linkedTo = this.storedTaskData.linkedTo;
        const taskFrequency = this.storedTaskData.frequency;
        const taskRecurrenceRulesValid = areTaskRecurrenceRulesValid(
          this.storedTaskData.recurrence
        );
        let taskDataForRulesParsing = {};
        if (isVirtualTask) {
          currRawData = this.rawTasksMap[this.storedTaskData.linkedTo];
          taskDataForRulesParsing = currRawData;
        } else if (!isEmpty(linkedTo) || taskRecurrenceRulesValid) {
          currRawData = this.rawTasksMap[this.storedTaskData.key];
          taskRecurrence = currRawData.recurrence;
          if (linkedTo && this.rawTasksMap[linkedTo]) {
            taskDataForRulesParsing = this.rawTasksMap[linkedTo];
          } else if (taskRecurrenceRulesValid) {
            taskDataForRulesParsing = { ...currRawData };
          }
        }
        if (!isEmpty(taskDataForRulesParsing)) {
          parsedRuleOptions = createRecurrenceOptsValuesFromTaskRecurrenceRules(
            {
              ...taskDataForRulesParsing,
              dueAsDate: createDateTimeFromDue(
                taskDataForRulesParsing.due,
                taskDataForRulesParsing.time
              ),
            }
          );
        }

        if (
          taskFrequency &&
          taskFrequency === "monthly" &&
          currRawData.due !== "none"
        ) {
          repeatMonthDate = `${formatDate(
            new Date(currRawData.due),
            DAY_FORMAT
          )}_monthly`;
        }
      } else {
        currRawData = this.rawTasksMap[this.storedTaskData.key];
      }

      if (isRecurringTask || !isEmpty(currRawData?.repeatInfo)) {
        extraData = {
          repeatMode: isRecurringTask ? "every" : "in",
        };
      }

      // if (!isEmpty(currRawData?.project)) {
      //   // this.currTaskId = currRawData.key;
      //   if (currRawData.project === this.newTaskData.project) {
      //     refreshProjectTasks = true;
      //   }
      // } else {
      //   // this.currTaskId = "";
      //   this.showList = false;
      // }

      if (!isEmpty(currRawData?.pausedReminderDays)) {
        extraData = {
          ...extraData,
          pausedReminderDays: currRawData.pausedReminderDays,
        };
      }

      const finalTaskData = {
        ...this.newTaskData,
        frequency: "",
        recurrenceCount: 1,
        repeatMode: "never",
        objective: "",
        reminderDays: 0,
        pausedReminderDays: [],
        repeatMonthDate,
        ...cloneDeep(currRawData || {}),
        isRecurring: !checkIfTaskIsContinued(currRawData)
          ? isRecurringTask
          : false,
        isContingent: currRawData?.isContingent || false,
        recurrence: !isEmpty(taskRecurrence) ? taskRecurrence : [],
        ...parsedRuleOptions,
        description: currRawData?.description || "",
        repeatInfo: !isEmpty(currRawData?.repeatInfo)
          ? currRawData.repeatInfo
          : {},
        ...extraData,

        dueAsDate: createDateTimeFromDue(currRawData.due, currRawData.time),
      };

      const projectIdToUse =
        finalTaskData.project || this.storedTaskData.project;
      this.newTaskData = cloneDeep(finalTaskData);
      this.activeProject = cloneDeep(this.projects[projectIdToUse]) || {};

      this.$refs.historyTable?.refreshList?.();

      if (isValidTaskLoaded) {
        this.selectedTaskRowKeys = [this.storedTaskData.key];
      }
      // if (this.showList) {
      //   this.$refs.historyTable.refreshList();
      //   this.selectedTaskRowKeys = [this.taskData.key];
      // }
    },
    refreshTaskDataInForm(onlyRefreshData) {
      if (this.isOpen && !isEmpty(this.resourceData)) {
        if (onlyRefreshData) {
          this.refreshTaskDataOnly();
        } else {
          this.loadTaskData();
        }
      }
    },
    async saveResourceDescr(descrData) {
      const allResourcesMap = getUserResources().getResources();

      const currResourceKey = this.resourceData.key;
      const currRawResourceData = allResourcesMap[currResourceKey];

      if (
        isEmpty(currRawResourceData) ||
        currRawResourceData.descr === descrData
      )
        return;

      let updatedResourceData = {
        descr: descrData || "",
      };
      const { updates, editedData } = createUpdateAndEditedResourceData(
        { ...updatedResourceData },
        this.resourceData
      );

      this.addOrRemoveOrUpdateResourcesInList({
        resourcesToUpdate: [
          {
            updates,
            editedData: { ...editedData, refreshDescr: true },
            key: this.resourceData.key,
          },
        ],
      });

      await addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToUpdate: [
          {
            key: this.resourceData.key,
            updates,
          },
        ],
      });

      this.areChangesSaved = true;
      this.updateEditResourceData({
        ...this.resourceData,
        ...updatedResourceData,
      });
    },
    async handleFormOpen() {
      this.loadResourceData();
    },

    updateProjectPriority(val) {
      if (!isEmpty(this.activeProject)) {
        this.activeProject.priority = val;
        this.newTaskData.priority = val;
      }
    },
    async changeProject(val) {
      this.newTaskData.project = val;
      // this.activeProject = cloneDeep(this.projects[val]);

      // this.setDataForArea(this.activeDescr !== "task");
      // await this.$nextTick();
      // await this.$nextTick();
      // this.addTaskInTaskList(val);
    },
    async setSelectedTaskDataInForm(taskDataToSet) {
      makeVirtualTaskVisibleInFormGrid(taskDataToSet);
      this.setStoredTaskData(taskDataToSet);
      await this.$nextTick();
      this.loadTaskData();
    },

    async loadTaskData() {
      this.selectedTaskRowKeys = [];
      this.currPausedReminderDays = [];
      this.dataIsLoaded = false;
      await this.$nextTick();
      await this.getAndSetTaskDataInForm();
    },

    async getAndSetTaskDataInForm() {
      try {
        const isRecurringTask = checkIfTaskIsRecurring(this.storedTaskData);

        let currRawData = {};
        let taskRecurrence = [];
        let parsedRuleOptions = {};
        let repeatMonthDate = "";
        let extraData = {};
        if (isRecurringTask) {
          const isVirtualTask = this.storedTaskData.isVirtual;
          const linkedTo = this.storedTaskData.linkedTo;
          const taskFrequency = this.storedTaskData.frequency;
          const taskRecurrenceRulesValid = areTaskRecurrenceRulesValid(
            this.storedTaskData.recurrence
          );
          let taskDataForRulesParsing = {};
          if (isVirtualTask) {
            currRawData = this.rawTasksMap[this.storedTaskData.linkedTo];
            taskDataForRulesParsing = currRawData;
          } else if (!isEmpty(linkedTo) || taskRecurrenceRulesValid) {
            currRawData = this.rawTasksMap[this.storedTaskData.key];

            taskRecurrence = currRawData.recurrence;
            if (linkedTo && this.rawTasksMap[linkedTo]) {
              taskDataForRulesParsing = this.rawTasksMap[linkedTo];
            } else if (taskRecurrenceRulesValid) {
              taskDataForRulesParsing = { ...currRawData };
            }
          }
          if (!isEmpty(taskDataForRulesParsing)) {
            parsedRuleOptions =
              createRecurrenceOptsValuesFromTaskRecurrenceRules({
                ...taskDataForRulesParsing,
                dueAsDate: createDateTimeFromDue(
                  taskDataForRulesParsing.due,
                  taskDataForRulesParsing.time
                ),
              });
          }

          if (
            taskFrequency &&
            taskFrequency === "monthly" &&
            currRawData.due !== "none"
          ) {
            repeatMonthDate = `${formatDate(
              new Date(currRawData.due),
              DAY_FORMAT
            )}_monthly`;
          }
        } else {
          currRawData = this.rawTasksMap[this.storedTaskData.key];
        }

        // if (!currRawData || isEmpty(currRawData)) {
        //   currRawData = {
        //     project: this.selectedProjectId,
        //   };
        // }

        if (isRecurringTask || !isEmpty(currRawData.repeatInfo)) {
          extraData = {
            repeatMode: isRecurringTask ? "every" : "in",
          };
        }

        if (!isEmpty(currRawData.pausedReminderDays)) {
          extraData = {
            ...extraData,
            pausedReminderDays: currRawData.pausedReminderDays,
          };
        }

        this.newTaskData = {
          frequency: "",
          recurrenceCount: 1,
          repeatMode: "never",
          objective: "",
          reminderDays: 0,
          pausedReminderDays: [],
          repeatMonthDate,
          ...cloneDeep(currRawData),
          isRecurring: !checkIfTaskIsContinued(currRawData)
            ? isRecurringTask
            : false,
          isContingent:
            (currRawData.isContingent &&
              currRawData.due === "none" &&
              !currRawData.completed) ||
            false,
          recurrence: !isEmpty(taskRecurrence) ? taskRecurrence : [],
          ...parsedRuleOptions,
          description: currRawData?.description || "",
          repeatInfo: !isEmpty(currRawData.repeatInfo)
            ? currRawData.repeatInfo
            : {},
          ...extraData,
          dueAsDate: this.storedTaskData.dueAsDate,
        };

        this.activeProject =
          cloneDeep(this.projects[currRawData.project]) || {};
        this.freqError = "";
        this.startDateError = "";
        await this.$nextTick();

        // const currTitleConvertedData = this.convertHTMLToDelta(
        //   this.storedTaskData.title
        // );

        // const currTaskDescrDelta = this.convertHTMLToDelta(
        //   this.storedTaskData.description
        // );
        // const convertedRawTitleDelta = this.convertHTMLToDelta(taskTitle);
        // const convertedDelta = this.convertHTMLToDelta(currRawData.description);

        // this.currTitleDelta = currTitleConvertedData;
        // this.currTaskDescrDelta = currTaskDescrDelta;
        // this.descrDelta = convertedDelta.ops;
        // this.titleDelta = convertedRawTitleDelta.ops;
        this.$refs.historyTable.selectTask(this.newTaskData.key);
        await this.$nextTick();
        this.loading = false;
        this.dataIsLoaded = true;
        this.loaderToggler(false);
      } catch (error) {
        console.error("Error in loadProject", error);
        this.loaderToggler(false);
      } finally {
        this.loading = false;
      }
    },
    checkAndLoadTaskDataFromSelection(rows) {
      // let currSelectedTasks = cloneDeep(this.selectedTaskRowKeys);

      if (!isEmpty(rows) && rows.length === 1) {
        const selectedTaskId = rows[0];

        if (selectedTaskId !== this.newTaskData.key) {
          const selectedTask = this.processedTasks.find(
            (t) => t.key === selectedTaskId
          );
          if (!isEmpty(selectedTask)) {
            const isDataChanged = this.checkIfAnyEditIsMade();
            if (isDataChanged) {
              this.loadTaskFromList = true;
              this.taskDataToLoad = selectedTask;
              this.showConfirmBoxForChanges(undefined, false);
              return;
            }

            this.setSelectedTaskDataInForm(
              getMainTaskRulesAndFillRecurringOpts(selectedTask)
            );
          }
        }
      }
    },
    handleRowsSelection(rows) {
      this.updateSelectedTasks(rows);
    },
  },
  computed: {
    ...mapGetters("task", ["rawTasksMap", "isProjectsResourceModeEnabled"]),
    reminderDaysBtnIconData() {
      const reminderDays = this.newTaskData.reminderDays || null;
      let reminderIcon = "$ctiBellOutlined";
      let iconColor = DARK_GREY_ICON_COLOR;
      let text = "Enable reminder for task";
      if (
        !isUndefinedVal(reminderDays) &&
        this.isValidRemindVal(reminderDays)
      ) {
        reminderIcon = "$ctiBellFilled";
        iconColor = PRIMARY_COLOR;
        text = "Remove reminder for task";
      }

      return {
        icon: reminderIcon,
        color: iconColor,
        label: text,
      };
    },
    isTaskActive() {
      return !isEmpty(this.storedTaskData) && !isEmpty(this.newTaskData?.key);
    },
    selectedDateIsValid() {
      const dateToUse = this.newTaskData.dueAsDate;
      return isValidDate(dateToUse);
    },
    taskRepeatInfo() {
      let txt = "";

      if (this.isTaskInRepeatEveryMode) {
        if (
          this.newTaskData.frequency === "weekly" &&
          this.selectedDateIsValid
        ) {
          const repeatDayVal = this.newTaskData.recurrenceRepeatDay;

          const repeatDay = daysOfWeek[repeatDayVal];
          txt = `Repeats weekly on ${repeatDay}`;
        } else if (
          this.newTaskData.frequency === "monthly" &&
          this.selectedDateIsValid
        ) {
          const repeatMonthVal = this.newTaskData.repeatMonthDate;
          const [date] = repeatMonthVal?.split("_");

          txt = `Repeats monthly on day ${date}`;
        }
      }

      if (this.isTaskInRepeatInMode) {
        const { frequency, count } = this.newTaskData.repeatInfo;
        const isCountMoreThanOne = count > 1;
        let period = "";
        if (frequency === "weekly") {
          period = "week";
        } else if (frequency === "monthly") {
          period = "month";
        } else if (frequency === "yearly") {
          period = "year";
        } else {
          period = "day";
        }

        txt = `Repeats in ${count} ${period}${isCountMoreThanOne ? "" : "s"}`;
      }

      return {
        showTxt: false,
        txt,
      };
    },
    isTaskInRepeatEveryMode() {
      return (
        !isEmpty(this.newTaskData) &&
        (this.newTaskData.isRecurring ||
          this.newTaskData.repeatMode === "every")
      );
    },
    isTaskInRepeatInMode() {
      return !isEmpty(this.newTaskData) && this.newTaskData.repeatMode === "in";
    },
    freqOpts() {
      let opts = frequencyOpts;
      let recCount;
      if (this.newTaskData.isRecurring) {
        recCount = this.newTaskData.recurrenceCount;
      } else if (this.isTaskInRepeatInMode) {
        recCount = this.newTaskData.repeatInfo.count;
      }

      if (recCount && recCount > 1) {
        opts = opts.map((opt) => ({ ...opt, label: `${opt.label}s` }));
      }

      return opts;
    },
    isRecurringAndModeOn() {
      return (
        this.storedTaskData.recurrenceRepeatDay !== undefined &&
        this.newTaskData.isRecurring
      );
    },
    repeatTypeOpts() {
      return repeatTypeOpts;
    },
    ...mapGetters("editForm", {
      isEditFormOpen: "isOpen",
    }),
    ...mapGetters({
      storedViewData: "currViewData",
    }),

    isFormGridActive() {
      return this.isOpen && this.isActive ? "true" : "false";
    },
    filterOpts() {
      return {
        resourceId: this.newResourceData.key,
      };
    },
    clearBtnAttrs() {
      return {
        "data-action-type": "clear-list",
        "data-action-value": "clear",
        "data-prevent-close": "1",
      };
    },
    descrTogglerData() {
      // let icon = "mdi-menu-down";
      let label = "Open Description";

      if (this.isDescrOpen) {
        // icon = "mdi-menu-right";
        label = "Collapse Description";
      }
      return {
        iconClasses: {
          "e-icons e-gdiagonaldown": true,
          "e-icon-gdownarrow": this.isDescrOpen && this.descrData,
          "e-icon-grightarrow": !this.isDescrOpen && this.descrData,
          "e-chevron-down": this.isDescrOpen && !this.descrData,
          "e-chevron-right": !this.isDescrOpen && !this.descrData,
        },
        label,
      };
    },
    isResourceDataValid() {
      return (
        this.isOpen &&
        !isEmpty(this.resourceData) &&
        !isEmpty(this.newResourceData) &&
        !isEmpty(this.newResourceData.key)
      );
    },
    currItemIndex() {
      let storedIndex = -1;

      if (this.isResourceDataValid) {
        storedIndex = this.filteredResources.findIndex(
          (n) => n.key === this.resourceData.key
        );
      }

      return storedIndex;
    },
    infoIconColor() {
      return GREY_ICON_COLOR;
    },

    editorPlaceholder() {
      return "Enter description of resource here";
    },
    ...mapGetters("resourcesData", {
      filteredResources: "filteredResources",
      totalResourcesCount: "resourcesCount",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      rawResourcesMap: "rawResourcesMap",
    }),

    isFilteredListEmpty() {
      return isEmpty(this.filteredResources);
    },
    isTaskListEmpty() {
      return isEmpty(this.taskList);
    },
    ...mapGetters("resourceInfo", {
      isOpen: "isOpen",
      resourceData: "data",
      storedReturnViewInfo: "returnViewInfo",
      storedTaskData: "storedTaskData",
      selectedTaskData: "selectedTaskData",
    }),
    createdAndModifiedValues() {
      const createdProp = "created";
      const modifiedProp = "modified";

      const dataToUse = this.resourceData;
      return {
        created: dataToUse[createdProp],
        modified: dataToUse[modifiedProp],
      };
    },
    createdAndModifiedDates() {
      const { created, modified } = this.createdAndModifiedValues;
      return {
        modifiedDate: convertDateTimeToReadableFormat(
          modified,
          DATE_WITH_TIME_FORMAT_IN_USE,
          "N/A"
        ),
        createdDate: convertDateTimeToReadableFormat(
          created,
          DATE_WITH_TIME_FORMAT_IN_USE,
          "N/A"
        ),
      };
    },

    isEditStarted() {
      let isDataChanged = false;
      const checkForEdit = this.dataIsLoaded || this.triggerEditChecker;
      const isOpen = this.isOpen;

      if (isOpen && checkForEdit) {
        if (
          !isDataChanged &&
          !isEmpty(this.resourceData) &&
          !isEmpty(this.newResourceData) &&
          this.descriptionEditorDelta
        ) {
          isDataChanged = this.checkIfAnyEditIsMade();
        }
      }

      return isDataChanged;
    },

    isResourceEditEnabled() {
      return this.isOpen && !isEmpty(this.resourceData);
    },
    totalItemCount() {
      return this.totalResourcesCount;
    },
    iconColor() {
      return DARK_GREY_ICON_COLOR;
    },

    showProjectsToggleBtn() {
      return (
        this.$route.name !== "Resources" && this.isProjectsResourceModeEnabled
      );
    },
    repeatCountOpts() {
      return repeatCountOpts;
    },
  },
  mounted() {
    this.closeFormAfterAction = true;
    this.debouncedDescrSave = debounce(this.saveResourceDescr, 500);
    this.debouncedConvertAndSetDelta = debounce(this.convertTitleAndSet, 500);
    this.debounedConvertAndSetDescrDelta = debounce(
      this.convertDescrDelta,
      500
    );
    this.setFormListeners();
    this.setWindowResizeObserver();
  },
  beforeDestroy() {
    this.removeWindowResizeObserver();
    this.removeFormListeners();
  },
  watch: {
    isOpen: {
      handler(v) {
        if (v && !isEmpty(this.resourceData)) {
          this.handleFormOpen();
        } else {
          this.resetResourceData();
        }
      },
      // immediate: true,
    },
    isResourceDataValid: {
      handler(v) {
        if (v) {
          this.adjustFormGrid();
        }
      },
    },
    rawTasksMap: {
      handler(n, o) {
        if (!isEmpty(n) && isEmpty(o)) {
          const selectTaskData = cloneDeep(this.setTaskDataForSelection);

          this.setTaskDataForSelection = undefined;
          this.selectTaskInGrid(selectTaskData, true);
        }
      },
    },
    $route: {
      handler: async function (n) {
        // put your code here
        const resourceId = n?.query?.resourceId;
        if (resourceId && this.newResourceData.key !== resourceId) {
          this.resourceData.key = resourceId;
          const resourceData = this.rawResourcesMap[resourceId];
          if (resourceData) {
            this.setResourceFormData(resourceData);
            await this.$nextTick();
            this.loadResourceData();
          }
        }
        this.$router.replace({ query: null });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 7px;
}

.e-toolbar-wrapper {
  height: 45px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1;
}

.hover-icon {
  display: none;
}

.task-action-btn >>> svg {
  fill: #000;
}
.task-action-btn:hover .hover-icon {
  display: inline-flex;
}
.task-action-btn:hover .main-icon {
  display: none;
}

.info-alert {
  margin-bottom: 0px;
}

.task-title-actions-wrapper {
  width: 100%;
  /* padding: 0px 0px 10px 0px; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.42);

  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1); */
  /* padding: 16px; */
}

.task-title-actions-wrapper:hover {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.87); */
  /* padding: 16px; */
}

.task-title-actions-wrapper .task-title-wrapper {
  align-items: center;
}

.date-helpers-group {
  margin-left: 20px;
}

.content-wrapper {
  position: relative;
}

.main-content-wrapper {
  /* padding-left: 40px;
  padding-right: 40px; */
  /* overflow: scroll; */
  padding-bottom: 20px;
}

.rowrap {
  flex-wrap: nowrap;
}

.e-richtexteditor {
  min-height: 300px;
}

.descr-editor >>> .e-rte-content {
  min-height: 300px;
}

.descr-editor >>> .e-content {
  min-height: 300px;
}

.task-title-input-wrapper,
.resource-title-input-wrapper >>> #title-edit {
  font-size: 20px;
  padding-top: 6px !important;
  padding-bottom: 4px !important;
}

.task-title-input-wrapper,
.resource-title-input-wrapper >>> textarea {
  font-size: 20px !important;
}
.task-title-input-wrapper >>> .e-rte-content {
  height: auto !important;
}
.task-title-input-wrapper,
.resource-title-input-wrapper >>> .title-editor {
  height: auto !important;
  min-height: auto !important;
}

.task-title-input-wrapper >>> .e-richtexteditor .e-rte-content .e-content {
  min-height: auto !important;
}
.task-title-input-wrapper,
.resource-title-input-wrapper >>> #title-edit:before {
  margin-bottom: 5px !important;
}

.advance-date-input-wrapper >>> input {
  padding: 0px !important;
}
.advance-date-input-wrapper >>> .e-datetime-wrapper {
  padding: 4px 0px 4px 0px !important;
  margin-bottom: 0px !important;
  line-height: 1 !important;
}
.advance-date-input-wrapper >>> .e-date-icon {
  margin-bottom: 0px !important;
}

.advance-date-input-wrapper >>> .e-time-icon {
  margin-bottom: 0px !important;
}
/* .e-richtexteditor div.e-rte-content {
  min-height: 300px !important;
} */

#main-header {
  position: sticky;
  left: 0px;
  right: 0px;
  min-height: 70px;
  top: -16px;
  z-index: 2;
  -webkit-backface-visibility: hidden;
}

#main-header .header-content {
  min-height: 54px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  -webkit-position: sticky;
  background-color: #fff;
  top: 0px;
  /* compensate for shadow with negative margin */
  margin-top: -16px;
  /* content should fall over shadow and cover */
  z-index: 3;
}

/* PSEUDO ELEMENTS to create drop-shadow */
#main-header::before {
  content: "";
  display: block;
  height: 16px;
  position: sticky;
  -webkit-position: sticky;
}
#main-header::after {
  content: "";
  display: block;
  height: 16px;
  position: sticky;
  -webkit-position: sticky;
}

#main-header::before {
  top: 38px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.5);
}

#main-header::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  background: linear-gradient(
    white 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    transparent
  );
  top: 0px;
  /* top: 44px; */
  /* cover should fall over shadow */
  z-index: 2;
}
#main-header .alert-wrapper .alert-box {
  margin-bottom: 0px !important;
}

.repeat-task-check-input {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.interval-selector-wrapper {
  width: 60px;
}

p.title {
  color: #000 !important;
  line-height: 1.3 !important;
  /* min-height: 2.625rem; */
}

/* .actions-wrapper >>> button:not(.edit-mode-btn) { */
/* color: rgba(0, 0, 0, 0.54) !important; */
/* width: 36px;
  height: 36px; */
/* color: #000 !important; */
/* border-radius: 50% !important;
  margin: 0px !important;
  min-width: 36px !important; */
/* } */

/* .actions-wrapper >>> button:not(.edit-mode-btn) { */
/* color: rgba(0, 0, 0, 0.54) !important; */
/* width: 36px;
  height: 36px; */
/* color: #000 !important; */
/* border-radius: 50% !important;
  margin: 0px !important;
  min-width: 36px !important; */
/* } */

.actions-wrapper >>> button:not(.v-btn--active) {
  /* color: rgba(0, 0, 0, 0.54) !important; */
  /* width: 36px;
  height: 36px; */
  /* color: #000 !important; */
  /* border-radius: 50% !important;
  margin: 0px !important;
  min-width: 36px !important; */
}

.actions-wrapper >>> button.v-btn--active:disabled svg {
  /* color: rgba(0, 0, 0, 0.54) !important; */
  /* width: 36px;
  height: 36px; */
  /* fill: var(--primary-color) !important; */
  /* border-radius: 50% !important;
  margin: 0px !important;
  min-width: 36px !important; */
}

.edit-task-form-wrapper {
  overflow-y: scroll;
}

.priority-selector {
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  margin-bottom: 3px !important;
}

.header-content >>> .priority-selector {
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  /* margin-bottom: 3px !important; */
}

.descr-editor >>> .e-rte-toolbar.e-toolbar.e-extended-toolbar {
  z-index: 1 !important;
}

/* .created-and-modified-info-wrapper {
  justify-content: flex-end;
   font-style: italic;
} */

.contingent-toggle-btn {
  height: 24px !important;
  width: 24px !important;
  margin-top: 2px !important;
}

.header-content >>> .project-selector-wrapper {
  width: 270px !important;
}

.subject-selector-wrapper >>> .v-select__selection--comma {
  font-size: 13px !important;
}

.subject-selector-wrapper
  >>> .v-select.v-text-field:not(.v-text-field--single-line)
  input {
  font-size: 13px !important;
}

.project-selector-wrapper >>> input {
  max-height: 34px !important;
}

.subject-selector-wrapper >>> input {
  max-height: 34px !important;
}

.project-selector-wrapper
  >>> .v-select.v-text-field:not(.v-text-field--single-line)
  input {
  font-size: 13px !important;
}

.project-selector-wrapper >>> .v-input__control {
  /* margin-top: 3px; */
}

.project-selector-wrapper >>> .v-select__selection--comma {
  font-size: 13px !important;
}

.btn-row {
  gap: 8px;
}
</style>

<style>
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value);
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "16";
  font-size: 16px;
}

.resource-title-input-wrapper #title-editor {
  border: none;
  /* border-bottom: 1px solid #000; */
}
.resource-title-input-wrapper #title-editor .ql-editor {
  min-height: 34px !important;
  padding: 7px 0px 6px 0px !important;
  /* padding: 6px 0px 4px 0px !important; */
  /* font-size: 20px; */
}
/* .descr-editor-wrapper .ql-editor.ql-blank::before {
  top: 10px;
  font-style: normal !important;
  color: #a6a6a6 !important;
} */

.resource-title-input-wrapper #title-editor .ql-editor.ql-blank::before {
  top: 8px;
  left: 0px;
  font-style: normal !important;
  color: #a6a6a6 !important;
}

.descr-editor .ql-container {
  font-size: 16px;
}

.resource-tasks-grid div.task-order-btn-wrapper {
  height: auto;
  margin-top: 7px;
}

.resource-tasks-grid div.task-order-number-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 0px;
  /* width: 100%; */
}
.resource-tasks-grid
  div.task-order-number-wrapper
  div.task-order-number-text-wrapper {
  margin-top: 0px;
  background-color: #7c7c7c;
  /* height: 22px;
  width: 22px; */
  /* font-size: 18px; */
}

.resource-tasks-grid
  .history-table
  .datagrid-row[role="row"]:hover:not(.row-editing)
  .datagrid-cell.row-handler-cell {
  border-left: 1px solid var(--primary-color) !important;
}

.resource-tasks-grid
  .history-table
  .datagrid-row[role="row"]:hover:not(.row-editing)
  .datagrid-cell.taskType-cell {
  border-left: unset !important;
}

.resource-tasks-grid
  .datagrid-row[role="row"].row-selected:not(.row-editing)
  .datagrid-cell.taskType-cell {
  border-left: unset !important;
}

.resource-tasks-grid
  div#activity-tasks-list
  .datagrid-header-cell.taskType-cell.sortable-header {
  border-left: 1px solid #e0e0e0 !important;
}

.resource-tasks-grid
  div#activity-tasks-list
  .datagrid-row[role="row"].row-active:not(.row-editing):not(.row-selected)
  .taskType-cell {
  border-left: unset !important;
}

.resource-title-input-wrapper #title-editor .ql-editor {
  min-height: 28px !important;
  min-width: 80px;
}

.resource-title-input-wrapper #title-editor .ql-editor p {
  font-size: 13px;
  color: #000000de;
}

.resource-title-input-wrapper #title-editor .ql-editor.ql-blank::before {
  font-size: 20px;
  height: 28px;
  top: 7px;
  width: 92px;
}
/* .descr-editor-wrapper .ql-editor {

  min-height: 250px !important;
  resize: vertical;
  overflow-y: auto;
  color: rgba(51, 51, 51);
}

.descr-editor-wrapper .ql-editor p {
  margin: 0px 0px 0px 0px;
} */

#descr-toggler-btn {
  height: 28px;
  width: 28px;
  margin-left: -10px;
}

.resource-title-input-wrapper .ql-editor p {
  font-size: 13px !important;
}

.resource-type-title-row .descr-toggler-btn {
  margin-top: 2px;
}

.item-and-title-resource-wrapper .item-type-menu-wrapper {
  margin-top: 2px;
}

.item-and-title-resource-wrapper {
  margin-left: -6px !important;
}

.resource-tag-editor-label {
  /* margin-right: 8px; */
  margin: 0px 8px 0px 0px !important;
  font-size: 13px;
  /* color: #878787; */
  /* font-weight: 500; */
}

.resource-tag-editor-row {
  align-items: center;
}

.resource-edit-form .actions-wrapper {
  margin-top: -3px;
}
</style>
