<template>
  <ItemTypeSelector
    :value="data.taskType"
    @input="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    @keydown="triggerKeydownMethod"
    type="task"
    ref="select"
    :hideNoteOpt="hideNoteOpt"
    :selectedOptIconColor="greyIconColor"
    :optItemIconColor="greyIconColor"
    :extraMenuProps="selectMenuProps"
  />
</template>

<script>
import {
  selectorColumnPropsMixin,
  columnPropsMixin,
} from "@/core/components/DataTable";

import ItemTypeSelector from "@/components/ItemTypeSelector";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { mapActions, mapGetters } from "vuex";

import {
  //  DARK_GREY_ICON_COLOR,
  NEW_DARK_ICON_COLOR,
} from "@/variables/colors";
import isEmpty from "lodash/isEmpty";
export default {
  name: "taskType",
  components: {
    ItemTypeSelector,
  },
  data() {
    return {
      taskType: "",
    };
  },
  mixins: [columnPropsMixin, selectorColumnPropsMixin, tasksHelpersMixin],
  mounted() {
    if (this.data.taskType) {
      this.taskType = this.data.taskType;
    }
  },
  methods: {
    ...mapActions("task", ["removeTasks"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    handleChange(val) {
      const isAddModeEnabled = this.cell.api.isAddModeEnabled();
      if (!isAddModeEnabled && val === "note") {
        let taskDataToUse = { ...this.data };

        if (taskDataToUse.isReminderTask) {
          const parentTaskData = this.tasks.find(
            (t) => t.key === taskDataToUse.reminderLinkedTo
          );

          if (!isEmpty(parentTaskData)) {
            taskDataToUse = { ...parentTaskData };
          }
        }

        this.setDataForConvertMode({
          dataList: [{ ...taskDataToUse }],
          typeToUse: "note",
        });
        return;
      }
      this.onSuccess(val, val, this.cell);
    },
    triggerKeydownMethod(e) {
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
  },
  computed: {
    hideNoteOpt() {
      return this.data.isVirtual;
    },
    greyIconColor() {
      return NEW_DARK_ICON_COLOR;
    },

    selectMenuProps() {
      return {
        disableKeys: true,
        contentClass: !this.hideNoteOpt
          ? "task-type-selection-all-menu"
          : "task-type-selection-limited-menu",
      };
    },
    ...mapGetters("task", ["tasks"]),
  },
};
</script>

<style scoped>
.task-type-input {
  height: 33px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
}
.v-select >>> .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

.v-select >>> .v-select__slot {
  height: 33px;
}

.v-select >>> .v-select__selections {
  height: 33px;
}
</style>
