<template>
  <ejs-treeview
    :id="id"
    :fields="fields"
    :cssClass="cssClass"
    :dragArea="dragArea"
    :nodeTemplate="nodeTemplate"
    :allowDragAndDrop="allowDragAndDrop"
    :allowEditing="allowEditing"
    :enablePersistence="enablePersistence"
    :expandOn="expandOn"
    :allowMultiSelection="allowMultiSelection"
    :selectedNodes="selectedNodes"
    :nodeExpanded="nodeOpened"
    :loadOnDemand="loadOnDemand"
    :nodeCollapsed="nodeClosed"
    :nodeDragStart="nodeDragStart"
    :nodeDragStop="dragStop"
    :nodeDragging="nodeDrag"
    :nodeClicked="nodeClicked"
    :nodeSelecting="nodeSelecting"
    :nodeEdited="nodeEdited"
    :dataSourceChanged="dataSourceChanged"
    :keyPress="handleKeyDown"
    ref="treeview"
  />
</template>
<script>
import Vue from "vue";
import {
  TreeViewComponent,
  TreeViewPlugin,
} from "@syncfusion/ej2-vue-navigations";
Vue.component(TreeViewPlugin.name, TreeViewComponent);
export default {
  props: [
    "fields",
    "cssClass",
    "dragArea",
    "nodeTemplate",
    "allowDragAndDrop",
    "allowEditing",
    "enablePersistence",
    "expandOn",
    "allowMultiSelection",
    "selectedNodes",
    "id",
    "refSetter",
    "loadOnDemand",
  ],
  mounted() {
    if (this.refSetter) {
      this.refSetter(this.$refs.treeview, "treeview");
    }
  },
  methods: {
    nodeOpened(args) {
      this.$emit("nodeExpanded", args);
    },
    nodeClosed(args) {
      this.$emit("nodeCollapsed", args);
    },
    nodeDragStart(args) {
      this.$emit("nodeDragStart", args);
    },
    dragStop(args) {
      this.$emit("nodeDragStop", args);
    },
    nodeDrag(args) {
      this.$emit("nodeDragging", args);
    },
    nodeClicked(args) {
      this.$emit("nodeClicked", args);
    },
    nodeSelecting(args) {
      this.$emit("nodeSelecting", args);
    },
    nodeEdited(args) {
      this.$emit("nodeEdited", args);
    },
    dataSourceChanged(args) {
      this.$emit("dataSourceChanged", args);
    },
    handleKeyDown(args) {
      this.$emit("keyPress", args);
    },
  },
};
</script>
<style>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-treeview .e-list-item.e-active:not(.e-editing) > .e-fullrow {
  color: var(--primary-color) !important;
  font-weight: 500;
  background-color: var(--selection-color) !important;
}

.e-treeview
  .e-list-item.e-active:not(.e-editing)
  > .e-text-content
  > .e-list-text {
  color: var(--primary-color);
  font-weight: 500;
}
.e-treeview .e-list-item.e-active:not(.e-editing):hover > .e-fullrow {
  border-color: transparent !important;
}

.e-treeview
  .e-list-item.e-active:not(.e-editing)
  > .e-text-content
  > div.e-icons:before {
  color: var(--primary-color) !important;
  font-weight: bold;
  background-color: var(--selection-color) !important;
}

.e-list-parent.e-ul {
  overflow: hidden;
}

.e-treeview .e-list-text {
  padding: 0 5px 0 0;
}

.e-treeview .e-list-item .e-ul {
  padding: 0 0 0 12px;
}

.e-treeview .e-list-item {
  position: relative;
  z-index: 110;
}

.e-treeview .e-list-item.e-level-1 {
  margin-left: -24px;
}
</style>
