import userDetailsMixin from "./userDetailsMixin";
import { updateProjectsInDb } from "@/helpers/projects";
import { isEmpty } from "lodash";
import { mapGetters } from "vuex";
const mixin = {
  mixins: [userDetailsMixin],
  methods: {
    createProjectPriorityDbData(taskData, projectId) {
      let dbUpdates = {},
        editedData = {};
      if (!isEmpty(taskData)) {
        let projectPriority;
        const projectData = this.projects[projectId];
        if (!isEmpty(projectData)) {
          const currProjectPriority = projectData.priority;
          if (!currProjectPriority && taskData.priority) {
            projectPriority = taskData.priority;
          }

          if (projectPriority) {
            editedData = {
              key: projectData.key,
              priority: projectData.priority || "",
              changedProps: {
                priority: projectPriority,
              },
            };

            dbUpdates[`/projects/${projectData.key}/priority`] =
              projectPriority;
          }
        }
      }
      return {
        dbUpdates,
        editedData,
      };
    },
    setProjectPriorityFromTaskOnce(taskData, projectId) {
      if (!isEmpty(taskData)) {
        let projectPriority;
        const projectData = this.projects[projectId];
        if (!isEmpty(projectData)) {
          const currProjectPriority = projectData.priority;
          if (!currProjectPriority && taskData.priority) {
            projectPriority = taskData.priority;
          }

          if (projectPriority) {
            const editProjectData = {
              key: projectData.key,
              priority: projectData.priority || "",
              changedProps: {
                priority: projectPriority,
              },
            };

            updateProjectsInDb(
              [
                {
                  key: projectData.key,
                  updates: {
                    priority: projectPriority,
                  },
                },
              ],
              [editProjectData],
              this.userInfo.uid
            );
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters("task", ["projects"]),
  },
};

export default mixin;
