<template>
  <ResourceFormPopUp
    :extraData="resourceData"
    :isSelectorOpen="isResourceParentFormOpen"
    @dialog:closed="closeResourcePopUp"
    mode="parent-change"
    watchData
    @data:submitted="handleSubmit"
  />
</template>
<script>
import ResourceFormPopUp from "@/components/ResourceInfoFormPopUp/ResourceInfoFormPopUp.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ResourceFormPopUp,
  },
  props: {},
  methods: {
    ...mapActions("resourceParentForm", ["closeResourceParentForm"]),
    closeResourcePopUp() {
      this.closeResourceParentForm();
    },
    handleSubmit() {
      this.closeResourcePopUp();
    },
  },
  computed: {
    ...mapGetters("resourceParentForm", [
      "isResourceParentFormOpen",
      "resourceData",
    ]),
  },
};
</script>
