const nodeAllChildrenMap = new Map();

export const storeChildrenOfNode = (nodeId, children) => {
  nodeAllChildrenMap.set(nodeId, children);
};

export const getStoredChildrenOfNode = (nodeId) => {
  return nodeAllChildrenMap.get(nodeId);
};

export const checkIfChildrenExists = (nodeId) => {
  return nodeAllChildrenMap.has(nodeId);
};

export const clearAllStoredChildren = () => {
  nodeAllChildrenMap.clear();
};
