<template>
  <div id="loader-container">
    <slot :size="loaderSize"></slot>
  </div>
</template>

<script>
export default {
  props: {
    loaderSize: {
      type: Number,
      default: 32,
    },
  },
};
</script>
<style scoped>
#loader-container {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
}
</style>
