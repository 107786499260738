import { getUserNotes } from "@/helpers/notes";
// import { TASK_ACTIONS, getUserActions } from "@/helpers/actionHistory";
import userDetailsMixin from "./userDetailsMixin";
import cloneDeep from "lodash/cloneDeep";
import {
  NOTES_ACTIONS,
  getUserActions,
  DIRECTORY_ACTIONS,
} from "@/helpers/actionHistory";
import { cleanNotes } from "@/helpers/notes";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import { fillTaskData } from "@/helpers/tasks";
import { createNoteDataForAdd } from "@/helpers/notes";
import { removeTasksFromTaskList } from "@/mixins/tasksHelpersMixin";
import { findDir } from "@/helpers/directories";
// import store from "@/store";

/**
 * It takes the list of notes to remove
 * @param {Object[]} notesToRemove - list of notes to remove
 * @param {Boolean} recordAction - To record action or not by default its true
 * @returns {Promise}
 */
export const removeNotesFromNotesList = async (
  notesToRemove,
  recordAction = true
) => {
  if (!Array.isArray(notesToRemove)) notesToRemove = [notesToRemove];
  const noteIds = [];

  notesToRemove.forEach((note) => noteIds.push(note.key));
  //   const cleanedTasks = notesToRemove.map((note) => {
  //     no
  //     return note
  //   }
  // cleanTask(fillTaskData(task))
  //   );

  await getUserNotes().removeMulti(noteIds);

  // //   await deleteTasks(cleanedTasks, this.userInfo.uid);

  if (recordAction) {
    const cleanedNotes = cleanNotes(notesToRemove);
    getUserActions().addNoteAction({
      type: NOTES_ACTIONS.BATCH_DELETE,
      data: cleanedNotes,
    });
  }
};

/**
 * It takes the list of notes to add
 * @param {Object[]|Object} notesToAdd - list of notes to add
 * @param {Boolean} recordAction - To record action or not by default its true
 * @param {Boolean} clean - To clean notes before adding default is true
 * @returns {Promise}
 */
export const addMultiNotesInNotesList = async (
  notesToAdd,
  recordAction = true,
  clean = true
) => {
  if (!Array.isArray(notesToAdd)) notesToAdd = [notesToAdd];

  if (clean) notesToAdd = cleanNotes(notesToAdd);

  await getUserNotes().addMulti(notesToAdd);
  if (recordAction) {
    await getUserActions().addNoteAction({
      type: NOTES_ACTIONS.BATCH_ADD,
      data: notesToAdd,
    });
  }
};

/**
 * It takes the list of tasks and converts them to notes
 * @param {Object[]|Object} tasksToConvert - list of tasks to convert
 * @returns {Promise}
 */
export const convertTasksToNotes = async (tasksToConvert) => {
  if (!Array.isArray(tasksToConvert)) tasksToConvert = [tasksToConvert];
  const tasksToRemove = [];
  const notesToAdd = [];

  tasksToConvert.forEach((task) => {
    const { key, ...restData } = task;

    const createdNoteData = createNoteDataForAdd({ ...restData });
    notesToAdd.push({ ...createdNoteData });
    tasksToRemove.push({ ...restData, key });
  });

  addMultiNotesInNotesList(notesToAdd, false);
  removeTasksFromTaskList(tasksToRemove, false);
};
const mixin = {
  mixins: [userDetailsMixin],
  methods: {
    // async addTaskInTaskList(taskData, recordAction = true, cleanTasks = true) {

    //   const cleanedTasks = cleanTasks(taskData);
    //   await addTasks(cleanedTasks, this.userInfo.uid);
    //   if (recordAction) {
    //     // const tasksData = createAddedTaskDataForAddAction([taskData]);
    //     await getUserActions().addTaskAction({
    //       type: TASK_ACTIONS.BATCH_ADD,
    //       data: cleanedTasks,
    //     });
    //   }
    // },
    /**
     * It takes the list of notes to add
     * @param {Object[]|Object} notesToAdd - list of notes to add
     * @param {Boolean} recordAction - To record action or not by default its true
     * @param {Boolean} clean - To clean notes before adding default is true
     * @returns {Promise}
     */
    addMultiNotesInNotesList,
    // /**
    //  * It takes the list of tasks to add and adds them to db with default values if not present
    //  * @param {Object[]|Object} tasksToAdd - list of tasks to add
    //  * @param {Boolean} recordAction - To record action or not by default its true
    //  * @returns {Promise}
    //  */
    // async fillTaskDataThenAddInList(tasksToAdd, recordAction = true) {
    //   if (!Array.isArray(tasksToAdd)) tasksToAdd = [tasksToAdd];
    //   tasksToAdd = tasksToAdd.map((task) => fillTaskData(task));
    //   await this.addMultiTasksInList(tasksToAdd, recordAction, false);
    // },
    /**
     * It takes the list of notes to remove
     * @param {Object[]} notesToRemove - list of notes to remove
     * @param {Boolean} recordAction - To record action or not by default its true
     * @returns {Promise}
     */
    removeNotesFromNotesList,

    async updateMultiNotesInNotesList(notesToUpdate, recordAction = true) {
      if (!Array.isArray(notesToUpdate)) notesToUpdate = [notesToUpdate];

      // if (cleanTasks) tasksToAdd = cleanTaskList(tasksToAdd);

      const currNotes = cloneDeep(getUserNotes().getNotes());
      let editedList = [];
      notesToUpdate.forEach((n) => {
        const noteIndex = currNotes.findIndex((note) => note.key === n.key);

        if (noteIndex >= 0) {
          const currNoteData = currNotes[noteIndex];
          currNotes[noteIndex] = {
            ...currNoteData,
            ...n.updates,
          };
          if (!isEmpty(n.editedData)) {
            editedList.push({
              ...n.editedData,
            });
          }
        }
      });

      // tasksToAdd = cleanTasks(tasksToAdd);
      await getUserNotes().set(currNotes);
      if (recordAction) {
        getUserActions().addNoteAction({
          type: NOTES_ACTIONS.BATCH_EDIT,
          data: editedList,
        });
        // const tasksData = createAddedTaskDataForAddAction(tasksToAdd);
        // await getUserActions().addTaskAction({
        //   type: TASK_ACTIONS.BATCH_ADD,
        //   data: tasksToAdd,
        // });
      }
    },
    async addMultiDirectories(directoriesToAdd, recordAction = true) {
      if (!Array.isArray(directoriesToAdd)) {
        directoriesToAdd = [directoriesToAdd];
      }

      await getUserNotes().addMulti(directoriesToAdd, "directories");
      if (recordAction) {
        let actionType = DIRECTORY_ACTIONS.BATCH_ADD;
        if (directoriesToAdd.length === 1) {
          actionType = DIRECTORY_ACTIONS.ADD;
          directoriesToAdd = directoriesToAdd[0];
        }
        await getUserActions().addDirAction({
          type: actionType,
          data: directoriesToAdd,
        });
      }
    },

    async updateMultiDir(directoriesToUpdate, recordAction = true) {
      if (!Array.isArray(directoriesToUpdate))
        directoriesToUpdate = [directoriesToUpdate];

      // if (cleanTasks) tasksToAdd = cleanTaskList(tasksToAdd);
      const currDirs = cloneDeep(getUserNotes().getDirectories());
      let editedList = [];
      directoriesToUpdate.forEach((d) => {
        if (currDirs[d.key]) {
          const currData = currDirs[d.key];
          currDirs[d.key] = {
            ...currData,
            ...d.updates,
            key: d.key,
          };

          if (!isEmpty(d.editedData)) {
            editedList.push({
              ...d.editedData,
            });
          }
        }
      });

      // tasksToAdd = cleanTasks(tasksToAdd);
      await getUserNotes().set(currDirs, "directories");
      if (recordAction) {
        this.recordUpdateDirAction(editedList);
        // let actionType = DIRECTORY_ACTIONS.BATCH_EDIT;
        // if (editedList.length === 1) {
        //   actionType = DIRECTORY_ACTIONS.EDIT;
        //   editedList = editedList[0];
        // }
        // // const tasksData = createAddedTaskDataForAddAction(tasksToAdd);
        // await getUserActions().addDirAction({
        //   type: actionType,
        //   data: editedList,
        // });
      }
    },
    async removeMultiDir(dirsToRemove, recordAction = true) {
      if (!Array.isArray(dirsToRemove)) dirsToRemove = [dirsToRemove];
      const dirIds = [];

      dirsToRemove.forEach((dir) => dirIds.push(dir.key));
      await getUserNotes().removeMulti(dirIds, "directories");
      if (recordAction) {
        let actionType = DIRECTORY_ACTIONS.BATCH_DELETE;
        if (dirsToRemove.length === 1) {
          actionType = DIRECTORY_ACTIONS.DELETE;
          dirsToRemove = dirsToRemove[0];
        }
        await getUserActions().addDirAction({
          type: actionType,
          data: dirsToRemove,
        });
      }
    },

    async resortChildrenInParentFolder(dirId) {
      const dir = this.directories[dirId];
      let children = this.notesTree;
      if (dir) {
        if (dir.parentKey) {
          children = this.dirChildrenMap[dir.parentKey];
        }

        const updates = [];
        if (children && children.length) {
          children = cloneDeep(children);
          children.sort((a, b) => a.order - b.order);
          children.forEach((dC, index) => {
            updates.push({
              updates: {
                order: index,
              },
              key: dC.key,
            });
          });

          await this.updateMultiDir(updates, false);
        }
      }
    },

    async removeFolderFromDirs(selectedDir, recordAction = true) {
      let parentKey = selectedDir.parentKey || "";
      // let dirOrder = selectedDir.order || 0;
      let currDirectories = cloneDeep(this.directories);
      delete currDirectories[selectedDir.key];

      let children = this.notesTree;
      if (parentKey && this.directories[parentKey]) {
        children =
          findDir(this.notesTree, (node) => node.key === parentKey)?.children ||
          [];
      }
      children = cloneDeep(children);

      if (children && children.length) {
        const dirIndex = children.findIndex((c) => c.key === selectedDir.key);
        if (dirIndex >= 0) {
          children.splice(dirIndex, 1);
        }
        children.sort((a, b) => a.order - b.order);
        children.forEach((c, index) => {
          if (currDirectories[c.key]) {
            currDirectories[c.key] = {
              ...currDirectories[c.key],
              order: index,
              parentKey: currDirectories[c.key].parentKey || "",
            };
          }
        });
      }

      await getUserNotes().set(currDirectories, "directories");

      if (recordAction) {
        await getUserActions().addDirAction({
          type: DIRECTORY_ACTIONS.DELETE,
          data: selectedDir,
        });
      }
    },
    async recordUpdateDirAction(editedList) {
      if (!Array.isArray(editedList)) editedList = [editedList];
      let actionType = DIRECTORY_ACTIONS.BATCH_EDIT;
      if (editedList.length === 1) {
        actionType = DIRECTORY_ACTIONS.EDIT;
        editedList = editedList[0];
      }
      await getUserActions().addDirAction({
        type: actionType,
        data: editedList,
      });
    },
    async convertNoteToTask(noteData = {}) {
      const { key, ...restData } = noteData;
      const createdTaskData = fillTaskData({ ...restData, taskType: "task" });
      this.addMultiTasksInList(createdTaskData, false);
      this.removeNotesFromNotesList({ ...restData, key }, false);
    },
    addNoteAsTask(noteData) {
      this.setDataForConvertMode({
        dataList: [{ ...noteData }],
        typeToUse: "task",
      });
    },
    ...mapActions("note", ["updateDirChildMap"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
  },
  computed: {
    ...mapGetters("note", ["dirChildrenMap", "directories", "notesTree"]),
  },
};

export default mixin;
