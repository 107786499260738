import { cloneDeep, isEmpty } from "lodash";

export default {
  openResourceParentForm(context, payload) {
    const stateUpdates = {
      isResourceParentFormOpen: true,
    };

    if (!isEmpty(payload.resourceData)) {
      stateUpdates.resourceData = Object.freeze(
        cloneDeep(payload.resourceData)
      );
    }
    context.commit("setData", stateUpdates);
  },
  closeResourceParentForm(context) {
    context.commit("setData", {
      isResourceParentFormOpen: false,
      resourceData: {},
    });
  },
};
