import convertDataModeActions from "./actions";
import convertDataModegMutations from "./mutations";
import convertDataModeGetters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      isSelectorOpen: false,
      type: "",
      dataList: [],
      calledFrom: "",
    };
  },
  mutations: convertDataModegMutations,
  actions: convertDataModeActions,
  getters: convertDataModeGetters,
};
