let observer;
export const setResizeObserverOnEl = (el, callback) => {
  observer = new ResizeObserver((entries) => {
    callback(entries);
  });

  observer.observe(el);
};

export const removeObserverFromEl = (el) => {
  observer.unobserve(el);
};
