import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".csv"},on:{"change":_vm.handleUpload}}),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-btn small-icon import-btn",attrs:{"text":"","data-action-type":"data-change","data-action-value":"upload"},on:{"click":_vm.openUploadWindow}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"#fff"}},[_vm._v("$ctiImport ")])],1)]}}])},[_c('span',[_vm._v(" Import ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }