<template>
  <v-app>
    <v-app-bar app color="primary" flat name="TaskBar">
      <p class="white--text text-h5 mb-0">Chaos To Intention</p>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row v-if="user">
          <v-col cols="12" offset="0" md="6" offset-md="3">
            <v-card class="pa-4">
              <v-card-title> Welcome Back </v-card-title>
              <v-card-text> User Email: {{ user.email }} </v-card-text>
              <v-card-actions>
                <v-btn v-on:click="signOut" class="mr-4"> Sign Out </v-btn>
                <router-link to="/dashboard">
                  <v-btn color="primary"> Continue to Dashboard </v-btn>
                </router-link>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <div v-else>
          <v-row>
            <v-col v-if="mode === 0" cols="12" offset="0" md="6" offset-md="3">
              <v-card>
                <v-card-title class="text-center" style="display: block">
                  Sign In
                </v-card-title>
                <v-form v-on:submit.prevent="signIn">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          solo
                          type="email"
                          hint="Email"
                          label="Email"
                          autocomplete="false"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          solo
                          type="password"
                          hint="Password"
                          label="Password"
                          autocomplete="new"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-btn class="mr-4" type="submit" color="primary">
                          Sign In
                        </v-btn>
                        <a class="mr-4"
                          ><span class="primary--text" v-on:click="mode = 1"
                            >No Account?</span
                          ></a
                        >
                        <a
                          ><span class="primary--text" v-on:click="mode = 2"
                            >Forgot Password?</span
                          ></a
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-col>
            <v-col v-if="mode === 1" cols="12" offset="0" md="6" offset-md="3">
              <v-card>
                <v-card-title class="text-center" style="display: block">
                  Create Account
                </v-card-title>
                <v-form v-on:submit.prevent="createAccount">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          solo
                          type="email"
                          hint="Email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          solo
                          type="password"
                          hint="Password"
                          label="Password"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-btn class="mr-4" type="submit" color="primary">
                          Create Account
                        </v-btn>
                        <a class="mr-4"
                          ><span class="primary--text" v-on:click="mode = 0"
                            >Already Have an Account?</span
                          ></a
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-col>
  
            <v-col v-if="mode === 2" cols="12" offset="0" md="6" offset-md="3">
              <v-card>
                <v-card-title class="text-center" style="display: block">
                  Recover Password
                </v-card-title>
                <v-form v-on:submit.prevent="recoverPassword">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          solo
                          type="email"
                          hint="Email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
  
                      <v-col>
                        <v-btn class="mr-4" type="submit" color="primary">
                          Recover Password
                        </v-btn>
                        <a class="mr-4"
                          ><span class="primary--text" v-on:click="mode = 0"
                            >Sign In</span
                          ></a
                        >
                        <a class="mr-4"
                          ><span class="primary--text" v-on:click="mode = 1"
                            >Create Account</span
                          ></a
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
          <p class="text-center">By continuing, you agree to CTI's <router-link to="/terms-of-service">Terms of Service</router-link> and <router-link to="/privacy-policy">Privacy Policy</router-link></p>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "@/services/firebase";
import { initUserNotes } from "@/helpers/notes";
import { mapActions } from "vuex";
import DatabaseInterface from "@/services/DatabaseInterface";
import { getAppColorThemeFromUserData } from "@/helpers/app";
export default {
  name: "Home",
  components: {},
  data: () => ({
    mode: 0,
    user: null,
    newUserData: {
      categories: {},
      globalView: false,
      inboxView: false,
      nav: 0,
      projects: {},
      resources: {},
      selectedItems: [],
      tasks: {},
      view: [
        {
          sortMode: 0,
          statusFilter: "scheduled",
          type: "calView",
        },
        {
          selectedCategory: "all",
          selectedProject: "all",
          selectedSubcategory: "all",
          sortMode: 2,
          statusFilter: "incomplete",
          type: "treeView",
        },
        {
          selectedCategory: "all",
          selectedProject: "all",
          selectedSubcategory: "all",
          sortMode: 2,
          statusFilter: "incomplete",
          type: "resourceView",
        },
        {
          sortMode: 1,
          statusFilter: "scheduled",
        },
      ],
    }
  }),
  mounted() {
    this.autoLoginCheckingInterface()
  },
  created() {
    this.checkLogin();
  },
  methods: {
    autoLoginCheckingInterface() {
      setTimeout(() => {
        const els = document.querySelectorAll("input:-webkit-autofill")
        els.forEach((el) => {
          const label = el.parentElement.querySelector("label")
          label.style.display = "none";
        })
      }, 100)
    },
    async checkAndRouteToNotes(userLogged) {
      const res = await (
        await DatabaseInterface.get(userLogged.uid).once("value")
      ).val();

      // res = res.val();
      if (res.notesView) {
        this.updateRootState({
          isNotesView: true,
        });
        this.$router.replace({
          name: "Notes",
        });
      }
    },
    checkLogin() {
      firebase.auth().onAuthStateChanged((user) => {
        try {
          if (user) {
            this.user = user;
            initUserNotes(user.uid);
            this.setResourceSettings(user.uid);
            this.setUserDetails(user);

            // const res = await (
            //   await DatabaseInterface.get(user.uid).once("value")
            // ).val();
            // res = res.val();
            // console.debug("RES", this.$route.name);
            // if (this.$route.name !== "Notes" && res.notesView) {
            //   this.$router
            //     .replace({
            //       name: "Notes",
            //     })
            //     .catch();
            // }
            // console.debug(user);
          } else {
            this.user = null;
          }
        } catch (error) {
          console.error("login check failed!:", error.message);
        }
      });
    },
    createAccount(event) {
      // console.debug("begin create account");
      let email = event.srcElement[0]._value;
      let password = event.srcElement[1]._value;

      // console.debug("email: " + email);
      // console.debug("password: " + password);

      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          //  This then block contains response
        })
        .catch((error) => {
          console.debug("account creation failed!");
          console.debug(error);
        });
    },
    async signIn(event) {
      // console.debug("begin sign in");
      let email = event.srcElement[0]._value;
      let password = event.srcElement[1]._value;

      // console.debug("email: " + email);
      // console.debug("password: " + password);
      try {
        const loginRes = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        const userLogged = loginRes.user;
        this.$router.push("/dashboard")

        initUserNotes(userLogged.uid);
        this.setUserDetails(userLogged);
        this.setResourceSettings(userLogged.uid);
        await this.checkAndRouteToNotes(userLogged);
      } catch (error) {
        console.error("sign in failed!:", error.message);
        // console.debug(error.message);
      }

      // firebase
      //   .auth()
      //   .signInWithEmailAndPassword(email, password)
      //   .then(async (user) => {
      //     initUserNotes(user.user?.uid);
      //     this.setUserDetails(user.user?.uid);

      //     const res = await DatabaseInterface.get(user.user?.uid).once("value");
      //     console.debug("RESSS", res);
      //     console.debug("sign in successful!");
      //   })
      //   .catch((error) => {
      //     console.debug("sign in failed!");
      //     console.debug(error);
      //   });
    },
    async setResourceSettings(userId) {
      const res = await (
        await DatabaseInterface.get(userId).once("value")
      ).val();

      // res = res.val();

      this.updateRootState({
        resourceSettings: res.resourceSettings || {},
        editFormSettings: res.editFormSettings || {},
        selectedColorTheme: getAppColorThemeFromUserData(res),
      });
    },
    recoverPassword(event) {
      let email = event.srcElement[0]._value;

      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          console.debug("recover password successful!");
        })
        .catch((error) => {
          console.debug("recover password failed!");
          console.debug(error);
        });
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          firebase.auth().onAuthStateChanged(() => {
            console.debug("signed out");
          });
        });
    },
    ...mapActions("user", ["setUserDetails"]),
    ...mapActions(["updateRootState"]),
  },
};
</script>
