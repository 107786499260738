<template>
  <v-btn-toggle id="toggle-btns-group">
    <v-tooltip bottom v-for="(b, index) in list" :key="index">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="handleClick($event, b)"
          :class="createBtnStyles(b)"
          v-on="on"
          v-bind="attrs"
          v-show="b.isVisible()"
        >
          <v-icon :size="b.iconSize">
            {{ b.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ b.label }}</span>
    </v-tooltip>
  </v-btn-toggle>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
    },
  },
  methods: {
    handleClick(e, itemData) {
      this.$emit("click", e, itemData);
    },
    createBtnStyles(itemData) {
      return {
        "toggle-btn": true,
        [itemData.getActiveClass()]: this.active === itemData.key,
      };
    },
  },
};
</script>
<style scoped>
.toggle-btn {
  background: #efefef !important;
  border: 1px solid transparent !important;
  height: 20px !important;
  border-radius: 6px;
}

#toggle-btns-group {
  height: 20px;
  background: #efefef !important;
  display: flex;
  align-items: center;
  border-radius: 6px !important;
}

#toggle-btns-group button.v-btn {
  padding: 0px 12px !important;
  border-color: transparent !important;
  border-width: 1.2px !important;
  min-width: auto !important;
  border-radius: 6px !important;
  padding: 0px 8px !important;
}
</style>
