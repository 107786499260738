<template>
  <v-btn-toggle class="date-helpers-btns-wrapper">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...btnSizeStylesAttrs, ...attrs }"
          @click="handleSubClick"
          v-on="on"
          style="font-weight: 600; font-size: 18px !important"
          tabindex="-1"
        >
          -
        </v-btn>
      </template>
      <span>Remove Date and Time</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...btnSizeStylesAttrs, ...attrs }"
          @click="handleAddClick"
          v-on="on"
          tabindex="-1"
        >
          +
        </v-btn>
      </template>
      <span>Set Current Date and Time</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...attrs, ...btnSizeStylesAttrs }"
          v-on="on"
          v-on:click="nextDay"
          tabindex="-1"
        >
          D
        </v-btn>
      </template>
      <span>Push One Day</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...attrs, ...btnSizeStylesAttrs }"
          v-on="on"
          v-on:click="nextWeek"
          tabindex="-1"
        >
          W
        </v-btn>
      </template>
      <span>Push One Week</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...attrs, ...btnSizeStylesAttrs }"
          v-on="on"
          v-on:click="nextMonth"
          tabindex="-1"
        >
          M
        </v-btn>
      </template>
      <span>Push One Month</span>
    </v-tooltip>
  </v-btn-toggle>
</template>
<script>
export default {
  props: {
    btnSize: {
      type: Number,
    },
  },
  methods: {
    nextMonth(e) {
      e.stopPropagation();
      this.sendModeData("month");
    },
    nextWeek(e) {
      e.stopPropagation();
      this.sendModeData("week");
    },
    nextDay(e) {
      e.stopPropagation();
      this.sendModeData("day");
    },
    handleAddClick(e) {
      e.stopPropagation();
      this.sendModeData("now");
    },
    handleSubClick(e) {
      e.stopPropagation();
      this.sendModeData("none");
    },
    sendModeData(type) {
      this.$emit("add", type);
    },
  },
  computed: {
    btnSizeStylesAttrs() {
      let stylesData = {};

      if (this.btnSize) {
        stylesData = {
          "x-small": true,
          class: "small-text-size",
          style: {
            width: `${this.btnSize}px !important`,
            height: `${this.btnSize}px !important`,
          },
        };
      }
      return { ...stylesData };
    },
  },
};
</script>
<style scoped>
.small-text-size {
  font-size: 12px !important;
  background-color: #FFFFFF !important;
}

.date-helpers-btns-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem .5rem;
  background: #EAEAEA !important;
}
</style>
