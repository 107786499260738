import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,_vm._b({attrs:{"disabled":!_vm.showTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"keydown":_vm.$listeners.keydown,"click":_vm.$listeners.click}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":_vm.btnData.iconSize,"color":_vm.btnData.iconColor}},[_vm._v(" "+_vm._s(_vm.btnData.iconName))])],1)]}}])},'v-tooltip',_vm.tooltipProps,false),[_c('span',[_vm._v(_vm._s(_vm.btnData.tooltipText))])])}
var staticRenderFns = []

export { render, staticRenderFns }