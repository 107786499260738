if (typeof window !== "undefined") {
  if (window && window.memoryStorage) {
    window.memoryStorage = {};
  }
}

export default class Storage {
  static get(key) {
    let value;
    if (localStorage) {
      value = localStorage.getItem(key) || this.getMemoryStorage()[key];
    } else {
      value = this.getMemoryStorage()[key];
    }
    return value || null;
  }

  static remove(key) {
    localStorage && localStorage.removeItem(key);
    if (window.memoryStorage) {
      delete window.memoryStorage[key];
    }
  }

  static set(key, value) {
    if (localStorage) {
      localStorage.setItem(key, value);
    } else {
      window.memoryStorage[key] = value;
    }
  }

  static clear() {
    localStorage && localStorage.clear();
    window.memoryStorage = {};
  }

  static getMemoryStorage() {
    return window.memoryStorage || {};
  }
}
