import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"20%","value":_vm.isEditConfirmBoxOpened},on:{"click:outside":_vm.close,"keydown":_vm.handleKeyDown}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Edit Recurring Task ")]),_c(VCardText,[_c('div',[_c(VRadioGroup,{model:{value:(_vm.selectedOpt),callback:function ($$v) {_vm.selectedOpt=$$v},expression:"selectedOpt"}},_vm._l((_vm.editOpts),function(opt,index){return _c(VRadio,{key:index,attrs:{"label":opt.label,"value":opt.value}})}),1)],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"save-confirm-btn",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.submit}},[_vm._v(" Save ")]),_c(VBtn,{attrs:{"depressed":"","text":"","color":"error"},on:{"click":_vm.close}},[_vm._v(" Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }