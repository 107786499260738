import {
  addNotesInLocalNotesList,
  removeNotesFromLocalList,
  updateNotesInLocalList,
} from "@/helpers/notes";
import { isEmpty } from "lodash";

export default {
  updateNoteState(context, payload) {
    let currState = context.state;
    currState = {
      ...currState,
      ...payload,
    };
    context.commit("updateState", currState);
  },
  setNotesTreeStructure(context, payload) {
    context.commit("setNotesDirChildTreeMap", payload.dirChildrenMap);
    context.commit("setNotesTree", payload.tree);
  },
  setFoldersListFilter(context, payload) {
    context.commit("setFoldersFilter", payload);
  },
  setNotesSearchQuery(context, payload) {
    context.commit("setSearchQuery", payload);
  },
  setSelectedNotes(context, payload) {
    context.commit("setSelectedNotes", payload);
  },
  updateDirChildMap(context, payload) {
    const { dirKey, list } = payload;
    const { dirChildrenMap } = context.state;
    if (dirChildrenMap[dirKey]) {
      dirChildrenMap[dirKey] = list || [];
    }
  },
  addOrRemoveOrUpdatesNotes(context, payload) {
    if (!isEmpty(payload)) {
      const currState = context.state;
      let currTasks = [...currState.notes];
      const {
        notesToAdd,
        notesToUpdate,
        notesIdsToRemove,
        addMethod,
        verifyAdd,
      } = payload;
      if (notesToUpdate && notesToUpdate.length) {
        currTasks = updateNotesInLocalList(notesToUpdate, currTasks);
      }

      if (notesIdsToRemove && notesIdsToRemove.length) {
        currTasks = removeNotesFromLocalList(notesIdsToRemove, currTasks);
      }

      if (notesToAdd && notesToAdd.length) {
        currTasks = addNotesInLocalNotesList(
          notesToAdd,
          currTasks,
          addMethod,
          verifyAdd
        );
      }

      context.commit("setNotesInList", currTasks);
    }
  },
  setNoteTopicsSelection(context, payload) {
    context.commit("updateState", {
      selectedNoteTopics: payload,
    });
  },
  clearNoteTopicsSelection(context) {
    context.commit("updateState", {
      selectedNoteTopics: [],
    });
  },
};
