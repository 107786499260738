import isEmpty from "lodash/isEmpty";
export const storeValueInArray = ({
  valueToStore,
  list,
  isObject,
  indexFinderFunc,
}) => {
  if (isObject) {
    const storedIndex = list.findIndex((i) => indexFinderFunc(i));

    if (storedIndex === -1) {
      list.push(valueToStore);
    }
  } else {
    if (!list.includes(valueToStore)) {
      list.push(valueToStore);
    }
  }

  return list;
};

export const convertValueToArray = (data) => {
  if (!isEmpty(data)) {
    if (!Array.isArray(data)) {
      data = [data];
    }
  }

  return data;
};
