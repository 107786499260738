import isCtrlKey from "@/utils/isCtrlKey";
import { mapActions } from "vuex";

const mixin = {
  mounted() {
    this.setSearchBoxKeyListeners();
  },
  beforeDestroy() {
    this.removeSearchBoxKeyListeners();
  },
  methods: {
    ...mapActions(["toggleGlobalSearchBox"]),
    setSearchBoxKeyListeners() {
      document.addEventListener("keydown", this.openSearchBoxByKey);
    },
    removeSearchBoxKeyListeners() {
      document.removeEventListener("keydown", this.openSearchBoxByKey);
    },
    openSearchBoxByKey(e) {
      let isKeyCharK = false;

      if ((e.key && e.key === "k") || (e.keyCode && e.keyCode === 75)) {
        isKeyCharK = true;
      }

      if (isKeyCharK && isCtrlKey(e)) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.toggleGlobalSearchBox();
      }
    },
  },
};

export default mixin;
