<template>
  <v-dialog
    width="30%"
    :value="isSelectorOpen"
    @click:outside="closeDialog"
    @keydown="handleKeyDown"
    content-class="add-resource-pop-up"
  >
    <v-card>
      <v-card-title class="text-h5"> {{ labelText }} </v-card-title>
      <v-card-text>
        <div>
          <template v-if="isInAddMode">
            <div class="resource-tag-editor-wrapper">
              <ResourceTagEditorInput ref="resourceTagInput" v-model="tag" />
            </div>

            <div class="resource-title-editor-wrapper mt-4">
              <RichTextEditor
                ref="titleEditor"
                v-model="title"
                :showToolbar="false"
                stopTabProcess
                editorId="resource-title-cell-editor"
                class="resource-title-field resource-form-field"
                :useAsInput="true"
                :enableMentions="false"
                placeholder="Enter Name"
              />
            </div>
          </template>

          <div class="mt-4">
            <ResourceTypeSelector
              v-model="type"
              ref="resourceTypeSelector"
              placeholder="Select Type"
              selectorClass="resource-type-selector resource-form-field"
              :menuProps="{
                'content-class': 'resource-type-selector-menu',
              }"
            />

            <!-- <ItemTypeSelector
              v-model="type"
              ref="resourceTypeSelector"
              type="resource"
              placeholder="Select Type"
              class="resource-type-selector resource-form-field"
              :extraMenuProps="{
                'content-class': 'resource-type-selector-menu',
              }"
            >
              <template #selection-content="{ item }">
                <span class="selected-resource">
                  <v-icon color="black" class="mr-2" size="18">
                    {{ item.icon }}
                  </v-icon>
                  <span>{{ item.title }}</span>
                </span>
              </template>
            </ItemTypeSelector> -->
          </div>
          <div class="mt-4">
            <ResourceCateSelector
              ref="resourceCateSelector"
              v-model="catId"
              :optsList="createdOptsList"
              :cateType="type"
              useProvidedOpts
              :useNoDataSlot="showAddGroupBtn"
              class="resource-type-cate-selector resource-form-field"
              :placeholder="selectPlaceholder"
              :menuProps="{
                'content-class': 'resource-type-selector-menu',
              }"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          class="resource-save-btn"
          color="primary"
          depressed
          ref="saveBtn"
          text
          @click.prevent.stop="submitData"
          @keydown="handleKeyDown"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import ResourceCateSelector from "@/components/EditComponents/ResourceCateSelector/ResourceCateSelector.vue";
import ResourceTagEditorInput from "@/components/ResourcesListView/components/ResourcesTable/components/ResourceTagEditorInput.vue";
// import ItemTypeSelector from "@/components/ItemTypeSelector";
import ResourceTypeSelector from "@/components/EditComponents/ResourceTypeSelectorInput.vue";
import { createResourceCategoriesOpts } from "@/helpers/resourceCategoriesHelpers";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  addOrUpdateOrRemoveResourcesInLocalList,
  createResourceDataForAdd,
  createUpdateAndEditedResourceData,
  expandAllParentsOfResource,
  processResourceData,
  setResourceIdInTree,
} from "@/helpers/resources";
import { PRIMARY_COLOR } from "@/variables/colors";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import { submitKeys } from "@/variables/keys";
import { getPathsFromEvent } from "@/utils/events";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { resourceTypeOptsList } from "@/data/resources";
import isEmpty from "lodash/isEmpty";
import {
  REFRESH_ACTIVITY_HISTORY,
  REFRESH_RESOURCE_FORM_DATA,
} from "@/variables/events";
import EventEmitter from "@/helpers/eventEmitter";
import { getUserActions, RESOURCE_ACTIONS } from "@/helpers/actionHistory";
export default {
  mixins: [resourcesHelpersMixin],
  props: {
    isSelectorOpen: {
      type: Boolean,
    },
    labelText: {
      type: String,
    },
    entertedTag: {
      type: String,
    },
    extraData: {
      type: Object,
    },
    watchData: {
      type: Boolean,
    },
    mode: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      type: "",
      catId: "",
      title: "",
      tag: "",
      resourceKey: "",
    };
  },
  mounted() {
    if (this.watchData) {
      this.$watch("extraData", this.prefillData, {
        deep: true,
      });
    }
  },
  methods: {
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    ...mapActions("task", ["setAdditionalTaskFilters"]),
    closeDialog() {
      this.$emit("dialog:closed");
      this.title = "";
      this.catId = "";
      this.type = "";
      this.tag = "";
      this.resourceKey = "";
    },
    handleKeyDown(e) {
      const keyCode = e.which ? e.which : e.keyCode;
      e.stopPropagation();
      // const isFromResourceField = findParentFromPathsByClassName(
      //   getPathsFromEvent(e),
      //   ["resource-form-field"]
      // );
      if (keyCode === 27) {
        this.closeDialog();

        return;
      }

      if (keyCode === 9) {
        const resourceSaveBtnFound = findParentFromPathsByClassName(
          getPathsFromEvent(e),
          ["resource-save-btn"]
        );
        const resourceCateSelectorParentFound = findParentFromPathsByClassName(
          getPathsFromEvent(e),
          ["resource-type-cate-selector"]
        );

        const resourceTypeSelectorParentFound = findParentFromPathsByClassName(
          getPathsFromEvent(e),
          ["resource-type-selector"]
        );

        const resourceTitleInputFound = findParentFromPathsByClassName(
          getPathsFromEvent(e),
          ["resource-title-field"]
        );

        if (!e.shiftKey) {
          if (resourceTitleInputFound) {
            setTimeout(() => {
              this.$refs.resourceTypeSelector.openInput();
            }, 0);
          } else if (resourceTypeSelectorParentFound) {
            this.$refs.resourceTypeSelector.blurInput();
            setTimeout(() => {
              this.$refs.resourceCateSelector.openInput();
            }, 0);
          }
        } else {
          //

          if (resourceCateSelectorParentFound) {
            setTimeout(() => {
              this.$refs.resourceTypeSelector.openInput();
            }, 0);
          } else if (resourceTypeSelectorParentFound) {
            this.$refs.resourceTypeSelector.blurInput();
            setTimeout(() => {
              this.$refs.titleEditor.focusOnEl(true);
            }, 0);
          } else if (resourceSaveBtnFound) {
            setTimeout(() => {
              this.$refs.resourceCateSelector.openInput();
            }, 0);
          }
        }
      }

      if (submitKeys.includes(keyCode)) {
        const submitBtnParentFound = findParentFromPathsByClassName(
          getPathsFromEvent(e),
          ["resource-save-btn"]
        );

        if (submitBtnParentFound && !e.shiftKey) {
          this.submitData();
        }
      }
    },
    async submitData() {
      // let catId = this.catId?.trim() || "";
      let type = this.type?.trim() || "";
      const updatedTag = this.tag?.trim() || "";

      if (!type) {
        this.$swal({
          titleText: "Oops!",
          html: "Resource Type is required",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
          customClass: {
            container: "resource-error-pop-up",
          },
        });
        return;
      }

      const isTagValidRes = await this.checkIfTagIsValid(updatedTag, type);
      if (!isTagValidRes.isValid) {
        this.showAlertForTagNotValid(isTagValidRes);
        return;
      }

      if (this.mode === "parent-change") {
        this.handleParentChange();
      } else if (this.mode === "add") {
        this.handleResourceAdd();
      }
    },

    async handleParentChange() {
      let catId = this.catId?.trim() || "";
      let type = this.type?.trim() || "";
      let resourceKey = this.resourceKey || "";
      const updatedTag = this.tag?.trim() || "";

      // if (!catId) {
      //   this.$swal({
      //     titleText: "Oops!",
      //     html: "Group is required",
      //     icon: "error",
      //     confirmButtonColor: PRIMARY_COLOR,
      //     iconColor: PRIMARY_COLOR,
      //     customClass: {
      //       container: "resource-error-pop-up",
      //     },
      //   });
      //   return;
      // }
      this.loaderToggler(true);

      const currResourceData = { ...this.rawResourcesMap[resourceKey] };

      const { updates, editedData } = createUpdateAndEditedResourceData(
        {
          tag: updatedTag,
          type,
          catId,
        },
        { ...currResourceData }
      );

      await this.addOrRemoveOrUpdateResourcesInList({
        resourcesToUpdate: [
          {
            updates,
            editedData,
            key: resourceKey,
          },
        ],
      });

      const updatedResourceData = {
        ...currResourceData,
        ...updates,
      };

      await addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToUpdate: [
          {
            key: resourceKey,
            updates,
          },
        ],
      });
      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY, true);
      await setResourceIdInTree(updatedResourceData);
      await Vue.nextTick();
      expandAllParentsOfResource(updatedResourceData);

      this.setAdditionalTaskFilters({
        key: "resourceTypes",
        list: [updatedResourceData.type],
      });

      if (this.isResourceInfoFormOpen) {
        this.setResourceData(
          processResourceData({
            ...updatedResourceData,
          })
        );

        await Vue.nextTick();
        EventEmitter.emit(REFRESH_RESOURCE_FORM_DATA, true);
      }
      this.$emit("data:submitted", updates);

      this.loaderToggler(false);

      this.closeDialog();
    },
    async handleResourceAdd() {
      let catId = this.catId?.trim() || "";
      let type = this.type?.trim() || "";
      const updatedTag = this.tag?.trim() || "";

      this.loaderToggler(true);

      const resourceData = createResourceDataForAdd(
        {
          title: this.title,
          catId,
          tag: updatedTag,
          type,
          // showInTree: !this.showAllTasks,
        },
        this.resources,
        this.categories
      );

      await this.addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToAdd: [{ ...resourceData }],
        },
        false
      );

      const actionToStore = RESOURCE_ACTIONS.BATCH_ADD;
      const actionData = [{ ...resourceData, showInTree: true }];
      getUserActions().addResourceAction({
        data: actionData,
        type: actionToStore,
      });
      this.closeDialog();
      await Vue.nextTick();

      this.$emit("data:submitted", resourceData);

      this.loaderToggler(false);

      setTimeout(() => {
        addOrUpdateOrRemoveResourcesInLocalList({
          resourcesToAdd: [{ ...resourceData, showInTree: true }],
        });
      }, 200);
    },
    async focusOnTitle() {
      await this.$nextTick();
      this.$refs.titleEditor.focusOnEl();
    },
    prefillData() {
      const providedExtraData = this.extraData;
      if (!isEmpty(providedExtraData)) {
        if (providedExtraData.type) {
          this.type = providedExtraData.type;
        }
        if (providedExtraData.catId) {
          this.catId = providedExtraData.catId;
        }

        if (providedExtraData.key) {
          this.resourceKey = providedExtraData.key;
        }

        if (this.mode === "parent-change" && providedExtraData.tag) {
          this.tag = providedExtraData.tag;
        }
      }
      if (this.entertedTag) {
        this.tag = this.entertedTag;
      }
    },
    async focusOnCateSelector() {
      await this.$nextTick();

      const inputEl =
        this.$refs.resourceCateSelector?.$el.querySelector("input");
      inputEl?.focus();
    },
  },
  computed: {
    ...mapGetters("resourcesData", {
      categories: "categories",
      resources: "resources",
      rawResourcesMap: "rawResourcesMap",
    }),

    ...mapGetters("resourceInfo", {
      isResourceInfoFormOpen: "isOpen",
      editedResourceData: "data",
    }),
    ...mapGetters("task", {
      showAllTasks: "showClearedTasks",
      isProjectsResourceModeEnabled: "isProjectsResourceModeEnabled",
    }),
    isInAddMode() {
      return this.mode === "add";
    },
    createdOptsList() {
      if (this.type) {
        const opts = createResourceCategoriesOpts(this.categories, this.type);

        const placeHolderVal = this.type === "project" ? "No Area" : "No Group";

        opts.unshift({
          fullLabel: placeHolderVal,
          shortLabel: placeHolderVal,
          order: -1,
          value: "",
        });
        return opts;
      }
      return [];
    },
    selectPlaceholder() {
      return this.type && this.type === "project"
        ? "Select Area"
        : "Select Group";
    },
    resourceTypeOpts() {
      return resourceTypeOptsList;
    },
    showAddGroupBtn() {
      return !isEmpty(this.type);
    },
  },
  watch: {
    isSelectorOpen(n) {
      if (n) {
        this.prefillData();

        if (this.mode === "add") {
          this.focusOnTitle();
        } else if (this.mode === "parent-change") {
          this.focusOnCateSelector();
        }
      }
    },
  },
  components: {
    ResourceTagEditorInput,
    ResourceCateSelector,
    // ItemTypeSelector,
    ResourceTypeSelector,
    RichTextEditor: () => import("../RichTextEditor/RichTextEditor.vue"),
  },
};
</script>
<style scoped>
.selected-resource {
  display: flex;
  align-items: center;
}

.resource-title-editor-wrapper >>> .ql-editor {
  min-height: 34px !important;
  padding: 6px 0px 6px 0px !important;
  line-height: 1.55;
  display: block; /* Change it as per your requirement. */
  overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit; */
}

.resource-title-editor-wrapper >>> .ql-editor.ql-blank::before {
  top: 8px;
  left: 0px;
  font-style: normal;
  color: #a6a6a6;
}

.resource-save-btn {
  transition: all 0.2s ease-out;
}
.resource-save-btn:focus {
  border: 1px solid var(--primary-color);
}
</style>
