<template>
  <div class="datagrid-container" tabindex="-1">
    <div v-if="isListEmpty" class="empty-list-placeholder">
      <DataColumns
        :columnsDefs="columnsDefs"
        :classes="classes"
        :generateColStyles="generateColStyles"
        :generateColClasses="generateColClasses"
        :handleHeaderCellClick="handleHeaderCellClick"
        :generateSortClasses="generateSortClasses"
      />
      <div v-if="newRowAdded">
        <DataRow :item="newRowData" :columns="columnsDefs" :isNew="true" />
      </div>

      <div v-if="placeholderText" class="no-list-text">
        {{ placeholderText }}
      </div>
    </div>
    <div v-show="!isListEmpty" class="scroller-wrapper">
      <DynamicScroller
        :items="finalList"
        :min-item-size="itemSize"
        class="scroller"
        keyField="key"
        v-if="!isListEmpty"
      >
        <template #before>
          <DataColumns
            :columnsDefs="columnsDefs"
            :classes="classes"
            :generateColStyles="generateColStyles"
            :generateColClasses="generateColClasses"
            :handleHeaderCellClick="handleHeaderCellClick"
            :generateSortClasses="generateSortClasses"
          />
          <!-- <div class="datagrid-header">
          <template v-for="(col, index) of columnsDefs">
            <div
              v-if="col.visible || col.visible === undefined"
              :key="index"
              :style="generateColStyles(col)"
              :class="generateColClasses(col, 'header')"
              @click="handleHeaderCellClick($event, col)"
            >
              <template v-if="col.HeaderComponent">
                <component :is="col.HeaderComponent" :key="index" :data="col" />
              </template>
              <span v-else :key="index" :class="classes.headerText">
                {{ col.title || "" }}
              </span>
              <span :class="generateSortClasses(col)"></span>
            </div>
          </template>
        </div> -->

          <div v-if="newRowAdded">
            <DataRow :item="newRowData" :columns="columnsDefs" :isNew="true" />
          </div>
        </template>

        <template v-if="!isListEmpty" #default="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :size-dependencies="[item.title]"
            :data-index="index"
            class="row-wrapper"
          >
            <DataGroup
              v-if="isGroup(item)"
              :item="item"
              :data-row-index="index"
            />
            <DataRow
              v-else
              :item="item"
              :columns="columnsDefs"
              :editing="editingRow === item.key"
              :data-row-index="index"
              :rowIndex="index"
              :aria-selected="selectedRows.includes(item.key)"
              :selected="selectedRows.includes(item.key)"
              :isActive="activeRow === item.key"
            />
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>
<script>
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import dataTableMixin from "./mixins/dataTable";
import DataColumns from "./DataColumns.vue";
export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    DataColumns,
  },
  mixins: [dataTableMixin],
};
</script>
<style lang="scss">
@import "./css/datagrid.scss";
@import "./css/datatable.scss";
</style>
<style>
.vue-recycle-scroller__slot {
  position: sticky;
  top: 0;
  z-index: 1;
}

.datagrid-container {
  outline: none;
}
.datagrid-container .no-list-text {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}
</style>
