<template>
  <v-row align="center" dense>
    <v-col cols="auto"> Snoozed reminders </v-col>
    <v-col>
      <v-chip-group class="reminder-snoozed-date-list">
        <v-chip
          v-for="date in dates"
          :key="date.key"
          close
          small
          @click:close="removeDate(date.val)"
          class="reminder-snoozed-date"
        >
          {{ date.label }}
        </v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
</template>
<script>
import { formatDate, isValidDate } from "@/helpers/dates";
import { parseISO } from "date-fns";
import { DATE_FORMAT_IN_USE } from "@/variables/dates";
import md5 from "blueimp-md5";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    removeDate(date) {
      this.$emit("date:removed", date);
    },
  },
  computed: {
    dates() {
      let validDates = [];
      this.list.forEach((d) => {
        const parsedDate = parseISO(d);
        if (isValidDate(parsedDate)) {
          validDates.push({
            val: d,
            key: md5(d),
            label: formatDate(parsedDate, DATE_FORMAT_IN_USE),
          });
        }
      });

      return validDates;
    },
  },
};
</script>
<style scoped>
.reminder-snoozed-date {
  margin: 0px !important;
}

.reminder-snoozed-date-list >>> .v-slide-group__content {
  padding: 0px;
}
</style>
