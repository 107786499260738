import DatabaseInterface from "@/services/DatabaseInterface";
import { isEmpty } from "lodash";
const patchTodayModeGroupView = async (userId) => {
  DatabaseInterface.get("/" + userId).once("value", async (item) => {
    const dataMap = item.val();
    const updates = {};
    if (dataMap.view[3]?.groupView !== 0) {
      updates["/view/3/groupView"] = 0;
    }

    if (!isEmpty(updates)) {
      await DatabaseInterface.update(updates, userId);
    }
  });
};

export default patchTodayModeGroupView;
