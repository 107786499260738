import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBtnToggle,{attrs:{"id":"toggle-btns-group"}},_vm._l((_vm.list),function(b,index){return _c(VTooltip,{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(b.isVisible()),expression:"b.isVisible()"}],class:_vm.createBtnStyles(b),attrs:{"icon":""},on:{"click":function($event){return _vm.handleClick($event, b)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":b.iconSize}},[_vm._v(" "+_vm._s(b.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(b.label))])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }