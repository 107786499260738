<template>
  <div id="resources-categories-tree-side-bar">
    <v-row
      align="center"
      no-gutters
      justify="center"
      id="resource-type-selector-wrapper"
      v-show="showTypeSelector"
    >
      <v-col cols="auto">
        <ResourceTypeSelector />
      </v-col>
    </v-row>

    <div
      class="pl-3"
      id="resources-categories-tree-wrapper"
      @drop="handleResourceDrop"
      @dragover.prevent
      @mousedown="handleMouseDown"
      @click="handleClick"
      @dblclick="handleNodeDbClick"
    >
      <div v-show="!showAllData">
        <TreeView
          :refSetter="storeTreeRef"
          id="resources-categories-tree"
          :fields="fields"
          cssClass="resourcesTree"
          :nodeTemplate="Template"
          :allowDragAndDrop="true"
          :allowEditing="allowEditing"
          :enablePersistence="true"
          :expandOn="'None'"
          :allowMultiSelection="false"
          :selectedNodes="selectedNodes"
          :loadOnDemand="false"
          @nodeSelecting="handleNodeSelecting"
          @nodeDragStop="dragStop"
          @nodeDragStart="nodeDragStart"
          @nodeDragging="nodeDrag"
          @nodeClicked="nodeclicked"
          @nodeEdited="nodeEdited"
          @nodeSelected="nodeSelected"
          @nodeCollapsed="nodeCollapsed"
          @nodeExpanded="nodeExpanded"
          @dataSourceChanged="treeEdited"
          @keyPress="handleKeyDown"
        />
      </div>
      <div v-show="showAllData">
        <TreeView
          :refSetter="(refEl) => storeTreeRef(refEl, 'allDataTreeview')"
          id="resources-categories-all-data-tree"
          :fields="fields"
          cssClass="resourcesTree"
          :nodeTemplate="Template"
          :allowDragAndDrop="true"
          :allowEditing="allowEditing"
          :enablePersistence="true"
          :expandOn="'None'"
          :allowMultiSelection="false"
          :selectedNodes="selectedNodes"
          :loadOnDemand="false"
          @nodeSelecting="handleNodeSelecting"
          @nodeDragStop="dragStop"
          @nodeDragStart="nodeDragStart"
          @nodeDragging="nodeDrag"
          @nodeClicked="nodeclicked"
          @nodeEdited="nodeEdited"
          @nodeSelected="nodeSelected"
          @nodeCollapsed="nodeCollapsed"
          @nodeExpanded="nodeExpanded"
          @dataSourceChanged="treeEdited"
          @keyPress="handleKeyDown"
        />
      </div>
    </div>

    <ContextMenu
      parentWrapperId="resources-categories-tree-wrapper"
      target="#resources-categories-tree-wrapper"
      :items="menuItems"
      :mainTarget="menuTarget"
      menuId="resource-cat-menu"
      @beforeMenuOpen="handleBeforeMenuOpen"
      @menu-item:selected="handleMenuItemSelection"
    />
  </div>
</template>
<script>
import Vue from "vue";
import ResourceCategoryNode from "./components/ResourceCategoryNode.vue";
import ResourceTypeSelector from "./components/ResourceTypeSelector.vue";
import EventEmitter from "@/helpers/eventEmitter";
import TreeView from "@/components/TreeView";
import ContextMenu from "@/core/components/ContextMenu";
import {
  MENU_OPTS_HIDE_FOR_RESOURCE,
  RESOURCES_ACTIONS_MENU,
  RESOURCES_ACTIONS_MENU_TYPES,
  RESOURCE_CATE_ACTIONS_TO_TOOGLE,
  RESOURCE_CATE_ACTIONS_TO_TOOGLE_IN_PROJECT,
  RESOURCE_ITEM_ACTIONS_TO_SHOW_IN_PROJECT,
} from "./contextMenuOpts";

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  checkIfResourceNodeHasNoProject,
  createCategoryDataForAdd,
  createResourceCategoriesTree,
  createResourceCateUpdateAndEditedData,
  getResourceCategoriesByType,
} from "@/helpers/resourceCategoriesHelpers";
import {
  addOrUpdateOrRemoveResourcesInLocalList,
  checkIfSelectedResourceCateHasCategoryOnly,
  // checkIfSelectedResourceCateHasResourcesOnly,
  checkIfTagIsValid,
  createLabelForResource,
  createResourceDataForAdd,
  createUpdateAndEditedResourceData,
  createUpdateAndEditedResourceDataByCell,
  expandAllParentsOfResource,
  // extractEditedDataFromResourcesUpdate,
  getResourceTreeView,
  getUserResources,
  processResourceData,
  // storeResourceEditAction,
} from "@/helpers/resources";
import hasParentEl, {
  findParentFromPathsByClassName,
} from "@/utils/hasParentEl";

import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import { PRIMARY_COLOR } from "@/variables/colors";
import {
  getTaskCateByRefKey,
  storeCatAddAction,
  storeCatEditAction,
  storeCatRemoveAction,
  storeCatReorderOrMoveAction,
} from "@/helpers/categories";
import {
  convertValueToArray,
  findNodeInTree,
  isNodeChildOrGrandChild,
} from "@/helpers/common";
import {
  ADD_DATA_ITEM_EVENT,
  CONTEXT_MENU_OPENED,
  REFRESH_DATA_LIST_EVENT,
  REFRESH_RESOURCE_FORM_DATA,
  REFRESH_RESOURCE_ID_FILTER,
  REFRESH_TREE_EVENT,
  SET_SELECTED_NODE_EVENT,
  STORE_REMOVED_NODE_ID,
  TOGGLE_RESOURCE_FORM_EVENT,
  TREE_NODE_MOVED_EVENT,
  TREE_NODE_RENAME_EVENT,
} from "@/variables/events";
import cloneDeep from "lodash/cloneDeep";
import { getPathsFromEvent } from "@/utils/events";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import treeViewHelpersMixin from "@/mixins/treeViewHelpersMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { increment } from "@/utils/number";
import { NODE_BROWSE_KEYS, NODE_MOVE_KEYS } from "@/variables/keys";
import { clearAllStoredChildren } from "@/helpers/nodeTreeHelpers";
import isUndefinedVal from "@/utils/isUndefinedVal";
import { getUserActions, RESOURCE_ACTIONS } from "@/helpers/actionHistory";
import {
  createTaskCategorySelectionOptsList,
  filterTasksWithoutProjectResource,
  // isProjectInbox,
} from "@/helpers/tasks";
import { sortOpts } from "@/variables/tasks";
import DatabaseInterface from "@/services/DatabaseInterface";
const orderPositions = ["Before", "After"];
export default {
  mixins: [
    tasksHelpersMixin,
    resourcesHelpersMixin,
    treeViewHelpersMixin,
    categoriesHelpersMixin,
  ],
  components: {
    ContextMenu,
    TreeView,
    ResourceTypeSelector,
  },
  props: {
    showTypeSelector: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAllData: false,
      notesMap: {},
      selectedNodes: [],
      Template() {
        return {
          template: ResourceCategoryNode,
        };
      },
      menuItems: RESOURCES_ACTIONS_MENU,
      fields: [],
      allDataFields: {
        id: "key",
        text: "title",
        child: "projects",
        htmlAttributes: "hasAttribute",
        expanded: "open",
        selected: "isSelected",
        dataSource: [],
      },
      contextType: "",
      allowEditing: false,
    };
  },
  methods: {
    ...mapActions("resourceInfo", [
      "openResourceInfoViewer",
      "setResourceData",
      "closeResourceInfoViewer",
    ]),
    ...mapActions(["updateRootState"]),
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    ...mapActions("resourcesData", [
      "setCatTreeAndChildrenMap",
      "setCateTree",
      "updateResourcesState",
    ]),
    ...mapMutations(["setLoader"]),
    handleDoNotOpenForm(val) {
      this.openForm = val;
    },
    handleNodeDbClick(e) {
      const paths = getPathsFromEvent(e);

      const { found: nodeItemElFound, parentEl: nodeItemEl } =
        findParentFromPathsByClassName(paths, ["e-list-item"], "obj");

      if (nodeItemElFound) {
        if (this.selectNodeFuncId !== undefined) {
          clearTimeout(this.selectNodeFuncId);
          this.selectNodeFuncId = undefined;
        }
      }

      if (hasParentEl(e.target, { className: ["edit-item-btn", "e-icons"] })) {
        return;
      }

      if (nodeItemEl) {
        const targetId = nodeItemEl.getAttribute("data-uid");

        if (targetId !== "root") {
          this.renameResourceCate(targetId);
        }
      }
    },
    handleNodeSelecting() {
      // args
      // const { nodeData } = args;
      // if (isEmpty(nodeData) || isEmpty(this.rawResourcesMap[nodeData.id])) {
      //   args.cancel = true;
      // }
    },
    // toggleNodesInInActiveTree(nodeId, type = "open") {
    //   const treeToUse = getResourceTreeView("inactive").treeInstance;

    //   if (type === "open") {
    //     treeToUse.expandAll([nodeId]);
    //   }

    //   if (type === "close") {
    //     treeToUse.collapseAll([nodeId]);
    //   }
    // },
    async openSelectedResourceInfo(selectedId, expandNode, checkResource) {
      if (this.rawResourcesMap[selectedId]) {
        const processedResourceData = processResourceData({
          ...this.rawResourcesMap[selectedId],
        });

        const currSelectedResourceType = this.selectedResourceTypeOpt;
        if (currSelectedResourceType === processedResourceData.type) {
          let openResource = true;

          if (
            checkResource &&
            !this.showAllTasks &&
            this.resourcesTasksMap[processedResourceData.key] &&
            this.resourcesTasksMap[processedResourceData.key]
              .incompleteTasksCount === 0
          ) {
            openResource = false;
          }

          if (openResource) {
            if (expandNode) {
              expandAllParentsOfResource(processedResourceData);
            }

            if (!this.isResourceFormOpen) {
              this.openResourceInfoViewer({
                data: { ...processedResourceData },
              });
            } else {
              if (
                !isEmpty(this.resourceData) &&
                this.resourceData.key !== processedResourceData.key
              ) {
                this.refreshFormData(processedResourceData);
              }
            }
          }
        }
      }
    },

    enableNoProjectsResourcesTasksFilter(noProjectNodeId) {
      this.closeResourceInfoViewer();

      this.updateResourcesState({
        selectedCategories: [noProjectNodeId],
      });

      const catTreeView = getResourceTreeView("active").treeInstance;

      catTreeView?.ensureVisible(noProjectNodeId);
    },

    async openSelectedCategoryInfo(selectedId, emitEvent, expandNode) {
      if (this.categories[selectedId]) {
        const categoryData = this.categories[selectedId];

        this.closeResourceInfoViewer();
        this.updateResourcesState({
          selectedCategories: [selectedId],
        });
        if (expandNode) {
          const cateData = {
            key: selectedId,
          };

          if (categoryData?.parentCatKey?.trim()) {
            cateData.catId = categoryData?.parentCatKey;
          }
          expandAllParentsOfResource({ ...cateData });
        }
        if (emitEvent) {
          EventEmitter.emit(REFRESH_RESOURCE_ID_FILTER);
        }

        // Add logic to open grid and close form
      }
    },
    async handleKeyDown(args) {
      args.event.stopPropagation();
      await this.$nextTick();
      const { event } = args;
      const keyCode = event.which ? event.which : event.keyCode;
      if (
        NODE_BROWSE_KEYS.includes(keyCode) ||
        NODE_MOVE_KEYS.includes(keyCode)
      ) {
        this.storeNodeActivity = true;
      }

      if (NODE_MOVE_KEYS.includes(keyCode)) {
        const treeView = this.getTreeViewRef();
        const focusedNode = this.$el.querySelector(
          ".e-list-item.e-node-focus.e-hover"
        );

        if (focusedNode) {
          let expandNode = false;
          if (focusedNode.classList.contains("e-has-child")) {
            expandNode = true;
          }
          const nodeId = this.getNodeIdFromNodeEl(focusedNode);

          if (nodeId) {
            await this.handleNodesData([nodeId]);
            treeView.ensureVisible(nodeId);
            await this.$nextTick();
            if (expandNode) {
              treeView.expandAll([nodeId]);
            }
          }
        }
      }
    },
    handleClick(e) {
      const targetEl = e.target;
      if (targetEl && targetEl.matches(".e-icons.interaction")) {
        this.storeNodeActivity = true;
      }
    },
    storeTreeRef(refEl) {
      this.$refs["treeview"] = refEl;
    },
    nodeDragStart() {
      this.dragStarted = true;
    },

    async handleMenuItemSelection(args) {
      await this.$nextTick();
      const selectedMenuItem = args.item;
      const nodeToUse = this.selectedNodeIdToUse;
      switch (selectedMenuItem.id) {
        case RESOURCES_ACTIONS_MENU_TYPES.ADD_CAT:
          this.addResourceCat(nodeToUse);
          break;
        case RESOURCES_ACTIONS_MENU_TYPES.ADD_SUB_CAT:
          this.addResourceSubCat(nodeToUse);
          break;
        case RESOURCES_ACTIONS_MENU_TYPES.ADD_RESOURCE:
          this.addResourceInCategory(nodeToUse);
          break;
        case RESOURCES_ACTIONS_MENU_TYPES.RENAME_RESOURCE_CATE:
          this.renameResourceCate(nodeToUse);

          break;
        case RESOURCES_ACTIONS_MENU_TYPES.REMOVE_RESOURCE_CATE:
          this.deleteResourceCate(nodeToUse);
          break;
      }
    },
    handleMouseDown(e) {
      if (e.button !== 2) return;

      const paths = getPathsFromEvent(e);
      const nodeId = this.getNodeIdFromEventPaths(paths);
      if (nodeId) {
        this.selectedNodeIdToUse = nodeId;
      }
    },
    getNodeIdFromEventPaths(paths) {
      let nodeId;
      for (const path of paths) {
        nodeId = this.getNodeIdFromNodeEl(path);
        if (nodeId) {
          break;
        }
      }
      return nodeId;
    },
    getNodeIdFromNodeEl(nodeEl) {
      let nodeId;

      if (nodeEl && nodeEl.getAttribute && nodeEl.getAttribute("data-uid")) {
        nodeId = nodeEl.getAttribute("data-uid");
      }

      return nodeId;
    },
    handleBeforeMenuOpen(args) {
      const menuEl =
        document.querySelector("#resource-cat-menu").ej2_instances[0];
      let rootNode = false;
      let rootNodeOptsToHide =
        this.selectedResourceTypeOpt === "project"
          ? [...RESOURCE_CATE_ACTIONS_TO_TOOGLE_IN_PROJECT]
          : [...RESOURCE_CATE_ACTIONS_TO_TOOGLE];
      const selectedNode = this.selectedNodeIdToUse;

      if (checkIfResourceNodeHasNoProject(selectedNode)) {
        args.cancel = true;
        return;
      }
      if (selectedNode === "root") {
        rootNode = true;
      }

      if (rootNode) {
        menuEl.hideItems(rootNodeOptsToHide);
      } else {
        menuEl.showItems(rootNodeOptsToHide);
      }

      // if (this.rawResourcesMap[selectedNode]) {
      //   if (this.selectedResourceTypeOpt === "project") {
      //     //
      //   } else {
      //     //
      //   }
      // }

      if (!rootNode) {
        if (this.selectedResourceTypeOpt === "project") {
          menuEl.hideItems(RESOURCE_CATE_ACTIONS_TO_TOOGLE_IN_PROJECT);
        } else {
          menuEl.showItems(RESOURCE_CATE_ACTIONS_TO_TOOGLE_IN_PROJECT);
        }
      }

      const addItemLabelOpt = createLabelForResource(
        this.selectedResourceTypeOpt
      );

      const optsToHide = [...MENU_OPTS_HIDE_FOR_RESOURCE];

      optsToHide.push(`Add ${addItemLabelOpt}`);

      if (this.selectedResourceTypeOpt !== "project") {
        if (!rootNode) {
          if (this.rawResourcesMap[selectedNode]) {
            menuEl.hideItems(optsToHide);
          } else {
            menuEl.showItems(optsToHide);
          }
        } else {
          menuEl.showItems([`Add ${addItemLabelOpt}`, "Add New Group"]);
        }
      } else {
        //
        if (this.rawResourcesMap[selectedNode]) {
          menuEl.hideItems([`Add ${addItemLabelOpt}`]);
          menuEl.showItems(RESOURCE_ITEM_ACTIONS_TO_SHOW_IN_PROJECT);
        } else {
          menuEl.showItems([`Add ${addItemLabelOpt}`]);
        }
      }

      this.openedFromRoot = args.openedFromRoot;
    },
    addResourceCat(targetNodeId) {
      // aadd logic to add category

      // let target = this.mainTarget ? this.mainTarget : this.target;
      const target = "#resources-categories-tree";
      const currTree = this.resourcesCateTree;
      const currParentChildMap = this.cateChildrenMap;
      const allCategories = this.categories;
      let selectedNode = "root";
      let cateName = "New Group";
      if (!this.openedFromRoot) {
        selectedNode = targetNodeId;
      }

      if (selectedNode) {
        let catIdToSet = "";
        let orderIndex = 0;
        let extraData = {};
        if (selectedNode !== "root" && this.categories[selectedNode]) {
          catIdToSet = this.categories[selectedNode].parentCatKey || "";
          orderIndex = this.categories[selectedNode].order;
          extraData = {
            order: increment(orderIndex, 1),
            parentCatKey: catIdToSet,
          };
        } else {
          extraData = {
            order: 0,
            parentCatKey: "",
          };
        }

        extraData.cateType = this.selectedResourceTypeOpt;
        const cateData = createCategoryDataForAdd(
          {
            name: cateName,
            parentCatKey: catIdToSet,
          },
          currParentChildMap,
          currTree,
          allCategories,
          extraData
        );
        const cateDataToSet = { ...cateData };

        if (!catIdToSet) {
          cateDataToSet.order = increment(
            cateDataToSet.order,
            this.isProjectsResourceModeEnabled ? 2 : 1
          );
        }
        this.setSelectedCategoriesNodes([cateDataToSet.key]);

        const cateWithAttrs = this.addAttributesInCate(
          cateDataToSet,
          "resources-tree"
        );
        this.addCategoryInTree(
          {
            ...cateWithAttrs,
            children: [],
          },
          catIdToSet,
          undefined,
          target,
          true
        );
        this.renameEnabledByUser = true;
        this.setCateInSelectedOpts([cateData.key], "resources");

        storeCatAddAction(cateData, "resource-category");
      }
    },
    addResourceSubCat(targetNodeId) {
      if (!targetNodeId) return;
      let target = "#resources-categories-tree";
      const currTree = this.resourcesCateTree;
      const currParentChildMap = this.cateChildrenMap;
      const allCategories = this.categories;
      let parentKey = "";
      let cateName = "New Sub-group";

      if (!this.openedFromRoot) {
        parentKey = targetNodeId && targetNodeId !== "root" ? targetNodeId : "";
      }

      if (parentKey && this.categories[parentKey]) {
        const cateData = createCategoryDataForAdd(
          {
            name: cateName,
            parentCatKey: parentKey,
          },
          currParentChildMap,
          currTree,
          allCategories,
          {
            cateType: this.selectedResourceTypeOpt,
          }
        );

        this.setSelectedCategoriesNodes([cateData.key]);

        const cateDataWithAttrs = this.addAttributesInCate(
          cateData,
          "resources-tree"
        );
        this.addCategoryInTree(
          { ...cateDataWithAttrs, children: [] },
          parentKey,
          undefined,
          target,
          true
        );
        this.renameEnabledByUser = true;
        this.setCateInSelectedOpts([cateData.key], "resources");
        storeCatAddAction(cateData, "resource-category");
      }
    },
    async addResourceInCategory(targetNodeId) {
      this.loaderToggler(true);

      this.loaderEnabled = true;
      let catId = "";
      if (!this.openedFromRoot) {
        catId = targetNodeId && targetNodeId !== "root" ? targetNodeId : "";
      }

      const selectedResourceType = this.selectedResourceTypeOpt || "thing";
      const currCategories = this.categories;
      const resourceData = createResourceDataForAdd(
        {
          title: "",
          catId,
          tag: "New_Resource",
          type: selectedResourceType,
          // showInTree: !this.showAllTasks,
        },
        this.resources,
        currCategories,
        true
      );

      this.enableEditAfterUpdate = true;
      await this.addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToAdd: [resourceData],
        },
        false
      );

      const actionToStore = RESOURCE_ACTIONS.BATCH_ADD;
      const actionData = [{ ...resourceData, showInTree: true }];
      getUserActions().addResourceAction({
        data: actionData,
        type: actionToStore,
      });
      await addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToAdd: [{ ...resourceData, showInTree: true }],
      });
      this.setSelectedCategoriesNodes([resourceData.key]);

      this.setCateInSelectedOpts([resourceData.key], "resources");

      // this.addNewResource({ catId, type: selectedResourceType });
    },
    async deleteResourceCate(selectedCateNode) {
      if (!selectedCateNode) return;
      let target = "#resources-categories-tree";
      const currResources = this.resources;
      const currCategories = this.categories;
      const storedCate = this.categories[selectedCateNode];
      const resourceData = this.rawResourcesMap[selectedCateNode];
      let closeForm = false;
      let clearSelection = false;
      if (!isEmpty(resourceData)) {
        // remove resource data

        // if (
        //   !isEmpty(this.resourceData) &&
        //   this.resourceData.key === resourceData.key
        // ) {
        //   this.$swal({
        //     titleText: "Oops!",
        //     html: "Cannot delete because item is being edited",
        //     icon: "error",
        //     confirmButtonColor: PRIMARY_COLOR,
        //     iconColor: PRIMARY_COLOR,
        //   });

        //   return;
        // }

        const activeTasksExists = this.rawTasks.some((t) => {
          if (this.showAllTasks) {
            return (
              !isEmpty(t.resources) && t.resources.includes(resourceData.key)
            );
          } else {
            return (
              !t.completed &&
              !isEmpty(t.resources) &&
              t.resources.includes(resourceData.key)
            );
          }
        });

        if (activeTasksExists) {
          this.$swal({
            titleText: "Oops!",
            html: "Cannot delete because resource is being used in tasks",
            icon: "error",
            confirmButtonColor: PRIMARY_COLOR,
            iconColor: PRIMARY_COLOR,
          });
          return;
        }

        if (
          !isEmpty(this.selectedCategories) &&
          this.selectedCategories[0] === resourceData.key
        ) {
          clearSelection = true;
        }
        closeForm = true;

        this.removeResourcesFromLocalAndDb([{ ...resourceData }]);
      } else if (!isEmpty(storedCate)) {
        const childCateExists = Object.values(currCategories).some(
          (d) => d.parentCatKey === selectedCateNode
        );
        let resourcesExists = false;
        if (!childCateExists) {
          resourcesExists = currResources.some(
            (n) => n.catId === selectedCateNode
          );
        }

        const itemsExists = childCateExists || resourcesExists;

        if (itemsExists) {
          this.$swal({
            titleText: "Oops!",
            html: "Cannot delete because it contains items.<br/>Please delete them first",
            icon: "error",
            confirmButtonColor: PRIMARY_COLOR,
            iconColor: PRIMARY_COLOR,
          });
          return;
        }
        let dataToRemove = {};
        if (this.categories[storedCate.key]) {
          dataToRemove = { ...this.categories[storedCate.key] };
        }

        if (!isEmpty(dataToRemove)) {
          if (
            !isEmpty(this.selectedCategories) &&
            this.selectedCategories[0] === storedCate.key
          ) {
            clearSelection = true;
          }

          // this.nodeIdToRemove = dataToRemove.key;
          await storeCatRemoveAction(dataToRemove, "resource-category");
          this.removeCategoryFromTree(selectedCateNode, target);
        }
      }

      if (clearSelection) {
        this.clearResourceSelection();
      }

      if (closeForm) {
        this.closeResourceInfoViewer();
      }
    },
    renameResourceCate(targetNodeId) {
      if (!targetNodeId) return;

      this.renameEnabledByUser = true;

      // this.handleNodesData([targetNodeId]);
      this.renameCateItemInTree(targetNodeId, "#resources-categories-tree");
    },
    addNewResource(data) {
      EventEmitter.emit(ADD_DATA_ITEM_EVENT, data);
    },
    refreshAfterNodeReordered() {
      this.nodeReordered = true;
    },
    async refreshTreeAfterEdit() {
      await this.$nextTick();
      this.createAndSetCategoryTree(this.filteredCategories);
      this.$nextTick(() => {
        EventEmitter.emit(REFRESH_DATA_LIST_EVENT);
      });
    },
    setContextMenuNodeId(nodeId) {
      this.selectedNodeIdToUse = nodeId;
    },
    setListeners() {
      EventEmitter.on(TREE_NODE_RENAME_EVENT, this.enableNodeRename);
      EventEmitter.on(TREE_NODE_MOVED_EVENT, this.refreshAfterNodeReordered);
      EventEmitter.on(REFRESH_TREE_EVENT, this.refreshTreeAfterEdit);
      EventEmitter.on(SET_SELECTED_NODE_EVENT, this.setCategories);
      EventEmitter.on(CONTEXT_MENU_OPENED, this.setContextMenuNodeId);
      EventEmitter.on(TOGGLE_RESOURCE_FORM_EVENT, this.handleDoNotOpenForm);
      EventEmitter.on(STORE_REMOVED_NODE_ID, this.storeRemovedNodeIds);
    },
    removeListeners() {
      EventEmitter.off(TREE_NODE_RENAME_EVENT, this.enableNodeRename);
      EventEmitter.off(TREE_NODE_MOVED_EVENT, this.refreshAfterNodeReordered);
      EventEmitter.off(REFRESH_TREE_EVENT, this.refreshTreeAfterEdit);
      EventEmitter.off(SET_SELECTED_NODE_EVENT, this.setCategories);
      EventEmitter.off(CONTEXT_MENU_OPENED, this.setContextMenuNodeId);
      EventEmitter.off(TOGGLE_RESOURCE_FORM_EVENT, this.handleDoNotOpenForm);
      EventEmitter.off(STORE_REMOVED_NODE_ID, this.storeRemovedNodeIds);
    },
    storeRemovedNodeIds(nodeIds = []) {
      this.nodeIdToRemove = convertValueToArray(nodeIds);
    },
    handleResourceDrop(event) {
      const isDisabled = true;

      if (isDisabled) return;

      let targetCatId = "";
      const paths = getPathsFromEvent(event);
      const resourcesList = JSON.parse(event.dataTransfer.getData("text"));
      const updatesList = [];
      for (const path of paths) {
        if (
          path &&
          path.getAttribute &&
          path.getAttribute("data-resrc-cat-id")
        ) {
          targetCatId = path.getAttribute("data-resrc-cat-id");
          break;
        }
      }

      if (targetCatId) {
        const catIdToUse = targetCatId !== "root" ? targetCatId : "";
        if (resourcesList && resourcesList.length) {
          resourcesList.forEach((n) => {
            const { updates, editedData } =
              createUpdateAndEditedResourceDataByCell({
                fieldName: "catId",
                currValue: catIdToUse,
                rowId: n.key,
                oldValue: n.catId,
                currRowData: n,
              });
            updatesList.push({ updates, editedData, key: n.key });
          });

          this.addOrRemoveOrUpdateResourcesInList({
            resourcesToUpdate: updatesList,
          });
          addOrUpdateOrRemoveResourcesInLocalList({
            resourcesToUpdate: updatesList,
          });
        }
      }
    },
    enableNodeRename(enableEdit, disableAfterEdit) {
      this.allowEditing = enableEdit;
      // this.renameEnabledByUser = true;
      this.disableEditAfterUpdate = disableAfterEdit;
    },
    handleNodeMove(args) {
      const { draggedNodeData, droppedNode, position } = args;
      let { dropIndex } = args;

      let itemType = "category";
      // const currCategories = cloneDeep(this.categories);
      // const currProjects = cloneDeep(this.projects);
      let draggedItemData = {};
      // let childrenToUpdate = [];

      if (this.rawResourcesMap[draggedNodeData.id]) {
        itemType = "resource";
        draggedItemData = { ...this.rawResourcesMap[draggedNodeData.id] };
      } else if (this.categories[draggedNodeData.id]) {
        draggedItemData = { ...this.categories[draggedNodeData.id] };
      }
      let updatedItem = {};
      let currItemData = {};

      // let parentPropToUse = itemType === "resource" ? "catId" : "parentCatKey";

      if (!isEmpty(draggedItemData)) {
        let targetId = "";
        let targetItemData = {};
        currItemData = cloneDeep(draggedItemData);
        updatedItem = {
          ...draggedItemData,
          order: typeof dropIndex === "number" ? dropIndex : 0,
        };
        if (droppedNode) {
          targetId = droppedNode.getAttribute("data-uid");
        }
        if (
          targetId &&
          (!isEmpty(this.categories[targetId]) ||
            !isEmpty(this.rawResourcesMap[targetId]))
        ) {
          const targetItemRes = findNodeInTree(
            this.resourcesCateTree,
            (node) => node.key === targetId
          );
          targetItemData = targetItemRes?.node;
          if (position && position === "Inside") {
            const parentProp =
              itemType === "resource" ? "catId" : "parentCatKey";
            if (!isEmpty(targetItemData)) {
              draggedItemData = {
                ...draggedItemData,
                [parentProp]:
                  targetItemData.key !== "root" ? targetItemData.key : "",
              };
              let itemChildren = targetItemData.children;
              if (itemChildren && itemChildren.length) {
                updatedItem = {
                  ...draggedItemData,
                  order: itemChildren.length,
                };
              } else {
                updatedItem = { ...draggedItemData, order: 0 };
              }
            }
          }

          if (orderPositions.indexOf(position) >= 0) {
            if (!isEmpty(targetItemData)) {
              let parentPropToUse = !isEmpty(
                this.rawResourcesMap[targetItemData.key]
              )
                ? "catId"
                : "parentCatKey";

              const parentItemProp =
                itemType === "resource" ? "catId" : "parentCatKey";
              const draggedCateParentKey = targetItemData[parentPropToUse];
              // let parentChildren = [];

              if (typeof dropIndex === "number") {
                draggedItemData = {
                  ...draggedItemData,
                  order: dropIndex,
                  [parentItemProp]: draggedCateParentKey || "",
                };

                updatedItem = { ...draggedItemData, order: dropIndex };
              }
            }
          }
          // Sub drop
        } else {
          const parentPropToUse =
            itemType === "resource" ? "catId" : "parentCatKey";
          draggedItemData = {
            ...draggedItemData,
            order: dropIndex,
            [parentPropToUse]: "",
          };

          updatedItem = { ...draggedItemData, order: dropIndex };

          // Root drop
        }
      }

      if (itemType === "resource") {
        const currResourceData = this.rawResourcesMap[draggedNodeData.id];
        const rowId = currResourceData.key;
        const { editedData } = createUpdateAndEditedResourceData(
          { order: updatedItem.order, catId: updatedItem.catId },
          currResourceData
        );

        // this.addOrRemoveOrUpdateResourcesInList({
        //   resourcesToUpdate: [{ updates, editedData, key: rowId }],
        // });

        // const actionData = extractEditedDataFromResourcesUpdate([
        //   { updates, editedData, key: rowId },
        // ]);
        this.resourceMoved = true;

        getUserActions().addResourceAction({
          data: {
            ...editedData,
            key: rowId,
          },
          type: RESOURCE_ACTIONS.MOVED_OR_REORDERED,
        });
        // storeResourceEditAction(
        //   actionData,
        //   RESOURCE_ACTIONS.MOVED_OR_REORDERED
        // );
        // addOrUpdateOrRemoveResourcesInLocalList({
        //   resourcesToUpdate: [
        //     {
        //       key: currResourceData.key,
        //       updates,
        //     },
        //   ],
        // });
      } else if (itemType === "category") {
        const { editedData } = createResourceCateUpdateAndEditedData(
          {
            order: updatedItem.order,
            parentCatKey: updatedItem.parentCatKey || "",
          },
          this.categories[currItemData.key]
        );

        storeCatReorderOrMoveAction(editedData, "resource-category");
      }
      this.nodeReordered = itemType === "category";
    },
    filterResourceCategories() {
      const selectedResourceType = this.selectedResourceTypeOpt;
      const categoriesToUse = this.getFilteredCategories(
        this.categories,
        selectedResourceType
      );
      this.filteredCategories = categoriesToUse;
    },

    getFilteredCategories(allCategoriesMap, type) {
      const categoriesToUse = getResourceCategoriesByType(
        allCategoriesMap,
        type
      );

      return categoriesToUse;
    },
    checkCategoriesForRecreation(newVal, oldVal) {
      const newFilteredCategories = this.getFilteredCategories(
        newVal,
        this.selectedResourceTypeOpt
      );
      const oldFilteredCategories = this.getFilteredCategories(
        oldVal,
        this.selectedResourceTypeOpt
      );

      let recreateTree = false;
      if (newFilteredCategories.length !== oldFilteredCategories.length) {
        recreateTree = true;
      } else {
        recreateTree = newFilteredCategories.some((newCateData) => {
          const oldCateData = oldVal[newCateData.key];

          if (!isEmpty(oldCateData)) {
            return (
              newCateData.parentCatKey !== oldCateData.parentCatKey ||
              newCateData.cateType !== oldCateData.cateType ||
              newCateData.name !== oldCateData.name ||
              newCateData.order !== oldCateData.order
            );
          }
        });
      }

      if (recreateTree) {
        this.filteredCategories = newFilteredCategories;

        this.createAndSetCategoryTree(
          this.filteredCategories,
          false,
          undefined,
          undefined,
          undefined,
          true
        );
      }
    },
    async init() {
      this.setListeners();

      // console.debug("THIS", this.selectedCategories);

      this.checkAndToggleOpenForm();

      this.setSelectedCategoriesNodes(this.selectedCategories);
      this.filterResourceCategories();
      this.updateMenuItems();
      await this.$nextTick();

      if (
        !isEmpty(this.rawTasks) &&
        this.checkToOpenResourceInfo() &&
        (checkIfSelectedResourceCateHasCategoryOnly(this.selectedCategories) ||
          (!isEmpty(this.selectedCategories) &&
            checkIfResourceNodeHasNoProject(this.selectedCategories[0])))
      ) {
        this.openCategory = true;
      }

      this.keepNodesClosed = isEmpty(this.selectedCategories);

      if (this.keepResourceNodesClosed) {
        this.updateRootState({
          keepResourceNodesClosed: false,
        });
      }
      this.createAndSetCategoryTree(
        this.filteredCategories,
        undefined,
        undefined,
        true,
        undefined,
        true
      );
    },
    checkAndToggleOpenForm() {
      this.openForm = this.checkToOpenResourceInfo();
    },

    checkToOpenResourceInfo() {
      const { linkedItemData, returnInfo } = this.$route.params;

      return isEmpty(linkedItemData) && isEmpty(returnInfo);
    },
    dragStop(args) {
      // this.loaderToggler(true);

      // this.loaderEnabled = true;
      const { position } = args;
      if (this.dragStarted) {
        this.dragStarted = false;
      }
      const { draggedNodeData, droppedNode } = args;

      const draggedNodeDataId = draggedNodeData.id;

      if (
        this.selectedResourceTypeOpt === "project" &&
        checkIfResourceNodeHasNoProject(draggedNodeDataId)
      ) {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }

      if (
        (this.selectedResourceTypeOpt === "project" &&
          !isEmpty(this.categories[draggedNodeDataId])) ||
        this.isSearchQueryValid
      ) {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }
      if (draggedNodeDataId === "root") {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }

      if (droppedNode) {
        const targetId = droppedNode.getAttribute("data-uid");

        if (!position) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }
        if (checkIfResourceNodeHasNoProject(targetId)) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }
        if (
          position === "Inside" &&
          targetId === "root" &&
          isEmpty(this.rawResourcesMap[draggedNodeData.id])
        ) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }

        if (
          targetId === "root" &&
          !isEmpty(this.rawResourcesMap[draggedNodeData.id]) &&
          position !== "Inside"
        ) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }
        if (
          position === "Inside" &&
          (targetId === draggedNodeData.id ||
            !isEmpty(this.rawResourcesMap[targetId]) ||
            isNodeChildOrGrandChild(
              targetId,
              draggedNodeData.id,
              this.categories,
              "parentCatKey"
            ))
        ) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }

        // if (
        //   position &&
        //   position !== "Inside" &&
        //   !isEmpty(this.rawResourcesMap[draggedNodeData.id]) &&
        //   !isEmpty(this.categories[targetId])
        // ) {
        //   args.dropIndicator = "e-no-drop";
        //   args.cancel = true;
        //   return;
        // }
      }

      // console.log("MOVED", args);
      this.handleNodeMove(args);
    },
    nodeDrag(args) {
      const { position } = args;
      this.scrollListForDrag({
        draggedItemEl: args.clonedNode,
        listWrappEl: getResourceTreeView("active").element,
        parentEl: this.parentWrapperEl,
      });

      const { draggedNodeData, droppedNode, draggedNode } = args;
      const draggedNodeId = draggedNode.getAttribute("data-uid");

      if (
        this.selectedResourceTypeOpt === "project" &&
        checkIfResourceNodeHasNoProject(draggedNodeId)
      ) {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }
      if (
        (this.selectedResourceTypeOpt === "project" &&
          !isEmpty(this.categories[draggedNodeId])) ||
        this.isSearchQueryValid
      ) {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }

      if (draggedNodeId === "root") {
        args.dropIndicator = "e-no-drop";
        args.cancel = true;
        return;
      }

      if (droppedNode) {
        const targetId = droppedNode.getAttribute("data-uid");

        if (!position) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }

        if (
          position === "Inside" &&
          targetId === "root" &&
          !isEmpty(this.categories[draggedNodeData.id])
        ) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }

        if (
          targetId === "root" &&
          !isEmpty(this.rawResourcesMap[draggedNodeData.id]) &&
          position !== "Inside"
        ) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }
        if (
          position === "Inside" &&
          (targetId === draggedNodeData.id ||
            !isEmpty(this.rawResourcesMap[targetId]) ||
            isNodeChildOrGrandChild(
              targetId,
              draggedNodeData.id,
              this.categories,
              "parentCatKey"
            ))
        ) {
          args.dropIndicator = "e-no-drop";
          args.cancel = true;
          return;
        }
        // if (
        //   this.isDragPositionNullOrInside(position) &&
        //   (targetId === "root" ||
        //     targetId === draggedNodeData.id ||
        //     !isEmpty(this.rawResourcesMap[targetId]) ||
        //     isNodeChildOrGrandChild(
        //       targetId,
        //       draggedNodeData.id,
        //       this.categories,
        //       "parentCatKey"
        //     ))
        // ) {
        //   console.log("TA", targetId, position);
        //   args.dropIndicator = "e-no-drop";
        //   args.cancel = true;
        //   return;
        // }
      }
    },
    isDragPositionNullOrInside(position) {
      return position === null || position === "Inside";
    },
    nodeclicked(args) {
      const event = args.event;
      if (hasParentEl(event.target, { className: ["edit-item-btn"] })) {
        return;
      }

      if (event.detail === 1) {
        const catTree = getResourceTreeView("active").treeInstance;

        this.selectNodeFuncId = setTimeout(() => {
          if (catTree) {
            const selectedIDs = catTree.selectedNodes;

            this.handleNodesData(selectedIDs);
          }
        }, 200);
      }
    },
    setNodeAndGetData(selectedIds) {
      selectedIds = convertValueToArray(selectedIds);
      this.handleNodesData(selectedIds);
    },
    async nodeEdited(args) {
      const nodeId = args.nodeData.id;
      let { newText, oldText } = args;
      newText = newText && newText.trim();

      let updateText = false;
      if (newText !== oldText) {
        let originalNodeData = {};
        let originalResourceData = {};

        if (this.rawResourcesMap[nodeId]) {
          originalResourceData = { ...this.rawResourcesMap[nodeId] };
        } else if (this.categories[nodeId]) {
          originalNodeData = this.categories[nodeId];
        }
        if (this.renameEnabledByUser) {
          if (!newText) {
            args.cancel = true;

            this.$swal({
              titleText: "Oops!",
              html: "Name cannot be empty",
              icon: "error",
              confirmButtonColor: PRIMARY_COLOR,
              iconColor: PRIMARY_COLOR,
            });

            return;
          }

          if (!isEmpty(originalResourceData)) {
            const isTagValidRes = checkIfTagIsValid(
              newText,
              undefined,
              originalResourceData.type
            );
            if (!isTagValidRes.isValid) {
              args.cancel = true;
              this.showAlertForTagNotValid(isTagValidRes);
              return;
            }

            const rowId = originalResourceData.key;
            const { updates, editedData } =
              createUpdateAndEditedResourceDataByCell({
                fieldName: "tag",
                oldValue: originalResourceData.tag,
                rowId,
                currValue: newText,
                currRowData: originalResourceData,
              });

            this.doNotUpdateTree = true;

            this.loaderEnabled = true;
            this.loaderToggler(true);
            await this.addOrRemoveOrUpdateResourcesInList({
              resourcesToUpdate: [{ updates, editedData, key: rowId }],
            });
            await addOrUpdateOrRemoveResourcesInLocalList({
              resourcesToUpdate: [{ key: rowId, updates }],
            });

            if (this.isResourceFormOpen && this.resourceData.key === rowId) {
              this.refreshForm = true;
              this.refreshedResourceData = {
                ...originalResourceData,
                ...updates,
              };
            }

            // Add resource rename logic
          } else if (!isEmpty(originalNodeData)) {
            let children = [];

            if (originalNodeData.parentCatKey) {
              const parentNodeRes = findNodeInTree(
                this.resourcesCateTree,
                (node) => node.key === originalNodeData.parentCatKey,
                "children"
              );

              const parent = parentNodeRes?.node;
              if (!isEmpty(parent)) {
                children = parent.children;
              }
            } else {
              children = this.resourcesCateTree;
            }

            const sameItem = children.find((ch) => ch.name.trim() === newText);
            if (!isEmpty(sameItem)) {
              args.cancel = true;

              this.$swal({
                titleText: "Oops!",
                html: "Cannot rename because an item with same name already exists!",
                icon: "error",
                confirmButtonColor: PRIMARY_COLOR,
                iconColor: PRIMARY_COLOR,
              });
              return;
            }
            this.loaderToggler(true);
            this.cateRenamed = true;

            updateText = true;
            const { editedData } = createResourceCateUpdateAndEditedData(
              {
                name: newText,
              },
              originalNodeData
            );

            storeCatEditAction(
              {
                ...editedData,
                key: originalNodeData.key,
              },
              "resource-category"
            );
          }
        } else if (this.storeAddAfterRename) {
          storeCatAddAction(
            { ...originalNodeData, name: newText },
            "resource-category"
          );
        } else {
          updateText = true;
        }

        if (updateText) {
          this.nodeIdToRename = nodeId;
          this.nodeTitleUpdate = newText;
        }
      }
    },
    nodeSelected() {},
    nodeCollapsed(args) {
      const nodeId = args.nodeData.id;

      if (nodeId && this.categories[nodeId]) {
        this.updateMultiCate(
          [{ updates: { open: false }, editedData: {}, key: nodeId }],
          false
        );

        // this.toggleNodesInInActiveTree(nodeId, "close");
      }
    },
    nodeExpanded(args) {
      const nodeId = args.nodeData.id;

      if (nodeId && this.categories[nodeId]) {
        this.updateMultiCate(
          [{ updates: { open: true }, editedData: {}, key: nodeId }],
          false
        );

        // this.toggleNodesInInActiveTree(nodeId, "open");
      }
    },
    setCategories(selectedCateIds) {
      this.setSelectedCategoriesNodes(selectedCateIds);
    },
    enableEditOfResource() {
      const resourceId = this.selectedNodes[0];

      if (this.rawResourcesMap[resourceId]) {
        this.enableEditAfterUpdate = false;
        this.renameEnabledByUser = true;
        this.setLoader(false);

        // this.openSelectedResourceInfo(resourceId);
        expandAllParentsOfResource({ ...this.rawResourcesMap[resourceId] });

        setTimeout(() => {
          this.renameCateItemInTree(
            this.selectedNodes[0],
            "#resources-categories-tree"
          );
        }, 250);
      }
    },

    calcuCountOfTasksWithoutProjectResources(resourceCateData) {
      if (
        resourceCateData.refKey &&
        this.taskAreasMap[resourceCateData.refKey] &&
        this.taskAreasMap[resourceCateData.refKey].type.split(" ")[0] ===
          "project"
      ) {
        let filteredTaskList = filterTasksWithoutProjectResource({
          tasks: this.tasks,
          areaId: resourceCateData.refKey,
          doNotCountAllTasks: !this.showAllTasks,
        });

        filteredTaskList = filteredTaskList.filter((t) => !t.isReminderTask);

        let sortBy = cloneDeep(sortOpts[9]);

        filteredTaskList = this.processTasksOfNextActionsMode(
          filteredTaskList,
          sortBy,
          true,
          true
        );

        // treeNode.allTasksList.push(...child.allTasksList);
        // treeNode.incompleteTasksList.push(...child.incompleteTasksList);
        // treeNode.totalAllTasksCount += child?.totalAllTasksCount || 0;
        // treeNode.totalIncompleteTasksCount +=
        //   child?.totalIncompleteTasksCount || 0;

        if (filteredTaskList.length) {
          if (this.showAllTasks) {
            resourceCateData.allTasksList.push(...filteredTaskList);
            resourceCateData.totalAllTasksCount += filteredTaskList.length;
          } else {
            resourceCateData.incompleteTasksList.push(...filteredTaskList);
            resourceCateData.totalIncompleteTasksCount +=
              filteredTaskList.length;
          }
          const nodeKey = `${resourceCateData.refKey}_no-project`;
          resourceCateData.children.unshift({
            key: nodeKey,
            name: "No Project",
            children: [],
            totalCount: filteredTaskList.length,
            order: -1,
            open: false,
            isNoProjectNode: true,
            isSelected: this.selectedNodes.includes(nodeKey),
          });
        }
      }

      return resourceCateData;

      // return filteredTaskList?.length || 0;
    },
    async setSelectedCategoriesNodes(selectedCategories) {
      this.selectedNodes = selectedCategories;

      //Disabled this logic in favour of setting categories also
      // this.selectedNodes = selectedCategories.filter(
      //   (i) => !isEmpty(this.rawResourcesMap[i])
      // );
    },
    async createAndSetCategoryTree(
      categoriesToUse,
      updateFields = true,
      clearNodeChildren = true,
      openResourceInfo,
      stopLoader
      // makeSelectionVisible
    ) {
      const allResourceCateData = this.categories;
      const {
        cateChildrenMap,
        resourcesTasksMap,
        // visbleDataSource,
        // visibleCateChildrenMap,
        ...fields
      } = createResourceCategoriesTree({
        categories: !isEmpty(categoriesToUse) ? categoriesToUse : [],
        resources: this.resources,
        selectedNodes: this.selectedNodes,
        resourceType: this.selectedResourceTypeOpt,
        allResourceCateData,
        searchQuery: this.searchQuery,
        taskList: this.rawTasks,
        doNotCountAllTasks:
          !this.showAllTasks && this.selectedResourceTypeOpt === "project",
        sortByOrder: this.isProjectsResourceModeEnabled,
        addNoProjectTreeNode: this.isProjectsResourceModeEnabled,

        calcNoProjectTasksOfGroupFunc:
          this.calcuCountOfTasksWithoutProjectResources,
      });

      if (this.isProjectsResourceModeEnabled) {
        // const tasksCount = this.calcuCountOfTasksWithoutProjectResources();
        // if (!tasksCount) {
        //   fields.dataSource.splice(0, 1);
        // } else {
        //   fields.dataSource[0].totalCount = tasksCount;
        // }
        // const totalTasksWithoutProjectResource = this.
      }
      const tree = getResourceTreeView().treeInstance;
      // let treeToSave = visbleDataSource;
      // let childrenMapToSave = visibleCateChildrenMap;

      // if (this.showAllTasks) {
      let treeToSave = [...fields.dataSource];
      let childrenMapToSave = { ...cateChildrenMap };

      // }
      this.setCatTreeAndChildrenMap({
        tree: treeToSave,
        childrenMap: childrenMapToSave,
        resourcesTasksMap: Object.freeze(resourcesTasksMap),
      });

      if (clearNodeChildren) {
        clearAllStoredChildren();
      }

      await Vue.nextTick();

      if (updateFields) {
        await this.$nextTick();
        this.fields = {
          ...fields,
          // dataSource: [...visbleDataSource],
        };

        this.allDataFields = {
          ...fields,
          dataSource: [...fields.dataSource],
        };
      }

      setTimeout(() => {
        if (tree) {
          tree.dataType = 2;
        }

        if (stopLoader || this.loaderEnabled) {
          if (this.loaderEnabled) this.loaderEnabled = false;
          this.loaderToggler(false);
        }

        if (!this.keepNodesClosed) {
          tree?.ensureVisible(this.selectedNodes[0]);
        } else {
          tree?.collapseAll?.();
          this.keepNodesClosed = false;
        }
        // if (makeSelectionVisible) {

        // }
        if (this.enableEditAfterUpdate) {
          this.enableEditOfResource();
        }

        if (this.refreshForm && !isEmpty(this.refreshedResourceData)) {
          const refreshedResourceData = { ...this.refreshedResourceData };
          this.refreshForm = false;
          this.refreshedResourceData = undefined;

          this.refreshFormData(refreshedResourceData, true);
        }
        if (openResourceInfo) {
          const selectedIDs = this.selectedNodes;

          if (this.openForm) {
            if (checkIfResourceNodeHasNoProject(selectedIDs[0])) {
              this.enableNoProjectsResourcesTasksFilter(selectedIDs[0]);
            } else if (this.rawResourcesMap[selectedIDs[0]]) {
              this.openSelectedResourceInfo(selectedIDs[0], true, true);
            } else if (this.categories[selectedIDs[0]]) {
              this.openSelectedCategoryInfo(selectedIDs[0], undefined, true);
            }
          }

          this.firstRunCompleted = true;
          // if (this.rawResourcesMap[selectedIDs[0]] && this.openForm) {
          //   this.openSelectedResourceInfo(selectedIDs[0]);
          // }
        }

        if (
          this.openCategory &&
          this.openForm &&
          isEmpty(this.rawResourcesMap[this.selectedNodes[0]]) &&
          this.categories[this.selectedNodes[0]]
        ) {
          this.openCategory = false;
          this.openSelectedCategoryInfo(this.selectedNodes[0], true);
        }
      }, 0);
    },
    handleNodesData(selectedIds) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (isEmpty(selectedIds)) return;
          let isNoProjectsNode = false;
          // let openForm = false;
          selectedIds = convertValueToArray(selectedIds);
          // Disabled this to allow handling of resource category
          // if (this.rawResourcesMap[selectedIds[0]]) {
          //   openForm = true;
          // }
          // else {
          //   selectedIds = [];
          // }

          if (!isEqual(this.selectedCategories, selectedIds)) {
            if (
              !isEmpty(selectedIds) &&
              selectedIds.length === 1 &&
              checkIfResourceNodeHasNoProject(selectedIds[0])
              //  === "no-project"
            ) {
              isNoProjectsNode = true;
            }

            const currType = this.selectedResourceTypeOpt;

            const dataToSet = {
              mode: currType,
              selection: selectedIds,
            };

            getUserResources().set(dataToSet, "selectedCategories");

            if (this.isProjectsResourceModeEnabled && !isNoProjectsNode) {
              let selectedCategories = [];

              if (!isEmpty(selectedIds)) {
                selectedIds.forEach((selectedId) => {
                  let cateData = {};
                  let resourceCateToUse = {};
                  if (this.categories[selectedId]) {
                    resourceCateToUse = this.categories[selectedId];
                  } else if (
                    this.rawResourcesMap[selectedId] &&
                    this.rawResourcesMap[selectedId].catId !== "root" &&
                    this.categories[this.rawResourcesMap[selectedId].catId]
                  ) {
                    resourceCateToUse =
                      this.categories[this.rawResourcesMap[selectedId].catId];
                  }

                  if (!isEmpty(resourceCateToUse)) {
                    cateData = getTaskCateByRefKey(resourceCateToUse.refKey);
                  }

                  if (!isEmpty(cateData)) {
                    selectedCategories.push(cateData.key);
                  }
                });

                selectedCategories =
                  createTaskCategorySelectionOptsList(selectedCategories);
              }
              const dbUpdates = {
                [`/view/1/selectedOptsList`]: selectedCategories,
              };

              DatabaseInterface.update(dbUpdates, this.userInfo.uid);
            }
          }

          this.updateRootState({
            searchVal: "",
            currViewData: {},
            itemInspectEnabled: false,
          });

          if (isNoProjectsNode) {
            this.enableNoProjectsResourcesTasksFilter(selectedIds[0]);
          } else if (this.rawResourcesMap[selectedIds[0]]) {
            this.openSelectedResourceInfo(selectedIds[0]);
          } else if (this.categories[selectedIds[0]]) {
            this.openSelectedCategoryInfo(selectedIds[0]);
          }

          resolve();
        }, 0);
      });
    },
    async treeEdited(event) {
      this.setLoader(true);
      let rootData = {};
      const keysToRemove = ["root", "no-project"];

      keysToRemove.forEach((k) => {
        const keyIndex = event.data.findIndex((node) => node.key === k);

        if (keyIndex >= 0) {
          const keyData = event.data.splice(keyIndex, 1)[0];
          if (k === "root") {
            rootData = { ...keyData };
          }
        }
      });

      let processTree = !this.doNotUpdateTree;

      let reprocessList = false;
      let updateResources = false;
      let localResourceListToUpdate = [];
      let editFormResourceData = {};
      // eslint-disable-next-line no-unused-vars
      let updateNodeName = "";
      // let updateCateTree = true;
      let rebuildTree =
        this.nodeReordered || this.resourceMoved || this.cateRenamed;
      if (this.renameEnabledByUser) {
        this.renameEnabledByUser = false;
      }
      if (this.nodeReordered) {
        this.nodeReordered = false;

        // this.loaderEnabled = true;
        // this.doNotRebuildTree = true;
        // rebuildTree = true;
        // reprocessList = true;
      }

      if (this.cateRenamed) {
        this.cateRenamed = false;
      }

      if (rebuildTree) {
        reprocessList = true;
        this.loaderEnabled = true;
        this.doNotRebuildTree = true;
      }

      if (processTree) {
        if (this.nodeIdToRename) {
          updateNodeName = this.nodeIdToRename;
          this.nodeIdToRename = false;
        }

        let reqRes;
        let { categories: data, resources: updatedResources } =
          this.flattenUpdatedTreeData(event.data);
        let rawResourcesMap = JSON.parse(JSON.stringify(this.rawResourcesMap));
        delete data.root;
        if (
          this.selectedResourceTypeOpt &&
          this.selectedResourceTypeOpt !== "all"
        ) {
          let dataToUse = JSON.parse(JSON.stringify(this.categories));

          dataToUse["root"] = {
            ...dataToUse["root"],
            open: rootData.open || false,
          };
          dataToUse = { ...dataToUse, ...data };
          if (!isEmpty(this.nodeIdToRemove)) {
            const idsToRemove = !Array.isArray(this.nodeIdToRemove)
              ? convertValueToArray(this.nodeIdToRemove)
              : this.nodeIdToRemove;

            idsToRemove.forEach((id) => {
              delete dataToUse[id];
            });
            this.nodeIdToRemove = undefined;
          }
          data = { ...dataToUse };

          if (this.resourceMoved && !isEmpty(updatedResources)) {
            this.resourceMoved = false;
            updateResources = true;
            Object.keys(updatedResources).forEach((rK) => {
              if (rawResourcesMap[rK]) {
                rawResourcesMap[rK] = {
                  ...rawResourcesMap[rK],
                  ...updatedResources[rK],
                };

                localResourceListToUpdate.push({
                  key: rK,
                  updates: {
                    ...updatedResources[rK],
                  },
                });

                if (
                  this.isResourceFormOpen &&
                  this.resourceData.key === rK &&
                  isEmpty(editFormResourceData)
                ) {
                  editFormResourceData = {
                    ...rawResourcesMap[rK],
                    ...updatedResources[rK],
                  };
                }
              }
            });
          }
        }

        if (updateResources) {
          reqRes = getUserResources().setMultiFields([
            {
              key: "categories",
              value: { ...data },
            },
            {
              key: "resources",
              value: { ...rawResourcesMap },
            },
          ]);
        } else {
          reqRes = getUserResources().set(data, "categories");
        }

        await reqRes;
      } else {
        if (this.doNotUpdateTree) {
          this.doNotUpdateTree = false;
        }
      }

      if (!isEmpty(localResourceListToUpdate)) {
        addOrUpdateOrRemoveResourcesInLocalList({
          resourcesToUpdate: localResourceListToUpdate,
        });
      }

      await this.$nextTick();

      if (rebuildTree) {
        this.filterResourceCategories();
        this.createAndSetCategoryTree(
          this.filteredCategories,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        );
      } else {
        this.setCateTree(event.data);
      }

      if (this.disableEditAfterUpdate) {
        this.allowEditing = false;
        this.disableEditAfterUpdate = false;
      }

      // this.renameEnabledByUser = true;

      this.setLoader(false);
      // if (updateCateTree) {
      // this.setCateTree(event.data);
      // }

      if (!isEmpty(editFormResourceData)) {
        this.refreshFormData(editFormResourceData, true);
      }

      EventEmitter.emit(REFRESH_DATA_LIST_EVENT, reprocessList);
      // if (updateNodeName) {
      //   this.updateTextInInActiveTree(updateNodeName, this.nodeTitleUpdate);
      // }
    },

    // updateTextInInActiveTree(nodeId, nextText) {
    //   let treeDataToUse, treeToUpdate;

    //   if (this.showAllTasks) {
    //     treeDataToUse = this.fields.dataSource;
    //     treeToUpdate = "visible";
    //   } else {
    //     treeDataToUse = this.allDataFields.dataSource;
    //     treeToUpdate = "all";
    //   }

    //   treeDataToUse = cloneDeep(treeDataToUse);
    //   const nodeData = findNodeInTree(
    //     treeDataToUse,
    //     (node) => node.key === nodeId
    //   );

    //   if (!isEmpty(nodeData)) {
    //     nodeData.name = nextText;
    //   }

    //   if (treeToUpdate === "all") {
    //     this.allDataFields = {
    //       ...this.allDataFields,
    //       dataSource: treeDataToUse,
    //     };
    //   } else if (treeToUpdate === "visible") {
    //     this.fields = {
    //       ...this.fields,
    //       dataSource: treeDataToUse,
    //     };
    //   }
    // },
    async refreshFormData(updatedResourceData, process) {
      const resourceDataToSet = process
        ? processResourceData({ ...updatedResourceData })
        : updatedResourceData;
      this.setResourceData({
        ...resourceDataToSet,
      });

      await Vue.nextTick();
      EventEmitter.emit(REFRESH_RESOURCE_FORM_DATA);
    },
    flattenUpdatedTreeData(treeData) {
      let updatedCateData = {};
      let updatedResourcesMap = {};
      const treeview = this.getTreeViewRef();
      //get all category data
      for (let i = 0; i < treeData.length; i++) {
        const nodeData = treeview.getTreeData(treeData[i].key)[0];
        updatedCateData[treeData[i].key] = {
          order: i,
          name: treeData[i].name,
          key: treeData[i].key,
          open: nodeData?.open || false,
          modified: treeData[i].modified,
          created: treeData[i].created,
          parentCatKey: "",
          cateType: nodeData.cateType || "thing",
          refKey: nodeData.refKey || "",
        };
        //if top level node has children
        if (treeData[i].children && treeData[i].children.length > 0) {
          // for (let j = 0; j < treeData[i].children.length; j++) {
          //get children keys

          const { categoriesData: nestedUpdatedChildrenData, resourcesData } =
            this.getNestedUpdatedChildrenData(
              treeData[i].children,
              treeData[i].key
            );
          updatedCateData = Object.assign(
            updatedCateData,
            nestedUpdatedChildrenData
          );

          updatedResourcesMap = Object.assign(
            updatedResourcesMap,
            resourcesData
          );
          // }
        }
      }
      return {
        categories: updatedCateData,
        resources: updatedResourcesMap,
      };
    },
    getNestedUpdatedChildrenData(childTree, parentCatKey) {
      const treeview = this.getTreeViewRef();
      let updatedChildTreeData = {};
      let updatedResourcesMap = {};
      for (var i = 0; i < childTree.length; i++) {
        if (childTree[i].isResource) {
          updatedResourcesMap[childTree[i].key] = {
            catId: parentCatKey,
            order: i,
          };
          continue;
        }
        //gets all projects from current level
        const nodeData = treeview.getTreeData(childTree[i].key)[0];
        updatedChildTreeData[childTree[i].key] = {
          order: i,
          name: childTree[i].name,
          parentCatKey,
          key: childTree[i].key,
          modified: childTree[i].modified,
          created: childTree[i].created,
          open: nodeData?.open || false,
          cateType: nodeData?.cateType || "thing",
          refKey: nodeData?.refKey || "",
        };

        if (childTree[i].children && childTree[i].children.length > 0) {
          // for (let j = 0; j < childTree[i].children.length; j++) {
          //get children keys

          const { categoriesData: nestedChildrenData, resourcesData } =
            this.getNestedUpdatedChildrenData(
              childTree[i].children,
              childTree[i].key
            );
          updatedChildTreeData = Object.assign(
            updatedChildTreeData,
            nestedChildrenData
          );

          updatedResourcesMap = Object.assign(
            updatedResourcesMap,
            resourcesData
          );
          // }
        }
      }
      return {
        categoriesData: { ...updatedChildTreeData },
        resourcesData: { ...updatedResourcesMap },
      };
    },
    updateMenuItems() {
      const currMenuItems = cloneDeep(this.menuItems);
      const resourceOpt = currMenuItems.find(
        (m) => m.id === RESOURCES_ACTIONS_MENU_TYPES.ADD_RESOURCE
      );

      if (!isEmpty(resourceOpt)) {
        const resourceLabel = createLabelForResource(
          this.selectedResourceTypeOpt
        );

        resourceOpt.text = `Add ${resourceLabel}`;
        this.menuItems = currMenuItems;
      }
    },
    checkResourcesAndUpdateTree(newList, oldList) {
      let listChanged = false;

      // let refreshList = false
      if (
        (!isEmpty(newList) && isEmpty(oldList)) ||
        (isEmpty(newList) && isEmpty(oldList))
      ) {
        listChanged = true;
      } else {
        if (newList.length !== oldList.length) {
          listChanged = true;
        } else {
          // refreshList = true;
          listChanged = newList.some((r) => {
            const existingR = oldList.find((rData) => rData.key === r.key);

            return (
              r.catId !== existingR.catId ||
              r.tag !== existingR.tag ||
              r.order !== existingR.order
            );
          });
        }

        // if (listChanged) {
        //   this.updateTree(true, false);
        //   // await this.$nextTick();
        //   // if (refreshList) {
        //   //   emitRefreshList(true, "task");
        //   // }
        // }
      }

      if (listChanged) {
        //         categoriesToUse,
        // updateFields = true,
        // clearNodeChildren = true,
        // openResourceInfo,
        // stopLoader,
        // makeSelectionVisible

        setTimeout(() => {
          this.createAndSetCategoryTree(
            this.filteredCategories,
            undefined,
            undefined,
            undefined,
            undefined,
            true
          );
        }, 0);
      }
    },

    openResourceFromOnTypeChange() {
      if (
        this.checkToOpenResourceInfo() &&
        !isEmpty(this.selectedCategories)
        // Disabled this check to allow resource category handling
        // &&
        // checkIfSelectedResourceCateHasResourcesOnly(this.selectedCategories)
      ) {
        this.handleNodesData([this.selectedCategories[0]]);
      }
    },

    getTreeViewRef() {
      return getResourceTreeView("active").treeInstance;
    },
    checkRawTasksAndRebuildTree(n, o) {
      if (!isEqual(n, o)) {
        let openCategory = false;
        let buildTree = false;
        if (!isEmpty(n) && isEmpty(o)) {
          openCategory = true;
          buildTree = true;
        } else if (isEmpty(n) && !isEmpty(o)) {
          buildTree = true;
        } else if (!isEmpty(n) && !isEmpty(o)) {
          if (n.length !== o.length) {
            buildTree = true;
          } else {
            buildTree = n.some((task, index) => {
              return (
                (!isUndefinedVal(task.resources) &&
                  !isUndefinedVal(o[index].resources) &&
                  !isEqual(task.resources, o[index].resources)) ||
                task.completed !== o[index].completed
              );
            });
          }
        }

        this.openCategory = openCategory;

        if (buildTree) {
          this.createAndSetCategoryTree(this.filteredCategories);
        }
      }
    },
  },
  computed: {
    ...mapGetters("resourcesData", {
      resources: "resources",
      categories: "categories",
      searchQuery: "query",
      selectedCategories: "selectedCategories",
      resourcesCateTree: "categoriesTree",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      cateChildrenMap: "cateChildrenMap",
      rawResourcesMap: "rawResourcesMap",
      resourcesTasksMap: "resourcesTasksMap",
    }),
    ...mapGetters(["keepResourceNodesClosed"]),
    ...mapGetters("task", {
      rawTasks: "rawTasks",
      showAllTasks: "showClearedTasks",
      taskAreasMap: "projects",
      tasks: "tasks",
    }),
    ...mapGetters("resourceInfo", {
      isResourceFormOpen: "isOpen",
      resourceData: "data",
    }),
    isSearchQueryValid() {
      const normalisedSearchQuery = this.searchQuery?.trim();
      return normalisedSearchQuery && normalisedSearchQuery !== "*";
    },
    menuTarget() {
      // return this.showAllData
      //   ? "#resources-categories-all-data-tree"
      //   : "#resources-categories-tree";

      return "#resources-categories-tree";
    },
  },
  created() {
    this.openForm = true;
  },
  mounted() {
    this.parentWrapperEl = this.$el.querySelector(
      "#resources-categories-tree-wrapper"
    );
    this.listWrapperEl = this.$el.querySelector("#resources-categories-tree");
    this.firstRunCompleted = false;
    this.init();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  watch: {
    resources: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          if (this.doNotRebuildTree) {
            this.doNotRebuildTree = false;
            return;
          }
          this.checkResourcesAndUpdateTree(n, o);
        }
      },
      deep: true,
    },
    categories: {
      handler(n, o) {
        // const newKeys = Object.keys(n);
        // const oldKeys = Object.keys(o);
        // // if (newKeys.length !== oldKeys.length) {
        // console.debug("CALLs");
        if (!isEqual(n, o)) {
          if (this.doNotRebuildTree) {
            this.doNotRebuildTree = false;
            return;
          }

          this.checkCategoriesForRecreation(n, o);
        }
        // }
      },
      deep: true,
    },
    selectedCategories: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.setSelectedCategoriesNodes(n);
        }
      },
      deep: true,
      // immediate: ,
    },
    selectedResourceTypeOpt: {
      handler() {
        this.filterResourceCategories();
        this.createAndSetCategoryTree(this.filteredCategories);
        this.updateMenuItems();

        this.openResourceFromOnTypeChange();
      },
    },

    // Disabled search in resource tree
    // searchQuery() {
    //   this.createAndSetCategoryTree(
    //     this.filteredCategories,
    //     undefined,
    //     true,
    //     undefined,
    //     undefined,
    //     true
    //   );
    // },
    showAllTasks: {
      handler() {
        // this.showAllData = newVal;
        this.createAndSetCategoryTree(
          this.filteredCategories,
          undefined,
          true,
          undefined,
          true,
          true
        );
      },
    },

    rawTasks: {
      handler(n, o) {
        this.checkRawTasksAndRebuildTree(n, o);
      },
      deep: true,
    },
    // tasks: {
    //   handler(n, o) {
    //     this.checkRawTasksAndRebuildTree(n, o);
    //   },
    //   deep: true,
    // },
  },
};
</script>
<style>
.resourcesTree .e-level-1 {
  margin-left: -24px;
}

.tree-node.resource-sub-category .e-text-content {
  padding-left: 10px !important;
}

.e-drag-item.e-treeview.resourcesTree .cat-resource-count {
  margin-left: 15px;
}

.e-name .subcategory {
  margin-left: 20px;
}

#resources-categories-tree-wrapper {
  /* height: calc(100% - 34px); */
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 8px;
}

#resources-categories-tree-side-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
}

#resources-categories-tree-side-bar-wrapper {
  height: 100%;
}

#resource-type-selector-wrapper {
  margin-bottom: 5px;
}
</style>
