import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.btnClassNames,attrs:{"icon":"","small":"","tabindex":"-1"},on:{"click":_vm.handleClick}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":_vm.btnIconSize,"color":_vm.btnIconColor}},[_vm._v(" "+_vm._s(_vm.btnIcon))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.btnText))])])}
var staticRenderFns = []

export { render, staticRenderFns }