<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        v-bind="{ ...attrs, ...btnAttrs }"
        v-on="on"
        :class="clearBtnData.btnClasses"
        @click.exact="handleSingleClick"
        @dblclick.exact="handleDblClick"
        x-small
        light
      >
        <v-icon size="12">
          {{ clearBtnIcon }}
        </v-icon>
        <span v-if="count" class="ml-2 filter-text"> ({{ count }}) </span>
      </v-btn>
    </template>
    <span> {{ clearBtnLabel }} </span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    clearBtnLabel: {
      type: String,
      default: "Clear List Filters",
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },

    count: {
      type: [String, Number],
    },
    btnAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleSingleClick(e) {
      this.$emit("clear-btn:click", e);
    },
    handleDblClick(e) {
      this.$emit("clear-btn:dblClick", e);
    },
  },
  computed: {
    clearBtnIcon() {
      return this.isEmpty ? "$ctiFilterEnabled" : "$ctiFilterCleared";
      // return "$ctiFilterEnabled";
    },
    clearBtnData() {
      return {
        icon: this.clearBtnIcon,
        btnClasses: {
          "filter-btn": true,
          "filters-active": !this.isEmpty,
        },
      };
    },
  },
};
</script>
<style scoped>
.filter-btn {
  height: 20px !important;
  width: auto !important;
  background: #eaeaea !important;
  color: #676767;
  border-radius: 6px !important;
}

.filter-btn.filters-active .filter-text {
  font-weight: 700;
}
.filter-text {
  font-size: 12px;
}

/* .filters-active {
  background-color: var(--primary-color) !important;
  color: #fff !important;
} */
</style>
