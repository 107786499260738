export default {
  showDialog(state, payload) {
    state.isOpen = true;
    state.taskData = payload.taskData;
    state.taskId = payload.taskData.key;
    state.selectedTasks = payload.selectedTasks;
    state.selectedResourceId = payload.selectedResourceId;
    state.returnViewInfo = payload.returnViewInfo || {};
  },
  showNewDialog(state, payload) {
    state.openNewForm = true;
    state.taskData = payload.taskData;
    state.taskId = payload.taskData.key;
    state.selectedTasks = payload.selectedTasks;
    state.selectedResourceId = payload.selectedResourceId;
    state.returnViewInfo = payload.returnViewInfo || {};
    state.projectId = payload.taskData.project;
  },
  resetDialog(state) {
    state.isOpen = false;
    state.openNewForm = false;
    state.taskData = {};
    state.selectedTasks = [];
    state.updatedTaskData = {};
    state.updatedProjectData = {};
    state.isEditConfirmBoxOpened = false;
    state.noteData = {};
    state.resourceData = {};
    state.updatedObjectiveData = {};
    state.selectedResourceId = "";
    state.returnViewInfo = {};
    state.taskId = null;
    state.projectId = null;
  },
  setTaskData(state, payload) {
    state.taskData = payload;
    state.taskId = payload.key;
    state.projectId = payload.project;
  },
  updateTaskData(state, payload) {
    state.taskData = {
      ...state.taskData,
      ...payload,
    };
    state.taskId = payload.key;
    state.projectId = payload.project;
  },
  openEditConfirmBox(state, payload) {
    state.updatedTaskData = payload.taskData;
    state.updatedProjectData = payload.projectData;
    state.updatedObjectiveData = payload.objectiveData;
    state.resourceDataChanges = payload.resourceData || {};
    state.isEditConfirmBoxOpened = true;
  },
  closeEditTaskConfirmBox(state) {
    state.isEditConfirmBoxOpened = false;
  },
  setData(state, payload) {
    Object.assign(state, payload);
  },
};
