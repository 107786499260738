export default {
  projects(state) {
    return state.projects;
  },
  tasks(state) {
    return state.tasks;
  },
  categories(state) {
    return state.categories;
  },
  groupMode(state) {
    return state.groupMode;
  },
  isInboxViewEnabled(state) {
    return state.inboxViewEnabled;
  },
  isGlobalViewEnabled(state) {
    return state.globalViewEnabled;
  },
  isInspectModeEnabled(state) {
    return state.inspectModeEnabled;
  },
  isFilterBarOptsEnabled(state) {
    return state.isFilterBarOptsEnabled;
  },
  filters(state) {
    return state.filters;
  },
  currNav(state) {
    return state.nav;
  },
  selectedDates(state) {
    return state.selectedDates;
  },
  selectedOptsList(state) {
    return state.selectedOptsList;
  },
  searchQuery(state) {
    return state.query;
  },
  tasksCount(state) {
    return state.tasksCount;
  },
  selectedTasks(state) {
    return state.selectedTasks;
  },
  showClearedTasks(state) {
    return state.showClearedTasks;
  },
  filteredTasks(state) {
    return state.filteredTasks;
  },
  prevTreeGroupView(state) {
    return state.prevTreeGroupView;
  },
  prevTodayViewGroupMode(state) {
    return state.prevTodayViewGroupMode;
  },
  projectsTree(state) {
    return state.projectsTree;
  },
  rawTasks(state) {
    return state.rawTasks;
  },
  selectedCatIds(state) {
    return state.selectedCatIds;
  },
  tasksMap(state) {
    const map = state.rawTasks.reduce((a, t) => {
      if (t.key) {
        a[t.key] = t;
      }
      return a;
    }, {});

    return map;
  },
  recurringTasksMap(state) {
    return state.recurringTasksMap;
  },
  rawTasksMap(state) {
    return state.rawTasksMap;
  },
  contingentFilter(state) {
    return state.contingentFilter;
  },
  isUserFromDifferentTreeMode(state) {
    return state.isUserFromDifferentTreeMode;
  },
  collapsedGroups(state) {
    return state.collapsedGroups;
  },
  openedCategories(state) {
    return state.openedCategories;
  },
  selectedOptsByFilterBar(state) {
    return state.selectedOptsByFilterBar;
  },
  objectivesMap(state) {
    return state.objectivesMap;
  },
  selectedCalForecastType(state) {
    return state.selectedCalForecastType;
  },
  areaWiseSortedTasks(state) {
    return state.areaWiseSortedTasks;
  },
  areaWiseSortedTasksGroups(state) {
    return state.areaWiseSortedTasksGroups;
  },
  selectedTaskFilter(state) {
    return state.selectedTaskFilter;
  },
  selectedTaskDateFilter(state) {
    return state.selectedTaskDateFilter;
  },
  inspectTriggeredFrom(state) {
    return state.inspectTriggeredFrom;
  },
  currInspectedTaskId(state) {
    return state.currInspectedTaskId;
  },
  inspectModeOpts(state) {
    return state.inspectModeOpts;
  },
  additionalTaskFilters(state) {
    return state.additionalTaskFilters;
  },
  showContingentTasks(state) {
    return state.showContingentTasks;
  },
  ignoreAreaCheck(state) {
    return state.ignoreAreaCheck;
  },
  isDeleteRecurringConfirmDialogOpen(state) {
    return state.isDeleteRecurringConfirmDialogOpen;
  },
  isProjectsResourceModeEnabled(state) {
    return state.isProjectsResourceModeEnabled;
  },
  isTasksDataLoaded(state) {
    return state.isTasksDataLoaded;
  },
};
