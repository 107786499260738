import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtnToggle,{attrs:{"id":"edit-mode-btns-group","value":_vm.activeModeIndex}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"edit-mode-btn duplicate-mode",class:_vm.getActiveBtnClass('duplicate'),attrs:{"icon":""},on:{"click":function($event){return _vm.handleMode('duplicate')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"14"}},[_vm._v("$ctiDuplicate")])],1)]}}])},[_c('span',[_vm._v("Duplicate Task")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"edit-mode-btn",class:_vm.getActiveBtnClass('delete'),attrs:{"icon":""},on:{"click":function($event){return _vm.handleMode('delete')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"14"}},[_vm._v("$ctiDelete")])],1)]}}])},[_c('span',[_vm._v("Delete Task")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }