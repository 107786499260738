import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"25%","value":_vm.isFormOpen,"content-class":"note-topic-form"},on:{"click:outside":_vm.closeDialog,"keydown":_vm.handleKeyDown}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Add Topic ")]),_c(VCardText,[_c('div',[_c('NoteFolderSelector',{ref:"noteCateSelector",attrs:{"allowToAddTopic":false,"placeholder":"Select Parent Topic to add topic in","onlyFirstLevel":""},on:{"keydown":_vm.handleSelectorKeyDown},model:{value:(_vm.selectedCate),callback:function ($$v) {_vm.selectedCate=$$v},expression:"selectedCate"}})],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","text":"","color":"error"},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel")]),_c(VBtn,{staticClass:"save-confirm-btn",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.submitData}},[_vm._v(" Create ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }