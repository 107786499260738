<template>
  <div class="filter-nav">
    <div class="filter-nav-content">
      <!-- FilterBar Area, hidden when in Global View -->
      <div>
        <div class="pa-0">
          <v-row
            no-gutters
            justify="space-between"
            class="no-flex-wrap"
            align="center"
          >
            <v-col cols="auto" class="flex-shrink-1">
              <filters :dataGridRef="dataGridRef" :mode="mode" />
            </v-col>
            <v-col cols="auto">
              <v-row no-gutters justify="end">
                <ContingentListFiltersBar
                  v-if="isContingentFilterAllowed"
                  :mode="contingentMode"
                />

                <!-- <AreasModeToggleBtns v-if="isAreaModeToggleAllowed" /> -->
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { treeSideBarModes } from "@/variables/viewConfigs";
import { mapGetters } from "vuex";
import ContingentListFiltersBar from "./ContingentFilterBar.vue";
// import AreasModeToggleBtns from "./AreaModeToggleBtns.vue";
import Filters from "./Filters.vue";

export default {
  props: {
    dataGridRef: {
      type: Object,
    },
    mode: {
      type: String,
    },
  },
  components: {
    Filters,
    ContingentListFiltersBar,
    // AreasModeToggleBtns,
  },
  computed: {
    ...mapGetters("task", [
      "currNav",
      "isGlobalViewEnabled",
      "isInspectModeEnabled",
      "isInboxViewEnabled",
    ]),
    isNotTodayMode() {
      return (
        this.currNav !== 3 ||
        this.isGlobalViewEnabled ||
        this.isInboxViewEnabled
      );
    },
    isContingentFilterAllowed() {
      // return (
      //   this.currNav === 3 &&
      //   !this.isGlobalViewEnabled &&
      //   !this.isInboxViewEnabled
      // );
      return false;
    },
    contingentMode() {
      return this.currNav === 0 ? "calendar" : "today";
    },
    isAreaModeToggleAllowed() {
      return (
        treeSideBarModes.includes(this.currNav) &&
        !this.isGlobalViewEnabled &&
        !this.isInboxViewEnabled
      );
    },
  },
};
</script>

<style>
.category-filters {
  flex-wrap: wrap;

  gap: 8px 0px;
}
/* HEADER with drop-shadow on scroll */
.filter-nav {
  top: 24px;
  /* make sure .filter-nav overlaps main*/
  z-index: 1;
  /* fix weird flickering issue in chrome: https://stackoverflow.com/a/22224884/286685 */
  -webkit-backface-visibility: hidden;
}

/* PSEUDO ELEMENTS to create drop-shadow */
.filter-nav::before,
.filter-nav::after {
  /* content: ""; */
  display: block;
  height: 16px;
  /* make pseudo elements sticky as well */
  /* position: sticky;
  -webkit-position: sticky; */
}

/* SHADOW */
.filter-nav::before {
  top: 88px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.5);
}

/* COVER */
.filter-nav::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  background: linear-gradient(
    white 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    transparent
  );
  top: 48px;
  /* cover should fall over shadow */
  z-index: 2;
}

.filter-nav-caption {
  margin-top: 5px;
  margin-bottom: 10px !important;
}

/* .filter-nav CONTENT */
.filter-nav-content {
  background: #fff;
  /* min-height: 70px; */
  padding: 8px 16px 10px 0px;
  /* position: sticky;
  -webkit-position: sticky; */
  /* top: 40px; */
  /* compensate for shadow with negative margin */
  /* margin-top: -16px; */
  /* content should fall over shadow and cover */
  /* z-index: 3; */
}
</style>
