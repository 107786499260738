<template>
  <v-dialog
    :value="isSettingsOpen"
    width="75%"
    @click:outside="closeDialog"
    content-class="settings-dialog"
  >
    <v-card outlined>
      <v-row dense no-gutters>
        <v-col cols="3">
          <div class="side-bar pa-3">
            <v-card-title class="pa-0"> Settings </v-card-title>
            <v-list class="menu-opts-wrapper">
              <v-list-item-group
                :value="model"
                mandatory
                @change="handleMenuChange"
              >
                <v-list-item v-for="(item, i) in items" :key="i">
                  <!-- <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon> -->
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div>
              <v-list-item @click="handleSignout">
                <!-- <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon> -->
                <v-list-item-content>
                  <v-list-item-title>Sign Out</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="fill-height settings-form">
            <AccountsSettings v-if="model === 0" />
            <ColorThemeSettings v-if="model === 1" />
            <ListSettings v-if="model === 2" />
            <DetailsPaneSettings v-if="model === 3" />
            <!-- Disabled google sync -->
            <!-- <GoogleSettings v-if="model === 4" /> -->
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountsSettings from "./AccountSettings.vue";

import userDetailsMixin from "@/mixins/userDetailsMixin";
import { mapActions, mapGetters } from "vuex";
import ListSettings from "./ListSettings.vue";
import DetailsPaneSettings from "./DetailsPaneSettings.vue";
import GoogleSettings from "./GoogleSettings.vue";
import ColorThemeSettings from "./ColorThemeSettings.vue";
import isEscKey from "@/utils/isEscKey";
export default {
  components: {
    AccountsSettings,
    ListSettings,
    DetailsPaneSettings,
    GoogleSettings,
    ColorThemeSettings,
  },
  mixins: [userDetailsMixin],
  data() {
    return {
      model: 0,
      items: [
        {
          text: "Account",
          // icon: "mdi-account-group",
        },
        {
          text: "Color Theme",
          // icon: "$ctiResourcesNewIcon",
        },
        {
          text: "List Options",
          // icon: "$ctiSimpleTask",
        },
        {
          text: "Task Details",
        },
        // {
        //   text: "Google Calendar",
        // },
      ],
    };
  },
  methods: {
    ...mapActions(["toggleSettingsDialog"]),

    closeDialog() {
      this.toggleSettingsDialog();
    },
    handleMenuChange(val) {
      this.model = val;
    },
    handleSignout() {
      this.handleUserSignout();
    },
    resetData() {
      this.model = 0;
    },
    handleKeydown(e) {
      if (isEscKey(e)) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        this.closeDialog();
      }
    },
    setListeners() {
      document.addEventListener("keydown", this.handleKeydown);
    },
    removeListeners() {
      document.removeEventListener("keydown", this.handleKeydown);
    },
  },
  computed: {
    ...mapGetters(["isSettingsOpen", "taskSettings", "openedSettings"]),
    modeLabel() {
      let label = "";

      if (this.openedSettings === "account-settings") {
        label = "Account Settings";
      }
      if (this.openedSettings === "list-options") {
        label = "List Options";
      }

      if (this.openedSettings === "details-pane-settings") {
        label = "Details Pane";
      }

      if (this.openedSettings === "google-cal-settings") {
        label = "Google Calendar";
      }
      return label;
    },
  },
  watch: {
    isSettingsOpen(n) {
      if (n) {
        this.setListeners();
      } else {
        this.removeListeners();
        this.resetData();
      }
    },
  },
};
</script>
<style>
.settings-form {
  overflow-y: auto;
  height: 565px;
}

.settings-dialog {
  overflow-y: unset;
}

.side-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #f9f9f9; */
}

.menu-opts-wrapper {
  flex: 1;
}
</style>
