const getTaskRowHandlerColor = (priority) => {
  let color = "";
  switch (priority) {
    case "power":
      color = "#F44336";
      break;
    case "priority":
      color = "#ff9800";
      break;
    case "purpose":
      color = "#2196F3";
      break;
    case "impact":
      color = "#4CAF50";
      break;
    default:
      color = "#0000008a";
  }
  return color;
};

export default getTaskRowHandlerColor;
