import DatabaseStoreInterface from "@/services/DbStoreInterface";

import { getCurrDate } from "@/helpers/dates";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import removeProps from "@/utils/removeProps";
import { findDir } from "./directories";
import {
  NOTES_PROPS_TO_REMOVE,
  NOTE_PROPS_FOR_FORM,
  NOTE_PROPS_TO_RESTORE,
} from "@/variables/notes";
import createUniqueId from "@/utils/createUniqueId";
import store from "@/store";
import Vue from "vue";
import {
  convertDateTimeStrToDate,
  convertValueToArray,
  emitRefreshList,
  getAllChildrenOfANode,
} from "./common";
import { escapeHtmlString } from "@/utils/stringHelpers";
export const notesCollection = new DatabaseStoreInterface("notes-collection");

const labels = {
  notes: "Note",
  directories: "Directory",
};
class UserNotes {
  userId = "";
  notes = [];
  directories = {};
  selectedFolders = [];
  collapsedGroups = [];
  constructor(userId) {
    // if (this.#instance) {
    //   return this.#instance;
    // }
    // this.#instance = this;
    this.userId = userId;
    this.init();
  }
  async init() {
    try {
      const record = await notesCollection.getByKey(this.userId);
      if (record.exists) {
        const data = record.data();
        this.notes = data.notes || [];
        this.directories = data.directories || {};
        this.selectedFolders = data.selectedFolders || [];
        this.collapsedGroups = data?.collapsedGroups || [];
        return;
      }

      await notesCollection.set(this.userId, {
        notes: [],
        directories: {},
        selectedFolders: [],
        collapsedGroups: [],
      });
    } catch (error) {
      console.error("Unable to init: ", error.message);
    }
  }

  async add(data, type = "notes") {
    try {
      let updates = {};

      if (type === "notes") {
        this[type].push(data);
      } else if (type === "directories") {
        this[type] = {
          ...this[type],
          ...data,
        };
      }

      updates[type] = this[type];

      const res = await notesCollection.update(this.userId, updates);

      return res;
    } catch (error) {
      console.error(`Unable to add ${labels[type]} :`, error.message);
    }
  }

  async remove(key, type = "notes") {
    try {
      const updates = {};

      if (type === "notes") {
        const itemIndex = this[type].findIndex((d) => d.key === key);
        if (itemIndex === -1) {
          return;
        }
        this[type].splice(itemIndex, 1);
      } else if (type === "directories") {
        delete this[type][key];
      }

      updates[type] = this[type];
      const res = await notesCollection.update(this.userId, updates);

      return res;
    } catch (error) {
      console.error(`Unable to remove ${labels[type]} :`, error.message);
    }
  }

  async update(key, data, type = "notes") {
    try {
      const updates = {};

      if (type === "notes") {
        const itemIndex = this[type].findIndex((i) => i.key === key);
        if (itemIndex === -1) return;
        const currItemData = this[type][itemIndex];
        this[type][currItemData] = {
          ...currItemData,
          ...data,
          key,
        };
      } else if (type === "directories") {
        const currData = this[type][key];
        this[type][key] = {
          ...currData,
          ...data,
          key,
        };
      }

      updates[type] = this[type];
      const res = await notesCollection.update(this.userId, updates);
      return res;
    } catch (error) {
      console.error(`Unable to update ${labels[type]} :`, error.message);
    }
  }

  async addMulti(data, type = "notes") {
    try {
      if (!Array.isArray(data)) data = [data];
      if (type === "notes") {
        const updatedItems = [...this[type], ...data];
        this[type] = updatedItems;
      } else if (type === "directories") {
        const currData = this[type];
        const newData = data.reduce((a, i) => {
          a[i.key] = i;
          return a;
        }, {});
        const updatedItems = {
          ...currData,
          ...newData,
        };
        this[type] = updatedItems;
      }
      const updates = {
        [type]: this[type],
      };
      const res = await notesCollection.update(this.userId, updates);
      return res;
    } catch (error) {
      console.error(`Unable to add multiple ${type}: `, error.message);
    }
  }

  async removeMulti(keysToRemove, type = "notes") {
    try {
      if (!Array.isArray(keysToRemove)) keysToRemove = [keysToRemove];

      if (type === "notes") {
        keysToRemove.forEach((key) => {
          const itemIndex = this[type].findIndex((i) => i.key === key);
          if (itemIndex >= 0) {
            this[type].splice(itemIndex, 1);
          }
        });
      } else if (type === "directories") {
        const currData = this[type];
        this[type] = removeProps(currData, keysToRemove);
      }

      const updates = {
        [type]: this[type],
      };
      const res = await notesCollection.update(this.userId, updates);
      return res;
    } catch (error) {
      console.error(`Unable to remove multiple ${type}: `, error.message);
    }
  }

  async set(data, type = "notes") {
    try {
      if (
        type === "notes" ||
        type === "selectedFolders" ||
        type === "collapsedGroups"
      ) {
        if (!Array.isArray(data)) data = [data];
        this[type] = data;
      } else if (type === "directories") {
        this[type] = data;
      }

      const updates = {
        [type]: this[type],
      };

      await notesCollection.update(this.userId, updates);
    } catch (error) {
      console.error(`Unable to set ${type}: `, error.message);
    }
  }

  getNotes() {
    return this.notes;
  }
  getDirectories() {
    return this.directories;
  }
  getSelectedFolders() {
    return this.selectedFolders;
  }

  // async updateMultiNotes(not)
}

let userNotes;

export const initUserNotes = (userId) => {
  if (!userNotes) {
    userNotes = new UserNotes(userId);
  }

  return userNotes;
};

export const getUserNotes = () => {
  if (!userNotes) {
    throw new Error("User notes class needs to initialized first!");
  }
  return userNotes;
};

export const createUpdateAndEditedNoteDataByCell = ({
  fieldName,
  oldValue,
  currValue,
  rowId,
  // currRowData,
}) => {
  let updates = {};
  let editActionData = {};
  let changedProps = {};
  // const booleanFields = {
  //   completed: "changedCompleted",
  //   flag: "changedFlag",
  // }; // Boolean fields
  // const booleanFields = ["completed", "flag"];
  // const booleanFieldsChangedProps = ["changedCompleted","changedFlag"]; //
  // if (fieldName === "dueAsDate") {
  //   let due = "none";
  //   let time = "00:00";

  //   editActionData = {
  //     time: "00:00",
  //     due: "none",
  //     flag: typeof currRowData.flag === "boolean" ? currRowData.flag : false,
  //   };

  //   const oldDateValid = oldValue && isValidDate(oldValue);
  //   const newDateValid = currValue && isValidDate(currValue);
  //   if (oldDateValid) {
  //     editActionData.time = makeTimeFormat(oldValue);
  //     editActionData.due = makeDateFormat(oldValue);
  //   }

  //   if (newDateValid) {
  //     due = makeDateFormat(currValue);
  //     time = makeTimeFormat(currValue);
  //     if (time === "00:00") {
  //       time = makeTimeFormat(new Date());
  //     }
  //   }

  //   /**
  //    * mark as flagged when schudeling unscheduled task
  //    */
  //   if (newDateValid && !oldDateValid) {
  //     updates["/tasks/" + rowId + "/flag"] = true;
  //     changedProps.flag = true;
  //   }

  //   updates["/tasks/" + rowId + "/due"] = due;
  //   updates["/tasks/" + rowId + "/time"] = time;
  //   changedProps.due = due;
  //   changedProps.time = time;
  // } else if (fieldName === "project") {
  //   editActionData = {
  //     project: oldValue || "",
  //   };

  //   changedProps[fieldName] = currValue || "";
  //   updates[`/tasks/${rowId}/${fieldName}`] = currValue || "";
  //   // if (currValue) {
  //   //   updates["/tasks/" + rowId + "/project"] = currValue;

  //   //   if (oldValue && oldValue !== currValue) {
  //   //     editActionData.changedProject = currValue;
  //   //     // let changedProjectTasksList =
  //   //     //   cloneDeep(this.projects[currValue].tasks) || [];
  //   //     // const oldProjectTasksList = cloneDeep(
  //   //     //   this.projects[oldValue].tasks
  //   //     // );

  //   //     // // Remove task from old project;
  //   //     // if (oldProjectTasksList && oldProjectTasksList.length) {
  //   //     //   oldProjectTasksList.splice(oldProjectTasksList.indexOf(rowId), 1);
  //   //     //   updates["/projects/" + oldValue + "/tasks"] = oldProjectTasksList;
  //   //     //   updates["/projects/" + oldValue + "/modified"] = getCurrDate();
  //   //     // }
  //   //     // // Add task to new project
  //   //     // if (changedProjectTasksList && changedProjectTasksList.length > 0) {
  //   //     //   changedProjectTasksList.push(rowId);
  //   //     // } else {
  //   //     //   changedProjectTasksList = [rowId];
  //   //     // }
  //   //     // updates["/projects/" + currValue + "/tasks"] =
  //   //     //   changedProjectTasksList;
  //   //     // updates["/projects/" + currValue + "/modified"] = getCurrDate();
  //   //   }
  //   // }
  // } else if (booleanPropsOfTask.indexOf(fieldName) > -1) {
  //   if (typeof currValue === "boolean") {
  //     updates[`/tasks/${rowId}/${fieldName}`] = currValue;
  //     changedProps[fieldName] = currValue;
  //   }
  //   if (typeof oldValue === "boolean") {
  //     editActionData[fieldName] = oldValue;
  //   }
  //   // if (fieldName === "completed") {
  //   //   updates[`/tasks/${rowId}/status`] = currValue
  //   //     ? "complete"
  //   //     : "incomplete";
  //   //   editActionData["status"] = oldValue ? "complete" : "incomplete";
  //   // }
  // } else {

  if (fieldName === "order") {
    if (typeof currValue === "number") {
      updates[fieldName] = currValue;
      changedProps[fieldName] = currValue;
    }

    if (typeof oldValue === "number") {
      editActionData[fieldName] = oldValue;
    }
  } else {
    updates[fieldName] = currValue || "";
    changedProps[fieldName] = currValue || "";
    editActionData[fieldName] = oldValue || "";
  }

  editActionData.key = rowId;

  // }

  editActionData.changedProps = changedProps;

  updates.modified = getCurrDate();
  return {
    updates,
    editedData: editActionData,
  };
};

export const createNotesMap = (notes) => {
  const map = notes.reduce((a, t) => {
    if (t.path) {
      a[t.path] = t;
    }
    return a;
  }, {});
  return map;
};

export const createNoteFullPath = (note, allDirs) => {
  let hashString = "";
  if (!note.path || note.path === "root") {
    hashString = "-1";
  }

  let paths = [];
  let parentId = note.path;
  let hashedPaths = [];

  while (parentId) {
    const parent = allDirs[parentId];
    if (!parent) {
      break;
    }
    paths.unshift(parent.name);
    hashedPaths.unshift(parent.order);
    parentId = parent.parentKey;
  }

  let createdPath = allDirs[note.path]?.name;

  if (paths.length) {
    // paths.push("")
    paths = paths.join(" / ");
    createdPath = paths;
    hashedPaths = hashedPaths.join("-");
    hashString = hashedPaths;
  } else {
    createdPath = `/`;
    // hashString = `${hashString}`;
  }

  return {
    hashedPath: hashString,
    createdPath,
  };
};

export const processNote = (
  note,
  allDirs = store.getters["note/directories"]
) => {
  const { createdPath, hashedPath } = createNoteFullPath(note, allDirs);
  note.fullPath = createdPath;
  note.hashedPath = hashedPath;
  note.createdAsDate = convertDateTimeStrToDate(note.created);
  note.modifiedAsDate = convertDateTimeStrToDate(note.modified);
  note.itemType = "note";
  return note;
};
export const processNotes = (notes, allDirs) => {
  const clonedNotes = cloneDeep(notes);
  return clonedNotes.map((n) => processNote(n, allDirs));
};

export const createFoldersFilter = (foldersList, tree) => {
  let filters = [];
  if (foldersList && foldersList.length) {
    filters = foldersList.reduce((a, id) => {
      let newIds = [];

      if (id !== "root") {
        newIds = [
          {
            field: "path",
            type: "=",
            value: id,
          },
        ];
        const storedDir = findDir(tree, (node) => node.key === id);
        const allDirChildren = getAllChildrenOfANode(storedDir);
        if (!isEmpty(allDirChildren)) {
          const childIds = allDirChildren.map((child) => ({
            field: "path",
            type: "=",
            value: child.key,
          }));

          newIds.push(...childIds);
        }
      } else {
        newIds = [
          {
            field: "path",
            type: "=",
            value: "root",
          },
          {
            field: "path",
            type: "=",
            value: null,
          },
          {
            field: "path",
            type: "=",
            value: undefined,
          },
          {
            field: "path",
            type: "=",
            value: "",
          },
        ];
      }
      a.push(...newIds);
      return a;
    }, []);

    // filters = foldersList.map((f) => ({
    //   field: "path",
    //   type: "=",
    //   value: f,
    // }));
  }
  return filters;
};

export function createNotesGroupCaptions(data, group) {
  let captionToDisplay;
  switch (group) {
    case "hashedPath":
      captionToDisplay = data[0].fullPath;
      break;
  }
  if (
    captionToDisplay === "" ||
    !captionToDisplay ||
    captionToDisplay === "/"
  ) {
    captionToDisplay = "No Topic";
  }
  return captionToDisplay;
}

export const createNotesSearchFilter = (query) => {
  const basicQueryProps = {
    type: "like",
    value: query,
  };

  const parsedStr = escapeHtmlString(query);
  return [
    {
      ...basicQueryProps,
      field: "title",
      value: parsedStr,
    },
    {
      ...basicQueryProps,
      field: "descr",
      value: parsedStr,
    },
  ];
};

export const cleanNote = (note) => {
  return removeProps(note, NOTES_PROPS_TO_REMOVE);
};

export const cleanNotes = (notesToClean) => {
  if (!Array.isArray(notesToClean)) notesToClean = [notesToClean];
  const clonedNotes = cloneDeep(notesToClean);
  return clonedNotes.map((n) => cleanNote(n));
};

export const createNoteDataForAdd = (dataToAdd, allNotes, allDirs) => {
  let order = allNotes?.length || 0;
  const path = dataToAdd.path || "";

  if (path && allDirs[path]) {
    order = allNotes.filter((n) => n.path === path)?.length || 0;
  }
  return {
    key: dataToAdd.key || createUniqueId(),
    title: dataToAdd.title || "",
    descr: dataToAdd.descr || "",
    path,
    order,
    created: dataToAdd.created || getCurrDate(),
    modified: dataToAdd.modified || "",
  };
};

export const createRestoredDataOfNote = (noteDataToRestore, allDirs) => {
  const updates = {};

  const propsToRestore = Object.keys(noteDataToRestore);

  propsToRestore.forEach((prop) => {
    if (NOTE_PROPS_TO_RESTORE.indexOf(prop) >= 0) {
      if (prop === "path") {
        if (noteDataToRestore[prop] && allDirs[noteDataToRestore[prop]]) {
          updates[prop] = allDirs[noteDataToRestore[prop]].key;
        } else {
          noteDataToRestore[prop] = "";
        }
      } else {
        updates[prop] = noteDataToRestore[prop] || "";
      }
    }
  });

  updates.modified = getCurrDate();

  return updates;
};

const checkNoteFields = (fields, fieldsToCheck) =>
  fields.some((field) => fieldsToCheck.includes(field));
export const checkNoteFieldsForProcessing = (fieldsInTask, fieldsToCheck) =>
  checkNoteFields(fieldsInTask, fieldsToCheck);

export const updateNotesInLocalList = (notesToUpdate, localNotes) => {
  const currNotes = [...localNotes];

  if (!Array.isArray(notesToUpdate)) notesToUpdate = [notesToUpdate];

  if (notesToUpdate && notesToUpdate.length) {
    notesToUpdate.forEach((noteData) => {
      const storedItemIndex = currNotes.findIndex(
        (n) => n.key === noteData.key
      );
      if (storedItemIndex >= 0) {
        currNotes[storedItemIndex] = {
          ...currNotes[storedItemIndex],
          ...noteData.updates,
        };
      }
    });
  }

  return currNotes;
};

export const removeNotesFromLocalList = (noteIdsToRemove, localNotes) => {
  const currNotes = [...localNotes];

  if (!isEmpty(noteIdsToRemove)) {
    if (!Array.isArray(noteIdsToRemove)) noteIdsToRemove = [noteIdsToRemove];

    noteIdsToRemove.forEach((noteId) => {
      const storedTaskIndex = currNotes.findIndex((n) => n.key === noteId);
      if (storedTaskIndex >= 0) {
        currNotes.splice(storedTaskIndex, 1);
      }
    });
  }

  return currNotes;
};

export const addNotesInLocalNotesList = (
  notesToAdd,
  localNotes,
  method = "unshift",
  verify = true
) => {
  const currNotes = [...localNotes];

  if (!Array.isArray(notesToAdd)) notesToAdd = [notesToAdd];
  if (notesToAdd && notesToAdd.length) {
    if (method === "unshift") {
      const finalTasksList = createFinalItemsToAddInList(
        notesToAdd,
        currNotes,
        verify
      );
      currNotes.unshift(...finalTasksList);
    }

    if (method === "push") {
      const finalTasksList = createFinalItemsToAddInList(
        notesToAdd,
        currNotes,
        verify
      );
      currNotes.push(...finalTasksList);
    }
  }
  return currNotes;
};

const createFinalItemsToAddInList = (
  itemsToProcess,
  localList,
  verify = true
) => {
  let finalItemsToAdd = [];
  if (verify) {
    itemsToProcess.forEach((t) => {
      const currIndex = localList.findIndex((cT) => cT.key === t.key);
      if (currIndex === -1) {
        finalItemsToAdd.push(t);
      }
    });
  } else {
    finalItemsToAdd = [...itemsToProcess];
  }

  return finalItemsToAdd;
};

export const addOrRemoveOrUpdateNotesInLocalList = (
  {
    notesToAdd,
    notesToUpdate,
    notesToRemove,
    addMethod = "unshift",
    verifyAdd = true,
  },
  refreshList = true,
  reprocessList = false
) => {
  const data = {
    addMethod,
    verifyAdd,
  };
  const currDirectories = store.getters["note/directories"];
  const currProcessedNotes = store.getters["note/notes"];
  if (notesToUpdate && notesToUpdate.length) {
    const notesUpdatesList = [];
    notesToUpdate.forEach((n) => {
      const currIndex = currProcessedNotes.findIndex(
        (note) => note.key === n.key
      );
      if (currIndex >= 0) {
        notesUpdatesList.push({
          key: n.key,
          updates: processNote(
            {
              ...currProcessedNotes[currIndex],
              ...n.updates,
            },
            currDirectories
          ),
        });
      }
    });
    data.notesToUpdate = notesUpdatesList;
  }

  if (notesToRemove && notesToRemove.length) {
    data.notesIdsToRemove = notesToRemove.map((t) => t.key);
  }

  if (notesToAdd && notesToAdd.length) {
    data.notesToAdd = processNotes(notesToAdd, currDirectories);
  }

  store.dispatch("note/addOrRemoveOrUpdatesNotes", data);
  Vue.nextTick(() => {
    if (refreshList) {
      emitRefreshList(reprocessList, "notes");
    }
  });
};

export const checkIfNoteIsEdited = (updatedNoteData, currNoteData) => {
  let dataChanged = false;
  if (!isEmpty(updatedNoteData) && !isEmpty(currNoteData)) {
    dataChanged = NOTE_PROPS_FOR_FORM.some((prop) => {
      return !isEqual(updatedNoteData[prop], currNoteData[prop]);
    });
  }

  return dataChanged;
};

export const createUpdateAndEditedNoteData = (
  updatedData,
  currData
  // currRowData,
) => {
  let updates = {};
  let editActionData = {};
  let changedProps = {};

  NOTE_PROPS_FOR_FORM.forEach((prop) => {
    if (updatedData[prop] !== undefined && prop !== "itemType") {
      const newVal = updatedData[prop];
      const oldVal = currData[prop];
      if (prop === "order") {
        if (typeof newVal === "number") {
          updates[prop] = newVal;
          changedProps[prop] = newVal;
        }

        if (typeof oldVal === "number") {
          editActionData[prop] = oldVal;
        }
      } else {
        updates[prop] = newVal || "";
        changedProps[prop] = newVal || "";
        editActionData[prop] = oldVal || "";
      }
    }
  });

  editActionData.key = currData.key;

  editActionData.changedProps = changedProps;
  updates.modified = getCurrDate();
  return {
    updates,
    editedData: editActionData,
  };
};

export const getRootNoteTopic = (selectedId, allNoteTopicMap = {}) => {
  if (allNoteTopicMap[selectedId]?.parentKey) {
    return getRootNoteTopic(
      allNoteTopicMap[selectedId].parentKey,
      allNoteTopicMap
    );
  } else if (allNoteTopicMap[selectedId]) {
    return selectedId;
  }
};

export const getRootTopicsFromList = (selectedIds, allNoteTopicMap = {}) => {
  selectedIds = convertValueToArray(selectedIds);
  return selectedIds.map((selectedId) =>
    getRootNoteTopic(selectedId, allNoteTopicMap)
  );
};
