export const getRowContingentClass = (isContingent) => {
  return isContingent ? "is-contingent" : "";
};
export const getRowReminderClass = (isReminderTask) =>
  isReminderTask ? "is-reminder-task" : "";
export const getRowPriorityClass = (priority) => {
  let className = "";
  switch (priority) {
    case "power":
      className = "red";
      break;
    case "priority":
      className = "orange";
      break;
    case "purpose":
      className = "blue";
      break;
    case "impact":
      className = "green";
      break;
    default:
      className = "grey";
  }
  return className;
};
