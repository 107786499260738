import Storage from "@/utils/storage";

const AREAS_TIP_KEY = "HIDE_AREAS_TIP";
const RESOURCES_TIP_KEY = "HIDE_RESOURCES_TIP";

export const setAreasTipStatus = (val) => {
  Storage.set(AREAS_TIP_KEY, val);
};

export const setResourcesTipStatus = (val) => {
  Storage.set(RESOURCES_TIP_KEY, val);
};

export const getAreasTipStatus = () => {
  const val = Storage.get(AREAS_TIP_KEY);
  return val ? JSON.parse(val) : false;
};

export const getResourcesTipStatus = () => {
  const val = Storage.get(RESOURCES_TIP_KEY);

  return val ? JSON.parse(val) : false;
};
