<template>
  <ejs-contextmenu
    :target="target"
    :items="items"
    :select="selectMenuItem"
    :beforeOpen="beforeOpen"
    :onClose="onClose"
    :onOpen="onOpen"
    :id="menuIdText"
  ></ejs-contextmenu>
</template>
<script>
import Vue from "vue";
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

Vue.use(ContextMenuPlugin);
export default {
  props: {
    parentWrapperId: {
      type: String,
    },
    target: String,
    items: Array,
    type: String,
    mainTarget: String,
    menuId: String,
  },
  date() {
    return {
      openedFromRoot: false,
    };
  },
  computed: {
    menuIdText() {
      return this.menuId ? this.menuId : undefined;
    },
  },
  methods: {
    beforeOpen: function (args) {
      // Prevent opening of menu when ctrl key is pressed;
      if (args.event?.ctrlKey) {
        args.cancel = true;
        return;
      }
      const targetEl = args.event?.target;
      this.checkIfOpenedFromRoot(targetEl);
      args.openedFromRoot = this.openedFromRoot;
      this.$emit("beforeMenuOpen", args);
    },
    checkIfOpenedFromRoot(targetEl) {
      this.openedFromRoot = targetEl && targetEl.id === this.parentWrapperId;
    },
    onClose(args) {
      this.openedFromRoot = false;
      this.$emit("close", args);
    },
    onOpen(args) {
      this.$emit("open", args);
    },
    selectMenuItem(args) {
      this.$emit("menu-item:selected", args);
    },
  },
};
</script>
<style></style>
