import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/Home.vue";
import Dashboard from "./views/Dashboard.vue";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "./views/About.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/notes",
    name: "Notes",
    component: () =>
      import(/* webpackChunkName: "about" */ "./views/Notes.vue"),
  },
  {
    path: "/resources",
    name: "Resources",
    props: true,
    component: () =>
      import(/* webpackChunkName: "resources" */ "./views/Resources.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    props: true,
    component: () =>
      import(/* webpackChunkName: "resources" */ "./views/PrivacyPolicy.vue"),
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    props: true,
    component: () =>
      import(/* webpackChunkName: "resources" */ "./views/TermsOfService.vue"),
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, _, next) => {
  let routeName = "";

  if (to.name === "Dashboard") {
    routeName = "dashboard";
  } else if (to.name === "Resources") {
    routeName = "resources";
  } else if (to.name === "Notes") {
    routeName = "notes";
  }

  if (routeName) {
    store.dispatch("updateRootState", {
      currActiveView: routeName,
    });
  }
  next();
});

export default router;
