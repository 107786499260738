<template>
  <v-dialog
    :value="isSearchBoxOpen"
    @click:outside="closeDialog"
    @keydown="handleKeyDown"
    max-width="480px"
    min-width="200px"
    :fullscreen="false"
    content-class="global-search-box"
  >
    <div id="search-wrapper">
      <v-autocomplete
        attach="#search-wrapper"
        :value="selectedVal"
        @input="handleChange"
        @keydown="handleKeyDown($event, 'input')"
        item-text="label"
        loader-height="4px"
        :loading="searching"
        :items="list"
        :search-input.sync="searchedVal"
        background-color="#fff"
        auto-select-first
        class="global-search-input"
        hide-details
        placeholder="Jump To"
        :menu-props="{
          auto: true,
          left: true,
          maxWidth: '100%',
          minWidth: '100%',
          contentClass: 'global-search-selector-popup',
        }"
        no-filter
        autofocus
        return-object
      >
        <template #prepend-inner>
          <v-icon size="26" color="#878787" class="search-icon mr-1">
            mdi-magnify
          </v-icon>
        </template>
        <template #selection="{ item }">
          <v-icon>{{ getItemIconData(item).iconName }}</v-icon>

          <v-list-item-title class="search-item-text">
            <RichTextEditor
              ref="textEditor1"
              isReadOnly
              :showToolbar="false"
              noBorders
              :value="item.label"
              noPadding
            />
          </v-list-item-title>
        </template>

        <template #item="{ item }">
          <div
            class="search-item-opt"
            data-action-type="open-selected-item"
            :data-action-value="item.value"
          >
            <v-icon>{{ getItemIconData(item).iconName }}</v-icon>

            <v-list-item-content class="ml-3 pb-1 pt-1">
              <v-list-item-title class="search-item-text">
                <SelectItemLabel :text="item.label" />
              </v-list-item-title>

              <template v-if="item.fullPathLabel">
                <v-list-item-subtitle>
                  {{ item.fullPathLabel }}
                </v-list-item-subtitle>
              </template>
            </v-list-item-content>
            <!-- <v-list-item-title class="search-item-text">

            </v-list-item-title> -->
          </div>
        </template>

        <template #append>
          <div>Ctrl + K</div>
        </template>
        <template #no-data>
          <div class="no-item-text">
            {{ noDataPlaceholder }}
          </div>
        </template>
      </v-autocomplete>
    </div>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import isEmpty from "lodash/isEmpty";
import {
  // areTaskRecurrenceRulesValid,
  checkIfTaskIsContinued,
  checkIfTaskIsRecurring,
  checkIfTaskIsRepeatIn,
  // createTaskSearchQueryFilter,
  // getMainTaskRulesAndFillRecurringOpts,
  // processTask,
} from "@/helpers/tasks";
import {
  createFiltersFromConfig,
  runFiltersOnRow,
} from "@/core/components/DataTable/lib/pandas/filter";
import { createNotesSearchFilter, processNote } from "@/helpers/notes";
import {
  createResourcesSearchFilter,
  expandAllParentsOfResource,
  getUserResources,
  processResourceData,
  setResourceIdInTree,
} from "@/helpers/resources";
import debounce from "lodash/debounce";
import RichTextEditor from "@/components/RichTextEditor";
import { resourceTypesMap } from "@/data/resources";
import { isExternalNavRoute } from "@/helpers/routesHelpers";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import EventEmitter from "@/helpers/eventEmitter";
import SelectItemLabel from "./SelectItemLabel.vue";
import {
  OPEN_SELECTED_ITEM,
  SET_SELECTED_NODE_EVENT,
} from "@/variables/events";
import { createFullProjectPath, createProjectLabel } from "@/helpers/projects";
import isEscKey from "@/utils/isEscKey";
const collator = new Intl.Collator([], { numeric: true });

export default {
  mixins: [userDetailsMixin, tasksHelpersMixin],
  components: {
    RichTextEditor,
    SelectItemLabel,
  },
  mounted() {
    this.debouncedListCreator = debounce(this.createListOptsForSearch, 800);
  },
  data() {
    return {
      selectedVal: null,
      list: [],
      loadingData: false,
      searchResult: null,
      searchedVal: "",
      searching: true,
    };
  },
  methods: {
    ...mapActions(["toggleGlobalSearchBox", "toggleLoader", "updateRootState"]),

    ...mapActions("editForm", ["showEditTaskDialog", "setDataForNoteEdit"]),
    ...mapActions("resourceInfo", ["openResourceInfoViewer"]),
    ...mapActions("task", ["setAdditionalTaskFilters"]),
    filterItems(item, queryText, itemText) {
      return (
        (!!item.header && this.groupHasValue(item.data, queryText)) ||
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    groupHasValue(groupData, queryText) {
      return groupData.some(
        (i) =>
          i.label.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    handleKeyDown(e, type = "default") {
      if (type === "default") {
        if (isEscKey(e)) {
          e.stopPropagation();
          this.closeDialog();
        }
      }

      if (type === "input") {
        if (isEscKey(e)) {
          e.stopPropagation();
          this.closeDialog();
        }
      }
    },
    async createListOptsForSearch(val) {
      this.loadingData = true;

      const list = Object.freeze(this.createListOpts(val));

      setTimeout(() => {
        this.list = list;

        this.loadingData = false;
        this.searching = false;

        setTimeout(() => {
          const input = document.querySelector(".global-search-input input");

          if (input) {
            const end = input.value.length;
            input.setSelectionRange(end, end);
            input.focus();
          }
          // this.$el.querySelector(".global-search-input input").focus();
        }, 0);
      }, 0);
    },
    handleSearch(val) {
      this.debouncedListCreator(val);
    },
    createListOpts(val) {
      let list = [];
      const normalisedVal = val?.trim().toLowerCase() || "";

      if (normalisedVal) {
        const taskAreas = [];

        const placeResources = [];
        const thingResources = [];
        const peopleResources = [];
        const projectResources = [];
        const notesView = [];
        // const taskList = this.rawTasks;
        const notesList = this.rawNotes;
        const taskAreasList = Object.values(this.projects || {});
        const resourceList = Object.values(this.rawResourcesMap || {});

        const isShowAllEnabled = this.showClearedTasks;
        if (!isEmpty(taskAreasList)) {
          taskAreasList.forEach((a) => {
            if (
              a.title &&
              a.title.toLowerCase().includes(normalisedVal) &&
              ((!isShowAllEnabled && !a.hidden && !a.deleted) ||
                isShowAllEnabled)
            ) {
              taskAreas.push({
                label: a.title,
                value: a.key,
                data: { ...a },
                fullPathLabel: createProjectLabel(
                  a,
                  this.projects,
                  this.categories
                ),
                order: createFullProjectPath(a, this.projects, this.categories),
                type: "task-area",
              });
            }
          });

          if (!isEmpty(taskAreas)) {
            taskAreas.sort((a, b) => collator.compare(a.order, b.order));
            list.push({
              header: "Areas",
              data: [...taskAreas],
            });

            list.push(...taskAreas);
          }
        }

        // if (!isEmpty(taskList)) {
        // const createdFilters = createFiltersFromConfig([
        //   [...createTaskSearchQueryFilter(normalisedVal)],
        // ]);

        //   taskList.forEach((t) => {
        //     if (!runFiltersOnRow(createdFilters, t)) {
        //       return;
        //     }

        //     const dataToStore = {
        //       type: "task",
        //       label: t.title,
        //       value: t.key,
        //       data: processTask({ ...t }),
        //     };

        //     list.push({
        //       ...dataToStore,
        //     });
        //   });
        // }

        if (!isEmpty(notesList)) {
          const createdFilters = createFiltersFromConfig([
            [...createNotesSearchFilter(normalisedVal)],
          ]);

          notesList.forEach((n) => {
            if (runFiltersOnRow(createdFilters, n)) {
              notesView.push({
                label: n.title || n?.tag,
                value: n.key,
                type: "note",
                data: { ...n },
              });
            }
          });

          if (!isEmpty(notesView)) {
            notesView.sort((a, b) => collator.compare(a.order, b.order));
            list.push({
              header: "Notes",
              data: [...notesView],
            });
            list.push(...notesView);
          }
        }

        if (!isEmpty(resourceList)) {
          const createdFilters = createFiltersFromConfig([
            [...createResourcesSearchFilter(normalisedVal)],
          ]);

          resourceList.forEach((r) => {
            if (runFiltersOnRow(createdFilters, r)) {
              const foundResourceData = {
                label: r.title || r.tag,
                value: r.key,
                type: "resource",
                data: { ...r },
              };

              if (r.type === "project") {
                projectResources.push({ ...foundResourceData });
              }

              if (r.type === "place") {
                placeResources.push({ ...foundResourceData });
              }

              if (r.type === "thing") {
                thingResources.push({ ...foundResourceData });
              }

              if (r.type === "people") {
                peopleResources.push({ ...foundResourceData });
              }
            }
          });
        }

        if (!isEmpty(projectResources)) {
          list.push({
            header: "Projects",
            data: [...projectResources],
          });

          list.push(...projectResources);
        }

        if (!isEmpty(placeResources)) {
          list.push({
            header: "Places",
            data: [...placeResources],
          });

          list.push(...placeResources);
        }

        if (!isEmpty(thingResources)) {
          list.push({
            header: "Things",
            data: [...thingResources],
          });

          list.push(...thingResources);
        }

        if (!isEmpty(peopleResources)) {
          list.push({
            header: "People",
            data: [...peopleResources],
          });

          list.push(...peopleResources);
        }
      }

      return list;
    },
    async handleChange(itemData) {
      this.closeDialog();
      this.searchedVal = "";
      this.selectedVal = null;
      let setResourceInTree = false;
      let routeToVisit;
      let extraRouteData = {};
      let addRouteInReturnData = false;
      let openResourceInDashboard = false;
      let openResourceDataInResourceForm = false;
      let currViewData = {
        ...this.createCurrViewData({
          enableContingentFilter: this.showAllOrderedTasks,
        }),
      };

      if (!isEmpty(itemData)) {
        let dbUpdates = {};
        let navigateToRoute = true;
        let routeName, routeData;
        let emitTaskTreeSelectEvent = false;
        let triggerLoader = false;
        let selectedTaskCateIds = [];

        if (itemData.type === "task-area") {
          dbUpdates["nav"] = 4;
          dbUpdates["globalView"] = false;
          dbUpdates["inboxView"] = false;
          dbUpdates["notesView"] = false;
          dbUpdates["activeNavView"] = "dashboard";
          // updates["view/0/groupView"] = 0;
          dbUpdates["view/0/sortMode"] = 0;
          dbUpdates["view/0/statusFilter"] = "scheduled";
          dbUpdates["view/1/statusFilter"] = "incomplete";
          dbUpdates["view/1/sortMode"] = 0;
          dbUpdates["inspectModeEnabled"] = false;
          dbUpdates[`view/4/groupView`] = 1;
          if (isExternalNavRoute(this.$route.name)) {
            routeToVisit = "Dashboard";
            extraRouteData = {
              cateId: itemData.data.key,
            };

            addRouteInReturnData = true;
          } else if (this.$route.name === "Dashboard") {
            emitTaskTreeSelectEvent = true;
            navigateToRoute = false;
            selectedTaskCateIds = [itemData.data.key];
          }
        }

        this.hideTempVisibleTasks();

        // if (itemData.type === "task") {
        //   let isTaskReccuring =
        //     checkIfTaskIsRecurring(itemData.data) &&
        //     !itemData.data.linkedTo &&
        //     areTaskRecurrenceRulesValid(itemData.data.recurrence);
        //   if (isExternalNavRoute(this.$route.name)) {
        //     dbUpdates = {
        //       notesView: false,
        //       activeNavView: "dashboard",
        //     };

        //     routeName = "Dashboard";

        //     routeData = {
        //       taskId: itemData.data.key,
        //       type: isTaskReccuring ? "recurring" : "normal",
        //     };
        //     // this.$router.replace({
        //     //   name: "Dashboard",
        //     // });
        //   } else if (this.$route.name === "Dashboard") {
        //     let taskDataToUse = { ...itemData.data };

        //     if (isTaskReccuring) {
        //       taskDataToUse = this.getFirstVirtualReccuringTask(
        //         taskDataToUse.key
        //       );
        //     }

        //     navigateToRoute = false;

        //     if (!this.isFormOpen) {
        //       this.openTaskInEditForm(taskDataToUse);
        //     } else {
        //       EventEmitter.emit(
        //         OPEN_SELECTED_ITEM,
        //         getMainTaskRulesAndFillRecurringOpts({ ...taskDataToUse })
        //       );
        //     }

        //     // const processedTaskData = getMainTaskRulesAndFillRecurringOpts({
        //     //   ...taskDataToUse,
        //     // });

        //     // this.showEditTaskDialog({
        //     //   taskData: { ...processedTaskData },
        //     //   selectedTasks: [],
        //     // });
        //   }
        // }

        if (itemData.type === "note") {
          const processedNote = processNote({ ...itemData.data });

          if (this.$route.name === "Notes") {
            navigateToRoute = false;

            if (!this.isFormOpen) {
              this.setDataForNoteEdit({
                dataToSet: { ...processedNote },
              });
            } else {
              EventEmitter.emit(OPEN_SELECTED_ITEM, processedNote);
            }
          } else {
            addRouteInReturnData = true;
            dbUpdates = {
              notesView: true,
              activeNavView: "notes",
            };

            routeName = "Notes";

            routeData = {
              noteId: itemData.data.key,
            };
          }

          // Handle note
        }

        if (itemData.type === "resource") {
          const processedResource = processResourceData({ ...itemData.data });
          const userResources = getUserResources();

          let currSelectedCategories = userResources.getSelectedCategories();

          if (isEmpty(currSelectedCategories)) {
            currSelectedCategories = {};
          }

          const resourceType = processedResource.type;

          if (resourceType === "project") {
            openResourceInDashboard = true;
          }
          // console.log("itemData", itemData)
          // reset filter for the searched
          this.setAdditionalTaskFilters({
            key: "resourceTypes",
            list: [itemData.data.type],
          });

          if (!openResourceInDashboard) {
            getUserResources().set(itemData.data.type, "selectedResourceType");
          }

          // reset filter for the searched

          if (this.$route.name === "Resources") {
            if (!openResourceInDashboard) {
              navigateToRoute = false;

              await setResourceIdInTree(processedResource);
              expandAllParentsOfResource(processedResource);
              if (!this.isResourceEditorOpen) {
                this.openResourceInfoViewer({
                  data: { ...processedResource },
                });
              } else {
                EventEmitter.emit(OPEN_SELECTED_ITEM, processedResource);
              }
            } else {
              addRouteInReturnData = true;
              dbUpdates = {
                notesView: false,
                activeNavView: "dashboard",
              };

              dbUpdates.isProjectsResourceModeEnabled = true;

              openResourceDataInResourceForm = true;
              navigateToRoute = true;

              routeName = "Dashboard";

              setResourceInTree = true;
            }
          } else {
            addRouteInReturnData = true;
            dbUpdates = {
              notesView: false,
              activeNavView: openResourceInDashboard
                ? "dashboard"
                : "resources",
            };

            if (openResourceInDashboard) {
              dbUpdates.isProjectsResourceModeEnabled = true;

              if (this.$route.name === "Dashboard") {
                openResourceDataInResourceForm = true;
                navigateToRoute = false;
              }
            }

            if (openResourceInDashboard) {
              routeName = "Dashboard";
            } else {
              routeName = "Resources";
            }

            routeData = {
              // resourceId: itemData.data.key,
            };
            setResourceInTree = true;
          }

          if (setResourceInTree) {
            this.toggleLoader(true);
            await setResourceIdInTree(processedResource);
          }
          // Handle resource
        }

        if (!isEmpty(dbUpdates)) {
          await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        }

        // await Vue.nextTick();

        // if (navigateToRoute) {
        //   this.$router.replace({
        //     name: routeName,
        //     query: {
        //       ...routeData,
        //     },
        //   });
        // }

        await Vue.nextTick();

        if (emitTaskTreeSelectEvent) {
          setTimeout(() => {
            EventEmitter.emit(
              SET_SELECTED_NODE_EVENT,
              selectedTaskCateIds,
              true,
              true,
              undefined,
              true,
              undefined,
              true
            );
          }, 0);
        }
      }
      this.$nextTick(async () => {
        this.searchedVal = "";
        this.selectedVal = null;
        if (!isEmpty(itemData)) {
          // this.selectedVal = itemData.value;

          let dbUpdates = {};
          let navigateToRoute = true;
          let routeName, routeData;
          // if (itemData.type === "task") {
          //   let isTaskReccuring =
          //     checkIfTaskIsRecurring(itemData.data) &&
          //     !itemData.data.linkedTo &&
          //     areTaskRecurrenceRulesValid(itemData.data.recurrence);
          //   if (isExternalNavRoute(this.$route.name)) {
          //     dbUpdates = {
          //       notesView: false,
          //       activeNavView: "dashboard",
          //     };

          //     routeName = "Dashboard";

          //     routeData = {
          //       taskId: itemData.data.key,
          //       type: isTaskReccuring ? "recurring" : "normal",
          //     };
          //     // this.$router.replace({
          //     //   name: "Dashboard",
          //     // });
          //   } else if (this.$route.name === "Dashboard") {
          //     let taskDataToUse = { ...itemData.data };

          //     if (isTaskReccuring) {
          //       taskDataToUse = this.getFirstVirtualReccuringTask(
          //         taskDataToUse.key
          //       );
          //     }

          //     navigateToRoute = false;

          //     this.openTaskInEditForm(taskDataToUse);

          //     // const processedTaskData = getMainTaskRulesAndFillRecurringOpts({
          //     //   ...taskDataToUse,
          //     // });

          //     // this.showEditTaskDialog({
          //     //   taskData: { ...processedTaskData },
          //     //   selectedTasks: [],
          //     // });
          //   }
          // }
          this.updateRootState({
            itemInspectEnabled: true,
          });
          if (itemData.type === "note") {
            const processedNote = processNote({ ...itemData.data });

            if (this.$route.name === "Notes") {
              navigateToRoute = false;
              this.setDataForNoteEdit({
                dataToSet: { ...processedNote },
              });
            } else {
              dbUpdates = {
                notesView: true,
                activeNavView: "notes",
              };

              routeName = "Notes";

              routeData = {
                noteId: itemData.data.key,
              };
            }

            // Handle note
          }

          if (itemData.type === "resource") {
            const processedResource = processResourceData({ ...itemData.data });

            if (this.$route.name === "Resources") {
              if (!openResourceInDashboard) {
                navigateToRoute = false;
                this.openResourceInfoViewer({
                  data: { ...processedResource },
                });
              } else {
                addRouteInReturnData = true;
                dbUpdates = {
                  notesView: false,
                  activeNavView: "dashboard",
                };

                dbUpdates.isProjectsResourceModeEnabled = true;

                openResourceDataInResourceForm = true;
                navigateToRoute = true;

                routeName = "Dashboard";

                setResourceInTree = true;
              }
            } else {
              dbUpdates = {
                notesView: false,
                activeNavView: openResourceInDashboard
                  ? "dashboard"
                  : "resources",
              };

              if (openResourceInDashboard) {
                dbUpdates.isProjectsResourceModeEnabled = true;

                if (this.$route.name === "Dashboard") {
                  openResourceDataInResourceForm = true;
                  navigateToRoute = false;
                }
              }

              routeName = openResourceInDashboard ? "Dashboard" : "Resources";

              routeData = {
                resourceId: itemData.data.key,
              };

              if (openResourceDataInResourceForm) {
                await setResourceIdInTree(processedResource);
                expandAllParentsOfResource(processedResource);
                if (!this.isResourceEditorOpen) {
                  this.openResourceInfoViewer({
                    data: { ...processedResource },
                  });
                } else {
                  EventEmitter.emit(OPEN_SELECTED_ITEM, processedResource);
                }
              }
            }

            if (setResourceInTree) {
              await setResourceIdInTree(processedResource);
              expandAllParentsOfResource(processedResource);
            }
            // Handle resource
          }

          if (!isEmpty(dbUpdates)) {
            DatabaseInterface.update(dbUpdates, this.userInfo.uid);
          }

          if (!isEmpty(currViewData)) {
            if (addRouteInReturnData) {
              currViewData.to = this.$route.name;
            }
            this.storeCurrViewData(Object.freeze(currViewData));
          }

          if (navigateToRoute) {
            this.$router.replace({
              name: routeName || routeToVisit,
              query: {
                ...routeData,
                ...extraRouteData,
              },
            });

            this.toggleLoader(false);
          }
        }
      });
    },
    closeDialog() {
      // this.selectedVal = "";
      // this.searchedVal = "";
      this.loadingData = false;
      this.list = [];
      this.toggleGlobalSearchBox();
    },
    getItemIconData(itemData) {
      let iconName,
        iconSize = 19;

      if (itemData.type === "task") {
        iconName = "$ctiCircleOutline";

        const isTaskContinued = checkIfTaskIsContinued(itemData.data);
        const isTaskRecurring = checkIfTaskIsRecurring(itemData.data);
        const isTaskRepeatIn = checkIfTaskIsRepeatIn(itemData.data);
        if (isTaskRepeatIn) {
          iconName = "$ctiRepeat";
        } else if (isTaskRecurring) {
          iconName = "$ctiRepeat";
        } else if (isTaskContinued) {
          iconName = "$ctiLogout";
        }
      }

      if (itemData.type === "task-area") {
        iconName = "$ctiCircleOutline";
      }

      if (itemData.type === "note") {
        iconName = "$ctiNoteNew";
      }

      if (itemData.type === "resource") {
        iconName = resourceTypesMap[itemData.data.type].icon;
      }

      return {
        iconName,
        iconSize,
      };
    },
  },
  computed: {
    ...mapGetters("editForm", {
      isFormOpen: "isOpen",
      selectedResourceId: "selectedResourceId",
    }),
    ...mapGetters("resourceInfo", {
      isResourceEditorOpen: "isOpen",
    }),
    ...mapGetters("task", [
      "rawTasks",
      "rawTasksMap",
      "projects",
      "categories",
      "showClearedTasks",
    ]),
    ...mapGetters("note", ["rawNotes"]),
    ...mapGetters("resourcesData", {
      resources: "resources",
      rawResourcesMap: "rawResourcesMap",
      resourceCategoriesMap: "categories",
    }),
    ...mapGetters({
      isSearchBoxOpen: "isGlobalSearchBoxOpen",
    }),
    noDataPlaceholder() {
      if (this.searching) {
        return "Searching";
      }
      return this.searchedVal && !this.loadingData && isEmpty(this.list)
        ? "Nothing found"
        : "Search for Tasks,Resources,Notes";
    },
  },
  watch: {
    isSearchBoxOpen(n) {
      if (n) {
        this.selectedVal = "";
        this.searchedVal = "";
        this.list = [];
      }
    },
    searchedVal(n) {
      this.searching = true;
      this.debouncedListCreator(n);
    },
  },
};
</script>
<style>
.global-search-box {
  overflow-x: unset;
  overflow-y: unset;
}

.search-item-text {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-item-text .ql-editor {
  min-height: 20px;
}

.search-item-text {
  margin-top: 4px;
}

.search-item-text .ql-editor p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
</style>

<style>
.no-item-text {
  text-align: center;
}
.global-search-input {
  margin: 0;
  padding: 0;
}
.global-search-input div[role="combobox"] {
  padding: 5px 10px 5px 10px;
}

.global-search-input .v-input__append-inner {
  align-self: unset;
  color: #9e9e9e;
}

.global-search-selector-popup {
  left: 0 !important;
}

.global-search-selector-popup .v-list-item.v-list-item--highlighted::before {
  opacity: 0;
  background: var(--selection-color);
}

.global-search-selector-popup .v-list-item.v-list-item--highlighted {
  background: var(--selection-color);
}

.global-search-selector-popup .v-subheader {
  height: auto;
  font-size: 14px;
  padding: 6px 0px 6px 8px;
}

.search-item-opt {
  display: inline-flex;
  width: 100%;
  flex-shrink: 0;
}

#search-wrapper {
  position: relative;
}
</style>
