import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OptSelectorInput',{ref:"select",attrs:{"value":_vm.value,"opts":_vm.resourceTypeOpts,"itemLabelToUse":"title","itemValueToUse":"key","placeholder":_vm.placeholderText,"selectorClass":"resource-type-selector","useItemSelectionSlot":"","useItemSlot":"","menuProps":_vm.menuProps},on:{"change":_vm.handleChange,"blur":_vm.handleBlur,"focus":_vm.handleFocus,"keydown":_vm.handleKeyDown},scopedSlots:_vm._u([{key:"selection-content",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"selected-resource"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":_vm.greyIconColor,"size":"18"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(_vm._s(item.title))])],1)]}},{key:"select-item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({staticClass:"cti-list-item-sm"},'v-list-item',attrs,false),on),[_c(VIcon,{staticClass:"mr-3",staticStyle:{"width":"33px"},attrs:{"color":_vm.greyIconColor}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }