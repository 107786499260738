import { createRestoredDataOfRescrCate } from "@/helpers/resourceCategoriesHelpers";
import userDetailsMixin from "./userDetailsMixin";
import { RESOURCE_CATEGORY_ACTIONS } from "@/helpers/actionHistory";
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import { convertValueToArray } from "@/helpers/common";
import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_TREE_EVENT } from "@/variables/events";
import { mapActions } from "vuex";
import { increment } from "@/utils/number";
import { getUserResources } from "@/helpers/resources";
const mixin = {
  mixins: [userDetailsMixin, categoriesHelpersMixin, resourcesHelpersMixin],
  methods: {
    async handleUndoOnResrCate(action) {
      switch (action.type) {
        case RESOURCE_CATEGORY_ACTIONS.ADD:
        case RESOURCE_CATEGORY_ACTIONS.BATCH_ADD:
          this.handleRemoveResrCate(action.data);
          break;
        case RESOURCE_CATEGORY_ACTIONS.DELETE:
          this.handleAddResrCate(action.data);
          break;
        case RESOURCE_CATEGORY_ACTIONS.EDIT:
        case RESOURCE_CATEGORY_ACTIONS.BATCH_EDIT:
        case RESOURCE_CATEGORY_ACTIONS.REORDER_OR_MOVE:
          this.restoreRescrCatesData(action.data);
          break;

        default:
        // Do nothing
      }
    },
    async handleRedoOnResrCate(action) {
      switch (action.type) {
        case RESOURCE_CATEGORY_ACTIONS.ADD:
          await this.handleAddResrCate(action.data);
          break;
        case RESOURCE_CATEGORY_ACTIONS.DELETE:
          await this.handleRemoveResrCate(action.data);
          break;
        case RESOURCE_CATEGORY_ACTIONS.EDIT:
        case RESOURCE_CATEGORY_ACTIONS.BATCH_EDIT:
        case RESOURCE_CATEGORY_ACTIONS.REORDER_OR_MOVE:
          await this.restoreRescrCatesData(action.data, true);
          break;
        default:
        // Do nothing
      }
    },
    async restoreRescrCatesData(cateDataToRestore, isRedoAction) {
      if (!Array.isArray(cateDataToRestore))
        cateDataToRestore = [cateDataToRestore];
      const parentChangedList = [];
      const renameList = [];

      cateDataToRestore.forEach((cateData) => {
        const { updates, type } = createRestoredDataOfRescrCate(
          isRedoAction ? cateData.changedProps : cateData
        );

        if (type === "parent-changed") {
          parentChangedList.push({
            ...updates,
            key: cateData.key,
          });
        } else if (type === "rename") {
          renameList.push({
            ...updates,
            key: cateData.key,
          });
        }
      });

      this.renameRescrCates(renameList);
      this.changeParents(parentChangedList);
    },
    async renameRescrCates(rescrCatesToRename) {
      if (rescrCatesToRename && rescrCatesToRename.length) {
        // Disabled to enable updating resource tree on rename
        // const resrcCateUpdates = rescrCatesToRename.map((d) => ({
        //   key: d.key,
        //   updates: { title: d.name },
        // }));

        // this.updateNameOfCategoryInTree(
        //   resrcCateUpdates,
        //   "#resources-categories-tree",
        //   true
        // );

        // New Logic

        const resrcCateUpdates = rescrCatesToRename.map((d) => ({
          key: d.key,
          updates: { name: d.name },
        }));
        await this.updateMultiCate(resrcCateUpdates, false);
        EventEmitter.emit(REFRESH_TREE_EVENT);
      }
    },
    async changeParents(dirsParentChange) {
      if (dirsParentChange && dirsParentChange.length) {
        const rootNodes = [];
        dirsParentChange.forEach((d) => {
          const itemUpdates = d;
          const dirOrder = itemUpdates.order;

          const parentKey = itemUpdates.parentCatKey;

          const currData = this.resourcesCategoriesMap[d.key];

          if (!isEmpty(currData)) {
            const itemData = {
              ...currData,
              order: dirOrder,
              key: d.key,
            };

            if (
              parentKey !== undefined &&
              parentKey !== currData.parentCatKey
            ) {
              itemData.parentCatKey = parentKey;
            }

            rootNodes.push({ key: d.key, updates: { ...itemData } });
            // if (itemData.parentCatKey) {
            //   this.moveNodesInTree(
            //     itemData,
            //     itemData.parentCatKey,
            //     "#resources-categories-tree"
            //   );
            // } else {
            //   rootNodes.push({ key: d.key, updates: { ...itemData } });
            // }
          }
        });
        if (!isEmpty(rootNodes)) {
          await this.updateMultiCate(rootNodes, false);
          EventEmitter.emit(REFRESH_TREE_EVENT);
        }
      }
    },

    async handleRemoveResrCate(data) {
      const resrcCateList = convertValueToArray(data);
      const resrCateIdsToRemove = resrcCateList.map((d) => d.key);
      this.removeCategoryFromTree(
        resrCateIdsToRemove,
        "#resources-categories-tree"
      );
    },
    async handleAddResrCate(data) {
      const dirDataWithAttrs = this.addAttributesInCate(data, "resources-tree");
      const parentKey = data.parentCatKey ? data.parentCatKey : "";

      if (!parentKey) {
        dirDataWithAttrs.order = increment(
          dirDataWithAttrs.order,
          this.isProjectsResourceModeEnabled ? 2 : 1
        );
      }
      await getUserResources().add({ [data.key]: { ...data } }, "categories");

      EventEmitter.emit(REFRESH_TREE_EVENT);
      // this.addCategoryInTree(
      //   dirDataWithAttrs,
      //   parentKey,
      //   undefined,
      //   "#resources-categories-tree"
      // );
    },
    ...mapActions("toast", ["showToast"]),
  },
  computed: {
    ...mapGetters("resourcesData", {
      categories: "categories",
      resourcesCategoriesMap: "categories",
    }),
    ...mapGetters("task", {
      isProjectsResourceModeEnabled: "isProjectsResourceModeEnabled",
    }),
  },
};

export default mixin;
