<template>
  <div class="due-container">
    <v-btn-toggle class="ml-2">
      <v-tooltip bottom :disabled="isCellEditDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            x-small
            style="
              max-width: 20px !important;
              min-width: 20px !important;
              /* font-size: 16px !important; */
            "
            class="icon-btn-bg"
            @click="handleRemoveClick"
          >
            <v-icon size="14" color="black">mdi-minus</v-icon>
          </v-btn>
        </template>
        <span>Unschedule this task</span>
      </v-tooltip>

      <template v-if="isValidDateVal || isContingentTask">
        <v-tooltip bottom :disabled="!formattedDateData.showTooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              v-on="on"
              v-on:click="editOpen = true"
              :class="dateValBtnClassNames"
              v-bind="attrs"
            >
              <template>
                {{ formattedDateData.formattedDate }}
              </template>
            </v-btn>
          </template>
          <span>
            {{
              formattedDateData.remainingDaysLabel ||
              formattedDateData.fullDateFormatted
            }}
          </span>
        </v-tooltip>
      </template>

      <v-tooltip bottom v-else :disabled="isCellEditDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            v-on:click="editOpen = true"
            :class="dateValBtnClassNames"
          >
            None
          </v-btn>
        </template>
        <span>Click to schedule</span>
      </v-tooltip>
      <!-- <v-btn
        v-show="!isValidDateVal"
        x-small
        v-on:click="editOpen = true"
        class="textSize "
      >
        {{ formattedDate }}
      </v-btn> -->

      <v-tooltip bottom :disabled="isCellEditDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            x-small
            style="max-width: 20px !important; min-width: 20px !important"
            class="icon-btn-bg"
            @click="addNowDateTime"
          >
            <v-icon size="14" color="black"> mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Schedule for now</span>
      </v-tooltip>

      <v-tooltip bottom :disabled="isCellEditDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            v-on:click="nextDay"
            style="max-width: 20px !important; min-width: 20px !important"
            class="textSize"
            tabindex="-1"
          >
            D
          </v-btn>
        </template>
        <span>Push One Day</span>
      </v-tooltip>
      <v-tooltip bottom :disabled="isCellEditDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            v-on:click="nextWeek"
            style="max-width: 20px !important; min-width: 20px !important"
            class="textSize"
            tabindex="-1"
          >
            W
          </v-btn>
        </template>
        <span>Push One Week</span>
      </v-tooltip>
      <v-tooltip bottom :disabled="isCellEditDisabled">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-small
            v-on:click="nextMonth"
            style="max-width: 20px !important; min-width: 20px !important"
            class="textSize"
            tabindex="-1"
          >
            M
          </v-btn>
        </template>
        <span>Push One Month</span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>

<script>
import {
  addDaysToDate,
  addMonthsToDate,
  addWeeksToDate,
  formatDate,
  isValidDate,
  isSameDates,
  parseDate,
  createCurrDateOnly,
} from "@/helpers/dates";
import {
  checkIfTaskGroupCaptionHasDate,
  createReviewGroupLabel,
  getReminderDaysInfoFromTask,
} from "@/helpers/tasks";
import { columnPropsMixin } from "@/core/components/DataTable";
import createGroupedTasksCaptions from "../helpers/createGroupedTasksCaptions";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import {
  DATE_FORMAT_IN_USE,
  DATE_WITH_TIME_FORMAT_IN_USE,
  INTERNAL_DATE_FORMAT,
  WEEK_DAY_FORMAT,
} from "@/variables/dates";
import { mapGetters } from "vuex";
import isEmpty from "lodash/isEmpty";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { differenceInDays } from "date-fns";
export default {
  mixins: [userDetailsMixin, columnPropsMixin, tasksHelpersMixin],
  props: {},

  methods: {
    handleRemoveClick(e) {
      e.stopPropagation();

      if (this.isCellEditDisabled) return;

      this.handleDataUpdate("none");
      // if (isValidDate(this.data.dueAsDate)) {

      // }
    },
    addNowDateTime(e) {
      e.stopPropagation();
      if (this.isCellEditDisabled) return;
      this.handleDataUpdate(new Date());
    },
    nextDay(e) {
      e.stopPropagation();
      if (this.isCellEditDisabled) return;
      let date = this.data.dueAsDate;
      if (!isValidDate(date)) {
        date = new Date();
      }
      const newDate = addDaysToDate(date, 1);

      this.handleDataUpdate(newDate);
    },
    nextWeek(e) {
      e.stopPropagation();
      if (this.isCellEditDisabled) return;
      let date = this.data.dueAsDate;
      if (!isValidDate(date)) {
        date = new Date();
      }

      const newDate = addWeeksToDate(date, 1);
      this.handleDataUpdate(newDate);
    },
    nextMonth(e) {
      e.stopPropagation();
      if (this.isCellEditDisabled) return;
      let date = this.data.dueAsDate;
      if (!isValidDate(date)) {
        date = new Date();
      }
      const convertedCurrDate = new Date(date);
      const newDate = addMonthsToDate(convertedCurrDate, 1);
      this.handleDataUpdate(newDate);
    },
    createGroupCaption(groupByKey, data) {
      if (!groupByKey) {
        return "";
      }

      const groupCaption = createGroupedTasksCaptions(
        [data],
        groupByKey,
        (groupKey) => {
          return createReviewGroupLabel(
            groupKey,
            this.projects,
            this.categories
          );
        }
      );
      return groupCaption || "";
    },
    isSelectedDateCurrDate() {
      let isSameDate = false;
      const currDate = this.data.dueAsDate;
      const selectedDates = this.selectedDates;

      if (!isEmpty(selectedDates) && selectedDates.length === 1) {
        const firstDate = parseDate(selectedDates[0], INTERNAL_DATE_FORMAT);
        isSameDate = isSameDates(firstDate, currDate);
      }

      return isSameDate;
    },
    getDateInDayAndTimeFormat(date, onlyDayAndTime) {
      const dayName = formatDate(date, WEEK_DAY_FORMAT);
      // const dayTime = formatDate(date, "h:mma");
      const dateFormatted = onlyDayAndTime
        ? formatDate(date, "h:mma")
        : formatDate(date, DATE_FORMAT_IN_USE);

      return `${dayName.toUpperCase()} ${dateFormatted}`;
    },
  },
  computed: {
    ...mapGetters("task", [
      "groupMode",
      "selectedDates",
      "projects",
      "categories",
    ]),
    isContingentTask() {
      return (
        this.extraData?.checkForContingent && !!this.data.showContingentInToday
      );
    },
    dateValBtnClassNames() {
      return {
        textSize: true,
        "date-text-wrapper": this.isValidDateVal,
        "day-time-wrapper": this.isDayTimeFormatEnabled,
        "date-icon": !this.isValidDateVal,
        "primary-due-date": !!this.data.isReminderTask || this.isContingentTask,
      };
    },
    disableRemoveBtn() {
      return !isValidDate(this.data.dueAsDate);
    },
    isValidDateVal() {
      return isValidDate(this.data.dueAsDate);
    },

    isReminderTask() {
      return !!this.data.isReminderTask;
    },

    showRemainingDays() {
      const res = this.isInCalModes && this.isReminderTask;
      return res;
    },
    formattedDateData() {
      let resDate = "None";
      let showTaskTimeOnly = false;
      let fullDateFormatted;
      let showFullDateFormatted;

      let remainingDaysLabel;
      const useDayTimeFormatUse = this.isDayTimeFormatEnabled;

      // const selectedDates = this.selectedDates;
      const groupByKey = this.cell.api.getGroupBy();
      const isTaskDateValid = isValidDate(this.data.dueAsDate);
      const groupByCaption = this.createGroupCaption(
        groupByKey,
        this.data
      ).trim();
      const isDateCaption = checkIfTaskGroupCaptionHasDate(groupByCaption);
      if (isDateCaption && !this.data.isReminderTask) {
        showTaskTimeOnly = true;
      } else if (
        groupByCaption &&
        (groupByCaption === "Past Due" || groupByCaption === "Future") &&
        this.isSelectedDateCurrDate()
      ) {
        showTaskTimeOnly = true;
      }

      if (isTaskDateValid) {
        if (showTaskTimeOnly) {
          resDate = formatDate(this.data.dueAsDate, "h:mma");
        } else if (isSameDates(this.data.dueAsDate, new Date())) {
          if (useDayTimeFormatUse) {
            showFullDateFormatted = true;
            resDate = this.getDateInDayAndTimeFormat(this.data.dueAsDate, true);
          } else {
            resDate = formatDate(this.data.dueAsDate, "h:mma");
          }
          //
        } else {
          showFullDateFormatted = true;
          if (useDayTimeFormatUse) {
            resDate = this.getDateInDayAndTimeFormat(this.data.dueAsDate);
          } else {
            resDate = formatDate(this.data.dueAsDate, DATE_FORMAT_IN_USE);
          }
        }

        if (showFullDateFormatted) {
          fullDateFormatted = formatDate(
            this.data.dueAsDate,
            DATE_WITH_TIME_FORMAT_IN_USE
          );
        }
      }

      if (this.isContingentTask) {
        resDate = formatDate(createCurrDateOnly(), DATE_FORMAT_IN_USE);
      }

      if (this.showRemainingDays) {
        const { start: startDayOfReminder } = getReminderDaysInfoFromTask(
          this.data
        );
        const mainRemainingDaysLabel = `${this.data.reminderDays} day${
          this.data.reminderDays > 1 ? "s" : ""
        } remaining`;

        if (isSameDates(startDayOfReminder, this.data.reminderDate)) {
          remainingDaysLabel = `All of ${mainRemainingDaysLabel}`;
        } else {
          const remainingDays = differenceInDays(
            this.data.dueAsDate,
            this.data.reminderDate
          );
          remainingDaysLabel = `${remainingDays} of ${mainRemainingDaysLabel}`;
        }
      }

      const res = {
        formattedDate: resDate,
        fullDateFormatted,
        remainingDaysLabel,
        showTooltip: !!remainingDaysLabel || !!fullDateFormatted,
      };

      return res;
    },
    isDayTimeFormatEnabled() {
      return this.checkIfDueDateDayAndDateEnabled();
    },
  },
};
</script>

<style>
.due-container {
  line-height: 2;
}
.textSize {
  font-size: 12px !important;
}
.icon-btn-bg {
  background-color: #f5f5f5;
}
.date-icon {
  background-color: #f5f5f5;
  width: 78px !important;
}

.date-text-wrapper {
  width: 78px;
}

.day-time-wrapper {
  width: 100px;
}

.primary-due-date {
  color: var(--primary-darker-color) !important;
  font-style: normal;
  font-weight: 500;
}
</style>
