<template>
  <div>
    <template v-if="showBtn">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            small
            @click="toggleReminder"
            tabindex="-1"
          >
            <v-icon :color="pauseBtnIconData.color" size="16">{{
              pauseBtnIconData.icon
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ pauseBtnIconData.text }}</span>
      </v-tooltip>
    </template>
  </div>
</template>
<script>
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import { PRIMARY_COLOR } from "@/variables/colors";
// eslint-disable-next-line no-unused-vars
import { formatDate, isDateBetweenDates, parseDate } from "@/helpers/dates";
import { DATE_FORMAT_IN_USE, INTERNAL_DATE_FORMAT } from "@/variables/dates";
import {
  checkIfTaskGroupCaptionHasDate,
  checkIfTaskHasReminder,
  createReviewGroupLabel,
  extractDateFromTaskDateGroupStr,
} from "@/helpers/tasks";
import createGroupedTasksCaptions from "../helpers/createGroupedTasksCaptions";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { mapGetters } from "vuex";
import isUndefinedVal from "@/utils/isUndefinedVal";
import { getReminderDaysInfoFromTask } from "@/helpers/tasks";
import { parseISO } from "date-fns";
const currDate = new Date();

const formattedDate = formatDate(currDate, INTERNAL_DATE_FORMAT);
export default {
  mixins: [userDetailsMixin, columnPropsMixin],
  methods: {
    extractDateFromGroupCaption() {
      const groupByKey = this.cell.api.getGroupBy();
      const groupByCaption = this.createGroupCaption(
        groupByKey,
        this.data
      ).trim();
      const isDateCaption = checkIfTaskGroupCaptionHasDate(groupByCaption);
      let currDate = new Date();

      if (isDateCaption) {
        const extractedDate = extractDateFromTaskDateGroupStr(groupByCaption);
        if (extractedDate) {
          currDate = parseDate(extractedDate, DATE_FORMAT_IN_USE);
        }
      }

      return currDate;
    },
    toggleReminder(e) {
      e.stopPropagation();
      const pausedReminderDays = cloneDeep(this.data.pausedReminderDays) || [];

      const extractedDate = this.extractDateFromGroupCaption();

      const formattedDateToUse = formatDate(
        extractedDate,
        INTERNAL_DATE_FORMAT
      );
      if (!isEmpty(pausedReminderDays)) {
        const storedIndex = pausedReminderDays.indexOf(formattedDateToUse);

        if (storedIndex >= 0) {
          pausedReminderDays.splice(storedIndex, 1);
        } else {
          pausedReminderDays.push(formattedDateToUse);
        }
      } else {
        pausedReminderDays.push(formattedDateToUse);
      }

      this.handleDataUpdate(pausedReminderDays, "pausedReminderDays");
    },
    createGroupCaption(groupByKey, data) {
      if (!groupByKey) {
        return "";
      }

      const groupCaption = createGroupedTasksCaptions(
        [data],
        groupByKey,
        (groupKey) => {
          return createReviewGroupLabel(
            groupKey,
            this.projects,
            this.categories
          );
        }
      );
      return groupCaption || "";
    },
  },
  computed: {
    ...mapGetters("task", ["currNav", "isGlobalViewEnabled"]),
    pauseBtnIconData() {
      // this.isInTodayNav
      return {
        color: PRIMARY_COLOR,
        icon: "$ctiBellFilled",
        text: "Click to snooze for a day",
      };
    },
    isCurrDateInReminderDays() {
      let isInRange = false;

      if (
        this.data.due !== "none" &&
        !isUndefinedVal(this.data.reminderDays) &&
        this.data.reminderDays > 0
      ) {
        const { start: startDateOfReminder, end: dueDate } =
          getReminderDaysInfoFromTask(this.data);
        isInRange = isDateBetweenDates(
          parseISO(formattedDate),
          startDateOfReminder,
          dueDate
        );
      }

      return isInRange;
    },
    isGroupDateInReminderDays() {
      let isInReminderDays = false;

      if (checkIfTaskHasReminder(this.data) && !this.data.completed) {
        const groupByKey = this.cell.api.getGroupBy();
        const groupByCaption = this.createGroupCaption(
          groupByKey,
          this.data
        ).trim();
        const isDateCaption = checkIfTaskGroupCaptionHasDate(groupByCaption);
        let currDate = new Date();

        if (isDateCaption) {
          const extractedDate = extractDateFromTaskDateGroupStr(groupByCaption);

          if (extractedDate) {
            currDate = parseDate(extractedDate, DATE_FORMAT_IN_USE);
          }
        }

        const { start: startDateOfReminder, end: dueDate } =
          getReminderDaysInfoFromTask(this.data);
        isInReminderDays = isDateBetweenDates(
          currDate,
          startDateOfReminder,
          dueDate
        );
      }

      return isInReminderDays;
    },
    isInTodayNav() {
      return this.currNav === 3 && !this.isGlobalViewEnabled;
    },
    isInCalNav() {
      return this.currNav === 0 && !this.isGlobalViewEnabled;
    },
    showBtn() {
      return (
        (this.isInTodayNav && this.isCurrDateInReminderDays) ||
        (this.isInCalNav && !!this.data.isReminderTask)
      );
    },
  },
};
</script>
