<template>
  <div class="item-type-menu-wrapper">
    <SelectorInput
      ref="select"
      :opts="itemTypeOpts"
      :value="value"
      @input="handleValueChange"
      @keydown="handleKeyDown"
      @focus="handleFocus"
      :openOnFocus="openOnFocus"
      :menuClass="selectMenuClass"
      :disabled="false"
      class="item-type-menu"
    >
      <template #activator-btn="{ handleClick }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              class="px-1"
              @click="handleClick"
              :small="useSmallBtn"
              v-on="on"
              v-bind="{ ...attrs }"
            >
              <v-icon :color="greyIconColor" :size="itemIconSize">
                {{ itemTypeLogo }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ itemTypeTitle }}</span>
        </v-tooltip>
      </template>
      <template #item-opt="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" :class="createItemClasses(item)">
          <v-icon
            :color="greyIconColor"
            class="mr-3"
            style="width: 33px"
            :size="getIconSize(item)"
          >
            {{ item.icon }}
          </v-icon>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>
      </template>
    </SelectorInput>
  </div>
</template>

<script>
import SelectorInput from "@/components/PopupLikeSelector/PopupLikeSelector.vue";
import {
  taskTypesMap,
  taskTypeOpts,
  taskTypeOptsWithoutNoteOpt,
  itemTypeOpts,
  itemTypeMap,
} from "@/data/taskTypes";
import { resourceTypesMap, resourceTypeOptsList } from "@/data/resources";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";

export default {
  components: {
    SelectorInput,
  },
  props: {
    value: {
      type: String,
    },
    hideNoteOpt: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "task",
    },
    openOnFocus: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    iconColor: {
      type: String,
      default: DARK_GREY_ICON_COLOR,
    },
    iconSize: {
      type: [Number, String],
      default: "18",
    },
    useSmallBtn: {
      type: Boolean,
    },
  },
  computed: {
    itemTypeLogo() {
      let itemLogo;
      if (this.type === "task") {
        itemLogo = taskTypesMap[this.value]?.icon;
      } else if (this.type === "note") {
        itemLogo = itemTypeMap["note"].icon;

        if (this.value) {
          itemLogo = itemTypeMap[this.value].icon;
        }
      } else if (this.type === "resource") {
        itemLogo = resourceTypesMap[this.value]?.icon;
      }
      return itemLogo;
    },
    itemTypeTitle() {
      return this.type === "resource"
        ? resourceTypesMap[this.value]?.title
        : taskTypesMap[this.value]?.title;
    },
    itemTypeOpts() {
      let list;
      if (this.type === "task") {
        list = this.hideNoteOpt ? taskTypeOptsWithoutNoteOpt : taskTypeOpts;
      } else if (this.type === "note") {
        list = itemTypeOpts;
      } else if (this.type === "resource") {
        list = resourceTypeOptsList;
      }
      return list;
    },
    greyIconColor() {
      return this.iconColor;
    },
    selectMenuProps() {
      const props = {
        // maxHeight: `${this.itemTypeOpts.length * 32 + 17}px`,
      };

      return props;
    },
    selectMenuClass() {
      let className = "";

      if (this.type === "task") {
        className = !this.hideNoteOpt
          ? "task-type-selection-all-menu"
          : "task-type-selection-limited-menu";
      }
      return className;
    },
    itemIconSize() {
      let iconSize = this.iconSize;
      if (this.type === "task") {
        if (this.value === "work") {
          iconSize = 20;
        }
      }

      return iconSize;
    },
  },
  methods: {
    handleValueChange(val) {
      this.$emit("input", val);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    openInput() {
      this.$refs.select.openInput();
    },
    blurInput() {
      this.$refs.select.blurInput();
    },
    createItemClasses(item) {
      return {
        "cti-list-item-sm": true,
        "dotted-select-item": !!item.useDottedBorder,
      };
    },
    getIconSize(item) {
      return item.displayIconSize;
    },
  },
};
</script>
<style scoped>
.item-type-menu-wrapper >>> .v-select {
  padding-top: 0px !important;
}
</style>
