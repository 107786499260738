<template>
  <v-text-field
    class="tag-editor-input"
    ref="textInput"
    :value="value"
    hide-details
    @input="handleChange"
    @blur="handleBlur"
    placeholder="Enter Tag"
  />
</template>
<script>
export default {
  methods: {
    focusOnEl(selectText) {
      const textInputElWrapper = this.$refs.textInput;
      textInputElWrapper.focus();
      const inputEl = textInputElWrapper.$el.querySelector("input");
      // console.debug("TE", textInputElWrapper);
      if (typeof inputEl.selectionStart == "number") {
        inputEl.selectionStart = inputEl.selectionEnd = inputEl.value.length;
      } else if (typeof inputEl.createTextRange != "undefined") {
        var range = inputEl.createTextRange();
        range.collapse(false);
        range.select();
      }
      if (selectText && textInputElWrapper) {
        const textInput = textInputElWrapper.$el.querySelector("input");
        textInput.select();
      }
    },
    handleChange(e) {
      this.$emit("input", e);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
  },
  props: {
    value: {
      type: String,
    },
  },
};
</script>
<style scoped>
.tag-editor-input {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
