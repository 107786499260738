import {
  addOrRemoveOrUpdateNotesInLocalList,
  createNoteDataForAdd,
  getUserNotes,
  processNote,
} from "@/helpers/notes";
import userDetailsMixin from "./userDetailsMixin";
import { NOTES_ACTIONS } from "@/helpers/actionHistory";
import { createRestoredDataOfNote } from "@/helpers/notes";
import notesHelpersMixin from "./notesHelpersMixin";
import tasksHelpersMixin from "./tasksHelpersMixin";
import { mapGetters, mapActions } from "vuex";
import { isEmpty } from "lodash";
import { restoreTasksInGoogleCal } from "./googleCalHelpersMixins";
import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_FORM_DATA } from "@/variables/events";
import { emitRefreshList } from "@/helpers/common";

const mixin = {
  mixins: [userDetailsMixin, notesHelpersMixin, tasksHelpersMixin],
  methods: {
    ...mapActions("editForm", ["setDataForNoteEdit"]),
    async updateLocalEditNoteData(dataToSet, refreshDescrOnly) {
      if (!isEmpty(this.editNoteData) && !isEmpty(dataToSet)) {
        const currRawData = getUserNotes()
          .getNotes()
          .find((n) => n.key === dataToSet.key);
        if (!isEmpty(currRawData)) {
          this.setDataForNoteEdit({
            dataToSet: processNote({ ...currRawData, ...dataToSet }),
          });
          await this.$nextTick();
          EventEmitter.emit(REFRESH_FORM_DATA, true, refreshDescrOnly);
        }
      }
    },
    async fillDefaultAndAddNotesInList(notesToAdd) {
      if (!Array.isArray(notesToAdd)) notesToAdd = [notesToAdd];
      notesToAdd = notesToAdd.map((note) =>
        createNoteDataForAdd(note, this.notes, this.directories)
      );

      await this.addMultiNotesInNotesList(notesToAdd, false, false);
      addOrRemoveOrUpdateNotesInLocalList({
        notesToAdd: [...notesToAdd],
      });
    },
    async handleUndoOnNotes(action) {
      switch (action.type) {
        case NOTES_ACTIONS.BATCH_ADD:
          await this.removeNotesFromNotesList(action.data, false);
          addOrRemoveOrUpdateNotesInLocalList({
            notesToRemove: [...action.data],
          });
          break;
        case NOTES_ACTIONS.BATCH_DELETE:
          await this.fillDefaultAndAddNotesInList(action.data);
          break;
        case NOTES_ACTIONS.BATCH_EDIT:
          await this.restoreNotes(action.data);
          break;
        case NOTES_ACTIONS.CONVERTED_TO_TASKS:
          await this.handleNotesConvertedToTasks(action.data);
          break;

        default:
        // Do nothing
      }
    },
    async handleRedoOnNotes(action) {
      switch (action.type) {
        case NOTES_ACTIONS.BATCH_ADD:
          await this.fillDefaultAndAddNotesInList(action.data);
          break;
        case NOTES_ACTIONS.BATCH_DELETE:
          await this.removeNotesFromNotesList(action.data, false);
          addOrRemoveOrUpdateNotesInLocalList({
            notesToRemove: [...action.data],
          });
          break;
        case NOTES_ACTIONS.BATCH_EDIT:
          await this.restoreNotes(action.data, true);
          break;
        case NOTES_ACTIONS.CONVERTED_TO_TASKS:
          await this.handleNotesConvertedToTasks(action.data, true);
          break;
        default:
        // Do nothing
      }
    },
    async handleNotesConvertedToTasks(data, isRedoAction) {
      const { notesToRemoveData, tasksToAddData } = data;

      let refreshNotes = false;
      if (isRedoAction) {
        await this.removeNotesFromNotesList(notesToRemoveData, false);
        this.restoreGoogleTasks(tasksToAddData);
        await this.fillTaskDataThenAddInList(tasksToAddData);
        refreshNotes = true;
      } else {
        await this.removeTasksFromTaskList(tasksToAddData, false);
        await this.fillDefaultAndAddNotesInList(notesToRemoveData);
      }

      if (refreshNotes) {
        emitRefreshList(true, "notes");
      }
    },
    restoreGoogleTasks(tasks) {
      if (this.isGoogleCalSyncEnabled) {
        restoreTasksInGoogleCal(tasks);
      }
    },
    async restoreNotes(notesToRestore, isRedoAction) {
      if (!Array.isArray(notesToRestore)) notesToRestore = [notesToRestore];
      let updatedDataForLocalEditForm = {};
      const updatesForTable = [];
      let refreshDescrOnly = false;
      if (isRedoAction) {
        notesToRestore = notesToRestore.map((note) => {
          updatesForTable.push({
            updates: { ...note.changedProps },
            key: note.key,
          });

          if (
            !isEmpty(this.editNoteData) &&
            this.editNoteData.key === note.key
          ) {
            refreshDescrOnly = !!note.refreshDescr;
            updatedDataForLocalEditForm = {
              ...note.changedProps,
              key: note.key,
            };
          }
          return {
            updates: createRestoredDataOfNote(
              note.changedProps,
              this.directories
            ),
            key: note.key,
          };
        });
      } else {
        notesToRestore = notesToRestore.map((note) => {
          updatesForTable.push({
            updates: { ...note },
            key: note.key,
          });

          if (
            !isEmpty(this.editNoteData) &&
            this.editNoteData.key === note.key
          ) {
            refreshDescrOnly = !!note.refreshDescr;
            updatedDataForLocalEditForm = {
              ...note,
              key: note.key,
            };
          }
          return {
            updates: createRestoredDataOfNote(note, this.directories),
            key: note.key,
          };
        });
      }

      await this.updateMultiNotesInNotesList(notesToRestore, false);

      addOrRemoveOrUpdateNotesInLocalList({
        notesToUpdate: [...updatesForTable],
      });

      this.updateLocalEditNoteData(
        updatedDataForLocalEditForm,
        refreshDescrOnly
      );
    },
  },
  computed: {
    ...mapGetters("note", ["directories", "notesTree", "notes"]),
    ...mapGetters("editForm", {
      editNoteData: "noteData",
    }),
    ...mapGetters(["isGoogleCalSyncEnabled"]),
  },
};

export default mixin;
