import listOptsActions from "./actions";
import listOptsMutations from "./mutations";
import listOptsGetters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      projectOpts: [],
      taskTypeOpts: []
    };
  },
  mutations: listOptsMutations,
  actions: listOptsActions,
  getters: listOptsGetters,
};
