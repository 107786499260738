export default {
  taskTypeOpts(state) {
    return state.taskTypeOpts;
  },
  projectOpts(state) {
    return state.projectOpts;
  },
  taskTypeOptsMap(state) {
    const map = state.taskTypeOpts.reduce((a, v) => {
      a[v.key] = v;
      return a;
    }, {});
    return map;
  }
};
