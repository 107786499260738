import { setCursorInEle } from "@/utils/elements";
import { decrement } from "@/utils/number";
const mixin = {
  props: {
    cell: {
      type: Object,
    },
    data: {
      type: Object,
    },
    fieldName: {
      type: String,
    },
    rowId: {
      type: String,
    },
    onDataUpdate: {
      type: Function,
    },
    onSuccess: {
      type: Function,
    },
    onCancel: {
      type: Function,
    },
    onAdd: {
      type: Function,
    },
    getSelectedRecords: {
      type: Function,
    },
    onRendered: {
      type: Function,
    },
    isRowSelected: {
      type: Boolean,
    },
    isRowEditing: {
      type: Boolean,
    },
    extraData: {
      type: Object,
    },
    rowIndex: {
      type: Number,
    },
    rowHovered: {
      type: Boolean,
    },
    rowSelected: {
      type: Boolean,
    },
  },
  methods: {
    handleDataUpdate(val, fieldName = this.fieldName) {
      this.onDataUpdate(this.rowId, fieldName, val, this.cell, this.cell.api);
    },
    checkIfCellIsDisabled(colData, rowData) {
      let isDisabled = false;

      if (this.cell.isCellDisabled) {
        isDisabled = this.cell.isCellDisabled(colData, rowData);
      }
      return isDisabled;
    },
    isCellLastEditable() {
      let isLastCell = false;
      const currentCell = this.$el.parentNode.parentNode;
      const editedRow = currentCell.closest(".row-editing");
      if (editedRow) {
        const editableCols = editedRow.querySelectorAll(".editing-cell");
        if (currentCell === editableCols[decrement(editableCols.length, 1)]) {
          isLastCell = true;
        }
      }

      return isLastCell;
    },
    async handleKeyDown(e) {
      if (e.keyCode === 9) {
        let nextCell = null;
        const currentCell = this.$el.parentNode.parentNode;
        let callEndEdit = false;
        if (e.shiftKey) {
          if (this.cell.previousCell) {
            nextCell = currentCell.parentNode.querySelector(
              `.${this.cell.previousCell}-cell`
            );
          } else {
            nextCell =
              currentCell?.previousSibling ||
              currentCell?.previousElementSibling;
          }
        } else {
          if (this.isCellLastEditable()) {
            callEndEdit = true;
          } else if (this.cell?.nextCell) {
            nextCell = currentCell.parentNode.querySelector(
              `.${this.cell.nextCell}-cell`
            );
          } else {
            nextCell =
              currentCell?.nextSibling || currentCell?.nextElementSibling;
          }
        }

        if (this.$refs.select) {
          if (this.$options.useOldSelectMethods) {
            this.$refs.select.blur();
          } else {
            this.$refs.select.blurInput();
          }
        }

        e.stopPropagation();
        await this.$nextTick();

        if (callEndEdit) {
          e.preventDefault();
          this.cell.api.endEditing();
        } else {
          setTimeout(() => {
            if (nextCell) {
              let input = nextCell.querySelector("input");
              let setCursor = false;
              if (!input && nextCell.querySelector("[contenteditable=true]")) {
                input = nextCell.querySelector("[contenteditable=true]");
                setCursor = true;
              }

              // console.debug("SSS", setCursor);
              if (setCursor) {
                setTimeout(() => {
                  setCursorInEle(input, true);
                  input?.focus();
                }, 0);
              } else {
                input?.focus();
                if (input?.select) {
                  input.select();
                }
              }
            }
          }, 0);
        }
      }
    },
    triggerKeyDown(e) {
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
  },
  computed: {
    isCellEditDisabled() {
      return this.checkIfCellIsDisabled(this.cell, this.data);
    },
  },
};

export default mixin;
