<template>
  <div class="wrapper">
    <div v-if="!data.addNew" style="height: 100%">
      <TaskStatusBtnVue
        :color="baseColor"
        :gridApi="cell.api"
        :data="data"
        :showPosition="showPosition"
        :showScheduledBtn="showScheduledBtn"
        :checkForContingent="checkContingentBtn"
        :showUnscheduledIcon="showUnscheduledIcon"
      />

      <!-- <div v-if="isTaskInCustomOrderGroup">
        <OpenTaskDetails
          @click="openEditForm"
          :disabled="areaSwitchTooltipDisabled"
        >
          <div
            class="task-order-number-text-wrapper"
            :style="createOrderStyles()"
          >
            {{ itemPos }}
          </div>
        </OpenTaskDetails>
      </div> -->

      <!-- </template> -->
      <!-- <template v-if="isFlagVisible">
        <FlagToggler
          v-if="flagEditAllowed"
          :value="data.flag"
          @input="toggleFlag"
          :isFocusable="false"
        />
        <v-icon v-else color="orange" :size="22">mdi-flag </v-icon>
      </template> -->

      <!-- <template v-else-if="isScheduleIconVisible">
        <v-icon :color="scheduledIconColor" :size="22"> $ctiSchedule </v-icon>
      </template> -->

      <!-- <template v-if="isTaskInCalAndWithValidArea">
        <OpenTaskDetails
          @click="openEditForm"
          :disabled="isScheduledTooltipDisabled"
          btnClass="scheduled-btn"
        >
          <v-icon v-if="showContingentIcon" :color="baseColor" :size="17">
            $ctiCalendarCross
          </v-icon>
          <v-icon v-else :color="scheduledIconColor" :size="22">
            $ctiSchedule
          </v-icon>
        </OpenTaskDetails>
      </template> -->

      <!-- <div v-show="isTaskInUnscheduledGroup">
        <OpenTaskDetails
          @click="openEditForm"
          :disabled="areaSwitchTooltipDisabled"
          btnClass="unscheduled-btn"
        >
          <v-icon :color="unscheduledIconColor" :size="16">
            $ctiHourglass
          </v-icon>
        </OpenTaskDetails>
      </div> -->

      <!-- <ContingentToggler
        :value="!!data.isContingent"
        :isFocusable="false"
        :cssClass="contingentBtnClass"
        @input="toggleContingent"
        :disabled="isCellEditDisabled"
      /> -->
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import {
  addOrRemoveOrUpdateTasksInLocalTasksList,
  checkIfSelectedTaskCateHasOnlyArea,
  createTaskId,
  getMainTaskRulesAndFillRecurringOpts,
  getTaskTreeView,
} from "@/helpers/tasks";
import {
  calModes,
  flagViews,
  groupViewsWithFlag,
  treeSideBarModes,
} from "@/variables/viewConfigs";
import { mapActions, mapGetters, mapMutations } from "vuex";
import taskHelpersMixin from "@/mixins/tasksHelpersMixin";
import { DARK_GREY_ICON_COLOR, PRIMARY_COLOR } from "@/variables/colors";
import FlagToggler from "@/components/FlagToggler";
// import ContingentToggler from "@/components/ContingentToggler";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import EventEmitter from "@/helpers/eventEmitter";
import {
  OPEN_INSPECT_MODE,
  // OPEN_SELECTED_ITEM,
  // eslint-disable-next-line no-unused-vars
  OPEN_SELECTED_TASK_ITEM,
  // eslint-disable-next-line no-unused-vars
  SET_SELECTED_NODE_EVENT,
  // eslint-disable-next-line no-unused-vars
  TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT,
  OPEN_SELECTED_NEW_EDIT_TASK_FORM,
  OPEN_STATUS_EDIT_MODE,
} from "@/variables/events";
import isUndefinedVal from "@/utils/isUndefinedVal";
import DatabaseInterface from "@/services/DatabaseInterface";
import { checkIfSelectedResourceCateHasCategoryOnly } from "@/helpers/resources";
import OpenTaskDetails from "@/components/TasksListView/components/TasksList/components/OpenTaskDetails.vue";
import TaskStatusBtnVue from "@/components/TasksListView/components/TasksList/components/TaskStatusBtn.vue";
// import { increment } from "@/utils/number";
export default {
  components: {
    FlagToggler,
    OpenTaskDetails,
    TaskStatusBtnVue,
    // ContingentToggler,
  },
  mixins: [userDetailsMixin, columnPropsMixin, taskHelpersMixin],
  name: "actionsTemplate",
  props: {},
  data: function () {
    return {};
  },
  methods: {
    ...mapActions("editForm", ["showNewEditTaskDialog"]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions("task", ["changeNav"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    ...mapActions("resourceInfo", ["closeResourceInfoViewer"]),
    ...mapActions("toast", {
      showToast: "showToast",
    }),
    openEditForm() {
      this.openTaskDataInEditForm({
        taskData: { ...this.data },
        gridApi: this.cell.api,
        isInNextActionsMode: this.isInNextActionsMode,
      });
      // if (this.$route.name === "Resources") {
      //   this.goToNextActionsFromResourceMode(true);
      //   return;
      // }

      // const processedTaskDataRes = this.processTaskDataForEditForm({
      //   ...this.data,
      // });

      // const taskDataToUse = processedTaskDataRes.processedTaskData;

      // if (!processedTaskDataRes.isValid) {
      //   this.showToast("Invalid task data");
      //   return;
      // }

      // if (processedTaskDataRes.showTaskInForm) {
      //   addOrRemoveOrUpdateTasksInLocalTasksList(
      //     {
      //       tasksToUpdate: [
      //         {
      //           key: taskDataToUse.key,
      //           updates: { showInForm: true },
      //         },
      //       ],
      //       doNotProcessTaskForUpdate: true,
      //     },
      //     false
      //   );
      // }

      // this.handledNewEditForm(this.isTaskEditFormOpened);

      // if (!this.isTaskEditFormOpened) {
      //   console.log("isTaskEditFormOpened", taskDataToUse);
      //   this.showNewEditTaskDialog({
      //     taskData: { ...taskDataToUse },
      //     selectedTasks: [],
      //   });
      // } else {
      //   EventEmitter.emit(
      //     OPEN_SELECTED_NEW_EDIT_TASK_FORM,
      //     getMainTaskRulesAndFillRecurringOpts({ ...this.data })
      //   );
      // }
    },
    async handledNewEditForm(openInEditForm) {
      let taskDataToUse = { ...this.data };

      this.cell.api.setRowIdsForScroll(taskDataToUse.key);
      await Vue.nextTick();

      let triggeredFrom = "next-actions";

      if (!this.isInNextActionsMode) {
        triggeredFrom = this.currNav === 3 ? "today" : "cal";
      }

      if (!this.isNewEditFormOpend) {
        EventEmitter.emit(OPEN_STATUS_EDIT_MODE, {
          triggeredFrom,
          taskData: { ...taskDataToUse },
          opts: {
            openProject: true,
            openTask: true,
          },
        });
      } else {
        EventEmitter.emit(
          OPEN_SELECTED_NEW_EDIT_TASK_FORM,
          getMainTaskRulesAndFillRecurringOpts({ ...this.data })
        );
      }
    },
    async handleScheduledClick() {
      if (
        this.extraData?.disableAreaSwitch ||
        this.isScheduledTooltipDisabled ||
        (this.isInNextActionsMode &&
          checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList))
      )
        return;

      let taskDataToUse = { ...this.data };
      let triggerInspect = true;

      if (this.data.isReminderTask && this.data.reminderLinkedTo) {
        const parentTaskData = this.tasks.find(
          (t) => t.key === this.data.reminderLinkedTo
        );

        if (!isEmpty(parentTaskData)) {
          taskDataToUse = {
            ...parentTaskData,
            reminderDate: this.data.reminderDate,
          };
        } else {
          triggerInspect = false;
        }
      }

      if (!triggerInspect) return;
      const isInResourceMode = this.$route.name === "Resources";
      if (isInResourceMode) {
        this.goToNextActionsFromResourceMode();
        return;
      }

      // if (checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList)) {
      //   return;
      // }

      // if (this.isTaskInCalAndWithValidArea) {
      this.updateState({
        selectedTasks: [{ ...taskDataToUse }],
      });
      this.cell.api.setRowIdsForScroll(taskDataToUse.key);
      await Vue.nextTick();

      let triggeredFrom = "next-actions";

      if (!this.isInNextActionsMode) {
        triggeredFrom = this.currNav === 3 ? "today" : "cal";
      }
      EventEmitter.emit(OPEN_STATUS_EDIT_MODE, {
        triggeredFrom,
        taskData: { ...taskDataToUse },
        opts: {
          openProject: true,
        },
      });

      // }
    },
    async goToNextActionsFromResourceMode(openForm) {
      const dbUpdates = {
        notesView: false,
        activeNavView: "dashboard",
      };

      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      this.closeResourceInfoViewer();

      const modeData = {};

      modeData.type = checkIfSelectedResourceCateHasCategoryOnly(
        this.selectedResrcCategories
      )
        ? "grid"
        : "form";

      if (modeData.type === "form") {
        modeData.resourceIdToOpen = this.selectedResrcCategories[0];
      }

      let viewItemId, viewItemDate;

      const taskDataViewed = { ...this.data };
      if (
        taskDataViewed.isVirtual &&
        this.rawTasksMap[taskDataViewed.linkedTo]
      ) {
        viewItemId = taskDataViewed.linkedTo;
        viewItemDate = taskDataViewed.dueAsDate;
      } else if (!taskDataViewed.isVirtual) {
        viewItemId = taskDataViewed.key;
      }
      this.$router.replace({
        name: "Dashboard",
        params: {
          inspectTaskData: {
            id: viewItemId,
            date: viewItemDate,
            viewFrom: "resources",
            selectTask: true,
            returnInfo: {
              to: "Resources",
              modeData,
              taskData: {
                key: viewItemId,
                date: viewItemDate,
              },
            },
            openForm,
            showNewDialog: true,
          },
        },
      });
      // await this.$nextTick();
      // this.handledNewEditForm(true);
    },
    async handleOrderClick() {
      if (this.extraData?.disableAreaSwitch) return;

      const isInResourceMode = this.$route.name === "Resources";

      if (
        !isInResourceMode &&
        checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList)
      ) {
        return;
      }

      if (isInResourceMode) {
        this.goToNextActionsFromResourceMode();
        return;
      }

      const activeTaskTreeViewData = getTaskTreeView();
      const inactiveTaskTreeViewData = getTaskTreeView("inactive");
      // const catTreeEl = activeTaskTreeViewData.element;
      // eslint-disable-next-line no-unused-vars
      const catTreeView = activeTaskTreeViewData.treeInstance;
      // eslint-disable-next-line no-unused-vars
      const inactiveTree = inactiveTaskTreeViewData.treeInstance;

      // let items = [],
      //   nodes = [];

      // let nodeId = catTreeEl.querySelector(
      //   '[data-uid="' + this.data.project + '"]'
      // );

      // let level = nodeId.getAttribute("aria-level");
      // // if (targetNodeId != null) {
      // items.push(catTreeEl.querySelectorAll('[aria-level="' + level + '"]'));
      // for (var i = 0; i < items[0].length; i++) {
      //   nodes.push(items[0][i].getAttribute("data-uid"));
      // }
      // // }

      // console.debug("CTT", catTreeView);
      // console.debug("IN", inactiveTree);
      // EventEmitter.emit(TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT, false);
      // catTreeView?.expandAll([this.data.project]);
      // inactiveTree?.expandAll([this.data.project]);
      // // catTreeView.expandedNodes = [this.data.project];
      await Vue.nextTick();

      this.cell.api.setRowIdsForScroll(this.data.key);
      await Vue.nextTick();
      EventEmitter.emit(OPEN_STATUS_EDIT_MODE, {
        triggeredFrom: "next-actions",
        taskData: { ...this.data },
      });

      await Vue.nextTick();
      catTreeView?.expandAll([this.data.project]);
      inactiveTree?.expandAll([this.data.project]);
      // catTreeView.expandedNodes = [this.data.project];
      // EventEmitter.emit(
      //   SET_SELECTED_NODE_EVENT,
      //   [this.data.project],
      //   true,
      //   true
      // );

      setTimeout(() => {
        catTreeView?.ensureVisible(this.data.project);
      });
    },
    toggleFlag(val) {
      if (this.data.due && this.data.due !== "none") {
        return;
      }
      this.handleDataUpdate(val);
    },
    toggleContingent() {
      this.handleDataUpdate(!this.data.isContingent, "isContingent");
    },

    async saveTask() {
      const currData = this.cell.getData();
      const createdTaskKey = createTaskId();
      if (currData.taskType && currData.taskType === "note") {
        this.addTaskAsNote({
          ...currData,
          key: createdTaskKey,
        });
        return;
      }

      currData.key = createdTaskKey;
      const addedTaskData = await this.addNewTaskEntry(currData);
      this.cell.api.removeNewRow();
      this.cell.api.triggerAddRow(addedTaskData);
    },
    cancel() {
      this.cell.api.removeNewRow();
    },
    checkIfSchduledItemExists() {
      let currGroupKeyData = this.cell.api.getGroupBy();

      if (!isEmpty(currGroupKeyData) && isPlainObject(currGroupKeyData)) {
        currGroupKeyData = currGroupKeyData.key;
      }
      const allTasksList = this.cell.api.getList();
      let isItemExists = false;
      if (!isEmpty(allTasksList)) {
        isItemExists = allTasksList.some((t) => t[currGroupKeyData] === "0");
      }

      return isItemExists;
    },
    checkIfOrderNumberIsVisible() {
      let currGroupKeyData = this.cell.api.getGroupBy();

      if (!isEmpty(currGroupKeyData) && isPlainObject(currGroupKeyData)) {
        currGroupKeyData = currGroupKeyData.key;
      }

      if (this.isInInboxViewOrGlobalEnabled) {
        return false;
      }

      if (
        this.currNav === 4 &&
        (currGroupKeyData === "ReviewGroup" ||
          currGroupKeyData === "CategoryGroup") &&
        this.data.isCustomPositioned
      ) {
        return true;
      }

      return (
        (this.currNav === 4 || this.extraData?.showPosition) &&
        (currGroupKeyData === "" || currGroupKeyData === "CTICustomGroup") &&
        this.data.CTICustomGroup === "1" &&
        this.data.isCustomPositioned
      );
    },
    createOrderStyles() {
      let styles = {
        backgroundColor: this.baseColor,
      };

      return styles;
    },
  },
  computed: {
    ...mapGetters("resourcesData", {
      selectedResrcCategories: "selectedCategories",
    }),
    ...mapGetters("editForm", {
      isTaskEditFormOpened: "isOpen",
      isNewEditFormOpend: "openNewForm",
    }),
    ...mapGetters("task", [
      "projects",
      "currNav",
      "isGlobalViewEnabled",
      "isInboxViewEnabled",
      "rawTasks",
      "rawTasksMap",
      "groupMode",
      "areaWiseSortedTasks",
      "areaWiseSortedTasksGroups",
      "selectedOptsList",
    ]),
    ...mapGetters(["isGoogleCalSyncEnabled"]),

    showPosition() {
      return this.currNav === 4 || this.extraData?.showPosition;
    },
    flagText() {
      return this.data.flag ? "Unflag Item" : "Flag Item";
    },
    contingentText() {
      return this.data.isContingent
        ? "Unmark as contingent"
        : "Mark as contingent";
    },
    isGroupModeWithFlag() {
      return groupViewsWithFlag.includes(this.groupMode);
    },
    isFlagVisible() {
      // const flagIsAllowedInView =
      //   flagViews.includes(this.currNav) || !!this.isGlobalViewEnabled;
      // const isGroupModeWithFlag = this.isGroupModeWithFlag;

      //       flagIsAllowedInView &&
      // isGroupModeWithFlag &&
      // !this.isTaskInCustomOrderGroup &&
      // !this.isTaskInScheduledGroup
      return false;
    },
    isScheduleIconVisible() {
      const flagIsAllowedInView =
        flagViews.includes(this.currNav) || !!this.isGlobalViewEnabled;
      const isGroupModeWithFlag = this.isGroupModeWithFlag;
      return (
        flagIsAllowedInView &&
        isGroupModeWithFlag &&
        this.isTaskInScheduledGroup &&
        !this.checkIfOrderNumberIsVisible()
        // !this.isTaskInCustomOrderGroup
      );
    },
    isInCalModes() {
      return (
        calModes.includes(this.currNav) &&
        !this.isGlobalViewEnabled &&
        !this.isInboxViewEnabled
      );
    },
    isTaskWithArea() {
      return !isUndefinedVal(this.data.project);
    },
    isInInboxViewOrGlobalEnabled() {
      return (
        this.isGlobalViewEnabled ||
        (treeSideBarModes.includes(this.currNav) && this.isInboxViewEnabled)
      );
    },
    showScheduledBtn() {
      return (
        this.extraData.showScheduledIcon ||
        this.isInCalModes ||
        this.isInNextActionsMode ||
        this.isInInboxViewOrGlobalEnabled
      );
    },
    isTaskInCalAndWithValidArea() {
      return (
        (this.extraData.showScheduledIcon ||
          this.isInCalModes ||
          this.isInNextActionsMode ||
          this.isInInboxViewOrGlobalEnabled) &&
        this.isTaskWithArea &&
        (this.data.due !== "none" || !!this.data.showContingentInToday)
      );
    },
    isInNextActionsMode() {
      return (
        treeSideBarModes.includes(this.currNav) && !this.isInboxViewEnabled
      );
    },
    contingentBtnClass() {
      return !this.data.isContingent ? "contingent-toggle-btn" : "";
    },
    flagEditAllowed() {
      return this.data.due === "none" || !this.data.due;
    },
    flagIconData() {
      let icon = "mdi-flag-outline";
      let color = DARK_GREY_ICON_COLOR;

      if (this.data.flag) {
        icon = "mdi-flag";
        color = "orange";
      }
      return {
        icon,
        color,
      };
    },
    showPositionNumb() {
      const res =
        // (treeSideBarModes.includes(this.currNav) === 1 &&
        //   !this.isGlobalViewEnabled &&
        //   this.data.positionChanged) ||
        this.isTaskInCustomOrderGroup;

      return res;
    },
    isTaskInCustomOrderGroup() {
      return this.checkIfOrderNumberIsVisible();
    },
    isTaskInScheduledGroup() {
      let currGroupKeyData = this.cell.api.getGroupBy();

      if (!isEmpty(currGroupKeyData) && isPlainObject(currGroupKeyData)) {
        currGroupKeyData = currGroupKeyData.key;
      }

      return (
        this.currNav === 4 &&
        ((currGroupKeyData === "CTICustomGroup" &&
          this.data[currGroupKeyData] === "0") ||
          (currGroupKeyData === "ReviewGroup" && this.data.due !== "none"))
      );
    },
    scheduledGroupData() {
      const scheduledGroupRowData = this.cell.api.getGroupRow("0");
      return scheduledGroupRowData;
    },
    itemPos() {
      let pos;

      //   if (this.showPositionNumb && !this.isTaskInCustomOrderGroup) {
      //   pos = this.rowIndex;
      // } else if
      if (this.showPositionNumb && this.isTaskInCustomOrderGroup) {
        pos = this.data.orderedTaskNumber;

        //     this.areaWiseSortedTasksGroups.indexOf(orderPropValue);
        // console.debug("POS", getTextContentFromHTML(this.data.title), pos);
        // let groupIndex = 0;
        // let taskIndexToUse = 0;
        // // let rowGroupIndex = 0;
        // const scheduledGroupRowData = this.scheduledGroupData;

        // if (scheduledGroupRowData?.dataCount !== undefined) {
        //   if (this.checkIfSchduledItemExists()) {
        //     groupIndex = scheduledGroupRowData.dataCount;
        //   }
        //   groupIndex = increment(groupIndex, 1);
        // }
        // // // const currRowIndex = this.cell.api.findIndexOfRowByKey(this.data.key);
        // const currData = this.data;
        // const orderPropValue = `${currData["customPosition"]}-${currData["customPositionIndex"]}`;
        // const firstAreaItemKey = this.areaWiseSortedTasks[orderPropValue]?.[0];

        // console.debug("FII", firstAreaItemKey);
        // if (!isEmpty(firstAreaItemKey)) {
        //   const taskIndex = this.cell.api.findIndexOfRowByKey(firstAreaItemKey);

        //   if (taskIndex >= 0) {
        //     taskIndexToUse = taskIndex;
        //   }
        // }

        // console.debug("MAIN ITEM", taskIndexToUse);
        // console.debug("SCEHDULE", groupIndex);
        // console.debug("GROUP VAL", orderPropValue);

        // if (this.areaWiseSortedTasksGroups.includes(orderPropValue)) {
        //   // pos = increment(
        //   //   this.areaWiseSortedTasksGroups.indexOf(orderPropValue)
        //   // );

        //   const indexToUse =
        //     this.areaWiseSortedTasksGroups.indexOf(orderPropValue);
        //   console.debug("INDEX", indexToUse);
        //   if (this.isAreaSelected) {
        //     pos = increment(indexToUse);
        //   } else {
        //     pos = taskIndexToUse - groupIndex - indexToUse;
        //     // pos = indexToUse
        //   }

        //   // if(this.isOnlyAreaSelected){
        //   //   pos = increment(
        //   //   this.areaWiseSortedTasksGroups.indexOf(orderPropValue)
        //   // );
        // }

        // console.debug("TDJJ", groupIndex, taskIndexToUse);

        // pos = taskIndexToUse - groupIndex;
        // if (currRowIndex >= 0) {
        //   pos = increment(currRowIndex - groupIndex);
        // }
        // const scheduledGroupRowData = this.scheduledGroupData;
        // let groupIndex = 0;
        // if (scheduledGroupRowData?.dataCount !== undefined) {
        //   if (this.checkIfSchduledItemExists()) {
        //     groupIndex = scheduledGroupRowData.dataCount;
        //   }
        //   groupIndex = increment(groupIndex, 1);
        // }

        // pos =
      }
      return pos;
    },
    contingentIcon() {
      let icon = "$ctiContingentNoDue";
      let color;
      if (this.data.isContingent) {
        color = PRIMARY_COLOR;
      } else {
        color = DARK_GREY_ICON_COLOR;
      }

      return {
        icon,
        color,
      };
    },
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
    onlyAreaIsSelected() {
      return (
        this.isInNextActionsMode &&
        checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList)
      );
    },
    areaSwitchTooltipDisabled() {
      return this.extraData?.disableAreaSwitch || this.onlyAreaIsSelected;
    },
    isScheduledTooltipDisabled() {
      return (
        !!this.extraData?.disableScheduledTooltip || this.onlyAreaIsSelected
      );
    },
    baseColor() {
      let color = "orange";
      if (this.extraData?.useDynamicColor && this.extraData?.colorProvider) {
        color = this.extraData.colorProvider(this);
      }

      return color;

      // useDynamicColor: true,
      // colorProvider: (cell) => {
      //   return getTaskRowHandlerColor(cell.data.priority);
      // },
    },

    scheduledIconColor() {
      return this.extraData?.scheduledIconColor || this.baseColor;
    },
    unscheduledIconColor() {
      return this.extraData?.unscheduledIconColor || this.baseColor;
    },
    scheduledBtnLabel() {
      // return this.isInCalModes
      //   ? "Click to view task in its area"
      //   : "Click to view task in its due date";
      // "Click to view task in its area"
      return "Open Task Details";
    },

    showUnscheduledIcon() {
      return this.extraData?.showUnscheduledIcon;
    },
    isTaskInUnscheduledGroup() {
      let currGroupKeyData = this.cell.api.getGroupBy();

      if (!isEmpty(currGroupKeyData) && isPlainObject(currGroupKeyData)) {
        currGroupKeyData = currGroupKeyData.key;
      }

      if (this.showContingentIcon) return false;
      if (
        this.extraData?.showUnscheduledIcon &&
        this.data.due == "none" &&
        this.data.CTICustomGroup === "2"
      ) {
        return true;
      }
      if (
        this.isInInboxViewOrGlobalEnabled &&
        (this.data.due === "none" || isEmpty(this.data.project))
      ) {
        return true;
      }

      return (
        (currGroupKeyData === "" || currGroupKeyData === "CTICustomGroup") &&
        this.data.CTICustomGroup === "2" &&
        !isEmpty(this.data.project)
      );
    },

    checkContingentBtn() {
      return this.extraData?.checkForContingent;
    },

    showContingentIcon() {
      return (
        this.extraData?.checkForContingent && !!this.data.showContingentInToday
      );
    },
  },
};
</script>
<style scoped>
.wrapper {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  height: 100%;
}
.wrapper >>> .contingent-toggle-btn {
  display: none;
}

.task-order-number-wrapper {
  text-align: center;
  height: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.task-order-btn-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.scheduled-btn {
  margin-left: -2px;
}
</style>
