import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.cssClass,attrs:{"icon":"","small":"","tabindex":_vm.tabIndexToUse,"disabled":_vm.disabled},on:{"click":_vm.handleClick,"keydown":_vm.handleKeyDown}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.iconData.color,"size":_vm.iconSize}},[_vm._v(" "+_vm._s(_vm.iconData.icon)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.iconData.label))])])}
var staticRenderFns = []

export { render, staticRenderFns }