<template>
  <v-dialog
    width="20%"
    :value="isSelectorOpen"
    @click:outside="closeDialog"
    @keydown.stop="handleKeyDown"
    content-class="convert-dialog"
  >
    <v-card>
      <v-card-title class="text-h5"> {{ labelText }} </v-card-title>
      <v-card-text>
        <div>
          <div v-if="typeToUse === 'task'">
            <ProjectSelector
              ref="areaSelector"
              :value="selectedVal"
              @change="handleChange"
              @keydown="handleSelectorKeyDown"
            />
          </div>
          <div v-if="typeToUse === 'note'">
            <NoteFolderSelector
              ref="topicSelector"
              :value="selectedVal"
              @change="handleChange"
              @keydown="handleSelectorKeyDown"
              placeholder="Select Topic"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="save-confirm-btn"
          depressed
          text
          @click="submitData"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import NoteFolderSelector from "@/components/EditComponents/NoteFolderSelector";
import ProjectSelector from "@/components/EditComponents/ProjectSelector";
import { mapActions, mapGetters } from "vuex";
import EventEmitter from "@/helpers/eventEmitter";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { CLOSE_INLINE_ADD, CONVERT_SUCCESS } from "@/variables/events";
import { getDateAndTimeFromDueDate } from "@/helpers/tasks";
import isEscKey from "@/utils/isEscKey";
import { isSubmitKeys, isTabKey } from "@/utils/keys";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
import popupHelpersMixin from "@/mixins/popupHelpersMixin";
export default {
  mixins: [tasksHelpersMixin, popupHelpersMixin],
  components: {
    ProjectSelector,
    NoteFolderSelector,
  },
  data() {
    return {
      selectedVal: "root",
    };
  },
  methods: {
    ...mapActions("convertModeData", ["resetDataOfConvertMode"]),

    handleSelectorKeyDown(e) {
      e.stopPropagation();
      setTimeout(() => {
        this.handleKeyDown(e, "input");
      }, 0);
    },
    async submitData() {
      const typeToUse = this.typeToUse;
      const selectedVal = this.selectedVal;
      const allProjects = this.projects;
      let dataList = this.dataList;
      let convertedType;
      switch (typeToUse) {
        case "note":
          convertedType = "note";
          dataList = dataList.map((dataItem) => ({
            ...dataItem,
            path: this.selectedVal,
          }));
          this.convertNormalOrRecurringTasksToNotes(dataList, true);
          break;
        case "task":
          convertedType = "task";
          dataList = dataList.map((dataItem) => {
            const createdData = {
              ...dataItem,
              project: selectedVal,
              ...getDateAndTimeFromDueDate(new Date()),
            };
            if (
              createdData.project &&
              allProjects[createdData.project] &&
              allProjects[createdData.project].priority
            ) {
              createdData.priority = allProjects[createdData.project].priority;
            } else if (!createdData.project) {
              createdData.priority = "purpose";
            }

            return createdData;
          });
          this.convertNotesToTasks(dataList);
          break;
      }
      this.closeDialog();

      await Vue.nextTick();
      EventEmitter.emit(CLOSE_INLINE_ADD);

      if (this.isEditFormOpen) {
        EventEmitter.emit(
          CONVERT_SUCCESS,
          convertedType,
          [...dataList],
          this.calledFrom
        );
      }
    },
    handleChange(val) {
      this.selectedVal = val;
    },
    closeDialog() {
      this.selectedVal = "";
      this.resetDataOfConvertMode();
    },
    handleKeyDown(e) {
      e.stopPropagation();
      if (isEscKey(e)) {
        this.closeDialog();
      }

      if (isSubmitKeys(e)) {
        const submitBtnParentFound = findParentFromPathsByClassName(
          getPathsFromEvent(e),
          ["save-confirm-btn"]
        );
        if (submitBtnParentFound && !e.shiftKey) {
          this.submitData();
          return;
        }
      }

      if (isTabKey(e)) {
        const selectorRef =
          this.typeToUse === "note" ? "topicSelector" : "areaSelector";
        const el = this.$refs[selectorRef];
        el?.blurInput?.();
        const btn = document.querySelector(".convert-dialog .save-confirm-btn");
        btn?.focus();
      }
    },
    focusOnInput() {
      let selectorRef;

      if (this.typeToUse === "task") {
        selectorRef = "areaSelector";
      } else {
        selectorRef = "topicSelector";
      }

      this.focusOnSelector(selectorRef);
    },
  },
  computed: {
    ...mapGetters("convertModeData", [
      "isSelectorOpen",
      "dataList",
      "calledFrom",
      "typeToUse",
    ]),
    ...mapGetters("task", ["projects"]),
    ...mapGetters("editForm", {
      isEditFormOpen: "isOpen",
    }),
    labelText() {
      return this.typeToUse === "note" ? "Select Topic" : "Select Area";
    },
  },
  watch: {
    isSelectorOpen: {
      handler(n) {
        if (n) {
          this.focusOnInput();
        }
      },
    },
  },
};
</script>
