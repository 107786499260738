<template>
  <div
    :class="popUpWrapperStyles"
    data-action-type="open-resource-info"
    :data-action-value="resourceId"
  >
    <!-- {{ resourceData }} -->
    <ResourceInfo
      v-if="isVisible && !showNotFoundError"
      :resourceData="resourceData"
      :isVisible="isVisible"
    />
    <div v-else class="not-found-text">Resource Not Found</div>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import ResourceInfo from "./ResourceInfo.vue";
export default {
  components: {
    ResourceInfo,
  },
  props: {
    resourceData: {
      type: Object,
    },
    isVisible: {
      type: Boolean,
    },
    showNotFoundError: {
      type: Boolean,
    },
  },
  computed: {
    popUpWrapperStyles() {
      return {
        [`${this.resourceData.type}`]: !isEmpty(this.resourceData?.type),
        "resource-info-pop-up": true,
        "resource-pop-up-visible": this.isVisible,
      };
    },
    resourceId() {
      return this.resourceData.key || "";
    },
  },
};
</script>
<style scoped>
.resource-info-pop-up {
  min-height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: white;
  /* border: 2px solid var(--primary-color); */
  z-index: 300;
  top: 0;
  left: 0;
  height: auto;
  position: absolute;
  /* -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 2px 2px 0px rgba (0, 0, 0, 0.75);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75); */
  -moz-box-shadow: 0px 8px 5px -3px rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0px 8px 5px -3px rgb(0 0 0 / 25%);
  box-shadow: 0px 8px 5px -3px rgb(0 0 0 / 25%);

  /* -webkit-box-shadow: 0px 3px 52px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 52px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 52px -19px rgba(0, 0, 0, 0.75); */
  display: none;
  /* background: #777675; */
  /* padding: 10px 12px 10px 12px; */
  cursor: pointer;
  background-color: #878787;
  border: 1px solid #878787;
}

.resource-pop-up-visible {
  display: block;
}

.not-found-text {
  font-weight: bold;
  font-size: 13px;
}
</style>
