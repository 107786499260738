function _filterData({ type, value }, val = "") {
  if (_comparison[type]) {
    return _comparison[type](val, value);
  }
  return true;
}

const _comparison = {
  "=": (a, b) => a == b,
  "!=": (a, b) => a != b,
  "<=": (a, b) => a <= b,
  "<": (a, b) => a < b,
  ">": (a, b) => a > b,
  ">=": (a, b) => a >= b,
  like: (a, b) => a.toLowerCase().includes(b.toLowerCase()),
  keywords: (a, b) => b.includes(a),
  startWith: (a, b) => a.startsWith(b),
  endWith: (a, b) => a.endsWith(b),
  in: (a, b) => b.includes(a),
  includes: (a, b) => {
    if (Array.isArray(b)) {
      return b.some((i) => a.includes(i));
    } else {
      return a.includes(b);
    }
  },
  excludes: (a, b) => {
    if (Array.isArray(b)) {
      return b.every((i) => !a.includes(i));
    } else {
      return !a.includes(b);
    }
  },
  regex: (a, b) => new RegExp(b).test(a),
  default: () => true,
};

export const runFiltersOnRow = (filters, row) =>
  filters.every((f) => f.compare(row));

export const createFiltersFromConfig = (filtersConfig) => {
  return filtersConfig.map((f) => {
    if (Array.isArray(f)) {
      f.compare = (row) =>
        f.some((or) => {
          if (Array.isArray(or)) {
            return or.every((filtr) => {
              if (Array.isArray(filtr)) {
                return filtr.some((deepF) =>
                  _filterData(deepF, row[deepF.field])
                );
              } else {
                return _filterData(filtr, row[filtr.field]);
              }
            });
          } else {
            return _filterData(or, row[or.field]);
          }
        });
    } else {
      f.compare = (row) => _filterData(f, row[f.field]);
    }
    return f;
  });
};
export default function filter(df, filters) {
  let filtered = df;

  if (filters && filters.length > 0) {
    filters = createFiltersFromConfig(filters);

    filtered = filtered.filter((row) => {
      return runFiltersOnRow(filters, row);
    });
  }
  return filtered;
}
