import { isValidDate } from "@/helpers/dates";

export const sortOpts = [
  [
    {
      column: "hashedPath",
      order: "asc",
    },
    // {
    //   column: "createdAsDate",
    //   order: "desc",
    //   defaultValue: new Date("1970-01-01"),
    //   transformFunc: (val) => {
    //     return isValidDate(val) ? val : null;
    //   },
    // },
    {
      column: "modifiedAsDate",
      order: "desc",
      defaultValue: new Date("1970-01-01"),
      transformFunc: (val) => {
        return isValidDate(val) ? val : null;
      },
    },
  ],
];

export const groupOpts = ["hashedPath"];

export const NOTES_PROPS_TO_REMOVE = [
  "actions",
  "editing",
  "fullPath",
  "group",
  "handler",
  "hashedPath",
  "row-handler",
  "saveBtn",
  "selected",
  "type",
  "createdFormatted",
  "modifiedFormatted",
  "modifiedAsDate",
  "createdAsDate",
];

export const NOTE_PROPS_FOR_FORM = [
  "title",
  "descr",
  "path",
  "order",
  "itemType",
];

export const NOTE_PROPS_TO_RESTORE = ["title", "descr", "path"];

export const NOTE_FIELDS_FOR_PROCESSING = ["path"];
