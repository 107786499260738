export const statusBtnDisplayOptsMap = {
  "with-task": {
    label: "Left of the task column",
  },
  "in-area": {
    label: "In the area column",
  },
};

export const dueDateDisplayOptsMap = {
  date: {
    label: "Date only (12/25/23)",
  },
  "day-date": {
    label: "Day of week and date (MON 12/25/23)",
  },
};
export const statusBtnDisplayOpts = Object.entries(statusBtnDisplayOptsMap).map(
  ([key, data]) => {
    return {
      label: data.label,
      value: key,
    };
  }
);

export const dueDateDisplayOpts = Object.entries(dueDateDisplayOptsMap).map(
  ([key, data]) => {
    return {
      label: data.label,
      value: key,
    };
  }
);
