export default {
  message(state) {
    return state.message;
  },
  isEnabled(state) {
    return state.isEnabled;
  },
  color(state) {
    return state.color;
  },
};
