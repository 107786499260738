<template>
  <div>{{ name }}</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: ["data"],
  computed: {
    ...mapGetters("resourcesData", ["categories", "selectedResourceTypeOpt"]),
    defaultText() {
      return this.selectedResourceTypeOpt === "project"
        ? "No Area"
        : "No Group";
    },
    name() {
      const cate = this.categories[this.data.catId];
      const name = cate?.name || this.defaultText;
      return name;
    },
  },
};
</script>
