<template>
  <v-chart 
    class="e-chart" 
    :option="options" 
    v-bind="$attrs" 
    v-on="$listeners"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import { BarChart } from 'echarts/charts';
import
VChart
//, { THEME_KEY }
from "vue-echarts";

use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);

export default {
  components: {
    VChart
  },
  // provide: {
  //   [THEME_KEY]: "dark"
  // },
  props: {
    options: {
      type: Object
    }
  }
};
</script>

<style scoped>
</style>