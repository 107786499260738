<template>
  <div class="project-editor-wrapper-cell">
    <div class="project-selector-wrapper">
      <ProjectSelector
        ref="select"
        :value="selectedVal"
        @change="handleChange"
        @blur="handleBlur"
        @focus="handleFocus"
        @keydown="checkForTabKey"
        :showOnlyIncompleteOpts="showOnlyIncompleteOpts"
      />
    </div>
    <TaskStatusBtn
      v-if="showStatusBtn"
      :color="baseColor"
      :gridApi="cell.api"
      :data="data"
      :showPosition="showPosition"
      :showScheduledBtn="showScheduledBtn"
      :checkForContingent="checkContingentBtn"
      :showUnscheduledIcon="showUnscheduledIcon"
    />
    <div v-if="!showStatusBtn && isTaskInCalAndWithValidArea">
      <div class="">
        <OpenTaskDetails btnClass="scheduled-btn">
          <v-icon v-if="showContingentIcon" :color="baseColor" :size="17">
            $ctiCalendarCross
          </v-icon>
          <v-icon v-else :color="baseColor" :size="22"> $ctiSchedule </v-icon>
        </OpenTaskDetails>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectSelector from "@/components/EditComponents/ProjectSelector";
import TaskStatusBtn from "./TaskStatusBtn.vue";
import {
  columnPropsMixin,
  selectorColumnPropsMixin,
} from "@/core/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { checkIfProjectHasNoPriorityByKey } from "@/helpers/projects";
import isEmpty from "lodash/isEmpty";
import isUndefinedVal from "@/utils/isUndefinedVal";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import OpenTaskDetails from "@/components/TasksListView/components/TasksList/components/OpenTaskDetails.vue";
import { calModes } from "@/variables/viewConfigs";
import { checkTaskSettingsIsNotEmpty } from "@/helpers/tasks";

export default {
  name: "project",
  mixins: [columnPropsMixin, selectorColumnPropsMixin, tasksHelpersMixin],
  data() {
    return {
      selectedVal: "",
    };
  },
  methods: {
    ...mapActions("areaPrioritySetterData", ["openDialog"]),
    handleBlur() {
      const isInAddMode = this.cell.api.isAddModeEnabled();
      if (
        !isInAddMode &&
        this.cell.api.isEditingActive() &&
        checkIfProjectHasNoPriorityByKey(this.selectedVal)
      ) {
        const currData = this.cell.api.getEditRowData();

        let currRowData = { ...this.data };
        if (currRowData.isReminderTask) {
          const parentTaskData = this.getParentTaskDataOfReminder(currRowData);

          if (!isEmpty(parentTaskData)) {
            currRowData = {
              ...parentTaskData,
              reminderDate: currRowData.reminderDate,
            };
          }
        }
        this.openDialog({
          taskList: [{ ...currRowData }],
          taskUpdates: { ...currData },
          areaId: this.selectedVal,
        });
      }
    },
    handleChange(val) {
      const isInAddMode = this.cell.api.isAddModeEnabled();
      if (!isInAddMode && checkIfProjectHasNoPriorityByKey(val)) {
        this.selectedVal = val;
        const currData = this.cell.api.getEditRowData();
        let currRowData = { ...this.data };
        if (currRowData.isReminderTask) {
          const parentTaskData = this.getParentTaskDataOfReminder(currRowData);

          if (!isEmpty(parentTaskData)) {
            currRowData = {
              ...parentTaskData,
              reminderDate: currRowData.reminderDate,
            };
          }
        }
        this.openDialog({
          taskList: [{ ...currRowData }],
          taskUpdates: { ...currData },
          areaId: val,
        });
        return;
      }
      this.selectedVal = val;
      this.onSuccess(val, val, this.cell);
    },
    checkForTabKey(e) {
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 10);
    },
    getParentTaskDataOfReminder(reminderTaskData) {
      const parentTaskData = this.tasks.find(
        (t) => t.key === reminderTaskData.reminderLinkedTo
      );

      return parentTaskData;
    },
  },
  components: {
    ProjectSelector,
    OpenTaskDetails,
    TaskStatusBtn,
  },
  computed: {
    ...mapGetters("task", ["projects", "showClearedTasks", "tasks"]),
    ...mapGetters(["taskSettings"]),
    showOnlyIncompleteOpts() {
      const isInAddMode = this.cell.api.isAddModeEnabled();
      let showOpts = false;
      if (isInAddMode && !this.showClearedTasks) {
        showOpts = true;
      }

      return showOpts;
    },

    baseColor() {
      let color = "orange";
      if (this.extraData?.useDynamicColor && this.extraData?.colorProvider) {
        color = this.extraData.colorProvider(this);
      }

      return color;
    },

    isTaskInCalAndWithValidArea() {
      const res =
        !checkTaskSettingsIsNotEmpty(this.taskSettings) &&
        this.isInCalModes &&
        (this.data.due !== "none" || !!this.data.showContingentInToday);

      return res;
    },
    showContingentIcon() {
      return (
        this.extraData?.checkForContingent && !!this.data.showContingentInToday
      );
    },

    showStatusBtn() {
      const res = this.checkIfStatusBtnIsVisibleInAreaColumn();
      return res;
    },

    showScheduledBtn() {
      return (
        this.extraData.showScheduledIcon ||
        this.isInCalModes ||
        this.isInNextActionsMode ||
        this.isInInboxViewOrGlobalEnabled
      );
    },
    checkContingentBtn() {
      return this.extraData?.checkForContingent;
    },
    showUnscheduledIcon() {
      return this.extraData?.showUnscheduledIcon;
    },
    showPosition() {
      return this.currNav === 4 || this.extraData?.showPosition;
    },
  },
  watch: {
    "data.project": {
      handler(newVal) {
        this.selectedVal = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.project-selector-wrapper {
  min-width: 0;
  padding-right: 0.3rem;
}
.project-selector-wrapper >>> .project-input {
  /* height: 33px; */
  margin-top: 0;
  font-size: 13px;
  padding-top: 0;
  margin-bottom: 1px;
}
.project-selector-wrapper >>> .project-input .v-input__control {
  height: 33px;
}
.project-selector-wrapper >>> .project-input .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}
.priority-color {
  width: 5px;
  margin-right: 4px;
}

.selection-container {
  display: inline-flex;
}

.v-select >>> .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

.v-select >>> .v-select__slot {
  height: 33px;
}

.v-select >>> .v-select__selections {
  height: 33px;
}

.project-editor-wrapper-cell {
  display: flex;
  align-items: center;
}
</style>
