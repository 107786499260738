import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyAM-t-NcmrLPaSv_4mlBGQufbvSwostztE",
  authDomain: "richard-bunnage.firebaseapp.com",
  databaseURL: "https://richard-bunnage-default-rtdb.firebaseio.com",
  projectId: "richard-bunnage",
  storageBucket: "richard-bunnage.appspot.com",
  messagingSenderId: "117793668644",
  appId: "1:117793668644:web:5b246f9a53c685d7f4e2b1",
  measurementId: "G-022G21GYZ5",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
