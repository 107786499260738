<template>
  <v-row align="center" no-gutters>
    <v-col cols="auto">
      <v-btn-toggle id="edit-mode-btns-group" :value="activeModeIndex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="edit-mode-btn duplicate-mode"
              v-on="on"
              v-bind="attrs"
              icon
              @click="handleMode('duplicate')"
              :class="getActiveBtnClass('duplicate')"
            >
              <v-icon size="14">$ctiDuplicate</v-icon>
            </v-btn>
          </template>
          <span>Duplicate Task</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="edit-mode-btn"
              v-on="on"
              v-bind="attrs"
              icon
              @click="handleMode('delete')"
              :class="getActiveBtnClass('delete')"
            >
              <v-icon size="14">$ctiDelete</v-icon>
            </v-btn>
          </template>
          <span>Delete Task</span>
        </v-tooltip>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>
<script>
import { editModesIndexes, editModesLabels } from "@/variables/editModeConfig";
export default {
  props: {
    activeMode: {
      type: String,
    },
    isTaskComplete: {
      type: Boolean,
    },
    isTaskContinued: {
      type: Boolean,
      required: true,
    },
    isTaskNotRepeatInOrEvery: {
      type: Boolean,
    },
    isTaskRepeatIn: {
      type: Boolean,
    },
    isTaskRepeatEvery: {
      type: Boolean,
    },
  },
  methods: {
    handleMode(btnType) {
      this.$emit("edit-mode:changed", btnType);
    },
    isModeActive(modeName) {
      return this.activeMode === modeName;
    },
    getActiveBtnClass(modeName) {
      const isActiveMode = this.isModeActive(modeName);
      const leftBorder = "left-border-enable";

      return {
        "v-btn--active": isActiveMode,
        [leftBorder]:
          modeName === "duplicate" &&
          !this.isTaskContinued &&
          !this.isTaskRepeatIn,
      };
    },
  },
  computed: {
    completeBtn() {
      let icon = "$ctiCircleOutline";
      let title = "Complete Task";
      let size = 14;
      if (this.isTaskRepeatEvery) {
        icon = "$ctiRepeat";
        size = 14;
      }
      if (this.isTaskComplete) {
        icon = "$ctiComplete";
        title = "Mark Task as Incomplete";
        size = 16;
      }
      return { icon, title, size };
    },
    activeModeIndex() {
      const index = editModesIndexes[this.activeMode];
      return index;
    },
    activeModeLabel() {
      return editModesLabels[this.activeMode] || "Edit";
    },
  },
};
</script>
<style scoped>
.edit-mode-btn {
  background: #efefef !important;
  border: 1px solid transparent !important;
  height: 20px !important;
  border-radius: 6px;
}

#edit-mode-btns-group button.v-btn.v-btn.duplicate-mode >>> .v-icon {
  height: auto !important;
  width: auto !important;
}
#edit-mode-btns-group button.v-btn.v-btn.duplicate-mode >>> svg {
  height: 13px !important;
  width: 17px !important;
}

#edit-mode-btns-group {
  background: #efefef !important;
  display: flex;
  align-items: center;
}

#edit-mode-btns-group > .v-btn.v-btn:first-child {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

#edit-mode-btns-group > .v-btn.v-btn:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
/* .v-btn--active {
  background-color: var(--primary-color) !important;
}

.v-btn--active >>> .v-icon {
  color: #fff !important;
}

.v-btn--active >>> svg {
  fill: #fff !important;
} */

.edit-mode-btn >>> .v-icon__component {
  /* width: 100% !important; */
  /* height: 100% !important; */
  /* height: px !important; */
}

.edit-mode-btn >>> svg {
  fill: var(--primary-color);
}

#edit-mode-btns-group button.v-btn--active {
  background-color: var(--primary-color) !important;
}

#edit-mode-btns-group button.v-btn--active >>> .v-icon {
  color: #fff !important;
}

#edit-mode-btns-group button.v-btn--active >>> svg {
  fill: #fff !important;
}

.edit-mode-label-text {
  width: 90px;
}

button.left-border-enable {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
</style>
<style>
#edit-mode-btns-group button.v-btn {
  border-color: transparent !important;
  min-width: auto !important;
  border-radius: 6px !important;
  padding: 5px 8px !important;
  width: auto !important;
  border-width: 0px !important;
}
</style>
