import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.toggleReminderDays,"keydown":_vm.handleToggleKeyDown}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":_vm.iconSize,"color":_vm.reminderDaysBtnIconData.color}},[_vm._v(" "+_vm._s(_vm.reminderDaysBtnIconData.icon)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.reminderDaysBtnIconData.label))])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('div',{staticStyle:{"width":"40px"}},[_c(VTextField,{ref:"reminderInput",staticClass:"reminder-input",attrs:{"value":_vm.value,"placeholder":"Enter number of days to remind before","type":"number","min":"0","hide-details":""},on:{"input":_vm.handleReminderDaysVal,"keydown":_vm.handleInputKeydown}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }