const scrollTo = ({ itemEl, itemParentEl, itemTopOffset = 20 }) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (itemEl && itemParentEl) {
        const itemRect = itemEl.getBoundingClientRect();
        const itemParentRect = itemParentEl.getBoundingClientRect();
        const itemOffset = itemRect.top - itemParentRect.top - itemTopOffset;
        if (itemRect.bottom > itemParentRect.height) {
          itemParentEl.scrollTop = itemOffset;
        }

        resolve();
      }
    }, 0);
  });
};

export const scrollToByPixil = (
  itemParentEl,
  scrollTo = 0,
  itemTopOffset = 20
) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      itemParentEl.scrollTop = scrollTo - itemTopOffset;
      resolve();
    }, 0);
  });
};
export default scrollTo;
