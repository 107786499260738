<template>
  <div
    :class="editorWrapperStyles"
    v-bind="attrs"
    v-on="on"
    @keydown="handleKeyDown"
    @mention-delete="handleMentionDeletion"
  >
    <VueEditor
      ref="editor"
      :id="editorId"
      :value="value"
      @input="handleTextChange"
      :disabled="isReadOnly"
      :editorOptions="editorOptions"
      :class="editorStyles"
      :placeholder="placeholder"
      :tabindex="tabIndex"
      v-bind="attrs"
      v-on="on"
    />
    <div class="underline"></div>

    <AddResourcePopUp
      :extraData="extraResourceData"
      :isSelectorOpen="isDialogOpen"
      @dialog:closed="closeResourcePopUp"
      @data:submitted="handleResourceAdd"
      :entertedTag="enteredTag"
    />
  </div>
</template>
<script>
import "quill-mention";
import { VueEditor, Quill } from "vue2-editor";
import ExtendSnowTheme from "./ExtendSnowTheme";
import ResourcesMention from "@/components/ResourceMention";
import "./css/quill-mentions.css";
import { searchResourcesByKeyword } from "@/helpers/resources";
import { resourceTypesMap } from "@/data/resources";
import isEmpty from "lodash/isEmpty";
import AddResourcePopUp from "@/components/ResourceInfoFormPopUp/ResourceInfoFormPopUp.vue";
import { increment } from "@/utils/number";
const fontSizes = ["8px", "10px", "13px", "16px", "20px", "24px", "36px"];

const toolbarFontSizes = ["8px", "10px", false, "16px", "20px", "24px", "36px"];
const Size = Quill.import("attributors/style/size");
import MagicUrl from "quill-magic-url";
const Block = Quill.import("blots/block");
Block.tagName = "P";
Size.whitelist = fontSizes;

const icons = Quill.import("ui/icons");
icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;
Quill.register(Block, true);
Quill.register(Size, true);
Quill.register(ResourcesMention);
Quill.register("modules/magicUrl", MagicUrl);
Quill.register("themes/snow", ExtendSnowTheme);
export default {
  name: "RichTextEditor",
  components: {
    VueEditor,
    AddResourcePopUp,
  },
  props: {
    addSpaceOnEnter: {
      type: Boolean,
      default: false,
    },
    enableMentions: {
      type: Boolean,
      default: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
    },
    editorId: {
      type: String,
    },
    mentionMenuPosition: {
      type: String,
      default: "absolute",
    },
    noBorders: {
      type: Boolean,
    },
    noPadding: {
      type: Boolean,
    },
    useAsInput: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    tabIndex: {
      type: Number,
    },
    stopTabProcess: {
      type: Boolean,
      default: false,
    },
    on: {
      type: Object,
    },
    attrs: {
      type: Object,
    },
    mentionOpts: {
      type: Object,
    },
    hideBorder: {
      type: Boolean,
    },
    doNotHandleMentionRemove: {
      type: Boolean,
    },
  },
  data(vm) {
    return {
      extraResourceData: {},
      isDialogOpen: false,
      enteredTag: "",
      editorOptions: {
        // theme: "snow",

        bounds: `#${vm.editorId}`,
        modules: {
          magicUrl: {
            // normalizeUrlOptions: {
            //   stripHash: true,
            //   stripWWW: false,
            //   normalizeProtocol: false,
            // },
            // Regex used to check URLs during typing
            urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)|(file?:\/\/\/[\S]+)|([A-Za-z]:[\S]+)/g,
            // Regex used to check URLs on paste
            globalRegularExpression: /(https?:\/\/|www\.|tel:|file:\/\/\/|[A-Za-z]:)[\S]+/g,
          },
          keyboard: {
            bindings: {
              tab: {
                key: 9,
                handler() {
                  if (vm.stopTabProcess) {
                    return true;
                  }
                  // Handle tab
                },
              },
              esc: {
                key: 27,
                handler() {
                  if (vm.stopTabProcess) {
                    return true;
                  }
                  // const escEvent = new Event("keydown");
                  // escEvent.which = 27;
                  // escEvent.keyCode = 27;
                  // vm.handleKeyDown(escEvent);
                  // Handle tab
                },
              },
              shiftTab: {
                key: 9,
                shiftKey: true,
                handler() {
                  if (vm.stopTabProcess) {
                    return true;
                  }
                  // Handle tab
                },
              },
              lineBreak: {
                key: 13,
                handler(range) {
                  if (!vm.addSpaceOnEnter) {
                    return true;
                  }

                  const quill = vm.getQuillInstance();

                  const nextLeaf = quill.getLeaf(range.index + 1)[0];
                  if (nextLeaf) {
                    return true;
                  }
                  if (nextLeaf === null) {
                    const delta = quill.getContents();
                    const opsLength = delta.ops.length;
                    const finalOps = delta.ops[opsLength - 1] 
                    
                    if (finalOps?.attributes?.list) {
                      if (finalOps?.attributes?.insert !== "\n") {
                        delta.ops[opsLength  - 1] = { attributes: { list: "ordered" }, "insert": "\n\n" }
                      }
                    }
                    delta.ops.push({ insert: '\n' })

                    quill.setContents(delta)
                    setTimeout(() => {
                      quill.setSelection(range.index + 1);
                    }, 0);
                  }
                },
              },
            },
          },
          history: {
            delay: 1000,
            maxStack: 100,
            userOnly: true,
          },
          mention: this.enableMentions
            ? {
                blotName: "resourceMention",
                allowedChars: /^[a-zA-Z0-9_]*$/,
                source: (searchTerm, renderList) => {
                  let filtersToUse = {};
                  let addBorder = false;
                  if (!isEmpty(this.mentionOpts)) {
                    if (
                      this.mentionOpts.useAdditionalFilters &&
                      !isEmpty(this.mentionOpts.mentionFiltersProps)
                    ) {
                      filtersToUse = {
                        ...this.mentionOpts.mentionFiltersProps,
                      };
                    }
                  }
                  let matchedResources = searchResourcesByKeyword(
                    searchTerm,
                    filtersToUse
                  );

                  if (!isEmpty(matchedResources)) {
                    matchedResources = matchedResources.map((r) => ({
                      ...r,
                      id: r.key,
                      value: r.tag,
                    }));
                    addBorder = true;
                  }

                  matchedResources.push({
                    id: "add-resource",
                    value: searchTerm,
                    isBtn: true,
                    addBorder,
                  });

                  renderList(matchedResources);
                },
                positioningStrategy: this.mentionMenuPosition,
                renderItem: (item) => {
                  if (item.isBtn) {
                    const btnEl = document.createElement("div");
                    btnEl.classList.add(
                      "mention-item-details-wrapper",
                      "add-resource-btn"
                    );

                    if (item.addBorder) {
                      btnEl.classList.add("dotted-border-bottom");
                    }

                    btnEl.append(`Add '${item.value}'`);
                    return btnEl.outerHTML;
                  }

                  const resourceTagIcon = resourceTypesMap[item.type].icon;
                  return `<div class='mention-item-details-wrapper'>
                    <div class='mention-item-type'>
                      <i class='mdi ${resourceTagIcon}'></i>
                    </div>
                    <div class='mention-item-name-details'>
                      <div class='mention-item-name mb-2'>${item.title}</div>
                      <div class='mention-item-tag'>@${item.tag}</div>
                    </div>
                  </div>`;
                },
                onSelect: (item, insertItem) => {
                  if (item.id === "add-resource") {
                    ///

                    this.openResourceTypeSelector(item.value);
                  } else {
                    insertItem(item);
                  }
                },
              }
            : false,
          clipboard: {
            matchVisual: false,
          },
          toolbar: this.showToolbar
            ? {
                container: [
                  ["bold", "italic", "underline"],
                  [{ size: toolbarFontSizes }],
                  [{ header: [] }],
                  [
                    // "image",
                    // "code-block",
                    "blockquote",
                    "link",
                    { list: "ordered" },
                    { list: "bullet" },
                    { align: [] },
                  ],
                  ["undo", "redo"],
                ],
                handlers: {
                  undo: () => this.$refs.editor.quill.history.undo(),
                  redo: () => this.$refs.editor.quill.history.redo(),
                },
              }
            : false,
        },
      },
      delta: null
    };
  },
  mounted() {
    const quillInstance = this.$refs.editor.quill;

    if (quillInstance) {
      quillInstance.root.addEventListener("blur", this.handleBlur);
      quillInstance.root.addEventListener("focus", this.handleFocus);
      quillInstance.root.addEventListener("mousedown", this.handleMouseDown);
    }
  },
  beforeDestroy() {
    const quillInstance = this.$refs.editor.quill;

    if (quillInstance) {
      quillInstance.root.removeEventListener("blur", this.handleBlur);
      quillInstance.root.removeEventListener("focus", this.handleFocus);
      quillInstance.root.removeEventListener("mousedown", this.handleMouseDown);
    }
  },

  methods: {
    handleMouseDown(e) {
      if (e.target.tagName === "A" && e.button !== 2 && !this.showToolbar) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        const link = e.target.href.startsWith("www.")
          ? `https://${e.target.href}`
          : e.target.href;

        window.open(link, "_blank", "noopener,noreferrer");
      }
    },
    async handleMentionDeletion(e) {
      e.stopPropagation();
      e.stopImmediatePropagation();

      if (this.doNotHandleMentionRemove) {
        this.$emit("remove-mention", e.detail, e);
        return;
      }

      this.removeMentionFromData({
        mentionInfo: e.detail?.mentionInfo,
        mentionEl: e.detail?.mentionEl,
      });

      await this.$nextTick();

      this.$emit("mention-removed", e.detail?.mentionInfo);
    },

    async removeMentionFromData({ mentionEl }) {
      // const mentionID = mentionInfo.id;
      // const div = document.createElement("div");
      // div.innerHTML = this.value;
      // div.childNodes;
      // const mentionFound = div.querySelector(`[data-id='${mentionID}']`);

      if (!isEmpty(mentionEl)) {
        const quillInstance = this.getQuillInstance();
        const mentionBlot = Quill.find?.(mentionEl);
        const mentionPositon = mentionBlot.offset(quillInstance.scroll);

        // /** To delete the mention then set the newly edited data into the title cell */
        // mentionFound.remove();
        // this.setHTMLData(div.innerHTML, true, true);

        mentionBlot?.remove?.();
        await this.$nextTick();
        quillInstance.setSelection(mentionPositon);
      }
      // div.remove();

      return true;
    },
    openResourceTypeSelector(tag) {
      this.doNotEmitBlur = true;
      const quill = this.$refs.editor.quill;
      this.caretPos = quill.getSelection().index - increment(tag.length, 1);
      this.enteredTag = tag;
      if (!isEmpty(this.mentionOpts) && !isEmpty(this.mentionOpts.formData)) {
        this.extraResourceData = { ...this.mentionOpts.formData };
      }
      this.isDialogOpen = true;
    },
    closeResourcePopUp() {
      this.doNotEmitBlur = false;
      this.isDialogOpen = false;
      this.extraResourceData = {};
    },
    async handleResourceAdd(resourceData, insertedProgrammatically) {
      await this.$nextTick();
      const mentionModule = this.$refs.editor.quill.getModule("mention");
      const quill = this.$refs.editor.quill;
      quill.focus();

      setTimeout(() => {
        mentionModule.insertItem(
          {
            id: resourceData.key,
            value: resourceData.tag,
          },
          insertedProgrammatically
        );
      }, 0);
    },
    setCursor(el, collapse, byPassCheck) {
      // setTimeout(() => {
      let selectText = true;
      const textData = this.getTextContent()?.trim();

      if (!byPassCheck) {
        selectText = textData && textData.length;
      }

      if (selectText) {
        const selection = window.getSelection();
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(el);
        if (collapse) {
          range.collapse(false);
        }
        selection.addRange(range);
      }

      // if (textData && textData.length) {

      // }
      // }, 0);
      // el.focus();
    },
    getTextContent() {
      return this.$refs.editor?.quill?.getText();
    },
    focusOnEl(selectText) {
      const textInputElWrapper = this.$refs.editor?.quill;
      const el = this.$el.querySelector("[contenteditable=true]");

      setTimeout(() => {
        this.setCursor(el, !selectText);
        el.focus();
        textInputElWrapper?.focus();
      }, 0);

      if (selectText && textInputElWrapper) {
        // console.debug("CURRENT SE", currSelection);
      }
    },
    handleBlur(e) {
      if (this.doNotEmitBlur) return;
      this.$emit("blur", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    blurInput() {
      const textInputElWrapper = this.$refs.editor?.quill;
      textInputElWrapper?.blur();
    },
    convertHTMLToDelta(html) {
      const currConvertedData =
        this.$refs.editor?.quill?.clipboard?.convert(html);
      return currConvertedData;
    },
    getDeltaContents() {
      return this.$refs.editor.quill.getContents();
    },
    setDeltaContents(delta) {
      this.$refs.editor.quill.setContents(delta);
      this.delta = this.$refs.editor.quill.editor.delta;
    },
    getDelta() {
      return this.$refs.editor.quill.editor.delta;
    },
    setHTMLData(html, convert = true, useEmptyForEmptyVal) {
      let processedHTML = html;

      if (useEmptyForEmptyVal && !processedHTML) {
        processedHTML = "<p>&nbsp;</p>";
      }
      if (convert) {
        const convertedDelta = this.convertHTMLToDelta(processedHTML);
        this.setDeltaContents(convertedDelta);
      } else {
        this.$refs.editor.quill.pasteHTML(processedHTML);
      }
    },
    handleTextChange(val) {
      // console.log(this.$refs.editor.quill)
      this.$emit("input", val, this.delta, this.$refs.editor.quill.editor.delta);
    },
    getQuillInstance() {
      return this.$refs.editor.quill;
    },
  },
  computed: {
    editorStyles() {
      return {
        "editor-no-borders": this.noBorders,
        "editor-no-padding": this.noPadding,
      };
    },
    editorWrapperStyles() {
      return {
        "editor-as-input-wrapper": this.useAsInput && !this.isReadOnly,
        "hide-border": this.hideBorder,
        "editor-wrapper": true,
      };
    },
  },
  watch: {
    placeholder(newVal) {
      const quillInstance = this.getQuillInstance();
      quillInstance.root.setAttribute("data-placeholder", newVal);
    },
  },
};
</script>
<style>
@import "~vue2-editor/dist/vue2-editor.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

.ql-editor {
  font-size: 13px !important;
}

.editor-as-input-wrapper .ql-editor {
  caret-color: var(--primary-color);
}

.editor-no-borders .ql-container {
  border: none !important;
}

.editor-as-input-wrapper {
  position: relative;
}

.editor-as-input-wrapper .ql-container {
  border: none !important;
}

.editor-as-input-wrapper:not(.hide-border) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6) !important;
}

.editor-as-input-wrapper:not(.hide-border) .underline {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 2px;
}

.editor-as-input-wrapper:not(.hide-border) .underline::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  bottom: -1px;
  background: var(--primary-color);
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.editor-as-input-wrapper:not(.hide-border):focus-within .underline::before {
  transform: scaleX(1);
}

.editor-no-padding .ql-editor {
  padding: 0px !important;
}

.ql-mention-list-item {
  padding: 10px;
}

.mention-item-details-wrapper {
  display: flex;
  align-items: center;
}

.mention-item-name {
  font-size: 14px;
  line-height: 1;
  color: black;
  margin-bottom: 8px;
}

.mention-item-tag {
  font-size: 12px;
  line-height: 1;
  color: black;
}

.mention-item-name-details {
  flex: 1;
  margin-left: 10px;
}

.mention-item-type i {
  color: black;
  font-size: 18px;
}

.ql-mention-list-item.selected {
  background-color: #f6f6f6;
}

.editor-wrapper {
  outline: none;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:not([data-value]):before,
.ql-snow .ql-picker.ql-size .ql-picker-item:not([data-value])::before {
  content: "13px" !important;
}

.editor-wrapper .ql-size.ql-picker {
  width: 70px !important;
}

.add-resource-btn {
  /* height: 100%;
  display: flex;
  align-items: center;
  width: 100%; */

  margin: -10px;
  padding: 10px;
  height: 54px;
}

.ql-editor em,
u,
strong {
  color: currentColor;
}

/* remove default label */
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: none !important;
}

/* control when to show link text input and label */
.ql-snow .ql-tooltip .link-text-container {
  display: none;
  margin-bottom: 5px;
}
.ql-snow .ql-tooltip[data-mode="link"] .link-text-container {
  display: block;
  text-align: start;
}
.ql-snow .ql-tooltip > span {
  display: none;
}
.ql-snow .ql-tooltip[data-mode="link"] span {
  display: inline-block;
  margin-right: 5px;
  width: 30px;
}

</style>
