<template>
  <TogglerBtn
    :cssClass="cssClass"
    :value="value"
    @input="handleChange"
    @keydown="handleKeyDown"
    :iconData="flagIconData"
    :isFocusable="isFocusable"
    :iconSize="iconSize"
  />
</template>
<script>
import TogglerBtn from "@/components/TogglerBtn";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
export default {
  components: {
    TogglerBtn,
  },
  props: {
    value: {
      type: Boolean,
    },
    cssClass: {
      type: String,
    },
    iconSize: {
      type: [String, Number],
      default: 22
    },
    isFocusable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleChange(v) {
      this.$emit("input", v);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
  },
  computed: {
    tabIndexToUse() {
      return !this.isFocusable ? "-1" : undefined;
    },
    flagIconData() {
      let icon = "mdi-flag-outline";
      let color = DARK_GREY_ICON_COLOR;
      let label = "Mark as Flagged";
      if (this.value) {
        icon = "mdi-flag";
        color = "orange";
        label = "Mark as Unflagged";
      }
      return {
        icon,
        color,
        label,
      };
    },
  },
};
</script>
