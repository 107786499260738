<template>
  <div class="resource-type-selector-wrapper" @click.stop @keydown.stop>
    <ItemTypeMenu
      :value="data.type"
      @input="onItemTypeChange"
      ref="select"
      type="resource"
    />
  </div>
</template>
<script>
import ItemTypeMenu from "@/components/ItemTypeMenu";
import { columnPropsMixin } from "@/core/components/DataTable";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
export default {
  mixins: [columnPropsMixin],
  components: {
    ItemTypeMenu,
  },
  methods: {
    onItemTypeChange(val) {
      this.handleDataUpdate(val);
    },
  },
  computed: {
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
  },
};
</script>
<style scoped>
.resource-type-selector-wrapper >>> .v-select {
  margin-top: 0px !important;
}

.resource-type-selector-wrapper >>> #activator-btn-wrapper {
  height: 34px;
}
.resource-type-selector-wrapper >>> #activator-btn-wrapper button {
  height: 34px !important;
  width: 34px !important;
}

.resource-type-selector-wrapper >>> .v-select__slot input[readonly="readonly"] {
  height: 100%;
}
</style>
