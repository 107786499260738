<template>
  <v-row dense>
    <v-col cols="auto">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            small
            @click="toggleReminderDays"
            @keydown="handleToggleKeyDown"
          >
            <v-icon :size="iconSize" :color="reminderDaysBtnIconData.color">
              {{ reminderDaysBtnIconData.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ reminderDaysBtnIconData.label }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="auto">
      <div style="width: 40px">
        <v-text-field
          class="reminder-input"
          :value="value"
          @input="handleReminderDaysVal"
          @keydown="handleInputKeydown"
          placeholder="Enter number of days to remind before"
          type="number"
          min="0"
          hide-details
          ref="reminderInput"
        />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { DARK_GREY_ICON_COLOR, PRIMARY_COLOR } from "@/variables/colors";
import isUndefinedVal from "@/utils/isUndefinedVal";
import { isRemindarValValid } from "@/helpers/tasks";

/**
 * Component which displays UI for setting reminder in the task
 */
export default {
  props: {
    value: {
      type: [String, Number],
    },
    iconSize: {
      type: [String, Number],
      default: "16",
    },
  },
  methods: {
    toggleReminderDays(e) {
      this.$emit("toggle:clicked", e);
    },
    handleToggleKeyDown(e) {
      this.$emit("toggle:keydown", e);
    },
    handleInputKeydown(e) {
      this.$emit("input:keydown", e);
    },
    handleReminderDaysVal(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    reminderDaysBtnIconData() {
      const reminderDays = this.value || null;
      let reminderIcon = "$ctiBellOutlined";
      let iconColor = DARK_GREY_ICON_COLOR;
      let text = "Enable reminder for task";
      if (!isUndefinedVal(reminderDays) && isRemindarValValid(reminderDays)) {
        reminderIcon = "$ctiBellFilled";
        iconColor = PRIMARY_COLOR;
        text = "Remove reminder for task";
      }

      return {
        icon: reminderIcon,
        color: iconColor,
        label: text,
      };
    },
  },
};
</script>
