<template>
  <div
    class="time-picker-wrapper"
    @keydown.tab="handleKeyDown"
    @keydown.enter="handleKeyDown"
    :class="classes"
  >
    <ejs-timepicker
      ref="datePicker"
      :name="name"
      :placeholder="placeholder"
      :allowEdit="true"
      :value="datetime"
      @change="handleChange"
      :format="formatToUse"
      @cleared="handleClear"
      @blur="handleBlur"
      @open="onOpen"
      @onClose="onClose"
      :showClearButton="showClearBtn"
      :min="minDate"
      :max="maxDate"
      :keyConfigs="keyConfigs"
    ></ejs-timepicker>
  </div>
</template>
<script>
import Vue from "vue";
import {
  createDateFromCommand,
  getRoundedDate,
  isValidDate,
} from "@/helpers/dates";
import { TIME_FORMAT_IN_USE } from "@/variables/dates";

import { TimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import isEqual from "date-fns/isEqual/index";
import { getPosOfEl } from "@/helpers/elements";

Vue.use(TimePickerPlugin);
export default {
  data() {
    return {
      datetime: null,
      defaultTime: null,
      keyConfigs: {
        enter: "tab,enter",
      },
    };
  },
  props: {
    name: {
      type: String,
    },
    value: {
      type: Date,
    },
    placeholder: {
      type: String,
      default: "Enter due",
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    inputWidth: {
      type: String,
      value: null,
    },
    showClearBtn: {
      type: Boolean,
      default: false,
    },
    scrollTimeList: {
      type: Boolean,
    },
    useExistingValue: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    createDate(val, providedDate) {
      let createdDate;
      if (val) {
        createdDate = createDateFromCommand(
          val,
          this.useExistingValue ? providedDate : null
        );
        if (!createdDate) {
          createdDate = this.prevDateVal;
        }
      }
      return createdDate;
    },
    handleBlur(e) {
      // let createdDate;
      const createdDate = this.createDate(
        e.model.element.value,
        this.prevDateVal
      );
      // if (e.model.element.value) {
      //   createdDate = createDateFromCommand(e.model.element.value);
      //   console.debug("CREATED DATE", createdDate, this.prevDateVal);
      //   if (!createdDate) {
      //     createdDate = this.prevDateVal;
      //   }
      // }

      const valueToSend = createdDate || this.datetime;
      this.sendBlur(valueToSend);
      this.setDateValue(valueToSend);
    },
    sendValue(value) {
      this.$emit("dateChanged", value);
    },
    handleChange(e) {
      // e.stopPropagation();
      const valueToSend = isValidDate(e.value) ? e.value : null;
      this.sendValue(valueToSend);
    },
    handleClear() {
      this.datetime = null;
    },
    setDateValue(dateValue) {
      this.datetime = dateValue;
    },
    handleKeyDown(e) {
      const createdDate = this.createDate(e.target.value, this.prevDateVal);
      const valueToSend = createdDate || this.datetime;
      this.setDateValue(valueToSend);
      this.$emit("keydown", e);
    },
    sendBlur(val) {
      this.$emit("blur", val);
    },
    handleKeyAllDown(e) {
      e.stopPropagation();
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    stopEventPropagation() {
      document.body.addEventListener("click", this.stopPropagation);
    },
    scrollToCurrentTime(popupEl) {
      const timeListWrapper = popupEl.querySelector("ul");
      const timeNodes = timeListWrapper.childNodes;

      // const currTimeRounded = getRoundedDate(30, new Date());
      // const formattedTime = formatDate(currTimeRounded, "h:mm a");
      const formattedTime = "10:00 AM";
      // const currSelectedTime = this.value;

      // const currSelectedTimeFormatted = isValidDate(currSelectedTime)
      //   ? formatDate(currSelectedTime, "kk:mm")
      //   : null;

      if (timeNodes) {
        let selectedTimeNode;
        for (const timeNode of timeNodes) {
          if (
            timeNode.getAttribute &&
            timeNode.getAttribute("data-value") === formattedTime
          ) {
            selectedTimeNode = timeNode;
            break;
          }
        }

        if (selectedTimeNode) {
          // const parentPos = getPosOfEl(popupEl); // Popup menu
          const currItemPos = getPosOfEl(selectedTimeNode); // Selected Time element
          const listWrapperPos = getPosOfEl(timeListWrapper); // Time list wrapper and its a child of Popup menu
          popupEl.scrollTop = currItemPos.top - listWrapperPos.top;
        }
      }
    },
    async onOpen(args) {
      this.stopEventPropagation();

      setTimeout(() => {
        const popupEl = args.popup.element;
        // eslint-disable-next-line no-debugger
        if (popupEl.id.includes("timepicker") && this.scrollTimeList) {
          this.scrollToCurrentTime(popupEl);
        }
      }, 0);
    },
    onClose() {
      document.body.removeEventListener("click", this.stopPropagation);
    },
  },
  computed: {
    formatToUse() {
      return TIME_FORMAT_IN_USE;
    },
    classes() {
      return {
        "input-xs": this.inputWidth === "xs",
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          let valueToSet = null;
          if (isValidDate(newValue)) {
            valueToSet = newValue;
            this.prevDateVal = newValue;
          } else {
            this.defaultTime = getRoundedDate(30, new Date());
          }
          this.datetime = valueToSet;
        }
      },
    },
  },
  destroyed() {
    document.body.removeEventListener("click", this.stopPropagation);
  },
};
</script>
<style scoped>
.time-picker-wrapper {
  width: 100%;
}

.input-xs >>> span.e-datetime-wrapper {
  width: 176px !important;
}

/* .input-xs {
  span.e-datetime-wrapper {

  }
} */
</style>
<style>
.e-datetimepicker.e-popup {
  max-height: 360px !important;
}
</style>
