export default {
  typeToUse(state) {
    return state.type;
  },
  isSelectorOpen(state) {
    return state.isSelectorOpen;
  },
  dataList(state) {
    return state.dataList;
  },
  calledFrom(state) {
    return state.calledFrom;
  },
};
