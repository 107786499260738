import { projectRestoreProps } from "@/variables/projects";
import { getCurrDate } from "../helpers/dates";
import DatabaseInterface from "@/services/DatabaseInterface";
import { PROJECT_ACTIONS, getUserActions } from "./actionHistory";
import removeProps from "@/utils/removeProps";
import { isEmpty } from "lodash";
import store from "@/store";
import createUniqueId from "@/utils/createUniqueId";
/**
 * It takes the project data and created the data required for to restore project in the provided updates object
 * @param {Object} projectData - project data which needs to be restored
 * @param {Object} updatesObj - Object which will be modified to store data
 * @param {string[]} propsToRestore - List of keys in project data to restore
 */
export const createRestoreProjectUpdateData = (
  projectData,
  updatesObj,
  propsToRestore
) => {
  propsToRestore.forEach((key) => {
    if (projectRestoreProps.includes(key)) {
      updatesObj["/projects/" + projectData.key + `/${key}`] = projectData[key];
    }
  });
  // if (propsToRestore.includes("priority")) {
  //   updatesObj["/projects/" + projectData.key + "/priority"] =
  //     projectData.priority ? projectData.priority : null;
  // }
  updatesObj["/projects/" + projectData.key + "/modified"] = getCurrDate();
};

// const getNestedProjects = (data, ) => {
//   console.debug("DATA", data, category, subcategory);
//   var rtn = {};
//   for (var i = 0; i < data.length; i++) {
//     //gets all projects from current level
//     if (data[i].type == "project") {
//       let theseTasks = [];
//       if (data[i].tasks) {
//         theseTasks = data[i].tasks;
//       }
//       rtn[data[i].key] = {
//         category: category,
//         subcategory: subcategory,
//         completed: data[i].completed,
//         created: data[i].created,
//         description: data[i].description,
//         type: data[i].hasAttribute.class,
//         icon: data[i].icon,
//         modified: data[i].modified,
//         key: data[i].key,
//         title: data[i].title,
//         priority: data[i].priority,
//         tasks: theseTasks,
//         order: i,
//         projects: [],
//       };
//     } else {
//       let tmp = [];
//       if (data[i].projects && data[i].projects.length > 0) {
//         for (let j = 0; j < data[i].projects.length; j++) {
//           //get children keys
//           tmp.push(data[i].projects[j].key);
//           let nestedProjects = this.getNestedProjects(
//             data[i].projects,
//             category,
//             data[i].key
//           );
//           Object.assign(rtn, nestedProjects);
//         }
//       }
//       rtn[data[i].key] = {
//         category: category,
//         subcategory: subcategory,
//         completed: data[i].completed,
//         created: data[i].created,
//         description: data[i].description,
//         type: data[i].hasAttribute.class,
//         icon: data[i].icon,
//         modified: data[i].modified,
//         key: data[i].key,
//         title: data[i].title,
//         priority: data[i].priority,
//         open: data[i].open,
//         tasks: [],
//         order: i,
//         projects: tmp,
//       };
//     }
//   }
//   return rtn;
// }

// export const createProjectOptsFromTree = (
//   tree,
//   allProjects = store.getters["task/projects"],
//   allCategories = "task/categroies"
// ) => {
//   let createdProjectOpts = {};
//   //get all category data
//   for (let i = 0; i < tree.length; i++) {
//     const parentKey = tree[i].key;
//     let parent = this.categories[parentKey];
//     // tmpCategories[tree[i].key] = {
//     //   order: i,
//     //   title: tree[i].title,
//     //   key: tree[i].key,
//     //   modified: tree[i].modified,
//     //   icon: tree[i].icon,
//     //   description: tree[i].description,
//     //   open: tree[i].open,
//     //   type: tree[i].hasAttribute.class,
//     //   children: tmp,
//     // };

//     //if top level node has children
//     if (tree[i].projects && tree[i].projects.length > 0) {
//       for (let j = 0; j < tree[i].projects.length; j++) {
//         //get children keys
//         // tmp.push(tree[i].projects[j].key);
//         const nestedProjectOpts = getNestedProjects(
//           tree[i].projects,
//           tree[i].key,
//           false
//         );

//         createdProjectOpts = [
//           ...createProjectOptsFromTree,
//           ...nestedProjectOpts,
//         ];
//       }
//     }
//   }
// };

export const createProjectLabel = (project, allProjects, allCategories) => {
  let labels = [];
  if (!isEmpty(project) && allProjects[project.key]) {
    labels.unshift(allProjects[project.key].title);
    labels.unshift(...getAllParentIDs(project.key, allProjects));
    if (allCategories[allProjects[project.key].category]) {
      labels.unshift(allCategories[allProjects[project.key].category].title);
    }
  }
  return labels.join(" / ");
};

export const createFullProjectPath = (project, allProjects, allCategories) => {
  let labels = [];
  if (allProjects[project.key]) {
    labels.unshift(allProjects[project.key].order);
    labels.unshift(...getParentFullPath(project.key, allProjects));
    if (allCategories[allProjects[project.key].category]) {
      labels.unshift(allCategories[allProjects[project.key].category].order);
    }
  }
  return labels.join("-");
};

const getParentFullPath = (projectParent, allProjects) => {
  let rtn = [];
  if (allProjects[projectParent].subcategory) {
    if (allProjects[allProjects[projectParent].subcategory]) {
      rtn.unshift(allProjects[allProjects[projectParent].subcategory].order);
      rtn.unshift(
        ...getParentFullPath(
          allProjects[projectParent].subcategory,
          allProjects
        )
      );
    }
  }
  return rtn;
};

const getAllParentIDs = (project, allProjects) => {
  let rtn = [];
  if (
    allProjects[project].subcategory &&
    allProjects[allProjects[project].subcategory]
  ) {
    rtn.unshift(allProjects[allProjects[project].subcategory].title);
    rtn.unshift(
      ...getAllParentIDs(allProjects[project].subcategory, allProjects)
    );
  }
  return rtn;
};

export const createProjectOpts = (
  allProjects,
  allCategories,
  doNotShowCompleted
) => {
  let opts = Object.values(allProjects);

  if (doNotShowCompleted) {
    opts = opts.filter((o) => !o.hidden && !o.deleted);
  }

  opts = opts.map((proj) => {
    return {
      fullLabel: createProjectLabel(proj, allProjects, allCategories),
      shortLabel: allProjects[proj.key].title,
      // label: createProjectLabel(proj, allProjects, allCategories),
      order: createFullProjectPath(proj, allProjects, allCategories),
      value: proj.key,
    };
  });

  const collator = new Intl.Collator([], { numeric: true });
  opts.sort((a, b) => {
    return collator.compare(a.order, b.order);
  });

  return opts;
};

export const findProjectOrCategory = (tree, predicate) => {
  for (const node of tree) {
    if (predicate(node)) return node;

    if (node.projects) {
      let match = findProjectOrCategory(node.projects, predicate);
      if (match) return match;
    }
  }
};

export const addProject = (projectData, userId, recordAction = true) => {
  if (!userId) {
    userId = store.getters["user/userInfo"]?.uid;
  }
  if (!Array.isArray(projectData)) projectData = [projectData];
  const projectsData = projectData.reduce((a, v) => {
    a[v.key] = v;
    return a;
  }, {});
  DatabaseInterface.addTo(`/${userId}/projects/`, projectsData);

  if (recordAction) {
    let actionToStore = PROJECT_ACTIONS.BATCH_ADD;
    if (projectData.length === 1) {
      actionToStore = PROJECT_ACTIONS.ADD;
      projectData = projectData[0];
    }

    getUserActions().addProjectAction({
      data: projectData,
      type: actionToStore,
    });
  }
};

export const removeProject = async (
  projectData,
  userId,
  recordAction = true
) => {
  if (!userId) {
    userId = store.getters["user/userInfo"]?.uid;
  }
  if (!Array.isArray(projectData)) projectData = [projectData];

  const projectKeysToDelete = projectData.map((proj) => proj.key);
  const currProjectsSnapshot = await DatabaseInterface.get(
    `/${userId}/projects/`
  ).get();
  let currProjects = currProjectsSnapshot.val();

  currProjects = removeProps(currProjects, projectKeysToDelete);

  DatabaseInterface.add(`/${userId}/projects/`, currProjects);
  if (recordAction) {
    let actionToStore = PROJECT_ACTIONS.BATCH_DELETE;
    if (projectData.length === 1) {
      actionToStore = PROJECT_ACTIONS.DELETE;
      projectData = projectData[0];
    }

    getUserActions().addProjectAction({
      data: projectData,
      type: actionToStore,
    });
  }
};

export const storeProjectAddAction = (projectData) => {
  if (!Array.isArray(projectData)) projectData = [projectData];

  let actionToStore = PROJECT_ACTIONS.BATCH_ADD;
  if (projectData.length === 1) {
    actionToStore = PROJECT_ACTIONS.ADD;
    projectData = projectData[0];
  }

  getUserActions().addProjectAction({
    data: projectData,
    type: actionToStore,
  });
};

export const storeProjectRemoveAction = (projectData) => {
  if (!Array.isArray(projectData)) projectData = [projectData];

  let actionToStore = PROJECT_ACTIONS.BATCH_DELETE;
  if (projectData.length === 1) {
    actionToStore = PROJECT_ACTIONS.DELETE;
    projectData = projectData[0];
  }

  getUserActions().addProjectAction({
    data: projectData,
    type: actionToStore,
  });
};

export const createProjectUpdateObj = (key, dataToUpdate, objToStore) => {
  const dataKeys = Object.keys(dataToUpdate);
  dataKeys.forEach((dataKey) => {
    objToStore[`/projects/${key}/${dataKey}`] = dataToUpdate[dataKey];
  });

  return objToStore;
};

export const updateProjectsInDb = async (
  projectsData,
  editedProjectsData,
  userId
) => {
  if (!isEmpty(projectsData)) {
    if (!Array.isArray(projectsData)) projectsData = [projectsData];
    const updates = {};
    projectsData.reduce((data, projData) => {
      const { key, updates: dataToUpdate } = projData;
      data = createProjectUpdateObj(key, dataToUpdate, data);
      return data;
    }, updates);

    await DatabaseInterface.update(updates, userId);
    if (!isEmpty(editedProjectsData)) {
      if (!Array.isArray(editedProjectsData))
        editedProjectsData = [editedProjectsData];
      let actionToUse = PROJECT_ACTIONS.BATCH_EDIT;
      let editDataToStore = editedProjectsData;
      if (editedProjectsData.length === 1) {
        actionToUse = PROJECT_ACTIONS.EDIT;
        editDataToStore = editedProjectsData[0];
      }
      getUserActions().addProjectAction({
        type: actionToUse,
        data: editDataToStore,
        on: "project",
      });
    }
  }
};

export const createProjectCategoryOpts = (categories) => {
  const opts = Object.values(categories).map((cate) => {
    return {
      fullLabel: cate.title,
      shortLabel: cate.title,
      // label: createProjectLabel(proj, allProjects, allCategories),
      order: cate.order,
      value: cate.key,
    };
  });

  const collator = new Intl.Collator([], { numeric: true });
  opts.sort((a, b) => {
    return collator.compare(a.order, b.order);
  });

  return opts;
};

export const createAllCategoriesOpts = (
  allProjects,
  allCategories,
  doNotShowCompleted
) => {
  let subcategories = Object.values(allProjects).filter(o => o.type !== "project");

  if (doNotShowCompleted) {
    subcategories = subcategories.filter((o) => !o.hidden && !o.deleted);
  }

  subcategories = subcategories.map((proj) => {
    return {
      fullLabel: createProjectLabel(proj, allProjects, allCategories),
      shortLabel: allProjects[proj.key].title,
      // label: createProjectLabel(proj, allProjects, allCategories),
      order: createFullProjectPath(proj, allProjects, allCategories),
      value: proj.key,
    };
  });

  const collator = new Intl.Collator([], { numeric: true });
  const categories = Object.values(allCategories).map((cate) => {
    return {
      fullLabel: cate.title,
      shortLabel: cate.title,
      // label: createProjectLabel(proj, allProjects, allCategories),
      order: cate.order,
      value: cate.key,
    };
  });
  
  const opts = [...categories, ...subcategories];
  opts.sort((a, b) => {
    return collator.compare(a.order, b.order);
  });


  return opts;
};

export const createProjectDataForAdd = (dataToAdd, parentId) => {
  const categories = store.getters["task/categories"];
  const projects = store.getters["task/projects"];
  const projectsTree = store.getters["task/projectsTree"];
  let createdData = {};

  let parent = findProjectOrCategory(
    projectsTree,
    (node) => node.key === parentId
  );

  if (isEmpty(parent)) {
    parent = categories[parentId];

    if (categories[parentId]) {
      parent = categories[parentId];
    } else if (projects[parentId]) {
      parent = projects[parentId];
    }
  }
  if (!isEmpty(parent)) {
    const parentType = parent.type.split(" ")[0];

    if (parentType !== "project") {
      createdData = {
        key: dataToAdd.key || createUniqueId(),
        title: dataToAdd.title || "New Project",
        hasAttribute: { class: "project" },
        type: "project",
        open: false,
        completed: false,
        created: getCurrDate(),
        description: dataToAdd.description || "New Project Description",
        icon: "mdi-circle",
        modified: getCurrDate(),
        priority: dataToAdd.priority || "",
        tasks: [],
      };
      const projectsOfParent = Object.values(projects).filter(
        (proj) =>
          proj.subcategory === parent.key || proj.category === parent.key
      );
      const currIndex = projectsOfParent?.length || 0;

      if (parentType === "category") {
        createdData.category = parent.key;
        createdData.subcategory = false;
      } else if (parentType === "subcategory") {
        createdData.category = parent.category;
        createdData.subcategory = parent.key;
      }

      createdData.order = currIndex;
    }
  }

  return createdData;
};

export const checkAndCreateProjectPriorityUpdateData = (
  updatedProjectData,
  currentProjectData
) => {
  let dbUpdates = {},
    editProjectData = {};
  if (!isEmpty(currentProjectData)) {
    let newProjectPriority;
    if (
      (!currentProjectData.priority && updatedProjectData.priority) ||
      (currentProjectData.priority !== updatedProjectData.priority &&
        updatedProjectData.priority)
    ) {
      newProjectPriority = updatedProjectData.priority;
    }

    if (newProjectPriority) {
      dbUpdates["/projects/" + currentProjectData.key + "/priority"] =
        newProjectPriority;
      dbUpdates[`/projects/${currentProjectData.key}/modified`] = getCurrDate();
      editProjectData = {
        key: currentProjectData.key,
        priority: currentProjectData.priority,
        changedProps: {
          priority: updatedProjectData?.priority || "",
        },
      };
    }
  }
  return {
    dbUpdates,
    editProjectData,
  };
};

export const checkIfProjectHasNoPriorityByKey = (projectKey) => {
  const allProjects = store.getters["task/projects"] || {};

  return (
    !isEmpty(allProjects) &&
    !isEmpty(allProjects[projectKey]) &&
    !allProjects[projectKey].priority
  );
};
