<template>
  <div class="wrapper tag-editor-wrapper">
    <ResourceTagEditorInput
      ref="tagEditor"
      v-model="title"
      @blur="handleBlur"
    />
  </div>
</template>
<script>
import ResourceTagEditorInput from "./ResourceTagEditorInput.vue";
import { columnPropsMixin } from "@/core/components/DataTable";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
export default {
  mixins: [columnPropsMixin, resourcesHelpersMixin],
  components: {
    ResourceTagEditorInput,
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    if (!this.data.addNew && this.data.tag) {
      this.title = this.data.tag;
    }
    this.onRendered(() => {
      this.focusInput();
    }, this.cell);
  },
  methods: {
    focusInput() {
      this.$refs.tagEditor.focusOnEl();
    },
    async handleBlur(e) {
      const origTagVal = (e.target.value && e.target.value.trim()) || "";
      const tagVal = origTagVal || "";
      const isAddModeEnabled = this.cell.api.isAddModeEnabled();
      if (!isAddModeEnabled && tagVal !== this.data.tag) {
        const isTagValidRes = await this.checkIfTagIsValid(
          tagVal,
          this.data.type
        );
        if (!isTagValidRes.isValid) {
          this.showAlertForTagNotValid(isTagValidRes);
          return;
        }
      }

      this.onSuccess(origTagVal, origTagVal, this.cell);
    },
  },
};
</script>
<style>
.wrapper .tag-editor-input {
  height: 33px;
  margin-top: 0;
  font-size: 13px;
  padding-top: 0;
  margin-bottom: 2px;
}
.wrapper .tag-editor-input .v-input__control {
  height: 33px;
}
.wrapper .tag-editor-input .v-input__slot {
  height: 33px;
}

.tag-editor-input .v-input__slot {
  height: 33px;
}

.tag-editor-input .v-select__slot {
  height: 33px;
}

.tag-editor-input .v-select__selections {
  height: 33px;
}
.wrapper.tag-editor-wrapper {
  width: 100%;
}
</style>
