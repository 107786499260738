<template>
  <v-btn-toggle id="forecast-btns-row" :value="selectedOptIndex" borderless>
    <v-tooltip v-for="(opt, index) in calForecastOpts" :key="index" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="handleSelectForecast(opt)"
          :class="createForecastBtnStyles(opt)"
        >
          <span class="forecast-btn-text">
            {{ opt.displayText }}
          </span>
        </v-btn>
      </template>
      <span> {{ opt.label }}</span>
    </v-tooltip>
  </v-btn-toggle>
</template>
<script>
import {
  calForecastOptsConfig,
  calForecastValMap,
} from "@/variables/viewConfigs";
export default {
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    async handleSelectForecast(forecastOpt) {
      this.$emit("input", forecastOpt);
    },

    createForecastBtnStyles(forecastOptData) {
      return {
        "forecast-btn": true,
        "forecast-selected": forecastOptData.value === this.value,
      };
    },
  },
  computed: {
    calForecastOpts() {
      return calForecastOptsConfig;
    },
    selectedOptIndex() {
      return calForecastValMap[this.value];
    },
  },
};
</script>
<style>
#forecast-btns-row {
  border-radius: 6px !important;
  background-color: #efefef !important;
  gap: 4px;
}

#forecast-btns-row .forecast-selected {
  background: var(--primary-color) !important;
  color: #fff !important;
}

#forecast-btns-row .forecast-btn {
  background-color: #efefef;
  color: var(--grey-icon-color);
}

#forecast-btns-row .forecast-btn .forecast-btn-text {
  font-size: 15px;
}

#forecast-btns-row button.forecast-btn.v-btn.v-btn.v-size--default {
  height: 22px !important;
  min-width: auto !important;
  border-radius: 6px !important;
  padding: 5px 5px !important;
}
</style>
