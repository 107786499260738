import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showBtn)?[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","tabindex":"-1"},on:{"click":_vm.toggleReminder}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.pauseBtnIconData.color,"size":"16"}},[_vm._v(_vm._s(_vm.pauseBtnIconData.icon))])],1)]}}],null,false,1200655525)},[_c('span',[_vm._v(_vm._s(_vm.pauseBtnIconData.text))])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }