export const editModesIndexes = {
  complete: 0,
  // "complete-and-repeat": 1,
  // continue: 2,
  // "clone-and-complete": 3,
  // duplicate: 3,
  // delete: 4,
  duplicate: 1,
  delete: 2,
};

export const editModesLabels = {
  complete: "Complete",
  "complete-and-repeat": "Complete and Repeat",
  "clone-and-complete": "Next",
  continue: "Continue",
  delete: "Delete",
  duplicate: "Duplicate",
};
