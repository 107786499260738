<template>
  <div class="cell-wrapper">
    <div class="cell-content-wrapper">
      <div class="area-name-txt">
        {{ name }}
      </div>

      <TaskStatusBtnVue
        v-if="showStatusBtn"
        :color="baseColor"
        :gridApi="cell.api"
        :data="data"
        :showPosition="showPosition"
        :showScheduledBtn="showScheduledBtn"
        :checkForContingent="checkContingentBtn"
        :showUnscheduledIcon="showUnscheduledIcon"
      />
      <div v-else-if="!showStatusBtn && isTaskInCalAndWithValidArea">
        <div class="">
          <OpenTaskDetails @click="openTaskInForm" btnClass="scheduled-btn">
            <v-icon v-if="showContingentIcon" :color="baseColor" :size="17">
              $ctiCalendarCross
            </v-icon>
            <v-icon v-else :color="baseColor" :size="22"> $ctiSchedule </v-icon>
          </OpenTaskDetails>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { calModes } from "@/variables/viewConfigs";
import { mapGetters } from "vuex";
import isUndefinedVal from "@/utils/isUndefinedVal";
import taskHelpersMixin from "@/mixins/tasksHelpersMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import OpenTaskDetails from "./OpenTaskDetails.vue";
import TaskStatusBtnVue from "./TaskStatusBtn.vue";
import isEmpty from "lodash/isEmpty";
import { checkTaskSettingsIsNotEmpty } from "@/helpers/tasks";
export default {
  components: {
    TaskStatusBtnVue,
    OpenTaskDetails,
  },
  mixins: [columnPropsMixin, taskHelpersMixin],
  name: "Project",
  props: ["data", "options"],
  methods: {
    openTaskInForm() {
      this.openTaskDataInEditForm({
        taskData: { ...this.data },
        gridApi: this.cell.api,
      });
    },
  },
  computed: {
    ...mapGetters("task", ["currNav", "isGlobalViewEnabled", "projects"]),
    ...mapGetters(["taskSettings"]),
    showScheduledBtn() {
      return (
        this.extraData.showScheduledIcon ||
        this.isInCalModes ||
        this.isInNextActionsMode ||
        this.isInInboxViewOrGlobalEnabled
      );
    },
    checkContingentBtn() {
      return this.extraData?.checkForContingent;
    },
    showUnscheduledIcon() {
      return this.extraData?.showUnscheduledIcon;
    },
    showPosition() {
      return this.currNav === 4 || this.extraData?.showPosition;
    },
    name() {
      const project = this.projects[this.data.project];
      return (project?.title || "None").replace(/(\s\(.+\))/, "");
    },
    baseColor() {
      let color = "orange";
      if (this.extraData?.useDynamicColor && this.extraData?.colorProvider) {
        color = this.extraData.colorProvider(this);
      }

      return color;
    },

    isTaskInCalAndWithValidArea() {
      const isInDashboard = this.$route.name === "Dashboard";

      const res =
        !checkTaskSettingsIsNotEmpty(this.taskSettings) &&
        isInDashboard &&
        this.isInCalModes &&
        (this.data.due !== "none" || !!this.data.showContingentInToday);

      return res;
    },
    showContingentIcon() {
      return (
        this.extraData?.checkForContingent &&
        // this.isInCalModes &&
        !!this.data.showContingentInToday
      );
    },

    showStatusBtn() {
      return this.checkIfStatusBtnIsVisibleInAreaColumn();
      // return (
      //   (!isEmpty(this.taskSettings?.statusBtnPosition) &&
      //     this.taskSettings?.statusBtnPosition === "in-area") ||
      //   (isEmpty(this.taskSettings?.statusBtnPosition) &&
      //     calModes.includes(this.currNav) &&
      //     this.isGlobalViewEnabled)
      // );
    },
  },
};
</script>
<style scoped>
.cell-content-wrapper {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.cell-wrapper {
  width: 100%;
}
.cell-wrapper .area-name-txt {
  /* border: 1px solid red; */
}

.cell-wrapper .scheduled-btn {
  /* margin-right: 4px; */
}

.cell-wrapper .area-name-txt {
  min-width: 0;
  padding-right: 0.3rem;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .cell-wrapper .scheduled-btn {
  border
} */
</style>
