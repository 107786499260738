export const resourceTypesMap = {
  project: {
    icon: "$ctiProject",
    iconSize: "12",
    iconColor: "#0070C0",
    color: "#0070C0",
    bgColor: "#E0EDFF",
    activeStyles: {
      borderColor: "#0070C0",
    },
    key: "project",
    title: "Projects",
    order: 0,
  },
  people: {
    icon: "$ctiPersonNewIcon",
    color: "#FF0000",
    iconSize: "12",
    iconColor: "#FF0000",
    bgColor: "#FFD8D8",
    activeStyles: {
      borderColor: "#FF0000",
    },
    key: "people",
    title: "People",
    order: 1,
  },
  place: {
    icon: "$ctiPlaceNewIcon",
    color: "#00B050",
    iconSize: "12",
    iconColor: "#00B050",
    bgColor: "#DDFFD8",
    activeStyles: {
      borderColor: "#00B050",
    },
    key: "place",
    title: "Places",
    order: 2,
  },
  thing: {
    icon: "$ctiThingNew",
    color: "#7730A0",
    key: "thing",
    title: "Things",
    order: 3,
    iconSize: "12",
    iconColor: "#7730A0",
    bgColor: "#EFE2FF",
    activeStyles: {
      borderColor: "#7730A0",
    },
  },
};

export const resourcesTypeKeys = Object.keys(resourceTypesMap);

const resourceTypeOptsList = Object.values(resourceTypesMap);
resourceTypeOptsList.sort((a, b) => a.order - b.order);

export const resourceTypePlaceholders = {
  project: "Project information",
  people: "Information about this person",
  thing: "Information about this place",
  place: "Information about this thing",
};

export const resourceTypeFilterOptsMap = {
  // all: {
  //   icon: "mdi-package-variant-closed",
  //   key: "all",
  //   title: "All",
  //   order: 0,
  // },
  ...resourceTypesMap,
  place: {
    ...resourceTypesMap["place"],
    title: "Places",
  },
  thing: {
    ...resourceTypesMap["thing"],
    title: "Things",
  },
};

const resourceTypeFilterOptList = Object.values(resourceTypeFilterOptsMap);

resourceTypeFilterOptList.sort((a, b) => a.order - b.order);

export const resourceTypeFilterIndexs = {
  // all: 0,
  // project: 0,
  // people: 1,
  // place: 2,
  // thing: 3,
  people: 0,
  place: 1,
  thing: 2,
};

export const resourceTypeLabels = {
  // all: 0,
  project: "Project",
  people: "People",
  place: "Place",
  thing: "Thing",
};

export { resourceTypeOptsList, resourceTypeFilterOptList };
