<template>
  <v-row no-gutters>
    <v-col cols="auto">
      <v-btn-toggle
        id="filter-btns-row"
        :value="filterOptIndexes"
        multiple
        borderless
      >
        <template v-for="(filterOpt, index) in filterOpts">
          <v-tooltip bottom :key="index">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="handleSelectFilter(filterOpt)"
                :class="createFilterBtnStyles(filterOpt)"
                x-small
              >
                <template v-if="filterOpt.icon">
                  <v-icon :color="getFilterIconColor(filterOpt)">
                    {{ getFilterIcon(filterOpt) }}
                  </v-icon>
                </template>
                <template v-else>
                  <span class="filter-btn-text">
                    {{ filterOpt.title }}
                  </span>
                </template>
              </v-btn>
            </template>
            <span> Filter: {{ filterOpt.title }}</span>
          </v-tooltip>
        </template>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>
<script>
import {
  todayModeFiltersMap,
  todayModeFilters,
  calendarModeFilters,
  calendarModeFiltersMap,
} from "@/variables/viewConfigs";
import { mapGetters, mapMutations } from "vuex";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { DARK_ICON_COLOR } from "@/variables/colors";
export default {
  mixins: [userDetailsMixin],
  props: {
    mode: {
      type: String,
      default: "today",
    },
  },
  methods: {
    ...mapMutations("task", ["updateState"]),
    handleSelectFilter(filterItem) {
      const navToUse = this.mode === "calendar" ? 0 : 3;
      const filterVal = filterItem.value;

      const currentContingentFilters = cloneDeep(this.contingentFilter);

      if (currentContingentFilters.includes(filterVal)) {
        currentContingentFilters.splice(
          currentContingentFilters.indexOf(filterVal),
          1
        );
      } else {
        currentContingentFilters.push(filterVal);
      }

      if (!this.isInspectModeEnabled) {
        DatabaseInterface.update(
          {
            [`/view/${navToUse}/selectedTypes`]: currentContingentFilters,
          },
          this.userInfo.uid
        );
      } else {
        this.updateState({
          contingentFilter: currentContingentFilters,
        });
      }
    },

    createFilterBtnStyles(filterData) {
      let isFilterSelected = false;
      const currFilters = this.contingentFilter;
      if (!isEmpty(currFilters)) {
        isFilterSelected = currFilters.includes(filterData.value);
      }

      return {
        "filter-btn": true,
        "filter-selected": isFilterSelected,
        [`${filterData.value}-btn`]: true,
      };
    },
    getFilterIconColor(filterData) {
      return this.contingentFilter.includes(filterData.value)
        ? "#fff"
        : DARK_ICON_COLOR;
    },
    getFilterIcon(filterData) {
      let iconToUse = filterData.icon;

      if (
        this.contingentFilter.includes(filterData.value) &&
        filterData.activeIcon
      ) {
        iconToUse = filterData.activeIcon;
      }

      return iconToUse;
    },
  },
  computed: {
    ...mapGetters("task", ["contingentFilter", "isInspectModeEnabled"]),
    filterOpts() {
      return this.mode === "calendar" ? calendarModeFilters : todayModeFilters;
    },
    filterOptIndexes() {
      let selectedIndexes = [];
      const mapToUse =
        this.mode === "calendar" ? calendarModeFiltersMap : todayModeFiltersMap;

      const currentContingentFilters = this.contingentFilter || [];
      currentContingentFilters.forEach((f) => {
        if (mapToUse[f] !== undefined) {
          selectedIndexes.push(mapToUse[f]);
        }
      });

      return selectedIndexes;
    },
  },
};
</script>
<style scoped>
#filter-btns-row {
  border-radius: 6px !important;
  background: #efefef !important;
}

#filter-btns-row .filter-selected {
  background: var(--primary-color) !important;
}

#filter-btns-row .filter-btn {
  height: 20px !important;
  min-width: 34px !important;
  border-radius: 6px !important;
  padding: 5px 10px !important;
}
#filter-btns-row .filter-btn.non-contingents-btn >>> svg {
  height: 13px !important;
  width: 16px !important;
}
#filter-btns-row .filter-btn.today-btn >>> svg {
  height: 13px !important;
  width: 16px !important;
}

#filter-btns-row .filter-btn.contingents-btn >>> svg {
  height: 14px !important;
  width: 15px !important;
}

#filter-btns-row .filter-btn.reminders-btn >>> svg {
  height: 13px !important;
  width: 14px !important;
}
</style>
