import Vue from "vue";
import { RESOURCE_ACTIONS } from "@/helpers/actionHistory";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import resourcesCateUndRedoHelpersMixin from "@/mixins/resourcesCateUndoRedoHelpersMixin";
// import { ACTION_ON_TYPES } from "@/helpers/actionHistory";
import {
  addOrUpdateOrRemoveResourcesInLocalList,
  createResourceDataForAdd,
  createRestoredDataOfResource,
  expandAllParentsOfResource,
  getUserResources,
  processResourceData,
  setResourceIdInTree,
} from "@/helpers/resources";
import { mapGetters, mapActions } from "vuex";
import removeProps from "@/utils/removeProps";
import { cloneDeep, isEmpty } from "lodash";
import EventEmitter from "@/helpers/eventEmitter";
import {
  REFRESH_ACTIVITY_HISTORY,
  REFRESH_RESOURCE_DESCR,
  REFRESH_RESOURCE_FORM_DATA,
} from "@/variables/events";
const mixin = {
  mixins: [resourcesHelpersMixin, resourcesCateUndRedoHelpersMixin],
  methods: {
    async handleResourceRedoAction(action) {
      switch (action.type) {
        case RESOURCE_ACTIONS.ADD:
        case RESOURCE_ACTIONS.BATCH_ADD:
          this.handleAddResr(action.data);
          break;
        case RESOURCE_ACTIONS.DELETE:
        case RESOURCE_ACTIONS.BATCH_DELETE:
          this.handleRemoveResr(action.data);
          break;
        case RESOURCE_ACTIONS.EDIT:
        case RESOURCE_ACTIONS.BATCH_EDIT:
          this.restoreResourcesData(action.data, true);
          break;
        case RESOURCE_ACTIONS.MOVED_OR_REORDERED:
          this.handleResourceMoved(action.data, true);
          break;

        default:
        // Do nothing
      }
    },
    handleResourceUndoAction(action) {
      switch (action.type) {
        case RESOURCE_ACTIONS.ADD:
        case RESOURCE_ACTIONS.BATCH_ADD:
          this.handleRemoveResr(action.data);
          break;
        case RESOURCE_ACTIONS.DELETE:
        case RESOURCE_ACTIONS.BATCH_DELETE:
          this.handleAddResr(action.data);
          break;
        case RESOURCE_ACTIONS.EDIT:
        case RESOURCE_ACTIONS.BATCH_EDIT:
          this.restoreResourcesData(action.data);
          break;
        case RESOURCE_ACTIONS.MOVED_OR_REORDERED:
          this.handleResourceMoved(action.data);
          break;

        default:
        // Do nothing
      }
    },
    // handleUndoAction() {
    //   let actions = getUserActions().getActions();
    //   const currAction = actions[0];
    //   let actionInUse = true;
    //   if (!currAction) {
    //     this.showToast("No action to undo!");
    //     return;
    //   }
    //   getUserActions().storeActionInHistory({ ...currAction });
    //   switch (currAction.on) {
    //     case ACTION_ON_TYPES.RESOURCE:
    //       this.handleResourceUndoAction(currAction);
    //       break;
    //     case ACTION_ON_TYPES.RESOURCES_CATEGORY:
    //       this.handleUndoOnResrCate(currAction);
    //       break;
    //     case ACTION_ON_TYPES.ALL:
    //       this.handleAllBatchAction(currAction.data);
    //       break;
    //     default:
    //       actionInUse = false;
    //   }

    //   if (actionInUse) {
    //     getUserActions().removeActionByIndex(0);
    //   }
    // },
    // async handleRedoAction() {
    //   const actions = [...getUserActions().getActionsHistory()];
    //   const currAction = actions[0];
    //   let actionInUse = true;
    //   if (!currAction) {
    //     this.showToast("No action to redo!");
    //     return;
    //   }

    //   switch (currAction.on) {
    //     case ACTION_ON_TYPES.RESOURCE:
    //       await this.handleResourceRedoAction(currAction);
    //       break;
    //     case ACTION_ON_TYPES.RESOURCES_CATEGORY:
    //       await this.handleRedoOnResrCate(currAction);
    //       break;
    //     case ACTION_ON_TYPES.ALL:
    //       await this.handleAllBatchAction(currAction.data, true);
    //       break;
    //     default:
    //       actionInUse = false;
    //   }

    //   if (actionInUse) {
    //     getUserActions().removeActionFromHistoryByIndex(0);
    //   }
    // },
    async handleAddResr(resrcToAdd) {
      if (!Array.isArray(resrcToAdd)) resrcToAdd = [resrcToAdd];

      const localAddList = [];
      const dbAddList = [];
      resrcToAdd = resrcToAdd.forEach((resrc) => {
        const clonedRescrData = cloneDeep(resrc);
        delete clonedRescrData.showInTree;

        dbAddList.push(
          createResourceDataForAdd(
            clonedRescrData,
            this.resources,
            this.categories
          )
        );
        localAddList.push(
          createResourceDataForAdd(resrc, this.resources, this.categories)
        );
      });

      await this.addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToAdd: [...dbAddList],
        },
        false
      );
      await addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToAdd: [...localAddList],
      });
    },
    async handleRemoveResr(resrcToRemove) {
      await this.addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToRemove: [...resrcToRemove],
        },
        false
      );

      await addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToRemove: [...resrcToRemove],
      });
    },
    async restoreResourcesData(resourcesDataToRestore, isRedoAction) {
      if (!Array.isArray(resourcesDataToRestore))
        resourcesDataToRestore = [resourcesDataToRestore];
      let updatedDataForLocalEditForm = {};
      const updatesForTable = [];
      let refreshDescr = false,
        refreshFormType;
      resourcesDataToRestore = resourcesDataToRestore.map((resource) => {
        let dataToUse = { ...resource.changedProps };
        const allowDescrRefresh = resource.refreshDescr;
        const doNotUpdateForm = resource.doNotUpdateForm;
        refreshFormType = resource.refreshFormType;

        if (!isRedoAction) {
          dataToUse = removeProps(cloneDeep(resource), [
            "changedProps",
            "refreshDescr",
            "refreshFormType",
            "doNotUpdateForm",
          ]);
        }
        updatesForTable.push({
          updates: { ...dataToUse },
          key: resource.key,
        });

        if (
          !isEmpty(this.editResourceData) &&
          this.editResourceData.key === resource.key &&
          !doNotUpdateForm
        ) {
          refreshDescr = !!allowDescrRefresh;
          updatedDataForLocalEditForm = {
            ...dataToUse,
            key: resource.key,
          };
        }
        return {
          updates: createRestoredDataOfResource(dataToUse),
          key: resource.key,
        };
      });

      this.addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToUpdate: [...resourcesDataToRestore],
        },
        false
      );
      await addOrUpdateOrRemoveResourcesInLocalList(
        {
          resourcesToUpdate: [...updatesForTable],
        },
        true
      );

      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY, true);
      await Vue.nextTick();

      if (!isEmpty(updatedDataForLocalEditForm)) {
        this.updateLocalEditResourceData(
          updatedDataForLocalEditForm,
          refreshDescr
        );
      } else if (refreshFormType) {
        EventEmitter.emit(REFRESH_RESOURCE_DESCR);
      }
    },
    async updateLocalEditResourceData(dataToProcess, refreshDescr) {
      const allResources = getUserResources().getResources();

      if (
        !isEmpty(this.editResourceData) &&
        allResources[this.editResourceData.key]
      ) {
        const currRawResourceData = {
          ...getUserResources().getResources()[this.editResourceData.key],
        };

        const processedData = processResourceData({
          ...currRawResourceData,
          ...dataToProcess,
          key: this.editResourceData.key,
        });
        const updatedResourceType = processedData.type;
        if (updatedResourceType !== this.selectedResourceTypeOpt) {
          await setResourceIdInTree(processedData);
          await Vue.nextTick();
          expandAllParentsOfResource(processedData);

          this.setAdditionalTaskFilters({
            key: "resourceTypes",
            list: [processedData.type],
          });
        }
        this.setResourceData(processedData);
        await this.$nextTick();
        EventEmitter.emit(REFRESH_RESOURCE_FORM_DATA, true, refreshDescr);
      }
    },
    async handleResourceMoved(dataToProcess, isRedoAction) {
      let dataToUse = { ...dataToProcess.changedProps };
      if (!isRedoAction) {
        dataToUse = removeProps(cloneDeep(dataToProcess), ["changedProps"]);
      }

      const currResourceData = this.rawResourcesMap[dataToProcess.key];

      if (!isEmpty(currResourceData)) {
        // const updatedResourceData = { ...currResourceData, ...dataToUse };

        const updates = createRestoredDataOfResource(dataToUse);

        this.addOrRemoveOrUpdateResourcesInList(
          {
            resourcesToUpdate: [
              {
                updates: updates,
                key: currResourceData.key,
              },
            ],
          },
          false
        );
        addOrUpdateOrRemoveResourcesInLocalList({
          resourcesToUpdate: [
            {
              updates,
              key: currResourceData.key,
            },
          ],
        });
        // const catTreeView = document.querySelector("#resources-categories-tree")
        //   .ej2_instances[0];
        // const parentKey = updatedResourceData.catId || "root";
        // let increaseIndex = false;
        // const moveParentData = catTreeView.getTreeData(parentKey)[0];
        // const currItemData = catTreeView.getTreeData(
        //   updatedResourceData.key
        // )[0];
        // const children = moveParentData.children;
        // const currItemParent = currItemData["catId"];
        // let orderToUse = parseInt(updatedResourceData.order, 10);
        // if (!isEmpty(children)) {
        //   if (currItemParent && currItemParent === parentKey) {
        //     increaseIndex = orderToUse !== 0 && !isEmpty(children[orderToUse]);
        //   }
        // }

        // if (!increaseIndex) {
        //   increaseIndex =
        //     !isEmpty(children[orderToUse]) &&
        //     children[orderToUse] === children[children.length - 1];
        // }

        // if (increaseIndex) {
        //   orderToUse++;
        // }

        // EventEmitter.emit(REFRESH_TREE_EVENT);
        // catTreeView.moveNodes([updatedResourceData.key], parentKey, orderToUse);
      }
    },
    ...mapActions("resourceInfo", ["setResourceData"]),
    ...mapActions("task", ["setAdditionalTaskFilters"]),
  },
  computed: {
    ...mapGetters("resourcesData", {
      categories: "categories",
      resources: "resources",
      rawResourcesMap: "rawResourcesMap",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
    }),
    ...mapGetters("resourceInfo", {
      editResourceData: "data",
      storedTaskDataInResourceForm: "storedTaskData",
    }),
  },
};

export default mixin;
