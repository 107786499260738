import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.clearBtnData.btnClasses,attrs:{"depressed":"","x-small":"","light":""},on:{"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.handleSingleClick.apply(null, arguments)},"dblclick":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.handleDblClick.apply(null, arguments)}}},'v-btn',Object.assign({}, attrs, _vm.btnAttrs),false),on),[_c(VIcon,{attrs:{"size":"12"}},[_vm._v(" "+_vm._s(_vm.clearBtnIcon)+" ")]),(_vm.count)?_c('span',{staticClass:"ml-2 filter-text"},[_vm._v(" ("+_vm._s(_vm.count)+") ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.clearBtnLabel)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }