import {
  addOrUpdateOrRemoveResourcesInLocalList,
  checkIfTagIsValid,
  cleanResources,
  getUserResources,
  extractEditedDataFromResourcesUpdate,
  getResourceTreeView,
  setResourceIdInTree,
  getUsedTagsByResourceType,
  processResourceData,
  processResources,
  addOrRemoveOrUpdateResourcesInList,
} from "@/helpers/resources";
// import { TASK_ACTIONS, getUserActions } from "@/helpers/actionHistory";
import userDetailsMixin from "./userDetailsMixin";
import cloneDeep from "lodash/cloneDeep";
import {
  RESOURCE_CATEGORY_ACTIONS,
  getUserActions,
  RESOURCE_ACTIONS,
} from "@/helpers/actionHistory";
import isEmpty from "lodash/isEmpty";
import { convertValueToArray } from "@/helpers/common";
import { PRIMARY_COLOR } from "@/variables/colors";
import Vue from "vue";
import DatabaseInterface from "@/services/DatabaseInterface";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { expandAllParentsOfResource } from "@/helpers/resources";
import EventEmitter from "@/helpers/eventEmitter";
import {
  REFRESH_RESOURCE_FORM_DATA,
  REFRESH_RESOURCE_ID_FILTER,
  SELECT_TASK_IN_ACTIVITY_GRID,
  TOGGLE_RESOURCE_FORM_EVENT,
} from "@/variables/events";
import {
  checkIfTaskIsParentRecurringTask,
  getFirstVirtualReccuringTask,
} from "@/helpers/tasks";
import { isEqual, isPlainObject } from "lodash";
import { closeTaskCateTreeview } from "@/helpers/categories";
import { checkIfResourceNodeHasNoProject } from "@/helpers/resourceCategoriesHelpers";
// import store from "@/store";

// /**
//  * It takes the list of notes to remove
//  * @param {Object[]} notesToRemove - list of notes to remove
//  * @param {Boolean} recordAction - To record action or not by default its true
//  * @returns {Promise}
//  */
// export const removeNotesFromNotesList = async (
//   notesToRemove,
//   recordAction = true
// ) => {
//   if (!Array.isArray(notesToRemove)) notesToRemove = [notesToRemove];
//   const noteIds = [];

//   notesToRemove.forEach((note) => noteIds.push(note.key));
//   //   const cleanedTasks = notesToRemove.map((note) => {
//   //     no
//   //     return note
//   //   }
//   // cleanTask(fillTaskData(task))
//   //   );

//   await getUserNotes().removeMulti(noteIds);

//   // //   await deleteTasks(cleanedTasks, this.userInfo.uid);

//   if (recordAction) {
//     const cleanedNotes = cleanNotes(notesToRemove);
//     getUserActions().addNoteAction({
//       type: NOTES_ACTIONS.BATCH_DELETE,
//       data: cleanedNotes,
//     });
//   }
// };

// /**
//  * It takes the list of notes to add
//  * @param {Object[]|Object} notesToAdd - list of notes to add
//  * @param {Boolean} recordAction - To record action or not by default its true
//  * @param {Boolean} clean - To clean notes before adding default is true
//  * @returns {Promise}
//  */
// export const addMultiResourcesInNotesList = async (
//   notesToAdd,
//   recordAction = true,
//   clean = true
// ) => {
//   if (!Array.isArray(notesToAdd)) notesToAdd = [notesToAdd];

//   if (clean) notesToAdd = cleanNotes(notesToAdd);

//   await getUserNotes().addMulti(notesToAdd);
//   if (recordAction) {
//     await getUserActions().addNoteAction({
//       type: NOTES_ACTIONS.BATCH_ADD,
//       data: notesToAdd,
//     });
//   }
// };

// /**
//  * It takes the list of tasks and converts them to notes
//  * @param {Object[]|Object} tasksToConvert - list of tasks to convert
//  * @returns {Promise}
//  */
// export const convertTasksToNotes = async (tasksToConvert) => {
//   if (!Array.isArray(tasksToConvert)) tasksToConvert = [tasksToConvert];
//   const tasksToRemove = [];
//   const notesToAdd = [];

//   tasksToConvert.forEach((task) => {
//     const { key, ...restData } = task;

//     const createdNoteData = createNoteDataForAdd({ ...restData });
//     notesToAdd.push({ ...createdNoteData });
//     tasksToRemove.push({ ...restData, key });
//   });

//   addMultiNotesInNotesList(notesToAdd, false);
//   removeTasksFromTaskList(tasksToRemove, false);
// };

export const updateResourceCateInResourceCateData = async (cateToUpdate) => {
  if (!Array.isArray(cateToUpdate)) cateToUpdate = [cateToUpdate];

  // if (cleanTasks) tasksToAdd = cleanTaskList(tasksToAdd);
  const currCategories = cloneDeep(getUserResources().getCategories());
  let editedList = [];
  cateToUpdate.forEach((d) => {
    if (currCategories[d.key]) {
      const currData = currCategories[d.key];
      currCategories[d.key] = {
        ...currData,
        ...d.updates,
        key: d.key,
      };

      if (!isEmpty(d.editedData)) {
        editedList.push({
          ...d.editedData,
        });
      }
    }
  });

  // tasksToAdd = cleanTasks(tasksToAdd);
  await getUserResources().set(currCategories, "categories");

  return {
    editedList,
  };
};

const mixin = {
  mixins: [userDetailsMixin],
  methods: {
    ...mapMutations("resourcesData", {
      setResourceStateData: "setData",
    }),
    ...mapMutations("task", {
      updateTasksState: "updateState",
    }),
    ...mapActions("resourceInfo", [
      "setLinkedTaskData",
      "setResourceData",
      "closeResourceInfoViewer",
      "openResourceInfoViewer",
    ]),
    ...mapActions("task", {
      clearAdditionalTaskFilters: "clearAdditionalFilters",
    }),
    ...mapActions(["updateRootState"]),
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    checkIfTagIsValid(tagToCheck, resourceType) {
      return new Promise((resolve) => {
        const isTagValidRes = checkIfTagIsValid(
          tagToCheck,
          undefined,
          resourceType
        );
        resolve(isTagValidRes);
      });
    },
    showAlertForTagNotValid(tagRes) {
      this.$swal({
        titleText: "Oops!",
        html: `${tagRes.error}${tagRes.subMsg ? `.<br/>${tagRes.subMsg}` : ""}`,
        icon: "error",
        confirmButtonColor: PRIMARY_COLOR,
        iconColor: PRIMARY_COLOR,
      });
    },

    addOrRemoveOrUpdateResourcesInList,
    // async addOrRemoveOrUpdateResourcesInList(
    //   { resourcesToAdd, resourcesToUpdate, resourcesToRemove },
    //   recordAction = true
    // ) {
    //   resourcesToAdd = convertValueToArray(resourcesToAdd);
    //   resourcesToRemove = convertValueToArray(resourcesToRemove);
    //   resourcesToUpdate = convertValueToArray(resourcesToUpdate);
    //   const userResourcesData = await getUserResources();
    //   const currDbResources = userResourcesData.getResources();
    //   const currDbUsedTagsMap = userResourcesData.getUsedTags();
    //   let currRawResources = !isEmpty(currDbResources)
    //     ? cloneDeep(currDbResources)
    //     : {};

    //   const currUsedTags = !isEmpty(currDbUsedTagsMap)
    //     ? cloneDeep(currDbUsedTagsMap)
    //     : {};
    //   let actionToStore, actionData;
    //   if (resourcesToAdd && resourcesToAdd.length) {
    //     currRawResources = addResourcesInResourcesMap(
    //       resourcesToAdd,
    //       currRawResources,
    //       currUsedTags
    //     );
    //     actionToStore = RESOURCE_ACTIONS.BATCH_ADD;
    //     actionData = [...resourcesToAdd];
    //   }

    //   if (resourcesToUpdate && resourcesToUpdate.length) {
    //     actionToStore = RESOURCE_ACTIONS.BATCH_EDIT;
    //     actionData = extractEditedDataFromResourcesUpdate(resourcesToUpdate);

    //     currRawResources = updateResourcesInResourcesMap(
    //       resourcesToUpdate,
    //       currRawResources,
    //       currUsedTags,
    //       actionData
    //     );
    //   }

    //   if (resourcesToRemove && resourcesToRemove.length) {
    //     currRawResources = removeResourcesFromResourcesMap(
    //       resourcesToRemove,
    //       currRawResources,
    //       currUsedTags
    //     );
    //     actionToStore = RESOURCE_ACTIONS.BATCH_DELETE;
    //     actionData = cleanResources(resourcesToRemove);
    //   }

    //   await userResourcesData.setMultiFields([
    //     {
    //       key: "resources",
    //       value: currRawResources,
    //     },

    //     {
    //       key: "usedTagsMap",
    //       value: currUsedTags,
    //     },
    //     // {
    //     //   key: "usedTags",
    //     //   value: currUsedTags,
    //     // },
    //   ]);

    //   if (recordAction && actionToStore) {
    //     getUserActions().addResourceAction({
    //       data: actionData,
    //       type: actionToStore,
    //     });
    //   }

    //   return true;
    // },

    async removeResourcesFromLocalAndDb(resourcesToRemove) {
      resourcesToRemove = convertValueToArray(resourcesToRemove);
      // Vue.nextTick(() => {
      await this.addOrRemoveOrUpdateResourcesInList({
        resourcesToRemove,
      });
      addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToRemove,
      });
      // Vue.nextTick(() => {

      // });
      // });
    },
    async addMultiCate(categoriesToAdd, recordAction = true) {
      if (!Array.isArray(categoriesToAdd)) {
        categoriesToAdd = [categoriesToAdd];
      }

      await getUserResources().addMulti(categoriesToAdd, "categories");
      if (recordAction) {
        let actionType = RESOURCE_CATEGORY_ACTIONS.BATCH_ADD;
        if (categoriesToAdd.length === 1) {
          actionType = RESOURCE_CATEGORY_ACTIONS.ADD;
          categoriesToAdd = categoriesToAdd[0];
        }
        await getUserActions().addResourceCateAction({
          type: actionType,
          data: categoriesToAdd,
        });
      }
    },

    async updateMultiCate(cateToUpdate, recordAction = true) {
      const { editedList } = await updateResourceCateInResourceCateData(
        cateToUpdate
      );

      // if (!Array.isArray(cateToUpdate)) cateToUpdate = [cateToUpdate];

      // // if (cleanTasks) tasksToAdd = cleanTaskList(tasksToAdd);
      // const currCategories = cloneDeep(getUserResources().getCategories());
      // let editedList = [];
      // cateToUpdate.forEach((d) => {
      //   if (currCategories[d.key]) {
      //     const currData = currCategories[d.key];
      //     currCategories[d.key] = {
      //       ...currData,
      //       ...d.updates,
      //       key: d.key,
      //     };

      //     if (!isEmpty(d.editedData)) {
      //       editedList.push({
      //         ...d.editedData,
      //       });
      //     }
      //   }
      // });

      // // tasksToAdd = cleanTasks(tasksToAdd);
      // await getUserResources().set(currCategories, "categories");
      if (recordAction) {
        this.recordUpdateDirAction(editedList);
        // let actionType = DIRECTORY_ACTIONS.BATCH_EDIT;
        // if (editedList.length === 1) {
        //   actionType = DIRECTORY_ACTIONS.EDIT;
        //   editedList = editedList[0];
        // }
        // // const tasksData = createAddedTaskDataForAddAction(tasksToAdd);
        // await getUserActions().addDirAction({
        //   type: actionType,
        //   data: editedList,
        // });
      }
    },
    async removeMultiCate(categoriesToRemove, recordAction = true) {
      if (!Array.isArray(categoriesToRemove))
        categoriesToRemove = [categoriesToRemove];
      const cateIds = [];

      categoriesToRemove.forEach((cate) => cateIds.push(cate.key));
      await getUserResources().removeMulti(cateIds, "categories");
      if (recordAction) {
        let actionType = RESOURCE_CATEGORY_ACTIONS.BATCH_DELETE;
        if (categoriesToRemove.length === 1) {
          actionType = RESOURCE_CATEGORY_ACTIONS.DELETE;
          categoriesToRemove = categoriesToRemove[0];
        }
        await getUserActions().addDirAction({
          type: actionType,
          data: categoriesToRemove,
        });
      }
    },
    // Adjust resort logic of categories
    // async resortChildrenInParentFolder(dirId) {
    //   const dir = this.directories[dirId];
    //   let children = this.notesTree;
    //   if (dir) {
    //     if (dir.parentKey) {
    //       children = this.dirChildrenMap[dir.parentKey];
    //     }

    //     const updates = [];
    //     if (children && children.length) {
    //       children = cloneDeep(children);
    //       children.sort((a, b) => a.order - b.order);
    //       children.forEach((dC, index) => {
    //         updates.push({
    //           updates: {
    //             order: index,
    //           },
    //           key: dC.key,
    //         });
    //       });

    //       await this.updateMultiDir(updates, false);
    //     }
    //   }
    // },

    // async removeFolderFromDirs(selectedDir, recordAction = true) {
    //   let parentKey = selectedDir.parentKey || "";
    //   // let dirOrder = selectedDir.order || 0;
    //   let currDirectories = cloneDeep(this.directories);
    //   delete currDirectories[selectedDir.key];

    //   let children = this.notesTree;
    //   if (parentKey && this.directories[parentKey]) {
    //     children =
    //       findDir(this.notesTree, (node) => node.key === parentKey)?.children ||
    //       [];
    //   }
    //   children = cloneDeep(children);

    //   if (children && children.length) {
    //     const dirIndex = children.findIndex((c) => c.key === selectedDir.key);
    //     if (dirIndex >= 0) {
    //       children.splice(dirIndex, 1);
    //     }
    //     children.sort((a, b) => a.order - b.order);
    //     children.forEach((c, index) => {
    //       if (currDirectories[c.key]) {
    //         currDirectories[c.key] = {
    //           ...currDirectories[c.key],
    //           order: index,
    //           parentKey: currDirectories[c.key].parentKey || "",
    //         };
    //       }
    //     });
    //   }

    //   await getUserNotes().set(currDirectories, "directories");

    //   if (recordAction) {
    //     await getUserActions().addDirAction({
    //       type: DIRECTORY_ACTIONS.DELETE,
    //       data: selectedDir,
    //     });
    //   }
    // },
    async recordUpdateDirAction(editedList) {
      if (!Array.isArray(editedList)) editedList = [editedList];
      let actionType = RESOURCE_CATEGORY_ACTIONS.BATCH_EDIT;
      if (editedList.length === 1) {
        actionType = RESOURCE_CATEGORY_ACTIONS.EDIT;
        editedList = editedList[0];
      }
      await getUserActions().addResourceCateAction({
        type: actionType,
        data: editedList,
      });
    },

    async clearListFiltersList(clearSelection, focusOnTree) {
      const currType = this.selectedResourceTypeOpt;

      const catTree = getResourceTreeView("active").treeInstance;

      let taskSelectedCategories;
      const dbUpdates = {
        showCompleted: false,
      };

      if (clearSelection && this.isProjectsResourceModeEnabled) {
        dbUpdates[`/view/1/selectedOptsList`] = [];

        taskSelectedCategories = this.selectedOptsList;
      }
      DatabaseInterface.update(
        {
          ...dbUpdates,
        },
        this.userInfo.uid
      );

      this.updateRootState({
        searchVal: "",
      });

      this.clearAdditionalTaskFilters({ ignore: ["resourceTypes"] });

      if (clearSelection) {
        if (this.isProjectsResourceModeEnabled) {
          await closeTaskCateTreeview();
          // taskSelectedCategories.map((opt) => opt.id)
        }
        await getUserResources().set(
          { mode: currType, selection: [] },
          "selectedCategories"
        );

        EventEmitter.emit(REFRESH_RESOURCE_ID_FILTER);
      }
      if (catTree) {
        catTree.collapseAll();
        // inactiveTree?.collapseAll();
      }
      // }
      // } else {

      setTimeout(() => {
        if (focusOnTree) {
          const selectedNodes = catTree.selectedNodes;

          if (!isEmpty(selectedNodes[0])) {
            const selectedResource = this.rawResourcesMap[selectedNodes[0]];
            expandAllParentsOfResource(selectedResource);
            // catTree.ensureVisible(selectedResource);
          }
        }
      }, 500);
    },
    async handleResourcesViewReturnInfo(returnViewData) {
      let dbUpdates = {};
      let viewConfig = {};
      let routeName = "";

      if (returnViewData.to !== undefined) {
        // this.loaderToggler(true);
        const { to, ...restConfig } = returnViewData;
        routeName = to;
        viewConfig = { ...restConfig };
        if (to === "Dashboard") {
          dbUpdates = {
            notesView: false,
            activeNavView: "dashboard",
          };
        } else if (to === "Notes") {
          dbUpdates = {
            notesView: true,
            activeNavView: "notes",
          };
        }
        // }

        if (!isEmpty(dbUpdates)) {
          DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        }

        this.$router.replace({
          name: routeName,
          params: {
            viewConfig: {
              ...viewConfig,
            },
          },
        });

        this.storeCurrViewData({});
        this.updateRootState({
          itemInspectEnabled: false,
        });
      } else {
        this.handleResourceViewData(returnViewData);
        //
      }
    },
    async handleResourceViewData(viewData) {
      if (!isEmpty(viewData)) {
        this.loaderToggler(true);
        let openForm = false,
          resourceIdToUse,
          resourceToOpen = {},
          selectTaskInList = false,
          taskDataToUse = {};
        let taskStateUpdates = {};
        let resourceStateUpdates = {};

        const { modeData, taskData } = viewData;
        let stopLoader = true;
        let closeForm = false;
        let dbFieldsUpdates = [];

        let currSelectedCategories = getUserResources().getSelectedCategories();

        if (isEmpty(currSelectedCategories)) {
          currSelectedCategories = {};
        }

        if (!isEmpty(modeData?.additionalTaskFilters)) {
          taskStateUpdates.additionalTaskFilters =
            modeData?.additionalTaskFilters;
        }

        if (!isEmpty(modeData?.resourceFiltersList)) {
          resourceStateUpdates.filterResources = modeData?.resourceFiltersList;
        }

        this.updateTasksState({
          ...taskStateUpdates,
        });
        this.setResourceStateData({
          ...resourceStateUpdates,
        });
        if (!isEmpty(modeData) && !isEmpty(taskData)) {
          const { type, resourceIdToOpen: resourceId } = modeData;

          // modeData.selectedCategories = this.selectedResrcCategories || [];

          // modeData.resourceType = this.selectedResourceTypeOpt;
          if (
            type === "form" &&
            resourceId &&
            this.rawResourcesMap[resourceId]
          ) {
            openForm = true;
            resourceIdToUse = resourceId;

            if (!isEmpty(taskData)) {
              taskDataToUse = { ...taskData };
            }
          } else if (type === "grid") {
            selectTaskInList = true;
            if (!isEmpty(taskData)) {
              taskDataToUse = { ...taskData };
            }
          }
        }

        if (openForm && resourceIdToUse) {
          stopLoader = false;
          const resourceData = this.processedResources.find(
            (r) => r.key === resourceIdToUse
          );
          if (!isEmpty(resourceData)) {
            resourceToOpen = { ...resourceData };
          }

          this.openResourceInForm({
            resourceData: { ...resourceToOpen },
            linkedItemData: { ...taskDataToUse },
          });
        } else if (selectTaskInList) {
          stopLoader = false;

          closeForm = true;

          const { selectedCategories, resourceType } = modeData;
          // selectedCategories,
          // resourceType,
          if (!isEmpty(resourceType)) {
            if (resourceType !== "project") {
              dbFieldsUpdates.push({
                key: "selectedResourceType",
                value: resourceType,
              });
            }

            if (selectedCategories !== undefined) {
              dbFieldsUpdates.push({
                key: "selectedCategories",
                value: {
                  ...currSelectedCategories,
                  [resourceType]: selectedCategories,
                },
              });
            }
          }

          if (!isEmpty(dbFieldsUpdates)) {
            await getUserResources().setMultiFields([...dbFieldsUpdates]);
          }

          if (!isEmpty(taskDataToUse)) {
            const { key, date } = taskDataToUse;
            let taskIdToUse = key;

            if (!isEmpty(this.rawTasksMap[key])) {
              if (checkIfTaskIsParentRecurringTask(this.rawTasksMap[key])) {
                taskIdToUse = getFirstVirtualReccuringTask(
                  this.processedTasks,
                  key,
                  date
                )?.key;
              }

              if (taskIdToUse) {
                EventEmitter.emit(
                  SELECT_TASK_IN_ACTIVITY_GRID,
                  taskIdToUse,
                  true,
                  true
                );
              }
            }
          }

          this.loaderToggler(false);
        }

        if (closeForm) {
          this.closeResourceInfoViewer();
        }
        if (stopLoader) {
          this.loaderToggler(false);
        }

        this.storeCurrViewData({});
        this.updateRootState({
          itemInspectEnabled: false,
        });
      }
    },

    async openResourceInForm({
      resourceData,
      linkedItemData,
      returnInfoToPass = {},
    }) {
      EventEmitter.emit(TOGGLE_RESOURCE_FORM_EVENT, false);

      const resourceDataToOpen = {
        ...resourceData,
        linkedItemData: !isEmpty(linkedItemData)
          ? Object.freeze(linkedItemData)
          : {},
        openDescr: true,
      };

      await Vue.nextTick();
      const resourceInfoFormData = {
        data: resourceDataToOpen,
      };

      if (!isEmpty(linkedItemData)) {
        resourceInfoFormData.selectedTaskData = Object.freeze(linkedItemData);
      }
      if (!isEmpty(returnInfoToPass)) {
        resourceInfoFormData.returnViewInfo = { ...returnInfoToPass };
      }

      await setResourceIdInTree(resourceData);
      expandAllParentsOfResource(resourceData);

      this.setAdditionalTaskFilters({
        key: "resourceTypes",
        list: [resourceData.type],
      });

      if (this.isResourceInfoFormOpen) {
        this.setResourceData({
          ...resourceData,
        });
        this.setLinkedTaskData(linkedItemData);
        await Vue.nextTick();
        EventEmitter.emit(REFRESH_RESOURCE_FORM_DATA);
      }
      // if (!this.isResourceInfoFormOpen) {
      //   this.openResourceInfoViewer(resourceInfoFormData);
      // } else if (!isEmpty(this.editResourceData)) {

      // }
      // setTimeout(() => {

      // {
      //   data: resourceDataToOpen,
      //   selectedTaskData: !isEmpty(linkedItemData)
      //     ? Object.freeze(linkedItemData)
      //     : {},
      //   returnViewInfo: { ...returnInfoToPass },
      // }

      // }, 0);
    },
    async clearResourceSelection() {
      const currSelectedResourceType = this.selectedResourceTypeOpt;

      const dataToSet = {
        mode: currSelectedResourceType,
        selection: [],
      };
      await getUserResources().set(dataToSet, "selectedCategories");
      return true;
    },
    handleResourceDataUpdates(data, successCB) {
      // if (!this.loadTasks) {
      //   this.setLoader(false);
      // }

      const isResourcesRoute = this.$route.name === "Resources";
      const openForm =
        (!isResourcesRoute && this.isProjectsResourceModeEnabled) ||
        this.$route.name === "Resources";
      const currResources = this.processedResources;
      const currNewRawResources = data?.resources || {};
      const currNewResourcesList = Object.values(currNewRawResources);
      const currRawResources = this.rawResourcesMap;
      const currResourceCategories = this.resourceCategories;

      const selectedResourceTypeOpt =
        !isResourcesRoute && this.isProjectsResourceModeEnabled
          ? "project"
          : data?.selectedResourceType || "people";

      let selectedCatIds = [];

      const newCategoriesData = data?.categories || {};

      // const currRaw
      const updates = {
        // selectedCategories: data?.selectedCategories || [],
        collapsedGroupsList: data?.collapsedGroups || [],
        internalCollapsedGroups: data?.internalCollapsedGroups || [],
        selectedResourceTypeOpt,
        rawSelectedCategoriesMap: isPlainObject(data?.selectedCategories)
          ? data?.selectedCategories
          : {},
        // selectedResourceTypeOpt: this.isProjectsResourceModeEnabled
        //   ? "project"
        //   : selectedResourceTypeOpt,
      };

      if (!isEqual(currResourceCategories, newCategoriesData)) {
        updates.categories = newCategoriesData;
      }
      if (
        !isEmpty(data?.selectedCategories) &&
        isPlainObject(data?.selectedCategories)
      ) {
        // if (isPlainObject(data?.selectedCategories)) {
        selectedCatIds =
          data?.selectedCategories[selectedResourceTypeOpt] || [];
        // }
      }

      updates.selectedCategories = selectedCatIds;
      if (!isEqual(currNewRawResources, currRawResources)) {
        if (isEmpty(currResources) && !isEmpty(currNewResourcesList)) {
          updates.processedResources = processResources(
            currNewResourcesList,
            data.categories
          );
        }

        updates.rawResourcesMap = currNewRawResources;
      }

      this.updateResourcesState(updates);

      if (
        openForm &&
        !isEmpty(this.selectedResourceCategories) &&
        this.selectedResourceCategories.length === 1 &&
        currNewRawResources[this.selectedResourceCategories[0]] &&
        currNewRawResources[this.selectedResourceCategories[0]].type ===
          this.selectedResourceTypeOpt &&
        !this.isResourceInfoFormOpen
      ) {
        const taskDataToUse = this.$route.params?.viewConfig?.taskData;

        const processedResourceData = processResourceData({
          ...currNewRawResources[this.selectedResourceCategories[0]],
        });

        const formStateData = {
          data: { ...processedResourceData },
        };

        if (!isEmpty(taskDataToUse)) {
          formStateData.selectedTaskData = Object.freeze(taskDataToUse);
        }

        if (this.selectedResourceTypeOpt === processedResourceData.type) {
          this.openResourceInfoViewer({
            ...formStateData,
          });
        }
      }

      if (successCB) {
        successCB();
      }
      // this.loading = false;

      // if (!this.dataLoaded) {
      //   this.dataLoaded = true;
      // }
    },
    ...mapActions(["storeCurrViewData"]),
    ...mapActions("task", ["setAdditionalTaskFilters"]),
    ...mapActions("resourcesData", ["updateResourcesState"]),

    async openResourceItemDataOnNav({
      queryData = {},
      routeParams = {},
      returnViewInfo = {},
    }) {
      const viewData = routeParams?.viewConfig;
      let linkedItemData = routeParams.linkedItemData;
      const storedReturnInfoData = { ...returnViewInfo };

      let openForm = false,
        resourceIdToUse,
        resourceToOpen = {},
        selectTaskInList = false,
        taskDataToUse;
      let taskStateUpdates = {};
      let resourceStateUpdates = {};
      let disableInspectMode = false;
      let res = {};
      if (!isEmpty(queryData)) {
        const resourceId = queryData.resourceId;
        if (resourceId && this.rawResourcesMap[resourceId]) {
          openForm = true;

          resourceIdToUse = resourceId;

          if (!isEmpty(linkedItemData)) {
            taskDataToUse = { ...linkedItemData };
          }
        }
      } else if (!isEmpty(viewData)) {
        const { modeData, taskData, goBackToResourceMode } = viewData;

        if (!isEmpty(modeData?.additionalTaskFilters)) {
          taskStateUpdates.additionalTaskFilters =
            modeData?.additionalTaskFilters;
        }

        if (goBackToResourceMode) {
          disableInspectMode = true;
        }

        if (!isEmpty(modeData?.resourceFiltersList)) {
          resourceStateUpdates.filterResources = modeData?.resourceFiltersList;
        }

        if (!isEmpty(modeData) && !isEmpty(taskData)) {
          const { type, resourceIdToOpen: resourceId } = modeData;

          if (
            type === "form" &&
            resourceId &&
            this.rawResourcesMap[resourceId]
          ) {
            openForm = true;
            resourceIdToUse = resourceId;

            if (!isEmpty(taskData)) {
              taskDataToUse = { ...taskData };
            }
          } else if (type === "grid") {
            selectTaskInList = true;
            if (!isEmpty(taskData)) {
              taskDataToUse = { ...taskData };
            }
          }
        }
      }

      this.updateTasksState({
        ...taskStateUpdates,
      });
      this.setResourceData({
        ...resourceStateUpdates,
      });
      if (!isEmpty(storedReturnInfoData)) {
        this.storeCurrViewData(Object.freeze(storedReturnInfoData));
      }

      if (openForm && resourceIdToUse) {
        const resourceData = this.processedResources.find(
          (r) => r.key === resourceIdToUse
        );
        if (!isEmpty(resourceData)) {
          resourceToOpen = { ...resourceData };
        }

        res = {
          taskDataProcessed: true,
        };
        this.openResourceInForm({
          resourceData: { ...resourceToOpen },
          linkedItemData: { ...taskDataToUse },
          returnInfoToPass: storedReturnInfoData,
        });
      } else if (selectTaskInList && !isEmpty(taskDataToUse)) {
        const { key, date } = taskDataToUse;
        let taskIdToUse = key;

        if (!isEmpty(this.rawTasksMap[key])) {
          if (checkIfTaskIsParentRecurringTask(this.rawTasksMap[key])) {
            taskIdToUse = getFirstVirtualReccuringTask(
              this.processedTasks,
              key,
              date
            )?.key;
          }

          if (taskIdToUse) {
            EventEmitter.emit(
              SELECT_TASK_IN_ACTIVITY_GRID,
              taskIdToUse,
              true,
              true
            );
          }
        } else {
          res = {
            taskDataProcessed: false,
            taskDataToProcess: { ...taskDataToUse },
          };
        }
      }
      if (disableInspectMode) {
        this.updateRootState({
          itemInspectEnabled: false,
          currViewData: {},
        });
      }
      if (openForm || selectTaskInList) {
        this.$router.push({
          query: {},
          params: {},
        });
      }

      return res;
    },

    getResourceTypeToIgnoreVal() {
      let finalResourceTypeToIgnore = "";

      if (
        !isEmpty(this.selectedResourceCategories) &&
        this.selectedResourceCategories.length === 1 &&
        checkIfResourceNodeHasNoProject(this.selectedResourceCategories[0])
      ) {
        finalResourceTypeToIgnore = "project";
      }
      return finalResourceTypeToIgnore;
    },
  },
  computed: {
    ...mapGetters("resourceInfo", {
      isResourceInfoFormOpen: "isOpen",
      editResourceData: "data",
    }),
    ...mapGetters("resourcesData", {
      processedResources: "resources",
      rawResourcesMap: "rawResourcesMap",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      resourceCategories: "categories",
      filterResourcesList: "filterResources",
      selectedResourceCategories: "selectedCategories",
    }),
    ...mapGetters("task", {
      rawTasksMap: "rawTasksMap",
      processedTasks: "tasks",
      additionalTaskFilters: "additionalTaskFilters",
      isProjectsResourceModeEnabled: "isProjectsResourceModeEnabled",
    }),
  },
};

export default mixin;
