import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex resource-type-selector-wrapper"},_vm._l((_vm.resourceTypeOpts),function(btnData,index){return _c(VTooltip,{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c(VBtn,_vm._g(_vm._b({key:index,staticClass:"resource-type-change-btn",class:( _obj = {
          selected: _vm.selectedFilterOptIndex === index
        }, _obj[((btnData.key) + "-nav-btn")] = true, _obj ),attrs:{"depressed":"","data-action-type":"resource-type-change","data-action-value":btnData.key},on:{"click":function($event){return _vm.handleClick($event, btnData)}}},'v-btn',attrs,false),on),[(btnData.icon)?_c(VIcon,{staticClass:"resource-type-icon",attrs:{"left":"","color":_vm.getBtnIconColor(btnData, index),"size":"16"}},[_vm._v(" "+_vm._s(btnData.icon)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(btnData.title))])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }