<template>
  <div id="treeview-side-bar">
    <v-row
      v-show="isInNextActionsModeOrGlobalMode"
      align="center"
      no-gutters
      justify="center"
      id="tasks-filter-selector-wrapper"
    >
      <v-col cols="auto">
        <TasksFilterBtns />
      </v-col>
    </v-row>

    <div
      class="pl-3 pb-5"
      id="treeview-wrapper"
      @drop="onDrop($event)"
      @dragover.prevent
      @click="handleClick"
      @mousedown="handleMouseDown"
      @dblclick="handleNodeDbClick"
    >
      <div v-show="!showAllData">
        <TreeView
          :refSetter="(refEl) => storeTreeRef(refEl, 'treeview')"
          id="treeview"
          :fields="fields"
          cssClass="CTITreeView"
          dragArea="#treeview-wrapper"
          :nodeTemplate="Template"
          :allowDragAndDrop="true"
          :allowEditing="allowEditing"
          :enablePersistence="true"
          :expandOn="'None'"
          :allowMultiSelection="false"
          :selectedNodes="selectedNodes"
          :loadOnDemand="false"
          @nodeExpanded="nodeOpened"
          @nodeCollapsed="nodeClosed"
          @nodeDragStart="nodeDragStart"
          @nodeDragStop="dragStop"
          @nodeDragging="nodeDrag"
          @nodeClicked="nodeclicked"
          @nodeEdited="nodeEdited"
          @dataSourceChanged="treeEdited"
          @keyPress="handleKeyDown"
        />
      </div>
      <div v-show="showAllData">
        <TreeView
          id="treeview-all-data"
          :fields="allDataFields"
          cssClass="CTITreeView"
          dragArea="#treeview-wrapper"
          :nodeTemplate="Template"
          :allowDragAndDrop="true"
          :allowEditing="allowEditing"
          :enablePersistence="true"
          :expandOn="'None'"
          :allowMultiSelection="true"
          :selectedNodes="selectedNodes"
          :loadOnDemand="false"
          :refSetter="(refEl) => storeTreeRef(refEl, 'allDataTreeview')"
          @nodeExpanded="nodeOpened"
          @nodeCollapsed="nodeClosed"
          @nodeDragStart="nodeDragStart"
          @nodeDragStop="dragStop"
          @nodeDragging="nodeDrag"
          @nodeClicked="nodeclicked"
          @nodeEdited="nodeEdited"
          @dataSourceChanged="treeEdited"
          @keyPress="handleKeyDown"
        />
      </div>
    </div>

    <ContextMenu
      target="#treeview-wrapper"
      :items="menuItems"
      menuId="treeview-menu"
      :mainTarget="menuTarget"
      @menu-item:selected="handleMenuItemSelection"
      @beforeMenuOpen="handleBeforeMenuOpen"
    />
  </div>
</template>
<script>
import Vue from "vue";
import TreeView from "@/components/TreeView";
import TreeNode from "./components/TaskCateNode.vue";
import TasksFilterBtns from "./components/TasksFilterBtns.vue";
import DatabaseInterface from "@/services/DatabaseInterface";
import { mapActions, mapGetters, mapMutations } from "vuex";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import projectsHelpersMixin from "@/mixins/projectsHelpersMixin";
import areaPrioritySetterHelpersMixin from "@/mixins/areaPrioritySetterHelpersMixin";
import isEqual from "lodash/isEqual";
import { getTreeFields, onDragItem, onDragStop } from "./helpers/treeUtils";
import ContextMenu from "@/core/components/ContextMenu";
import {
  PROJECTS_MENU_ACTIONS,
  PROJECT_ACTIONS_MENU_TYPES,
} from "./contextMenuOpts";
import {
  createViewDataForDb,
  getNewID,
  getTaskTreeView,
} from "@/helpers/tasks";
import {
  checkIfProjectHasNoPriorityByKey,
  findProjectOrCategory,
  storeProjectAddAction,
  storeProjectRemoveAction,
} from "@/helpers/projects";
import isEmpty from "lodash/isEmpty";
import {
  createUpdateAndEditedForCatOrProj,
  storeCatAddAction,
  storeCatEditAction,
  storeCatRemoveAction,
  storeCatReorderOrMoveAction,
} from "@/helpers/categories";
import cloneDeep from "lodash/cloneDeep";
import EventEmitter from "@/helpers/eventEmitter";
import { catTypes, typesOfProjects } from "@/variables/categories";
import sleep from "@/utils/sleep";
import hasParentEl, {
  findParentFromPathsByClassName,
} from "@/utils/hasParentEl";
import {
  ADD_NEW_TASK_IN_ACTIVITY_GRID,
  ADD_TASK_EVENT,
  CONTEXT_MENU_OPENED,
  RECOUNT_TASKS_AFTER_MOVE_EVENT,
  REFRESH_FORM_DATA,
  REFRESH_TASKS_EVENT,
  REFRESH_TREE_EVENT,
  SET_SELECTED_NODE_EVENT,
  STORE_REMOVED_NODE_ID,
  TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT,
  TREE_NODE_MOVED_EVENT,
  TREE_NODE_RENAME_EVENT,
} from "@/variables/events";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { PRIMARY_COLOR } from "@/variables/colors";
import { convertValueToArray } from "@/helpers/common";
import treeViewHelpersMixin from "@/mixins/treeViewHelpersMixin";
import { NODE_BROWSE_KEYS, NODE_MOVE_KEYS } from "@/variables/keys";
import { treeSideBarModes } from "@/variables/viewConfigs";
import { getPathsFromEvent } from "@/utils/events";
import { getCurrDate } from "@/helpers/dates";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import {
  moveResourceCateByTaskCateData,
  removeTaskCateFromResourceCateData,
  storeTaskCateInResourceCateData,
  updateTaskCateInResourceCateData,
} from "@/helpers/resourceCategoriesHelpers";

const nodeTemplate = Vue.component("tree-node", TreeNode);

export default {
  mixins: [
    userDetailsMixin,
    tasksHelpersMixin,
    projectsHelpersMixin,
    treeViewHelpersMixin,
    categoriesHelpersMixin,
    areaPrioritySetterHelpersMixin,
  ],
  props: {
    // uid: String,
    changeView: Function,
  },
  components: {
    ContextMenu,
    TreeView,
    TasksFilterBtns,
  },
  data() {
    return {
      showAllData: false,
      selectedNodes: [],
      selectedCatIds: [],
      expandedCategories: [],
      Template: function () {
        return {
          template: nodeTemplate,
        };
      },
      menuItems: [...PROJECTS_MENU_ACTIONS],
      fields: {
        id: "key",
        text: "title",
        child: "projects",
        htmlAttributes: "hasAttribute",
        expanded: "open",
        selected: "isSelected",
        dataSource: [],
      },
      allDataFields: {
        id: "key",
        text: "title",
        child: "projects",
        htmlAttributes: "hasAttribute",
        expanded: "open",
        selected: "isSelected",
        dataSource: [],
      },
      allowEditing: false,
    };
  },
  mounted() {
    this.storeNodeActivity = true;
    this.parentWrapperEl = this.$el.querySelector("#treeview-wrapper");
    this.listWrapperEl = this.$el.querySelector("#treeview");
    // if (this.tasks && this.tasks.length) {
    //   this.createTasksMap(this.tasks);
    // }

    this.setNodes();
    // if (
    //   !this.isInspectModeEnabled &&
    //   this.selectedOptsList &&
    //   this.selectedOptsList.length
    // ) {
    //   // set nodes if user has any pre selection
    //   let selectedIDs = [];
    //   // let selectedCategories = [];
    //   this.selectedOptsList.forEach((opt) => {
    //     selectedIDs.push(opt.id);
    //     // selectedCatIds.push(task.project);
    //     // selectedCategories = selectedCategories.concat(
    //     //   this.getAllParentIDs(opt.id)
    //     // );
    //     // if (this.projects[opt.id]?.category) {
    //     //   selectedCategories.push(this.projects[opt.id].category);
    //     // }
    //     // tmp = tmp.concat(this.getAllParentIDs(task.project));
    //   });
    //   // const selectedIds = this.selectedOptsList.map(
    //   //   (selectOpt) => selectOpt.id
    //   // );

    //   if (this.listWrapperEl) {
    //     console.debug("SSS", this.listWrapperEl.ej2_instances[0]);
    //     const instance = this.listWrapperEl.ej2_instances[0];
    //     instance.selectedNodes = selectedIDs;

    //     console.debug("INS", instance.selectedNodes);
    //   }
    //   // console.debug("DDDD", selectedIDs);
    //   // this.selectedNodes = selectedIDs;
    //   this.setSelectedCatIds(selectedIDs);
    //   // this.selectedCatIds = selectedCategories;
    // }

    this.setNodesForInspectMode();

    EventEmitter.on(TREE_NODE_RENAME_EVENT, this.enableNodeRename);
    EventEmitter.on(TREE_NODE_MOVED_EVENT, this.refreshAfterNodeReordered);
    EventEmitter.on(REFRESH_TREE_EVENT, this.refreshTreeAfterEdit);
    EventEmitter.on(SET_SELECTED_NODE_EVENT, this.setNodeAndGetData);
    EventEmitter.on(
      RECOUNT_TASKS_AFTER_MOVE_EVENT,
      this.handleRecountAfterMove
    );
    EventEmitter.on(
      TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT,
      this.toggleStoreNodeOpenClose
    );
    EventEmitter.on(CONTEXT_MENU_OPENED, this.setContextMenuNodeId);
    EventEmitter.on(STORE_REMOVED_NODE_ID, this.storeRemovedNodesIds);

    // if (
    //   !isEmpty(this.tasks) ||
    //   (!isEmpty(this.categories) && isEmpty(this.tasks))
    // ) {
    this.createTreeData(this.categories, this.projects);
    // }
  },
  destroyed() {
    EventEmitter.off(TREE_NODE_RENAME_EVENT, this.enableNodeRename);
    EventEmitter.off(TREE_NODE_MOVED_EVENT, this.refreshAfterNodeReordered);
    EventEmitter.off(REFRESH_TREE_EVENT, this.refreshTreeAfterEdit);
    EventEmitter.off(SET_SELECTED_NODE_EVENT, this.setNodeAndGetData);
    EventEmitter.off(
      TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT,
      this.toggleStoreNodeOpenClose
    );
    EventEmitter.off(
      RECOUNT_TASKS_AFTER_MOVE_EVENT,
      this.handleRecountAfterMove
    );
    EventEmitter.off(CONTEXT_MENU_OPENED, this.setContextMenuNodeId);
    EventEmitter.off(STORE_REMOVED_NODE_ID, this.storeRemovedNodesIds);
  },
  methods: {
    ...mapActions("task", [
      "updateSelectedTasks",
      "setProjectsTree",
      "setSelectedCatIds",
      "clearListFilters",
    ]),
    ...mapActions("editForm", ["showNewEditTaskDialog", "closeEditTaskDialog"]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions(["updateRootState"]),

    handleNodeDbClick(e) {
      const paths = getPathsFromEvent(e);

      const { found: nodeItemElFound, parentEl: nodeItemEl } =
        findParentFromPathsByClassName(paths, ["e-list-item"], "obj");
      if (nodeItemElFound) {
        if (this.selectNodeFuncId !== undefined) {
          clearTimeout(this.selectNodeFuncId);

          this.selectNodeFuncId = undefined;
        }
      }

      if (hasParentEl(e.target, { className: ["edit-item-btn", "e-icons"] })) {
        return;
      }

      if (nodeItemEl) {
        const targetId = nodeItemEl.getAttribute("data-uid");

        if (targetId !== "root") {
          this.renameCate(targetId, true);
        }
      }
    },
    storeTreeRef(refEl, name) {
      this.$refs[name] = refEl;
    },
    handleBeforeMenuOpen(args) {
      const isOpenedFromRoot = args.openedFromRoot;
      const menuEl = document.querySelector("#treeview-menu").ej2_instances[0];
      const selectedNode = this.selectedNodeIdToUse;

      menuEl.hideItems(["Hide Item", "Show Item"]);
      if (!isOpenedFromRoot && selectedNode) {
        menuEl.showItems(["Mark as Complete", "Mark as Incomplete"]);
        let hideOpt = false;

        if (this.projects[selectedNode]) {
          if (!this.projects[selectedNode].hidden) {
            hideOpt = true;
          }
        } else if (this.categories[selectedNode]) {
          if (!this.categories[selectedNode].hidden) {
            hideOpt = true;
          }
        }

        if (hideOpt) {
          menuEl.showItems(["Mark as Complete"]);
          menuEl.hideItems(["Mark as Incomplete"]);
        } else {
          menuEl.hideItems(["Mark as Complete"]);
          menuEl.showItems(["Mark as Incomplete"]);
        }
      } else if (isOpenedFromRoot) {
        menuEl.hideItems(["Mark as Complete", "Mark as Incomplete"]);
      }
    },
    storeRemovedNodesIds(ids) {
      this.nodeIdsToRemove = ids;
    },
    setContextMenuNodeId(nodeId) {
      this.selectedNodeIdToUse = nodeId;
      // this.$nextTick(() => {
      //   this.handleNodesData(
      //     [nodeId],
      //     1
      //     // 1
      //   );
      // });
    },
    async handleMenuItemSelection(args) {
      await this.$nextTick();
      const selectedMenuItem = args.item;
      const nodeToUse = this.selectedNodeIdToUse;
      switch (selectedMenuItem.id) {
        case PROJECT_ACTIONS_MENU_TYPES.ADD_CATEGORY:
          this.addCategory();
          break;
        case PROJECT_ACTIONS_MENU_TYPES.ADD_DOC:
          this.addDoc(nodeToUse);
          break;
        case PROJECT_ACTIONS_MENU_TYPES.ADD_DIR:
          this.addDirectory(nodeToUse);
          break;
        case PROJECT_ACTIONS_MENU_TYPES.ADD_PROJECT_TASK:
          this.addProjectTask(nodeToUse);
          break;
        case PROJECT_ACTIONS_MENU_TYPES.REMOVE_PROJECT_CATE:
          this.deleteCat(nodeToUse);
          // this.
          break;
        case PROJECT_ACTIONS_MENU_TYPES.RENAME_PROJECT_CATE:
          this.renameCate(nodeToUse);
          break;
        case PROJECT_ACTIONS_MENU_TYPES.HIDE_PROJECT_CATE:
          this.hideProjCate(nodeToUse);
          break;
        case PROJECT_ACTIONS_MENU_TYPES.SHOW_PROJECT_CATE:
          this.showProj(nodeToUse);
          break;
        case PROJECT_ACTIONS_MENU_TYPES.DELETE_CATE:
          this.markCateAsDelete(nodeToUse);
          break;
      }

      this.selectedNodeIdToUse = undefined;
    },
    async markCateAsDelete(targetNodeId) {
      if (!targetNodeId) return;

      const targetNodeData = findProjectOrCategory(
        this.projectsTree,
        (node) => node.key === targetNodeId
      );

      if (!isEmpty(targetNodeData)) {
        let hideNode = true;
        let hideNodeWarnMsg;
        const targetNodeType = targetNodeData.type.split(" ")[0];
        const targetProjects = targetNodeData.projects;
        if (targetNodeType === "project") {
          const projectTasks = this.tasks.filter(
            (t) => t.project === targetNodeData.key && !t.completed
          );

          if (!isEmpty(projectTasks)) {
            hideNode = false;
            hideNodeWarnMsg =
              "Cannot hide the item because it contains incomplete tasks.<br/>Please delete or complete them first";
          }
        } else if (
          targetNodeType === "subcategory" ||
          targetNodeType === "category"
        ) {
          if (!isEmpty(targetProjects)) {
            const hasIncompleteProjects = targetProjects.some(
              (p) => !p.hidden || !p.deleted
            );

            if (hasIncompleteProjects) {
              hideNode = false;
              hideNodeWarnMsg =
                "Cannot hide the item because it contains items.<br/>Please delete or complete them first";
            }
          }
        }

        if (!hideNode) {
          this.$swal({
            titleText: "Oops!",
            html: hideNodeWarnMsg,
            icon: "error",
            confirmButtonColor: PRIMARY_COLOR,
            iconColor: PRIMARY_COLOR,
          });
          return;
        }

        let originalNodeData = {};
        let dbUpdate = {};
        if (this.projects[targetNodeId]) {
          originalNodeData = this.projects[targetNodeId];

          dbUpdate[`/projects/${targetNodeId}/deleted`] = true;
        } else if (this.categories[targetNodeId]) {
          originalNodeData = this.categories[targetNodeId];
          dbUpdate[`/categories/${targetNodeId}/deleted`] = true;
        }

        const { editedData } = createUpdateAndEditedForCatOrProj(
          {
            deleted: true,
          },
          originalNodeData
        );

        updateTaskCateInResourceCateData({
          refKey: targetNodeId,
          updatedData: { deleted: true },
        });

        await DatabaseInterface.update(dbUpdate, this.userInfo.uid);
        storeCatEditAction({
          editedData,
          key: originalNodeData.key,
          type: originalNodeData.type.split(" ")[0],
        });

        await this.$nextTick();
        this.refreshTreeAfterEdit(true, undefined, [true]);
      }
    },
    async hideProjCate(targetNodeId) {
      if (!targetNodeId) return;

      const targetNodeData = findProjectOrCategory(
        this.projectsTree,
        (node) => node.key === targetNodeId
      );

      if (!isEmpty(targetNodeData)) {
        let hideNode = true;
        let hideNodeWarnMsg;
        const targetNodeType = targetNodeData.type.split(" ")[0];
        const targetProjects = targetNodeData.projects;
        if (targetNodeType === "project") {
          const projectTasks = this.tasks.filter(
            (t) => t.project === targetNodeData.key && !t.completed
          );

          if (!isEmpty(projectTasks)) {
            hideNode = false;
            hideNodeWarnMsg =
              "Cannot mark it as complete because it contains incomplete tasks.<br/>Please delete or complete them first";
          }
        } else if (
          targetNodeType === "subcategory" ||
          targetNodeType === "category"
        ) {
          if (!isEmpty(targetProjects)) {
            const hasIncompleteProjects = targetProjects.some((p) => !p.hidden);

            if (hasIncompleteProjects) {
              hideNode = false;
              hideNodeWarnMsg =
                "Cannot mark it as complete because it contains items.<br/>Please delete or complete them first";
            }
          }
        }

        if (!hideNode) {
          this.$swal({
            titleText: "Oops!",
            html: hideNodeWarnMsg,
            icon: "error",
            confirmButtonColor: PRIMARY_COLOR,
            iconColor: PRIMARY_COLOR,
          });
          return;
        }

        let originalNodeData = {};
        let dbUpdate = {};
        if (this.projects[targetNodeId]) {
          originalNodeData = this.projects[targetNodeId];

          dbUpdate[`/projects/${targetNodeId}/hidden`] = true;
        } else if (this.categories[targetNodeId]) {
          originalNodeData = this.categories[targetNodeId];
          dbUpdate[`/categories/${targetNodeId}/hidden`] = true;
        }

        const { editedData } = createUpdateAndEditedForCatOrProj(
          {
            hidden: true,
          },
          originalNodeData
        );

        await DatabaseInterface.update(dbUpdate, this.userInfo.uid);
        storeCatEditAction({
          editedData,
          key: originalNodeData.key,
          type: originalNodeData.type.split(" ")[0],
        });

        await this.$nextTick();
        this.refreshTreeAfterEdit(true);
      }
    },
    async showProj(targetNodeId) {
      if (!targetNodeId) return;

      const targetNodeData = findProjectOrCategory(
        this.projectsTree,
        (node) => node.key === targetNodeId
      );

      if (!isEmpty(targetNodeData)) {
        let originalNodeData = {};
        let dbUpdate = {};
        if (this.projects[targetNodeId]) {
          originalNodeData = this.projects[targetNodeId];

          dbUpdate[`/projects/${targetNodeId}/hidden`] = false;
        } else if (this.categories[targetNodeId]) {
          originalNodeData = this.categories[targetNodeId];
          dbUpdate[`/categories/${targetNodeId}/hidden`] = false;
        }

        const { editedData } = createUpdateAndEditedForCatOrProj(
          {
            hidden: false,
          },
          originalNodeData
        );

        await DatabaseInterface.update(dbUpdate, this.userInfo.uid);
        storeCatEditAction({
          editedData,
          key: originalNodeData.key,
          type: originalNodeData.type.split(" ")[0],
        });

        await this.$nextTick();
        this.refreshTreeAfterEdit(true);
      }
    },

    async addCategory() {
      const currTree = this.projectsTree;
      let nodeId = getNewID();
      let item = {
        key: nodeId,
        title: "New Category",
        hasAttribute: { class: "category" },
        type: "category",
        order: currTree.length,
        open: false,
        // completed: false,
        created: getCurrDate(),
        description: "New Category Description",
        icon: "mdi-circle",
        modified: getCurrDate(),
        children: [],
        projects: [],
      };
      storeCatAddAction(item);
      storeTaskCateInResourceCateData(item);
      const itemWithAttributes = this.addAttributesInCate(
        item,
        "projects-tree"
      );

      this.setSelectedNodesInTree([item.key]);
      this.addCategoryInTree(
        { ...itemWithAttributes, isSelected: true },
        undefined,
        undefined,
        undefined,
        true
      );

      this.setCateInSelectedOpts(
        {
          id: item.key,
          projectId: "all",
          subCategoryId: "all",
          type: "category",
        },
        "projects"
      );
      // this.setSelectedCate([nodeId]);

      // treevalidate.moveNodes(["5641"], "categoryIdOne", 2);
      // EventEmitter.emit("update-tree", updatedTreeData);
      // treevalidate.fields.dataSource = updatedTreeData.dataSource;
      // addCategories(item, this.userInfo.uid);

      // treevalidate.addNodes([item], null, null);
      // addCategories(item, this.userInfo.uid);

      // treevalidate.fields.dataSource.push(item);
      // treevalidate.beginEdit(nodeId);
    },
    async addDoc(targetNodeId) {
      if (!targetNodeId) return;
      let nodeId = getNewID();
      let item = {
        key: nodeId,
        title: "New Area",
        hasAttribute: { class: "project" },
        type: "project",
        open: false,
        completed: false,
        created: getCurrDate(),
        description: "New Area Description",
        icon: "mdi-circle",
        modified: getCurrDate(),
        priority: "",
        tasks: [],
      };

      const parent = findProjectOrCategory(
        this.projectsTree,
        (node) => node.key === targetNodeId
      );

      const parentType = parent.type.split(" ")[0];
      if (!isEmpty(parent) && parentType !== "project") {
        const projectsOfParent = Object.values(this.projects).filter(
          (proj) =>
            proj.subcategory === parent.key || proj.category === parent.key
        );
        const currIndex = projectsOfParent?.length || 0;

        if (parentType === "category") {
          item.category = parent.key;
        } else if (parentType === "subcategory") {
          item.category = parent.category;
          item.subcategory = parent.key;
        }

        item.order = currIndex;

        storeTaskCateInResourceCateData(item);
        // this.setSelectedCate([item.key]);
        storeProjectAddAction(item);

        const createdCateWithAttributes = this.addAttributesInCate(
          item,
          "projects-tree"
        );

        this.setSelectedNodesInTree([item.key]);
        this.addCategoryInTree(
          { ...createdCateWithAttributes, isSelected: true },
          parent.key,
          undefined,
          undefined,
          true
        );

        this.setCateInSelectedOpts(
          {
            id: item.key,
            subCategoryId:
              item.subcategory && this.projects[item.subcategory]
                ? item.subcategory
                : "all",
            categoryId: item.category,
            type: "project",
          },
          "projects"
        );

        this.handleNodesData(
          [nodeId],
          // this.isInspectModeEnabled ? 1 : this.currNav
          this.currNav
        );
      }
    },
    async addDirectory(targetNodeId) {
      if (!targetNodeId) return;
      let nodeId = getNewID();
      let item = {
        key: nodeId,
        title: "New Sub-Category",
        hasAttribute: { class: "subcategory" },
        type: "subcategory",
        completed: false,
        created: getCurrDate(),
        description: "New Sub-Category",
        icon: "mdi-circle",
        modified: getCurrDate(),
        priority: "",
        open: true,
        projects: [],
        subcategory: false,
      };

      const selectedNodeData = findProjectOrCategory(
        this.projectsTree,
        (node) => node.key === targetNodeId
      );

      if (!isEmpty(selectedNodeData)) {
        const parentType = selectedNodeData.type.split(" ")[0];

        let addInDb = true;
        if (parentType === "category") {
          item.category = selectedNodeData.key;
        } else if (parentType === "subcategory") {
          item.category = selectedNodeData.category;
          item.subcategory = selectedNodeData.key;
        } else {
          addInDb = false;
        }

        if (addInDb) {
          let nodeChildren = [];
          if (selectedNodeData?.projects && selectedNodeData.projects.length) {
            nodeChildren = cloneDeep(selectedNodeData.projects);
          }
          const currIndex = nodeChildren.length;
          item.order = currIndex;

          storeCatAddAction(item);
          storeTaskCateInResourceCateData(item);
          // addSubCategory(item, this.userInfo.uid);
          this.setSelectedNodesInTree([item.key]);

          const createdItemWithAttributes = this.addAttributesInCate(
            item,
            "projects-tree"
          );
          this.addCategoryInTree(
            { ...createdItemWithAttributes, isSelected: true },
            selectedNodeData.key,
            undefined,
            undefined,
            true
          );
          this.setCateInSelectedOpts(
            {
              id: item.key,
              projectId: "all",
              categoryId: item.category,
              type: "sub-category",
            },
            "projects"
          );
        }
      }

      // console.debug("PA", item.key, parent.key);
      // treevalidate.addNodes([item], targetNodeId, null);
      // treevalidate.fields.dataSource.push(item);
      // treevalidate.beginEdit(nodeId);
    },
    deleteCat(targetNodeId) {
      if (!targetNodeId) return;
      let deleteNode = true;
      let closeForm = false;
      let clearSelection = false;
      const selectNodeToDelete = findProjectOrCategory(
        this.projectsTree,
        (node) => node.key === targetNodeId
      );

      if (!isEmpty(selectNodeToDelete)) {
        if (selectNodeToDelete.projects && selectNodeToDelete.projects.length) {
          deleteNode = false;
        }
        if (!deleteNode) {
          // this.$swal(
          //   "Oops!",
          //   "Cannot delete because it contains items.<br/>Please delete them first",
          //   "error"
          // );

          this.$swal({
            titleText: "Oops!",
            html: "Cannot delete because it contains items.<br/>Please delete them first",
            icon: "error",
            confirmButtonColor: PRIMARY_COLOR,
            iconColor: PRIMARY_COLOR,
          });
          return;
        }

        let dataToRemove = {};
        let dataType = "category";
        if (this.categories[selectNodeToDelete.key]) {
          dataToRemove = { ...this.categories[selectNodeToDelete.key] };
        } else if (this.projects[selectNodeToDelete.key]) {
          dataType =
            this.projects[selectNodeToDelete.key].type === "subcategory"
              ? "category"
              : "project";
          dataToRemove = { ...this.projects[selectNodeToDelete.key] };
        }
        this.removeCategoryFromTree(targetNodeId);

        if (dataType === "project") {
          if (
            !isEmpty(this.selectedOptsList) &&
            this.selectedOptsList[0].id === dataToRemove.key
          ) {
            closeForm = true;
          }

          storeProjectRemoveAction(dataToRemove);
        } else if (dataType === "category" || dataType === "subcategory") {
          storeCatRemoveAction(dataToRemove);
        }

        clearSelection = true;

        removeTaskCateFromResourceCateData([dataToRemove.key]);
      }

      if (clearSelection) {
        this.handleNodesData([], this.currNav);
      }

      if (closeForm) {
        this.closeEditTaskDialog();
      }
    },
    renameCate(targetNodeId, doNotSelectNode) {
      if (!targetNodeId) return;

      if (!doNotSelectNode) {
        this.handleNodesData(
          [targetNodeId],
          // this.isInspectModeEnabled ? 1 : this.currNav
          this.currNav
        );
      }
      this.renameEnabledByUser = true;
      this.renameCateItemInTree(targetNodeId, this.menuTarget);
    },
    addNewTask(data) {
      EventEmitter.emit(ADD_TASK_EVENT, data);
      EventEmitter.emit(ADD_NEW_TASK_IN_ACTIVITY_GRID, data);
    },
    addProjectTask(targetNodeId) {
      if (!targetNodeId) return;

      // if project
      if (
        Object.keys(this.projects).includes(targetNodeId) &&
        this.projects[targetNodeId].type === "project"
      ) {
        const projectData = this.projects[targetNodeId];
        this.setCateInSelectedOpts(
          {
            id: projectData.key,
            subCategoryId:
              projectData.subcategory && this.projects[projectData.subcategory]
                ? projectData.subcategory
                : "all",
            categoryId: projectData.category,
            type: "project",
          },
          "projects"
        );
        this.addNewTask({ project: targetNodeId, dueAsDate: new Date() });
      }
    },
    handleMouseDown(e) {
      if (e.button !== 2) return;

      const paths = getPathsFromEvent(e);

      const nodeId = this.getNodeIdFromEventPaths(paths);

      this.selectedNodeIdToUse = nodeId ? nodeId : undefined;

      // console.debug("NODE ID", nodeId);
    },
    getNodeIdFromEventPaths(paths) {
      let nodeId;

      for (const path of paths) {
        nodeId = this.getNodeIdFromNodeEl(path);
        if (nodeId) {
          break;
        }
      }

      return nodeId;
      // getNodeIdFromNodeEl
    },
    async setNodes() {
      if (
        !this.isInspectModeEnabled &&
        this.selectedOptsList &&
        this.selectedOptsList.length
      ) {
        // const openedCategories = this.openedCategories;
        // set nodes if user has any pre selection
        let selectedIDs = [];
        let selectedCategories = [];
        this.selectedOptsList.forEach((opt) => {
          selectedIDs.push(opt.id);
          if (this.categories[opt.id]) {
            selectedCategories.push(opt.id);
          } else if (this.projects[opt.id]) {
            selectedCategories = selectedCategories.concat(
              this.getAllParentIDs(opt.id)
            );
          }
          // selectedCatIds.push(task.project);
          // selectedCategories = selectedCategories.concat(
          //   this.getAllParentIDs(opt.id)
          // );
          // if (this.projects[opt.id]?.category) {
          //   selectedCategories.push(this.projects[opt.id].category);
          // }
          // tmp = tmp.concat(this.getAllParentIDs(task.project));
        });
        // const selectedIds = this.selectedOptsList.map(
        //   (selectOpt) => selectOpt.id
        // );
        await this.$nextTick();
        this.selectedNodes = this.selectedOptsByFilterBar
          ? [selectedIDs[0]]
          : selectedIDs;
        // this.expandedCategories = this.openedCategories;
      }
      //   if (this.listWrapperEl) {
      //     console.debug("SSS", this.listWrapperEl.ej2_instances[0]);
      //     const instance = this.listWrapperEl.ej2_instances[0];
      //     instance.selectedNodes = selectedIDs;

      //     console.debug("INS", instance.selectedNodes);
      //   }
      // }
    },
    handleNodeSelected(nodeArgs) {
      if (this.nodeSelectedByEnter) {
        const { nodeData } = nodeArgs;

        this.handleNodesData(
          [nodeData.id],
          // this.isInspectModeEnabled ? 1 : this.currNav
          this.currNav
        );
      }
    },
    getTreeViewRef() {
      return this.showAllData
        ? this.$refs.allDataTreeview
        : this.$refs.treeview;
    },
    async handleKeyDown(args) {
      args.event.stopPropagation();
      await this.$nextTick();
      const { event } = args;
      const keyCode = event.which ? event.which : event.keyCode;
      if (
        NODE_BROWSE_KEYS.includes(keyCode) ||
        NODE_MOVE_KEYS.includes(keyCode)
      ) {
        this.storeNodeActivity = true;
      }

      if (NODE_MOVE_KEYS.includes(keyCode)) {
        const treeView = this.getTreeViewRef();
        const focusedNode = this.$el.querySelector(
          ".e-list-item.e-node-focus.e-hover"
        );

        if (focusedNode) {
          let expandNode = false;
          if (focusedNode.classList.contains("e-has-child")) {
            expandNode = true;
          }
          const nodeId = this.getNodeIdFromNodeEl(focusedNode);

          if (nodeId) {
            await this.handleNodesData(
              [nodeId],
              // this.isInspectModeEnabled ? 1 : this.currNav
              // 1
              this.currNav,
              false
            );
            treeView.ensureVisible(nodeId);
            await this.$nextTick();
            if (expandNode) {
              treeView.expandAll([nodeId]);
            }
          }
        }
      }
    },
    handleClick(e) {
      const targetEl = e.target;
      if (targetEl && targetEl.matches(".e-icons.interaction")) {
        this.storeNodeActivity = true;
      }
    },
    nodeDragStart() {
      if (!this.dragStarted) {
        this.dragStarted = true;
      }
    },
    toggleStoreNodeOpenClose(val) {
      this.storeNodeActivity = val;
    },
    async setNodesForInspectMode() {
      if (this.isInspectModeEnabled) {
        let tmp = [];
        let selectedCatIds = [];
        // const treeView = this.$refs.treeview;
        // const treeViewEl = this.$el.querySelector("#treeview").ej2_instances[0];
        // const currExpandedNodes = [...treeViewEl.expandedNodes];

        if (!isEmpty(this.selectedTasks)) {
          for (const task of this.selectedTasks) {
            tmp.push(task.project);
            // selectedCatIds.push(task.project);
            selectedCatIds = selectedCatIds.concat(
              this.getAllParentIDs(task.project)
            );
            // tmp = tmp.concat(this.getAllParentIDs(task.project));
            selectedCatIds.push(this.projects[task.project].category);
            // tmp.push(this.projects[task.project].category);
          }
        } else if (!isEmpty(this.selectedOptsList)) {
          this.selectedOptsList.forEach((opt) => {
            tmp.push(opt.id);
            if (this.categories[opt.id]) {
              selectedCatIds.push(opt.id);
            } else if (this.projects[opt.id]) {
              selectedCatIds = selectedCatIds.concat(
                this.getAllParentIDs(opt.id)
              );
            }
            // selectedCatIds.push(task.project);
            // selectedCategories = selectedCategories.concat(
            //   this.getAllParentIDs(opt.id)
            // );
            // if (this.projects[opt.id]?.category) {
            //   selectedCategories.push(this.projects[opt.id].category);
            // }
            // tmp = tmp.concat(this.getAllParentIDs(task.project));
          });
        }

        if (!isEmpty(tmp)) {
          this.selectedNodes = tmp;
          this.selectedCatIds = selectedCatIds;
        }

        // if (this.isUserFromDifferentTreeMode) {
        //   const clonedSelectedCatIds = cloneDeep(selectedCatIds);
        //   clonedSelectedCatIds.reverse();
        //   treeViewEl.collapseAll();
        //   await sleep(300);
        //   treeViewEl.expandAll(clonedSelectedCatIds);
        //   treeViewEl.expandedNodes = clonedSelectedCatIds;
        //   this.currExpandedNodes = currExpandedNodes;
        // }
      }
    },
    async setNodeAndGetData(
      selectedIds,
      select = true,
      saveInDb,
      fromFilterBar,
      makeItVisible,
      doNotOpenForm,
      doNotClearViewData
    ) {
      selectedIds = convertValueToArray(selectedIds);

      if (select) {
        if (fromFilterBar) {
          this.selectOnlyFirst = true;
        }
        this.setSelectedNodesInTree(
          fromFilterBar ? [selectedIds[0]] : selectedIds
        );
      }

      if (saveInDb) {
        // this.isInspectModeEnabled ? 1 : this.currNav,
        await this.handleNodesData(
          selectedIds,
          this.currNav,
          fromFilterBar,
          undefined,
          doNotOpenForm,
          undefined,
          doNotClearViewData
        );
      }

      await this.$nextTick();

      if (makeItVisible) {
        setTimeout(() => {
          const treeView = this.getTreeViewRef();
          treeView.ensureVisible(selectedIds[0]);
        }, 0);
      }
    },
    updateView: async function (
      data,
      viewToSend,
      fromFilterBar,
      turnOffInspectMode = true,
      doNotClearViewData
    ) {
      let updates = {};
      let localUpdates = {
        isFilterBarOptsEnabled: false,
      };
      let hideTempVisibleTasksFromList = false;
      const isInboxViewEnabled = this.isInboxViewEnabled;
      const isGlobalViewEnabled = this.isGlobalViewEnabled;
      let rootStateUpdates = {
        searchVal: "",
      };
      let navToUse = 1;

      updates[`/view/${navToUse}/selectedByFilterBar`] = !!fromFilterBar;
      if (Array.isArray(data)) {
        updates[`/view/${navToUse}/selectedOptsList`] = data;
      } else {
        updates[`/view/${navToUse}/selectedCategory`] = data.selectedCategory;
        updates[`/view/${navToUse}/selectedSubcategory`] =
          data.selectedSubCategory;
        updates[`/view/${navToUse}/selectedProject`] = data.selectedProject;
        updates[`/view/${navToUse}/selectedOptsList`] = [];
      }

      let viewDbData = {};
      if (!this.isGlobalViewEnabled) {
        viewDbData = createViewDataForDb(viewToSend);
        if (
          this.isInboxViewEnabled &&
          (isEmpty(data) || !isEmpty(this.$route.params.viewConfig?.viewData))
        ) {
          delete viewDbData.inboxView;
        }
      }

      updates = {
        ...updates,
        ...viewDbData,
      };

      if (turnOffInspectMode && this.isInspectModeEnabled) {
        localUpdates.inspectModeEnabled = false;

        hideTempVisibleTasksFromList = true;
      }

      if (
        !hideTempVisibleTasksFromList &&
        (isGlobalViewEnabled || isInboxViewEnabled)
      ) {
        hideTempVisibleTasksFromList = true;
      }

      if (hideTempVisibleTasksFromList) {
        this.hideTempVisibleTasks();
      }

      if (!turnOffInspectMode) {
        localUpdates = {
          nav: parseInt(viewToSend, 10),
          selectedOptsList: data,
          selectedOptsByFilterBar: !!fromFilterBar,
        };
      }

      this.updateState({ ...localUpdates });
      this.clearListFilters();

      if (!doNotClearViewData) {
        rootStateUpdates = {
          ...rootStateUpdates,
          currViewData: {},
          itemInspectEnabled: false,
        };
      }

      this.updateRootState({
        ...rootStateUpdates,
      });
      // if (this.isInboxViewEnabled && !this.isGlobalViewEnabled) {
      //   this.selectedNodes = data.map((d) => d.id);
      // } else {
      if (turnOffInspectMode) {
        DatabaseInterface.update(updates, this.userInfo.uid);
      }
      // }

      return true;
    },
    updateCategoryValue: function (data) {
      let updates = {};
      updates["/categories/" + data.key + "/open"] = data.open;
      DatabaseInterface.update(updates, this.userInfo.uid);
    },
    updateSubCategoryOpen: function (data) {
      let updates = {};
      updates["/projects/" + data.key + "/open"] = data.open;
      DatabaseInterface.update(updates, this.userInfo.uid);
    },
    handleRecountAfterMove(type) {
      this.subCatOrProjectReordered = typesOfProjects.indexOf(type) >= 0;
    },
    async nodeEdited(args) {
      // console.debug("AARGS", args);
      const nodeId = args.nodeData.id;
      let { newText, oldText } = args;
      newText = newText && newText.trim();

      // const currProjectsTree = cloneDeep(this.projectsTree);
      if (newText !== oldText) {
        let originalNodeData = {};
        let updateText = false;
        if (this.projects[nodeId]) {
          originalNodeData = this.projects[nodeId];
        } else if (this.categories[nodeId]) {
          originalNodeData = this.categories[nodeId];
        }
        if (this.renameEnabledByUser) {
          if (!newText) {
            args.cancel = true;

            this.$swal({
              titleText: "Oops!",
              html: "Name cannot be empty",
              icon: "error",
              confirmButtonColor: PRIMARY_COLOR,
              iconColor: PRIMARY_COLOR,
            });

            // this.$swal("Oops!", "Name cannot be empty", "error");
            return;
          }
          updateText = true;
          let children = [];
          if (
            originalNodeData.type === "subcategory" ||
            originalNodeData.type === "project"
          ) {
            const parentIdToFind = originalNodeData.subcategory
              ? originalNodeData.subcategory
              : originalNodeData.category;

            const parent = findProjectOrCategory(
              this.projectsTree,
              (node) => node.key === parentIdToFind
            );
            if (!isEmpty(parent)) {
              children = parent.projects;
            }
          } else {
            children = this.projectsTree;
          }

          const sameItem = children.find((ch) => ch.title.trim() === newText);
          if (!isEmpty(sameItem)) {
            args.cancel = true;

            this.$swal({
              titleText: "Oops!",
              html: "Cannot rename because an item with same name already exists!",
              icon: "error",
              confirmButtonColor: PRIMARY_COLOR,
              iconColor: PRIMARY_COLOR,
            });
            return;
          }

          const { editedData } = createUpdateAndEditedForCatOrProj(
            {
              title: newText,
            },
            originalNodeData
          );

          storeCatEditAction({
            editedData,
            key: originalNodeData.key,
            type: originalNodeData.type,
          });
        } else {
          updateText = true;
        }

        if (updateText) {
          this.nodeIdToRename = nodeId;
          this.nodeTitleUpdate = newText;

          updateTaskCateInResourceCateData({
            refKey: nodeId,
            updatedData: { title: newText },
          });
        }

        // const node = findProjectOrCategory(
        //   currProjectsTree,
        //   (node) => node.key === originalNodeData.key
        // );
        // node.title = newText;
        // updateCatOrSubCatOrProject(
        //   {
        //     updates,
        //     editedData,
        //     key: originalNodeData.key,
        //     type: originalNodeData.type,
        //   },
        //   this.userInfo.uid
        // );
        // EventEmitter.emit(FOLDER_NAME_CHANGED_EVENT);
      }
    },
    updateTextInInActiveTree(nodeId, nextText) {
      let treeDataToUse, treeToUpdate;

      if (this.showClearedTasks) {
        treeDataToUse = this.fields.dataSource;
        treeToUpdate = "visible";
      } else {
        treeDataToUse = this.allDataFields.dataSource;
        treeToUpdate = "all";
      }

      treeDataToUse = cloneDeep(treeDataToUse);
      const nodeData = findProjectOrCategory(
        treeDataToUse,
        (node) => node.key === nodeId
      );

      if (!isEmpty(nodeData)) {
        nodeData.title = nextText;
      }

      if (treeToUpdate === "all") {
        this.allDataFields = {
          ...this.allDataFields,
          dataSource: treeDataToUse,
        };
      } else if (treeToUpdate === "visible") {
        this.fields = {
          ...this.fields,
          dataSource: treeDataToUse,
        };
      }
    },
    onDrop(event) {
      const tasks = JSON.parse(event.dataTransfer.getData("text"));
      const paths = (event.composedPath && event.composedPath()) || event.path;
      for (let path of paths) {
        if (path.tagName === "LI" && path.className.includes("project")) {
          const project = path.dataset.uid;
          this.updateProject(project, tasks);
          break;
        }
      }
    },
    async updateProject(projectId, tasks) {
      if (projectId && tasks && tasks.length) {
        // this.setProjectPriorityFromTaskOnce(tasks[0], projectId);
        const updates = { project: projectId };
        // const allProjects = this.projects;

        // if (allProjects[projectId] && allProjects[projectId].priority) {
        //   updates.priority = allProjects[projectId].priority;
        // }

        if (checkIfProjectHasNoPriorityByKey(projectId)) {
          this.openDialog({
            taskList: [...tasks],
            areaId: projectId,
          });

          return;
        }

        // updates.projectUpdateData = this.createProjectPriorityDbData(
        //   { ...this.selectedTasks[0], ...updates },
        //   projectId
        // );

        this.updateRecurringOrNormalTasks(
          tasks,
          { ...updates },
          true,
          undefined,
          true
        );

        this.updateSelectedTasks([]);
      }
    },

    // createTasksMap(tasks) {
    //   const map = tasks.reduce((a, t) => {
    //     if (t.key) {
    //       a[t.key] = t;
    //     }
    //     return a;
    //   }, {});
    //   this.tasksMap = map;
    // },
    async handleNodesData(
      selectedIDs,
      viewToSend,
      fromFilterBar,
      turnOffInspectMode,
      doNotOpenForm,
      doNotSaveInDb,
      doNotClearViewData
    ) {
      if (Array.isArray(selectedIDs)) {
        const categoriesKeys = Object.keys(this.categories);
        const projectsKeys = Object.keys(this.projects);
        // let selectedMainCateList = [];
        // this.selectedNodes = selectedIDs;
        // create an array of select opts to view tasks;
        const selectedList = [];
        selectedIDs.forEach((selectedId) => {
          if (categoriesKeys.includes(selectedId)) {
            selectedList.push({
              id: selectedId,
              projectId: "all",
              subCategoryId: "all",
              type: "category",
            });
          } else if (projectsKeys.includes(selectedId)) {
            if (this.projects[selectedId].type === "project") {
              const itemData = {
                id: selectedId,
                subCategoryId: "all",
                categoryId: this.projects[selectedId].category,
                type: "project",
              };

              if (this.projects[selectedId].subcategory) {
                itemData.subCategoryId = this.projects[selectedId].subcategory;
              }
              selectedList.push(itemData);

              if (!doNotOpenForm) {
                if (
                  !this.isEditTaskFormOpen ||
                  (!isEmpty(this.editTaskData) &&
                    this.editTaskData.project !== selectedId)
                ) {
                  this.showNewEditTaskDialog({
                    taskData: {
                      project: selectedId,
                    },
                    selectedTasks: [],
                  });
                }
              }
            } else {
              selectedList.push({
                id: selectedId,
                projectId: "all",
                categoryId: this.projects[selectedId].category,
                type: "sub-category",
              });
            }
          }
        });
        // }

        await this.setSelectedCatIds(selectedIDs);

        // Emits the selection list
        if (!doNotSaveInDb) {
          await new Promise((resolve) => {
            setTimeout(async () => {
              await this.updateView(
                selectedList,
                viewToSend,
                fromFilterBar,
                turnOffInspectMode,
                doNotClearViewData
              );

              resolve();
            }, 250);
          });
        }

        // Set the selected nodes so that nodes can remain selected
        // this.selectedNodes = selectedIDs;
      }
    },
    getNodeIdFromNodeEl(nodeEl) {
      let nodeId;

      if (nodeEl && nodeEl.getAttribute && nodeEl.getAttribute("data-uid")) {
        nodeId = nodeEl.getAttribute("data-uid");
      }

      return nodeId;
    },
    nodeclicked(args) {
      const event = args.event;
      const currView = this.currNav;
      if (
        hasParentEl(event.target, { className: ["edit-item-btn"] })
        // hasParentEl(event.target, { className: ["interaction"] })
      ) {
        return;
      }

      if (event.detail === 1) {
        const targetElId = this.showClearedTasks
          ? "treeview-all-data"
          : "treeview";
        const selectedIDs = document.querySelector(`#${targetElId}`)
          .ej2_instances[0].selectedNodes;

        this.selectNodeFuncId = setTimeout(() => {
          this.handleNodesData(
            selectedIDs,
            currView,
            false
            // 1
          );
        }, 250);
        // const dbUpdates = createViewDataForDb(
        //   this.isInspectModeEnabled ? 1 : currView,
        //   {
        //     isInspectModeEnabled: this.isInspectModeEnabled,
        //     isInboxViewEnabled: this.isInboxViewEnabled,
        //   }
        // );
      }
    },
    toggleNodesInInActiveTree(nodeId, type = "open") {
      const treeToUse = this.showClearedTasks
        ? this.$refs.treeview
        : this.$refs.allDataTreeview;

      if (type === "open") {
        treeToUse.expandAll([nodeId]);
      }

      if (type === "close") {
        treeToUse.collapseAll([nodeId]);
      }
    },
    nodeOpened: function (event) {
      if (this.isInspectModeEnabled) return;
      // const nodeKey = event.node.attributes[2].nodeValue;
      let data = {
        key: event.node.attributes[2].nodeValue,
        open: true,
      };

      if (event.node.classList.contains("subcategory")) {
        this.updateSubCategoryOpen(data);
      } else {
        this.updateCategoryValue(data);
      }
      this.toggleNodesInInActiveTree(
        event.node.attributes[2].nodeValue,
        "open"
      );
      // const openedCategories = cloneDeep(this.openedCategories);

      // if (!openedCategories.includes(nodeKey)) {
      //   openedCategories.push(nodeKey);
      // }

      // this.updateOpenCategoriesInDb(openedCategories);
    },
    nodeClosed: function (event) {
      if (this.isInspectModeEnabled) return;

      // const nodeKey = event.node.attributes[2].nodeValue;
      let data = {
        key: event.node.attributes[2].nodeValue,
        open: false,
      };
      if (event.node.classList.contains("subcategory")) {
        this.updateSubCategoryOpen(data);
      } else {
        this.updateCategoryValue(data);
      }

      this.toggleNodesInInActiveTree(
        event.node.attributes[2].nodeValue,
        "close"
      );
      // const openedCategories = cloneDeep(this.openedCategories);
      // if (openedCategories.includes(nodeKey)) {
      //   openedCategories.splice(openedCategories.indexOf(nodeKey), 1);
      // }

      // this.updateOpenCategoriesInDb(openedCategories);
    },
    updateOpenCategoriesInDb(cateList) {
      DatabaseInterface.update(
        {
          [`/view/${this.currNav}/openedCategories`]: cateList,
        },
        this.userInfo.uid
      );
    },
    getListWrapperEl() {
      return this.showAllData
        ? this.$el.querySelector("#treeview-all-data")
        : this.$el.querySelector("#treeview");
    },
    nodeDrag(args) {
      onDragItem(args, this.selectedOptsByFilterBar);
      this.scrollListForDrag({
        draggedItemEl: args.clonedNode,
        listWrappEl: this.getListWrapperEl(),
        parentEl: this.parentWrapperEl,
      });
    },
    dragStop: function (args) {
      const currArgs = onDragStop(args);
      if (this.dragStarted) {
        this.dragStarted = false;
      }
      if (currArgs.cancel) {
        return;
      }
      this.handleNodeMove(args);
    },
    handleNodeMove(args) {
      const { draggedNodeData, droppedNode, position } = args;
      let { dropIndex } = args;

      let orderPositions = ["Before", "After"];
      // const currCategories = cloneDeep(this.categories);
      // const currProjects = cloneDeep(this.projects);
      let draggedItemData = {};
      // let childrenToUpdate = [];
      if (this.projects[draggedNodeData.id]) {
        draggedItemData = { ...this.projects[draggedNodeData.id] };
      } else if (this.categories[draggedNodeData.id]) {
        draggedItemData = { ...this.categories[draggedNodeData.id] };
      }

      let updatedItem = {};
      let currItemData = {};
      if (!isEmpty(draggedItemData)) {
        let targetItemData = {};
        currItemData = cloneDeep(draggedItemData);
        currItemData.type = currItemData.type.split(" ")[0];
        updatedItem = {
          ...draggedItemData,
          order: typeof dropIndex === "number" ? dropIndex : 0,
        };
        if (draggedItemData.type === "category") {
          // this.catReordered = true;
          if (orderPositions.indexOf(position) >= 0) {
            // let children = cloneDeep(this.projectsTree);
            // if (typeof dropIndex === "number") {
            //   const currIndex = children.findIndex(
            //     (ch) => ch.key === draggedItemData.key
            //   );
            //   if (currIndex >= 0) {
            //     children.splice(currIndex, 1);
            //   }
            //   updatedItem = { ...draggedItemData, order: dropIndex };
            //   children.splice(dropIndex, 0, {
            //     ...draggedItemData,
            //     order: dropIndex,
            //   });
            //   children.sort((a, b) => a.order - b.order);
            // }
            // childrenToUpdate = children;
            // if (!isEmpty(targetItemData)) {
            //   children = this;
            // }
          }
        } else if (
          draggedItemData.type === "subcategory" ||
          draggedItemData.type === "project"
        ) {
          if (orderPositions.indexOf(position) >= 0) {
            const targetId = droppedNode.getAttribute("data-uid");
            let targetItemData = {};
            // let indexToUse =
            //   position === "After" ? dropIndex + 1 : dropIndex - 1;
            if (this.projects[targetId]) {
              targetItemData = { ...this.projects[targetId] };
            } else if (this.categories[targetId]) {
              targetItemData = { ...this.categories[targetId] };
            }
            if (!isEmpty(targetItemData)) {
              // let parentChildren = [];
              if (targetItemData.type === "category") {
                // const catParent = findProjectOrCategory(
                //   this.projectsTree,
                //   (node) => node.key === targetItemData.key
                // );
                // parentChildren = catParent?.projects;

                draggedItemData = {
                  ...draggedItemData,
                  order: dropIndex,
                  category: targetItemData.key,
                  subcategory: false,
                };
              } else if (
                targetItemData.type === "subcategory" ||
                targetItemData.type === "project"
              ) {
                const draggedItemParentId = draggedItemData.subcategory
                  ? draggedItemData.subcategory
                  : draggedItemData.category;
                draggedItemData = {
                  ...draggedItemData,
                  subcategory: false,
                  order: dropIndex,
                };

                let parentIdToGet = "";
                if (targetItemData.subcategory) {
                  parentIdToGet = targetItemData.subcategory;
                  // draggedItemData.subcategory = targetItemData.subcategory;
                  // draggedItemData.category = targetItemData.category;
                } else {
                  parentIdToGet = targetItemData.category;
                  // draggedItemData.category = targetItemData.category;
                }

                this.subCatOrProjectReordered =
                  parentIdToGet !== draggedItemParentId;

                const subCatParent = findProjectOrCategory(
                  this.projectsTree,
                  (node) => node.key === parentIdToGet
                );

                if (!subCatParent.type) {
                  args.cancel = true;
                  return;
                }

                if (subCatParent.type === "subcategory") {
                  draggedItemData.subcategory = subCatParent.key;
                  draggedItemData.category = subCatParent.category;
                  // draggedItemData.subcategory = targetItemData.subcategory;
                  // draggedItemData.category = targetItemData.category;
                } else if (subCatParent.type === "category") {
                  draggedItemData.category = subCatParent.key;
                  // draggedItemData.category = targetItemData.category;
                }

                // parentChildren = subCatParent?.projects;
              }

              updatedItem = { ...draggedItemData, order: dropIndex };

              // if (parentChildren && parentChildren.length) {
              //   parentChildren = cloneDeep(parentChildren);

              //   const currIndex = parentChildren.findIndex(
              //     (pc) => pc.key === draggedItemData.key
              //   );
              //   if (currIndex >= 0) {
              //     parentChildren.splice(currIndex, 1);
              //   }
              //   parentChildren.splice(dropIndex, 0, draggedItemData);
              //   updatedItem = { ...draggedItemData };
              //   parentChildren.sort((a, b) => a.order - b.order);
              // } else {
              //   parentChildren = [{ ...draggedItemData, order: 0 }];
              //   updatedItem = { ...draggedItemData, order: 0 };
              // }

              // childrenToUpdate = parentChildren;
            }

            // if (!isEmpty(targetItemData)) {
            //   children = this;
            // }
          } else if (position === "Inside") {
            if (droppedNode) {
              const targetId = droppedNode.getAttribute("data-uid");

              targetItemData = findProjectOrCategory(
                this.projectsTree,
                (node) => node.key === targetId
              );
            }

            this.subCatOrProjectReordered = true;
            draggedItemData = { ...draggedItemData, subcategory: false };

            if (!targetItemData.type) {
              args.cancel = true;
              return;
            }

            if (targetItemData.type === "category") {
              draggedItemData.category = targetItemData.key;
            } else if (targetItemData.type === "subcategory") {
              draggedItemData.category = targetItemData.category;
              draggedItemData.subcategory = targetItemData.key;
            }

            let itemChildren = targetItemData.projects;
            if (itemChildren && itemChildren.length) {
              itemChildren = cloneDeep(itemChildren);
              updatedItem = { ...draggedItemData, order: itemChildren.length };
              // itemChildren.push({
              //   ...updatedItem,
              // });
            } else {
              updatedItem = { ...draggedItemData, order: 0 };
              // itemChildren = [{ ...updatedItem }];
            }

            // childrenToUpdate = itemChildren;
          }
        }

        // childrenToUpdate = childrenToUpdate.map((child, index) => {
        //   const list = this[catTypes[child.type]];

        //   const { updates, editedData } = createUpdateAndEditedForCatOrProj(
        //     {
        //       order: index,
        //       subcategory: child.subcategory,
        //       category: child.category,
        //     },
        //     list[child.key]
        //   );
        //   return {
        //     updates,
        //     editedData,
        //     key: child.key,
        //     type: child.type,
        //     title: child.title,
        //   };
        // });

        const list = this[catTypes[currItemData.type]];
        const { editedData } = createUpdateAndEditedForCatOrProj(
          {
            order: updatedItem.order,
            subcategory: updatedItem.subcategory,
            category: updatedItem.category,
          },
          list[currItemData.key]
        );

        storeCatReorderOrMoveAction(editedData);

        moveResourceCateByTaskCateData({
          refKey: currItemData.key,
          order: updatedItem.order,
          parentKey: updatedItem.subcategory || updatedItem.category || "",
        });
        // this.updateTreeStructure()
        // reorderOrMoveCatOrProject(
        //   childrenToUpdate,
        //   editedData,
        //   this.userInfo.uid
        // );

        // updateCatOrSubCatOrProject(childrenToUpdate, this.userInfo.uid);

        // if (!isEmpty(targetItemData)) {
        //   targetItemData = cloneDeep(targetItemData);

        //   if()

        //   // if(draggedItemData.subcategory && draggedItemData.subcategory === targetItemData.key){

        //   // } else if(draggedItemData.){

        //   // }
        //   // if (targetItemData.type === "subcategory") {
        //   // } else if (targetItemData.type === "category") {
        //   // }
        // }
        this.nodeReordered = true;
      }
    },

    menuclick: function (args) {
      var treevalidate = document.getElementById("treeview").ej2_instances[0];
      var targetNodeId = treevalidate.selectedNodes[0];
      if (args.item.text == "Add New Sub-Category") {
        let nodeId = this.newID();
        let item = {
          key: nodeId,
          title: "New Folder",
          hasAttribute: { class: "subcategory" },
          type: "subcategory",
          completed: false,
          created: "2021-09-29",
          description: "New Folder",
          icon: "mdi-circle",
          modified: "2021-09-29",
          priority: "",
          open: true,
          projects: [],
        };
        treevalidate.addNodes([item], targetNodeId, null);
        treevalidate.fields.dataSource.push(item);
        treevalidate.beginEdit(nodeId);
      } else if (args.item.text == "Add New Project") {
        let nodeId = this.newID();
        let item = {
          key: nodeId,
          title: "New Project",
          hasAttribute: { class: "project" },
          type: "project",
          completed: false,
          created: "2021-09-29",
          description: "New Project Description",
          icon: "mdi-circle",
          modified: "2021-09-29",
          priority: "priority",
          tasks: [],
        };
        treevalidate.addNodes([item], targetNodeId, null);
        treevalidate.fields.dataSource.push(item);
        treevalidate.beginEdit(nodeId);
      } else if (args.item.text == "Remove Item") {
        let deleteNode = true;
        if (Object.keys(this.categories).includes(targetNodeId)) {
          if (
            this.categories[targetNodeId].projects &&
            this.categories[targetNodeId].projects.length
          ) {
            deleteNode = false;
          }

          // if (deleteNode) {
          //   let tmpProjects = this.projects;
          //   tasksToInbox = tasksToInbox.filter(function (item) {
          //     if (tmpProjects[item.project].category == targetNodeId) {
          //       return true;
          //     }
          //   });
          // }
        } else if (Object.keys(this.projects).includes(targetNodeId)) {
          if (
            this.projects[targetNodeId].projects &&
            this.projects[targetNodeId].projects.length
          ) {
            deleteNode = false;
          }

          // if (deleteNode) {
          //   if (this.projects[targetNodeId].type == "project") {
          //     tasksToInbox = tasksToInbox.filter(function (item) {
          //       if (item.project == targetNodeId) {
          //         return true;
          //       }
          //     });
          //   } else {
          //     tasksToInbox = this.getSubcatTasks(this.projects[targetNodeId]);
          //   }
          // }
        }

        if (!deleteNode) {
          this.$swal({
            titleText: "Oops!",
            html: "Cannot delete because it contains items.<br/>Please delete them first",
            icon: "error",
            confirmButtonColor: PRIMARY_COLOR,
            iconColor: PRIMARY_COLOR,
          });
          return;
        }

        let updates = {};

        updates["/view/1/selectedCategory"] = "all";
        updates["/view/1/selectedSubcategory"] = "all";
        updates["/view/1/selectedProject"] = "all";
        DatabaseInterface.update(updates, this.userInfo.uid);
        treevalidate.removeNodes([targetNodeId]);
      } else if (args.item.text == "Rename Item") {
        treevalidate.beginEdit(targetNodeId);
      }
    },
    beforeopen: function (args) {
      // Prevent opening of menu when ctrl key is pressed;
      if (args.event.ctrlKey) {
        args.cancel = true;
        return;
      }
      var treevalidate = document.getElementById("treeview").ej2_instances[0];
      var targetNodeId = treevalidate.selectedNodes[0];
      var targetNode = document.querySelector(
        '[data-uid="' + targetNodeId + '"]'
      );
      var contentmenutree =
        document.getElementById("contentmenutree").ej2_instances[0];
      contentmenutree.enableItems(["Add New Sub-Category"], true);
      contentmenutree.enableItems(["Add New Project"], true);
      if (targetNode?.classList?.contains("project")) {
        contentmenutree.enableItems(["Add New Sub-Category"], false);
        contentmenutree.enableItems(["Add New Project"], false);
      }
    },
    async trigggerRefreshTree() {
      await this.$nextTick();
      this.refreshTreeAfterEdit();
    },
    async handleTreeEditedData(listData) {
      const data = this.flattenTreeData(listData);
      let reprocessTasks = false;
      let reprocessTree = false;
      let updateNodeName = false;
      if (this.renameEnabledByUser) {
        this.renameEnabledByUser = false;
      }
      if (this.nodeReordered) {
        this.nodeReordered = false;
        reprocessTasks = true;
        reprocessTree = true;
      }

      if (this.nodeIdToRename) {
        updateNodeName = this.nodeIdToRename;
        this.nodeIdToRename = false;
      }
      let updates = {};
      delete data.categories.undefined;

      let categoriesData = JSON.parse(JSON.stringify(this.categories || {}));
      let projectsData = JSON.parse(JSON.stringify(this.projects || {}));

      if (!isEmpty(data.categories)) {
        categoriesData = {
          ...categoriesData,
          ...data.categories,
        };
      }

      if (!isEmpty(data.projects)) {
        projectsData = {
          ...projectsData,
          ...data.projects,
        };
      }

      if (!isEmpty(this.nodeIdsToRemove)) {
        this.nodeIdsToRemove.forEach((nodeId) => {
          if (categoriesData[nodeId]) {
            delete categoriesData[nodeId];
          } else if (projectsData[nodeId]) {
            delete projectsData[nodeId];
          }
        });
        this.nodeIdsToRemove = undefined;
      }

      updates["/categories"] = categoriesData;
      updates["/projects"] = projectsData;
      DatabaseInterface.update(updates, this.userInfo.uid);
      this.setProjectsTree(listData);
      await this.$nextTick();
      EventEmitter.emit(REFRESH_TASKS_EVENT, reprocessTasks);
      EventEmitter.emit(REFRESH_FORM_DATA, true);
      if (reprocessTree) {
        this.trigggerRefreshTree();
      }

      if (this.disableEditAfterUpdate) {
        this.allowEditing = false;
        this.disableEditAfterUpdate = false;
      }

      if (updateNodeName) {
        this.updateTextInInActiveTree(updateNodeName, this.nodeTitleUpdate);
      }
    },
    async treeEdited(event) {
      this.handleTreeEditedData(event.data);
    },

    flattenTreeData(data) {
      let tmpCategories = {};
      let tmpProjects = {};
      const treeview = this.getTreeViewRef();
      //get all category data
      for (let i = 0; i < data.length; i++) {
        let tmp = [];
        //if top level node has children
        if (data[i].projects && data[i].projects.length > 0) {
          for (let j = 0; j < data[i].projects.length; j++) {
            //get children keys
            tmp.push(data[i].projects[j].key);
            let nestedProjects = this.getNestedProjects(
              data[i].projects,
              data[i].key,
              false
            );
            Object.assign(tmpProjects, nestedProjects);
          }
        }
        const nodeData = treeview.getTreeData(data[i].key)[0];
        tmpCategories[data[i].key] = {
          order: i,
          title: data[i].title,
          key: data[i].key,
          modified: data[i].modified,
          icon: data[i].icon,
          description: data[i].description,
          open: nodeData?.open || false,
          type: "category",
          children: tmp,
          hidden: data[i].hidden || false,
          deleted: data[i].deleted || false,
        };
      }

      const rtnC = tmpCategories;
      const rtnP = tmpProjects;
      // rtnC["inboxCat"] = this.categories.inboxCat;
      return {
        categories: rtnC,
        projects: rtnP,
      };
    },
    async createTreeData() {
      // console.debug("TA", this.tasks.length);
      // const catTreeView = document.querySelector("#treeview").ej2_instances[0];
      await this.$nextTick();
      const { visbleDataSource, ...treeData } = getTreeFields({
        categories: this.categories,
        projects: this.projects,
        tasksMap: this.tasksMap,
        selectedNodes: this.selectedNodes,
        isInspectModeEnabled: this.isInspectModeEnabled,
        selectedCategories: this.selectedCatIds,
        // expandedCategories: this.openedCategories,
      });

      if (!this.ignoreAreaCheck) {
        this.checkSelectedNodesAndOpenArea(this.selectedOptsList);
      }

      // this.handleNodesData(
      //   this.selectedNodes,
      //   this.currNav,
      //   undefined,
      //   undefined,
      //   undefined,
      //   this.isInspectModeEnabled
      // );

      //console.log('createTreeData', this.selectedNodes)
      this.setProjectsTree(
        this.showClearedTasks ? treeData.dataSource : visbleDataSource
      );
      this.fields = {
        ...treeData,
        dataSource: visbleDataSource,
      };

      this.allDataFields = {
        ...treeData,
        dataSource: treeData.dataSource,
      };

      this.showAllData = this.showClearedTasks;

      await this.$nextTick();

      this.setDataTypeOfTrees();
      const treeView = this.getTreeViewRef();

      treeView.ensureVisible(this.selectedNodes[0]);
    },

    setDataTypeOfTrees() {
      const activeTreeView = getTaskTreeView("active").treeInstance;
      const inActiveTreeview = getTaskTreeView("inactive").treeInstance;

      activeTreeView.dataType = 2;
      inActiveTreeview.dataType = 2;
    },
    async updateTree(updateTree, noDelay, scrollToFirst = true) {
      // const catTreeView = document.querySelector("#treeview").ej2_instances[0];

      const { visbleDataSource, ...treeData } = getTreeFields({
        categories: this.categories,
        projects: this.projects,
        tasksMap: this.tasksMap,
        selectedNodes: this.selectedNodes,
        isInspectModeEnabled: this.isInspectModeEnabled,
        selectedCategories: this.selectedCatIds,
        // expandedCategories: this.openedCategories,
      });

      // console.time("VUEX");
      // this.setProjectsTree(treeData.dataSource);
      // console.timeEnd("VUEX");
      if (this.catReordered) {
        this.catReordered = false;
      }

      if (this.subCatOrProjectReordered) {
        this.subCatOrProjectReordered = false;
      }

      if (updateTree && !noDelay) {
        await sleep(70);
        this.fields = {
          ...treeData,
          dataSource: visbleDataSource,
        };
        this.allDataFields = {
          ...treeData,
        };
        // this.fields = treeData;
      } else if (updateTree && noDelay) {
        this.fields = {
          ...treeData,
          dataSource: visbleDataSource,
        };
        this.allDataFields = {
          ...treeData,
        };
      }

      this.setProjectsTree(
        this.showClearedTasks ? treeData.dataSource : visbleDataSource
      );
      await this.$nextTick();
      const treeView = this.getTreeViewRef();

      if (scrollToFirst) {
        treeView.ensureVisible(this.selectedNodes[0]);
      }

      // catTreeView.fields.dataSource = treeData.dataSource;
      // console.debug("EEE", treeData);
    },
    refreshTreeAfterEdit(
      refreshTasks = true,
      scrollToFirst = true,
      taskRefreshProps = []
    ) {
      this.updateTree(true, true, scrollToFirst);
      this.$nextTick(() => {
        if (refreshTasks) {
          EventEmitter.emit(REFRESH_TASKS_EVENT, true, ...taskRefreshProps);
        }
      });
    },

    enableNodeRename(enableEdit, disableAfterUpdate) {
      this.allowEditing = enableEdit;

      this.disableEditAfterUpdate = disableAfterUpdate;
      // this.renameEnabledByUser = true;
    },
    refreshAfterNodeReordered() {
      this.nodeReordered = true;
    },
    setSelectedNodesInTree(nodeIds) {
      this.selectedNodes = nodeIds;
    },

    getAllParentIDs(project) {
      let rtn = [];
      if (this.projects[project]?.subcategory) {
        rtn.push(this.projects[project].subcategory);
        rtn = rtn.concat(
          this.getAllParentIDs(this.projects[project].subcategory)
        );
      }
      return rtn;
    },
    getNestedProjects(data, category, subcategory) {
      var rtn = {};
      const treeview = this.getTreeViewRef();
      for (var i = 0; i < data.length; i++) {
        //gets all projects from current level

        data[i].type = data[i].type.split(" ")[0];
        const nodeData = treeview.getTreeData(data[i].key)[0];
        if (data[i].type == "project") {
          let theseTasks = [];
          if (data[i].tasks) {
            theseTasks = data[i].tasks;
          }
          rtn[data[i].key] = {
            category: category,
            subcategory: subcategory,
            completed: data[i].completed,
            created: data[i].created,
            description: data[i].description,
            type: data[i].type.split(" ")[0],
            icon: data[i].icon,
            modified: data[i].modified,
            key: data[i].key,
            title: data[i].title,
            priority: data[i].priority,
            tasks: theseTasks,
            order: i,
            projects: [],
            hidden: nodeData?.hidden || false,
            deleted: nodeData?.deleted || false,
          };
        } else {
          let tmp = [];
          if (data[i].projects && data[i].projects.length > 0) {
            for (let j = 0; j < data[i].projects.length; j++) {
              //get children keys
              tmp.push(data[i].projects[j].key);
              let nestedProjects = this.getNestedProjects(
                data[i].projects,
                category,
                data[i].key
              );
              Object.assign(rtn, nestedProjects);
            }
          }

          const nodeData = treeview.getTreeData(data[i].key)[0];
          rtn[data[i].key] = {
            category: category,
            subcategory: subcategory,
            completed: data[i].completed,
            created: data[i].created,
            description: data[i].description,
            type: data[i].type.split(" ")[0],
            icon: data[i].icon,
            modified: data[i].modified,
            key: data[i].key,
            title: data[i].title,
            priority: data[i].priority,
            open: nodeData?.open || false,
            tasks: [],
            order: i,
            projects: tmp,
            hidden: nodeData?.hidden || false,
            deleted: nodeData?.deleted || false,
          };
        }
      }
      return rtn;
    },
    getSubcatTasks(subcat) {
      var rtn = [];
      let tmpTasks = this.tasksMap;
      for (let project in subcat.projects) {
        if (
          subcat.projects[project].type == "project" &&
          subcat.projects[project].tasks
        ) {
          for (var i = 0; i < subcat.projects[project].tasks.length; i++) {
            rtn.push(tmpTasks[subcat.projects[project].tasks[i]]);
          }
        } else {
          rtn = rtn.concat(this.getSubcatTasks(subcat.projects[project]));
        }
      }
      return rtn;
    },
    newID: function () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    },
    async checkTasksAndCreateTree(newList, oldList) {
      let listChanged = false;
      // let refreshList = false;

      if (
        (!isEmpty(newList) && isEmpty(oldList)) ||
        (isEmpty(newList) && isEmpty(oldList))
      ) {
        this.createTreeData();
      } else {
        if (newList.length !== oldList.length) {
          listChanged = true;
        } else {
          // refreshList = true;
          listChanged = newList.some(
            (task, index) =>
              (!task.isTemp && task.project !== oldList[index].project) ||
              (task.project === oldList[index].project &&
                oldList[index].isTemp) ||
              task.completed !== oldList[index].completed
          );
        }

        if (listChanged) {
          this.updateTree(true, false);
          // await this.$nextTick();
          // if (refreshList) {
          //   emitRefreshList(true, "task");
          // }
        }
      }
    },
    parseSelectedOptsListAndSetInTree(selectedOptsList) {
      let selectedIds = [];
      // let selectedCategories = [];
      selectedOptsList.forEach((opt) => {
        selectedIds.push(opt.id);
        // selectedCatIds.push(task.project);
        // selectedCategories = selectedCategories.concat(
        //   this.getAllParentIDs(opt.id)
        // );
        // if (this.projects[opt.id]?.category) {
        //   selectedCategories.push(this.projects[opt.id].category);
        // }
      });

      if (this.selectedOptsByFilterBar) {
        selectedIds = [selectedIds[0]];
      }
      this.selectedNodes = selectedIds;
      // this.selectedCatIds = selectedCategories;
    },
    restoreExpandedNodes() {
      const treeViewEl = this.$el.querySelector("#treeview")?.ej2_instances[0];
      const currExpandedNodes = this.currExpandedNodes;
      const currSelectedNodes = this.currSelectedNodes;

      if (treeViewEl) {
        if (!isEmpty(currExpandedNodes)) {
          treeViewEl.expandedNodes = currExpandedNodes;
        }

        if (!isEmpty(currSelectedNodes)) {
          treeViewEl.selectedNodes = currSelectedNodes;
        }
      }
    },
    async changeOpenNodesByMode(mode) {
      if (
        treeSideBarModes.includes(mode) &&
        !this.isGlobalViewEnabled &&
        !this.isInboxViewEnabled &&
        !this.isInspectModeEnabled
      ) {
        const modeOpenNodes = this.openedCategories;

        const catTree = this.$el.querySelector("#treeview")?.ej2_instances[0];

        if (catTree) {
          const currExpandedNodes = [...catTree.expandedNodes];
          catTree.collapseAll(
            currExpandedNodes.filter((n) => !modeOpenNodes.includes(n))
          );

          catTree.expandAll(modeOpenNodes);
        }
      }
    },
    updateTreeDataInInActiveTree() {
      const { visbleDataSource, ...treeData } = getTreeFields({
        categories: this.categories,
        projects: this.projects,
        tasksMap: this.tasksMap,
        selectedNodes: this.selectedNodes,
        isInspectModeEnabled: this.isInspectModeEnabled,
        selectedCategories: this.selectedCatIds,
      });

      if (this.showClearedTasks) {
        this.fields = {
          ...this.fields,
          dataSource: visbleDataSource,
        };
        this.setProjectsTree(visbleDataSource);
      } else {
        this.allDataFields = {
          ...this.allDataFields,
          dataSource: treeData.dataSource,
        };
        this.setProjectsTree(treeData.dataSource);
      }
    },
    checkProjectPirorityHasChanged(newListMap, oldListMap) {
      const newList = Object.values(newListMap);
      const oldList = Object.values(oldListMap);

      const res = newList.some(
        (task, index) => task.priority !== oldList[index].priority
      );
      return res;
    },
    async recreateTreeWithNewData() {
      await this.$nextTick();
      this.refreshTreeAfterEdit(undefined, false);
    },
    checkSelectedNodesAndOpenArea(selectedOptsList) {
      if (!isEmpty(selectedOptsList) && selectedOptsList.length === 1) {
        if (selectedOptsList[0].type === "project") {
          if (
            !this.isEditTaskFormOpen ||
            (!isEmpty(this.editTaskData) &&
              this.editTaskData.project !== selectedOptsList[0].id)
          ) {
            this.showNewEditTaskDialog({
              taskData: {
                project: selectedOptsList[0].id,
              },
              selectedTasks: [],
            });
          }
        }
      }
    },
  },
  watch: {
    projects: {
      handler(n, o) {
        if (!isEmpty(n) && !isEmpty(o)) {
          const oldProjectsKeysCount = Object.keys(o).length;
          const newProjectKeysCount = Object.keys(n).length;
          if (oldProjectsKeysCount !== newProjectKeysCount) {
            this.updateTreeDataInInActiveTree();
          }
        }
      },
      deep: true,
    },
    categories: {
      handler(n, o) {
        if (!isEmpty(n) && (!isEmpty(o) || isEmpty(o))) {
          const oldCategoriesKeysCount = Object.keys(o).length;
          const newCategoriesKeysCount = Object.keys(n).length;

          if (oldCategoriesKeysCount !== newCategoriesKeysCount) {
            this.updateTreeDataInInActiveTree();
          }
        }
      },
    },
    tasks: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.checkTasksAndCreateTree(n, o);
        }
      },
      deep: true,
    },
    selectedOptsList: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.parseSelectedOptsListAndSetInTree(n);
        }
      },
      deep: true,
    },
    showClearedTasks: {
      handler(newVal) {
        this.showAllData = newVal;
        const treeData = newVal
          ? this.allDataFields.dataSource
          : this.fields.dataSource;
        this.setProjectsTree(treeData);
      },
    },
  },
  computed: {
    ...mapGetters("editForm", {
      editTaskData: "taskData",
      isEditTaskFormOpen: "openNewForm",
    }),
    ...mapGetters("task", [
      "projects",
      "categories",
      "selectedOptsList",
      "isInspectModeEnabled",
      "selectedTasks",
      "tasks",
      "projectsTree",
      "rawTasks",
      "tasksMap",
      "currNav",
      "isUserFromDifferentTreeMode",
      "isInboxViewEnabled",
      "isGlobalViewEnabled",
      "openedCategories",
      "selectedOptsByFilterBar",
      "showClearedTasks",
      "ignoreAreaCheck",
    ]),
    menuTarget() {
      return this.showAllData ? "#treeview-all-data" : "#treeview";
    },

    isInNextActionsMode() {
      return (
        treeSideBarModes.includes(this.currNav) &&
        !this.isInboxViewEnabled &&
        !this.isGlobalViewEnabled
      );
    },
    isInNextActionsModeOrGlobalMode() {
      //       treeSideBarModes.includes(this.currNav) &&
      // (this.isInNextActionsMode || this.isGlobalViewEnabled)
      return false;
    },
  },
};
</script>

<style>
.e-treeview
  .e-list-item.e-active:not(.e-editing)
  > .e-fullrow
  .e-text-content
  .e-list-text {
  color: var(--primary-color) !important;
  background-color: var(--selection-color) !important;
}

/* .e-treeview .e-list-item.e-active:not(.e-editing):hover > .e-fullrow {
  border-color: transparent !important;
} */

/* .e-treeview .e-list-item.e-active:not(.e-editing){
  color: var(--primary-color) !important;
  background-color: var(--selection-color) !important;
} */

.e-treeview
  .e-list-item.e-active:not(.e-editing)
  > .e-text-content
  > div.e-icons:before {
  color: var(--primary-color) !important;
  background-color: var(--selection-color) !important;
}

.CTITreeView .e-level-1 {
  margin-left: -24px;
}

.subcategory .e-text-content {
  padding-left: 10px !important;
}
.project .e-text-content {
  padding-left: 10px !important;
}
.e-name .subcategory {
  margin-left: 20px;
}
.e-list-text {
  width: 100%;
}

.e-list-parent.e-ul {
  overflow: hidden;
}

/* span.project:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #747474;
} */
.e-treeview .e-list-text {
  padding: 0 5px 0 0;
}

.e-treeview .e-list-item .e-ul {
  padding: 0 0 0 12px;
}

.e-treeview .e-list-item {
  position: relative;
  z-index: 110;
}

/* #treeview {
  height: 100%;
  overflow-y: scroll;
} */

#treeview-wrapper {
  height: 100%;
  overflow-y: scroll;
}
#treeview-side-bar {
  height: 100%;
}

.e-drag-item.e-treeview.CTITreeView .category-project-count {
  margin-left: 10px;
}

#tasks-filter-selector-wrapper {
  margin-top: 0px;
  margin-bottom: 4px;
}
/* Disabled For Now */
/* .e-treeview
  .e-list-item.e-active:not(.e-editing)
  > .e-text-content
  span.project:before {
  background-color: var(--primary-color);
} */
</style>
