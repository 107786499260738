import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.isSettingsOpen,"width":"75%","content-class":"settings-dialog"},on:{"click:outside":_vm.closeDialog}},[_c(VCard,{attrs:{"outlined":""}},[_c(VRow,{attrs:{"dense":"","no-gutters":""}},[_c(VCol,{attrs:{"cols":"3"}},[_c('div',{staticClass:"side-bar pa-3"},[_c(VCardTitle,{staticClass:"pa-0"},[_vm._v(" Settings ")]),_c(VList,{staticClass:"menu-opts-wrapper"},[_c(VListItemGroup,{attrs:{"value":_vm.model,"mandatory":""},on:{"change":_vm.handleMenuChange}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1),_c('div',[_c(VListItem,{on:{"click":_vm.handleSignout}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Sign Out")])],1)],1)],1)],1)]),_c(VCol,[_c('div',{staticClass:"fill-height settings-form"},[(_vm.model === 0)?_c('AccountsSettings'):_vm._e(),(_vm.model === 1)?_c('ColorThemeSettings'):_vm._e(),(_vm.model === 2)?_c('ListSettings'):_vm._e(),(_vm.model === 3)?_c('DetailsPaneSettings'):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }