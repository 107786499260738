export const repeatTypesOptsMap = {
  none: {
    label: "Never",
    value: "never",
  },
  // every: {
  //   label: "Every",
  //   value: "every",
  // },
  in: {
    label: "In",
    value: "in",
  },
};

const repeatTypeOpts = Object.values(repeatTypesOptsMap);

export default repeatTypeOpts;
