import resourceParentFormActions from "./actions";
import resourceParentFormMutators from "./mutations";
import resourceParentFormGetters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      isResourceParentFormOpen: false,
      resourceData: {},
    };
  },
  actions: resourceParentFormActions,
  mutations: resourceParentFormMutators,
  getters: resourceParentFormGetters,
};
