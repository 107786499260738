<template>
  <div class="input-cell">
    <select
      :value="text"
      @input="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      @keydown="handleKeyDown"
    >
      <template v-if="optsToUse">
        <option v-for="(o, index) in optsToUse" :key="index" :value="o.value">
          {{ o.label }}
        </option>
      </template>
    </select>
  </div>
</template>
<script>
import columnProps from "../mixins/columnPropsMixin";
export default {
  mixins: [columnProps],
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      text: "",
    };
  },
  mounted() {
    this.onRendered(() => {
      const inputEl = this.$el.querySelector("input");
      inputEl?.focus();
    }, this.cell);
    this.text = this.data[this.cell.field] || "";
  },
  methods: {
    parseInputType(providedType) {
      if (providedType === "date-time") {
        providedType = "datetime-loca";
      }
      return providedType;
    },
    selectTextOnFocus() {
      const inputEl = this.$el.querySelector("input");
      if (inputEl && inputEl.select) {
        inputEl.select();
      }
    },
    handleChange(e) {
      let value = e.target.value;
      this.text = value;

      if (this.extraData?.modifier) {
        value = this.extraData?.modifier(value);
      }
      this.onSuccess(value, value, this.cell);
    },
    handleFocus() {
      setTimeout(() => {
        this.selectTextOnFocus();
      }, 0);
    },
    handleBlur() {
      if (this.isRowEditing) {
        let value = this.text;
        if (this.extraData?.modifier) {
          value = this.extraData?.modifier(value);
        }
        this.onSuccess(value, value, this.cell);
      }
    },
  },
  computed: {
    optsToUse() {
      let opts = [];

      if (this.extraData?.opts && this.extraData?.opts.length) {
        opts = this.extraData?.opts;
      }
      return opts;
    },
  },
};
</script>
<style scoped>
.input-cell {
  width: 100%;
}

.input-cell select {
  width: 100%;
}
</style>
