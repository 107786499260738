import Storage from "@/utils/storage";

const COLOR_THEME_KEY = "app-color-theme";

const DEFAULT_APP_COLOR_THEME = "ocean";

/**
 * Store app color theme in local storage
 * @param {string} colorTheme Color theme
 */
export const setAppColorTheme = (colorTheme) => {
  if (colorTheme) {
    document.documentElement.setAttribute("data-color-theme", colorTheme);
    Storage.set(COLOR_THEME_KEY, colorTheme);
  }
};

/**
 * Get stored app color theme
 * @returns {string}
 */
export const getStoredAppColorTheme = () => {
  return Storage.get(COLOR_THEME_KEY) || DEFAULT_APP_COLOR_THEME;
};

/**
 *
 * @param {{[string]:*}} userData User app data
 * @returns {string}
 */
export const getAppColorThemeFromUserData = (userData) => {
  return userData?.appColorTheme || DEFAULT_APP_COLOR_THEME;
};
