<template>
  <DialogLayout label="Account">
    <template #content>
      <div>
        <p>Name:</p>
        <v-text-field label="Name" v-model="userName"></v-text-field>
        <v-btn color="primary" v-on:click="updateDisplayName()">
          Update Name
        </v-btn>
        <p v-if="userNameSuccess">Display Name Updated Successfully</p>
        <p v-if="userNameSuccess">Failed to Update Display Name</p>
        <v-divider></v-divider>
      </div>
      <div>
        <p>Email:</p>
        <p>
          {{ userEmail }}
        </p>
        <v-btn v-if="!emailVerified" color="primary" v-on:click="verifyEmail()">
          Verify Email
        </v-btn>
        <v-divider></v-divider>
      </div>
      <div>
        <p>Password:</p>
        <v-btn color="primary" v-on:click="resetPassword()">
          Reset Password
        </v-btn>
        <p v-if="passwordResetSent">Password Reset Sent To: {{ userEmail }}</p>
        <p v-if="passwordResetFailed">
          Failed to Send Password Reset - Try Again
        </p>
        <v-divider></v-divider>
      </div>
    </template>

    <template #actions>
      <!-- <v-spacer /> -->
      <!-- <v-btn class="mr-4" @click="saveSettings"> submit </v-btn> -->
      <v-btn @click="closeDialog"> Close </v-btn>
    </template>
  </DialogLayout>
</template>

<script>
import firebase from "@/services/firebase";
import { resourceDisplayOpts } from "@/data/resourceSettingsConfig";
import { statusBtnDisplayOpts } from "@/data/taskSettingsConfig";
import { mapActions, mapGetters } from "vuex";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import isEmpty from "lodash/isEmpty";
import DialogLayout from "./components/DialogLayout.vue";
const settingsModeLabels = {
  0: "Account Settings",
  1: "Resource Settings",
};
export default {
  name: "AccountDialog",
  mixins: [userDetailsMixin],
  components: {
    DialogLayout,
  },
  props: {},
  data() {
    return {
      userName: "",
      userEmail: "",
      emailVerified: false,
      userNameSuccess: false,
      userNameFailure: false,
      passwordResetSent: false,
      passwordResetFailed: false,
      user: {},
    };
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!isEmpty(user)) {
        this.user = user;

        this.fillUserData(user);
      }
    });
  },
  methods: {
    ...mapActions(["toggleSettingsDialog"]),
    fillUserData(user) {
      this.userName = user.displayName;

      this.userEmail = user.email;
      this.emailVerified = user.emailVerified;
    },
    updateDisplayName() {
      this.user
        .updateProfile({
          displayName: this.userName,
        })
        .then(
          function () {
            this.userNameSuccess = true;
          },
          function () {
            this.userNameFailure = true;
          }
        );
    },
    verifyEmail() {
      this.user.sendEmailVerification();
    },
    resetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.userEmail)
        .then(() => {
          this.passwordResetSent = true;
          console.debug("recover password successful!");
        })
        .catch((error) => {
          this.passwordResetFailed = true;
          console.debug("recover password failed!");
          console.debug(error);
        });
    },
    async saveSettings() {
      let showSuccessMsg = false;

      this.closeDialog();

      if (showSuccessMsg) {
        this.showToast({ message: "Changes saved", color: "green" });
      }
    },
    closeDialog() {
      this.toggleSettingsDialog();
    },
    ...mapActions("toast", ["showToast"]),
  },
  computed: {
    displayOpts() {
      return resourceDisplayOpts;
    },
    statusBtnDisplayOpts() {
      return statusBtnDisplayOpts;
    },
    ...mapGetters(["resourceSettings", "isSettingsOpen", "taskSettings"]),
  },
  watch: {},
};
</script>
<style scoped>
.menu-opts-wrapper {
  background: #f9f9f9;
}

.selector-label {
  margin-bottom: 0px;
}

.show-underline-checkbox {
  align-items: center;
}
.show-underline-checkbox >>> .v-input__control {
  flex: 0;
}
</style>
