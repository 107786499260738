<template>
  <div class="select-wrapper">
    <v-select
      :items="itemTypeOpts"
      item-value="key"
      :value="value"
      :attach="attach"
      class="item-type-input"
      :placeholder="placeholder"
      :menu-props="menuProps"
      :width="width"
      @change="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="handleKeyDown"
      hide-details
      ref="select"
    >
      <template v-slot:selection="{ item }">
        <slot name="selection-content" :item="item">
          <v-icon
            :size="getIconSize(item)"
            style="width: 100%"
            class="mt-0 ml-0"
            :color="selectedOptIconColor"
          >
            {{ item.icon }}
          </v-icon>
        </slot>
      </template>
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-on="on" v-bind="attrs" :class="createItemClasses(item)">
          <v-icon
            :color="optItemIconColor"
            class="mr-3"
            style="width: 33px"
            :size="getIconSize(item)"
          >
            {{ item.icon }}
          </v-icon>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import {
  taskTypeOpts,
  taskTypeOptsWithoutNoteOpt,
  itemTypeOpts,
} from "@/data/taskTypes";
import { resourceTypeOptsList } from "@/data/resources";
import isEmpty from "lodash/isEmpty";

export default {
  data() {
    return {
      menuProps: {
        disableKeys: true,
        auto: true,
      },
    };
  },
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
      default: "task",
    },
    hideNoteOpt: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "Select Item Type",
    },
    extraMenuProps: {
      type: Object,
    },
    attach: {
      type: [String, Object],
    },
    width: {
      type: String,
    },
    selectedOptIconColor: {
      type: String,
      default: "black",
    },
    optItemIconColor: {
      type: String,
      default: "black",
    },
  },
  mounted() {
    if (!isEmpty(this.extraMenuProps)) {
      this.menuProps = {
        ...this.menuProps,
        ...this.extraMenuProps,
      };
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    openInput() {
      this.$refs.select.onClick(this.$refs.select);
    },
    blurInput() {
      this.$refs.select.blur();
    },
    createItemClasses(item) {
      return {
        "cti-list-item-sm": true,
        "dotted-select-item": !!item.useDottedBorder,
      };
    },
    getIconSize(item) {
      return item.displayIconSize || 18;
    },
  },
  computed: {
    itemTypeOpts() {
      let list;

      if (this.type === "task") {
        list = this.hideNoteOpt ? taskTypeOptsWithoutNoteOpt : taskTypeOpts;
      } else if (this.type === "note") {
        list = itemTypeOpts;
      } else if (this.type === "resource") {
        list = resourceTypeOptsList;
      }

      return list;
    },
  },
};
</script>

<style scoped>
.item-type-input {
  height: 33px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
}
.v-select >>> .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

.v-select >>> .v-select__slot {
  height: 33px;
}

.v-select >>> .v-select__selections {
  height: 33px;
}

.v-select >>> .v-input__icon--append {
  height: 10px !important;
  width: 10px !important;
  min-width: unset !important;
}

.v-select >>> .v-input__append-inner {
  align-self: center !important;
}

.v-select >>> .v-input__icon--append .v-icon {
  height: 10px !important;
  width: 10px !important;
  min-width: auto !important;
  font-size: 24px !important;
}
</style>
