<template>
  <v-dialog :value="isLoading" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Updating Information
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["isLoading"],
};
</script>
