import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-cell-wrapper"},[(!_vm.showSnoozeBtn)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"task-action-btn",attrs:{"icon":"","small":"","tabindex":"-1","disabled":_vm.isCellEditDisabled},on:{"click":_vm.handleCompleteBtnClick}},'v-btn',attrs,false),on),[_c(VIcon,{class:_vm.completeBtnProps.btnClasses,attrs:{"size":_vm.completeBtnProps.size,"color":_vm.btnColor}},[_vm._v(" "+_vm._s(_vm.completeBtnProps.btnIcon)+" ")])],1)]}}],null,false,1997812058)},[_c('span',[_vm._v(_vm._s(_vm.completeBtnProps.btnText))])]):[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","tabindex":"-1"},on:{"click":_vm.toggleReminder}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.pauseBtnIconData.color,"size":"17"}},[_vm._v(_vm._s(_vm.pauseBtnIconData.icon))])],1)]}}])},[_c('span',[_vm._v(" Snooze this "),_c('span',{staticClass:"reminder-date-text"},[_vm._v(_vm._s(_vm.pauseBtnIconData.formattedDate))]),_vm._v(" task until tomorrow ")])])],(_vm.showEditBtn)?_c('EditBtn',{attrs:{"cssClass":_vm.editBtnClassNames,"btnText":_vm.editPenLabel,"btnIcon":_vm.editBtnIcon,"btnIconSize":_vm.editBtnIconSize,"btnIconColor":_vm.greyIconColor},on:{"click":_vm.editTask}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }