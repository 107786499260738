export const RESOURCE_CATEGORY_PROPS_TO_RESTORE = [
  "name",
  "order",
  "parentCatKey",
];

export const TASK_CATE_PROPS_ALLOWED_FOR_PARSE = [
  {
    field: "title",
    key: "name",
    type: "string",
  },
  {
    field: "order",
    type: "number",
    key: "order",
  },
  {
    field: "parentKey",
    type: "string",
    key: "parentCatKey",
  },
  {
    field: "deleted",
    type: "boolean",
    key: "deleted",
  },
];
export const RESOURCE_CATE_PROPS_REQUIRES_CONFIG = ["order", "parentCatKey"];
export const sortOpts = [
  [
    {
      column: "tag",
      order: "asc",
    },

    {
      column: "hashedPath",
      order: "asc",
    },
  ],
];

export const sortForResourceList = [
  {
    column: "tag",
    order: "asc",
  },
];

export const groupOpts = ["hashedParentCatePath"];

export const RESOURCE_PROPS_FOR_FORM = [
  "title",
  "descr",
  "catId",
  "order",
  "type",
  "tag",
];
export const RESOURCE_PROPS_TO_RESTORE = [
  "title",
  "descr",
  "catId",
  "order",
  "type",
  "tag",
];
export const RESOURCE_PROPS_TO_REMOVE = [
  "actions",
  "editing",
  "fullParentCatePath",
  "hashedParentCatePath",
  "createdAsDate",
  "modifiedAsDate",
  "row-handler",
  "saveBtn",
  "selected",
  "createdFormatted",
  "modifiedFormatted",
];
