import EventEmitter from "@/helpers/eventEmitter";

import { TRIGGER_USER_ACTION_EVENT } from "@/variables/events";
import { mapActions } from "vuex";
const mixin = {
  methods: {
    ...mapActions("editForm", ["closeEditTaskDialog"]),
    getNavBarRef() {
      return this.$refs.viewWrapper.getNavBarRef();
    },
    getAppBarRef() {
      return this.$refs.viewWrapper.getAppBar();
    },
    setUserActionListener() {
      EventEmitter.on(TRIGGER_USER_ACTION_EVENT, this.handleUserAction);
    },
    removeUserActionListener() {
      EventEmitter.off(TRIGGER_USER_ACTION_EVENT, this.handleUserAction);
    },
    triggerCalDate(dateVal) {
      const calComp = this.$refs.sideCompWrapper.$el;
      if (calComp) {
        const calDateBtn = calComp.querySelector(
          `.cal-day[data-action-value="${dateVal}"]`
        );

        if (calDateBtn) {
          calDateBtn.click();
        }
      }
    },
    triggerResourceTypeChange(val) {
      const calComp = this.$refs.sideCompWrapper.$el;

      if (calComp) {
        const changeBtn = calComp.querySelector(
          `.resource-type-selector-wrapper button[data-action-value="${val}"]`
        );

        changeBtn?.click();
      }
    },
    triggerTreeNodeSelect(nodeId) {
      const treeViewEl = this.$refs.sideCompWrapper;
      if (treeViewEl) {
        treeViewEl.setNodeAndGetData(nodeId, true, true);
        // treeViewEl.handleNodesData([projectId]);
        // treeViewEl.changeView("1");
      }
    },
    callAppBarMethod(actionData) {
      const { type, value } = actionData;
      const appBar = this.getAppBarRef();
      switch (type) {
        case "nav-route-change-by-app-bar":
          appBar.goTo(value);
          break;
        case "search":
          appBar.focusOnSearchInput();
          break;
        case "data-change":
          appBar.callDataAction(value);
          break;
      }
    },
    callNavBarMethod(actionData) {
      const { type, value } = actionData;
      const navBar = this.getNavBarRef();
      switch (type) {
        case "nav-route-change":
          navBar.goTo(value);
          break;
      }
    },
    openResourceInfo(resourceId) {
      const listWrapper = this.$refs.tableWrapper;
      if (listWrapper) {
        listWrapper.openResourceInfoForm(resourceId);
      }
    },
    handleUserAction(actionData) {
      const { type, value } = actionData;
      switch (type) {
        case "nav-route-change-by-app-bar":
        case "search":
        case "data-change":
          this.callAppBarMethod(actionData);
          break;
        case "nav-route-change":
          this.callNavBarMethod(actionData);
          break;
        case "cal-month-change":
          this.$refs.sideCompWrapper.changeCalMonth(value);
          break;
        case "cal-date-select":
          this.triggerCalDate(value);
          break;
        case "project-change":
        case "notes-subject-change":
        case "resource-category-change":
          this.triggerTreeNodeSelect(value);
          break;
        case "project-category-change":
          this.closeEditTaskDialog();
          this.triggerTreeNodeSelect(value);
          break;
        case "open-resource-info":
          this.openResourceInfo(value);
          break;
        case "resource-type-change":
          this.triggerResourceTypeChange(value);
      }
    },
  },
};

export default mixin;
