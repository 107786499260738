import DatabaseInterface from "@/services/DatabaseInterface";
import {
  getUserActions,
  CATEGORY_ACTIONS,
  PROJECT_ACTIONS,
  RESOURCE_CATEGORY_ACTIONS,
  DIRECTORY_ACTIONS,
} from "@/helpers/actionHistory";
import {
  catTypes,
  typesOfCategories,
  typesOfProjects,
} from "@/variables/categories";
import removeProps from "@/utils/removeProps";
import { cloneDeep, isEmpty } from "lodash";
import { getCurrDate } from "./dates";
import store from "@/store";
import createUniqueId from "@/utils/createUniqueId";
import { getTaskTreeView } from "./tasks";

export const addCategories = async (
  categoryData,
  userId,
  recordAction = true
) => {
  if (!userId) {
    userId = store.getters["user/userInfo"]?.uid;
  }

  if (!Array.isArray(categoryData)) categoryData = [categoryData];
  const categoriesData = categoryData.reduce((a, v) => {
    a[v.key] = v;
    return a;
  }, {});
  DatabaseInterface.addTo(`/${userId}/categories/`, categoriesData);

  if (recordAction) {
    let actionToStore = CATEGORY_ACTIONS.BATCH_ADD;
    if (categoryData.length === 1) {
      actionToStore = CATEGORY_ACTIONS.ADD;
      categoryData = categoryData[0];
    }
    getUserActions().addCategoryAction({
      data: categoryData,
      type: actionToStore,
    });
  }
};

export const addSubCategory = async (
  subCatData,
  userId,
  recordAction = true
) => {
  // const clonedAllProjects = cloneDeep(allProjects);
  // const clonedAllCategories = cloneDeep(allCategories);

  if (!Array.isArray(subCatData)) subCatData = [subCatData];
  const subCategoryData = subCatData.reduce((a, v) => {
    a[v.key] = v;
    return a;
  }, {});
  DatabaseInterface.addTo(`/${userId}/projects/`, subCategoryData);
  if (recordAction) {
    let actionToStore = CATEGORY_ACTIONS.BATCH_ADD;
    if (subCatData.length === 1) {
      actionToStore = CATEGORY_ACTIONS.ADD;
      subCatData = subCatData[0];
    }
    getUserActions().addCategoryAction({
      data: subCatData,
      type: actionToStore,
    });
  }
};

// export const removeSubCategories = (
//   subCatData,
//   userId,
//   recordAction = true
// ) => {

// };

export const handleAddCategoryAction = async (
  catData,
  userId,
  recordAction = true
) => {
  let subCategoriesList = [];
  let categoriesList = [];
  if (Array.isArray(catData)) {
    catData.forEach((cat) => {
      // cat.key = createUniqueId();
      if (cat.type === "category") {
        categoriesList.push(cat);
      } else if (cat.type === "subcategory") {
        subCategoriesList.push(cat);
      }
    });
  } else {
    // catData.key = createUniqueId();
    if (catData.type === "category") {
      categoriesList.push(catData);
    } else if (catData.type === "subcategory") {
      subCategoriesList.push(catData);
    }
  }

  if (categoriesList && categoriesList.length) {
    await addCategories(categoriesList, userId, recordAction);
  }

  if (subCategoriesList && subCategoriesList.length) {
    await addSubCategory(subCategoriesList, userId, recordAction);
  }
};

export const removeCategories = async (
  categoryData,
  userId,
  recordAction = true
) => {
  if (!userId) {
    userId = store.getters["user/userInfo"]?.uid;
  }
  if (!Array.isArray(categoryData)) categoryData = [categoryData];
  let categoriesKeysList = [];
  let subCategoriesKeysList = [];
  categoryData.forEach((cat) => {
    if (cat.type === "category") {
      categoriesKeysList.push(cat.key);
    } else if (cat.type === "subcategory") {
      subCategoriesKeysList.push(cat.key);
    }
  });

  if (categoriesKeysList && categoriesKeysList.length) {
    const currCategoriesSnapshot = await DatabaseInterface.get(
      `/${userId}/categories/`
    ).get();
    let currCategories = currCategoriesSnapshot.val();
    currCategories = removeProps(currCategories, categoriesKeysList);
    await DatabaseInterface.add(`/${userId}/categories/`, currCategories);
  }

  if (subCategoriesKeysList && subCategoriesKeysList.length) {
    const currCategoriesSnapshot = await DatabaseInterface.get(
      `/${userId}/projects/`
    ).get();
    let currCategories = currCategoriesSnapshot.val();
    currCategories = removeProps(currCategories, subCategoriesKeysList);
    await DatabaseInterface.add(`/${userId}/projects/`, currCategories);
  }

  if (recordAction) {
    let actionToStore = CATEGORY_ACTIONS.BATCH_DELETE;
    if (categoryData.length === 1) {
      actionToStore = CATEGORY_ACTIONS.DELETE;
      categoryData = categoryData[0];
    }
    getUserActions().addCategoryAction({
      data: categoryData,
      type: actionToStore,
    });
  }
};

export const createUpdateData = (dataToUpdate, keyPath, updateObj) => {
  const propsToUpdate = Object.keys(dataToUpdate);

  propsToUpdate.forEach((prop) => {
    updateObj[`${keyPath}/${prop}`] = dataToUpdate[prop];
  });
};

export const reorderOrMoveCatOrProject = (
  items,
  updatedItem,
  userId,
  recordAction = true
) => {
  if (!Array.isArray(items)) items = [items];
  const updates = {};

  items.forEach((i) => {
    const keyPath = `/${catTypes[i.type]}/${i.key}`;
    createUpdateData(i.updates, keyPath, updates);
  });

  if (!isEmpty(updates)) {
    DatabaseInterface.update(updates, userId);
  }

  if (recordAction) {
    getUserActions().addCategoryAction({
      data: [updatedItem],
      type: CATEGORY_ACTIONS.REORDER_OR_MOVE,
    });
  }
};
export const updateCatOrSubCatOrProject = async (
  itemsToUpdate,
  userId,
  recordAction = true
) => {
  if (!Array.isArray(itemsToUpdate)) itemsToUpdate = [itemsToUpdate];
  let updates = {};
  let projectEditedList = [];
  let categoriesEditedList = [];
  itemsToUpdate.forEach((i) => {
    const keyPath = `/${catTypes[i.type]}/${i.key}`;
    createUpdateData(i.updates, keyPath, updates);

    if (!isEmpty(i.editedData)) {
      if (i.type === "project" || i.type === "subcategory") {
        projectEditedList.push({
          ...i.editedData,
          key: i.key,
        });
      } else if (i.type === "category") {
        categoriesEditedList.push({
          ...i.editedData,
          key: i.key,
        });
      }
    }
  });

  if (!isEmpty(updates)) {
    await DatabaseInterface.update(updates, userId);
  }

  if (recordAction) {
    if (projectEditedList && projectEditedList.length) {
      let actionToUse = PROJECT_ACTIONS.BATCH_EDIT;

      if (projectEditedList.length === 1) {
        actionToUse = PROJECT_ACTIONS.EDIT;
        projectEditedList = projectEditedList[0];
      }
      getUserActions().addProjectAction({
        data: projectEditedList,
        type: actionToUse,
      });
    }

    if (categoriesEditedList && categoriesEditedList.length) {
      let actionToUse = CATEGORY_ACTIONS.BATCH_EDIT;

      if (categoriesEditedList.length === 1) {
        actionToUse = PROJECT_ACTIONS.EDIT;
        categoriesEditedList = categoriesEditedList[0];
      }
      getUserActions().addCategoryAction({
        data: categoriesEditedList,
        type: actionToUse,
      });
    }
  }
};

const PROPS_ALLOWED_TO_RESTORE = [
  "title",
  "order",
  "subcategory",
  "category",
  "priority",
  "tasks",
  "hidden",
  "deleted",
];
export const PROPS_REQUIRES_CONFIG = ["subcategory", "order", "category"];

export const CAT_DATA_PROPS = ["priority"];
export const createUpdateAndEditedForCatOrProj = (newData, currData) => {
  let updates = {};
  let editedData = {};
  let changedProps = {};
  const keysToUpdate = Object.keys(newData);

  keysToUpdate.forEach((key) => {
    if (PROPS_ALLOWED_TO_RESTORE.indexOf(key) >= 0) {
      if (key === "order") {
        editedData[key] = currData[key];
        if (typeof newData[key] === "number") {
          updates[key] = newData[key];
          changedProps[key] = newData[key];
        }
      } else {
        if (key === "hidden" || key === "deleted") {
          updates[key] = newData[key] || false;
          changedProps[key] = newData[key] || false;
          editedData[key] = currData[key] || false;
        } else {
          updates[key] = newData[key] || "";
          changedProps[key] = newData[key] || "";
          editedData[key] = currData[key] || "";
        }
      }
    }
  });
  updates.modified = getCurrDate();
  editedData.changedProps = changedProps;
  editedData.key = currData.key;

  return {
    updates,
    editedData,
  };
};

export const isCatChildOrGrandChild = (childNodeId, nodeIdToCheck) => {
  const allCategories = store.getters["task/categories"];
  const allSubCategories = store.getters["task/projects"];
  let nodeIsChildOrGrandChild = false;

  let foundParentInSub = false;
  let foundParentInCatgories = false;
  if (allSubCategories[childNodeId]) {
    const childDirData = allSubCategories[childNodeId];
    let parentKey = childDirData.key || "";

    while (!foundParentInSub) {
      if (!allSubCategories[parentKey]) {
        break;
      }

      const parentDirData = allSubCategories[parentKey];
      if (
        parentDirData.key === nodeIdToCheck ||
        parentDirData.subcategory === nodeIdToCheck
      ) {
        foundParentInSub = true;
      }

      parentKey = parentDirData.subcategory;
    }

    nodeIsChildOrGrandChild = foundParentInSub;
  }

  if (!nodeIsChildOrGrandChild && allCategories[childNodeId]) {
    const childDirData = allCategories[childNodeId];
    let parentKey = childDirData.key || "";
    while (!foundParentInCatgories) {
      if (!allCategories[parentKey]) {
        break;
      }

      const parentDirData = allCategories[parentKey];

      if (parentDirData.key === nodeIdToCheck) {
        foundParentInCatgories = true;
      }

      parentKey = parentDirData.subcategory;
    }
    nodeIsChildOrGrandChild = foundParentInCatgories;
  }

  return nodeIsChildOrGrandChild;
};

export const createRestoreDataForCategory = (catData) => {
  const updates = {};
  let updateType;
  const propsToRestore = Object.keys(catData);

  propsToRestore.forEach((prop) => {
    if (PROPS_ALLOWED_TO_RESTORE.indexOf(prop) >= 0) {
      updates[prop] = catData[prop] || "";
      if (PROPS_REQUIRES_CONFIG.indexOf(prop) >= 0) {
        updateType = "parent-changed";
        if (prop === "order") {
          updates[prop] = catData[prop];
        }
      } else if (CAT_DATA_PROPS.indexOf(prop) >= 0) {
        updateType = "extra-details";
        updates[prop] = catData[prop];
      } else if (prop === "title") {
        updateType = "rename";
      } else if (prop === "hidden" || prop === "deleted") {
        updateType = "visibility";
        updates[prop] = catData[prop];
      }
    }
  });

  updates.modified = getCurrDate();

  return {
    updates,
    type: updateType,
  };
};

export const createCatOrSubCatOrProjectDataForTree = (
  list,
  currProjects,
  currCategories
) => {
  const clonedCategories = cloneDeep(currCategories);
  const clonedProjects = cloneDeep(currProjects);
  if (list && list.length) {
    list.forEach((i) => {
      let listToUse = {};
      if (typesOfCategories.indexOf(i.type) >= 0) {
        listToUse = clonedCategories;
      } else if (typesOfProjects.indexOf(i.type) >= 0) {
        listToUse = clonedProjects;
      }
      if (listToUse[i.key]) {
        listToUse[i.key] = {
          ...listToUse[i.key],
          ...i.updates,
        };
      }
    });
  }

  return {
    categories: clonedCategories,
    projects: clonedProjects,
  };
};

export const createCategoryData = (type = "category") => {
  const createdData = {
    key: createUniqueId(),
    title: "New Category",
    hasAttribute: { class: "category" },
    type: "category",
    // completed: false,
    created: getCurrDate(),
    description: "New Category Description",
    icon: "mdi-circle",
    modified: getCurrDate(),
    open: false,
    subcategory: "",
    projects: [],
  };

  if (type === "subcategory") {
    createdData.title = "New Folder";
    createdData.hasAttribute = { class: "subcategory" };
    createdData.type = "subcategory";
    createdData.completed = false;
    createdData.description = "New Folder";
    createdData.priority = "";
    createdData.open = true;
  } else if (type === "project") {
    createdData.title = "New Project";
    createdData.hasAttribute = { class: "project" };
    createdData.type = "project";
    createdData.completed = false;
    createdData.description = "New Project Description";
    createdData.priority = "";
    createdData.tasks = [];
  }

  return createdData;
  // key: nodeId,
  // title: "New Category",
  // hasAttribute: { class: "category" },
  // type: "category",
  // order: currTree.length,
  // open: false,
  // // completed: false,
  // created: getCurrDate(),
  // description: "New Category Description",
  // icon: "mdi-circle",
  // modified: getCurrDate(),
  // children: [],
  // // priority: "priority",
  // projects: [],
};

export const addCatItem = (catItemData, userId, recordAction = true) => {
  const catItemType = catItemData.type;
  let listToUse = "";
  let actionTypeToStore = "";
  let actionTypeToCall = "";
  if (typesOfCategories.indexOf(catItemType) >= 0) {
    listToUse = "categories/";
    actionTypeToCall = "addCategoryAction";
    actionTypeToStore = CATEGORY_ACTIONS.ADD;
  } else if (typesOfProjects.indexOf(catItemType) >= 0) {
    actionTypeToStore = PROJECT_ACTIONS.ADD;
    actionTypeToCall = "addProjectAction";
    if (catItemType === "subcategory") {
      actionTypeToStore = CATEGORY_ACTIONS.ADD;
      actionTypeToCall = "addCategoryAction";
    }
    listToUse = "projects/";
  }

  DatabaseInterface.addTo(`${userId}/${listToUse}`, {
    [catItemData.key]: catItemData,
  });

  if (recordAction) {
    getUserActions()[actionTypeToCall]({
      data: catItemData,
      type: actionTypeToStore,
    });
  }
};

export const storeCatEditAction = (catData, treeName = "task-category") => {
  if (!Array.isArray(catData)) catData = [catData];

  if (treeName === "task-category") {
    let projectEditedList = [];
    let categoriesEditedList = [];

    catData.forEach((catItem) => {
      if (catItem.type === "project" || catItem.type === "subcategory") {
        projectEditedList.push({
          ...catItem.editedData,
          key: catItem.key,
        });
      } else if (catItem.type === "category") {
        categoriesEditedList.push({
          ...catItem.editedData,
          key: catItem.key,
        });
      }
    });

    if (projectEditedList && projectEditedList.length) {
      let actionToUse = PROJECT_ACTIONS.BATCH_EDIT;

      if (projectEditedList.length === 1) {
        actionToUse = PROJECT_ACTIONS.EDIT;
        projectEditedList = projectEditedList[0];
      }
      getUserActions().addProjectAction({
        data: projectEditedList,
        type: actionToUse,
      });
    }

    if (categoriesEditedList && categoriesEditedList.length) {
      let actionToUse = CATEGORY_ACTIONS.BATCH_EDIT;

      if (categoriesEditedList.length === 1) {
        actionToUse = PROJECT_ACTIONS.EDIT;
        categoriesEditedList = categoriesEditedList[0];
      }

      getUserActions().addCategoryAction({
        data: categoriesEditedList,
        type: actionToUse,
      });
    }
  }

  if (treeName === "resource-category") {
    let actionToUse = RESOURCE_CATEGORY_ACTIONS.BATCH_EDIT;

    if (catData.length === 1) {
      actionToUse = RESOURCE_CATEGORY_ACTIONS.EDIT;
      catData = catData[0];
    }

    getUserActions().addResourceCateAction({
      data: catData,
      type: actionToUse,
    });
  }

  if (treeName === "subjects") {
    if (!Array.isArray(catData)) catData = [catData];
    let actionType = DIRECTORY_ACTIONS.BATCH_EDIT;
    if (catData.length === 1) {
      actionType = DIRECTORY_ACTIONS.EDIT;
      catData = catData[0];
    }

    getUserActions().addDirAction({
      type: actionType,
      data: catData,
    });
  }
};

export const storeCatAddAction = (catData, treeName = "task-category") => {
  if (!Array.isArray(catData)) catData = [catData];

  if (treeName === "task-category") {
    let actionToStore = CATEGORY_ACTIONS.BATCH_ADD;
    if (catData.length === 1) {
      actionToStore = CATEGORY_ACTIONS.ADD;
      catData = catData[0];
    }
    getUserActions().addCategoryAction({
      data: catData,
      type: actionToStore,
    });
  }

  if (treeName === "resource-category") {
    let actionToStore = RESOURCE_CATEGORY_ACTIONS.BATCH_ADD;
    if (catData.length === 1) {
      actionToStore = RESOURCE_CATEGORY_ACTIONS.ADD;
      catData = catData[0];
    }
    getUserActions().addResourceCateAction({
      data: catData,
      type: actionToStore,
    });
  }

  if (treeName === "notes-subject") {
    let actionType = DIRECTORY_ACTIONS.BATCH_ADD;
    if (catData.length === 1) {
      actionType = DIRECTORY_ACTIONS.ADD;
      catData = catData[0];
    }
    getUserActions().addDirAction({
      type: actionType,
      data: catData,
    });
  }
};

export const storeCatRemoveAction = async (
  categoryData,
  treeName = "task-category"
) => {
  if (!Array.isArray(categoryData)) categoryData = [categoryData];

  if (treeName === "task-category") {
    let actionToStore = CATEGORY_ACTIONS.BATCH_DELETE;
    if (categoryData.length === 1) {
      actionToStore = CATEGORY_ACTIONS.DELETE;
      categoryData = categoryData[0];
    }

    getUserActions().addCategoryAction({
      data: categoryData,
      type: actionToStore,
    });
  }

  if (treeName === "resource-category") {
    let actionToStore = RESOURCE_CATEGORY_ACTIONS.BATCH_DELETE;
    if (categoryData.length === 1) {
      actionToStore = RESOURCE_CATEGORY_ACTIONS.DELETE;
      categoryData = categoryData[0];
    }
    getUserActions().addResourceCateAction({
      data: categoryData,
      type: actionToStore,
    });
  }

  if (treeName === "subjects") {
    if (!Array.isArray(categoryData)) categoryData = [categoryData];
    let actionType = DIRECTORY_ACTIONS.BATCH_DELETE;
    if (categoryData.length === 1) {
      actionType = DIRECTORY_ACTIONS.DELETE;
      categoryData = categoryData[0];
    }
    getUserActions().addDirAction({
      type: actionType,
      data: categoryData,
    });
  }

  return true;
};

export const storeCatReorderOrMoveAction = (
  categoryData,
  treeName = "task-category"
) => {
  if (treeName === "task-category") {
    getUserActions().addCategoryAction({
      data: [categoryData],
      type: CATEGORY_ACTIONS.REORDER_OR_MOVE,
    });
  }

  if (treeName === "resource-category") {
    getUserActions().addResourceCateAction({
      data: categoryData,
      type: RESOURCE_CATEGORY_ACTIONS.REORDER_OR_MOVE,
    });
  }

  if (treeName === "subjects") {
    getUserActions().addDirAction({
      type: DIRECTORY_ACTIONS.REORDER_OR_MOVE,
      data: categoryData,
    });
  }
};

// export const addResourceCateInTasksCate = (resrcCateData, userId) => {

//   await
// }

export const getTaskCateByRefKey = (refKey) => {
  const taskCategoriesMap = store.getters["task/categories"];
  const taskProjectsMap = store.getters["task/projects"];

  let taskCateData = Object.values(taskCategoriesMap).find(
    (c) => c.key === refKey
  );

  if (isEmpty(taskCateData)) {
    taskCateData = Object.values(taskProjectsMap).find((p) => p.key === refKey);
  }

  return taskCateData;
};

export const getChildrenOfTaskCate = (taskCate) => {
  const taskProjectsMap = store.getters["task/projects"];

  return Object.values(taskProjectsMap).filter(
    (proj) => proj.subcategory === taskCate || proj.category === taskCate
  );
};

export const storeCateInTasksCateData = async (
  taskCateData,
  type = "project",
  userId
) => {
  let storeKey;
  if (type === "project") {
    storeKey = "/projects/";
  }

  if (type === "category") {
    storeKey = "/categories/";
  }

  if (!isEmpty(taskCateData)) {
    const dbUpdates = {
      [`${storeKey}${taskCateData.key}`]: { ...taskCateData },
    };

    if (!isEmpty(dbUpdates)) {
      await DatabaseInterface.update(dbUpdates, userId);
    }
  }
};

/**
 * Make provided node id visible in task treeview
 * @param {string} nodeId Node Id
 */
export const makeNodeVisible = (nodeId) => {
  const activeTaskTreeViewData = getTaskTreeView();
  const inactiveTaskTreeViewData = getTaskTreeView("inactive");
  const catTreeView = activeTaskTreeViewData.treeInstance;
  const inactiveTree = inactiveTaskTreeViewData.treeInstance;

  setTimeout(() => {
    catTreeView?.ensureVisible(nodeId);
    inactiveTree?.ensureVisible(nodeId);
  }, 0);
};

export const closeTaskCategoriesInDb = async (expandedCategories) => {
  const taskCategoriesData = store.getters["task/categories"];
  const taskProjectsData = store.getters["task/projects"];
  const userId = store.getters["user/userInfo"]?.uid;
  if (!isEmpty(expandedCategories)) {
    const dbUpdates = expandedCategories.reduce((dbData, cate) => {
      if (taskCategoriesData[cate]) {
        dbData["/categories/" + cate + "/open"] = false;
      } else if (taskProjectsData[cate]) {
        dbData["/projects/" + cate + "/open"] = false;
      }
      return dbData;
    }, {});
    if (!isEmpty(dbUpdates)) {
      await DatabaseInterface.update(dbUpdates, userId);
    }
  }
};

export const closeTaskCateTreeview = async (providedExpandedNodes) => {
  const activeCatTreeView = getTaskTreeView()?.treeInstance;
  const inactiveTreeView = getTaskTreeView("inactive")?.treeInstance;
  const currExpandedNodes = cloneDeep(activeCatTreeView?.expandedNodes) || [];

  activeCatTreeView?.collapseAll();
  inactiveTreeView?.collapseAll();
  await closeTaskCategoriesInDb(
    !isEmpty(providedExpandedNodes) ? providedExpandedNodes : currExpandedNodes
  );
  return true;
};

/**
 * Create category data object for add
 * @param {{[string]:*}} providedCategoryData
 * @returns {{[string]:*}}
 */
export const createTaskCategoryDataForAdd = (providedCategoryData = {}) => {
  const nodeId = createUniqueId();
  const item = {
    key: providedCategoryData.key || nodeId,
    title: providedCategoryData.title || "New Category",
    hasAttribute: { class: "category" },
    type: "category",
    open: false,
    created: getCurrDate(),
    description: "New Category Description",
    icon: "mdi-circle",
    modified: providedCategoryData.modified || getCurrDate(),
    children: [],
    projects: [],
  };
  return item;
};
