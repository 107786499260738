<template>
  <div class="wrapper">
    <div v-if="data.addNew">
      <SaveAndCancelBtns @save="saveTask" @cancel="cancel" />
    </div>
  </div>
</template>
<script>
import SaveAndCancelBtns from "@/components/GridComponents/SaveAndCancelBtns.vue";
import taskHelpersMixin from "@/mixins/tasksHelpersMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import { createTaskId } from "@/helpers/tasks";
import { mapActions, mapGetters } from "vuex";
import { checkIfProjectHasNoPriorityByKey } from "@/helpers/projects";
export default {
  mixins: [columnPropsMixin, taskHelpersMixin],
  components: {
    SaveAndCancelBtns,
  },
  methods: {
    async saveTask() {
      const currData = this.cell.getData();
      const createdTaskKey = createTaskId();
      if (currData.taskType && currData.taskType === "note") {
        this.addTaskAsNote({
          ...currData,
          key: createdTaskKey,
        });
        return;
      }

      const selectedProject = currData.project;

      if (checkIfProjectHasNoPriorityByKey(selectedProject)) {
        this.openDialog({
          taskList: [{ ...currData, key: createdTaskKey }],
          isAddMode: true,
          areaId: selectedProject,
        });

        return;
      }

      currData.key = createdTaskKey;
      const addedTaskData = await this.addNewTaskEntry(currData);
      this.cell.api.removeNewRow();
      this.cell.api.triggerAddRow(addedTaskData);
    },
    cancel() {
      this.cell.api.removeNewRow();
    },
    ...mapActions("areaPrioritySetterData", ["openDialog"]),
  },
  computed: {
    ...mapGetters("task", ["projects"]),
  },
};
</script>
