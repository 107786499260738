// import { isValidDate, makeTimeFormat } from "@/helpers/dates";
import GoogleCalHelpers, {
  isGoogleCalSyncEnabled,
} from "@/helpers/googleCalHelpers";
import {
  addOrRemoveOrUpdateTasksInLocalTasksList,
  checkTaskIsAllDay,
  convertTaskKeyForGoogleCal,
  createDataForAddOrRemoveOrUpdateInGoogleCalByTaskData,
  // createTaskDataForGoogleCal,
  createDataForTasksDbFromEvents,
} from "@/helpers/tasks";
import DatabaseInterface from "@/services/DatabaseInterface";
// import { addHours, endOfDay } from "date-fns";
import { mapGetters, mapActions, mapMutations } from "vuex";
import store from "@/store";
import userDetailsMixin from "./userDetailsMixin";
import { getCurrDate, isValidDate } from "@/helpers/dates";
import { addHours, endOfDay } from "date-fns";
import { isEmpty } from "lodash";

const createTaskDbUpdateData = async (tasks, type) => {
  if (!Array.isArray(tasks)) tasks = [tasks];
  const updates = tasks.reduce((a, tData) => {
    a[`/tasks/${tData.key}/googleEventId`] =
      type === "add" ? convertTaskKeyForGoogleCal(tData.key) : null;

    a[`/tasks/${tData.key}/googleCalendarId`] =
      type === "add" ? GoogleCalHelpers.getStoredCalId() : "";
    return a;
  }, {});

  return updates;
};
export const addTasksToGoogleCal = async (
  tasks,
  createKey = true,
  verifyCalendar = true
) => {
  if (!Array.isArray(tasks)) tasks = [tasks];
  const tasksToAdd = [];
  tasks.forEach((taskData) => {
    // const { dueAsDate } = taskData;
    // if (isValidDate(new Date(dueAsDate))) {
    tasksToAdd.push({
      id: createKey ? convertTaskKeyForGoogleCal(taskData.key) : taskData.key,
      ...taskData.data,
    });
    // }
  });

  const { calendarCreated, calendarId } = await GoogleCalHelpers.addEvents(
    tasksToAdd,
    undefined,
    tasksToAdd.length > 1,
    verifyCalendar
  );

  if (verifyCalendar && calendarCreated) {
    updateCalendarIdInDb(calendarId);
  }
};

export const updateTasksInGoogleCal = async (
  tasks,
  createKey = true,
  verifyCalendar = true,
  calHasChanged
) => {
  if (!isGoogleCalSyncEnabled()) return;

  let calendarStatusRes = {};
  let addTasksInCal = false;
  let calIdToUpdate;
  if (verifyCalendar) {
    const storedCalendarId = GoogleCalHelpers.getStoredCalId();
    calendarStatusRes = await GoogleCalHelpers.checkAndSetupCalendar(
      storedCalendarId
    );
    if (calendarStatusRes.calendarCreated) {
      addTasksInCal = true;
      calIdToUpdate = calendarStatusRes.calendarId;
    }
  }

  if (calHasChanged) {
    calIdToUpdate = GoogleCalHelpers.getStoredCalId();
    addTasksInCal = true;
  }

  if (!Array.isArray(tasks)) tasks = [tasks];

  if (addTasksInCal) {
    tasks = tasks.reduce((list, taskD) => {
      if (!isEmpty(taskD.updates) && !isEmpty(taskD.updates.dataForAdd)) {
        const taskDataForAdd = taskD.updates.dataForAdd;
        list.push({
          id: createKey ? convertTaskKeyForGoogleCal(taskD.key) : taskD.key,
          ...taskDataForAdd,
        });
      }

      return list;
    }, []);
  } else {
    tasks = tasks.map((taskD) => ({
      key: createKey ? convertTaskKeyForGoogleCal(taskD.key) : taskD.key,
      updates: taskD.updates,
    }));
  }

  if (addTasksInCal) {
    await GoogleCalHelpers.addEvents(tasks, undefined, tasks.length > 1, false);
  } else {
    await GoogleCalHelpers.updateEvents(
      tasks,
      undefined,
      tasks.length > 1,
      false
    );
  }

  if (calIdToUpdate) {
    updateCalendarIdInDb(calIdToUpdate);
  }
};

export const removeTaskFromGoogleCal = async (
  tasksToRemove,
  _,
  verifyCalendar = true
) => {
  let resValue = false;
  if (!Array.isArray(tasksToRemove)) tasksToRemove = [tasksToRemove];
  if (tasksToRemove && tasksToRemove.length) {
    const taskIds = [];
    tasksToRemove.forEach((taskData) => {
      if (taskData.googleEventId) {
        taskIds.push(taskData.googleEventId);
      }
    });

    const { calendarCreated, calendarId } = await GoogleCalHelpers.removeEvents(
      taskIds,
      // calenderId,
      taskIds.length > 1,
      verifyCalendar
    );

    if (calendarCreated) {
      updateCalendarIdInDb(calendarId);
    }
    resValue = true;
  }

  return resValue;
};

export const addOrUpdateOrRemoveTasksInGoogleCal = async (
  { tasksToAdd, tasksToRemove, tasksToUpdate },
  addKeyToTasksDb = true
) => {
  if (!isGoogleCalSyncEnabled()) return;
  const userId = store.getters["user/userInfo"].uid;
  const storedCalendarId = GoogleCalHelpers.getStoredCalId();
  const { calendarCreated, calendarId } =
    await GoogleCalHelpers.checkAndSetupCalendar(storedCalendarId);

  // Will use it later
  // const isGoogleCalSyncEnabled = store.getters["isGoogleCalSyncEnabled"];
  if (tasksToAdd && tasksToAdd.length) {
    addTasksToGoogleCal(tasksToAdd, undefined, false);

    if (addKeyToTasksDb) {
      DatabaseInterface.update(
        createTaskDbUpdateData(tasksToAdd, "add"),
        userId
      );
      store.dispatch("task/addOrUpdateOrRemoveTasks", {
        tasksToUpdate: tasksToAdd.map((t) => ({
          key: t.key,
          updates: {
            googleEventId: convertTaskKeyForGoogleCal(t.key),
            googleCalendarId: GoogleCalHelpers.getStoredCalId(),
          },
        })),
      });
    }
    // this.addOrUpdateOrRemoveTasks({

    // });
  }

  if (tasksToUpdate && tasksToUpdate.length) {
    updateTasksInGoogleCal(tasksToUpdate, undefined, false, calendarCreated);
  }

  if (tasksToRemove && tasksToRemove.length) {
    removeTaskFromGoogleCal(tasksToRemove, undefined, false);
  }

  if (calendarCreated) {
    updateCalendarIdInDb(calendarId);
  }
};

export const restoreTasksInGoogleCal = async (tasks) => {
  if (!Array.isArray(tasks)) tasks = [tasks];
  const restoreList = [];
  tasks.forEach((taskData) => {
    if (taskData.googleEventId) {
      let createdData = { status: "confirmed" };

      if (!isEmpty(taskData.extraData)) {
        createdData = {
          ...createdData,
          ...taskData.extraData,
        };
      }
      restoreList.push({
        key: taskData.googleEventId,
        updates: {
          ...createdData,
        },
      });
    }
  });

  const { calendarCreated, calendarId } = await GoogleCalHelpers.updateEvents(
    restoreList
  );

  if (calendarCreated) {
    updateCalendarIdInDb(calendarId);
  }
};

/**
 * Update the provided  google calendar id in DB
 * @param {string} calendarId
 */
const updateCalendarIdInDb = async (calendarId) => {
  const userId = store.getters["user/userInfo"].uid;
  const dbUpdates = {
    "/googleCalSyncInfo/calendarId": calendarId,
  };
  DatabaseInterface.update(dbUpdates, userId);
};

const mixin = {
  mixins: [userDetailsMixin],
  methods: {
    ...mapActions("task", ["addOrUpdateOrRemoveTasks"]),
    ...mapActions("toast", ["showToast"]),
    ...mapActions(["setCalSync", "updateRootState", "setGoogleCalLastSync"]),
    ...mapMutations(["setLoader"]),
    addTasksToGoogleCal,
    // async addTasksToGoogleCal(tasks, createKey = true) {
    //   if (!Array.isArray(tasks)) tasks = [tasks];
    //   const tasksToAdd = [];
    //   tasks.forEach((taskData) => {
    //     // const { dueAsDate } = taskData;
    //     // if (isValidDate(new Date(dueAsDate))) {
    //     tasksToAdd.push({
    //       id: createKey
    //         ? convertTaskKeyForGoogleCal(taskData.key)
    //         : taskData.key,
    //       ...taskData.data,
    //     });
    //     // }
    //   });

    //   await GoogleCalHelpers.addEvents(tasksToAdd);
    // },
    updateTasksInGoogleCal,
    // async updateTasksInGoogleCal(tasks) {
    //   if (!Array.isArray(tasks)) tasks = [tasks];

    //   tasks = tasks.map((taskD) => ({
    //     key: convertTaskKeyForGoogleCal(taskD.key),
    //     updates: taskD.updates,
    //   }));

    //   await GoogleCalHelpers.updateEvents(tasks);
    // },
    // patchTasksInGoogleCal(tasksToPatch) {
    //   if (!Array.isArray(tasksToPatch)) tasksToPatch = [tasksToPatch];
    //   // eslint-disable-next-line no-unused-vars
    //   const tasksDataList = [];
    //   tasksToPatch.forEach((t) => {
    //     // eslint-disable-next-line no-unused-vars
    //     const { key, updates } = t;
    //   });
    // },
    addOrUpdateOrRemoveTasksInGoogleCal,
    updateTaskDataInGoogleCal(tasks, getRawData) {
      if (!Array.isArray(tasks)) tasks = [tasks];

      if (getRawData) {
        tasks = tasks.map((t) => ({
          key: t.key,
          updates: t.updates,
          currData: this.rawTasks.find((rT) => rT.key === t.key),
        }));
      }

      // console.debug("DJJJJJ", )
      const dataForGoogleCal =
        createDataForAddOrRemoveOrUpdateInGoogleCalByTaskData(tasks);
      this.addOrUpdateOrRemoveTasksInGoogleCal(dataForGoogleCal);
    },
    // async addOrUpdateOrRemoveTasksInGoogleCal(
    //   { tasksToAdd, tasksToRemove, tasksToUpdate },
    //   addKeyToTasksDb = true
    // ) {
    //   console.debug("FIMCMCMCM");
    //   if (tasksToAdd && tasksToAdd.length) {
    //     console.debug("I{DAYED", tasksToAdd);
    //     await this.addTasksToGoogleCal(tasksToAdd);
    //     DatabaseInterface.update(
    //       this.createTaskDbUpdateData(tasksToAdd, "add"),
    //       this.userInfo.uid
    //     );
    //     this.addOrUpdateOrRemoveTasks({
    //       tasksToUpdate: tasksToAdd.map((t) => ({
    //         key: t.key,
    //         updates: {
    //           googleEventId: convertTaskKeyForGoogleCal(t.key),
    //         },
    //       })),
    //     });
    //   }

    //   if (tasksToUpdate && tasksToUpdate.length) {
    //     console.debug("FUN CALLED", tasksToUpdate);
    //     this.updateTasksInGoogleCal(tasksToUpdate);
    //   }

    //   if (tasksToRemove && tasksToRemove.length) {
    //     this.removeTaskFromGoogleCal(tasksToRemove);
    //   }
    // },

    removeTaskFromGoogleCal,
    async syncCalendar(
      syncTimeStamp,
      useLastSync = true,
      useLastConnectDate,
      syncCurrTasks,
      verifyCalendar = true
    ) {
      this.setCalSync(true);

      let resStatus = "Error";
      try {
        let lastSync;
        let lastConnectedDate;
        if (useLastSync) {
          lastSync = this.googleCalLastSync;
        }

        if (useLastConnectDate) {
          lastConnectedDate =
            this.googleCalLastConnected || getCurrDate("default");
        }

        let calendarId = GoogleCalHelpers.getStoredCalId();
        let calendarStatusRes = {};

        if (verifyCalendar) {
          calendarStatusRes = await GoogleCalHelpers.checkAndSetupCalendar(
            calendarId
          );
        }

        const { events, status } = await GoogleCalHelpers.list(
          undefined,
          lastSync,
          false
        );

        const dbUpdates = {};
        if (calendarStatusRes?.calendarCreated) {
          dbUpdates["/googleCalSyncInfo/calendarId"] =
            calendarStatusRes.calendarId;
        }
        if (status === "OK") {
          const createdLastSync = syncTimeStamp
            ? syncTimeStamp
            : new Date().toISOString();

          const res = await createDataForTasksDbFromEvents(
            events,
            lastConnectedDate,
            syncCurrTasks,
            lastSync
          );

          if (res.status === "OK") {
            this.setGoogleCalLastSync(createdLastSync);
            resStatus = "OK";
          }
        }

        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      } catch (error) {
        this.setCalSync(false);
        console.error(`Error in syncCalendar method`, error);

        /**
         * Disabled google sync
         */
        // let errorMsg = "Error occurred while syncing";
        // const { response, message } = error;

        // if (response && response.status) {
        //   if (response.status === 401) {
        //     this.askUserToReLoginAgain();
        //     return;
        //   } else {
        //     errorMsg =
        //       response.data?.messsage || "Error occurred while syncing";
        //   }
        // } else if (message) {
        //   errorMsg = message;
        // }

        // this.showToast({
        //   message: errorMsg,
        //   color: "red",
        // });
      }

      return {
        status: resStatus,
      };
    },
    askUserToReLoginAgain() {
      this.updateRootState({
        isGoogleCalSyncEnabled: false,
      });

      DatabaseInterface.update(
        {
          "/googleCalSyncInfo": {
            isEnabled: false,
            refreshToken: null,
            lastSynced: null,
          },
        },
        this.userInfo.uid
      );
      this.showToast({
        message: "Auth expired please re-connect again",
        color: "red",
      });
    },
    async findEventByDateAndUpdateIt(
      eventData,
      mainId,
      useMax = true,
      bulk = false,
      eventAdded,
      verifyCalendar = true
    ) {
      if (!isGoogleCalSyncEnabled()) return;

      const { currData, newData } = eventData;
      mainId = convertTaskKeyForGoogleCal(mainId);
      const currDueDate = currData.dueAsDate;
      if (isValidDate(currDueDate)) {
        let startDateTimestamp, endDateTimeStamp;
        if (checkTaskIsAllDay(currDueDate)) {
          startDateTimestamp = currDueDate.toISOString();
          endDateTimeStamp = endOfDay(currDueDate).toISOString();
        } else {
          startDateTimestamp = currDueDate.toISOString();
          endDateTimeStamp = addHours(currDueDate, 1);
        }

        const storedCalendarId = GoogleCalHelpers.getStoredCalId();
        const calendarStatusRes = await GoogleCalHelpers.checkAndSetupCalendar(
          storedCalendarId
        );
        const { events: list, status } = await GoogleCalHelpers.listRecurring(
          mainId,
          undefined,
          {
            start: startDateTimestamp,
            end: useMax ? endDateTimeStamp : null,
          },
          false
        );

        if (status === "OK") {
          let googleTaskToUpdate = [];
          let googleEventId;
          if (!bulk) {
            const storedEvent = list[0];
            if (!isEmpty(storedEvent)) {
              googleTaskToUpdate.push({
                key: storedEvent.id,
                updates: newData,
              });
            }

            if (eventAdded && !isEmpty(storedEvent)) {
              googleEventId = storedEvent.id;
            }
          } else {
            googleTaskToUpdate = list.map((eventData) => ({
              key: eventData.id,
              updates: newData,
            }));
          }

          this.updateTasksInGoogleCal(
            googleTaskToUpdate,
            undefined,
            false,
            calendarStatusRes.calendarCreated
          );

          if (
            eventAdded &&
            googleEventId &&
            currData.taskKey &&
            this.rawTasksMap[currData.taskKey]
          ) {
            const googleCalId = GoogleCalHelpers.getStoredCalId();
            DatabaseInterface.update(
              {
                [`/tasks/${currData.taskKey}/googleEventId`]: googleEventId,
                [`/tasks/${currData.taskKey}/googleCalendarId`]: googleCalId,
              },
              this.userInfo.uid
            );
            const storedTaskData = this.tableTasks.find(
              (t) => t.key === currData.taskKey
            );

            if (!isEmpty(storedTaskData)) {
              addOrRemoveOrUpdateTasksInLocalTasksList({
                tasksToUpdate: [
                  {
                    key: currData.taskKey,
                    updates: {
                      ...storedTaskData,
                      googleEventId,
                      googleCalendarId: googleCalId,
                    },
                  },
                ],
              });
            }
          }
        }

        if (verifyCalendar && calendarStatusRes.calendarCreated) {
          updateCalendarIdInDb(calendarStatusRes.calendarId);
        }
      }
    },
    /**
     * Get google event data
     * @param {string} eventId Id of recurring event Id
     * @param {boolean} verifyCalendar Verify Calendar
     * @returns {Promise<{status:string, event:{[string]:*}, [string]:*}>}
     */
    async getGoogleEvent(eventId, verifyCalendar = true) {
      let res = {};
      try {
        const {
          event: storedEvent,
          status,
          ...restRes
        } = await GoogleCalHelpers.getEventById(eventId, verifyCalendar);

        if (status === "OK") {
          res.event = storedEvent;
          res.status = "OK";
        }

        res = {
          ...res,
          ...restRes,
        };
      } catch (error) {
        console.error("ERROR IN getGoogleEvent call", error);
        res.status = "Error";
        res.message = error.message;
      }

      return res;
    },

    async findRecurringEventByIdOrDateAndUpdateIt(
      eventData,
      parentId,
      addKeyForNewData = true,
      verifyCalendar = true
    ) {
      if (!isGoogleCalSyncEnabled()) return;
      const { currData, newData } = eventData;
      const { googleEventId } = currData;

      if (!isEmpty(newData)) {
        const storedCalendarId = GoogleCalHelpers.getStoredCalId();
        const calendarStatusRes = await GoogleCalHelpers.checkAndSetupCalendar(
          storedCalendarId
        );
        const { event: storedEvent } = await this.getGoogleEvent(
          googleEventId,
          false
        );

        if (!isEmpty(storedEvent)) {
          this.updateTasksInGoogleCal(
            {
              key: googleEventId,
              updates: newData,
            },
            undefined,
            false,
            calendarStatusRes.calendarCreated
          );
        } else {
          this.findEventByDateAndUpdateIt(
            {
              currData: {
                ...currData,
              },
              newData: newData,
            },
            parentId,
            undefined,
            false,
            addKeyForNewData,
            false
          );
        }

        if (verifyCalendar && calendarStatusRes.calendarCreated) {
          updateCalendarIdInDb(calendarStatusRes.calendarId);
        }
      }
    },
    async updateRecurringAndItsChildEventsFromMap(recurringEventMap) {
      if (!isGoogleCalSyncEnabled()) return;
      let mainEventUpdatesList = [];
      const storedCalendarId = GoogleCalHelpers.getStoredCalId();
      const { calendarCreated, calendarId } =
        await GoogleCalHelpers.checkAndSetupCalendar(storedCalendarId);
      for (const recurringEventKey in recurringEventMap) {
        const recurringUpdateData = recurringEventMap[recurringEventKey];
        const eventChildrenUpdates = recurringUpdateData.childrenUpdates;
        mainEventUpdatesList.push({
          key: recurringEventKey,
          updates: recurringUpdateData.mainEventUpdates,
        });

        eventChildrenUpdates.forEach((childEventData) => {
          this.findRecurringEventByIdOrDateAndUpdateIt(
            childEventData,
            recurringEventKey,
            false,
            false
          );
        });
      }

      this.updateTasksInGoogleCal(
        mainEventUpdatesList,
        undefined,
        false,
        calendarCreated
      );

      if (calendarCreated) {
        updateCalendarIdInDb(calendarId);
      }
    },
    async enableGoogleSync() {
      this.setLoader(true);

      try {
        const url = await GoogleCalHelpers.createCalAuthLink();
        this.setLoader(false);

        if (url) {
          window.location.href = url;
        }
      } catch (error) {
        console.error(error);
        this.setLoader(false);
      }
    },
    async revokeGoogleCalAccess() {
      this.setLoader(true);
      try {
        GoogleCalHelpers.revokeAccess();
        this.updateRootState({
          isGoogleCalSyncEnabled: false,
          googleCalLastSync: "",
        });

        const dbUpdates = {
          ["/googleCalSyncInfo/isEnabled"]: false,
          ["/googleCalSyncInfo/refreshToken"]: null,
          ["/googleCalSyncInfo/lastSynced"]: null,
        };
        DatabaseInterface.update(
          {
            ...dbUpdates,
          },
          this.userInfo.uid
        );
        this.showToast({
          message: "Google calendar is disconnected",
          color: "green",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.setLoader(false);
      }
    },
    // updateRecurringTaskByDateOrId(){

    // }
    // async removeTaskFromGoogleCal(tasksToRemove) {
    //   let resValue = false;
    //   if (!Array.isArray(tasksToRemove)) tasksToRemove = [tasksToRemove];

    //   console.debug("TASKS", tasksToRemove);
    //   if (tasksToRemove && tasksToRemove.length) {
    //     const taskIds = [];

    //     tasksToRemove.forEach((taskData) => {
    //       if (taskData.googleEventId) {
    //         taskIds.push(taskData.googleEventId);
    //       }
    //     });

    //     await GoogleCalHelpers.removeEvents(taskIds);
    //     resValue = true;
    //   }

    //   return resValue;
    // },
  },
  computed: {
    ...mapGetters(["googleCalLastSync", "googleCalLastConnected"]),
    ...mapGetters("task", {
      rawTasks: "rawTasks",
      rawTasksMap: "rawTasksMap",
      tableTasks: "tasks",
    }),
  },
};

export default mixin;
