import taskActions from "./actions";
import taskMutators from "./mutations";
import taskGetters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      projects: {},
      tasks: [],
      rawTasks: [],
      rawTasksMap: {},
      recurringTasksMap: {},
      objectivesMap: {},
      tasksCount: 0,
      categories: {},
      groupMode: 0,
      nav: 0,
      inboxViewEnabled: false,
      globalViewEnabled: false,
      inspectModeEnabled: false,
      isFilterBarOptsEnabled: false,
      selectedOptsByFilterBar: false,
      showClearedTasks: false,
      filteredTasks: [],
      filters: {
        priority: [],
        taskType: [],
        flag: [],
        category: [],
        type: [],
        resourceTypes: [],
      },
      additionalTaskFilters: {},
      selectedDates: [],
      selectedOptsList: [],
      query: "",
      selectedTasks: [],
      prevTreeGroupView: null,
      prevTodayViewGroupMode: null,
      prevTreeGridMode: null,
      projectsTree: [],
      contingentFilter: [],
      isUserFromDifferentTreeMode: false,
      collapsedGroups: [],
      openedCategories: [],
      selectedCalForecastType: "",
      areaWiseSortedTasks: {},
      areaWiseSortedTasksGroups: [],
      selectedTaskFilter: "",
      selectedTaskDateFilter: "",
      inspectTriggeredFrom: "",
      inspectModeOpts: {},
      currInspectedTaskId: "",
      showContingentTasks: false,
      ignoreAreaCheck: false,
      isDeleteRecurringConfirmDialogOpen: false,
      isProjectsResourceModeEnabled: false,
      isTasksDataLoaded: false,
    };
  },
  actions: taskActions,
  mutations: taskMutators,
  getters: taskGetters,
};
