<template>
  <div class="d-flex mt-3 mb-3 created-and-modified-info-wrapper">
    <span> Created: {{ createdText }}</span>
    <span class="ml-1 mr-1"> --> </span>
    <span>Modified: {{ modifiedText }}</span>
  </div>
</template>
<script>
export default {
  props: {
    createdText: {
      type: String,
    },
    modifiedText: {
      type: String,
    },
  },
};
</script>
