<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        v-bind="attrs"
        v-on="on"
        :class="toggleBtnClasses"
        @click.exact="handleClick"
        x-small
        light
        v-if="!isGlobalViewEnabled"
      >
        <span class="toggle-btn-text"> 1 </span>
      </v-btn>
    </template>
    <span> Show All </span>
  </v-tooltip>
</template>
<script>
import { TOGGLE_ORDERED_TASKS } from "@/variables/events";
import EventEmitter from "@/helpers/eventEmitter";
import { mapGetters } from "vuex";

export default {
  inject: {
    toggleOrderTasksData: {
      from: "toggleData",
      default: {},
    },
  },
  methods: {
    handleClick() {
      EventEmitter.emit(TOGGLE_ORDERED_TASKS);
    },
  },
  computed: {
    ...mapGetters("task", {
      isGlobalViewEnabled: "isGlobalViewEnabled",
    }),
    showAllOrderedTasks() {
      return this.toggleOrderTasksData?.showAllTasks || false;
    },
    toggleBtnClasses() {
      return {
        "toggle-btn": true,
        "toggle-active": this.showAllOrderedTasks,
      };
    },
  },
};
</script>
<style scoped>
.toggle-btn {
  min-width: auto !important;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 12px;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.toggle-btn.toggle-active {
  background-color: #d9d9d9 !important;
  color: #676767 !important;
}
</style>
