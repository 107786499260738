export const frequencyOptsMap = {
  daily: {
    label: "Day",
    value: "daily",
    frequency: 1,
  },
  weekly: {
    label: "Week",
    value: "weekly",
    frequency: 1,
  },
  monthly: {
    label: "Month",
    value: "monthly",
    frequency: 1,
  },
  yearly: {
    label: "Year",
    value: "yearly",
    frequency: 1,
  },
};

const frequencyOpts = Object.values(frequencyOptsMap);

export const maxFrequencyDates = {
  daily: {
    maxCount: 6,
    unit: "months",
  },
  weekly: {
    maxCount: 100,
    unit: "weeks",
  },
  monthly: {
    maxCount: 100,
    unit: "months",
  },
};
export default frequencyOpts;
