<template>
  <div
    class="d-flex flex-column flex-nowrap fill-height history-table"
    id="activity-tasks-list"
  >
    <div
      :id="`activity-tasks-list-wrapper-${randomId}`"
      class="flex-grow-1 overflow-hidden"
      @contextmenu="handleContextMenuTrigger"
    >
      <DataTable
        :list="tasks"
        :columnsList="tableColumns"
        ref="dataGrid"
        :selectedRowIds="selectedRowKeys"
        :options="options"
        :allowSelectionByArrowKeys="allowArrowKeySelection"
        :renderGroupCaption="createGroupItemsCaptions"
        :rowClassNamesCreator="createRowClassNames"
        :whiteListBtns="[
          'project-form',
          'add-resource-pop-up',
          'resource-type-selector',
          'resource-type-cate-selector',
          'resource-save-btn',
          'resource-title-editor-wrapper',
          'resource-error-pop-up',
          'convert-dialog',
          'note-path-selector-popup',
          'v-overlay',
        ]"
        :allowMultiSelect="allowMultiSelect"
        :allowDragAndDrop="allowDrag"
        :rowDragVerifier="verifyRowDrag"
        :collapsedGroupsList="collapsedGroupsList"
        :verifyColumnSortablity="checkColumnIsSortable"
        :postSortProcessor="processTasksAfterSort"
        :doNotHandleRowScroll="doNotHandleRowScroll"
        :checkDeslectOnClick="deselectOnClick"
        :checkRowDragEnabled="checkIfRowDragEnabled"
        :itemSize="36"
        placeholderText="Select tasks to display"
        @edit:enabled="handleEditEnabled"
        @row:updated="handleRowUpdated"
        @rows:selected="handleRowsSelected"
        @row:select="handleRowSelect"
        @row:deselect="handleRowDeselect"
        @row:clicked="handleRowClick"
        @row:focused="handleRowFocused"
        @row:added="handleRowAdd"
        @list:processed="handleListProcessed"
        @group:toggled="handleGroupToggle"
        @keydown="handleKeydown"
        @key-pressed:delete="handleDeleteCall"
        @list:post-grouping="handlePostGrouping"
        @row:reordered="handleRowDragStop"
        @row:touch:long="handleLongTouch"
      />
    </div>

    <ContextMenu
      :target="`#activity-tasks-list-wrapper-${randomId}`"
      :items="menuItems"
      :menuId="`activity-tasks-menu-opts-${randomId}`"
      @menu-item:selected="handleMenuSelection"
      @beforeMenuOpen="handleBeforeMenuOpen"
      class="activity-tasks-popup-menu"
    />
  </div>
</template>
<script>
import Vue from "vue";
import ContextMenu from "@/core/components/ContextMenu";
import DataTable from "@/core/components/DataTable/DataTable.vue";
import DueVue from "@/components/TasksListView/components/TasksList/components/DueCell.vue";
import Project from "@/components/TasksListView/components/TasksList/components/ProjectNameCell.vue";
import ProjectEditor from "@/components/TasksListView/components/TasksList/components/EditProjectCell.vue";
import TitleViewerCell from "@/components/GridComponents/TitleViewerCell.vue";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import googleCalHelpersMixin from "@/mixins/googleCalHelpersMixins";
import recurringTasksHelperMixin from "@/mixins/recurringTasksHelpersMixin";
import {
  formatDate,
  getCurrDate,
  isSameDates,
  isValidDate,
} from "@/helpers/dates";
import EditDueDateCell from "@/components/TasksListView/components/TasksList/components/EditDueDateCell.vue";
import TaskTypeSelectEditorCellVue from "@/components/TasksListView/components/TasksList/components/EditTaskTypeCell.vue";
import TaskTypeCellVue from "@/components/TasksListView/components/TasksList/components/TaskTypeCell.vue";
import EventEmitter from "@/helpers/eventEmitter";
import {
  REFRESH_ACTIVITY_HISTORY,
  REFRESH_FORM_DATA,
  SELECT_TASK_IN_ACTIVITY_GRID,
  SELECT_TEXT_IN_INPUT,
  ADD_NEW_TASK_IN_ACTIVITY_GRID,
  CLOSE_INLINE_ADD,
  REFRESH_TASK_DATA_IN_RESOURCE_FORM,
} from "@/variables/events";
import {
  areQlDeltasEqual,
  convertHTMLToQlDelta,
  createOrGetQlInstance,
  removeQlInstance,
} from "@/helpers/quill";
import {
  addOrRemoveOrUpdateTasksInLocalTasksList,
  checkIfRowTaskDataEditedChanged,
  checkIfTaskHasReminder,
  createDateTimeFromDue,
  createDbUpdateDataFromProvidedData,
  createReminderTasks,
  createReviewGroupLabel,
  createTaskDataFromProvidedData,
  createTaskRowDataByCell,
  createTaskRowDataByRowData,
  createUpdateAndEditedTaskDataByCell,
  getMainTaskRulesAndFillRecurringOpts,
  getOrderedTasksByProject,
  getReminderTasksLinkedToTaskId,
  isTaskRecurring,
  processTask,
  checkIfTaskIsContinued,
  checkIfTaskIsOrderedTask,
  getNextFirstTaskByDate,
  processTaskList,
  checkIfSelectedTaskCateHasOnlyArea,
  checkStatusBtnPosIsFlagCell,
  checkTaskSettingsIsNotEmpty,
  checkStatusBtnPosIsAreaCell,
  checkTaskAdditionalFiltersEmpty,
  checkIfContingentTaskIsUnscheduled,
  makeVirtualTaskVisibleInFormGrid,
} from "@/helpers/tasks";
import { isEmpty, isEqual, cloneDeep } from "lodash";
// import { createUpdateAndEditedForCatOrProj } from "@/helpers/categories";
import DatabaseInterface from "@/services/DatabaseInterface";
import { getUserActions } from "@/helpers/actionHistory";
import {
  addActivityListFilters,
  checkIfTaskIsUnscheduled,
} from "@/helpers/tasks";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  REMINDER_TASK_EDIT_PROPS,
  // groupOpts,
  // OBJECTIVE_TASKS_SORT_CONFIG,
  sortOpts,
  groupOpts,
  TASK_PROPS_FOR_FORM,
  TASK_MENU_ACTIONS,
  TASK_ACTIONS_TYPES,
  nextActionsGroupsDragAllowed,
  DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS,
} from "@/variables/tasks";
import { convertValueToArray } from "@/helpers/common";
import {
  getRowContingentClass,
  getRowPriorityClass,
} from "@/components/TasksListView/components/TasksList/helpers/rowClassNamesCreators";
import {
  changeCatOfProjectResources,
  getAllMentionedResourceIdsFromText,
  getProjectResourcesFromText,
  getResourceViewTaskFilters,
  getUserResources,
} from "@/helpers/resources";
// import ActionsVue from "@/components/TasksActionCell.vue";
import TaskEditBtnCell from "@/components/TasksListView/components/TasksList/components/TaskEditBtnCell.vue";
import RowHandler from "@/components/GridComponents/RowHandlerCell.vue";
import ActionsVue from "@/components/TasksListView/components/TasksList/components/TasksActionCell.vue";
import createGroupedTasksCaptions from "@/components/TasksListView/components/TasksList/helpers/createGroupedTasksCaptions";
import getTaskRowHandlerColor from "@/components/TasksListView/components/TasksList/helpers/getTaskRowHandlerColor";
import FlagColumnHeaderVue from "@/components/TasksListView/components/TasksList/components/FlagColumnHeader.vue";
import FlagCellVue from "@/components/TasksListView/components/TasksList/components/FlagCell.vue";
import SaveBtnCell from "@/components/TasksListView/components/TasksList/components/SaveBtnCell.vue";
import DateCell from "@/components/GridComponents/DateCell.vue";
import { parseISO } from "date-fns";
import { INTERNAL_DATE_FORMAT } from "@/variables/dates";
import { NEW_DARK_ICON_COLOR } from "@/variables/colors";
import isPlainObject from "lodash/isPlainObject";
import isEscKey from "@/utils/isEscKey";

const currDateOnly = parseISO(formatDate(new Date(), INTERNAL_DATE_FORMAT));
export default {
  mixins: [
    userDetailsMixin,
    googleCalHelpersMixin,
    tasksHelpersMixin,
    recurringTasksHelperMixin,
  ],
  components: {
    DataTable,
    ContextMenu,
  },
  props: {
    isActive: {
      type: Boolean,
    },
    allowDrag: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "task",
    },
    projectId: {
      type: [String, Array],
      default: "",
    },
    resourceId: {
      type: [String, Array],
      default: "",
    },
    currTaskId: {
      type: String,
    },
    selectedRowKeys: {
      type: Array,
      default: () => [],
    },
    listLoaded: {
      type: Boolean,
      required: true,
    },
    allowMultiSelect: {
      type: Boolean,
      default: false,
    },
    deselectOnClick: {
      type: Boolean,
      default: false,
    },
    allowArrowKeySelection: {
      type: Boolean,
      default: false,
    },
    actionsAllowed: {
      type: Array,
      default: () => [],
    },
    useNextActionModeConfig: {
      type: Boolean,
    },
    useAdditionalTaskFilters: {
      type: Boolean,
    },
    allowNextActionsDragRow: {
      type: Boolean,
    },
    additionalColumnsVisible: {
      type: Array,
      default: () => [],
    },
    showOnlyNextActionsTasks: {
      type: Boolean,
    },
    from: {
      type: String,
    },
    shouldSetTaskData: {
      type: Boolean,
    },
    taskId: {
      type: String,
    },
    resourceTypeToIgnore: {
      type: String,
    },
    ignoreResourceSelectionCheck: {
      type: Boolean,
    },
    additionalFilterOpts: {
      type: Object,
      default: () => ({}),
    },
  },
  data(vm) {
    return {
      selectedUpcomingTaskId: "",
      menuItems: TASK_MENU_ACTIONS,
      columnsVisibleInResourceMode: [
        "handler",
        "edit-btn",
        // "actions",
        "flag",
        "modifiedAsDate",
        "saveBtnCell",
      ],
      allowDragAndDrop: false,
      finalList: [],
      selectedTasks: [],
      columns: [
        {
          frozen: true,
          width: 14,
          minWidth: 14,
          visible: false,
          cssClass: "intend-cell",
          field: "handler",
          sortable: false,
          editable: false,
        },
        {
          rowHandle: true,
          frozen: true,
          width: 30,
          visible: false,
          minWidth: 30,
          cssClass: "row-handler-cell",
          CellComponent: RowHandler,
          isCellDisabled: vm.checkIfCellIsDisabled,
          field: "actions",
          sortable: false,
          editable: false,
          extraData: {
            useDynamicColor: true,
            colorProvider: () => {
              return NEW_DARK_ICON_COLOR;
              // return getTaskRowHandlerColor(cell.data.priority);
            },
          },
        },
        {
          field: "priority",
          width: 30,
          resizable: false,
          editable: false,
          isCellDisabled: vm.checkIfCellIsDisabled,
          vertAlign: "middle",
          CellComponent: ActionsVue,
          sortable: false,
          onDataUpdate: vm.onDataUpdate,
          extraData: {
            doNotShowEditBtn: true,
            showContingentBtn: true,
          },
        },
        {
          title: "Type",
          field: "taskType",
          resizable: false,
          editable: true,
          vertAlign: "middle",
          CellComponent: TaskTypeCellVue,
          cellEdited: vm.cellEdited,
          CellEditor: TaskTypeSelectEditorCellVue,
          onDataUpdate: vm.onDataUpdate,
          verifySortability: vm.checkColumnIsSortable,
          tristateSort: true,
          startSortDir: "asc",
          width: 38,
        },

        {
          frozen: true,
          width: 32,
          visible: false,
          minWidth: 32,
          HeaderComponent: FlagColumnHeaderVue,
          CellComponent: FlagCellVue,
          CellEditor: FlagCellVue,
          isCellDisabled: vm.checkIfCellIsDisabled,
          onDataUpdate: vm.onDataUpdate,
          extraData: {
            showPosition: true,
            showUnscheduledIcon: true,
            // disableAreaSwitch: true,
            // disableScheduledTooltip: true,
            checkForContingent: true,
            useDynamicColor: true,
            colorProvider: (cell) => {
              return getTaskRowHandlerColor(cell.data.priority);
            },
            // scheduledIconColor: "#7C7C7C",
            // unscheduledIconColor: "#7C7C7C",
          },
          field: "flag",
          sortable: false,
          editable: false,
        },
        {
          field: "edit-btn",
          width: 32,
          resizable: false,
          editable: false,
          // cellEditor: PriorityEditor,
          // cellEditor: PrioritySelectEditor,
          isCellDisabled: vm.checkIfCellIsDisabled,
          vertAlign: "middle",
          CellComponent: TaskEditBtnCell,
          sortable: false,
          visible: false,
          onDataUpdate: vm.onDataUpdate,
        },
        {
          title: "Task",
          field: "title",
          resizable: false,
          editable: true,
          primary: true,
          vertAlign: "middle",
          cellEdited: this.cellEdited,
          CellEditor: TitleViewerCell,
          CellComponent: TitleViewerCell,
          extraData: {
            watchResources: true,
            showObjectiveBtn: false,
            previousCell: "taskType",
            filterResourcesByArea: true,
          },
          width: "stretch",
          minWidth: 300,
          placeholder: "Enter Title",
          onDataUpdate: vm.onDataUpdate,
          tristateSort: false,
          startSortDir: "asc",
          expand: true,
          verifySortability: vm.checkColumnIsSortable,
        },
        {
          field: "project",
          title: "Area",
          resizable: false,
          editable: true,
          CellComponent: Project,
          width: 148,
          CellEditor: ProjectEditor,
          vertAlign: "middle",
          cellEdited: vm.cellEdited,
          tristateSort: false,
          startSortDir: "asc",
          onDataUpdate: vm.onDataUpdate,
          verifySortability: vm.checkColumnIsSortable,
          getWidth: () => {
            return vm.checkIfStatusBtnIsVisibleInAreaColumn() ? 176 : 148;
          },
          extraData: {
            showPosition: true,
            showUnscheduledIcon: true,
            // disableAreaSwitch: true,
            // disableScheduledTooltip: true,
            checkForContingent: true,
            useDynamicColor: true,
            colorProvider: (cell) => {
              return getTaskRowHandlerColor(cell.data.priority);
            },
          },
        },
        {
          field: "dueAsDate",
          visible: true,
          title: "Due",
          resizable: false,
          editable: true,
          cellEdited: vm.cellEdited,
          CellComponent: DueVue,
          CellEditor: EditDueDateCell,
          onDataUpdate: vm.onDataUpdate,
          tristateSort: true,
          startSortDir: "asc",
          defaultValue: new Date("1990-01-01"),

          getWidth: () => {
            return vm.checkIfDueDateDayAndDateEnabled() ? 250 : 235;
          },
          width: 235,
          type: "date",
          transformFunc: (val) => (isValidDate(val) ? val : null),
          verifySortability: vm.checkColumnIsSortable,
          extraData: {
            checkForContingent: true,
          },
        },
        {
          width: 135,
          field: "modifiedAsDate",
          title: "Modified",
          CellComponent: DateCell,
          visible: false,
          tristateSort: false,
          startSortDir: "asc",
          editable: false,
          resizable: false,
          defaultValue: new Date("1970-01-01"),
          missingValue: "skip",
          transformFunc: (val) => (isValidDate(val) ? val : null),
          verifySortability: vm.checkColumnIsSortable,
        },
        {
          field: "saveBtnCell",
          isCellDisabled: vm.checkIfCellIsDisabled,
          resizable: false,
          editable: true,
          width: 75,
          CellComponent: SaveBtnCell,
          CellEditor: SaveBtnCell,
          onDataUpdate: vm.onDataUpdate,
          sortable: false,
        },
        {
          field: "due",
          visible: false,
        },
        {
          field: "project",
          visible: false,
        },
        {
          field: "snapshots",
          visible: false,
        },
        {
          field: "googleEventId",
          visible: false,
        },
        {
          field: "linkedTo",
          visible: false,
        },
        {
          field: "isVirtual",
          visible: false,
        },
        {
          field: "isContingent",
          visible: false,
        },
        {
          field: "created",
          visible: false,
        },
        {
          field: "modified",
          visible: false,
        },
        // {
        //   field: "modifiedAsDate",
        //   visible: false,
        // },
        {
          field: "resources",
          visible: false,
        },
      ],
      tableColumns: [],
      options: {},
      createdFilters: [],
      randomId: Math.floor((Math.random() * 16 * 1000).toString()),
      doNotHandleRowScroll: false,
    };
  },
  mounted() {
    this.setTableOpts();
    this.setListeners();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    ...mapActions("editForm", ["setTaskData", "showNewEditTaskDialog"]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions("resourcesData", ["updateResourcesState"]),
    ...mapActions("resourceInfo", ["setStoredTaskData"]),
    handleLongTouch(e, item) {
      const menu = document.getElementById("activity-tasks-popup-menu")
      if (menu?.ej2_instances && menu.ej2_instances[0].vueInstance?.open) {
        const touch = e.touches[0] || e.changedTouches[0] || e.targetTouches[0];
        const x = touch.pageX;
        const y = touch.pageY;
        menu.ej2_instances[0].vueInstance.open(y, x)
      }
    },
    getCollapsedGroupsList() {
      let collapsedGroups = this.collapsedGroups;

      if (this.checkIfResourceModeAndSelectionEmpty()) {
        if (
          !this.isSearchKeywordValid &&
          !this.resourceTypeToIgnore &&
          checkTaskAdditionalFiltersEmpty(getResourceViewTaskFilters())
        ) {
          collapsedGroups = [...DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS];
        } else {
          collapsedGroups = [];
        }
      }

      return collapsedGroups;
    },
    async changeOrderOfTasksByDragAndDrop(eventData) {
      const { dropTargetRowData = {}, isLastRow } = eventData;

      const currGroupData = this.$refs.dataGrid.getGroupBy();
      let groupKey = currGroupData;
      if (
        !isEmpty(currGroupData) &&
        isPlainObject(currGroupData) &&
        currGroupData.key
      ) {
        groupKey = currGroupData.key;
      }
      const draggedTargetRows = eventData.draggedRows;
      const dropTargetRowGroup = dropTargetRowData[groupKey];
      if (groupKey === "CTICustomGroup") {
        if (dropTargetRowGroup === "1") {
          await this.processTaskDroppedInCustomOrder({
            draggedTasks: draggedTargetRows,
            dropRowData: dropTargetRowData,
            groupByField: currGroupData,
            tasksList: this.finalList,
            isLastRow,
          });
        } else if (nextActionsGroupsDragAllowed.includes(dropTargetRowGroup)) {
          await this.processTaskDroppedInFlaggedAndUnFlaggedGroups(
            draggedTargetRows,
            dropTargetRowData,
            groupKey
          );
        }

        await Vue.nextTick();

        this.refreshList();
        if (this.isTaskEditFormOpened && !isEmpty(this.editTaskData)) {
          this.refreshFullFormData(this.editTaskData.key);
        }
      }
    },
    handleRowDragStop(eventData) {
      if (this.allowDrag && this.allowNextActionsDragRow) {
        this.changeOrderOfTasksByDragAndDrop(eventData);
      }
    },
    verifyRowDrag(dropRowData) {
      if (!this.allowDrag || !this.allowNextActionsDragRow) {
        return false;
      }

      let groupKey = this.$refs.dataGrid.getGroupBy();

      if (!isEmpty(groupKey) && isPlainObject(groupKey)) {
        groupKey = groupKey.key;
      }

      const rowGroupKey = dropRowData[groupKey];

      return nextActionsGroupsDragAllowed.includes(rowGroupKey);
    },
    checkIfRowDragEnabled() {
      return this.actionsAllowed.includes("drag");
    },
    async handleRowAdd(dataToData) {
      let data = dataToData;
      if (!Array.isArray(data)) data = [data];
      let reminderTasks = [];
      const processedTaskList = processTaskList(
        data,
        this.projects,
        this.categories
      );

      const taskDataToAdd = data[0];

      if (checkIfTaskHasReminder(taskDataToAdd)) {
        reminderTasks.push(...createReminderTasks(taskDataToAdd));
      }

      await addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToAdd: [...processedTaskList, ...reminderTasks],
        verifyAdd: true,
      });
      await this.$nextTick();
      this.enableScroll = true;
      this.setTaskIdsForScroll(data[0].key);
      this.refreshList();
    },
    handlePostGrouping(list) {
      if (!isEmpty(list)) {
        const nextUpcomingTask = getNextFirstTaskByDate(list, currDateOnly);
        if (!isEmpty(nextUpcomingTask)) {
          this.selectedUpcomingTaskId = nextUpcomingTask.key;
        }
      }
      this.doNotHandleRowScroll =
        isEmpty(this.taskIdToSelectInGrid) && !this.enableScroll;
      this.taskIdToSelectInGrid = undefined;
    },
    handleContextMenuTrigger(e) {
      const isGridInEditMode = this.$refs.dataGrid.isEditingActive();

      if (isGridInEditMode) {
        e.stopImmediatePropagation();
        e.stopPropagation();
      }
    },
    taskCompleteToggle(tasksToComplete) {
      const selectedTask = tasksToComplete[0];
      const currTaskRepeatInfo = selectedTask.repeatInfo;

      if (!isEmpty(currTaskRepeatInfo)) {
        const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
          fieldName: "completed",
          currValue: true,
          rowId: selectedTask.key,
          oldValue: false,
          currRowData: selectedTask,
        });
        const createdRowData = createTaskRowDataByCell(
          selectedTask,
          "completed",
          true,
          false
        );
        this.completeCurrTaskAndCreateNextRepeatTask({
          key: selectedTask.key,
          updates,
          editedData,
          updatedRowData: createdRowData,
          refreshList: true,
          checkEditForm: true,
        });

        return;
      } else if (checkIfTaskIsContinued(selectedTask)) {
        const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
          fieldName: "completed",
          currValue: true,
          rowId: selectedTask.key,
          oldValue: false,
          currRowData: selectedTask,
        });
        const createdRowData = createTaskRowDataByCell(
          selectedTask,
          "completed",
          true,
          false
        );

        this.completeContinuedTask({
          key: selectedTask.key,
          updates,
          editedData,
          updatedRowData: createdRowData,
          checkEditForm: true,
          refreshList: true,
        });

        return;
      }
      this.updateRecurringOrNormalTasks(tasksToComplete, { completed: true });
    },
    async handleMenuSelection(args) {
      await this.$nextTick();
      const selectedMenuItem = args.item;

      if (!isEmpty(this.selectedTaskToUse)) {
        switch (selectedMenuItem.id) {
          case TASK_ACTIONS_TYPES.DELETE:
            this.handleDeletionOfTasks([this.selectedTaskToUse]);
            break;
          case TASK_ACTIONS_TYPES.COMPLETE:
            this.taskCompleteToggle([this.selectedTaskToUse]);
            break;
          case TASK_ACTIONS_TYPES.DUPLICATE:
            this.duplicateTask([this.selectedTaskToUse]);
            break;
        }
      }
    },
    handleBeforeMenuOpen(args) {
      const id = `activity-tasks-menu-opts-${this.randomId}`;
      const menuObj = document.getElementById(id).ej2_instances[0];
      if (isEmpty(this.selectedTasks)) {
        args.cancel = true;
        return;
      }

      // menuObj.hideItems(["Duplicate"]);

      const taskToSelect = this.selectedTasks[0];
      if (!isEmpty(taskToSelect)) {
        if (taskToSelect.completed) {
          menuObj.hideItems(["Complete"]);
        } else {
          menuObj.showItems(["Complete"]);
        }

        this.selectedTaskToUse = { ...taskToSelect };
      }
    },
    handleDeleteCall(e) {
      if (isEmpty(this.selectedTasks)) return;
      const selectedTasks = cloneDeep(this.selectedTasks);
      this.$refs.dataGrid.deselectPreviousRows();
      this.handleDeletionOfTasks(selectedTasks);

      this.$emit("keypress:delete", e);
    },
    async handleRowFocused(rowId) {
      this.enableScroll = false;
      if (this.triggerEditForRow) {
        this.triggerEditForRow = false;
        this.$refs.dataGrid.enableEditByRowId(rowId);
        await this.$nextTick();
        setTimeout(() => {
          EventEmitter.emit(SELECT_TEXT_IN_INPUT);
        }, 0);
      }
    },
    updateCollapsedGroupListInDb(list) {
      getUserResources().set(list, "internalCollapsedGroups");
    },
    handleGroupToggle(groupData, mode) {
      if (this.mode !== "resource" && this.mode !== "resource-edit") return;
      const currCollapsedGroups = cloneDeep(this.collapsedGroupsList);
      const storedIndex = currCollapsedGroups.findIndex((g) =>
        isEqual(g, groupData)
      );

      if (mode) {
        if (mode === "remove") {
          if (storedIndex >= 0) {
            currCollapsedGroups.splice(storedIndex, 1);
          }
        }

        if (mode === "add") {
          if (storedIndex === -1) {
            currCollapsedGroups.push({ ...groupData });
          }
        }
      } else {
        if (storedIndex >= 0) {
          currCollapsedGroups.splice(storedIndex, 1);
        } else {
          currCollapsedGroups.push({ ...groupData });
        }
      }

      if (this.checkIfResourceModeAndSelectionEmpty()) {
        this.updateResourcesState({
          collapsedGroupsList: currCollapsedGroups,
        });
      } else {
        this.updateCollapsedGroupListInDb(currCollapsedGroups);
      }
    },

    checkIfResourceModeAndSelectionEmpty() {
      return (
        this.mode === "resource" &&
        !this.ignoreResourceSelectionCheck &&
        isEmpty(this.resourceId)
      );
    },
    processTasksAfterSort(list) {
      const sortBy = this.$refs.dataGrid.sortBy || [];

      // if (this.mode !== "resource") {
      //   return list;
      // }

      list = this.processTasksOfNextActionsMode(
        list,
        sortBy,
        this.mode === "resource-edit" || this.mode === "resource",
        this.mode === "resource-edit",
        true,
        this.showAllTasks
      );
      console.debug("processTasksAfterSort list", list.length);
      return list;
    },
    checkColumnIsSortable(colData) {
      if (this.mode !== "resource" && this.mode !== "resource-edit") {
        return colData.sortable !== false;
      }

      if (colData.field === "taskType") {
        return true;
      }
      if (colData.field === "title") {
        return false;
      }

      if (colData.field === "modifiedAsDate") {
        return true;
      }

      if (colData.field !== "project") {
        return false;
      }

      return true;
    },
    createGroupItemsCaptions(data, group) {
      const groupCaption = createGroupedTasksCaptions(
        data,
        group,
        (groupKey) => {
          const currView = this.currActiveView;
          const isProjectsViewEnabled = this.isProjectsResourceModeEnabled;
          if (groupKey === "1") {
            return !isProjectsViewEnabled &&
              currView !== "resources" &&
              checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList)
              ? "Next Actions (drag to order)"
              : "Next Actions";
          }

          return createReviewGroupLabel(
            groupKey,
            this.projects,
            this.categories
          );
        }
      );

      return groupCaption;
    },
    checkBeforeScrollToRow(rowData) {
      return this.selectedRowKeys.includes(rowData);
    },
    createRowClassNames(rowData) {
      let classNames = "";

      const rowPriorityClass = getRowPriorityClass(rowData.priority || "");
      const rowContingentClass = getRowContingentClass(rowData.isContingent);
      if (rowPriorityClass) {
        classNames += ` lighten-3 ${rowPriorityClass}`;
      }

      if (rowContingentClass) {
        classNames += ` ${rowContingentClass}`;
      }

      if (rowData.isOlderTask) {
        classNames += ` is-task-older`;
      }

      if (rowData.key === this.selectedUpcomingTaskId) {
        classNames += ` upcoming-task`;
      }
      return classNames;
    },
    async handleReminderTaskUpdates(
      rowUpdates,
      refreshList = true,
      updateRemindersOnly
    ) {
      let reminderTasksToAdd = [];
      let reminderTasksToRemove = [];
      let tasksToUpdate = [];
      if (!Array.isArray(rowUpdates)) rowUpdates = [rowUpdates];
      rowUpdates.forEach((r) => {
        const { currRowData, updates: updatedRowData, key } = r;
        if (!currRowData.completed && updatedRowData.completed) {
          reminderTasksToRemove.push(
            ...getReminderTasksLinkedToTaskId(this.tasks, key)
          );
        } else if (currRowData.completed && !updatedRowData.completed) {
          reminderTasksToAdd.push(...createReminderTasks(updatedRowData));
        } else if (
          !isSameDates(currRowData.dueAsDate, updatedRowData.dueAsDate) ||
          !isEqual(
            currRowData.pausedReminderDays,
            updatedRowData.pausedReminderDays
          )
        ) {
          reminderTasksToRemove.push(
            ...getReminderTasksLinkedToTaskId(this.tasks, key)
          );
          reminderTasksToAdd.push(...createReminderTasks(updatedRowData));
        }

        if (!updateRemindersOnly) {
          tasksToUpdate.push({ key, updates: updatedRowData });
        }
      });

      await this.$nextTick();
      addOrRemoveOrUpdateTasksInLocalTasksList(
        {
          tasksToAdd: !isEmpty(reminderTasksToAdd) ? reminderTasksToAdd : [],
          tasksToRemove: !isEmpty(reminderTasksToRemove)
            ? reminderTasksToRemove
            : [],
          tasksToUpdate: !isEmpty(tasksToUpdate) ? tasksToUpdate : [],
          verifyAdd: false,
        },
        refreshList
      );

      if (refreshList) {
        this.refreshList();
      }

      return true;
    },
    async handleTaskUnscheduling(
      currTaskData,
      updatedTaskData = {},
      refreshForm,
      refreshList
    ) {
      const groupByData = "CTICustomGroup";

      updatedTaskData = createTaskDataFromProvidedData({ ...updatedTaskData });

      const finalUpdatedTaskData = {
        ...currTaskData,
        ...updatedTaskData,
      };

      const isAreaChanged =
        currTaskData.project &&
        currTaskData.project !== finalUpdatedTaskData.project;
      if (finalUpdatedTaskData.project) {
        const orderedTaskOfProject = getOrderedTasksByProject(
          this.tasks,
          finalUpdatedTaskData.project
        );
        await this.processTaskDroppedInCustomOrder({
          draggedTasks: [{ ...currTaskData, updates: { ...updatedTaskData } }],
          dropRowData: orderedTaskOfProject[0],
          isLastRow: false,
          groupByField: groupByData,
          tasksList: orderedTaskOfProject,
        });
      }

      if (refreshList) {
        this.refreshList();
      }
      if (refreshForm) {
        if (
          !isEmpty(this.storedTaskDataInResource) &&
          this.storedTaskDataInResource.key === currTaskData.key
        ) {
          this.refreshFullFormData(currTaskData.key);
        } else if (
          !isEmpty(this.editTaskData) &&
          this.editTaskData.key === currTaskData.key
        ) {
          if (isAreaChanged) {
            this.setTaskAreaInFormAndRefresh(currTaskData.project);
          } else {
            this.refreshFullFormData(currTaskData.key);
          }

          //   const updatedTaskData = this.tasks.find(
          //     (t) => t.key === currTaskData.key
          //   );

          //   if (!isEmpty(updatedTaskData)) {
          //     const processedTaskData = processTask(
          //       getMainTaskRulesAndFillRecurringOpts(updatedTaskData)
          //     );
          //     this.setTaskData(processedTaskData);

          //     await Vue.nextTick();
          //     EventEmitter.emit(REFRESH_FORM_DATA, true);
          //   }
        }
      }
    },
    async refreshFullFormData(taskKey) {
      const updatedTaskData = this.tasks.find((t) => t.key === taskKey);

      if (!isEmpty(updatedTaskData)) {
        const processedTaskData = processTask(
          getMainTaskRulesAndFillRecurringOpts(updatedTaskData)
        );

        if (
          !isEmpty(this.storedTaskDataInResource) &&
          this.storedTaskDataInResource.key === taskKey
        ) {
          this.setStoredTaskData(processedTaskData);
          await Vue.nextTick();
          EventEmitter.emit(REFRESH_TASK_DATA_IN_RESOURCE_FORM, true);
        } else {
          this.setTaskData(processedTaskData);

          await Vue.nextTick();
          EventEmitter.emit(REFRESH_FORM_DATA, true);
        }
      }
    },

    async onDataUpdate(rowId, fieldName, value, cell) {
      if (!rowId) return;
      const row = cell.getRow();
      const currRowData = row.getData();
      const currTaskRepeatInfo = currRowData.repeatInfo;
      if (isTaskRecurring(currRowData)) {
        let editData = {
          [fieldName]: value,
        };
        if (
          fieldName === "dueAsDate" &&
          !isValidDate(value) &&
          !isValidDate(currRowData.dueAsDate)
        ) {
          editData.flag = false;

          if (checkIfTaskIsOrderedTask(currRowData)) {
            editData.isCustomPositioned = false;
          }

          if (currRowData.isContingent) {
            editedData.isContingent = false;
          }
        }

        this.handleRecurringTaskUpdate(
          {
            [fieldName]: value,
          },
          currRowData,
          true
        );
        return;
      }
      const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
        fieldName,
        currValue: value,
        rowId,
        oldValue: currRowData[fieldName],
        currRowData,
      });

      const createdRowData = createTaskRowDataByCell(
        currRowData,
        fieldName,
        value,
        currRowData[fieldName]
      );

      let taskIsUnscheduled = false;

      if (
        fieldName === "dueAsDate" &&
        (checkIfContingentTaskIsUnscheduled(createdRowData, currRowData) ||
          checkIfTaskIsUnscheduled(createdRowData, currRowData))
      ) {
        taskIsUnscheduled = true;
      }

      if (fieldName === "completed" && value) {
        let stopExcution = false;

        if (!isEmpty(currTaskRepeatInfo)) {
          stopExcution = true;
          await this.completeCurrTaskAndCreateNextRepeatTask({
            key: rowId,
            updates,
            editedData,
            updatedRowData: createdRowData,
            refreshList: true,
            checkEditForm: true,
          });
        } else if (checkIfTaskIsContinued(currRowData)) {
          stopExcution = true;
          this.completeContinuedTask({
            key: rowId,
            updates,
            editedData,
            updatedRowData: createdRowData,
            checkEditForm: true,
            refreshList: true,
          });
        }

        if (stopExcution) {
          return;
        }
      }

      if (
        checkIfTaskHasReminder(currRowData) &&
        REMINDER_TASK_EDIT_PROPS.includes(fieldName)
      ) {
        this.handleReminderTaskUpdates(
          { key: rowId, updates: createdRowData, currRowData: currRowData },
          !taskIsUnscheduled,
          taskIsUnscheduled
        );
      } else {
        if (!taskIsUnscheduled) {
          this.updateRowData(
            { key: rowId, updates: createdRowData },
            undefined,
            true
          );
        }
        // this.updateRowData(
        //   { key: rowId, updates: createdRowData },
        //   undefined,
        //   true
        // );
      }

      if (taskIsUnscheduled) {
        await this.handleTaskUnscheduling(
          currRowData,
          createdRowData,
          true,
          true
        );
      } else {
        this.updateTaskInDbAndStoreEditData(rowId, updates, editedData);
        await this.$nextTick();
        this.refreshFormData({ ...createdRowData, key: rowId });
      }

      if (this.isGoogleCalSyncEnabled) {
        this.updateTaskDataInGoogleCal(
          {
            key: rowId,
            updates: { [fieldName]: value },
          },
          true
        );
      }
    },
    async refreshFormData(createdData, isAreaChanged) {
      if (
        !isEmpty(this.storedTaskDataInResource) &&
        this.storedTaskDataInResource.key === createdData.key
      ) {
        const processedTaskData = processTask(
          getMainTaskRulesAndFillRecurringOpts(createdData)
        );

        this.setStoredTaskData(processedTaskData);
        await Vue.nextTick();
        EventEmitter.emit(REFRESH_TASK_DATA_IN_RESOURCE_FORM, true);
      } else if (
        !isEmpty(this.editTaskData) &&
        this.editTaskData.key === createdData.key
      ) {
        if (isAreaChanged && this.editTaskData.project) {
          this.setTaskAreaInFormAndRefresh(this.editTaskData.project);
        } else {
          const processedTaskData = processTask(
            getMainTaskRulesAndFillRecurringOpts(createdData)
          );
          this.setTaskData(processedTaskData);

          await Vue.nextTick();
          EventEmitter.emit(REFRESH_FORM_DATA, true);
        }
      }
    },
    handleRowClick(e, rowData) {
      this.$emit("row:clicked", e, rowData);
    },
    handleClick(e) {
      this.$emit("click", e);
    },
    handleRowSelect(rowKeys) {
      this.$emit("row:select", rowKeys);
    },
    handleRowDeselect(rowKeys) {
      this.$emit("row:deselect", rowKeys);
    },
    async refreshList(reprocessTasks, useExisting) {
      if (reprocessTasks) {
        const tasksToUse = useExisting ? this.tasks : this.rawTasks;
        this.updateState({
          tasks: processTaskList(
            [...tasksToUse],
            this.projects,
            this.categories
          ),
        });
      }
      await this.$nextTick();
      this.$refs.dataGrid.setup();
    },
    async setTableOpts() {
      const filters = cloneDeep(this.createCurrFilters());

      let currColumns = this.columns;
      // const sortConfig = OBJECTIVE_TASKS_SORT_CONFIG;

      let sortOptIndex = 10;
      let groupOptIndex = 3;

      if (this.mode === "resource" || this.mode === "resource-edit") {
        sortOptIndex = 9;
        groupOptIndex = 5;

        if (this.isShowOnlyNextActionsTasksEnabled) {
          sortOptIndex = 12;
          groupOptIndex = 3;
        }

        currColumns = currColumns.map((c) => {
          if (this.columnsVisibleInResourceMode.includes(c.field)) {
            if (c.field === "flag") {
              const isVisible = checkTaskSettingsIsNotEmpty(this.taskSettings)
                ? checkStatusBtnPosIsFlagCell(this.taskSettings)
                : true;

              return {
                ...c,
                visible: isVisible,
              };
            }

            if (c.field === "edit-btn") {
              const isVisible = this.checkIfEditBtnEnabled();

              return {
                ...c,
                visible: isVisible,
              };
            }
            return {
              ...c,
              visible: true,
            };
          }

          return c;
        });
      } else if (this.useNextActionModeConfig) {
        sortOptIndex = 9;
        groupOptIndex = 5;

        if (this.isShowOnlyNextActionsTasksEnabled) {
          sortOptIndex = 12;
          groupOptIndex = 3;
        }

        if (!isEmpty(this.additionalColumnsVisible)) {
          currColumns = currColumns.map((c) => {
            if (this.additionalColumnsVisible.includes(c.field)) {
              if (c.field === "edit-btn") {
                const isVisible = this.checkIfEditBtnEnabled();

                return {
                  ...c,
                  visible: isVisible,
                };
              }
              return {
                ...c,
                visible: true,
              };
            }

            return c;
          });
        }
      }
      const sortOpt = cloneDeep(sortOpts[sortOptIndex]);
      const groupOpt = cloneDeep(groupOpts[groupOptIndex]);
      this.tableColumns = currColumns;
      this.options = {
        ...this.options,
        sortBy: sortOpt,
        groupBy: groupOpt,
        filters,
      };
    },
    async cellEdited(cell) {
      const fieldName = cell.getField();
      let oldValue = cell.getOldValue();
      let updateVals = true;
      let extraUpdateData = {};
      const row = cell.getRow();
      const currRowData = row.getData();
      const rowId = row.getIndex();
      const currValue = cell.getValue();

      if (fieldName === "dueAsDate" && !isValidDate(oldValue)) {
        oldValue = null;
      }

      if (fieldName === "title") {
        const qlInstance = createOrGetQlInstance();
        const convertedOldValue = convertHTMLToQlDelta(oldValue, qlInstance);
        const convertedNewValue = convertHTMLToQlDelta(currValue, qlInstance);
        removeQlInstance();
        updateVals = !areQlDeltasEqual(convertedOldValue, convertedNewValue);

        if (updateVals) {
          extraUpdateData = {
            resources: getAllMentionedResourceIdsFromText(
              convertedNewValue,
              false
            ),
          };
        }
      } else {
        updateVals = oldValue !== currValue;
      }

      if (updateVals) {
        if (isTaskRecurring(currRowData)) {
          return;
        }
        if (
          fieldName === "dueAsDate" &&
          isValidDate(oldValue) &&
          !isValidDate(currValue)
        ) {
          return;
        }
        const { updates, editedData } = createDbUpdateDataFromProvidedData(
          {
            [fieldName]: currValue,
            ...extraUpdateData,
          },
          {
            ...currRowData,
          }
        );

        let userActions = { ...editedData, refreshFormData: true };

        // if (fieldName === "project") {
        //   const newProjectData = this.projects[currValue];

        //   if (!isEmpty(newProjectData)) {
        //     const projectPriority = newProjectData.priority || "";
        //     if (!projectPriority && currRowData.priority) {
        //       const { editedData: projectEditedData } =
        //         createUpdateAndEditedForCatOrProj(
        //           {
        //             priority: currRowData.priority,
        //           },
        //           newProjectData
        //         );

        //       userActions = [
        //         {
        //           type: "edit",
        //           data: {
        //             ...projectEditedData,
        //             key: newProjectData.key,
        //           },
        //           on: "project",
        //         },
        //         {
        //           type: "edit",
        //           data: {
        //             ...editedData,
        //             key: rowId,
        //             refreshFormData: true,
        //           },
        //           on: "task",
        //         },
        //       ];

        //       updates[`/projects/${newProjectData.key}/priority`] =
        //         currRowData.priority;
        //     }
        //   }
        // }

        this.updateTaskInDbAndStoreEditData(rowId, updates, userActions);
      }
    },

    handleRowsSelected(rows) {
      const isInEditMode = this.$refs.dataGrid.isInEditMode();
      if (!isInEditMode) {
        this.currEditedRow = {};
      }

      this.selectedTasks = rows;

      this.$emit("rows:selected", rows);
      if (
        rows[0]?.key &&
        this.shouldSetTaskData &&
        this.taskId !== rows[0]?.key
      ) {
        this.refreshFullFormData(rows[0]?.key);
      }
    },
    async handleRowUpdated({ rowId, updatedRowData: editedRowData }) {
      const currTaskData = this.tasks.find((t) => t.key === rowId);
      if (!isEmpty(currTaskData)) {
        if (isTaskRecurring(currTaskData)) {
          this.handleRecurringTaskUpdate(editedRowData, currTaskData);
          return;
        }
        const updatedData = createTaskRowDataByRowData(
          currTaskData,
          editedRowData
        );
        const isTaskDataChanged = checkIfRowTaskDataEditedChanged(
          updatedData,
          currTaskData,
          TASK_PROPS_FOR_FORM
        );

        if (isTaskDataChanged) {
          const isAreaChanged = updatedData.project !== currTaskData.project;
          let taskIsUnscheduled = false;

          if (checkIfTaskIsUnscheduled(updatedData, currTaskData)) {
            taskIsUnscheduled = true;
          }
          if (
            checkIfTaskHasReminder(currTaskData) &&
            this.checkIfParsedTaskDataHasReminderProps(Object.keys(updatedData))
          ) {
            await this.handleReminderTaskUpdates(
              { key: rowId, updates: updatedData, currRowData: currTaskData },
              !taskIsUnscheduled,
              taskIsUnscheduled
            );
          } else {
            if (!taskIsUnscheduled) {
              await this.updateRowData(
                { key: rowId, updates: updatedData },
                true
              );
            }
          }

          if (isAreaChanged) {
            const mentionedProjectResources = getProjectResourcesFromText(
              updatedData.title
            );

            if (!isEmpty(mentionedProjectResources)) {
              changeCatOfProjectResources([
                {
                  resources: mentionedProjectResources,
                  catId: updatedData.project,
                  isTaskRef: true,
                },
              ]);
            }
          }

          if (taskIsUnscheduled) {
            await this.handleTaskUnscheduling(
              currTaskData,
              updatedData,
              true,
              true
            );
          } else {
            this.refreshFormData({ ...updatedData }, isAreaChanged);
          }

          if (this.isGoogleCalSyncEnabled) {
            this.updateTaskDataInGoogleCal(
              {
                key: rowId,
                updates: { ...currTaskData, ...editedRowData },
                currData: currTaskData,
              },
              false
            );
          }
        }
      }
    },
    async focusOnSelectedItem(selectedItemKey, select, noScroll, noWait) {
      if (!noWait) {
        await this.$nextTick();
      }
      if (selectedItemKey) {
        this.$refs.dataGrid.scrollToRow(
          selectedItemKey,
          select,
          true,
          noScroll,
          noWait
        );
      }
    },
    async handleRecurringTaskUpdate(dataToUpdate = {}, currRowData) {
      const dataChanged = this.checkRowTaskDataChanged(
        dataToUpdate,
        currRowData
      );

      if (!dataChanged) {
        return;
      }

      const parsedDataToUpdate = createTaskDataFromProvidedData(dataToUpdate);
      const currRawData = this.getRawDataOfTheRecurringTask(currRowData);

      const combinedData = {
        ...currRawData,
        ...parsedDataToUpdate,
        modified: getCurrDate(),
      };

      combinedData.dueAsDate = createDateTimeFromDue(
        combinedData.due,
        combinedData.time
      );

      const isAreaChanged = combinedData.project !== currRawData.project;

      if (isAreaChanged) {
        const mentionedProjectResources = getProjectResourcesFromText(
          combinedData.title
        );

        if (!isEmpty(mentionedProjectResources)) {
          changeCatOfProjectResources([
            {
              resources: mentionedProjectResources,
              catId: combinedData.project,
              isTaskRef: true,
            },
          ]);
        }
      }

      if (
        checkIfTaskIsUnscheduled(combinedData, currRowData, dataToUpdate) ||
        checkIfContingentTaskIsUnscheduled(
          combinedData,
          currRowData,
          dataToUpdate
        )
      ) {
        const reminderTasksToRemove = [];
        const allReminderTasksLinkedToKey = getReminderTasksLinkedToTaskId(
          this.tasks,
          currRowData.key
        );
        if (!isEmpty(allReminderTasksLinkedToKey)) {
          reminderTasksToRemove.push(...allReminderTasksLinkedToKey);
        }

        await addOrRemoveOrUpdateTasksInLocalTasksList(
          {
            tasksToRemove: [...reminderTasksToRemove],
          },
          false
        );

        this.handleTaskUnscheduling(currRowData, combinedData, true, true);
      } else {
        const { createdData } = await this.updateSingleRecurringTask(
          currRowData,
          combinedData,
          true,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          false
        );

        this.refreshList();

        this.refreshFormData(createdData, isAreaChanged);
      }
    },
    handleEditEnabled(_, rowData) {
      this.currEditedRow = rowData;
    },
    async handleListProcessed(list = []) {
      this.finalList = list;
      this.$emit("list:processed", list);
    },
    setTaskIdsForScroll(taskIds) {
      this.$refs.dataGrid.setRowIdsForScroll(convertValueToArray(taskIds));
    },
    async updateRowData(rowUpdates, refreshList = true, setForScroll) {
      if (!Array.isArray(rowUpdates)) rowUpdates = [rowUpdates];
      await addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToUpdate: rowUpdates,
      });

      await this.$nextTick();

      if (setForScroll) {
        this.setTaskIdsForScroll(rowUpdates[0].key);
      }

      if (refreshList) {
        this.refreshList();
      }
    },
    async updateTaskInDbAndStoreEditData(rowId, updates, editedData) {
      try {
        DatabaseInterface.update(updates, this.userInfo.uid);

        if (Array.isArray(editedData)) {
          getUserActions().addBatchAction({
            data: editedData,
            on: "all",
          });
        } else {
          getUserActions().addTaskAction({
            type: "edit",
            data: {
              ...editedData,
              refreshFormData: true,
              key: rowId,
            },
          });
        }
      } catch (error) {
        console.error("Error while updating task in db:", error.message);
      }
    },
    closeInlineAdd() {
      this.$refs.dataGrid.cancelAddRow();
    },
    setListeners() {
      EventEmitter.on(SELECT_TASK_IN_ACTIVITY_GRID, this.selectTask);
      EventEmitter.on(REFRESH_ACTIVITY_HISTORY, this.refreshList);
      EventEmitter.on(CLOSE_INLINE_ADD, this.closeInlineAdd);
      EventEmitter.on(ADD_NEW_TASK_IN_ACTIVITY_GRID, this.handleAddNewTask);
    },
    removeListeners() {
      EventEmitter.off(SELECT_TASK_IN_ACTIVITY_GRID, this.selectTask);
      EventEmitter.off(REFRESH_ACTIVITY_HISTORY, this.refreshList);
      EventEmitter.off(ADD_NEW_TASK_IN_ACTIVITY_GRID, this.handleAddNewTask);
      EventEmitter.off(CLOSE_INLINE_ADD, this.closeInlineAdd);
    },
    updateFilters() {
      const filters = this.createCurrFilters();
      this.createdFilters = filters;
    },
    setUpdatedFiltersInGrid() {
      const filters = cloneDeep(this.createdFilters);
      this.options = {
        ...this.options,
        filters: filters,
      };
    },
    createCurrFilters() {
      const localFilters = [];
      const projectId = this.projectId;
      const resourceId = this.resourceId;
      const showClearedTasks = this.showClearedTasks;
      const additionalTaskFilters = this.additionalTaskFilters;
      const mode = this.mode;
      const showOnlyNextActionsTasks = this.isShowOnlyNextActionsTasksEnabled;

      // const currTaskId = this.currTaskId;
      const filters = addActivityListFilters({
        currFilters: localFilters,
        projectId,
        resourceId,
        isInResourceMode: mode === "resource" || this.mode === "resource-edit",
        showAllTasks: showClearedTasks,
        additionalTaskFilters: this.useAdditionalTaskFilters
          ? additionalTaskFilters
          : {},
        removeDueFilter: this.useNextActionModeConfig,
        showOnlyNextActionsTasks: showOnlyNextActionsTasks,
        resourceTypeToIgnore: this.resourceTypeToIgnore,
        useAreaAndResourceIds: this.additionalFilterOpts?.useAreaAndResourceIds,
        isNoProjectNodeSelected:
          this.additionalFilterOpts?.isNoProjectNodeSelected,
        noProjectNodeId: this.additionalFilterOpts?.noProjectNodeId,
      });
      // eslint-disable-next-line no-debugger
      // debugger;
      return filters;
    },
    async selectTask(taskIdData, onlySelect, refreshList, setAsRows) {
      if (!setAsRows) {
        this.taskIdToSelectInGrid = taskIdData;
      }

      this.$refs.dataGrid.disableRowViewCheck();

      if (!setAsRows && !onlySelect) {
        this.$refs.dataGrid.setActiveRow(taskIdData);
      }

      if (setAsRows) {
        this.$refs.dataGrid.setSelectedRows(taskIdData);
      } else {
        this.$refs.dataGrid.setRowIdsForScroll(taskIdData);
      }

      if (refreshList) {
        this.refreshList();
      }
      // this.$refs.dataGrid.selectRowByKey(taskId, true, true);
    },
    clearSelection() {
      this.$refs.dataGrid.clearActiveRow();
      this.$refs.dataGrid.deselectPreviousRows({});
    },

    handleAddNewTask(data = {}, useDefaultTaskType = true) {
      if (this.isActive && this.actionsAllowed.includes("add")) {
        const dataToUse = { ...data };
        if (useDefaultTaskType) {
          dataToUse.taskType = "task";
        }
        this.$refs.dataGrid.addNewRow({ ...dataToUse });
      }
    },

    changeGroupAndSortConfig() {
      if (this.useNextActionModeConfig) {
        let sortOptIndex = 9;
        let groupOptIndex = 5;

        if (this.isShowOnlyNextActionsTasksEnabled) {
          sortOptIndex = 12;
          groupOptIndex = 3;
        }

        const sortOpt = sortOpts[sortOptIndex];
        const groupOpt = groupOpts[groupOptIndex];

        this.options = {
          ...this.options,
          sortBy: sortOpt,
          groupBy: groupOpt || "",
        };
      }
    },

    handleTaskSettingsChange(n, o) {
      if (
        n.statusBtnPosition !== o.statusBtnPosition ||
        n.showEditBtn !== o.showEditBtn
      ) {
        this.toggleColumns();
      }
    },
    toggleColumns() {
      const grid = this.$refs.dataGrid;

      let hideFlagCell = false;

      if (
        checkTaskSettingsIsNotEmpty(this.taskSettings) &&
        checkStatusBtnPosIsAreaCell(this.taskSettings)
      ) {
        hideFlagCell = true;
      }

      const showEditBtn = this.checkIfEditBtnEnabled();

      if (showEditBtn) {
        grid.showColumn(["edit-btn"]);
      } else {
        grid.hideColumn(["edit-btn"]);
      }
      if (hideFlagCell) {
        grid.hideColumn(["flag"]);
      } else {
        grid.showColumn(["flag"]);
      }
    },
    setCollapsedGroups() {
      let collapsedGroups = this.getCollapsedGroupsList();

      // if (this.checkIfResourceModeAndSelectionEmpty()) {
      //   if (
      //     !this.isSearchKeywordValid &&
      //     checkTaskAdditionalFiltersEmpty(getResourceViewTaskFilters())
      //   ) {
      //     collapsedGroups = [...DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS];
      //   } else {
      //     collapsedGroups = [];
      //   }
      // }

      this.$refs.dataGrid.setCollapsedGroupList(collapsedGroups);
    },
    handleKeydown(e) {
      if (isEscKey(e)) {
        const isGridEditingActive = this.$refs.dataGrid.isEditingActive();
        if (isGridEditingActive) {
          e.stopImmediatePropagation();
          e.stopPropagation();
          e.preventDefault();
        }
      }
    },
  },
  computed: {
    collapsedGroupsList() {
      // let collapsedGroups = this.collapsedGroups;
      // if (this.checkIfResourceModeAndSelectionEmpty()) {
      //   if (
      //     !this.isSearchKeywordValid &&
      //     checkTaskAdditionalFiltersEmpty(getResourceViewTaskFilters())
      //   ) {
      //     collapsedGroups = [...DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS];
      //   } else {
      //     console.log("ALL RASS");
      //     collapsedGroups = [];
      //   }
      // }
      return this.getCollapsedGroupsList();
    },

    ...mapGetters("resourceInfo", {
      storedTaskDataInResource: "storedTaskData",
    }),
    ...mapGetters("task", [
      "tasks",
      "rawTasksMap",
      "rawTasks",
      "projects",
      "categories",
      "showClearedTasks",
      "additionalTaskFilters",
      "searchQuery",
      "selectedOptsList",
      "isProjectsResourceModeEnabled",
    ]),
    ...mapGetters(["isGoogleCalSyncEnabled", "currActiveView", "taskSettings"]),
    ...mapGetters("editForm", {
      isTaskEditFormOpened: "openNewForm",
      editTaskData: "taskData",
    }),
    ...mapGetters("resourcesData", {
      resourceInternalGroups: "internalCollapsedGroups",
    }),
    collapsedGroups() {
      return this.mode === "resource" || this.mode === "resource-edit"
        ? this.resourceInternalGroups
        : [];
    },
    refreshData() {
      return {
        projectId: this.projectId,
        listLoaded: this.listLoaded,
        resourceId: this.resourceId || "",
      };
    },
    isShowOnlyNextActionsTasksEnabled() {
      return this.showOnlyNextActionsTasks && !this.showAllOrderedTasks;
    },
  },
  watch: {
    projectId: {
      handler() {
        this.updateFilters();
      },
    },
    resourceId: {
      handler(n) {
        this.setCollapsedGroups();
        this.updateFilters();
      },
      deep: true,
    },
    showClearedTasks: {
      handler() {
        this.updateFilters();
      },
    },
    additionalTaskFilters: {
      handler() {
        this.setCollapsedGroups();
        this.updateFilters();
      },
    },
    createdFilters: {
      handler(newCreatedFilters, oldCreatedFilters) {
        if (!isEqual(newCreatedFilters, oldCreatedFilters)) {
          this.setUpdatedFiltersInGrid();
        }
      },
      deep: true,
    },
    isShowOnlyNextActionsTasksEnabled: {
      handler() {
        this.changeGroupAndSortConfig();
        this.updateFilters();
      },
    },
    showAllOrderedTasks: {
      async handler() {
        await Vue.nextTick();
        this.changeGroupAndSortConfig();
        this.refreshList();
      },
    },
    refreshData: {
      handler(n, o) {
        if (
          !isEqual(n, o) &&
          !isEmpty(n) &&
          !isEmpty(o) &&
          n.projectId === o.projectId &&
          isEqual(n.resourceId, o.resourceId) &&
          n.listLoaded
        ) {
          this.refreshList();
        }
      },
      deep: true,
    },
    searchQuery() {
      this.setCollapsedGroups();
      this.refreshList();
      // this.updateFilters();
    },
    taskSettings: {
      handler(n, o) {
        this.handleTaskSettingsChange(n, o);
      },
      deep: true,
    },
    currNav: {
      handler() {
        if (this.$route.name === "Dashboard") {
          this.toggleColumns();
        }
      },
      // immediate: true,
    },
  },
};
</script>
<style lang="scss">
.history-table .datagrid-row[role="row"]:hover:not(.row-editing) {
  // background: none !important;
  .edit-task-btn {
    display: inline-block;
  }

  .contingent-toggle-btn {
    display: inline-block;
  }

  // .datagrid-cell {
  //   background: none !important;
  // }

  // .datagrid-group {
  //   background: none !important;
  // }
  .intend-cell {
    background: #fff !important;
  }
  .datagrid-cell:not(.intend-cell) {
    // background: none !important;
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  // .taskType-cell {
  //   border-left: 1px solid var(--primary-color) !important;
  // }
  .priority-cell {
    border-left: 1px solid var(--primary-color) !important;
  }

  .datagrid-cell.row-handler-cell {
    border-left: 1px solid var(--primary-color) !important;
  }

  .datagrid-cell.row-handler-cell + .datagrid-cell.priority-cell {
    border-left: 0px !important;
  }
}

.history-table .datagrid-row[role="row"] .datagrid-cell {
  background: #fff;
}

.history-table .datagrid-row[role="row"].row-selected:not(.row-editing) {
  // .datagrid-cell {
  //   background: none;
  // }
  // .intend-cell {
  //   background: #fff;
  // }
  .datagrid-cell:not(.intend-cell) {
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  .priority-cell {
    border-left: 1px solid var(--primary-color) !important;
  }

  .datagrid-cell.row-handler-cell {
    border-left: 1px solid var(--primary-color) !important;
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
    background: var(--selection-color) !important;
  }

  .datagrid-cell.row-handler-cell + .datagrid-cell.priority-cell {
    border-left: 0px !important;
  }

  // .row-handler-cell {
  //   // border-left: 1px solid var(--primary-color) !important;
  // }
}

#activity-tasks-list .datagrid-header .datagrid-header-cell.taskType-cell {
  border: 0 !important;
}

// Disable it for now
// #activity-tasks-list .row-selected.is-task-older:not(.row-editing) {
//   & .taskType-cell {
//     border-left: 1px solid var(--primary-color) !important;
//   }
//   & .datagrid-cell {
//     background-color: #d7d6d6 !important;
//     border-top: 1px solid var(--primary-color) !important;
//     border-bottom: 1px solid var(--primary-color) !important;
//   }
// }

.history-table
  .datagrid-row[role="row"].row-active:not(.row-editing):not(.row-selected) {
  .datagrid-cell:not(.intend-cell) {
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }

  .priority-cell {
    border-left: 1px solid var(--primary-color) !important;
  }
  // .taskType-cell {
  //   border-left: 1px solid var(--primary-color) !important;
  // }
  .datagrid-cell.row-handler-cell {
    border-left: 1px solid var(--primary-color) !important;
  }

  .datagrid-cell.row-handler-cell + .datagrid-cell.priority-cell {
    border-left: 0px !important;
  }
}

.history-table .vue-recycle-scroller__item-wrapper {
  margin-bottom: calc(80% - 34px);
}

.history-table .datagrid-row[role="row"]:hover:not(.row-editing) {
  .edit-item-btn {
    display: inline-block;
  }
}
// .row-selected.is-older-task
//   .datagrid-cell:not(.intend-cell):not(.row-handler-cell) {
//   background-color: #fffcca !important;
// }
</style>
