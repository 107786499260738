<template>
  <div class="edit-task-form-wrapper fill-height">
    <div v-if="showForm" class="content-wrapper">
      <CoreLoaderWrapper v-if="showLoading" :loader-size="48">
        <template #default="{ size }">
          <v-progress-circular
            :size="size"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
      </CoreLoaderWrapper>
      <div id="main-header">
        <div class="header-content pl-4 pr-4">
          <ActionsBar
            @save="handleSave"
            @close="handleClose"
            @edit-item:select="handleEditItemSelect"
            :isEditStarted="isEditStarted"
            :currItemIndex="currItemIndex"
            :totalItemCount="totalItemCount"
            :paginationRowClass="paginationRowClass"
            :editType="editType"
            :title="title"
            :useBtnRightSide="useBtnRightSide"
            :useBeforeControls="useBeforeControls"
            :paginationBtnIconColor="paginationBtnIconColor"
            :showPagination="showPagination"
            :showDivider="false"
          >
            <!-- <template #header-right>
              <slot name="header-right"></slot>
            </template> -->
            <template v-if="useBtnRightSide" #bar-btn-right>
              <slot name="header-left-side"></slot>
            </template>
            <template v-if="useHeaderCenter" #center>
              <slot name="header-center"> </slot>
            </template>
            <template v-if="useBeforeControls" #before-controls>
              <slot name="before-controls"> </slot>
            </template>
          </ActionsBar>
        </div>
      </div>
      <div class="main-content-wrapper pl-4 pr-4">
        <slot name="main-content"> </slot>
      </div>
    </div>
  </div>
</template>
<script>
import CoreLoaderWrapper from "@/core/components/CoreLoaderWrapper";
import ActionsBar from "@/components/FormComponents/ActionsBar.vue";
export default {
  components: {
    ActionsBar,
    CoreLoaderWrapper,
  },
  props: {
    title: {
      type: String,
    },
    showForm: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: String,
      default: "",
    },
    isEditStarted: {
      type: Boolean,
      default: false,
    },
    currItemIndex: {
      type: Number,
    },
    totalItemCount: {
      type: Number,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
    useBtnRightSide: {
      type: Boolean,
    },
    useHeaderCenter: {
      type: Boolean,
    },
    useBeforeControls: {
      type: Boolean,
    },
    paginationRowClass: {
      type: String,
    },
    paginationBtnIconColor: {
      type: String,
    },
    showPagination: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleEditItemSelect(type, event) {
      this.$emit("edit-item:select", type, event);
    },
    handleClose(e) {
      this.$emit("close", e);
    },
    handleSave(e) {
      this.$emit("save", e);
    },
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 7px;
}

.e-toolbar-wrapper {
  height: 45px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1;
}

.main-content-wrapper {
  padding-bottom: 20px;
  margin-top: -8px;
}

.rowrap {
  flex-wrap: nowrap;
}

#main-header {
  position: sticky;
  left: 0px;
  right: 0px;
  min-height: 48px;
  top: -8px;

  /* height: 50px;
  top: -10px; */
  z-index: 2;
  -webkit-backface-visibility: hidden;
}

#main-header .header-content {
  min-height: 40px;
  padding-top: 10px;
  padding-bottom: 8px;
  position: sticky;
  -webkit-position: sticky;
  background-color: #fff;
  top: 0px;
  margin-top: -8px;
  z-index: 3;
}

/* PSEUDO ELEMENTS to create drop-shadow */
#main-header::before {
  top: 30px;
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.5);

  content: "";
  display: block;
  height: 8px;
  position: sticky;
  -webkit-position: sticky;
  /* content: "";
  display: block;
  height: 16px;
  position: sticky;
  -webkit-position: sticky; */
}
#main-header::after {
  content: "";
  display: block;
  height: 8px;
  position: sticky;
  -webkit-position: sticky;
}

#main-header::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  background: linear-gradient(
    white 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    transparent
  );
  top: 0px;
  /* top: 44px; */
  /* cover should fall over shadow */
  z-index: 2;
}
</style>
