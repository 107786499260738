<template>
  <div class="date-cell-value">
    {{ formattedDate }}
  </div>
</template>
<script>
import { columnPropsMixin } from "@/core/components/DataTable";
import { formatDate, isValidDate } from "@/helpers/dates";
import { DATE_WITH_TIME_FORMAT_IN_USE } from "@/variables/dates";
export default {
  mixins: [columnPropsMixin],
  computed: {
    formattedDate() {
      const field = this.fieldName;
      const data = this.data;
      const value = data[field];
      let valueToDisplay = "None";

      if (isValidDate(value)) {
        valueToDisplay = formatDate(value, DATE_WITH_TIME_FORMAT_IN_USE);
      }

      return valueToDisplay;
    },
  },
};
</script>
<style scoped>
.date-cell-value {
  text-align: center;
}
</style>
