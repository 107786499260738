import { MENTION_REGEX } from "@/variables/regex";
import { isEmpty } from "lodash";
import { getResourceDataByTag } from "./resources";
export const getAllMentionsFromStr = (str) => {
  const mentions = str?.match(MENTION_REGEX);
  return mentions?.map((m) => m.trim());
};

export const replaceMentionTagsWithTagEls = (
  textToGetTagEls,
  mentionsList,
  textToModify
) => {
  const tempDiv = document.createElement("div");
  let processedText = textToModify;
  tempDiv.innerHTML = textToGetTagEls;
  mentionsList.forEach((tag) => {
    const tagData = getResourceDataByTag(tag.substring(1));
    if (!isEmpty(tagData)) {
      const tagEl = tempDiv.querySelector(`span[data-id='${tagData.key}']`);
      if (tagEl) {
        processedText = processedText.replace(tag, tagEl.outerHTML);
      }
    }
  });
  tempDiv.remove();
  return processedText;
};
