import DbStoreCollectionInterface from "../services/DbStoreInterface";

const actionHistory = new DbStoreCollectionInterface("actions-history");

const MAX_ACTIONS_COUNT = 50;

const DEFAULT_ACTIONS = {
  ADD: "add",
  EDIT: "edit",
  BATCH_EDIT: "batch-edit",
  BATCH_ADD: "batch-add",
  DELETE: "delete",
  BATCH_DELETE: "batch-delete",
};
export const TASK_ACTIONS = {
  ADD: "add",
  EDIT: "edit",
  BATCH_EDIT: "batch-edit",
  BATCH_ADD: "batch-add",
  DELETE: "delete",
  BATCH_DELETE: "batch-delete",
  NORMAL_RECURRING_EDIT: "normal-recurring-edit",
  NORMAL_TO_RECURRING: "normal-to-recurring",
  REPEAT_IN_TO_RECURRING: "repeat-in-to-recurring",
  REPEAT_EVERY_TO_REPEAT_IN: "repeat-every-to-repeat-in",
  REPEAT_EVERY_TO_NORMAL: "repeat-every-to-normal",
  NORMAL_RECURRING_DELETE: "normal-recurring-delete",
  ACTIONS_BATCH: "task-actions-batch",
  GROUP_OR_REORDER_BATCH: "task-group-reorder-batch",
  CONVERTED_TO_NOTES: "task-converted-to-notes",
};

export const PROJECT_ACTIONS = {
  ...DEFAULT_ACTIONS,
};

export const OBJECTIVE_ACTIONS = {
  ...DEFAULT_ACTIONS,
};

export const CATEGORY_ACTIONS = {
  ...DEFAULT_ACTIONS,
  REORDER_OR_MOVE: "reorder_or_move",
};

export const NOTES_ACTIONS = {
  ...DEFAULT_ACTIONS,
  CONVERTED_TO_TASKS: "notes-converted-to-tasks",
};

export const DIRECTORY_ACTIONS = {
  ...DEFAULT_ACTIONS,
  REORDER_OR_MOVE: "reorder_or_move",
};

export const RESOURCE_CATEGORY_ACTIONS = {
  ...DEFAULT_ACTIONS,
  REORDER_OR_MOVE: "reorder_or_move",
};

export const RESOURCE_ACTIONS = {
  ...DEFAULT_ACTIONS,
  MOVED_OR_REORDERED: "moved-or-reordered",
  // CONVERTED_TO_TASKS: "notes-converted-to-tasks",
};

export const ACTION_ON_TYPES = {
  NOTES: "notes",
  CATEGORY: "category",
  DIRECTORY: "directory",
  OBJECTIVE: "objective",
  TASK: "task",
  PROJECT: "project",
  RESOURCES_CATEGORY: "resource-category",
  RESOURCE: "resource",
  ALL: "all",
};
export class UserActions {
  userId = "";
  actions = [];
  actionsHistory = [];
  // #instance = null;
  constructor(userId) {
    // if (this.#instance) {
    //   return this.#instance;
    // }
    // this.#instance = this;
    this.userId = userId;
    this.init();
  }

  async init() {
    try {
      const record = await actionHistory.getByKey(this.userId);
      if (record.exists) {
        this.actions = record.data().actions;
        return;
      }

      await actionHistory.set(this.userId, {
        actions: [],
      });
    } catch (error) {
      console.error("Unable to set actions: ", error.message);
    }
  }
  async addAction(actionData) {
    try {
      this.actions.unshift(actionData);
      this.actionsHistory = [];
      if (this.actions.length > MAX_ACTIONS_COUNT) {
        this.actions.pop();
      }

      const res = await actionHistory.set(this.userId, {
        actions: this.actions,
      });

      return res;
    } catch (error) {
      console.error("Unable to add action: ", error.message);
    }
  }
  async addBatchAction(actionData) {
    await this.addAction({
      data: actionData.data,
      on: actionData.on || "all",
    });
  }

  async addTaskAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: "task",
    });
  }

  async addProjectAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: "project",
    });
  }

  async addCategoryAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: ACTION_ON_TYPES.CATEGORY,
    });
  }

  async addObjectiveAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: ACTION_ON_TYPES.OBJECTIVE,
    });
  }

  async addNoteAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: ACTION_ON_TYPES.NOTES,
    });
  }

  async addDirAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: ACTION_ON_TYPES.DIRECTORY,
    });
  }

  async addResourceCateAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: ACTION_ON_TYPES.RESOURCES_CATEGORY,
    });
  }

  async addResourceAction(actionData) {
    await this.addAction({
      data: actionData.data,
      type: actionData.type,
      on: ACTION_ON_TYPES.RESOURCE,
    });
  }

  async removeActionByIndex(actionIndex) {
    try {
      this.actions.splice(actionIndex, 1);
      await actionHistory.set(this.userId, {
        actions: this.actions,
      });
    } catch (error) {
      console.error("Unable to update actions: ", error.message);
    }
  }

  removeActionFromHistoryByIndex(index) {
    this.actionsHistory.splice(index, 1);
  }

  storeActionInHistory(actionData) {
    this.actionsHistory.unshift(actionData);
    if (this.actionsHistory.length > MAX_ACTIONS_COUNT) {
      this.actionsHistory.pop();
    }
  }

  getActionsHistory() {
    return this.actionsHistory;
  }

  getActions() {
    return this.actions;
  }
}

let userActions;

export const createUserActionsHistory = (userId) => {
  if (!userActions) {
    userActions = new UserActions(userId);
  }

  return userActions;
};

export const getUserActions = () => {
  if (!userActions) {
    throw new Error("User actions class needs to initialized first!");
  }
  return userActions;
};
