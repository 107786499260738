const isEscKey = (e) => {
  let isEscKeyTriggered = false;
  if (e.key && e.key === "Escape") {
    isEscKeyTriggered = true;
  } else {
    const keyCode = e.keyCode ? e.keyCode : e.which;

    isEscKeyTriggered = keyCode === 27;
  }

  return isEscKeyTriggered;
};

export default isEscKey;
