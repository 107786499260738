<template>
  <div class="actions-cell-wrapper">
    <EditBtn
      v-if="showEditBtn"
      :cssClass="editBtnClassNames"
      @click="editTask"
      :btnText="editPenLabel"
      :btnIcon="editBtnIcon"
      :btnIconSize="editBtnIconSize"
      :btnIconColor="greyIconColor"
    />

    <!-- <v-icon
      class="descr-icon"
      v-show="showDescrIcon"
      :color="greyIconColor"
      :size="16"
    >
      $ctiNoteIcon
    </v-icon> -->
  </div>
</template>
<script>
import userDetailsMixin from "@/mixins/userDetailsMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import { mapActions, mapGetters } from "vuex";
import EditBtn from "@/components/GridComponents/EditBtn";
import {
  // DARK_GREY_ICON_COLOR,
  NEW_DARK_ICON_COLOR,
} from "@/variables/colors";

import { emptyDescrTypes as emptyTaskDescrTypes } from "@/variables/common";
export default {
  mixins: [userDetailsMixin, columnPropsMixin, tasksHelpersMixin],
  components: {
    EditBtn,
  },

  methods: {
    editTask(e) {
      e.stopPropagation();
      this.openTaskDataInEditForm({
        taskData: { ...this.data },
        gridApi: this.cell.api,
        isInNextActionsMode: this.isInNextActionsMode,
      });
    },

    ...mapActions("editForm", ["showEditTaskDialog"]),
    ...mapActions("toast", ["showToast"]),
  },
  computed: {
    ...mapGetters("user", ["userInfo"]),
    ...mapGetters("task", [
      "rawTasksMap",
      "objectivesMap",
      "currNav",
      "isGlobalViewEnabled",
      "tasks",
    ]),
    greyIconColor() {
      return NEW_DARK_ICON_COLOR;
    },
    editPenLabel() {
      return "Click to edit";
    },

    editBtnClassNames() {
      // const descrData = this.data.description;
      // return emptyTaskDescrTypes.includes(descrData)
      //   ? "edit-item-btn"
      //   : "edit-btn-always-visible";

      return "edit-item-btn";
    },

    showEditBtn() {
      return !this.extraData?.doNotShowEditBtn;
    },
    editBtnIcon() {
      // const descrData = this.data.description;
      // return !emptyTaskDescrTypes.includes(descrData) && !this.rowHovered
      //   ? "$ctiNoteIcon"
      //   : "mdi-pencil";

      return "mdi-pencil";
    },
    editBtnIconSize() {
      // const descrData = this.data.description;
      // return !emptyTaskDescrTypes.includes(descrData) && !this.rowHovered
      //   ? 16
      //   : 18;

      return 18;
    },
    showDescrIcon() {
      return !emptyTaskDescrTypes.includes(this.data.description);
    },
  },
};
</script>
<style scoped>
.hover-icon {
  display: none;
}

.edit-item-btn {
  display: none;
  padding-bottom: 2px;
}

.actions-cell-wrapper {
  text-align: center;
}

.actions-cell-wrapper >>> .edit-btn-always-visible {
  display: inline-block;
  padding-bottom: 2px;
}

.descr-icon {
  margin-left: -4px;
}
</style>
