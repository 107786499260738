import {
  getUserActions,
  PROJECT_ACTIONS,
  TASK_ACTIONS,
} from "@/helpers/actionHistory";
import { isValidDate } from "@/helpers/dates";
import {
  addOrRemoveOrUpdateTasksInLocalTasksList,
  checkIfTaskHasReminder,
  createDataForRecurringTask,
  createDateTimeFromDue,
  createGoogleEventIdByDateAndTaskId,
  createRecurringDatesFromTaskRules,
  createReminderTasks,
  createTaskDataForGoogleCal,
  excludeDatesInTaskRules,
  fillRecurrenceOptsInTaskData,
  getDateAndTimeFromDueDate,
  getRecurrenceIdByDateFromRecurrences,
  getRecurringTasks,
  getReminderTasksLinkedToTaskId,
  isTaskVirtual,
  parseRuleDataFromTaskRecurrenceRules,
  processTask,
  removeTasksFromRecurringTasksMap,
} from "@/helpers/tasks";
import removeProps from "@/utils/removeProps";
import { isSameDay } from "date-fns";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { mapGetters, mapActions } from "vuex";

import googleCalHelpersMixin from "@/mixins/googleCalHelpersMixins";
import Vue from "vue";
import eventEmitter from "@/helpers/eventEmitter";
import { REFRESH_FORM_DATA, REFRESH_TREE_EVENT } from "@/variables/events";
import DatabaseInterface from "@/services/DatabaseInterface";
import EventEmitter from "@/helpers/eventEmitter";
import { increment } from "@/utils/number";
import { createDbDataFromProvidedData } from "@/helpers/dbHelpers";
import {
  changeCatOfProjectResources,
  getProjectResourcesFromText,
} from "@/helpers/resources";
import GoogleCalHelpers from "@/helpers/googleCalHelpers";

const mixin = {
  mixins: [googleCalHelpersMixin],
  methods: {
    ...mapActions("editForm", ["setTaskData"]),
    getRawDataOfTheRecurringTask(currTaskData) {
      const isVirtual = currTaskData.isVirtual;
      let currRawData = {};
      if (!isVirtual) {
        currRawData = this.rawTasksMap[currTaskData.key];
      } else {
        currRawData = cloneDeep(this.rawTasksMap[currTaskData.linkedTo]);
        currRawData = {
          ...currRawData,
          ...getDateAndTimeFromDueDate(currTaskData.dueAsDate),
        };
      }

      return currRawData;
    },
    async updateSingleRecurringTask(
      currTaskData,
      providedUpdatedData,
      refreshList = true,
      updateLocalDataForEdit,
      storeUserAction = true,
      updatedProjectData = {},
      updatedObjectiveData = {},
      extraEditActionData = {},
      updateProjectResources = true
    ) {
      //   try {
      let addToDb = true;
      let tasksToAdd = [];
      let dbUpdates = {};
      let tasksToUpdate = [];
      let reminderTasksToRemove = [];
      let reminderTasksToAdd = [];
      let googleTasksUpdateList = [];
      let removeTaskFromList = false;
      let rulesChanged = false;
      let excludedDates = [];
      let rawDataForLocalSave = {};
      if (!currTaskData.isVirtual) {
        addToDb = false;
      } else {
        removeTaskFromList = true;
      }
      const mainId = currTaskData.linkedTo || currTaskData.key;
      const mainTaskData = this.rawTasksMap[mainId];
      const mainTaskRules = mainTaskData.recurrence;

      const clonedMainTaskData = cloneDeep(mainTaskData);
      const newDueDate = providedUpdatedData.dueAsDate;
      const currDueDate = currTaskData.dueAsDate;
      let newData = cloneDeep(providedUpdatedData);
      let dateToExclude;
      let recreateTasks = false;
      let updateMain = false;
      let editedDataForMainTask = {};
      let mainTaskUpdateData = {};
      let localExtraDataForCompletedTask = {};
      newData.linkedTo = mainId;
      newData = removeProps(newData, ["recurrence"]);

      if (!currTaskData.completed && newData.completed) {
        localExtraDataForCompletedTask = {
          keepItVisible: true,
        };
      }
      let {
        newTaskData,
        editProjectData,
        editedTaskData,
        updatedTaskData,
        onlyProjectPriorityChanged,
        projectDbUpdates,
      } = createDataForRecurringTask(
        newData,
        addToDb ? "add" : "update",
        undefined,
        currTaskData,
        false,
        false,
        false,
        updatedProjectData,
        updatedObjectiveData
      );

      newTaskData.key = currTaskData.key;

      const isAreaChanged = newTaskData.project !== currTaskData.project;

      const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules({
        recurrence: mainTaskRules,
        dueAsDate: createDateTimeFromDue(mainTaskData.due, mainTaskData.time),
      });

      excludedDates.push(currDueDate);
      if (newDueDate !== undefined) {
        if (!isEqual(currDueDate, newDueDate)) {
          if (
            isValidDate(currDueDate) &&
            isValidDate(newDueDate) &&
            !isSameDay(newDueDate, currDueDate)
          ) {
            dateToExclude = newDueDate;
          } else if (isValidDate(currDueDate) && !isValidDate(newDueDate)) {
            dateToExclude = currDueDate;
          } else if (isValidDate(newDueDate) && !isValidDate(currDueDate)) {
            dateToExclude = newDueDate;
          }
        }
      }

      if (isValidDate(dateToExclude)) {
        excludedDates.push(dateToExclude);
      }

      excludeDatesInTaskRules(excludedDates, parsedRuleSet);
      const convertedRules = parsedRuleSet.valueOf().slice(1);
      if (!isEqual(convertedRules, mainTaskRules) && !isEmpty(convertedRules)) {
        mainTaskUpdateData = {
          recurrence: convertedRules,
        };
        rulesChanged = true;
        clonedMainTaskData.recurrence = convertedRules;

        if (mainTaskData.googleEventId) {
          googleTasksUpdateList.push({
            key: mainTaskData.googleEventId,
            updates: createTaskDataForGoogleCal(
              {
                recurrence: convertedRules,
                taskKey: mainTaskData.key,
              },
              {
                ...mainTaskData,
                dueAsDate: createDateTimeFromDue(
                  mainTaskData.due,
                  mainTaskData.time
                ),
              }
            ),
          });
        }
      }

      const allReminderTasksLinkedToKey = getReminderTasksLinkedToTaskId(
        this.processedTasks,
        currTaskData.key
      );
      if (!isEmpty(allReminderTasksLinkedToKey)) {
        reminderTasksToRemove.push(...allReminderTasksLinkedToKey);
      }

      if (addToDb) {
        editedTaskData.key = newTaskData.key;
        newTaskData.googleEventId = "";
        newTaskData.googleCalendarId = "";
        if (this.isGoogleCalSyncEnabled && newTaskData.due !== "none") {
          if (currTaskData.isFromGoogle) {
            newTaskData.googleEventId = createGoogleEventIdByDateAndTaskId(
              currTaskData.googleEventId.split("_")[0],
              currDueDate
            );
          } else {
            newTaskData.googleEventId = createGoogleEventIdByDateAndTaskId(
              mainId,
              currDueDate
            );
          }
          newTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
        }

        if (checkIfTaskHasReminder(newTaskData) && !newTaskData.completed) {
          reminderTasksToAdd.push(...createReminderTasks(newTaskData));
        }

        tasksToAdd.push(
          processTask({ ...newTaskData, ...localExtraDataForCompletedTask })
        );

        dbUpdates[`/tasks/${newTaskData.key}`] = { ...newTaskData };
        // dbTasksAdd.push(newTaskData);
        rawDataForLocalSave = { ...newTaskData };
      } else {
        const taskToProcess = {
          ...this.rawTasksMap[currTaskData.key],
          ...updatedTaskData,
          ...localExtraDataForCompletedTask,
        };

        if (checkIfTaskHasReminder(taskToProcess) && !taskToProcess.completed) {
          reminderTasksToAdd.push(...createReminderTasks(taskToProcess));
        }
        dbUpdates[`/tasks/${updatedTaskData.key}`] = { ...updatedTaskData };
        // dbTasksUpdate.push({
        //   key: currTaskData.key,
        //   updates: { ...updatedTaskData },
        // });
        tasksToUpdate.push({
          key: currTaskData.key,
          updates: processTask(taskToProcess),
        });
        rawDataForLocalSave = { ...taskToProcess };
      }

      const localListUpdates = {
        tasksToAdd,
        tasksToUpdate,
      };

      if (removeTaskFromList || rulesChanged) {
        const recurrences =
          createRecurringDatesFromTaskRules(clonedMainTaskData);
        const firstRecurrence = recurrences[0];

        const firstTaskData = getRecurrenceIdByDateFromRecurrences(
          firstRecurrence,
          clonedMainTaskData.key
        );

        let recurringTasksToUpdate = [];

        if (
          removeTaskFromList &&
          newTaskData.project === clonedMainTaskData.project
        ) {
          recreateTasks = true;
          updateMain = true;

          const dataToUpdate = {
            order: newTaskData.order,
            userPosition: increment(newTaskData.userPosition, 1),
            customPosition: newTaskData.customPosition,
            customPositionIndex: increment(newTaskData.customPositionIndex, 1),
          };

          mainTaskUpdateData = {
            ...mainTaskUpdateData,
            ...dataToUpdate,
          };
          editedDataForMainTask = {
            order: mainTaskData.order,
            userPosition: mainTaskData.userPosition,
            changedProps: { ...dataToUpdate },
            key: mainTaskData.key,
          };
          const currRecurringTasks = getRecurringTasks(
            this.processedTasks,
            "list",
            {
              linkedTo: clonedMainTaskData.key,
              filter: true,
              filterOpts: [isTaskVirtual],
            }
          );

          if (!isEmpty(currRecurringTasks)) {
            currRecurringTasks.forEach((currTask) => {
              const createdDataUpdate = {
                ...dataToUpdate,
              };
              if (
                !isEmpty(firstTaskData) &&
                firstTaskData.key === currTask.key
              ) {
                createdDataUpdate.isCalendarOnly = false;
              }
              recurringTasksToUpdate.push({
                key: currTask.key,
                updates: {
                  ...createdDataUpdate,
                },
              });
            });
          }
        } else {
          if (!isEmpty(firstTaskData)) {
            recurringTasksToUpdate.push({
              key: firstTaskData.key,
              updates: {
                isCalendarOnly: false,
              },
            });
          }
        }

        localListUpdates.tasksToUpdate = [
          ...localListUpdates.tasksToUpdate,
          ...recurringTasksToUpdate,
        ];
      }

      if (removeTaskFromList) {
        localListUpdates.tasksToRemove = [currTaskData];
      }

      if (!isEmpty(reminderTasksToRemove)) {
        if (!isEmpty(localListUpdates.tasksToRemove)) {
          localListUpdates.tasksToRemove = [
            ...localListUpdates.tasksToRemove,
            ...reminderTasksToRemove,
          ];
        } else {
          localListUpdates.tasksToRemove = [...reminderTasksToRemove];
        }
      }

      if (!isEmpty(reminderTasksToAdd)) {
        localListUpdates.verifyAdd = false;
        if (!isEmpty(localListUpdates.tasksToAdd)) {
          localListUpdates.tasksToAdd = [
            ...localListUpdates.tasksToAdd,
            ...reminderTasksToAdd,
          ];
        } else {
          localListUpdates.tasksToAdd = [...reminderTasksToAdd];
        }
      }

      if (!isEmpty(mainTaskUpdateData)) {
        dbUpdates = {
          ...dbUpdates,
          ...createDbDataFromProvidedData(
            {
              ...mainTaskUpdateData,
              key: mainTaskData.key,
            },
            mainTaskData.key,
            "/tasks"
          ),
        };
        // dbTasksUpdate.push({
        //   key: mainTaskData.key,
        //   updates: {
        //     ...mainTaskUpdateData,
        //   },
        // });
      }

      // let updatesForDb = {};

      if (!isEmpty(projectDbUpdates)) {
        dbUpdates = {
          ...dbUpdates,
          ...projectDbUpdates,
        };
      }

      // addOrRemoveOrUpdateTasksInDb({
      //   tasksToAdd: dbTasksAdd,
      //   tasksToUpdate: dbTasksUpdate,
      // });

      if (!isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }

      if (updateProjectResources && isAreaChanged) {
        const mentionedProjectResources = getProjectResourcesFromText(
          newTaskData.title
        );

        if (!isEmpty(mentionedProjectResources)) {
          changeCatOfProjectResources([
            {
              resources: mentionedProjectResources,
              catId: newTaskData.project,
              isTaskRef: true,
            },
          ]);
        }
      }
      addOrRemoveOrUpdateTasksInLocalTasksList(localListUpdates, refreshList);

      const finalEditedDataObj = {
        ...editedTaskData,
        key: editedTaskData.key,
        ...extraEditActionData,
      };

      let userActions = {
        type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
        data: {
          [mainTaskData.key]: {
            editedTasks: [
              {
                ...finalEditedDataObj,
              },
            ],
            updateMain,
            editedData: editedDataForMainTask,
            recreateTasks,
          },
        },
        on: "task",
      };

      if (!isEmpty(editProjectData)) {
        userActions = [
          {
            type: PROJECT_ACTIONS.EDIT,
            data: editProjectData,
            on: "project",
          },
          {
            ...userActions,
          },
        ];
      }

      if (storeUserAction) {
        if (Array.isArray(userActions)) {
          getUserActions().addBatchAction({
            data: userActions,
            on: "all",
          });
        } else {
          getUserActions().addTaskAction(userActions);
        }
      }

      if (removeTaskFromList) {
        removeTasksFromRecurringTasksMap({
          mainTaskId: clonedMainTaskData.key,
          tasksIds: [currTaskData.key],
        });
      }

      // Google code
      if (this.isGoogleCalSyncEnabled) {
        const dataToSend = addToDb
          ? {
              ...newTaskData,
              dueAsDate: createDateTimeFromDue(
                newTaskData.due,
                newTaskData.time
              ),
            }
          : {
              ...updatedTaskData,
              dueAsDate: createDateTimeFromDue(
                updatedTaskData.due,
                updatedTaskData.time
              ),
            };

        const dataForGoogleCal = {
          ...createTaskDataForGoogleCal(
            {
              ...dataToSend,
              taskKey: dataToSend.key,
            },
            currTaskData
          ),
        };

        this.findRecurringEventByIdOrDateAndUpdateIt(
          {
            currData: {
              dueAsDate: currDueDate,
              taskKey: newTaskData.key,
              googleEventId:
                newTaskData.googleEventId || currTaskData.googleEventId,
            },
            newData: dataForGoogleCal,
          },
          mainId
        );

        this.updateTasksInGoogleCal(googleTasksUpdateList);
      }

      await Vue.nextTick();

      if (onlyProjectPriorityChanged) {
        eventEmitter.emit(REFRESH_TREE_EVENT, true, false);
      }

      rawDataForLocalSave = fillRecurrenceOptsInTaskData(
        rawDataForLocalSave,
        mainTaskData
      );

      const localTaskDataForForm = processTask({
        ...rawDataForLocalSave,
        ...localExtraDataForCompletedTask,
      });
      if (updateLocalDataForEdit) {
        Vue.nextTick(() => {
          if (
            isAreaChanged &&
            !isEmpty(this.editTaskData?.key) &&
            this.editTaskData.project
          ) {
            this.setTaskAreaInFormAndRefresh(this.editTaskData.project);
          } else {
            this.setTaskData({ ...localTaskDataForForm });
            this.$nextTick(() => {
              EventEmitter.emit(REFRESH_FORM_DATA);
            });
          }
          // if()
        });
      }

      return {
        editedTaskData: finalEditedDataObj,
        userActions,
        mainTaskId: mainTaskData.key,
        onlyProjectPriorityChanged,
        rawDataForLocalSave,
        localTaskDataForForm,
        createdData: {
          ...(addToDb ? newTaskData : updatedTaskData),
          ...localExtraDataForCompletedTask,
        },
      };
    },
  },
  computed: {
    ...mapGetters("task", {
      rawTasksMap: "rawTasksMap",
      processedTasks: "tasks",
      // ["rawTasksMap", "tasks"]
    }),
    ...mapGetters("editForm", {
      editTaskData: "taskData",
    }),
    ...mapGetters(["isGoogleCalSyncEnabled"]),
  },
};

export default mixin;
