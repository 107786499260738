import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"30%","value":_vm.isSelectorOpen,"content-class":"add-resource-pop-up"},on:{"click:outside":_vm.closeDialog,"keydown":_vm.handleKeyDown}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]),_c(VCardText,[_c('div',[(_vm.isInAddMode)?[_c('div',{staticClass:"resource-tag-editor-wrapper"},[_c('ResourceTagEditorInput',{ref:"resourceTagInput",model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1),_c('div',{staticClass:"resource-title-editor-wrapper mt-4"},[_c('RichTextEditor',{ref:"titleEditor",staticClass:"resource-title-field resource-form-field",attrs:{"showToolbar":false,"stopTabProcess":"","editorId":"resource-title-cell-editor","useAsInput":true,"enableMentions":false,"placeholder":"Enter Name"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]:_vm._e(),_c('div',{staticClass:"mt-4"},[_c('ResourceTypeSelector',{ref:"resourceTypeSelector",attrs:{"placeholder":"Select Type","selectorClass":"resource-type-selector resource-form-field","menuProps":{
              'content-class': 'resource-type-selector-menu',
            }},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('div',{staticClass:"mt-4"},[_c('ResourceCateSelector',{ref:"resourceCateSelector",staticClass:"resource-type-cate-selector resource-form-field",attrs:{"optsList":_vm.createdOptsList,"cateType":_vm.type,"useProvidedOpts":"","useNoDataSlot":_vm.showAddGroupBtn,"placeholder":_vm.selectPlaceholder,"menuProps":{
              'content-class': 'resource-type-selector-menu',
            }},model:{value:(_vm.catId),callback:function ($$v) {_vm.catId=$$v},expression:"catId"}})],1)],2)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{ref:"saveBtn",staticClass:"resource-save-btn",attrs:{"color":"primary","depressed":"","text":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submitData.apply(null, arguments)},"keydown":_vm.handleKeyDown}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }