var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-nowrap fill-height resources-table"},[_c('div',{staticClass:"flex-grow-1 overflow-hidden"},[_c('DataTable',{ref:"dataGrid",attrs:{"list":_vm.resources,"columnsList":_vm.columns,"options":_vm.options,"whiteListBtns":[
        'resource-cate-add-form',
        'data-table-popup-menu',
        'resource-type-selector',
        'resource-type-cate-selector',
        'resource-save-btn',
        'resource-error-pop-up',
        'resource-area-error-pop-up',
        'v-overlay' ],"renderGroupCaption":_vm.createGroupItemsCaptions,"collapsedGroupsList":_vm.collapsedGroupsList,"itemSize":36},on:{"edit:enabled":_vm.handleEditEnabled,"row:added":_vm.handleRowAdd,"row:updated":_vm.handleRowUpdated,"rows:selected":_vm.handleRowsSelected,"filter":_vm.handleFilterSuccess,"list:processed":_vm.handleListProcessed,"group:toggled":_vm.handleGroupToggle,"key-pressed:delete":_vm.handleDeleteCall}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }