import {
  REFRESH_DATA_LIST_EVENT,
  REFRESH_NOTES_LIST,
  REFRESH_TASKS_EVENT,
} from "@/variables/events";
import EventEmitter from "@/helpers/eventEmitter";
import {
  checkIfStringContainsNewLine,
  escapeHtmlString,
  isHTML,
  replaceCharInString,
} from "@/utils/stringHelpers";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import {
  checkIfChildrenExists,
  getStoredChildrenOfNode,
  storeChildrenOfNode,
} from "@/helpers/nodeTreeHelpers";
import removeProps from "@/utils/removeProps";
import { DEFAULT_PROPS_TO_REMOVE } from "@/variables/items";
import { formatDate, hasDateSchema, isValidDate } from "./dates";
import { parseISO } from "date-fns";
import { HTML_BLOB_CONTENT_REGEX } from "@/variables/regex";
import { increment } from "@/utils/number";

export const alphaSorter = new Intl.Collator();
export const emitRefreshList = (reprocessList, type = "tasks") => {
  let eventToEmit = REFRESH_TASKS_EVENT;
  if (type === "notes") {
    eventToEmit = REFRESH_NOTES_LIST;
  } else if (type === "resources") {
    eventToEmit = REFRESH_DATA_LIST_EVENT;
  }
  EventEmitter.emit(eventToEmit, reprocessList);
};

export const isGroupRow = (rowData) => rowData.group !== undefined;

export const convertDescrValToHTMLString = (descr) => {
  let strToRtn = descr && descr.trim();
  if (strToRtn) {
    if (!isHTML(strToRtn)) {
      strToRtn = escapeHtmlString(strToRtn);
      if (checkIfStringContainsNewLine(strToRtn)) {
        strToRtn = replaceCharInString(strToRtn, /\r|\n/g, "</p><p>");
      }
      strToRtn = `<p>${strToRtn}</p>`;
    }
  } else {
    strToRtn = "<p></p>";
  }

  return strToRtn;
};

export const checkAndConvertDescrValToHTMLString = (descr) => {
  let descrToChange = descr && descr.trim();

  if (!isHTML(descrToChange)) {
    descrToChange = convertDescrValToHTMLString(descrToChange);
  }

  return descrToChange;
};

export const getUniqueName = (
  newName,
  allItems,
  index = 0,
  matchType = "object",
  propToUse = "name",
  nameModifier
) => {
  let checkName = newName;
  if (index) {
    if (nameModifier) {
      checkName = nameModifier(checkName, index);
    } else {
      checkName = `${checkName} ${index}`;
    }
  }

  const nameExists =
    allItems.filter((f) =>
      matchType === "object" ? f[propToUse] === checkName : f === checkName
    ).length > 0;
  return nameExists
    ? getUniqueName(
        newName,
        allItems,
        increment(index, 1),
        matchType,
        propToUse,
        nameModifier
      )
    : checkName;
};

const createCount = (treeNode, itemsMap, selectedNodes, opts) => {
  const {
    classAttribute,
    setActionAttris,
    actionType,
    itemMainType,
    itemSubType,
    showItemsAsChildren,
    extraItemData = {},
    isSearchEnabled,
    verifierForAttris,
    extraAttrisCreator,
    sortItemsAlphabetically,
  } = opts;

  let itemList = [];
  const totalCount = itemsMap[treeNode.key]?.length || 0; // Gets the length of stored tasks by project key
  treeNode.totalCount = totalCount;

  if (totalCount > 0 && showItemsAsChildren) {
    itemList = itemsMap[treeNode.key].map((i) => {
      return {
        ...i,
        key: i.key,
        name: i.tag,
        order: i.order,
        children: [],
        ...extraItemData,
      };
    });
    // .sort((a, b) => a.order - b.order);

    if (sortItemsAlphabetically) {
      itemList.sort((a, b) => {
        const normalisedFirstName = a.name?.toLowerCase();
        const normailisedSecondName = b.name?.toLowerCase();

        return alphaSorter.compare(normalisedFirstName, normailisedSecondName);
      });

      itemList.forEach((i, order) => {
        i.alphaOrder = order;
      });
    }
  }

  let classNames = classAttribute;

  if (!isEmpty(itemList)) {
    if (!isEmpty(treeNode.children)) {
      treeNode.children = treeNode.children.concat(itemList);
    } else {
      treeNode.children = [...itemList];
    }
  }

  // if (showItemsAsChildren) {
  //   treeNode.children.sort((a, b) => a.order - b.order);
  // }

  if (treeNode.children && treeNode.children.length) {
    classNames += ` ${itemMainType}`;
  } else {
    classNames += ` ${itemSubType}`;
  }
  treeNode["hasAttribute"] = {
    class: classNames,
  };

  if (setActionAttris) {
    let addAttris = true;
    if (verifierForAttris) {
      addAttris = verifierForAttris(treeNode);
    }
    let extraAttris = {};

    if (extraAttrisCreator) {
      extraAttris = extraAttrisCreator(treeNode);
    }
    if (addAttris) {
      treeNode["hasAttribute"] = {
        ...treeNode["hasAttribute"],
        "data-action-type": actionType,
        "data-action-value": treeNode.key,
        ...extraAttris,
      };
    }
  }

  treeNode.type = itemSubType;
  treeNode.isSelected = selectedNodes.includes(treeNode.key);

  if (isSearchEnabled) {
    treeNode.open = true;
  }
  // if (selectedNodes && selectedNodes.length) {
  //   dir.open = selectedNodes.includes(dir.key);
  // }
  if (treeNode.children && treeNode.children.length) {
    treeNode.type = itemMainType;
    treeNode.children.forEach((d) => {
      d = createCount(d, itemsMap, selectedNodes, opts);
      treeNode.totalCount += d?.totalCount || 0;
    });
  }
  return treeNode;
};

export const processTree = (tree, itemsMap, selectedNodes, opts) => {
  const {
    classAttribute,
    setActionAttris,
    actionType,
    itemMainType,
    itemSubType,
    showItemsAsChildren,
    extraItemData = {},
    isSearchEnabled,
    verifierForAttris,
    extraAttrisCreator,
    sortItemsAlphabetically,
  } = opts;
  tree.forEach((treeNode) => {
    let itemList = [];
    const itemsCount = itemsMap[treeNode.key]?.length || 0; // Gets the length of stored tasks by project key
    treeNode.totalCount = itemsCount;

    if (itemsCount > 0 && showItemsAsChildren) {
      itemList = itemsMap[treeNode.key].map((i) => {
        return {
          ...i,
          key: i.key,
          name: i.tag,
          order: i.order,
          children: [],
          ...extraItemData,
        };
      });
      // .sort((a, b) => a.order - b.order);

      if (sortItemsAlphabetically) {
        itemList.sort((a, b) => {
          const normalisedFirstName = a.name?.toLowerCase();
          const normailisedSecondName = b.name?.toLowerCase();

          return alphaSorter.compare(
            normalisedFirstName,
            normailisedSecondName
          );
        });
        itemList.forEach((i, order) => {
          i.alphaOrder = order;
        });
      }
    }

    let classNames = classAttribute;

    if (!isEmpty(itemList)) {
      if (!isEmpty(treeNode.children)) {
        treeNode.children = treeNode.children.concat(itemList);
      } else {
        treeNode.children = [...itemList];
      }
    }

    // if (showItemsAsChildren) {
    //   treeNode.children.sort((a, b) => a.order - b.order);
    // }

    if (treeNode.children && treeNode.children.length) {
      classNames += ` ${itemMainType}`;
    } else {
      classNames += ` ${itemSubType}`;
    }

    treeNode["hasAttribute"] = {
      class: classNames,
    };
    if (setActionAttris) {
      let addAttris = true;
      if (verifierForAttris) {
        addAttris = verifierForAttris(treeNode);
      }

      if (addAttris) {
        let extraAttris = {};

        if (extraAttrisCreator) {
          extraAttris = extraAttrisCreator(treeNode);
        }
        treeNode["hasAttribute"] = {
          ...treeNode["hasAttribute"],
          "data-action-type": actionType,
          "data-action-value": treeNode.key,
          ...extraAttris,
        };
      }
    }

    treeNode.type = itemSubType;
    treeNode.isSelected = selectedNodes.includes(treeNode.key);

    if (isSearchEnabled) {
      treeNode.open = true;
    }
    if (treeNode.children && treeNode.children) {
      treeNode.type = itemMainType;
      treeNode.children.forEach((child) => {
        child = createCount(child, itemsMap, selectedNodes, opts);
        treeNode.totalCount += child.totalCount || 0;
      });
    }
  });

  return tree;
};

export const findNodeInTree = (tree, predicate, childrenProp = "children") => {
  const treeNodes = tree.entries();
  for (const [index, node] of treeNodes) {
    if (predicate(node)) return { node, index };

    if (node[childrenProp]) {
      let match = findNodeInTree(node[childrenProp], predicate);
      if (match) return match;
    }
  }
};

export const isNodeChildOrGrandChild = (
  childNodeId,
  nodeIdToCheck,
  allNodes,
  parentKeyProp = "parentKey",
  verifier
) => {
  let nodeIsChildOrGrandChild = false;

  if (allNodes[childNodeId]) {
    const childDirData = allNodes[childNodeId];
    let parentKey = childDirData[parentKeyProp] || "";
    while (!nodeIsChildOrGrandChild) {
      if (!allNodes[parentKey]) {
        break;
      }

      const parentDirData = allNodes[parentKey];

      if (verifier) {
        nodeIsChildOrGrandChild = verifier(parentDirData, nodeIdToCheck);
      } else {
        if (
          parentDirData.key === nodeIdToCheck ||
          parentDirData[parentKeyProp] === nodeIdToCheck
        ) {
          nodeIsChildOrGrandChild = true;
        }
      }

      parentKey = parentDirData[parentKeyProp];
    }
  }

  return nodeIsChildOrGrandChild;
};
const getChildrenRecursive = (tree, result, childrenProp = "children") => {
  if (Array.isArray(tree)) {
    tree.forEach((item) => {
      result.push({ ...item });
      getChildrenRecursive(item[childrenProp], result, childrenProp);
    });
  } else if (tree && tree[childrenProp]) {
    getChildrenRecursive(tree[childrenProp], result, childrenProp);
  }
};

export const getAllChildrenOfANode = (nodeData, childrenProp = "children") => {
  if (isEmpty(nodeData)) {
    return [];
  }
  if (checkIfChildrenExists(nodeData.key)) {
    return getStoredChildrenOfNode(nodeData.key);
  }
  const result = [];
  getChildrenRecursive(nodeData, result, childrenProp);
  if (!isEmpty(result)) {
    storeChildrenOfNode(nodeData.key, result);
  }
  return result;
};

const createFinalItemsToAddInList = (
  itemsToProcess,
  localList,
  verify = true
) => {
  let finalItemsToAdd = [];
  if (verify) {
    itemsToProcess.forEach((t) => {
      const currIndex = localList.findIndex((cT) => cT.key === t.key);
      if (currIndex === -1) {
        finalItemsToAdd.push(t);
      }
    });
  } else {
    finalItemsToAdd = [...itemsToProcess];
  }

  return finalItemsToAdd;
};

export const addItemssInLocalItemsList = (
  itemsToAdd,
  localItems,
  method = "unshift",
  verify = true
) => {
  const currItems = [...localItems];

  if (!Array.isArray(itemsToAdd)) itemsToAdd = [itemsToAdd];
  if (itemsToAdd && itemsToAdd.length) {
    if (method === "unshift") {
      const finalItemsList = createFinalItemsToAddInList(
        itemsToAdd,
        currItems,
        verify
      );
      currItems.unshift(...finalItemsList);
    }

    if (method === "push") {
      const finalItemsList = createFinalItemsToAddInList(
        itemsToAdd,
        currItems,
        verify
      );
      currItems.push(...finalItemsList);
    }
  }
  return currItems;
};

export const removeItemsFromLocalItemsList = (itemIdsToRemove, localItems) => {
  const currItems = [...localItems];

  if (!isEmpty(itemIdsToRemove)) {
    if (!Array.isArray(itemIdsToRemove)) itemIdsToRemove = [itemIdsToRemove];

    itemIdsToRemove.forEach((taskId) => {
      const storedItemIndex = currItems.findIndex((t) => t.key === taskId);
      if (storedItemIndex >= 0) {
        currItems.splice(storedItemIndex, 1);
      }
    });
  }

  return currItems;
};

export const updateItemsInLocalItemsList = (itemsToUpdate, localItems) => {
  const currItems = [...localItems];

  if (!Array.isArray(itemsToUpdate)) itemsToUpdate = [itemsToUpdate];

  if (itemsToUpdate && itemsToUpdate.length) {
    itemsToUpdate.forEach((itemData) => {
      const storedItemIndex = currItems.findIndex(
        (n) => n.key === itemData.key
      );
      if (storedItemIndex >= 0) {
        currItems[storedItemIndex] = {
          ...currItems[storedItemIndex],
          ...itemData.updates,
        };
      }
    });
  }

  return currItems;
};

export const cleanItem = (itemData, extraPropsToRemove = []) => {
  const propsToRemove = [...DEFAULT_PROPS_TO_REMOVE, extraPropsToRemove];
  return removeProps(itemData, propsToRemove);
};

export const cleanItemList = (itemsToClean) => {
  if (!Array.isArray(itemsToClean)) itemsToClean = [itemsToClean];
  const clonedItems = cloneDeep(itemsToClean);
  return clonedItems.map((n) => cleanItem(n));
};

export const convertValueToArray = (data) => {
  let list = [];

  if (!isEmpty(data)) {
    if (!Array.isArray(data)) {
      list = [data];
    } else {
      list = data;
    }
  }

  return list;
};

export const checkIfItemDataIsEdited = (
  updatedItemData,
  currItemData,
  itemDataProps
) => {
  let dataChanged = false;
  if (!isEmpty(updatedItemData) && !isEmpty(currItemData)) {
    dataChanged = itemDataProps.some((prop) => {
      return !isEqual(updatedItemData[prop], currItemData[prop]);
    });
  }

  return dataChanged;
};

export const convertDateTimeStrToDate = (dateStr) => {
  let convertedToDate;
  if (dateStr && hasDateSchema(dateStr)) {
    const parsedVal = parseISO(dateStr);
    convertedToDate = parsedVal;
  }

  return convertedToDate;
};

export const convertDateTimeToReadableFormat = (
  dateStr,
  format,
  defaultText = "None"
) => {
  let formattedDate = defaultText;
  const parsedDate = convertDateTimeStrToDate(dateStr);

  if (isValidDate(parsedDate)) {
    formattedDate = formatDate(parsedDate, format);
  }

  return formattedDate;
};

export const extractDataFromHTMLBlob = (googleEventDesc) => {
  return googleEventDesc.match(HTML_BLOB_CONTENT_REGEX);
};

export const getInnerTextFromHTMLContent = (html) => {
  const htmlCont = document.createElement("div");
  htmlCont.innerHTML = html;
  const textContent = htmlCont.innerText;

  htmlCont.remove();
  return textContent;
};

export const checkIfRowDataHasChanged = (
  updatedData,
  currData,
  propsToCheck
) => {
  const isDataChanged = propsToCheck.some((prop) => {
    if (updatedData[prop] !== undefined) {
      return !isEqual(updatedData[prop], currData[prop]);
    }
  });

  return isDataChanged;
};
