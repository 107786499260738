import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-wrapper"},[_c(VSelect,{ref:"select",staticClass:"item-type-input",attrs:{"items":_vm.itemTypeOpts,"item-value":"key","value":_vm.value,"attach":_vm.attach,"placeholder":_vm.placeholder,"menu-props":_vm.menuProps,"width":_vm.width,"hide-details":""},on:{"change":_vm.handleChange,"blur":_vm.handleBlur,"focus":_vm.handleFocus,"keydown":_vm.handleKeyDown},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._t("selection-content",function(){return [_c(VIcon,{staticClass:"mt-0 ml-0",staticStyle:{"width":"100%"},attrs:{"size":_vm.getIconSize(item),"color":_vm.selectedOptIconColor}},[_vm._v(" "+_vm._s(item.icon)+" ")])]},{"item":item})]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({class:_vm.createItemClasses(item)},'v-list-item',attrs,false),on),[_c(VIcon,{staticClass:"mr-3",staticStyle:{"width":"33px"},attrs:{"color":_vm.optItemIconColor,"size":_vm.getIconSize(item)}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }