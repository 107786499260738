import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({ref:"settingsBtn",staticClass:"action-btn task-bar-action-btn settings-btn",class:{
        'btn-is-active': attrs['aria-expanded'] === 'true',
      },attrs:{"color":_vm.color,"text":"","data-action-type":"open-settings"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" $ctiSetting ")])],1)]}}])},[_c(VList,[_vm._l((_vm.userOptions),function(item,index){return [(_vm.isMenuItemVisible(item))?_c(VListItem,{key:index,on:{"click":function($event){return _vm.menuSelect(item)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }