<template>
  <div class="d-flex d-md-block flex nav-bar" :class="{'flex-column': !sidebarOpen}">
    <v-btn-toggle
      :value="navIndex"
      mandatory
      text
      dense
      :color="isInboxViewEnabled ? '#878787' : 'var(--primary-color)'"
      group
      class="nav-bar-btns"
    >
      <v-tooltip v-for="item in menuOpts" :key="item.index" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            link
            icon
            elevation="0"
            v-bind="attrs"
            v-on="on"
            @click="handleMenuClick(item)"
            data-action-type="nav-route-change"
            class="nav-btn keep-task-selected"
            :data-action-value="item.actionVal"
          >
            <v-icon :size="item.fontSize">{{ item.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
    </v-btn-toggle>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          link
          icon
          elevation="0"
          :color="isInboxViewEnabled ? 'var(--primary-color)' : '#878787'"
          class="float-right non-toggle-button nav-btn"
          v-bind="attrs"
          v-on="on"
          @click="handleMenuClick(inboxMenuConfig)"
          data-action-type="nav-route-change"
          data-action-value="inbox"
        >
          <v-icon size="20"> $ctiInboxNewIcon </v-icon>
        </v-btn>
      </template>
      <span>Inbox</span>
    </v-tooltip>
    <div class="d-md-none d-flex flex align-end" v-if="!sidebarOpen">
      <SettingsMenu />
    </div>
  </div>
</template>
<script>
import DatabaseInterface from "@/services/DatabaseInterface";
import { mapActions, mapGetters, mapMutations } from "vuex";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { routeViewsMap } from "@/variables/routeViews";
import isEmpty from "lodash/isEmpty";
import { isModeInterChangableForInspectMode } from "@/helpers/tasks";
import { treeSideBarModes } from "@/variables/viewConfigs";
import taskHelpersMixin from "@/mixins/tasksHelpersMixin";
import { getUserResources } from "@/helpers/resources";
import SettingsMenu from "@/components/TaskBar/SettingsMenu.vue";
const externalViews = [2, 5];
export default {
  mixins: [userDetailsMixin, taskHelpersMixin],
  components: {
    SettingsMenu
  },
  data() {
    return {
      navIndex: 0,
      menuOpts: [
        {
          title: "Calendar",
          icon: "$ctiCalendarNewIcon",
          index: 0,
          groupView: 0,
          actionVal: "calendar",
          fontSize: 20,
        },
        // {
        //   title: "Next Actions View",
        //   icon: "$ctiSort",
        //   index: 4,
        //   groupView: 1,
        //   actionVal: "prioritize",
        //   fontSize: 22,
        // },
        {
          title: "Areas",
          icon: "$ctiTreeViewIcon",
          index: 4,
          groupView: 1,
          // groupView: 2,
          // index: 1,
          actionVal: "projects",
          fontSize: 20,
        },
        // {
        //   title: "Projects",
        //   icon: "$ctiProject",
        //   index: 7,

        //   // groupView: 2,
        //   // index: 1,
        //   actionVal: "projects",
        //   fontSize: 20,
        // },
        {
          title: "Contexts",
          icon: "$ctiResourcesNewIcon",
          actionVal: "resources",
          index: 5,
          fontSize: 20,
        },
        {
          title: "Notes",
          icon: "$ctiNoteMode",
          index: 2,
          fontSize: 20,
          actionVal: "notes",
        },
      ],
    };
  },
  props: {
    sidebarOpen: Boolean,
  },
  methods: {
    checkIfInspectModeNeedsToBeDisabled(currNav, navToGo) {
      let disable = true;

      if (isModeInterChangableForInspectMode(currNav, navToGo)) {
        disable = false;
      }
      return disable;
    },
    handleMenuClick(menuItem) {
      let localUpdates = {
        isFilterBarOptsEnabled: false,
      };
      let updateResourceType = false;
      let dbUpdates = {};
      let navigateToRoute = false;
      let routeToGo = "";
      let disableInspectMode = true;
      let isLocalUpdate = false;
      let rootUpdates = {
        currViewData: {},
        itemInspectEnabled: false,
      };
      // if (this.isInspectModeEnabled) {
      //   localUpdates.inspectModeEnabled = false;
      // }

      if (!externalViews.includes(menuItem.index)) {
        if (
          this.$route.name === "Dashboard" &&
          menuItem.index === 1 &&
          !this.isInboxViewEnabled &&
          treeSideBarModes.includes(this.nav)
        ) {
          return;
        }
        let updates = {
          "/isProjectsResourceModeEnabled": false,
        };

        if (menuItem.index === 7) {
          updates["/inboxView"] = false;
          updates["/globalView"] = false;
          updates["/notesView"] = false;
          updates["/activeNavView"] = "dashboard";
          updates["/showCompleted"] = false;
          updates["/isProjectsResourceModeEnabled"] = true;

          updateResourceType = true;
        } else if (menuItem.index === 6) {
          updates["/inboxView"] = true;
          updates["/globalView"] = false;
          updates["/notesView"] = false;
          updates["/activeNavView"] = "dashboard";
          updates["/showCompleted"] = false;
          updates["/view/5/groupView"] = 3;
          updates["/view/5/sortMode"] = 0;
          updates["/nav"] = 1;
        } else {
          if (menuItem.index === 4 && this.isProjectsResourceModeEnabled) {
            return;
          }
          if (this.isInspectModeEnabled) {
            const allowToDisableInspectMode =
              this.checkIfInspectModeNeedsToBeDisabled(
                this.nav,
                menuItem.index
              );

            if (!allowToDisableInspectMode) {
              disableInspectMode = false;
              isLocalUpdate = true;
              const selectedTasks = this.selectedTasks;
              localUpdates = {
                nav: menuItem.index,
              };

              if (!isEmpty(selectedTasks)) {
                localUpdates.selectedOptsList = selectedTasks.map((i) => ({
                  id: i.project,
                  subCategoryId: "all",
                  categoryId: this.projects[i.project]?.category,
                  type: "project",
                }));
              }

              if (menuItem.index === 4) {
                localUpdates.groupMode = 1;
              } else if (menuItem.index === 1) {
                localUpdates.groupMode = 2;
              }
              localUpdates.collapsedGroups = [];
              rootUpdates.currNav = menuItem.index;
            }
          }

          updates["nav"] = menuItem.index;
          updates["globalView"] = false;
          updates["inboxView"] = false;
          updates["notesView"] = false;
          updates["activeNavView"] = "dashboard";
          updates["showCompleted"] = false;
          // updates["view/0/groupView"] = 0;
          updates["view/0/sortMode"] = 0;
          updates["view/0/statusFilter"] = "scheduled";
          updates["view/1/statusFilter"] = "incomplete";
          updates["view/1/sortMode"] = 0;

          if (menuItem.groupView !== undefined) {
            updates[`view/${menuItem.index}/groupView`] = menuItem.groupView;
          }

          if (this.isInboxViewEnabled) {
            if (
              typeof this.prevTreeGroupView === "number" &&
              this.prevTreeGroupView >= 0
            ) {
              updates["view/1/groupView"] = this.prevTreeGroupView;
            }
          }

          updates["view/2/statusFilter"] = "incomplete";
          updates["view/3/statusFilter"] = "incomplete";
          updates["view/3/sortMode"] = 1;
        }

        updates["inspectModeEnabled"] = disableInspectMode ? false : true;

        dbUpdates = { ...updates };

        // updates["view/4/statusFilter"] = "all";
        // updates["view/4/sortMode"] = 0;
        // DatabaseInterface.update(updates, this.userInfo.uid);
        if (this.$route.name !== "Dashboard") {
          navigateToRoute = true;
          routeToGo = "Dashboard";
          // this.$router.replace({
          //   name: "Dashboard",
          // });
        }
      } else {
        dbUpdates = {
          notesView: menuItem.index === 2,
          inboxView: false,
          globalView: false,
          activeNavView: routeViewsMap[menuItem.index],
          showCompleted: false,
          inspectModeEnabled: false,
          isProjectsResourceModeEnabled: false,
        };
        // DatabaseInterface.update(
        //   {

        //   },
        //   this.userInfo.uid
        // );

        if (menuItem.index === 2 && this.$route.name !== "Notes") {
          navigateToRoute = true;
          routeToGo = "Notes";
          // this.$router.replace({
          //   name: "Notes",
          // });
        }

        if (menuItem.index === 5 && this.$route.name !== "Resources") {
          navigateToRoute = true;
          routeToGo = "Resources";
          // this.$router.replace({
          //   name: "Resources",
          // });
        }
      }
      if (disableInspectMode) {
        localUpdates.inspectModeEnabled = false;
      }

      this.hideTempVisibleTasks();

      this.closeResourceInfoViewer();
      this.updateState({ ...localUpdates });
      this.clearListFilters();
      this.toggleContingentTasks(false);
      this.updateRootState({
        searchVal: "",
        ...rootUpdates,
      });

      // if (updateResourceType) {
      //   getUserResources().set("project", "selectedResourceType");
      // }

      if (!isLocalUpdate && !isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }

      if (navigateToRoute) {
        this.$router.replace({
          name: routeToGo,
        });
      }
    },
    openInbox() {
      let updates = {};
      let localUpdates = {};
      if (this.isInspectModeEnabled) {
        localUpdates.inspectModeEnabled = false;
      }
      // this.inboxView = true;
      // this.globalView = false;
      // this.view[1].sortMode = 0;
      // this.view[1].groupView = 0;
      // this.navSelect = 1;

      updates["/inboxView"] = true;
      updates["/globalView"] = false;
      updates["/notesView"] = false;
      updates["/activeNavView"] = "dashboard";
      updates["/showCompleted"] = false;
      updates["/view/5/groupView"] = 3;
      updates["/view/5/sortMode"] = 0;
      updates["/nav"] = 1;

      // this.sortMode = 7;
      this.updateState({ ...localUpdates });
      DatabaseInterface.update(updates, this.userInfo.uid);
      if (this.$route.name !== "Dashboard") {
        this.$router.replace({
          name: "Dashboard",
        });
      }
    },
    goTo(routeName) {
      // if (routeName !== "inbox") {
      this.$el
        .querySelector(`.nav-btn[data-action-value="${routeName}"]`)
        ?.click();
      // } else {
      //   this.openInbox();
      // }
    },
    setNavValue() {
      let nav = this.nav;

      if (this.isNotesView || this.$route.name === "Notes") {
        nav = 3;
      } else if (
        this.currActiveView === "resources" ||
        this.$route.name === "Resources"
      ) {
        nav = 2;
      } else if (this.isInboxViewEnabled) {
        nav = 4;
      } else if (nav === 0 || nav === 3) {
        nav = 0;
      } else if (
        !this.isInboxViewEnabled &&
        (this.isProjectsResourceModeEnabled || treeSideBarModes.includes(nav))
      ) {
        nav = 1;
      }

      this.navIndex = nav;
    },
    ...mapActions(["toggleNotesView", "updateRootState"]),
    ...mapActions("task", ["clearListFilters", "toggleContingentTasks"]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions("resourceInfo", ["closeResourceInfoViewer"]),
  },
  mounted() {},
  watch: {
    nav: {
      handler() {
        this.setNavValue();
      },
      immediate: true,
    },
    isInboxViewEnabled() {
      this.setNavValue();
    },
    isNotesView() {
      this.setNavValue();
    },
    currActiveView() {
      this.setNavValue();
    },
    isProjectsResourceModeEnabled() {
      this.setNavValue();
    },
  },
  computed: {
    ...mapGetters("task", {
      isInboxViewEnabled: "isInboxViewEnabled",
      isInspectModeEnabled: "isInspectModeEnabled",
      prevTreeGroupView: "prevTreeGroupView",
      selectedTasks: "selectedTasks",
      projects: "projects",
      isProjectsResourceModeEnabled: "isProjectsResourceModeEnabled",
    }),
    ...mapGetters(["isNotesView", "nav", "currActiveView"]),

    inboxMenuConfig() {
      return {
        title: "Inbox",
        icon: "$ctiInboxNewIcon",
        index: 6,
        fontSize: 20,
        actionVal: "inbox",
      };
    },
  },
};
</script>
<style scoped lang="scss">
.nav-btn {
  min-width: 38px !important;
  flex: 1;
}
.non-toggle-button {
  margin: 4px 0px;
  width: 20%;
  flex: none;
}

.nav-bar-btns {
  width: 80%;

  .theme--light.v-btn.v-btn--icon {
    caret-color: #878787;
    color: #878787;
    opacity: 1;

    &.v-btn--active {
      caret-color: inherit;
      color: inherit;
    }
  }
}
</style>
