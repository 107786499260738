<template>
  <v-dialog
    width="20%"
    :value="isEditConfirmBoxOpened"
    @click:outside="close"
    @keydown="handleKeyDown"
  >
    <v-card>
      <v-card-title class="text-h5"> Edit Recurring Task </v-card-title>
      <v-card-text>
        <div>
          <v-radio-group v-model="selectedOpt">
            <v-radio
              v-for="(opt, index) in editOpts"
              :key="index"
              :label="opt.label"
              :value="opt.value"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          text
          @click="submit"
          class="save-confirm-btn"
        >
          Save
        </v-btn>
        <v-btn depressed text @click="close" color="error"> Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  formatDate,
  getCurrDate,
  isValidDate,
  makeDateFormat,
  makeTimeFormat,
} from "@/helpers/dates";
import {
  addOrRemoveOrUpdateTasksInDb,
  addRecurringTasksInfoInMap,
  areTaskRecurrenceRulesValid,
  checkIfTaskHasReminder,
  checkIfTaskObjectiveIsValid,
  checkIfTaskRecurrenceIntervalOrDayChanged,
  checkTaskIsAllDay,
  convertTaskDateToAllDayOrTimeForGoogle,
  convertTaskKeyForGoogleCal,
  createAdditionalDates,
  createDateTimeFromDue,
  createRecurrenceOptsValuesFromTaskRecurrenceRules,
  createRecurringDatesFromTaskRules,
  createReminderTasks,
  createTaskDataForGoogleCal,
  createTaskDueDate,
  createTaskId,
  createVirtualTasksFromRecurringTasks,
  excludeDatesInTaskRules,
  fillRecurrenceOptsInTaskData,
  fillTaskData,
  filterRecurringTasks,
  getDateAndTimeFromDueDate,
  getPausedReminderDaysVal,
  getRecurrenceIdByDateFromRecurrences,
  getRecurringTasks,
  getReminderTasksLinkedToTaskId,
  isTaskAfterDateFilterCreator,
  isTaskNotCompleted,
  parseRuleDataFromTaskRecurrenceRules,
  processTask,
  removeTasksFromRecurringTasksMapByDates,
  replaceRecurringTasksInfoInMap,
} from "@/helpers/tasks";
import isEqual from "lodash/isEqual";

import removeProps from "@/utils/removeProps";
import cloneDeep from "lodash/cloneDeep";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import googleCalHelpersMixin, {
  addOrUpdateOrRemoveTasksInGoogleCal,
} from "@/mixins/googleCalHelpersMixins";
import recurringTasksHelpersMixin from "@/mixins/recurringTasksHelpersMixin";
import {
  ACTION_ON_TYPES,
  getUserActions,
  PROJECT_ACTIONS,
  RESOURCE_ACTIONS,
  TASK_ACTIONS,
} from "@/helpers/actionHistory";
import { addOrRemoveOrUpdateTasksInLocalTasksList } from "@/helpers/tasks";
import isSameDay from "date-fns/isSameDay";
import { createRRuleSet } from "@/helpers/rrulesHelpers";
import createUniqueId from "@/utils/createUniqueId";
import { frequencyOptsMap } from "@/data/frequencyOpts";
import { subDate, endOfDay } from "@/helpers/dates";
import Vue from "vue";
import { INTERNAL_DATE_FORMAT } from "@/variables/dates";

import { PRIMARY_COLOR } from "@/variables/colors";
import EventEmitter from "@/helpers/eventEmitter";
import {
  REFRESH_FORM_DATA,
  REFRESH_RESOURCE_FORM_DATA,
  REFRESH_TREE_EVENT,
} from "@/variables/events";
import {
  checkIfObjectiveDataChanged,
  createUpdateAndEditedForObjective,
} from "@/helpers/objectives";
import {
  addOrRemoveOrUpdateResourcesInList,
  addOrUpdateOrRemoveResourcesInLocalList,
  changeCatOfProjectResources,
  getAllMentionedResourceIdsFromText,
  getProjectResourcesFromText,
  processResourceData,
} from "@/helpers/resources";
import GoogleCalHelpers from "@/helpers/googleCalHelpers";

const editOpts = [
  {
    label: "This Task",
    value: "self",
  },
  {
    label: "This task and following tasks",
    value: "withFollowing",
  },
  {
    label: "All Tasks",
    value: "all",
  },
];
export default {
  mixins: [userDetailsMixin, googleCalHelpersMixin, recurringTasksHelpersMixin],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    toggleLoader: {
      type: Function,
    },
    setDataForEditForm: {
      type: Boolean,
      default: true,
    },

    inResourceMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedOpt: "",
    };
  },
  methods: {
    ...mapActions("editForm", [
      "closeEditTaskConfirmBox",
      "closeEditTaskDialog",
      "setTaskData",
    ]),
    ...mapActions("toast", ["showToast"]),
    ...mapMutations(["setLoader"]),
    ...mapActions("resourceInfo", ["setResourceData", "setStoredTaskData"]),
    close() {
      this.closeEditTaskConfirmBox();
      this.selectedOpt = "";
    },
    showErrorToast() {
      this.showToast("An error occurred!");
    },
    sendSuccessSubmit() {
      this.$emit("data-submit:success");
    },
    async updateSelfTask() {
      this.toggleLoader(true);
      try {
        const storeUserActions = !this.inResourceMode;
        const updateLocalFormData =
          this.setDataForEditForm && !this.inResourceMode;

        //         currTaskData,
        // providedUpdatedData,
        // refreshList = true,
        // updateLocalDataForEdit,
        // storeUserAction = true,
        // updatedProjectData = {},
        // updatedObjectiveData = {},
        // extraEditActionData = {},
        // updateProjectResources = true
        let {
          userActions,
          // onlyProjectPriorityChanged,
          // rawDataForLocalSave,
          localTaskDataForForm,
          // createdData,
        } = await this.updateSingleRecurringTask(
          this.taskData,
          this.updatedTaskData,
          undefined,
          updateLocalFormData,
          storeUserActions
        );

        if (this.inResourceMode) {
          await this.processResourceDataAndTaskDataUpdates({
            userActions,
            localTaskDataForForm,
          });
          // const {
          //   editedData: resourceEditData,
          //   key: resourceKey,
          //   updates: resourceUpdates,
          // } = this.resourceDataChanges;

          // const resourceEditDataConfig = {
          //   data: [{ ...resourceEditData, key: resourceKey }],
          //   type: RESOURCE_ACTIONS.BATCH_EDIT,
          //   on: ACTION_ON_TYPES.RESOURCE,
          // };

          // if (Array.isArray(userActions)) {
          //   userActions.unshift({ ...resourceEditDataConfig });
          // } else {
          //   userActions = [{ ...resourceEditDataConfig }, { ...userActions }];
          // }

          // getUserActions().addBatchAction({
          //   data: userActions,
          //   on: "all",
          // });

          // addOrRemoveOrUpdateResourcesInList(
          //   {
          //     resourcesToUpdate: [
          //       {
          //         updates: resourceUpdates,
          //         editedData: {},
          //         key: resourceKey,
          //       },
          //     ],
          //   },
          //   false
          // );

          // addOrUpdateOrRemoveResourcesInLocalList({
          //   resourcesToUpdate: [
          //     {
          //       key: resourceKey,
          //       updates: resourceUpdates,
          //     },
          //   ],
          // });

          // this.updateResourceFormData({
          //   ...resourceUpdates,
          //   key: resourceKey,
          //   taskData: localTaskDataForForm,
          // });
        }
        this.sendSuccessSubmit();
      } catch (error) {
        console.debug("EROR", error);
        this.toggleLoader(false);
        this.showErrorToast();
      } finally {
        this.closeEditForm();
      }
      this.toggleLoader(false);
    },

    async updateResourceFormData({ taskData, ...resourceUpdatesData }) {
      if (this.inResourceMode && !isEmpty(this.storedResourceData)) {
        const rawResourceData =
          this.rawResourcesMap[this.storedResourceData.key];

        const finalResourceData = {
          ...rawResourceData,
          ...resourceUpdatesData,
          key: this.storedResourceData.key,
        };

        this.setResourceData(processResourceData(finalResourceData));

        if (!isEmpty(taskData)) {
          this.setStoredTaskData(taskData);
        }

        await this.$nextTick();
        EventEmitter.emit(REFRESH_RESOURCE_FORM_DATA);
      }
    },
    createDataForRecurringTask(
      providedNewData,
      mode = "update",
      replaceRecurrenceRules = false,
      providedCurrData,
      storeRecurrenceRulesChange,
      keepExistingRulesInUpdate,
      autoIncrementOrder = true
    ) {
      let newTaskData = {};
      let updatedTaskData = {};
      let toProject = providedNewData.project;
      const previousDueDate = providedCurrData.dueAsDate;
      const currRecurrenceRules = providedCurrData.recurrence;
      const updatedObjectiveData = this.updatedObjectiveData;
      const objectivesMap = this.objectivesMap;
      const newRecurrenceRules = providedNewData.recurrence;
      let newTaskFlagged = providedNewData.flag;
      let taskProjectChanged = false;
      let onlyProjectPriorityChanged = false;
      let taskProjectData = {};
      let editedTaskData = {};
      let recurrenceData = {};
      let projectDbUpdates = {};
      const rawTasksMap = this.rawTasksMap || {};
      // const currentTaskPriority = providedCurrData.priority;

      const newTaskDueDate = providedNewData.dueAsDate;
      let newTaskPriority = providedNewData.priority || "";
      let updates = {};
      let editProjectData = {};
      let googleEventIdData = {};
      let googleCalUpdatesList = [];
      let timeToSet = "00:00"; // Default time
      let dateToSet = "none"; // Date defaults to 'none'
      const currTasksCount = Object.keys(rawTasksMap).length || 0;
      let objectiveDbUpdates = {},
        objectiveEditData = {};
      let isTaskObjectiveValid = checkIfTaskObjectiveIsValid(providedNewData);
      const isNewTaskDateValid = newTaskDueDate && isValidDate(newTaskDueDate);

      if (mode === "update") {
        const rulesAreValid = areTaskRecurrenceRulesValid(currRecurrenceRules);
        if (rulesAreValid && !replaceRecurrenceRules) {
          recurrenceData = {
            recurrence:
              areTaskRecurrenceRulesValid(newRecurrenceRules) &&
              !isEqual(newRecurrenceRules, currRecurrenceRules)
                ? newRecurrenceRules
                : currRecurrenceRules,
          };
        }

        if (
          replaceRecurrenceRules &&
          areTaskRecurrenceRulesValid(newRecurrenceRules)
        ) {
          recurrenceData = {
            recurrence: newRecurrenceRules,
          };
        }

        if (keepExistingRulesInUpdate && rulesAreValid) {
          recurrenceData = {
            recurrence: currRecurrenceRules,
          };
        }
      }

      if (mode === "add" && areTaskRecurrenceRulesValid(newRecurrenceRules)) {
        recurrenceData = {
          recurrence: newRecurrenceRules,
        };
      }

      if (isNewTaskDateValid) {
        timeToSet = makeTimeFormat(newTaskDueDate);
        dateToSet = makeDateFormat(newTaskDueDate);
        newTaskFlagged = true;
      }

      const dataToFill = {
        completed: providedNewData.completed,
        created: providedNewData.created,
        description: providedNewData.description || "",
        due: dateToSet,
        flag: newTaskFlagged,
        key: providedCurrData.key,
        modified: getCurrDate(),
        order: providedNewData.order,
        priority: newTaskPriority,
        project: providedNewData.project || "",
        status: providedNewData.status,
        taskType: providedNewData.taskType || "",
        time: timeToSet,
        title: providedNewData.title || "",
        googleEventId: providedNewData.googleEventId,
        googleCalendarId: providedNewData.googleCalendarId,
        coRelationalId:
          providedNewData.coRelationalId || this.taskData.coRelationalId,
        frequency: providedNewData.frequency,
        linkedTo: providedNewData.linkedTo,
        isFirstTask: providedNewData.isFirstTask || this.taskData.isFirstTask,
        isContingent: providedNewData.isContingent || false,
        userPosition: providedNewData.userPosition || 0,
        objective: providedNewData.objective || "",
        reminderDays: providedNewData.reminderDays || null,
        pausedReminderDays: getPausedReminderDaysVal(
          providedNewData.pausedReminderDays
        ),
        positionChanged: providedNewData.positionChanged || false,
        resources:
          getAllMentionedResourceIdsFromText(providedNewData.title) || [],
        isCustomPositioned: providedNewData.isCustomPositioned || false,
        customPosition: providedNewData.customPosition || 0,
        customPositionIndex: providedNewData.customPositionIndex || 0,
      };

      const changedProps = fillTaskData(dataToFill, recurrenceData);

      if (mode === "add") {
        changedProps.originalDate = isValidDate(providedCurrData.dueAsDate)
          ? providedCurrData.dueAsDate
          : "";

        if (autoIncrementOrder) {
          changedProps.order = currTasksCount;
          changedProps.userPosition = 0;
          changedProps.customPositionIndex = 0;
          changedProps.customPosition = 0;
        }
      } else {
        changedProps.originalDate = isValidDate(
          this.rawTasksMap[providedCurrData.key]?.originalDate
        )
          ? this.rawTasksMap[providedCurrData.key]?.originalDate
          : "";
      }

      editedTaskData = {
        project: providedCurrData.project || "",
        time: "00:00",
        due: "none",
        completed: providedCurrData.completed,
        created: providedCurrData.created,
        description: providedCurrData.description || "",
        flag: providedCurrData.flag,
        key: providedCurrData.key,
        modified: providedCurrData.modified || "",
        order: providedCurrData.order || 0,
        priority: providedCurrData.priority || "",
        status: providedCurrData.status || "",
        taskType: providedCurrData.taskType || "",
        title: providedCurrData.title,
        isContingent: providedCurrData.isContingent || false,
        frequency: providedCurrData.frequency || "",
        userPosition: providedCurrData.userPosition || 0,
        objective: providedCurrData.objective || "",
        reminderDays: providedCurrData.reminderDays || null,
        pausedReminderDays: getPausedReminderDaysVal(
          providedCurrData.pausedReminderDays
        ),
        positionChanged: providedCurrData.positionChanged || false,
        resources: providedCurrData.resources || [],
        isCustomPositioned: providedCurrData.isCustomPositioned || false,
        customPosition: providedCurrData.customPosition || 0,
        customPositionIndex: providedCurrData.customPositionIndex || 0,
      };

      if (previousDueDate && isValidDate(previousDueDate)) {
        (editedTaskData.due = makeDateFormat(previousDueDate)),
          (editedTaskData.time = makeTimeFormat(previousDueDate));
      }

      if (providedCurrData.project && this.projects[providedCurrData.project]) {
        if (toProject !== providedCurrData.project) {
          taskProjectChanged = true;
          editedTaskData.changedProject = toProject;
        } else {
          taskProjectData = this.projects[providedCurrData.project];
        }
      } else if (!providedCurrData.project && providedNewData.project) {
        taskProjectChanged = true;
      }

      if (taskProjectChanged && toProject && this.projects[toProject]) {
        taskProjectData = this.projects[toProject];
      }

      if (!isEmpty(taskProjectData)) {
        let newProjectPriority;
        // if (!taskProjectData.priority) {
        //   // if (newTaskPriority) {
        //   //   newProjectPriority = newTaskPriority;
        //   // }
        // } else {
        // if (!currentTaskPriority) {
        //   newTaskPriority = taskProjectData.priority;
        // }

        // }

        if (
          !taskProjectChanged &&
          taskProjectData.priority !== this.updatedProjectData.priority
        ) {
          newProjectPriority = this.updatedProjectData.priority;
          onlyProjectPriorityChanged = true;
        }

        if (
          taskProjectChanged &&
          taskProjectData.priority !== this.updatedProjectData.priority
        ) {
          newProjectPriority = this.updatedProjectData.priority;
        }
        if (newProjectPriority) {
          updates["/projects/" + taskProjectData.key + "/priority"] =
            newProjectPriority;
          projectDbUpdates[`/projects/${taskProjectData.key}/priority`] =
            newProjectPriority;
          projectDbUpdates[`/projects/${taskProjectData.key}/modified`] =
            getCurrDate();
          updates[`/projects/${taskProjectData.key}/modified`] = getCurrDate();

          editProjectData = {
            key: taskProjectData.key,
            priority: taskProjectData.priority,
            changedProps: {
              priority: newProjectPriority,
            },
          };
        }
      }

      editedTaskData.changedProps = changedProps;
      editedTaskData.key = providedCurrData.key;
      if (storeRecurrenceRulesChange) {
        if (!isEmpty(currRecurrenceRules)) {
          editedTaskData.recurrence = currRecurrenceRules;
        }

        if (!isEmpty(newRecurrenceRules)) {
          editedTaskData.changedProps = {
            ...editedTaskData.changedProps,
            recurrence: newRecurrenceRules,
          };
        }
      }

      if (
        isTaskObjectiveValid &&
        !isEmpty(updatedObjectiveData) &&
        checkIfObjectiveDataChanged(
          updatedObjectiveData,
          objectivesMap[providedNewData.objective]
        )
      ) {
        ({ dbUpdates: objectiveDbUpdates, editedData: objectiveEditData } =
          createUpdateAndEditedForObjective(
            updatedObjectiveData,
            objectivesMap[providedNewData.objective]
          ));
        //
      }

      if (!isEmpty(objectiveDbUpdates)) {
        updates = {
          ...updates,
          ...objectiveDbUpdates,
        };
      }
      if (mode === "update") {
        const areRulesValid = areTaskRecurrenceRulesValid(
          providedNewData.recurrence
        );

        if (this.isGoogleCalSyncEnabled) {
          if (!providedCurrData.googleEventId && isNewTaskDateValid) {
            googleEventIdData = {
              googleEventId: convertTaskKeyForGoogleCal(changedProps.key),
              googleCalendarId: GoogleCalHelpers.getStoredCalId(),
            };
          }

          const recurrenceUpdates = {};
          if (areRulesValid) {
            recurrenceUpdates.recurrence = providedNewData.recurrence;
          }
          googleCalUpdatesList.push({
            key: providedNewData.key,
            updates: {
              ...providedNewData,
              dueAsDate: newTaskDueDate,
              ...recurrenceUpdates,
            },
            currData: {
              ...this.taskData,
            },
          });
        }

        if (changedProps.completed) {
          changedProps.completedOn = getCurrDate("extended");
        }

        updatedTaskData = {
          ...changedProps,
          key: providedCurrData.key,
          modified: getCurrDate(),
          ...googleEventIdData,
        };

        updates["/tasks/" + providedCurrData.key] = updatedTaskData;
      } else {
        newTaskData = {
          ...changedProps,
          key: createTaskId(),
        };

        newTaskData.googleEventId = "";
        newTaskData.googleCalendarId = "";
        if (this.isGoogleCalSyncEnabled && isNewTaskDateValid) {
          newTaskData.googleEventId = convertTaskKeyForGoogleCal(
            newTaskData.key
          );
          newTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
        }

        updates[`/tasks/${newTaskData.key}`] = newTaskData;
        // updates[]
        // For Add
      }

      return {
        newTaskData,
        updatedTaskData,
        editProjectData,
        editedTaskData,
        objectiveDbUpdates,
        objectiveEditData,
        dbUpdates: updates,
        projectDbUpdates,
        onlyProjectPriorityChanged,
      };
    },
    closeEditForm() {
      // this.toggleLoader(false);
      // Disabled form close
      // this.closeEditTaskDialog();
    },
    async updateEditTaskData(dataToStore, isAreaChanged) {
      if (this.setDataForEditForm) {
        await Vue.nextTick();
        if (
          isAreaChanged &&
          !isEmpty(this.taskData) &&
          this.taskData.key &&
          this.taskData.project
        ) {
          this.setTaskAreaInFormAndRefresh(this.taskData.project);
        } else {
          this.setTaskData(processTask(dataToStore));
          await this.$nextTick();
          EventEmitter.emit(REFRESH_FORM_DATA);
        }
      }
    },

    async processResourceDataAndTaskDataUpdates({
      userActions,
      localTaskDataForForm,
    }) {
      const {
        editedData: resourceEditData,
        key: resourceKey,
        updates: resourceUpdates,
      } = this.resourceDataChanges;

      const resourceEditDataConfig = {
        data: [
          { ...resourceEditData, key: resourceKey, doNotUpdateForm: true },
        ],
        type: RESOURCE_ACTIONS.BATCH_EDIT,
        on: ACTION_ON_TYPES.RESOURCE,
      };

      if (Array.isArray(userActions)) {
        userActions.unshift({ ...resourceEditDataConfig });
      } else {
        userActions = [{ ...resourceEditDataConfig }, { ...userActions }];
      }

      getUserActions().addBatchAction({
        data: userActions,
        on: "all",
      });

      addOrRemoveOrUpdateResourcesInList(
        {
          resourcesToUpdate: [
            {
              updates: resourceUpdates,
              editedData: {},
              key: resourceKey,
            },
          ],
        },
        false
      );

      await this.$nextTick();

      addOrUpdateOrRemoveResourcesInLocalList({
        resourcesToUpdate: [
          {
            key: resourceKey,
            updates: resourceUpdates,
          },
        ],
      });

      this.updateResourceFormData({
        ...resourceUpdates,
        key: resourceKey,
        taskData: localTaskDataForForm,
      });
    },
    async updateSelfAndFollowTasks() {
      this.toggleLoader(true);
      try {
        const currTaskData = this.taskData;

        const isInResourceMode = this.inResourceMode;
        const isFirstTask = currTaskData.isFirstTask;

        let addToDb = true;
        if (
          currTaskData.coRelationalId &&
          areTaskRecurrenceRulesValid(currTaskData.recurrence)
        ) {
          addToDb = false;
        } else if (isFirstTask) {
          addToDb = false;
        }
        let newData = cloneDeep(this.updatedTaskData);
        newData.coRelationalId = currTaskData.coRelationalId;

        const isAreaChanged = newData.project !== currTaskData.project;

        if (isAreaChanged) {
          const mentionedProjectResources = getProjectResourcesFromText(
            newData.title
          );

          if (!isEmpty(mentionedProjectResources)) {
            changeCatOfProjectResources([
              {
                resources: mentionedProjectResources,
                catId: newData.project,
                isTaskRef: true,
              },
            ]);
          }
        }

        if (addToDb) {
          let reminderTasksToRemove = [];

          const reminderTasksLinkedToMain = getReminderTasksLinkedToTaskId(
            this.tasks,
            this.taskData.key
          );

          if (!isEmpty(reminderTasksLinkedToMain)) {
            reminderTasksToRemove.push(...reminderTasksLinkedToMain);
          }
          const allRecurringTasks = getRecurringTasks(this.rawTasks, "list", {
            coRelationalId: this.taskData.coRelationalId,
          });
          let clonedUpdatedData = cloneDeep(this.updatedTaskData);
          const mainTaskData = allRecurringTasks[0];
          const mainTaskId = mainTaskData.key;
          const mainTaskGoogleId = mainTaskData.googleEventId;
          let rawTaskDataForLocalSave = {};
          const mainTaskDueDate = createDateTimeFromDue(
            mainTaskData.due,
            mainTaskData.time
          );
          let endDate = clonedUpdatedData.dueAsDate;
          if (checkTaskIsAllDay(endDate)) {
            endDate = subDate(endDate, { days: 1 });
          } else {
            endDate = subDate(endDate, { days: 1 });
            endDate = endOfDay(endDate);
          }
          const { rule, ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskData.recurrence,
              dueAsDate: mainTaskDueDate,
            });
          const parsedRuleOptions = rule.options;

          const excludedDates = parsedRuleSet.exdates();
          const rulesOptsToUse = {
            ...parsedRuleOptions,
            dtstart: createTaskDueDate(mainTaskData.due),
            until: endDate,
          };

          const newRuleSetCreatedForMainTask = createRRuleSet(rulesOptsToUse);

          excludeDatesInTaskRules(
            excludedDates,
            newRuleSetCreatedForMainTask,
            false
          );

          const rulesList = newRuleSetCreatedForMainTask.valueOf();

          const convertedRules = rulesList.slice(1);
          const mainTaskGoogleUpdate = {
            key: mainTaskGoogleId,
            updates: createTaskDataForGoogleCal(
              {
                recurrence: convertedRules,
                taskKey: mainTaskData.key,
              },
              { ...mainTaskData, dueAsDate: mainTaskDueDate }
            ),
          };
          const mainTaskDataDbUpdate = {
            key: mainTaskId,
            updates: {
              recurrence: convertedRules,
            },
          };
          clonedUpdatedData = removeProps(clonedUpdatedData, ["linkedTo"]);
          const newRecurrenceRulesData = createAdditionalDates({
            frequency: frequencyOptsMap[this.updatedTaskData.frequency],
            startDateTime: this.updatedTaskData.dueAsDate,
            repeatCount: this.updatedTaskData.recurrenceCount,
            repeatDay: this.updatedTaskData.recurrenceRepeatDay,
          });

          const oldRuleValues =
            createRecurrenceOptsValuesFromTaskRecurrenceRules(mainTaskData);
          const oldRecurrenceRulesData = createAdditionalDates({
            repeatCount: oldRuleValues.recurrenceCount,
            repeatDay: oldRuleValues.recurrenceRepeatDay,
            frequency: frequencyOptsMap[this.taskData.frequency],
            startDateTime: this.taskData.dueAsDate,
          });

          const oldRecurrenceRulesList = oldRecurrenceRulesData.rules;
          clonedUpdatedData.recurrence = newRecurrenceRulesData.rules;
          const {
            projectDbUpdates,
            newTaskData,
            editedTaskData,
            editProjectData,
            onlyProjectPriorityChanged,
          } = this.createDataForRecurringTask(
            clonedUpdatedData,
            "add",
            undefined,
            { ...this.taskData, recurrence: oldRecurrenceRulesList },
            true,
            false,
            false
          );

          newTaskData.coRelationalId = createUniqueId();
          const taskDataForGoogle = createTaskDataForGoogleCal({
            ...clonedUpdatedData,
            taskKey: newTaskData.key,
          });
          const googleEventData = {
            key: newTaskData.key,
            data: taskDataForGoogle,
          };

          const recurringTasksAfterCurrTaskDate = getRecurringTasks(
            this.tasks,
            "list",
            {
              coRelationalId: this.taskData.coRelationalId,
              filter: true,
              filterOpts: [
                isTaskNotCompleted,
                isTaskAfterDateFilterCreator(
                  this.taskData.dueAsDate,
                  "dueAsDate",
                  "afterOrEqual"
                ),
              ],
            }
          );

          recurringTasksAfterCurrTaskDate.forEach((r) => {
            const linkedReminderTasks = getReminderTasksLinkedToTaskId(
              this.tasks,
              r.key
            );

            if (!isEmpty(linkedReminderTasks)) {
              reminderTasksToRemove.push(...linkedReminderTasks);
            }
          });

          const updatedRecurrences = createRecurringDatesFromTaskRules({
            ...getDateAndTimeFromDueDate(mainTaskDueDate),
            recurrence: convertedRules,
          });

          const { tasks: newRecurringTasks, datesList } =
            createVirtualTasksFromRecurringTasks(newTaskData);
          rawTaskDataForLocalSave = fillRecurrenceOptsInTaskData(
            newRecurringTasks[0],
            newTaskData
          );

          let updatesForDb = {};

          if (!isEmpty(projectDbUpdates)) {
            updatesForDb = {
              ...updatesForDb,
              ...projectDbUpdates,
            };
          }

          if (!isEmpty(updatesForDb)) {
            await DatabaseInterface.update(updatesForDb, this.userInfo.uid);
          }

          addOrRemoveOrUpdateTasksInDb(
            {
              tasksToUpdate: [mainTaskDataDbUpdate],
              tasksToRemove: recurringTasksAfterCurrTaskDate,
              tasksToAdd: [newTaskData],
            },
            undefined,
            this.userInfo.uid
          );

          let userActions = {
            type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
            data: {
              [newTaskData.key]: {
                updateMain: true,
                editedData: editedTaskData,
                recreateTasks: true,
              },
            },
            on: "task",
          };

          if (!isEmpty(editProjectData)) {
            userActions = [
              {
                type: PROJECT_ACTIONS.EDIT,
                data: editProjectData,
                on: "project",
              },
              {
                ...userActions,
              },
            ];
          }

          if (!isInResourceMode) {
            if (Array.isArray(userActions)) {
              getUserActions().addBatchAction({
                data: userActions,
                on: "all",
              });
            } else {
              getUserActions().addTaskAction(userActions);
            }
          }

          await addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToUpdate: [
              {
                key: mainTaskId,
                updates: {
                  ...this.rawTasksMap[mainTaskId],
                  recurrence: convertedRules,
                },
              },
            ],
            tasksToAdd: newRecurringTasks,
            tasksToRemove: [
              ...recurringTasksAfterCurrTaskDate,
              ...reminderTasksToRemove,
            ],
            addMethod: "push",
            verifyAdd: false,
          });

          addRecurringTasksInfoInMap(newTaskData.key, datesList);
          removeTasksFromRecurringTasksMapByDates(
            mainTaskId,
            updatedRecurrences
          );

          if (onlyProjectPriorityChanged) {
            EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
          }
          await this.updateTasksInGoogleCal(mainTaskGoogleUpdate);
          this.addTasksToGoogleCal(googleEventData);
          await Vue.nextTick();

          if (!isInResourceMode) {
            this.updateEditTaskData(rawTaskDataForLocalSave, isAreaChanged);
          } else {
            await this.processResourceDataAndTaskDataUpdates({
              userActions,
              localTaskDataForForm: processTask(rawTaskDataForLocalSave),
            });
          }
          this.sendSuccessSubmit();
        } else {
          let taskDataToUse = this.taskData;
          let removeLinkedTasks = false;
          let recurringEditedTasks = [];
          let dbRemoveTaskList = [];
          let localRemoveTaskList = [];
          let reminderTasksToRemove = [];
          let reminderTasksToAdd = [];
          let googleRemoveEventList = [];
          let dbTasksUpdate = [];
          let localTasksUpdate = [];
          let googleTasksUpdate = [];
          let createVirtualTasks = false;
          let localTaskUpdatedData = {};
          let recurringTasksToAdd = [];
          let recurringTasksDates = [];
          let recreateTasks = false;
          let updateVirtualTasks = false;
          let rawTaskDataForLocalSave = {};

          const reminderTasksLinked = getReminderTasksLinkedToTaskId(
            this.tasks,
            currTaskData.key
          );

          if (!isEmpty(reminderTasksLinked)) {
            reminderTasksToRemove.push(...reminderTasksLinked);
          }

          if (
            isFirstTask &&
            !isEmpty(this.rawTasksMap[currTaskData.linkedTo])
          ) {
            const mainTaskId = currTaskData.linkedTo;
            const mainTaskData = this.rawTasksMap[mainTaskId];
            newData.coRelationalId = mainTaskData.coRelationalId;
            taskDataToUse = {
              ...mainTaskData,
              dueAsDate: createDateTimeFromDue(
                mainTaskData.due,
                mainTaskData.time
              ),
            };

            const reminderTasksLinked = getReminderTasksLinkedToTaskId(
              this.tasks,
              currTaskData.key
            );
            if (!isEmpty(reminderTasksLinked)) {
              reminderTasksToRemove.push(...reminderTasksLinked);
            }
          }
          const isIntervalOrDayChanged =
            checkIfTaskRecurrenceIntervalOrDayChanged(
              this.updatedTaskData,
              taskDataToUse,
              "existing"
            );

          if (
            !isEqual(this.taskData.dueAsDate, newData.dueAsDate) ||
            !isEqual(this.taskData.frequency, newData.frequency) ||
            isIntervalOrDayChanged
          ) {
            removeLinkedTasks = true;
            recreateTasks = true;
          } else {
            updateVirtualTasks = true;
          }

          if (
            !isEqual(taskDataToUse.recurrence, newData.recurrence) &&
            !isEmpty(newData.recurrence)
          ) {
            recreateTasks = true;
            createVirtualTasks = true;
          }

          const {
            projectDbUpdates,
            updatedTaskData,
            editedTaskData,
            editProjectData,
            onlyProjectPriorityChanged,
          } = this.createDataForRecurringTask(
            newData,
            "update",
            undefined,
            { ...taskDataToUse },
            true
          );
          const createdUpdatedGoogleData = createTaskDataForGoogleCal(
            { ...newData, taskKey: taskDataToUse.key },
            taskDataToUse
          );

          const newMainTaskData = { ...newData };

          dbTasksUpdate.push({
            key: taskDataToUse.key,
            updates: updatedTaskData,
          });

          localTaskUpdatedData = {
            ...taskDataToUse,
            ...updatedTaskData,
          };

          localTasksUpdate.push({
            key: taskDataToUse.key,
            updates: localTaskUpdatedData,
          });

          if (taskDataToUse.googleEventId) {
            if (isEmpty(createdUpdatedGoogleData.recurrence)) {
              delete createdUpdatedGoogleData.recurrence;
            }
            googleTasksUpdate.push({
              key: taskDataToUse.googleEventId,
              updates: {
                ...createdUpdatedGoogleData,
              },
            });
          }

          const linkedTasks = getRecurringTasks(this.tasks, "list", {
            linkedTo: taskDataToUse.key,
          });

          if (!isEmpty(linkedTasks)) {
            if (removeLinkedTasks) {
              dbRemoveTaskList = linkedTasks;
              localRemoveTaskList = linkedTasks;

              linkedTasks.forEach((lT) => {
                const linkedReminderTasks = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  lT.key
                );

                if (!isEmpty(linkedReminderTasks)) {
                  reminderTasksToRemove.push(...linkedReminderTasks);
                }
                if (!lT.isVirtual) {
                  googleRemoveEventList.push(lT);
                }
              });
            } else {
              let updatedDataForLinkedTasks = cloneDeep(updatedTaskData);
              updatedDataForLinkedTasks = removeProps(
                updatedDataForLinkedTasks,
                [
                  "recurrence",
                  "linkedTo",
                  "coRelationalId",
                  "key",
                  "googleEventId",
                  "isFirstTask",
                ]
              );

              // const taskDataForGoogle = cloneDeep(createdUpdatedGoogleData);
              // delete taskDataForGoogle.recurrence;
              linkedTasks.forEach((taskD) => {
                const createdDataForUpdate = {
                  ...updatedDataForLinkedTasks,
                  googleEventId: taskD.googleEventId || "",
                  googleCalendarId: taskD.googleEventId
                    ? GoogleCalHelpers.getStoredCalId()
                    : "",
                  ...getDateAndTimeFromDueDate(
                    taskD.dueAsDate,
                    undefined,
                    false
                  ),
                  key: taskD.key,
                  isFirstTask: !!taskD.isFirstTask,
                  isCalendarOnly: !!taskD.isCalendarOnly,
                  linkedTo: taskDataToUse.key,
                };

                const remindersLinkedToTask = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  taskD.key
                );

                if (!isEmpty(remindersLinkedToTask)) {
                  reminderTasksToRemove.push(...remindersLinkedToTask);
                }

                const { editedTaskData: taskEditedData } =
                  this.createDataForRecurringTask(
                    {
                      ...createdDataForUpdate,
                      dueAsDate: createDateTimeFromDue(
                        createdDataForUpdate.due,
                        createdDataForUpdate.time
                      ),
                    },
                    "update",
                    false,
                    taskD
                  );

                dbTasksUpdate.push({
                  key: taskD.key,
                  updates: createdDataForUpdate,
                });

                const localTaskUpdatedData = {
                  ...taskD,
                  ...createdDataForUpdate,
                };

                if (
                  checkIfTaskHasReminder(localTaskUpdatedData) &&
                  !localTaskUpdatedData.completed
                ) {
                  reminderTasksToAdd.push(
                    ...createReminderTasks(localTaskUpdatedData)
                  );
                }
                localTasksUpdate.push({
                  key: taskD.key,
                  updates: { ...localTaskUpdatedData },
                });

                if (!taskD.isVirtual) {
                  recurringEditedTasks.push({
                    ...taskEditedData,
                    key: taskD.key,
                    isVirtual: !!taskD.isVirtual,
                    isFromGoogle: !!taskD.isFromGoogle,
                  });

                  if (taskD.googleEventId) {
                    googleTasksUpdate.push({
                      key: taskD.googleEventId,
                      updates: {
                        ...createTaskDataForGoogleCal(
                          {
                            ...newMainTaskData,
                            dueAsDate: taskD.dueAsDate,
                            taskKey: taskD.key,
                          },
                          { ...taskD }
                        ),
                        // ...taskDataForGoogle,
                        // ...convertTaskDateToAllDayOrTimeForGoogle(
                        //   taskD.dueAsDate
                        // ),
                      },
                    });
                  }
                }
              });
            }
          }

          rawTaskDataForLocalSave = { ...updatedTaskData };

          rawTaskDataForLocalSave = fillRecurrenceOptsInTaskData(
            rawTaskDataForLocalSave,
            localTaskUpdatedData
          );

          if (createVirtualTasks) {
            const res =
              createVirtualTasksFromRecurringTasks(localTaskUpdatedData);
            recurringTasksToAdd = res.tasks;
            recurringTasksDates = res.datesList;

            rawTaskDataForLocalSave = res.tasks[0];

            if (!isEmpty(recurringTasksDates)) {
              replaceRecurringTasksInfoInMap(
                taskDataToUse.key,
                recurringTasksDates
              );
            }
          } else {
            const recurrences =
              createRecurringDatesFromTaskRules(localTaskUpdatedData);
            const firstRecurrence = recurrences[0];
            const firstTaskData = getRecurrenceIdByDateFromRecurrences(
              firstRecurrence,
              localTaskUpdatedData.key
            );

            if (!isEmpty(firstTaskData)) {
              const taskData = this.tasks.find(
                (t) => t.key === firstTaskData.key
              );
              if (!isEmpty(taskData)) {
                rawTaskDataForLocalSave = { ...taskData };
              }
            }
          }

          let updatesForDb = {};

          if (!isEmpty(projectDbUpdates)) {
            updatesForDb = {
              ...updatesForDb,
              ...projectDbUpdates,
            };
          }

          if (!isEmpty(updatesForDb)) {
            DatabaseInterface.update(updatesForDb, this.userInfo.uid);
          }

          await addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToUpdate: localTasksUpdate,
            tasksToRemove: [...localRemoveTaskList, ...reminderTasksToRemove],
            tasksToAdd: [...recurringTasksToAdd, ...reminderTasksToAdd],
            addMethod:
              createVirtualTasks || !isEmpty(reminderTasksToAdd)
                ? "push"
                : "unshift",
            verifyAdd: !createVirtualTasks && isEmpty(reminderTasksToAdd),
          });

          await addOrRemoveOrUpdateTasksInDb(
            {
              tasksToUpdate: dbTasksUpdate,
              tasksToRemove: dbRemoveTaskList,
            },
            undefined,
            this.userInfo.uid
          );

          let userActions = {
            type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
            data: {
              [taskDataToUse.key]: {
                editedTasks: recurringEditedTasks,
                updateMain: true,
                editedData: editedTaskData,
                recreateTasks,
                updateVirtualTasks,
              },
            },
            on: "task",
          };

          if (!isEmpty(editProjectData)) {
            userActions = [
              {
                type: PROJECT_ACTIONS.EDIT,
                data: editProjectData,
                on: "project",
              },
              {
                ...userActions,
              },
            ];
          }

          const isInResourceMode = this.inResourceMode;
          if (!isInResourceMode) {
            if (Array.isArray(userActions)) {
              getUserActions().addBatchAction({
                data: userActions,
                on: "all",
              });
            } else {
              getUserActions().addTaskAction(userActions);
            }
          }
          addOrUpdateOrRemoveTasksInGoogleCal({
            tasksToUpdate: googleTasksUpdate,
            tasksToRemove: googleRemoveEventList,
          });

          if (onlyProjectPriorityChanged) {
            EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
          }

          await Vue.nextTick();

          if (!isInResourceMode) {
            this.updateEditTaskData(rawTaskDataForLocalSave, isAreaChanged);
          } else {
            await this.processResourceDataAndTaskDataUpdates({
              userActions,
              localTaskDataForForm: processTask(rawTaskDataForLocalSave),
            });
          }
          this.sendSuccessSubmit();
        }
      } catch (error) {
        console.debug("ERROR", error);
        this.toggleLoader(false);
        this.showErrorToast();
      } finally {
        this.closeEditForm();
      }
      this.toggleLoader(false);
    },
    async updateAllTasks() {
      this.toggleLoader(true);
      try {
        const coRelationalId = this.taskData.coRelationalId;
        const isVirtualTask = this.taskData.isVirtual;
        const linkedTo = this.taskData.linkedTo;
        let mainTaskData = {};

        if (isVirtualTask || linkedTo) {
          mainTaskData = this.rawTasksMap[this.taskData.linkedTo];
        }

        const coRelatedTasks = getRecurringTasks(this.tasks, "list", {
          coRelationalId,
        });

        const newTaskData = this.updatedTaskData;

        let googleUpdateList = [];
        let dbUpdateList = [];
        let dbRemoveTaskList = [];
        let googleRemoveTaskList = [];
        let convertAllTasksToAllDay = false;
        let updateMainAndRemoveAllRest = false;
        let localTableListUpdates = [];
        let mainTaskLocalUpdatedData = {};
        let normalUpdateAllTasks = false;
        let createVirtualTasks = false;
        let recurringTasksToAdd = [];
        let recurringTasksDates = [];
        let recreateTasks = false;
        let updateVirtualTasks = false;
        let saveOldFrequency = false;
        let recurringEditedTaskDataMap = {};
        let recurringEditedTasks = [];
        let newRecurrenceRulesData = {};
        let rawTaskDataForLocalSave = {};
        if (!isEmpty(mainTaskData)) {
          const newMainTaskData = { ...newTaskData };
          const oldMainTaskData = { ...this.taskData };
          const createdUpdatedGoogleData = createTaskDataForGoogleCal(
            { ...newMainTaskData, taskKey: oldMainTaskData.key },
            oldMainTaskData
          );

          const {
            updatedTaskData,
            editProjectData,
            onlyProjectPriorityChanged,
            projectDbUpdates,
          } = this.createDataForRecurringTask(
            newTaskData,
            "update",
            undefined,
            this.taskData
          );

          const isAreaChanged = newTaskData.project !== this.taskData.project;

          if (isAreaChanged) {
            const mentionedProjectResources = getProjectResourcesFromText(
              newTaskData.title
            );

            if (!isEmpty(mentionedProjectResources)) {
              changeCatOfProjectResources([
                {
                  resources: mentionedProjectResources,
                  catId: newTaskData.project,
                  isTaskRef: true,
                },
              ]);
            }
          }

          const linkedTasksWhichAreNotCompleted = filterRecurringTasks(
            coRelatedTasks,
            [isTaskNotCompleted]
          );

          if (
            !isSameDay(newTaskData.dueAsDate, this.taskData.dueAsDate) &&
            isEqual(newTaskData.frequency, this.taskData.frequency)
          ) {
            saveOldFrequency = true;
            updateMainAndRemoveAllRest = true;
            recreateTasks = true;
          } else if (
            !isEqual(newTaskData.dueAsDate, this.taskData.dueAsDate) &&
            isEqual(newTaskData.frequency, this.taskData.frequency)
          ) {
            if (checkTaskIsAllDay(newTaskData.dueAsDate)) {
              convertAllTasksToAllDay = true;
              updateVirtualTasks = true;
            } else {
              updateMainAndRemoveAllRest = true;
              recreateTasks = true;
            }
            // Date Changed
          } else if (
            !isEqual(newTaskData.frequency, this.taskData.frequency) ||
            checkIfTaskRecurrenceIntervalOrDayChanged(newTaskData, mainTaskData)
          ) {
            // Frequency changed
            saveOldFrequency = true;
            updateMainAndRemoveAllRest = true;
            recreateTasks = true;
          } else {
            // Data changed
            normalUpdateAllTasks = true;
            updateVirtualTasks = true;
          }
          if (updateMainAndRemoveAllRest) {
            newRecurrenceRulesData = createAdditionalDates({
              frequency: frequencyOptsMap[this.updatedTaskData.frequency],
              startDateTime: this.updatedTaskData.dueAsDate,
              repeatDay: this.updatedTaskData.recurrenceRepeatDay,
              repeatCount: this.updatedTaskData.recurrenceCount,
            });
          }

          if (convertAllTasksToAllDay && !updateMainAndRemoveAllRest) {
            let remindersToRemove = [];
            let remindersToAdd = [];
            const dataOfMainTask = {
              ...mainTaskData,
              dueAsDate: createDateTimeFromDue(
                mainTaskData.due,
                mainTaskData.time
              ),
            };

            const { updatedTaskData: mainTaskUpdatedData, editedTaskData } =
              this.createDataForRecurringTask(
                newTaskData,
                "update",
                undefined,
                dataOfMainTask,
                true
              );

            const allRemindersLinkedToMain = getReminderTasksLinkedToTaskId(
              this.tasks,
              mainTaskData.key
            );

            if (!isEmpty(allRemindersLinkedToMain)) {
              remindersToRemove.push(...allRemindersLinkedToMain);
            }

            const mainTaskDataDbUpdate = {
              key: mainTaskData.key,
              updates: {
                ...mainTaskUpdatedData,
                linkedTo: "",
                key: mainTaskData.key,
                googleEventId: mainTaskData.googleEventId || "",
                googleCalendarId: mainTaskData.googleEventId
                  ? GoogleCalHelpers.getStoredCalId()
                  : "",
              },
            };

            mainTaskLocalUpdatedData = {
              ...mainTaskData,
              ...mainTaskUpdatedData,
            };

            rawTaskDataForLocalSave = {
              ...mainTaskUpdatedData,
              linkedTo: "",
              key: mainTaskData.key,
              googleEventId: mainTaskData.googleEventId || "",
              googleCalendarId: mainTaskData.googleEventId
                ? GoogleCalHelpers.getStoredCalId()
                : "",
            };

            if (!isEmpty(newRecurrenceRulesData)) {
              mainTaskDataDbUpdate.updates.recurrence =
                newRecurrenceRulesData.rules;
              mainTaskLocalUpdatedData.recurrence =
                newRecurrenceRulesData.rules;
              rawTaskDataForLocalSave.recurrence = newRecurrenceRulesData.rules;
            }

            // if (
            //   checkIfTaskHasReminder(mainTaskLocalUpdatedData) &&
            //   !mainTaskLocalUpdatedData.completed
            // ) {
            //   remindersToAdd.push(
            //     ...createReminderTasks(mainTaskLocalUpdatedData)
            //   );
            // }
            localTableListUpdates.push({
              key: mainTaskData.key,
              updates: {
                ...mainTaskLocalUpdatedData,
              },
            });

            const mainTaskGoogleUpdate = {
              key: mainTaskData.googleEventId,
              updates: {
                ...createTaskDataForGoogleCal(
                  {
                    ...newTaskData,
                    ...mainTaskDataDbUpdate.updates,
                    taskKey: dataOfMainTask.key,
                  },
                  dataOfMainTask
                ),
              },
            };

            dbUpdateList.push(mainTaskDataDbUpdate);

            if (this.isGoogleCalSyncEnabled) {
              googleUpdateList.push(mainTaskGoogleUpdate);
            }

            if (
              linkedTasksWhichAreNotCompleted &&
              linkedTasksWhichAreNotCompleted.length
            ) {
              const taskDataUpdated = cloneDeep(updatedTaskData);
              // const taskDataForGoogle = cloneDeep(createdUpdatedGoogleData);
              delete taskDataUpdated.recurrence;
              // delete taskDataForGoogle.recurrence;
              linkedTasksWhichAreNotCompleted.forEach((taskData) => {
                const createdDataForUpdate = {
                  ...taskDataUpdated,
                  googleEventId: taskData.googleEventId || "",
                  due: formatDate(taskData.dueAsDate, INTERNAL_DATE_FORMAT),
                  time: "00:00",
                  key: taskData.key,
                  linkedTo: mainTaskData.key,
                  isFirstTask: !!taskData.isFirstTask,
                  isCalendarOnly: !!taskData.isCalendarOnly,
                };
                const localRemindersList = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  taskData.key
                );

                if (!isEmpty(localRemindersList)) {
                  remindersToRemove.push(...localRemindersList);
                }
                const { editedTaskData: taskEditedData } =
                  this.createDataForRecurringTask(
                    {
                      ...createdDataForUpdate,
                      dueAsDate: createDateTimeFromDue(
                        createdDataForUpdate.due,
                        createdDataForUpdate.time
                      ),
                    },
                    "update",
                    false,
                    taskData
                  );

                dbUpdateList.push({
                  key: taskData.key,
                  updates: createdDataForUpdate,
                });

                const localUpdatedData = {
                  ...taskData,
                  ...createdDataForUpdate,
                };

                if (
                  checkIfTaskHasReminder(localUpdatedData) &&
                  !localUpdatedData.completed
                ) {
                  remindersToAdd.push(...createReminderTasks(localUpdatedData));
                }

                localTableListUpdates.push({
                  key: taskData.key,
                  updates: { ...localUpdatedData },
                });

                if (!taskData.isVirtual) {
                  recurringEditedTasks.push({
                    ...taskEditedData,
                    key: taskData.key,
                  });
                  if (taskData.googleEventId) {
                    const createdGoogleTaskData = createTaskDataForGoogleCal(
                      {
                        ...newMainTaskData,
                        dueAsDate: taskData.dueAsDate,
                        taskKey: taskData.key,

                        // ...convertTaskDateToAllDayOrTimeForGoogle(
                        //   taskData.dueAsDate
                        // ),
                      },
                      { ...taskData }
                    );

                    delete createdGoogleTaskData.recurrence;
                    googleUpdateList.push({
                      key: taskData.googleEventId,
                      updates: createdGoogleTaskData,
                    });
                  }
                }
              });
            }
            // if (createVirtualTasks) {
            //   const res = createVirtualTasksFromRecurringTasks(
            //     mainTaskLocalUpdatedData
            //   );
            //   recurringTasksToAdd = res.tasks;
            //   recurringTasksDates = res.datesList;
            //   rawTaskDataForLocalSave = res.tasks[0];
            // }

            recurringEditedTaskDataMap[mainTaskData.key] = {
              editedTasks: recurringEditedTasks,
              updateMain: true,
              editedData: editedTaskData,
              recreateTasks,
              updateVirtualTasks,
              // recreateTasks: true,
            };

            let userActions = {
              type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
              data: recurringEditedTaskDataMap,
              on: "task",
            };

            let updatesForDb = {};

            if (!isEmpty(projectDbUpdates)) {
              updatesForDb = {
                ...updatesForDb,
                ...projectDbUpdates,
              };
            }

            if (!isEmpty(editProjectData)) {
              userActions = [
                {
                  type: PROJECT_ACTIONS.EDIT,
                  data: editProjectData,
                  on: "project",
                },
                {
                  ...userActions,
                },
              ];
            }

            if (!this.inResourceMode) {
              if (Array.isArray(userActions)) {
                getUserActions().addBatchAction({
                  data: userActions,
                  on: "all",
                });
              } else {
                getUserActions().addTaskAction(userActions);
              }
            }

            if (!isEmpty(updatesForDb)) {
              DatabaseInterface.update(updatesForDb, this.userInfo.uid);
            }

            await addOrRemoveOrUpdateTasksInDb(
              {
                tasksToUpdate: dbUpdateList,
              },
              undefined,
              this.userInfo.uid
            );

            // Db code

            await addOrRemoveOrUpdateTasksInLocalTasksList({
              tasksToUpdate: localTableListUpdates,
              tasksToAdd: [...recurringTasksToAdd, ...remindersToAdd],
              tasksToRemove: [...remindersToRemove],
              addMethod:
                createVirtualTasks || !isEmpty(remindersToAdd)
                  ? "push"
                  : "unshift",
              verifyAdd: !createVirtualTasks && isEmpty(remindersToAdd),
            });

            const recurrences = createRecurringDatesFromTaskRules(
              mainTaskLocalUpdatedData
            );
            const firstRecurrence = recurrences[0];
            const firstTaskData = getRecurrenceIdByDateFromRecurrences(
              firstRecurrence,
              mainTaskLocalUpdatedData.key
            );

            if (!isEmpty(firstTaskData)) {
              const taskData = this.tasks.find(
                (t) => t.key === firstTaskData.key
              );
              if (!isEmpty(taskData)) {
                rawTaskDataForLocalSave = { ...taskData };
              }
            }

            rawTaskDataForLocalSave = fillRecurrenceOptsInTaskData(
              rawTaskDataForLocalSave,
              mainTaskLocalUpdatedData
            );

            // if (!isEmpty(recurringTasksDates)) {
            //   replaceRecurringTasksInfoInMap(
            //     mainTaskLocalUpdatedData.key,
            //     recurringTasksDates
            //   );
            // }

            // Google code
            this.addOrUpdateOrRemoveTasksInGoogleCal({
              tasksToUpdate: googleUpdateList,
            });

            if (onlyProjectPriorityChanged) {
              EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
            }

            await Vue.nextTick();

            if (!this.inResourceMode) {
              this.updateEditTaskData(rawTaskDataForLocalSave, isAreaChanged);
            } else {
              await this.processResourceDataAndTaskDataUpdates({
                userActions,
                localTaskDataForForm: processTask(rawTaskDataForLocalSave),
              });
            }
            this.sendSuccessSubmit();
          } else if (!convertAllTasksToAllDay && updateMainAndRemoveAllRest) {
            let rulesToSave = newRecurrenceRulesData.rules;
            let reminderTasksToRemove = [];

            if (saveOldFrequency) {
              const oldRecurrenceRulesData = createAdditionalDates({
                frequency: frequencyOptsMap[this.taskData.frequency],
                startDateTime: this.taskData.dueAsDate,
                repeatDay: this.taskData.recurrenceRepeatDay,
                repeatCount: this.taskData.recurrenceCount,
              });
              rulesToSave = oldRecurrenceRulesData.rules;
            }

            const allRemindersLinkedToMain = getReminderTasksLinkedToTaskId(
              this.tasks,
              mainTaskData.key
            );

            if (!isEmpty(allRemindersLinkedToMain)) {
              reminderTasksToRemove.push(...allRemindersLinkedToMain);
            }
            const dataOfMainTask = {
              ...mainTaskData,
              dueAsDate: createDateTimeFromDue(
                mainTaskData.due,
                mainTaskData.time
              ),
            };

            const {
              updatedTaskData: newUpdatedTaskDataForMain,
              editedTaskData: mainTaskEditedData,
            } = this.createDataForRecurringTask(
              newTaskData,
              "update",
              undefined,
              { ...dataOfMainTask, recurrence: rulesToSave },
              true
            );

            const updatedDataForMainTask = {
              ...newUpdatedTaskDataForMain,
              linkedTo: "",
              key: mainTaskData.key,
              googleEventId: mainTaskData.googleEventId || "",
              googleCalendarId: mainTaskData.googleEventId
                ? GoogleCalHelpers.getStoredCalId()
                : "",
            };

            if (!isEmpty(newRecurrenceRulesData)) {
              updatedDataForMainTask.recurrence = newRecurrenceRulesData.rules;
            }

            mainTaskLocalUpdatedData = {
              ...updatedDataForMainTask,
            };

            rawTaskDataForLocalSave = { ...mainTaskLocalUpdatedData };
            const mainTaskDataDbUpdate = {
              key: mainTaskData.key,
              updates: updatedDataForMainTask,
            };

            const mainTaskGoogleUpdate = {
              key: mainTaskData.googleEventId,
              updates: {
                ...createTaskDataForGoogleCal(
                  { ...newTaskData, taskKey: mainTaskData.key },
                  dataOfMainTask
                ),
                // ...convertTaskDateToAllDayOrTimeForGoogle(
                //   newTaskData.dueAsDate,
                //   "time"
                // ),
              },
            };
            dbUpdateList.push(mainTaskDataDbUpdate);
            localTableListUpdates.push({
              key: mainTaskData.key,
              updates: {
                ...mainTaskData,
                ...updatedDataForMainTask,
              },
            });
            if (this.isGoogleCalSyncEnabled) {
              googleUpdateList.push(mainTaskGoogleUpdate);
            }
            if (
              linkedTasksWhichAreNotCompleted &&
              linkedTasksWhichAreNotCompleted.length
            ) {
              linkedTasksWhichAreNotCompleted.forEach((taskData) => {
                dbRemoveTaskList.push(taskData);

                const localReminderTasksLinked = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  taskData.key
                );

                if (!isEmpty(localReminderTasksLinked)) {
                  reminderTasksToRemove.push(...localReminderTasksLinked);
                }
                if (!taskData.isVirtual && taskData.googleEventId) {
                  googleRemoveTaskList.push(taskData);
                }
              });
            }
            const res = createVirtualTasksFromRecurringTasks(
              mainTaskLocalUpdatedData
            );
            recurringTasksToAdd = res.tasks;
            recurringTasksDates = res.datesList;
            rawTaskDataForLocalSave = res.tasks[0];

            rawTaskDataForLocalSave = fillRecurrenceOptsInTaskData(
              rawTaskDataForLocalSave,
              mainTaskLocalUpdatedData
            );

            recurringEditedTaskDataMap[mainTaskData.key] = {
              updateMain: true,
              editedData: mainTaskEditedData,
              recreateTasks: true,
            };

            let updatesForDb = {};

            if (!isEmpty(projectDbUpdates)) {
              updatesForDb = {
                ...updatesForDb,
                ...projectDbUpdates,
              };
            }

            let userActions = {
              type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
              data: recurringEditedTaskDataMap,
              on: "task",
            };

            if (!isEmpty(editProjectData)) {
              userActions = [
                {
                  type: PROJECT_ACTIONS.EDIT,
                  data: editProjectData,
                  on: "project",
                },
                {
                  ...userActions,
                },
              ];
            }

            if (!this.inResourceMode) {
              if (Array.isArray(userActions)) {
                getUserActions.addBatchAction({
                  data: userActions,
                  on: "all",
                });
              } else {
                getUserActions().addTaskAction(userActions);
              }
            }

            if (!isEmpty(updatesForDb)) {
              DatabaseInterface.update(updatesForDb, this.userInfo.uid);
            }
            await addOrRemoveOrUpdateTasksInLocalTasksList({
              tasksToUpdate: localTableListUpdates,
              tasksToRemove: [...dbRemoveTaskList, ...reminderTasksToRemove],
              tasksToAdd: recurringTasksToAdd,
              addMethod: createVirtualTasks ? "push" : "unshift",
              verifyAdd: !createVirtualTasks,
            });

            if (!isEmpty(recurringTasksDates)) {
              replaceRecurringTasksInfoInMap(
                mainTaskLocalUpdatedData.key,
                recurringTasksDates
              );
            }

            // Db code
            await addOrRemoveOrUpdateTasksInDb(
              {
                tasksToUpdate: dbUpdateList,
                tasksToRemove: dbRemoveTaskList,
              },
              undefined,
              this.userInfo.uid
            );

            this.addOrUpdateOrRemoveTasksInGoogleCal({
              tasksToRemove: googleRemoveTaskList,
              tasksToUpdate: googleUpdateList,
            });

            if (onlyProjectPriorityChanged) {
              EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
            }

            await Vue.nextTick();

            if (!this.inResourceMode) {
              this.updateEditTaskData(rawTaskDataForLocalSave, isAreaChanged);
            } else {
              await this.processResourceDataAndTaskDataUpdates({
                userActions,
                localTaskDataForForm: processTask(rawTaskDataForLocalSave),
              });
            }
            this.sendSuccessSubmit();
          } else if (normalUpdateAllTasks) {
            let reminderTasksToRemove = [];
            let reminderTasksToAdd = [];
            const reminderTasksLinkedToMain = getReminderTasksLinkedToTaskId(
              this.tasks,
              mainTaskData.key
            );

            if (!isEmpty(reminderTasksLinkedToMain)) {
              reminderTasksToRemove.push(...reminderTasksLinkedToMain);
            }
            // Normal Update
            const dataOfMainTask = {
              ...mainTaskData,
              dueAsDate: createDateTimeFromDue(
                mainTaskData.due,
                mainTaskData.time
              ),
            };

            const {
              updatedTaskData: mainTaskUpdatedData,
              editedTaskData: mainTaskEditedData,
            } = this.createDataForRecurringTask(
              newTaskData,
              "update",
              undefined,
              dataOfMainTask,
              false,
              true
            );

            const updatedDataForMainTask = {
              ...mainTaskUpdatedData,
              ...getDateAndTimeFromDueDate(dataOfMainTask.dueAsDate),
              linkedTo: "",
              key: mainTaskData.key,
              googleEventId: mainTaskData.googleEventId || "",
              googleCalendarId: mainTaskData.googleEventId
                ? GoogleCalHelpers.getStoredCalId()
                : "",
            };
            mainTaskLocalUpdatedData = {
              ...updatedDataForMainTask,
            };

            rawTaskDataForLocalSave = { ...mainTaskLocalUpdatedData };
            if (!isEmpty(newRecurrenceRulesData)) {
              updatedDataForMainTask.recurrence = newRecurrenceRulesData.rules;
              createdUpdatedGoogleData.recurrence =
                newRecurrenceRulesData.rules;
            }

            const mainTaskDataDbUpdate = {
              key: mainTaskData.key,
              updates: updatedDataForMainTask,
            };

            const mainTaskGoogleUpdate = {
              key: mainTaskData.googleEventId,
              updates: createTaskDataForGoogleCal(
                { ...newTaskData, taskKey: mainTaskData.key },
                dataOfMainTask
              ),
            };

            dbUpdateList.push(mainTaskDataDbUpdate);
            localTableListUpdates.push({
              key: mainTaskData.key,
              updates: {
                ...mainTaskData,
                ...updatedDataForMainTask,
              },
            });
            if (this.isGoogleCalSyncEnabled) {
              googleUpdateList.push(mainTaskGoogleUpdate);
            }

            if (
              linkedTasksWhichAreNotCompleted &&
              linkedTasksWhichAreNotCompleted.length
            ) {
              const taskDataUpdated = cloneDeep(updatedTaskData);
              // const taskDataForGoogle = cloneDeep(createdUpdatedGoogleData);
              delete taskDataUpdated.recurrence;
              // delete taskDataForGoogle.recurrence;
              linkedTasksWhichAreNotCompleted.forEach((taskData) => {
                const updatedDataForTask = {
                  ...taskDataUpdated,
                  ...getDateAndTimeFromDueDate(taskData.dueAsDate),
                  googleEventId: taskData.googleEventId || "",
                  googleCalendarId: taskData.googleEventId
                    ? GoogleCalHelpers.getStoredCalId()
                    : "",
                  key: taskData.key,
                  linkedTo: mainTaskData.key,
                  isCalendarOnly: !!taskData.isCalendarOnly,
                  isFirstTask: !!taskData.isFirstTask,
                };

                const { editedTaskData: taskEditedData } =
                  this.createDataForRecurringTask(
                    {
                      ...updatedDataForTask,
                      dueAsDate: createDateTimeFromDue(
                        updatedDataForTask.due,
                        updatedDataForTask.time
                      ),
                    },
                    "update",
                    false,
                    taskData
                  );
                dbUpdateList.push({
                  key: taskData.key,
                  updates: updatedDataForTask,
                });

                const localTaskUpdated = {
                  ...taskData,
                  ...updatedDataForTask,
                };

                const remindersLinkedToTask = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  taskData.key
                );

                if (!isEmpty(remindersLinkedToTask)) {
                  reminderTasksToRemove.push(...remindersLinkedToTask);
                }

                if (
                  checkIfTaskHasReminder(localTaskUpdated) &&
                  !localTaskUpdated.completed
                ) {
                  reminderTasksToAdd.push(
                    ...createReminderTasks(localTaskUpdated)
                  );
                }
                localTableListUpdates.push({
                  key: taskData.key,
                  updates: {
                    ...localTaskUpdated,
                  },
                });

                if (!taskData.isVirtual) {
                  recurringEditedTasks.push({
                    ...taskEditedData,
                    key: taskData.key,
                  });
                  if (taskData.googleEventId) {
                    const createdGoogleTaskData = createTaskDataForGoogleCal(
                      {
                        ...newMainTaskData,
                        dueAsDate: taskData.dueAsDate,
                        taskKey: taskData.key,

                        // ...convertTaskDateToAllDayOrTimeForGoogle(
                        //   taskData.dueAsDate
                        // ),
                      },
                      { ...taskData }
                    );

                    delete createdGoogleTaskData.recurrence;

                    googleUpdateList.push({
                      key: taskData.googleEventId,
                      updates: createdGoogleTaskData,
                    });
                  }
                }
              });
            }

            recurringEditedTaskDataMap[mainTaskData.key] = {
              editedTasks: recurringEditedTasks,
              updateMain: true,
              editedData: mainTaskEditedData,
              recreateTasks,
              updateVirtualTasks,
            };

            // eslint-disable-next-line no-unused-vars
            let updatesForDb = {};
            if (!isEmpty(projectDbUpdates)) {
              updatesForDb = {
                ...updatesForDb,
                ...projectDbUpdates,
              };
            }
            let userActions = {
              type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
              data: recurringEditedTaskDataMap,
              on: "task",
            };

            if (!isEmpty(editProjectData)) {
              userActions = [
                {
                  type: PROJECT_ACTIONS.EDIT,
                  data: editProjectData,
                  on: "project",
                },
                {
                  ...userActions,
                },
              ];
            }

            if (!this.inResourceMode) {
              if (Array.isArray(userActions)) {
                getUserActions().addBatchAction({
                  data: userActions,
                  on: "all",
                });
              } else {
                getUserActions().addTaskAction(userActions);
              }
            }

            if (!isEmpty(updatesForDb)) {
              DatabaseInterface.update(updatesForDb, this.userInfo.uid);
            }
            await addOrRemoveOrUpdateTasksInLocalTasksList({
              tasksToUpdate: localTableListUpdates,
              tasksToAdd: [...recurringTasksToAdd, ...reminderTasksToAdd],
              addMethod:
                createVirtualTasks || !isEmpty(reminderTasksToAdd)
                  ? "push"
                  : "unshift",
              tasksToRemove: !isEmpty(reminderTasksToRemove)
                ? reminderTasksToRemove
                : [],
              verifyAdd: !createVirtualTasks && isEmpty(reminderTasksToAdd),
            });

            const recurrences = createRecurringDatesFromTaskRules(
              mainTaskLocalUpdatedData
            );
            const firstRecurrence = recurrences[0];
            const firstTaskData = getRecurrenceIdByDateFromRecurrences(
              firstRecurrence,
              mainTaskLocalUpdatedData.key
            );

            if (!isEmpty(firstTaskData)) {
              const taskData = this.tasks.find(
                (t) => t.key === firstTaskData.key
              );
              if (!isEmpty(taskData)) {
                rawTaskDataForLocalSave = { ...taskData };
              }
            }
            rawTaskDataForLocalSave = fillRecurrenceOptsInTaskData(
              rawTaskDataForLocalSave,
              mainTaskLocalUpdatedData
            );

            if (!isEmpty(recurringTasksDates)) {
              replaceRecurringTasksInfoInMap(
                mainTaskLocalUpdatedData.key,
                recurringTasksDates
              );
            }

            await addOrRemoveOrUpdateTasksInDb(
              {
                tasksToUpdate: dbUpdateList,
              },
              undefined,
              this.userInfo.uid
            );

            this.updateTasksInGoogleCal(googleUpdateList);

            if (onlyProjectPriorityChanged) {
              EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
            }

            await Vue.nextTick();

            if (!this.inResourceMode) {
              this.updateEditTaskData(rawTaskDataForLocalSave, isAreaChanged);
            } else {
              await this.processResourceDataAndTaskDataUpdates({
                userActions,
                localTaskDataForForm: processTask(rawTaskDataForLocalSave),
              });
            }
            this.sendSuccessSubmit();
          }
        }
      } catch (error) {
        this.toggleLoader(false);
        this.showErrorToast();
      } finally {
        this.closeEditForm();
      }
      this.toggleLoader(false);
    },
    submit() {
      const currOpt = this.selectedOpt;

      if (!currOpt) {
        this.$swal({
          titleText: "Oops!",
          html: "Please select an option",
          icon: "warning",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
        });

        return;
      }
      this.close();
      switch (currOpt) {
        case "self":
          this.updateSelfTask();
          break;
        case "withFollowing":
          this.updateSelfAndFollowTasks();
          break;
        case "all":
          this.updateAllTasks();
          break;
      }
    },
    handleKeyDown(e) {
      if (e.keyCode === 27) {
        this.close();
      }
    },
  },
  computed: {
    ...mapGetters("editForm", [
      "isEditConfirmBoxOpened",
      "updatedTaskData",
      "updatedProjectData",
      "updatedObjectiveData",
      "resourceDataChanges",
    ]),
    ...mapGetters("editForm", {
      storedTaskData: "taskData",
    }),
    ...mapGetters("resourceInfo", {
      storedTaskDataInResource: "storedTaskData",
      storedResourceData: "data",
    }),
    ...mapGetters("resourcesData", {
      rawResourcesMap: "rawResourcesMap",
    }),
    ...mapGetters("task", [
      "projects",
      "rawTasksMap",
      "tasks",
      "rawTasks",
      "objectivesMap",
    ]),
    ...mapGetters(["isGoogleCalSyncEnabled"]),

    taskData() {
      if (this.inResourceMode && !isEmpty(this.storedTaskDataInResource)) {
        return this.storedTaskDataInResource;
      }

      return this.storedTaskData;
    },
    editOpts() {
      const currOpts = [...editOpts];
      let frequencyChanged = false;
      let intervalOrDayChanged = false;
      let dateChanged = false;
      if (!isEmpty(this.taskData) && !isEmpty(this.updatedTaskData)) {
        frequencyChanged =
          this.taskData.frequency !== this.updatedTaskData.frequency;

        const currDueDate = this.taskData.dueAsDate;
        const newDueDate = this.updatedTaskData.dueAsDate;

        dateChanged = !isSameDay(currDueDate, newDueDate);
        intervalOrDayChanged = checkIfTaskRecurrenceIntervalOrDayChanged(
          this.updatedTaskData,
          this.taskData,
          "existing",
          "existing"
        );
      }

      if (frequencyChanged || intervalOrDayChanged) {
        currOpts.splice(0, 1);
      }

      if (dateChanged) {
        currOpts.splice(2, 1);
      }

      return currOpts;
    },
  },
};
</script>
