const importAll = (r) =>
  r.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = r(next);
    return acc;
  }, {});

const icons = importAll(
  require.context("../assets/icons/svg", false, /\.(png|jpe?g|svg)$/)
);

const CTI_ICONS_VALUE = {};

Object.entries(icons).forEach(([key, value]) => {
  let name =
    "cti" +
    key
      .split("-")
      .map((v) => v.charAt(0).toUpperCase() + v.substring(1))
      .join("");
  name = name.replace(".svg", "");

  CTI_ICONS_VALUE[name] = {
    component: value,
  };
});

export default CTI_ICONS_VALUE;
