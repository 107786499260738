<template>
  <v-dialog :value="show" hide-overlay persistent width="300">
    <v-card color="primary" class="pt-4">
      <v-card-text> No User Signed In </v-card-text>
      <v-card-actions>
        <router-link to="/">
          <v-btn tile text color="white" class="float-right"> SIGN IN </v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
