<template>
  <component :is="illustrations[name]"></component>
</template>
<script>
const importAll = (r) =>
  r.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = r(next);
    return acc;
  }, {});

const svgs = importAll(require.context("./illustrations", false, /\.(svg)$/));

const illustrations = {};
Object.entries(svgs).forEach(([key, value]) => {
  let name = key
    .split("-")
    .map((v) => v.charAt(0).toUpperCase() + v.substring(1))
    .join("");
  name = name.replace(".svg", "");

  illustrations[name] = value;
});

export default {
  name: "Illustrations",
  props: ["name"],
  data() {
    return {
      illustrations: [],
    };
  },
  mounted() {
    this.illustrations = illustrations;
  },
};
</script>
