import {
  addItemssInLocalItemsList,
  removeItemsFromLocalItemsList,
  updateItemsInLocalItemsList,
} from "@/helpers/common";
import { isEmpty } from "lodash";

export default {
  updateResourcesState(context, payload) {
    let currState = context.state;
    currState = Object.assign(currState, payload);
    context.commit("setData", currState);
  },
  setCateTree(context, payload) {
    context.commit("setData", { categoriesTree: payload || {} });
  },
  setResourcesSearchQuery(context, payload) {
    context.commit("setData", { query: payload });
  },
  setSelectedResources(context, payload) {
    context.commit("setData", { selectedResources: payload });
  },
  setResourceTypeOpt(context, payload) {
    context.commit("setData", { selectedResourceTypeOpt: payload });
  },
  setCatTreeAndChildrenMap(context, payload) {
    if (!isEmpty(payload)) {
      const {
        tree: categoriesTree,
        childrenMap: cateChildrenMap,
        resourcesTasksMap,
      } = payload;

      context.commit("setData", {
        categoriesTree,
        cateChildrenMap,
        resourcesTasksMap,
      });
    }
  },
  addOrRemoveOrUpdatesResources(context, payload) {
    if (!isEmpty(payload)) {
      const currState = context.state;
      let currResources = [...currState.processedResources];
      const {
        resourcesToAdd,
        resourcesToUpdate,
        resourceIdsToRemove,
        addMethod,
        verifyAdd,
      } = payload;
      if (resourcesToUpdate && resourcesToUpdate.length) {
        currResources = updateItemsInLocalItemsList(
          resourcesToUpdate,
          currResources
        );
      }

      if (resourceIdsToRemove && resourceIdsToRemove.length) {
        currResources = removeItemsFromLocalItemsList(
          resourceIdsToRemove,
          currResources
        );
      }

      if (resourcesToAdd && resourcesToAdd.length) {
        currResources = addItemssInLocalItemsList(
          resourcesToAdd,
          currResources,
          addMethod,
          verifyAdd
        );
      }

      context.commit("setData", { processedResources: currResources });
    }
  },
};
