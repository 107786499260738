import Vue from "vue";
import DataRow from "../DataRow.vue";
import DataGroup from "../DataGroup.vue";
import classesObj from "../classes";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import DataFrame from "../lib/pandas/pandas";
import filter from "../lib/pandas/filter";
import isEmpty from "lodash/isEmpty";
import isCtrlKey from "@/utils/isCtrlKey";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
import { isGroupRow } from "../helpers/group";
import scrollToEl, { scrollToByPixil } from "@/utils/scrollTo";
import { getPosOfEl } from "@/utils/elements";
import { convertValueToArray } from "@/utils/array";
import { isDeleteKey } from "@/utils/hotKeys";
import { isEmptyRow } from "../helpers/row";
import { isPlainObject } from "lodash";
import { decrement, increment } from "@/utils/number";
import isUndefinedVal from "@/utils/isUndefinedVal";
const arrowKeys = [38, 40];

var touchTimer;
var touchDuration = 500; //length of time we want the user to touch before we do something

export default {
  components: {
    DataRow,
    DataGroup,
  },
  props: {
    placeholderText: {
      type: String,
    },
    itemSize: {
      type: Number,
    },
    list: {
      type: Array,
      required: true,
    },
    manageSelection: {
      type: Boolean,
      default: false,
    },
    selectedRowIds: {
      type: Array,
      default: () => [],
    },
    columnsList: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    keyField: {
      type: String,
      default: "key",
    },
    renderGroupCaption: {
      type: Function,
    },
    allowDragAndDrop: {
      type: Boolean,
    },
    allowManualRefresh: {
      type: Boolean,
    },
    whiteListBtns: {
      type: Array,
    },
    collapsedGroupsList: {
      type: Array,
    },
    rowClassNamesCreator: {
      type: Function,
    },
    checkDeslectOnClick: {
      type: Boolean,
      default: true,
    },
    createUpdatedRowData: {
      type: Function,
    },
    verifyBeforeRemove: {
      type: Function,
    },
    manageRemove: {
      type: Boolean,
      default: true,
    },
    itemRowOffset: {
      type: Number,
    },
    manageList: {
      type: Boolean,
      default: false,
    },
    doNotHandleRowScroll: {
      type: Boolean,
    },
    rowSelectionChecker: {
      type: Function,
    },
    rowDragVerifier: {
      type: Function,
    },
    postGroupProcessor: {
      type: Function,
    },
    postSortProcessor: {
      type: Function,
    },
    verifyColumnSortablity: {
      type: Function,
    },
    checkRowDragEnabled: {
      type: Function,
    },
  },
  data() {
    return {
      dataList: [],
      editingRow: "",
      activeRow: "",
      newRowData: {},
      selectedRowList: [],
      dataMap: {},
      dfList: [],
      finalList: [],
      newRowAdded: false,
      activeCell: null,
      columns: [],
      columnsDefs: [],
      editedRowData: {},
      collapsedGroups: [],
      sortDefs: [],
      sortBy: [],
    };
  },
  provide() {
    return {
      api: this,
      success: this.success,
      cancel: this.cancel,
    };
  },
  mounted() {
    this.init();
    this.sortBy = this.options?.sortBy;
  },
  methods: {
    async addRow(rowDataToAdd = {}, select = true) {
      await this.applyTransaction(
        {
          rowsToAdd: [rowDataToAdd],
        },
        false
      );
      await this.$nextTick();

      if (select) {
        this.selectRow({}, rowDataToAdd);
      }

      await this.$nextTick();
      this.triggerAddRow(rowDataToAdd);
    },
    async removeRows(rowsToRemove) {
      if (!Array.isArray(rowsToRemove)) {
        rowsToRemove = [rowsToRemove];
      }

      const currDataMap = cloneDeep(this.dataMap);

      rowsToRemove.forEach((r) => {
        if (currDataMap[r[this.keyField]]) {
          delete currDataMap[r[this.keyField]];
        }
      });

      this.dataMap = currDataMap;
      await this.$nextTick();
      this.setup();
    },
    createRowClassNames(rowData) {
      let classNames = "";

      if (this.rowClassNamesCreator) {
        classNames = this.rowClassNamesCreator(rowData);
      }

      return classNames;
    },
    setCollapsedGroupList(list) {
      this.collapsedGroups = cloneDeep(list);
    },
    getCollapsedGroupList() {
      return this.collapsedGroups;
    },

    triggerGroupToggledEvent(groupData, mode) {
      this.$emit("group:toggled", groupData, mode);
    },
    addOrRemoveGroupInCollapsedList({ key, value }, mode) {
      let currCollapsedGroups = cloneDeep(this.collapsedGroups);

      if (mode) {
        const storedIndex = currCollapsedGroups.findIndex((g) =>
          isEqual({ key, value }, g)
        );

        if (mode === "remove") {
          if (storedIndex >= 0) {
            currCollapsedGroups.splice(storedIndex, 1);
            this.triggerGroupToggledEvent({ key, value }, "remove");
          }
        }

        if (mode === "add") {
          if (storedIndex === -1) {
            currCollapsedGroups.push({ key, value });
            this.triggerGroupToggledEvent({ key, value }, "add");
          }
        }
      } else {
        if (!isEmpty(currCollapsedGroups)) {
          const storedIndex = currCollapsedGroups.findIndex((g) =>
            isEqual({ key, value }, g)
          );
          if (storedIndex >= 0) {
            currCollapsedGroups.splice(storedIndex, 1);
          } else {
            currCollapsedGroups.push({ key, value });
          }
          this.triggerGroupToggledEvent({ key, value });
        } else {
          currCollapsedGroups = [{ key, value }];

          this.triggerGroupToggledEvent({ key, value });
        }
      }

      return currCollapsedGroups;
    },
    createGroupCollapsedFiltersList(groupList) {
      const groupFilters = [];
      let groupByKeyData = this.getGroupBy();

      if (!isEmpty(groupByKeyData) && isPlainObject(groupByKeyData)) {
        groupByKeyData = groupByKeyData.key;
      }
      groupList.forEach((g) => {
        if (groupByKeyData === g.key && !isUndefinedVal(g.value)) {
          groupFilters.push({
            field: g.key,
            type: "!=",
            value: g.value,
          });
        }
      });

      return groupFilters;
    },
    async toggleGroup({ key: keyData, value }, mode) {
      const finalCollapsedList = this.addOrRemoveGroupInCollapsedList(
        {
          key: isPlainObject(keyData) ? keyData.key : keyData,
          value,
        },
        mode
      );
      const newListDf = this.filterCollapsedGroupsFromList(
        this.rawDf,
        finalCollapsedList
      );

      this.collapsedGroups = finalCollapsedList;
      this.df = newListDf;

      let listToSet = newListDf.toCollection();

      if (this.postGroupProcessor) {
        listToSet = await this.postGroupProcessor(newListDf.toCollection());
      }
      // this.filtered = newListDf;
      this.finalList = listToSet;
    },
    async triggerRowScroll(rowId, select, useIndex, scrollNow, noScroll) {
      const rowsParentEl = this.$el.querySelector(".vue-recycle-scroller");
      if (rowsParentEl) {
        if (!scrollNow) {
          await this.$nextTick();
        }

        const rowIndex = this.df.findIndex({ key: rowId });
        if (!noScroll) {
          if (useIndex) {
            if (rowIndex >= 0) {
              const rowValToUse = this.itemSize;
              const rowPosition = rowIndex * rowValToUse;
              setTimeout(async () => {
                scrollToByPixil(
                  rowsParentEl,
                  rowPosition,
                  this.itemRowOffset ? this.itemRowOffset : 0
                );
                if (select && rowIndex >= 0) {
                  const rowData = this.df.getRow(rowIndex);
                  this.deselectPreviousRows({});
                  this.selectRow({}, rowData);
                }
              }, 0);
            }
          } else {
            setTimeout(() => {
              const rowEl = this.getRowEl(undefined, rowId);
              if (rowEl) {
                const rowPos = getPosOfEl(rowEl);
                if (rowPos.bottom > -1) {
                  setTimeout(() => {
                    scrollToEl({
                      itemEl: rowEl,
                      itemParentEl: rowsParentEl,
                      itemTopOffset: 20,
                    });

                    if (select && rowIndex >= 0) {
                      const rowData = this.df.getRow(rowIndex);
                      this.deselectPreviousRows({});
                      this.selectRow({}, rowData);
                    }
                  }, 0);
                } else if (rowIndex >= 0) {
                  const rowPosition = rowIndex * this.itemSize;
                  setTimeout(() => {
                    scrollToByPixil(rowsParentEl, rowPosition, 20);

                    if (select && rowIndex >= 0) {
                      const rowData = this.df.getRow(rowIndex);

                      this.deselectPreviousRows({});
                      this.selectRow({}, rowData);
                    }
                  }, 0);
                }
              } else {
                if (rowIndex >= 0) {
                  const rowPosition = rowIndex * this.itemSize;
                  setTimeout(() => {
                    scrollToByPixil(rowsParentEl, rowPosition, 20);
                    if (select && rowIndex >= 0) {
                      const rowData = this.df.getRow(rowIndex);
                      this.deselectPreviousRows({});
                      this.selectRow({}, rowData);
                    }
                  }, 0);
                }
              }
            }, 0);
          }
        } else {
          if (rowIndex >= 0) {
            const rowData = this.df.getRow(rowIndex);
            this.deselectPreviousRows({});
            this.selectRow({}, rowData);
          }
        }
      }
    },
    scrollAndSelectRowByIndex({ rowsParentEl, rowPosition, rowIndex, select }) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          await scrollToByPixil(
            rowsParentEl,
            rowPosition,
            this.itemRowOffset ? this.itemRowOffset : 0
          );
          if (select && rowIndex >= 0) {
            const rowData = this.df.getRow(rowIndex);
            this.deselectPreviousRows({});
            this.selectRow({}, rowData);
          }

          resolve();
        }, 250);
      });
    },
    scrollAndSelectRowByEl({ rowId, rowIndex, select, rowsParentEl }) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const rowEl = this.getRowEl(undefined, rowId);
          if (rowEl) {
            const rowPos = getPosOfEl(rowEl);
            if (rowPos.bottom > -1) {
              setTimeout(async () => {
                await scrollToEl({
                  itemEl: rowEl,
                  itemParentEl: rowsParentEl,
                  itemTopOffset: 20,
                });

                if (select && rowIndex >= 0) {
                  const rowData = this.df.getRow(rowIndex);
                  this.deselectPreviousRows({});
                  this.selectRow({}, rowData);
                }
                resolve();
              }, 0);
            } else if (rowIndex >= 0) {
              const rowPosition = rowIndex * this.itemSize;
              await this.scrollAndSelectRowByIndex({
                rowPosition,
                rowsParentEl,
                rowIndex,
                select,
              });
              resolve();
            }
          } else {
            if (rowIndex >= 0) {
              const rowPosition = rowIndex * this.itemSize;
              await this.scrollAndSelectRowByIndex({
                rowPosition,
                rowsParentEl,
                rowIndex,
                select,
              });
            }

            resolve();
          }
        }, 0);
      });
    },
    async scrollToRow(rowId, select, useIndex, noScroll = false, scrollNow) {
      const rowsParentEl = this.$el.querySelector(".vue-recycle-scroller");
      if (rowsParentEl) {
        if (!scrollNow) {
          await this.$nextTick();
        }

        const rowIndex = this.df.findIndex({ key: rowId });
        if (!noScroll) {
          if (useIndex) {
            if (rowIndex >= 0) {
              const rowValToUse = this.itemSize;
              const rowPosition = rowIndex * rowValToUse;
              await this.scrollAndSelectRowByIndex({
                rowPosition,
                rowsParentEl,
                rowIndex,
                select,
              });
            }
          } else {
            await this.scrollAndSelectRowByEl({
              rowId,
              rowsParentEl,
              rowIndex,
              select,
            });
          }
        } else {
          if (rowIndex >= 0) {
            const rowData = this.df.getRow(rowIndex);
            this.deselectPreviousRows({});
            this.selectRow({}, rowData);
          }
        }
      }

      return true;
    },
    checkInView(
      container,
      elPos,
      partial,
      containerOffset = 0,
      containerTopOffset = 0
    ) {
      //Get container properties
      let cTop = container.scrollTop - containerTopOffset;
      let cBottom = cTop + container.clientHeight - containerOffset;

      //Get element properties
      let eTop = elPos.top;
      let eBottom = elPos.bottom;
      //Check if in view

      let isTotal = eTop >= cTop && eBottom <= cBottom;
      let isPartial =
        partial &&
        ((eTop < cTop && eBottom > cTop) ||
          (eBottom > cBottom && eTop < cBottom));

      //Return outcome
      return isTotal || isPartial;
    },
    checkAndScrollToRow(rowId, enableEdit) {
      return new Promise((resolve) => {
        // this.$nextTick(() => {
        (async () => {
          const rowsParentEl = this.$el.querySelector(".vue-recycle-scroller");
          const rowIndex = this.df.findIndex({ key: rowId });
          let rowTop, rowBottom;
          let scrollRow = true;
          if (rowIndex >= 0) {
            rowTop = rowIndex * this.itemSize;
            rowBottom = rowTop + this.itemSize;
            const isInView = this.checkInView(
              rowsParentEl,
              {
                top: rowTop,
                bottom: rowBottom,
              },
              false,
              0,
              0
            );

            const checkRowInView = !this.doNotCheckRowInView;

            if (checkRowInView && isInView) {
              scrollRow = false;
            }

            // console.debug("KKKKKK", isInView);
            if (scrollRow) {
              await this.scrollToRow(rowId, false, true, false, true);
            }

            await this.$nextTick();
            const rowData = this.df.getRow(rowIndex);
            this.endEditing(true);
            await this.$nextTick();
            // resolve();
            setTimeout(() => {
              if (!enableEdit) {
                if (this.isRowIdInSelectedRows(rowData.key)) return;
                this.selectRow({}, rowData);
              } else {
                this.enableEdit({}, rowData);
              }
              resolve();
            }, 300);
          }
        })();
        // });
      });
    },
    async focusOnSelectedRow({ selectedItemKey, select, noScroll, noWait }) {
      if (!noWait) {
        await this.$nextTick();
      }
      if (selectedItemKey) {
        this.scrollToRow(selectedItemKey, select, true, noScroll, noWait);
      }
    },
    checkColumnIsSortable(colDef) {
      let isSortable = true;

      if (this.verifyColumnSortablity) {
        isSortable = this.verifyColumnSortablity(colDef);
      } else {
        isSortable = colDef.sortable !== false;
      }

      return isSortable;
    },

    handleHeaderCellClick(e, colDef) {
      if (!this.checkColumnIsSortable(colDef)) {
        return;
      }

      const currSortList = cloneDeep(this.sortDefs) || [];

      const columnField = colDef.getSortField
        ? colDef.getSortField()
        : colDef.field;
      let sort = currSortList.find((s) => s.column === columnField);
      let order = sort?.order || "";

      if (colDef.tristateSort) {
        if (!order || order === "none") {
          order = colDef.startSortDir;
        } else if (order === colDef.startSortDir) {
          order = order === "asc" ? "desc" : "asc";
        } else {
          order = "none";
        }
      } else {
        if (order === "asc") {
          order = "desc";
        } else {
          order = "asc";
        }
      }

      const colOrderData = {
        column: colDef.getSortField ? colDef.getSortField() : colDef.field,
        missingValue: colDef.missingValue || "last",
        type: colDef.type,
        order,
        defaultValue: colDef.defaultValue,
        transformFunc: colDef.transformFunc,
      };

      if (isCtrlKey(e)) {
        if (!isEmpty(sort)) {
          sort.order = order;
        } else {
          currSortList.unshift(colOrderData);
        }

        this.setSort(currSortList);
      } else {
        this.setSort(
          order === "none" && !isEmpty(this.rawSortDefs)
            ? this.rawSortDefs
            : [colOrderData]
        );
      }
    },
    refreshList() {
      this.setup();
    },
    callDragStart(eventData) {
      this.$emit("row:drag-start", eventData);
    },
    callRowDragging(eventData) {
      this.$emit("row:dragging", eventData);
    },

    callDragStop(eventData) {
      const rowDragInfoRes = this.getRowDragInfo(eventData);
      const targetRow = rowDragInfoRes.rowEl;
      if (targetRow) {
        let rowIndex = targetRow.dataset.rowIndex;
        let rowKey = targetRow.dataset.key;
        const tasks = JSON.parse(eventData.dataTransfer.getData("text"));
        let rowData = {};
        if (rowIndex) {
          rowIndex = parseInt(rowIndex, 10);
        }
        if (rowKey) {
          const rowIndex = this.df.findIndex({
            key: rowKey,
          });

          if (rowIndex >= 0) {
            rowData = this.df.getRow(rowIndex);
          }
        }

        this.$emit("row:reordered", {
          dropTargetRowIndex: rowIndex,
          dropTargetRowData: rowData,
          draggedRows: tasks,
          isLastRow: rowDragInfoRes.isLastRow,
          position: rowDragInfoRes.position,
          event: eventData,
        });
      }
    },
    callDragOver(event) {
      if (this.allowDragAndDrop) {
        const {
          isDragAllowed,
          rowEl: rowEl,
          position,
          isLastRow,
        } = this.getRowDragInfo(event);

        if (isDragAllowed) {
          event.preventDefault();
        }

        if (rowEl && isDragAllowed) {
          const elToUse =
            position === "bottom" && isLastRow
              ? rowEl.nextSibling
              : rowEl.previousSibling;
          const elToSwitch =
            position === "bottom" ? rowEl.previousSibling : rowEl.nextSibling;

          elToSwitch.classList.remove("row-drop-line-visible");
          elToUse.classList.add("row-drop-line-visible");
        }
      }
    },
    getRowElFromDragEvent(evenData) {
      let rowEl;
      const paths = getPathsFromEvent(evenData);
      for (const path of paths) {
        const pathRole = path.getAttribute("role");
        if (pathRole === "row" || pathRole === "empty-row") {
          rowEl = path;
          break;
        }
      }

      return rowEl;
    },
    isLastRowOfGroup(rowEl) {
      let isLastRow = false;
      const rowWrapper = rowEl.closest(".vue-recycle-scroller__item-view");
      const groupRow =
        rowWrapper?.nextSibling?.querySelector("div[role='group']");
      if (rowWrapper && groupRow) {
        isLastRow = true;
      }

      return isLastRow;
    },
    getRowDragInfo(eventData) {
      const rowEl = this.getRowElFromDragEvent(eventData);
      let isDragAllowed = true;
      let position;
      let isLastRow = false;

      if (this.rowDragVerifier && rowEl && rowEl.dataset.key) {
        const rowKey = rowEl.dataset.key;
        const rowIndex = this.df.findIndex({ key: rowKey });

        if (rowIndex >= 0) {
          const rowData = this.df.getRow(rowIndex);
          const midPosOfEl = rowEl.getBoundingClientRect().height / 2;

          if (eventData.offsetY > midPosOfEl) {
            position = "bottom";

            if (this.isLastRowOfGroup(rowEl)) {
              isLastRow = true;
            }
          } else {
            position = "top";
          }
          isDragAllowed = this.rowDragVerifier(rowData);
        } else {
          isDragAllowed = false;
        }
      }
      return {
        isDragAllowed,
        rowEl,
        position,
        isLastRow,
      };
    },
    callDragExit(event) {
      if (this.allowDragAndDrop) {
        const { rowEl: rowEl } = this.getRowDragInfo(event);

        // if (isDragAllowed) {
        //   event.preventDefault();
        // }

        // const paths = getPathsFromEvent(event);
        // let hoveredEl;
        // for (const path of paths) {
        //   const pathRole = path.getAttribute("role");
        //   if (pathRole === "row") {
        //     hoveredEl = path;
        //     break;
        //   }
        // }

        if (rowEl) {
          rowEl.nextSibling.classList.remove("row-drop-line-visible");
          rowEl.previousSibling.classList.remove("row-drop-line-visible");
        }
      }
    },
    updateEditRowData(field, value) {
      this.editedRowData[field] = value;
    },
    getEditRowData() {
      return this.editedRowData;
    },
    isGroup(itemData, itemEl, type) {
      if (itemEl) return itemEl.getAttribute("role") === "group";

      if (itemData) return itemData.group !== undefined;

      if (type) return type === "group";
    },
    isEmptyRow(rowData) {
      return isEmptyRow(rowData);
    },
    createGroupCaption(data, groupBy, value) {
      let caption = "";
      if (this.renderGroupCaption) {
        caption = this.renderGroupCaption(data, groupBy, value);
      }
      return caption;
    },
    cancelAddRow() {
      if (this.newRowAdded) {
        this.removeNewRow();
      }
    },
    isAddModeEnabled() {
      return this.newRowAdded;
    },
    init() {
      let defaultList = [
        "datagrid",
        "datagrid-cell",
        "datagrid-row",
        "v-menu__content",
        "inspect-mode-btn",
        "item-edit-btn",
      ];

      if (!isEmpty(this.whiteListBtns)) {
        defaultList = [...defaultList, ...this.whiteListBtns];
      }

      this.whiteListBtnsList = defaultList;
      this.previousSelectedIndex = new Set();
      this.setGlobalListeners();
      this.columnsDefs = this.columnsList;
      this.setDataAndCreateList(this.list);
    },
    setGlobalListeners() {
      this.$el.addEventListener("keydown", this.handleGlobalKeyDown);
      document.addEventListener("click", this.handleGlobalClick);
    },
    removeGlobalListeners() {
      this.$el.removeEventListener("keydown", this.handleGlobalKeyDown);
      document.removeEventListener("click", this.handleGlobalClick);
    },

    focusRowByArrowKeys(e) {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      if (
        !arrowKeys.includes(keyCode) ||
        isCtrlKey(e) ||
        e.shiftKey ||
        this.isEditingActive()
      ) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();
      let indexToUse = -1;
      const modifier = keyCode === 40 ? 1 : -1;
      const currSelectedRows = this.selectedRows;

      if (currSelectedRows && currSelectedRows.length) {
        const currListCount = this.df.count();
        const lastRowKey =
          currSelectedRows[decrement(currSelectedRows.length, 1)];
        let rowIndex = this.df.findIndex({ key: lastRowKey });
        if (rowIndex > -1) {
          if (rowIndex === decrement(currListCount, 1) && keyCode !== 38) {
            rowIndex = -1;
          }

          if (rowIndex === 0 && keyCode !== 40) {
            rowIndex = currListCount;
          }

          indexToUse = rowIndex + modifier;
        }
      } else {
        indexToUse += modifier;
      }

      if (indexToUse >= 0) {
        const nextValidRowIndex = this.getNextVaildRowIndex(
          indexToUse,
          modifier
        );

        if (nextValidRowIndex >= 0) {
          const rowData = this.df.getRow(nextValidRowIndex);

          if (!isEmpty(rowData) && !this.isRowIdInSelectedRows(rowData.key)) {
            this.selectRow({}, rowData);
          }
        }
      }
    },
    isRowIdInSelectedRows(rowId) {
      return !isEmpty(this.selectedRows) && this.selectedRows.includes(rowId);
    },
    async cancelEditByEsc(e) {
      const keyCode = e.which ? e.which : e.keyCode;

      if (keyCode !== 27) return;

      this.cancelAddRow();
      if (!this.editingRow) {
        this.deselectRows(this.selectedRows, true);
      } else {
        this.cancelRowUpdate = true;
        this.editCanceledByEsc = true;
        this.endEditing(true);
      }
    },
    async removeSelectedRows() {
      const selectedRows = this.getSelectedRows();
      if (isEmpty(selectedRows) || !this.manageRemove) return;

      let removeRows = true;

      if (this.verifyBeforeRemove) {
        removeRows = await this.verifyBeforeRemove(selectedRows);
      }

      if (removeRows) {
        await this.applyTransaction({
          rowsToRemove: [...selectedRows],
        });
        this.$emit("row:removed", [...selectedRows]);
      }
    },
    handleDelKey(e) {
      if (!isDeleteKey(e) || this.isEditingActive()) return;
      if (this.manageList) {
        this.removeSelectedRows();
      } else {
        this.$emit("key-pressed:delete", e);
      }
    },
    handleGlobalKeyDown(e) {
      this.$emit("keydown", e);
      this.handleDelKey(e);
      this.cancelEditByEsc(e);
      this.focusRowByArrowKeys(e);
      if (e.shiftKey) this.shiftSelect(e);
    },
    shiftSelect(e) {
      const code = e ? e.keyCode : e.which;

      if ((code !== 40 && code !== 38) || this.isEditingActive()) return;

      let previousIndex = [...this.previousSelectedIndex].pop();
      const currSelectedRows = [...this.selectedRows];

      const selectedIndexes = currSelectedRows
        .map((rowKey) => {
          const _index = this.df.findIndex({ key: rowKey });
          return parseInt(_index, 10);
        })
        .filter((index) => index >= 0);

      if (selectedIndexes.length === 0) return;
      const min = Math.min(...selectedIndexes);
      const max = Math.max(...selectedIndexes);

      // selected rows downward
      const more = parseInt(previousIndex, 10) === min;
      // selected rows upwards
      const less = parseInt(previousIndex, 10) === max;

      let newIndex = -1;

      const modifier = code === 40 ? 1 : -1;
      if (modifier) {
        // move down
        newIndex = more ? max : min;
      } else {
        // move up
        newIndex = less ? min : max;
      }
      newIndex += modifier;

      if (newIndex >= 0) {
        const validRowIndex = this.getNextVaildRowIndex(newIndex, modifier);
        if (validRowIndex >= 0) this.selectBatchRows(e, validRowIndex, true);
      }
    },
    getRowElByIndex(index) {
      return document.querySelector(`[data-row-index="${index}"`);
    },
    handleGlobalClick(e) {
      const parent = findParentFromPathsByClassName(
        getPathsFromEvent(e),
        this.whiteListBtnsList
      );
      if (this.checkDeslectOnClick && !parent) {
        this.deselectRows(this.selectedRows, true);
      }

      const isEditing = this.editingRow !== "";
      if (isEditing && !parent) {
        this.endEditing();
      }
    },
    onCellRendered(fn, cell) {
      if (!this.activeCell && cell.primary) {
        setTimeout(fn);
      }
      if (this.activeCell && this.activeCell === cell.field) {
        setTimeout(fn);
      }
    },
    handleCellClick(_, colData) {
      this.activeCell = colData.field;
    },
    setup(providedList = [], forceUpdate) {
      const { groupBy, filters } = this.options;
      this._filter(
        filters,
        !isEmpty(providedList) ? providedList : this.getAllData()
      );
      this.rawSortDefs = this.sortBy;
      this.sort(this.sortBy);
      this.group(groupBy, forceUpdate);
    },
    setDataAndCreateList(list) {
      if (!isEmpty(this.collapsedGroupsList)) {
        this.setCollapsedGroupList(this.collapsedGroupsList);
      }
      if (this.manageList) {
        this.createMap(list);
      }
      this.setup(list);
    },
    setDataList(list) {
      this.dataList = cloneDeep(list);
    },
    setData(list) {
      if (this.allowManualRefresh) {
        this.rawList = list;
        this.setDataAndCreateList(list);
      }
    },
    createMap(data) {
      if (data.length) {
        const defs = this.columnsList.map((def) => def.field);
        const columnsSet = new Set([
          ...Object.keys(data[0]),
          ...defs,
          "selected",
          "editing",
          "group",
        ]);
        this.columns = [...columnsSet];
      }
      this.dataMap = (data || []).reduce((a, v) => {
        a[v[this.keyField]] = v;
        return a;
      }, {});
    },

    getAllData(type = "list") {
      if (type === "map") {
        return this.dataMap;
      }

      let listToReturn = [];
      if (this.allowManualRefresh) {
        listToReturn = this.rawList || [];
      } else {
        listToReturn = this.list || [];
        if (this.manageList) {
          listToReturn = Object.values(this.dataMap) || [];
        }
      }
      return listToReturn;
    },
    _filter(filtersList, listToFilter = []) {
      const columns = this.columns || [];

      this.filtered = filter(new DataFrame(listToFilter, columns), filtersList);
      const list = this.filtered.toCollection();
      this.$emit("filter", {
        count: this.filtered.count(),
        list,
      });
    },
    async sort(sortBy) {
      this.sorted = this.filtered;
      if (sortBy) {
        this.sortDefs = sortBy;
        const columns = [];
        const orders = [];
        const missingValues = [];
        const types = [];
        const defaultVals = [];
        const transformers = [];
        sortBy.forEach((sort) => {
          if (sort.order !== "none") {
            columns.push(sort.column);
            orders.push(sort.order === "desc");
            missingValues.push(sort.missingValue || "first");
            types.push(sort.type || "alphanumeric");
            defaultVals.push(sort.defaultValue);
            transformers.push(sort.transformFunc);
          }
        });

        this.sorted = this.sorted.sortBy(
          columns,
          orders,
          transformers,
          defaultVals,
          "last"
        );
      }
    },
    setSort(sortBy) {
      this.sortBy = sortBy;
      this.sort(sortBy);
      this.group(this.options.groupBy);
    },

    filterCollapsedGroupsFromList(listToFilter, collapsedGroupsList) {
      const createdGroupFilters =
        this.createGroupCollapsedFiltersList(collapsedGroupsList);
      if (!isEmpty(createdGroupFilters)) {
        return filter(listToFilter, createdGroupFilters);
      }

      return listToFilter;
    },
    async group(groupBy, forceUpdate) {
      let sortedList = this.sorted;
      let createdGroupData = {};

      if (this.postSortProcessor) {
        const processedList = await this.postSortProcessor(
          sortedList.toCollection()
        );
        const columns = this.columns || [];
        sortedList = new DataFrame(processedList, columns);
      }

      if (!isEmpty(groupBy)) {
        const groupedInstance = sortedList.groupBy(groupBy).sort();
        createdGroupData = groupedInstance.toMap();
        const groupedData = groupedInstance.toList();
        sortedList = new DataFrame(groupedData, [...this.columns]);
      }
      if (this.postGroupProcessor) {
        sortedList = await this.postGroupProcessor(sortedList.toCollection());

        sortedList = new DataFrame(sortedList, [...this.columns]);
      }

      this.rawDf = sortedList;

      if (!isEmpty(this.collapsedGroups)) {
        sortedList = this.filterCollapsedGroupsFromList(
          this.rawDf,
          this.collapsedGroups
        );
      }

      this.df = sortedList;
      let list = sortedList.toCollection();

      this.$emit("list:post-grouping", list);
      if (!isEmpty(createdGroupData)) {
        this.groupedDataMap = createdGroupData;
      }

      await Vue.nextTick();

      if ((!forceUpdate && !isEqual(this.finalList, list)) || forceUpdate) {
        this.finalList = list;
      }

      const dataRowsOnly = this.rawDf
        .filter((row) => !isGroupRow(row) && !isEmptyRow(row))
        .toCollection();
      await this.$nextTick();

      this.$emit("list:processed", dataRowsOnly);
      if (!this.doNotHandleRowScroll) {
        this.scrollToEditedOrSelectedRow();
      }
    },
    setRowIdsForScroll(rowIds) {
      rowIds = convertValueToArray(rowIds);
      this.rowIdsForScroll = rowIds;
    },
    scrollToEditedOrSelectedRow() {
      return new Promise((resolve) => {
        (async () => {
          let rowKeyToUse;
          if (!isEmpty(this.rowIdsForScroll)) {
            rowKeyToUse = this.rowIdsForScroll[0];
            this.rowIdsForScroll = undefined;
          }
          if (!rowKeyToUse && !isEmpty(this.currEditedRowId)) {
            rowKeyToUse = this.currEditedRowId;
          }

          if (
            !rowKeyToUse &&
            !isEmpty(this.selectedRows) &&
            this.selectedRows.length === 1
          ) {
            rowKeyToUse = this.selectedRows[0];
          }

          if (rowKeyToUse) {
            const storedRow = this.finalList.find((t) => t.key === rowKeyToUse);
            if (!isEmpty(storedRow)) {
              const currGroupMode = this.getGroupBy();

              const currGroupValue = storedRow[currGroupMode];
              this.toggleGroup(
                {
                  key: currGroupMode,
                  value: currGroupValue,
                },
                "remove"
              );

              await this.$nextTick();
              setTimeout(async () => {
                await this.checkAndScrollToRow(storedRow.key);
                this.$emit("row:focused", rowKeyToUse);

                resolve();
              }, 250);
            }
          }
        })();
      });
    },
    getRowIndex(rowEl) {
      return rowEl?.dataset?.rowIndex || -1;
    },
    // updateDataMap(index, key, rowData) {
    //   if (index >= 0) {
    //     const rowData = this.df.getRow(index);
    //     const key = rowData[this.keyField];
    //     if (this.dataMap[key]) {
    //       this.dataMap[key] = rowData;
    //     }
    //     return;
    //   } else if (key && this.dataMap[key]) {
    //     this.dataMap[key] = rowData;
    //   }
    // },
    async setDataMap(dataMapToSet = {}, triggerRefresh = true) {
      this.dataMap = cloneDeep(dataMapToSet);
      await this.$nextTick();
      if (triggerRefresh) this.setup();
    },

    async applyTransaction(
      { rowsToUpdate = [], rowsToRemove = [], rowsToAdd = [] } = {},
      { addMethod = "unshift", verifyAdd = true } = {},
      refreshList = true
    ) {
      rowsToAdd = convertValueToArray(rowsToAdd);
      rowsToUpdate = convertValueToArray(rowsToUpdate);
      rowsToRemove = convertValueToArray(rowsToRemove);

      let currDataMap = cloneDeep(this.dataMap);

      if (!isEmpty(rowsToRemove)) {
        currDataMap = rowsToRemove.reduce((acc, r) => {
          if (acc[r[this.keyField]]) {
            delete acc[r[this.keyField]];
          }
          return acc;
        }, currDataMap);
      }
      if (!isEmpty(rowsToUpdate)) {
        currDataMap = rowsToUpdate.reduce((acc, r) => {
          if (acc[r[this.keyField]]) {
            acc[r[this.keyField]] = {
              ...acc[r[this.keyField]],
              ...r.updates,
            };
          }

          return acc;
        }, currDataMap);
      }

      if (!isEmpty(rowsToAdd)) {
        let createdMap = {};
        if (verifyAdd) {
          createdMap = rowsToAdd.reduce((acc, r) => {
            if (!acc[r[this.keyField]]) acc[r[this.keyField]] = r;
            return acc;
          }, {});
        } else {
          createdMap = rowsToAdd.reduce((acc, r) => {
            acc[r[this.keyField]] = r;
            return acc;
          }, {});
        }

        if (addMethod === "unshift") {
          currDataMap = { ...createdMap, ...currDataMap };
        } else if (addMethod === "push") {
          currDataMap = {
            ...currDataMap,
            ...createdMap,
          };
        }
      }

      this.dataMap = currDataMap;

      await this.$nextTick();

      if (refreshList) {
        this.setup();
      }
    },

    updateSortIcon() {
      if (!document.querySelector("." + this.classes.header)) return;

      const sorted = document.querySelectorAll(".e-sortfilterdiv");

      // clear sorting
      if (sorted)
        [...sorted].forEach((el) => (el.className = "e-sortfilterdiv"));

      this.sortBy.forEach((sort) => {
        const column = this.getColumn(null, { field: sort.column });
        this.updateColumnSortIcon(column, sort);
      });
    },

    updateColumnSortIcon(column, sort) {
      if (column && column.querySelector(".e-sortfilterdiv")) {
        column.querySelector(".e-sortfilterdiv").className =
          this.getSortOrderClass(sort.order);
      }
    },

    getRowEl(rowData, key) {
      let querySelector = "." + this.classes.header;
      if (rowData?.addNew) querySelector = "." + this.classes.addNew;
      else if (rowData)
        querySelector = `[data-key="${this.getRowKey(rowData)}"`;
      else if (key) querySelector = `[data-key="${key}"`;

      return document.querySelector(querySelector);
    },
    getRow(rowKey) {
      const rowEl = this.getRowEl(null, rowKey);
      const index = this.getRowIndex(rowEl);
      return {
        getData: () => this.getData(index),
        getIndex: () => rowKey,
        update: (data) => {
          // update silent if editing
          this.updateRows({ key: rowKey, updates: data });
        },
      };
    },
    emitUpdateRowEvent(data) {
      this.$emit("row:updated", { ...data });
    },
    async endEditing(doNotCallRowUpdate) {
      const editRowId = this.editingRow;
      let editedRowData = { ...this.editedRowData };
      this.editingRow = "";
      this.editedRowData = {};
      this.activeCell = "";
      if (!doNotCallRowUpdate) {
        this.editCanceledByEsc = false;
        this.cancelRowUpdate = false;

        if (this.manageList) {
          if (editRowId && this.dataMap[editRowId]) {
            let refreshGrid = true;
            let finalRowData = {},
              updatedRowData = {};
            const currRowData = cloneDeep(this.dataMap[editRowId]);
            if (this.createUpdatedRowData) {
              ({ refreshAllowed: refreshGrid, data: updatedRowData } =
                await this.createUpdatedRowData(editRowId, editedRowData));

              finalRowData = {
                ...this.dataMap[editRowId],
                ...updatedRowData,
              };
            } else {
              finalRowData = {
                ...this.dataMap[editRowId],
                ...editedRowData,
              };
            }

            this.dataMap[editRowId] = {
              ...finalRowData,
            };
            await this.$nextTick();
            if (refreshGrid) {
              this.setup();
            }
            await this.$nextTick();

            this.emitUpdateRowEvent({
              rowId: editRowId,
              currRowData,
              updatedRowData,
              isGridRefreshed: refreshGrid,
              grid: this,
            });
          }
        } else {
          this.emitUpdateRowEvent({
            rowId: editRowId,
            updatedRowData: editedRowData,
            isGridRefreshed: false,
            grid: this,
          });
        }
      }
    },
    generateColStyles(colData) {
      const def = colData;
      const style = {};
      if (def.width && !def.getWidth) {
        if (def.width === "stretch") {
          style.width = "100%";
        } else if ("%".includes(def.width)) {
          style.width = def.width;
          style.flexShrink = "1";
          // className += ` flex-shrink-1`;
        } else {
          style.width = def.width + "px";
          style.flexShrink = "0";
          // className += ` flex-shrink-0`;
        }
      }

      if (def.getWidth) {
        style.width = `${def.getWidth()}px`;
        style.flexShrink = "0";
      }

      if (def.maxWidth) {
        style.maxWidth = def.maxWidth + "px";
      }

      if (def.minWidth) {
        style.minWidth = def.minWidth + "px";
      }
      return style;
    },
    generateColClasses(col, type) {
      const def = col;
      let className = "";

      if (type === "header") {
        className = this.classes.headerCell;
        // cellClass = this.classes.headerCell;
        if (def.sortable !== false) {
          className += ` sortable-header`;
        }

        if (col.verifySortability?.(col)) {
          className += " header-is-sortable";
        }
      } else if (type === "row") {
        className = this.classes.cell;
        // cellClass = this.classes.cell;

        if (def.expand) {
          className += " expand";
        }
      }

      if (def.field) className += ` ${def.field}-cell`;
      if (def.cssClass) className += ` ${def.cssClass}`;
      return { [className]: true };
    },
    getSortOrderClass(order) {
      if (order === "asc") return this.classes.sortAsc;
      else if (order === "desc") return this.classes.sortDesc;
      return "e-sortfilterdiv";
    },
    generateSortClasses(col) {
      let className = "";
      const currSortDefs = this.sortDefs || [];

      const columnName = col.getSortField ? col.getSortField() : col.field;
      const sortBy = currSortDefs.find((sort) => sort.column === columnName);
      if (sortBy) {
        className = this.getSortOrderClass(sortBy.order);
      } else {
        className = "e-sortfilterdiv";
      }

      return {
        [className]: true,
      };
    },
    checkAndEndEdit(rowData) {
      const isEditingSameRow = this.editingRow === rowData.key;
      // const selectedRows = this.getSelectedRows().length > 1;

      if (isEditingSameRow) {
        return;
      }
      // if another row is being edited

      if (!isEditingSameRow && this.editingRow) {
        this.endEditing();
      }
    },
    handleRowClick(e, rowData) {
      this.$emit("row:clicked", e, rowData);

      if (this.checkIfRowSelectionAllowed(rowData)) {
        this.selectRow(e, rowData);
      }
      this.checkAndEndEdit(rowData);
    },
    handleTouchStart(e, rowData) {
      touchTimer = setTimeout(() => {
        this.selectRow(e, rowData)
        this.$emit("row:touch:long", e, rowData)
      }, touchDuration); 
    },
    clearTouchTimer() {
      //stops short touches from firing the event
      if (touchTimer) clearTimeout(touchTimer); // clearTimeout, not cleartimeout..
    },
    handleTouchMove(){
      this.clearTouchTimer();
    },
    handleTouchEnd(){
      this.clearTouchTimer();
    },
    checkIfRowSelectionAllowed(rowData) {
      let isAllowed = true;

      if (this.rowSelectionChecker) {
        isAllowed = this.rowSelectionChecker(rowData);
      }

      return isAllowed;
    },
    handleRowDblClick(e, rowData) {
      if (this.checkIfRowSelectionAllowed(rowData)) {
        this.editingRow = rowData.key;
        this.currEditedRowId = rowData.key;
      }
    },
    selectBatchRows(e, index, triggerEvent) {
      const previousIndex = [...this.previousSelectedIndex].pop();
      const start = Math.min(index, previousIndex);
      const end = Math.max(index, previousIndex);
      const rows = this.df
        .slice(start, increment(end, 1))
        .toCollection()
        .map((row) => row);
      this.deselectPreviousRows({});
      this.selectRows(rows, triggerEvent);
    },
    selectRowByKey(rowKey, isActive, ignoreCheck) {
      const rowIndex = this.df.findIndex({ key: rowKey });

      if (rowIndex >= 0) {
        const rowData = this.df.getRow(rowIndex);

        if (isActive) {
          this.activeRow = rowData.key;
        }
        if (!ignoreCheck && this.isRowIdInSelectedRows(rowData.key)) return;
        this.deselectPreviousRows({});
        this.selectRow({}, rowData);
      }
    },
    clearActiveRow() {
      this.activeRow = "";
    },
    setActiveRow(rowId) {
      this.activeRow = rowId || "";
    },
    selectRow(e, rowData) {
      // const rowEl = this.getRowEl(null, rowData.key);
      // const _index = this.getRowIndex(rowEl);
      const _index = this.df.findIndex({
        key: rowData.key,
      });
      const isSelected = this.selectedRows.indexOf(rowData.key) >= 0;
      this.currEditedRowId = undefined;
      if (e.shiftKey && this.previousSelectedIndex.size) {
        this.selectBatchRows(e, _index);
      } else {
        this.triggerRowClick(e, rowData);

        this.updatePreviousSelected(e, _index, isSelected);
        /**
         * Select if not selected
         */
        if (!isSelected) {
          this.deselectPreviousRows(e);
          this.selectRows(rowData);
        } else {
          
          if (isCtrlKey(e)) {
            this.deselectRows([rowData.key], true);
            return;
          }
          this.enableEdit(e, rowData);
        }
      }

      this.triggerSelectedRows();
    },
    triggerSelectedRows() {
      this.$nextTick(() => {
        this.$emit("rows:selected", this.getSelectedRows());
      });
    },
    getSelectedRows() {
      let selectedRows = [];

      if (this.selectedRows && this.selectedRows.length) {
        const currList = this.getAllData();
        this.selectedRows.forEach((selectedKey) => {
          const storedTask = currList.find((t) => t.key === selectedKey);
          if (!isEmpty(storedTask)) {
            selectedRows.push(storedTask);
          }
        });
      }
      return selectedRows;
    },
    getSelectedRowKeys() {
      return this.selectedRows;
    },
    triggerRowClick() {},
    async enableEditByRowId(rowId) {
      await this.$nextTick();
      this.enableEdit({}, { key: rowId });
    },
    enableEdit(e, rowData, callBack) {
      if (isCtrlKey(e)) {
        return;
      }
      this.editingRow = rowData.key;
      this.editCanceledByEsc = false;
      this.cancelRowUpdate = false;
      this.currEditedRowId = rowData.key;
      this.$emit("edit:enabled", e, rowData, callBack);
      // this.idState.editing = true;
    },
    onRendered() {},
    success() {},
    cancel() {},
    getNewRowData() {
      return this.newRowData;
    },
    updateNewRowData(field, value) {
      this.newRowData[field] = value;
    },
    removeNewRow() {
      this.newRowAdded = false;
      this.newRowData = {};
      this.activeCell = null;
    },
    createColProps(rowData, col) {
      const CellComponent = {
        getValue: () => {
          return this.getValue(rowData, col);
        },
        getElement: function () {},

        getData: () => rowData,
        ...col,
      };
      if (rowData?.addNew) {
        CellComponent.getData = () => this.getNewRowData();
      }

      return CellComponent;
    },

    deselectPreviousRows(e = {}) {
      if (isCtrlKey(e) || e.shiftKey) return;
      this.deselectRows(this.selectedRows);
    },
    deselectRows(keys, triggerEvent) {
      if (!Array.isArray(keys)) keys = [keys];

      if (this.manageSelection) {
        this.$emit("row:deselect", keys);
      } else {
        const currSelection = [...this.selectedRows];
        keys.forEach((k) => {
          // Add group check code

          const keyIndex = currSelection.indexOf(k);

          if (keyIndex >= 0) {
            currSelection.splice(keyIndex, 1);
          }
        });
        this.selectedRowList = currSelection;
        if (triggerEvent) this.triggerSelectedRows();
      }
    },
    selectRows(rows, triggerEvent) {
      if (!Array.isArray(rows)) rows = [rows];

      if (this.manageSelection) {
        const rowIds = rows.reduce((acc, r) => {
          if (
            !this.isGroup(r) &&
            !isEmpty(r) &&
            this.checkIfRowSelectionAllowed(r)
          ) {
            acc.push(r.key);
          }

          return acc;
        }, []);
        this.$emit("row:select", rowIds);
      } else {
        const currSelectedRows = [...this.selectedRows];

        rows.forEach((row) => {
          if (
            this.isGroup(row) ||
            isEmptyRow(row) ||
            !this.checkIfRowSelectionAllowed(row)
          )
            return;
          const storedKeyIndex = currSelectedRows.indexOf(row.key);

          if (storedKeyIndex === -1) {
            currSelectedRows.push(row.key);
          }
        });
        this.selectedRowList = currSelectedRows;

        if (triggerEvent) {
          this.$nextTick(() => {
            this.triggerSelectedRows();
          });
        }
      }
    },
    updatePreviousSelected(e, index, selected) {
      if (e.shiftKey) return;

      if (!isCtrlKey(e) && !selected) {
        this.previousSelectedIndex = new Set([index]);
      } else if (isCtrlKey(e) && !selected) {
        this.previousSelectedIndex.add(index);
      } else if (!isCtrlKey(e) && selected) {
        this.previousSelectedIndex.delete(index);
      } else {
        this.previousSelectedIndex = new Set();
      }
    },
    addNewRow(data = {}) {
      if (!this.newRowAdded) {
        this.newRowData = { ...data, addNew: true };
        this.newRowAdded = true;
      }
    },
    triggerAddRow(data = {}) {
      this.removeNewRow();
      this.$emit("row:added", data);
    },
    showColumn(fields) {
      if (!Array.isArray(fields)) fields = [fields];
      const currColDefs = [...this.columnsDefs];
      fields.forEach((field) => {
        const _index = currColDefs.findIndex((def) => def.field === field);
        if (_index > -1) {
          const colDef = currColDefs[_index];
          colDef.visible = true;
          const newColDef = {
            ...colDef,
            prev: currColDefs[decrement(_index, 1)]?.field,
            next: currColDefs[increment(_index, 1)]?.field,
          };
          currColDefs[_index] = newColDef;
        }
      });

      this.columnsDefs = currColDefs;
    },

    hideColumn(fields) {
      if (!Array.isArray(fields)) fields = [fields];
      const currColDefs = [...this.columnsDefs];
      fields.forEach((field) => {
        const _index = currColDefs.findIndex((def) => def.field === field);
        if (_index > -1) {
          const colDef = currColDefs[_index];
          colDef.visible = false;
          const newColDef = {
            ...colDef,
            prev: currColDefs[decrement(_index, 1)]?.field,
            next: currColDefs[increment(_index, 1)]?.field,
          };
          currColDefs[_index] = newColDef;
        }
      });
      this.columnsDefs = currColDefs;
    },
    getGroupedRowsByKey(groupKey) {
      let rows = [];

      if (!isEmpty(this.groupedDataMap)) {
        rows = this.groupedDataMap[groupKey] || [];
      }
      return rows;
    },
    getGroupBy() {
      return this.options.groupBy;
    },
    getNextVaildRowIndex(currIndex, modifier) {
      let foundNextValidRow = false;
      let indexToUse = currIndex;
      let finalIndex = -1;
      const totalItemCount = this.df.count();
      while (!foundNextValidRow) {
        const row = this.df.getRow(indexToUse);
        if (isEmpty(row)) {
          break;
        }
        if (
          this.isGroup(row) ||
          isEmptyRow(row) ||
          !this.checkIfRowSelectionAllowed(row)
        ) {
          indexToUse += modifier;
          if (indexToUse === -1) {
            indexToUse = decrement(totalItemCount, 1);
          } else if (indexToUse > decrement(totalItemCount, 1)) {
            indexToUse = 0;
          }

          continue;
        }

        if (!this.isGroup(row) && !isEmptyRow(row)) {
          foundNextValidRow = true;
          finalIndex = indexToUse;
          break;
        }
      }

      return finalIndex;
    },
    clearCollapsedGroups() {
      this.collapsedGroups = [];
    },
    getCurrEditingRow() {
      return this.editingRow;
    },
    isEditCancelledByEsc() {
      return this.editCanceledByEsc;
    },
    isInEditMode() {
      return this.editingRow !== "";
    },
    isEditingActive() {
      return this.isAddModeEnabled() || this.isInEditMode();
    },
    getCurrActiveCell() {
      return this.activeCell;
    },
    getGroupRow(groupKey) {
      let groupRow = {};

      if (!isEmpty(groupKey)) {
        const rowIndex = this.df.findIndex({
          key: groupKey,
        });

        if (rowIndex >= 0) {
          groupRow = this.df.getRow(rowIndex);
        }
      }
      return groupRow;
    },
    findIndexOfRowByKey(rowKey) {
      return this.df.findIndex({
        key: rowKey,
      });
    },
    getList() {
      return this.finalList;
    },
    disableRowViewCheck() {
      this.doNotCheckRowInView = true;
    },
    checkIfRowDragEnabled(rowData) {
      let isEnabled = true;

      if (this.checkRowDragEnabled) {
        isEnabled = this.checkRowDragEnabled(rowData);
      }

      return isEnabled;
    },
    setSelectedRows(rowsToSet = [], triggerEvent) {
      const normalisedList = convertValueToArray(rowsToSet);

      this.deselectRows(this.selectedRows, triggerEvent);
      this.selectRows(normalisedList, triggerEvent);
    },
  },
  computed: {
    classes() {
      return classesObj;
    },
    selectedRows() {
      return this.manageSelection ? this.selectedRowIds : this.selectedRowList;
    },
    isListEmpty() {
      return isEmpty(this.finalList);
    },
  },
  beforeDestroy() {
    this.removeGlobalListeners();
  },
  watch: {
    options: {
      handler(n, o) {
        if (!isEqual(n?.sortBy, o?.sortBy)) {
          this.sortBy = n?.sortBy || [];
        }
        if (!isEqual(n, o)) {
          this.setup();
        }
      },
      deep: true,
    },
    list: {
      handler(n, o) {
        if (!isEmpty(n) && isEmpty(o)) {
          this.setDataAndCreateList(n);
        }
      },
      deep: true,
    },
    columnsList: {
      handler(n, o) {
        if (!isEmpty(n) && isEmpty(o)) {
          this.columnsDefs = n;
        }
      },
      deep: true,
    },
  },
};
