<template>
  <div ref="menuWrapper" id="menu-wrapper">
    <div ref="activatorBtn" id="activator-btn-wrapper">
      <slot
        name="activator-btn"
        :handleClick="openMenu"
        :selectedValue="value"
      ></slot>
    </div>

    <div class="selector-wrapper">
      <v-select
        :items="opts"
        item-value="key"
        :value="value"
        :menu-props="selectMenuProps"
        @change="handleChange"
        @blur="handleBlur"
        @focus="handleFocus"
        @keydown="handleKeyDown"
        :disabled="disabled"
        hide-details
        ref="select"
      >
        <template v-slot:selection="{ item }">
          <slot name="selection-content" :item="item"> </slot>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <slot name="item-opt" :item="item" :attrs="attrs" :on="on"> </slot>
        </template>
      </v-select>
    </div>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
export default {
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
    },
    menuProps: {
      type: Object,
    },
    opts: {
      type: Array,
    },
    openOnFocus: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    menuClass: {
      type: String,
    },
  },
  methods: {
    async openMenu() {
      setTimeout(() => {
        const inputEl = this.$refs.select.$el.querySelector("input");

        inputEl?.focus();

        setTimeout(() => {
          this.$refs.select.onClick({ target: inputEl });
        }, 0);
      }, 0);
    },

    handleChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    openInput() {
      this.$refs.select.onClick(this.$refs.select);
    },
    blurInput() {
      this.$refs.select.blur();
    },
  },

  computed: {
    selectMenuProps() {
      let props = {
        offsetY: true,
        minWidth: "165px",
        // maxHeight: "unset !important",
        contentClass: "item-selector-menu",
        offsetOverflow: true,
        contentProps: {
          "data-trest": "true",
        },
      };

      if (!isEmpty(this.menuProps)) {
        props = {
          ...props,
          ...this.menuProps,
        };
      }
      if (this.menuClass) {
        let classToUse = this.menuClass;

        if (props.contentClass) {
          classToUse = `${props.contentClass} ${classToUse}`;
        }

        props.contentClass = classToUse;
      }

      return props;
    },
  },
};
</script>
<style scoped>
#menu-wrapper {
  position: relative;
}

#activator-btn-wrapper {
  height: auto;
  width: auto;
  position: relative;
  z-index: 99;
}

.selector-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
</style>
