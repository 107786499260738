export default {
  "activeNavView": "resources",
  "categories": {
    "1ab58335-8bb0-4b1c-8dc3-545f1d0eaaa9": {
      "children": [
        "b7ad912b-e50e-4b2d-86bd-5182eafa9a58",
        "8466dbbb-881e-42d5-9e9c-acfbcfdfdb8a",
        "ba55df3c-08e8-46b5-9b21-d29ff891dfd7",
        "15edd194-3676-4f54-9ca2-6edfd05f3df6"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "1ab58335-8bb0-4b1c-8dc3-545f1d0eaaa9",
      "modified": "2024-07-22T10:34:59-04:00",
      "open": true,
      "order": 5,
      "title": "Finances",
      "type": "category"
    },
    "3922f023-2836-4fc2-828f-0f2c2299ae62": {
      "children": [
        "e739b0ba-772d-4894-a591-d14d5eb2f2ec",
        "d64cfb5a-8121-4e42-acd7-a9f3e344399a",
        "44ea18a6-7d97-4775-826f-cffcfed3cf1f",
        "b1019419-1a7d-466d-b160-e5e664925ec2",
        "9610471f-8082-488b-b472-aca4c894d877",
        "1ba8cbd9-56c9-494f-baf5-038592f3cc6c",
        "ad0d1ec4-90fd-4723-a0ff-9b014f75aeaa"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "modified": "2024-07-22T10:34:54-04:00",
      "open": true,
      "order": 4,
      "title": "Fixing",
      "type": "category"
    },
    "3f53ff19-0ad1-4b0d-8aa3-ca4e669a40c0": {
      "children": [
        "90ab8c4b-6684-4741-b3e1-6796f85956e1"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "3f53ff19-0ad1-4b0d-8aa3-ca4e669a40c0",
      "modified": "2024-07-22T10:35:10-04:00",
      "open": true,
      "order": 7,
      "title": "Learning",
      "type": "category"
    },
    "4da220eb-2971-405a-bf11-63742543e4c7": {
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "4da220eb-2971-405a-bf11-63742543e4c7",
      "modified": "2024-08-06T13:54:50-04:00",
      "open": false,
      "order": 0,
      "title": "Work",
      "type": "category"
    },
    "520624be-5124-453e-b55c-81b0bdbbbaee": {
      "children": [
        "50329913-333a-49e2-bd61-b7b0c79617ed",
        "fcd697a9-fd76-43cb-b0b4-3a520a2bb55e",
        "f0a9bc8e-2892-4f1a-9cce-4aee4ae571a0",
        "621a2170-6de5-49d7-9bfd-66407edb5f39",
        "ab60c79a-78ac-4415-89c4-94a23701565f",
        "788a3fd9-4b44-46ee-929a-37f36d67d799",
        "8f454bb2-2cbf-4a83-b606-795ff5caf537"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "modified": "2024-07-22T10:34:36-04:00",
      "open": true,
      "order": 1,
      "title": "Business",
      "type": "category"
    },
    "5bd8df90-1972-45be-9424-c164bb028d6a": {
      "children": [
        "88e53460-ca68-421b-b730-14972e1b340f",
        "422423cf-8af4-4596-a1d8-88a81299c264"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "5bd8df90-1972-45be-9424-c164bb028d6a",
      "modified": "2024-07-22T10:35:05-04:00",
      "open": true,
      "order": 6,
      "title": "Consuming",
      "type": "category"
    },
    "6fc12101-4071-4f6c-8978-f9929dad0d17": {
      "children": [
        "8159cda7-1829-40a1-ac6e-b6c6c3a0bca3",
        "4fdb79aa-0039-4ada-a8ab-4e423c7fee78",
        "506632e5-f417-4b61-84bf-f6f40d78cf24",
        "0f81565e-d301-43b2-b029-57c47b1e35ce"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "6fc12101-4071-4f6c-8978-f9929dad0d17",
      "modified": "2024-07-22T10:34:43-04:00",
      "open": true,
      "order": 2,
      "title": "Health",
      "type": "category"
    },
    "8439074b-93af-42e8-a8be-013ac7ca602e": {
      "children": [
        "aca14ab9-8380-4b1a-a087-d9eaefdc4227",
        "e38c8e32-ee7e-4f83-9ba7-9ca2ce66e50a",
        "aeaed343-d34f-4508-ada2-e35cd88dacd5",
        "df8c281a-56a6-4b9a-b7ee-035990732de1",
        "d6d26bcb-4198-430b-82d3-8fdb878b12dd"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "8439074b-93af-42e8-a8be-013ac7ca602e",
      "modified": "2024-07-22T10:34:48-04:00",
      "open": true,
      "order": 3,
      "title": "Relations",
      "type": "category"
    },
    "a18129a2-f189-468b-af80-177bc7791540": {
      "children": [
        "34a09089-5e8e-411b-9015-efef9834d2b6",
        "2422ec77-743f-4ed9-8930-0b71255dce97",
        "6a995f61-2193-4cb7-8a24-fee997ccb2fa"
      ],
      "deleted": false,
      "description": "New Category Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "a18129a2-f189-468b-af80-177bc7791540",
      "modified": "2024-07-22T10:35:15-04:00",
      "open": true,
      "order": 8,
      "title": "Fun",
      "type": "category"
    }
  },
  "globalView": false,
  "inboxView": false,
  "inspectModeEnabled": false,
  "isProjectsResourceModeEnabled": false,
  "lastViewChange": "",
  "nav": 4,
  "notesView": false,
  "projects": {
    "0f81565e-d301-43b2-b029-57c47b1e35ce": {
      "category": "6fc12101-4071-4f6c-8978-f9929dad0d17",
      "completed": false,
      "created": "2024-07-22T10:35:58-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "0f81565e-d301-43b2-b029-57c47b1e35ce",
      "modified": "2024-07-22T10:35:58-04:00",
      "order": 3,
      "priority": "",
      "subcategory": false,
      "title": "Vision",
      "type": "project"
    },
    "15edd194-3676-4f54-9ca2-6edfd05f3df6": {
      "category": "1ab58335-8bb0-4b1c-8dc3-545f1d0eaaa9",
      "completed": false,
      "created": "2024-07-22T10:39:12-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "15edd194-3676-4f54-9ca2-6edfd05f3df6",
      "modified": "2024-07-22T10:39:12-04:00",
      "order": 3,
      "priority": "",
      "subcategory": false,
      "title": "Sell, Return, Warranty",
      "type": "project"
    },
    "1ba8cbd9-56c9-494f-baf5-038592f3cc6c": {
      "category": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "completed": false,
      "created": "2024-07-22T10:38:31-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "1ba8cbd9-56c9-494f-baf5-038592f3cc6c",
      "modified": "2024-07-22T10:38:31-04:00",
      "order": 5,
      "priority": "",
      "subcategory": false,
      "title": "Software",
      "type": "project"
    },
    "2422ec77-743f-4ed9-8930-0b71255dce97": {
      "category": "a18129a2-f189-468b-af80-177bc7791540",
      "completed": false,
      "created": "2024-07-22T10:40:25-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "2422ec77-743f-4ed9-8930-0b71255dce97",
      "modified": "2024-07-22T10:40:25-04:00",
      "order": 1,
      "priority": "",
      "subcategory": false,
      "title": "News, Entertain & Literature",
      "type": "project"
    },
    "34a09089-5e8e-411b-9015-efef9834d2b6": {
      "category": "a18129a2-f189-468b-af80-177bc7791540",
      "completed": false,
      "created": "2024-07-22T10:40:15-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "34a09089-5e8e-411b-9015-efef9834d2b6",
      "modified": "2024-07-22T10:40:15-04:00",
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Holidays & Birthdays",
      "type": "project"
    },
    "422423cf-8af4-4596-a1d8-88a81299c264": {
      "category": "5bd8df90-1972-45be-9424-c164bb028d6a",
      "completed": false,
      "created": "2024-07-22T10:39:38-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "422423cf-8af4-4596-a1d8-88a81299c264",
      "modified": "2024-07-22T10:39:38-04:00",
      "order": 1,
      "priority": "",
      "subcategory": false,
      "title": "Shop Online",
      "type": "project"
    },
    "44ea18a6-7d97-4775-826f-cffcfed3cf1f": {
      "category": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "completed": false,
      "created": "2024-07-22T10:37:48-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "44ea18a6-7d97-4775-826f-cffcfed3cf1f",
      "modified": "2024-07-22T10:37:48-04:00",
      "order": 2,
      "priority": "",
      "subcategory": false,
      "title": "Home & Fixtures",
      "type": "project"
    },
    "4fdb79aa-0039-4ada-a8ab-4e423c7fee78": {
      "category": "6fc12101-4071-4f6c-8978-f9929dad0d17",
      "completed": false,
      "created": "2024-07-22T10:36:05-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "4fdb79aa-0039-4ada-a8ab-4e423c7fee78",
      "modified": "2024-07-22T10:36:05-04:00",
      "order": 1,
      "priority": "",
      "subcategory": false,
      "title": "Primary Care",
      "type": "project"
    },
    "50329913-333a-49e2-bd61-b7b0c79617ed": {
      "category": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "completed": false,
      "created": "2024-07-22T11:02:01-04:00",
      "deleted": false,
      "description": "New Sub-Category",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "50329913-333a-49e2-bd61-b7b0c79617ed",
      "modified": "2024-07-22T11:02:01-04:00",
      "open": false,
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Admin-Planning",
      "type": "subcategory"
    },
    "506632e5-f417-4b61-84bf-f6f40d78cf24": {
      "category": "6fc12101-4071-4f6c-8978-f9929dad0d17",
      "completed": false,
      "created": "2024-07-22T10:35:52-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "506632e5-f417-4b61-84bf-f6f40d78cf24",
      "modified": "2024-07-22T10:35:52-04:00",
      "order": 2,
      "priority": "",
      "subcategory": false,
      "title": "Dental",
      "type": "project"
    },
    "621a2170-6de5-49d7-9bfd-66407edb5f39": {
      "category": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "completed": false,
      "created": "2024-07-22T11:03:06-04:00",
      "deleted": false,
      "description": "New Sub-Category",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "621a2170-6de5-49d7-9bfd-66407edb5f39",
      "modified": "2024-07-22T11:03:06-04:00",
      "open": false,
      "order": 3,
      "priority": "",
      "subcategory": false,
      "title": "Legal",
      "type": "subcategory"
    },
    "6a995f61-2193-4cb7-8a24-fee997ccb2fa": {
      "category": "a18129a2-f189-468b-af80-177bc7791540",
      "completed": false,
      "created": "2024-07-22T10:40:37-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "6a995f61-2193-4cb7-8a24-fee997ccb2fa",
      "modified": "2024-07-22T10:40:37-04:00",
      "order": 2,
      "priority": "",
      "subcategory": false,
      "title": "Travel & Experiences",
      "type": "project"
    },
    "788a3fd9-4b44-46ee-929a-37f36d67d799": {
      "category": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "completed": false,
      "created": "2024-07-22T11:02:09-04:00",
      "deleted": false,
      "description": "New Sub-Category",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "788a3fd9-4b44-46ee-929a-37f36d67d799",
      "modified": "2024-07-22T11:02:09-04:00",
      "open": false,
      "order": 5,
      "priority": "",
      "subcategory": false,
      "title": "Networking",
      "type": "subcategory"
    },
    "8159cda7-1829-40a1-ac6e-b6c6c3a0bca3": {
      "category": "6fc12101-4071-4f6c-8978-f9929dad0d17",
      "completed": false,
      "created": "2024-07-22T10:42:05-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "8159cda7-1829-40a1-ac6e-b6c6c3a0bca3",
      "modified": "2024-07-22T10:42:05-04:00",
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Health ADMIN",
      "type": "project"
    },
    "8466dbbb-881e-42d5-9e9c-acfbcfdfdb8a": {
      "category": "1ab58335-8bb0-4b1c-8dc3-545f1d0eaaa9",
      "completed": false,
      "created": "2024-07-22T10:38:59-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "8466dbbb-881e-42d5-9e9c-acfbcfdfdb8a",
      "modified": "2024-07-22T10:38:59-04:00",
      "order": 1,
      "priority": "",
      "subcategory": false,
      "title": "Expenses",
      "type": "project"
    },
    "88e53460-ca68-421b-b730-14972e1b340f": {
      "category": "5bd8df90-1972-45be-9424-c164bb028d6a",
      "completed": false,
      "created": "2024-07-22T10:39:30-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "88e53460-ca68-421b-b730-14972e1b340f",
      "modified": "2024-07-22T10:39:30-04:00",
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Shop Local",
      "type": "project"
    },
    "8f454bb2-2cbf-4a83-b606-795ff5caf537": {
      "category": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "completed": false,
      "created": "2024-07-22T11:03:15-04:00",
      "deleted": false,
      "description": "New Sub-Category",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "8f454bb2-2cbf-4a83-b606-795ff5caf537",
      "modified": "2024-07-22T11:03:15-04:00",
      "open": false,
      "order": 6,
      "priority": "",
      "subcategory": false,
      "title": "Training",
      "type": "subcategory"
    },
    "90ab8c4b-6684-4741-b3e1-6796f85956e1": {
      "category": "3f53ff19-0ad1-4b0d-8aa3-ca4e669a40c0",
      "completed": false,
      "created": "2024-07-22T10:40:05-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "90ab8c4b-6684-4741-b3e1-6796f85956e1",
      "modified": "2024-07-22T10:40:05-04:00",
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Classes",
      "type": "project"
    },
    "9610471f-8082-488b-b472-aca4c894d877": {
      "category": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "completed": false,
      "created": "2024-07-22T10:38:10-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "9610471f-8082-488b-b472-aca4c894d877",
      "modified": "2024-07-22T10:38:10-04:00",
      "order": 4,
      "priority": "",
      "subcategory": false,
      "title": "Pet Care",
      "type": "project"
    },
    "ab60c79a-78ac-4415-89c4-94a23701565f": {
      "category": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "completed": false,
      "created": "2024-07-22T11:03:00-04:00",
      "deleted": false,
      "description": "New Sub-Category",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "ab60c79a-78ac-4415-89c4-94a23701565f",
      "modified": "2024-07-22T11:03:00-04:00",
      "open": false,
      "order": 4,
      "priority": "",
      "subcategory": false,
      "title": "Marketing",
      "type": "subcategory"
    },
    "aca14ab9-8380-4b1a-a087-d9eaefdc4227": {
      "category": "8439074b-93af-42e8-a8be-013ac7ca602e",
      "completed": false,
      "created": "2024-07-22T10:36:21-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "aca14ab9-8380-4b1a-a087-d9eaefdc4227",
      "modified": "2024-07-22T10:36:21-04:00",
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Family & Life",
      "type": "project"
    },
    "ad0d1ec4-90fd-4723-a0ff-9b014f75aeaa": {
      "category": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "completed": false,
      "created": "2024-07-22T10:38:37-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "ad0d1ec4-90fd-4723-a0ff-9b014f75aeaa",
      "modified": "2024-07-22T10:38:37-04:00",
      "order": 6,
      "priority": "",
      "subcategory": false,
      "title": "Transportation",
      "type": "project"
    },
    "aeaed343-d34f-4508-ada2-e35cd88dacd5": {
      "category": "8439074b-93af-42e8-a8be-013ac7ca602e",
      "completed": false,
      "created": "2024-07-22T10:36:41-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "aeaed343-d34f-4508-ada2-e35cd88dacd5",
      "modified": "2024-07-22T10:36:41-04:00",
      "order": 2,
      "priority": "",
      "subcategory": false,
      "title": "Groups",
      "type": "project"
    },
    "b1019419-1a7d-466d-b160-e5e664925ec2": {
      "category": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "completed": false,
      "created": "2024-07-22T10:37:59-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "b1019419-1a7d-466d-b160-e5e664925ec2",
      "modified": "2024-07-22T10:37:59-04:00",
      "order": 3,
      "priority": "",
      "subcategory": false,
      "title": "Information Management",
      "type": "project"
    },
    "b7ad912b-e50e-4b2d-86bd-5182eafa9a58": {
      "category": "1ab58335-8bb0-4b1c-8dc3-545f1d0eaaa9",
      "completed": false,
      "created": "2024-07-22T10:38:51-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "b7ad912b-e50e-4b2d-86bd-5182eafa9a58",
      "modified": "2024-07-22T10:38:51-04:00",
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Credit",
      "type": "project"
    },
    "ba55df3c-08e8-46b5-9b21-d29ff891dfd7": {
      "category": "1ab58335-8bb0-4b1c-8dc3-545f1d0eaaa9",
      "completed": false,
      "created": "2024-07-22T10:39:04-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "ba55df3c-08e8-46b5-9b21-d29ff891dfd7",
      "modified": "2024-07-22T10:39:04-04:00",
      "order": 2,
      "priority": "",
      "subcategory": false,
      "title": "Income",
      "type": "project"
    },
    "d64cfb5a-8121-4e42-acd7-a9f3e344399a": {
      "category": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "completed": false,
      "created": "2024-07-22T10:37:40-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "d64cfb5a-8121-4e42-acd7-a9f3e344399a",
      "modified": "2024-07-22T10:37:40-04:00",
      "order": 1,
      "priority": "",
      "subcategory": false,
      "title": "Hardware",
      "type": "project"
    },
    "d6d26bcb-4198-430b-82d3-8fdb878b12dd": {
      "category": "8439074b-93af-42e8-a8be-013ac7ca602e",
      "completed": false,
      "created": "2024-07-22T10:41:35-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "d6d26bcb-4198-430b-82d3-8fdb878b12dd",
      "modified": "2024-07-22T10:41:35-04:00",
      "order": 4,
      "priority": "",
      "subcategory": false,
      "title": "Professional",
      "type": "project"
    },
    "df8c281a-56a6-4b9a-b7ee-035990732de1": {
      "category": "8439074b-93af-42e8-a8be-013ac7ca602e",
      "completed": false,
      "created": "2024-07-22T10:36:46-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "df8c281a-56a6-4b9a-b7ee-035990732de1",
      "modified": "2024-07-22T10:36:46-04:00",
      "order": 3,
      "priority": "",
      "subcategory": false,
      "title": "Community",
      "type": "project"
    },
    "e38c8e32-ee7e-4f83-9ba7-9ca2ce66e50a": {
      "category": "8439074b-93af-42e8-a8be-013ac7ca602e",
      "completed": false,
      "created": "2024-07-22T10:36:35-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "e38c8e32-ee7e-4f83-9ba7-9ca2ce66e50a",
      "modified": "2024-07-22T10:36:35-04:00",
      "order": 1,
      "priority": "",
      "subcategory": false,
      "title": "Friends",
      "type": "project"
    },
    "e739b0ba-772d-4894-a591-d14d5eb2f2ec": {
      "category": "3922f023-2836-4fc2-828f-0f2c2299ae62",
      "completed": false,
      "created": "2024-07-22T10:37:25-04:00",
      "deleted": false,
      "description": "New Area Description",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "e739b0ba-772d-4894-a591-d14d5eb2f2ec",
      "modified": "2024-07-22T10:37:25-04:00",
      "order": 0,
      "priority": "",
      "subcategory": false,
      "title": "Furnishings & Appliances",
      "type": "project"
    },
    "f0a9bc8e-2892-4f1a-9cce-4aee4ae571a0": {
      "category": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "completed": false,
      "created": "2024-07-22T11:02:52-04:00",
      "deleted": false,
      "description": "New Sub-Category",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "f0a9bc8e-2892-4f1a-9cce-4aee4ae571a0",
      "modified": "2024-07-22T11:02:52-04:00",
      "open": false,
      "order": 2,
      "priority": "",
      "subcategory": false,
      "title": "Finances",
      "type": "subcategory"
    },
    "fcd697a9-fd76-43cb-b0b4-3a520a2bb55e": {
      "category": "520624be-5124-453e-b55c-81b0bdbbbaee",
      "completed": false,
      "created": "2024-07-22T11:02:25-04:00",
      "deleted": false,
      "description": "New Sub-Category",
      "hidden": false,
      "icon": "mdi-circle",
      "key": "fcd697a9-fd76-43cb-b0b4-3a520a2bb55e",
      "modified": "2024-07-22T11:02:25-04:00",
      "open": false,
      "order": 1,
      "priority": "",
      "subcategory": false,
      "title": "Development",
      "type": "subcategory"
    }
  },
  "showCompleted": false,
  "taskSettings": {
    "statusBtnPosition": "with-task"
  },
  "taskTypes": {
    "consult": {
      "icon": "$consult",
      "key": "consult",
      "modified": "2021-05-21",
      "title": "Call"
    },
    "goal": {
      "icon": "mdi-calendar-star",
      "key": "goal",
      "modified": "2021-04-30",
      "title": "Goal"
    },
    "journal": {
      "icon": "$journal",
      "key": "journal",
      "modified": "2021-06-01",
      "title": "Journal"
    },
    "message": {
      "icon": "$messages",
      "key": "message",
      "modified": "2021-05-30",
      "title": "Message"
    },
    "post": {
      "icon": "$posts",
      "key": "post",
      "modified": "2021-05-01",
      "title": "Post"
    },
    "task": {
      "icon": "mdi-calendar-check-outline",
      "key": "task",
      "modified": "2021-06-04",
      "title": "Task"
    }
  },
  "view": [
    {
      "groupView": 0,
      "sortMode": 0,
      "statusFilter": "scheduled",
      "type": "calView"
    },
    {
      "groupView": 1,
      "selectedByFilterBar": false,
      "selectedCategory": "all",
      "selectedOptsList": [
        {
          "id": "4da220eb-2971-405a-bf11-63742543e4c7",
          "projectId": "all",
          "subCategoryId": "all",
          "type": "category"
        }
      ],
      "selectedProject": "all",
      "selectedSubcategory": "all",
      "sortMode": 0,
      "statusFilter": "incomplete",
      "type": "treeView"
    },
    {
      "groupView": 0,
      "selectedCategory": "all",
      "selectedProject": "all",
      "sortMode": 6,
      "statusFilter": "incomplete",
      "type": "resourceView"
    },
    {
      "groupView": 0,
      "selectedDates": [
        "2024-08-06"
      ],
      "sortMode": 1,
      "statusFilter": "incomplete"
    },
    {
      "groupView": 1,
      "selectedDateFilter": "",
      "selectedFilter": "",
      "sortMode": 0,
      "statusFilter": "all"
    },
    {
      "groupView": 3,
      "sortMode": 0
    }
  ],
  "tasks": {}
};
