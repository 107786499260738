import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBtnToggle,{staticClass:"date-helpers-btns-wrapper"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"font-weight":"600","font-size":"18px !important"},attrs:{"tabindex":"-1"},on:{"click":_vm.handleSubClick}},'v-btn',Object.assign({}, _vm.btnSizeStylesAttrs, attrs),false),on),[_vm._v(" - ")])]}}])},[_c('span',[_vm._v("Remove Date and Time")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"tabindex":"-1"},on:{"click":_vm.handleAddClick}},'v-btn',Object.assign({}, _vm.btnSizeStylesAttrs, attrs),false),on),[_vm._v(" + ")])]}}])},[_c('span',[_vm._v("Set Current Date and Time")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"tabindex":"-1"},on:{"click":_vm.nextDay}},'v-btn',Object.assign({}, attrs, _vm.btnSizeStylesAttrs),false),on),[_vm._v(" D ")])]}}])},[_c('span',[_vm._v("Push One Day")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"tabindex":"-1"},on:{"click":_vm.nextWeek}},'v-btn',Object.assign({}, attrs, _vm.btnSizeStylesAttrs),false),on),[_vm._v(" W ")])]}}])},[_c('span',[_vm._v("Push One Week")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"tabindex":"-1"},on:{"click":_vm.nextMonth}},'v-btn',Object.assign({}, attrs, _vm.btnSizeStylesAttrs),false),on),[_vm._v(" M ")])]}}])},[_c('span',[_vm._v("Push One Month")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }