import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"item-count-wrapper",on:{"mousedown":_vm.handleMouseDown}},[_c('span',{staticClass:"edit-btn-wrapper"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g({ref:"editBtn",staticClass:"edit-item-btn",attrs:{"icon":"","attrs":attrs,"small":"","tabindex":"-1"},on:{"click":_vm.openContextMenu}},on),[_c(VIcon,{attrs:{"size":"16","color":"black"}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Edit")])])],1),_c('span',{staticClass:"item-count"},[_vm._v(" "+_vm._s(_vm.itemCount)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }