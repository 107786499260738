import createUniqueId from "@/utils/createUniqueId";
import { isEmpty } from "lodash";
import { getCurrDate } from "./dates";
import { OBJECTIVE_EDIT_PROPS } from "@/variables/objectives";

export const createObjectiveOpts = (objectivesMap, projectId) => {
  let opts = [];

  const objList = Object.values(objectivesMap || {});

  if (!isEmpty(objList) && projectId) {
    opts = objList
      .filter((obj) => obj.project === projectId)
      .map((o) => ({
        shortLabel: o.name,
        fullLabel: o.name,
        order: o.order,
        value: o.key,
      }));
  }

  return opts;
};

export const createObjectiveData = (data) => {
  return {
    name: data.name || "",
    order: data.order || 0,
    project: data.project || "",
    key: createUniqueId(),
  };
};

export const createUpdateAndEditedForObjective = (newData, currData) => {
  let updates = {};
  let editedData = {};
  let changedProps = {};
  let dbUpdates = {};
  const keysToUpdate = Object.keys(newData);

  keysToUpdate.forEach((key) => {
    if (OBJECTIVE_EDIT_PROPS.indexOf(key) >= 0) {
      if (key === "order") {
        editedData[key] = currData[key];
        if (typeof newData[key] === "number") {
          updates[key] = newData[key];
          changedProps[key] = newData[key];
          dbUpdates[`/objectives/${currData.key}/${key}`] = newData[key];
        }
      } else {
        updates[key] = newData[key] || "";
        changedProps[key] = newData[key] || "";
        dbUpdates[`/objectives/${currData.key}/${key}`] = newData[key];
        editedData[key] = currData[key] || "";
      }
    }
  });

  dbUpdates[`/objectives/${currData.key}/modified`] = getCurrDate();
  updates.modified = getCurrDate();
  editedData.changedProps = changedProps;
  editedData.key = currData.key;

  return {
    updates,
    editedData,
    dbUpdates,
  };
};

export const checkIfObjectiveDataChanged = (updatedData, currTaskData) => {
  return OBJECTIVE_EDIT_PROPS.some((key) => {
    return updatedData[key] !== currTaskData[key];
  });
};
