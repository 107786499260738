<template>
  <ViewLayout
    :name="modeHeaderLabel"
    :noUser="noUser"
    :loading="loading"
    ref="viewWrapper"
    :class="viewWrapperClass"
    :data-resource="resourceEnabledType"
  >
    <template #header>
      <MultiTasksDataChangeBar ref="multiTasksDateBar" />
    </template>

    <template v-slot:side-nav-view>
      <ResourcesCateTreeView
        v-if="user && isProjectsResourceModeEnabled"
        ref="sideCompWrapper"
        :showTypeSelector="false"
      />
      <CalNav
        v-show="user && showCalView && !isProjectsResourceModeEnabled"
        :navMode="currNav"
        ref="sideCompWrapper"
      />
      <TreeView
        v-if="
          user &&
          isTreeSideBarAvailable &&
          isTasksDataLoaded &&
          !isProjectsResourceModeEnabled
        "
        ref="sideCompWrapper"
      />
    </template>
    <template v-slot:main-view>
      <TasksListView ref="tableWrapper" />
    </template>
  </ViewLayout>
</template>

<script>
import Vue from "vue";
import firebase from "@/services/firebase";
import DatabaseInterface from "@/services/DatabaseInterface";
import CalNav from "@/components/CalNav";
import TreeView from "@/components/TasksCateView/TasksCateView.vue";
import ViewLayout from "@/layouts/ViewLayout.vue";
import TasksListView from "@/components/TasksListView/TasksListView.vue";
import MultiTasksDataChangeBar from "@/components/MultiTasksDataChangeBar";
import { treeSideBarModes } from "../variables/viewConfigs";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { getCurrDate, makeDateFormat } from "../helpers/dates";

import GoogleCalHelpers from "@/helpers/googleCalHelpers";

import isToday from "date-fns/isToday";
import { parseISO } from "date-fns";
import isEqual from "lodash/isEqual";
// import CustomTabulator from "@/components/Tabulator/CustomTabulator.vue";
import { initUserNotes, notesCollection } from "@/helpers/notes";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import googleCalHelpersMixins from "@/mixins/googleCalHelpersMixins";
import patchTasksToAddOrderAndPosition from "@/scripts/patchTasksToAddOrderAndPosition";
import patchTodayModeGroupView from "@/scripts/patchTodayModeGroupView";
import isEmpty from "lodash/isEmpty";
import handleUserActionMixin from "@/mixins/handleUserActionHelperMixin";
import { initUserResources, resourcesCollection } from "@/helpers/resources";
import { getAppColorThemeFromUserData } from "@/helpers/app";
import globalSearchBoxHelperMixin from "@/mixins/globalSearchBoxHelperMixin";
import ResourcesCateTreeView from "@/components/ResourcesCateTreeView";
const acceptedNavViews = [0, 3];
export default {
  name: "App",
  components: {
    TreeView,
    CalNav,
    TasksListView,
    MultiTasksDataChangeBar,
    ViewLayout,
    ResourcesCateTreeView,
  },
  mixins: [
    userDetailsMixin,
    googleCalHelpersMixins,
    handleUserActionMixin,
    globalSearchBoxHelperMixin,
  ],
  data: () => ({
    noUser: true,
    loading: false,
    user: null,
  }),
  methods: {
    removeNotesListener() {
      if (this.notesListener) {
        this.notesListener();
      }
    },
    handleNotesUpdates(doc) {
      const noteData = doc.data();
      const currRawNotes = this.rawNotes;

      const currRawDirectories = this.rawDirectories;
      const notesUpdats = {};

      if (!isEqual(currRawDirectories, noteData.directories)) {
        notesUpdats.directories = noteData.directories;
      }

      if (!isEqual(currRawNotes, noteData.notes)) {
        notesUpdats.rawNotes = noteData.notes;
      }

      this.updateNoteState({
        ...notesUpdats,
      });
    },
    async fillInitUserData(userId) {
      await DatabaseInterface.initUser(userId);
    },
    async setUserNotesListener(userId) {
      const notesRes = await notesCollection.dbStore.doc(userId).get();

      if (notesRes.exists) {
        this.notesListener = notesCollection.dbStore
          .doc(userId)
          .onSnapshot(this.handleNotesUpdates);
      }
    },
    async initGoogleCalSync(
      currTimeStamp,
      updateDb = false,
      passLastConnected,
      syncCurrTasksOnly
    ) {
      let useLastSync = true;
      let useLastConnectedDate = false;
      let syncCurrTasks = false;
      if (isEmpty(this.rawTasks)) {
        useLastSync = false;
        useLastConnectedDate = true;
      }

      if (!useLastConnectedDate && passLastConnected) {
        useLastConnectedDate = true;
        syncCurrTasks = true;
      }

      if (syncCurrTasksOnly) {
        useLastConnectedDate = true;
        syncCurrTasks = true;
        useLastSync = false;
      }

      // syncTimeStamp,
      // useLastSync = true,
      // useLastConnectDate,
      // syncCurrTasks,
      // verifyCalendar = true

      const syncRes = await this.syncCalendar(
        currTimeStamp,
        useLastSync,
        useLastConnectedDate,
        true,
        false
      );

      if (updateDb && syncRes.status === "OK") {
        DatabaseInterface.update(
          {
            "/googleCalSyncInfo/lastSynced": currTimeStamp,
          },
          this.userInfo.uid
        );
      }

      return syncRes;
    },
    async initCalSync(userId, googleCalSyncData, queryData) {
      if (!queryData || !queryData.calType) {
        return;
      }
      let toastMsg = "";
      let updates = {};
      let toastBgColor = "green";
      const { calType, accessToken, refreshToken, errorMsg } = queryData;
      if (calType === "google") {
        if (accessToken) {
          await GoogleCalHelpers.loadCalAuthToken(accessToken, refreshToken);

          let calendarIdToStore;

          const calendarStatusRes =
            await GoogleCalHelpers.checkAndSetupCalendar(
              googleCalSyncData?.calendarId
            );

          if (calendarStatusRes.calendarCreated) {
            calendarIdToStore = calendarStatusRes.calendarId;
          }
          const lastSynced = new Date().toISOString();
          const lastConnected =
            googleCalSyncData?.lastConnected || getCurrDate("default");
          const stateUpdates = {
            isGoogleCalSyncEnabled: true,
          };

          if (lastConnected) {
            stateUpdates.googleCalLastConnected = lastConnected;
          }
          this.updateRootState({
            ...stateUpdates,
          });

          const syncRes = await this.initGoogleCalSync(lastSynced, false, true);

          const dbCalUpdates = {
            "/googleCalSyncInfo/isEnabled": true,
            "/googleCalSyncInfo/refreshToken": refreshToken,
            "/googleCalSyncInfo/lastConnected": lastConnected,
          };

          if (syncRes.status === "OK") {
            dbCalUpdates["/googleCalSyncInfo/lastSynced"] = lastSynced;
          }
          if (calendarIdToStore) {
            dbCalUpdates["/googleCalSyncInfo/calendarId"] = calendarIdToStore;
          }

          updates = {
            ...dbCalUpdates,
          };
          toastMsg = "Google Calender is connected";
          this.$router.replace(this.$route.path);
        } else {
          toastMsg = errorMsg ? errorMsg : "Some Error occurred!";
          toastBgColor = "red";
        }
      }

      if (!isEmpty(updates)) {
        DatabaseInterface.update(updates, userId);
      }

      this.showToast({
        message: toastMsg,
        color: toastBgColor,
      });
    },
    async checkGoogleCalAndSync(googleCalSyncInfo) {
      if (!googleCalSyncInfo || !googleCalSyncInfo.isEnabled) {
        return;
      }

      const syncTimeStamp = new Date().toISOString();
      await GoogleCalHelpers.setCalAuthRefreshToken(
        googleCalSyncInfo.refreshToken
      );

      const currGoogleCalId = googleCalSyncInfo?.calendarId;

      this.setCalSync(true);
      const storedGoogleCal = await GoogleCalHelpers.getCalendarById(
        currGoogleCalId
      );

      if (isEmpty(storedGoogleCal)) {
        this.toggleGoogleCalReconnectStatus(true);
        this.setCalSync(false);
        return;
      }

      this.setGoogleCalLastSync(googleCalSyncInfo.lastSynced);
      this.$nextTick(() => {
        // currTimeStamp, updateDb = false, passLastConnected
        this.initGoogleCalSync(syncTimeStamp, true);
      });
    },
    async getNotesDirsAndSetInStore(userId) {
      const notesRes = await notesCollection.dbStore.doc(userId).get();

      if (notesRes.exists) {
        const noteData = notesRes.data();

        const notesUpdats = {};

        if (!isEmpty(noteData.directories)) {
          notesUpdats.directories = noteData.directories;
        }

        if (!isEmpty(noteData.notes)) {
          notesUpdats.rawNotes = noteData.notes;
        }

        this.updateNoteState({
          ...notesUpdats,
        });
      }
    },
    clearNotesState() {
      this.updateNoteState({
        rawNotes: [],
        notes: [],
        directories: {},
      });
    },
    clearResourcesState() {
      // this.updateResourcesState({
      //   rawResourcesMap: {},
      //   processedResources: [],
      // });
    },
    handleResourcesUpdates(doc) {
      const data = doc.data();
      const currNewRawResources = data?.resources || {};
      const newResourcesCategories = data?.categories || {};
      const currRawResources = this.rawResourcesMap;
      const currResourcesCategories = this.resourcesCategories;
      const updates = {};

      const selectedResourceTypeOpt = data?.selectedResourceType || "people";
      if (!isEqual(currNewRawResources, currRawResources)) {
        updates.rawResourcesMap = currNewRawResources;
      }

      if (!isEqual(newResourcesCategories, currResourcesCategories)) {
        updates.categories = newResourcesCategories;
      }

      updates.selectedResourceTypeOpt = selectedResourceTypeOpt;

      this.updateResourcesState(updates);
    },
    removeResourcesListener() {
      if (this.resourcesListener) {
        this.resourcesListener();
      }
    },
    async updateCurrDateForTodayMode(userId, userData) {
      if (!isEmpty(userData)) {
        const dbUpdates = {
          ["/view/3/selectedDates"]: [makeDateFormat(new Date())],
        };
        await DatabaseInterface.update(dbUpdates, userId);
      }

      return true;
    },
    async patchContingentFilterInCalMode(userData, userId) {
      if (!isEmpty(userData)) {
        const dbUpdates = {};
        const contingentTypes = userData.view[0].selectedTypes;
        const contingentTypesInTodayMode = userData.view[3].selectedTypes;
        if (!isEmpty(contingentTypes)) {
          dbUpdates[`/view/0/selectedTypes`] = [];
        }

        if (!isEmpty(contingentTypesInTodayMode)) {
          dbUpdates[`/view/3/selectedTypes`] = [];
        }

        if (!isEmpty(dbUpdates)) {
          await DatabaseInterface.update(dbUpdates, userId);
        }
      }
    },
    async redirectToNextActions(userId) {
      if (!isEmpty(userId)) {
        const dbUpdates = {
          nav: 4,
          [`view/4/groupView`]: 1,
        };
        await DatabaseInterface.update(dbUpdates, userId);
      }
      return true;
    },
    clearTasksState() {
      this.updateState({
        rawTasks: [],
        rawTasksMap: {},
        selectedTasks: [],
      });

      this.closeEditTaskDialog();
    },

    // goToNextAreas(userId, userData) {},
    ...mapActions([
      "updateRootState",
      "toggleNotesView",
      "setGoogleCalLastSync",
      "setCalSync",
      "toggleGoogleCalReconnectStatus",
    ]),
    // ...mapActions(["setCalSync"]),
    ...mapActions("editForm", ["showEditTaskDialog", "closeEditTaskDialog"]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions("toast", ["showToast"]),
    ...mapActions("note", ["updateNoteState"]),
    ...mapActions("resourcesData", ["updateResourcesState"]),
  },
  computed: {
    ...mapGetters("task", [
      "isInspectModeEnabled",
      "isInboxViewEnabled",
      "isGlobalViewEnabled",
      "currNav",
      "selectedDates",
      "prevTreeGroupView",
      "rawTasks",
      "groupMode",
      "isProjectsResourceModeEnabled",
      "isTasksDataLoaded",
    ]),
    ...mapGetters(["isGoogleCalSyncEnabled"]),
    ...mapGetters("resourcesData", {
      rawResourcesMap: "rawResourcesMap",
      resourcesCategories: "categories",
    }),
    ...mapGetters("note", {
      rawNotes: "rawNotes",
      rawDirectories: "directories",
    }),
    isNotCalenderOrPrioritizeViewOrInbox() {
      // returns true if selected view is not calender view or prioritize view or inbox view ;
      return (
        !this.isInboxViewEnabled && this.currNav !== 0 && this.currNav !== 3
      );
    },
    isNotCalenderView() {
      // returns true if selected view is not calender view ;
      return this.currNav !== 0;
    },
    showCalView() {
      return this.user && acceptedNavViews.indexOf(this.currNav) > -1;
    },

    modeHeaderLabel() {
      const modeHeader = this.modeHeader;
      return modeHeader;
    },
    modeHeader() {
      const nav = this.currNav;
      if (
        this.isInspectModeEnabled &&
        (this.isGlobalViewEnabled || this.isInboxViewEnabled)
      ) {
        // if (this.isInspectModeEnabled) {

        if (nav === 0 || nav === 3) {
          const selectedDates = this.selectedDates;
          if (
            selectedDates.length === 1 &&
            isToday(parseISO(selectedDates[0]))
          ) {
            return "Today";
          }
          return "Calendar";
        } else if (nav === 1) {
          return "Areas";
        } else if (nav === 5) {
          return "Areas";
        }
        // }
        return "All Tasks";
      }

      if (this.isProjectsResourceModeEnabled) {
        return "Projects";
      }

      if (this.isGlobalViewEnabled) {
        return "All Tasks";
      }
      if (this.isInboxViewEnabled) {
        return "Inbox";
      }
      if (nav === 0) {
        return "Calendar";
      } else if (nav === 3) {
        return "Today";
      } else if (treeSideBarModes.includes(nav)) {
        return "Areas";
      }

      return "";
    },
    isTreeSideBarAvailable() {
      const currNav = this.currNav;

      return treeSideBarModes.includes(currNav);
    },
    viewWrapperClass() {
      return this.isProjectsResourceModeEnabled ? "resource-list" : undefined;
    },
    resourceEnabledType() {
      return this.isProjectsResourceModeEnabled ? "project" : undefined;
    },
  },
  created() {
    this.loadedData = false;
    this.updateState({
      rawTasks: [],
      rawTasksMap: {},
    });
  },
  mounted() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // console.debug("here");
        this.user = user;
        // console.debug(this.user);
        this.noUser = false;
        let redirectToNextActions = false;
        let isNotesView = false;
        let loadNotes = true;
        let currActiveView = "";
        const queryData = this.$route.query;

        let taskStateUpdates = {};
        let rootStateUpdates = {};
        await this.patchUserProjectResourceView(user.uid);

        const res = await (
          await DatabaseInterface.get(user.uid).once("value")
        ).val();

        initUserNotes(user.uid);
        initUserResources(user.uid);

        this.setUserActionListener();

        taskStateUpdates = {
          ...taskStateUpdates,
          collapsedGroups: res?.view[res.nav]?.collapsedGroups || [],
        };
        if (res.isProjectsResourceModeEnabled) {
          taskStateUpdates.isProjectsResourceModeEnabled = true;

          rootStateUpdates.currActiveView = "dashboard";
        }
        // this.resourcesListener = resourcesCollection.dbStore
        //   .doc(user.uid)
        //   .onSnapshot(this.handleResourcesUpdates);
        this.updateState({
          ...taskStateUpdates,
        });

        this.updateRootState({
          ...rootStateUpdates,
        });

        await Vue.nextTick();
        if (isEmpty(res)) {
          await this.fillInitUserData(user.uid);
          return;
        }

        patchTasksToAddOrderAndPosition(user.uid);
        patchTodayModeGroupView(user.uid);
        this.patchContingentFilterInCalMode(res, user.uid);
        await this.updateCurrDateForTodayMode(user.uid, res);

        if (this.$route.name !== "Notes" && res.notesView) {
          isNotesView = true;
          currActiveView = "notes";
          loadNotes = false;
          this.$router.replace({
            name: "Notes",
          });
        }

        if (
          (res.isProjectsResourceModeEnabled === undefined ||
            res.isProjectsResourceModeEnabled === false) &&
          this.$route.name !== "Resources" &&
          res.activeNavView === "resources"
        ) {
          currActiveView = "resources";
          loadNotes = false;
          this.$router.replace({
            name: "Resources",
          });
        }

        if (
          !isEmpty(res) &&
          res.activeNavView === "dashboard" &&
          !res.inboxView &&
          treeSideBarModes.includes(res.nav)
        ) {
          redirectToNextActions = true;
          this.redirectToNextActions(user.uid);
        }

        this.updateRootState({
          currNav: !redirectToNextActions ? res.nav : 1,
          isNotesView,
          isGoogleCalSyncEnabled: res.googleCalSyncInfo?.isEnabled || false,
          currActiveView,
          resourceSettings: res.resourceSettings || {},
          editFormSettings: res.editFormSettings || {},
          selectedColorTheme: getAppColorThemeFromUserData(res),
        });
        this.initCalSync(user.uid, res.googleCalSyncInfo, queryData);
        this.checkGoogleCalAndSync(res.googleCalSyncInfo);
        if (loadNotes) {
          this.setUserNotesListener(user.uid);
        }
        if (!res.inboxView) {
          this.updateState({
            prevTreeGroupView: !redirectToNextActions
              ? res.view[1].groupView
              : 1,
            prevTreeGridMode: res.view[1].sortMode,
          });
        }
      } else {
        this.user = null;
        document.location.href = "/";
      }
    });
  },
  beforeDestroy() {
    this.updateRootState({
      searchVal: "",
    });
    this.clearTasksState();
    this.clearNotesState();
    this.clearResourcesState();
    this.removeResourcesListener();
    this.removeNotesListener();
    this.removeUserActionListener();
  },
  destroyed() {
    DatabaseInterface.get(this.userInfo.uid).off("value");
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1e1e1;
}

.width-fixed {
  width: 280px;
}

.el-fixed {
  position: fixed;
}

.mode-select-bar {
  background-color: #eeeeee;
  top: 36px;
  padding-right: 0px !important;
}

.calender-view {
  top: 114px;
  bottom: 8px;
}

.reset-calender-top {
  top: 80px;
  padding-top: 10px;
}

.v-btn--active >>> svg {
  fill: rgb(255, 152, 0) !important;
}

.side-nav {
  width: 100%;
  max-width: 280px;
  position: relative;
  height: 100%;
  background-color: #f9f9f9;
}

.side-nav .side-nav-content {
  width: 100%;
  max-width: 280px;
  position: fixed;
}

.page-layout aside {
  min-width: 280px;
  max-width: 280px;
}

.sidebar-title {
  font-size: 1.3845rem;
  padding: 2px;
}
.main {
  width: 100%;
}
</style>
