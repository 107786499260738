import EventEmitter from "@/helpers/eventEmitter";
import patchCompletedTasksInDb from "@/scripts/patchCompletedTasksInDb";
import DatabaseInterface from "@/services/DatabaseInterface";
import { mapGetters, mapActions, mapMutations } from "vuex";
import firebase from "@/services/firebase";
import { getUserResources, resourcesCollection } from "@/helpers/resources";
import { isEmpty } from "lodash";
const userDetailsMixin = {
  computed: {
    ...mapGetters("user", ["userInfo"]),
  },
  methods: {
    ...mapActions("user", ["setUserDetails", "clearUserDetails"]),
    ...mapMutations("task", {
      updateTasksState: "updateState",
    }),
    ...mapActions(["updateRootState"]),
    patchTasks() {
      patchCompletedTasksInDb(this.userInfo.uid);
    },
    handleUserSignout() {
      const ref = DatabaseInterface.get(this.userInfo.uid);
      EventEmitter.emit("unsubscribe");
      ref.off("value");
      firebase

        .auth()
        .signOut()
        .then(() => {
          this.clearUserDetails();
          firebase.auth().onAuthStateChanged(() => {
            this.$router.push("/");
          });
        });
    },

    async patchUserProjectResourceView(userId) {
      const resourceDataRes = await resourcesCollection.getByKey(userId);
      const userAppData = await (
        await DatabaseInterface.get(userId).once("value")
      ).val();
      if (resourceDataRes.exists) {
        const resourceData = resourceDataRes.data();

        const dbUpdates = {};
        if (
          typeof userAppData?.isProjectsResourceModeEnabled === "undefined" &&
          userAppData?.activeNavView === "resources" &&
          resourceData?.selectedResourceType === "project"
        ) {
          dbUpdates["/inboxView"] = false;
          dbUpdates["/globalView"] = false;
          dbUpdates["/notesView"] = false;
          dbUpdates["/activeNavView"] = "dashboard";
          dbUpdates["/showCompleted"] = false;
          dbUpdates["/isProjectsResourceModeEnabled"] = true;
        }

        if (!isEmpty(dbUpdates)) {
          // this.updateTasksState({
          //   isProjectsResourceModeEnabled: true,
          // });
          // this.updateRootState({
          //   currActiveView: "dashboard",
          // });
          await DatabaseInterface.update(dbUpdates, userId);
        }
      }

      return true;
    },
  },
};

export default userDetailsMixin;
