<template>
  <div class="handler-icon-container">
    <v-icon size="20" class="main-icon" :color="iconColor">
      $ctiBraillesixsquare
    </v-icon>
  </div>
</template>
<script>
import { columnPropsMixin } from "@/core/components/DataTable";
import {
  DARK_GREY_ICON_COLOR,
  // NEW_DARK_ICON_COLOR,
} from "@/variables/colors";
export default {
  name: "RowHandler",
  mixins: [columnPropsMixin],

  computed: {
    iconColor() {
      let color = DARK_GREY_ICON_COLOR;
      if (this.extraData?.useDynamicColor && this.extraData?.colorProvider) {
        color = this.extraData.colorProvider(this);
      }

      return color;
    },
  },
};
</script>
