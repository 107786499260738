import Vue from "vue";
import { convertValueToArray } from "@/helpers/common";
import EventEmitter from "@/helpers/eventEmitter";
import { getUserNotes } from "@/helpers/notes";
import { getResourceTreeView, getUserResources } from "@/helpers/resources";
import DatabaseInterface from "@/services/DatabaseInterface";
import {
  RECOUNT_ITEMS_AFTER_MOVE_EVENT,
  RECOUNT_TASKS_AFTER_MOVE_EVENT,
  REFRESH_TREE_EVENT,
  STORE_REMOVED_NODE_ID,
  TREE_NODE_MOVED_EVENT,
  TREE_NODE_RENAME_EVENT,
} from "@/variables/events";
import { cloneDeep, isEmpty } from "lodash";
import { mapGetters } from "vuex";
import userDetailsMixin from "./userDetailsMixin";
const mixin = {
  mixins: [userDetailsMixin],
  methods: {
    addAttributesInCate(cateItem, treeType) {
      const localCateItem = cloneDeep(cateItem);
      const cateAttrs = {
        "data-action-value": localCateItem.key,
      };

      if (treeType === "projects-tree") {
        cateAttrs["data-action-type"] =
          cateItem.type === "project"
            ? "project-change"
            : "project-category-change";

        cateAttrs["data-prevent-close"] = "1";
        if (cateItem.type === "category") {
          cateAttrs["data-continue-process"] = "1";
          cateAttrs["data-close-form-only"] = "1";
        }
      } else if (treeType === "resources-tree") {
        cateAttrs["data-action-type"] = "resource-category-change";
      } else if (treeType === "notes-tree") {
        cateAttrs["data-action-value"] = "notes-subject-change";
      }

      if (!isEmpty(localCateItem.hasAttribute)) {
        if (!isEmpty(localCateItem.hasAttribute.class)) {
          localCateItem.hasAttribute.class += " tree-node";
        }
        localCateItem.hasAttribute = {
          ...localCateItem.hasAttribute,
          ...cateAttrs,
        };
      } else {
        localCateItem.hasAttribute = {
          class: "tree-node",
          ...cateAttrs,
        };
      }

      return localCateItem;
    },
    addCategoryInTree(
      categoryData,
      parentId,
      focus = true,
      treeViewElId = "#treeview",
      editNode
    ) {
      if (treeViewElId === "#treeview") {
        treeViewElId = this.getTasksTreeViewElId(treeViewElId);
      }

      if (treeViewElId === "#resources-categories-tree") {
        treeViewElId = getResourceTreeView("active").targetId;
      }
      const catTreeView = document.querySelector(treeViewElId).ej2_instances[0];
      let extraData = {};
      if (treeViewElId === "#treeview") {
        extraData = { class: categoryData.type };
      }

      catTreeView.addNodes(
        [
          {
            ...categoryData,
            isSelected: focus ? true : false,
            ...extraData,
          },
        ],
        parentId,
        categoryData.order
      );
      if (focus) {
        setTimeout(() => {
          catTreeView.ensureVisible(categoryData.key);
        }, 0);
      }

      if (editNode) {
        catTreeView.beginEdit(categoryData.key);
      }
    },
    removeCategoryFromTree(categoryIdList, treeViewElId = "#treeview") {
      let emitEvent = false;
      if (treeViewElId === "#treeview") {
        treeViewElId = this.getTasksTreeViewElId(treeViewElId);
        emitEvent = true;
      }

      if (treeViewElId === "#resources-categories-tree") {
        treeViewElId = getResourceTreeView("active").targetId;

        emitEvent = true;
      }
      const categoryIds = convertValueToArray(categoryIdList);
      const catTreeView = document.querySelector(treeViewElId).ej2_instances[0];

      if (emitEvent) {
        EventEmitter.emit(STORE_REMOVED_NODE_ID, categoryIds);
      }
      catTreeView.removeNodes(categoryIds);
    },
    async moveNodesInTree(nodeData, parentId, treeViewElId = "#treeview") {
      // let target = parentId;

      if (treeViewElId === "#treeview") {
        treeViewElId = this.getTasksTreeViewElId(treeViewElId);
      }
      const catTreeView = document.querySelector(treeViewElId).ej2_instances[0];

      if (treeViewElId === "#treeview") {
        if (parentId) {
          EventEmitter.emit(TREE_NODE_MOVED_EVENT);
          EventEmitter.emit(RECOUNT_TASKS_AFTER_MOVE_EVENT, nodeData.type);
          let increaseIndex = false;
          const moveParentData = catTreeView.getTreeData(parentId)[0];
          const currItemData = catTreeView.getTreeData(nodeData.key)[0];
          const children = moveParentData.projects;
          const currItemParent =
            currItemData.subcategory || currItemData.category;
          let orderToUse = parseInt(nodeData.order, 10);
          if (!isEmpty(children)) {
            if (currItemParent && currItemParent === parentId) {
              increaseIndex =
                orderToUse !== 0 && !isEmpty(children[orderToUse]);
            }
          }

          if (!increaseIndex) {
            increaseIndex =
              !isEmpty(children[orderToUse]) &&
              children[orderToUse] === children[orderToUse.length - 1];
          }

          if (increaseIndex) {
            orderToUse++;
          }

          catTreeView.moveNodes([nodeData.key], parentId, orderToUse);
        } else {
          const updates = {
            [`/categories/${nodeData.key}`]: {
              ...nodeData,
            },
          };
          DatabaseInterface.update(updates, this.userInfo.uid);
          EventEmitter.emit(REFRESH_TREE_EVENT);
          // Vue.nextTick(() => {
          //   EventEmitter.emit("refresh-tree");
          // });

          // console.debug("TIME", new Date().toISOString());
          // Vue.nextTick(() => {
          //   console.debug("TIME", new Date().toISOString());
          //   EventEmitter.emit(REFRESH_TASKS_EVENT);
          // });
          // console.debug("REFRE", catTreeView.refreshNode);

          // catTreeView.moveNodes(
          //   [nodeData.key],
          //   document.querySelector("#treeview"),
          //   parseInt(nodeData.order, 10)
          // );
          // catTreeView.refreshNode(nodeData.key, {
          //   ...nodeData,
          //   hasAttribute: { class: nodeData.type },
          //   isSelected: true,
          // });
          // catTreeView.removeNodes([nodeData.key]);
          // catTreeView.addNodes(
          //   [
          //     {
          //       ...nodeData,
          //       hasAttribute: { class: nodeData.type },
          //       isSelected: true,
          //     },
          //   ],
          //   null,
          //   nodeData.order
          // );
          // EventEmitter.emit(TREE_NODE_MOVED_EVENT);
        }
      } else {
        if (parentId) {
          EventEmitter.emit(TREE_NODE_MOVED_EVENT);
          EventEmitter.emit(RECOUNT_ITEMS_AFTER_MOVE_EVENT, nodeData.type);

          let increaseIndex = false;

          const parentKeyProp =
            treeViewElId === "#resources-categories-tree"
              ? "parentCatKey"
              : "parentKey";
          const moveParentData = catTreeView.getTreeData(parentId)[0];
          const currItemData = catTreeView.getTreeData(nodeData.key)[0];
          const children = moveParentData.children;
          const currItemParent = currItemData[parentKeyProp];
          let orderToUse = parseInt(nodeData.order, 10);
          if (!isEmpty(children)) {
            if (currItemParent && currItemParent === parentId) {
              increaseIndex =
                orderToUse !== 0 && !isEmpty(children[orderToUse]);
            }
          }

          if (!increaseIndex) {
            increaseIndex =
              !isEmpty(children[orderToUse]) &&
              children[orderToUse] === children[orderToUse.length - 1];
          }

          if (increaseIndex) {
            orderToUse++;
          }
          catTreeView.moveNodes([nodeData.key], parentId, orderToUse);
        }
      }

      // console.debug("DDDD", nodeIds, parentId, index);
    },
    setCateInSelectedOpts(cateData, treeType) {
      cateData = convertValueToArray(cateData);
      if (treeType === "projects") {
        const updates = {};
        updates["/view/1/selectedOptsList"] = cateData;
        DatabaseInterface.update(updates, this.userInfo.uid);
      }

      if (treeType === "resources") {
        getUserResources().set(cateData, "selectedCategories");
      }

      if (treeType === "subjects") {
        getUserNotes().set(cateData, "selectedFolders");
      }
    },
    async updateNameOfCategoryInTree(list, targetElId = "#treeview", useEvent) {
      if (list && list.length) {
        let targetId = targetElId;

        if (targetId === "#treeview") {
          targetId = this.getTasksTreeViewElId(targetId);
        }

        if (useEvent) {
          EventEmitter.emit(TREE_NODE_RENAME_EVENT, true, true);
        }

        await Vue.nextTick();
        const treevalidate =
          document.querySelector(targetElId).ej2_instances[0];
        list.forEach((item) => {
          treevalidate.updateNode(item.key, item.updates.title);
        });
      }
    },
    addCatInSelectedIds() {},
    renameCateItemInTree(targetNodeId, target) {
      if (target === "#resources-categories-tree") {
        target = getResourceTreeView("active").targetId;
      }
      const treevalidate = document.querySelector(target).ej2_instances[0];
      treevalidate.beginEdit(targetNodeId);

      // treevalidate.updateNode("61a63bc9-1b60-47d5-9f62-814e24809837", "TEST");
    },
    getTasksTreeViewElId(targetId) {
      if (this.showClearedTasks) {
        targetId = "#treeview-all-data";
      }

      return targetId;
    },
  },
  computed: {
    ...mapGetters("task", ["showClearedTasks"]),
  },
};

export default mixin;
