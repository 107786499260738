import { taskContingentFilter } from "./tasks";

export const globalViewConfig = [
  {
    type: "calView",
    selectedDates: ["2021-09-16"],
    sortMode: 1,
    statusFilter: "scheduled",
    groupView: 0,
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "treeView",
    selectedCategory: "all",
    selectedProject: "all",
    selectedOptsList: [],
    sortMode: 1,
    groupView: 0,
    statusFilter: "incomplete",
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "resourceView",
    selectedCategory: "all",
    selectedProject: "all",
    sortMode: 0,
    groupView: 0,
    statusFilter: "incomplete",
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "priorityView",
    selectedCategory: "all",
    selectedProject: "all",
    selectedDates: [],
    sortMode: 1,
    statusFilter: "incomplete",
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "globalView",
    selectedCategory: "all",
    selectedProject: "all",
    selectedDates: [],
    sortMode: 0,
    groupView: 0,
    statusFilter: "all",
    showCurrAndFutureTasks: true,
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
];

export const navViewConfig = [
  {
    type: "calView",
    selectedDates: ["2021-09-16"],
    sortMode: 1,
    statusFilter: "scheduled",
    groupView: 0,
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "treeView",
    selectedCategory: "all",
    selectedProject: "all",
    selectedOptsList: [],
    sortMode: 1,
    groupView: 0,
    statusFilter: "incomplete",
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "resourceView",
    selectedCategory: "all",
    selectedProject: "all",
    sortMode: 0,
    groupView: 0,
    statusFilter: "incomplete",
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "priorityView",
    selectedCategory: "all",
    selectedProject: "all",
    selectedDates: [],
    sortMode: 1,
    statusFilter: "incomplete",
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
  {
    type: "globalView",
    selectedCategory: "all",
    selectedProject: "all",
    selectedDates: [],
    sortMode: 0,
    groupView: 0,
    statusFilter: "all",
    showCurrAndFutureTasks: true,
    listFilters: {
      priority: [],
      taskType: [],
      flag: [],
      category: [],
    },
  },
];

export const groupsConfigs = [
  {
    type: "schedule",
    sortColumnType: 0,
    groupColumnType: 0,
  },
  {
    type: "prioritize",
    sortColumnType: 4,
    groupColumnType: 1,
  },
  {
    type: "review",
    sortColumnType: 2,
    groupColumnType: 2,
  },
  {
    type: "history",
    sortColumnType: 3,
    groupColumnType: 3,
  },
];

export const groupModeLabels = {
  0: "Schedule",
  1: "Prioritize",
  2: "Organize",
  3: "Review",
};
const notInboxFilters = [
  {
    field: "project",
    type: "!=",
    value: "inbox",
  },
  {
    field: "project",
    type: "!=",
    value: null,
  },
  {
    field: "project",
    type: "!=",
    value: undefined,
  },
  {
    field: "project",
    type: "!=",
    value: "",
  },

  {
    field: "isReminderTask",
    type: "=",
    value: false,
  },
];

export const navViewFiltersConfig = [
  {
    name: "CalView",
    filters: [
      [
        [
          { field: "due", type: "!=", value: "none" },
          {
            field: "isReminderTask",
            type: "!=",
            value: true,
          },
        ],
        [
          { field: "due", type: "!=", value: "none" },
          [
            {
              field: "showReminder",
              type: "=",
              value: true,
            },
            {
              field: "showInToday",
              type: "=",
              value: true,
            },
          ],
          {
            field: "isFirstReminderTask",
            type: "=",
            value: true,
          },
        ],
        [
          { field: "due", type: "!=", value: "none" },
          [
            {
              field: "showReminder",
              type: "=",
              value: true,
            },
            {
              field: "showInToday",
              type: "=",
              value: true,
            },
          ],
          {
            field: "isFirstReminderTask",
            type: "=",
            value: false,
          },
        ],
      ],
      [
        {
          field: "keepItVisible",
          type: "=",
          value: true,
        },
        {
          field: "completed",
          type: "=",
          value: false,
        },
      ],

      {
        field: "projectCompleted",
        type: "=",
        value: false,
      },
      {
        field: "projectHidden",
        type: "=",
        value: false,
      },
      [
        {
          field: "isFirstTask",
          type: "=",
          value: true,
        },
        {
          field: "isCalendarOnly",
          type: "=",
          value: true,
        },
        {
          field: "isCalendarOnly",
          type: "=",
          value: false,
        },
        // {
        //   field: "isVirtual",
        //   type: "=",
        //   value: false,
        // },
        {
          field: "isVirtual",
          type: "=",
          value: true,
        },
        {
          field: "coRelationalId",
          type: "=",
          value: "",
        },
      ],
      // [
      //   {
      //     field: "contingentHasDate",
      //     type: "=",
      //     value: "true",
      //   },
      // ],

      // {
      //   field: "isCalendarOnly",
      //   type: "=",
      //   value: true,
      // },
      // {
      //   field: "status",
      //   type: "=",
      //   value: "scheduled",
      // },
      // {
      //   field: "completed",
      //   type: "=",
      //   value: "scheduled",
      // },
      // [
      //   {
      //     field: "completed",
      //     type: "=",
      //     value: "incomplete",
      //   },
      //   {
      //     field: "completed",
      //     type: "=",
      //     value: "scheduled",
      //   },
      //   {
      //     field: "status",
      //     type: "=",
      //     value: "scheduled",
      //   },
      // ],
    ],
  },
  {
    name: "TreeView",
    filters: [
      ...notInboxFilters,
      [
        {
          field: "keepItVisible",
          type: "=",
          value: true,
        },
        {
          field: "completed",
          type: "=",
          value: false,
        },
      ],

      {
        field: "projectCompleted",
        type: "=",
        value: false,
      },
      {
        field: "projectHidden",
        type: "=",
        value: false,
      },
      [
        {
          field: "isFirstTask",
          type: "=",
          value: true,
        },
        {
          field: "isCalendarOnly",
          type: "=",
          value: false,
        },
        {
          field: "coRelationalId",
          type: "=",
          value: "",
        },
      ],
      // {
      //   field: "isCalendarOnly",
      //   type: "=",
      //   value: false,
      // },
      // [
      //   {
      //     field: "status",
      //     type: "=",
      //     value: "incomplete",
      //   },
      //   {
      //     field: "status",
      //     type: "=",
      //     value: "scheduled",
      //   },
      // ],
    ],
  },
  {
    name: "GlobalView",
    filters: [
      {
        field: "isReminderTask",
        type: "=",
        value: false,
      },
      [
        {
          field: "keepItVisible",
          type: "=",
          value: true,
        },
        {
          field: "completed",
          type: "=",
          value: false,
        },
      ],

      {
        field: "projectCompleted",
        type: "=",
        value: false,
      },
      {
        field: "projectHidden",
        type: "=",
        value: false,
      },
      [
        {
          field: "isCalendarOnly",
          type: "=",
          value: false,
        },
        {
          field: "isFirstTask",
          type: "=",
          value: true,
        },
        {
          field: "coRelationalId",
          type: "=",
          value: "",
        },
        // {
        //   field: "isVirtual",
        //   type: "=",
        //   value: true,
        // },
      ],
      // [
      //   {
      //     field: "isCalendarOnly",
      //     type: "=",
      //     value: false,
      //   },
      //   {
      //     field: "isVirtual",
      //     type: "=",
      //     value: false,
      //   },
      // ],
      // {
      //   field: "isCalendarOnly",
      //   type: "=",
      //   value: false,
      // },
      // [
      //   // {
      //   //   field: "status",
      //   //   type: "=",
      //   //   value: "scheduled",
      //   // },
      //   {
      //     field: "due",
      //     type: "!=",
      //     value: "none",
      //   },
      // ],
    ],
  },
  {
    name: "InboxView",
    filters: [
      [
        {
          field: "project",
          type: "=",
          value: "inbox",
        },
        {
          field: "project",
          type: "=",
          value: null,
        },
        {
          field: "project",
          type: "=",
          value: undefined,
        },
        {
          field: "project",
          type: "=",
          value: "",
        },
      ],
      {
        field: "isReminderTask",
        type: "=",
        value: false,
      },
      [
        {
          field: "keepItVisible",
          type: "=",
          value: true,
        },
        {
          field: "completed",
          type: "=",
          value: false,
        },
      ],

      {
        field: "projectCompleted",
        type: "=",
        value: false,
      },
      {
        field: "projectHidden",
        type: "=",
        value: false,
      },
      [
        {
          field: "isCalendarOnly",
          type: "=",
          value: false,
        },
        {
          field: "isFirstTask",
          type: "=",
          value: true,
        },
        {
          field: "coRelationalId",
          type: "=",
          value: "",
        },
      ],
      // {
      //   field: "isCalendarOnly",
      //   type: "=",
      //   value: false,
      // },
      // [
      //   {
      //     field: "completed",
      //     type: "=",
      //     value: "incomplete",
      //   },
      //   {
      //     field: "status",
      //     type: "=",
      //     value: "scheduled",
      //   },
      // ]
    ],
  },
  {
    name: "NotesView",
    filters: [],
  },
  {
    name: "TodayView",
    filters: [
      // ...notInboxFilters,
      // [
      //   [
      //     {
      //       field: "isContingent",
      //       type: "=",
      //       value: true,
      //     },
      //     {
      //       field: "due",
      //       type: "=",
      //       value: "none",
      //     },
      //   ],
      //   {
      //     field: "due",
      //     type: "=",
      //     value: "2022-09-12",
      //   },
      // ],
      [
        {
          field: "keepItVisible",
          type: "=",
          value: true,
        },
        {
          field: "completed",
          type: "=",
          value: false,
        },
      ],

      {
        field: "projectCompleted",
        type: "=",
        value: false,
      },
      {
        field: "projectHidden",
        type: "=",
        value: false,
      },
      [
        {
          field: "isFirstTask",
          type: "=",
          value: true,
        },
        {
          field: "isCalendarOnly",
          type: "=",
          value: true,
        },
        {
          field: "isCalendarOnly",
          type: "=",
          value: false,
        },
        // {
        //   field: "isVirtual",
        //   type: "=",
        //   value: false,
        // },
        {
          field: "isVirtual",
          type: "=",
          value: true,
        },
        {
          field: "coRelationalId",
          type: "=",
          value: "",
        },
      ],
      // {
      //   field: "isCalendarOnly",
      //   type: "=",
      //   value: true,
      // },
      // {
      //   field: "status",
      //   type: "=",
      //   value: "scheduled",
      // },
      // {
      //   field: "completed",
      //   type: "=",
      //   value: "scheduled",
      // },
      // [
      //   {
      //     field: "completed",
      //     type: "=",
      //     value: "incomplete",
      //   },
      //   {
      //     field: "completed",
      //     type: "=",
      //     value: "scheduled",
      //   },
      //   {
      //     field: "status",
      //     type: "=",
      //     value: "scheduled",
      //   },
      // ],
    ],
  },
  {
    name: "ResourcesView",
    filters: [],
  },
  {
    name: "PrioritizeView",
    filters: [
      ...notInboxFilters,
      [
        {
          field: "keepItVisible",
          type: "=",
          value: true,
        },
        {
          field: "completed",
          type: "=",
          value: false,
        },
      ],

      {
        field: "projectCompleted",
        type: "=",
        value: false,
      },
      {
        field: "projectHidden",
        type: "=",
        value: false,
      },
      [
        {
          field: "isFirstTask",
          type: "=",
          value: true,
        },
        {
          field: "isCalendarOnly",
          type: "=",
          value: false,
        },
        {
          field: "coRelationalId",
          type: "=",
          value: "",
        },
      ],
    ],
  },
];

export const clearedTaskFiltersToRemove = [
  [
    {
      field: "keepItVisible",
      type: "=",
      value: true,
    },
    {
      field: "completed",
      type: "=",
      value: false,
    },
  ],

  {
    field: "projectCompleted",
    type: "=",
    value: false,
  },
  // {
  //   field: "status",
  //   type: "=",
  //   value: "scheduled",
  // },
];

const isValidView = (
  currView,
  isCurrViewGlobal,
  viewsAvailable,
  isAvailableInGlobal
) => {
  return isCurrViewGlobal
    ? isAvailableInGlobal
    : viewsAvailable.indexOf(currView) > -1;
};
export const groupModes = [
  {
    type: "schedule",
    index: 0,
    gridView: 0,
    title: "Schedule",
    icon: "$ctiClock",
    visibleInViews: [0, 1, 3],
    isAvailableInGlobal: true,
    show(currView, isGlobalView) {
      return isValidView(
        currView,
        isGlobalView,
        this.visibleInViews,
        this.isAvailableInGlobal
      );
    },
  },
  {
    // type: "review",
    type: "prioritize",
    index: 1,
    gridView: 2,
    title: "Prioritize",
    // title: "Organize",
    // icon: "$ctiStopClock",
    visibleInViews: [1],
    icon: "$ctiSort",
    // visibleInViews: [0, 1],
    isAvailableInGlobal: true,
    show(currView, isGlobalView) {
      return isValidView(
        currView,
        isGlobalView,
        this.visibleInViews,
        this.isAvailableInGlobal
      );
    },
  },
  {
    type: "review",
    // type: "prioritize",
    index: 2,
    gridView: 1,
    visibleInViews: [0, 1, 3],
    // visibleInViews: [1],
    // title: "Prioritize",
    title: "Organize",
    icon: "$ctiOrganizeNew",
    // icon: "$ctiSort",
    isAvailableInGlobal: true,
    show(currView, isGlobalView) {
      return isValidView(
        currView,
        isGlobalView,
        this.visibleInViews,
        this.isAvailableInGlobal
      );
    },
  },
  {
    type: "history",
    index: 3,
    gridView: 3,
    title: "Review",
    icon: "$ctiHistory",
    visibleInViews: [0, 1, 3],
    isAvailableInGlobal: true,
    show(currView, isGlobalView) {
      return isValidView(
        currView,
        isGlobalView,
        this.visibleInViews,
        this.isAvailableInGlobal
      );
    },
  },
];

export const viewsWithDateFilter = [0, 3];

export const todayModeFilters = [
  // {
  //   title: "All",
  //   value: "all",
  // },
  {
    title: "Scheduled",
    value: "today",
    icon: "$ctiEvent",
    iconSize: 13,
  },
  // {
  //   title: "Contingents",
  //   value: "contingents",
  //   icon: "$ctiContingent",
  //   iconSize: 13,
  // },
  {
    title: "Reminders",
    value: "reminders",
    icon: "$ctiBellOutlined",
    activeIcon: "$ctiBellFilled",
    iconSize: 13,
  },
];

export const calendarModeFilters = [
  // {
  //   title: "All",
  //   value: "all",
  // },
  {
    title: "Scheduled",
    value: "non-contingents",
    icon: "$ctiEvent",
    iconSize: 13,
  },
  // {
  //   title: "Contingents",
  //   value: "contingents",
  //   icon: "$ctiContingent",
  //   iconSize: 13,
  // },
  {
    title: "Reminders",
    value: "reminders",
    icon: "$ctiBellOutlined",
    activeIcon: "$ctiBellFilled",
    iconSize: 13,
  },
];

const defaultForecastPrefix = "Forecast";
export const calForecastOptsConfig = [
  {
    label: `Week ${defaultForecastPrefix}`,
    displayText: "W",
    value: "week",
    days: 7,
  },
  {
    label: `Month ${defaultForecastPrefix}`,
    displayText: "M",
    value: "month",
    days: 30,
  },
  {
    label: `Quarter ${defaultForecastPrefix}`,
    displayText: "Q",
    value: "quarter",
    days: 91,
  },
  {
    label: `Year ${defaultForecastPrefix}`,
    days: 365,
    displayText: "Y",
    value: "year",
  },
];

export const calForecastValMap = {
  week: 0,
  month: 1,
  quarter: 2,
  year: 3,
};

export const todayModeFiltersMap = {
  // all: 0,
  today: 0,
  contingents: 2,
  reminders: 1,
};

export const calendarModeFiltersMap = {
  // all: 0,
  "non-contingents": 0,
  contingents: 2,
  reminders: 1,
};

export const treeSideBarModes = [1, 4];
export const flagViews = [4, 0];
export const calModes = [0, 3];
export const allTimeFlagViews = [3];
export const groupViewsWithFlag = [1, 2];
export const dragAllowedGroups = [2, 1];
export const areasModeConfig = [
  {
    title: "Areas",
    icon: "$ctiTreeViewIcon",
    groupView: 2,
    index: 1,
    iconSize: 14,
  },
  {
    title: "Areas",
    icon: "$ctiSort",
    index: 4,
    groupView: 1,
    iconSize: 15,
  },
];

export const areasModeIndexsMap = {
  1: 0,
  4: 1,
};

export const taskFiltersConfigMap = {
  week: {
    label: ``,
    displayText: "",
    value: "week",
    subtractType: "week",
    bgColor: "#FFF",
  },
  month: {
    label: ``,
    displayText: "",
    value: "month",
    subtractType: "month",
    bgColor: "#E9E9E9",
  },
  quarter: {
    label: ``,
    displayText: "",
    value: "quarter",
    subtractType: "quarter",
    bgColor: "#D3D3D3",
  },
  year: {
    label: ``,
    displayText: "",
    value: "year",
    subtractType: "year",
    bgColor: "#B8B8B8",
  },
};
export const taskFiltersConfig = Object.values(taskFiltersConfigMap);
export const taskFiltersValMap = {
  week: 0,
  month: 1,
  quarter: 2,
  year: 3,
};

export const taskVisibiltyToggleVisibleModes = ["resources", "dashboard"];
