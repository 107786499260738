export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

let dayStart = 0;
const daysOptsMap = {};
while (dayStart <= 6) {
  daysOptsMap[dayStart] = {
    label: daysOfWeek[dayStart],
    value: dayStart,
  };

  dayStart++;
}

const daysOpts = Object.values(daysOptsMap);

export const parsedWeekOptsMap = {
  0: daysOptsMap[1].value,
  1: daysOptsMap[2].value,
  2: daysOptsMap[3].value,
  3: daysOptsMap[4].value,
  4: daysOptsMap[5].value,
  5: daysOptsMap[6].value,
  6: daysOptsMap[0].value,
};
export default daysOpts;
export { daysOptsMap };
