
import { Quill } from "vue2-editor";
// import MagicUrl from "quill-magic-url";
// import ImageUploader from "quill-image-uploader";

// Quill.register("modules/magicUrl", MagicUrl);
// Quill.register("modules/imageUploader", ImageUploader);

// --- link stuff begin ---
const SnowTheme = Quill.import("themes/snow");
const Delta = Quill.import("delta");

let linkListener;
let actionListener;

/**
 * Extended snow theme for custom 'link tooltip'
 */
class ExtendSnowTheme extends SnowTheme {
  constructor(quill, options) {
    super(quill, options);
    // flag that ensure only create/add our custom elements once
    this.tooltipModified = false;
    this.quill = quill;
    this.options = options;
    this.tooltip = quill?.theme?.tooltip;

    this.addSelectionChange();
    this.addEventListener = true;
  }

  addSelectionChange() {
    const quill = this.quill;
    if(this.quill?.options?.readOnly) return;

    quill.on("selection-change", (range) => {
      this.range = range;
      if (!quill?.theme?.tooltip) return;
      if (!this.tooltipModified) {
        // mark flag
        this.tooltipModified = true;
        this.tooltip = quill.theme.tooltip;
  
        // create input element
        const input = document.createElement("input");
        input.type = "text";
        input.placeholder = "link text";
        input.addEventListener("input", (e) => {
          this.newText = e.target.value;
        });
        input.addEventListener("keydown", (e) => {
          if (e.key === "Enter") {
            this.saveForm();
            this.tooltip.root.classList.add("ql-hidden");
          }
        });
  
        // create input label
        const label = document.createElement("span");
        label.textContent = "Text: ";
  
        // modify tooltip root to replace psuedo elements
        const textInputContainer = document.createElement("div");
        textInputContainer.classList.add("link-text-container");
        textInputContainer.append(label, input);
        this.tooltip.root.insertBefore(textInputContainer, this.tooltip.root.firstChild);
        const linkInputLabel = document.createElement("span");
        linkInputLabel.textContent = "URL: ";
        this.tooltip.root.insertBefore(linkInputLabel, this.tooltip.textbox);
  
        // Modify original link textbox
        const url = this.tooltip.textbox;
        url.placeholder = "www.google.com";
        const setLinkValue = (e) => {
          this.linkValue = e.target.value;
        };
        // quill auto focus this textbox by default, so this ensure we get the value
        url.addEventListener("focus", setLinkValue);
        // if user update link, need to update value
        url.addEventListener("input", setLinkValue);
        this.textEl = input;
        this.urlEl = url;
      };

      if (this.addEventListener && this.textEl && this.urlEl) {
        this.addTooltipListener();
      }
    });
  }

  saveForm() {
    let delta = new Delta()
      .retain(this.index)
      .delete(this.length)
      .insert(this.newText, { link: this.linkValue });
    
    this.quill.updateContents(delta);
    this.removeTooltipListener();
  }
  
  addTooltipListener() {
    this.linkBtn = document.querySelector(".ql-link");
    linkListener = this.linkListener.bind(this)
    this.linkBtn.addEventListener("click", linkListener);
    this.actionBtn = this.tooltip.root.querySelector(".ql-action");
    actionListener = this.actionListener.bind(this)
    this.actionBtn.addEventListener("click", actionListener);
    this.addEventListener = false;
  }

  removeTooltipListener() {
    this.linkBtn.removeEventListener("click", linkListener);
    this.actionBtn.removeEventListener("click", actionListener);
    this.addEventListener = true;
  }

  linkListener() {
    this.setValues(this.range.index, this.range.length);
  }

  actionListener() {
    if (this.tooltip.root.classList.contains("ql-hidden")) {
      this.saveForm();
      return;
    }
    if (this.tooltip.root.classList.contains("ql-editing")) {
      this.setValues(this.tooltip.linkRange.index, this.tooltip.linkRange.length);
    }
  }

  setValues (index, length) {
    this.index = index;
    this.length = length;
    this.newText = this.quill.getText(this.index, this.length);

    this.textEl.value = this.newText;

    const [blot] = this.quill.getLeaf(index); 
    if (blot?.parent?.domNode && blot.parent.domNode.getAttribute("href")) {
      this.urlEl.value = blot.parent.domNode.getAttribute("href");
    } else {
      this.urlEl.value = this.newText;
    }
  }
}

// Quill.register("themes/snow", ExtendSnowTheme);

export default ExtendSnowTheme;
