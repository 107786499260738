import { isEmpty } from "lodash";
import { createUpdateAndEditedForObjective } from "@/helpers/objectives";
import userDetailsMixin from "./userDetailsMixin";
import DatabaseInterface from "@/services/DatabaseInterface";
import { mapGetters } from "vuex";
import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_FORM_DATA } from "@/variables/events";
const mixin = {
  mixins: [userDetailsMixin],
  methods: {
    async restoreObjectiveData(objectiveData, isRedoAction) {
      if (!Array.isArray(objectiveData)) objectiveData = [objectiveData];
      let updatesForDb = {};
      let triggerTaskFormRefresh = false;
      objectiveData.forEach((objD) => {
        const currObjData = this.objectivesMap[objD.key];
        if (!isEmpty(currObjData)) {
          const dataToUse = isRedoAction ? objD.changedProps : objD;

          if (objD.updateDescrText) triggerTaskFormRefresh = true;

          const { dbUpdates: objectiveDbUpdates } =
            createUpdateAndEditedForObjective(dataToUse, currObjData);

          updatesForDb = {
            ...updatesForDb,
            ...objectiveDbUpdates,
          };
        }
      });
      await DatabaseInterface.update(updatesForDb, this.userInfo.uid);
      await this.$nextTick();
      if (triggerTaskFormRefresh) {
        EventEmitter.emit(REFRESH_FORM_DATA, true, "objective");
      }
    },
  },
  computed: {
    ...mapGetters("task", ["objectivesMap"]),
  },
};

export default mixin;
