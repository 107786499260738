<template>
  <v-tooltip top :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="hovered-icon-btn"
        :class="btnClass"
        icon
        small
        v-bind="attrs"
        v-on="on"
        @click.stop="clicked"
      >
        <span class="icon-btn" :class="{'has-hover-icon': hasHoveredSlot}">
          <slot></slot>
        </span>
        <span class="hovered-icon" v-if="hasHoveredSlot">
          <slot name="hovered"></slot>
        </span>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    tooltip: {
      type: String,
      require: true,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btnClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasHoveredSlot() {
      return !!this.$slots.hovered;
    }
  },
  methods: {
    clicked(e) {
      this.$emit("click", e)
    }
  },
}
</script>
<style scoped lang="scss">
.hovered-icon-btn {
  .hovered-icon {
    display: none;
  }
  &:hover .has-hover-icon {
    display: none;
    + .hovered-icon {
      display: inline;
    }
  }

}
</style>