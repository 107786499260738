<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" :attrs="attrs" small depressed icon @click.stop="save">
          <v-icon :color="greyIconColor">mdi-content-save</v-icon>
        </v-btn>
      </template>
      <span> Save</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          :attrs="attrs"
          icon
          small
          depressed
          @click.stop="cancel"
        >
          <v-icon :color="greyIconColor" size="22">mdi-close-circle</v-icon>
        </v-btn>
      </template>
      <span> Cancel </span>
    </v-tooltip>
  </div>
</template>
<script>
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
export default {
  methods: {
    save() {
      this.$emit("save");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  computed: {
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
  },
};
</script>
