<template>
  <div class="wrapper">
    <div v-if="isTaskInCustomOrderGroup">
      <OpenTaskDetails
        @click="openEditForm"
        :disabled="areaSwitchTooltipDisabled"
      >
        <div
          class="task-order-number-text-wrapper"
          :style="createOrderStyles()"
        >
          {{ itemPos }}
        </div>
      </OpenTaskDetails>
    </div>

    <template v-if="isTaskInCalAndWithValidArea">
      <OpenTaskDetails
        @click="openEditForm"
        :disabled="isScheduledTooltipDisabled"
        btnClass="scheduled-btn"
      >
        <v-icon v-if="showContingentIcon" :color="color" :size="17">
          $ctiCalendarCross
        </v-icon>
        <v-icon v-else :color="color" :size="22"> $ctiSchedule </v-icon>
      </OpenTaskDetails>
    </template>

    <div v-show="isTaskInUnscheduledGroup">
      <OpenTaskDetails
        @click="openEditForm"
        :disabled="areaSwitchTooltipDisabled"
        btnClass="unscheduled-btn"
      >
        <v-icon :color="unscheduledIconColor" :size="16">
          $ctiHourglass
        </v-icon>
      </OpenTaskDetails>
    </div>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { checkIfTaskHasArea } from "@/helpers/tasks";

import OpenTaskDetails from "@/components/TasksListView/components/TasksList/components/OpenTaskDetails.vue";
import isUndefinedVal from "@/utils/isUndefinedVal";
export default {
  mixins: [tasksHelpersMixin],
  components: {
    OpenTaskDetails,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showPosition: {
      type: Boolean,
    },
    gridApi: {
      type: Object,
    },
    tooltipDisabled: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    showScheduledBtn: {
      type: Boolean,
    },
    disableScheduledTooltip: {
      type: Boolean,
    },

    checkForContingent: {
      type: Boolean,
    },
    showUnscheduledIcon: {
      type: Boolean,
    },
  },
  methods: {
    openEditForm() {
      this.openTaskDataInEditForm({
        taskData: { ...this.data },
        gridApi: this.gridApi,
        isInNextActionsMode: this.isInNextActionsMode,
      });
    },
    createOrderStyles() {
      let styles = {
        backgroundColor: this.color,
      };

      return styles;
    },
    checkIfOrderNumberIsVisible() {
      let currGroupKeyData = this.gridApi.getGroupBy();

      if (!isEmpty(currGroupKeyData) && isPlainObject(currGroupKeyData)) {
        currGroupKeyData = currGroupKeyData.key;
      }

      if (this.isInInboxViewOrGlobalEnabled) {
        return false;
      }

      if (
        this.currNav === 4 &&
        (currGroupKeyData === "ReviewGroup" ||
          currGroupKeyData === "CategoryGroup") &&
        this.data.isCustomPositioned
      ) {
        return true;
      }

      return (
        this.showPosition &&
        (currGroupKeyData === "" || currGroupKeyData === "CTICustomGroup") &&
        this.data.CTICustomGroup === "1" &&
        this.data.isCustomPositioned
      );
    },
  },
  computed: {
    unscheduledIconColor() {
      return this.color;
    },
    isScheduledTooltipDisabled() {
      return this.onlyAreaIsSelected;
    },
    isTaskWithArea() {
      return checkIfTaskHasArea(this.data);
    },
    isTaskInCalAndWithValidArea() {
      return (
        this.showScheduledBtn &&
        !isUndefinedVal(this.data.due) &&
        (this.data.due !== "none" ||
          (this.data.due === "none" && !!this.data.showContingentInToday))
      );
    },
    isTaskInCustomOrderGroup() {
      return this.checkIfOrderNumberIsVisible();
    },
    itemPos() {
      let pos;

      if (this.isTaskInCustomOrderGroup) {
        pos = this.data.orderedTaskNumber;
      }
      return pos;
    },
    isTaskInUnscheduledGroup() {
      let currGroupKeyData = this.gridApi.getGroupBy();

      if (!isEmpty(currGroupKeyData) && isPlainObject(currGroupKeyData)) {
        currGroupKeyData = currGroupKeyData.key;
      }

      if (this.showContingentIcon) {
        return false;
      }
      if (
        this.showUnscheduledIcon &&
        !isUndefinedVal(this.data.due) &&
        this.data.due === "none" &&
        this.data.CTICustomGroup === "2"
      ) {
        return true;
      }
      if (
        this.isInInboxViewOrGlobalEnabled &&
        !this.data.addNew &&
        (isUndefinedVal(this.data.due) || this.data.due === "none")
      ) {
        return true;
      }

      // const res =
      //   (currGroupKeyData === "" || currGroupKeyData === "CTICustomGroup") &&
      //   this.data.CTICustomGroup === "2";

      // console.log("MAIN RES", {
      //   res,
      //   currGroupKeyData,
      //   CTICustomGroup: this.data.CTICustomGroup,
      //   title: this.data.title,
      //   due: this.data.due,
      //   isContingent: this.data.isContingent,
      // });

      return (
        currGroupKeyData === "CTICustomGroup" &&
        this.data.CTICustomGroup === "2"
      );
    },
    showContingentIcon() {
      return this.checkForContingent && !!this.data.showContingentInToday;
    },

    areaSwitchTooltipDisabled() {
      return this.onlyAreaIsSelected;
    },
  },
};
</script>
<style scoped>
.wrapper {
  /* height: 100%; */
  /* margin-top: 4px; */
}
</style>
