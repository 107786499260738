import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.isSearchBoxOpen,"max-width":"480px","min-width":"200px","fullscreen":false,"content-class":"global-search-box"},on:{"click:outside":_vm.closeDialog,"keydown":_vm.handleKeyDown}},[_c('div',{attrs:{"id":"search-wrapper"}},[_c(VAutocomplete,{staticClass:"global-search-input",attrs:{"attach":"#search-wrapper","value":_vm.selectedVal,"item-text":"label","loader-height":"4px","loading":_vm.searching,"items":_vm.list,"search-input":_vm.searchedVal,"background-color":"#fff","auto-select-first":"","hide-details":"","placeholder":"Jump To","menu-props":{
        auto: true,
        left: true,
        maxWidth: '100%',
        minWidth: '100%',
        contentClass: 'global-search-selector-popup',
      },"no-filter":"","autofocus":"","return-object":""},on:{"input":_vm.handleChange,"keydown":function($event){return _vm.handleKeyDown($event, 'input')},"update:searchInput":function($event){_vm.searchedVal=$event},"update:search-input":function($event){_vm.searchedVal=$event}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{staticClass:"search-icon mr-1",attrs:{"size":"26","color":"#878787"}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(_vm.getItemIconData(item).iconName))]),_c(VListItemTitle,{staticClass:"search-item-text"},[_c('RichTextEditor',{ref:"textEditor1",attrs:{"isReadOnly":"","showToolbar":false,"noBorders":"","value":item.label,"noPadding":""}})],1)]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"search-item-opt",attrs:{"data-action-type":"open-selected-item","data-action-value":item.value}},[_c(VIcon,[_vm._v(_vm._s(_vm.getItemIconData(item).iconName))]),_c(VListItemContent,{staticClass:"ml-3 pb-1 pt-1"},[_c(VListItemTitle,{staticClass:"search-item-text"},[_c('SelectItemLabel',{attrs:{"text":item.label}})],1),(item.fullPathLabel)?[_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.fullPathLabel)+" ")])]:_vm._e()],2)],1)]}},{key:"append",fn:function(){return [_c('div',[_vm._v("Ctrl + K")])]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-item-text"},[_vm._v(" "+_vm._s(_vm.noDataPlaceholder)+" ")])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }