<template>
  <div
    class="d-flex flex-wrap align-center flex-shrink-1 tasks-filters-bar-wrapper pr-4"
    :class="filterBarClasses"
  >
    <slot name="filter-btn">
      <ClearFilterBtn
        v-if="showClrFilterBtn"
        :isEmpty="isEmpty"
        :count="taskCount"
        @clear-btn:click="handleSingleClickOnClear"
        @clear-btn:dblClick="handleDblClickOnClear"
      />
    </slot>
    <template v-for="filter in createdFiltersMap">
      <v-btn-toggle
        :key="filter.hash"
        :label="filter.title"
        multiple
        borderless
        class="pt-0 filter-toggle-btns flex-wrap"
        :class="filter.title + '-filters'"
        v-if="filter.title === 'category' && filter.data.length"
      >
        <template v-for="filterItem in filter.data">
          <v-tooltip bottom :key="filterItem.order">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :class="createCategoryBtnStyles(filterItem)"
                v-on:click="handleFilterSelect(filterItem, $event)"
                x-small
              >
                {{ filterItem.title }}
              </v-btn>
            </template>
            <span> Filter: {{ filterItem.title }} </span>
          </v-tooltip>
        </template>
      </v-btn-toggle>
      <v-btn-toggle
        :key="filter.hash"
        :label="filter.title"
        multiple
        borderless
        class="pt-0 filter-toggle-btns flex-wrap"
        :class="filter.title + '-filters'"
        v-else-if="filter.title === 'resources' && filter.data.length"
      >
        <template v-for="filterItem in filter.data">
          <v-tooltip bottom :key="filterItem.order">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="resource-filter-btn"
                :class="createResourceBtnStyles(filterItem)"
                v-on:click="handleFilterSelect(filterItem, $event)"
                x-small
              >
                {{ filterItem.title }}
              </v-btn>
            </template>
            <span> Filter: {{ filterItem.title }} </span>
          </v-tooltip>
        </template>
      </v-btn-toggle>
      <v-btn-toggle
        :key="filter.hash"
        :label="filter.title"
        multiple
        borderless
        class="pt-0 filter-toggle-btns flex-wrap"
        :class="filter.title + '-filters'"
        v-else-if="
          filter.title !== 'resources' &&
          filter.title !== 'category' &&
          filter.data.length
        "
      >
        <template v-for="filterItem in filter.data">
          <v-tooltip bottom :key="filterItem.order">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :class="createFilterBtnStyles(filterItem)"
                v-on:click="handleFilterSelect(filterItem, $event)"
                :data-filter-cate-id="filterItem.key"
                x-small
              >
                <v-icon
                  :size="filterItem.iconSize || 13"
                  :color="getFilterIconColor(filterItem, filter.title)"
                >
                  {{ filterItem.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span> Filter: {{ filterItem.title }} </span>
          </v-tooltip>
        </template>
      </v-btn-toggle>
    </template>
  </div>
</template>
<script>
import DataFrame from "@/core/components/DataTable/lib/pandas/pandas";
import ClearFilterBtn from "@/components/ClearFilterBtn";
import { priorityTypesMap } from "@/data/priorityTypes";
import { taskTypesMap, typesMap } from "@/data/taskTypes";
import { DARK_ICON_COLOR, LIGHT_GREY_BG_CLASSNAME } from "@/variables/colors";
import isEmpty from "lodash/isEmpty";
import blueimpMD5 from "blueimp-md5";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  addActivityListFilters,
  checkIfSelectedTaskFiltersHasCategory,
  createTaskListFiltersFromFiltersMap,
  isProjectInbox,
} from "@/helpers/tasks";
import filterTasks from "@/core/components/DataTable/lib/pandas/filter";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import cloneDeep from "lodash/cloneDeep";
import {
  sortOpts,
  taskfiltersWithCustomStyles as filtersWithCustomStyles,
} from "@/variables/tasks";
import EventEmitter from "@/helpers/eventEmitter";
import { REFRESH_RESOURCE_ID_FILTER } from "@/variables/events";
import isEqual from "lodash/isEqual";
import { checkIfResourceNodeHasNoProject } from "@/helpers/resourceCategoriesHelpers";

const taskTypesList = Object.keys(taskTypesMap);
const typeList = Object.keys(typesMap);

const processTaskHelperValidModes = ["resource", "task"];
export default {
  mixins: [userDetailsMixin, tasksHelpersMixin],
  components: {
    ClearFilterBtn,
  },
  props: {
    showClrFilterBtn: {
      type: Boolean,
      default: true,
    },
    taskCount: {
      type: Number,
    },
    isEmpty: {
      type: Boolean,
    },
    filterOpts: {
      type: Object,
      default: () => ({
        projectId: "",
        resourceId: "",
        showOnlyNextActionsTasks: false,
      }),
    },
    mode: {
      type: String,
      default: "task",
    },
    showCategoryFilters: {
      type: Boolean,
    },
    showResourceCategories: {
      type: Boolean,
    },
    from: {
      type: String,
    },
    resourceTypeToIgnore: {
      type: String,
    },
  },

  data() {
    return {
      createdFiltersMap: {},
    };
  },
  methods: {
    ...mapActions("task", [
      "setAdditionalTaskFilters",
      "clearAdditionalFilters",
    ]),
    ...mapMutations("resourcesData", {
      setResourceData: "setData",
    }),
    ...mapActions(["updateRootState"]),
    handleSingleClickOnClear() {
      this.clearAdditionalFilters({ ignore: ["resourceTypes"] });
    },
    handleDblClickOnClear() {},
    createCategoryBtnStyles(categoryFilterData) {
      const currFilters = this.getFilters("category");
      let isCategorySelected = false;
      if (!isEmpty(currFilters)) {
        isCategorySelected = currFilters.includes(categoryFilterData.key);
      }

      // if (!isCategorySelected && !isEmpty(this.selectedMainCateIds)) {
      // isCategorySelected = this.checkIfCategoryIsSelected(
      //   categoryFilterData.key
      // );
      // }
      return {
        "cate-filter-btn": true,
        "cate-selected": isCategorySelected,
      };
    },
    getRootCategory(key) {
      if (this.resourceCategories[key]?.parentCatKey) {
        return this.getRootCategory(this.resourceCategories[key].parentCatKey);
      } else if (this.resourceCategories[key]) {
        return key;
      } else {
        const resource = this.resources.find(
          (resource) => resource.key === key
        );
        return this.getRootCategory(resource.catId);
      }
    },
    getSelectedRootCategories() {
      const res = this.selectedResourceCategories.map((id) =>
        this.getRootCategory(id)
      );

      return res;
    },
    createResourceBtnStyles(resource) {
      const currFilters = this.getFilters(resource.type);
      let isSelected = false;
      if (!isEmpty(currFilters)) {
        isSelected = currFilters.includes(resource.key);
      }

      if (
        this.selectedResourceCategories.length &&
        !checkIfResourceNodeHasNoProject(this.selectedResourceCategories[0])
      ) {
        isSelected =
          this.getSelectedRootCategories().indexOf(resource.key) > -1;
      }

      return {
        [`${resource.cateType}-filter-btn`]: resource.cateType,
        selected: isSelected,
      };
    },
    createFilterBtnStyles(filterData, filterName) {
      const currFilters = this.getFilters(filterName || filterData.type);

      if (!filterName) filterName = filterData.type;

      const priorityBtn = filterName === "priority";
      const isUnSelected = !currFilters.includes(filterData.key);
      return {
        "filter-btn": true,
        "selected-filter":
          !filtersWithCustomStyles.includes(filterName) &&
          filterName !== "priority" &&
          filterName !== "resourceTypes"
            ? currFilters.includes(filterData.key)
            : false,
        "priority-filters": priorityBtn,
        [`${filterData.key}-filter-btn`]: priorityBtn,
        selected:
          filtersWithCustomStyles.includes(filterName) && priorityBtn
            ? currFilters.includes(filterData.key)
            : false,
        [`selected-resource-${filterData.key}-filter`]:
          filtersWithCustomStyles.includes(filterName) &&
          filterName === "resourceTypes"
            ? currFilters.includes(filterData.key)
            : false,
        [LIGHT_GREY_BG_CLASSNAME]: isUnSelected,
      };
    },
    handleFilterSelect(filterData, event) {
      const filterName = filterData.type;

      let currFilters = cloneDeep(this.getFilters(filterName));
      const isAltKeyPressed = event && event.altKey;

      if (
        filterName === "resource" &&
        !isEmpty(this.createdFiltersMap.resources.data) &&
        this.createdFiltersMap.resources.data.length > 1 &&
        isAltKeyPressed
      ) {
        if (currFilters.includes(filterData.key)) {
          currFilters.splice(currFilters.indexOf(filterData.key), 1);
        } else {
          const allResourceCategories = this.createdFiltersMap.resources.data;

          currFilters = allResourceCategories.reduce((accu, cate) => {
            if (cate.key !== filterData.key) {
              accu.push(cate.key);
            }
            return accu;
          }, []);
        }
      } else {
        if (currFilters.includes(filterData.key)) {
          currFilters.splice(currFilters.indexOf(filterData.key), 1);
        } else {
          currFilters.push(filterData.key);
        }
      }

      this.updateRootState({
        currViewData: {},
        itemInspectEnabled: false,
      });

      if (filterName === "resource") {
        this.setResourceData({
          filterResources: currFilters,
        });
        EventEmitter.emit(REFRESH_RESOURCE_ID_FILTER);
        return;
      }

      this.setAdditionalTaskFilters({
        list: [...currFilters],
        key: filterName,
      });
    },
    getFilterIconColor(filerData, filterName) {
      const currFilters = this.getFilters(filterName);
      if (filtersWithCustomStyles.includes(filterName)) {
        return filerData.color;
      }
      return currFilters.includes(filerData.key) ? "#fff" : "#676767";
    },

    getFilters(filterName) {
      if (filterName === "resource" || filterName === "resources")
        return this.filterResources || [];
      return this.additionalTaskFilters[filterName] || [];
    },
  },
  computed: {
    ...mapGetters("task", [
      "tasks",
      "showClearedTasks",
      "additionalTaskFilters",
      "projects",
      "categories",
    ]),
    ...mapGetters("resourcesData", {
      categoriesTree: "categoriesTree",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      filterResources: "filterResources",
      selectedResourceCategories: "selectedCategories",
      resourceCategories: "categories",
      resources: "resources",
    }),
    currentFilters() {
      const localFilters = [];
      const projectId = this.filterOpts?.projectId || "";

      const showOnlyNextActionsTasks =
        this.filterOpts?.showOnlyNextActionsTasks;
      let resourceId = "";

      if (Array.isArray(this.filterOpts.resourceId)) {
        resourceId = [...this.filterOpts.resourceId];
      } else if (!isEmpty(this.filterOpts.resourceId)) {
        resourceId = this.filterOpts.resourceId;
      }

      const showClearedTasks = this.showClearedTasks;
      const mode = this.mode;
      // const currTaskId = this.currTaskId;
      const res = addActivityListFilters({
        currFilters: localFilters,
        projectId,
        resourceId,
        isInResourceMode: mode === "resource",
        showAllTasks: showClearedTasks,
        showOnlyNextActionsTasks: showOnlyNextActionsTasks,
        resourceTypeToIgnore:
          this.filterOpts.resourceTypeToIgnore || this.resourceTypeToIgnore,
        useAreaAndResourceIds: this.filterOpts?.useAreaAndResourceIds,
        isNoProjectNodeSelected: this.filterOpts?.isNoProjectNodeSelected,
        noProjectNodeId: this.filterOpts?.noProjectNodeId,
      });
      return res;
    },
    taskListDataFrame() {
      let currTasks = null;
      if (this.tasks && this.tasks.length) {
        const columns = new Set([
          ...Object.keys(this.tasks[0]),
          "due",
          "project",
        ]);

        currTasks = new DataFrame(this.tasks, [...columns]);
      }
      return currTasks;
    },

    filterList() {
      const resourceView =
        this.showResourceCategories || this.$route.path === "/resources";
      let currTasks = [];
      let filters = {
        category: {
          title: "category",
          data: [],
        },
        resources: {
          title: "resources",
          data: [],
        },
        taskType: {
          title: "taskType",
          data: [],
        },
        priority: {
          title: "priority",
          data: [],
        },

        type: {
          title: "type",
          data: [],
        },
      };
      const usedCategories = [];
      const usedPriority = [];
      const usedTaskType = [];
      const usedTypes = [];
      let allFilteredTasks = [];
      if (this.taskListDataFrame !== null) {
        currTasks = cloneDeep(
          filterTasks(
            this.taskListDataFrame,
            this.currentFilters
          ).toCollection()
        );

        currTasks = currTasks.filter((t) => !t.isReminderTask);

        let sortBy = cloneDeep(sortOpts[9]);

        currTasks = this.processTasksOfNextActionsMode(
          currTasks,
          sortBy,
          this.mode === "resource"
        );

        if (checkIfSelectedTaskFiltersHasCategory(this.additionalTaskFilters)) {
          allFilteredTasks = [...currTasks];

          const categoryFilters = createTaskListFiltersFromFiltersMap({
            category: [...this.additionalTaskFilters.category],
          });

          if (!isEmpty(allFilteredTasks)) {
            const columns = new Set([
              ...Object.keys(allFilteredTasks[0]),
              "due",
              "project",
            ]);

            currTasks = filterTasks(
              new DataFrame(currTasks, [...columns]),
              categoryFilters
            ).toCollection();
          }
        }
      }

      for (const task of currTasks) {
        //get priorities
        if (task.priority && !usedPriority.includes(task.priority)) {
          usedPriority.push(task.priority);
          filters.priority.data.push({
            key: task.priority,
            title: priorityTypesMap[task.priority]?.title,
            icon: priorityTypesMap[task.priority]?.icon,
            iconSize: 13,
            order: priorityTypesMap[task.priority]?.displayOrder,
            color: priorityTypesMap[task.priority]?.color,
            type: "priority",
          });
        }
        //get task types
        if (
          task.taskType &&
          taskTypesList.includes(task.taskType) &&
          !usedTaskType.includes(task.taskType)
        ) {
          usedTaskType.push(task.taskType);
          filters.taskType.data.push({
            key: task.taskType,
            title: taskTypesMap[task.taskType]?.title,
            icon: taskTypesMap[task.taskType]?.icon,
            color: DARK_ICON_COLOR,
            iconSize:
              taskTypesMap[task.taskType].key === "work"
                ? taskTypesMap[task.taskType].iconSize
                : 13,
            type: "taskType",
          });
        }

        if (
          task.type &&
          typeList.includes(task.type) &&
          !usedTypes.includes(task.type)
        ) {
          usedTypes.push(task.type);
          filters.type.data.push({
            key: task.type,
            title: typesMap[task.type]?.title,
            icon: typesMap[task.type]?.icon,
            order: typesMap[task.type]?.filterOrder,
            color: DARK_ICON_COLOR,
            iconSize: typesMap[task.type]?.iconSize,
            type: "type",
          });
        }
      }

      if (!resourceView) {
        const listToUse = !isEmpty(allFilteredTasks)
          ? allFilteredTasks
          : currTasks;
        for (const task of listToUse) {
          if (task.project && !isProjectInbox(task.project)) {
            if (
              this.projects[task.project]?.category &&
              !usedCategories.includes(this.projects[task.project].category)
            ) {
              usedCategories.push(this.projects[task.project].category);
              filters.category.data.push({
                key: this.projects[task.project].category,
                title:
                  this.categories[this.projects[task.project].category]?.title,
                order:
                  this.categories[this.projects[task.project].category]?.order,
                icon: this.categories[this.projects[task.project].category]
                  ?.icon,
                color: DARK_ICON_COLOR,
                type: "category",
                hidden:
                  this.categories[this.projects[task.project].category]
                    ?.hidden || false,
              });
            }
          }
        }

        if (this.selectedCategories?.length) {
          filters.category.data = filters.category.data.filter(
            (category) => this.selectedCategories.indexOf(category.key) > -1
          );
        }
      }

      if (resourceView) {
        const resourceData = [];

        const ignoreKeys = ["root", "no-project"];
        this.categoriesTree.forEach((resource) => {
          if (!ignoreKeys.includes(resource.key)) {
            const resourceFilter = {
              ...resource,
              key: resource.key,
              title: resource.name,
              icon: "",
              iconSize: 13,
              order: resource.order,
              color: "#7c7c7c",
              type: "resource",
              children: null,
              // resources: [...resource.children]
            };
            resourceData.push(resourceFilter);
          }
        });
        if (
          this.selectedResourceCategories.length &&
          !checkIfResourceNodeHasNoProject(this.selectedResourceCategories[0])
        ) {
          const selectedCategories = this.getSelectedRootCategories();
          filters.resources.data = resourceData.filter(
            (resource) => selectedCategories.indexOf(resource.key) > -1
          );
        } else {
          filters.resources.data = resourceData;
        }
      }

      filters.category.data.sort((a, b) => {
        return a.order - b.order;
      });

      filters.priority.data.sort((a, b) => {
        return a.order - b.order;
      });

      filters.taskType.data.sort((a, b) => {
        return taskTypesMap[a.key].order - taskTypesMap[b.key].order;
      });
      filters.type.data.sort((a, b) => a.order - b.order);

      filters.taskType.hash = !isEmpty(filters.taskType.data)
        ? blueimpMD5(JSON.stringify(filters.taskType.data))
        : "taskType";

      filters.priority.hash = !isEmpty(filters.priority.data)
        ? blueimpMD5(JSON.stringify(filters.priority.data))
        : "priority";

      filters.type.hash = !isEmpty(filters.type.data)
        ? blueimpMD5(JSON.stringify(filters.type.data))
        : "types";

      filters.category.hash = !isEmpty(filters.category.data)
        ? blueimpMD5(JSON.stringify(filters.category.data))
        : "categories";

      filters.resources.hash = !isEmpty(filters.resources.data)
        ? blueimpMD5(JSON.stringify(filters.resources.data))
        : "resources";

      return filters;
    },
    filterBarClasses() {
      let classes = {};

      const ignoreKeys = ["root", "no-project"];
      if (this.$route.path === "/resources") {
        const resource = this.categoriesTree.find(
          (r) => !ignoreKeys.includes(r.key)
        );
        if (resource) {
          classes[`${resource.cateType}-filter-bar`] = true;
        }
      }
      return classes;
    },
  },
  watch: {
    filterList: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.createdFiltersMap = n;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.tasks-filters-bar-wrapper {
  gap: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
