import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"25%","value":_vm.isFormOpen,"content-class":"resource-cate-add-form"},on:{"click:outside":_vm.closeDialog,"keydown":_vm.handleKeyDown}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Add "+_vm._s(_vm.itemTypeLabel)+" ")]),_c(VCardText,[_c('div',[_c('div',[_c('ResourceTypeSelector',{ref:"resourceTypeSelector",attrs:{"placeholder":"Select Type","selectorClass":"resource-type-selector resource-form-field","menuProps":{
              'content-class': 'resource-type-selector-menu',
            }},on:{"keydown":_vm.handleSelectorKeyDown},model:{value:(_vm.cateType),callback:function ($$v) {_vm.cateType=$$v},expression:"cateType"}})],1),_c('div',{staticClass:"mt-4"},[_c('ResourceCateSelector',{ref:"resourceCateSelector",staticClass:"resource-type-cate-selector resource-form-field",attrs:{"optsList":_vm.createdOptsList,"cateType":_vm.cateType,"useProvidedOpts":"","useNoDataSlot":false,"placeholder":_vm.selectPlaceholder,"menuProps":{
              'content-class': 'resource-type-selector-menu',
            }},on:{"keydown":_vm.handleSelectorKeyDown},model:{value:(_vm.parentCatKey),callback:function ($$v) {_vm.parentCatKey=$$v},expression:"parentCatKey"}})],1)])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","text":"","color":"error"},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel")]),_c(VBtn,{staticClass:"save-confirm-btn",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.submitData}},[_vm._v(" Create ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }