<template>
  <div class="d-flex flex-column flex-nowrap fill-height resources-table">
    <div class="flex-grow-1 overflow-hidden">
      <DataTable
        :list="resources"
        :columnsList="columns"
        ref="dataGrid"
        :options="options"
        :whiteListBtns="[
          'resource-cate-add-form',
          'data-table-popup-menu',
          'resource-type-selector',
          'resource-type-cate-selector',
          'resource-save-btn',
          'resource-error-pop-up',
          'resource-area-error-pop-up',
          'v-overlay',
        ]"
        :renderGroupCaption="createGroupItemsCaptions"
        :collapsedGroupsList="collapsedGroupsList"
        @edit:enabled="handleEditEnabled"
        @row:added="handleRowAdd"
        @row:updated="handleRowUpdated"
        @rows:selected="handleRowsSelected"
        @filter="handleFilterSuccess"
        @list:processed="handleListProcessed"
        @group:toggled="handleGroupToggle"
        @key-pressed:delete="handleDeleteCall"
        :itemSize="36"
      />
    </div>
  </div>
</template>
<script>
import DataTable from "@/core/components/DataTable";
import ResourceTypeSelectorCell from "./components/ResourceTypeSelectorCell.vue";
import ResourceTypeEditorCell from "./components/ResourceTypeEditorCell.vue";
import ResourceCateNameCell from "./components/ResourceCateNameCell.vue";
import ResourceCateEditorCell from "./components/ResourceCateEditorCell.vue";
import ResourcesActionCell from "./components/ResourceActions.vue";
import ResourceSaveBtnCell from "./components/ResourceSaveBtnCell.vue";
import TitleViewerCell from "@/components/GridComponents/TitleViewerCell.vue";
import ResourceTagEditorCell from "./components/ResourceTagEditorCell.vue";
import EventEmitter from "@/helpers/eventEmitter";
import RowHandler from "@/components/GridComponents/RowHandlerCell.vue";
import { navViewFiltersConfig } from "@/variables/viewConfigs";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import {
  groupOpts,
  RESOURCE_PROPS_FOR_FORM,
  sortOpts,
} from "@/variables/resources";
import { mapActions, mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {
  addOrUpdateOrRemoveResourcesInLocalList,
  createResourceCateFilters,
  createResourceGroupCaptions,
  createResourcesSearchFilter,
  createResourceTypeOptFilter,
  createUpdateAndEditedResourceDataByCell,
  getUserResources,
  processResources,
} from "@/helpers/resources";
import {
  ADD_DATA_ITEM_EVENT,
  ADD_REMOVE_UPDATE_DATA_ITEM_EVENT,
  CLOSE_INLINE_ADD,
  REFRESH_DATA_LIST_EVENT,
} from "@/variables/events";
import DateCell from "@/components/GridComponents/DateCell.vue";
import { checkIfRowDataHasChanged } from "@/helpers/common";
import { convertValueToArray } from "@/utils/array";
import ResourceGroupHeaderCellVue from "./components/ResourceGroupHeaderCell.vue";
import { checkIfResourceNodeHasNoProject } from "@/helpers/resourceCategoriesHelpers";

export default {
  mixins: [userDetailsMixin, resourcesHelpersMixin],
  components: {
    DataTable,
  },
  data(vm) {
    return {
      options: {},
      columns: [
        {
          frozen: true,
          width: 30,
          minWidth: 30,
          cssClass: "intend-cell",
          field: "handler",
          sortable: false,
        },
        {
          rowHandle: true,
          frozen: true,
          width: 30,
          minWidth: 30,
          cssClass: "row-handler-cell",
          CellComponent: RowHandler,
          field: "row-handler",
          sortable: false,
        },
        {
          title: "Type",
          field: "type",
          resizable: false,
          editable: true,
          vertAlign: "middle",
          CellEditor: ResourceTypeEditorCell,
          CellComponent: ResourceTypeSelectorCell,
          cellEdited: vm.cellEdited,
          onDataUpdate: vm.onDataUpdate,
          width: 45,
        },
        {
          field: "actions",
          width: 50,
          resizable: false,
          sortable: false,
          editable: false,
          visible: true,
          CellComponent: ResourcesActionCell,
        },

        {
          title: "Tag",
          field: "tag",
          primary: true,
          resizable: false,
          editable: true,
          vertAlign: "middle",
          CellEditor: ResourceTagEditorCell,
          cellEdited: vm.cellEdited,
          onDataUpdate: vm.onDataUpdate,
          width: 180,
        },
        {
          title: "Name",
          field: "title",
          resizable: false,
          editable: true,
          // editor: "input",
          vertAlign: "middle",
          // CellEditor: TitleEditorVue,
          CellEditor: TitleViewerCell,
          CellComponent: TitleViewerCell,
          cellEdited: vm.cellEdited,
          onDataUpdate: vm.onDataUpdate,
          width: "stretch",
          minWidth: 300,
          expand: true,
          extraData: {
            placeholder: "Enter Name here",
            enableMentions: false,
            previousCell: "tag",
          },
        },
        {
          title: "Group",
          field: "catId",
          HeaderComponent: ResourceGroupHeaderCellVue,
          resizable: false,
          editable: true,
          width: 138,
          CellComponent: ResourceCateNameCell,
          CellEditor: ResourceCateEditorCell,
          vertAlign: "middle",
          cellEdited: vm.cellEdited,
          tristateSort: true,
          startSortDir: "asc",
          onDataUpdate: vm.onDataUpdate,
        },
        {
          title: "",
          field: "hashedParentCatePath",
          resizable: false,
          visible: false,
        },
        {
          title: "Modified",
          field: "modifiedAsDate",
          CellComponent: DateCell,
          resizable: false,
          editable: false,
          visible: true,
          width: 135,
        },
        {
          title: "Created",
          field: "createdAsDate",
          resizable: false,
          // editable: true,
          CellComponent: DateCell,
          CellEditor: ResourceSaveBtnCell,
          visible: true,
          width: 135,
        },
        {
          field: "modified",
          visible: false,
        },
        {
          field: "created",
          visible: false,
        },
      ],
      createdFilters: [],
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    ...mapActions("resourcesData", [
      "updateResourcesState",
      "setSelectedResources",
    ]),
    handleDeleteCall() {
      if (isEmpty(this.selectedResources)) return;
      const selectedResources = cloneDeep(this.selectedResources);
      this.$refs.dataGrid.deselectPreviousRows();
      this.removeResourcesFromLocalAndDb(selectedResources);
    },
    isGridInEditMode() {
      return this.$refs.dataGrid.isEditingActive();
    },
    handleEditEnabled(_, rowData) {
      this.currEditedRow = rowData;
    },
    async scrollToSelectedResourceOrEditedResource() {
      let rowKeyToUse;
      if (!isEmpty(this.currEditedRow)) {
        rowKeyToUse = this.currEditedRow.key;
      }
      if (
        !rowKeyToUse &&
        !isEmpty(this.selectedResources) &&
        this.selectedResources.length === 1
      ) {
        rowKeyToUse = this.selectedResources[0].key;
      }

      if (rowKeyToUse) {
        const storedRow = this.filteredResources.find(
          (t) => t.key === rowKeyToUse
        );

        if (!isEmpty(storedRow)) {
          const currGroupMode = this.$refs.dataGrid.getGroupBy();
          const currGroupValue = storedRow[currGroupMode];
          this.$refs.dataGrid.toggleGroup(
            {
              key: currGroupMode,
              value: currGroupValue,
            },
            "remove"
          );
          await this.$nextTick();
          this.$refs.dataGrid.checkAndScrollToRow(storedRow.key);
        }
      }
    },
    updateCollapsedGroupListInDb(list) {
      getUserResources().set(list, "collapsedGroups");
    },
    handleGroupToggle(groupData, mode) {
      const currCollapsedGroups = cloneDeep(this.collapsedGroupsList);
      const storedIndex = currCollapsedGroups.findIndex((g) =>
        isEqual(g, groupData)
      );

      if (mode) {
        if (mode === "remove") {
          if (storedIndex >= 0) {
            currCollapsedGroups.splice(storedIndex, 1);
          }
        }

        if (mode === "add") {
          if (storedIndex === -1) {
            currCollapsedGroups.push({ ...groupData });
          }
        }
      } else {
        if (storedIndex >= 0) {
          currCollapsedGroups.splice(storedIndex, 1);
        } else {
          currCollapsedGroups.push({ ...groupData });
        }
      }
      this.updateCollapsedGroupListInDb(currCollapsedGroups);
    },
    closeInlineAdd() {
      this.$refs.dataGrid.cancelAddRow();
    },
    addNewResource(data = {}) {
      if (!data.type) {
        data.type = "thing";
      }
      this.$refs.dataGrid.addNewRow({ ...data });
    },
    refreshList() {
      const createdFilters = this.createCurrNavFilters();
      const currFilters = this.createdFilters;
      if (!isEqual(currFilters, createdFilters)) {
        this.updateFilters();
      } else {
        this.$refs.dataGrid.setup();
      }
    },
    async handleAddOrRemoveOrUpdateResources(data) {
      addOrUpdateOrRemoveResourcesInLocalList(data, false);
      await this.$nextTick();
      this.refreshList();
    },
    async handleResourcesRefresh(reprocessList) {
      if (reprocessList) {
        const currCategories = this.categories;
        this.updateResourcesState({
          processedResources: processResources(
            Object.values(this.rawResourcesMap || {}),
            currCategories
          ),
        });
      }
      await this.$nextTick();
      this.refreshList();
    },
    init() {
      this.setListeners();
      this.createTable();
    },
    setListeners() {
      EventEmitter.on(CLOSE_INLINE_ADD, this.closeInlineAdd);
      EventEmitter.on(ADD_DATA_ITEM_EVENT, this.addNewResource);
      EventEmitter.on(REFRESH_DATA_LIST_EVENT, this.handleResourcesRefresh);
      EventEmitter.on(
        ADD_REMOVE_UPDATE_DATA_ITEM_EVENT,
        this.handleAddOrRemoveOrUpdateResources
      );
    },
    removeListeners() {
      EventEmitter.off(CLOSE_INLINE_ADD, this.closeInlineAdd);
      EventEmitter.off(ADD_DATA_ITEM_EVENT, this.addNewResource);
      EventEmitter.off(REFRESH_DATA_LIST_EVENT, this.handleResourcesRefresh);
      EventEmitter.off(
        ADD_REMOVE_UPDATE_DATA_ITEM_EVENT,
        this.handleAddOrRemoveOrUpdateResources
      );
    },
    addCateFilters(list, currFilters) {
      let filters = cloneDeep(currFilters);
      const createdFilters = [
        ...createResourceCateFilters(list, this.categoriesTree),
      ];
      filters[0] = [...filters, ...createdFilters];
      return filters;
    },
    async updateRowData(rowUpdates, refreshList = true, setForScroll) {
      if (!Array.isArray(rowUpdates)) rowUpdates = [rowUpdates];

      addOrUpdateOrRemoveResourcesInLocalList(
        {
          resourcesToUpdate: rowUpdates,
        },
        false
      );

      if (setForScroll) {
        this.setResourceIdForScroll(rowUpdates[0].key);
      }
      await this.$nextTick();
      if (refreshList) {
        this.refreshList();
      }
    },
    cellEdited(cell) {
      const fieldName = cell.getField();
      const oldValue = cell.getOldValue();
      const row = cell.getRow();
      const currRowData = row.getData();
      const rowId = row.getIndex();
      const currValue = cell.getValue();
      if (oldValue !== currValue) {
        const { updates, editedData } = createUpdateAndEditedResourceDataByCell(
          {
            fieldName,
            oldValue,
            rowId,
            currValue,
            currRowData,
          }
        );

        this.addOrRemoveOrUpdateResourcesInList({
          resourcesToUpdate: [{ updates, editedData, key: rowId }],
        });
      }
    },
    onDataUpdate(rowId, fieldName, value, cell) {
      if (!rowId) return;
      const row = cell.getRow();
      const currRowData = row.getData();
      const { updates, editedData } = createUpdateAndEditedResourceDataByCell({
        fieldName,
        currValue: value,
        rowId,
        oldValue: currRowData[fieldName],
        currRowData,
      });

      this.addOrRemoveOrUpdateResourcesInList({
        resourcesToUpdate: [{ updates, editedData, key: rowId }],
      });

      this.updateRowData(
        [
          {
            key: rowId,
            updates: updates,
          },
        ],
        undefined,
        true
      );
    },
    addMissingValuesInSortOpts(sortOpt) {
      const sortBy = sortOpt.map((sort) => ({
        ...sort,
        missingValue: "last",
      }));
      return sortBy;
    },
    async createTable() {
      const { sortOpt, groupOpt } = this.groupAndSortResourcesConfig;
      const addedSortOpts = this.addMissingValuesInSortOpts(sortOpt);
      await this.$nextTick();
      const filters = cloneDeep(this.createCurrNavFilters());
      this.createdFilters = [...filters];
      this.options = {
        ...this.options,
        sortBy: addedSortOpts,
        groupBy: groupOpt || "",
      };
    },
    addNoProjectsTaskFilter(currFilters) {
      return currFilters;
    },
    createCurrNavFilters() {
      let currFilters = cloneDeep(this.navFilter);
      const selectedCategories = this.selectedCategories;
      const selectedResourceTypeOpt = this.selectedResourceTypeOpt;
      let searchQuery = this.searchQuery;
      searchQuery = searchQuery && searchQuery.trim();

      if (!isEmpty(selectedCategories)) {
        if (checkIfResourceNodeHasNoProject(selectedCategories[0])) {
          currFilters = this.addNoProjectsTaskFilter(
            currFilters,
            selectedCategories[0]
          );
        } else {
          currFilters = this.addCateFilters(selectedCategories, currFilters);
        }
      }
      if (selectedResourceTypeOpt && selectedResourceTypeOpt !== "all") {
        currFilters = createResourceTypeOptFilter(
          selectedResourceTypeOpt,
          currFilters
        );
      }
      if (searchQuery && searchQuery !== "*") {
        currFilters.push([...createResourcesSearchFilter(searchQuery)]);
      }

      return currFilters;
    },
    createGroupItemsCaptions(data, group) {
      return createResourceGroupCaptions(data, group);
    },
    async handleRowAdd(data) {
      if (!Array.isArray(data)) data = [data];
      addOrUpdateOrRemoveResourcesInLocalList(
        {
          resourcesToAdd: data,
        },
        false
      );

      this.setResourceIdForScroll(data[0].key);
      await this.$nextTick();
      this.refreshList();
    },
    setResourceIdForScroll(resourceIds) {
      this.$refs.dataGrid.setRowIdsForScroll(convertValueToArray(resourceIds));
    },
    async focusOnSelectedItem(selectedItemKey, select, noScroll, noWait) {
      await this.$nextTick();
      if (selectedItemKey) {
        this.$refs.dataGrid.focusOnSelectedRow({
          selectedItemKey,
          select,
          noScroll,
          noWait,
        });
      }
    },
    handleRowUpdated({ rowId, updatedRowData: editedRowData }) {
      if (!rowId || isEmpty(editedRowData)) return;
      const currResourceData = this.resources.find((r) => r.key === rowId);

      const isDataChanged = checkIfRowDataHasChanged(
        editedRowData,
        currResourceData,
        RESOURCE_PROPS_FOR_FORM
      );

      if (!isDataChanged) {
        return;
      }
      this.updateRowData([
        {
          key: rowId,
          updates: editedRowData,
        },
      ]);
    },
    handleRowsSelected(rows = []) {
      this.setSelectedResources(rows);
    },
    handleFilterSuccess({ count }) {
      this.updateResourcesState({
        resourcesCount: count,
      });
    },
    handleListProcessed(list = []) {
      this.updateResourcesState({
        filteredResources: list,
      });
    },
    updateFilters() {
      const filters = this.createCurrNavFilters();
      this.createdFilters = filters;
    },
    async setUpdatedFiltersInGrid() {
      const filters = cloneDeep(this.createdFilters);
      this.options = {
        ...this.options,
        filters: filters,
      };
      await this.$nextTick();
      if (this.$refs.dataGrid) {
        const notes = this.$refs.dataGrid.getSelectedRows();
        this.setSelectedResources(notes);
      }
    },

    checkCategoriesAndSetFilters(newMap, oldMap) {
      if (!isEmpty(newMap) && !isEmpty(oldMap)) {
        const newKeysList = Object.keys(newMap);
        const oldKeysList = Object.keys(oldMap);
        if (newKeysList.length !== oldKeysList.length) {
          this.updateFilters();
        }
      }
    },
  },
  computed: {
    ...mapGetters("resourcesData", {
      resources: "resources",
      searchQuery: "query",
      selectedCategories: "selectedCategories",
      categoriesTree: "categoriesTree",
      categories: "categories",
      rawResourcesMap: "rawResourcesMap",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      collapsedGroupsList: "collapsedGroupsList",
      selectedResources: "selectedResources",
      filteredResources: "filteredResources",
    }),
    groupAndSortResourcesConfig() {
      const sortOpt = sortOpts[0];
      const groupOpt = groupOpts[0];
      return {
        sortOpt,
        groupOpt,
      };
    },
    navFilter() {
      return navViewFiltersConfig[6].filters;
    },
  },
  watch: {
    selectedCategories(n, o) {
      if (!isEqual(n, o)) {
        this.updateFilters();
      }
    },
    createdFilters: {
      handler(newCreatedFilters, oldCreatedFilters) {
        if (!isEqual(newCreatedFilters, oldCreatedFilters)) {
          this.setUpdatedFiltersInGrid();
        }
      },
      deep: true,
    },
    searchQuery() {
      this.updateFilters();
    },
    selectedResourceTypeOpt() {
      this.updateFilters();
    },
  },
};
</script>
<style lang="scss">
.datagrid-row[role="row"]:hover .edit-btn {
  display: inline-block;
}

.datagrid-header-cell.type-cell {
  border: 0 !important;
}

.notes-table .datagrid-cell.title-cell > div {
  line-height: 1.55 !important;
  // padding-top: 7px;
  // padding-bottom: 7px;
  // white-space: pre-wrap;
  overflow: hidden;
  height: 33px;
  // height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  // margin-top: 7px;
  // margin-bottom: 7px;
}
.resources-table
  .datagrid-row.row-selected
  .datagrid-cell.expand.title-cell
  > div {
  line-height: 1.55 !important;
  white-space: pre-line !important;
  max-height: max-content !important;
  height: auto !important;
  display: block;
  // padding-top: 0px !important;
  // padding-bottom: 0px !important;
  // padding-top: 7px !important;
  // padding-bottom: 7px !important;
}
.resources-table
  .datagrid-row.row-selected
  .datagrid-cell.expand.title-cell
  > div {
  line-height: 1.55 !important;
  white-space: pre-line !important;
  max-height: max-content !important;
  height: auto !important;
  display: block;
  // padding-top: 0px !important;
  // padding-bottom: 0px !important;
  // padding-top: 7px !important;
  // padding-bottom: 7px !important;
}

.resources-table .datagrid-row[role="row"].row-selected {
  background: var(--selection-color) !important;

  // .datagrid-cell {
  //   background: none;
  // }
  // .intend-cell {
  //   background: #fff;
  // }
  .datagrid-cell:not(.intend-cell) {
    background: none;
    // border-top: 1px solid var(--primary-color) !important;
    // border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  // .row-handler-cell {
  //   // border-left: 1px solid var(--primary-color) !important;
  // }
}

.resources-table .datagrid-row[role="row"]:hover:not(.row-editing) {
  // background: none !important;

  // .datagrid-cell {
  //   background: none !important;
  // }

  // .datagrid-group {
  //   background: none !important;
  // }
  .intend-cell {
    background: #fff !important;
  }
  .datagrid-cell:not(.intend-cell) {
    // background: none !important;
    border-top: 1px solid var(--primary-color) !important;
    border-bottom: 1px solid var(--primary-color) !important;
  }
  .intend-cell {
    background: #fff;
  }
  .row-handler-cell {
    border-left: 1px solid var(--primary-color) !important;
  }
  // .priority-cell {
  //   width: 70px !important;
  // }
}
</style>
