<template>
  <v-select
    :items="priorityTypeOpts"
    ref="select"
    item-value="key"
    v-model="selected"
    class="priority-type-input"
    :placeholder="placeholder"
    @keydown="handleKeyDown"
    hide-details
  >
    <template v-slot:selection="{ item }">
      <span class="selection-container">
        <span
          class="priority-color"
          :style="{ backgroundColor: item.color }"
        ></span>
        <span> {{ item.title }}</span>
      </span>
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-icon>
          <v-icon :color="item.color" class="mr-3"> mdi-circle </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import { priorityTypeOpts } from "@/data/priorityTypes";

export default {
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Select priority",
    },
  },
  data: function () {
    return {
      selected: this.value,
    };
  },
  methods: {
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    openInput() {
      this.$refs.select.onClick(this.$refs.select);
      this.$refs.select.focus();
    },
    blurInput() {
      this.$refs.select.blur();
    },
  },
  watch: {
    selected(n) {
      this.$emit("input", n);
    },
    value(n) {
      this.selected = n;
    },
  },
  created() {
    this.priorityTypeOpts = priorityTypeOpts;
  },
};
</script>

<style scoped>
.priority-type-input {
  height: 33px;
  margin-top: 0;
  font-size: 13px;
  padding-top: 0;
  margin-bottom: 2px;
}
.priority-type-input >>> .v-input__control {
  height: 33px;
}
.priority-type-input >>> .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}
.priority-color {
  width: 5px;
  margin-right: 4px;
}

.selection-container {
  display: inline-flex;
}

.v-select >>> .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

.v-select >>> .v-select__slot {
  height: 33px;
}

.v-select >>> .v-select__selections {
  height: 33px;
}
</style>
