// import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";

export default {
  updateState(state, payload) {
    Object.assign(state, payload);
  },
  setNotesTree(state, payload) {
    state.notesTreeStructure = cloneDeep(payload);
  },
  setFoldersFilter(state, payload) {
    state.selectedFolders = cloneDeep(payload);
  },
  setSearchQuery(state, payload) {
    state.query = payload;
  },
  setSelectedNotes(state, payload) {
    state.selectedNotes = cloneDeep(payload);
  },
  setNotesDirChildTreeMap(state, payload) {
    state.dirChildrenMap = payload || {};
  },
  updateNotesDirChildTreeMap(state, payload) {
    state.dirChildrenMap = payload || {};
  },
  setNotesInList(state, payload) {
    state.notes = payload;
  },
};
