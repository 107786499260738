<template>
  <RichTextEditor
    ref="textEditor"
    isReadOnly
    :showToolbar="false"
    noBorders
    noPadding
  />
</template>
<script>
import RichTextEditor from "@/components/RichTextEditor";
export default {
  components: { RichTextEditor },
  props: {
    text: {
      type: String,
    },
  },
  mounted() {
    this.$refs.textEditor.setHTMLData(this.text, false, true);
  },
  watch: {
    text(n) {
      this.$refs.textEditor.setHTMLData(n, false, true);
    },
  },
};
</script>
