<template>
  <div class="actions-cell-wrapper">
    <!-- <ContingentToggleBtn
      v-if="showContingentBtn"
      :isEnabled="data.isContingent"
      iconName="$ctiCalendarArrow"
      :iconSize="19"
      tooltipText="Push to Tomorrow"
      @click="toggleContingent"
    /> -->
    <v-tooltip bottom v-if="!showSnoozeBtn">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
          v-on:click="handleCompleteBtnClick"
          tabindex="-1"
          class="task-action-btn"
          :disabled="isCellEditDisabled"
        >
          <v-icon
            :size="completeBtnProps.size"
            :class="completeBtnProps.btnClasses"
            :color="btnColor"
          >
            {{ completeBtnProps.btnIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ completeBtnProps.btnText }}</span>
    </v-tooltip>

    <template v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            small
            @click="toggleReminder"
            tabindex="-1"
          >
            <v-icon :color="pauseBtnIconData.color" size="17">{{
              pauseBtnIconData.icon
            }}</v-icon>
          </v-btn>
        </template>
        <span>
          Snooze this
          <span class="reminder-date-text">{{
            pauseBtnIconData.formattedDate
          }}</span>
          task until tomorrow
        </span>
      </v-tooltip>
    </template>

    <!-- <template v-if="!isDisabled"> -->
    <EditBtn
      v-if="showEditBtn"
      :cssClass="editBtnClassNames"
      @click="editTask"
      :btnText="editPenLabel"
      :btnIcon="editBtnIcon"
      :btnIconSize="editBtnIconSize"
      :btnIconColor="greyIconColor"
    />
    <!-- </template> -->
  </div>
</template>
<script>
import userDetailsMixin from "@/mixins/userDetailsMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { columnPropsMixin } from "@/core/components/DataTable";
import { mapActions, mapGetters } from "vuex";
import {
  areTaskRecurrenceRulesValid,
  checkIfTaskIsContinued,
  checkIfTaskIsRecurring,
  checkIfTaskIsRepeatIn,
  createDateTimeFromDue,
  createRecurrenceOptsValuesFromTaskRecurrenceRules,
  createRepeatInfoLabelFromTaskData,
  checkIfTaskGroupCaptionHasDate,
  createReviewGroupLabel,
  extractDateFromTaskDateGroupStr,
  addOrRemoveOrUpdateTasksInLocalTasksList,
} from "@/helpers/tasks";
import createGroupedTasksCaptions from "../helpers/createGroupedTasksCaptions";
import EditBtn from "@/components/GridComponents/EditBtn";
import ContingentToggleBtn from "@/components/ContingentToggleBtn.vue";
import {
  // DARK_GREY_ICON_COLOR,
  NEW_DARK_ICON_COLOR,
  PRIMARY_COLOR,
} from "@/variables/colors";

import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { formatDate, parseDate } from "@/helpers/dates";
import { DATE_FORMAT_IN_USE, INTERNAL_DATE_FORMAT } from "@/variables/dates";

import { emptyDescrTypes as emptyTaskDescrTypes } from "@/variables/common";
import parseISO from "date-fns/parseISO";
import addDays from "date-fns/addDays/index";
export default {
  mixins: [userDetailsMixin, columnPropsMixin, tasksHelpersMixin],
  components: {
    EditBtn,
    ContingentToggleBtn,
  },

  methods: {
    toggleContingent(e) {
      e.stopPropagation();
      let currDate = new Date();

      this.handleDataUpdate(addDays(currDate, 1), "dueAsDate");
    },
    handleCompleteBtnClick(e) {
      e.stopPropagation();

      if (this.isContingentTask) return;

      // if (this.isContingentTask) {
      //   this.$swal({
      //     titleText: "Oops!",
      //     html: "Contingents must be scheduled before they can be completed",
      //     icon: "error",
      //     confirmButtonColor: PRIMARY_COLOR,
      //     iconColor: PRIMARY_COLOR,
      //   });
      //   return;
      // }

      this.handleDataUpdate(!this.data.completed, "completed");
    },
    editTask(e) {
      e.stopPropagation();
      let dataToSet = { ...this.data };

      if (dataToSet.isReminderTask && dataToSet.reminderLinkedTo) {
        const taskData = this.tasks.find(
          (t) => t.key === dataToSet.reminderLinkedTo
        );

        if (!isEmpty(taskData)) {
          dataToSet = { ...taskData };
        }
      }
      const isRecurringTask = checkIfTaskIsRecurring(dataToSet);
      let dataIsValid = false;
      let showTaskInForm = false;
      if (isRecurringTask) {
        let taskDataForRulesParsing = {};
        const isVirtual = dataToSet.isVirtual;
        const linkedTo = dataToSet.linkedTo;
        const tasksRulesAreValid = areTaskRecurrenceRulesValid(
          dataToSet.recurrence
        );
        if (isVirtual) {
          taskDataForRulesParsing = this.rawTasksMap[dataToSet.linkedTo];

          showTaskInForm = !dataToSet.isFirstTask && !!dataToSet.isCalendarOnly;
        } else if (linkedTo || tasksRulesAreValid) {
          const keyToUse = linkedTo || dataToSet.key;
          if (this.rawTasksMap[keyToUse]) {
            taskDataForRulesParsing = this.rawTasksMap[keyToUse];
          }
        }

        if (!isEmpty(taskDataForRulesParsing) && taskDataForRulesParsing.key) {
          dataIsValid = true;
          dataToSet = {
            ...dataToSet,
            ...createRecurrenceOptsValuesFromTaskRecurrenceRules({
              ...taskDataForRulesParsing,
              dueAsDate: createDateTimeFromDue(
                taskDataForRulesParsing.due,
                taskDataForRulesParsing.time
              ),
            }),
          };
        }
      } else {
        if (
          this.rawTasksMap[dataToSet.key] &&
          this.rawTasksMap[dataToSet.key].key
        ) {
          dataIsValid = true;
        }
      }

      if (!dataIsValid) {
        this.showToast("Invalid task data");
        return;
      }

      if (showTaskInForm) {
        addOrRemoveOrUpdateTasksInLocalTasksList(
          {
            tasksToUpdate: [
              {
                key: dataToSet.key,
                updates: { showInForm: true },
              },
            ],
            doNotProcessTaskForUpdate: true,
          },
          false
        );
      }

      this.showEditTaskDialog({
        taskData: dataToSet,
        selectedTasks: [],
      });
    },
    deleteTask(e) {
      e.stopPropagation();

      this.removeTasksFromTaskList([this.data]);
    },
    createGroupCaption(groupByKey, data) {
      if (!groupByKey) {
        return "";
      }

      const groupCaption = createGroupedTasksCaptions(
        [data],
        groupByKey,
        (groupKey) => {
          return createReviewGroupLabel(
            groupKey,
            this.projects,
            this.categories
          );
        }
      );
      return groupCaption || "";
    },
    extractDateFromGroupCaption() {
      const groupByKey = this.cell.api.getGroupBy();
      const groupByCaption = this.createGroupCaption(
        groupByKey,
        this.data
      ).trim();
      const isDateCaption = checkIfTaskGroupCaptionHasDate(groupByCaption);
      let currDate = new Date();

      if (isDateCaption) {
        const extractedDate = extractDateFromTaskDateGroupStr(groupByCaption);
        if (extractedDate) {
          currDate = parseDate(extractedDate, DATE_FORMAT_IN_USE);
        }
      }

      return currDate;
    },
    toggleReminder(e) {
      e.stopPropagation();
      const pausedReminderDays = cloneDeep(this.data.pausedReminderDays) || [];

      const extractedDate = this.extractDateFromGroupCaption();

      const formattedDateToUse = formatDate(
        extractedDate,
        INTERNAL_DATE_FORMAT
      );
      if (!isEmpty(pausedReminderDays)) {
        const storedIndex = pausedReminderDays.indexOf(formattedDateToUse);

        if (storedIndex >= 0) {
          pausedReminderDays.splice(storedIndex, 1);
        } else {
          pausedReminderDays.push(formattedDateToUse);
        }
      } else {
        pausedReminderDays.push(formattedDateToUse);
      }

      this.handleDataUpdate(pausedReminderDays, "pausedReminderDays");
    },
    ...mapActions("editForm", ["showEditTaskDialog"]),
    ...mapActions("toast", ["showToast"]),
  },
  computed: {
    ...mapGetters("user", ["userInfo"]),
    ...mapGetters("task", [
      "rawTasksMap",
      "objectivesMap",
      "currNav",
      "isGlobalViewEnabled",
      "tasks",
    ]),
    pauseBtnIconData() {
      return {
        color: PRIMARY_COLOR,
        icon: "$ctiBellFilled",
        formattedDate:
          this.data.due !== "none"
            ? formatDate(parseISO(this.data.due), DATE_FORMAT_IN_USE)
            : undefined,
      };
    },

    btnColor() {
      return this.isContingentTask && !this.isInCalModes
        ? PRIMARY_COLOR
        : this.greyIconColor;
    },
    greyIconColor() {
      return NEW_DARK_ICON_COLOR;
    },
    editPenLabel() {
      return "Edit Task";
    },
    completeBtnProps() {
      const res = {
        btnText: "",
        btnIcon: "",
        size: 19,
        btnClasses: {
          "main-icon": true,
        },
      };
      if (this.data.completed) {
        res.btnText = "Un-Complete Task";
        res.btnIcon = "$ctiComplete";
        res.size = 22;
      } else {
        const isTaskRecurring = checkIfTaskIsRecurring(this.data);
        const isTaskContinued =
          checkIfTaskIsContinued(this.data) && !isTaskRecurring;
        const isTaskRepeatIn = checkIfTaskIsRepeatIn(this.data);
        const { showTxt: showRepeatLabel, txt: repeatLabel } =
          createRepeatInfoLabelFromTaskData(
            JSON.parse(JSON.stringify(this.data || {})),
            true
          );
        let btnText = "Complete Task";
        let btnIcon = "$ctiCircleOutline";
        if (isTaskRepeatIn) {
          btnIcon = "$ctiRepeat";
          res.size = 18;
          res.btnClasses["repeat-action-btn"] = true;
        } else if (isTaskRecurring) {
          btnIcon = "$ctiRepeat";
          res.size = 18;
          res.btnClasses["repeat-action-btn"] = true;
        } else if (isTaskContinued) {
          btnText = "Continue Task";
          btnIcon = "$ctiLogout";
          res.btnClasses["continue-action-btn"] = true;
          // res.size = 20;
        }

        res.btnText = showRepeatLabel ? repeatLabel : btnText;
        res.btnIcon = btnIcon;
      }

      if (this.isContingentTask) {
        res.btnText =
          "Contingents must be scheduled before they can be completed";
      }

      return res;
    },

    isDisabled() {
      return this.checkIfCellIsDisabled(undefined, this.data);
    },
    isReminderTask() {
      return !!this.data.isReminderTask;
    },
    isInTodayNav() {
      return this.currNav === 3 && !this.isGlobalViewEnabled;
    },
    isInCalNav() {
      return this.currNav === 0 && !this.isGlobalViewEnabled;
    },
    showSnoozeBtn() {
      return (this.isInTodayNav || this.isInCalNav) && this.isReminderTask;
    },
    editBtnClassNames() {
      const descrData = this.data.description;
      return emptyTaskDescrTypes.includes(descrData)
        ? "edit-item-btn"
        : "edit-btn-always-visible";
    },

    showEditBtn() {
      return !this.extraData?.doNotShowEditBtn;
    },
    editBtnIcon() {
      const descrData = this.data.description;
      return !emptyTaskDescrTypes.includes(descrData) && !this.rowHovered
        ? "$ctiNoteIcon"
        : "mdi-pencil";
    },
    editBtnIconSize() {
      const descrData = this.data.description;
      return !emptyTaskDescrTypes.includes(descrData) && !this.rowHovered
        ? 16
        : 18;
    },

    // showContingentBtn() {
    //   return (
    //     this.extraData?.showContingentBtn &&
    //     (this.isInTodayNav || this.isInCalNav) &&
    //     !!this.data.showContingentInToday
    //   );
    // },
    isContingentTask() {
      return (
        this.extraData?.showContingentBtn && !!this.data.showContingentInToday
      );
    },
  },
};
</script>
<style scoped>
.hover-icon {
  display: none;
}
.task-action-btn {
  padding-bottom: 2px;
}
.task-action-btn >>> svg {
  fill: #000;
}
/* .task-action-btn:hover .hover-icon {
  display: inline-flex;
}
.task-action-btn:hover .main-icon {
  display: none;
} */

.edit-item-btn {
  display: none;
  padding-bottom: 2px;
}

.actions-cell-wrapper >>> .edit-btn-always-visible {
  display: inline-block;
  padding-bottom: 2px;
}

.main-icon {
  width: 22px !important;
  height: 22px !important;
}
.main-icon.continue-action-btn >>> svg {
  height: 20px !important;
}

.main-icon.repeat-action-btn >>> svg {
  width: 20px !important;
}
/* .actions-cell-wrapper {
  margin-left: -5px;
} */
</style>
