<template>
  <div
    class="action-bar-wrapper"
    :class="barVisibleClass"
    @click="stopPropagation"
  >
    <div class="action-bar" v-if="isVisible">
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="auto">
          <div class="tasks-count-container">
            <!-- <div class="dot"></div> -->
            <span class="tasks-count"> {{ tasksCount }} tasks selected </span>
          </div>
        </v-col>

        <v-col cols="8">
          <v-row class="justify-center" align="center">
            <!-- <v-col cols="auto" @keydown.stop>
              <priority-selector :value="priority" @input="onPriorityChange" />
            </v-col> -->
            <v-col class="smoke-bg">
              <v-row class="justify-center" align="center">
                <v-col class="small-pad" @keydown.stop>
                  <ItemTypeSelectorInput
                    :value="taskType"
                    @input="onTaskTypeChange"
                    type="task"
                    :hideNoteOpt="true"
                    :extraMenuProps="selectMenuProps"
                    placeholder="Select Task Type"
                  />
                </v-col>
                <v-col class="small-pad" @keydown.stop>
                  <project-selector :value="project" @input="onProjectChange" />
                </v-col>
                <v-col
                  class="small-pad due-date-selector-wrappper"
                  @keydown.stop
                >
                  <advance-date-picker
                    ref="datePicker"
                    :value="date"
                    @dateChanged="handleDateChange"
                    placeholder="Enter Due Date"
                  />
                </v-col>
                <v-col class="small-pad" @keydown.stop>
                  <advance-time-picker
                    ref="timePicker"
                    :value="time"
                    placeholder="Enter Due Time"
                    @dateChanged="handleTimeChange"
                    :scrollTimeList="true"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="auto">
              <div class="reminder-input-wrapper">
                <ReminderSetter
                  :value="reminderDays"
                  @toggle:clicked="handleReminderDaysToggle"
                  @input="handleReminderDaysVal"
                />
              </div>
            </v-col>
            <v-col cols="auto" class="contingent-container">
              <ContingentToggleBtn
                iconName="$ctiCalendarCross"
                @click="createContingents"
                tooltipPosition="bottom"
                :iconColor="greyIconColor"
                :iconSize="15"
              />
            </v-col>

            <v-col cols="auto">
              <DateShortcutHelperBtns @add="handleDateAdd" :btnSize="24" />
            </v-col>

            <v-col cols="auto">
              <v-row class="justify-center" align="center" no-gutters>
                <v-col cols="auto">
                  <div class="submit-btn-wrapper">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :attrs="attrs"
                          v-on="on"
                          small
                          depressed
                          class="submit-btn"
                          @click="submitData"
                        >
                          <v-icon size="15" color="white"
                            >$ctiSaveOutlined</v-icon
                          >
                        </v-btn>
                      </template>
                      <span> Save</span>
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        tabindex="-1"
                        @click="deleteTasks"
                        class="delete-btn"
                      >
                        <v-icon
                          size="16"
                          class="delete-task-btn"
                          :color="greyIconColor"
                        >
                          $ctiTrashIcon
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Tasks</span>
                  </v-tooltip>
                </v-col>

                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="black"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="handleClose"
                      >
                        <v-icon size="18" :color="greyIconColor">
                          mdi-close-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Close</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  AdvanceDatePicker,
  AdvanceTimePicker,
} from "@/components/AdvancedDateTimePicker";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isValidDate, formatDate } from "@/helpers/dates";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import areaPrioritySetterHelpersMixin from "@/mixins/areaPrioritySetterHelpersMixin";
// import { PrioritySelectorInput as PrioritySelector } from "@/components/EditComponents/PrioritySelector";
import DateShortcutHelperBtns from "./components/DateShortcutHelpersBtns.vue";
import ItemTypeSelectorInput from "@/components/ItemTypeSelector";
import googleCalHelpersMixin from "@/mixins/googleCalHelpersMixins";
import projectsHelpersMixin from "@/mixins/projectsHelpersMixin";

import ProjectSelector from "@/components/EditComponents/ProjectSelector";
import { INTERNAL_DATE_FORMAT, INTERNAL_TIME_FORMAT } from "@/variables/dates";
import { checkIfProjectHasNoPriorityByKey } from "@/helpers/projects";
import ContingentToggleBtn from "@/components/TaskEditForm/components/ContingentToggleBtn.vue";
import ReminderSetter from "@/components/ReminderSetter.vue";
import { isNumeric } from "@/utils/number";
import { isRemindarValValid } from "@/helpers/tasks";
import isUndefinedVal from "@/utils/isUndefinedVal";
// import { PRIMARY_COLOR } from "@/variables/colors";
export default {
  mixins: [
    userDetailsMixin,
    tasksHelpersMixin,
    googleCalHelpersMixin,
    projectsHelpersMixin,
    areaPrioritySetterHelpersMixin,
  ],
  components: {
    AdvanceDatePicker,
    AdvanceTimePicker,
    ProjectSelector,
    // PrioritySelector,
    ItemTypeSelectorInput,
    DateShortcutHelperBtns,
    ContingentToggleBtn,
    ReminderSetter,
  },
  data() {
    return {
      datetime: null,
      date: null,
      time: null,
      tasksCount: 0,
      isVisible: false,
      taskType: "",
      project: "",
      priority: "",
      reminderDays: null,
    };
  },
  methods: {
    handleReminderDaysToggle() {
      let reminderDays = this.reminderDays || null;
      if (!isUndefinedVal(reminderDays) && isRemindarValValid(reminderDays)) {
        this.reminderDays = null;
      } else {
        this.reminderDays = "1";
      }
    },
    handleReminderDaysVal(val) {
      this.reminderDays = val;
    },
    onTaskTypeChange(e) {
      if (e === "note") {
        this.handleNoteChange();
        return;
      }

      this.updateMultipleTasks({ taskType: e });
    },
    async onProjectChange(e) {
      const updates = {
        project: e,
      };

      if (checkIfProjectHasNoPriorityByKey(e)) {
        this.closeBar();
        await this.$nextTick();
        this.openDialog({
          taskList: [...this.selectedTasks],
          areaId: e,
        });
        return;
      }
      // if (this.projects[e] && this.projects[e].priority) {
      //   updates.priority = this.projects[e].priority;
      // }

      // updates.projectUpdateData = this.createProjectPriorityDbData(
      //   { ...this.selectedTasks[0], ...updates },
      //   e
      // );
      this.updateMultipleTasks({ ...updates });
    },
    onPriorityChange(e) {
      this.updateMultipleTasks({ priority: e });
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    toggleBar() {
      const selectedTasks = this.selectedTasks;
      if (selectedTasks && selectedTasks.length > 1) {
        if (!this.isVisible) this.isVisible = true;
        this.tasksCount = selectedTasks.length;
        this.datetime = null;
        this.date = null;
        this.time = null;
      } else {
        this.closeBar();
      }
    },
    handleDateChange(date) {
      this.date = date;
    },
    handleTimeChange(date) {
      this.time = date;
    },
    async submitData() {
      let updates = {};
      let updateTasks = true;
      const date = this.date;
      const time = this.time;
      const storedReminderDays = this.reminderDays;
      let runDateCheck = true;
      if (date && isValidDate(date)) {
        updates.due = formatDate(date, INTERNAL_DATE_FORMAT);
      }

      if (time && isValidDate(time)) {
        updates.time = formatDate(time, INTERNAL_TIME_FORMAT);
      }

      if (
        !isUndefinedVal(storedReminderDays) &&
        isNumeric(storedReminderDays)
      ) {
        const parsedVal = parseInt(storedReminderDays, 10);
        const finalVal = parsedVal > 0 ? parsedVal : 0;
        updates.reminderDays = finalVal;
        updates.isContingent = false;
        runDateCheck = false;
      }

      if (runDateCheck && !isValidDate(time) && !isValidDate(date)) {
        const confirmRes = await this.$swal({
          title: "Do you want to continue?",
          text: "This action will remove date and time from the selected tasks",
          icon: "question",
          showCancelButton: true,
          // iconColor: PRIMARY_COLOR,
          // confirmButtonColor: PRIMARY_COLOR,
          // // cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });

        if (confirmRes.isConfirmed) {
          updates = {
            due: "none",
            time: "00:00",
          };
        } else {
          updateTasks = false;
        }
      }
      if (updateTasks) {
        this.updateMultipleTasks({ ...updates });
      }
    },
    async deleteTasks() {
      this.setLoader(true);
      this.closeBar();
      await this.removeRecurringOrNormalTasks(this.selectedTasks);
      this.setLoader(false);
      // this.setLoader(false);
    },
    handleClose() {
      this.closeBar();
    },
    handleDateAdd(type) {
      const rows = [...this.selectedTasks];
      this.closeBar();
      if (type === "none") {
        const moveableTasks = this.getMoveableTasksFromList(rows);
        if (!isEmpty(moveableTasks)) {
          this.handleUnschedulingOfNormalAndRecurringTasks(moveableTasks);
        } else {
          this.updateDateTimeOfNormalOrRecurringTasks(rows, type);
        }
      } else {
        this.updateDateTimeOfNormalOrRecurringTasks(rows, type);
      }
    },
    getMoveableTasksFromList(tasksToProcess) {
      return tasksToProcess.filter(
        (t) =>
          (!isValidDate(t.dueAsDate) && t.isContingent) ||
          isValidDate(t.dueAsDate)
      );
    },
    closeBar() {
      this.datetime = null;
      this.date = null;
      this.time = null;
      this.isVisible = false;
      this.tasksCount = 0;
      this.priority = "";
      this.taskType = "";
      this.project = "";
      this.priority = "";
      this.reminderDays = null;
    },
    async updateMultipleTasks(data) {
      const rows = this.selectedTasks;
      this.closeBar();
      if (rows && rows.length) {
        this.updateRecurringOrNormalTasks(
          rows,
          { ...data },
          undefined,
          undefined
          // isInDashboardAndFormOpened
        );
      }
    },
    async handleNoteChange() {
      const rows = this.selectedTasks;

      if (rows && rows.length) {
        this.setDataForConvertMode({
          dataList: [...rows],
          typeToUse: "note",
        });
      }
    },

    createContingents() {
      this.updateMultipleTasks({ isContingent: true, dueAsDate: null });
    },
    ...mapActions("task", ["updateSelectedTasks"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    ...mapMutations(["setLoader"]),
  },
  computed: {
    barVisibleClass() {
      return { "bar-is-visible": this.isVisible };
    },
    greyIconColor() {
      // return DARK_GREY_ICON_COLOR;

      return "#6b6b6b";
    },
    selectMenuProps() {
      return {
        contentClass: "task-type-selection-limited-menu",
      };
    },
    ...mapGetters("task", ["selectedTasks", "tasks"]),
    ...mapGetters(["isGoogleCalSyncEnabled"]),
  },
  watch: {
    selectedTasks: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.toggleBar();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.action-bar-wrapper {
  height: 40px;
  border-color: #d5d6d7;
  background: #eaeaea;
  z-index: 888;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 0px 10px 0 rgb(0 0 0 / 10%);
  transform: translateY(-250px);
  /* top: -250px; */
  transition: transform 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
}

.bar-is-visible {
  transform: translateY(0px);
}

.action-bar {
  padding: 0;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
}

.tasks-count-container {
  display: inline-flex;
  align-items: center;
}

.smoke-bg {
  background-color: #eaeaea;
}

.small-pad {
  padding: 0 1.5rem;
}

.save-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.icon-pad {
  margin-top: 1px;
  padding-left: 0;
  padding-right: 0.5rem;
}

.tasks-count {
  background-color: #eaeaea;
  margin-left: 20px;
  padding: 0.2rem 0.8rem;
}

.date-input-container {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.date-input-label {
  margin-right: 20px;
}

.date-input-container >>> input {
  line-height: 1 !important;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background: #f42c2c;
}
.submit-btn {
  background-color: var(--multi-task-bar-save-btn-color);
  border-radius: 4px;
}
.contingent-container {
  padding-right: 0.5rem;
}

.delete-btn {
  margin-left: 6px;
}

.reminder-input-wrapper >>> button {
  margin-top: 2px;
}
</style>
<style lang="scss">
.action-bar-wrapper {
  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    color: #6b6b6b;
  }

  .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
  .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
    color: var(--primary-color);
  }

  .due-date-selector-wrappper .e-date-icon {
    margin-top: 3px !important;
  }
}
</style>
