<template>
  <v-dialog
    width="20%"
    :value="isOpen"
    @click:outside="close"
    @keydown="handleKeyDown"
  >
    <v-card>
      <v-card-title class="text-h5"> {{ label }} </v-card-title>
      <v-card-text>
        <div>
          <v-radio-group v-model="selectedOpt">
            <v-radio
              v-for="(opt, index) in options"
              :key="index"
              :label="opt.label"
              :value="opt.value"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          text
          @click="submit"
          class="save-confirm-btn"
        >
          Submit
        </v-btn>
        <v-btn depressed text @click="close" color="error"> Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { PRIMARY_COLOR } from "@/variables/colors";
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },

  data() {
    return {
      selectedOpt: "",
    };
  },
  methods: {
    clearOptValue() {
      this.selectedOpt = "";
    },
    close() {
      this.clearOptValue();
      this.$emit("dialog:closed");
    },
    submit() {
      const currOpt = this.selectedOpt;

      if (!currOpt) {
        this.$swal({
          titleText: "Oops!",
          html: "Please select an option",
          icon: "warning",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
        });

        return;
      }

      this.$emit("option:selected", currOpt);
    },
    handleKeyDown(e) {
      if (e.keyCode === 27) {
        this.close();
      }
    },
  },
};
</script>
