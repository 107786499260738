<template>
  <DialogLayout label="Task Details">
    <template #content>
      <div>
        <p class="selector-label">
          Open the Details Pane with information collapsed for:
        </p>

        <v-row dense>
          <template v-for="option in availableOpts">
            <v-col cols="auto" :key="option.value">
              <v-checkbox
                :key="option.value"
                hide-details
                dense
                v-model="selectedOptions"
                :label="option.label"
                :value="option.value"
              ></v-checkbox>
            </v-col>
          </template>
        </v-row>

        <v-divider></v-divider>
      </div>
    </template>

    <template #actions>
      <!-- <v-spacer /> -->
      <v-btn class="mr-4" @click="saveSettings"> submit </v-btn>
      <v-btn @click="closeDialog"> cancel </v-btn>
    </template>
  </DialogLayout>
</template>
<script>
import { editorCollapseOpts } from "@/data/collapsedEditorOpts";
import { mapActions, mapGetters } from "vuex";
import DialogLayout from "./components/DialogLayout.vue";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
export default {
  mixins: [userDetailsMixin],
  components: {
    DialogLayout,
  },
  data() {
    return {
      selectedOptions: [],
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    ...mapActions("toast", ["showToast"]),
    ...mapActions(["toggleSettingsDialog"]),
    fillData() {
      this.selectedOptions = this.editFormSettings.editorCollapsedViews || [];
    },
    async saveSettings() {
      const editorCollapsedViewOpts = this.selectedOptions || [];
      const dbUpdates = {};
      dbUpdates["/editFormSettings/editorCollapsedViews"] =
        editorCollapsedViewOpts;

      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);

      this.closeDialog();

      this.showToast({ message: "Changes saved", color: "green" });
    },
    closeDialog() {
      this.toggleSettingsDialog();
    },
  },
  computed: {
    availableOpts() {
      return editorCollapseOpts;
    },
    ...mapGetters(["editFormSettings"]),
  },
};
</script>
