<template>
  <div class="task-type-wrapper" @click.stop @keydown.stop="triggerKeyDown">
    <ItemTypeMenu
      :value="data.taskType"
      @input="onTaskTypeChange"
      :hideNoteOpt="hideNoteOpt"
      :disabled="isCellEditDisabled"
      :iconColor="greyIconColor"
      ref="select"
    />
  </div>
</template>

<script>
import ItemTypeMenu from "@/components/ItemTypeMenu/ItemTypeMenu.vue";
import { columnPropsMixin } from "@/core/components/DataTable";
import { mapActions, mapGetters } from "vuex";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import {
  // DARK_GREY_ICON_COLOR,
  NEW_DARK_ICON_COLOR,
} from "@/variables/colors";
import isEmpty from "lodash/isEmpty";
// import isEmpty from "lodash/isEmpty";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ItemTypeMenu },
  mixins: [columnPropsMixin, tasksHelpersMixin],
  methods: {
    ...mapActions("task", ["removeTasks"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    onTaskTypeChange(val) {
      if (val === "note") {
        let taskDataToUse = { ...this.data };

        if (taskDataToUse.isReminderTask) {
          const parentTaskData = this.tasks.find(
            (t) => t.key === taskDataToUse.reminderLinkedTo
          );

          if (!isEmpty(parentTaskData)) {
            taskDataToUse = { ...parentTaskData };
          }
        }
        this.setDataForConvertMode({
          dataList: [{ ...taskDataToUse }],
          typeToUse: "note",
        });
        return;
      }

      this.handleDataUpdate(val);
    },
    triggerKeyDown() {
      //   e.stopPropagation();
      //   setTimeout(() => {
      //     this.$refs.select.blurMenu();
      //   }, 0);
    },
  },
  computed: {
    hideNoteOpt() {
      return this.data.isVirtual;
    },
    greyIconColor() {
      return NEW_DARK_ICON_COLOR;
    },
    ...mapGetters("task", ["tasks"]),
  },
};
</script>
<style scoped>
.task-type-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 4px; */
}

.task-type-wrapper >>> .v-select {
  margin-top: 0px !important;
}

.task-type-wrapper >>> #activator-btn-wrapper {
  height: 34px;
}
.task-type-wrapper >>> #activator-btn-wrapper button {
  height: 34px !important;
  width: 34px !important;
}

.task-type-wrapper >>> .v-select__slot input[readonly="readonly"] {
  height: 100%;
  /* padding: 0px; */
}
</style>
