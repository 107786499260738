<template>
  <div class="root-app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import { setAppColorTheme, getStoredAppColorTheme } from "@/helpers/app";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  processRawTasksAndRefreshList,
  refreshSubTasksTable,
} from "@/helpers/tasks";
import { emitRefreshList } from "./helpers/common";
import Storage from "@/utils/storage";

export default {
  name: "App",
  mounted() {
    const storedAppColorTheme = getStoredAppColorTheme();
    if (storedAppColorTheme) {
      setAppColorTheme(storedAppColorTheme);
    }
    Storage.set("app-loaded", new Date().getDate());
    this.addListeners();
    this.hideSidebarOnMobile();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  components: {},
  methods: {
    ...mapMutations(["toggleSidebar"]),
    hideSidebarOnMobile() {
      if (window.innerWidth < 768) {
        this.toggleSidebar(false);
      }
    },
    debounce(func, delay) {
      let timeout=null
      return () => {
          if(timeout) clearTimeout(timeout)

          timeout=setTimeout(() => {
              func()
          }, delay)
      }
    },
    addListeners() {
      document.addEventListener("visibilitychange", this.handleTabVisibility);
      window.addEventListener('resize', this.debounce(this.hideSidebarOnMobile, 250));
    },
    removeListeners() {
      document.removeEventListener(
        "visibilitychange",
        this.handleTabVisibility
      );
    },

    refreshTasks() {
      return new Promise((resolve) => {
        (async () => {
          await processRawTasksAndRefreshList(this.rawTasks, false);

          emitRefreshList(undefined, "tasks");
          setTimeout(() => {
            refreshSubTasksTable();
            this.$nextTick(() => {
              resolve();
            });
          }, 0);
        })();
      });
    },
    async handleTabVisibility() {
      const visibilityState = document.visibilityState;
      const currDate = new Date().getDate();
      const appLoaded = Storage.get("app-loaded");
      if (visibilityState === "visible" && appLoaded != currDate) {
        this.setLoader(true);
        setTimeout(async () => {
          Storage.set("app-loaded", new Date().getDate());
          await this.refreshTasks();
          this.setLoader(false);
        }, 250);
      }
    },
    ...mapActions(["toggleLoader"]),
    ...mapMutations(["setLoader"]),
  },
  computed: {
    ...mapGetters(["selectedColorTheme"]),
    ...mapGetters("task", ["rawTasks"]),
  },

  watch: {
    selectedColorTheme(n) {
      setAppColorTheme(n);
    },
  },
};
</script>
