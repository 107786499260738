<template>
  <OptSelectorInput
    ref="select"
    :value="value"
    :opts="resourceTypeOpts"
    itemLabelToUse="title"
    itemValueToUse="key"
    :placeholder="placeholderText"
    @change="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    @keydown="handleKeyDown"
    selectorClass="resource-type-selector"
    useItemSelectionSlot
    useItemSlot
    :menuProps="menuProps"
  >
    <template #selection-content="{ item }">
      <span class="selected-resource">
        <v-icon :color="greyIconColor" class="mr-2" size="18">
          {{ item.icon }}
        </v-icon>
        <span>{{ item.title }}</span>
      </span>
    </template>
    <template #select-item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" class="cti-list-item-sm">
        <v-icon :color="greyIconColor" class="mr-3" style="width: 33px">
          {{ item.icon }}
        </v-icon>
        <v-list-item-title v-text="item.title"></v-list-item-title>
      </v-list-item>
    </template>
  </OptSelectorInput>
</template>
<script>
import OptSelectorInput from "@/components/OptSelectorInput";
import { resourceTypeOptsList } from "@/data/resources";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
export default {
  components: {
    OptSelectorInput,
  },
  props: {
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    menuProps: {
      type: Object,
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    openInput() {
      this.$refs.select.openInput();
    },
    blurInput() {
      this.$refs.select.blurInput();
    },
  },
  computed: {
    resourceTypeOpts() {
      return resourceTypeOptsList;
    },
    placeholderText() {
      return this.placeholder ? this.placeholder : "Select Resource Type";
    },
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
  },
};
</script>
