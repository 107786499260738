import { render, staticRenderFns } from "./ResourceInfo.vue?vue&type=template&id=59db0032&scoped=true&"
import script from "./ResourceInfo.vue?vue&type=script&lang=js&"
export * from "./ResourceInfo.vue?vue&type=script&lang=js&"
import style0 from "./ResourceInfo.vue?vue&type=style&index=0&id=59db0032&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59db0032",
  null
  
)

export default component.exports