<template>
  <div class="resource-cate-editor-cell-wrapper">
    <ResourceCateSelector
      labelType="fullLabel"
      :value="selectedVal"
      @input="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="triggerKeyDown"
      :cateType="selectedResourceTypeOpt"
      ref="select"
    />
  </div>
</template>
<script>
import {
  columnPropsMixin,
  selectorColumnPropsMixin,
} from "@/core/components/DataTable";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import ResourceCateSelector from "@/components/EditComponents/ResourceCateSelector";
import cloneDeep from "lodash/cloneDeep";
import { createResourceDataForAdd } from "@/helpers/resources";
import { mapGetters } from "vuex";
import { isReverseTabKeys } from "@/utils/hotKeys";
export default {
  name: "resource-cate-editor-cell",
  mixins: [columnPropsMixin, selectorColumnPropsMixin, resourcesHelpersMixin],
  components: {
    ResourceCateSelector,
  },
  computed: {
    ...mapGetters("resourcesData", [
      "categories",
      "selectedResourceTypeOpt",
      "resources",
    ]),
  },
  data() {
    return {
      selectedVal: "",
    };
  },
  methods: {
    handleChange(val) {
      this.onSuccess(val, val, this.cell);
      this.selectedVal = val;
    },
    triggerKeyDown(e) {
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 10);
    },
    async handleKeyDown(e) {
      const keyCode = e.which ? e.which : e.keyCode;

      if (isReverseTabKeys(e)) return;
      const currentCell = this.$el.parentNode.parentNode;
      if (keyCode === 9 && currentCell) {
        e.stopPropagation();
        e.preventDefault();
        const currData = cloneDeep(this.cell.getData());
        const isInAddMode = this.cell.api.isAddModeEnabled();
        let callEndEdit = false;

        if (!isInAddMode) {
          callEndEdit = this.isCellLastEditable();
        }

        if (this.$options.useOldSelectMethods) {
          this.$refs.select.blur();
        } else {
          this.$refs.select.blurInput();
        }

        await this.$nextTick();

        let nextEl = currentCell.parentNode.querySelector(".type-cell");

        if (isInAddMode) {
          const resourceTag = (currData.tag && currData.tag.trim()) || "";
          const currCategories = this.categories;
          const currResources = this.resources;
          const isValidRes = await this.checkIfTagIsValid(
            resourceTag,
            currData.type
          );
          if (!isValidRes.isValid) {
            this.showAlertForTagNotValid(isValidRes);
            return;
          }

          const resourceData = createResourceDataForAdd(
            {
              title: currData.title,
              catId: currData.catId,
              tag: resourceTag || "",
              type: currData.type,
            },
            currResources,
            currCategories
          );

          this.addOrRemoveOrUpdateResourcesInList({
            resourcesToAdd: [resourceData],
          });
          this.cell.api.removeNewRow();
          this.cell.api.triggerAddRow(resourceData);
        } else {
          setTimeout(() => {
            if (callEndEdit) {
              this.cell.api.endEditing();
            } else {
              const input = nextEl?.querySelector("input");
              input?.focus();
            }
          }, 0);
        }
      }
    },
  },
  watch: {
    "data.catId": {
      handler(newVal) {
        this.selectedVal = newVal;
      },
      immediate: true,
    },
  },
};
</script>
<style>
.resource-cate-editor-cell-wrapper .opt-selector {
  height: 33px;
  margin-top: 0;
  font-size: 13px;
  padding-top: 0;
  margin-bottom: 2px;
}
.resource-cate-editor-cell-wrapper .opt-selector .v-input__control {
  height: 33px;
}
.resource-cate-editor-cell-wrapper .opt-selector .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

/* .selection-container {
  display: inline-flex;
} */

.opt-selector .v-input__slot {
  height: 33px;
  /* border: 1px solid #000; */
}

.opt-selector .v-select__slot {
  height: 33px;
}

.opt-selector .v-select__selections {
  height: 33px;
}
</style>
