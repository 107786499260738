<template>
  <div class="actions-cell-wrapper">
    <EditBtn
      cssClass="item-edit-btn"
      @click="editResource"
      :btnText="editPenLabel"
      :btnIconColor="greyIconColor"
    />
  </div>
</template>
<script>
import { columnPropsMixin } from "@/core/components/DataTable";
import EditBtn from "@/components/GridComponents/EditBtn";
import { mapActions } from "vuex";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
export default {
  mixins: [columnPropsMixin],
  components: {
    EditBtn,
  },
  props: {},
  methods: {
    ...mapActions("resourceInfo", ["openResourceInfoViewer"]),
    editResource(e) {
      e.stopPropagation();
      this.openResourceInfoViewer({
        data: this.data,
      });
    },
  },
  computed: {
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
    editPenLabel() {
      return "Edit Resource";
    },
  },
};
</script>
<style scoped>
.edit-btn {
  display: none;
}
</style>
