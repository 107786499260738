import isEmpty from "lodash/isEmpty";
export default {
  isNotesView(state) {
    return state.isNotesView;
  },
  currActiveView(state) {
    return state.currActiveView;
  },
  nav(state) {
    return state.currNav;
  },
  showLoader(state) {
    return state.showLoader || state.isLoaderEnabled;
  },
  isGoogleCalSyncEnabled(state) {
    const googleCalNeedsReconnect = state.googleCalNeedsReconnect;
    return !googleCalNeedsReconnect && state.isGoogleCalSyncEnabled;
  },
  isCalSyncRunning(state) {
    return state.isCalSyncRunning;
  },
  googleCalLastSync(state) {
    return state.googleCalLastSync;
  },
  googleCalLastConnected(state) {
    return state.googleCalLastConnected;
  },
  searchVal(state) {
    return state.searchVal;
  },
  currentViewData(state) {
    return state.currentViewData;
  },
  viewedItemKey(state) {
    return state.viewedItemKey;
  },
  resourceSettings(state) {
    return state.resourceSettings;
  },
  isSettingsOpen(state) {
    return state.isSettingsOpen;
  },
  isGlobalSearchBoxOpen(state) {
    return state.isGlobalSearchBoxOpen;
  },
  isLoaderEnabled(state) {
    return state.isLoaderEnabled;
  },
  currRowInEditing(state) {
    return state.currRowInEditing;
  },
  currViewData(state) {
    return state.currViewData;
  },
  taskSettings(state) {
    return state.taskSettings;
  },
  itemInspectEnabled(state) {
    return state.itemInspectEnabled;
  },

  isResourceTipHidden(state) {
    return state.isResourceTipHidden;
  },

  isAreasTipHidden(state) {
    return state.isAreasTipHidden;
  },
  openedSettings(state) {
    return state.openedSettings;
  },
  editFormSettings(state) {
    return state.editFormSettings;
  },
  selectedColorTheme(state) {
    return state.selectedColorTheme;
  },
  keepResourceNodesClosed(state) {
    return state.keepResourceNodesClosed;
  },

  googleCalNeedsReconnect(state) {
    return state.googleCalNeedsReconnect;
  },

  googleCalSyncIsEnabled(state) {
    return state.isGoogleCalSyncEnabled;
  },
};
