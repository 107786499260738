<template>
  <div>{{ flagColText }}</div>
</template>
<script>
import { mapGetters } from "vuex";
import { flagViews, groupViewsWithFlag } from "@/variables/viewConfigs";
export default {
  computed: {
    ...mapGetters("task", ["currNav", "groupMode"]),
    isGroupModeWithFlag() {
      return groupViewsWithFlag.includes(this.groupMode);
    },
    isFlagVisible() {
      const flagIsAllowedInView =
        flagViews.includes(this.currNav) || !!this.isGlobalViewEnabled;
      const isGroupModeWithFlag = this.isGroupModeWithFlag;
      return flagIsAllowedInView && isGroupModeWithFlag;
    },
    flagColText() {
      // return this.isFlagVisible ? "Flag" : "";
      return "";
    },
  },
};
</script>
