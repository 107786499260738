<template>
  <div class="datagrid-header">
    <template v-for="(col, index) of columnsDefs">
      <div
        v-if="col.visible || col.visible === undefined"
        :key="index"
        :style="generateColStyles(col)"
        :class="generateColClasses(col, 'header')"
        @click="handleHeaderCellClick($event, col)"
      >
        <template v-if="col.HeaderComponent">
          <component :is="col.HeaderComponent" :key="index" :data="col" />
        </template>
        <span v-else :key="index" :class="classes.headerText">
          {{ col.title || "" }}
        </span>
        <span :class="generateSortClasses(col)"></span>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    columnsDefs: {
      type: Array,
      default: () => [],
    },
    generateColStyles: {
      type: Function,
      default: () => {},
    },
    generateColClasses: {
      type: Function,
      default: () => {},
    },
    handleHeaderCellClick: {
      type: Function,
      default: () => {},
    },
    generateSortClasses: {
      type: Function,
      default: () => {},
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
