import { getPosOfEl } from "@/helpers/elements";
import sleep from "@/utils/sleep";

const getCurrItemPos = (
  draggedItemEl,
  parentElTop,
  offset = 0,
  propToUse = "bottom"
) => {
  return getPosOfEl(draggedItemEl)[propToUse] - parentElTop - offset;
};
const mixin = {
  methods: {
    async scrollListForDrag({ parentEl, listWrappEl, draggedItemEl }) {
      if (!this.dragStarted) return;
      const itemPos = getPosOfEl(draggedItemEl);
      const parentPos = getPosOfEl(parentEl);
      const listWrapperPos = getPosOfEl(listWrappEl);
      const itemTop = getCurrItemPos(draggedItemEl, parentPos.top, 22, "top");
      if (itemPos.bottom - parentPos.top >= parentPos.height) {
        const finalHeight = Math.floor(listWrapperPos.height - itemPos.height);
        let parentScrollTop = Math.floor(parentEl.scrollTop);
        while (parentScrollTop <= finalHeight) {
          if (
            !this.dragStarted ||
            getCurrItemPos(draggedItemEl, parentPos.top) < parentPos.height
          ) {
            break;
          }
          await sleep(20);
          parentScrollTop += 10;
          parentEl.scrollTop = parentScrollTop;
        }
      } else if (itemTop <= 0) {
        let parentScrollTop = Math.floor(parentEl.scrollTop);
        while (parentScrollTop > 0) {
          if (
            !this.dragStarted ||
            getCurrItemPos(draggedItemEl, parentPos.top, 22, "top") > 0
          ) {
            break;
          }
          await sleep(20);
          parentScrollTop -= 10;
          parentEl.scrollTop = parentScrollTop;
        }
      }
    },
  },
};

export default mixin;
