import noteActions from "./actions";
import noteMutators from "./mutations";
import noteGetters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      notes: [],
      rawNotesMap: {},
      rawNotes: [],
      directories: {},
      notesCount: 0,
      notesTreeStructure: [],
      selectedFolders: [],
      selectedNotes: [],
      dirChildrenMap: {},
      filteredNotes: [],
      collapsedGroupsList: [],
      selectedNoteTopics: [],
      query: "",
    };
  },
  actions: noteActions,
  mutations: noteMutators,
  getters: noteGetters,
};
