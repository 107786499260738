<template>
  <div class="fill-height task-edit-form-wrapper">
    <FormLayout
      :showForm="isTaskDataValid"
      :showLoading="loading"
      editType="Task"
      :isEditStarted="isEditStarted"
      :currItemIndex="currItemIndex"
      :totalItemCount="totalTasksCount"
      :useBtnRightSide="true"
      :showPagination="true"
      @save="submitData"
      @close="closeForm"
      @edit-item:select="handleTaskEditType"
    >
      <template #header-right>
        <TaskEditModeBtns
          @edit-mode:changed="handleEditModeChange"
          :isTaskComplete="taskCopy.completed"
          :isTaskRepeatEvery="isTaskInRepeatEveryMode"
          :isTaskNotRepeatInOrEvery="isTaskNotRepeatInOrWithProject"
          :isTaskRepeatIn="isCurrTaskRepeatIn"
          :isTaskContinued="isTaskNotRecurringButContinued"
          :activeMode="currActiveMode"
        />
      </template>

      <template #header-left-side>
        <ResourceList
          :list="mentionedResources"
          :active="activeResourceId"
          @click="handleResourceSelect"
        >
          <div class="mr-2">
            <ClearFilterBtn
              :isEmpty="!processedListCount"
              :count="processedListCount"
              @clear-btn:click="closeForm"
            />
          </div>
          <v-tooltip bottom v-if="taskCopy.project">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="handleAreaSelect"
                :class="createAreaBtnClasses()"
                v-on="on"
                v-bind="attrs"
                class="mr-2"
              >
                <v-icon :size="10" color="var(--primary-color)">
                  $ctiCircleOutline
                </v-icon>
                <span class="area-btn-text ml-1">
                  {{ activeProject.title }}
                </span>
              </v-btn>
            </template>
            <span>{{ activeProject.title }}</span>
          </v-tooltip>
        </ResourceList>
      </template>

      <!-- <template #header-left-side>
        <div>
          <v-row dense align="center" wrap>
            <v-col cols="auto">
              <div class="toggle-btn-wrapper">
                <TogglerBtns
                  :list="toggleBtns"
                  @click="handleToggleClick"
                  :active="activeDescr"
                />
              </div>
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom v-if="taskCopy.project">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="handleAreaSelect"
                    :class="createAreaBtnClasses()"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon :size="10" color="var(--primary-color)">
                      $ctiCircleOutline
                    </v-icon>
                    <span class="area-btn-text ml-1">
                      {{ activeProject.title }}
                    </span>
                  </v-btn>
                </template>
                <span>{{ activeProject.title }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <ResourceList
                :list="mentionedResources"
                :active="activeResourceId"
                @click="handleResourceSelect"
              />
            </v-col>
          </v-row>
        </div>
      </template> -->
      <template #main-content>
        <div class="task-title-actions-wrapper">
          <v-row no-gutters align="center" justify="space-between">
            <v-col cols="auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleDescr"
                    color="#515151"
                    small
                  >
                    <!-- <v-icon :size="12" v-if="isTitleOpen">$ctiEditPenSolid</v-icon>
                    <v-icon :size="12" v-else>$ctiEditPenOutline</v-icon> -->
                    <span :class="titleTogglerData.iconClasses" />
                  </v-btn>
                </template>
                <span> {{ descrTogglerData.label }} </span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto" class="flex-1">
              <v-row dense align="center">
                <v-col cols="auto">
                  <div class="project-priority-wrapper">
                    <PrioritySelectorInput
                      :value="activeProject.priority"
                      @input="updateProjectPriority"
                      contentClass="px-1 project-priority"
                    />
                  </div>
                </v-col>
                <v-col cols="auto" class="flex-shrink-80">
                  <div class="project-selector-wrapper">
                    <ProjectSelector
                      :value="taskCopy.project"
                      @change="changeProject"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col md="auto" sm="12">
              <v-row align="center" dense>
                <v-col cols="auto">
                  <v-row align="center" dense>
                    <v-col cols="auto">
                      <v-row align="center" dense>
                        <v-col cols="auto">
                          <span class="txt-sm"> Repeat </span>
                        </v-col>
                        <v-col cols="auto">
                          <div style="width: 70px">
                            <OptSelector
                              ref="repeatSelector"
                              :value="taskCopy.repeatMode"
                              @input="handleRepeatTypeSelection"
                              @focus="handleRepeatTypeFocus"
                              class="txt-sm"
                              :opts="repeatTypeOpts"
                              :disabled="disableRepeatSelector"
                              @keydown="
                                handleTabFocus(
                                  $event,
                                  'repeat-interval-selector',
                                  'title'
                                )
                              "
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="auto" v-if="taskCopy.isRecurring">
                      <div style="width: 50px">
                        <OptSelector
                          :value="taskCopy.recurrenceCount"
                          @input="handleIntervalChange"
                          @keydown="
                            handleTabFocus(
                              $event,
                              'repeat-frequency-selector',
                              'repeat-type-selector'
                            )
                          "
                          @focus="handleRepeatInternalSelectorFocus"
                          :disabled="isRecurringAndModeOn"
                          class="txt-sm"
                          :opts="repeatCountOpts"
                          ref="repeatEveryIntervalSelector"
                        />
                      </div>
                    </v-col>

                    <v-col cols="auto" v-if="taskCopy.isRecurring">
                      <div style="width: 85px">
                        <OptSelector
                          :value="taskCopy.frequency"
                          @input="handleFrequencyChange"
                          @blur="handleFreqBlur"
                          :disabled="isRecurringAndModeOn"
                          @keydown="
                            handleTabFocus(
                              $event,
                              'date-picker',
                              'repeat-interval-selector'
                            )
                          "
                          @focus="handleRepeatFrequencySelector"
                          class="txt-sm repeat-every-frequency"
                          ref="repeatEveryFrequencySelector"
                          :opts="freqOpts"
                        />
                        <error-text :text="freqError" />
                      </div>
                    </v-col>

                    <v-col cols="auto" v-if="isTaskInRepeatInMode">
                      <div style="width: 50px">
                        <v-text-field
                          class="repeat-interval-input"
                          :value="taskCopy.repeatInfo.count"
                          :placeholder="numberInputForRepeatCount"
                          @input="handleInternvalCount"
                          @focus="handleRepeatInternalSelectorFocus"
                          type="number"
                          min="1"
                          hide-details
                          ref="repeatInIntervalInput"
                          @keydown="
                            handleTabFocus(
                              $event,
                              'repeat-frequency-selector',
                              'repeat-type-selector'
                            )
                          "
                        />
                      </div>
                    </v-col>

                    <v-col cols="auto" v-if="isTaskInRepeatInMode">
                      <div style="width: 85px">
                        <OptSelector
                          :value="taskCopy.repeatInfo.frequency"
                          @input="handleFrequencyChange"
                          @blur="handleFreqBlur"
                          class="txt-sm repeat-in-frequency"
                          @keydown="
                            handleTabFocus(
                              $event,
                              repeatInfo.showTxt
                                ? 'repeat-info'
                                : 'date-picker',
                              'repeat-interval-selector'
                            )
                          "
                          @focus="handleRepeatFrequencySelector"
                          ref="repeatInFrequencySelector"
                          :opts="freqOpts"
                        />

                        <error-text :text="freqError" />
                      </div>
                    </v-col>

                    <v-col cols="auto" v-if="repeatInfo.showTxt">
                      <div
                        style="
                          height: 24px;
                          width: 24px;
                          text-align: center;
                          cursor: pointer;
                        "
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              size="20"
                              :color="infoIconColor"
                              class="repeat-info-icon"
                              @keydown="
                                handleTabFocus(
                                  $event,
                                  'date-picker',
                                  'frequency-selector'
                                )
                              "
                              :tabindex="-1"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <span>{{ repeatInfo.txt }}</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="auto">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        @click="toggleReminderDays"
                        @keydown="handleTabFocus($event, '', 'date-picker')"
                      >
                        <v-icon
                          size="16"
                          :color="reminderDaysBtnIconData.color"
                        >
                          {{ reminderDaysBtnIconData.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ reminderDaysBtnIconData.label }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="auto">
                  <div style="width: 40px">
                    <v-text-field
                      class="reminder-input"
                      :value="taskCopy.reminderDays"
                      @input="handleReminderDaysVal"
                      @keydown="handleTabFocus($event, 'description')"
                      placeholder="Enter number of days to remind before"
                      type="number"
                      min="0"
                      hide-details
                      ref="reminderInput"
                    />
                  </div>
                </v-col>

                <v-col cols="auto">
                  <div class="advance-date-input-wrapper" v-if="dataIsLoaded">
                    <AdvancedDateTimePicker
                      :value="dateCopy"
                      @dateChanged="updateDate"
                      @blur="handleDateBlur"
                      @keydown="handleTabFocus($event, 'reminder-info')"
                      inputWidth="xs"
                      :scrollTimeList="enableTimeScroll"
                      :tabindex="6"
                      useExistingValue
                    />
                    <error-text :text="startDateError" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="mt-3" hidden>
          <div>
            <v-row dense>
              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="descr-toggler-btn"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleDescr"
                      color="#515151"
                      small
                    >
                      <span :class="descrTogglerData.iconClasses" />
                    </v-btn>
                  </template>
                  <span> {{ descrTogglerData.label }} </span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
                <div class="task-type-btn-wrapper mt-1">
                  <ItemTypeMenu
                    :value="taskCopy.taskType"
                    @input="handleTaskTypeChange"
                    :hideNoteOpt="hideNoteTypeOpt"
                    ref="taskTypeSelector"
                    @keydown="
                      handleTabFocus($event, 'task-repeat-selector', 'title')
                    "
                  />
                </div>
              </v-col>
              <v-col cols="auto">
                <div class="mt-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="handleTaskAction"
                        tabindex="-1"
                        class="task-action-btn"
                      >
                        <v-icon :size="actionBtnProps.size">
                          {{ actionBtnProps.btnIcon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ actionBtnProps.btnText }}</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col>
                <div
                  class="task-title-input-wrapper full-title-row title-auto-save-input"
                >
                  <input
                    class="title-selector-input"
                    tabindex="-1"
                    @keydown="handleTabFocus($event, 'title', '')"
                  />
                  <TitleEditor
                    :value="taskCopy.title"
                    @input="handleTitleChange"
                    @keydown="
                      handleTabFocus(
                        $event,
                        'task-repeat-selector',
                        'objective'
                      )
                    "
                    :mentionOpts="mentionEditOpts"
                    :refSetter="(refEl) => setRef(refEl, 'titleInput')"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- <div v-if="isValidPausedDates" class="mt-2">
          <PausedReminderDays
            :list="taskCopy.pausedReminderDays"
            @date:removed="handlePausedReminderDayRemoved"
          />
        </div> -->

        <v-row v-show="showResourceTitle" dense class="mt-3">
          <v-col id="resource-title-editor-wrapper">
            <div class="d-flex">
              <p class="resource-input-label primary-text">Name:</p>
              <div class="flex-1">
                <TitleEditor
                  :value="resourceExtraData.title"
                  @input="handleTitleChange($event, 'resource')"
                  placeholder="Enter resource title"
                  :enableMentions="false"
                  :refSetter="(refEl) => setRef(refEl, 'resourceTitleInput')"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="auto">
            <div class="d-flex">
              <p class="resource-input-label primary-text">Tag:</p>
              <div class="flex-1">
                <ResourceTagEditorInput
                  ref="resourceTagInput"
                  v-model="resourceExtraData.tag"
                />
              </div>
            </div>
          </v-col>
        </v-row>

        <div class="mt-2 descr-editor-wrapper">
          <DescrEditor
            v-show="isDescrOpen"
            :value="descrDataToUse"
            @input="handleDescrDataChange"
            @keydown="handleTabFocus($event, '', 'reminder-input')"
            :placeholder="editorPlaceholder"
            :refSetter="(refEl) => setRef(refEl, 'editor')"
            :mentionOpts="mentionEditOpts"
            :enableMentions="isActiveDescrTask"
          />

          <div v-show="areChangesSaved" class="changes-saved-text">
            Changes saved
          </div>
        </div>
        <!-- <CreatedAndModifiedViewer
          :createdText="createdAndModifiedDates.createdDate"
          :modifiedText="createdAndModifiedDates.modifiedDate"
        /> -->
        <div v-show="showList" class="mt-3 form-grid" style="height: 510px">
          <ActivityHistoryTable
            :projectId="taskCopy.project"
            :resourceId="activeResourceId"
            :listLoaded="dataIsLoaded"
            @list:processed="handleListProcessed"
            ref="historyTable"
          />
        </div>
      </template>
    </FormLayout>

    <RecurringTaskEditConfirm
      :toggleLoader="toggleLoader"
      :setDataForEditForm="!doNotSetDataInForm"
      @data-submit:success="callActionAfterSubmitSuccess"
    />
  </div>
</template>
<script>
import Vue from "vue";
import FormLayout from "@/layouts/FormLayout.vue";
import ItemTypeMenu from "@/components/ItemTypeMenu";
import AdvancedDateTimePicker from "@/components/AdvancedDateTimePicker";
import PrioritySelectorInput from "@/components/EditComponents/PrioritySelector";
import ErrorText from "@/core/components/ErrorText";
import ProjectSelector from "@/components/EditComponents/ProjectSelector";
import TitleEditor from "@/components/FormComponents/TitleEditor.vue";
import DescrEditor from "@/components/FormComponents/DescrEditor.vue";
import OptSelector from "@/components/OptSelector";
// import ContingentToggler from "@/components/ContingentToggler";
import FlagToggler from "@/components/FlagToggler";
import RecurringTaskEditConfirm from "@/components/RecurringTaskEditConfirm";
import CreatedAndModifiedViewer from "@/components/FormComponents/CreatedAndModifiedViewer.vue";
import TaskEditModeBtns from "./components/TaskEditModeBtns.vue";
import TogglerBtns from "./components/ToggleBtns.vue";
import ResourceList from "./components/ActiveResources.vue";
import ActivityHistoryTable from "@/components/ActivityHistoryTable";
import PausedReminderDays from "./components/PausedReminderDays.vue";
import frequencyOpts, { frequencyOptsMap } from "@/data/frequencyOpts";
import repeatCountOpts from "@/data/repeatTasksCountOpts";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {
  parseISO,
  formatDate,
  isValidDate,
  isDateBetweenDates,
  getComponentsFromDate,
  getCurrDate,
  makeDateFormat,
  makeTimeFormat,
  subDate,
} from "@/helpers/dates";
import {
  DATE_WITH_TIME_FORMAT_IN_USE,
  DAY_FORMAT,
  INTERNAL_DATE_FORMAT,
} from "@/variables/dates";
import { DARK_GREY_ICON_COLOR, PRIMARY_COLOR } from "@/variables/colors";
import {
  addOrRemoveOrUpdateTasksInLocalTasksList,
  areTaskRecurrenceRulesValid,
  checkIfTaskIsContinued,
  checkIfTaskIsRecurring,
  checkIfTaskIsRepeatIn,
  checkIfTasksWithProjectExists,
  cleanTask,
  convertTaskKeyForGoogleCal,
  createAdditionalDates,
  createDataForRecurringTask,
  createDateTimeFromDue,
  createNextRepeatTask,
  createRecurrenceOptsValuesFromTaskRecurrenceRules,
  createRecurringTasksInfoData,
  createTaskDataForGoogleCal,
  createTaskRowDataByCell,
  createUpdateAndEditedTaskDataByCell,
  createVirtualTasksFromRecurringTasks,
  duplicateTask,
  fillRecurrenceOptsInTaskData,
  fillTaskData,
  getDateAndTimeFromDueDate,
  getMainTaskRulesAndFillRecurringOpts,
  getReminderDaysInfoFromTask,
  isDateInPausedReminderDates,
  isTaskRecurring,
  processTask,
  checkIfTaskIsEdited,
  isTaskGroup,
  getRecurringTasks,
  createRecurringDatesFromTaskRules,
  checkTaskIsAllDay,
  parseRuleDataFromTaskRecurrenceRules,
  filterRecurringTasks,
  addOrRemoveOrUpdateTasksInDb,
  removeTasksFromRecurringTasksMap,
  replaceRecurringTasksInfoInMap,
  createTaskDueDate,
  excludeDatesInTaskRules,
  getRecurrenceIdByDateFromRecurrences,
  isTaskAfterDateFilterCreator,
  isTaskNotCompleted,
  addRecurringTasksInfoInMap,
  checkIfTasksWithResourceExists,
  createRepeatInfoLabelFromTaskData,
  createDbUpdateDataFromProvidedData,
  createTaskRowDataByRowData,
  createStrWithMentionsFromQlDelta,
  checkIfTaskHasReminder,
  createReminderTasks,
  getReminderTasksLinkedToTaskId,
  checkIfTaskIsUnscheduled,
  getOrderedTasksByProject,
  getTasksByCustomOrderAndOrderIndex,
  makeVirtualTaskVisibleInFormGrid,
} from "@/helpers/tasks";

import {
  createUpdateAndEditedResourceData,
  getUserResources,
} from "@/helpers/resources";

import { createRRuleSet } from "@/helpers/rrulesHelpers";
import removeProps from "@/utils/removeProps";
import isUndefinedVal from "@/utils/isUndefinedVal";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import cloneDeep from "lodash/cloneDeep";
import createUniqueId from "@/utils/createUniqueId";
import isNumeric from "@/utils/isNumber";
import { daysOfWeek } from "@/data/weekDays";
import repeatTypeOpts from "@/data/repeatTypeOpts";
import DatabaseInterface from "@/services/DatabaseInterface";
import {
  getUserActions,
  PROJECT_ACTIONS,
  TASK_ACTIONS,
} from "@/helpers/actionHistory";
import { convertStringToPTagString } from "@/utils/stringHelpers";
import { getTextContentFromHTML } from "@/utils/elements";
import { checkAndCreateProjectPriorityUpdateData } from "@/helpers/projects";
import EventEmitter from "@/helpers/eventEmitter";
import { mapGetters, mapActions } from "vuex";
import {
  REFRESH_RESOURCE_DESCR,
  REFRESH_TASKS_EVENT,
  REFRESH_TREE_EVENT,
} from "@/variables/events";
import { addOrUpdateOrRemoveTasksInGoogleCal } from "@/mixins/googleCalHelpersMixins";
import { getPathsFromEvent } from "@/utils/events";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import googleCalHelpersMixin from "@/mixins/googleCalHelpersMixins";
import recurringTasksHelpersMixin from "@/mixins/recurringTasksHelpersMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import formHelpersMixin from "@/mixins/formHelpersMixin";
import resourcesHelpesMixin from "@/mixins/resourcesHelpersMixin";
import { taskTypePlaceholders } from "@/data/taskTypes";
import { resourceTypePlaceholders } from "@/data/resources";
import { convertDateTimeToReadableFormat } from "@/helpers/common";
import { endOfDay } from "date-fns";
import {
  getAllMentionedResourceIdsFromText,
  getAllResourcesByIds,
} from "@/helpers/resources";
import { increment } from "@/utils/number";
import { TASK_FREQ_FROM_RRULE_MAP } from "@/variables/tasks";
import ResourceTagEditorInput from "@/components/ResourcesListView/components/ResourcesTable/components/ResourceTagEditorInput.vue";
import { emptyDescrTypes } from "@/variables/common";
import ClearFilterBtn from "@/components/ClearFilterBtn/ClearFilterBtn.vue";

const currFormattedDate = formatDate(new Date(), INTERNAL_DATE_FORMAT);

import isUUID from "@/utils/isUUID";
import GoogleCalHelpers from "@/helpers/googleCalHelpers";
export default {
  mixins: [
    googleCalHelpersMixin,
    userDetailsMixin,
    recurringTasksHelpersMixin,
    tasksHelpersMixin,
    formHelpersMixin,
    resourcesHelpesMixin,
  ],
  components: {
    FormLayout,
    TitleEditor,
    DescrEditor,
    // eslint-disable-next-line vue/no-unused-components
    CreatedAndModifiedViewer,
    ProjectSelector,
    PrioritySelectorInput,
    ItemTypeMenu,
    AdvancedDateTimePicker,
    OptSelector,
    TaskEditModeBtns,
    ResourceTagEditorInput,
    // eslint-disable-next-line vue/no-unused-components
    FlagToggler,
    // ContingentToggler,
    ErrorText,
    ActivityHistoryTable,
    RecurringTaskEditConfirm,
    // eslint-disable-next-line vue/no-unused-components
    TogglerBtns,
    ResourceList,
    // eslint-disable-next-line vue/no-unused-components
    PausedReminderDays,
    ClearFilterBtn,
  },
  data(vm) {
    return {
      showResourceBtn: false,
      areChangesSaved: false,
      selectedTaskRowKeys: [],
      mainTaskDescr: "",
      mainTaskDescrDelta: [],
      showList: false,
      currTaskId: "",
      doNotSetDataInForm: false,
      taskCopy: {},
      isInContinueMode: false,
      disableRepeatSelector: false,
      dateCopy: vm.taskData?.dueAsDate,
      loading: true,
      projectSelect: [],
      activeProject: {},
      showEditor: false,
      recurringDates: [],
      endDateError: "",
      freqError: "",
      startDateError: "",
      recurringErrors: false,
      isCompleteAndCloneModeEnabled: false,
      isCompleteRepeatInEnabled: false,
      isDuplicateModeEnabled: false,
      completeToggled: false,
      currActiveMode: "",
      dataIsLoaded: false,
      triggerEditChecker: false,
      currItemIndex: 0,
      repeatInModeInfo: {},
      descrData: "",
      descrDelta: [],
      titleDelta: [],
      activeDescr: "task",
      activeResourceId: "",
      projectId: "",
      toggleBtns: [
        {
          key: "task",
          label: "Task",
          icon: "$ctiTree",
          getActiveClass: () => "active-btn",
          isVisible: () => true,
          iconSize: "14",
        },
        {
          key: "resource",
          label: "Resource",
          icon: "$ctiTreeFlipped",
          getActiveClass: () => "active-btn",
          isVisible: () => !!this.showResourceBtn,
          iconSize: "14",
        },
      ],
      mentionedResources: [],
      titleData: "",
      resourceDescrData: "",
      resourceDescrDelta: [],
      resourceExtraData: {
        title: "",
        tag: "",
      },
      resourceTitleDelta: [],
      showResourceTitle: false,
      isDescrOpen: false,
      processedListCount: 0,
    };
  },
  mounted() {
    this.closeFormAfterAction = true;
    this.debouncedTextSave = debounce(this.saveTextData, 800);
    this.debouncedConvertAndSetDelta = debounce(this.convertTitleAndSet, 800);
    this.debouncedConvertAndSetDescrDelta = debounce(
      this.convertDescrDelta,
      800
    );
    EventEmitter.on(REFRESH_RESOURCE_DESCR, this.refreshRescrDesc);
    this.setWindowResizeObserver();
    this.init();
  },
  beforeDestroy() {
    this.removeWindowResizeObserver();
    this.removeListeners();
    EventEmitter.off(REFRESH_RESOURCE_DESCR, this.refreshRescrDesc);
  },
  methods: {
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    toggleDescr() {
      this.isDescrOpen = !this.isDescrOpen;
    },
    handleListProcessed(list = []) {
      this.processedListCount = list.length;
    },
    handleOpenSelectedItem(taskData) {
      if (!this.isOpen) return;
      const isDataChanged = this.checkIfAnyEditIsMade();
      if (isDataChanged) {
        this.loadTaskFromList = true;
        this.taskDataToLoad = taskData;
        this.showConfirmBoxForChanges(undefined, false);
        return;
      }

      this.setSelectedTaskDataInForm(taskData);
    },
    handlePausedReminderDayRemoved(date) {
      const currDates = cloneDeep(this.taskCopy.pausedReminderDays);
      const storedIndex = currDates.indexOf(date);
      if (storedIndex >= 0) {
        currDates.splice(storedIndex, 1);
      }

      this.taskCopy.pausedReminderDays = currDates;
    },
    async convertDescrDelta(dataToConvert) {
      await this.$nextTick();
      const convertedDelta =
        this.$refs.editor?.convertHTMLToDelta(dataToConvert);

      if (!isEmpty(convertedDelta)) {
        if (this.activeDescr === "task") {
          this.descrDelta = [...convertedDelta.ops];
        } else if (this.activeDescr === "resource") {
          this.resourceDescrDelta = [...convertedDelta.ops];
        }
      }
    },
    checkIfTaskOrResourceDataHasChanged() {
      let updatedData, currData;

      if (this.activeDescr === "task") {
        updatedData = createStrWithMentionsFromQlDelta({
          ops: this.descrDelta,
        });

        currData = createStrWithMentionsFromQlDelta(this.currTaskDescrDelta);

        return !isEqual(updatedData, currData);
      } else if (this.activeDescr === "resource") {
        let updatedTitleData, currTitleData;
        const currResourceTag =
          this.rawResourcesMap[this.activeResourceId]?.tag;
        const updatedResourceTag = this.resourceExtraData.tag;
        currData = createStrWithMentionsFromQlDelta({
          ops: this.currResourceDescrDelta,
        });

        updatedData = createStrWithMentionsFromQlDelta({
          ops: this.resourceDescrDelta,
        });

        currTitleData = createStrWithMentionsFromQlDelta({
          ops: this.currResourceTitleDelta,
        });

        updatedTitleData = createStrWithMentionsFromQlDelta({
          ops: this.resourceTitleDelta,
        });

        return (
          !isEqual(updatedData, currData) ||
          !isEqual(updatedTitleData, currTitleData) ||
          !isEqual(updatedResourceTag, currResourceTag)
        );
      }
    },
    toggleReminderDays() {
      let reminderDays = this.taskCopy.reminderDays || null;

      if (
        !isUndefinedVal(reminderDays) &&
        this.isValidRemindVal(reminderDays)
      ) {
        this.currPausedReminderDays = !isEmpty(this.taskCopy.pausedReminderDays)
          ? [...this.taskCopy.pausedReminderDays]
          : undefined;
        this.taskCopy.reminderDays = 0;
        this.taskCopy.pausedReminderDays = [];
      } else {
        this.taskCopy.reminderDays = this.taskData.reminderDays || 1;

        if (!isEmpty(this.currPausedReminderDays)) {
          this.taskCopy.pausedReminderDays = [...this.currPausedReminderDays];
        }
      }
    },
    saveTextData(data, type = "descr") {
      if (type === "descr") {
        this.saveTaskDescr(data);
        this.saveResourceDescr(data);
      }
      if (type === "title") {
        this.saveTaskTitle(data);
        // this.saveResourceTitle(data);
      }
    },
    async saveTaskTitle(text) {
      if (this.isDuplicateModeEnabled) return;
      let taskDataToUse = {};
      taskDataToUse = this.tasks.find((t) => t.key === this.taskData.key);

      if (!isEmpty(taskDataToUse)) {
        const currTaskTitle =
          this.rawTasksMap[this.getTaskId(taskDataToUse)]?.title || "";
        const currTextOfTitle = getTextContentFromHTML(currTaskTitle);
        const updatedTextOfTitle = getTextContentFromHTML(text);
        if (currTextOfTitle === updatedTextOfTitle) return;

        let updatedTaskData = {},
          refousOnTitle = false;
        if (isTaskRecurring(taskDataToUse)) {
          const { createdData } = await this.updateSingleRecurringTask(
            taskDataToUse,
            { ...taskDataToUse, title: text },
            true,
            false,
            true,
            undefined,
            undefined,
            {}
          );

          updatedTaskData = { ...createdData };
          refousOnTitle = true;
        } else {
          const resourceList = getAllMentionedResourceIdsFromText(text);
          const { updates, editedData } = createDbUpdateDataFromProvidedData(
            {
              title: text,
              resources: resourceList,
            },
            {
              ...taskDataToUse,
            }
          );

          const createdData = createTaskRowDataByRowData(taskDataToUse, {
            title: text,
          });

          addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToUpdate: [{ key: taskDataToUse.key, updates: createdData }],
          });

          updatedTaskData = {
            ...createdData,
          };

          DatabaseInterface.update(updates, this.userInfo.uid);

          getUserActions().addTaskAction({
            type: "edit",
            data: {
              ...editedData,
              key: taskDataToUse.key,
            },
          });

          if (this.isGoogleCalSyncEnabled) {
            this.updateTaskDataInGoogleCal(
              {
                key: taskDataToUse.key,
                updates: { title: text || "" },
                currData: this.rawTasksMap[taskDataToUse.key],
              },
              true
            );
          }
        }

        this.mentionedResources = getAllResourcesByIds(
          updatedTaskData.resources
        );
        this.areChangesSaved = true;

        if (!isEmpty(updatedTaskData)) {
          this.updateEditTaskData(updatedTaskData);
        }

        Vue.nextTick(() => {
          this.$refs.historyTable.refreshList();

          if (refousOnTitle) {
            this.focusOnTitleInput();
          }
        });
      }
    },
    async saveResourceTitle(titleText) {
      if (
        this.activeDescr !== "resource" ||
        !this.activeResourceId ||
        isEmpty(this.rawResourcesMap[this.activeResourceId])
      )
        return;
      const updatedTitle = titleText || "";
      const currResourceData = this.rawResourcesMap[this.activeResourceId];
      const currResourceTitle = currResourceData.title || "";

      const currTextOfTitle = getTextContentFromHTML(currResourceTitle);
      const updatedTextOfTitle = getTextContentFromHTML(updatedTitle);
      if (currTextOfTitle === updatedTextOfTitle) return;

      let updatedResourceData = {
        title: updatedTitle,
      };
      const { updates, editedData } = createUpdateAndEditedResourceData(
        { ...updatedResourceData },
        currResourceData
      );

      this.addOrRemoveOrUpdateResourcesInList({
        resourcesToUpdate: [
          {
            updates,
            editedData: {
              ...editedData,
            },
            key: currResourceData.key,
          },
        ],
      });

      this.areChangesSaved = true;
      await this.$nextTick();

      this.focusOnTitleInput();
    },
    async setDataForArea(triggerCheck) {
      if (triggerCheck) {
        const isDescrChanged = this.checkIfTaskOrResourceDataHasChanged();

        if (isDescrChanged) {
          this.loadDescrType = "task";
          this.showConfirmBoxForChanges(undefined, false);
          return;
        }
      }

      const isNotTaskDescr = this.activeDescr !== "task";
      this.dataIsLoaded = false;
      await this.$nextTick();
      this.activeDescr = "task";
      this.showList = true;
      this.showResourceTitle = false;
      this.activeResourceId = "";
      // this.setTitleText(this.rawTasksMap[this.getTaskId(this.taskData)].title);

      if (isNotTaskDescr) {
        const description =
          this.rawTasksMap[this.getTaskId(this.taskData)].description;
        this.setDescrText(description);

        this.focusOnDescrInput(false);
        await this.$nextTick();
        this.isDescrOpen = !emptyDescrTypes.includes(description);
      }

      await this.$nextTick();
      this.dataIsLoaded = true;
    },

    handleAreaSelect() {
      this.prevResourceSelectId = this.activeResourceId;
      this.setDataForArea(true);
    },
    async handleResourceSelect(_, resourceData, triggerCheck = true) {
      if (triggerCheck) {
        const isDescrChanged = this.checkIfTaskOrResourceDataHasChanged();
        if (isDescrChanged) {
          this.loadDescrType = "resource";
          this.loadDescrTypeKey = resourceData.key;
          this.showConfirmBoxForChanges(undefined, false);
          return;
        }
      }
      this.dataIsLoaded = false;
      await this.$nextTick();
      if (this.activeDescr !== "resource") this.activeDescr = "resource";
      if (!this.showResourceBtn) this.showResourceBtn = true;
      if (this.prevResourceSelectId) this.prevResourceSelectId = false;

      if (this.activeResourceId !== resourceData.key) {
        this.showList = true;
        this.activeDescr = "resource";
        this.projectId = "";
        this.activeResourceId = resourceData.key;
        const resourceText =
          this.rawResourcesMap[resourceData.key]?.descr || "";
        const resourceTitle =
          this.rawResourcesMap[resourceData.key]?.title || "";
        this.resourceExtraData.title = resourceTitle;
        this.currResourceTitleDelta =
          this.$refs.editor.convertHTMLToDelta(resourceTitle)?.ops || [];

        this.currResourceDescrDelta =
          this.$refs.editor.convertHTMLToDelta(resourceText)?.ops || [];
        this.resourceExtraData.tag =
          this.rawResourcesMap[resourceData.key]?.tag;
        this.setTitleText(
          this.rawResourcesMap[resourceData.key]?.title,
          "resource"
        );

        this.setDescrText(resourceText);
        this.focusOnDescrInput(false);
        this.showResourceTitle = true;

        this.isDescrOpen = !emptyDescrTypes.includes(resourceText);
      }

      await this.$nextTick();

      this.dataIsLoaded = true;
    },
    createAreaBtnClasses() {
      return {
        "area-btn": true,
        "area-selected": this.activeDescr === "task" && !this.activeResourceId,
      };
    },
    async handleToggleClick(_, itemData) {
      this.activeDescr = itemData.key;
      await this.$nextTick();
      if (itemData.key === "task") {
        // this.setTitleText(
        //   this.rawTasksMap[this.getTaskId(this.taskData)]?.title || ""
        // );

        await this.setDescrText(
          this.rawTasksMap[this.getTaskId(this.taskData)]?.description || ""
        );
      } else if (itemData.key === "resource") {
        let setActiveState = false;
        let resourceIdToUse = this.activeResourceId;

        if (this.prevResourceSelectId) {
          setActiveState = true;
          resourceIdToUse = this.prevResourceSelectId;
          this.prevResourceSelectId = false;
        }
        if (!isEmpty(this.rawResourcesMap[resourceIdToUse])) {
          if (setActiveState) {
            this.activeResourceId = resourceIdToUse;
          }

          // await this.setTitleText(
          //   this.rawResourcesMap[resourceIdToUse]?.title || ""
          // );
          await this.setDescrText(
            this.rawResourcesMap[resourceIdToUse]?.descr || ""
          );
        }
      }

      this.focusOnDescrInput(false);
    },
    submitData() {
      this.processTaskData();
    },
    async checkForEditAndCloseForm() {
      const taskDataChanged = this.checkIfAnyEditIsMade();
      if (taskDataChanged) {
        await this.showConfirmBoxForChanges(undefined, undefined, true);
        return;
      } else {
        this.closeFormAfterAction = true;
      }

      this.closeDialog();
    },
    checkIfAnyEditIsMade() {
      return this.checkTaskDataForAnyEdits();
    },
    handleTaskEditType(type, event) {
      //diabled check
      const isDataChanged = this.checkIfAnyEditIsMade();

      if (isDataChanged) {
        const parentRes = findParentFromPathsByClassName(
          getPathsFromEvent(event),
          ["task-action-btn"],
          "obj"
        );

        this.showConfirmBoxForChanges(parentRes.parentEl, false);
        return;
      }

      this.getTaskData(type);
    },
    showReminderErrorMsg(type = "reminder") {
      let msgToShow = "";
      if (type === "reminder") {
        msgToShow = "Please enter a valid value for reminder";
      }

      if (type === "date") {
        msgToShow = "Due Date is required for reminder";
      }

      if (msgToShow) {
        this.showToast(msgToShow);
      }
    },
    toggleLoader(val) {
      this.loading = val;
    },
    isValidRemindVal(val) {
      return val > 0;
      //
    },
    async convertRecurringTask(type = "normal") {
      this.loading = true;
      const coRelationalId = this.taskData.coRelationalId;
      const isVirtualTask = this.taskData.isVirtual;
      const isFirstTask = this.taskData.isFirstTask;
      const mainTaskId = this.taskData.linkedTo;
      const mainTaskData = this.rawTasksMap[mainTaskId];
      let removeAllTasks = false;
      let newTaskData = {};
      let dbRemoveList = [];
      let localUpdateList = [];
      let localRemoveList = [];
      let remindersToRemove = [];
      let remindersToAdd = [];
      let googleTasksToRemove = [];
      let googleTasksToAdd = [];
      let taskMoveUpdatedData = {};

      let movedTasksEditedData = {};
      const recurringTasks = getRecurringTasks(this.processedTasks, "list", {
        coRelationalId,
      });

      if (!isEmpty(mainTaskData)) {
        const mainTaskGoogleId = mainTaskData.googleEventId;
        const mainTaskId = mainTaskData.key;
        const mainTaskFrequency = mainTaskData.frequency;
        const mainTaskRecurrence = mainTaskData.recurrence;
        const coRelationalId = createUniqueId();
        const remindersLinkedToMain = getReminderTasksLinkedToTaskId(
          this.processedTasks,
          mainTaskId
        );

        if (!isEmpty(remindersLinkedToMain)) {
          remindersToRemove.push(...remindersLinkedToMain);
        }
        // const mainTaskRecurrenceRules = mainTaskData.recurrence;
        // const mainTaskDueDateUTC = converDateToUTC(mainTaskData.dueAsDate);
        // let dbTasksToRemove = [];

        if (isFirstTask) {
          removeAllTasks = true;
        } else if (isVirtualTask) {
          const mainTaskRecurrences = createRecurringDatesFromTaskRules({
            ...mainTaskData,
            dueAsDate: createDateTimeFromDue(
              mainTaskData.due,
              mainTaskData.time
            ),
          });

          if (isEmpty(mainTaskRecurrences)) {
            removeAllTasks = true;
          }
        }
        let dbTasksToUpdate = [];
        let googleTaskUpdateList = [];
        let taskDataForGoogle = {};

        let clonedData = cloneDeep(this.taskCopy);

        clonedData = removeProps(clonedData, [
          "recurrence",
          "linkedTo",
          "coRelationalId",
          "frequency",
          "repeatMonthDate",
          "recurrenceCount",
          "recurrenceRepeatDay",
        ]);

        let totalTasksCount = Object.keys(this.rawTasksMap || {}).length;
        const createdDateAndTime = getDateAndTimeFromDueDate(this.dateCopy);
        const isTaskUnscheduled = checkIfTaskIsUnscheduled(
          {
            ...clonedData,
            dueAsDate: this.dateCopy,
          },
          this.taskData
        );

        clonedData.resources = getAllMentionedResourceIdsFromText(
          clonedData.title
        );
        let createdTaskData = duplicateTask(
          {
            ...clonedData,
            created: getCurrDate(),
            ...createdDateAndTime,
            userPosition: 0,
          },
          totalTasksCount
        );

        if (isTaskUnscheduled) {
          let dropTargetOrder = 0;
          let dropTargetUserPosition = 0;
          let dropPositionIndexToUse = 0,
            customPositionToUse = 0;

          let currRowsOnPosition = [];
          const orderedTasksByProject = getOrderedTasksByProject(
            this.tasks,
            createdTaskData.project
          );

          const dropTargetRowData = orderedTasksByProject[0];

          if (!isEmpty(dropTargetRowData)) {
            dropTargetOrder = dropTargetRowData.customPosition;
            dropTargetUserPosition = dropTargetRowData.customPositionIndex;
            customPositionToUse = dropTargetOrder;
            dropPositionIndexToUse = dropTargetUserPosition;

            currRowsOnPosition = getTasksByCustomOrderAndOrderIndex(
              orderedTasksByProject,
              dropTargetOrder,
              dropPositionIndexToUse,
              []
            );
          }

          const moveUpdateData = {
            isCustomPositioned: true,
            customPosition: customPositionToUse,
            customPositionIndex: dropPositionIndexToUse,
            ...getDateAndTimeFromDueDate(""),
          };
          createdTaskData = {
            ...createdTaskData,
            ...moveUpdateData,
            modified: getCurrDate(),
          };

          taskMoveUpdatedData = {
            currData: {
              isCustomPositioned: false,
              customPosition: 0,
              customPositionIndex: 0,
              flag: this.taskData.flag,
              ...getDateAndTimeFromDueDate(this.taskData.dueAsDate),
            },
            changedProps: {
              ...moveUpdateData,
            },
          };

          if (!isEmpty(currRowsOnPosition)) {
            const res = this.createDataForOtherTasksMovedInCustomOrder({
              tasksOnPosition: currRowsOnPosition,
              dropTargetOrder,
              startIndex: dropPositionIndexToUse + 1,
            });

            if (!isEmpty(res.editedData)) {
              movedTasksEditedData["normal"] = {
                editedTasks: [...res.editedData],
              };
            }

            dbTasksToUpdate.push(...res.tasksToUpdateInDb);
            localUpdateList.push(...res.tasksToUpdateInLocalList);
          }
        }

        if (this.isGoogleCalSyncEnabled && isValidDate(this.dateCopy)) {
          createdTaskData.googleEventId = convertTaskKeyForGoogleCal(
            createdTaskData.key
          );

          createdTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
          taskDataForGoogle = createTaskDataForGoogleCal({
            ...clonedData,
            dueAsDate: this.dateCopy,
            taskKey: createdTaskData.key,
          });
        }

        if (!isTaskUnscheduled) {
          createdTaskData.flag = createdDateAndTime.due !== "none";
        }

        if (!removeAllTasks) {
          let endDate = this.dateCopy;

          if (!isValidDate(endDate)) {
            endDate = this.taskData.dueAsDate;
          }

          if (checkTaskIsAllDay(endDate)) {
            endDate = subDate(endDate, { days: 1 });
          } else {
            endDate = subDate(endDate, { days: 1 });
            endDate = endOfDay(endDate);
          }

          const { rule, ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskData.recurrence,
              dueAsDate: createDateTimeFromDue(
                mainTaskData.due,
                mainTaskData.time
              ),
            });
          const parsedRuleOptions = rule.options;

          const excludedDates = parsedRuleSet.exdates();
          const rulesOptsToUse = {
            ...parsedRuleOptions,
            dtstart: createTaskDueDate(mainTaskData.due),
            until: endDate,
          };
          const newRuleSetCreatedForMainTask = createRRuleSet(rulesOptsToUse);

          excludeDatesInTaskRules(
            excludedDates,
            newRuleSetCreatedForMainTask,
            false
          );

          const rulesList = newRuleSetCreatedForMainTask.valueOf();

          const convertedRules = rulesList.slice(1);

          const mainTaskGoogleUpdate = {
            key: mainTaskGoogleId,
            updates: {
              recurrence: convertedRules,
            },
          };

          const mainTaskDataDbUpdate = {
            key: mainTaskId,
            updates: {
              recurrence: convertedRules,
            },
          };

          const localTaskUpdatedData = {
            ...mainTaskData,
            ...mainTaskDataDbUpdate.updates,
          };
          localUpdateList.push({
            key: mainTaskId,
            updates: {
              ...localTaskUpdatedData,
            },
          });

          dbTasksToUpdate.push(mainTaskDataDbUpdate);
          googleTaskUpdateList.push(mainTaskGoogleUpdate);

          const linkedTasksWhichAreNotCompleted = filterRecurringTasks(
            recurringTasks,
            [
              isTaskNotCompleted,
              isTaskAfterDateFilterCreator(
                this.taskData.dueAsDate,
                "dueAsDate",
                "afterOrEqual"
              ),
            ]
          );

          // const { } =

          const recurrences = createRecurringDatesFromTaskRules({
            ...mainTaskData,
            recurrence: convertedRules,
          });
          const firstRecurrence = recurrences[0];

          const firstTaskData = getRecurrenceIdByDateFromRecurrences(
            firstRecurrence,
            mainTaskData.key
          );

          if (!isEmpty(firstTaskData)) {
            const currTaskData = this.processedTasks.find(
              (t) => t.key === firstTaskData.key
            );
            if (!isEmpty(currTaskData)) {
              localUpdateList.push({
                key: currTaskData.key,
                updates: {
                  ...currTaskData,
                  isCalendarOnly: false,
                },
              });
            }
          }

          linkedTasksWhichAreNotCompleted.forEach((t) => {
            const linkedReminders = getReminderTasksLinkedToTaskId(
              this.processedTasks,
              t.key
            );

            if (!isEmpty(linkedReminders)) {
              remindersToRemove.push(...linkedReminders);
            }
          });

          dbRemoveList.push(...linkedTasksWhichAreNotCompleted);
          localRemoveList.push(...linkedTasksWhichAreNotCompleted);
          googleTasksToRemove.push(...linkedTasksWhichAreNotCompleted);
          // Remove single task
        } else {
          recurringTasks.forEach((childT) => {
            const isVirtualTask = childT.isVirtual;
            if (!isVirtualTask) {
              googleTasksToRemove.push(childT);
            }

            const reminderTasks = getReminderTasksLinkedToTaskId(
              this.processedTasks,
              childT.key
            );

            if (!isEmpty(reminderTasks)) {
              remindersToRemove.push(...reminderTasks);
            }
            localRemoveList.push(childT);
            dbRemoveList.push(childT);
          });
          googleTasksToRemove.push(mainTaskData);
          dbRemoveList.push(mainTaskData);
          // Remove All Tasks
        }

        newTaskData = { ...createdTaskData };

        if (checkIfTaskHasReminder(newTaskData) && !newTaskData.completed) {
          remindersToAdd.push(...createReminderTasks(newTaskData));
        }

        let userActionData = {};

        if (type === "repeat-in") {
          userActionData = {
            type: TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN,
            data: {
              key: newTaskData.key,
              frequency: mainTaskFrequency,
              recurrence: mainTaskRecurrence,
              coRelationalId,
              changedProps: {
                repeatInfo: newTaskData.repeatInfo,
                frequency: "",
                coRelationalId: "",
                recurrence: [],
              },
            },
            on: "task",
          };
        } else if (type === "normal") {
          userActionData = {
            type: TASK_ACTIONS.REPEAT_EVERY_TO_NORMAL,
            data: {
              key: newTaskData.key,
              frequency: mainTaskFrequency,
              recurrence: mainTaskRecurrence,
              coRelationalId,
              ...taskMoveUpdatedData.currData,
              changedProps: {
                frequency: "",
                coRelationalId: "",
                recurrence: [],
                ...taskMoveUpdatedData.changedProps,
              },
            },
            on: "task",
          };
        }

        if (!isEmpty(movedTasksEditedData)) {
          userActionData = {
            type: TASK_ACTIONS.ACTIONS_BATCH,
            data: [
              {
                type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
                data: {
                  ...movedTasksEditedData,
                },
                on: "task",
              },
              {
                ...userActionData,
              },
            ],
            on: "task",
          };
        }

        if (!isEmpty(userActionData)) {
          getUserActions().addTaskAction(userActionData);
        }

        googleTasksToAdd.push({
          key: createdTaskData.googleEventId,
          data: taskDataForGoogle,
        });
        await addOrRemoveOrUpdateTasksInDb(
          {
            tasksToUpdate: dbTasksToUpdate,
            tasksToRemove: dbRemoveList,
            tasksToAdd: [newTaskData],
          },
          undefined,
          this.userInfo.uid
        );

        if (this.isGoogleCalSyncEnabled) {
          addOrUpdateOrRemoveTasksInGoogleCal(
            {
              tasksToUpdate: googleTaskUpdateList,
              tasksToAdd: googleTasksToAdd,
              tasksToRemove: [...googleTasksToRemove],
            },
            false
          );
        }

        addOrRemoveOrUpdateTasksInLocalTasksList({
          tasksToUpdate: localUpdateList,
          tasksToAdd: [newTaskData, ...remindersToAdd],
          tasksToRemove: [...localRemoveList, ...remindersToRemove],
          verifyAdd: isEmpty(remindersToAdd),
          addMethod: !isEmpty(remindersToAdd) ? "push" : "unshift",
        });

        if (!removeAllTasks) {
          removeTasksFromRecurringTasksMap({
            mainTaskId: mainTaskData.key,
            tasksIds: [this.taskData.key],
          });
        } else {
          replaceRecurringTasksInfoInMap(mainTaskData.key, []);
        }
        this.updateTaskData(processTask(newTaskData));
        this.callActionAfterSubmitSuccess();
      }
    },
    checkAndSaveResourceDescr() {
      if (this.activeDescr === "resource") {
        const currResourceData = this.rawResourcesMap[this.activeResourceId];
        let updatedResourceData = {
          descr: this.resourceDescrData,
          ...this.resourceExtraData,
        };
        const { updates, editedData } = createUpdateAndEditedResourceData(
          { ...updatedResourceData },
          currResourceData
        );

        this.addOrRemoveOrUpdateResourcesInList({
          resourcesToUpdate: [
            {
              updates,
              editedData: {
                ...editedData,
                refreshDescr: true,
                refreshFormType: "task",
              },
              key: currResourceData.key,
            },
          ],
        });
      }
    },

    async convertRepeatEveryToRepeatIn() {
      const coRelationalId = this.taskData.coRelationalId;
      const isVirtualTask = this.taskData.isVirtual;
      const isFirstTask = this.taskData.isFirstTask;
      const mainTaskId = this.taskData.linkedTo;
      const mainTaskData = this.rawTasksMap[mainTaskId];
      let removeAllTasks = false;
      let newTaskData = {};
      let dbRemoveList = [];
      let localUpdateList = [];
      let localRemoveList = [];
      let remindersToRemove = [];
      let remindersToAdd = [];
      let googleTasksToRemove = [];
      let googleTasksToAdd = [];
      const recurringTasks = getRecurringTasks(this.processedTasks, "list", {
        coRelationalId,
      });

      if (!isEmpty(mainTaskData)) {
        const mainTaskGoogleId = mainTaskData.googleEventId;
        const mainTaskId = mainTaskData.key;

        const remindersLinkedToMain = getReminderTasksLinkedToTaskId(
          this.processedTasks,
          mainTaskId
        );

        if (!isEmpty(remindersLinkedToMain)) {
          remindersToRemove.push(...remindersLinkedToMain);
        }
        // const mainTaskRecurrenceRules = mainTaskData.recurrence;
        // const mainTaskDueDateUTC = converDateToUTC(mainTaskData.dueAsDate);
        // let dbTasksToRemove = [];

        if (isFirstTask) {
          removeAllTasks = true;
        } else if (isVirtualTask) {
          const mainTaskRecurrences = createRecurringDatesFromTaskRules({
            ...mainTaskData,
            dueAsDate: createDateTimeFromDue(
              mainTaskData.due,
              mainTaskData.time
            ),
          });

          if (isEmpty(mainTaskRecurrences)) {
            removeAllTasks = true;
          }
        }
        let dbTasksToUpdate = [];
        let googleTaskUpdateList = [];
        let taskDataForGoogle = {};

        let clonedData = cloneDeep(this.taskCopy);

        clonedData = removeProps(clonedData, [
          "recurrence",
          "linkedTo",
          "coRelationalId",
          "frequency",
          "repeatMonthDate",
          "recurrenceCount",
          "recurrenceRepeatDay",
        ]);

        let totalTasksCount = Object.keys(this.rawTasksMap || {}).length;
        const createdDateAndTime = getDateAndTimeFromDueDate(this.dateCopy);

        clonedData.resources = getAllMentionedResourceIdsFromText(
          clonedData.title
        );
        const createdTaskData = duplicateTask(
          {
            ...clonedData,
            created: getCurrDate(),
            ...createdDateAndTime,
            userPosition: 0,
          },
          totalTasksCount
        );

        if (this.isGoogleCalSyncEnabled && isValidDate(this.dateCopy)) {
          createdTaskData.googleEventId = convertTaskKeyForGoogleCal(
            createdTaskData.key
          );
          createdTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
          taskDataForGoogle = createTaskDataForGoogleCal({
            ...clonedData,
            dueAsDate: this.dateCopy,
            taskKey: createdTaskData.key,
          });
        }

        createdTaskData.flag = createdDateAndTime.due !== "none";
        if (!removeAllTasks) {
          let endDate = this.dateCopy;

          if (checkTaskIsAllDay(endDate)) {
            endDate = subDate(endDate, { days: 1 });
          } else {
            endDate = subDate(endDate, { days: 1 });
            endDate = endOfDay(endDate);
          }

          const { rule, ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskData.recurrence,
              dueAsDate: createDateTimeFromDue(
                mainTaskData.due,
                mainTaskData.time
              ),
            });
          const parsedRuleOptions = rule.options;

          const excludedDates = parsedRuleSet.exdates();
          const rulesOptsToUse = {
            ...parsedRuleOptions,
            dtstart: createTaskDueDate(mainTaskData.due),
            until: endDate,
          };
          const newRuleSetCreatedForMainTask = createRRuleSet(rulesOptsToUse);

          excludeDatesInTaskRules(
            excludedDates,
            newRuleSetCreatedForMainTask,
            false
          );

          const rulesList = newRuleSetCreatedForMainTask.valueOf();

          const convertedRules = rulesList.slice(1);

          const mainTaskGoogleUpdate = {
            key: mainTaskGoogleId,
            updates: {
              recurrence: convertedRules,
            },
          };

          const mainTaskDataDbUpdate = {
            key: mainTaskId,
            updates: {
              recurrence: convertedRules,
            },
          };

          const localTaskUpdatedData = {
            ...mainTaskData,
            ...mainTaskDataDbUpdate.updates,
          };
          localUpdateList.push({
            key: mainTaskId,
            updates: {
              ...localTaskUpdatedData,
            },
          });

          dbTasksToUpdate.push(mainTaskDataDbUpdate);
          googleTaskUpdateList.push(mainTaskGoogleUpdate);

          const linkedTasksWhichAreNotCompleted = filterRecurringTasks(
            recurringTasks,
            [
              isTaskNotCompleted,
              isTaskAfterDateFilterCreator(
                this.taskData.dueAsDate,
                "dueAsDate",
                "afterOrEqual"
              ),
            ]
          );

          // const { } =

          const recurrences = createRecurringDatesFromTaskRules({
            ...mainTaskData,
            recurrence: convertedRules,
          });
          const firstRecurrence = recurrences[0];

          const firstTaskData = getRecurrenceIdByDateFromRecurrences(
            firstRecurrence,
            mainTaskData.key
          );

          if (!isEmpty(firstTaskData)) {
            const currTaskData = this.processedTasks.find(
              (t) => t.key === firstTaskData.key
            );
            if (!isEmpty(currTaskData)) {
              localUpdateList.push({
                key: currTaskData.key,
                updates: {
                  ...currTaskData,
                  isCalendarOnly: false,
                },
              });
            }
          }

          linkedTasksWhichAreNotCompleted.forEach((t) => {
            const linkedReminders = getReminderTasksLinkedToTaskId(
              this.processedTasks,
              t.key
            );

            if (!isEmpty(linkedReminders)) {
              remindersToRemove.push(...linkedReminders);
            }
          });

          dbRemoveList.push(...linkedTasksWhichAreNotCompleted);
          localRemoveList.push(...linkedTasksWhichAreNotCompleted);
          googleTasksToRemove.push(...linkedTasksWhichAreNotCompleted);
          // Remove single task
        } else {
          recurringTasks.forEach((childT) => {
            const isVirtualTask = childT.isVirtual;
            if (!isVirtualTask) {
              googleTasksToRemove.push(childT);
            }

            const reminderTasks = getReminderTasksLinkedToTaskId(
              this.processedTasks,
              childT.key
            );

            if (!isEmpty(reminderTasks)) {
              remindersToRemove.push(...reminderTasks);
            }
            localRemoveList.push(childT);
            dbRemoveList.push(childT);
          });
          googleTasksToRemove.push(mainTaskData);
          dbRemoveList.push(mainTaskData);
          // Remove All Tasks
        }

        newTaskData = { ...createdTaskData };

        if (checkIfTaskHasReminder(newTaskData) && !newTaskData.completed) {
          remindersToAdd.push(...createReminderTasks(newTaskData));
        }
        googleTasksToAdd.push({
          key: createdTaskData.googleEventId,
          data: taskDataForGoogle,
        });
        await addOrRemoveOrUpdateTasksInDb(
          {
            tasksToUpdate: dbTasksToUpdate,
            tasksToRemove: dbRemoveList,
            tasksToAdd: [newTaskData],
          },
          undefined,
          this.userInfo.uid
        );

        if (this.isGoogleCalSyncEnabled) {
          addOrUpdateOrRemoveTasksInGoogleCal(
            {
              tasksToUpdate: googleTaskUpdateList,
              tasksToAdd: googleTasksToAdd,
              tasksToRemove: [...googleTasksToRemove],
            },
            false
          );
        }

        addOrRemoveOrUpdateTasksInLocalTasksList({
          tasksToUpdate: localUpdateList,
          tasksToAdd: [newTaskData, ...remindersToAdd],
          tasksToRemove: [...localRemoveList, ...remindersToRemove],
          verifyAdd: isEmpty(remindersToAdd),
          addMethod: !isEmpty(remindersToAdd) ? "push" : "unshift",
        });

        if (!removeAllTasks) {
          removeTasksFromRecurringTasksMap({
            mainTaskId: mainTaskData.key,
            tasksIds: [this.taskData.key],
          });
        } else {
          replaceRecurringTasksInfoInMap(mainTaskData.key, []);
        }
        this.updateTaskData(processTask(newTaskData));
        this.callActionAfterSubmitSuccess();
      }
    },
    isResourceModeActive() {
      return (
        this.activeDescr === "resource" &&
        this.activeResourceId &&
        !isEmpty(this.activeResourceId)
      );
    },
    async checkIfResourceTagIsValid() {
      if (this.activeDescr !== "resource") {
        return {
          isValid: true,
        };
      }
      const updatedTag = this.resourceExtraData.tag;
      const currTag = this.rawResourcesMap[this.activeResourceId].tag;

      if (updatedTag !== currTag) {
        const isTagValidRes = await this.checkIfTagIsValid(updatedTag);

        return isTagValidRes;
      }

      return {
        isValid: true,
      };
    },
    async processTaskData() {
      const isCurrTaskRecurring = checkIfTaskIsRecurring(this.taskData);
      let updatedReminderDays = this.taskCopy.reminderDays || null;
      if (updatedReminderDays === "0") {
        this.taskCopy.reminderDays = 0;
        updatedReminderDays = null;
      }
      let runRecurringChecks = true;
      let runModeChecks = false;

      if (!isUndefinedVal(updatedReminderDays)) {
        let stopExcution = false;
        if (!this.isValidRemindVal(updatedReminderDays)) {
          this.showReminderErrorMsg("reminder");
          stopExcution = true;
        } else if (!isValidDate(this.dateCopy)) {
          this.showReminderErrorMsg("date");
          stopExcution = true;
        }
        if (stopExcution) {
          return;
        }
      }

      if (
        this.taskData.taskType !== this.taskCopy.taskType &&
        this.taskCopy.taskType === "note"
      ) {
        const isInResourceMode = this.isResourceModeActive();

        if (isInResourceMode) {
          const isResourceTagValidRes = await this.checkIfResourceTagIsValid();

          if (!isResourceTagValidRes.isValid) {
            this.showAlertForTagNotValid(isResourceTagValidRes);
            return;
          }
          this.checkAndSaveResourceDescr();
        }

        this.setDataForConvertMode({
          dataList: [{ ...this.taskCopy }],
          typeToUse: "note",
          calledFrom: "edit-form",
        });
        return;
      }

      if (this.cloneAndCompleteOrDuplicateModeEnabled) {
        runRecurringChecks = false;
        runModeChecks = true;
      }

      if (!runModeChecks && this.isTaskInRepeatInMode) {
        runRecurringChecks = false;
        if (
          isEmpty(this.taskCopy.repeatInfo) ||
          !this.taskCopy.repeatInfo.frequency ||
          !this.taskCopy.repeatInfo.count ||
          !isValidDate(this.dateCopy)
        ) {
          this.showRequiredFieldsToast("repeatIn");
          return;
        }

        if (isCurrTaskRecurring) {
          const isInResourceMode = this.isResourceModeActive();

          if (isInResourceMode) {
            const isResourceTagValidRes =
              await this.checkIfResourceTagIsValid();

            if (!isResourceTagValidRes.isValid) {
              this.showAlertForTagNotValid(isResourceTagValidRes);
              return;
            }
            this.checkAndSaveResourceDescr();
          }

          this.convertRecurringTask("repeat-in");
          return;
        }
      }

      if (runRecurringChecks && !runModeChecks) {
        if (
          this.taskCopy.isRecurring &&
          !this.checkIfFieldsExistsForRecurring()
        ) {
          this.showRequiredFieldsToast();
          return;
        }
        if (isCurrTaskRecurring && this.taskCopy.isRecurring) {
          if (!this.checkIfFieldsExistsForRecurring()) {
            this.showRequiredFieldsToast();
            return;
          }

          const isInResourceMode = this.isResourceModeActive();

          if (isInResourceMode) {
            const isResourceTagValidRes =
              await this.checkIfResourceTagIsValid();

            if (!isResourceTagValidRes.isValid) {
              this.showAlertForTagNotValid(isResourceTagValidRes);
              return;
            }
            this.checkAndSaveResourceDescr();
          }

          this.openEditTaskConfirmBox({
            taskData: {
              ...this.taskCopy,
              dueAsDate: this.dateCopy,
              snapshots: this.taskSnapShots,
              description: this.getTaskDescriptionVal(),
            },
            projectData: this.activeProject,
            objectiveData: this.activeObj,
          });
          return;
        }

        if (
          isCurrTaskRecurring &&
          !this.isTaskInRepeatInMode &&
          !this.taskCopy.isRecurring
        ) {
          const isInResourceMode = this.isResourceModeActive();

          if (isInResourceMode) {
            const isResourceTagValidRes =
              await this.checkIfResourceTagIsValid();

            if (!isResourceTagValidRes.isValid) {
              this.showAlertForTagNotValid(isResourceTagValidRes);
              return;
            }
            this.checkAndSaveResourceDescr();
          }

          this.convertRecurringTask();
          return;
        }

        // if () {
        //   this.checkAndSaveResourceDescr();
        //   this.convertRepeatEveryToRepeatIn();
        //   return;
        // }
      }

      if (runModeChecks) {
        if (this.isDuplicateModeEnabled) {
          this.processForDuplicateMode();
          return;
        }
      }

      const isTaskUnscheduled = checkIfTaskIsUnscheduled(
        {
          ...this.taskCopy,
          dueAsDate: this.dateCopy,
        },
        this.taskData
      );
      const isInResourceMode = this.isResourceModeActive();

      if (isInResourceMode) {
        const isResourceTagValidRes = await this.checkIfResourceTagIsValid();

        if (!isResourceTagValidRes.isValid) {
          this.showAlertForTagNotValid(isResourceTagValidRes);
          return;
        }
        this.checkAndSaveResourceDescr();
      }

      this.loading = true;
      let timeToSet = "00:00"; // Default time
      let dateToSet = "none"; // Date defaults to 'none'
      let taskFlagged = this.taskCopy.flag;
      const googleCalUpdatesList = [];
      let taskDescription = this.getTaskDescriptionVal();
      const isNewTaskDateValid = this.dateCopy && isValidDate(this.dateCopy);
      // Check if dateCopy exists then use date and time from it
      if (isNewTaskDateValid) {
        timeToSet = makeTimeFormat(this.dateCopy);
        dateToSet = makeDateFormat(this.dateCopy);
        taskFlagged = true;
      }
      let taskListUpdates = [];
      let reminderTasksToRemove = [];
      let updates = {};
      let editTaskList = [];
      let googleEventIdData = {};
      let taskProjectData = {};
      let taskProjectChanged = false;
      let taskDroppedInCustomOrderRes = {};
      let editProjectData = {};
      let taskRecurringData = {};
      let createdTaskUpdatedData = {};
      let areRecurrenceRulesValid = false;
      let taskDataForLocalSave = {};
      let repeatedTaskData = {};
      // let reminderTasksList = [];
      let addTaskActionData = {};
      let tasksToAddInGoogle = [];
      // const currentTaskPriority = this.taskData.priority;
      let newTaskPriority = this.taskCopy.priority || "";
      let userBatchActions = [];
      let actionType = "task";
      const allReminderTasksLinked = getReminderTasksLinkedToTaskId(
        this.processedTasks,
        this.taskData.key
      );

      if (!isEmpty(allReminderTasksLinked)) {
        reminderTasksToRemove.push(...allReminderTasksLinked);
      }
      const isTaskContnued =
        this.isInContinueMode || checkIfTaskIsContinued(this.taskData);
      const isRepeatedTaskCompleted =
        !isTaskContnued && this.isCompleteRepeatInEnabled;
      const isTaskConvertedToRecurring =
        !checkIfTaskIsRecurring(this.taskData) &&
        areTaskRecurrenceRulesValid(this.taskCopy.recurrence);

      const isRepeatInTaskConvertedToRepeatEvery =
        !checkIfTaskIsRecurring(this.taskData) &&
        checkIfTaskIsRepeatIn(this.taskData) &&
        areTaskRecurrenceRulesValid(this.taskCopy.recurrence);
      let onlyProjectPriorityChanged = false;

      let toProject = this.taskCopy.project;
      const previousDueDate = this.taskData.dueAsDate;
      const editedTaskData = {
        project: this.taskData.project || "",
        time: "00:00",
        due: "none",
        completed: this.taskData.completed,
        created: this.taskData.created,
        description: this.taskData.description || "",
        flag: this.taskData.flag,
        key: this.taskData.key,
        modified: this.taskData.modified || "",
        order: this.taskData.order || 0,
        priority: this.taskData.priority || "",
        status: this.taskData.status || "",
        taskType: this.taskData.taskType || "",
        title: this.taskData.title,
        isContingent: this.taskData.isContingent || false,
        userPosition: this.taskData.userPosition || 0,
        repeatInfo: !isEmpty(this.taskData.repeatInfo)
          ? this.taskData.repeatInfo
          : {},
        objective: this.taskData.objective || "",
        reminderDays: this.taskData.reminderDays || null,
        pausedReminderDays: this.taskData.pausedReminderDays || [],
        positionChanged: this.taskData.positionChanged || false,
        resources: this.taskData.resources || [],
        isCustomPositioned: this.taskData.isCustomPositioned || false,
        customPosition: this.taskData.customPosition || 0,
        customPositionIndex: this.taskData.customPositionIndex || 0,
      };
      if (previousDueDate && isValidDate(previousDueDate)) {
        (editedTaskData.due = makeDateFormat(previousDueDate)),
          (editedTaskData.time = makeTimeFormat(previousDueDate));
      }
      if (this.taskData.project && this.projects[this.taskData.project]) {
        if (toProject !== this.taskData.project) {
          taskProjectChanged = true;
          editedTaskData.changedProject = toProject;
        } else {
          taskProjectData = this.projects[this.taskData.project];
        }
      } else if (!this.taskData.project && this.taskCopy.project) {
        taskProjectChanged = true;
      }
      if (
        taskProjectChanged &&
        this.taskCopy.project &&
        this.projects[this.taskCopy.project]
      ) {
        taskProjectData = this.projects[this.taskCopy.project];
      }
      if (!isEmpty(taskProjectData)) {
        let newProjectPriority;
        // if (!taskProjectData.priority) {
        //   if (newTaskPriority) {
        //     newProjectPriority = newTaskPriority;
        //   }
        // } else {

        // if (!currentTaskPriority) {
        //   newTaskPriority = taskProjectData.priority;
        // }
        // }

        if (
          !taskProjectChanged &&
          taskProjectData.priority !== this.activeProject.priority
        ) {
          newProjectPriority = this.activeProject.priority;
          onlyProjectPriorityChanged = true;
        }

        if (
          taskProjectChanged &&
          taskProjectData.priority !== this.activeProject.priority
        ) {
          newProjectPriority = this.activeProject.priority;
        }
        if (newProjectPriority) {
          updates["/projects/" + taskProjectData.key + "/priority"] =
            newProjectPriority;
          updates[`/projects/${taskProjectData.key}/modified`] = getCurrDate();
          editProjectData = {
            key: taskProjectData.key,
            priority: taskProjectData.priority || "",
            changedProps: {
              priority: this.activeProject?.priority,
            },
          };
        }
      }
      areRecurrenceRulesValid = isTaskConvertedToRecurring;

      if (!areRecurrenceRulesValid && isRepeatInTaskConvertedToRepeatEvery) {
        areRecurrenceRulesValid = true;
      }
      const changedProps = fillTaskData({
        completed: this.taskCopy.completed,
        created: this.taskCopy.created,
        description: taskDescription || "",
        due: dateToSet,
        flag: taskFlagged,
        key: this.taskCopy.key,
        modified: getCurrDate(),
        order: this.taskCopy.order || 0,
        priority: newTaskPriority,
        project: this.taskCopy.project || "",
        status: this.taskCopy.status,
        taskType: this.taskCopy.taskType || "",
        time: timeToSet,
        title: this.taskCopy.title || "",
        googleEventId: this.taskCopy.googleEventId,
        frequency: this.taskCopy.frequency,
        linkedTo: this.taskCopy.linkedTo,
        coRelationalId: this.taskCopy.coRelationalId,
        isContingent: this.taskCopy.isContingent || false,
        userPosition: this.taskCopy.userPosition || 0,
        repeatInfo: !isEmpty(this.taskCopy.repeatInfo)
          ? this.taskCopy.repeatInfo
          : {},
        objective: this.taskCopy.objective || "",
        reminderDays: this.taskCopy.reminderDays || null,
        pausedReminderDays: this.taskCopy.pausedReminderDays || [],
        positionChanged: this.taskCopy.positionChanged || false,
        resources:
          getAllMentionedResourceIdsFromText(this.taskCopy.title) || [],
        isCustomPositioned: this.taskCopy.isCustomPositioned || false,
        customPosition: this.taskCopy.customPosition || 0,
        customPositionIndex: this.taskCopy.customPositionIndex || 0,
      });

      changedProps.recurrence = areRecurrenceRulesValid
        ? this.taskCopy.recurrence
        : this.taskData.recurrence || [];

      if (this.isGoogleCalSyncEnabled) {
        if (!this.taskCopy.googleEventId) {
          googleEventIdData = {
            googleEventId: convertTaskKeyForGoogleCal(this.taskCopy.key),
          };
        }

        if (!isRepeatedTaskCompleted) {
          const recurrenceUpdates = {};
          if (areRecurrenceRulesValid) {
            recurrenceUpdates.recurrence = this.taskCopy.recurrence;
          }
          googleCalUpdatesList.push({
            key: this.taskCopy.key,
            updates: {
              ...this.taskCopy,
              description: taskDescription,
              dueAsDate: this.dateCopy,
              ...recurrenceUpdates,
            },
            currData: {
              ...this.taskData,
            },
          });
        }
      }
      if (changedProps.completed) {
        changedProps.completedOn = getCurrDate("extended");
      }

      if (isRepeatedTaskCompleted) {
        repeatedTaskData = createNextRepeatTask(
          {
            ...changedProps,

            dueAsDate: createDateTimeFromDue(
              this.taskData.due,
              this.taskData.time
            ),
          },
          {
            due: this.taskData.due,
            time: this.taskData.time,
          }
        );
      }

      createdTaskUpdatedData = {
        ...changedProps,
        modified: getCurrDate(),
        ...googleEventIdData,
        ...taskRecurringData,
        key: this.taskCopy.key,
      };

      if (!isTaskUnscheduled) {
        updates["/tasks/" + this.taskCopy.key] = {
          ...createdTaskUpdatedData,
        };
      }

      if (!isEmpty(repeatedTaskData)) {
        updates["/tasks/" + repeatedTaskData.key] = {
          ...repeatedTaskData,
        };
        addTaskActionData = { ...repeatedTaskData };

        tasksToAddInGoogle.push({
          key: repeatedTaskData.key,
          data: { ...createTaskDataForGoogleCal(repeatedTaskData) },
        });
      }
      editedTaskData.changedProps = changedProps;
      editedTaskData.key = this.taskCopy.key;
      editTaskList.push(editedTaskData);
      taskListUpdates.push({
        ...changedProps,
        key: this.taskCopy.key,
      });

      let actionToStore = TASK_ACTIONS.BATCH_EDIT;
      if (editTaskList && editTaskList.length === 1) {
        editTaskList = editTaskList[0];
        actionToStore = TASK_ACTIONS.EDIT;
      }

      if (isTaskUnscheduled) {
        const orderedTasksOfProject = getOrderedTasksByProject(
          this.tasks,
          createdTaskUpdatedData.project
        );

        taskDroppedInCustomOrderRes =
          this.createDataForTaskDroppedInCustomOrder({
            draggedTasks: [
              {
                ...this.taskData,
                updates: { ...changedProps },
                editedData: { ...editedTaskData },
              },
            ],
            dropRowData: orderedTasksOfProject[0] || {},
            isLastRow: false,
            groupByField: "CTICustomGroup",
            tasksList: orderedTasksOfProject,
          });
      }

      await DatabaseInterface.update(updates, this.userInfo.uid);

      if (
        !isEmpty(taskDroppedInCustomOrderRes.tasksToUpdateInDb) ||
        !isEmpty(taskDroppedInCustomOrderRes.tasksToAddInDb)
      ) {
        await addOrRemoveOrUpdateTasksInDb({
          tasksToUpdate: taskDroppedInCustomOrderRes.tasksToUpdateInDb,
          tasksToAdd: taskDroppedInCustomOrderRes.tasksToAddInDb,
        });
      }

      if (!isEmpty(googleCalUpdatesList)) {
        this.updateTaskDataInGoogleCal(googleCalUpdatesList);
      } else if (!isEmpty(tasksToAddInGoogle)) {
        addOrUpdateOrRemoveTasksInGoogleCal(
          {
            tasksToAdd: tasksToAddInGoogle,
          },
          false
        );
      }

      if (areRecurrenceRulesValid) {
        userBatchActions = {
          type: TASK_ACTIONS.NORMAL_TO_RECURRING,
          data: {
            key: createdTaskUpdatedData.key,
            frequency: "",
            coRelationalId: "",
            recurrence: [],
            restoreOldIdData: {
              key: createdTaskUpdatedData.key,
              restoreFrom: "raw",
            },
            changedProps: {
              frequency: createdTaskUpdatedData.frequency,
              recurrence: createdTaskUpdatedData.recurrence,
              coRelationalId: createdTaskUpdatedData.coRelationalId,
            },
          },
          on: "task",
        };

        if (isRepeatInTaskConvertedToRepeatEvery) {
          userBatchActions.data = {
            ...userBatchActions.data,
            repeatInfo: this.taskData.repeatInfo || {},
            reminderDays: this.taskData.reminderDays || null,
            pausedReminderDays: this.taskData.pausedReminderDays || [],
          };

          userBatchActions.data.changedProps = {
            ...userBatchActions.data.changedProps,
            reminderDays: createdTaskUpdatedData.reminderDays,
            pausedReminderDays: createdTaskUpdatedData.pausedReminderDays,
          };
        }

        if (!isEmpty(editProjectData)) {
          actionType = "all";
          userBatchActions = [
            {
              type: PROJECT_ACTIONS.EDIT,
              data: editProjectData,
              on: "project",
            },
            { ...userBatchActions },
          ];
        }
      } else {
        if (!isEmpty(addTaskActionData)) {
          userBatchActions = [
            {
              type: actionToStore,
              data: editTaskList,
            },
            {
              type: TASK_ACTIONS.ADD,
              data: addTaskActionData,
              on: "task",
            },
          ];

          userBatchActions = {
            type: TASK_ACTIONS.ACTIONS_BATCH,
            data: userBatchActions,
            on: "task",
          };

          if (!isEmpty(editProjectData)) {
            actionType = "all";
            userBatchActions = [
              {
                type: PROJECT_ACTIONS.EDIT,
                data: editProjectData,
                on: "project",
              },
              {
                ...userBatchActions,
              },
            ];
          }
        } else {
          userBatchActions = {
            type: actionToStore,
            data: editTaskList,
            on: "task",
          };

          if (!isEmpty(taskDroppedInCustomOrderRes.groupedEditedTaskData)) {
            userBatchActions = {
              data: taskDroppedInCustomOrderRes.groupedEditedTaskData,
              type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
              on: "task",
            };
          }

          if (!isEmpty(editProjectData)) {
            actionType = "all";
            userBatchActions = [
              {
                type: PROJECT_ACTIONS.EDIT,
                data: editProjectData,
                on: "project",
              },
              {
                ...userBatchActions,
              },
            ];
          }
        }
      }

      if (actionType === "all") {
        getUserActions().addBatchAction({
          data: userBatchActions,
          on: "all",
        });
      } else {
        getUserActions().addTaskAction(userBatchActions);
      }
      const rowUpdates = [];
      let rawTaskDataForLocalSave = {};
      let recurringTasksToAdd = [];
      let recurringDatesList = [];

      rawTaskDataForLocalSave = { ...taskListUpdates[0] };
      taskDataForLocalSave = { ...taskListUpdates[0] };

      if (!isEmpty(repeatedTaskData)) {
        rawTaskDataForLocalSave = { ...repeatedTaskData };
        taskDataForLocalSave = { ...repeatedTaskData };
      }

      if (areRecurrenceRulesValid) {
        const res = createVirtualTasksFromRecurringTasks(
          createdTaskUpdatedData
        );
        recurringTasksToAdd = res.tasks;
        recurringDatesList = res.datesList;
        taskDataForLocalSave = fillRecurrenceOptsInTaskData(
          {
            ...res.tasks[0],
          },
          rawTaskDataForLocalSave
        );
      }

      if (!isEmpty(taskDataForLocalSave)) {
        taskDataForLocalSave = processTask(taskDataForLocalSave);
      }

      taskListUpdates.forEach((tU) => {
        const { key, ...restTu } = tU;
        delete restTu.googleEventId;
        rowUpdates.push({
          key,
          updates: processTask({ ...restTu, key }),
        });
      });

      if (rawTaskDataForLocalSave.project) {
        this.activeProject = cloneDeep(
          this.projects[rawTaskDataForLocalSave.project]
        );
      }

      const localTasksListConfig = {
        tasksToUpdate: rowUpdates,
      };

      if (!isEmpty(repeatedTaskData)) {
        localTasksListConfig.tasksToAdd = [
          { ...processTask(repeatedTaskData) },
          ...createReminderTasks(repeatedTaskData),
        ];
      } else if (!isEmpty(recurringTasksToAdd)) {
        localTasksListConfig.tasksToAdd = [...recurringTasksToAdd];
        localTasksListConfig.tasksToRemove = [createdTaskUpdatedData];
        localTasksListConfig.addMethod = "push";
      } else if (checkIfTaskHasReminder(createdTaskUpdatedData)) {
        localTasksListConfig.tasksToAdd = [
          ...createReminderTasks(createdTaskUpdatedData),
        ];
        localTasksListConfig.addMethod = "push";
      }

      if (
        !areRecurrenceRulesValid &&
        !checkIfTaskHasReminder(createdTaskUpdatedData)
      ) {
        localTasksListConfig.verifyAdd = true;
      } else {
        localTasksListConfig.verifyAdd = false;
      }

      if (!isEmpty(reminderTasksToRemove)) {
        if (!isEmpty(localTasksListConfig.tasksToRemove)) {
          localTasksListConfig.tasksToRemove = [
            ...localTasksListConfig.tasksToRemove,
            ...reminderTasksToRemove,
          ];
        } else {
          localTasksListConfig.tasksToRemove = [...reminderTasksToRemove];
        }
      }

      if (isTaskUnscheduled) {
        if (!isEmpty(localTasksListConfig.tasksToAdd)) {
          localTasksListConfig.tasksToAdd.push(
            ...taskDroppedInCustomOrderRes.tasksToAddInLocaList
          );
        } else {
          localTasksListConfig.tasksToAdd = [
            ...taskDroppedInCustomOrderRes.tasksToAddInLocaList,
          ];
        }

        if (!isEmpty(localTasksListConfig.tasksToUpdate)) {
          localTasksListConfig.tasksToUpdate.push(
            ...taskDroppedInCustomOrderRes.tasksToUpdateInLocalList
          );
        } else {
          localTasksListConfig.tasksToUpdate = [
            ...taskDroppedInCustomOrderRes.tasksToUpdateInLocalList,
          ];
        }

        if (!isEmpty(localTasksListConfig.tasksToRemove)) {
          localTasksListConfig.tasksToRemove.push(
            ...taskDroppedInCustomOrderRes.tasksToRemoveFromLocalList
          );
        } else {
          localTasksListConfig.tasksToRemove = [
            ...taskDroppedInCustomOrderRes.tasksToRemoveFromLocalList,
          ];
        }
      }

      await Vue.nextTick();

      await addOrRemoveOrUpdateTasksInLocalTasksList({
        ...localTasksListConfig,
        // tasksToUpdate: rowUpdates,
        // tasksToAdd: !isEmpty(repeatedTaskData)
        //   ? [{ ...processTask(repeatedTaskData) }]
        //   : recurringTasksToAdd,
        // tasksToRemove: areRecurrenceRulesValid ? [createdTaskUpdatedData] : [],
        // addMethod: areRecurrenceRulesValid ? "push" : "unshift",
        // verifyAdd: !areRecurrenceRulesValid,
      });

      if (onlyProjectPriorityChanged) {
        EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
      }

      this.storeRecurringTasksInInfoMap(
        createdTaskUpdatedData.key,
        recurringDatesList
      );

      await Vue.nextTick();

      this.updateTaskData(taskDataForLocalSave);
      this.callActionAfterSubmitSuccess();
    },
    checkIfFieldsExistsForRecurring() {
      return this.taskCopy.frequency && isValidDate(this.dateCopy)
        ? true
        : false;
    },
    storeRecurringTasksInInfoMap(taskKey, tasksList) {
      if (!isEmpty(tasksList)) {
        addRecurringTasksInfoInMap(taskKey, tasksList);
      }
    },
    async processForDuplicateMode() {
      this.loading = true;
      let createRecurringTasks = false;
      let taskData = cloneDeep(this.taskCopy);
      if (
        this.taskData.taskType !== taskData.taskType &&
        taskData.taskType === "note"
      ) {
        this.setDataForConvertMode({
          dataList: [
            { ...taskData, description: this.getTaskDescriptionVal() },
          ],
          typeToUse: "note",
          calledFrom: "edit-form",
        });

        return;
      }

      if (
        this.taskCopy.isRecurring &&
        !this.checkIfFieldsExistsForRecurring()
      ) {
        this.loading = false;
        this.showRequiredFieldsToast();
        return;
      }

      if (
        isEmpty(this.taskCopy.repeatInfo) ||
        !this.taskCopy.repeatInfo.frequency ||
        !this.taskCopy.repeatInfo.count ||
        !isValidDate(this.dateCopy)
      ) {
        this.showRequiredFieldsToast("repeatIn");
        return;
      }

      const recurrenceRules = taskData.recurrence;
      const repeatInfo = !isEmpty(taskData.repeatInfo)
        ? { ...taskData.repeatInfo }
        : {};
      taskData = removeProps(taskData, [
        "recurrence",
        "linkedTo",
        "coRelationalId",
        "frequency",
        "recurrenceCount",
        "repeatMonthDate",
        "recurrenceRepeatDay",
        "repeatInfo",
        "snapshots",
      ]);

      taskData.resources = getAllMentionedResourceIdsFromText(taskData.title);
      const updatedProjectKey = taskData.project;
      let newTaskPriority = taskData.priority;
      // const currentTaskPriority = this.taskData.priority;
      let taskProjectData;
      let taskProjectChanged = false;
      let onlyProjectPriorityChanged = false;
      let editProjectData = {};

      let updates = {};
      if (this.taskData.project && this.projects[this.taskData.project]) {
        if (updatedProjectKey !== this.taskData.project) {
          taskProjectChanged = true;
        } else {
          taskProjectData = this.projects[this.taskData.project];
        }
      } else if (!this.taskData.project && updatedProjectKey) {
        taskProjectChanged = true;
      }
      if (
        taskProjectChanged &&
        updatedProjectKey &&
        this.projects[updatedProjectKey]
      ) {
        taskProjectData = this.projects[updatedProjectKey];
      }
      if (!isEmpty(taskProjectData)) {
        let newProjectPriority;
        // if (!taskProjectData.priority) {
        //   if (newTaskPriority) {
        //     newProjectPriority = newTaskPriority;
        //   }
        // } else {
        // if (taskProjectData.priority !== this.activeProject.priority) {
        //   newProjectPriority = this.activeProject.priority;
        // }

        // if (!currentTaskPriority) {
        //   newTaskPriority = taskProjectData.priority;
        // }
        // }

        if (
          !taskProjectChanged &&
          taskProjectData.priority !== this.activeProject.priority
        ) {
          newProjectPriority = this.activeProject.priority;
          onlyProjectPriorityChanged = true;
        }

        if (
          taskProjectChanged &&
          taskProjectData.priority !== this.activeProject.priority
        ) {
          newProjectPriority = this.activeProject.priority;
        }
        if (newProjectPriority) {
          const res = checkAndCreateProjectPriorityUpdateData(
            {
              ...taskProjectData,
              priority: newProjectPriority,
            },
            taskProjectData
          );

          if (!isEmpty(res.dbUpdates)) {
            updates = {
              ...updates,
              ...res.dbUpdates,
            };
          }

          if (!isEmpty(res.editProjectData)) {
            editProjectData = {
              ...res.editProjectData,
            };
          }
        }
      }

      let addToGoogle = false;

      let taskDataForGoogle = {};
      let createdRecurringTasks = [];
      let createdRecurringDatesList = [];
      let createdReminderTasks = [];
      const createdDateAndTime = getDateAndTimeFromDueDate(this.dateCopy);
      const recurrenceRulesAreValid =
        areTaskRecurrenceRulesValid(recurrenceRules);
      let createdTaskData = duplicateTask(
        {
          ...taskData,
          created: getCurrDate(),
          ...createdDateAndTime,
          description: this.getTaskDescriptionVal(),
          priority: newTaskPriority,
          userPosition: increment(taskData.userPosition, 1),
          customPositionIndex: increment(taskData.customPositionIndex, 1),
        },
        taskData.order
      );

      if (this.isGoogleCalSyncEnabled && isValidDate(this.dateCopy)) {
        createdTaskData.googleEventId = convertTaskKeyForGoogleCal(
          createdTaskData.key
        );

        createdTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
        taskDataForGoogle = createTaskDataForGoogleCal({
          ...taskData,
          dueAsDate: this.dateCopy,
          taskKey: createdTaskData.key,
        });

        addToGoogle = true;
      }

      createdTaskData.flag = createdDateAndTime.due !== "none";

      if (recurrenceRulesAreValid) {
        createdTaskData.recurrence = recurrenceRules;
        createdTaskData.coRelationalId = createUniqueId();
        taskDataForGoogle.recurrence = taskDataForGoogle;

        createRecurringTasks = true;
      } else if (!isEmpty(repeatInfo)) {
        createdTaskData.repeatInfo = repeatInfo;
      }

      updates[`/tasks/${createdTaskData.key}`] = createdTaskData;

      await DatabaseInterface.update(updates, this.userInfo.uid);

      let userActions = {
        type: TASK_ACTIONS.ADD,
        data: {
          ...createdTaskData,
          restoreOldIdData: {
            key: this.taskData.key,
            restoreFrom: "processed",
          },
        },
        on: "task",
      };

      if (!isEmpty(editProjectData)) {
        userActions = [
          {
            type: PROJECT_ACTIONS.EDIT,
            data: editProjectData,
            on: "project",
          },
          {
            ...userActions,
          },
        ];
      }

      if (Array.isArray(userActions)) {
        getUserActions().addBatchAction({
          data: userActions,
          on: "all",
        });
      } else {
        getUserActions().addTaskAction(userActions);
      }

      if (addToGoogle) {
        this.addTasksToGoogleCal({
          key: createdTaskData.key,
          data: taskDataForGoogle,
        });
      }

      let rawTaskDataForLocalSave = createdTaskData;
      if (createRecurringTasks) {
        ({
          recurringTasks: createdRecurringTasks,
          recurringDatesList: createdRecurringDatesList,
        } = createRecurringTasksInfoData(createdTaskData));

        rawTaskDataForLocalSave = fillRecurrenceOptsInTaskData(
          createdRecurringTasks[0],
          createdTaskData
        );
      }

      const isTaskWithReminder = checkIfTaskHasReminder(createdTaskData);
      // const currDateFormatted = formatDate(new Date(), INTERNAL_DATE_FORMAT);
      if (
        !createRecurringTasks &&
        isTaskWithReminder &&
        !createdTaskData.completed
      ) {
        createdReminderTasks.push(
          ...createReminderTasks({ ...createdTaskData })
        );
      }

      const processedAddedTask = processTask({ ...createdTaskData });
      this.activeProject = cloneDeep(this.projects[createdTaskData.project]);

      this.storeRecurringTasksInInfoMap(
        createdTaskData.key,
        createdRecurringDatesList
      );
      await this.$nextTick();
      await addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToAdd: createRecurringTasks
          ? [...createdRecurringTasks]
          : [{ ...processedAddedTask }, ...createdReminderTasks],
        addMethod:
          createRecurringTasks || isTaskWithReminder ? "push" : "unshift",
        verifyAdd: !createRecurringTasks && !isTaskWithReminder,
      });
      await Vue.nextTick();

      if (onlyProjectPriorityChanged) {
        EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
      }
      this.updateTaskData(processTask(rawTaskDataForLocalSave));
      this.callActionAfterSubmitSuccess();
    },
    getTaskDescriptionVal() {
      return this.descrData;
    },
    async updateSingleTask(newData) {
      if (checkIfTaskIsRecurring(this.taskData)) {
        this.loading = true;
        await this.updateSingleRecurringTask(
          {
            ...this.taskData,
          },
          {
            ...this.taskCopy,
            dueAsDate: this.dateCopy,
            snapshots: this.taskSnapShots,
            description: this.getTaskDescriptionVal(),
            ...newData,
          },
          undefined,
          true,
          undefined,
          !isEmpty(this.activeProject) ? { ...this.activeProject } : {}
        );

        return;
      }

      this.loading = true;
      this.nextTaskId = this.taskData.key;
      await this.updateRecurringOrNormalTasks([{ ...this.taskData }], {
        ...newData,
      });
    },
    refreshActivityHistoryTable() {
      this.$refs.historyTable.refreshList();
    },
    getTaskId(taskData) {
      let taskId = taskData.key;

      if (isTaskRecurring(taskData)) {
        if (taskData.isVirtual && this.rawTasksMap[taskData.linkedTo]) {
          taskId = taskData.linkedTo;
        }
      }
      return taskId;
    },
    async loadTaskFormData(_, refreshHistoryTable) {
      const isRecurringTask = checkIfTaskIsRecurring(this.taskData);
      let currRawData = {},
        taskRecurrence = [],
        parsedRuleOptions = {},
        repeatMonthDate = "",
        extraData = {},
        refreshProjectTasks = false;
      if (refreshHistoryTable) {
        this.refreshActivityHistoryTable();
      } else {
        if (isRecurringTask) {
          const isVirtualTask = this.taskData.isVirtual;
          const linkedTo = this.taskData.linkedTo;
          const taskFrequency = this.taskData.frequency;
          const taskRecurrenceRulesValid = areTaskRecurrenceRulesValid(
            this.taskData.recurrence
          );
          let taskDataForRulesParsing = {};
          if (isVirtualTask) {
            currRawData = this.rawTasksMap[this.taskData.linkedTo];
            taskDataForRulesParsing = currRawData;
          } else if (!isEmpty(linkedTo) || taskRecurrenceRulesValid) {
            currRawData = this.rawTasksMap[this.taskData.key];
            taskRecurrence = currRawData.recurrence;
            if (linkedTo && this.rawTasksMap[linkedTo]) {
              taskDataForRulesParsing = this.rawTasksMap[linkedTo];
            } else if (taskRecurrenceRulesValid) {
              taskDataForRulesParsing = { ...currRawData };
            }
          }
          if (!isEmpty(taskDataForRulesParsing)) {
            parsedRuleOptions =
              createRecurrenceOptsValuesFromTaskRecurrenceRules({
                ...taskDataForRulesParsing,
                dueAsDate: createDateTimeFromDue(
                  taskDataForRulesParsing.due,
                  taskDataForRulesParsing.time
                ),
              });
          }

          if (
            taskFrequency &&
            taskFrequency === "monthly" &&
            currRawData.due !== "none"
          ) {
            repeatMonthDate = `${formatDate(
              new Date(currRawData.due),
              DAY_FORMAT
            )}_monthly`;
          }
        } else {
          currRawData = this.rawTasksMap[this.taskData.key];
        }

        if (isRecurringTask || !isEmpty(currRawData.repeatInfo)) {
          extraData = {
            repeatMode: isRecurringTask ? "every" : "in",
          };
        }

        if (!isEmpty(currRawData.project)) {
          this.currTaskId = currRawData.key;
          if (currRawData.project === this.taskCopy.project) {
            refreshProjectTasks = true;
          }
        } else {
          this.currTaskId = "";
          this.showList = false;
        }

        if (!isEmpty(currRawData.pausedReminderDays)) {
          extraData = {
            ...extraData,
            pausedReminderDays: currRawData.pausedReminderDays,
          };
        }
        const finalTaskData = {
          ...this.taskCopy,
          frequency: "",
          recurrenceCount: 1,
          repeatMode: "never",
          objective: "",
          reminderDays: 0,
          pausedReminderDays: [],
          repeatMonthDate,
          ...cloneDeep(currRawData),
          isRecurring: !checkIfTaskIsContinued(currRawData)
            ? isRecurringTask
            : false,
          isContingent: currRawData.isContingent || false,
          recurrence: !isEmpty(taskRecurrence) ? taskRecurrence : [],
          ...parsedRuleOptions,
          description: currRawData?.description || "",
          repeatInfo: !isEmpty(currRawData.repeatInfo)
            ? currRawData.repeatInfo
            : {},
          ...extraData,
        };

        await this.$nextTick();
        this.taskCopy = { ...finalTaskData };
        this.activeProject =
          cloneDeep(this.projects[finalTaskData.project]) || {};

        this.dateCopy = cloneDeep(this.taskData.dueAsDate);

        this.currItemIndex = this.filteredTasks.findIndex(
          (t) => t.key === this.taskData.key
        );
        const currTitleConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.taskData.title
        );

        const currTaskDescrDelta = this.$refs.editor.convertHTMLToDelta(
          this.taskData.description
        );

        const convertedRawTitleDelta = this.$refs.editor.convertHTMLToDelta(
          finalTaskData.title
        );

        const allMentionedResources = getAllMentionedResourceIdsFromText(
          finalTaskData.title
        );
        this.titleDelta = convertedRawTitleDelta.ops;
        this.$refs.titleInput.setDeltaContents(convertedRawTitleDelta, false);
        this.currTitleDelta = currTitleConvertedData;
        this.currTaskDescrDelta = currTaskDescrDelta;
        this.mentionedResources = getAllResourcesByIds(allMentionedResources);
        if (this.activeDescr === "task") {
          const descrText =
            this.rawTasksMap[this.getTaskId(this.taskData)].description || "";
          const convertedDelta =
            this.$refs.editor.convertHTMLToDelta(descrText);
          this.descrData = descrText;
          this.descrDelta = convertedDelta.ops;
          this.setDescrText(descrText);
          this.isDescrOpen = !emptyDescrTypes.includes(descrText);
        } else if (this.activeDescr === "resource" && this.activeResourceId) {
          const descrText =
            this.rawResourcesMap[this.activeResourceId]?.descr || "";
          const convertedDelta =
            this.$refs.editor.convertHTMLToDelta(descrText);
          this.resourceDescrData = descrText;
          this.resourceDescrDelta = convertedDelta.ops;
          this.setDescrText(descrText);
          this.isDescrOpen = !emptyDescrTypes.includes(descrText);
        }

        await this.$nextTick();

        if (!this.activeResourceId && refreshProjectTasks) {
          this.showList = checkIfTasksWithProjectExists(
            finalTaskData.project,
            this.processedTasks
          );
        } else if (this.activeResourceId) {
          this.showList = checkIfTasksWithResourceExists(
            this.activeResourceId,
            this.processedTasks
          );
        }

        if (this.showList) {
          this.$refs.historyTable.refreshList();
          this.selectedTaskRowKeys = [this.taskData.key];
        }
      }
    },
    handleConvertSuccess(convertedType, convertedData) {
      if (convertedType === "note") {
        if (this.convertedTaskToNote) {
          this.callActionAfterSubmitSuccess();
        } else {
          const convertedTaskData = convertedData[0];
          if (
            !isEmpty(convertedTaskData) &&
            this.taskData.key === convertedTaskData.key
          ) {
            // set next id and call getNextTask function
            this.nextTaskId =
              this.filteredTasks[increment(this.currItemIndex, 1)]?.key;

            this.getNextTaskOrCloseForm();
          } else {
            // refresh list
            this.refreshActivityHistoryTable();
          }
        }
      } else {
        this.closeDialog();
      }
    },
    async refreshRescrDesc() {
      if (this.activeDescr !== "resource" || !this.activeResourceId) return;
      await this.$nextTick();
      const allResourcesMap = getUserResources().getResources();
      if (isEmpty(allResourcesMap[this.activeResourceId])) return;
      const updaredResourceData =
        allResourcesMap[this.activeResourceId].descr || "";
      this.setDescrText(updaredResourceData || "");
      setTimeout(() => {
        this.$refs.editor.focusOnEl();
      }, 0);
    },
    reloadItemData(onlyRefreshData, refreshDescrText, refreshHistoryTable) {
      if (this.isOpen && !isEmpty(this.taskData)) {
        if (onlyRefreshData) {
          this.loadTaskFormData(refreshDescrText, refreshHistoryTable);
        } else {
          this.loadTaskData();
        }
      }
    },
    checkIfCheckFlowShouldContinue() {
      return !this.isResourceFormOpen;
    },
    async getAndSetTaskDataInForm(isMounted) {
      try {
        const isRecurringTask = checkIfTaskIsRecurring(this.taskData);
        const selectedResourceId = this.selectedResourceId;
        let currRawData = {};
        let taskRecurrence = [];
        let parsedRuleOptions = {};
        let repeatMonthDate = "";
        let extraData = {};
        let disableRepeatSelector = false;
        let focusOnTitle = true;
        if (isRecurringTask) {
          const isVirtualTask = this.taskData.isVirtual;
          const linkedTo = this.taskData.linkedTo;
          const taskFrequency = this.taskData.frequency;
          const taskRecurrenceRulesValid = areTaskRecurrenceRulesValid(
            this.taskData.recurrence
          );
          let taskDataForRulesParsing = {};
          if (isVirtualTask) {
            currRawData = this.rawTasksMap[this.taskData.linkedTo];
            taskDataForRulesParsing = currRawData;
          } else if (!isEmpty(linkedTo) || taskRecurrenceRulesValid) {
            currRawData = this.rawTasksMap[this.taskData.key];
            taskRecurrence = currRawData.recurrence;
            if (linkedTo && this.rawTasksMap[linkedTo]) {
              taskDataForRulesParsing = this.rawTasksMap[linkedTo];
            } else if (taskRecurrenceRulesValid) {
              taskDataForRulesParsing = { ...currRawData };
            }
          }
          if (!isEmpty(taskDataForRulesParsing)) {
            parsedRuleOptions =
              createRecurrenceOptsValuesFromTaskRecurrenceRules({
                ...taskDataForRulesParsing,
                dueAsDate: createDateTimeFromDue(
                  taskDataForRulesParsing.due,
                  taskDataForRulesParsing.time
                ),
              });
          }

          if (
            taskFrequency &&
            taskFrequency === "monthly" &&
            currRawData.due !== "none"
          ) {
            repeatMonthDate = `${formatDate(
              new Date(currRawData.due),
              DAY_FORMAT
            )}_monthly`;
          }
        } else {
          currRawData = this.rawTasksMap[this.taskData.key];
        }

        if (isRecurringTask || !isEmpty(currRawData.repeatInfo)) {
          extraData = {
            repeatMode: isRecurringTask ? "every" : "in",
          };
        }

        if (!isEmpty(currRawData.pausedReminderDays)) {
          extraData = {
            ...extraData,
            pausedReminderDays: currRawData.pausedReminderDays,
          };
        }

        const mentionedResourcesList = currRawData.resources;

        this.taskCopy = {
          frequency: "",
          recurrenceCount: 1,
          repeatMode: "never",
          objective: "",
          reminderDays: 0,
          pausedReminderDays: [],
          repeatMonthDate,
          ...cloneDeep(currRawData),
          isRecurring: !checkIfTaskIsContinued(currRawData)
            ? isRecurringTask
            : false,
          isContingent: currRawData.isContingent || false,
          recurrence: !isEmpty(taskRecurrence) ? taskRecurrence : [],
          ...parsedRuleOptions,
          description: currRawData?.description || "",
          repeatInfo: !isEmpty(currRawData.repeatInfo)
            ? currRawData.repeatInfo
            : {},
          ...extraData,
        };
        this.activeProject =
          cloneDeep(this.projects[currRawData.project]) || {};
        this.dateCopy = cloneDeep(this.taskData.dueAsDate);
        this.freqError = "";
        this.startDateError = "";
        this.isCompleteAndCloneModeEnabled = false;
        this.isDuplicateModeEnabled = false;
        this.isInContinueMode = false;
        this.isCompleteRepeatInEnabled = false;
        this.currItemIndex = this.filteredTasks.findIndex(
          (t) => t.key === this.taskData.key
        );

        this.disableRepeatSelector = disableRepeatSelector;
        this.currActiveMode = "";
        await this.$nextTick();
        const currTitleConvertedData = this.$refs.editor.convertHTMLToDelta(
          this.taskData.title
        );

        const currTaskDescrDelta = this.$refs.editor.convertHTMLToDelta(
          this.taskData.description
        );
        const convertedRawTitleDelta = this.$refs.editor.convertHTMLToDelta(
          currRawData.title
        );
        const convertedDelta = this.$refs.editor.convertHTMLToDelta(
          currRawData.description
        );

        this.$refs.editor.setDeltaContents(convertedDelta, false);

        this.$refs.titleInput.setDeltaContents(convertedRawTitleDelta, false);
        this.currTitleDelta = currTitleConvertedData;
        this.currTaskDescrDelta = currTaskDescrDelta;
        this.descrDelta = convertedDelta.ops;
        this.titleDelta = convertedRawTitleDelta.ops;

        if (isMounted && selectedResourceId) {
          const resourceData = getAllResourcesByIds([selectedResourceId])[0];

          if (!isEmpty(resourceData)) {
            this.handleResourceSelect({}, resourceData);
          }
        }

        await this.$nextTick();
        this.mentionedResources = getAllResourcesByIds(mentionedResourcesList);
        // this.selectedTaskRowKeys = [this.taskData.key];
        if (focusOnTitle) {
          setTimeout(() => {
            const inputEl = this.$el.querySelector(".title-selector-input");
            inputEl.focus();
          }, 0);
        }
        this.dataIsLoaded = true;

        this.isDescrOpen = !emptyDescrTypes.includes(currRawData.description);

        this.loaderToggler(false);
      } catch (error) {
        console.error("Error in loadProject", error);
        this.loaderToggler(false);
      } finally {
        this.loading = false;
      }
    },
    async loadTaskData(isMounted) {
      this.loading = true;
      this.dataIsLoaded = false;
      this.selectedTaskRowKeys = [];
      this.activeDescr = "task";
      this.activeResourceId = "";
      this.showResourceBtn = false;
      this.currPausedReminderDays = [];
      this.resetResourceData();
      await this.$nextTick();
      await this.getAndSetTaskDataInForm(isMounted);
      if (this.taskCopy.project) {
        this.showList = checkIfTasksWithProjectExists(
          this.taskCopy.project,
          this.rawTasksMap
        );
      }

      if (this.showList) {
        this.$refs.historyTable.selectTask(this.taskData.key);
      }
    },
    checkTaskDataForAnyEdits() {
      const updatedTitleDelta = this.titleDelta;
      const updatedDescrDelta = this.descrDelta;
      let isDataChanged = false;
      const currStitchedText = createStrWithMentionsFromQlDelta(
        this.currTitleDelta
      );
      let currSitchedDescrText = createStrWithMentionsFromQlDelta(
        this.currTaskDescrDelta
      );
      const updatedStitchedText = createStrWithMentionsFromQlDelta({
        ops: updatedTitleDelta,
      });
      let updatedSitchedDescrText = createStrWithMentionsFromQlDelta({
        ops: updatedDescrDelta,
      });

      if (!isDataChanged && this.activeDescr === "resource") {
        isDataChanged = this.checkIfTaskOrResourceDataHasChanged();
      }

      if (!isDataChanged) {
        if (this.activeDescr !== "task") {
          updatedSitchedDescrText = "";
          currSitchedDescrText = "";
        }
        isDataChanged = checkIfTaskIsEdited(
          {
            ...this.taskCopy,
            dueAsDate: isValidDate(this.dateCopy) ? this.dateCopy : null,
            title: updatedStitchedText,
            description: updatedSitchedDescrText || "",
            objective: this.taskCopy.objective || "",
            reminderDays:
              !isUndefinedVal(this.taskCopy.reminderDays) &&
              this.taskCopy.reminderDays > 0
                ? this.taskCopy.reminderDays?.toString()
                : null,
            pausedReminderDays: this.taskCopy.pausedReminderDays || [],
          },
          {
            ...this.taskData,
            dueAsDate: isValidDate(this.taskData.dueAsDate)
              ? this.taskData.dueAsDate
              : null,
            description: currSitchedDescrText,
            title: currStitchedText,
            isContingent:
              typeof this.taskData.isContingent === "boolean"
                ? this.taskData.isContingent
                : false,
            objective: this.taskData.objective || "",
            reminderDays:
              !isUndefinedVal(this.taskData.reminderDays) &&
              this.taskData.reminderDays > 0
                ? this.taskData.reminderDays?.toString()
                : null,
            pausedReminderDays: this.taskData.pausedReminderDays || [],
          }
        );
      }

      if (!isDataChanged) {
        const tasksRulesAreValid =
          this.taskData.recurrenceRepeatDay !== undefined;
        const newTaskRulesAreValid = areTaskRecurrenceRulesValid(
          this.taskCopy.recurrence
        );
        if (tasksRulesAreValid && !this.taskCopy.isRecurring) {
          isDataChanged = true;
        } else if (
          !checkIfTaskIsRecurring(this.taskData) &&
          newTaskRulesAreValid
        ) {
          isDataChanged = true;
        } else if (tasksRulesAreValid && newTaskRulesAreValid) {
          const oldRules = createAdditionalDates({
            frequency: frequencyOptsMap[this.taskData.frequency],
            startDateTime: this.taskData.dueAsDate,
            repeatDay: this.taskData.recurrenceRepeatDay,
            repeatCount: this.taskData.recurrenceCount,
          }).rules;

          if (!isEqual(oldRules, this.taskCopy.recurrence)) {
            isDataChanged = true;
          }
        }
      }

      if (!isDataChanged) {
        const oldTaskRepeatInfo = this.taskData.repeatInfo;
        const currTaskRepeatInfo = this.taskCopy.repeatInfo;
        if (isEmpty(oldTaskRepeatInfo) && !isEmpty(this.taskCopy.repeatInfo)) {
          isDataChanged = true;
        }
        if (
          !isDataChanged &&
          !isEmpty(oldTaskRepeatInfo) &&
          isEmpty(currTaskRepeatInfo)
        ) {
          isDataChanged = true;
        }

        if (
          !isDataChanged &&
          !isEmpty(oldTaskRepeatInfo) &&
          this.isCompleteRepeatInEnabled
        ) {
          isDataChanged = true;
        }
        if (
          !isDataChanged &&
          !isEmpty(oldTaskRepeatInfo) &&
          !isEmpty(currTaskRepeatInfo) &&
          !isEqual(oldTaskRepeatInfo, currTaskRepeatInfo)
        ) {
          isDataChanged = true;
        }
      }

      if (!isDataChanged && !isEmpty(this.activeProject)) {
        const currProjectData = this.projects[this.activeProject.key];
        const newUpdatedProjectData = this.activeProject;
        isDataChanged = !isEqual(currProjectData, newUpdatedProjectData);
      }

      if (!isDataChanged && this.isInMode) {
        isDataChanged = true;
      }

      return isDataChanged;
    },
    async handleRowSelect(rows) {
      let currSelectedTasks = cloneDeep(this.selectedTaskRowKeys);
      if (rows.length && rows.length === 1) {
        const selectedTaskId = rows[0];

        if (currSelectedTasks.includes(selectedTaskId)) return;
        const selectedTask = this.tasks.find((t) => t.key === selectedTaskId);
        if (!isEmpty(selectedTask)) {
          const isDataChanged = this.checkIfAnyEditIsMade();
          if (isDataChanged) {
            this.loadTaskFromList = true;
            this.taskDataToLoad = selectedTask;
            this.showConfirmBoxForChanges(undefined, false);
            return;
          }

          this.setSelectedTaskDataInForm(
            getMainTaskRulesAndFillRecurringOpts(selectedTask)
          );
        }
      }
    },
    async setSelectedTaskDataInForm(taskDataToSet) {
      makeVirtualTaskVisibleInFormGrid(taskDataToSet);
      this.setTaskData(taskDataToSet);
      await this.$nextTick();

      this.loading = true;
      this.dataIsLoaded = false;
      this.selectedTaskRowKeys = [];
      this.activeDescr = "task";
      await this.getAndSetTaskDataInForm();
    },
    handleRowDeselect(rowKeys) {
      const currSelection = cloneDeep(this.selectedTaskRowKeys);
      rowKeys.forEach((k) => {
        const keyIndex = currSelection.indexOf(k);
        if (keyIndex >= 0) {
          currSelection.splice(keyIndex, 1);
        }
      });
      this.selectedTaskRowKeys = currSelection;
    },
    getNextValidTaskIndex(providedIndex, modifier) {
      let foundNextValidRow = false;
      let indexToUse = providedIndex;
      let finalIndex = -1;
      while (!foundNextValidRow) {
        let taskData = this.filteredTasks[indexToUse];

        if (isEmpty(taskData)) {
          break;
        }
        if (isTaskGroup(taskData) || !taskData.key || taskData.isReminderTask) {
          indexToUse += modifier;
          continue;
        }

        if (!isTaskGroup(taskData) && !taskData.isReminderTask) {
          foundNextValidRow = true;
          finalIndex = indexToUse;
          break;
        }
      }

      return finalIndex;
    },
    async getTaskData(typeOrId) {
      let taskFound = true;
      let currIndex = this.currItemIndex;
      this.loading = true;

      const typeVals = ["next", "previous"];
      if (currIndex !== undefined || currIndex !== null) {
        let taskData = {};

        if (typeOrId) {
          if (isUUID(typeOrId)) {
            taskData = this.tasks.find((t) => t.key === typeOrId);
          } else if (typeVals.includes(typeOrId)) {
            const nextValidTaskIndex = this.getNextValidTaskIndex(
              (currIndex += typeOrId === "next" ? 1 : -1),
              typeOrId === "next" ? 1 : -1
            );
            // if (type === "previous") {
            //   currIndex--;
            // }
            // if (type === "next") {
            //   currIndex++;
            // }
            taskData = this.filteredTasks[nextValidTaskIndex];
          }
        }

        if (isEmpty(taskData)) {
          taskFound = false;
        }

        if (!taskFound) {
          this.loading = false;
          this.showToast("No task found!");
          return;
        }

        // taskData = this.filteredTasks[currIndex];

        taskData = getMainTaskRulesAndFillRecurringOpts(taskData);

        makeVirtualTaskVisibleInFormGrid(taskData);
        this.setTaskData(taskData);
        await this.$nextTick();
        this.reloadItemData();
      }
    },
    async callActionAfterSubmitSuccess() {
      await this.$nextTick();
      const formActions = ["item-change", "close-form", "open-resource-info"];
      const otherFormActions = ["open-selected-item"];
      if (this.resumeActionAfterSubmit && this.userActionType) {
        if (
          formActions.includes(this.userActionType) ||
          otherFormActions.includes(this.userActionType)
        ) {
          if (this.userActionType === formActions[0]) {
            this.getTaskData(this.userActionValue);
          }

          if (this.userActionType === formActions[1]) {
            this.closeDialog();
          }

          if (this.userActionType === formActions[2]) {
            this.triggerOpenResourceInfo();
          }
          this.resetUserAction();
        } else {
          this.triggerActionActionAfterSubmit();
        }
      } else if (this.convertedTaskToNote || this.completeCurrAndShowNextTask) {
        this.getNextTaskOrCloseForm();
      } else if (this.loadTaskFromList && !isEmpty(this.taskDataToLoad)) {
        this.setSelectedTaskDataInForm(this.taskDataToLoad);
      } else if (this.loadDescrType) {
        this.loadDescr(this.loadDescrType, this.loadDescrTypeKey);
      }
    },

    async loadDescr(type, key) {
      this.resetResourceData();
      await this.$nextTick();
      if (type === "task") {
        this.setDataForArea();
      } else if (type === "resource" && key) {
        const resourceData = getAllResourcesByIds([key])[0];
        if (!isEmpty(resourceData)) {
          this.handleResourceSelect({}, resourceData, false);
        }
      }

      this.loadDescrType = "";
      this.loadDescrTypeKey = "";
    },
    async loadTaskDataInForm(taskDataToUse) {
      await this.$nextTick();
      this.loading = true;
      const taskData = getMainTaskRulesAndFillRecurringOpts(
        cloneDeep(taskDataToUse)
      );

      makeVirtualTaskVisibleInFormGrid(taskData);
      this.updateTaskData(taskData);
      this.reloadItemData();
    },
    closeForm() {
      this.checkForEditAndCloseForm();
    },
    showRequiredFieldsToast(mode = "repeatEvery") {
      let msgToShow = "";
      if (mode === "repeatEvery") {
        msgToShow = "Frequency and date are required for recurring task";
      }

      if (mode === "repeatIn") {
        msgToShow =
          "Frequency,Interval and date are required for repeated task";
      }

      if (msgToShow) {
        this.showToast(msgToShow);
      }
    },
    async getNextTaskOrCloseForm() {
      await this.$nextTick();

      let nextTaskId = this.nextTaskId;
      let taskData = {};

      if (!nextTaskId) {
        this.closeFormAfterAction = true;
        this.closeDialog();
        return;
      }

      taskData = this.filteredTasks.find(
        (t) => t.key === nextTaskId && !t.isReminderTask
      );

      if (isEmpty(taskData)) {
        this.closeFormAfterAction = true;
        this.closeDialog();
        return;
      }

      taskData = getMainTaskRulesAndFillRecurringOpts(taskData);

      makeVirtualTaskVisibleInFormGrid(taskData);
      this.setTaskData(taskData);
      await this.$nextTick();
      this.reloadItemData();
    },
    async handleTaskContinued(showConfirmBox) {
      let triggerCall = true;

      let onlyCompleteCurrentTask = false,
        createTaskFlaggedOnly = false;
      if (showConfirmBox) {
        const confirmRes = await this.$swal({
          text: "Do you wish to add a next step?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });

        if (
          !confirmRes.isConfirmed &&
          (confirmRes.dismiss === "backdrop" || confirmRes.dismiss === "esc")
        ) {
          this.continuedTaskData = {};
          return;
        }

        if (!confirmRes.isConfirmed && confirmRes.dismiss === "cancel") {
          onlyCompleteCurrentTask = true;
        }
      }

      if (triggerCall && !isEmpty(this.continuedTaskData)) {
        this.loading = true;
        let newTaskData = cloneDeep(this.taskCopy);
        let taskDataForGoogle = {};
        let googleTasksToAdd = [];
        let localActiveProject = {};
        let newRawTaskData = {};
        let userActionsBatch = [];
        let addToGoogle = false;
        let rawTaskDataForLocalSave = {};
        const currTaskData = this.taskData;
        const dbUpdates = {};
        let addTask = true;
        let isTaskConvertedToNote = false;
        if (
          currTaskData.taskType !== newTaskData.taskType &&
          newTaskData.taskType === "note"
        ) {
          addTask = false;
          isTaskConvertedToNote = true;
        } else if (onlyCompleteCurrentTask) {
          addTask = false;
        }

        const taskDataRes = createDataForRecurringTask(
          this.continuedTaskData,
          "update",
          undefined,
          currTaskData
        );

        const updatedDataOfCurrTask = taskDataRes.updatedTaskData;
        const editedTaskData = taskDataRes.editedTaskData;
        userActionsBatch.push({
          type: TASK_ACTIONS.EDIT,
          data: editedTaskData,
        });

        if (addTask) {
          newTaskData = removeProps(newTaskData, [
            "recurrence",
            "linkedTo",
            "coRelationalId",
            "frequency",
            "recurrenceCount",
            "repeatMonthDate",
            "recurrenceRepeatDay",
            "snapshots",
          ]);

          const createdDateAndTime = getDateAndTimeFromDueDate(
            !createTaskFlaggedOnly ? this.dateCopy : null
          );

          newTaskData.resources = getAllMentionedResourceIdsFromText(
            newTaskData.title
          );
          let createdTaskData = duplicateTask(
            {
              ...newTaskData,
              created: getCurrDate(),
              ...createdDateAndTime,
              description: "",
              userPosition: increment(newTaskData.userPosition, 1),
              customPositionIndex: increment(
                newTaskData.customPositionIndex,
                1
              ),
            },
            newTaskData.order
          );
          createdTaskData.title = !createTaskFlaggedOnly
            ? newTaskData.title
            : convertStringToPTagString("Enter the next step here");
          createdTaskData.key = createUniqueId();
          createdTaskData.modified = "";
          createdTaskData.flag = !createTaskFlaggedOnly
            ? createdDateAndTime.due !== "none"
            : true;

          if (
            this.isGoogleCalSyncEnabled &&
            createdDateAndTime.due !== "none"
          ) {
            createdTaskData.googleEventId = convertTaskKeyForGoogleCal(
              createdTaskData.key
            );

            createdTaskData.googleCalendarId =
              GoogleCalHelpers.getStoredCalId();
            taskDataForGoogle = createTaskDataForGoogleCal({
              ...newTaskData,
              desscription: createdTaskData.description,
              dueAsDate: createDateTimeFromDue(
                createdDateAndTime.due,
                createdDateAndTime.time
              ),
              taskKey: createdTaskData.key,
            });

            addToGoogle = true;
          }

          dbUpdates[`/tasks/${createdTaskData.key}`] = { ...createdTaskData };
          newRawTaskData = { ...createdTaskData };
          createdTaskData.restoreOldIdData = {
            key: currTaskData.key,
            restoreFrom: "raw",
          };

          userActionsBatch.push({
            type: TASK_ACTIONS.ADD,
            data: createdTaskData,
          });

          if (addToGoogle) {
            googleTasksToAdd.push({
              key: createdTaskData.key,
              data: taskDataForGoogle,
            });
          }
        }
        dbUpdates[`/tasks/${currTaskData.key}`] = updatedDataOfCurrTask;
        await DatabaseInterface.update(dbUpdates, this.userInfo.uid);

        getUserActions().addTaskAction({
          data: userActionsBatch,
          type: TASK_ACTIONS.ACTIONS_BATCH,
        });

        if (addToGoogle) {
          this.addTasksToGoogleCal(googleTasksToAdd);
        }

        const currTaskKey = currTaskData.key;
        const processedUpdatedTask = processTask({
          ...updatedDataOfCurrTask,
        });
        const localListUpdates = {
          tasksToUpdate: [{ key: currTaskKey, updates: processedUpdatedTask }],
        };

        const currReminderTasks = getReminderTasksLinkedToTaskId(
          this.processedTasks,
          currTaskData.key
        );
        localListUpdates.tasksToRemove = [...currReminderTasks];

        if (addTask) {
          let reminderTasks = [];
          if (checkIfTaskHasReminder(newRawTaskData)) {
            reminderTasks.push(...createReminderTasks(newRawTaskData));
          }
          rawTaskDataForLocalSave = newRawTaskData;
          const processedAddedTask = processTask({ ...newRawTaskData });
          localListUpdates.tasksToAdd = [
            { ...processedAddedTask },
            ...reminderTasks,
          ];
          localActiveProject = cloneDeep(this.projects[newRawTaskData.project]);
        }

        if (!isEmpty(localActiveProject)) {
          this.activeProject = localActiveProject;
        }

        await this.$nextTick();
        await addOrRemoveOrUpdateTasksInLocalTasksList({
          ...localListUpdates,
        });
        await Vue.nextTick();
        let callSuccessAction = true;
        if (!isEmpty(rawTaskDataForLocalSave)) {
          this.updateTaskData(
            processTask(
              getMainTaskRulesAndFillRecurringOpts(rawTaskDataForLocalSave)
            )
          );
        } else if (!isTaskConvertedToNote && onlyCompleteCurrentTask) {
          this.completeCurrAndShowNextTask = true;
          this.doNotSetDataInForm = true;
          this.nextTaskId =
            this.filteredTasks[increment(this.currItemIndex, 1)]?.key;
        }

        if (isTaskConvertedToNote) {
          callSuccessAction = false;
          this.setDataForConvertMode({
            dataList: [
              {
                ...this.taskCopy,
              },
            ],
            typeToUse: "note",
            calledFrom: "edit-form",
          });
        }

        if (callSuccessAction) {
          this.callActionAfterSubmitSuccess();
        }
      }
    },
    async updateTaskData(taskData) {
      if (!this.doNotSetDataInForm) {
        this.setTaskData(taskData);
        await this.$nextTick();
        this.reloadItemData();
      }
    },
    async handleTaskAction() {
      let taskDataToUse = cloneDeep(this.taskData);

      const isTaskRecurring = checkIfTaskIsRecurring(taskDataToUse);
      const isTaskRepeatIn = checkIfTaskIsRepeatIn(taskDataToUse);
      const isTaskContinued =
        checkIfTaskIsContinued(taskDataToUse) && !isTaskRecurring;
      const isNormalTask =
        !isTaskRepeatIn && !isTaskRecurring && !isTaskContinued;

      taskDataToUse = removeProps(taskDataToUse, [
        "recurrenceCount",
        "repeatMonthDate",
        "recurrenceRepeatDay",
      ]);

      const isCompleted = !this.taskData.completed;

      if (isCompleted) {
        if (isTaskRepeatIn) {
          this.loading = true;
          const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
            fieldName: "completed",
            currValue: true,
            rowId: taskDataToUse.key,
            oldValue: false,
            currRowData: taskDataToUse,
          });
          const createdRowData = createTaskRowDataByCell(
            taskDataToUse,
            "completed",
            true,
            false
          );
          const { repeatedTaskData } =
            await this.completeCurrTaskAndCreateNextRepeatTask({
              key: taskDataToUse.key,
              updates,
              editedData,
              updatedRowData: createdRowData,
              updateSelectedTasks: false,
            });

          this.updateTaskData(processTask(repeatedTaskData));
        } else if (isTaskContinued) {
          let taskData = JSON.parse(JSON.stringify(this.taskCopy || {}));
          let taskTitleToStore = taskData.title && taskData.title.trim();

          taskData = cleanTask(taskData, [
            "recurrenceCount",
            "repeatMonthDate",
            "recurrenceRepeatDay",
            "repeatMode",
          ]);
          taskData.completed = true;
          taskData.completedOn = getCurrDate();

          taskData = {
            ...taskData,
            dueAsDate: this.taskData.dueAsDate,
            title: taskTitleToStore,
          };
          this.continuedTaskData = taskData;
          this.handleTaskContinued(true);
        } else if (isTaskRecurring || isNormalTask) {
          this.loading = true;
          this.nextTaskId =
            this.filteredTasks[increment(this.currItemIndex, 1)]?.key;
          await this.updateRecurringOrNormalTasks([taskDataToUse], {
            completed: true,
          });
          await this.getNextTaskOrCloseForm();
        }
      } else {
        this.updateSingleTask({ completed: false });
      }
    },
    handleDescrDataChange(val) {
      if (this.areChangesSaved) this.areChangesSaved = false;

      if (this.activeDescr === "resource") {
        this.resourceDescrData = val;
      } else {
        this.descrData = val;
      }

      this.debouncedConvertAndSetDescrDelta(val);

      // this.debouncedTextSave(val);
    },
    async saveResourceDescr(descrData) {
      if (
        this.activeDescr !== "resource" ||
        !this.activeResourceId ||
        isEmpty(this.rawResourcesMap[this.activeResourceId])
      )
        return;

      const currResourceData = this.rawResourcesMap[this.activeResourceId];

      const currResourceDescr = currResourceData.descr || "";
      const updatedResourceDescr = descrData || "";

      const currTextOfDescr = getTextContentFromHTML(currResourceDescr);
      const updatedTextOfDescr = getTextContentFromHTML(updatedResourceDescr);

      if (currTextOfDescr === updatedTextOfDescr) return;

      let updatedResourceData = {
        descr: updatedResourceDescr,
      };
      const { updates, editedData } = createUpdateAndEditedResourceData(
        { ...updatedResourceData },
        currResourceData
      );

      this.addOrRemoveOrUpdateResourcesInList({
        resourcesToUpdate: [
          {
            updates,
            editedData: {
              ...editedData,
              refreshDescr: true,
              refreshFormType: "task",
            },
            key: currResourceData.key,
          },
        ],
      });

      this.areChangesSaved = true;
      await this.$nextTick();

      this.focusOnDescrInput(false);
    },
    async saveTaskDescr(descrData) {
      if (this.activeDescr !== "task" || this.isDuplicateModeEnabled) return;

      let taskDataToUse = {};
      taskDataToUse = this.tasks.find((t) => t.key === this.taskData.key);

      if (!isEmpty(taskDataToUse)) {
        const currTaskDescr =
          this.rawTasksMap[this.getTaskId(taskDataToUse)]?.description || "";
        // const editorInstance = this.$refs.editor.getQuillInstance();
        const currTextOfDescr = getTextContentFromHTML(currTaskDescr);
        const updatedTextOfDescr = getTextContentFromHTML(descrData);
        if (currTextOfDescr === updatedTextOfDescr) return;

        let updatedTaskData = {},
          // eslint-disable-next-line no-unused-vars
          refousOnTitle = false;
        if (isTaskRecurring(taskDataToUse)) {
          const { createdData } = await this.updateSingleRecurringTask(
            taskDataToUse,
            { ...taskDataToUse, description: descrData },
            false,
            false,
            true,
            undefined,
            undefined,
            {
              updateDescrText: true,
            }
          );

          updatedTaskData = { ...createdData };
          refousOnTitle = true;
        } else {
          const { updates, editedData } = createUpdateAndEditedTaskDataByCell({
            fieldName: "description",
            currValue: descrData || "",
            rowId: taskDataToUse.key,
            oldValue: taskDataToUse.description || "",
            currRowData: taskDataToUse,
          });
          const createdRowData = createTaskRowDataByCell(
            taskDataToUse,
            "description",
            descrData || "",
            taskDataToUse.description || ""
          );

          updatedTaskData = {
            ...createdRowData,
          };

          DatabaseInterface.update(updates, this.userInfo.uid);
          addOrRemoveOrUpdateTasksInLocalTasksList(
            {
              tasksToUpdate: [
                {
                  key: taskDataToUse.key,
                  updates: { ...updatedTaskData },
                },
              ],
            },
            false
          );
          getUserActions().addTaskAction({
            type: "edit",
            data: {
              ...editedData,
              updateDescrText: true,
              descrType: "",
              key: taskDataToUse.key,
            },
          });

          if (this.isGoogleCalSyncEnabled) {
            this.updateTaskDataInGoogleCal(
              {
                key: taskDataToUse.key,
                updates: { description: descrData },
                currData: this.rawTasksMap[taskDataToUse.key],
              },
              true
            );
          }
        }

        this.areChangesSaved = true;

        if (!isEmpty(updatedTaskData)) {
          this.updateEditTaskData(updatedTaskData);
        }

        // await this.$nextTick();
        setTimeout(() => {
          EventEmitter.emit(REFRESH_TASKS_EVENT);
        });

        // setTimeout(() => {
        //   console.debug("ACTIVE", document.activeElement);
        // }, 0);
        // await Vue.nextTick();
        // setTimeout(() => {
        //   console.debug("SKL", editorInstance.getSelection().index);
        //   editorInstance.setSelection(editorInstance.getSelection().index, 0);
        // }, 0);
      }
    },
    updateEditTaskData(taskData) {
      const processedTaskData = processTask(
        getMainTaskRulesAndFillRecurringOpts(taskData)
      );

      this.updateCurrTaskData(processedTaskData);
    },
    handleTitleChange(val, type = "default") {
      // if (this.areChangesSaved) this.areChangesSaved = false;
      // if (this.activeDescr === "task") {

      if (type === "default") {
        this.taskCopy.title = val;
      }

      if (type === "resource") {
        this.resourceExtraData.title = val;
      }

      // }d
      this.debouncedConvertAndSetDelta(val, type);
      // this.debouncedTextSave(val, "title");
    },

    handleTaskTypeChange(val) {
      this.taskCopy.taskType = val;

      if (val === "note") {
        this.nextTaskId =
          this.filteredTasks[increment(this.currItemIndex, 1)]?.key;
        this.convertedTaskToNote = true;
        this.doNotSetDataInForm = true;
        this.setDataForConvertMode({
          dataList: [
            {
              ...this.taskCopy,
            },
          ],
          typeToUse: "note",
          calledFrom: "edit-form",
        });
      } else {
        this.nextTaskId = undefined;
        this.convertedTaskToNote = false;
        this.doNotSetDataInForm = false;
      }
    },
    toggleContingent() {
      this.taskCopy.isContingent = !this.taskCopy.isContingent;
    },

    toggleReminderPause() {
      const pausedReminderDays =
        cloneDeep(this.taskCopy.pausedReminderDays) || [];

      if (!isEmpty(pausedReminderDays)) {
        const storedIndex = pausedReminderDays.indexOf(currFormattedDate);

        if (storedIndex >= 0) {
          pausedReminderDays.splice(storedIndex, 1);
        } else {
          pausedReminderDays.push(currFormattedDate);
        }
      } else {
        pausedReminderDays.push(currFormattedDate);
      }

      this.taskCopy.pausedReminderDays = [...pausedReminderDays];
    },
    handleReminderDaysVal(val) {
      if (isNumeric(val)) {
        const parsedVal = parseInt(val, 10);
        const finalVal = parsedVal > 0 ? parsedVal : "0";
        this.taskCopy.reminderDays = finalVal;

        if (finalVal === "0") {
          this.currPausedReminderDays = !isEmpty(
            this.taskCopy.pausedReminderDays
          )
            ? [...this.taskCopy.pausedReminderDays]
            : undefined;
          this.taskCopy.pausedReminderDays = [];
        } else {
          if (!isEmpty(this.currPausedReminderDays)) {
            this.taskCopy.pausedReminderDays = [...this.currPausedReminderDays];
          }
        }
      } else {
        this.taskCopy.reminderDays = "0";
      }
    },
    handleDateBlur() {
      let dateErrorMsg = "";
      if (this.isTaskInRepeatEveryMode || this.isTaskInRepeatInMode) {
        if (!isValidDate(this.dateCopy)) {
          dateErrorMsg = "Date is required!";
        }
      }

      this.startDateError = dateErrorMsg;
    },
    updateDate(date) {
      const isCurrTaskDateValid =
        this.taskData.dueAsDate && isValidDate(this.taskData.dueAsDate);

      const isNewTaskDateValid = date && isValidDate(date);

      if (!isCurrTaskDateValid && isNewTaskDateValid && !this.taskCopy.flag) {
        this.taskCopy.flag = true;
      }
      this.dateCopy = date;
      if (this.taskCopy.isRecurring && isValidDate(date)) {
        this.startDateError = "";
        this.taskCopy.recurrenceRepeatDay = getComponentsFromDate(date).weekDay;
        if (this.taskCopy.frequency === "monthly") {
          this.taskCopy.repeatMonthDate = `${formatDate(
            date,
            DAY_FORMAT
          )}_monthly`;
        }
      }

      this.$nextTick(this.createRecurringDates);
    },
    handleInternvalCount(val) {
      this.taskCopy.repeatInfo = {
        ...this.taskCopy.repeatInfo,
        count: val !== "" ? parseInt(val, 10) : undefined,
      };
      // this.taskCopy.repeatInfo.count =
    },
    handleFreqBlur() {
      let freqError = "";
      let showRequiredError = false;
      if (this.isTaskInRepeatEveryMode) {
        if (this.taskCopy.isRecurring) {
          if (!this.taskCopy.frequency) {
            showRequiredError = true;
          }
        }
      }

      if (this.isTaskInRepeatInMode) {
        if (
          !isEmpty(this.taskCopy.repeatInfo) &&
          !this.taskCopy.repeatInfo.frequency
        ) {
          showRequiredError = true;
        }
      }

      if (showRequiredError) {
        freqError = "Frequency is required";
      }

      this.freqError = freqError;
    },
    handleFrequencyChange(val) {
      this.taskCopy.frequency = val;
      this.freqError = "";

      if (this.isTaskInRepeatEveryMode) {
        if (val === "weekly" && isValidDate(this.dateCopy)) {
          this.taskCopy.recurrenceRepeatDay = getComponentsFromDate(
            this.dateCopy
          ).weekDay;
        }

        if (val === "monthly" && isValidDate(this.dateCopy)) {
          this.taskCopy.repeatMonthDate = `${formatDate(
            this.dateCopy,
            DAY_FORMAT
          )}_monthly`;
        }
        this.$nextTick(this.createRecurringDates);
      }

      if (this.isTaskInRepeatInMode) {
        this.taskCopy.repeatInfo = {
          ...this.taskCopy.repeatInfo,
          frequency: val,
        };
      }
    },
    handleRepeatTypeSelection(val) {
      let isDateValid = true;

      if (val !== "never") {
        if (val === "every") {
          let changeRestVals = true;
          if (!isValidDate(this.dateCopy)) {
            isDateValid = false;
            changeRestVals = false;
          }
          let frequencyToSet, frequencyCount;

          if (!this.taskCopy.frequency) {
            frequencyToSet = "daily";
          }

          if (
            !checkIfTaskIsRecurring(this.taskData) &&
            checkIfTaskIsRepeatIn(this.taskData)
          ) {
            frequencyToSet = this.taskData.repeatInfo.frequency;
            frequencyCount = this.taskData.repeatInfo.count;
          }

          if (frequencyToSet !== undefined) {
            this.taskCopy.frequency = frequencyToSet;

            if (frequencyCount !== undefined) {
              this.taskCopy.recurrenceCount = frequencyCount;
            }
          }

          if (changeRestVals) {
            if (this.taskCopy.frequency === "monthly") {
              this.taskCopy.repeatMonthDate = `${formatDate(
                this.dateCopy,
                DAY_FORMAT
              )}_monthly`;
            }

            if (!this.taskCopy.recurrenceRepeatDay) {
              this.taskCopy.recurrenceRepeatDay = getComponentsFromDate(
                this.dateCopy
              ).weekDay;
            }
          }
          this.taskCopy.isRecurring = true;
          this.taskCopy.repeatInfo = {};
        }

        if (val === "in") {
          if (!isValidDate(this.dateCopy)) {
            isDateValid = false;
          }

          if (isEmpty(this.taskData.repeatInfo)) {
            let repeatInfo = {
              count: 1,
              frequency: "daily",
            };

            if (
              !checkIfTaskIsRepeatIn(this.taskData) &&
              checkIfTaskIsRecurring(this.taskData)
            ) {
              const mainTaskData =
                this.rawTasksMap[this.getTaskId(this.taskData)];

              if (!isEmpty(mainTaskData)) {
                const { rule } = parseRuleDataFromTaskRecurrenceRules({
                  recurrence: mainTaskData.recurrence,
                  dueAsDate: createDateTimeFromDue(
                    mainTaskData.due,
                    mainTaskData.time
                  ),
                });
                const parsedRuleOptions = rule.options;
                repeatInfo.frequency =
                  TASK_FREQ_FROM_RRULE_MAP[parsedRuleOptions.freq];
                repeatInfo.count = parsedRuleOptions.interval;
              }
              // repeatInfo.
            }

            this.taskCopy.repeatInfo = { ...repeatInfo };
          } else {
            this.taskCopy.repeatInfo = {
              ...this.taskData.repeatInfo,
            };
          }

          this.taskCopy.isRecurring = false;
          this.taskCopy.recurrence = [];
          this.taskCopy.frequency = "";
        }

        if (!isDateValid) {
          this.startDateError = "Date is required";
        }

        if (this.currActiveMode === "continue") {
          this.resetModes();
          this.currActiveMode = "";
        }
      } else {
        this.taskCopy.frequency = this.taskData.frequency
          ? this.taskData.frequency
          : "";
        this.taskCopy.recurrence = areTaskRecurrenceRulesValid(
          this.taskData.recurrence
        )
          ? this.taskData.recurrence
          : null;

        this.taskCopy.coRelationalId = this.taskData.coRelationalId
          ? this.taskData.coRelationalId
          : "";

        this.taskCopy.repeatInfo = {};
        this.taskCopy.isRecurring = false;
        this.freqError = "";
        this.startDateError = "";
        this.resetModes();
        this.currActiveMode = "";
      }

      this.taskCopy.repeatMode = val;

      this.$nextTick(this.createRecurringDates);
    },
    createRecurringDates() {
      const startDateTime = this.dateCopy;
      const isRecurring = this.taskCopy.isRecurring;

      const currFrequency = frequencyOptsMap[this.taskCopy.frequency];
      const currRecurrenceCount = this.taskCopy.recurrenceCount;
      const currRepeatDay = this.taskCopy.recurrenceRepeatDay;
      let rules = [];
      if (
        isRecurring &&
        isValidDate(startDateTime) &&
        currFrequency &&
        currFrequency.value !== "once"
      ) {
        // Calculate

        const res = createAdditionalDates({
          startDateTime,
          frequency: currFrequency,
          repeatCount: currRecurrenceCount,
          repeatDay: currRepeatDay,
        });
        rules = res.rules;
      }

      if (!isEmpty(rules) && !this.taskCopy.coRelationalId) {
        this.taskCopy.coRelationalId = createUniqueId();
      }

      this.taskCopy.recurrence = rules;
    },
    handleRepeatTypeFocus() {
      this.$refs.repeatSelector.openInput();
    },
    handleRepeatFrequencySelector() {
      if (this.isTaskInRepeatEveryMode) {
        this.$refs.repeatEveryIntervalSelector.blurInput();
        this.$refs.repeatEveryFrequencySelector.openInput();
      } else if (this.isTaskInRepeatInMode) {
        this.$refs.repeatInFrequencySelector.openInput();
      }
    },
    handleRepeatInternalSelectorFocus() {
      if (this.isTaskInRepeatEveryMode) {
        this.$refs.repeatEveryIntervalSelector.openInput();
      } else if (this.isTaskInRepeatInMode) {
        this.$refs.repeatInIntervalInput.focus();
      }
    },
    async changeProject(val) {
      this.taskCopy.project = val;
      this.activeProject = cloneDeep(this.projects[val]);

      // if (!this.activeProject.priority && this.taskCopy.priority) {
      //   this.activeProject.priority = this.taskCopy.priority;
      // }
      // if (this.projects[val] && this.projects[val].priority) {
      //   this.taskCopy.priority = this.projects[val].priority;
      // }
      this.prevResourceSelectId = this.activeResourceId;
      this.setDataForArea(this.activeDescr !== "task");
      await this.$nextTick();
      this.projectId = val;

      await this.$nextTick();
      this.addTaskInTaskList(val);
    },
    async addTaskInTaskList(projectVal) {
      if (!isUndefinedVal(projectVal)) {
        await addOrRemoveOrUpdateTasksInLocalTasksList(
          {
            tasksToUpdate: [
              {
                key: this.taskData.key,
                updates: { project: projectVal, isTemp: true },
              },
            ],
          },
          false
        );
        await this.$nextTick();

        this.showList = checkIfTasksWithProjectExists(
          projectVal,
          this.processedTasks
        );
        this.selectedTaskRowKeys = [this.taskData.key];
        this.$refs.historyTable.refreshList();
      } else {
        this.showList = false;
        this.selectedTaskRowKeys = [];
      }
    },
    updateProjectPriority(val) {
      if (this.activeProject) {
        this.activeProject.priority = val;
        this.taskCopy.priority = val;
      }
    },
    setRef(ref, name) {
      this.$refs[name] = ref;
    },
    resetModes(ignoreComplete = false) {
      this.isInContinueMode = false;
      this.isDuplicateModeEnabled = false;
      this.isCompleteAndCloneModeEnabled = false;
      this.isCompleteRepeatInEnabled = false;
      this.currUpdatedTaskData = {};
      this.disableRepeatSelector = false;
      this.completeCurrAndShowNextTask = false;
      this.loadTaskFromList = false;
      this.taskDataToLoad = {};
      this.doNotSetDataInForm = false;
      this.dateCopy = cloneDeep(this.taskData.dueAsDate);
      this.taskSnapShots = !isEmpty(this.tasksOfObjectives)
        ? this.tasksOfObjectives
        : [];

      if (!ignoreComplete) {
        this.taskCopy.completed = this.taskData.completed || false;
      }
    },
    handleIntervalChange(val) {
      this.taskCopy.recurrenceCount = val;
      this.$nextTick(this.createRecurringDates);
    },
    async toggleCompleteTask() {
      const isDuplicateModeEnabled = this.isDuplicateModeEnabled;
      this.resetModes(true);
      await this.$nextTick();
      if (isDuplicateModeEnabled) {
        this.setDescrText(
          this.rawTasksMap[this.getTaskId(this.taskData)].description
        );
      }
      this.activeDescr = "task";
      this.taskCopy.completed = !this.taskCopy.completed;
      this.currActiveMode =
        this.taskData.completed !== this.taskCopy.completed ? "complete" : "";
      this.$nextTick(() => {
        if (this.taskCopy.completed) {
          this.completeCurrAndShowNextTask = true;
          this.doNotSetDataInForm = true;
          this.nextTaskId =
            this.filteredTasks[increment(this.currItemIndex, 1)]?.key;
        }
        this.focusOnTitleInput(true);
      });
    },
    focusOnTitleInput(selectText) {
      setTimeout(() => {
        this.$refs.titleInput?.focusOnEl(selectText);
      }, 0);
    },
    focusOnDescrInput(selectText) {
      setTimeout(() => {
        this.$refs.editor?.focusOnEl(selectText);
      }, 0);
    },
    async enableDuplicateMode() {
      if (!this.isDuplicateModeEnabled) {
        this.resetModes();
        this.currActiveMode = "duplicate";
        this.isDuplicateModeEnabled = true;
        this.dateCopy = new Date();
        this.activeDescr = "task";
        await this.$nextTick();
        this.setDescrText("");
      }
      this.focusOnTitleInput(true);
    },
    async handleTaskDelete() {
      this.resetModes();
      this.currActiveMode = "delete";

      const res = await this.$swal({
        title: "Are you sure?",
        text: "This will remove the task",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        iconColor: PRIMARY_COLOR,
        confirmButtonColor: PRIMARY_COLOR,
      });

      if (res.isConfirmed) {
        this.nextTaskId =
          this.filteredTasks[increment(this.currItemIndex, 1)]?.key;
        this.loading = true;
        this.removeRecurringOrNormalTasks([this.taskData]);
        this.getNextTaskOrCloseForm();
      } else if (
        res.dismiss === "cancel" ||
        res.dismiss === "backdrop" ||
        res.dismiss === "esc"
      ) {
        this.currActiveMode = "";
      }
    },
    handleEditModeChange(btnType) {
      switch (btnType) {
        case "complete":
          this.toggleCompleteTask();
          break;
        case "duplicate":
          this.enableDuplicateMode();
          break;
        case "delete":
          this.handleTaskDelete();
          break;
      }
    },
    async handleTabFocus(e, nextFocus, previousFocus) {
      const previousKeys = [
        "flag-toggler",
        "repeat-interval-selector",
        "repeat-type-selector",
        "task-priority",
        "task-type-selector",
        "title",
        "objective",
        "reminder-input",
      ];
      const keyCode = e.which ? e.which : e.keyCode;

      const paths = getPathsFromEvent(e);

      const shiftKey = e.shiftKey;
      let jumpToPriority = false;
      let jumpToDatePicker = false;
      let jumpToRepeatInfo = false;
      if (keyCode !== 9) return;

      e.stopPropagation();

      const datePickerParentFound = findParentFromPathsByClassName(paths, [
        "date-picker-wrapper",
      ]);

      const prioritySelectorFound = findParentFromPathsByClassName(paths, [
        "task-priority-selector",
      ]);
      const repeatIconEl = findParentFromPathsByClassName(paths, [
        "repeat-info-icon",
      ]);

      const fromFrequencySelector = findParentFromPathsByClassName(paths, [
        "repeat-every-frequency",
        "repeat-in-frequency",
      ]);

      if (shiftKey && previousKeys.includes(previousFocus)) {
        e.preventDefault();
      }

      if (
        shiftKey &&
        datePickerParentFound &&
        this.isInContinueMode &&
        this.isTaskNotRepeatInOrWithProject
      ) {
        e.preventDefault();
        jumpToPriority = true;
      } else if (
        this.isInContinueMode &&
        this.isTaskNotRepeatInOrWithProject &&
        prioritySelectorFound
      ) {
        e.preventDefault();
        jumpToDatePicker = true;
      } else if (this.repeatInfo.showTxt && repeatIconEl && !shiftKey) {
        e.preventDefault();
        jumpToDatePicker = true;
      }

      if (shiftKey && datePickerParentFound && this.repeatInfo.showTxt) {
        e.preventDefault();
        jumpToRepeatInfo = true;
      }

      setTimeout(() => {
        if (shiftKey) {
          if (jumpToRepeatInfo) {
            const iconEl = this.$el.querySelector(".repeat-info-icon");
            iconEl?.focus();
          } else if (jumpToPriority) {
            const el =
              this.$refs.taskPrioritySelector.$el.querySelector("input");
            el?.focus();
            this.$refs.taskPrioritySelector.$refs.select.openInput();
          } else {
            if (previousFocus === "flag-toggler") {
              this.$refs.editor.blurInput();
              const btn = this.$el.querySelector(".flag-toggle-btn");
              btn?.focus();
            }

            if (previousFocus === "reminder-input") {
              const inputEl = this.$el.querySelector(".reminder-input input");
              inputEl?.focus();
            }

            if (previousFocus === "repeat-interval-selector") {
              if (this.isTaskInRepeatEveryMode) {
                this.$refs.repeatEveryFrequencySelector.blurInput();
                this.$refs.repeatEveryIntervalSelector.openInput();
              } else if (this.isTaskInRepeatInMode) {
                this.$refs.repeatInFrequencySelector.blurInput();
                this.$refs.repeatInIntervalInput.focus();
                this.$refs.repeatInIntervalInput.$el
                  .querySelector("input")
                  ?.select();
              }
            }

            if (previousFocus === "repeat-type-selector") {
              if (this.isTaskInRepeatEveryMode) {
                this.$refs.repeatEveryIntervalSelector.blurInput();
              } else if (this.isTaskInRepeatInMode) {
                this.$refs.repeatInIntervalInput.blur();
              }
              const inputEl =
                this.$refs.repeatSelector.$el.querySelector("input");
              inputEl?.focus();
              this.$refs.repeatSelector.openInput();
            }

            if (previousFocus === "task-priority") {
              this.$refs.repeatSelector.$refs.select.blur();
              setTimeout(() => {
                const el =
                  this.$refs.taskPrioritySelector.$el.querySelector("input");
                el?.focus();
                this.$refs.taskPrioritySelector.$refs.select.openInput();
              }, 0);
            }

            if (previousFocus === "task-type-selector") {
              this.$refs.repeatSelector.$refs.select.blur();
              setTimeout(() => {
                const el =
                  this.$refs.taskTypeSelector.$el.querySelector("input");
                el?.focus();
                this.$refs.taskTypeSelector.$refs.select.openInput();
              }, 0);
            }

            if (previousFocus === "title") {
              this.$refs.repeatSelector.$refs.select.blur();
              this.focusOnTitleInput(true);
            }

            if (previousFocus === "objective") {
              this.$refs.titleInput.blurInput();
              this.$refs.objectiveInput.openInput();
            }
          }
        } else {
          if (nextFocus === "title") {
            this.focusOnTitleInput(true);
          }

          if (nextFocus === "task-type-selector") {
            const el = this.$refs.taskTypeSelector.$el.querySelector("input");
            el?.focus();
            el?.click();
          }
          if (nextFocus === "task-priority") {
            const el =
              this.$refs.taskPrioritySelector.$el.querySelector("input");
            el?.focus();

            el?.click();
          }

          if (!jumpToDatePicker && nextFocus === "task-repeat-selector") {
            const inputEl =
              this.$refs.repeatSelector.$el.querySelector("input");
            inputEl?.focus();
            this.$refs.repeatSelector.openInput();
          }

          if (nextFocus === "repeat-interval-selector") {
            this.$refs.repeatSelector.blurInput();
            if (this.isTaskInRepeatEveryMode) {
              this.$refs.repeatEveryIntervalSelector.openInput();
            } else if (this.isTaskInRepeatInMode) {
              this.$refs.repeatInIntervalInput.focus();
            } else {
              const inputEl = this.$el
                .querySelector(".advance-date-input-wrapper")
                .querySelector("input");
              inputEl?.select();
              inputEl?.focus();
            }
          }

          if (nextFocus === "repeat-frequency-selector") {
            if (this.isTaskInRepeatEveryMode) {
              this.$refs.repeatEveryIntervalSelector.blurInput();
              this.$refs.repeatEveryFrequencySelector.openInput();
            } else if (this.isTaskInRepeatInMode) {
              this.$refs.repeatInFrequencySelector.openInput();
            }
          }

          if (
            (!this.repeatInfo.showTxt && nextFocus === "date-picker") ||
            jumpToDatePicker
          ) {
            if (!jumpToDatePicker) {
              // this.$refs.taskPrioritySelector.$refs.select.blurInput();
              if (this.isTaskInRepeatEveryMode) {
                this.$refs.repeatEveryFrequencySelector.blurInput();
              } else if (this.isTaskInRepeatInMode) {
                this.$refs.repeatInFrequencySelector.blurInput();
              }
            }

            const inputEl = this.$el
              .querySelector(".advance-date-input-wrapper")
              .querySelector("input");
            inputEl?.select();
            inputEl?.focus();
          }

          if (
            fromFrequencySelector &&
            this.repeatInfo.showTxt &&
            !jumpToDatePicker
          ) {
            if (this.isTaskInRepeatEveryMode) {
              this.$refs.repeatEveryFrequencySelector.blurInput();
            } else if (this.isTaskInRepeatInMode) {
              this.$refs.repeatInFrequencySelector.blurInput();
            }
            const iconEl = this.$el.querySelector(".repeat-info-icon");
            iconEl?.focus();
          }

          // if (nextFocus === "contingent-toggler") {
          //   const btn = this.$el.querySelector(".contingent-toggle-btn");
          //   btn?.focus();
          // }
          if (nextFocus === "flag-toggler") {
            const btn = this.$el.querySelector(".flag-toggle-btn");
            btn?.focus();
          }

          if (nextFocus === "description") {
            this.$refs.editor.focusOnEl(false);
          }

          if (nextFocus === "repeat-info") {
            const iconEl = this.$el.querySelector(".repeat-info-icon");
            iconEl?.focus();
          }
        }
      }, 0);
    },
    checkIfTaskIsConvertedToRecurring() {
      const tasksRulesAreValid =
        this.taskData.recurrenceRepeatDay !== undefined;
      const newTaskRulesAreValid = areTaskRecurrenceRulesValid(
        this.taskCopy.recurrence
      );

      return (
        !tasksRulesAreValid &&
        (newTaskRulesAreValid || this.taskCopy.isRecurring)
      );
    },
    checkAndTriggerResourceListUpdate() {
      // const newKeys = Object.keys(newVal);
      // const oldKeys = Object.keys(oldVal);
      // let updateList = false;
      // if (newKeys.length === oldKeys.length) {
      //   updateList = newKeys.some((k) => {
      //     return (
      //       newVal[k].title !== oldVal[k].title ||
      //       newVal[k].tag !== oldVal[k].tag
      //     );
      //   });
      // }
      // if (updateList) {
      // }
    },
    resetResourceData() {
      this.resourceExtraData = {
        title: "",
        tag: "",
      };
    },
  },
  computed: {
    descrTogglerData() {
      // let icon = "mdi-menu-down";
      let label = "Open Description";

      if (this.isDescrOpen) {
        // icon = "mdi-menu-right";
        label = "Collapse Description";
      }
      return {
        iconClasses: {
          "e-icons e-gdiagonaldown": true,
          "e-icon-gdownarrow": this.isDescrOpen,
          "e-icon-grightarrow": !this.isDescrOpen,
        },
        label,
      };
    },
    isValidPausedDates() {
      return !isEmpty(this.taskCopy.pausedReminderDays);
    },
    mentionEditOpts() {
      let opts = {};

      if (this.taskCopy.project) {
        const resourcesCatgeories = getUserResources().getCategories("list");
        const cateKey =
          resourcesCatgeories?.find((c) => c.refKey === this.taskCopy.project)
            ?.key || "";
        opts.formData = {
          catId: cateKey,
          type: "project",
        };
        opts.useAdditionalFilters = true;
        opts.mentionFiltersProps = {
          catId: cateKey,
          filterByProjectAndCatId: true,
        };
      }

      return opts;
    },
    descrDataToUse() {
      return this.activeDescr === "resource"
        ? this.resourceDescrData
        : this.descrData;
    },
    isActiveDescrTask() {
      return this.activeDescr === "task";
    },
    reminderDaysBtnIconData() {
      const reminderDays = this.taskCopy.reminderDays || null;
      let reminderIcon = "$ctiBellOutlined";
      let iconColor = DARK_GREY_ICON_COLOR;
      let text = "Enable reminder for task";
      if (
        !isUndefinedVal(reminderDays) &&
        this.isValidRemindVal(reminderDays)
      ) {
        reminderIcon = "$ctiBellFilled";
        iconColor = PRIMARY_COLOR;
        text = "Remove reminder for task";
      }

      return {
        icon: reminderIcon,
        color: iconColor,
        label: text,
      };
    },
    titleText() {
      return this.taskCopy.title;
    },
    formTitle() {
      let text = "";
      if (this.activeDescr === "task" && !isEmpty(this.activeProject)) {
        text = this.activeProject.title;
      }

      if (
        this.activeDescr === "resource" &&
        this.activeResourceId &&
        !isEmpty(this.rawResourcesMap[this.activeResourceId])
      ) {
        text = this.rawResourcesMap[this.activeResourceId].title;
      }

      return text;
    },
    cloneAndCompleteOrDuplicateModeEnabled() {
      return this.isDuplicateModeEnabled || this.isCompleteAndCloneModeEnabled;
    },
    isRecurringAndModeOn() {
      return (
        this.taskData.recurrenceRepeatDay !== undefined &&
        this.taskCopy.isRecurring &&
        this.cloneAndCompleteOrDuplicateModeEnabled
      );
    },
    createdAndModifiedDates() {
      const { created, modified } = this.createdAndModifiedValues;

      return {
        modifiedDate: convertDateTimeToReadableFormat(
          modified,
          DATE_WITH_TIME_FORMAT_IN_USE,
          "N/A"
        ),
        createdDate: convertDateTimeToReadableFormat(
          created,
          DATE_WITH_TIME_FORMAT_IN_USE,
          "N/A"
        ),
      };
    },
    repeatTypeOpts() {
      let opts = [...repeatTypeOpts];
      // let indexToRemove;

      // if (checkIfTaskIsRecurring(this.taskData)) {
      //   indexToRemove = opts.findIndex((o) => o.value === "in");
      // } else if (checkIfTaskIsRepeatIn(this.taskData)) {
      //   indexToRemove = opts.findIndex((o) => o.value === "every");
      // }

      // if (indexToRemove !== undefined && indexToRemove >= 0) {
      //   opts.splice(indexToRemove, 1);
      // }
      return opts;
    },
    isEditStarted() {
      let isDataChanged = false;
      const checkForEdit = this.dataIsLoaded || this.triggerEditChecker;
      const isOpen = this.isOpen;

      if (
        isOpen &&
        checkForEdit &&
        !isEmpty(this.taskData) &&
        !isEmpty(this.taskCopy)
      ) {
        isDataChanged = this.checkIfAnyEditIsMade();
      }
      return isDataChanged;
    },
    infoIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
    actionBtnProps() {
      const res = {
        btnText: "",
        btnIcon: "",
        size: 19,
      };
      if (this.taskData.completed) {
        res.btnText = "Un-Complete Task";
        res.btnIcon = "$ctiComplete";
        res.size = 22;
      } else {
        const isTaskContinued = checkIfTaskIsContinued(this.taskData);
        const isTaskRecurring = checkIfTaskIsRecurring(this.taskData);
        const isTaskRepeatIn = checkIfTaskIsRepeatIn(this.taskData);
        const { showTxt: showRepeatLabel, txt: repeatLabel } =
          createRepeatInfoLabelFromTaskData(
            JSON.parse(JSON.stringify(this.taskData || {})),
            true
          );
        let btnText = "Complete Task";
        let btnIcon = "$ctiCircleOutline";
        if (isTaskRepeatIn) {
          btnIcon = "$ctiRepeat";
          res.size = 19;
        } else if (isTaskRecurring) {
          btnIcon = "$ctiRepeat";
          res.size = 19;
        } else if (isTaskContinued) {
          btnText = "Continue Task";
          btnIcon = "$ctiLogout";
        }

        res.btnText = showRepeatLabel ? repeatLabel : btnText;
        res.btnIcon = btnIcon;
      }
      return res;
    },
    selectedDateIsValid() {
      const dateToUse = this.dateCopy;
      return isValidDate(dateToUse);
    },
    repeatInfo() {
      // eslint-disable-next-line no-unused-vars
      let showTxt = false;
      let txt = "";

      if (this.isTaskInRepeatEveryMode) {
        showTxt = true;
        if (this.taskCopy.frequency === "weekly" && this.selectedDateIsValid) {
          const repeatDayVal = this.taskCopy.recurrenceRepeatDay;

          const repeatDay = daysOfWeek[repeatDayVal];

          if (!repeatDay) {
            showTxt = false;
          } else {
            txt = `Repeats weekly on ${repeatDay}`;
          }
        } else if (
          this.taskCopy.frequency === "monthly" &&
          this.selectedDateIsValid
        ) {
          const repeatMonthVal = this.taskCopy.repeatMonthDate;
          const [date] = repeatMonthVal?.split("_");

          txt = `Repeats monthly on day ${date}`;
        } else {
          showTxt = false;
        }
      }

      if (this.isTaskInRepeatInMode) {
        showTxt = true;
        const { frequency, count } = this.taskCopy.repeatInfo;
        const isCountMoreThanOne = count > 1;
        let period = "";
        if (frequency === "weekly") {
          period = "week";
        } else if (frequency === "monthly") {
          period = "month";
        } else if (frequency === "yearly") {
          period = "year";
        } else {
          period = "day";
        }

        txt = `Repeats in ${count} ${period}${isCountMoreThanOne ? "" : "s"}`;
      }

      return {
        showTxt: false,
        txt,
      };
    },
    numberInputForRepeatCount() {
      return "Enter number";
    },
    repeatCountOpts() {
      return repeatCountOpts;
    },
    freqOpts() {
      let opts = frequencyOpts;
      let recCount;
      if (this.taskCopy.isRecurring) {
        recCount = this.taskCopy.recurrenceCount;
      } else if (this.isTaskInRepeatInMode) {
        recCount = this.taskCopy.repeatInfo.count;
      }

      if (recCount && recCount > 1) {
        opts = opts.map((opt) => ({ ...opt, label: `${opt.label}s` }));
      }

      return opts;
    },
    isTaskInRepeatEveryMode() {
      return (
        !isEmpty(this.taskCopy) &&
        (this.taskCopy.isRecurring || this.taskCopy.repeatMode === "every")
      );
    },
    isTaskInRepeatInMode() {
      return !isEmpty(this.taskCopy) && this.taskCopy.repeatMode === "in";
    },
    isCurrTaskRepeatIn() {
      return (
        !isEmpty(this.taskData) &&
        !isEmpty(this.taskData.repeatInfo) &&
        this.taskCopy.repeatMode === "in"
      );
    },
    taskDoesNotHaveProject() {
      return !isEmpty(this.taskCopy) && !this.taskCopy.project;
    },
    pauseBtnIconData() {
      let icon = "mdi-bell-sleep";
      let color = DARK_GREY_ICON_COLOR;
      let text = "Hide reminder for today";
      if (
        !isEmpty(this.taskCopy.pausedReminderDays) &&
        isDateInPausedReminderDates(
          this.taskCopy.pausedReminderDays,
          currFormattedDate
        )
      ) {
        icon = "mdi-bell";
        color = PRIMARY_COLOR;
        text = "Show reminder";
      }

      return {
        icon,
        color,
        size: 20,
        text,
      };
    },
    isInMode() {
      return (
        this.isCompleteAndCloneModeEnabled ||
        this.isDuplicateModeEnabled ||
        this.isInContinueMode
      );
    },
    createdAndModifiedValues() {
      const createdProp = "created";
      const modifiedProp = "modified";

      let readCreatedDate = true;
      let readModifiedDate = true;
      let createdValToSet = getCurrDate();
      let modifiedValToSet = "";

      const dataToUse = this.taskData;
      if (
        this.isDuplicateModeEnabled ||
        this.isInContinueMode ||
        this.isCompleteRepeatInEnabled
      ) {
        readCreatedDate = false;
        readModifiedDate = false;
      }

      return {
        created: readCreatedDate ? dataToUse[createdProp] : createdValToSet,
        modified: readModifiedDate ? dataToUse[modifiedProp] : modifiedValToSet,
      };
    },
    showPauseBtn() {
      let showBtn = false;

      if (
        !isUndefinedVal(this.taskCopy.reminderDays) &&
        this.taskCopy.reminderDays > 0 &&
        isValidDate(this.dateCopy)
      ) {
        const { start: startDateOfReminder, end: parsedDueDate } =
          getReminderDaysInfoFromTask({
            ...this.taskCopy,
            ...getDateAndTimeFromDueDate(this.dateCopy),
          });
        showBtn = isDateBetweenDates(
          parseISO(currFormattedDate),
          startDateOfReminder,
          parsedDueDate
        );
      }
      return showBtn;
    },
    isTaskNotRepeatInOrWithProject() {
      return !this.isTaskInRepeatEveryMode && !this.taskDoesNotHaveProject;
    },
    isTaskNotRecurringButContinued() {
      return (
        !isEmpty(this.taskData) &&
        !isEmpty(this.taskData.project) &&
        !checkIfTaskIsRecurring(this.taskData)
      );
    },
    enableTimeScroll() {
      return !isValidDate(this.dateCopy);
    },
    hideNoteTypeOpt() {
      const isTaskConvertedToRecurring =
        this.checkIfTaskIsConvertedToRecurring();
      return (
        this.taskData.isVirtual ||
        isTaskConvertedToRecurring ||
        this.isInContinueMode
      );
    },
    isTaskDataValid() {
      return (
        this.isOpen &&
        !isEmpty(this.taskData) &&
        !isEmpty(this.taskCopy) &&
        !isEmpty(this.taskCopy.key)
      );
    },
    ...mapGetters(["isGoogleCalSyncEnabled"]),
    ...mapGetters("resourcesData", {
      rawResourcesMap: "rawResourcesMap",
    }),
    ...mapGetters("task", {
      processedTasks: "tasks",
      rawTasksMap: "rawTasksMap",
      filteredTasks: "filteredTasks",
      totalTasksCount: "tasksCount",
      categories: "categories",
    }),
    ...mapGetters("editForm", {
      isOpen: "isOpen",
      taskData: "taskData",
      selectedResourceId: "selectedResourceId",
    }),
    ...mapGetters("resourceInfo", {
      isResourceFormOpen: "isOpen",
    }),
    editorPlaceholder() {
      let placeholder = "";

      if (this.showResourceTitle) {
        const resourceType = this.rawResourcesMap[this.activeResourceId]?.type;
        placeholder = resourceTypePlaceholders[resourceType];
      }

      if (!placeholder) {
        placeholder = taskTypePlaceholders[this.taskCopy.taskType];
      }

      return placeholder;
    },
  },
  watch: {
    isOpen: {
      handler(v) {
        if (v) {
          if (!isEmpty(this.taskData)) {
            this.loadTaskData(true);
          }
        } else {
          this.currActiveMode = "";
          this.dataIsLoaded = false;
          this.triggerEditChecker = false;
          this.selectedTaskRowKeys = [];
          this.currActiveDescr = "";
          this.activeDescr = "";
          this.activeResourceId = "";
          this.showResourceBtn = false;
          this.resetResourceData();
          this.resetModes();
        }
      },
      immediate: true,
    },
    isTaskDataValid: {
      handler(v) {
        if (v) {
          this.adjustFormGrid();
        }
      },
    },
  },
};
</script>
<style>
button.v-btn.area-btn {
  border-color: transparent !important;
  border-width: 1.2px !important;
  min-width: auto !important;
  width: auto !important;
  height: 20px !important;
  border-radius: 6px !important;
  padding: 0px 8px !important;
  background: var(--selection-color);
  color: #000 !important;
  font-weight: 400 !important;
  text-transform: capitalize;
  font-size: 1rem;
}

.toggle-btn-wrapper #toggle-btns-group button.toggle-btn.v-btn.active-btn {
  border: 1.2px solid #1991a2 !important;
  color: #1991a2 !important;
  background: #e0edff !important;
}

.toggle-btn-wrapper button.toggle-btn.active-btn.v-btn .v-icon {
  color: #1991a2 !important;
}
.task-title-actions-wrapper {
  width: 100%;
}

.task-title-actions-wrapper .task-title-wrapper {
  align-items: center;
}

.date-helpers-group {
  margin-left: 20px;
}

.content-wrapper {
  position: relative;
}

.main-content-wrapper {
  padding-bottom: 20px;
}

.rowrap {
  flex-wrap: nowrap;
}
.task-title-input-wrapper,
.resource-title-input-wrapper >>> #title-edit {
  font-size: 20px;
  padding-top: 6px !important;
  padding-bottom: 4px !important;
}

.task-title-input-wrapper.full-title-row {
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.task-title-input-wrapper,
.resource-title-input-wrapper >>> textarea {
  font-size: 20px !important;
}
.task-title-input-wrapper >>> .e-rte-content {
  height: auto !important;
}
.task-title-input-wrapper,
.resource-title-input-wrapper >>> .title-editor {
  height: auto !important;
  min-height: auto !important;
}

.task-title-input-wrapper >>> .e-richtexteditor .e-rte-content .e-content {
  min-height: auto !important;
}
.task-title-input-wrapper,
.resource-title-input-wrapper >>> #title-edit:before {
  margin-bottom: 5px !important;
}

.advance-date-input-wrapper >>> input {
  padding: 0px !important;
}
.advance-date-input-wrapper >>> .e-datetime-wrapper {
  padding: 4px 0px 4px 0px !important;
  margin-bottom: 0px !important;
  line-height: 1 !important;
}
.advance-date-input-wrapper >>> .e-date-icon {
  margin-bottom: 0px !important;
}

.advance-date-input-wrapper >>> .e-time-icon {
  margin-bottom: 0px !important;
}

.repeat-task-check-input {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.interval-selector-wrapper {
  width: 60px;
}

p.title {
  color: #000 !important;
  line-height: 1.3 !important;
}

.edit-task-form-wrapper {
  overflow-y: scroll;
}

.priority-selector {
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  margin-bottom: 3px !important;
}

.task-priority-wrapper >>> .priority-selector.task-priority {
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  margin-bottom: 3px !important;
}

.header-content >>> .priority-selector {
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  /* margin-bottom: 3px !important; */
}

.descr-editor >>> .e-rte-toolbar.e-toolbar.e-extended-toolbar {
  z-index: 1 !important;
}

/* .created-and-modified-info-wrapper {
  justify-content: flex-end;
   font-style: italic;
} */

.contingent-toggle-btn {
  height: 24px !important;
  width: 24px !important;
  margin-top: 2px !important;
}
.pause-reminder-btn {
  height: 24px !important;
  width: 24px !important;
  margin-top: 2px !important;
}

/* .header-content >>> .project-selector-wrapper {
  width: 300px !important;
} */

.project-selector-wrapper {
  min-width: 270px !important;
  width: 100%;
}

.subject-selector-wrapper >>> .v-select__selection--comma {
  font-size: 13px !important;
}

.subject-selector-wrapper
  >>> .v-select.v-text-field:not(.v-text-field--single-line)
  input {
  font-size: 13px !important;
}

.project-selector-wrapper >>> input {
  max-height: 34px !important;
}

.subject-selector-wrapper >>> input {
  max-height: 34px !important;
}

.project-selector-wrapper
  >>> .v-select.v-text-field:not(.v-text-field--single-line)
  input {
  font-size: 13px !important;
}

.project-selector-wrapper >>> .v-input__control {
  /* margin-top: 3px; */
}

.project-selector-wrapper >>> .v-select__selection--comma {
  font-size: 13px !important;
}

.task-action-btn >>> svg {
  fill: #000;
}

.edit-task-form-wrapper .task-type-btn-wrapper {
  margin-left: 0px !important;
}
/* .edit-task-form-wrapper .descr-toggler-btn {
  margin-top: 4px;
} */

.task-type-btn-wrapper >>> #activator-btn-wrapper button {
  height: 28px;
  width: 28px;
}

button.area-btn.area-selected {
  border: 1.2px solid var(--primary-color) !important;
}

.title-selector-input {
  width: 0px;
  height: 0px;
  opacity: 0;
  position: absolute;
}
</style>

<style>
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value);
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "13";
  font-size: 13px;
}

/* .descr-editor-wrapper .ql-editor.ql-blank::before {
  top: 10px;
  font-style: normal !important;
  color: #a6a6a6 !important;
} */

/* .descr-editor-wrapper .ql-editor {
  min-height: 150px !important;
  color: rgba(51, 51, 51);
  overflow-y: auto;
  resize: vertical;
} */

/* .descr-editor-wrapper .ql-editor p {
  margin: 0px 0px 0px 0px;

} */

.objt-selector-input-wrapper input {
  font-size: 18px !important;
}

.repeat-info-icon {
  outline: none !important;
}

.reminder-info-icon {
  outline: none !important;
}

.changes-saved-text {
  font-size: 13px;
  margin-top: 5px;
}

.reminder-input,
.repeat-interval-input {
  padding-top: 0px;
  margin-top: 0px;
}

.task-type-btn-wrapper #activator-btn-wrapper button {
  height: 28px;
  width: 28px;
}

.task-edit-form-wrapper .form-title {
  color: var(--primary-color);
}

#resource-title-editor-wrapper #title-editor .ql-editor {
  min-height: 32.5px !important;
}

.resource-input-label {
  margin-right: 8px;
  font-size: 18px;
  font-weight: 500;
}
</style>
