export const catTypes = {
  subcategory: "projects",
  project: "projects",
  category: "categories",
};

export const typesOfProjects = ["subcategory", "project"];
export const typesOfCategories = ["category"];
export const typeOfCateNodes = ["category", "subcategory"];
export const catLabels = {
  subcategory: "Sub-Category",
  project: "Project",
  category: "Category",
};
