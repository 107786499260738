import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-type-menu-wrapper"},[_c('SelectorInput',{ref:"select",staticClass:"item-type-menu",attrs:{"opts":_vm.itemTypeOpts,"value":_vm.value,"openOnFocus":_vm.openOnFocus,"menuClass":_vm.selectMenuClass,"disabled":false},on:{"input":_vm.handleValueChange,"keydown":_vm.handleKeyDown,"focus":_vm.handleFocus},scopedSlots:_vm._u([{key:"activator-btn",fn:function(ref){
var handleClick = ref.handleClick;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"px-1",attrs:{"dark":"","icon":"","small":_vm.useSmallBtn},on:{"click":handleClick}},'v-btn',Object.assign({}, attrs),false),on),[_c(VIcon,{attrs:{"color":_vm.greyIconColor,"size":_vm.itemIconSize}},[_vm._v(" "+_vm._s(_vm.itemTypeLogo)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.itemTypeTitle))])])]}},{key:"item-opt",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({class:_vm.createItemClasses(item)},'v-list-item',attrs,false),on),[_c(VIcon,{staticClass:"mr-3",staticStyle:{"width":"33px"},attrs:{"color":_vm.greyIconColor,"size":_vm.getIconSize(item)}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }