<template>
  <DialogLayout label="Color Theme">
    <template #content>
      <div>
        <p class="selector-label">Select Color theme</p>

        <v-radio-group
          :value="selectedTheme"
          @change="handleThemeSelect"
          class="theme-selector"
          hide-details
        >
          <v-radio
            v-for="(opt, index) in themeOpts"
            :key="index"
            :label="opt.label"
            :value="opt.value"
            class="theme-display-opt"
          ></v-radio>
        </v-radio-group>

        <v-divider></v-divider>
      </div>
    </template>

    <template #actions>
      <!-- <v-spacer /> -->
      <!-- <v-btn class="mr-4" @click="saveSettings"> submit </v-btn> -->
      <v-btn @click="closeDialog"> Close </v-btn>
    </template>
  </DialogLayout>
</template>
<script>
import DialogLayout from "./components/DialogLayout.vue";
import { mapActions, mapGetters } from "vuex";
import { colorThemeOpts } from "@/data/colorThemeOpts";
import { setAppColorTheme } from "@/helpers/app";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
export default {
  mixins: [userDetailsMixin],
  components: {
    DialogLayout,
  },
  data() {
    return {
      selectedTheme: "",
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    ...mapActions(["toggleSettingsDialog"]),
    fillData() {
      this.selectedTheme = this.selectedColorTheme;
    },
    handleThemeSelect(value) {
      this.updateRootState({
        selectedColorTheme: value,
      });
      DatabaseInterface.update(
        {
          ["/appColorTheme"]: value,
        },
        this.userInfo.uid
      );
    },
    closeDialog() {
      this.toggleSettingsDialog();
    },
    ...mapActions(["updateRootState"]),
  },
  computed: {
    ...mapGetters(["selectedColorTheme"]),
    themeOpts() {
      return colorThemeOpts;
    },
  },
};
</script>
