import firebase from "./firebase";
// import firebase from "../test-firebase";
// import firebase from "./test-firebase";
class DbStoreCollectionInterface {
  static dbStore;

  constructor(collectionName) {
    this.dbStore = firebase.firestore().collection(collectionName);
  }

  async getByKey(key) {
    const doc = this.dbStore.doc(key).get();

    return doc;
  }

  async add(data) {
    const doc = await this.dbStore.add(data);
    return doc;
  }

  async set(key, data) {
    const doc = await this.dbStore.doc(key).set(data);
    return doc;
  }

  async update(key, data) {
    const doc = await this.dbStore.doc(key).update(data);
    return doc;
  }

  async remove(key) {
    const res = await this.dbStore.doc(key).delete();
    return res;
  }
}

export default DbStoreCollectionInterface;
