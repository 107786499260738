import {
  getUserResources,
  getResourceMentionStyleConfig,
  getResourceMentionStyleClassNameBasedOnType,
} from "@/helpers/resources";
import { resourceTypesMap } from "@/data/resources";
import { Quill } from "vue2-editor";
import { isEmpty } from "lodash";

const Embed = Quill.import("blots/embed");

class MentionBlot extends Embed {
  hoverHandler;

  constructor(scroll, node) {
    super(scroll, node);
    this.clickHandler = null;
    this.hoverHandler = null;
    this.mouseOverHandler = null;
    this.mounted = false;
  }

  static create(data) {
    const resourcesMap = MentionBlot.getResourcesList();
    delete data.value;
    const node = super.create();
    let doNotSetType = false;
    const resourceStyleConfig = getResourceMentionStyleConfig();

    const resourceDisplayType = resourceStyleConfig.displayType;
    const resourceShowUnderlineOnHover =
      resourceStyleConfig.showUnderlineOnHover;
    const resourceData = resourcesMap[data.id];
    const resourceTypeData = resourceTypesMap[resourceData?.type];
    const denotationChar = document.createElement("span");
    denotationChar.className = "ql-mention-denotation-char";
    denotationChar.innerHTML = data.denotationChar;

    if (!isEmpty(resourceTypeData)) {
      node.setAttribute("data-type", resourceData.type);
    } else {
      node.className += " deleted-resource";
      doNotSetType = true;
      node.setAttribute("data-type", "deleted");
    }

    node.className += ` ${getResourceMentionStyleClassNameBasedOnType(
      resourceDisplayType
    )}`;

    if (!resourceShowUnderlineOnHover) {
      node.className += ` no-hover-underline`;
    }

    node.contentEditable = false;
    node.appendChild(denotationChar);

    const nodeData = { ...data };

    if (!doNotSetType) {
      nodeData.type = resourceData.type;
    }
    node.innerHTML += resourceData?.tag || "deleted-resource";
    return MentionBlot.setDataValues(node, {
      ...nodeData,
    });
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }

  attach() {
    super.attach();

    if (!this.mounted) {
      this.mounted = true;
      this.clickHandler = this.getClickHandler();
      this.hoverHandler = this.getHoverHandler();
      this.mouseOverHandler = this.getMouseOverHandler();
      this.domNode.addEventListener("click", this.clickHandler, false);
      this.domNode.addEventListener("mouseenter", this.hoverHandler, false);
      this.domNode.addEventListener("mouseleave", this.mouseOverHandler, false);
    }
  }

  detach() {
    super.detach();
    this.mounted = false;
    if (this.clickHandler) {
      this.domNode.removeEventListener("click", this.clickHandler);
      this.clickHandler = null;
    }

    this.domNode.removeEventListener("mouseenter", this.hoverHandler, false);
    this.domNode.removeEventListener(
      "mouseleave",
      this.mouseOverHandler,
      false
    );
  }

  getClickHandler() {
    return (e) => {
      const event = this.buildEvent("mention-clicked", e);
      window.dispatchEvent(event);
      e.preventDefault();
    };
  }

  getHoverHandler() {
    return (e) => {
      const event = this.buildEvent("mention-hovered", e);
      window.dispatchEvent(event);
      e.preventDefault();
    };
  }
  getMouseOverHandler() {
    return (e) => {
      const event = this.buildEvent("mention-hover-out", e);
      window.dispatchEvent(event);
      e.preventDefault();
    };
  }
  static getResourcesList() {
    return getUserResources().getResources();
  }

  buildEvent(name, e) {
    const event = new Event(name, {
      bubbles: true,
      cancelable: true,
    });
    event.value = Object.assign({}, this.domNode.dataset);
    event.event = e;
    return event;
  }

  hoverHandler;
}

MentionBlot.blotName = "resourceMention";
MentionBlot.tagName = "span";
MentionBlot.className = "mention";

export default MentionBlot;
