export const PROJECT_ACTIONS_MENU_TYPES = {
  ADD_PROJECT_TASK: "add_project_task",
  ADD_DOC: "add_project",
  ADD_DIR: "add_sub_category",
  REMOVE_PROJECT_CATE: "remove-project-cate",
  RENAME_PROJECT_CATE: "rename-project-cate",
  HIDE_PROJECT_CATE: "hide-project-cate",
  SHOW_PROJECT_CATE: "show-project-cate",
  ADD_CATEGORY: "add_category",
  DELETE_CATE: "delete-cate",
  SHOW_CATE: "show-cate",
};

export const PROJECTS_MENU_ACTIONS = [
  { text: "Add New Category", id: PROJECT_ACTIONS_MENU_TYPES.ADD_CATEGORY },
  { text: "Add New Sub-Category", id: PROJECT_ACTIONS_MENU_TYPES.ADD_DIR },
  { text: "Add New Area", id: PROJECT_ACTIONS_MENU_TYPES.ADD_DOC },
  { text: "Add New Task", id: PROJECT_ACTIONS_MENU_TYPES.ADD_PROJECT_TASK },
  {
    text: "Hide Item",
    id: PROJECT_ACTIONS_MENU_TYPES.DELETE_CATE,
  },
  {
    text: "Show Item",
    id: PROJECT_ACTIONS_MENU_TYPES.SHOW_CATE,
  },

  { text: "Rename Item", id: PROJECT_ACTIONS_MENU_TYPES.RENAME_PROJECT_CATE },
  {
    text: "Mark as Complete",
    id: PROJECT_ACTIONS_MENU_TYPES.HIDE_PROJECT_CATE,
  },
  {
    text: "Mark as Incomplete",
    id: PROJECT_ACTIONS_MENU_TYPES.SHOW_PROJECT_CATE,
  },
  { text: "Remove Item", id: PROJECT_ACTIONS_MENU_TYPES.REMOVE_PROJECT_CATE },
];
