<template>
  <RichTextEditor
    editorId="title-editor"
    :showToolbar="showToolbar"
    :value="value"
    @input="handleChange"
    @mention-removed="handleMentionRemoved"
    @keydown="handleKeyDown"
    ref="titleInput"
    mentionMenuPosition="fixed"
    :useAsInput="useAsInput"
    :placeholder="placeholder"
    stopTabProcess
    :enableMentions="enableMentions"
    :mentionOpts="mentionOpts"
    :isReadOnly="isReadOnly"
    :hideBorder="hideBorder"
    :addSpaceOnEnter="addSpaceOnEnter"
  />
</template>
<script>
import RichTextEditor from "@/components/RichTextEditor";
import EventEmitter from "@/helpers/eventEmitter";
import { CLOSE_RESOURCE_POPUP } from "@/variables/events";
export default {
  components: {
    RichTextEditor,
  },
  props: {
    value: {
      type: String,
    },
    refSetter: {
      type: Function,
    },
    enableMentions: {
      type: Boolean,
      default: true,
    },
    mentionOpts: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: "Enter Title",
    },
    isReadOnly: {
      type: Boolean,
    },
    hideBorder: {
      type: Boolean,
    },
    showToolbar: {
      type: Boolean,
      default: false,
    },
    useAsInput: {
      type: Boolean,
      default: true,
    },
    addSpaceOnEnter: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.refSetter) {
      this.refSetter(this.$refs.titleInput);
    }
  },
  methods: {
    handleChange(v, oldDelta, newDelta) {
      this.$emit("input", v, oldDelta, newDelta);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    handleMentionRemoved() {
      EventEmitter.emit(CLOSE_RESOURCE_POPUP);
    },
  },
};
</script>
