<template>
  <div class="input-cell">
    <input
      :type="inputType"
      :value="text"
      @input="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      @keydown="handleKeyDown"
    />
  </div>
</template>
<script>
import columnProps from "../mixins/columnPropsMixin";
import formatDate from "date-fns/format";
export default {
  mixins: [columnProps],
  props: {
    type: {
      type: String,
    },
  },
  created() {
    this.text = this.data[this.fieldName] || "";
    if (this.type) {
      this.inputType = this.parseInputType(this.type);

      if (this.type === "date") {
        let cellVal = this.cellValue;

        if (this.extraData?.modifier) {
          cellVal = this.extraData.modifier(cellVal);
        }
        this.text = this.isValidDate(cellVal)
          ? formatDate(cellVal, this.extraData?.dateFormat || "yyyy-MM-dd")
          : "";
      }
    }
  },
  data() {
    return {
      text: "",
      inputType: "text",
    };
  },
  mounted() {
    this.onRendered(() => {
      const inputEl = this.$el.querySelector("input");
      inputEl?.focus();
    }, this.cell);
  },
  methods: {
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    parseInputType(providedType) {
      if (providedType === "date-time") {
        providedType = "datetime-loca";
      }
      return providedType;
    },
    selectTextOnFocus() {
      const inputEl = this.$el.querySelector("input");
      if (inputEl && inputEl.select) {
        inputEl.select();
      }
    },
    handleChange(e) {
      let value = e.target.value;
      this.text = value;

      if (this.extraData?.modifier) {
        value = this.extraData?.modifier(value);
      }
      this.onSuccess(value, value, this.cell);
    },
    handleFocus() {
      // setTimeout(() => {
      //   this.selectTextOnFocus();
      // }, 0);
    },
    handleBlur() {
      if (this.isRowEditing) {
        this.onSuccess(this.text, this.text, this.cell);
      }
    },
  },
  computed: {
    cellValue() {
      return this.data[this.fieldName];
    },
  },
};
</script>
<style scoped>
.input-cell {
  width: 100%;
}

.input-cell input {
  width: 100%;
}
</style>
