import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-wrapper"},[_c(VRow,{staticStyle:{"flex-wrap":"wrap","row-gap":"8px"},attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VRow,{attrs:{"dense":"","align":"center"}},[(_vm.useBeforeControls)?_c(VCol,{staticClass:"actions-before-controls",attrs:{"cols":"auto"}},[_vm._t("before-controls")],2):_vm._e(),(_vm.showPagination)?_c(VCol,{staticClass:"actions-pagination",attrs:{"cols":"auto"}},[_c(VRow,{class:_vm.classesForPaginationRow,attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"task-action-btn item-change-btn",attrs:{"icon":"","data-action-type":"item-change","data-action-value":"previous","disabled":_vm.disablePrevBtn,"x-small":""},on:{"click":_vm.editPreviousItem}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"main-icon",attrs:{"size":"20","color":_vm.paginationBtnIconColor}},[_vm._v(" mdi-chevron-left ")])],1)]}}],null,false,4026881041)},[_c('span',[_vm._v("Edit Previous "+_vm._s(_vm.editItemBtnLabel))])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c('span',{staticClass:"count-text"},[_vm._v(" "+_vm._s(_vm.itemNumAndTotalItemCountLabel)+" ")])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"task-action-btn item-change-btn",attrs:{"icon":"","data-action-type":"item-change","data-action-value":"next","disabled":_vm.disableNextBtn,"x-small":""},on:{"click":_vm.editNextItem}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"main-icon",attrs:{"size":"20","color":_vm.paginationBtnIconColor}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,false,1491612860)},[_c('span',[_vm._v("Edit Next "+_vm._s(_vm.editItemBtnLabel))])])],1)],1)],1):_vm._e(),(_vm.useBtnRightSide)?_c(VCol,{staticClass:"actions-after-controls",attrs:{"cols":"auto"}},[_vm._t("bar-btn-right")],2):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"auto"}},[_vm._t("center",function(){return [_c('FormTitle',{attrs:{"text":_vm.title}})]})],2),_c(VCol,{attrs:{"sm":"auto","md":"auto","lg":"2"}},[_c(VRow,{staticClass:"actions-right-controls",attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c(VCol,{staticClass:"side-btns-wrapper side-btns-wrapper-start",attrs:{"cols":"auto"}},[_vm._t("header-right")],2),_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(_vm.showDivider),expression:"showDivider"}],staticClass:"side-btns-wrapper",attrs:{"cols":"auto"}},[_c('div',{staticClass:"btn-divider"})]),_c(VCol,{staticClass:"side-btns-wrapper",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.classesForSaveBtn,attrs:{"icon":""},on:{"click":_vm.handleSave}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"13"}},[_vm._v("$ctiSave")])],1)]}}])},[_c('span',[_vm._v(" Save")])])],1),_c(VCol,{staticClass:"side-btns-wrapper side-btns-wrapper-end",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-btn task-btn-lg action-bar-btn",attrs:{"color":_vm.iconColor,"icon":""},on:{"click":_vm.handleClose}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"13"}},[_vm._v("$ctiCancelIcon")])],1)]}}])},[_c('span',[_vm._v(" Cancel")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }