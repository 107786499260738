export const isNumeric = (str) => {
  if (typeof str !== "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export function increment(initial, count = 1) {
  let start = Number(initial);
  if (Number.isNaN(start)) {
    start = 0;
  }

  start += count;
  return start;
}

export const decrement = (initial, count = 1) => {
  let start = Number(initial);
  if (Number.isNaN(start)) {
    start = 0;
  }

  start -= count;
  return start;
};

export function getNumber(given) {
  let value = Number(given);
  if (Number.isNaN(value)) {
    value = 0;
  }
  return value;
}
