<template>
  <e-chart :options="chartOpts" autoresize />
</template>

<script>
import EChart from "@/core/components/EChart";
import { BASE_BLACK_COLOR } from "@/variables/colors";
export default {
  components: {
    EChart,
  },
  props: {
    yAxisData: {
      type: Array,
      default: () => [],
    },
    barSeriesData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chartOpts() {
      const colorList = [
        "#8498d6",
        "#b0da9c",
        "#fbd786",
        "#f29191",
        "#9ad2e8",
        "#67c79a",
        "#fda783",
        "#b68dc9",
        "#f0a0da",
      ];
      return Object.freeze({
        // color: colorList,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          align: "left",
          left: 20,
          textStyle: {
            color: BASE_BLACK_COLOR,
            fontSize: 13,
          },
          // orient: "vertical",
          // bottom: "50%",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "40",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            show: true,
            color: BASE_BLACK_COLOR,
            fontSize: 13,
          },
        },
        yAxis: {
          type: "category",
          data: Object.freeze(this.yAxisData),

          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: BASE_BLACK_COLOR,
            fontSize: 13,
          },
        },
        series: Object.freeze(this.barSeriesData),
      });
    },
  },
};
</script>
