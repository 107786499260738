import {
  expandAllParentsOfResource,
  getUserResources,
  processResourceData,
  setResourceIdInTree,
} from "@/helpers/resources";
import {
  // checkIfTaskIsParentRecurringTask,
  checkIfTaskIsRecurring,
  getMainTaskRulesAndFillRecurringOpts,
} from "@/helpers/tasks";
import DatabaseInterface from "@/services/DatabaseInterface";
import { computePosition, flip, shift, offset, inline } from "@floating-ui/dom";
import { isEmpty } from "lodash";
import { mapActions, mapGetters } from "vuex";
import userDetailsMixin from "./userDetailsMixin";
import tasksHelpersMixin from "./tasksHelpersMixin";

import EventEmitter from "@/helpers/eventEmitter";
import { CLOSE_INPECT_MODE, CLOSE_RESOURCE_POPUP } from "@/variables/events";
const mixin = {
  mixins: [userDetailsMixin, tasksHelpersMixin],
  data() {
    return {
      showMentionInfo: false,
      currMentionIdVisible: "",
      showNoMentionFoundError: false,
      mentionInfo: {},
    };
  },
  methods: {
    ...mapActions("resourceInfo", ["openResourceInfoViewer"]),
    ...mapActions("editForm", {
      showEditTaskDialog: "showEditTaskDialog",
      closeEditForm: "closeEditTaskDialog",
    }),
    ...mapActions(["toggleLoader", "updateRootState"]),
    setMentionListeners() {
      this.popupWrapper = document.querySelector(".resource-info-pop-up");
      window.addEventListener("mention-hovered", this.handleMentionHovered);
      window.addEventListener("mention-hover-out", this.handleMentionHoverOut);
      EventEmitter.on(CLOSE_RESOURCE_POPUP, this.closePopUp);
      this.setListenersOnPopUpWrapper();
    },
    removeMentionListeners() {
      window.removeEventListener("mention-hovered", this.handleMentionHover);
      window.removeEventListener(
        "mention-hover-out",
        this.handleMentionHoverOut
      );
      EventEmitter.off(CLOSE_RESOURCE_POPUP, this.closePopUp);
      this.removeListenersFromPopUpWrapper();
    },
    setListenersOnPopUpWrapper() {
      if (this.popupWrapper) {
        this.popupWrapper.addEventListener(
          "mouseenter",
          this.handlePopUpMouseEnter
        );
        this.popupWrapper.addEventListener(
          "mouseleave",
          this.handlePopupMouseLeave
        );
        this.popupWrapper.addEventListener("click", this.handlePopUpClick);
      }
    },
    removeListenersFromPopUpWrapper() {
      if (this.popupWrapper) {
        this.popupWrapper.removeEventListener(
          "mouseenter",
          this.handlePopUpMouseEnter
        );
        this.popupWrapper.removeEventListener(
          "mouseleave",
          this.handlePopupMouseLeave
        );
        this.popupWrapper.removeEventListener("click", this.handlePopUpClick);
      }
    },
    closePopUp() {
      this.showMentionInfo = false;
      this.showNoMentionFoundError = false;
    },
    handlePopupMouseLeave(e) {
      e.stopPropagation();

      this.triggerClosePopUp();
    },
    handlePopUpMouseEnter(e) {
      e.stopPropagation();

      if (this.idHovered && this.hoveredEl) {
        if (this.callPopupClose !== undefined) {
          clearTimeout(this.callPopupClose);
          this.callPopupClose = undefined;
        }

        this.showResourceInfo(this.idHovered, this.hoveredEl);
      }
    },
    async showResourceInfo(resourcesId, targetEl) {
      const allResources = getUserResources().getResources();
      const popupEl = this.$refs.resourcePopUp?.$el;
      if (popupEl) {
        if (allResources[resourcesId]) {
          this.mentionInfo = { ...allResources[resourcesId] };
          this.showNoMentionFoundError = false;
        } else {
          this.showNoMentionFoundError = true;
          this.mentionInfo = {};
        }
        this.showMentionInfo = true;
        await this.$nextTick();
        const res = await computePosition(targetEl, popupEl, {
          placement: "top",
          middleware: [inline(), offset(15), flip(), shift({ padding: 20 })],
        });

        popupEl.style.left = `${res.x}px`;
        popupEl.style.top = `${res.y}px`;
      }
    },
    async openResourceInfoForm(resourceId) {
      if (this?.$route?.query?.resourceId === resourceId) return;
      this.toggleLoader(true);

      try {
        const allResourcesMap = getUserResources().getResources();
        const routeData = {
          resourceId,
        };

        let viewItemId;
        let viewItemDate;
        let expandResourcesInTree = false;
        const returnViewInfo = {
          to: this.$route.name,
        };
        const dbUpdates = {
          notesView: false,
          activeNavView: "resources",
        };

        let closeTaskForm = false;
        let closeNoteForm = false;
        let closeInspectMode = false;
        let storeViewDataForReturn = false;
        let openProjectsMode = false;
        if (this.$route.name === "Dashboard") {
          dbUpdates["inspectModeEnabled"] = false;
          const currViewedId = this.viewedItemKey;

          if (!isEmpty(this.selectedTasks)) {
            let itemSelectDate;
            let itemIdToSelect;

            const selectedTask = this.selectedTasks[0];

            itemIdToSelect = selectedTask.key;

            if (
              selectedTask.isVirtual &&
              this.rawTasksMap[selectedTask.linkedTo]
            ) {
              itemIdToSelect = selectedTask.linkedTo;
              itemSelectDate = selectedTask.dueAsDate;
            }

            if (itemIdToSelect) {
              returnViewInfo.itemIdToSelect = itemIdToSelect;
              returnViewInfo.itemIdDateSelect = itemSelectDate;
            }
          }
          if (this.isInspectModeEnabled) {
            returnViewInfo.enableInspectMode = true;

            let taskData = this.tasks.find(
              (t) => t.key === this.currInspectedTaskId
            );

            if (!isEmpty(taskData)) {
              let taskIdToInspect = taskData.key;
              let taskDate;
              if (taskData.isVirtual && this.rawTasksMap[taskData.linkedTo]) {
                taskIdToInspect = taskData.linkedTo;
                taskDate = taskData.dueAsDate;
              }

              if (taskIdToInspect) {
                returnViewInfo.inspectModeConfig = {
                  inspectedTaskId: taskIdToInspect,
                  taskDate,
                  triggeredFrom: this.inspectTriggeredFrom,
                  opts: this.inspectModeOpts,
                };
              }
            }

            closeInspectMode = true;
          }

          if (this.isTaskFormOpen) {
            closeTaskForm = true;
            let itemIdToOpen = this.editTaskData.key;
            if (
              checkIfTaskIsRecurring(this.editTaskData) &&
              this.editTaskData.isVirtual &&
              this.rawTasksMap[this.editTaskData.linkedTo]
            ) {
              itemIdToOpen = this.editTaskData.linkedTo;

              returnViewInfo.itemDate = this.editTaskData.dueAsDate;
            }

            returnViewInfo.itemIdToOpen = itemIdToOpen;
            returnViewInfo.openForm = true;
          }

          if (currViewedId) {
            const taskDataViewed = this.tasks.find(
              (t) => t.key === currViewedId
            );
            if (!isEmpty(taskDataViewed)) {
              if (
                taskDataViewed.isVirtual &&
                this.rawTasksMap[taskDataViewed.linkedTo]
              ) {
                viewItemId = taskDataViewed.linkedTo;
                viewItemDate = taskDataViewed.dueAsDate;
              } else if (!taskDataViewed.isVirtual) {
                viewItemId = taskDataViewed.key;
              }
            }
          }

          const viewData = this.getCurrViewData({
            enableContingentFilter: this.showAllOrderedTasks,
          });
          returnViewInfo.viewData = viewData;
          if (
            !isEmpty(allResourcesMap[resourceId]) &&
            allResourcesMap[resourceId].type === "project"
          ) {
            dbUpdates["isProjectsResourceModeEnabled"] = true;
            dbUpdates["activeNavView"] = "dashboard";
            expandResourcesInTree = true;
            storeViewDataForReturn = true;
            openProjectsMode = true;

            // returnViewInfo = { ...viewData };
          }

          // currViewType = "task";
          // currEditingId = this.$refs.tasksListWrapper.getCurrEditingRowId();
          // if (!isEmpty(this.selectedTasks)) {
          //   currSelectedItemId = this.selectedTasks[0].key;
          // }
        } else if (this.$route.name === "Notes") {
          let selectedNoteId;
          if (!isEmpty(this.selectedNotes)) {
            selectedNoteId = this.selectedNotes[0].key;
          }
          // currViewType = "note";
          // currEditingId = this.$refs.grid.getCurrEditingRowId();

          if (this.isEditFormOpen) {
            closeNoteForm = true;
            const itemIdToOpen = this.editNoteData.key;

            returnViewInfo.itemIdToOpen = itemIdToOpen;
            returnViewInfo.openForm = true;
          }
          if (selectedNoteId) {
            returnViewInfo.itemIdToSelect = selectedNoteId;
          }

          // if (!isEmpty(this.selectedNotes)) {
          //   currSelectedItemId = this.selectedNotes[0].key;
          // }
        } else if (this.$route.name === "Resources") {
          expandResourcesInTree = true;
          storeViewDataForReturn = true;

          if (
            !isEmpty(allResourcesMap[resourceId]) &&
            allResourcesMap[resourceId].type === "project"
          ) {
            dbUpdates["isProjectsResourceModeEnabled"] = true;
            dbUpdates["activeNavView"] = "dashboard";
            openProjectsMode = true;

            // returnViewInfo = { ...viewData };
          }
        }

        if (storeViewDataForReturn) {
          const viewData = this.createCurrViewData({
            enableContingentFilter: this.showAllOrderedTasks,
          });
          this.storeCurrViewData(viewData);
        }

        this.closePopUp();

        this.hideTempVisibleTasks();

        await setResourceIdInTree(
          processResourceData({ ...allResourcesMap[resourceId] })
        );

        if (expandResourcesInTree) {
          expandAllParentsOfResource({ ...allResourcesMap[resourceId] });
        }

        await DatabaseInterface.update(dbUpdates, this.userInfo.uid);

        this.updateRootState({
          itemInspectEnabled: true,
          currActiveView: openProjectsMode ? "dashboard" : "resources",
        });

        this.$router.replace({
          name: openProjectsMode ? "Dashboard" : "Resources",
          query: {
            ...routeData,
          },
          params: {
            linkedItemData: Object.freeze({
              key: viewItemId,
              date: viewItemDate,
            }),
            returnViewInfo: Object.freeze({
              ...returnViewInfo,
            }),
          },
        });
        if (closeNoteForm || closeTaskForm) {
          this.closeEditForm();
        }

        if (closeInspectMode) {
          EventEmitter.emit(CLOSE_INPECT_MODE);
        }
      } catch (error) {
        console.error(error);
      }

      this.toggleLoader(false);
    },
    handlePopUpClick(e) {
      if (this.isGlobalSearchBoxOpen) {
        e.preventDefault();
        e.stopPropagation();
        this.closePopUp();
        return;
      }
      // const foundParent = findParentFromPathsByClassName(getPathsFromEvent(e), [
      //   "resource-info-pop-up",
      // ]);

      // if (foundParent) {
      //   console.debug("EEE TYAG", e.cancel);
      const allResources = getUserResources().getResources();
      if (this.idHovered) {
        if (!allResources[this.idHovered]) {
          this.closePopUp();
          return;
        }

        // if (
        //   this.$route.name === "Dashboard" &&
        //   this.viewedItemKey &&
        //   !this.isEditFormOpen
        // ) {
        //   this.openTaskFormByTaskId(this.viewedItemKey, this.idHovered);
        // } else {
        this.openResourceInfoForm(this.idHovered);
        // }
      }
      // }
    },
    openTaskFormByTaskId(taskId, resourceId) {
      const currTaskData = this.filteredTasks.find((t) => t.key === taskId);
      this.closePopUp();
      if (!isEmpty(currTaskData)) {
        const processedTaskData =
          getMainTaskRulesAndFillRecurringOpts(currTaskData);

        this.showEditTaskDialog({
          taskData: { ...processedTaskData },
          selectedResourceId: resourceId,
          selectedTasks: [],
        });
      }
    },
    handleMentionClicked() {},
    createMentionDeleteButton(target, mentionInfo) {
      if (!isEmpty(target) && !isEmpty(mentionInfo)) {
        const haveMentionDeleteBtn = target.querySelector(
          `#mention-${mentionInfo.id}`
        );

        if (haveMentionDeleteBtn) {
          return;
        }
        const deleteButton = document.createElement("button");
        deleteButton.innerText = "x";
        deleteButton.classList.add("delete-selected-mention");
        deleteButton.setAttribute("id", `mention-${mentionInfo.id}`);
        deleteButton.onclick = (e) => {
          const event = new CustomEvent("mention-delete", {
            bubbles: true,
            cancelable: true,
            detail: {
              mentionInfo,
              event: e,
              mentionEl: target,
            },
          });
          e.preventDefault();
          // e.stopPropagation();
          // e.stopImmediatePropagation();
          target.dispatchEvent(event);
        };
        target.appendChild(deleteButton);
      }
    },
    removeMentionDeleteButton(target, mentionInfo) {
      if (!isEmpty(target) && !isEmpty(mentionInfo)) {
        const mentionDeleteBtn = target.querySelector(
          `#mention-${mentionInfo.id}`
        );
        if (mentionDeleteBtn) {
          mentionDeleteBtn.remove();
        }
      }
    },
    handleMentionHovered(e) {
      const mainEventData = e.event;
      const mentionInfo = e.value;
      this.createMentionDeleteButton(mainEventData.target, mentionInfo);
      if (this.callPopupClose !== undefined) {
        clearTimeout(this.callPopupClose);
        this.callPopupClose = undefined;
      }

      if (this.mentionDisplayTimer !== undefined) {
        clearTimeout(this.mentionDisplayTimer);
        this.mentionDisplayTimer = undefined;
      }
      this.hoveredEl = mainEventData.target;

      if (this.showMentionInfo) {
        this.showMentionInfo = false;
      }

      this.mentionDisplayTimer = setTimeout(() => {
        this.showResourceInfo(mentionInfo.id, this.hoveredEl);
      }, 600);
    },
    triggerClosePopUp() {
      this.callPopupClose = setTimeout(() => {
        this.closePopUp();
      }, 150);
    },
    handleMentionHoverOut(e) {
      const mainEventData = e.event;
      const mentionInfo = e.value;
      this.removeMentionDeleteButton(mainEventData.target, mentionInfo);
      this.idHovered = mentionInfo.id;

      if (this.mentionDisplayTimer !== undefined) {
        clearTimeout(this.mentionDisplayTimer);
        this.mentionDisplayTimer = undefined;
      }
      this.triggerClosePopUp();
    },
  },
  computed: {
    ...mapGetters("task", [
      "selectedTasks",
      "filteredTasks",
      "inspectTriggeredFrom",
      "inspectModeOpts",
      "currInspectedTaskId",
      "isInspectModeEnabled",
      "rawTasksMap",
      "isProjectsResourceModeEnabled",
    ]),
    ...mapGetters("note", ["selectedNotes"]),
    ...mapGetters(["viewedItemKey", "isGlobalSearchBoxOpen"]),
    ...mapGetters("editForm", {
      isEditFormOpen: "isOpen",
      isTaskFormOpen: "openNewForm",
      editTaskData: "taskData",
      editNoteData: "noteData",
    }),
  },
};

export default mixin;
