<template>
  <ConfirmRecurringTaskDialog
    ref="dialog"
    :options="editOpts"
    :isOpen="isDialogOpen"
    label="Delete recurring task"
    @dialog:closed="handleClose"
    @option:selected="handleOptSelect"
  />
</template>
<script>
import ConfirmRecurringTaskDialog from "./ConfirmRecurringTaskDialog.vue";
import { RECURRING_TASK_EDIT_OPTS } from "@/variables/tasks";
import { mapActions, mapGetters } from "vuex";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
export default {
  mixins: [tasksHelpersMixin],
  components: {
    ConfirmRecurringTaskDialog,
  },
  methods: {
    handleClose() {
      this.$refs.dialog.clearOptValue();
      this.closeDialog();
    },
    async handleOptSelect(selectedOpt) {
      switch (selectedOpt) {
        case "self":
          await this.removeRecurringOrNormalTasks([this.taskDataToRemove]);
          break;
        case "withFollowing":
          await this.removeCurrAndFollowRecurringTasks(this.taskDataToRemove);
          break;
        case "all":
          await this.removeAllRecurringTasks(this.taskDataToRemove);
          break;
      }

      await this.$nextTick();
      this.handleClose();
    },
    ...mapActions("deleteRecurringConfirmDialog", ["closeDialog"]),
  },
  computed: {
    editOpts() {
      return RECURRING_TASK_EDIT_OPTS;
    },
    ...mapGetters("deleteRecurringConfirmDialog", {
      isDialogOpen: "isDialogOpen",
      taskDataToRemove: "taskData",
    }),
  },
};
</script>
