export default {
  notes(state) {
    return state.notes;
  },
  directories(state) {
    return state.directories;
  },
  notesTree(state) {
    return state.notesTreeStructure;
  },
  selectedFolders(state) {
    return state.selectedFolders;
  },
  searchQuery(state) {
    return state.query;
  },
  selectedNotes(state) {
    return state.selectedNotes;
  },
  dirChildrenMap(state) {
    return state.dirChildrenMap;
  },
  rawNotesMap(state) {
    return state.rawNotesMap;
  },
  rawNotes(state) {
    return state.rawNotes;
  },
  filteredNotes(state) {
    return state.filteredNotes;
  },
  notesCount(state) {
    return state.notesCount;
  },
  collapsedGroupsList(state) {
    return state.collapsedGroupsList;
  },
  selectedNoteTopics(state) {
    return state.selectedNoteTopics;
  },
};
