<template>
  <v-app>
    <Toast color="red" />
    <GlobalLoader />
    <!-- <v-app-bar app color="primary" flat name="TaskBar" height="40px"> -->
    <TaskBar ref="appBar" :toggleSidebar="toggleSidebar" :sidebarOpen="sidebarOpen" />

    <!-- </v-app-bar> -->
    <slot name="header"> </slot>
    <v-main>
      <!-- Loading Dialog during initial db read -->
      <LoadingDialog :isLoading="loading" />
      <!-- No User Dialog if there is noone signed in and for some reason they did not get kicked back to login screen -->
      <NoUserLoggedInDialog :show="noUser" />
      <div class="d-flex page-layout fill-height">
        <aside :class="{'slim-side-nav': !sidebarOpen}">
          <div class="side-nav" name="NavBar">
            <div class="pr-0 mode-select-bar side-nav-content">
              <NavMenuBar ref="navBar" :sidebarOpen="sidebarOpen"/>
              <div class="text-center white--text sidebar-title">
                {{ name }}
              </div>
            </div>
            <div class="customScroll side-nav-content side-tree-content">
              <slot name="side-nav-view"></slot>
            </div>
          </div>
        </aside>

        <main class="main" style="min-width: 0">
          <slot name="main-view"></slot>
        </main>
      </div>
      <SettingsDialog />
      <GlobalSearchBox />
    </v-main>
  </v-app>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import SettingsDialog from "@/components/SettingsDialog/SettingsDialog.vue";
import LoadingDialog from "@/components/LoaderDialog";
import Toast from "@/components/Toast";
import TaskBar from "@/components/TaskBar/TaskBar.vue";
import GlobalLoader from "@/components/GlobalLoader";
import GlobalSearchBox from "@/components/GlobalSearchBox";
import NoUserLoggedInDialog from "@/components/NoUserLoggedInDialog";
import NavMenuBar from "@/components/NavMenuBar/NavMenuBar.vue";
import handleUserActionHelperMixin from "@/mixins/handleUserActionHelperMixin";
export default {
  mixins: [handleUserActionHelperMixin],
  props: {
    name: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    noUser: {
      type: Boolean,
    },
    showTaskBar: {
      type: Boolean,
    },
    showGlobalSearch: {
      type: Boolean,
    },
  },
  methods: {
    ...mapMutations(["toggleSidebar"]),
    getNavBarRef() {
      if (window.innerHeight > 960) {
        return this.$refs.navBar;
      } else {
        return this.$refs.slimNavBar;
      }
    },
    getAppBar() {
      return this.$refs.appBar;
    },
  },
  computed: {
    ...mapState(["sidebarOpen"]),
  },
  components: {
    Toast,
    TaskBar,
    LoadingDialog,
    NoUserLoggedInDialog,
    NavMenuBar,
    GlobalLoader,
    SettingsDialog,
    GlobalSearchBox,
  },
};
</script>
<style lang="scss">
.sidebar-title {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.slim-side-nav {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .page-layout main {
    // margin-left: 46px;
  }

  .page-layout aside {
    position: fixed;
    z-index: 2;
    height: calc(100vh - 40px);
    overflow: hidden;
  }
  .page-layout aside.slim-side-nav {
    z-index: 1 !important;
  }

  .slim-side-nav {
    display: none !important;
    min-width: 46px !important;
    max-width: 46px !important;

    .sidebar-title, .side-tree-content {
      display: none;
    }

    .side-nav {
      min-width: 100%;
      height: 100%;
      background-color: #eeeeee !important;
    }
  
    .side-nav-content {
      max-width: 46px !important;
    }
    
    .side-nav .nav-bar-btns  {
      flex-direction: column;
      // width: 100% !important;
    }
    
    .side-nav .nav-bar-btns .nav-btn {
      padding: 8px !important;
    }
    
    .side-nav .non-toggle-button{
      float: none !important;
      margin: 4px !important;
    }
  }
}

</style>
