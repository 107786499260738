function convertToMilli(secs) {
  return secs * 1000;
}

function convertToSecs(min) {
  return min * 60;
}
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function sleep(millisecs, secs, mins) {
  let timer = millisecs ? millisecs : 0;
  if (mins && secs) {
    timer = convertToMilli(convertToSecs(mins) + secs);
  } else if (secs) {
    timer = convertToMilli(secs);
  }

  await timeout(timer);
  return true;
}

export default sleep;
