<template>
  <span class="error-text">{{ text }}</span>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>
<style scoped>
.error-text {
  font-size: 12px;
  color: red;
}
</style>
