import { formatDate, isValidDate, parseDate } from "@/helpers/dates";
import { DATE_FORMAT_FOR_CAPTION } from "@/variables/dates";
import { isEmpty } from "lodash";
import isPlainObject from "lodash/isPlainObject";
const createGroupedTasksCaptions = (data, group, reviewGroupCaptionCreator) => {
  let captionToDisplay;
  let groupKey = group;

  if (!isEmpty(group) && isPlainObject(group) && group.key) {
    groupKey = group.key;
  }

  switch (groupKey) {
    case "CalendarGroup":
    case "CTICustomDateGroup":
      {
        captionToDisplay = data[0].CalendarGroupCaption;
        let parseCaption = data[0].CalendarGroupParseCaption;

        if (groupKey === "CTICustomDateGroup") {
          captionToDisplay = data[0].CTICustomDateGroupCaption;
          parseCaption = data[0].CTICustomDateGroupParseCaption;
        }

        if (parseCaption) {
          if (isValidDate(parseDate(captionToDisplay, "M/dd/yy"))) {
            const parsedDate = parseDate(captionToDisplay, "M/dd/yy");
            captionToDisplay = formatDate(parsedDate, DATE_FORMAT_FOR_CAPTION);
          }

          if (captionToDisplay === "Today") {
            captionToDisplay = `Today (${formatDate(new Date(), "iiii")})`;
          }
        }
      }

      break;
    case "TodayGroup":
      captionToDisplay = data[0].TodayGroupCaption;

      if (captionToDisplay === "Today") {
        captionToDisplay = `Today (${formatDate(new Date(), "iiii")})`;
      }

      break;
    case "CTIGroup":
      captionToDisplay = data[0].CTIGroupCaption;
      break;
    case "CTICustomGroup":
      captionToDisplay = data[0].CTICustomGroupCaption;

      if (data[0].CTICustomGroup === "1") {
        captionToDisplay = reviewGroupCaptionCreator(data[0].CTICustomGroup);
      }

      break;
    case "ReviewGroup":
      captionToDisplay = data[0].ReviewGroupCaptionShow
        ? reviewGroupCaptionCreator(data[0].ReviewGroupKey)
        : "";
      break;
    case "CategoryGroup":
      captionToDisplay = data[0].CategoryGroupCaptionShow
        ? reviewGroupCaptionCreator(data[0].CategoryGroupKey)
        : "";
  }
  if (captionToDisplay === "" || captionToDisplay === "ZZZZZZZ") {
    captionToDisplay = "None";
  }

  return captionToDisplay;
};

export const createCategoryCaption = (categoryId, categories) => {
  return categories[categoryId]?.title;
};
export default createGroupedTasksCaptions;
