import resourceInfoActions from "./actions";
import resourceInfoMutators from "./mutations";
import resourceInfoGetters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      isOpen: false,
      data: {},
      returnViewInfo: {},
      selectedTaskData: {},
      storedTaskData: {},
    };
  },
  actions: resourceInfoActions,
  mutations: resourceInfoMutators,
  getters: resourceInfoGetters,
};
