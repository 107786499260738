<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="btnData.btnClasses"
        x-small
        depressed
        v-bind="attrs"
        v-on="on"
        @click="handleToggle"
      >
        <v-icon :size="13" :color="btnData.iconColor">
          {{ btnData.icon }}
        </v-icon>
      </v-btn>
    </template>
    <span> {{ btnData.tooltipText }} </span>
  </v-tooltip>
</template>

<script>
import { LIGHT_GREY_BG_CLASSNAME } from "@/variables/colors";
import { mapActions, mapGetters, mapMutations } from "vuex";
import isEmpty from "lodash/isEmpty";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { getUserResources } from "@/helpers/resources";
import { findResourceCateDataByRef } from "@/helpers/resourceCategoriesHelpers";
import { makeNodeVisible } from "@/helpers/categories";
import EventEmitter from "@/helpers/eventEmitter";
import { SET_SELECTED_NODE_EVENT } from "@/variables/events";
export default {
  props: {
    // isActive: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  mixins: [userDetailsMixin, tasksHelpersMixin],
  methods: {
    async handleToggle() {
      this.loaderToggler(true);

      setTimeout(async () => {
        let localUpdates = {
          isFilterBarOptsEnabled: false,
          isProjectsResourceModeEnabled: false,
        };
        let keepResourceNodesClosed = false;
        let moveToAreas = false;
        let resourceSelectionData = {};
        const allResourceCates = { ...getUserResources().getCategories() };
        let resourceStateUpdates = {};
        let rootUpdates = {
          currViewData: {},
          itemInspectEnabled: false,
        };
        let dbUpdates = {
          "/isProjectsResourceModeEnabled": false,
        };

        dbUpdates["/inboxView"] = false;
        dbUpdates["/globalView"] = false;
        dbUpdates["/notesView"] = false;
        dbUpdates["/activeNavView"] = "dashboard";
        dbUpdates["/showCompleted"] = false;
        dbUpdates["inspectModeEnabled"] = false;
        if (!this.isProjectsResourceModeEnabled) {
          dbUpdates["/isProjectsResourceModeEnabled"] = true;
          localUpdates.isProjectsResourceModeEnabled = true;
        } else {
          moveToAreas = true;
        }

        if (this.isInNextActionsMode) {
          let selectedIds = [];
          this.selectedOptsList.forEach((opt) => {
            const resourceCateData = findResourceCateDataByRef(
              Object.values(allResourceCates),
              opt.id,
              "project"
            );

            if (!isEmpty(resourceCateData)) {
              selectedIds.push(resourceCateData.key);
            }
          });

          resourceSelectionData = {
            mode: "project",
            selection: selectedIds,
          };
          resourceStateUpdates.rawSelectedCategoriesMap = {
            ...this.rawSelectedCategoriesMap,
            project: selectedIds,
          };
          keepResourceNodesClosed = isEmpty(selectedIds);
        }
        localUpdates.inspectModeEnabled = false;

        this.updateState({ ...localUpdates });

        this.hideTempVisibleTasks();
        this.closeResourceInfoViewer();
        this.clearListFilters();
        this.toggleContingentTasks(false);
        this.closeEditTaskDialog();
        this.updateRootState({
          searchVal: "",
          ...rootUpdates,
          keepResourceNodesClosed,
        });

        this.updateResourcesState(resourceStateUpdates);
        if (!isEmpty(resourceSelectionData)) {
          await getUserResources().set(
            resourceSelectionData,
            "selectedCategories"
          );
        }
        if (!isEmpty(dbUpdates)) {
          await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        }
        await this.$nextTick();

        if (moveToAreas && !isEmpty(this.selectedOptsList)) {
          setTimeout(() => {
            EventEmitter.emit(
              SET_SELECTED_NODE_EVENT,
              [this.selectedOptsList[0].id],
              true,
              true,
              undefined,
              true,
              undefined,
              true
            );
          }, 0);
          // makeNodeVisible(this.selectedOptsList[0].id);
        }
        this.loaderToggler(false);
      }, 100);
    },
    ...mapActions(["updateRootState"]),
    ...mapActions("task", ["clearListFilters", "toggleContingentTasks"]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions("resourceInfo", ["closeResourceInfoViewer"]),
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    ...mapActions("editForm", ["closeEditTaskDialog"]),
    ...mapActions("resourcesData", ["updateResourcesState"]),
  },
  computed: {
    ...mapGetters("task", ["isProjectsResourceModeEnabled"]),
    ...mapGetters("resourcesData", ["rawSelectedCategoriesMap"]),
    btnData() {
      let iconColor = "#676767";
      let tooltip = "Enable Projects View";
      let btnClasses = {
        [LIGHT_GREY_BG_CLASSNAME]: true,
      };

      if (this.isProjectsResourceModeEnabled) {
        iconColor = "#fff";
        btnClasses = {
          "project-view-btn-active": true,
        };
        tooltip = "Disable Projects View";
      }

      return {
        btnClasses: {
          "filter-btn": true,
          "project-view-btn": true,

          ...btnClasses,
        },
        iconColor,
        icon: "$ctiProject",
        tooltipText: tooltip,
      };
    },
  },
};
</script>

<style>
.filter-btn {
  background: #f5f5f5;
  border-radius: 6px !important;
  padding: 5px 12px !important;
}
.project-view-btn {
  background-color: #d9d9d9 !important;
  color: #676767;
}
.project-view-btn.project-view-btn-active {
  background-color: var(--primary-color) !important;
}
</style>
