import userActions from "./actions";
import userMutations from "./mutations";
import userGetters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      info: {},
    };
  },
  mutations: userMutations,
  actions: userActions,
  getters: userGetters,
};
