import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.btnData.btnClasses,attrs:{"x-small":"","depressed":""},on:{"click":_vm.handleToggle}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":13,"color":_vm.btnData.iconColor}},[_vm._v(" "+_vm._s(_vm.btnData.icon)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.btnData.tooltipText)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }