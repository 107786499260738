const MAX_REPEAT_COUNT = 10;
const MIN_REPEAT_COUNT = 1;

let repeatCount = MIN_REPEAT_COUNT;
const repeatCountOptsMap = {};
while (repeatCount <= MAX_REPEAT_COUNT) {
  repeatCountOptsMap[repeatCount] = {
    label: repeatCount.toString(),
    value: repeatCount,
  };
  repeatCount++;
}
const repeatCountOpts = Object.values(repeatCountOptsMap);

export default repeatCountOpts;
export { repeatCountOptsMap };

// export const frequencyOptsMap = {
//   daily: {
//     label: "Every Day",
//     value: "daily",
//     frequency: 1,
//   },
//   weekly: {
//     label: "Every Week",
//     value: "weekly",
//     frequency: 1,
//   },
//   monthly: {
//     label: "Every Month",
//     value: "monthly",
//     frequency: 1,
//   },
//   yearly: {
//     label: "Every Year",
//     value: "yearly",
//     frequency: 1,
//   },
// };

// const frequencyOpts = Object.values(frequencyOptsMap);

// export const maxFrequencyDates = {
//   daily: {
//     maxCount: 6,
//     unit: "months",
//   },
//   weekly: {
//     maxCount: 100,
//     unit: "weeks",
//   },
//   monthly: {
//     maxCount: 100,
//     unit: "months",
//   },
// };
// export default frequencyOpts;
