import cateDataActions from "./actions";
import cateDataMutations from "./mutations";
import cateDataGetters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      isFormOpen: false,
      isNoteTopicFormOpen: false,
      isResourceCateFormOpen: false,
      data: {},
    };
  },
  mutations: cateDataMutations,
  actions: cateDataActions,
  getters: cateDataGetters,
};
