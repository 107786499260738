import { isEqual } from "lodash";
import { Quill } from "vue2-editor";
let qlInstance;
let qlEl;

const getQlInstanceToUse = (providedQl) => {
  if (providedQl) return providedQl;

  return createOrGetQlInstance();
};

export const createOrGetQlInstance = () => {
  if (qlInstance) return qlInstance;
  qlEl = document.createElement("div");
  qlInstance = new Quill(qlEl);
  return qlInstance;
};

export const removeQlInstance = () => {
  if (qlEl) {
    qlEl.remove();
    qlEl = null;
    qlInstance = null;
  }
};

export const getHTMLFromQl = (qlInsta) => {
  const instanceToUse = getQlInstanceToUse(qlInsta);
  return instanceToUse.root.innerHTML;
};

export const setDataInQl = (dataToSet, convert, qlInst) => {
  const instanceToUse = getQlInstanceToUse(qlInst);
  let proccessedData = dataToSet;

  if (convert) {
    proccessedData = convertHTMLToQlDelta(proccessedData);
    setDeltaContentsInQl(proccessedData, instanceToUse);
  } else {
    instanceToUse.pasteHTML(proccessedData);
  }
};

export const convertHTMLToQlDelta = (html, qlInst) => {
  const instanceToUse = getQlInstanceToUse(qlInst);
  return instanceToUse.clipboard.convert(html);
};

export const setDeltaContentsInQl = (delta, qlInst) => {
  const instanceToUse = getQlInstanceToUse(qlInst);
  instanceToUse.setContents(delta);
};

export const setDataAndGetHTMLFromQl = (data, qlInst) => {
  const instanceToUse = getQlInstanceToUse(qlInst);
  setDataInQl(data, false, instanceToUse);
  return getHTMLFromQl(instanceToUse);
};

export const areQlDeltasEqual = (oldDelta, newDelta) => {
  const oldList = oldDelta.ops;
  const newList = newDelta.ops;

  return isEqual(JSON.stringify(oldList), JSON.stringify(newList));
};

export const stichStringFromQlDelta = (delta, processDeltaFnc) => {
  let str = "";
  const opsList = delta.ops;

  if (Array.isArray(opsList)) {
    opsList.forEach((o) => {
      str += processDeltaFnc(o, str);
      // if (typeof o.insert === "string") {
      //   strAccu += o.insert;
      // } else if (typeof o.insert === "object") {
      //   strAccu += processDeltaFnc(o);
      //   // if(o.insert.resourceMention){
      //   //   strAccu += ` @${o.insert.resourceMention.}`
      //   // }
      // }
    }, str);
  }

  return str;
};
