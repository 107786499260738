export const colorThemeOpts = [
  {
    label: "Tangerine",
    value: "tangerine",
  },
  {
    label: "Cherry",
    value: "cherry",
  },
  {
    label: "Olive",
    value: "olive",
  },
  {
    label: "Ocean",
    value: "ocean",
  },
];
