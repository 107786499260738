<template>
  <div>
    <OptSelectorInput
      ref="select"
      :value="selectedVal"
      :opts="resourceCateOpts"
      :itemLabelToUse="labelType"
      itemValueToUse="value"
      :placeholder="placeholderText"
      @change="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="handleKeyDown"
      selectorClass="opt-selector"
      :useCustomSlot="enableNoDataSlot"
    >
      <template v-if="enableNoDataSlot" #no-data>
        <v-btn @click="addNewGroup" text class="new-group-btn">
          Add New Group
        </v-btn>
      </template>
    </OptSelectorInput>

    <template v-if="enableNoDataSlot">
      <ResourceCateForm @resource-cate:added="handleResourceCateAdd" />
    </template>
  </div>

  <!-- <div class="resource-cate-selector-wrapper"> -->
  <!-- <OptionSelectorInput
    class="opt-selector"
    :value="value"
    @input="handleChange"
    @keydown="handleKeyDown"
    @blur="handleBlur"
    @focus="handleFocus"
    placeholder="Select Group"
    :optLabelProp="labelType"
    optValueProp="value"
    :opts="resourceCateOpts"
    ref="select"
    :menuProps="menuProps"
  /> -->
  <!-- </div> -->
</template>
<script>
import ResourceCateForm from "./ResourceCateForm.vue";
import OptSelectorInput from "@/components/OptSelectorInput";
import { mapActions, mapGetters } from "vuex";
import { createResourceCategoriesOpts } from "@/helpers/resourceCategoriesHelpers";
export default {
  data() {
    return {
      selectedVal: "",
    };
  },
  components: {
    // OptionSelectorInput,
    OptSelectorInput,
    ResourceCateForm,
  },
  props: {
    value: {
      type: String,
    },
    labelType: {
      type: String,
      default: "fullLabel",
    },
    optsList: {
      type: Array,
    },
    useProvidedOpts: {
      type: Boolean,
    },
    menuProps: {
      type: Object,
    },
    cateType: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    useNoDataSlot: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions("cateData", ["openResourceCateForm"]),
    handleChange(val) {
      this.$emit("input", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    openInput() {
      this.$refs.select.openInput();
    },
    blurInput() {
      this.$refs.select.blurInput();
    },
    addNewGroup() {
      const currInput = this.$el.querySelector(".v-select__slot  input");
      const enteredVal = currInput.value && currInput.value.trim();

      this.openResourceCateForm({
        data: {
          name: enteredVal,
          cateType: this.cateType || this.selectedResourceTypeOpt,
        },
      });
    },
    handleResourceCateAdd(resourceCateData) {
      this.selectedVal = resourceCateData.key;
      this.handleChange(resourceCateData.key);
    },
  },
  computed: {
    ...mapGetters("resourcesData", ["categories", "selectedResourceTypeOpt"]),
    resourceCateOpts() {
      if (this.useProvidedOpts) {
        return this.optsList;
      }
      return createResourceCategoriesOpts(this.categories, this.cateType);
    },
    placeholderText() {
      return this.placeholder ? this.placeholder : "Select Group";
    },
    enableNoDataSlot() {
      if (this.useProvidedOpts) {
        return this.useNoDataSlot;
      }

      return true;
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedVal = newVal;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.new-group-btn {
  width: 100%;
  text-transform: none !important;
}
</style>
