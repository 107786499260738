import { increment } from "@/utils/number";
import {
  addDays,
  addWeeks,
  addMonths,
  setHours,
  format,
  setMinutes,
  isValid,
  parse,
  isSameDay,
  formatISO,
  parseISO,
  subDays,
  startOfDay,
  startOfWeek,
  startOfMonth,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  endOfDay,
  endOfWeek,
  endOfMonth,
  isWithinInterval,
  nextDay,
  getHours,
  getMinutes,
  set as setInDate,
  eachDayOfInterval,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  set,
} from "date-fns";

export { parseISO, sub as subDate, parseJSON as parseJSONDate } from "date-fns";
export { endOfDay };
import { DateTime } from "luxon";
import {
  DATE_SCHEMA_REGEX,
  DATE_SHORTCUTS_REGEX,
  DAY_STRING_REGEX,
  TIME_IN_24_HOURS_REGEX,
  TIME_SHORTCUTS_REGEX,
  WEEK_DAYS_SHORT_REGEX,
} from "../variables/regex";

/**
 * @param {Date} str - Date
 * @returns {String} Formatted Time in HH:mm
 */
export const makeTimeFormat = (str) => {
  const d = str ? new Date(str) : new Date();
  const fullText = d.toTimeString();
  return fullText.split(":")[0] + ":" + fullText.split(":")[1];
};

/**
 * @param {Date} str - Date
 * @returns {String} Formatted Date in YYYY-MM-DD
 */
export const makeDateFormat = (str) => {
  let date = str ? new Date(str) : new Date();
  let dd = date.getDate();
  let mm = increment(date.getMonth(), 1);
  let yy = date.getFullYear().toString();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yy + "-" + mm + "-" + dd;
};

/**
 * @param {String} command - short cut command to create date for example : '#2a','2a'
 * @returns {Date} Created date
 */
export const createDateFromCommand = (command, providedDate) => {
  // Uses regex to extract commands from string;
  const args =
    command.match(DATE_SHORTCUTS_REGEX) ||
    command.match(TIME_SHORTCUTS_REGEX) ||
    command.match(DAY_STRING_REGEX) ||
    command.match(TIME_IN_24_HOURS_REGEX) ||
    command.match(WEEK_DAYS_SHORT_REGEX);
  const {
    short1,
    short2,
    numb1,
    numb2,
    shortString,
    timeShort1,
    timeShort2,
    timeNum1,
    timeNum2,
    timeNumHrs,
    timeNumMins,
    timeNumHrs2,
    timeNumMins2,
    timeShortKey1,
    timeShortKey2,
    numHrsIn24_1,
    numHrsIn24_2,
    numMinsIn24_1,
    numMinsIn24_2,
    weekDayFull,
    weekDayShort,
  } = args?.groups || {};

  // shortString;
  let valueToSet;
  const shortKey = short1 || short2 || shortString; // Day key short
  const weekDayShortKey = weekDayFull || weekDayShort; // Week day short;
  const numbToAdd = numb1 || numb2; // Number to add
  const timeShort = timeShort1 || timeShort2; // Time short key
  const timeNumbToSet = timeNum1 || timeNum2; // Time in hrs
  const timeMeridiemKey = timeShortKey1 || timeShortKey2; // meridiem 'a' or 'p'
  const timeInHrs = timeNumHrs || timeNumHrs2 || numHrsIn24_1 || numHrsIn24_2; // Second pattern variable to store hrs
  const timeInMins =
    timeNumMins || timeNumMins2 || numMinsIn24_1 || numMinsIn24_2; // Second pattern variable to store minutes

  if (shortKey && numbToAdd) {
    let currValue = new Date();

    if (isValidDate(providedDate)) {
      const hoursToSet = getHours(providedDate);
      const minsToSet = getMinutes(providedDate);

      currValue = setInDate(currValue, {
        hours: hoursToSet,
        minutes: minsToSet,
      });
    }
    const normalisedShortKey = shortKey.toLowerCase();
    switch (normalisedShortKey) {
      case "d":
        currValue = addDays(currValue, numbToAdd); // Add days in current date
        break;
      case "w":
        currValue = addWeeks(currValue, numbToAdd); // Adds weeks in current date
        break;
      case "m":
        currValue = addMonths(currValue, numbToAdd); // Adds months in current date
        break;
    }
    valueToSet = currValue; // Assigns the value to use
  } else if (shortKey) {
    let currValue = new Date();
    let setMins = true;
    if (isValidDate(providedDate)) {
      const hoursToSet = getHours(providedDate);
      const minsToSet = getMinutes(providedDate);

      currValue = setInDate(currValue, {
        hours: hoursToSet,
        minutes: minsToSet,
      });
      setMins = false;
    }
    const normisedShortKey = shortKey.toLowerCase();

    switch (normisedShortKey) {
      case "d": // Check if shortKey is 'd' then add one day into the current date
      case "t":
      case "tomorrow":
        valueToSet = addDays(currValue, 1);
        break;
      case "w": // Check if shortKey is 'w' then add one week into the current date
        valueToSet = addWeeks(currValue, 1);
        break;
      case "m": // Check if shortKey is 'm' then add one month into the current date
        valueToSet = addMonths(currValue, 1);
        break;
      case "now": // Check if shortKey is 'now' then assign current date and time
      case "n":
        valueToSet = new Date();
        setMins = false;
        break;
      case "y":
      case "yesterday":
        valueToSet = subDays(currValue, 1);
        break;
    }

    if (setMins) {
      valueToSet = setMinutes(valueToSet, 0);
    }

    // valueToSet = setMins ? setMinutes(valueToSet, 0) : valueToSet;
  } else if (timeShort && timeNumbToSet) {
    const normalisedTimeShort = timeShort.toLowerCase();
    let hoursToSet = parseInt(timeNumbToSet, 10); // Converts number of hrs into int
    if (hoursToSet === 12) {
      hoursToSet = 0;
    }
    if (normalisedTimeShort === "p") {
      hoursToSet = increment(hoursToSet, 12);
    }
    const currValue = isValidDate(providedDate) ? providedDate : new Date();
    valueToSet = setHours(currValue, hoursToSet); // Sets hours in current date
    valueToSet = setMinutes(valueToSet, 0); // Sets hours in current date
  } else if (timeMeridiemKey && timeInHrs && timeInMins) {
    const normalisedTimeMeridiemKey = timeMeridiemKey.toLowerCase();
    let hoursToSet = parseInt(timeInHrs, 10); // Converts number of hrs into int
    let minsToSet = parseInt(timeInMins, 10); // Converts number of hrs into int
    if (hoursToSet === 12) {
      hoursToSet = 0;
    }
    if (normalisedTimeMeridiemKey === "p") {
      hoursToSet = increment(hoursToSet, 12);
    }

    const currValue = isValidDate(providedDate) ? providedDate : new Date();

    valueToSet = setHours(currValue, hoursToSet);
    valueToSet = setMinutes(valueToSet, minsToSet);
  } else if (timeInHrs && timeInMins) {
    let hoursToSet = parseInt(timeInHrs, 10); // Converts number of hrs into int
    let minsToSet = parseInt(timeInMins, 10); // Converts number of hrs into int
    const currValue = isValidDate(providedDate) ? providedDate : new Date();

    valueToSet = setHours(currValue, hoursToSet);
    valueToSet = setMinutes(valueToSet, minsToSet);
  } else if (weekDayShortKey) {
    let currValue = new Date();
    if (isValidDate(providedDate)) {
      const hoursToSet = getHours(providedDate);
      const minsToSet = getMinutes(providedDate);

      currValue = setInDate(currValue, {
        hours: hoursToSet,
        minutes: minsToSet,
      });
      // currValue = providedDate;
    }
    const parsedWeekDay = getWeekDayFromShortKey(weekDayShortKey);

    if (parsedWeekDay !== undefined) {
      valueToSet = nextDay(currValue, parsedWeekDay);
    }
  }
  return valueToSet;
};

/**
 * @returns {String} Current Date in 'YYYY-MM-DD' format
 */
export const getCurrDate = (type = "extended") => {
  const today = new Date();
  if (type === "extended") {
    return formatISO(today);
  }

  return format(today, "yyyy-MM-dd");
};

export const isValidDate = (date) => isValid(date);

export const parseDate = (str, format) => parse(str, format, new Date());
export const parseISODate = (str) => parseISO(str);
export const formatDate = (date, formatString) => format(date, formatString);

export const addDaysToDate = (date, numberOfDays) =>
  addDays(date, numberOfDays);

export const addWeeksToDate = (date, numberOfWeeks) =>
  addWeeks(date, numberOfWeeks);
export const addMonthsToDate = (date, numberOfMonths) =>
  addMonths(date, numberOfMonths);

export const parseAndFormatISOString = (date, formatToReturn) => {
  const parsedDate = parseISO(date);
  return formatDate(parsedDate, formatToReturn);
};
export const parseAndFormatDate = (date, parseFormat, formatToReturn) => {
  const parsedDate = parse(date, parseFormat, new Date());
  return formatDate(parsedDate, formatToReturn);
};

export const isSameDates = (dateLeft, dateRight) =>
  isSameDay(dateLeft, dateRight);

export const startOfGetters = {
  day: startOfDay,
  week: startOfWeek,
  month: startOfMonth,
};

export const endOfGetters = {
  day: endOfDay,
  week: endOfWeek,
  month: endOfMonth,
};

export const diffGetters = {
  day: differenceInDays,
  week: differenceInWeeks,
  month: differenceInMonths,
};

export const isBetweenInterval = (dateToCheck, startDate, endDate) =>
  isWithinInterval(dateToCheck, {
    start: startDate,
    end: endDate,
  });

/**
 * @param {Date} date - Date
 * @returns {Object}
 */
export const getComponentsFromDate = (date, type = "default") => {
  return type === "utc"
    ? {
        hours: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
        day: date.getUTCDate(),
        month: date.getUTCMonth(),
        year: date.getUTCFullYear(),
        seconds: date.getUTCSeconds(),
        weekDay: date.getUTCDay(),
      }
    : {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        seconds: date.getSeconds(),
        weekDay: date.getDay(),
      };
};

export const converDateToUTC = (date, withTime = true, type = "default") => {
  const dateComponents = getComponentsFromDate(date, type);

  const componentsForUTC = [
    dateComponents.year,
    dateComponents.month,
    dateComponents.day,
  ];

  if (withTime) {
    componentsForUTC.push(dateComponents.hours, dateComponents.minutes);
  }

  // const convertedDate = new Date(Date.UTC(...componentsForUTC));
  // return convertedDate;

  return new Date(Date.UTC(...componentsForUTC));
};

export const getCurrentTimeZoneName = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertDateToUTCWithLocalTime = (date) => {
  return DateTime.fromJSDate(date)
    .toUTC()
    .setZone("local", { keepLocalTime: true })
    .toJSDate();
};

export const hasDateSchema = (str) => DATE_SCHEMA_REGEX.test(str);

export const getRoundedDate = (minutes, d = new Date()) => {
  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

  return roundedDate;
};

export const getWeekDayFromShortKey = (shortKey) => {
  let parsedDay;
  if (shortKey) {
    const normalisedWeekDayShortKey = shortKey.toLowerCase();
    const firstTwoChars = normalisedWeekDayShortKey.slice(0, 2);
    switch (firstTwoChars) {
      // case "monday":
      case "mo":
        parsedDay = 1;
        break;
      // case "tuesday":
      case "tu":
        parsedDay = 2;
        break;
      case "we":
        // case "wed":
        parsedDay = 3;
        break;
      // case "thursday":
      case "th":
        parsedDay = 4;
        break;
      // case "friday":
      case "fr":
        parsedDay = 5;
        break;
      // case "saturday":
      case "sa":
        parsedDay = 6;
        break;
      // case "sunday":
      case "su":
        parsedDay = 0;
        break;
    }
  }

  return parsedDay;
};

export const isDateBetweenDates = (givenDate, startDate, endDate) => {
  return givenDate >= startDate && givenDate <= endDate;
};

export const createDates = ({ start, end, step }) => {
  return eachDayOfInterval(
    {
      start,
      end,
    },
    {
      step,
    }
  );
};

export const extractDateFromString = (str, dateRegex) => {
  return str.match(dateRegex)[0];
};

export const createDateRange = (start, end) => {
  return eachDayOfInterval({
    start: new Date(start),
    end: new Date(end),
  });
};

export const addDates = () => {};

export const sortDateArray = (arr) => {
  return arr.sort((a, b) => a.localeCompare(b));
};

export const isDateArraySorted = (arr) => {
  return arr.slice(1).every((item, i) => arr[i] <= item);
};

export const getDateValue = (d) => {
  return isValidDate(d) ? d : null;
};

export const subFromDate = (date, type, count = 1) => {
  let finalDate;
  switch (type) {
    case "week":
      finalDate = subWeeks(date, count);
      break;
    case "month":
      finalDate = subMonths(date, count);
      break;
    case "quarter":
      finalDate = subQuarters(date, count);
      break;
    case "year":
      finalDate = subYears(date, count);
      break;
  }

  return finalDate;
};

const getValidDate = function (d) {
  return new Date(d);
};
export const isBetweenDates = (givenDate, fromDate, toDate) => {
  return (
    getValidDate(givenDate) <= getValidDate(toDate) &&
    getValidDate(givenDate) >= getValidDate(fromDate)
  );
};

/**
 * Create date object with only current date value no time
 * @returns {Date}
 */
export const createCurrDateOnly = () => {
  return set(new Date(), {
    hours: 0,
    minutes: 0,
  });
};
