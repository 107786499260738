<template>
  <v-autocomplete
    ref="select"
    :value="value"
    :items="cateOpts"
    :item-text="labelToUse"
    item-value="value"
    auto-select-first
    :placeholder="placeholder"
    @change="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    @keydown="handleKeyDown"
    class="project-category-input"
    hide-details
  >
    <template #no-data>
      <v-btn @click="addNewCategory" text class="new-category-btn">
        Add New Category
      </v-btn>
    </template>
  </v-autocomplete>
</template>
<script>
import {
  createProjectCategoryOpts,
  createAllCategoriesOpts,
} from "@/helpers/projects";
import { mapGetters, mapMutations } from "vuex";
import {
  createTaskCategoryDataForAdd,
  storeCatAddAction,
} from "@/helpers/categories";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { storeTaskCateInResourceCateData } from "@/helpers/resourceCategoriesHelpers";
import { REFRESH_TREE_EVENT } from "@/variables/events";
import EventEmitter from "@/helpers/eventEmitter";
import { PRIMARY_COLOR } from "@/variables/colors";
export default {
  mixins: [userDetailsMixin],
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Select Category",
    },
    labelType: {
      type: String,
      default: "full",
    },
    subcategories: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openInput() {
      this.$refs.select.onClick(this.$refs.select);
    },
    blurInput() {
      this.$refs.select.blur();
    },
    handleChange(val) {
      this.$emit("change", val);
      this.$emit("input", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    async addNewCategory() {
      const treeEl = this.showClearedTasks
        ? document.querySelector("#treeview-all-data")
        : document.querySelector("#treeview");
      const cateTree = treeEl?.ej2_instances[0];
      const currInput = this.$el.querySelector(".v-select__slot  input");
      const enteredVal = currInput.value && currInput.value.trim();

      if (!enteredVal) {
        this.$swal({
          titleText: "Oops!",
          html: "Category name is required",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
        });

        return;
      }

      this.setLoader(true);

      const createdTaskCategoryData = createTaskCategoryDataForAdd({
        title: enteredVal,
      });

      const currTaskCateTree = this.projectsTree || [];

      const cateOrder =
        currTaskCateTree.length || Object.keys(this.categories).length;

      createdTaskCategoryData.order = cateOrder;

      await DatabaseInterface.update(
        {
          [`/categories/${createdTaskCategoryData.key}`]:
            createdTaskCategoryData,
        },
        this.userInfo.uid
      );

      storeCatAddAction(createdTaskCategoryData);
      storeTaskCateInResourceCateData(createdTaskCategoryData);

      if (cateTree) {
        EventEmitter.emit(REFRESH_TREE_EVENT, false);
      }

      await this.$nextTick();
      this.setLoader(false);
      setTimeout(() => {
        this.handleChange(createdTaskCategoryData.key);
      }, 250);

      // setTimeout(() => {
      //   this.$emit("category:added", createdTaskCategoryData);
      // }, 0);
    },
    ...mapMutations(["setLoader"]),
  },
  computed: {
    ...mapGetters("task", [
      "categories",
      "projects",
      "projectsTree",
      "showClearedTasks",
    ]),
    labelToUse() {
      return this.labelType === "short" ? "shortLabel" : "fullLabel";
    },
    cateOpts() {
      if (this.categories) {
        if (this.subcategories) {
          return createAllCategoriesOpts(this.projects, this.categories, false);
        }
        return createProjectCategoryOpts(this.categories);
      }
      return [];
    },
  },
};
</script>
<style scoped>
.new-category-btn {
  width: 100%;
  text-transform: none !important;
}
</style>
