<template>
  <div class="priority-selector-wrapper">
    <SelectorInput
      ref="select"
      :opts="priorityOpts"
      :value="value"
      @input="handleValueChange"
      @keydown="handleKeyDown"
      @focus="handleFocus"
      :openOnFocus="openOnFocus"
      class="task-priority-selector"
    >
      <template #activator-btn="{ handleClick }">
        <v-btn
          :color="selectedPriorityColor"
          dark
          min-width="15px"
          :class="selectorBtnClasses"
          small
          @click="handleClick"
        >
        </v-btn>
      </template>
      <template #item-opt="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" @keydown="handleKeyDown">
          <v-icon :color="item.color" class="mr-3"> mdi-circle </v-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </SelectorInput>
  </div>
</template>

<script>
import SelectorInput from "@/components/PopupLikeSelector";
import { priorityTypeOpts, priorityTypesMap } from "@/data/priorityTypes";
// eslint-disable-next-line no-unused-vars
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
export default {
  components: {
    SelectorInput,
  },
  props: {
    value: {
      type: String,
    },
    contentClass: {
      type: String,
    },
    openOnFocus: {
      type: Boolean,
    },
  },
  computed: {
    priorityOpts() {
      return priorityTypeOpts;
    },
    selectedPriorityColor() {
      return (
        priorityTypesMap[this.value]?.color || priorityTypesMap["none"].color
      );
    },
    selectorBtnClasses() {
      return {
        "priority-selector": true,
        [this.contentClass]: !!this.contentClass,
      };
    },
  },
  methods: {
    handleValueChange(val) {
      this.$emit("input", val);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    openInput() {
      this.$refs.select.openInput();
    },
    blurInput() {
      this.$refs.select.blurInput();
    },
  },
};
</script>
<style scoped>
.priority-selector {
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  margin-bottom: 3px !important;
}
.priority-selector-wrapper >>> #menu-wrapper .v-select__selections {
  width: 10px !important;
  min-width: auto !important;
}

.priority-selector-wrapper >>> #menu-wrapper input[readonly] {
  width: 10px !important;
}
.task-priority-selector >>> .v-input {
  margin-top: 0px;
  padding-top: 0px;
}

.task-priority-selector >>> input[readonly="readonly"] {
  padding: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.task-priority-selector >>> .v-input__append-inner {
  position: absolute;
  padding: 0px;
  margin: 0px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.task-priority-selector >>> .v-input__append-inner .v-input__icon--append {
  height: 100%;
  width: 100%;
  min-width: 0px;
}
</style>
