import { RRule, RRuleSet, rrulestr } from "rrule";
import { formatDate, isValidDate } from "./dates";

const weekDays = {
  0: RRule.SU,
  1: RRule.MO,
  2: RRule.TU,
  3: RRule.WE,
  4: RRule.TH,
  5: RRule.FR,
  6: RRule.SA,
};

// const defaultRRuleParseOpts = {
//   forceset: true,
// };
export const parseRRuleFromString = (ruleString) => {
  return RRule.parseString(ruleString);
};

export const createRRule = (rulesOptions) => {
  return new RRule({
    ...rulesOptions,
  });
};

export const convertRRuleStringToArray = (rulesString) => {
  return rulesString.split(/\r\n|\r|\n/);
};

export const getRRuleWeekDay = (dayOfWeek) => weekDays[dayOfWeek];

export const createRRuleSet = (ruleData) => {
  const rruleSet = new RRuleSet();
  rruleSet.rrule(new RRule({ ...ruleData }));
  return rruleSet;
};

export const getRuleSetFromRuleString = (ruleString, options = {}) => {
  return rrulestr(ruleString, { ...options });
};

export const addDTSTARTRuleInRulesString = (dtstart, ruleString) => {
  if (!isValidDate(dtstart)) {
    throw new Error("DTSTART is invalid");
  }

  // console.debug(
  //   "DDDDDD",
  //   formatInTimeZone(dtstart, getCurrentTimeZoneName(), "yyyyMMdd'T'HHmmss")
  // );

  // const convertedRecurrence = DateTime.fromJSDate(dtstart)
  //   .toUTC()
  //   .setZone("local", { keepLocalTime: true })
  //   .toJSDate();

  const formattedDate = formatDate(dtstart, "yyyyMMdd'T000000Z'");

  return `DTSTART:${formattedDate}\n${ruleString}`;
};
