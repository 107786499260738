const getters = {
  areaId(state) {
    return state.areaId;
  },
  isOpen(state) {
    return state.isOpen;
  },
  isAddMode(state) {
    return state.isAddMode;
  },
  taskList(state) {
    return state.taskList;
  },
  taskUpdates(state) {
    return state.taskUpdates;
  },
};

export default getters;
