<template>
  <div
    class="tasks-list-wrapper d-flex flex-column flex-nowrap fill-height pl-4"
    tabindex="0"
    @keydown="handleKeyDown"
  >
    <FiltersBar :dataGridRef="$refs.dataGrid" />

    <div v-show="displayPlaceholder" class="select-tooltip-wrapper">
      <SelectTooltip :isGraphEnabled="showGraph" />
    </div>
    <div class="flex-grow-1 overflow-hidden tasks-grid-wrapper">
      <!-- <div v-show="showGraph" style="height: 500px; max-width: 800px">
        <BarGraph
          :yAxisData="finalChartOpts.yAxisData"
          :barSeriesData="finalChartOpts.seriesData"
        />
      </div> -->

      <div v-show="!showGraph" style="height: 100%">
        <TasksGrid ref="dataGrid" :isActive="isActive" />
      </div>
    </div>
  </div>
</template>

<script>
import FiltersBar from "../TasksFilterBar/TasksFilterBar.vue";
import TasksGrid from "./TasksGrid.vue";
import BarGraph from "@/core/components/BarGraph.vue";
import {
  NODE_BROWSE_STRING_KEYCODES,
  // eslint-disable-next-line no-unused-vars
  // NODE_BROWSE_KEYS,
} from "@/variables/keys";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";
import { treeSideBarModes } from "@/variables/viewConfigs";
import SelectTooltip from "./SelectTooltip.vue";
import {
  checkIfSelectedTaskFiltersHasCategory,
  checkTaskAdditionalFiltersEmpty,
  // checkIfTaskIsParentRecurringTask,
  createTaskTypeSeriesDataFromCategoryList,
  groupTasksByTaskType,
  // isTaskRecurring,
} from "@/helpers/tasks";
import isEqual from "lodash/isEqual";
// import filterTasks from "@/core/components/DataTable/lib/pandas/filter";
import DataFrame from "@/core/components/DataTable/lib/pandas/pandas";
// import { DARK_ICON_COLOR } from "@/variables/colors";
import { getTreeFields } from "@/components/TasksCateView/helpers/treeUtils";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
export default {
  mixins: [tasksHelpersMixin],
  components: {
    FiltersBar,
    TasksGrid,
    SelectTooltip,
    BarGraph,
  },
  props: {
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      finalChartOpts: {},
    };
  },
  methods: {
    handleKeyDown(e) {
      let callCateFilterHandler = false;
      // if (e.key && NODE_BROWSE_STRING_KEYCODES.includes(e.key)) {
      //   callCateFilterHandler = true;
      // } else {
      //   const keyCode = e.which ? e.which : e.keyCode;
      //   if (NODE_BROWSE_KEYS.includes(keyCode)) {
      //     callCateFilterHandler = true;
      //   }
      // }
      if (callCateFilterHandler) {
        this.browserCateFilters(e);
      }
    },
    browserCateFilters(e) {
      let arrowRightCalled = false;
      // eslint-disable-next-line no-unused-vars
      let arrowLeftCalled = false;

      if (e.key && NODE_BROWSE_STRING_KEYCODES.includes(e.key)) {
        if (e.key === "ArrowLeft") {
          arrowLeftCalled = true;
        } else if (e.key === "ArrowRight") {
          arrowRightCalled = true;
        }
      } else {
        const keyCode = e.which ? e.which : e.keyCode;
        if (keyCode === 37) {
          arrowLeftCalled = true;
        } else if (keyCode === 39) {
          arrowRightCalled = true;
        }
      }

      const categoryFiltersEl = document.querySelector(".category-filters");

      if (categoryFiltersEl) {
        const categoryNodeList =
          categoryFiltersEl.querySelectorAll(".cate-filter-btn");

        if (categoryNodeList) {
          let currActiveCateFilters = !isEmpty(this.filters.category)
            ? cloneDeep(this.filters.category)
            : [];

          if (
            treeSideBarModes.includes(this.currNav) &&
            !isEmpty(this.selectedOptsList)
          ) {
            currActiveCateFilters = this.selectedOptsList
              .filter((i) => i.type === "category")
              .map((n) => n.id);
          }
          let indexToUse = -1;

          if (!isEmpty(currActiveCateFilters)) {
            const cateIndex = [...categoryNodeList].findIndex(
              (n) => n.dataset.filterCateId === currActiveCateFilters[0]
            );
            if (cateIndex >= 0) {
              indexToUse = cateIndex;
            }
          }
          indexToUse = arrowRightCalled ? indexToUse + 1 : indexToUse - 1;

          if (indexToUse < 0) {
            indexToUse = categoryNodeList.length - 1;
          } else if (indexToUse === categoryNodeList.length) {
            indexToUse = 0;
          }
          if (categoryNodeList[indexToUse]) {
            const selectedEvent = new Event("selected");
            categoryNodeList[indexToUse].dispatchEvent(selectedEvent);
          }
        }
      }
    },
    updateListRow(updates, refreshList) {
      this.$refs.dataGrid?.updateRowData(updates, refreshList);
    },
    getGridRef() {
      return this.$refs.dataGrid;
    },
    getCurrEditingRowId() {
      return this.$refs.dataGrid?.getCurrEditingRowId();
    },
    isGridInEditMode() {
      return this.$refs.dataGrid?.isGridInEditMode();
    },
    /**
     * To Create category series.
     * @param {String} name Label/Name of the category.
     * @param {Number[]} data data related to category.
     * @param {Object=} extraParams extraParams to be passed.
     */
    createCategorySeries(name, data, extraParams) {
      return {
        name,
        type: "bar",
        stack: "total",
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data,
        ...extraParams,
      };
    },
  },
  computed: {
    ...mapGetters("task", [
      "currNav",
      "selectedOptsList",
      "filters",
      "isGlobalViewEnabled",
      "isInboxViewEnabled",
      "currNav",
      "groupMode",
      "searchQuery",
      "tasks",
      "categories",
      "projects",
      "tasksMap",
      "showClearedTasks",
    ]),
    ...mapGetters(["isAreasTipHidden"]),
    isSearchKeywordValid() {
      let searchQuery = this.searchQuery;

      searchQuery = searchQuery && searchQuery.trim();

      return !isEmpty(searchQuery) && searchQuery !== "*";
    },
    isInNextActionsMode() {
      return this.groupMode === 1 && this.currNav === 4;
    },
    displayPlaceholder() {
      return (
        !this.isAreasTipHidden &&
        this.isInNextActionsMode &&
        !this.isGlobalViewEnabled &&
        isEmpty(this.selectedOptsList) &&
        !checkIfSelectedTaskFiltersHasCategory(this.filters) &&
        !this.isSearchKeywordValid &&
        !this.showGraph
      );
    },
    showGraph() {
      return false;
      // return (
      //   this.isInNextActionsMode &&
      //   !this.isGlobalViewEnabled &&
      //   isEmpty(this.selectedOptsList) &&
      //   checkTaskAdditionalFiltersEmpty(this.filters) &&
      //   this.showAllOrderedTasks &&
      //   !this.isSearchKeywordValid &&
      //   this.isActive &&
      //   !isEmpty(this.finalChartOpts.seriesData)
      // );
    },
    taskDataFrame() {
      let currTasks = null;
      if (this.tasks && this.tasks.length) {
        const columns = new Set([
          ...Object.keys(this.tasks[0]),
          "due",
          "project",
        ]);

        currTasks = new DataFrame(this.tasks, [...columns]);
      }
      return currTasks;
    },
    isInTreeViewMode() {
      return (
        treeSideBarModes.includes(this.currNav) && !this.isInboxViewEnabled
      );
    },
    chartOptions() {
      const { visbleDataSource, dataSource: allDataSource } = getTreeFields({
        categories: this.categories,
        projects: this.projects,
        tasksMap: this.tasksMap,
        selectedNodes: [],
        isInspectModeEnabled: false,
        selectedCategories: [],
      });

      let listToUse = allDataSource;

      if (!this.showClearedTasks) {
        listToUse = visbleDataSource;
      }

      const cateTaskTypesMap = {};
      listToUse.forEach((c) => {
        const categoryTasksMap = c.taskListMap;

        if (!isEmpty(categoryTasksMap)) {
          let tasksList = categoryTasksMap.all;

          if (!this.showClearedTasks) {
            tasksList = categoryTasksMap.incomplete;
          }
          const localCategoryTasksMap = {
            tasksTypeMap: {},
            totalTasksCount: 0,
            key: c.title,
          };
          let totalCategoryTasksCount = 0;

          const res = groupTasksByTaskType(tasksList);

          if (res.totalTasks) {
            totalCategoryTasksCount = res.totalTasks;
          }
          localCategoryTasksMap.tasksTypeMap = res.tasksTypeMap;
          localCategoryTasksMap.totalTasksCount = totalCategoryTasksCount;
          cateTaskTypesMap[c.title] = localCategoryTasksMap;
        }
      });

      const cateTasksList = Object.values(cateTaskTypesMap);

      cateTasksList
        .sort((a, b) => b.totalTasksCount - a.totalTasksCount)
        .reverse()
        .filter((cateTaskMap) => cateTaskMap.totalTasksCount > 0);

      const reversedCategoryNamesList = cateTasksList.map((c) => c.key);

      const barsData = createTaskTypeSeriesDataFromCategoryList(
        cateTasksList,
        cateTaskTypesMap
      );

      return Object.freeze({
        yAxisData: reversedCategoryNamesList,
        seriesData: barsData,
      });
    },
  },
  watch: {
    // chartOptions: {
    //   handler(n, o) {
    //     if (!isEqual(n, o)) {
    //       this.finalChartOpts = n;
    //     }
    //   },
    // },
  },
};
</script>

<style scoped>
.tasks-list-wrapper {
  outline: none;
}

.tasks-grid-wrapper {
  margin-top: 6px;
}

.select-tooltip-wrapper {
  /* padding-top: 5px; */
}
</style>
