<template>
  <span :class="wrapperClasses">
    <v-icon
      v-show="data.isResource"
      :color="resourceColor"
      size="15"
      class="mention-icon"
    >
      $ctiMentionSymbol
    </v-icon>
    <span
      class="data-container"
      :data-resrc-cat-id="data.key"
      :data-no-hover="disableHover"
      :class="dataNodeClasses"
    >
      <span>
        {{ data.name }}
      </span>
      <EditTreeNodeBtn
        :itemCount="count"
        menuId="resource-cat-menu"
        :nodeId="data.key"
        preventEvent
      />
    </span>
  </span>
</template>
<script>
import EditTreeNodeBtn from "@/components/EditTreeNodeBtn";
import { mapGetters } from "vuex";
import { checkIfResourceNodeHasNoProject } from "@/helpers/resourceCategoriesHelpers";
export default {
  components: {
    EditTreeNodeBtn,
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapGetters("task", ["showClearedTasks"]),
    ...mapGetters("resourcesData", {
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
    }),
    dataNodeClasses() {
      return {
        "node-with-symbol": this.data.isResource,
        "no-project-node": this.data.isNoProjectNode,
      };
    },
    wrapperClasses() {
      return {
        [this.data.type]: true,
        "node-wrapper": true,
      };
    },
    count() {
      if (checkIfResourceNodeHasNoProject(this.data.key)) {
        return this.data.totalCount || null;
      }
      const valToUse = this.showClearedTasks
        ? this.data.totalAllTasksCount
        : this.data.totalIncompleteTasksCount;

      return valToUse || null;
    },
    disableHover() {
      return checkIfResourceNodeHasNoProject(this.data.key) ? "true" : "false";
    },
    resourceColor() {
      const selectedResourceType = this.selectedResourceTypeOpt;

      let color = "";
      switch (selectedResourceType) {
        case "place":
          color = "--place-secondary";
          break;
        case "project":
          color = `--project-secondary`;
          break;
        case "thing":
          color = "--thing-secondary";
          break;
        case "people":
          color = "--people-secondary";
      }

      return color ? `var(${color})` : "";
    },
  },
};
</script>
<style scoped>
.data-container {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  min-height: 25px;
  padding-right: 10px;
}

.data-container[data-no-hover="false"]:hover >>> .edit-btn-wrapper {
  display: inline-block;
}

.mention-icon {
  /* margin-right: 5px; */
  position: absolute;
}

.node-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.node-with-symbol {
  padding-left: 20px;
  padding-right: 10px !important;
}

.no-project-node {
  margin-left: 5px;
}
</style>
