export const UNDO_EVENT = "trigger-undo";
export const REDO_EVENT = "trigger-redo";
export const ADD_NOTE_EVENT = "add-note";
export const FOLDER_NAME_CHANGED_EVENT = "folder-name-changed";
export const REGROUP_NOTES_EVENT = "regroup-notes";
export const UPDATE_NOTES_EVENT = "update-notes";
export const SELECT_TASKS_EVENT = "select-tasks";
export const RECOUNT_TASKS_AFTER_MOVE_EVENT = "recount-tasks-after-move";
export const RECOUNT_ITEMS_AFTER_MOVE_EVENT = "recount-item-after-move";
export const TREE_NODE_RENAME_EVENT = "node-rename";
export const TREE_NODE_MOVED_EVENT = "node-moved";
export const REFRESH_TREE_EVENT = "refresh-tree";
export const SET_SELECTED_NODE_EVENT = "set-selected-note";
export const TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT =
  "toogle-store-open-close-node";
export const ADD_DATA_ITEM_EVENT = "add-data-item";
export const REFRESH_DATA_LIST_EVENT = "refresh-data-list";
export const REFRESH_FORM_DATA = "refresh-form-data";
export const REFRESH_RESOURCE_FORM_DATA = "refresh-form-data";
export const REFRESH_RESOURCE_DESCR = "refresh-resource-descr";
export const ADD_REMOVE_UPDATE_DATA_ITEM_EVENT = "add-remove-update-data-item";
export const CLOSE_INLINE_ADD = "close-inline-add";
export const CLOSE_EDIT_FORM = "close-edit-form";
export const FOCUS_SELECTED_ITEM = "focus-selected-item";
export const STORE_REMOVED_NODE_ID = "store-removed-node-id";
export const CONVERT_SUCCESS = "convert-success";
export const NEW_PROJECT_ADDED_EVENT = "new-project-added";
// Tasks
export const ADD_TASK_EVENT = "add-task";
export const TASK_ADDED_EVENT = "task-added";
export const NEW_TASK_ADDED_EVENT = "new-task-added";
export const TASK_UPDATED_EVENT = "task-updated";
export const UPDATE_OR_ADD_TASK_EVENT = "task-update-or-add";
export const DESELECT_TASKS_EVENT = "deselect-tasks";
export const REMOVE_TASKS_EVENT = "remove-task";
export const REFRESH_TASKS_EVENT = "refresh-tasks";
export const ADD_UPDATE_REMOVE_TASK_EVENT = "add-update-remove-event";
export const SELECT_TEXT_IN_INPUT = "select-text-input";
export const REFRESH_ACTIVITY_HISTORY = "refresh-activity-history-event";
export const OPEN_INSPECT_MODE = "open-inspect-mode";
export const OPEN_STATUS_EDIT_MODE = "open-status-edit-mode";
export const CLOSE_INPECT_MODE = "close-inspect-mode";
export const CLEAR_ALL_FILTERS = "clear-all-filters";
// Notes

export const REFRESH_NOTES_LIST = "refresh-notes-list";

// Resources
export const ADD_RESOURCE_DATA = "add-resource-data";

// User action

export const TRIGGER_USER_ACTION_EVENT = "trigger-user-action";

// Context menu

export const CONTEXT_MENU_OPENED = "context-menu-opened";

export const SYNC_COMPLETED = "google-cal-sync-completed";

export const OPEN_SELECTED_ITEM = "open-selected-item";
export const CLOSE_GLOBAL_SEARCH = "close-global-search";
export const TOGGLE_RESOURCE_FORM_EVENT = "toggle-resource-form";
export const SET_ID_FOR_SCROLL = "set-id-for-scroll";

export const REFRESH_RESOURCE_ID_FILTER = "refresh-resource-id-filter";

export const SELECT_TASK_IN_ACTIVITY_GRID = "select-task-in-activity-grid";

export const ADD_NEW_TASK_IN_ACTIVITY_GRID = "add-new-task-activity-grid";
export const OPEN_SELECTED_TASK_ITEM = "open-selected-task-item";
export const OPEN_SELECTED_NEW_EDIT_TASK_FORM =
  "open-selected-new-edit-task-form";

export const REFRESH_FULL_FORM = "refresh-full-form";
export const CLOSE_RESOURCE_POPUP = "close-resource-popup";
export const TOGGLE_ORDERED_TASKS = "toggle-ordered-tasks";

export const TOGGLE_AREA_CHECK_TREE = "toggle-area-check-tree";
export const UPDATE_AREA_DATA = "update-area-data";

export const TOGGLE_NO_PROJECT_TASKS_FILTER = "toggle-no-project-tasks-filter";

export const REFRESH_TASK_DATA_IN_RESOURCE_FORM =
  "refresh-task-in-resource-form";
