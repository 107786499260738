var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-nowrap fill-height history-table",attrs:{"id":"activity-tasks-list"}},[_c('div',{staticClass:"flex-grow-1 overflow-hidden",attrs:{"id":"activity-tasks-list-wrapper"},on:{"contextmenu":_vm.handleContextMenuTrigger}},[_c('DataTable',{ref:"dataGrid",attrs:{"list":_vm.tasks,"columnsList":_vm.tableColumns,"selectedRowIds":_vm.selectedRowKeys,"options":_vm.options,"allowSelectionByArrowKeys":_vm.allowArrowKeySelection,"renderGroupCaption":_vm.createGroupItemsCaptions,"rowClassNamesCreator":_vm.createRowClassNames,"whiteListBtns":[
        'project-form',
        'add-resource-pop-up',
        'resource-type-selector',
        'resource-type-cate-selector',
        'resource-save-btn',
        'resource-title-editor-wrapper',
        'resource-error-pop-up',
        'convert-dialog',
        'note-path-selector-popup',
        'v-overlay' ],"allowMultiSelect":_vm.allowMultiSelect,"collapsedGroupsList":_vm.collapsedGroups,"verifyColumnSortablity":_vm.checkColumnIsSortable,"postSortProcessor":_vm.processTasksAfterSort,"doNotHandleRowScroll":false,"checkDeslectOnClick":_vm.deselectOnClick,"checkRowDragEnabled":_vm.checkIfRowDragEnabled,"itemSize":36,"placeholderText":"Select tasks to display"},on:{"edit:enabled":_vm.handleEditEnabled,"row:updated":_vm.handleRowUpdated,"rows:selected":_vm.handleRowsSelected,"row:select":_vm.handleRowSelect,"row:deselect":_vm.handleRowDeselect,"row:clicked":_vm.handleRowClick,"row:focused":_vm.handleRowFocused,"row:added":_vm.handleRowAdd,"list:processed":_vm.handleListProcessed,"group:toggled":_vm.handleGroupToggle,"key-pressed:delete":_vm.handleDeleteCall,"list:post-grouping":_vm.handlePostGrouping,"row:touch:long":_vm.handleLongTouch}})],1),_c('ContextMenu',{staticClass:"activity-tasks-popup-menu",attrs:{"target":"#activity-tasks-list-wrapper","items":_vm.menuItems,"menuId":"activity-tasks-menu-opts"},on:{"menu-item:selected":_vm.handleMenuSelection,"beforeMenuOpen":_vm.handleBeforeMenuOpen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }