export default {
  show(state, payload) {
    state.isEnabled = true;
    state.message = payload.message;
    state.color = payload.color || "red";
  },
  hide(state) {
    state.isEnabled = false;
    state.message = "";
    // state.color = "";
  },
};
