import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./routes";
import VueSweetalert2 from "vue-sweetalert2";
import { PRIMARY_COLOR } from "@/variables/colors";
import "./assets/css/global.scss";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2, {
  iconColor: PRIMARY_COLOR,
  confirmButtonColor: PRIMARY_COLOR,
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
