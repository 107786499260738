const editorCollpasedOptsMap = {
  tasks: {
    label: "Tasks in Areas",
  },
  project: {
    label: "Projects",
  },
  people: {
    label: "People",
  },
  thing: {
    label: "Things",
  },
  place: {
    label: "Places",
  },
};

export const editorCollapseOpts = Object.entries(editorCollpasedOptsMap).map(
  ([key, data]) => {
    return {
      label: data.label,
      value: key,
    };
  }
);
