<template>
  <v-tooltip :disabled="!showTooltip" v-bind="tooltipProps">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        small
        @keydown="$listeners.keydown"
        @click="$listeners.click"
      >
        <v-icon :size="btnData.iconSize" :color="btnData.iconColor">
          {{ btnData.iconName }}</v-icon
        >
      </v-btn>
    </template>
    <span>{{ btnData.tooltipText }}</span>
  </v-tooltip>
</template>
<script>
import { DARK_GREY_ICON_COLOR, PRIMARY_COLOR } from "@/variables/colors";
export default {
  inheritAttrs: false,
  props: {
    showTooltip: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    isEnabled: {
      type: Boolean,
    },
    tooltipText: {
      type: String,
    },
    iconName: {
      type: String,
      default: "$ctiCalendarCross",
    },
    iconColor: {
      type: String,
    },
    tooltipPosition: {
      type: String,

      default: "top",
    },
  },
  computed: {
    btnData() {
      let iconColor = this.iconColor || DARK_GREY_ICON_COLOR;
      let text = "Make Contingent";

      if (this.isEnabled) {
        iconColor = PRIMARY_COLOR;
        text = "Unmark as contingent";
      }

      if (this.tooltipText) {
        text = this.tooltipText;
      }

      return {
        iconName: this.iconName,
        iconSize: this.iconSize,
        iconColor: iconColor,
        tooltipText: text,
      };
    },
    tooltipProps() {
      let props = {};

      if (this.tooltipPosition) {
        if (this.tooltipPosition === "bottom") {
          props.bottom = true;
        } else if (this.tooltipPosition === "top") {
          props.top = true;
        }
      }

      return {
        ...props,
      };
    },
  },
};
</script>
