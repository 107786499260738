<template>
  <div class="active-resources-wrapper d-flex align-center flex-wrap">
    <slot></slot>
    <v-chip
      v-for="(item, index) in list"
      :key="index"
      :class="createBtnClasses(item)"
      @click="handleClick($event, item)"
    >
      <v-icon left :size="12" :color="getResourceIconColor(item)">
        {{ getResourceIcon(item) }}
      </v-icon>
      <span class="resource-label"> {{ item.tag }}</span>
    </v-chip>
  </div>
</template>
<script>
import { resourceTypesMap } from "@/data/resources";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
    },
  },
  methods: {
    handleClick(e, item) {
      this.$emit("click", e, item);
    },
    createBtnClasses(resourceData) {
      return {
        "mr-2": true,
        "resource-chip": true,
        "project-resource": resourceData.type === "project",
        "thing-resource": resourceData.type === "thing",
        "place-resource": resourceData.type === "place",
        "person-resource": resourceData.type === "people",
        "selected-resource": resourceData.key === this.active,
      };
    },
    getResourceIconColor(resourceData) {
      return resourceTypesMap[resourceData.type].iconColor;
    },
    getResourceIcon(resourceData) {
      return resourceTypesMap[resourceData.type].icon;
    },
  },
};
</script>
<style lang="scss" scoped>
.resource-chip {
  border-radius: 4px;
  border: 1.2px solid transparent;
  height: 20px;
  margin: 0px;
}

.active-resources-wrapper {
  padding: 0px;
}
.active-resources-wrapper >>> .v-slide-group__content {
  padding: 0px;
}

.active-resources-wrapper >>> .v-chip--active:focus::before {
  opacity: 0 !important;
}

.project-resource {
  background-color: #e0edff !important;
  &.selected-resource {
    border: 1.2px solid #0070c0 !important;
  }
}
.thing-resource {
  background-color: #efe2ff !important;
  &.selected-resource {
    border: 1.2px solid #7730a0 !important;
  }
}

.place-resource {
  background-color: #ddffd8 !important;
  &.selected-resource {
    border: 1.2px solid #00b050 !important;
  }
}
.person-resource {
  background-color: #ffd8d8 !important;
  &.selected-resource {
    border: 1.2px solid #ff0000 !important;
  }
}

.active-resources-wrapper {
  row-gap: 8px;
}
</style>
