import { submitKeys, submitKeyCodes } from "@/variables/keys";
export const isSubmitKeys = (e) => {
  let isSubmitKeyTriggered = false;
  if (e.key && submitKeyCodes.includes(e.key)) {
    isSubmitKeyTriggered = true;
  } else {
    const keyCode = e.keyCode ? e.keyCode : e.which;

    isSubmitKeyTriggered = submitKeys.includes(keyCode);
  }

  return isSubmitKeyTriggered;
};

export const isTabKey = (e) => {
  let isTabKeyTriggered = false;
  if (e.key && e.key === "Tab") {
    isTabKeyTriggered = true;
  } else {
    const keyCode = e.keyCode ? e.keyCode : e.which;

    isTabKeyTriggered = keyCode === 9;
  }

  return isTabKeyTriggered;
};
