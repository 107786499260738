<template>
  <v-dialog
    width="25%"
    :value="isFormOpen"
    @click:outside="closeDialog"
    @keydown="handleKeyDown"
    content-class="note-topic-form"
  >
    <v-card>
      <v-card-title class="text-h5"> Add Topic </v-card-title>
      <v-card-text>
        <div>
          <NoteFolderSelector
            ref="noteCateSelector"
            v-model="selectedCate"
            @keydown="handleSelectorKeyDown"
            :allowToAddTopic="false"
            placeholder="Select Parent Topic to add topic in"
            onlyFirstLevel
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed text @click="closeDialog" color="error"> Cancel</v-btn>
        <v-btn
          color="primary"
          class="save-confirm-btn"
          depressed
          text
          @click="submitData"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { PRIMARY_COLOR } from "@/variables/colors";
import {
  createDirectoryDataForAdd,
  getChildrenOfTopic,
} from "@/helpers/directories";
import { storeCatAddAction } from "@/helpers/categories";
// import EventEmitter from "@/helpers/eventEmitter";
// import { REFRESH_TREE_EVENT } from "@/variables/events";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { getUserNotes } from "@/helpers/notes";

import popupHelpersMixin from "@/mixins/popupHelpersMixin";
import isEscKey from "@/utils/isEscKey";
import { isSubmitKeys, isTabKey } from "@/utils/keys";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
export default {
  mixins: [userDetailsMixin, categoriesHelpersMixin, popupHelpersMixin],
  components: {
    NoteFolderSelector: () => import("../NoteFolderSelector"),
  },
  data() {
    return {
      selectedCate: "",
    };
  },
  methods: {
    ...mapActions("cateData", ["resetCateData"]),
    ...mapMutations(["setLoader"]),
    handleSelectorKeyDown(e) {
      e.stopPropagation();
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
    async submitData() {
      const selectedCate = this.selectedCate && this.selectedCate.trim();
      const currFolders = this.notesTree;
      const currDirChildMap = this.dirChildrenMap;
      const allDirs = this.directories;
      if (!selectedCate) {
        this.$swal({
          titleText: "Oops!",
          html: "Parent Topic is required",
          icon: "error",
          confirmButtonColor: PRIMARY_COLOR,
          iconColor: PRIMARY_COLOR,
        });
        return;
      }

      this.setLoader(true);
      let extraData = {};

      const noteTopicDataToAdd = {
        ...this.noteTopicData,
        parentKey: selectedCate,
      };

      if (this.$route.name !== "Notes") {
        const childrenOfTopic = getChildrenOfTopic(selectedCate, allDirs);
        extraData = {
          order: childrenOfTopic?.length || 0,
          parentKey: selectedCate,
        };
      }

      const createdTopicData = createDirectoryDataForAdd(
        { ...noteTopicDataToAdd },
        currDirChildMap,
        currFolders,
        allDirs,
        extraData
      );
      storeCatAddAction(createdTopicData, "notes-subject");
      await getUserNotes().add(
        {
          [createdTopicData.key]: {
            ...createdTopicData,
          },
        },
        "directories"
      );

      await Vue.nextTick();
      this.closeDialog();
      this.setLoader(false);
      this.$emit("topic:added", createdTopicData);
    },
    closeDialog() {
      this.selectedCate = "";
      this.resetCateData();
    },
    handleKeyDown(e) {
      if (isEscKey(e)) {
        this.closeDialog();
      }

      if (isSubmitKeys(e)) {
        const submitBtnParentFound = findParentFromPathsByClassName(
          getPathsFromEvent(e),
          ["save-confirm-btn"]
        );
        if (submitBtnParentFound && !e.shiftKey) {
          this.submitData();
          return;
        }
      }

      if (isTabKey(e)) {
        const el = this.$refs.noteCateSelector;
        el?.blurInput?.();
        const btn = document.querySelector(
          ".note-topic-form .save-confirm-btn"
        );
        btn?.focus();
      }
    },
    focusOnInput() {
      this.focusOnSelector("noteCateSelector");
    },
  },
  computed: {
    ...mapGetters("cateData", {
      noteTopicData: "data",
      isFormOpen: "isNoteTopicFormOpen",
    }),
    ...mapGetters("note", ["directories", "notesTree", "dirChildrenMap"]),
  },
  watch: {
    isFormOpen(n) {
      if (n) {
        this.focusOnInput();
      }
    },
  },
};
</script>
