export const resourceDisplayOptsMap = {
  default: {
    label: "Text in colored background",
    styleClassName: "mention-text default",
  },
  link: {
    label: "Colored Text",
    styleClassName: "mention-link",
  },
  "link-underlined": {
    label: "Colored Text with underline",
    styleClassName: "mention-link-underlined",
  },
  underlined: {
    label: "Colored underline",
    styleClassName: "mention-underlined",
  },
};

export const resourceDisplayOpts = Object.entries(resourceDisplayOptsMap).map(
  ([key, data]) => {
    return {
      label: data.label,
      value: key,
    };
  }
);

// [
//   {
//     label: "Text in colored background",
//     value: "default",
//   },
//   {
//     label: "Colored Text with underline",
//     value: "link-underlined",
//   },
//   {
//     label: "Colored Text",
//     value: "link",
//   },
//   {
//     label: "Colored underline",
//     value: "underlined",
//   },
// ];
