export default {
  setDataForConvertMode(context, payload) {
    const currState = { ...context.state };
    currState.isSelectorOpen = true;
    currState.dataList = payload.dataList;
    currState.type = payload.typeToUse;
    currState.calledFrom = payload.calledFrom || "";
    context.commit("setData", currState);
  },
  resetDataOfConvertMode(context) {
    const currState = { ...context.state };
    currState.isSelectorOpen = false;
    currState.dataList = [];
    currState.type = "";
    currState.calledFrom = "";
    context.commit("setData", currState);
  },
};
