import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap align-center flex-shrink-1 tasks-filters-bar-wrapper pr-4",class:_vm.filterBarClasses},[_vm._t("filter-btn",function(){return [(_vm.showClrFilterBtn)?_c('ClearFilterBtn',{attrs:{"isEmpty":_vm.isEmpty,"count":_vm.taskCount},on:{"clear-btn:click":_vm.handleSingleClickOnClear,"clear-btn:dblClick":_vm.handleDblClickOnClear}}):_vm._e()]}),_vm._l((_vm.createdFiltersMap),function(filter){return [(filter.title === 'category' && filter.data.length)?_c(VBtnToggle,{key:filter.hash,staticClass:"pt-0 filter-toggle-btns flex-wrap",class:filter.title + '-filters',attrs:{"label":filter.title,"multiple":"","borderless":""}},[_vm._l((filter.data),function(filterItem){return [_c(VTooltip,{key:filterItem.order,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.createCategoryBtnStyles(filterItem),attrs:{"x-small":""},on:{"click":function($event){return _vm.handleFilterSelect(filterItem, $event)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(filterItem.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Filter: "+_vm._s(filterItem.title)+" ")])])]})],2):(filter.title === 'resources' && filter.data.length)?_c(VBtnToggle,{key:filter.hash,staticClass:"pt-0 filter-toggle-btns flex-wrap",class:filter.title + '-filters',attrs:{"label":filter.title,"multiple":"","borderless":""}},[_vm._l((filter.data),function(filterItem){return [_c(VTooltip,{key:filterItem.order,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"resource-filter-btn",class:_vm.createResourceBtnStyles(filterItem),attrs:{"x-small":""},on:{"click":function($event){return _vm.handleFilterSelect(filterItem, $event)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(filterItem.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Filter: "+_vm._s(filterItem.title)+" ")])])]})],2):(
        filter.title !== 'resources' &&
        filter.title !== 'category' &&
        filter.data.length
      )?_c(VBtnToggle,{key:filter.hash,staticClass:"pt-0 filter-toggle-btns flex-wrap",class:filter.title + '-filters',attrs:{"label":filter.title,"multiple":"","borderless":""}},[_vm._l((filter.data),function(filterItem){return [_c(VTooltip,{key:filterItem.order,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.createFilterBtnStyles(filterItem),attrs:{"data-filter-cate-id":filterItem.key,"x-small":""},on:{"click":function($event){return _vm.handleFilterSelect(filterItem, $event)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":filterItem.iconSize || 13,"color":_vm.getFilterIconColor(filterItem, filter.title)}},[_vm._v(" "+_vm._s(filterItem.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Filter: "+_vm._s(filterItem.title)+" ")])])]})],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }