export default {
  updateState(state, payload) {
    Object.assign(state, payload);
  },
  toggleNotesView(state) {
    state.isNotesView = !state.isNotesView;
  },
  toggleLoaderState(state) {
    state.showLoader = !state.showLoader;
  },
  setLoader(state, payload) {
    state.showLoader = payload;
  },
  setCalSync(state, payload) {
    state.isCalSyncRunning = payload;
  },
  setGoogleCalLastSync(state, payload) {
    state.googleCalLastSync = payload;
  },
  setGoggleCalLastConnected(state, payload) {
    state.googleCalLastConnected = payload;
  },
  setCurrRowInEditing(state, payload) {
    state.currRowInEditing = payload;
  },
  toggleSidebar(state, payload) {
    if (payload === undefined) {
      state.sidebarOpen = !state.sidebarOpen;
    } else {
      state.sidebarOpen = payload
    }
  }
};
