<template>
  <v-chip
    v-if="isVisible"
    close
    @click:close="handleClose"
    :class="tipClasses"
    color="#E8E8E8"
  >
    <slot></slot>
  </v-chip>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
    },
  },
  methods: {
    handleClose() {
      this.$emit("tip:closed");
    },
  },
  computed: {
    tipClasses() {
      return {
        "tip-content": true,
      };
    },
  },
};
</script>
<style scoped>
.tip-content {
  padding: 4px 13px 4px 11px;
  height: auto !important;
  line-height: unset !important;
}

.tip-content::hover::before {
  opacity: 0 !important;
}

.tip-content >>> .v-chip__close {
  font-size: 16px !important;
}
</style>
<style>
.tip-content:hover::before {
  opacity: 0 !important;
}
</style>
