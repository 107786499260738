/* eslint-disable no-debugger */
import createUniqueId from "@/utils/createUniqueId";
import {
  DIRECTORY_PROPS_REQUIRES_CONFIG,
  DIRECTORY_PROPS_TO_RESTORE,
} from "@/variables/directories";
import { isEmpty } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { getCurrDate } from "./dates";
import store from "@/store";
import { increment } from "@/utils/number";
import { alphaSorter } from "@/helpers/common";

/**
 * Treepath takes an array of pathnames and gives you back
 * an object representation of that path hierarchy.
 */
// export const createTree = (function () {
//   function buildTree(tree, parts) {
//     var lastDir = "root";
//     var dirPath = "";

//     parts.forEach(function (part) {
//       var name = part.trim();

//       // In case we have a single `/`
//       if (!name || !!name.match(/^\/$/)) {
//         return;
//       }

//       // It's a directory
//       if (name.indexOf(".") === -1) {
//         lastDir = name;
//         dirPath += lastDir + "/";

//         if (!tree[name]) {
//           tree[name] = {
//             path: dirPath,
//             files: [],
//           };
//         }
//       } else {
//         // It's a file
//         tree[lastDir].files.push(name);
//       }
//     });
//   }

//   return function init(paths) {
//     if (!paths || !Array.isArray(paths)) {
//       throw new TypeError(
//         "Expected paths to be an array of strings but received: " + typeof paths
//       );
//     }

//     var tree = {
//       root: {
//         path: "",
//         files: [],
//       },
//     };

//     paths.forEach(function (path) {
//       buildTree(tree, path.split("/"));
//     });

//     return tree;
//   };
// })();

// const directories = [
//   {
//     path: "/New Folder",
//     name: "New Folder",
//   },
//   {
//     path: "/New",
//     name: "New",
//   },
//   {
//     path: "/New Folder/New",
//     name: "New",
//   },
// ];

// const getNestedChildren = (arr, parent) => {
//   // const clonedDirectories = cloneDeep(arr);
//   var out = [];
//   for (var i in arr) {
//     if (!arr[i].parentKey) {
//       out.push(arr[i]);
//       continue;
//     }

//     console.debug("I", arr[i].parentKey);

//     if (arr[i].parentKey == parent) {
//       var children = getNestedChildren(arr, arr[i].key);

//       if (children.length) {
//         arr[i].children = children;
//       }
//       out.push(arr[i]);
//     }
//   }
//   return out;
// };

// console.debug("TEEDDDD", getNestedChildren(list, ""));

// console.debug(
//   "LIST",
//   getNestedChildren([
//     {
//       key: "767e3d56-d9c9-4d21-b641-ce08adaaedaf",
//       open: false,
//       order: 0,
//       modified: "2022-03-03",
//       name: "New Folder 1",
//       parentKey: "",
//       created: "2022-03-03",
//     },
//     {
//       parentKey: "",
//       modified: "2022-03-03",
//       key: "3863fed5-53f4-4034-ab10-aa09c5742b3d",
//       order: 1,
//       created: "2022-03-03",
//       name: "New Folder 2",
//       open: false,
//     },
//     {
//       parentKey: "",
//       name: "New Folder 3",
//       order: 2,
//       key: "a8701256-a843-4c23-af56-41518d8912d0",
//       created: "2022-03-03",
//       open: false,
//       modified: "2022-03-03",
//     },
//     {
//       modified: "2022-03-03",
//       key: "16e396d0-05d1-48da-bebc-ffae78f9f950",
//       parentKey: "767e3d56-d9c9-4d21-b641-ce08adaaedaf",
//       created: "2022-03-03",
//       order: 0,
//       name: "New Folder ",
//       open: false,
//     },
//     {
//       key: "6ac76098-7b17-4576-8012-b999d13e71bc",
//       parentKey: "16e396d0-05d1-48da-bebc-ffae78f9f950",
//       name: "New Folder ",
//       created: "2022-03-03",
//       open: false,
//       order: 0,
//       modified: "2022-03-03",
//     },
//     {
//       created: "2022-03-03",
//       key: "e83b5b8a-f683-4abb-9ed4-04f23c5bdffe",
//       parentKey: "767e3d56-d9c9-4d21-b641-ce08adaaedaf",
//       order: 1,
//       name: "New Folder 1",
//       open: false,
//       modified: "2022-03-03",
//     },
//     {
//       parentKey: "e83b5b8a-f683-4abb-9ed4-04f23c5bdffe",
//       key: "9bbc5ea4-acaf-4ade-bf9d-afea919da48f",
//       created: "2022-03-03",
//       name: "New Folder ",
//       modified: "2022-03-03",
//       order: 0,
//       open: false,
//     },
//     {
//       name: "New Folder ",
//       order: 0,
//       open: false,
//       parentKey: "9bbc5ea4-acaf-4ade-bf9d-afea919da48f",
//       key: "f0babb46-8fad-4e90-99dc-94416b78937d",
//       modified: "2022-03-03",
//       created: "2022-03-03",
//     },
//   ])
// );

// const nest = (items, id = "", link = "parentKey") =>
//   items
//     .filter((item) => item[link] === id)
//     .map((item) => ({
//       ...item,
//       children: nest(items, item.id),
//     }));

// const list = nest(listI);

// console.debug("LLLLLL", list);

// const getCount = (dir, notesMap, selectedNodes) => {
//   const notesCount = notesMap[dir.key] ? notesMap[dir.key].length : 0; // Gets the length of stored tasks by project key
//   dir.totalCount = notesCount;

//   dir["hasAttribute"] = {
//     class: "dir",
//   };
//   dir.open = selectedNodes.includes(dir.key);

//   return dir;
// };

const createCount = (dir, notesMap, selectedNodes) => {
  const notesCount = notesMap[dir.key]?.length || 0; // Gets the length of stored tasks by project key
  dir.totalCount = notesCount;
  dir["hasAttribute"] = {
    class: "dir tree-node",
    "data-action-type": "notes-subject-change",
    "data-action-value": dir.key,
  };

  dir.type = "sub-dir";
  dir.isSelected = selectedNodes.includes(dir.key);
  // if (selectedNodes && selectedNodes.length) {
  //   dir.open = selectedNodes.includes(dir.key);
  // }
  if (dir.children && dir.children.length) {
    dir.type = "dir";
    dir.children.forEach((d) => {
      d = createCount(d, notesMap, selectedNodes);
      dir.totalCount += d?.totalCount || 0;
    });
  }
  return dir;
};

const processTree = (dirs, notesMap, selectedNodes) => {
  dirs.forEach((dir) => {
    const notesCount = notesMap[dir.key]?.length || 0; // Gets the length of stored tasks by project key
    dir.totalCount = notesCount;
    dir["hasAttribute"] = {
      class: "dir tree-node",
      "data-action-type": "notes-subject-change",
      "data-action-value": dir.key,
    };

    dir.type = "sub-dir";
    dir.isSelected = selectedNodes.includes(dir.key);

    // if (selectedNodes && selectedNodes.length) {
    //   dir.open = selectedNodes.includes(dir.key);
    // }
    if (dir.children && dir.children) {
      dir.type = "dir";
      dir.children.forEach((child) => {
        child = createCount(child, notesMap, selectedNodes);
        dir.totalCount += child.totalCount || 0;
      });
    }
  });

  return dirs;
};
// directories,

const createTreeData = (items) => {
  const tree = [],
    mappedArr = {};

  // Build a hash table and map items to objects
  items.forEach(function (item) {
    var id = item.key;
    if (!Object.prototype.hasOwnProperty.call(mappedArr, id)) {
      // in case of duplicates
      mappedArr[id] = item; // the extracted id as key, and the item as value
      mappedArr[id].children = []; // under each item, add a key "children" with an empty array as value
    }
  });

  // If root-level nodes are not included in hash table, include them
  //   items.forEach(function(item) {
  //     var parentId = item.parentCatKey;
  //     if (!mappedArr.hasOwnProperty(parentId)) {
  //       // make up an item for root-level node
  //       newItem = {};
  //       newItem.key = parentId;
  //       newItem.name = 'abc';
  //       newItem.parentCatKey = '';
  //       mappedArr[parentId] = newItem; // the parent id as key, and made-up an item as value
  //       mappedArr[parentId].children = [];
  //     }
  //   })

  // Loop over hash table
  for (var id in mappedArr) {
    if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
      const mappedElem = mappedArr[id];

      // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
      if (mappedElem.parentKey) {
        var parentId = mappedElem.parentKey;
        mappedArr[parentId].children.push(mappedElem);
        mappedArr[parentId].children.sort((a, b) => {
          const normalisedFirstName = a.name?.toLowerCase();
          const normailisedSecondName = b.name?.toLowerCase();

          return alphaSorter.compare(
            normalisedFirstName,
            normailisedSecondName
          );
        });
      }

      // If the element is at the root level, directly push to the tree
      else {
        tree.push(mappedElem);
        tree.sort((a, b) => a.order - b.order);
      }
    }
  }

  return {
    tree,
    childrenMap: Object.values(mappedArr).reduce((accu, item) => {
      if (!accu[item.key]) {
        accu[item.key] = item.children;
      }
      return accu;
    }, {}),
  };
};
export const createDirectoryTree = ({ directories, notes, selectedNodes }) => {
  const clonedDirectories = cloneDeep(Object.values(directories));
  const clonedNotes = cloneDeep(notes);
  let dirNotesMap = {};
  // let dirChildrenMap = {};

  // Create root for top-level node(s)
  // const tree = [];

  clonedNotes.forEach((note) => {
    const notePath = note.path || "root";
    // if (note.path) {
    if (!dirNotesMap[notePath]) {
      dirNotesMap[notePath] = [];
    }

    dirNotesMap[notePath].push(note);
    // }
  });

  const { tree, childrenMap: cateChildrenMap } =
    createTreeData(clonedDirectories);

  // for (const dir of clonedDirectories) {
  //   if (!dir.parentKey) {
  //     tree.push(dir);
  //     dirChildrenMap[dir.key] = [];
  //     continue;
  //   }

  //   // Insert node as child of parent in flat array
  //   const parentIndex = clonedDirectories.findIndex(
  //     (el) => el.key === dir.parentKey
  //   );
  //   if (!clonedDirectories[parentIndex].children) {
  //     dirChildrenMap[clonedDirectories[parentIndex].key] = [
  //       {
  //         key: dir.key,
  //         order: dir.order,
  //         name: dir.name,
  //       },
  //     ];
  //     clonedDirectories[parentIndex].children = [dir];
  //     continue;
  //   }
  //   dirChildrenMap[clonedDirectories[parentIndex].key].push({
  //     key: dir.key,
  //     order: dir.order,
  //     name: dir.name,
  //   });
  //   clonedDirectories[parentIndex].children.push(dir);
  //   clonedDirectories[parentIndex].children.sort((a, b) => a.order - b.order);
  //   dirChildrenMap[clonedDirectories[parentIndex].key].sort(
  //     (a, b) => a.order - b.order
  //   );
  // }

  const rootDirData = {
    key: "root",
    name: "No Topic",
    children: [],
    totalCount: 0,
    order: -1,
  };
  tree.sort((a, b) => a.order - b.order);

  if (!isEmpty(clonedNotes)) {
    tree.unshift(rootDirData);
  }

  let processedTree = cloneDeep(processTree(tree, dirNotesMap, selectedNodes));

  // delete dirChildrenMap.root;
  return {
    dirChildrenMap: cateChildrenMap,
    dataSource: processedTree,
    id: "key",
    text: "name",
    child: "children",
    htmlAttributes: "hasAttribute",
    expanded: "open",
    selected: "isSelected",
  };
};

export const createFullDirPath = (dir, allDirs) => {
  let paths = [];
  let parentId = dir.parentKey;
  while (parentId) {
    const parent = allDirs[parentId];
    if (!parent) {
      break;
    }
    paths.push(parent.name);
    parentId = parent.parentKey;
  }
  let createdPath = dir.name;

  if (paths.length) {
    paths.push("");
    paths = paths.reverse();
    paths = paths.join("/");
    createdPath = `${paths}/${createdPath}`;
  } else {
    createdPath = `/` + createdPath;
  }

  return createdPath;
};

const processDir = (dir, allDirs) => {
  dir.fullPath = createFullDirPath(dir, allDirs);
  return dir;
};

export const processDirs = (dirs) => {
  const clonedDirs = cloneDeep(Object.values(dirs));
  return clonedDirs.map((dir) => processDir(dir, clonedDirs));
};

export const createDirUpdateAndEditedData = (newData, currDirData) => {
  let updates = {};
  let editedData = {};
  let changedProps = {};
  const keysToUpdate = Object.keys(newData);

  keysToUpdate.forEach((key) => {
    if (DIRECTORY_PROPS_TO_RESTORE.indexOf(key) >= 0) {
      if (key === "order") {
        editedData[key] = currDirData[key];
        if (typeof newData[key] === "number") {
          updates[key] = newData[key];
          changedProps[key] = newData[key];
        }
      } else {
        updates[key] = newData[key] || "";
        changedProps[key] = newData[key] || "";
        editedData[key] = currDirData[key] || "";
      }
    }
  });
  updates.modified = getCurrDate();
  editedData.changedProps = changedProps;
  editedData.key = currDirData.key;

  return {
    updates,
    editedData,
  };
};

export const searchSameDirsByName = (dirToFind, dirs) => {
  const normalisedName = dirToFind && dirToFind.toLowerCase();
  return dirs.filter(
    (d) => d.name && d.name.toLowerCase().includes(normalisedName)
  );
};

export const findDir = (tree, predicate) => {
  for (const node of tree) {
    if (predicate(node)) return node;

    if (node.children) {
      let match = findDir(node.children, predicate);
      if (match) return match;
    }
  }
};

export const updateDirInTree = (obj, nodeId, updates) =>
  Array.isArray(obj)
    ? obj.map((o) => updateDirInTree(o, nodeId, updates))
    : obj.key === nodeId
    ? {
        ...obj,
        ...updates,
      }
    : // else
      {
        ...obj,
        ...(obj.children
          ? { children: updateDirInTree(obj.children, nodeId, updates) }
          : {}),
      };

export const getUniqueDirName = (
  newDirName,
  allDirs,
  index = 0,
  matchType = "object"
) => {
  let checkName = newDirName;
  if (index) {
    checkName = `${checkName} ${index}`;
  }

  const nameExists =
    allDirs.filter((f) =>
      matchType === "object" ? f.name === checkName : f === checkName
    ).length > 0;
  return nameExists
    ? getUniqueDirName(newDirName, allDirs, increment(index, 1), matchType)
    : checkName;
};

export const createDirectoryDataForAdd = (
  newDirData,
  dirChildrenMap,
  mainTree,
  allDirs,
  extraData = {}
) => {
  let parentKey = "";
  let dirName = newDirData?.name || "New Subject";
  let dirOrder = mainTree?.length || 0;

  if (newDirData.parentKey && allDirs[newDirData.parentKey]) {
    parentKey = newDirData.parentKey;
    const selectedDirChildren = dirChildrenMap[newDirData.parentKey];
    if (!isEmpty(selectedDirChildren)) {
      dirOrder = selectedDirChildren.length;
      dirName = getUniqueDirName(dirName, selectedDirChildren);
    } else {
      dirOrder = 0;
    }
  } else {
    dirName = getUniqueDirName(dirName, mainTree);
  }

  const dir = {
    parentKey,
    name: dirName,
    open: false,
    created: getCurrDate(),
    modified: getCurrDate(),
    key: createUniqueId(),
    order: dirOrder,
    ...extraData,
  };

  return dir;
};

export const createRestoredDataOfDir = (dirDataToRestore) => {
  const updates = {};
  let updateType;
  const propsToRestore = Object.keys(dirDataToRestore);

  propsToRestore.forEach((prop) => {
    if (DIRECTORY_PROPS_TO_RESTORE.indexOf(prop) >= 0) {
      updates[prop] = dirDataToRestore[prop] || "";
      if (DIRECTORY_PROPS_REQUIRES_CONFIG.indexOf(prop) >= 0) {
        updateType = "parent-changed";
        if (prop === "order") {
          updates[prop] = dirDataToRestore[prop];
        }
      } else if (prop === "name") {
        updateType = "rename";
      }
    }
  });

  updates.modified = getCurrDate();

  return {
    updates,
    type: updateType,
  };
};

export const isChildOrGrandChild = (
  childNodeId,
  nodeIdToCheck,
  allDirs = store.getters["note/directories"]
) => {
  let nodeIsChildOrGrandChild = false;

  if (allDirs[childNodeId]) {
    const childDirData = allDirs[childNodeId];
    let parentKey = childDirData.parentKey || "";
    while (!nodeIsChildOrGrandChild) {
      if (!allDirs[parentKey]) {
        break;
      }

      const parentDirData = allDirs[parentKey];

      if (
        parentDirData.key === nodeIdToCheck ||
        parentDirData.parentKey === nodeIdToCheck
      ) {
        nodeIsChildOrGrandChild = true;
      }

      parentKey = parentDirData.parentKey;
    }
  }

  return nodeIsChildOrGrandChild;
};

export const createFullDirectoryPath = (dir, allDirs) => {
  let labels = [];
  if (allDirs[dir.key]) {
    labels.unshift(allDirs[dir.key].order);
    labels.unshift(...getParentDirFullPath(dir.key, allDirs));
  }
  return labels.join("-");
};

const getParentDirFullPath = (dirForParent, allDirs) => {
  let rtn = [];
  if (allDirs[dirForParent].parentKey) {
    if (allDirs[allDirs[dirForParent].parentKey]) {
      rtn.unshift(allDirs[allDirs[dirForParent].parentKey].order);
      rtn.unshift(
        ...getParentDirFullPath(allDirs[dirForParent].parentKey, allDirs)
      );
    }
  }
  return rtn;
};

export const createDirectoryLabel = (dir, allDirs) => {
  let labels = [];

  if (allDirs[dir.key]) {
    labels.unshift(allDirs[dir.key].name);
    labels.unshift(...getAllParentIDs(dir.key, allDirs));
  }
  return labels.join(" / ");
};

const getAllParentIDs = (dirKey, allDirs) => {
  let rtn = [];
  if (allDirs[dirKey].parentKey && allDirs[allDirs[dirKey].parentKey]) {
    rtn.unshift(allDirs[allDirs[dirKey].parentKey].name);
    rtn.unshift(...getAllParentIDs(allDirs[dirKey].parentKey, allDirs));
  }
  return rtn;
};

export const createDirectoryOpts = (allDirs, onlyFirstLevel) => {
  let opts = Object.values(allDirs);

  if (onlyFirstLevel) {
    opts = opts.filter((o) => o.parentKey === "");
  }

  opts = opts.map((dir) => {
    return {
      fullLabel: createDirectoryLabel(dir, allDirs),
      shortLabel: allDirs[dir.key].name,
      // label: createProjectLabel(proj, allProjects, allCategories),
      order: createFullDirectoryPath(dir, allDirs),
      value: dir.key,
    };
  });

  const collator = new Intl.Collator([], { numeric: true });
  opts.sort((a, b) => {
    return collator.compare(a.order, b.order);
  });

  opts.unshift({
    fullLabel: "No Topic",
    shortLabel: "No Topic",
    // label: createProjectLabel(proj, allProjects, allCategories),
    order: "-1",
    value: "root",
  });

  return opts;
};

export const getChildrenOfTopic = (topicId, allTopicsMap) => {
  return Object.values(allTopicsMap).filter((t) => t.parentKey === topicId);
};
