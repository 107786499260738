<template>
  <DialogLayout label="Google Calendar">
    <template #content>
      <div>
        <v-btn
          depressed
          color="primary"
          v-if="!googleCalSyncIsEnabled"
          @click="enableGoogleSync"
        >
          Connect Google Calendar
        </v-btn>
        <v-btn depressed color="error" v-else @click="revokeGoogleCalAccess">
          Disconnect Google Calendar
        </v-btn>

        <v-divider></v-divider>
      </div>
    </template>

    <template #actions>
      <!-- <v-spacer /> -->
      <v-btn @click="closeDialog"> Close </v-btn>
    </template>
  </DialogLayout>
</template>
<script>
import userDetailsMixin from "@/mixins/userDetailsMixin";
import { mapActions, mapMutations, mapGetters } from "vuex";
import googleCalHelpersMixin from "@/mixins/googleCalHelpersMixins";
import DialogLayout from "./components/DialogLayout.vue";
export default {
  mixins: [userDetailsMixin, googleCalHelpersMixin],
  components: {
    DialogLayout,
  },
  methods: {
    ...mapActions(["updateRootState", "toggleSettingsDialog"]),
    ...mapMutations(["setLoader"]),
    ...mapActions("toast", ["showToast"]),
    closeDialog() {
      this.toggleSettingsDialog();
    },
  },
  computed: {
    ...mapGetters([
      "isGoogleCalSyncEnabled",
      "googleCalNeedsReconnect",
      "googleCalSyncIsEnabled",
    ]),
  },
};
</script>
