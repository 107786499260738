import createUniqueId from "@/utils/createUniqueId";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import flow from "lodash/flow";
import {
  alphaSorter,
  getUniqueName,
  // alphaSorter
} from "./common";
import { getCurrDate } from "./dates";
import {
  RESOURCE_CATEGORY_PROPS_TO_RESTORE,
  RESOURCE_CATE_PROPS_REQUIRES_CONFIG,
  TASK_CATE_PROPS_ALLOWED_FOR_PARSE,
} from "@/variables/resources";
import { resourcesTypeKeys } from "@/data/resources";
import { getUserResources } from "./resources";
import { getNumber, increment } from "@/utils/number";
import { filterTasksWithoutProjectResource } from "./tasks";

const sortResourceCateList = (resourceCateList, type) => {
  if (type === "alphabetical") {
    resourceCateList.sort((a, b) => {
      const normalisedFirstName = a.name?.toLowerCase();
      const normailisedSecondName = b.name?.toLowerCase();

      return alphaSorter.compare(normalisedFirstName, normailisedSecondName);
    });
  }

  if (type === "order") {
    resourceCateList.sort((a, b) => a.order - b.order);
  }
  return resourceCateList;
};

const createTree = (
  items,
  resourcesMap,
  isSearchEnabled,
  doNotCountAllTasks,
  sortByOrder
) => {
  let tree = [];
  let mappedArr = {};

  // Build a hash table and map items to objects
  items.forEach(function (item) {
    const id = item.key;
    if (item.deleted) return;
    if (!mappedArr[id]) {
      // in case of duplicates
      mappedArr[id] = item; // the extracted id as key, and the item as value
      mappedArr[id].children = []; // under each item, add a key "children" with an empty array as value
    }
  });

  // If root-level nodes are not included in hash table, include them
  //   items.forEach(function(item) {
  //     var parentId = item.parentCatKey;
  //     if (!mappedArr.hasOwnProperty(parentId)) {
  //       // make up an item for root-level node
  //       newItem = {};
  //       newItem.key = parentId;
  //       newItem.name = 'abc';
  //       newItem.parentCatKey = '';
  //       mappedArr[parentId] = newItem; // the parent id as key, and made-up an item as value
  //       mappedArr[parentId].children = [];
  //     }
  //   })

  // TESS
  // Loop over hash table
  for (let id in mappedArr) {
    if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
      const mappedElem = mappedArr[id];

      // If the element is not at the root level, add it to its parent array of children. Note this will continue till we have only root level elements left
      if (mappedElem.parentCatKey) {
        const parentId = mappedElem.parentCatKey;

        if (mappedArr[parentId]?.children) {
          mappedArr[parentId].children.push(mappedElem);

          mappedArr[parentId].children = sortResourceCateList(
            mappedArr[parentId].children,

            sortByOrder ? "order" : "alphabetical"
          );
          // mappedArr[parentId].children.sort((a, b) => {
          //   const normalisedFirstName = a.name?.toLowerCase();
          //   const normailisedSecondName = b.name?.toLowerCase();

          //   return alphaSorter.compare(
          //     normalisedFirstName,
          //     normailisedSecondName
          //   );
          // });

          // mappedArr[parentId].children.sort((a, b) => a.order - b.order);
        }
      } else {
        // If the element is at the root level, directly push to the tree

        tree.push(mappedElem);

        tree = sortResourceCateList(tree);
        // tree.sort((a, b) => a.order - b.order);
      }
    }
  }

  if (doNotCountAllTasks) {
    tree = filterResourceTree(tree, resourcesMap);
  }

  if (isSearchEnabled) {
    tree = filterResourceTree(tree, resourcesMap);
  }

  return {
    tree,
    childrenMap: Object.values(mappedArr).reduce((accu, item) => {
      if (!accu[item.key]) {
        accu[item.key] = item.children;
      }
      return accu;
    }, {}),
  };
};

export const createResourceTypeListMap = () => {
  const map = resourcesTypeKeys.reduce((map, r) => {
    map[r] = {
      visible: {},
      all: {},
    };
    return map;
  }, {});

  map["all"] = {
    visible: {},
    all: {},
  };

  return map;
};

export const groupResourcesByTypes = (
  resourcesList,
  typeMap,
  allResourceCateData,
  searchQuery,
  taskList,
  doNotCountAllTasks
) => {
  const resourcesTasksMap = {};
  resourcesList.forEach((resource) => {
    const allMap = typeMap["all"];
    let resourceCate = "root";

    if (searchQuery && !checkIfResourceMatches(resource, searchQuery)) {
      return;
    }
    if (resource.catId && allResourceCateData[resource.catId]) {
      resourceCate = resource.catId;
    }

    const allLinkedTasks = taskList.filter((t) =>
      t.resources?.includes(resource.key)
    );
    const incompleteTasks = allLinkedTasks.filter((t) => !t.completed);

    let addInVisible = false;

    if (
      doNotCountAllTasks &&
      ((allLinkedTasks.length && incompleteTasks.length) || resource.showInTree)
    ) {
      addInVisible = true;
    }

    resource.allTasksCount = allLinkedTasks.length;
    resource.incompleteTasksCount = incompleteTasks.length;
    resource.allTasks = Object.freeze(allLinkedTasks || []);
    resource.incompleteTasks = Object.freeze(incompleteTasks || []);

    if (!resourcesTasksMap[resource.key]) {
      resourcesTasksMap[resource.key] = resource;
    }
    const resourceType = resource.type;

    if (!allMap.all[resourceCate]) {
      allMap.all[resourceCate] = [];
    }

    if (!allMap.visible[resourceCate]) {
      allMap.visible[resourceCate] = [];
    }
    // if (!allMap[resourceCate]) {
    //   allMap[resourceCate] = [];
    // }

    allMap.all[resourceCate].push(resource);

    if (addInVisible) {
      allMap.visible[resourceCate].push(resource);
    }
    if (resourceType) {
      if (!typeMap[resourceType]) {
        typeMap[resourceType] = {
          all: {},
          visible: {},
        };
      }
      if (!typeMap[resourceType].all[resourceCate]) {
        typeMap[resourceType].all[resourceCate] = [];
      }

      if (!typeMap[resourceType].visible[resourceCate]) {
        typeMap[resourceType].visible[resourceCate] = [];
      }

      // if (!typeMap[resourceType]) {
      //   typeMap[resourceType] = {};
      // }
      // if (!typeMap[resourceType][resourceCate]) {
      //   typeMap[resourceType][resourceCate] = [];
      // }

      typeMap[resourceType].all[resourceCate].push(resource);

      if (addInVisible) {
        typeMap[resourceType].visible[resourceCate].push(resource);
      }
    }
  });

  return { typeMap, resourcesTasksMap };
};

export const getResourceCategoriesByType = (
  resourceCategoriesMap,
  cateType
) => {
  let list = [];
  if (cateType && cateType !== "all") {
    list = flow([
      Object.entries,
      (arr) => arr.filter(([, value]) => value.cateType === cateType),
      (filteredList) => filteredList.map((f) => f[1]),
    ])(resourceCategoriesMap);
  } else {
    list = Object.values(resourceCategoriesMap);
  }

  return list;
};
export const createResourceCategoriesTree = ({
  categories,
  resources,
  selectedNodes,
  resourceType = "all",
  allResourceCateData = {},
  searchQuery,
  taskList = [],
  doNotCountAllTasks,
  sortByOrder,
  addNoProjectTreeNode,
  calcNoProjectTasksOfGroupFunc,
}) => {
  let clonedCategories = cloneDeep(categories);
  let normalisedSearchQuery = searchQuery?.trim().toLowerCase();
  let includeRoot = true;
  const isSearchEnabled = !isEmpty(searchQuery) && searchQuery !== "*";
  // if (resourceType && resourceType !== "all") {
  //   clonedCategories = flow([
  //     Object.entries,
  //     (arr) => arr.filter(([, value]) => value.type === resourceType),
  //   ])(clonedCategories);
  // } else {
  //   clonedCategories = Object.values(clonedCategories);
  // }
  const clonedResources = cloneDeep(resources);
  let cateResourcesMap = createResourceTypeListMap();

  let resourcesTasksMap = {};
  ({ typeMap: cateResourcesMap, resourcesTasksMap } = groupResourcesByTypes(
    clonedResources,
    {
      ...cateResourcesMap,
    },
    allResourceCateData,
    isSearchEnabled ? normalisedSearchQuery : "",
    taskList,
    doNotCountAllTasks
  ));

  let cateResourcesMapToUse =
    resourceType && resourceType !== "all"
      ? cateResourcesMap[resourceType]
      : cateResourcesMap["all"];

  if (doNotCountAllTasks) {
    cateResourcesMapToUse = cateResourcesMapToUse.visible;
  } else {
    cateResourcesMapToUse = cateResourcesMapToUse.all;
  }
  // const clonedCategories = cloneDeep(Object.values(categories));
  // const clonedResources = cloneDeep(resources);
  // const cateResourcesMap = {};
  // const cateChildrenMap = {};
  // Create root for top-level node(s)
  // const tree = [];
  // clonedResources.forEach((resource) => {
  //   const resourceCate = resource.catId || "root";

  //   if (!cateResourcesMap[resourceCate]) {
  //     cateResourcesMap[resourceCate] = [];
  //   }

  //   cateResourcesMap[resourceCate].push(resource);
  // });

  // console.debug(JSON.stringify(clonedCategories));
  // for (const category of clonedCategories) {
  //   if (!category.parentCatKey) {
  //     // category.children = [];
  //     tree.push(category);
  //     cateChildrenMap[category.key] = [];
  //     continue;
  //   }

  //   // Insert node as child of parent in flat array
  //   const parentIndex = clonedCategories.findIndex(
  //     (el) => el.key === category.parentCatKey
  //   );

  //   if (!clonedCategories[parentIndex].children) {
  //     cateChildrenMap[clonedCategories[parentIndex].key] = [
  //       {
  //         key: category.key,
  //         order: category.order,
  //         name: category.name,
  //       },
  //     ];
  //     clonedCategories[parentIndex].children = [category];
  //     continue;
  //   }
  //   cateChildrenMap[clonedCategories[parentIndex].key].push({
  //     key: category.key,
  //     order: category.order,
  //     name: category.name,
  //   });

  //   clonedCategories[parentIndex].children.push(category);
  //   clonedCategories[parentIndex].children.sort((a, b) => a.order - b.order);
  //   cateChildrenMap[clonedCategories[parentIndex].key].sort(
  //     (a, b) => a.order - b.order
  //   );
  // }

  const { tree, childrenMap: cateChildrenMap } = createTree(
    clonedCategories,
    cateResourcesMapToUse,
    isSearchEnabled,
    doNotCountAllTasks,
    sortByOrder
  );

  const rootDirData = {
    key: "root",
    name: resourceType === "project" ? "No Area" : "No Group",
    children: [],
    totalCount: 0,
    order: -1,
    open: allResourceCateData["root"]?.open || false,
  };

  if (
    (isSearchEnabled && isEmpty(cateResourcesMapToUse["root"])) ||
    (doNotCountAllTasks && isEmpty(cateResourcesMapToUse["root"]))
  ) {
    includeRoot = false;
  }

  if (includeRoot) {
    tree.unshift(rootDirData);
  }

  // if (addNoProjectTreeNode) {
  //   tree.unshift({
  //     key: "no-project",
  //     name: "No Project",
  //     children: [],
  //     totalCount: 0,
  //     order: -2,
  //     open: false,
  //   });
  // }

  // if (!isEmpty(cateResourcesMapToUse)) {

  // }
  tree.sort((a, b) => a.order - b.order);

  let processedTree = cloneDeep(
    processTree(cloneDeep(tree), cateResourcesMapToUse, selectedNodes, {
      classAttribute: "resource-category tree-node",
      itemMainType: "resource-category",
      itemSubType: "resource-sub-category",
      setActionAttris: true,
      actionType: "resource-category-change",
      showItemsAsChildren: true,
      extraItemData: {
        isResource: true,
      },
      verifierForAttris: () => {
        return true;
      },
      extraAttrisCreator: (nodeData) => {
        let attris = {
          "data-prevent-close": "1",
        };

        if (!nodeData.isResource) {
          attris = {
            ...attris,
            // "data-action-type": undefined,
            // "data-action-value": undefined,
            // "data-ignore": "1",
          };
        }
        return {
          ...attris,
        };
      },
      sortItemsAlphabetically: true,
      isSearchEnabled,
      calcNoProjectTasksOfGroupFunc,
      addNoProjectTreeNode,
      rawResourceCateMap: clonedCategories,
    })
  );

  // processedTree.sort((a, b) => a.order - b.order);

  return {
    cateChildrenMap,
    dataSource: processedTree,
    id: "key",
    text: "name",
    child: "children",
    htmlAttributes: "hasAttribute",
    expanded: "open",
    selected: "isSelected",
    resourcesTasksMap: resourcesTasksMap,
  };
};

export const createResourceCateData = (dataToAdd) => {
  return {
    ...dataToAdd,
    parentCatKey: dataToAdd.parentCatKey || "",
    name: dataToAdd.name || "",
    open: dataToAdd.open || false,
    created: dataToAdd.created || getCurrDate("extended"),
    modified: dataToAdd.modified || "",
    key: dataToAdd.key || createUniqueId(),
    order: dataToAdd.order || 0,
  };
};

export const createCategoryDataForAdd = (
  newCategoryData,
  categoryChildrenMap,
  mainTree,
  allCategoryMap,
  extraData = {}
) => {
  let parentKey = "";
  let catName = newCategoryData?.name || "New Topic";
  let catOrder = mainTree?.length || 0;

  if (
    newCategoryData.parentCatKey &&
    allCategoryMap[newCategoryData.parentCatKey]
  ) {
    parentKey = newCategoryData.parentCatKey;
    const selectedCatChildren =
      categoryChildrenMap[newCategoryData.parentCatKey];
    if (!isEmpty(selectedCatChildren)) {
      catOrder = selectedCatChildren.length;
      catName = getUniqueName(catName, selectedCatChildren);
    } else {
      catOrder = 0;
    }
  } else {
    catName = getUniqueName(catName, mainTree);
  }

  return createResourceCateData({
    parentCatKey: parentKey,
    name: catName,
    open: false,
    created: getCurrDate("extended"),
    modified: getCurrDate("extended"),
    key: createUniqueId(),
    order: catOrder,
    ...extraData,
  });
};

export const createResourceCateUpdateAndEditedData = (
  newData,
  currCateData
) => {
  let updates = {};
  let editedData = {};
  let changedProps = {};
  const keysToUpdate = Object.keys(newData);

  keysToUpdate.forEach((key) => {
    if (RESOURCE_CATEGORY_PROPS_TO_RESTORE.indexOf(key) >= 0) {
      if (key === "order") {
        editedData[key] = currCateData[key];
        if (typeof newData[key] === "number") {
          updates[key] = newData[key];
          changedProps[key] = newData[key];
        }
      } else {
        updates[key] = newData[key] || "";
        changedProps[key] = newData[key] || "";
        editedData[key] = currCateData[key] || "";
      }
    }
  });
  updates.modified = getCurrDate("extended");
  editedData.changedProps = changedProps;
  editedData.key = currCateData.key;

  return {
    updates,
    editedData,
  };
};

const getAllParentIDs = (cateKey, allCategories) => {
  let rtn = [];
  if (
    allCategories[cateKey].parentCatKey &&
    allCategories[allCategories[cateKey].parentCatKey]
  ) {
    rtn.unshift(allCategories[allCategories[cateKey].parentCatKey].name);
    rtn.unshift(
      ...getAllParentIDs(allCategories[cateKey].parentCatKey, allCategories)
    );
  }
  return rtn;
};

export const createFullCatePath = (cate, allCategories) => {
  let labels = [];
  if (allCategories[cate.key]) {
    labels.unshift(allCategories[cate.key].order);
    labels.unshift(...getParentCateFullPath(cate.key, allCategories));
  }
  return labels.join("-");
};

const getParentCateFullPath = (cateKeyForParent, allCategories) => {
  let rtn = [];
  if (allCategories[cateKeyForParent].parentCatKey) {
    if (allCategories[allCategories[cateKeyForParent].parentCatKey]) {
      rtn.unshift(
        allCategories[allCategories[cateKeyForParent].parentCatKey].order
      );
      rtn.unshift(
        ...getParentCateFullPath(
          allCategories[cateKeyForParent].parentCatKey,
          allCategories
        )
      );
    }
  }
  return rtn;
};

export const createResourceCateLabel = (cate, allDirs) => {
  let labels = [];

  if (allDirs[cate.key]) {
    labels.unshift(allDirs[cate.key].name);
    labels.unshift(...getAllParentIDs(cate.key, allDirs));
  }
  return labels.join(" / ");
};

export const createResourceCategoriesOpts = (
  allCategories,
  resourceType,
  firstLevelOnly
) => {
  let allCateList = Object.values(allCategories);

  if (resourceType) {
    allCateList = allCateList.filter(
      (rCate) => rCate.cateType === resourceType
    );
  }

  if (firstLevelOnly) {
    allCateList = allCateList.filter((rCate) => rCate.parentCatKey === "");
  }

  const opts = allCateList.map((cate) => {
    return {
      fullLabel: createResourceCateLabel(cate, allCategories),
      shortLabel: allCategories[cate.key].name,
      // label: createProjectLabel(proj, allProjects, allCategories),
      order: createFullCatePath(cate, allCategories),
      value: cate.key,
    };
  });

  const collator = new Intl.Collator([], { numeric: true });
  opts.sort((a, b) => {
    return collator.compare(a.order, b.order);
  });

  return opts;
};

export const createRestoredDataOfRescrCate = (rescrDataToRestore) => {
  const updates = {};
  let updateType;
  const propsToRestore = Object.keys(rescrDataToRestore);

  propsToRestore.forEach((prop) => {
    if (RESOURCE_CATEGORY_PROPS_TO_RESTORE.indexOf(prop) >= 0) {
      updates[prop] = rescrDataToRestore[prop] || "";
      if (RESOURCE_CATE_PROPS_REQUIRES_CONFIG.indexOf(prop) >= 0) {
        updateType = "parent-changed";
        if (prop === "order") {
          updates[prop] = rescrDataToRestore[prop];
        }
      } else if (prop === "name") {
        updateType = "rename";
      }
    }
  });

  updates.modified = getCurrDate();

  return {
    updates,
    type: updateType,
  };
};

export const findResourceCateDataByRef = (allResourcesList, refKey, type) => {
  if (!allResourcesList) {
    const allResourceCates = { ...getUserResources().getCategories() };

    allResourcesList = Object.values(allResourceCates);
  }

  return allResourcesList.find(
    (cate) => cate.refKey === refKey && cate.cateType === type
  );
};

export const storeTaskCateInResourceCateData = (
  cateDataToAddList,
  type = "project"
) => {
  const allResourceCates = { ...getUserResources().getCategories() };

  if (!Array.isArray(cateDataToAddList))
    cateDataToAddList = [cateDataToAddList];

  cateDataToAddList.forEach((cateDataToAdd) => {
    let parentCatKey = "";

    const parentKey = cateDataToAdd.subcategory || cateDataToAdd.category || "";

    if (parentKey) {
      const parentCatData = findResourceCateDataByRef(
        Object.values(allResourceCates),
        parentKey,
        type
      );

      if (!isEmpty(parentCatData)) {
        parentCatKey = parentCatData.key;
      }
    }
    const createdData = {
      parentCatKey,
      name: cateDataToAdd.title,
      order: cateDataToAdd.order,
      refKey: cateDataToAdd.key,
      created: cateDataToAdd.created,
      modified: cateDataToAdd.modified,
      cateType: type,
    };

    const cateData = createResourceCateData(createdData);

    allResourceCates[cateData.key] = { ...cateData };
  });

  getUserResources().set(allResourceCates, "categories");
};

export const removeTaskCateFromResourceCateData = (
  refKeyList,
  type = "project"
) => {
  const allResourceCateData = cloneDeep(getUserResources().getCategories());
  const allResourceCateList = Object.values(allResourceCateData);

  if (!Array.isArray(refKeyList)) refKeyList = [refKeyList];

  refKeyList.forEach((refKey) => {
    if (refKey) {
      const resourceCateDataToRemove = allResourceCateList.find(
        (c) => c.refKey === refKey && c.cateType === type
      );
      if (!isEmpty(resourceCateDataToRemove)) {
        delete allResourceCateData[resourceCateDataToRemove.key];
      }
    }
  });

  getUserResources().set(allResourceCateData, "categories");
};

export const createUpdatedResourceCateDataFromTaskCateData = (taskCateData) => {
  const createdUpdatedData = {};
  TASK_CATE_PROPS_ALLOWED_FOR_PARSE.forEach((p) => {
    if (taskCateData[p.field] !== undefined) {
      let initialVal = "";
      let val = taskCateData[p.field];
      if (p.type === "number") {
        initialVal = getNumber(taskCateData[p.field]);
      }

      if (p.type === "boolean") {
        initialVal = false;

        val = !!val;
      }
      createdUpdatedData[p.key] = val || initialVal;
    }
  });

  return createdUpdatedData;
};

export const updateTaskCateInResourceCateData = (
  dataList,
  type = "project"
) => {
  const allResourceCateData = cloneDeep(getUserResources().getCategories());
  const allResourcesList = Object.values(allResourceCateData);
  if (!Array.isArray(dataList)) {
    dataList = [dataList];
  }
  dataList.forEach((d) => {
    const { refKey, updatedData } = d;
    if (refKey) {
      const currResourceCateData = findResourceCateDataByRef(
        allResourcesList,
        refKey,
        type
      );

      if (!isEmpty(currResourceCateData)) {
        if (updatedData.parentKey !== undefined) {
          let parentKeyToUse = "";

          const parentCateData = findResourceCateDataByRef(
            allResourcesList,
            updatedData.parentKey,
            type
          );

          if (!isEmpty(parentCateData)) {
            parentKeyToUse = parentCateData.key;
          }

          updatedData.parentKey = parentKeyToUse;
        }
        const updatedCateData = createUpdatedResourceCateDataFromTaskCateData({
          ...updatedData,
        });

        allResourceCateData[currResourceCateData.key] = {
          ...allResourceCateData[currResourceCateData.key],
          ...updatedCateData,
        };
      }
    }
  });

  getUserResources().set(allResourceCateData, "categories");
};

export const moveResourceCateByTaskCateData = (
  taskCateDataList,
  type = "project"
) => {
  const allResourceCateData = cloneDeep(getUserResources().getCategories());
  const allResourceCateList = Object.values(allResourceCateData);

  if (!Array.isArray(taskCateDataList)) taskCateDataList = [taskCateDataList];

  taskCateDataList.forEach((taskCateData) => {
    const dropOrder = getNumber(taskCateData.order);
    const parentKey = taskCateData.parentKey || "";

    const resourceCateData = findResourceCateDataByRef(
      allResourceCateList,
      taskCateData.refKey,
      type
    );

    if (!isEmpty(resourceCateData)) {
      let parentKeyToUse = "";
      const parentCateData = findResourceCateDataByRef(
        allResourceCateList,
        parentKey,
        type
      );

      if (!isEmpty(parentCateData)) {
        parentKeyToUse = parentCateData.key;
      }
      const currResourcesOnOrder = allResourceCateList.filter(
        (c) =>
          c.cateType === type &&
          c.parentCatKey === parentKeyToUse &&
          c.order >= dropOrder &&
          c.key !== resourceCateData.key
      );

      currResourcesOnOrder.sort((a, b) => a.order - b.order);

      const updatedCateData = createUpdatedResourceCateDataFromTaskCateData({
        ...taskCateData,
        parentKey: parentKeyToUse,
      });

      allResourceCateData[resourceCateData.key] = {
        ...allResourceCateData[resourceCateData.key],
        ...updatedCateData,
      };
      if (!isEmpty(currResourcesOnOrder)) {
        let orderIndexToUse = increment(dropOrder, 1);
        currResourcesOnOrder.forEach((c) => {
          allResourceCateData[c.key] = {
            ...allResourceCateData[c.key],
            order: orderIndexToUse,
          };
          orderIndexToUse++;
        });
      }
    }
  });

  getUserResources().set(allResourceCateData, "categories");
};

export const getChildrenOfResourceCate = (
  resourceCateId = "",
  allResourceCateMap
) => {
  let childList = [];

  if (!isEmpty(allResourceCateMap)) {
    childList =
      Object.values(allResourceCateMap).filter(
        (rC) => rC.parentCatKey === resourceCateId
      ) || [];
  }

  return childList;
};

export const checkIfResourceMatches = (r, query) => {
  const rTag = r.tag;
  return rTag.toLowerCase().includes(query.toLowerCase());
};

export const filterResourceTree = (tree, resourcesMap) => {
  // const s = (r, { children, ...object }) => {
  //   if (!isEmpty(resourcesMap[object.key])) {
  //     r.push({ ...object, children: [] });
  //     return r;
  //   }
  //   children = children.reduce(s, []);
  //   if (children.length) r.push({ ...object, children: children });
  //   return r;
  // };
  // return tree.reduce(s, []);
  // const getNodes = (result, object) => {
  //   if (!isEmpty(resourcesMap[object.key])) {
  //     result.push({ ...object });
  //   }
  //   if (Array.isArray(object.children)) {
  //     const nodes = object.children.reduce(getNodes, []);
  //     if (nodes.length) result.push({ ...object, children: nodes });
  //   }
  //   return result;
  // };

  return tree.filter((o) => {
    if (o.children && o.children.length) {
      o.children = filterResourceTree(o.children, resourcesMap);
    }

    return (
      !isEmpty(resourcesMap[o.key]) ||
      checkIfNodeChildrenHasResources(o.children, resourcesMap)
    );
  });

  // return tree.reduce(getNodes, []);
};

const checkIfNodeChildrenHasResources = (treeChildren, resourcesMap) => {
  let res = false;

  if (!isEmpty(treeChildren)) {
    res = treeChildren.some(
      (c) =>
        !isEmpty(resourcesMap[c.key]) ||
        checkIfNodeChildrenHasResources(c.children, resourcesMap)
    );
  }

  return res;
};

export const processTree = (tree, itemsMap, selectedNodes, opts) => {
  const {
    classAttribute,
    setActionAttris,
    actionType,
    itemMainType,
    itemSubType,
    showItemsAsChildren,
    extraItemData = {},
    isSearchEnabled,
    verifierForAttris,
    extraAttrisCreator,
    // calcNoProjectTasksOfGroupFunc,

    // rawResourceCateMap,
    sortItemsAlphabetically,
  } = opts;

  tree.forEach((treeNode) => {
    let itemList = [];
    const itemsCount = itemsMap[treeNode.key]?.length || 0; // Gets the length of stored tasks by project key
    // treeNode.totalCount = itemsCount;
    treeNode.totalAllTasksCount = 0;
    treeNode.totalIncompleteTasksCount = 0;
    treeNode.allTasksList = [];
    treeNode.incompleteTasksList = [];
    let addResourceItemClass = !!treeNode.isResource;
    if (treeNode.isResource) {
      treeNode.totalAllTasksCount += treeNode.allTasksCount || 0;
      treeNode.totalIncompleteTasksCount += treeNode.incompleteTasksCount || 0;
      treeNode.allTasksList.push(...treeNode.allTasks);

      treeNode.incompleteTasksList.push(...treeNode.incompleteTasks);
    }
    //    resource.allTasksCount = allLinkedTasks.length;
    // resource.incompleteTasksCount = incompleteTasks.length;

    // if (itemsCount > 0 && showItemsAsChildren) {
    if (itemsCount > 0 && showItemsAsChildren) {
      itemList = itemsMap[treeNode.key].map((i) => {
        return {
          ...i,
          key: i.key,
          name: i.tag,
          order: i.order,
          children: [],
          ...extraItemData,
        };
      });
      // .sort((a, b) => a.order - b.order);

      if (sortItemsAlphabetically) {
        itemList.sort((a, b) => {
          const normalisedFirstName = a.name?.toLowerCase();
          const normailisedSecondName = b.name?.toLowerCase();

          return alphaSorter.compare(
            normalisedFirstName,
            normailisedSecondName
          );
        });
      }
    }

    let classNames = classAttribute;

    if (!isEmpty(itemList)) {
      if (!isEmpty(treeNode.children)) {
        // Disabled to show resources last
        // treeNode.children.unshift(...itemList);
        treeNode.children.push(...itemList);
      } else {
        treeNode.children = [...itemList];
      }
    }

    // if (showItemsAsChildren) {
    //   treeNode.children.sort((a, b) => a.order - b.order);
    // }

    if (treeNode.children && treeNode.children.length) {
      classNames += ` ${itemMainType}`;
    } else {
      classNames += ` ${itemSubType}`;
    }

    if (addResourceItemClass) {
      classNames += ` is-resource-item`;
    }

    treeNode["hasAttribute"] = {
      class: classNames,
    };
    if (setActionAttris) {
      let addAttris = true;
      if (verifierForAttris) {
        addAttris = verifierForAttris(treeNode);
      }

      if (addAttris) {
        let extraAttris = {};

        if (extraAttrisCreator) {
          extraAttris = extraAttrisCreator(treeNode);
        }
        treeNode["hasAttribute"] = {
          ...treeNode["hasAttribute"],
          "data-action-type": actionType,
          "data-action-value": treeNode.key,
          ...extraAttris,
        };
      }
    }

    treeNode.type = itemSubType;
    treeNode.isSelected = selectedNodes.includes(treeNode.key);

    if (isSearchEnabled) {
      treeNode.open = true;
    }
    if (treeNode.children && treeNode.children) {
      treeNode.type = itemMainType;
      treeNode.children.forEach((child) => {
        child = createCount(child, itemsMap, selectedNodes, opts);

        treeNode.allTasksList.push(...child.allTasksList);
        treeNode.incompleteTasksList.push(...child.incompleteTasksList);
        treeNode.totalAllTasksCount += child?.totalAllTasksCount || 0;
        treeNode.totalIncompleteTasksCount +=
          child?.totalIncompleteTasksCount || 0;
        // treeNode.totalCount += child.totalCount || 0;
      });
    }
    treeNode.allTasksList = Object.freeze(treeNode.allTasksList);
    treeNode.incompleteTasksList = Object.freeze(treeNode.incompleteTasksList);
  });

  return tree;
};

const createCount = (treeNode, itemsMap, selectedNodes, opts) => {
  const {
    classAttribute,
    setActionAttris,
    actionType,
    itemMainType,
    itemSubType,
    showItemsAsChildren,
    extraItemData = {},
    isSearchEnabled,
    verifierForAttris,
    extraAttrisCreator,
    sortItemsAlphabetically,
    addNoProjectTreeNode,
    calcNoProjectTasksOfGroupFunc,
  } = opts;

  let itemList = [];
  const totalCount = itemsMap[treeNode.key]?.length || 0; // Gets the length of stored tasks by project key

  // treeNode.totalCount = 0;

  treeNode.totalAllTasksCount = 0;
  treeNode.totalIncompleteTasksCount = 0;
  treeNode.allTasksList = [];
  treeNode.incompleteTasksList = [];

  let addResourceItemClass = !!treeNode.isResource;
  if (treeNode.isResource) {
    treeNode.totalAllTasksCount += treeNode.allTasksCount || 0;
    treeNode.totalIncompleteTasksCount += treeNode.incompleteTasksCount || 0;

    treeNode.allTasksList.push(...treeNode.allTasks);

    treeNode.incompleteTasksList.push(...treeNode.incompleteTasks);
  }

  // totalCount > 0
  if (totalCount > 0 && showItemsAsChildren) {
    itemList = itemsMap[treeNode.key].map((i) => {
      return {
        ...i,
        key: i.key,
        name: i.tag,
        order: i.order,
        children: [],
        ...extraItemData,
      };
    });
    // .sort((a, b) => a.order - b.order);

    if (sortItemsAlphabetically) {
      itemList.sort((a, b) => {
        const normalisedFirstName = a.name?.toLowerCase();
        const normailisedSecondName = b.name?.toLowerCase();

        return alphaSorter.compare(normalisedFirstName, normailisedSecondName);
      });
    }
  }

  let classNames = classAttribute;

  if (!isEmpty(itemList)) {
    if (!isEmpty(treeNode.children)) {
      // Disabled to show resources last in list
      // treeNode.children.unshift(...itemList);
      treeNode.children.push(...itemList);
    } else {
      treeNode.children = [...itemList];
    }
  }

  // if (showItemsAsChildren) {
  //   treeNode.children.sort((a, b) => a.order - b.order);
  // }

  if (treeNode.children && treeNode.children.length) {
    classNames += ` ${itemMainType}`;
  } else {
    classNames += ` ${itemSubType}`;
  }

  if (addResourceItemClass) {
    classNames += ` is-resource-item`;
  }

  treeNode["hasAttribute"] = {
    class: classNames,
  };

  if (setActionAttris) {
    let addAttris = true;
    if (verifierForAttris) {
      addAttris = verifierForAttris(treeNode);
    }
    let extraAttris = {};

    if (extraAttrisCreator) {
      extraAttris = extraAttrisCreator(treeNode);
    }
    if (addAttris) {
      treeNode["hasAttribute"] = {
        ...treeNode["hasAttribute"],
        "data-action-type": actionType,
        "data-action-value": treeNode.key,
        ...extraAttris,
      };
    }
  }

  treeNode.type = itemSubType;
  treeNode.isSelected = selectedNodes.includes(treeNode.key);

  if (isSearchEnabled) {
    treeNode.open = true;
  }
  // if (selectedNodes && selectedNodes.length) {
  //   dir.open = selectedNodes.includes(dir.key);
  // }
  if (treeNode.children && treeNode.children.length) {
    treeNode.type = itemMainType;
    treeNode.children.forEach((d) => {
      d = createCount(d, itemsMap, selectedNodes, opts);

      treeNode.allTasksList.push(...d.allTasksList);
      treeNode.incompleteTasksList.push(...d.incompleteTasksList);
      treeNode.totalAllTasksCount += d?.totalAllTasksCount || 0;
      treeNode.totalIncompleteTasksCount += d?.totalIncompleteTasksCount || 0;
    });
  }

  if (
    addNoProjectTreeNode &&
    treeNode.parentCatKey !== undefined &&
    calcNoProjectTasksOfGroupFunc
  ) {
    treeNode = calcNoProjectTasksOfGroupFunc(treeNode);
  }

  treeNode.allTasksList = Object.freeze(treeNode.allTasksList);
  treeNode.incompleteTasksList = Object.freeze(treeNode.incompleteTasksList);
  return treeNode;
};

export const getResourceCateFromTaskCategories = (selectedTaskCategories) => {
  const allResourceCates = { ...getUserResources().getCategories() };
  let selectedCategories = [];
  selectedTaskCategories.forEach((opt) => {
    const resourceCateData = findResourceCateDataByRef(
      Object.values(allResourceCates),
      opt.id,
      "project"
    );

    if (!isEmpty(resourceCateData)) {
      selectedCategories.push(resourceCateData.key);
    }
  });

  return selectedCategories;
};

export const checkIfResourceNodeHasNoProject = (rescrCateNode) => {
  return rescrCateNode?.includes("no-project");
};
