<template>
  <ItemTypeSelector
    :value="data.type"
    @input="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    @keydown="triggerKeyDown"
    type="resource"
    ref="select"
    :selectedOptIconColor="greyIconColor"
    :optItemIconColor="greyIconColor"
  />
</template>
<script>
import ItemTypeSelector from "@/components/ItemTypeSelector";
import {
  columnPropsMixin,
  selectorColumnPropsMixin,
} from "@/core/components/DataTable";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
export default {
  name: "resource-type-cell",
  mixins: [columnPropsMixin, selectorColumnPropsMixin],
  components: {
    ItemTypeSelector,
  },
  methods: {
    handleChange(val) {
      const isAddModeEnabled = this.cell.api.isAddModeEnabled();
      if (!isAddModeEnabled && val === "note") {
        this.setDataForConvertMode({
          dataList: [{ ...this.data }],
          typeToUse: "note",
        });
        return;
      }
      this.onSuccess(val, val, this.cell);
    },
    triggerKeyDown(e) {
      setTimeout(() => {
        this.handleKeyDown(e);
      }, 0);
    },
  },
  computed: {
    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
  },
};
</script>
