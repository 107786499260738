<template>
  <v-badge
    :value="isVisible"
    v-bind="$attrs"
    color="transparent"
    offset-y="20px"
    offset-x="20px"
  >
    <template #badge>
      <slot name="badge-content"> </slot>
    </template>

    <slot name="default"> </slot>
  </v-badge>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
