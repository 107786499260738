import Storage from "@/utils/storage";

export const getAuthToken = async (authKey) => {
  return Storage.get(authKey);
  //   if (tokenExpired()) {
  //     const refreshtoken = sessionStorage.getItem("refreshToken");
  //     const token = await getValidTokenFromServer(refreshtoken);
  //     sessionStorage.setItem(authKey, token.accessToken);
  //     sessionStorage.setItem(`${authKey}-expiry`, newExpirationDate());
  //     return token.accessToken;
  //   } else {
  //     console.debug("tokens.js 11 | token not expired");
  //     return sessionStorage.getItem("accessToken");
  //   }
};

export const setAuthToken = (authKey, token, tokenExpiry) => {
  Storage.set(authKey, token);
  Storage.set(`${authKey}-expiry`, tokenExpiry);
};
export const createAuthTokenExpirationDate = (hrsToAdd = 1) => {
  var expiration = new Date();
  expiration.setHours(expiration.getHours() + hrsToAdd);
  return expiration;
};

export const isAuthTokenExpired = (authKey) => {
  const now = Date.now();

  const authToken = localStorage.getItem(authKey);
  if (!authToken || authToken === "undefined") {
    return true;
  }
  const expirationDate = localStorage.getItem(`${authKey}-expiry`);
  const expDate = new Date(expirationDate);
  if (now > expDate.getTime()) {
    return true; // token expired
  }
  return false; // valid token
};

export const setAuthRefreshToken = (refreshAuthKey, token) => {
  Storage.set(refreshAuthKey, token);
};

export const getAuthRefreshToken = (refreshAuthKey) => {
  return Storage.get(refreshAuthKey);
};

export const clearAuthToken = (authKey, refreshAuthKey) => {
  Storage.remove(authKey);
  Storage.remove(`${authKey}-expiry`);
  Storage.remove(refreshAuthKey);
};
