export default {
  props: {
    data: {
      type: Object,
    },
  },
  mounted() {
    // this.cell
    this.onRendered(() => {
      this.open();
    }, this.cell);
  },
  methods: {
    handleFocus(e) {
      this.open(e);
    },
    handleBlur() {},
    open() {
      if (this.$options.useOldSelectMethods) {
        this.$refs.select.onClick(this.$refs.select);
      } else {
        this.$refs.select.openInput();
      }
    },
    handleEnter() {},
  },
};
