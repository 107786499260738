<template>
  <ForecastBtns :value="selectedOptVal" @input="handleSelectForecast" />

  <!-- <v-btn-toggle id="forecast-btns-row" :value="selectedOptIndex" borderless>
    <v-tooltip v-for="(opt, index) in calForecastOpts" :key="index" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="handleSelectForecast(opt)"
          :class="createForecastBtnStyles(opt)"
        >
          <span class="forecast-btn-text">
            {{ opt.displayText }}
          </span>
        </v-btn>
      </template>
      <span> {{ opt.label }}</span>
    </v-tooltip>
  </v-btn-toggle> -->
</template>
<script>
import {
  calForecastOptsConfig,
  // calForecastValMap,
} from "@/variables/viewConfigs";
import { mapGetters, mapMutations } from "vuex";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import isEmpty from "lodash/isEmpty";

import { add as addDates } from "date-fns";
import { createDateRange, formatDate } from "@/helpers/dates";
import { INTERNAL_DATE_FORMAT } from "@/variables/dates";
import ForecastBtns from "@/components/ForecastBtns";
export default {
  components: {
    ForecastBtns,
  },
  mixins: [userDetailsMixin],
  props: {
    mode: {
      type: String,
      default: "today",
    },
  },
  data() {
    return {
      selectedForecast: null,
    };
  },
  methods: {
    ...mapMutations("task", ["updateState"]),

    createDates(forecastOpt) {
      let forecast = forecastOpt.value;
      let createdDates = [];
      const startDate = new Date();
      const addDatesConfig = {};
      switch (forecast) {
        case "week":
          addDatesConfig.weeks = 1;
          break;
        case "month":
          addDatesConfig.months = 1;
          break;
        case "quarter":
          addDatesConfig.days = 91;
          break;
        case "year":
          addDatesConfig.years = 1;
          break;
      }

      if (!isEmpty(addDatesConfig)) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const endDate = addDates(yesterday, addDatesConfig);

        createdDates = createDateRange(startDate, endDate).map((d) =>
          formatDate(d, INTERNAL_DATE_FORMAT)
        );
      }
      return createdDates;
    },
    async handleSelectForecast(forecastOpt) {
      if (
        this.isInspectModeEnabled ||
        this.selectedCalForecastType === forecastOpt.value
      ) {
        // don't run if same forecase or inspect mode
        const dates = [formatDate(new Date(), INTERNAL_DATE_FORMAT)];
        this.$emit("forecast:set", dates, "");
        return;
      }

      const createdDates = this.createDates(forecastOpt);
      this.$emit("forecast:set", createdDates, forecastOpt.value);
    },

    createForecastBtnStyles(forecastOptData) {
      return {
        "forecast-btn": true,
        "forecast-selected":
          forecastOptData.value === this.selectedCalForecastType,
      };
    },
  },
  computed: {
    ...mapGetters("task", ["selectedCalForecastType", "isInspectModeEnabled"]),
    calForecastOpts() {
      return calForecastOptsConfig;
    },
    selectedOptVal() {
      return !this.isInspectModeEnabled
        ? this.selectedCalForecastType
        : undefined;
    },
  },
};
</script>
<style>
#forecast-btns-row {
  border-radius: 6px !important;
  background-color: #efefef !important;
  gap: 4px;
}

#forecast-btns-row .forecast-selected {
  background: var(--primary-color) !important;
  color: #fff !important;
}

#forecast-btns-row .forecast-btn {
  background-color: #efefef;
  color: var(--grey-icon-color);
}

#forecast-btns-row .forecast-btn .forecast-btn-text {
  font-size: 15px;
}

#forecast-btns-row button.forecast-btn.v-btn.v-btn.v-size--default {
  height: 22px !important;
  min-width: auto !important;
  border-radius: 6px !important;
  padding: 5px 5px !important;
}
</style>
