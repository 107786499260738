<template>
  <div
    :class="rowClass"
    role="group"
    @click="handleRowClick"
    @dblclick="handleDbClickRow"
    :data-key="item.key"
    :data-group-key="groupKey"
    :data-row-count="item.dataCount"
  >
    <div v-if="groupTitle" :class="classes.groupCell">
      <span :class="toggleBtnClasses" @click="handleGroupToggle" />
      {{ groupTitle }}
    </div>
  </div>
</template>
<script>
import isEqual from "lodash/isEqual";
import classesObj from "./classes";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
export default {
  props: {
    item: Object,
  },
  methods: {
    handleRowClick() {},
    handleDbClickRow() {},
    handleGroupToggle() {
      this.api.toggleGroup({ key: this.item.groupProp, value: this.item.key });
    },
  },
  inject: ["api"],
  computed: {
    rowClass() {
      let className = `${this.classes.row} ${this.classes.groupRow}`;
      return className;
    },
    groupTitle() {
      const groupBy = this.item.groupProp;
      const data = this.item.data;
      const value = this.item.key;
      return this.api.createGroupCaption(data, groupBy, value);
    },
    classes() {
      return classesObj;
    },

    isGroupCollapsed() {
      const currCollapsedGroups = this.api.getCollapsedGroupList();
      const currGroup = {
        key: this.groupKey,
        value: this.item.key,
      };
      const storedIndex = currCollapsedGroups.findIndex((g) =>
        isEqual(currGroup, g)
      );
      return storedIndex >= 0;
    },
    toggleBtnClasses() {
      return {
        "e-icons e-gdiagonaldown group-toggler": true,
        "e-icon-gdownarrow": !this.isGroupCollapsed,
        "e-icon-grightarrow": this.isGroupCollapsed,
      };
    },
    groupKey() {
      let createdKey = "";

      if (!isEmpty(this.item.groupProp)) {
        createdKey = isPlainObject(this.item.groupProp)
          ? this.item.groupProp.key
          : this.item.groupProp;
      }
      return createdKey;
    },
  },
};
</script>
<style scoped>
.group-toggler {
  cursor: pointer;
}
</style>
