<template>
  <div
    class="d-flex flex-column flex-nowrap fill-height pl-4 resources-table-wrapper"
  >
    <div>
      <ResourcesTypeFilterBar
        v-if="isActive"
        :isInCategoryMode="true"
        :tasksCount="filteredTasksCount"
        :showCategoryFilter="isSelectionEmpty"
        :filterOpts="selectedResourcesIdFilterOpts"
      />
    </div>

    <div v-show="displayPlaceholder" class="placeholder-text-wrapper">
      <TipLayout :isVisible="true" @tip:closed="hideTip">
        <div class="d-flex align-center tip-content-wrapper">
          <div class="selection-tip-label mr-2" :style="createTipTextStyles()">
            tip
          </div>

          <div>
            <span>Select</span>&nbsp;a category on the left to view&nbsp;
            <span v-if="resourceType === 'project'"
              >the associated project tasks</span
            >
            <span v-else-if="resourceType === 'people'"
              >its tasks involving people</span
            >
            <span v-else-if="resourceType === 'place'"
              >its tasks involving places</span
            >
            <span v-else-if="resourceType === 'thing'"
              >its tasks involving things</span
            >
          </div>
        </div>
      </TipLayout>
      <!-- <div class="placeholder-text">
        <div class="selection-tip-label mr-2">tip</div>

        <div>
          <span class="primary--text">Select</span>&nbsp;a category on the left
          to view&nbsp;
          <span v-if="resourceType === 'project'"
            >the associated project tasks</span
          >
          <span v-else-if="resourceType === 'people'"
            >its tasks involving people</span
          >
          <span v-else-if="resourceType === 'place'"
            >its tasks involving places</span
          >
          <span v-else-if="resourceType === 'thing'"
            >its tasks involving things</span
          >
        </div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              :attrs="attrs"
              icon
              small
              depressed
              class="ml-1"
              @click.stop="hideTip"
            >
              <v-icon :color="greyIconColor" size="22">mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span> Hide tip </span>
        </v-tooltip>
      </div> -->
    </div>
    <!-- <div
      v-show="showBarGraph"
      class="overflow-hidden resources-tasks-grid-wrapper resource-tasks-grid"
      style="height: 454px; max-width: 800px"
    >
      <BarGraph
        :barSeriesData="finalChartOpts.seriesData"
        :yAxisData="finalChartOpts.yAxisData"
      />
    </div> -->
    <div
      v-show="showTasksGrid"
      class="flex-grow-1 overflow-hidden resources-tasks-grid-wrapper resource-tasks-grid"
    >
      <NewActivityHistoryTable
        @rows:selected="handleRowsSelected"
        @list:processed="handleTasksProcessed"
        :projectId="areaIds"
        mode="resource"
        :resourceId="resourceIds"
        :resourceTypeToIgnore="resourceTypeToIgnore"
        :ignoreResourceSelectionCheck="!!resourceTypeToIgnore"
        :listLoaded="true"
        :deselectOnClick="true"
        :allowArrowKeySelection="true"
        :allowMultiSelect="true"
        :actionsAllowed="['add']"
        useNextActionModeConfig
        useAdditionalTaskFilters
        :additionalFilterOpts="additionalFilterOpts"
        :showOnlyNextActionsTasks="isSelectionEmpty"
        :isActive="showTasksGrid"
        from="LIST"
        ref="tasksGrid"
      />
    </div>
    <!-- <div class="flex-grow-1 overflow-hidden">
      <ResourcesTable ref="dataGrid" />
    </div> -->
  </div>
</template>
<script>
import NewActivityHistoryTable from "@/components/ActivityHistoryTable/NewActivityHistoryTable.vue";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { mapActions, mapGetters } from "vuex";
import ResourcesTable from "../ResourcesTable/ResourcesTable.vue";
import ResourcesTypeFilterBar from "../ResourcesTypeFilterBar/ResourcesTypeFilterBar.vue";
import DataFrame from "@/core/components/DataTable/lib/pandas/pandas";
import BarGraph from "@/core/components/BarGraph.vue";
import TipLayout from "@/components/TipLayout.vue";
import {
  createNoProjectTasksFilter,
  // checkIfSelectedResourceCateHasCategoryOnly,
  createResourcesSearchFilter,
  createResourceTypeOptFilter,
  getAllNoProjectNodesOfResourceCate,
  getAllResourcesOfResourceCate,
} from "@/helpers/resources";
import { sortForResourceList } from "@/variables/resources";
import { checkIfResourceNodeHasNoProject } from "@/helpers/resourceCategoriesHelpers";
import EventEmitter from "@/helpers/eventEmitter";
import filterList from "@/core/components/DataTable/lib/pandas/filter";
import {
  // alphaSorter,
  findNodeInTree,
} from "@/helpers/common";
import isEqual from "lodash/isEqual";
import {
  REFRESH_RESOURCE_ID_FILTER,
  TOGGLE_NO_PROJECT_TASKS_FILTER,
} from "@/variables/events";
import {
  createResourceCategoriesTree,
  getResourceCategoriesByType,
} from "@/helpers/resourceCategoriesHelpers";
const collator = new Intl.Collator([], { numeric: true });
import {
  checkTaskAdditionalFiltersEmpty,
  createTaskTypeSeriesDataFromCategoryList,
  groupTasksByTaskType,
} from "@/helpers/tasks";
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
import { setResourcesTipStatus } from "@/helpers/tips";
export default {
  components: {
    NewActivityHistoryTable,
    // eslint-disable-next-line vue/no-unused-components
    ResourcesTable,
    ResourcesTypeFilterBar,
    BarGraph,
    TipLayout,
  },
  inject: {
    toggleOrderTasksData: {
      from: "toggleData",
      default: {},
    },
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resourceIds: [],
      filteredTasksCount: 0,
      finalChartOpts: {},
      isNoProjectTasksFilterEnabled: false,
      useAreaAndResourceIds: false,
      isNoProjectNodeSelected: false,
      areaIds: [],
    };
  },
  mounted() {
    EventEmitter.on(
      TOGGLE_NO_PROJECT_TASKS_FILTER,
      this.handleToggleNoProjectTasksFilter
    );
    EventEmitter.on(
      REFRESH_RESOURCE_ID_FILTER,
      this.handleResourceFilterRefresh
    );
  },
  beforeDestroy() {
    EventEmitter.off(
      REFRESH_RESOURCE_ID_FILTER,
      this.handleResourceFilterRefresh
    );
    EventEmitter.off(
      TOGGLE_NO_PROJECT_TASKS_FILTER,
      this.handleToggleNoProjectTasksFilter
    );
  },
  methods: {
    ...mapActions("resourcesData", ["updateResourcesState"]),
    ...mapActions(["updateRootState"]),
    ...mapActions("task", ["updateSelectedTasks"]),

    handleToggleNoProjectTasksFilter(val) {
      this.isNoProjectTasksFilterEnabled =
        val !== undefined ? val : !this.isNoProjectTasksFilterEnabled;
    },
    handleRowsSelected(rows) {
      if (this.isActive) {
        this.updateSelectedTasks(rows);
      }
    },
    handleTasksProcessed(list) {
      this.filteredTasksCount = list.length;
    },
    handleResourceFilterRefresh() {
      this.getAndSetResourceIdsAndAreaIds();
    },
    getAndSetResourceIdsAndAreaIds() {
      const selectedCategory = this.selectedCategories[0];
      let resourceIds = [];
      let areaNoProjectIds = [];
      let useAreaAndResourceIds = false;
      let isNoProjectNodeSelected = false;
      if (selectedCategory) {
        if (!checkIfResourceNodeHasNoProject(selectedCategory)) {
          ({ resourceIds, areaNoProjectIds } =
            this.getCategoryResourcesAndAreaNoProjectIds(selectedCategory));

          useAreaAndResourceIds = !isEmpty(areaNoProjectIds);
        } else {
          isNoProjectNodeSelected = true;
        }
      } else if (this.filterResources && this.filterResources.length) {
        this.filterResources.forEach((categoryId) => {
          const data = this.getCategoryResourcesAndAreaNoProjectIds(categoryId);
          resourceIds.push(...data.resourceIds);
          areaNoProjectIds.push(...data.areaNoProjectIds);
        });

        useAreaAndResourceIds = !isEmpty(areaNoProjectIds);
      }

      this.resourceIds = [...resourceIds];
      this.areaIds = areaNoProjectIds.map((id) => id.split("_")[0]);
      this.useAreaAndResourceIds = useAreaAndResourceIds;
      this.isNoProjectNodeSelected = isNoProjectNodeSelected;
    },
    checkCategorySelectionForNoProjectNode(selectedCategory) {
      let res = false;

      if (this.categories[selectedCategory]) {
        const nodeRes = findNodeInTree(
          this.categoriesTree,
          (node) => node.key === selectedCategory
        );

        const cateNodeData = nodeRes?.node;
        if (!isEmpty(cateNodeData)) {
          const firstNodeKey = cateNodeData.children[0]?.key;

          res = !!(
            firstNodeKey && checkIfResourceNodeHasNoProject(firstNodeKey)
          );
        }
      }

      return res;
    },
    getCategoryResourcesAndAreaNoProjectIds(categoryId) {
      let resourceIds = [];
      let areaNoProjectIds = [];
      if (this.categories[categoryId]) {
        const nodeRes = findNodeInTree(
          this.categoriesTree,
          (node) => node.key === categoryId
        );

        const cateNodeData = nodeRes?.node;
        if (!isEmpty(cateNodeData)) {
          const resources = getAllResourcesOfResourceCate(cateNodeData);
          const noProjectNodes =
            getAllNoProjectNodesOfResourceCate(cateNodeData);
          if (!isEmpty(resources)) {
            resourceIds = resources.map((r) => r.key);
          }

          if (!isEmpty(noProjectNodes)) {
            areaNoProjectIds = noProjectNodes.map((r) => r.key);
          }
        }
      }
      return { resourceIds, areaNoProjectIds };
    },
    isGridInEditMode() {
      return this.$refs.dataGrid.isGridInEditMode();
    },
    updateFilteredList() {
      const processedResources = this.processedResources;
      let filteredList = [];
      if (!isEmpty(processedResources) && !isEmpty(this.rawTasks)) {
        let finalList = [];
        let groupedResourcesMap = {};

        const columns = new Set([...Object.keys(processedResources[0])]);

        let currResourcesList = new DataFrame(processedResources, [...columns]);
        currResourcesList = filterList(currResourcesList, this.currFiltersList);
        currResourcesList = currResourcesList.toCollection();
        currResourcesList.forEach((r) => {
          if (!groupedResourcesMap[r.hashedParentCatePath]) {
            groupedResourcesMap[r.hashedParentCatePath] = [];
          }

          groupedResourcesMap[r.hashedParentCatePath].push(r);
        });

        const groupedKeys = Object.keys(groupedResourcesMap).sort((a, b) =>
          collator.compare(a, b)
        );

        groupedKeys.forEach((k) => {
          // groupedResourcesMap[k].sort((a, b) => {
          //   const normalisedFirstName = a.tag?.toLowerCase();
          //   const normailisedSecondName = b.tag?.toLowerCase();

          //   return alphaSorter.compare(
          //     normalisedFirstName,
          //     normailisedSecondName
          //   );
          // });
          // groupedResourcesMap[k].forEach((i, order) => {
          //   i.alphaOrder = order;
          //   i.orderHashedPath = `${i.hashedParentCatePath}-${i.alphaOrder}`;
          // });

          finalList.push(...groupedResourcesMap[k]);
        });

        currResourcesList = [...finalList];

        currResourcesList.sort((a, b) => {
          return collator.compare(a.hashedOrderPath, b.hashedOrderPath);
        });

        // currResourcesList.sort((a, b) => {
        //   return collator.compare(a.alphaOrderPath, b.alphaOrderPath);
        // });

        filteredList = [...currResourcesList];

        if (!this.showClearedTasks) {
          const finalList = [];

          filteredList.forEach((r) => {
            const allLinkedTasks = this.rawTasks.filter((t) =>
              t.resources?.includes(r.key)
            );
            const incompleteTasks = allLinkedTasks.filter((t) => !t.completed);

            if (
              (allLinkedTasks.length && incompleteTasks.length) ||
              r.showInTree
            ) {
              finalList.push(r);
            }
          });

          filteredList = [...finalList];
        }
      }

      filteredList = Object.freeze(filteredList);

      this.updateResourcesState({
        filteredResources: filteredList,
        resourcesCount: filteredList.length,
      });
    },

    checkResourcesAndUpdateFilteredList(n, o) {
      let updateList = false;

      if (!isEmpty(n) && isEmpty(o)) {
        updateList = true;
      } else if (!isEmpty(n) && !isEmpty(o) && n.length !== o.length) {
        updateList = true;
      }
      if (updateList) {
        this.updateFilteredList();
      }
    },

    hideTip() {
      setResourcesTipStatus(!this.isResourceTipHidden);
      this.updateRootState({
        isResourceTipHidden: !this.isResourceTipHidden,
      });
    },

    createTipTextStyles() {
      let color;

      switch (this.resourceType) {
        case "place":
          color = "--place-secondary";
          break;
        case "project":
          color = `--project-secondary`;
          break;
        case "thing":
          color = "--thing-secondary";
          break;
        case "people":
          color = "--people-secondary";
      }
      return {
        color: `var(${color}) !important`,
      };
    },
  },
  computed: {
    ...mapGetters(["isResourceTipHidden"]),
    sortDefs() {
      const sortBy = sortForResourceList;
      const columns = [];
      const orders = [];
      const missingValues = [];
      const types = [];
      const defaultVals = [];
      const transformers = [];
      sortBy.forEach((sort) => {
        if (sort.order !== "none") {
          columns.push(sort.column);
          orders.push(sort.order === "desc");
          missingValues.push(sort.missingValue || "first");
          types.push(sort.type || "alphanumeric");
          defaultVals.push(sort.defaultValue);
          transformers.push(sort.transformFunc);
        }
      });

      return {
        columns,
        orders,
        transformers,
        defaultVals,
        missingValue: "last",
      };
    },
    ...mapGetters("resourcesData", {
      selectedCategories: "selectedCategories",
      rawResourcesMap: "rawResourcesMap",
      processedResources: "resources",
      searchQuery: "query",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      categories: "categories",
      categoriesTree: "categoriesTree",
      filterResources: "filterResources",
    }),
    ...mapGetters("resourceInfo", {
      isEditFormOpen: "isOpen",
    }),
    ...mapGetters("task", [
      "rawTasks",
      "showClearedTasks",
      "additionalTaskFilters",
    ]),
    resourceType() {
      let type = "";
      if (this.additionalTaskFilters?.resourceTypes) {
        type = this.additionalTaskFilters?.resourceTypes[0];
      }
      return type;
    },
    displayPlaceholder() {
      const res =
        !this.isResourceTipHidden &&
        !this.isEditFormOpen &&
        isEmpty(this.selectedCategories) &&
        isEmpty(this.filterResources) &&
        !this.showBarGraph;

      // (isEmpty(this.selectedCategories) ||
      //   this.selectedCategories.some((i) =>
      //     isEmpty(this.rawResourcesMap[i])
      //   ) );
      // ||
      //           !isEmpty(this.selectedCategories)
      return res;
    },
    currFiltersList() {
      const selectedResourceTypeOpt = this.selectedResourceTypeOpt;
      let searchQuery = this.searchQuery;
      let currFilters = [];
      if (selectedResourceTypeOpt && selectedResourceTypeOpt !== "all") {
        currFilters = createResourceTypeOptFilter(
          selectedResourceTypeOpt,
          currFilters
        );
      }
      if (searchQuery && searchQuery !== "*") {
        currFilters.push([...createResourcesSearchFilter(searchQuery, true)]);
      }

      return currFilters;
    },
    showTasksGrid() {
      return this.isActive && !this.isEditFormOpen && !this.showBarGraph;

      // !this.isEditFormOpen &&
      // !isEmpty(this.selectedCategories) &&
      // checkIfSelectedResourceCateHasCategoryOnly(this.selectedCategories)
    },
    selectedResourcesIdFilterOpts() {
      return {
        resourceId: this.resourceIds,
        showOnlyNextActionsTasks: this.isSelectionEmpty,
        ...this.additionalFilterOpts,
        projectId: this.areaIds,
        resourceTypeToIgnore: this.resourceTypeToIgnore,
      };
    },
    isSelectionEmpty() {
      return isEmpty(this.selectedCategories);
    },
    showBarGraph() {
      // const clonedAdditionalFilters = cloneDeep(this.additionalTaskFilters);
      // delete clonedAdditionalFilters.resourceTypes;
      // return (
      //   this.isSelectionEmpty &&
      //   this.showAllOrderedTasks &&
      //   isEmpty(this.filterResources) &&
      //   checkTaskAdditionalFiltersEmpty(clonedAdditionalFilters) &&
      //   !isEmpty(this.finalChartOpts.seriesData)
      // );

      return false;
    },
    chartOptions() {
      // const selectedResourceType = this.selectedResourceTypeOpt;
      // const categoriesToUse = getResourceCategoriesByType(
      //   this.categories,
      //   selectedResourceType
      // );

      // const allResourceCateData = this.categories;
      // const { dataSource: processedTree } = createResourceCategoriesTree({
      //   categories: !isEmpty(categoriesToUse) ? categoriesToUse : [],
      //   resources: this.processedResources,
      //   selectedNodes: [],
      //   resourceType: this.selectedResourceTypeOpt,
      //   allResourceCateData,
      //   searchQuery: this.searchQuery,
      //   taskList: this.rawTasks,
      //   doNotCountAllTasks: !this.showClearedTasks,
      // });

      // const cateTaskTypesMap = {};

      // processedTree.forEach((c) => {
      //   let tasksList = c.allTasksList;

      //   if (!this.showClearedTasks) {
      //     tasksList = c.incompleteTasksList;
      //   }

      //   const localCategoryTasksMap = {
      //     tasksTypeMap: {},
      //     totalTasksCount: 0,
      //     key: c.name,
      //   };

      //   let totalCategoryTasksCount = 0;
      //   const res = groupTasksByTaskType(tasksList);

      //   if (res.totalTasks) {
      //     totalCategoryTasksCount = res.totalTasks;
      //   }

      //   localCategoryTasksMap.tasksTypeMap = res.tasksTypeMap;
      //   localCategoryTasksMap.totalTasksCount = totalCategoryTasksCount;
      //   cateTaskTypesMap[c.name] = localCategoryTasksMap;
      // });
      // const cateTasksList = Object.values(cateTaskTypesMap);

      // cateTasksList
      //   .sort((a, b) => b.totalTasksCount - a.totalTasksCount)
      //   .reverse()
      //   .filter((cateTasksMap) => cateTasksMap.totalTasksCount > 0);

      // const reversedCategoryNamesList = cateTasksList.map((c) => c.key);

      // const barsData = createTaskTypeSeriesDataFromCategoryList(
      //   cateTasksList,
      //   cateTaskTypesMap
      // );

      // return Object.freeze({
      //   yAxisData: reversedCategoryNamesList,
      //   seriesData: barsData,
      // });

      return Object.freeze({
        yAxisData: [],
        seriesData: [],
      });
    },
    showAllOrderedTasks() {
      return this.toggleOrderTasksData?.showAllTasks || false;
    },

    greyIconColor() {
      return DARK_GREY_ICON_COLOR;
    },
    resourceTypeToIgnore() {
      let finalResourceTypeToIgnore = "";

      if (
        !isEmpty(this.selectedCategories) &&
        this.selectedCategories.length === 1 &&
        (checkIfResourceNodeHasNoProject(this.selectedCategories[0]) ||
          this.useAreaAndResourceIds)
      ) {
        finalResourceTypeToIgnore = "project";
      }

      return finalResourceTypeToIgnore;
    },

    additionalFilterOpts() {
      const res = {
        useAreaAndResourceIds: this.useAreaAndResourceIds,
        isNoProjectNodeSelected: this.isNoProjectNodeSelected,
        noProjectNodeId: this.isNoProjectNodeSelected
          ? this.selectedCategories[0]
          : "",
      };

      return res;
      // return {
      //   useAreaAndResourceIds: this.useAreaAndResourceIds,
      //   isNoProjectNodeSelected: this.isNoProjectNodeSelected,
      //   noProjectNodeId: this.isNoProjectNodeSelected
      //     ? this.selectedCategories[0]
      //     : "",
      // };
    },
  },
  watch: {
    showTasksGrid: {
      handler(n) {
        if (n) {
          this.getAndSetResourceIdsAndAreaIds();
        }
      },
      immediate: true,
    },
    processedResources: {
      handler(n, o) {
        this.checkResourcesAndUpdateFilteredList(n, o);
      },
      deep: true,
    },
    searchQuery: {
      handler() {
        this.updateFilteredList();
      },
    },
    selectedResourceTypeOpt: {
      handler() {
        this.updateFilteredList();
      },
      immediate: true,
    },
    rawTasks: {
      handler(n, o) {
        if (!isEmpty(n) && isEmpty(o)) {
          this.updateFilteredList();
        }
      },
      deep: true,
    },
    selectedCategories: {
      handler(n, o) {
        if (!isEqual(n, o) && this.showTasksGrid) {
          this.getAndSetResourceIdsAndAreaIds();
        }
      },
      deep: true,
      // immediate: true,
    },
    showClearedTasks: {
      handler() {
        this.updateFilteredList();
      },
    },
    chartOptions: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.finalChartOpts = n;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.resources-table-wrapper {
  padding-top: 10px;
}

.placeholder-text-wrapper {
  font-size: 16px;
  /* padding-top: 8px;
  padding-bottom: 8px; */

  margin-bottom: 4px;
  margin-top: 4px;
}

.placeholder-text {
  display: flex;
  align-items: center;
}
.resources-tasks-grid-wrapper {
  margin-top: 6px;
}

.placeholder-text .selection-tip-label {
  height: fit-content;
}

.tip-content-wrapper {
  font-size: 12px;
}
</style>
