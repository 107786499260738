<template>
  <div class="opt-selector-box">
    <v-select
      :items="opts"
      :item-text="itemLabelProp"
      :item-value="itemValueProp"
      :value="value"
      @change="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="handleKeyDown"
      hide-details
      ref="select"
      :disabled="disabled"
    >
    </v-select>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    itemLabelProp: {
      type: String,
      default: "label",
    },
    itemValueProp: {
      type: String,
      default: "value",
    },
    disabled: {
      type: Boolean,
    },
    opts: {
      type: Array,
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    openInput() {
      const inputEl = this.$refs.select.$el.querySelector("input");
      this.$refs.select.focus();
      this.$refs.select.onClick(this.$refs.select);

      inputEl?.focus();
    },
    blurInput() {
      this.$refs.select.blur();
    },
  },
};
</script>
<style scoped>
.opt-selector-box >>> .v-input {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
</style>
