export default {
  resources(state) {
    return state.processedResources;
  },
  categories(state) {
    return state.categories;
  },
  searchQuery(state) {
    return state.query;
  },
  rawResourcesMap(state) {
    return state.rawResourcesMap;
  },
  resourcesCount(state) {
    return state.resourcesCount;
  },
  query(state) {
    return state.query;
  },
  selectedCategories(state, _1, _2, rootStateGetters) {
    const isProjectModeEnabled =
      rootStateGetters["task/isProjectsResourceModeEnabled"];
    const activeView = rootStateGetters.currActiveView;

    if (activeView !== "resources" && isProjectModeEnabled) {
      return state.rawSelectedCategoriesMap["project"] || [];
    }
    return state.selectedCategories;
  },
  selectedResources(state) {
    return state.selectedResources;
  },
  filteredResources(state) {
    return state.filteredResources;
  },
  categoriesTree(state) {
    return state.categoriesTree;
  },
  cateChildrenMap(state) {
    return state.cateChildrenMap;
  },
  selectedResourceTypeOpt(state, _1, _2, rootStateGetters) {
    const isProjectModeEnabled =
      rootStateGetters["task/isProjectsResourceModeEnabled"];
    const activeView = rootStateGetters.currActiveView;

    if (activeView !== "resources" && isProjectModeEnabled) {
      return "project";
    }
    return state.selectedResourceTypeOpt;
  },
  collapsedGroupsList(state) {
    return state.collapsedGroupsList;
  },
  internalCollapsedGroups(state) {
    return state.internalCollapsedGroups;
  },
  filterResources: ({ filterResources }) => filterResources,
  rawSelectedCategoriesMap(state) {
    return state.rawSelectedCategoriesMap;
  },
  resourcesTasksMap(state) {
    return state.resourcesTasksMap;
  },
};
