import DatabaseInterface from "@/services/DatabaseInterface";
import isEmpty from "lodash/isEmpty";

const incompleteStatus = ["scheduled", "incomplete"];

const patchCompletedTasksInDb = (userId) => {
  DatabaseInterface.get("/" + userId + "/tasks/").once("value", (item) => {
    let tasks = Object.values(item.val());
    const updates = {};

    for (const task of tasks) {
      let isCompleted;

      if (typeof task.completed === "string") {
        if (incompleteStatus.indexOf(task.completed) > -1 || !task.completed) {
          isCompleted = false;
        } else if (typeof task.completed === "string") {
          isCompleted = true;
        }
      }
      if (typeof isCompleted === "boolean") {
        updates[`/tasks/${task.key}/completed`] = isCompleted;
      }
    }

    // console.debug("UP", updates);

    if (!isEmpty(updates)) {
      DatabaseInterface.update(updates, userId);
    }

    // console.debug("UPDATES", updates);

    //   let updates = {};
    //   updates["/projects/" + data.project + "/" + tasks.length] = id;
    //   const taskData = {
    //     cleared: data.cleared,
    //     completed: false,
    //     created: today,
    //     description: data.description,
    //     due: today,
    //     flag: data.flag,
    //     key: id,
    //     modified: today,
    //     order: tasks.length.toString(),
    //     priority: data.priority,
    //     project: data.project,
    //     status: data.status,
    //     taskType: data.taskType,
    //     time: now,
    //     title: data.title,
    //   };
    //   updates["/tasks/" + id] = taskData;
    //   DatabaseInterface.update(updates, uid);
    //   getUserActions().addTaskAction({
    //     type: "add",
    //     data: {
    //       key: id,
    //       project: data.project,
    //     },
    //     on: "task",
    //   });
    //   this.onAdd(taskData);
  });
};

export default patchCompletedTasksInDb;
