import { getCurrentTimeZoneName } from "@/helpers/dates";

export const INTERNAL_DATE_FORMAT = "yyyy-MM-dd";
export const DATE_FORMAT_FOR_CAPTION = "M/d/yy (iiii)";
export const DATE_FORMAT_IN_USE = "M/d/yy";
export const WEEK_DAY_FORMAT = "EEE";
export const DAY_FORMAT = "d";
export const MONTH_DAY_FORMAT = "M/d";
export const DATE_WITH_TIME_FORMAT_IN_USE = "M/d/yy h:mm a";
export const TIME_FORMAT_IN_USE = "h:mm a";
export const INTERNAL_TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT_IN_FILE = "yyyy-MM-dd HH:mm:ss XXXX";
export const DATE_TIME_STAMP_FORMAT = "yyyy-MM-ddTHH:mm:ssXXXX";
export const GOOGLE_ID_ALL_DAY_FORMAT = "yyyyMMdd";
export const GOOGLE_ID_DAY_FORMAT = "yyyyMMdd'T'HHmmss'Z'";
export const CURR_TIMEZONE_NAME = getCurrentTimeZoneName();
