import { getPathsFromEvent } from "@/utils/events";
import sleep from "./sleep";

export const findElInDragEventPaths = (eventData, validator) => {
  const paths = getPathsFromEvent(eventData);
  let el;
  for (const path of paths) {
    if (validator(path)) {
      el = path;
      break;
    }
  }

  return el;
};

export const getPosOfEl = (elToCheck) => {
  return elToCheck.getBoundingClientRect();
};

export const getElCurrPos = (
  draggedItemEl,
  parentElTop,
  offset = 0,
  propToUse = "bottom"
) => {
  return getPosOfEl(draggedItemEl)[propToUse] - parentElTop - offset;
};

export const focusOnInputEle = (inputEl) => {
  inputEl.focus();
  if (typeof inputEl.selectionStart == "number") {
    inputEl.selectionStart = inputEl.selectionEnd = inputEl.value.length;
  } else if (typeof inputEl.createTextRange != "undefined") {
    const range = inputEl.createTextRange();
    range.collapse(false);
    range.select();
  }
};

export const setCursorInEle = (el, collapse) => {
  const selection = window.getSelection();
  const range = document.createRange();
  selection.removeAllRanges();
  range.selectNodeContents(el);
  if (collapse) {
    range.collapse(false);
  }
  selection.addRange(range);
  // el.focus();
};

export const getTextContentFromHTML = (htmlContent) => {
  const container = document.createElement("div");
  container.innerHTML = htmlContent;
  const textContent = container.textContent;
  container.remove();
  return textContent;
};

const getCurrItemPos = (
  draggedItemEl,
  parentElTop,
  offset = 0,
  propToUse = "bottom"
) => {
  return getPosOfEl(draggedItemEl)[propToUse] - parentElTop - offset;
};

export const scrollListForDrag = async ({
  isDragStarted,
  parentEl,
  listWrappEl,
  draggedItemEl,
}) => {
  if (isDragStarted) return;
  const itemPos = getPosOfEl(draggedItemEl);
  const parentPos = getPosOfEl(parentEl);
  const listWrapperPos = getPosOfEl(listWrappEl);
  const itemTop = getCurrItemPos(draggedItemEl, parentPos.top, 22, "top");
  if (itemPos.bottom - parentPos.top >= parentPos.height) {
    const finalHeight = Math.floor(listWrapperPos.height - itemPos.height);
    let parentScrollTop = Math.floor(parentEl.scrollTop);
    while (parentScrollTop <= finalHeight) {
      if (
        !isDragStarted ||
        getCurrItemPos(draggedItemEl, parentPos.top) < parentPos.height
      ) {
        break;
      }
      await sleep(20);
      parentScrollTop += 10;
      parentEl.scrollTop = parentScrollTop;
    }
  } else if (itemTop <= 0) {
    let parentScrollTop = Math.floor(parentEl.scrollTop);
    while (parentScrollTop > 0) {
      if (
        !isDragStarted ||
        getCurrItemPos(draggedItemEl, parentPos.top, 22, "top") > 0
      ) {
        break;
      }
      await sleep(20);
      parentScrollTop -= 10;
      parentEl.scrollTop = parentScrollTop;
    }
  }
};
