import { isEmpty, escape } from "lodash";
import { decrement, increment } from "./number";

export const countWords = (strToProcess, countOnly) => {
  const cleanedStr = countOnly ? strToProcess : cleanString(strToProcess);
  return cleanedStr.split(" ").length;
};

export const countCharacters = (strToProcess) => {
  return strToProcess.length;
};
export const cleanString = (strToProcess) => {
  let res = [];
  // eslint-disable-next-line no-useless-escape
  const str = strToProcess.replace(/[\t\n\r\.\?\!]/gm, " ").split(" ");
  str.forEach((s) => {
    let trimStr = s.trim();
    if (trimStr.length > 0) {
      res.push(trimStr);
    }
  });

  return res.join(" ");
};

export const truncateString = (
  str,
  max = 10,
  addDots = true,
  type = "characters"
) => {
  let strToRtn = "";

  if (type === "characters") {
    strToRtn = str.trim().substring(0, decrement(max, 1));
  }

  if (type === "words") {
    const array = str.trim().split(" ");
    const ellipsis = addDots && array.length > max ? "..." : "";
    strToRtn = array.slice(0, decrement(max, 1)).join(" ") + ellipsis;
  }

  return strToRtn;
};

export const checkIfStringContainsNewLine = (strToCheck) => {
  const cleanedStr = (strToCheck && strToCheck.trim()) || "";
  const match = /\r|\n/g.exec(cleanedStr);
  return !isEmpty(match);
};

export const replaceCharInString = (str, match, replace) => {
  return str.replace(match, replace);
};

export const escapeString = (str) => {
  return escape(str);
};

export function escapeHtmlString(html) {
  const text = document.createTextNode(html);
  const div = document.createElement("div");
  div.appendChild(text);
  const parsedText = div.innerHTML;
  div.remove();
  return parsedText;
}

export function isHTML(str) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export const convertStringToPTagString = (str) => {
  const div = document.createElement("div");
  const p = document.createElement("p");
  p.innerHTML = str;
  div.appendChild(p);

  const convertedText = div.innerHTML;
  div.remove();
  return convertedText;
};

export const getAllIndexesOfCharInString = (str, char) => {
  const a = [];
  let i = -1;
  while ((i = str.indexOf(char, increment(i, 1))) >= 0) a.push(i);
  return a;
};
