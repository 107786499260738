<template>
  <div v-if="text" class="form-title">
    <RichTextEditor
      editorId="title-editor"
      :isReadOnly="true"
      :showToolbar="false"
      :value="text"
      :enableMentions="false"
    />
  </div>
</template>
<script>
import RichTextEditor from "@/components/RichTextEditor";
export default {
  components: {
    RichTextEditor,
  },
  props: {
    text: {
      type: String,
    },
  },
};
</script>
<style scoped>
.form-title {
  margin: 0px !important;
}

.form-title >>> #title-editor .ql-editor {
  padding: 0px !important;
  line-height: 1 !important;
  min-height: 34px !important;
}

.form-title >>> #title-editor .ql-editor * {
  font-size: 2.46rem !important;
  color: var(--primary-color);
}
</style>
