const actions = {
  openCateForm(context, payload) {
    context.commit("setData", { data: payload.data, isFormOpen: true });
  },
  openResourceCateForm(context, payload) {
    context.commit("setData", {
      data: payload.data,
      isResourceCateFormOpen: true,
    });
  },
  resetCateData(context) {
    context.commit("setData", {
      isFormOpen: false,
      isNoteTopicFormOpen: false,
      isResourceCateFormOpen: false,
      data: {},
    });
  },
  openNoteTopicForm(context, payload) {
    context.commit("setData", {
      data: payload.data,
      isNoteTopicFormOpen: true,
    });
  },
};

export default actions;
