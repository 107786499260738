import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"20%","value":_vm.isSelectorOpen,"content-class":"convert-dialog"},on:{"click:outside":_vm.closeDialog,"keydown":function($event){$event.stopPropagation();return _vm.handleKeyDown.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.labelText)+" ")]),_c(VCardText,[_c('div',[(_vm.typeToUse === 'task')?_c('div',[_c('ProjectSelector',{ref:"areaSelector",attrs:{"value":_vm.selectedVal},on:{"change":_vm.handleChange,"keydown":_vm.handleSelectorKeyDown}})],1):_vm._e(),(_vm.typeToUse === 'note')?_c('div',[_c('NoteFolderSelector',{ref:"topicSelector",attrs:{"value":_vm.selectedVal,"placeholder":"Select Topic"},on:{"change":_vm.handleChange,"keydown":_vm.handleSelectorKeyDown}})],1):_vm._e()])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"save-confirm-btn",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.submitData}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }