const mixin = {
  methods: {
    async focusOnSelector(selectorRef) {
      await this.$nextTick();

      setTimeout(() => {
        const el = this.$refs[selectorRef]?.$el;
        const inputEl = el?.querySelector(".v-select__slot input");
        inputEl?.focus();
      }, 250);
    },
  },
};

export default mixin;
