import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export default {
  namespaced: true,
  state() {
    return {
      isOpen: false,
      areaId: "",
      taskList: [],
      isAddMode: false,
      taskUpdates: {},
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
