<template>
  <HoveredIconButton
    @click="openTaskDetails"
    tooltip="Click to edit"
    :btnClass="btnClass"
    :disabled="disabled"
  >
    <slot></slot>
    <!-- <v-icon :size="18" slot="hovered"> mdi-pencil </v-icon> -->
  </HoveredIconButton>
</template>
<script>
import HoveredIconButton from "@/components/TasksListView/components/TasksList/components/HoveredIconButton.vue";

export default {
  components: {
    HoveredIconButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    openTaskDetails(e) {
      this.$emit("click", e);
    },
  },
};
</script>
