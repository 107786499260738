<template>
  <v-snackbar :timeout="3000" v-model="show" right tile :color="color">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon color="#fff" text v-bind="attrs" @click="closeToast">
        <v-icon color="#fff"> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeToast() {
      this.show = false;
    },
    ...mapActions("toast", ["hideToast"]),
  },
  computed: {
    ...mapGetters("toast", ["message", "isEnabled", "color"]),
  },
  watch: {
    isEnabled(newVal) {
      if (newVal) {
        this.show = newVal;
      }
    },
    show(newVal) {
      if (!newVal) {
        this.hideToast();
      }
    },
  },
};
</script>
