<template>
  <v-autocomplete
    ref="select"
    :value="value"
    :items="opts"
    :item-text="itemLabelToUse"
    :item-value="itemValueToUse"
    :placeholder="placeholder"
    :loading="loading"
    @change="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    @keydown="handleKeyDown"
    @update:search-input="handleSearch"
    :class="selectorClassNames"
    :menu-props="propsForMenu"
    :tabIndex="tabIndex"
    :solo="solo"
    :return-object="returnObject"
    auto-select-first
    hide-details
    :background-color="backgroundColor"
    :hideNoData="hideNoData"
  >
    <template v-if="useItemSelectionSlot" v-slot:selection="{ item }">
      <slot name="selection-content" :item="item"> </slot>
    </template>

    <template v-if="useItemSlot" v-slot:item="{ item, attrs, on }">
      <slot name="select-item" :item="item" :attrs="attrs" :on="on"> </slot>
    </template>
    <template v-if="useCustomSlot" #no-data>
      <slot name="no-data"> </slot>
    </template>
  </v-autocomplete>
</template>
<script>
import isEmpty from "lodash/isEmpty";
export default {
  props: {
    value: {
      type: String,
    },
    opts: {
      type: Array,
    },
    itemLabelToUse: {
      type: String,
    },
    itemValueToUse: {
      type: String,
      default: "value",
    },
    placeholder: {
      type: String,
    },
    useCustomSlot: {
      type: Boolean,
    },
    selectorClass: {
      type: String,
    },
    tabIndex: {
      type: Number,
    },
    menuProps: {
      type: Object,
    },
    useItemSelectionSlot: {
      type: Boolean,
    },
    useItemSlot: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    returnObject: {
      type: Boolean,
    },
    solo: {
      type: Boolean,
    },
    backgroundColor: {
      type: String,
    },
    hideNoData: {
      type: Boolean,
    },
  },
  data() {
    return {
      propsForMenu: {
        auto: true,
      },
    };
  },
  mounted() {
    if (!isEmpty(this.menuProps)) {
      this.propsForMenu = {
        ...this.propsForMenu,
        ...this.menuProps,
      };
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    handleSearch(e) {
      this.$emit("search-value", e);
    },
    openInput() {
      this.$refs.select.onClick(this.$refs.select);
    },
    blurInput() {
      this.$refs.select.blur();
    },
  },
  computed: {
    selectorClassNames() {
      return {
        "opt-selector-input": true,
        [this.selectorClass]: !!this.selectorClass,
      };
    },
  },
};
</script>
<style scoped>
.opt-selector-input {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
