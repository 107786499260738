import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"20%","value":_vm.isOpen,"content-class":"area-priority-setter-dialog"},on:{"click:outside":_vm.closeDialog,"keydown":_vm.handleKeyDown}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Set importance for this Area ")]),_c(VCardText,[_c('div',{staticClass:"area-priority-selector-wrapper"},[_c('PrioritySelector',{ref:"prioritySelector",on:{"keydown":_vm.handleSelectorKeyDown},model:{value:(_vm.selectedVal),callback:function ($$v) {_vm.selectedVal=$$v},expression:"selectedVal"}})],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"save-confirm-btn",attrs:{"color":"primary","depressed":"","text":""},on:{"click":_vm.submitData}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }