<template>
  <div class="note-topic-selector-wrapper">
    <OptSelectorInput
      ref="select"
      :value="value"
      :opts="pathOpts"
      :itemLabelToUse="labelToUse"
      itemValueToUse="value"
      :placeholder="placeholder"
      @change="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="handleKeyDown"
      selectorClass="path-input"
      :menuProps="{ contentClass: 'note-path-selector-popup' }"
      :useCustomSlot="true"
    >
      <template v-if="allowToAddTopic" #no-data>
        <v-btn @click="addNewTopic" text class="new-item-btn">
          Add New Topic
        </v-btn>
      </template>
    </OptSelectorInput>

    <template v-if="allowToAddTopic">
      <AddTopicFormDialog @topic:added="handleTopicAdd" />
    </template>
  </div>
</template>
<script>
import { createDirectoryOpts } from "@/helpers/directories";
import { mapActions, mapGetters } from "vuex";
import OptSelectorInput from "@/components/OptSelectorInput";
import AddTopicFormDialog from "./AddTopicFormDialog.vue";
export default {
  components: {
    OptSelectorInput,
    AddTopicFormDialog,
  },
  data() {
    return {
      selectedVal: "",
    };
  },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Select Topic",
    },
    labelType: {
      type: String,
      default: "full",
    },
    allowToAddTopic: {
      type: Boolean,
      default: true,
    },
    onlyFirstLevel: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("note", ["directories"]),
    pathOpts() {
      const opts = createDirectoryOpts(this.directories, this.onlyFirstLevel);
      return opts;
    },
    labelToUse() {
      return this.labelType === "short" ? "shortLabel" : "fullLabel";
    },
  },
  methods: {
    ...mapActions("cateData", ["openNoteTopicForm"]),
    handleTopicAdd(topicData) {
      this.selectedVal = topicData.key;
      this.handleChange(topicData.key);
    },
    addNewTopic() {
      const currInput = this.$el.querySelector(".v-select__slot  input");
      const enteredVal = currInput.value && currInput.value.trim();

      this.openNoteTopicForm({
        data: {
          name: enteredVal,
        },
      });
    },
    handleChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    handleKeyDown(e) {
      this.$emit("keydown", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    handleBlur(e) {
      this.$emit("blur", e);
    },
    openInput() {
      this.$refs.select.openInput();
    },
    blurInput() {
      this.$refs.select.blurInput();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedVal = newVal;
      },
      immediate: true,
    },
  },
};
</script>
