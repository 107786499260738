import {
  checkIfTaskIsRecurring,
  isTaskRecurring,
  getDateAndTimeFromDueDate,
  checkIfTaskIsUnscheduled,
  getReminderTasksLinkedToTaskId,
  checkIfTaskIsContinued,
  areTaskRecurrenceRulesValid,
  checkIfTaskIsRepeatIn,
  fillTaskData,
  convertTaskKeyForGoogleCal,
  createNextRepeatTask,
  createDateTimeFromDue,
  getOrderedTasksByProject,
  createVirtualTasksFromRecurringTasks,
  fillRecurrenceOptsInTaskData,
  processTask,
  createReminderTasks,
  checkIfTaskHasReminder,
  createTaskDataForGoogleCal,
  addRecurringTasksInfoInMap,
  replaceRecurringTasksInfoInMap,
  removeTasksFromRecurringTasksMap,
  getRecurrenceIdByDateFromRecurrences,
  createRecurringDatesFromTaskRules,
  getRecurringTasks,
  duplicateTask,
  getTasksByCustomOrderAndOrderIndex,
  checkTaskIsAllDay,
  parseRuleDataFromTaskRecurrenceRules,
  createTaskDueDate,
  excludeDatesInTaskRules,
  filterRecurringTasks,
  isTaskNotCompleted,
  isTaskAfterDateFilterCreator,
  checkIfTaskIsEdited,
  createAdditionalDates,
} from "@/helpers/tasks";
import { isNumeric } from "@/utils/number";
import {
  formatDate,
  getComponentsFromDate,
  getCurrDate,
  isValidDate,
  subDate,
} from "@/helpers/dates";
import { cloneDeep, isEmpty } from "lodash";
import { mapGetters, mapActions } from "vuex";
import { DAY_FORMAT } from "@/variables/dates";
import isUndefinedVal from "@/utils/isUndefinedVal";
import {
  changeCatOfProjectResources,
  getAllMentionedResourceIdsFromText,
  getProjectResourcesFromText,
} from "@/helpers/resources";
import GoogleCalHelpers from "@/helpers/googleCalHelpers";
import { PROJECT_ACTIONS, TASK_ACTIONS } from "@/helpers/actionHistory";
import { addOrUpdateOrRemoveTasksInGoogleCal } from "./googleCalHelpersMixins";
import googleCalHelpersMixin from "./googleCalHelpersMixins";
import userDetailsMixin from "./userDetailsMixin";
import tasksHelpersMixin from "./tasksHelpersMixin";
import createUniqueId from "@/utils/createUniqueId";
import removeProps from "@/utils/removeProps";
import { endOfDay } from "date-fns";
import { createRRuleSet } from "@/helpers/rrulesHelpers";
import { frequencyOptsMap } from "@/data/frequencyOpts";
import isEqual from "lodash/isEqual";
import { getPathsFromEvent } from "@/utils/events";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
const mixin = {
  mixins: [googleCalHelpersMixin, userDetailsMixin, tasksHelpersMixin],
  methods: {
    ...mapActions("toast", {
      showToast: "showToast",
    }),
    ...mapActions("editForm", ["openEditTaskConfirmBox"]),
    handleReminderDaysVal(
      val,
      updateTaskDataKey = "taskCopy",
      currTaskDataKey = "taskData"
    ) {
      if (isNumeric(val)) {
        const parsedVal = parseInt(val, 10);
        const finalVal = parsedVal > 0 ? parsedVal : "0";
        this[updateTaskDataKey].reminderDays = finalVal;
        if (finalVal === "0") {
          this.currPausedReminderDays = !isEmpty(
            this[updateTaskDataKey].pausedReminderDays
          )
            ? [...this[updateTaskDataKey].pausedReminderDays]
            : undefined;
          this[updateTaskDataKey].pausedReminderDays = [];
          this[updateTaskDataKey].isContingent =
            this[currTaskDataKey].isContingent || false;
        } else {
          if (!isEmpty(this.currPausedReminderDays)) {
            this[updateTaskDataKey].pausedReminderDays = [
              ...this.currPausedReminderDays,
            ];
          }

          this[updateTaskDataKey].isContingent = false;
        }
      } else {
        this[updateTaskDataKey].reminderDays = "0";
        this[updateTaskDataKey].isContingent =
          this[updateTaskDataKey].isContingent || false;
      }
    },

    getTaskId(taskData) {
      let taskId = taskData.key;

      if (isTaskRecurring(taskData)) {
        if (taskData.isVirtual && this.rawTasksMap[taskData.linkedTo]) {
          taskId = taskData.linkedTo;
        } else if (taskData.linkedTo && this.rawTasksMap[taskData.linkedTo]) {
          taskId = taskData.linkedTo;
        }
      }
      return taskId;
    },

    async toggleContingent(
      updateTaskDataKey = "taskCopy",
      currTaskDataKey = "taskData"
    ) {
      let recreateRecurrenceRules = true;
      this[updateTaskDataKey].isContingent =
        !this[updateTaskDataKey].isContingent;

      const isUpdatedTaskDataKeyChanged = updateTaskDataKey === "newTaskData";
      if (this[updateTaskDataKey].isContingent) {
        this.prevDate = isUpdatedTaskDataKeyChanged
          ? this[updateTaskDataKey].dueAsDate
          : this.dateCopy;
        this.prevReminderDays = this[updateTaskDataKey].reminderDays;
        this.prevPausedReminderDays =
          this[updateTaskDataKey].pausedReminderDays;
        this.prevRepeatVals = cloneDeep({
          frequency: this[updateTaskDataKey].frequency,
          repeatMonthDate: this[updateTaskDataKey].repeatMonthDate,
          recurrenceCount: this[updateTaskDataKey].recurrenceCount,
          recurrenceRepeatDay: this[updateTaskDataKey].recurrenceRepeatDay,
          isRecurring: this[updateTaskDataKey].isRecurring,
          repeatInfo: this[updateTaskDataKey].repeatInfo,
          repeatMode: this[updateTaskDataKey].repeatMode,
        });

        if (!isUpdatedTaskDataKeyChanged) {
          this.resetModes();
          this.dateCopy = null;
        } else {
          this[updateTaskDataKey].dueAsDate = null;
        }

        this[updateTaskDataKey].repeatMode = "never";
        this[updateTaskDataKey].reminderDays = 0;
        this[updateTaskDataKey].pausedReminderDays = [];

        this[updateTaskDataKey].frequency = "";
        this[updateTaskDataKey].recurrence = null;

        this[updateTaskDataKey].coRelationalId = "";

        this[updateTaskDataKey].repeatInfo = {};
        this[updateTaskDataKey].isRecurring = false;
        this.freqError = "";
        this.startDateError = "";
        this.currActiveMode = "";
      } else {
        let dateToSet = this[currTaskDataKey].dueAsDate;
        if (isValidDate(this.prevDate)) {
          dateToSet = this.prevDate;
        }

        if (!isEmpty(this.prevRepeatVals)) {
          this[updateTaskDataKey] = {
            ...this[updateTaskDataKey],
            ...this.prevRepeatVals,
          };
        }

        if (this.prevReminderDays !== undefined) {
          this[updateTaskDataKey].reminderDays = this.prevReminderDays;
        }

        if (this.prevPausedReminderDays !== undefined) {
          this[updateTaskDataKey].pausedReminderDays =
            this.prevPausedReminderDays;
        }

        if (!isUpdatedTaskDataKeyChanged) {
          this.dateCopy = dateToSet;
        } else {
          this[updateTaskDataKey].dueAsDate = dateToSet;
        }
      }

      if (recreateRecurrenceRules) {
        this.$nextTick(this.createRecurringDates);
      }
    },

    changeTaskDate(
      date,
      updateTaskDataKey = "taskCopy",
      currTaskDataKey = "taskData"
    ) {
      const isDateValid = isValidDate(date);
      const isCurrTaskDateValid =
        this[currTaskDataKey].dueAsDate &&
        isValidDate(this[currTaskDataKey].dueAsDate);

      const isNewTaskDateValid = date && isDateValid;

      if (
        !isCurrTaskDateValid &&
        isNewTaskDateValid &&
        !this[updateTaskDataKey].flag
      ) {
        this[updateTaskDataKey].flag = true;
      }

      if (isDateValid) {
        this[updateTaskDataKey].isContingent = false;
      }

      if (updateTaskDataKey === "taskCopy") {
        this.dateCopy = date;
      } else {
        this[updateTaskDataKey].dueAsDate = date;
      }

      if (this[updateTaskDataKey].isRecurring && isDateValid) {
        this.startDateError = "";
        this[updateTaskDataKey].recurrenceRepeatDay =
          getComponentsFromDate(date).weekDay;
        if (this[updateTaskDataKey].frequency === "monthly") {
          this[updateTaskDataKey].repeatMonthDate = `${formatDate(
            date,
            DAY_FORMAT
          )}_monthly`;
        }
      }

      this.$nextTick(this.createRecurringDates);
    },

    handleDateInputBlur(updateTaskDataKey = "taskCopy") {
      let dateErrorMsg = "";
      if (this.isTaskInRepeatEveryMode || this.isTaskInRepeatInMode) {
        const dateToCheck =
          updateTaskDataKey === "newTaskData"
            ? this[updateTaskDataKey].dueAsDate
            : this.dateCopy;
        if (!isValidDate(dateToCheck)) {
          dateErrorMsg = "Date is required!";
        }
      }

      this.startDateError = dateErrorMsg;
    },

    handleRepeatTypeFocus() {
      this.$refs.repeatSelector.openInput();
    },
    handleRepeatInternalSelectorFocus() {
      if (this.isTaskInRepeatEveryMode) {
        this.$refs.repeatEveryIntervalSelector.openInput();
      } else if (this.isTaskInRepeatInMode) {
        this.$refs.repeatInIntervalInput.focus();
      }
    },
    handleRepeatFrequencySelector() {
      if (this.isTaskInRepeatEveryMode) {
        this.$refs.repeatEveryIntervalSelector.blurInput();
        this.$refs.repeatEveryFrequencySelector.openInput();
      } else if (this.isTaskInRepeatInMode) {
        this.$refs.repeatInFrequencySelector.openInput();
      }
    },
    isValidRemindVal(val) {
      return val > 0;
    },
    async handleTabFocus(e, nextFocus, previousFocus) {
      const previousKeys = [
        "flag-toggler",
        "repeat-interval-selector",
        "repeat-type-selector",
        "task-priority",
        "task-type-selector",
        "title",
        "objective",
        "reminder-input",
        "date-picker",
      ];
      const keyCode = e.which ? e.which : e.keyCode;

      const paths = getPathsFromEvent(e);

      const shiftKey = e.shiftKey;
      let jumpToPriority = false;
      let jumpToDatePicker = false;
      let jumpToRepeatInfo = false;
      if (keyCode !== 9) return;

      e.stopPropagation();

      const datePickerParentFound = findParentFromPathsByClassName(paths, [
        "date-picker-wrapper",
      ]);

      const prioritySelectorFound = findParentFromPathsByClassName(paths, [
        "task-priority-selector",
      ]);
      const repeatIconEl = findParentFromPathsByClassName(paths, [
        "repeat-info-icon",
      ]);

      const fromFrequencySelector = findParentFromPathsByClassName(paths, [
        "repeat-every-frequency",
        "repeat-in-frequency",
      ]);

      if (shiftKey && previousKeys.includes(previousFocus)) {
        e.preventDefault();
      }

      if (
        shiftKey &&
        datePickerParentFound &&
        this.isInContinueMode &&
        this.isTaskNotRepeatInOrWithProject
      ) {
        e.preventDefault();
        jumpToPriority = true;
      } else if (
        this.isInContinueMode &&
        this.isTaskNotRepeatInOrWithProject &&
        prioritySelectorFound
      ) {
        e.preventDefault();
        jumpToDatePicker = true;
      } else if (this.taskRepeatInfo.showTxt && repeatIconEl && !shiftKey) {
        e.preventDefault();
        jumpToDatePicker = true;
      }

      if (shiftKey && datePickerParentFound && this.taskRepeatInfo.showTxt) {
        e.preventDefault();
        jumpToRepeatInfo = true;
      }

      setTimeout(() => {
        if (shiftKey) {
          if (jumpToRepeatInfo) {
            const iconEl = this.$el.querySelector(".repeat-info-icon");
            iconEl?.focus();
          } else if (jumpToPriority) {
            const el =
              this.$refs.taskPrioritySelector.$el.querySelector("input");
            el?.focus();
            this.$refs.taskPrioritySelector.$refs.select.openInput();
          } else {
            if (previousFocus === "flag-toggler") {
              this.blurInput();
              const btn = this.$el.querySelector(".flag-toggle-btn");
              btn?.focus();
            }

            if (previousFocus === "reminder-input") {
              const inputEl = this.$el.querySelector(".reminder-input input");
              inputEl?.focus();
            }

            if (previousFocus === "repeat-interval-selector") {
              if (this.isTaskInRepeatEveryMode) {
                this.$refs.repeatEveryFrequencySelector.blurInput();
                this.$refs.repeatEveryIntervalSelector.openInput();
              } else if (this.isTaskInRepeatInMode) {
                this.$refs.repeatInFrequencySelector.blurInput();
                this.$refs.repeatInIntervalInput.focus();
                this.$refs.repeatInIntervalInput.$el
                  .querySelector("input")
                  ?.select();
              }
            }

            if (previousFocus === "repeat-type-selector") {
              if (this.isTaskInRepeatEveryMode) {
                this.$refs.repeatEveryIntervalSelector.blurInput();
              } else if (this.isTaskInRepeatInMode) {
                this.$refs.repeatInIntervalInput.blur();
              }
              const inputEl =
                this.$refs.repeatSelector.$el.querySelector("input");
              inputEl?.focus();
              this.$refs.repeatSelector.openInput();
            }

            if (previousFocus === "task-priority") {
              this.$refs.repeatSelector.$refs.select.blur();
              setTimeout(() => {
                const el =
                  this.$refs.taskPrioritySelector.$el.querySelector("input");
                el?.focus();
                this.$refs.taskPrioritySelector.$refs.select.openInput();
              }, 0);
            }

            if (previousFocus === "task-type-selector") {
              this.$refs.repeatSelector.$refs.select.blur();
              setTimeout(() => {
                const el =
                  this.$refs.taskTypeSelector.$el.querySelector("input");
                el?.focus();
                this.$refs.taskTypeSelector.$refs.select.openInput();
              }, 0);
            }

            if (previousFocus === "title") {
              this.$refs.repeatSelector.$refs.select.blur();
              this.focusOnTitleInput(true);
            }

            if (previousFocus === "objective") {
              this.$refs.titleInput.blurInput();
              this.$refs.objectiveInput.openInput();
            }

            if (previousFocus === "date-picker") {
              this.$refs.titleInput.blurInput();

              const dateInput = this.$refs.datePicker.$el.querySelector(
                ".e-datetimepicker.e-input"
              );

              dateInput?.focus();
              dateInput?.select();
            }
          }
        } else {
          if (nextFocus === "title") {
            this.focusOnTitleInput(true);
          }

          if (nextFocus === "task-type-selector") {
            const el = this.$refs.taskTypeSelector.$el.querySelector("input");
            el?.focus();
            el?.click();
          }
          if (nextFocus === "task-priority") {
            const el =
              this.$refs.taskPrioritySelector.$el.querySelector("input");
            el?.focus();

            el?.click();
          }

          if (!jumpToDatePicker && nextFocus === "task-repeat-selector") {
            const inputEl =
              this.$refs.repeatSelector.$el.querySelector("input");
            inputEl?.focus();
            this.$refs.repeatSelector.openInput();
          }

          if (nextFocus === "repeat-interval-selector") {
            this.$refs.repeatSelector.blurInput();
            if (this.isTaskInRepeatEveryMode) {
              this.$refs.repeatEveryIntervalSelector.openInput();
            } else if (this.isTaskInRepeatInMode) {
              this.$refs.repeatInIntervalInput.focus();
            } else {
              const inputEl = this.$el
                .querySelector(".advance-date-input-wrapper")
                .querySelector("input");
              inputEl?.select();
              inputEl?.focus();
            }
          }

          if (nextFocus === "repeat-frequency-selector") {
            if (this.isTaskInRepeatEveryMode) {
              this.$refs.repeatEveryIntervalSelector.blurInput();
              this.$refs.repeatEveryFrequencySelector.openInput();
            } else if (this.isTaskInRepeatInMode) {
              this.$refs.repeatInFrequencySelector.openInput();
            }
          }

          if (
            (!this.taskRepeatInfo.showTxt && nextFocus === "date-picker") ||
            jumpToDatePicker
          ) {
            if (!jumpToDatePicker) {
              // this.$refs.taskPrioritySelector.$refs.select.blurInput();
              if (this.isTaskInRepeatEveryMode) {
                this.$refs.repeatEveryFrequencySelector.blurInput();
              } else if (this.isTaskInRepeatInMode) {
                this.$refs.repeatInFrequencySelector.blurInput();
              }
            }

            const inputEl = this.$el
              .querySelector(".advance-date-input-wrapper")
              .querySelector("input");
            inputEl?.select();
            inputEl?.focus();
          }

          if (
            fromFrequencySelector &&
            this.taskRepeatInfo.showTxt &&
            !jumpToDatePicker
          ) {
            if (this.isTaskInRepeatEveryMode) {
              this.$refs.repeatEveryFrequencySelector.blurInput();
            } else if (this.isTaskInRepeatInMode) {
              this.$refs.repeatInFrequencySelector.blurInput();
            }
            const iconEl = this.$el.querySelector(".repeat-info-icon");
            iconEl?.focus();
          }

          // if (nextFocus === "contingent-toggler") {
          //   const btn = this.$el.querySelector(".contingent-toggle-btn");
          //   btn?.focus();
          // }
          if (nextFocus === "flag-toggler") {
            const btn = this.$el.querySelector(".flag-toggle-btn");
            btn?.focus();
          }

          if (nextFocus === "description") {
            this.focusOnDescrInput(false);
          }

          if (nextFocus === "repeat-info") {
            const iconEl = this.$el.querySelector(".repeat-info-icon");
            iconEl?.focus();
          }
        }
      }, 0);
    },
    showReminderErrorMsg(type = "reminder") {
      let msgToShow = "";
      if (type === "reminder") {
        msgToShow = "Please enter a valid value for reminder";
      }

      if (type === "date") {
        msgToShow = "Due Date is required for reminder";
      }

      if (msgToShow) {
        this.showToast(msgToShow);
      }
    },

    checkIfFieldsExistsForRecurring(taskData) {
      return !isEmpty(taskData) && isValidDate(taskData.dueAsDate);
    },

    checkIfTaskIsInRepeatInMode(taskData) {
      return !isEmpty(taskData) && taskData.repeatMode === "in";
    },
    showRequiredFieldsToast(mode = "repeatEvery") {
      let msgToShow = "";
      if (mode === "repeatEvery") {
        msgToShow = "Frequency and date are required for recurring task";
      }

      if (mode === "repeatIn") {
        msgToShow =
          "Frequency,Interval and date are required for repeated task";
      }

      if (msgToShow) {
        this.showToast(msgToShow);
      }
    },
    async processTaskDataForSave({
      currTaskData = {},
      updatedTaskData = {},
      updatedProjectData = {},
    }) {
      let timeToSet = "00:00"; // Default time
      let dateToSet = "none"; // Date defaults to 'none'
      let taskFlagged = currTaskData.flag;
      const googleCalUpdatesList = [];
      let taskDescription = updatedTaskData.description;
      const isNewTaskDateValid = isValidDate(updatedTaskData.dueAsDate);

      const isTaskUnscheduled =
        !currTaskData.isContingent &&
        !updatedTaskData.isContingent &&
        checkIfTaskIsUnscheduled(
          {
            ...updatedTaskData,
          },
          currTaskData
        );
      if (isNewTaskDateValid) {
        const res = getDateAndTimeFromDueDate(updatedTaskData.dueAsDate);
        timeToSet = res.time;
        dateToSet = res.due;
        taskFlagged = true;
      }
      let taskListUpdates = [];
      let reminderTasksToRemove = [];
      let updates = {};
      let editTaskList = [];
      let googleEventIdData = {};
      let taskProjectData = {};
      let taskProjectChanged = false;
      let taskDroppedInCustomOrderRes = {};
      let editProjectData = {};
      let taskRecurringData = {};
      let createdTaskUpdatedData = {};
      let areRecurrenceRulesValid = false;
      let taskDataForLocalSave = {};
      let repeatedTaskData = {};
      let addTaskActionData = {};
      let tasksToAddInGoogle = [];
      let newTaskPriority = updatedTaskData.priority || "";
      let userBatchActions = [];
      let actionType = "task";
      const allReminderTasksLinked = getReminderTasksLinkedToTaskId(
        this.processedTasks,
        currTaskData.key
      );

      if (!isEmpty(allReminderTasksLinked)) {
        reminderTasksToRemove.push(...allReminderTasksLinked);
      }
      const isTaskContnued = checkIfTaskIsContinued(currTaskData);

      const isRepeatedTaskCompleted = !isTaskContnued;
      //   !isTaskContnued && this.isCompleteRepeatInEnabled;
      const isTaskConvertedToRecurring =
        !checkIfTaskIsRecurring(currTaskData) &&
        areTaskRecurrenceRulesValid(updatedTaskData.recurrence);

      const isRepeatInTaskConvertedToRepeatEvery =
        !checkIfTaskIsRecurring(currTaskData) &&
        checkIfTaskIsRepeatIn(currTaskData) &&
        areTaskRecurrenceRulesValid(updatedTaskData.recurrence);
      let onlyProjectPriorityChanged = false;

      let toProject = updatedTaskData.project;
      const previousDueDate = currTaskData.dueAsDate;
      const editedTaskData = {
        project: currTaskData.project || "",
        time: "00:00",
        due: "none",
        completed: currTaskData.completed,
        created: currTaskData.created,
        description: currTaskData.description || "",
        flag: currTaskData.flag,
        key: currTaskData.key,
        modified: currTaskData.modified || "",
        order: currTaskData.order || 0,
        priority: currTaskData.priority || "",
        status: currTaskData.status || "",
        taskType: currTaskData.taskType || "",
        title: currTaskData.title,
        isContingent: currTaskData.isContingent || false,
        userPosition: currTaskData.userPosition || 0,
        repeatInfo: !isEmpty(currTaskData.repeatInfo)
          ? currTaskData.repeatInfo
          : {},
        objective: currTaskData.objective || "",
        reminderDays: currTaskData.reminderDays || null,
        pausedReminderDays: currTaskData.pausedReminderDays || [],
        positionChanged: currTaskData.positionChanged || false,
        resources: currTaskData.resources || [],
        isCustomPositioned: currTaskData.isCustomPositioned || false,
        customPosition: currTaskData.customPosition || 0,
        customPositionIndex: currTaskData.customPositionIndex || 0,
      };
      if (previousDueDate && isValidDate(previousDueDate)) {
        const res = getDateAndTimeFromDueDate(previousDueDate);
        editedTaskData.due = res.due;
        editedTaskData.time = res.time;
      }
      if (currTaskData.project && this.projects[currTaskData.project]) {
        if (toProject !== currTaskData.project) {
          taskProjectChanged = true;
          editedTaskData.changedProject = toProject;
        } else {
          taskProjectData = this.projects[currTaskData.project];
        }
      } else if (!currTaskData.project && updatedTaskData.project) {
        taskProjectChanged = true;
      }
      if (
        taskProjectChanged &&
        updatedTaskData.project &&
        this.projects[updatedTaskData.project]
      ) {
        taskProjectData = this.projects[updatedTaskData.project];
      }
      if (!isEmpty(taskProjectData)) {
        let newProjectPriority;
        if (
          !taskProjectChanged &&
          taskProjectData.priority !== updatedProjectData.priority
        ) {
          newProjectPriority = updatedProjectData.priority;
          onlyProjectPriorityChanged = true;
        }

        if (
          taskProjectChanged &&
          taskProjectData.priority !== updatedProjectData.priority
        ) {
          newProjectPriority = updatedProjectData.priority;
        }
        if (newProjectPriority) {
          updates["/projects/" + taskProjectData.key + "/priority"] =
            newProjectPriority;
          updates[`/projects/${taskProjectData.key}/modified`] = getCurrDate();
          editProjectData = {
            key: taskProjectData.key,
            priority: taskProjectData.priority || "",
            changedProps: {
              priority: updatedProjectData?.priority,
            },
          };
        }
      }
      areRecurrenceRulesValid = isTaskConvertedToRecurring;

      if (!areRecurrenceRulesValid && isRepeatInTaskConvertedToRepeatEvery) {
        areRecurrenceRulesValid = true;
      }

      const changedProps = fillTaskData({
        completed: updatedTaskData.completed,
        created: updatedTaskData.created,
        description: taskDescription || "",
        due: dateToSet,
        flag: taskFlagged,
        key: updatedTaskData.key,
        modified: getCurrDate(),
        order: updatedTaskData.order || 0,
        priority: newTaskPriority,
        project: updatedTaskData.project || "",
        status: updatedTaskData.status,
        taskType: updatedTaskData.taskType || "",
        time: timeToSet,
        title: updatedTaskData.title || "",
        googleEventId: updatedTaskData.googleEventId,
        frequency: updatedTaskData.frequency,
        linkedTo: updatedTaskData.linkedTo,
        coRelationalId: updatedTaskData.coRelationalId,
        isContingent: updatedTaskData.isContingent || false,
        userPosition: updatedTaskData.userPosition || 0,
        repeatInfo: !isEmpty(updatedTaskData.repeatInfo)
          ? updatedTaskData.repeatInfo
          : {},
        objective: updatedTaskData.objective || "",
        reminderDays: updatedTaskData.reminderDays || null,
        pausedReminderDays: updatedTaskData.pausedReminderDays || [],
        positionChanged: updatedTaskData.positionChanged || false,
        resources:
          getAllMentionedResourceIdsFromText(updatedTaskData.title) || [],
        isCustomPositioned: updatedTaskData.isCustomPositioned || false,
        customPosition: updatedTaskData.customPosition || 0,
        customPositionIndex: updatedTaskData.customPositionIndex || 0,
      });

      changedProps.recurrence = areRecurrenceRulesValid
        ? updatedTaskData.recurrence
        : currTaskData.recurrence || [];

      if (this.isGoogleCalSyncEnabled) {
        if (!updatedTaskData.googleEventId) {
          googleEventIdData = {
            googleEventId: convertTaskKeyForGoogleCal(updatedTaskData.key),

            googleCalendarId: GoogleCalHelpers.getStoredCalId(),
          };
        }

        if (!isRepeatedTaskCompleted) {
          const recurrenceUpdates = {};
          if (areRecurrenceRulesValid) {
            recurrenceUpdates.recurrence = updatedTaskData.recurrence;
          }
          googleCalUpdatesList.push({
            key: updatedTaskData.key,
            updates: {
              ...updatedTaskData,
              description: taskDescription,
              dueAsDate: updatedTaskData.dueAsDate,
              ...recurrenceUpdates,
            },
            currData: {
              ...currTaskData,
            },
          });
        }
      }
      if (changedProps.completed) {
        changedProps.completedOn = getCurrDate("extended");
      }

      // if (isRepeatedTaskCompleted) {
      //   repeatedTaskData = createNextRepeatTask(
      //     {
      //       ...changedProps,

      //       dueAsDate: createDateTimeFromDue(
      //         currTaskData.due,
      //         currTaskData.time
      //       ),
      //     },
      //     {
      //       due: currTaskData.due,
      //       time: currTaskData.time,
      //     }
      //   );
      // }

      createdTaskUpdatedData = {
        ...changedProps,
        modified: getCurrDate(),
        ...googleEventIdData,
        ...taskRecurringData,
        key: updatedTaskData.key,
      };

      const isAreaChanged =
        createdTaskUpdatedData.project !== currTaskData.project;

      if (isAreaChanged) {
        const mentionedProjectResources = getProjectResourcesFromText(
          createdTaskUpdatedData.title
        );

        if (!isEmpty(mentionedProjectResources)) {
          changeCatOfProjectResources([
            {
              resources: mentionedProjectResources,
              catId: createdTaskUpdatedData.project,
              isTaskRef: true,
            },
          ]);
        }
      }

      if (!isTaskUnscheduled) {
        updates["/tasks/" + updatedTaskData.key] = {
          ...createdTaskUpdatedData,
        };
      }

      if (!isEmpty(repeatedTaskData)) {
        updates["/tasks/" + repeatedTaskData.key] = {
          ...repeatedTaskData,
        };
        addTaskActionData = { ...repeatedTaskData };

        tasksToAddInGoogle.push({
          key: repeatedTaskData.key,
          data: { ...createTaskDataForGoogleCal(repeatedTaskData) },
        });
      }
      editedTaskData.changedProps = changedProps;
      editedTaskData.key = updatedTaskData.key;
      editTaskList.push(editedTaskData);
      taskListUpdates.push({
        ...changedProps,
        key: updatedTaskData.key,
      });

      let actionToStore = TASK_ACTIONS.BATCH_EDIT;
      if (editTaskList && editTaskList.length === 1) {
        editTaskList = editTaskList[0];
        actionToStore = TASK_ACTIONS.EDIT;
      }

      if (isTaskUnscheduled) {
        const orderedTasksOfProject = getOrderedTasksByProject(
          this.processedTasks,
          createdTaskUpdatedData.project
        );

        taskDroppedInCustomOrderRes =
          this.createDataForTaskDroppedInCustomOrder({
            draggedTasks: [
              {
                ...currTaskData,
                updates: { ...changedProps },
                editedData: { ...editedTaskData },
              },
            ],
            dropRowData: orderedTasksOfProject[0] || {},
            isLastRow: false,
            groupByField: "CTICustomGroup",
            tasksList: orderedTasksOfProject,
          });

        googleCalUpdatesList.push(
          ...taskDroppedInCustomOrderRes.tasksToUpdateInGoogle
        );
      }

      const dbUpdates = {
        ...updates,
      };

      let dbUpdateConfig = {};
      // await DatabaseInterface.update(updates, this.userInfo.uid);

      if (
        !isEmpty(taskDroppedInCustomOrderRes.tasksToUpdateInDb) ||
        !isEmpty(taskDroppedInCustomOrderRes.tasksToAddInDb)
      ) {
        dbUpdateConfig = {
          tasksToUpdate: taskDroppedInCustomOrderRes.tasksToUpdateInDb,
          tasksToAdd: taskDroppedInCustomOrderRes.tasksToAddInDb,
        };
        // await addOrRemoveOrUpdateTasksInDb({

        // });
      }

      if (!isEmpty(googleCalUpdatesList)) {
        this.updateTaskDataInGoogleCal(googleCalUpdatesList);
      } else if (!isEmpty(tasksToAddInGoogle)) {
        addOrUpdateOrRemoveTasksInGoogleCal(
          {
            tasksToAdd: tasksToAddInGoogle,
          },
          false
        );
      }

      if (areRecurrenceRulesValid) {
        userBatchActions = {
          type: TASK_ACTIONS.NORMAL_TO_RECURRING,
          data: {
            key: createdTaskUpdatedData.key,
            frequency: "",
            coRelationalId: "",
            recurrence: [],
            restoreOldIdData: {
              key: createdTaskUpdatedData.key,
              restoreFrom: "raw",
            },
            changedProps: {
              frequency: createdTaskUpdatedData.frequency,
              recurrence: createdTaskUpdatedData.recurrence,
              coRelationalId: createdTaskUpdatedData.coRelationalId,
            },
          },
          on: "task",
        };

        if (isRepeatInTaskConvertedToRepeatEvery) {
          userBatchActions.data = {
            ...userBatchActions.data,
            repeatInfo: currTaskData.repeatInfo || {},
            reminderDays: currTaskData.reminderDays || null,
            pausedReminderDays: currTaskData.pausedReminderDays || [],
          };

          userBatchActions.data.changedProps = {
            ...userBatchActions.data.changedProps,
            reminderDays: createdTaskUpdatedData.reminderDays,
            pausedReminderDays: createdTaskUpdatedData.pausedReminderDays,
          };
        }

        if (!isEmpty(editProjectData)) {
          actionType = "all";
          userBatchActions = [
            {
              type: PROJECT_ACTIONS.EDIT,
              data: editProjectData,
              on: "project",
            },
            { ...userBatchActions },
          ];
        }
      } else {
        if (!isEmpty(addTaskActionData)) {
          userBatchActions = [
            {
              type: actionToStore,
              data: editTaskList,
            },
            {
              type: TASK_ACTIONS.ADD,
              data: addTaskActionData,
              on: "task",
            },
          ];

          userBatchActions = {
            type: TASK_ACTIONS.ACTIONS_BATCH,
            data: userBatchActions,
            on: "task",
          };

          if (!isEmpty(editProjectData)) {
            actionType = "all";
            userBatchActions = [
              {
                type: PROJECT_ACTIONS.EDIT,
                data: editProjectData,
                on: "project",
              },
              {
                ...userBatchActions,
              },
            ];
          }
        } else {
          userBatchActions = {
            type: actionToStore,
            data: editTaskList,
            on: "task",
          };

          if (!isEmpty(taskDroppedInCustomOrderRes.groupedEditedTaskData)) {
            userBatchActions = {
              data: taskDroppedInCustomOrderRes.groupedEditedTaskData,
              type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
              on: "task",
            };
          }

          if (!isEmpty(editProjectData)) {
            actionType = "all";
            userBatchActions = [
              {
                type: PROJECT_ACTIONS.EDIT,
                data: editProjectData,
                on: "project",
              },
              {
                ...userBatchActions,
              },
            ];
          }
        }
      }

      if (actionType !== "all") {
        userBatchActions = {
          ...userBatchActions,
          on: "task",
        };
      }
      // if (actionType === "all") {
      //   getUserActions().addBatchAction({
      //     data: userBatchActions,
      //     on: "all",
      //   });
      // } else {
      //   getUserActions().addTaskAction(userBatchActions);
      // }
      const rowUpdates = [];
      let rawTaskDataForLocalSave = {};
      let recurringTasksToAdd = [];
      let recurringDatesList = [];

      rawTaskDataForLocalSave = { ...taskListUpdates[0] };
      taskDataForLocalSave = { ...taskListUpdates[0] };

      if (!isEmpty(repeatedTaskData)) {
        rawTaskDataForLocalSave = { ...repeatedTaskData };
        taskDataForLocalSave = { ...repeatedTaskData };
      }

      if (areRecurrenceRulesValid) {
        const res = createVirtualTasksFromRecurringTasks(
          createdTaskUpdatedData
        );
        recurringTasksToAdd = res.tasks;
        recurringDatesList = res.datesList;
        taskDataForLocalSave = fillRecurrenceOptsInTaskData(
          {
            ...res.tasks[0],
          },
          rawTaskDataForLocalSave
        );
      }

      if (!isEmpty(taskDataForLocalSave)) {
        taskDataForLocalSave = processTask(taskDataForLocalSave);
      }

      taskListUpdates.forEach((tU) => {
        const { key, ...restTu } = tU;
        delete restTu.googleEventId;
        rowUpdates.push({
          key,
          updates: processTask({ ...restTu, key }),
        });
      });
      // Change;
      // if (rawTaskDataForLocalSave.project) {
      //   this.activeProject = cloneDeep(
      //     this.projects[rawTaskDataForLocalSave.project]
      //   );
      // }

      const localTasksListConfig = {
        tasksToUpdate: rowUpdates,
      };

      if (!isEmpty(repeatedTaskData)) {
        localTasksListConfig.tasksToAdd = [
          { ...processTask(repeatedTaskData) },
          ...createReminderTasks(repeatedTaskData),
        ];
      } else if (!isEmpty(recurringTasksToAdd)) {
        localTasksListConfig.tasksToAdd = [...recurringTasksToAdd];
        localTasksListConfig.tasksToRemove = [createdTaskUpdatedData];
        localTasksListConfig.addMethod = "push";
      } else if (checkIfTaskHasReminder(createdTaskUpdatedData)) {
        localTasksListConfig.tasksToAdd = [
          ...createReminderTasks(createdTaskUpdatedData),
        ];
        localTasksListConfig.addMethod = "push";
      }

      if (
        !areRecurrenceRulesValid &&
        !checkIfTaskHasReminder(createdTaskUpdatedData)
      ) {
        localTasksListConfig.verifyAdd = true;
      } else {
        localTasksListConfig.verifyAdd = false;
      }

      if (!isEmpty(reminderTasksToRemove)) {
        if (!isEmpty(localTasksListConfig.tasksToRemove)) {
          localTasksListConfig.tasksToRemove = [
            ...localTasksListConfig.tasksToRemove,
            ...reminderTasksToRemove,
          ];
        } else {
          localTasksListConfig.tasksToRemove = [...reminderTasksToRemove];
        }
      }

      if (isTaskUnscheduled) {
        if (!isEmpty(localTasksListConfig.tasksToAdd)) {
          localTasksListConfig.tasksToAdd.push(
            ...taskDroppedInCustomOrderRes.tasksToAddInLocaList
          );
        } else {
          localTasksListConfig.tasksToAdd = [
            ...taskDroppedInCustomOrderRes.tasksToAddInLocaList,
          ];
        }

        if (!isEmpty(localTasksListConfig.tasksToUpdate)) {
          localTasksListConfig.tasksToUpdate.push(
            ...taskDroppedInCustomOrderRes.tasksToUpdateInLocalList
          );
        } else {
          localTasksListConfig.tasksToUpdate = [
            ...taskDroppedInCustomOrderRes.tasksToUpdateInLocalList,
          ];
        }

        if (!isEmpty(localTasksListConfig.tasksToRemove)) {
          localTasksListConfig.tasksToRemove.push(
            ...taskDroppedInCustomOrderRes.tasksToRemoveFromLocalList
          );
        } else {
          localTasksListConfig.tasksToRemove = [
            ...taskDroppedInCustomOrderRes.tasksToRemoveFromLocalList,
          ];
        }
      }

      return {
        createdTaskData: createdTaskUpdatedData,
        localTasksListConfig,
        dbUpdateConfig,
        dbUpdates,
        onlyProjectPriorityChanged,
        recurringDatesList,
        userBatchActions,
        taskDataForLocalSave,
        isAreaChanged,
        userActionType: actionType,
      };
      // await addOrRemoveOrUpdateTasksInLocalTasksList({
      //   ...localTasksListConfig,
      // });

      // if (onlyProjectPriorityChanged) {
      //   EventEmitter.emit(REFRESH_TREE_EVENT, true, false);
      // }

      // this.storeRecurringTasksInInfoMap(
      //   createdTaskUpdatedData.key,
      //   recurringDatesList
      // );
      // this.updateTaskData(taskDataForLocalSave, isAreaChanged);
      // this.callActionAfterSubmitSuccess();
    },

    storeRecurringTasksInInfoMap(taskKey, tasksList) {
      if (!isEmpty(tasksList)) {
        addRecurringTasksInfoInMap(taskKey, tasksList);
      }
    },

    processRecurringTaskDataForConvert({
      currTaskData,
      updatedTaskData,
      type,
    }) {
      const coRelationalId = currTaskData.coRelationalId;
      const isVirtualTask = currTaskData.isVirtual;
      const isFirstTask = currTaskData.isFirstTask;
      const mainTaskId = currTaskData.linkedTo;
      const mainTaskData = this.rawTasksMap[mainTaskId];
      let removeAllTasks = false;
      let newTaskData = {};
      let dbRemoveList = [];
      let localUpdateList = [];
      let localRemoveList = [];
      let remindersToRemove = [];
      let remindersToAdd = [];
      let googleTasksToRemove = [];
      let googleTasksToAdd = [];
      let taskMoveUpdatedData = {};

      let movedTasksEditedData = {};
      const recurringTasks = getRecurringTasks(this.processedTasks, "list", {
        coRelationalId,
      });

      if (!isEmpty(mainTaskData)) {
        const mainTaskGoogleId = mainTaskData.googleEventId;
        const mainTaskId = mainTaskData.key;
        const mainTaskFrequency = mainTaskData.frequency;
        const mainTaskRecurrence = mainTaskData.recurrence;
        const coRelationalId = createUniqueId();
        const remindersLinkedToMain = getReminderTasksLinkedToTaskId(
          this.processedTasks,
          mainTaskId
        );

        if (!isEmpty(remindersLinkedToMain)) {
          remindersToRemove.push(...remindersLinkedToMain);
        }
        if (isFirstTask) {
          removeAllTasks = true;
        } else if (isVirtualTask) {
          const mainTaskRecurrences = createRecurringDatesFromTaskRules({
            ...mainTaskData,
            dueAsDate: createDateTimeFromDue(
              mainTaskData.due,
              mainTaskData.time
            ),
          });

          if (isEmpty(mainTaskRecurrences)) {
            removeAllTasks = true;
          }
        }
        let dbTasksToUpdate = [];
        let googleTaskUpdateList = [];
        let taskDataForGoogle = {};

        let clonedData = cloneDeep({
          ...updatedTaskData,
          ...getDateAndTimeFromDueDate(updatedTaskData.dueAsDate),
        });

        clonedData = removeProps(clonedData, [
          "recurrence",
          "linkedTo",
          "coRelationalId",
          "frequency",
          "repeatMonthDate",
          "recurrenceCount",
          "recurrenceRepeatDay",
          "dueAsDate",
        ]);

        let totalTasksCount = Object.keys(this.rawTasksMap || {}).length;
        const createdDateAndTime = getDateAndTimeFromDueDate(
          updatedTaskData.dueAsDate
        );
        const isTaskUnscheduled = checkIfTaskIsUnscheduled(
          {
            ...clonedData,
            dueAsDate: updatedTaskData.dueAsDate,
          },
          currTaskData
        );

        clonedData.resources = getAllMentionedResourceIdsFromText(
          clonedData.title
        );
        let createdTaskData = duplicateTask(
          {
            ...clonedData,
            created: getCurrDate(),
            ...createdDateAndTime,
            userPosition: 0,
          },
          totalTasksCount
        );

        if (isTaskUnscheduled) {
          let dropTargetOrder = 0;
          let dropTargetUserPosition = 0;
          let dropPositionIndexToUse = 0,
            customPositionToUse = 0;

          let currRowsOnPosition = [];
          const orderedTasksByProject = getOrderedTasksByProject(
            this.processedTasks,
            createdTaskData.project
          );

          const dropTargetRowData = orderedTasksByProject[0];

          if (!isEmpty(dropTargetRowData)) {
            dropTargetOrder = dropTargetRowData.customPosition;
            dropTargetUserPosition = dropTargetRowData.customPositionIndex;
            customPositionToUse = dropTargetOrder;
            dropPositionIndexToUse = dropTargetUserPosition;

            currRowsOnPosition = getTasksByCustomOrderAndOrderIndex(
              orderedTasksByProject,
              dropTargetOrder,
              dropPositionIndexToUse,
              []
            );
          }

          const moveUpdateData = {
            isCustomPositioned: true,
            customPosition: customPositionToUse,
            customPositionIndex: dropPositionIndexToUse,
            ...getDateAndTimeFromDueDate(""),
          };
          createdTaskData = {
            ...createdTaskData,
            ...moveUpdateData,
            modified: getCurrDate(),
          };

          taskMoveUpdatedData = {
            currData: {
              isCustomPositioned: false,
              customPosition: 0,
              customPositionIndex: 0,
              flag: currTaskData.flag,
              ...getDateAndTimeFromDueDate(currTaskData.dueAsDate),
            },
            changedProps: {
              ...moveUpdateData,
            },
          };

          if (!isEmpty(currRowsOnPosition)) {
            const res = this.createDataForOtherTasksMovedInCustomOrder({
              tasksOnPosition: currRowsOnPosition,
              dropTargetOrder,
              startIndex: dropPositionIndexToUse + 1,
            });

            if (!isEmpty(res.editedData)) {
              movedTasksEditedData["normal"] = {
                editedTasks: [...res.editedData],
              };
            }

            dbTasksToUpdate.push(...res.tasksToUpdateInDb);
            localUpdateList.push(...res.tasksToUpdateInLocalList);
          }
        }

        if (
          this.isGoogleCalSyncEnabled &&
          isValidDate(updatedTaskData.dueAsDate)
        ) {
          createdTaskData.googleEventId = convertTaskKeyForGoogleCal(
            createdTaskData.key
          );

          createdTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();

          taskDataForGoogle = createTaskDataForGoogleCal({
            ...clonedData,
            dueAsDate: updatedTaskData.dueAsDate,
            taskKey: createdTaskData.key,
          });
        }

        if (!isTaskUnscheduled) {
          createdTaskData.flag = createdDateAndTime.due !== "none";
        }

        if (!removeAllTasks) {
          let endDate = updatedTaskData.dueAsDate;

          if (!isValidDate(endDate)) {
            endDate = currTaskData.dueAsDate;
          }

          if (checkTaskIsAllDay(endDate)) {
            endDate = subDate(endDate, { days: 1 });
          } else {
            endDate = subDate(endDate, { days: 1 });
            endDate = endOfDay(endDate);
          }

          const { rule, ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskData.recurrence,
              dueAsDate: createDateTimeFromDue(
                mainTaskData.due,
                mainTaskData.time
              ),
            });
          const parsedRuleOptions = rule.options;

          const excludedDates = parsedRuleSet.exdates();
          const rulesOptsToUse = {
            ...parsedRuleOptions,
            dtstart: createTaskDueDate(mainTaskData.due),
            until: endDate,
          };
          const newRuleSetCreatedForMainTask = createRRuleSet(rulesOptsToUse);

          excludeDatesInTaskRules(
            excludedDates,
            newRuleSetCreatedForMainTask,
            false
          );

          const rulesList = newRuleSetCreatedForMainTask.valueOf();

          const convertedRules = rulesList.slice(1);

          const mainTaskGoogleUpdate = {
            key: mainTaskGoogleId,
            updates: createTaskDataForGoogleCal(
              {
                recurrence: convertedRules,
                taskKey: mainTaskData.key,
              },
              {
                ...mainTaskData,
              }
            ),
          };

          const mainTaskDataDbUpdate = {
            key: mainTaskId,
            updates: {
              recurrence: convertedRules,
            },
          };

          const localTaskUpdatedData = {
            ...mainTaskData,
            ...mainTaskDataDbUpdate.updates,
          };
          localUpdateList.push({
            key: mainTaskId,
            updates: {
              ...localTaskUpdatedData,
            },
          });

          dbTasksToUpdate.push(mainTaskDataDbUpdate);
          googleTaskUpdateList.push(mainTaskGoogleUpdate);

          const linkedTasksWhichAreNotCompleted = filterRecurringTasks(
            recurringTasks,
            [
              isTaskNotCompleted,
              isTaskAfterDateFilterCreator(
                currTaskData.dueAsDate,
                "dueAsDate",
                "afterOrEqual"
              ),
            ]
          );

          const recurrences = createRecurringDatesFromTaskRules({
            ...mainTaskData,
            recurrence: convertedRules,
          });
          const firstRecurrence = recurrences[0];

          const firstTaskData = getRecurrenceIdByDateFromRecurrences(
            firstRecurrence,
            mainTaskData.key
          );

          if (!isEmpty(firstTaskData)) {
            const currTaskData = this.processedTasks.find(
              (t) => t.key === firstTaskData.key
            );
            if (!isEmpty(currTaskData)) {
              localUpdateList.push({
                key: currTaskData.key,
                updates: {
                  ...currTaskData,
                  isCalendarOnly: false,
                },
              });
            }
          }

          linkedTasksWhichAreNotCompleted.forEach((t) => {
            const linkedReminders = getReminderTasksLinkedToTaskId(
              this.processedTasks,
              t.key
            );

            if (!isEmpty(linkedReminders)) {
              remindersToRemove.push(...linkedReminders);
            }
          });

          dbRemoveList.push(...linkedTasksWhichAreNotCompleted);
          localRemoveList.push(...linkedTasksWhichAreNotCompleted);
          googleTasksToRemove.push(...linkedTasksWhichAreNotCompleted);
          // Remove single task
        } else {
          recurringTasks.forEach((childT) => {
            const isVirtualTask = childT.isVirtual;
            if (!isVirtualTask) {
              googleTasksToRemove.push(childT);
            }

            const reminderTasks = getReminderTasksLinkedToTaskId(
              this.processedTasks,
              childT.key
            );

            if (!isEmpty(reminderTasks)) {
              remindersToRemove.push(...reminderTasks);
            }
            localRemoveList.push(childT);
            dbRemoveList.push(childT);
          });
          googleTasksToRemove.push(mainTaskData);
          dbRemoveList.push(mainTaskData);
          // Remove All Tasks
        }

        newTaskData = { ...createdTaskData };

        const isAreaChanged = newTaskData.project !== mainTaskData.project;

        if (checkIfTaskHasReminder(newTaskData) && !newTaskData.completed) {
          remindersToAdd.push(...createReminderTasks(newTaskData));
        }

        let userActionData = {};
        let googleCalUpdateConfig = {};
        let dbListUpdateConfig = {};
        if (type === "repeat-in") {
          userActionData = {
            type: TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN,
            data: {
              key: newTaskData.key,
              frequency: mainTaskFrequency,
              recurrence: mainTaskRecurrence,
              coRelationalId,
              changedProps: {
                repeatInfo: newTaskData.repeatInfo,
                frequency: "",
                coRelationalId: "",
                recurrence: [],
              },
            },
            on: "task",
          };
        } else if (type === "normal") {
          userActionData = {
            type: TASK_ACTIONS.REPEAT_EVERY_TO_NORMAL,
            data: {
              key: newTaskData.key,
              frequency: mainTaskFrequency,
              recurrence: mainTaskRecurrence,
              coRelationalId,
              ...taskMoveUpdatedData.currData,
              changedProps: {
                frequency: "",
                coRelationalId: "",
                recurrence: [],
                ...taskMoveUpdatedData.changedProps,
              },
            },
            on: "task",
          };
        }

        if (!isEmpty(movedTasksEditedData)) {
          userActionData = {
            type: TASK_ACTIONS.ACTIONS_BATCH,
            data: [
              {
                type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
                data: {
                  ...movedTasksEditedData,
                },
                on: "task",
              },
              {
                ...userActionData,
              },
            ],
            on: "task",
          };
        }

        // if (!isEmpty(userActionData)) {
        //   getUserActions().addTaskAction(userActionData);
        // }

        googleTasksToAdd.push({
          key: createdTaskData.googleEventId,
          data: taskDataForGoogle,
        });

        dbListUpdateConfig = {
          tasksToUpdate: dbTasksToUpdate,
          tasksToRemove: dbRemoveList,
          tasksToAdd: [newTaskData],
        };
        // await addOrRemoveOrUpdateTasksInDb(
        //   {
        //     tasksToUpdate: dbTasksToUpdate,
        //     tasksToRemove: dbRemoveList,
        //     tasksToAdd: [newTaskData],
        //   },
        //   undefined,
        //   this.userInfo.uid
        // );

        if (isAreaChanged) {
          const mentionedProjectResources = getProjectResourcesFromText(
            newTaskData.title
          );

          if (!isEmpty(mentionedProjectResources)) {
            changeCatOfProjectResources([
              {
                resources: mentionedProjectResources,
                catId: newTaskData.project,
                isTaskRef: true,
              },
            ]);
          }
        }

        if (this.isGoogleCalSyncEnabled) {
          googleCalUpdateConfig = {
            tasksToUpdate: googleTaskUpdateList,
            tasksToAdd: googleTasksToAdd,
            tasksToRemove: [...googleTasksToRemove],
          };
          // addOrUpdateOrRemoveTasksInGoogleCal(
          //   {

          //   },
          //   false
          // );
        }

        const localTaskListConfig = {
          tasksToUpdate: localUpdateList,
          tasksToAdd: [newTaskData, ...remindersToAdd],
          tasksToRemove: [...localRemoveList, ...remindersToRemove],
          verifyAdd: isEmpty(remindersToAdd),
          addMethod: !isEmpty(remindersToAdd) ? "push" : "unshift",
        };
        // addOrRemoveOrUpdateTasksInLocalTasksList({
        //   tasksToUpdate: localUpdateList,
        //   tasksToAdd: [newTaskData, ...remindersToAdd],
        //   tasksToRemove: [...localRemoveList, ...remindersToRemove],
        //   verifyAdd: isEmpty(remindersToAdd),
        //   addMethod: !isEmpty(remindersToAdd) ? "push" : "unshift",
        // });

        if (!removeAllTasks) {
          removeTasksFromRecurringTasksMap({
            mainTaskId: mainTaskData.key,
            tasksIds: [currTaskData.key],
          });
        } else {
          replaceRecurringTasksInfoInMap(mainTaskData.key, []);
        }

        return {
          localTaskListConfig,
          removeAllTasks,
          googleCalUpdateConfig,
          userActionData,
          dbListUpdateConfig,
          newTaskData,
          taskDataForLocalSave: processTask(cloneDeep(newTaskData)),
        };
        // this.updateTaskData(processTask(newTaskData), isAreaChanged);
        // this.callActionAfterSubmitSuccess();
      }
    },
    checkIfActiveProjectChanged({
      currProjectData = {},
      updatedProjectData = {},
    }) {
      const keysToCheck = ["title", "priority", "subcategory", "category"];

      return keysToCheck.some((k) => {
        return currProjectData[k] !== updatedProjectData[k];
      });
    },
    checkIfTaskOrProjectDataHasChanged({
      currTaskData = {},
      updatedTaskData = {},
      currProjectData = {},
      updatedProjectData = {},
      taskPropCheckList = [],
    }) {
      let isDataChanged = false;
      if (!isEmpty(updatedProjectData)) {
        isDataChanged = this.checkIfActiveProjectChanged({
          currProjectData,
          updatedProjectData,
        });
      }

      if (
        !isDataChanged &&
        !isEmpty(currTaskData.key) &&
        currTaskData.key === updatedTaskData.key
      ) {
        isDataChanged = checkIfTaskIsEdited(
          {
            ...updatedTaskData,
            dueAsDate: isValidDate(updatedTaskData.dueAsDate)
              ? updatedTaskData.dueAsDate
              : null,
            reminderDays:
              !isUndefinedVal(updatedTaskData.reminderDays) &&
              updatedTaskData.reminderDays > 0
                ? updatedTaskData.reminderDays?.toString()
                : null,
            pausedReminderDays: updatedTaskData.pausedReminderDays || [],
          },
          {
            ...currTaskData,
            dueAsDate: isValidDate(currTaskData.dueAsDate)
              ? currTaskData.dueAsDate
              : null,
            isContingent:
              typeof currTaskData.isContingent === "boolean"
                ? currTaskData.isContingent &&
                  !currTaskData.completed &&
                  currTaskData.due === "none"
                : false,
            objective: currTaskData.objective || "",
            reminderDays:
              !isUndefinedVal(currTaskData.reminderDays) &&
              currTaskData.reminderDays > 0
                ? currTaskData.reminderDays?.toString()
                : null,
            pausedReminderDays: currTaskData.pausedReminderDays || [],
          },
          taskPropCheckList
        );

        if (!isDataChanged) {
          const tasksRulesAreValid =
            currTaskData.recurrenceRepeatDay !== undefined;
          const newTaskRulesAreValid = areTaskRecurrenceRulesValid(
            updatedTaskData.recurrence
          );
          if (tasksRulesAreValid && !updatedTaskData.isRecurring) {
            isDataChanged = true;
          } else if (
            !checkIfTaskIsRecurring(currTaskData) &&
            newTaskRulesAreValid
          ) {
            isDataChanged = true;
          } else if (tasksRulesAreValid && newTaskRulesAreValid) {
            const repeatKeysToCheck = ["frequency", "recurrenceCount"];

            isDataChanged = repeatKeysToCheck.some(
              (k) => !isEqual(currTaskData[k], updatedTaskData[k])
            );
            // const oldRules = createAdditionalDates({
            //   frequency: frequencyOptsMap[currTaskData.frequency],
            //   startDateTime: currTaskData.dueAsDate,
            //   repeatDay: currTaskData.recurrenceRepeatDay,
            //   repeatCount: currTaskData.recurrenceCount,
            // }).rules;

            // if (!isEqual(oldRules, updatedTaskData.recurrence)) {
            //   isDataChanged = true;
            // }
          }
        }

        if (!isDataChanged) {
          const oldTaskRepeatInfo = currTaskData.repeatInfo;
          const currTaskRepeatInfo = updatedTaskData.repeatInfo;
          if (
            isEmpty(oldTaskRepeatInfo) &&
            !isEmpty(updatedTaskData.repeatInfo)
          ) {
            isDataChanged = true;
          }
          if (
            !isDataChanged &&
            !isEmpty(oldTaskRepeatInfo) &&
            isEmpty(currTaskRepeatInfo)
          ) {
            isDataChanged = true;
          }

          if (
            !isDataChanged &&
            !isEmpty(oldTaskRepeatInfo) &&
            !isEmpty(currTaskRepeatInfo) &&
            !isEqual(oldTaskRepeatInfo, currTaskRepeatInfo)
          ) {
            isDataChanged = true;
          }
        }

        // if (!isDataChanged && !isEmpty(this.activeProject)) {
        //   const currProjectData = this.projects[this.activeProject.key];
        //   const newUpdatedProjectData = this.activeProject;
        //   isDataChanged = !isEqual(currProjectData, newUpdatedProjectData);
        // }
      }
      return isDataChanged;
    },
  },
  computed: {
    ...mapGetters("task", {
      rawTasksMap: "rawTasksMap",
      processedTasks: "tasks",
      projects: "projects",
    }),

    ...mapGetters(["isGoogleCalSyncEnabled"]),
  },
};

export default mixin;
