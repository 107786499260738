<template>
  <span :class="nodeClasses">
    <span class="category-container" ref="categoryInfoContainer">
      <span>
        {{ data.title }}
      </span>
      <EditTreeNodeBtn
        :itemCount="tasksCount"
        menuId="treeview-menu"
        preventEvent
        :nodeId="data.key"
      />
    </span>
  </span>
</template>
<style scoped>
.category-container {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  /* position: relative; */
  align-items: center;
  min-height: 25px;
  padding-right: 10px;
}

.project .category-container {
  padding-right: 24px;
}

.category-container:hover >>> .edit-btn-wrapper {
  display: inline-block;
}

span.project-power::before {
  background-color: #f44336;
}

span.project-purpose::before {
  background-color: #2196f3;
}

span.project-impact::before {
  background-color: #4caf50;
}

span.project-priority::before {
  background-color: var(--primary-muted);
}

.project:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #747474;
}
</style>
<script>
import { mapGetters } from "vuex";
import { catLabels, typeOfCateNodes } from "@/variables/categories";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import categoriesHelpersMixin from "@/mixins/categoriesHelpersMixin";
import EditTreeNodeBtn from "@/components/EditTreeNodeBtn";
import isEmpty from "lodash/isEmpty";
export default {
  mixins: [userDetailsMixin, categoriesHelpersMixin],
  components: {
    EditTreeNodeBtn,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    handleAddItem(e) {
      e.stopPropagation();
      e.preventDefault();
      const contextEl = document.querySelector(".e-contextmenu-wrapper")
        .childNodes[0];

      if (contextEl?.id === "#treeview-menu") {
        const contextInstance = contextEl?.ej2_instances[0];

        const positions = this.$refs.editBtn.$el.getBoundingClientRect();

        if (contextInstance) {
          contextInstance.open(
            positions.top,
            positions.left,
            this.$refs.editBtn.$el
          );
        }
      }
    },
    getProjectPriorityClass(projectData) {
      let priorityClass;
      switch (projectData.priority) {
        case "power":
          priorityClass = "project-power";
          break;
        case "impact":
          priorityClass = "project-impact";
          break;
        case "purpose":
          priorityClass = "project-purpose";
          break;
        case "priority":
          priorityClass = "project-priority";
          break;
      }

      return priorityClass;
    },
  },
  computed: {
    tasksCount() {
      let count;

      if (this.makeAllTasksCountVisible) {
        count = this.data.allTasksCount;
      } else {
        count = this.data.tasksCount;
      }
      return count || null;
    },
    makeAllTasksCountVisible() {
      return this.showClearedTasks;
    },
    addText() {
      return `Add ${catLabels[this.data.type]}`;
    },
    nodeClasses() {
      const nodeType = this.data.type?.split(" ")[0];
      let priorityClass;

      if (nodeType && !typeOfCateNodes.includes(nodeType)) {
        priorityClass = this.getProjectPriorityClass(this.data);
      }
      return {
        [nodeType]: !!nodeType,
        [priorityClass]: !isEmpty(priorityClass),
      };
    },

    ...mapGetters("task", [
      "projectsTree",
      "projects",
      "categories",
      "isGlobalViewEnabled",
      "showClearedTasks",
    ]),
  },
};
</script>
