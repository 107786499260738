function hasParent(el, options) {
  let { tagName, className, id } = options;
  if (className && !Array.isArray(className)) className = [className];
  let parent = false;

  while (el && !parent) {
    if (el === document) {
      el = null;
    } else if (tagName && el.tagName === tagName.toUpperCase()) {
      parent = true;
    } else if (
      className &&
      [...el.classList].some((v) => className.includes(v))
    ) {
      parent = true;
    } else if (id && el.getAttribute("id") === id) {
      parent = true;
    } else if (el) {
      el = el.parentNode;
    } else {
      el = null;
    }
  }
  return parent;
}

export const findParentFromPathsByClassName = (
  paths,
  classNames,
  returnType = "boolean",
  attributesList = []
) => {
  if (!Array.isArray(classNames)) classNames = [classNames];
  let foundParent = false;
  let parentEl = {};
  for (const path of paths) {
    if (
      path &&
      path.classList &&
      [...path.classList].some((v) => classNames.includes(v))
    ) {
      foundParent = true;
      parentEl = path;
      break;
    }

    if (
      !foundParent &&
      path &&
      path.getAttributeNames &&
      [...path.getAttributeNames()].some((v) => attributesList.includes(v))
    ) {
      foundParent = true;
      parentEl = path;
      break;
    }
  }

  return returnType === "obj" ? { found: foundParent, parentEl } : foundParent;
};
export default hasParent;
