<template>
  <div>
    <div class="row-drop-before row-drop-line"></div>
    <div
      v-if="isEmptyRow"
      :class="rowClassName"
      :data-key="item.key"
      :data-row-index="rowIndex"
      role="empty-row"
      :draggable="false"
      @dragover="dragOver($event)"
      @dragleave="dragExit($event)"
      @drop="
        dragExit($event);
        onDrop($event);
      "
    >
      <div v-if="item.text" class="empty-row-text">
        {{ item.text }}
      </div>
    </div>
    <div
      v-else
      :class="rowClassName"
      role="row"
      @click="handleRowClick"
      @contextmenu="handleContextEvent"
      @dblclick="handleDbClickRow"
      @dragstart="handleRowDragStart"
      @drag="handleDragging"
      @dragover="dragOver($event)"
      @dragleave="dragExit($event)"
      @drop="
        dragExit($event);
        onDrop($event);
      "
      @mouseenter="handleMouseOver"
      @mouseleave="handleMouseOut"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      :draggable="isDraggable"
      :data-key="item.key"
      :data-row-index="rowIndex"
      :data-virtual="isVirtualItem(item)"
    >
      <template v-for="(col, index) of columns">
        <div
          v-if="col.visible !== false"
          :key="index"
          :style="generateColStyles(col)"
          :class="generateColClasses(col, 'row', editing || isNew)"
          @click="handleCellClick($event, col)"
        >
          <template v-if="editing || isNew">
            <template v-if="col.editable !== false">
              <template v-if="col.CellEditor">
                <div>
                  <component
                    :is="col.CellEditor"
                    :key="index"
                    v-bind="generateColProps(col)"
                  />
                </div>
              </template>
              <div
                v-else-if="
                  col.cellEditorType && col.cellEditorType === 'select'
                "
              >
                <SelectorCellInput
                  :key="index"
                  v-bind="generateColProps(col)"
                />
              </div>
              <div v-else>
                <InputCellEditor
                  :key="index"
                  v-bind="generateColProps(col)"
                  :type="col.cellEditorType"
                />
              </div>
            </template>
          </template>
          <template v-else-if="!editing && !isNew">
            <template v-if="col.CellComponent">
              <div>
                <component
                  :is="col.CellComponent"
                  :key="index"
                  v-bind="generateColProps(col)"
                />
              </div>
            </template>
            <div v-else :key="index">
              {{ item[col.field] }}
            </div>
          </template>
        </div>
      </template>
    </div>

    <div class="row-drop-after row-drop-line"></div>
  </div>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import { isEmptyRow } from "./helpers/row";
import { IdState } from "vue-virtual-scroller";
import classesObj from "./classes";

import InputCellEditor from "./components/InputCellEditor.vue";
import SelectorCellInput from "./components/SelectorCellInput.vue";
export default {
  components: {
    InputCellEditor,
    SelectorCellInput,
  },
  mixins: [
    IdState({
      // You can customize this
      idProp: (vm) => vm.item.key || "new-row",
    }),
  ],
  inject: ["api"],
  props: {
    item: Object,
    columns: Array,
    editing: Boolean,
    selected: Boolean,
    isNew: Boolean,
    rowIndex: Number,
    isActive: Boolean,
  },
  idState() {
    return {
      hovered: false,
    };
  },
  methods: {
    handleMouseOver() {
      this.idState.hovered = true;
    },
    handleMouseOut() {
      this.idState.hovered = false;
    },
    dragExit(event) {
      this.api.callDragExit(event);
    },
    dragOver(event) {
      this.api.callDragOver(event);
    },
    dragStart(event) {
      if (this.api.allowDragAndDrop) {
        this.api.callDragStart(event);
      }
    },
    handleDragging(event) {
      if (this.api.allowDragAndDrop) {
        this.api.callRowDragging(event);
      }
    },
    onDrop(event) {
      if (this.api.allowDragAndDrop) {
        this.api.callDragStop(event);
      }
    },
    generateColStyles(colData) {
      const def = colData;
      const style = {};
      if (def.width && !def.getWidth) {
        if (def.width === "stretch") {
          style.width = "100%";
        } else if ("%".includes(def.width)) {
          style.width = def.width;
          style.flexShrink = "1";
        } else {
          style.width = def.width + "px";
          style.flexShrink = "0";
        }
      }

      if (def.getWidth) {
        style.width = `${def.getWidth()}px`;

        style.flexShrink = "0";
      }

      if (def.maxWidth) {
        style.maxWidth = def.maxWidth + "px";
      }

      if (def.minWidth) {
        style.minWidth = def.minWidth + "px";
      }
      return style;
    },
    generateColClasses(col, type, isEditing) {
      const def = col;
      let className = "";

      if (type === "header") {
        className = this.classes.headerCell;
        // cellClass = this.classes.headerCell;
        if (def.sortable !== false) {
          className += ` sortable-header`;
        }
      } else if (type === "row") {
        className = this.classes.cell;
        // cellClass = this.classes.cell;

        if (def.expand) {
          className += " expand";
        }
      }

      if (def.field) className += ` ${def.field}-cell`;
      if (def.cssClass) className += ` ${def.cssClass}`;

      if (isEditing && def.editable) {
        className += " editing-cell";
      }
      return { [className]: true };
    },
    async handleContextEvent() {
      const isInAddMode = this.api.isAddModeEnabled();
      if (this.editing || isInAddMode) return;
      this.api.cancelAddRow();
      this.api.checkAndEndEdit(this.item);
      this.api.selectRowByKey(this.item.key);
    },
    handleRowClick(e) {
      if (this.editing) {
        return;
      }

      this.api.handleRowClick(e, this.item);
    },
    handleTouchStart(e) {
      this.api.handleTouchStart(e, this.item)
    },
    handleTouchMove(e){
      this.api.handleTouchMove(e)
    },
    handleTouchEnd(e){
      this.api.handleTouchEnd(e)
    },
    endEditing(doNotCallRowUpdate) {
      this.api.endEditing(doNotCallRowUpdate);
    },
    handleDbClickRow(e) {
      this.api.handleRowDblClick(e, this.item);
      // this.$emit("edit:start", e, this.item);
    },
    getData() {
      return this.item;
    },
    handleCellClick(e, cellData) {
      // this.activeCell = cellData.field;
      this.api.handleCellClick(e, cellData);
    },
    handleRowDragStart(e) {
      if (!this.isDraggable || this.api.isEditingActive()) {
        return;
      }
      let selectedRows = this.api.getSelectedRows();
      if (isEmpty(selectedRows) || selectedRows.length === 1) {
        selectedRows = [{ ...this.item }];
      }
      selectedRows = selectedRows.filter((r) =>
        this.api.checkIfRowSelectionAllowed(r)
      );

      // if (selectedRows.length) {
      e.dataTransfer.setData("text", JSON.stringify(selectedRows));
      // }

      if (this.api.allowDragAndDrop) {
        this.api.callDragStart(e);
      }
    },

    getIndex() {
      return this.item.key;
    },
    isRowSelected() {
      return this.selected;
    },
    cellEdited(cell, value) {
      const rowData = this.item;
      return {
        getField: () => cell.field,
        getOldValue: () => this.item[cell.field],
        getRow: () => this,
        getData: () => ({
          ...rowData,
          [cell.field]: value,
        }),
        getGrid: () => this.api,
        getValue: () => value,
      };
    },
    success(e, value, cell) {
      if (this.editing) {
        const code = e?.keyCode ? e?.keyCode : e?.which;
        if (code === 13) this.endEditing();
        if (cell.cellEdited) cell.cellEdited(this.cellEdited(cell, value));

        this.api.updateEditRowData(cell.field, value);
      } else if (this.isNew) {
        this.api.updateNewRowData(cell.field, value);
      }
    },
    cancel(e) {
      if (e.keyCode === 27) {
        this.endEditing(true);
      }
    },

    getColumnIndex(col) {
      if (col) return `${col.field}-cell`;
      return this.classes.groupCell;
    },
    generateColProps(col) {
      // const currentCell = this.$el.querySelector(
      //   "." + this.getColumnIndex(col)
      // );

      const cellProps = {
        getValue: () => {
          return this.item[col.field] || "";
        },
        getElement: function () {},
        // currentCell,
        getData: () => this.item,
        getRow: () => this,
        cellEdited: col.cellEdited,
        api: this.api,
        ...col,
        rowIndex: this.rowIndex,
        // api: this,
      };

      if (this.item?.addNew) {
        cellProps.getData = () => this.api.getNewRowData();
      }

      const props = {
        cell: cellProps,
        onSuccess: this.success,
        onCancel: this.cancel,
        onRendered: this.api.onCellRendered,
        data: this.item,
        rowId: this.item.key,
        isRowSelected: this.selected,
        isRowEditing: this.editing || this.isNew,
        fieldName: col.field,
        extraData: col.extraData || {},
        rowIndex: this.rowIndex,
        rowHovered: this.idState.hovered,
        rowSelected: this.idState.selected,
        ...col,
        title: undefined,
        // onDataUpdate: col.onDataUpdate,
      };

      return props;
    },
    update(dataToUpdate) {
      this.item = {
        ...this.item,
        ...dataToUpdate,
      };
    },
    isVirtualItem(item) {
      return item.isVirtual ? 1 : 0;
    },
    checkIfRowDragEnabled(item) {
      return this.api.checkIfRowDragEnabled(item);
    },
  },
  computed: {
    classes() {
      return classesObj;
    },
    rowClassName() {
      let className = this.classes.row;

      const extraRowClassNames = this.api.createRowClassNames(this.item);
      const isEmptyRow = this.isEmptyRow;

      if (!isEmptyRow) {
        if (extraRowClassNames) {
          className += ` ${extraRowClassNames}`;
        }

        if (this.editing) {
          className += ` editing`;
        }

        if (this.editing || this.isNew) {
          className += ` row-editing`;
        }

        if (this.selected) {
          className += ` ${this.classes.selected}`;
        }

        if (this.isActive) {
          className += ` ${this.classes.active}`;
        }

        if (this.isNew) {
          className += ` ${this.classes.addNew}`;
        }
      } else {
        className += ` ${this.classes.emptyRow}`;
      }
      return {
        [className]: true,
      };
    },
    isDraggable() {
      return (
        !this.editing &&
        !this.isNew &&
        !this.api.isEditingActive() &&
        this.checkIfRowDragEnabled(this.item)
      );
    },
    isEmptyRow() {
      return isEmptyRow(this.item);
    },
  },
  watch: {
    "item.key": {
      handler() {
        this.$parent.updateSize();
      },
    },
    selected: {
      handler(nVal) {
        this.idState.selected = nVal;
      },
    },
  },
};
</script>
